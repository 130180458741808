import { TsSortDirection } from '@terminus-lib/ui-sort';
import { dateCohortFactory, IAnalyticsGlobalFilters, IDateCohort, IFactoryParams } from '@shared/interfaces';
import { AdsInsightsReportType } from '../enums/insights-report-types.enum';
import { InsightTypes } from '../enums/ad-insight-types.enum';

export interface IAdsInfluencedSharedBaseRequest {
  reportType: AdsInsightsReportType;
  influenceWindow?: number;
  tacticIds?: string[];
  minImpressions?: number;
  maxImpressions?: number;
  pageOffset?: number;
  pageSize?: number;
  insightType?: InsightTypes;
  globalFilters?: IAnalyticsGlobalFilters;
  sortColumn?: string; // TODO: Create an enum of the possible columns to sort by
  sortDirection?: TsSortDirection;
}

export interface IAdsInfluencedBaseRequest extends IAdsInfluencedSharedBaseRequest {
  cohort?: IDateCohort;
}

export interface IAdsInfluencedRequest extends IAdsInfluencedBaseRequest {
  includeSpend?: boolean;
  searchParam?: string;
}

export interface IAdsInfluencedAddToListRequest extends IAdsInfluencedBaseRequest {
  listId: number;
}

export interface IAdsInfluencedPageViewRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.PageView;
}

export interface IAdsInfluencedLeadRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.Lead;
}

export interface IAdsInfluencedCampaignResponsesRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.CampaignResponses;
}

export interface IAdsInfluencePipelineRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.Pipeline;
}

export interface IAdsInfluencedNewPipelineRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.NewPipeline;
}

export interface IAdsInfluencedRevenueViewRequest extends IAdsInfluencedRequest {
  reportType: AdsInsightsReportType.Revenue;
}

export function adsInfluencedRequestFactory<T extends IAdsInfluencedRequest>({
  overrides = {}
}: IFactoryParams<T, number, boolean> = {}): T {
  return {
    cohort: dateCohortFactory(),
    ...overrides
  } as unknown as T;
}
