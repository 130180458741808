<div class="selector-with-trigger-container">
  <mat-select (selectionChange)="emitSelect($event)"
              data-cy="selector-with-trigger-select"
              [formControl]="control"
              class="report-filter-selector">
    <mat-select-trigger>
      {{ triggerTitle | translate }}: {{ control?.value?.label | translate | titlecase }}
    </mat-select-trigger>
    <mat-option *ngFor="let option of options"
                [attr.data-cy]="'selector-with-trigger-' + option.value"
                [value]="option"
                class="reports-filter-options"
                data-cy="selector-with-trigger-option">
      {{ option.label | translate | titlecase }}
    </mat-option>
  </mat-select>
</div>
