import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IStageInfluenceCSVRequest, IStageInfluenceRequest, IStageInfluenceResponse } from '../interfaces';

export enum StageInfluenceURLs {
  CampaignStageInfluence = '/v1/campaign_stage_influence/campaign',
  CampaignTypeStageInfluence = '/v1/campaign_stage_influence/campaign_type',
  CampaignStageInfluenceTotals = '/v1/campaign_stage_influence/campaign/totals',
  CampaignStageInfluenceCSV = '/v1/campaign_stage_influence/export',
}

@Injectable({providedIn: 'root'})
export class StageInfluenceSource extends HttpClientBase {

  getCampaignStageInfluence$(body: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    const endpoint = this.endpoint(StageInfluenceURLs.CampaignStageInfluence);

    return this.httpClient.post<IStageInfluenceResponse>(endpoint, body);
  }

  getCampaignTypeStageInfluence$(body: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    const endpoint = this.endpoint(StageInfluenceURLs.CampaignTypeStageInfluence);

    return this.httpClient.post<IStageInfluenceResponse>(endpoint, body);
  }

  getCampaignStageInfluenceTotals$(body: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    const endpoint = this.endpoint(StageInfluenceURLs.CampaignStageInfluenceTotals);

    return this.httpClient.post<IStageInfluenceResponse>(endpoint, body);
  }

  getCampaignStageInfluenceCSV$(body: IStageInfluenceCSVRequest): Observable<string> {
    const endpoint = this.endpoint(StageInfluenceURLs.CampaignStageInfluenceCSV);

    return this.httpClient.post<string>(endpoint, body, {
      responseType: 'text' as never // use this option to prevent parse error
    });
  }
}
