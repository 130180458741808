import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TsMenuComponent } from '@terminus-lib/ui-menu';

import { DataTypeEnum, NewTrendsType, TrendsType } from '@shared/enums';
import { ILabelValue, TrendingPrevious } from '@shared/interfaces';
import { ReportFilterChange } from '@shared/classes';
import { adaptNewTrendsTypeToOldOne, adaptOldTrendsTypeToNewOne } from './benchmark-control.utils';
import { BenchmarkFields } from './benchmark.enum';

export interface IBenchmarkConfirmEvent {
  [BenchmarkFields.Type]: TrendsType;
  [BenchmarkFields.Previous]: TrendingPrevious | null;
  [BenchmarkFields.Goal]: number | null;
}

@Component({
  selector: 'tsh-benchmark-control',
  templateUrl: './benchmark-control.component.html',
  styleUrls: ['./benchmark-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenchmarkControlComponent extends ReportFilterChange implements OnInit {
  @Input() public goalField: FormControl;
  @Input() public typeField: FormControl;
  @Input() public previous: TrendingPrevious;
  @Output() public confirm = new EventEmitter<IBenchmarkConfirmEvent>();

  @ViewChild(TsMenuComponent) public menu!: TsMenuComponent;

  public readonly options: ILabelValue<NewTrendsType>[] = [{
    label: 'feature.sharedTrending.filters.benchmarkOptions.previousCohort',
    value: NewTrendsType.BenchmarkCohort
  }, {
    label: 'feature.sharedTrending.filters.benchmarkOptions.previousYear',
    value: NewTrendsType.BenchmarkYeah
  }, {
    label: 'feature.sharedTrending.filters.benchmarkOptions.goal',
    value: NewTrendsType.Goal
  }, {
    label: 'feature.sharedTrending.filters.benchmarkOptions.none',
    value: NewTrendsType.Trend
  }];
  public newTrendsType = NewTrendsType;
  public dataTypes = DataTypeEnum;
  public selectedBenchmark: ILabelValue<NewTrendsType>;

  get currentType(): ILabelValue<NewTrendsType> {
    return this._currentType;
  }

  get currentAmount(): number {
    return this._currentAmount;
  }

  private _currentType: ILabelValue<NewTrendsType>;
  private _currentAmount: number;

  ngOnInit(): void {
    const newBenchmarkType = adaptOldTrendsTypeToNewOne(this.typeField.value, this.previous);
    this._currentType = this.options.find(option => option.value === newBenchmarkType);
    this.selectedBenchmark = this._currentType;
    this._currentAmount = this.goalField?.value;
  }

  confirmBenchmarkChange(): void {
    this._currentType = this.selectedBenchmark;
    this._currentAmount = this.goalField?.value;

    if (this._currentType.value === NewTrendsType.Goal
      && (!this._currentAmount || isNaN(this._currentAmount) || Number(this._currentAmount) <= 0)) {
      this.goalField.markAsTouched();
      this.goalField.setErrors({required: true});
      return;
    }

    const {type, previous} = adaptNewTrendsTypeToOldOne(this.currentType.value);
    this.confirm.emit({
      [BenchmarkFields.Type]: type,
      [BenchmarkFields.Previous]: previous,
      [BenchmarkFields.Goal]: this.currentType.value === NewTrendsType.Goal ? this._currentAmount : null,
    });
    this.menu.trigger.closeMenu();
    this.change$.next(true);
  }

  cancelBenchmarkChange(): void {
    this.selectedBenchmark = this._currentType;
    this.goalField.setValue(this._currentAmount, {emitEvent: false});
  }
}
