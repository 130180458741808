import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteItemEnum } from '@shared/enums';

import { AttributionComponent } from './containers/attribution.component';

const routes: Routes = [
  {
    path: '',
    component: AttributionComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'leads'
      },
      {
        path: 'leads',
        loadChildren: () => import('./containers/leads/leads.module').then(m => m.LeadsModule),
        data: {
          parent: {
            title: 'feature.campaignSpecific.parentTitle',
            routeId: RouteItemEnum.CampaignAnalytics
          },
          routeId: RouteItemEnum.CampaignSpecificLeads
        }
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./containers/opportunities/opptys.module').then(m => m.OpptysModule),
        data: {
          parent: {
            title: 'feature.campaignSpecific.parentTitle',
            routeId: RouteItemEnum.CampaignAnalytics
          },
          routeId: RouteItemEnum.CampaignSpecificOpportunities
        }
      },
      {
        path: 'deals',
        loadChildren: () => import('./containers/deals/deals.module').then(m => m.DealsModule),
        data: {
          parent: {
            title: 'feature.campaignSpecific.parentTitle',
            routeId: RouteItemEnum.CampaignAnalytics
          },
          routeId: RouteItemEnum.CampaignSpecificDeals
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttributionRoutingModule {
}
