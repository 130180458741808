/* istanbul ignore file */

import { BREAKPOINT } from '@angular/flex-layout';

export const CARD_CONTAINER_BREAKPOINTS = [
    {
        alias: 'cardContainer.large',
        mediaQuery: 'screen and (min-width: 2557px)',
        overlapping: false
    }
];

export const CustomBreakPointsProvider = {
    multi: true,
    provide: BREAKPOINT,
    useValue: CARD_CONTAINER_BREAKPOINTS
};
