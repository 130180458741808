<div class="header">
  <h3 class="header-title">{{ (data.folder ? ('shared.accountHub.editFolder' | translate: {name: data.folder.folderName}) : 'shared.accountHub.createNewFolder' | translate) }}</h3>
  <i class="fas fa-times"
    mat-dialog-close></i>
</div>
<hr class="dialog-divider">
<div class="content">
  <form [formGroup]="form">
    <ts-input
      type="text"
      [label]="'shared.accountHub.folderName' | translate"
      [placeholder]="'shared.accountHub.folderName' | translate"
      formControlName="folder_name"
      data-cy="ds-create-folder-name"
      [isRequired]="true"
      [errorMessage]="form.get('folder_name').dirty && (
              (form.get('folder_name').errors?.required && ('shared.accountHub.errors.required' | translate)) ||
              (form.errors?.duplicate && ('shared.accountHub.errors.duplicate' | translate)) ||
              (form.get('folder_name').errors?.maxlength && ('shared.accountHub.errors.maxlength' | translate))
            )"
      [hint]="'[' + (form.get('folder_name').value?.length || 0) +  '/' +  '64]'">
    </ts-input>
    <ts-selection-list
      *ngIf="data.folder"
      formControlName="owners"
      [displayFormatter]="formatDisplay"
      [valueComparator]="userCompareFunction"
      [label]="'shared.accountHub.owners' | translate"
      (queryChange)="query = $event"
      (selectionChange)="query = $event.value[0].email">
      <ts-option
        *ngFor="let user of users"
        [value]="user"
        [option]="user">
        {{ user.email }}
      </ts-option>
    </ts-selection-list>
      <ts-radio-group
        formControlName="folder_permission"
        [fieldsetLegend]="'shared.accountHub.folderAccess' | translate"
        class="accounts-create-folder">
        <ts-radio-button [value]="FolderPermission.Public">
          {{ FolderPermission.Public }}
        </ts-radio-button>
        <ts-radio-button [value]="FolderPermission.Private">
          {{ FolderPermission.Private }}
        </ts-radio-button>
        <ts-radio-button [value]="FolderPermission.ReadOnly">
          {{ FolderPermission.ReadOnly }}
        </ts-radio-button>
      </ts-radio-group>
  </form>
</div>
<hr class="dialog-divider">
<div class="actions">
  <ts-button
    theme="default"
    [isDisabled]="form.invalid"
    data-cy="ds-create-folder-save-button"
    (clicked)="close()">
    {{ (data ? 'shared.accountHub.saveChanges' : 'shared.accountHub.createNewFolder') | translate }}
  </ts-button>
</div>
