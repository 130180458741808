import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TsInputModule } from '@terminus-lib/ui-input';

import { VisualizationTypesModule } from './visualization-types/visualization-types.component';
import { TileFormFields } from '../../enums/tile-form-fields.enum';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import {
  IAccountFolder,
  IDataFilter,
  IDateCohort,
  IDateCohortGroup,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
  ISelectedGlobalFilter,
  ITargetFolderItem,
  ITileSettingControl
} from '@shared/interfaces';
import { TileSettingsFormControlsModule } from './controls/tile-settings-form-controls.component';
import { VisualizationOptionsModule } from './visualization-options/visualization-options.component';
import { TileGlobalFiltersModule } from './tile-global-filters/tile-global-filters.component';
import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { TILE_NAME_MAX_LENGTH } from '@shared/constants';

@Component({
  selector: 'tsh-tile-settings-form',
  templateUrl: './tile-settings-form.component.html',
  styleUrls: ['./tile-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileSettingsFormComponent {
  // parent form
  @Input() public form: FormGroup;
  @Input() public dateCohortOptions: IDateCohortGroup[];
  @Input() public settingsFilters: ITileSettingControl[] | null;
  @Input() public selectedCohort: IDateCohort;
  @Input() public savedGlobalFilters: IDataFilter[];
  @Input() public selectedGlobalFilters: IDataFilter;
  @Input() public globalFilters: IGlobalFilters;
  @Input() public accountListFolders: IAccountFolder[];
  @Input() public globalFiltersState: IGlobalFilterFolder;
  @Input() public accountListFoldersState: IGlobalFilterFolderItem;
  @Input() public globalFiltersSearchState: IGlobalFilterFolder;
  @Input() public accountListsSearchState: IGlobalFilterFolderItem;
  @Input() public showGlobalFiltersSection: boolean;
  @Input() public appliedFiltersNumber: number;
  @Input() public allSelectFilters: ISelectedGlobalFilter[] = [];
  @Input() public isHighlightedErrors: boolean;

  @Output() public dateRangeFilterChange = new EventEmitter<IDateCohort>();
  @Output() public apply = new EventEmitter<IDateCohort | undefined>();
  @Output() public searchGlobalFilters = new EventEmitter<string>();
  @Output() public applySavedGlobalFilter = new EventEmitter<IDataFilter>();
  @Output() public toggleFolderSelect = new EventEmitter<{folders: ITargetFolderItem[], selectAll: boolean}>();
  @Output() public toggleFolderItemSelect = new EventEmitter<ITargetFolderItem>();
  @Output() public removeSelectedFolder = new EventEmitter<ITargetFolderItem>();

  public readonly fields = TileFormFields;
  public readonly MAX_NAME_LENGTH = TILE_NAME_MAX_LENGTH;

  applyDateCohort(cohort: IDateCohort | undefined): void {
    this.apply.emit(cohort);
  }

  changeDateCohort(cohort: IDateCohort): void {
    this.dateRangeFilterChange.emit(cohort);
  }

  emitSearchGlobalFilters(searchQuery: string): void {
    this.searchGlobalFilters.emit(searchQuery);
  }

  emitApplySavedGlobalFilter(filter: IDataFilter): void {
    this.applySavedGlobalFilter.emit(filter);
  }

  emitToggleFolderSelect(folders: ITargetFolderItem[]): void {
    this.toggleFolderSelect.emit({folders, selectAll: false});
  }

  folderSelectAllItems(folders: ITargetFolderItem[]): void {
    this.toggleFolderSelect.emit({folders, selectAll: true});
  }

  emitToggleFolderItemSelect(folderItem: ITargetFolderItem): void {
    this.toggleFolderItemSelect.emit(folderItem);
  }

  emitRemoveSelectedFolder(folderItem: ITargetFolderItem): void {
    this.removeSelectedFolder.emit(folderItem);
  }

  getInputHint(hasError: boolean): string {
    if (hasError) {
      return 'shared.dashboards.dialog.nameLengthValidation';
    } else {
      return '';
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    VisualizationTypesModule,
    DateCohortsModule,
    TranslateModule,
    TsInputModule,
    TileSettingsFormControlsModule,
    VisualizationOptionsModule,
    TileGlobalFiltersModule,
    SharedUiPipesMemoizeModule,
  ],
  declarations: [TileSettingsFormComponent],
  exports: [TileSettingsFormComponent],
})
export class TileSettingsFormModule {
}
