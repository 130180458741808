/* istanbul ignore file */
import { Action, createAction, props } from '@ngrx/store';

import * as interfaces from '../../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { TypedAction } from '@ngrx/store/src/models';
import { CampaignActionsTypes } from '../campaign/campaign.actions';
import { ILabelValue } from '@shared/interfaces';

export enum MediumsActionsTypes {
  SetUsedParams = '[Mediums] Set used params in driver request',
  GetMediums = '[Mediums] Get all data',
  ToggleTableRow = '[Mediums] Toggle Table Row',
  CloseNestedTables = '[Mediums] Close Nested Tables',
  LoadMetaData = '[Mediums] Load MetaData',
  LoadMetaDataSuccess = '[Mediums] Load MetaData Success',
  LoadMetaDataFailure = '[Mediums] Load MetaData Failure',
  LoadResponses = '[Mediums] Load Responses',
  LoadResponsesSuccess = '[Mediums] Load Responses Success',
  LoadResponsesFailure = '[Mediums] Load Responses Failure',
  LoadAttribution = '[Mediums] Load Attribution',
  LoadAttributionSuccess = '[Mediums] Load Attribution Success',
  LoadAttributionFailure = '[Mediums] Load Attribution Failure',
  LoadInfluence = '[Mediums] Load Influence',
  LoadInfluenceSuccess = '[Mediums] Load Influence Success',
  LoadInfluenceFailure = '[Mediums] Load Influence Failure',
  LoadReturns = '[Mediums] Load Returns',
  LoadReturnsSuccess = '[Mediums] Load Returns Success',
  LoadReturnsFailure = '[Mediums] Load Returns Failure',
  LoadCosts = '[Mediums] Load Costs',
  LoadCostsSuccess = '[Mediums] Load Costs Success',
  LoadCostsFailure = '[Mediums] Load Costs Failure',
}

const setUsedParams = createAction(
  MediumsActionsTypes.SetUsedParams,
  props<{ request: IAnalyticsRequest }>()
);

const getMediums = createAction(
  MediumsActionsTypes.GetMediums,
);

const toggleTableRow = createAction(
  MediumsActionsTypes.ToggleTableRow,
  props<{ id: string }>()
);

const closeNestedTables = createAction(
  MediumsActionsTypes.CloseNestedTables,
);

const loadMetaData = createAction(
  MediumsActionsTypes.LoadMetaData,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadMetaDataSuccess = createAction(
  MediumsActionsTypes.LoadMetaDataSuccess,
  props<interfaces.ISuccessPayload<interfaces.IMetaData<interfaces.IWebActivitiesMediumResponse>>>()
);

const loadMetaDataFailure = createAction(
  MediumsActionsTypes.LoadMetaDataFailure,
  props<{ message: string }>()
);

const loadResponses = createAction(
  MediumsActionsTypes.LoadResponses,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadResponsesSuccess = createAction(
  MediumsActionsTypes.LoadResponsesSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignResponses>>()
);

const loadResponsesFailure = createAction(
  MediumsActionsTypes.LoadResponsesFailure,
  props<{ message: string }>()
);

const loadAttribution = createAction(
  MediumsActionsTypes.LoadAttribution,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadAttributionSuccess = createAction(
  MediumsActionsTypes.LoadAttributionSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignAttribution>>()
);

const loadAttributionFailure = createAction(
  MediumsActionsTypes.LoadAttributionFailure,
  props<{ message: string }>()
);

const loadInfluence = createAction(
  MediumsActionsTypes.LoadInfluence,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadInfluenceSuccess = createAction(
  MediumsActionsTypes.LoadInfluenceSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignInfluence>>()
);

const loadInfluenceFailure = createAction(
  MediumsActionsTypes.LoadInfluenceFailure,
  props<{ message: string }>()
);

const loadReturns = createAction(
  MediumsActionsTypes.LoadReturns,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadReturnsSuccess = createAction(
  MediumsActionsTypes.LoadReturnsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignReturns>>()
);

const loadReturnsFailure = createAction(
  MediumsActionsTypes.LoadReturnsFailure,
  props<{ message: string }>()
);

const loadCosts = createAction(
  MediumsActionsTypes.LoadCosts,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadCostsSuccess = createAction(
  MediumsActionsTypes.LoadCostsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignCosts>>()
);

const loadCostsFailure = createAction(
  MediumsActionsTypes.LoadCostsFailure,
  props<{ message: string }>()
);

export const mediumsActions = {
  setUsedParams,
  getMediums,
  toggleTableRow,
  closeNestedTables,
  loadMetaData,
  loadMetaDataSuccess,
  loadMetaDataFailure,
  loadResponses,
  loadResponsesSuccess,
  loadResponsesFailure,
  loadAttribution,
  loadAttributionSuccess,
  loadAttributionFailure,
  loadInfluence,
  loadInfluenceSuccess,
  loadInfluenceFailure,
  loadReturns,
  loadReturnsSuccess,
  loadReturnsFailure,
  loadCosts,
  loadCostsSuccess,
  loadCostsFailure,
}

export type MediumsLoadActions = interfaces.ILoadPayload & TypedAction<MediumsActionsTypes>;

export type MediumsLoadSuccessActions = interfaces.ISuccessPayload<any> & TypedAction<MediumsActionsTypes>;

// Establishing dependencies between LoadSuccess and Load actions (Campaign Groups load actions)
export const mediumsActionsMap: Map<MediumsActionsTypes, MediumsLoadActions> = new Map()
  .set(MediumsActionsTypes.LoadAttributionSuccess, loadAttribution())
  .set(MediumsActionsTypes.LoadCostsSuccess, loadCosts())
  .set(MediumsActionsTypes.LoadInfluenceSuccess, loadInfluence())
  .set(MediumsActionsTypes.LoadMetaDataSuccess, loadMetaData())
  .set(MediumsActionsTypes.LoadResponsesSuccess, loadResponses())
  .set(MediumsActionsTypes.LoadReturnsSuccess, loadReturns());
