export enum OrgAdminConfigTypes {
    OcrOverride = 'ocrOverride',
    WeightType = 'weightType'
}

export enum AttributionObjects {
    Channel = 'channel',
    TouchPosition = 'touchPosition',
    Time = 'time',
    TouchType = 'touchType',
    TouchStatus = 'touchStatus',
    OcrRole = 'ocrRole',
}

export enum AttributionTypes {
    Percent = 'percent',
    Custom = 'custom'
}
