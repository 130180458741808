import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { hubTokenName, TILE_DATA_FIELD_NAME } from '@shared/constants';

import { TrendingSource } from '../sources/trending.source';
import { EnvService } from '@shared/environment';
import { ITrendingDetails, ITrendingGroupDetails, ITrendingGroupDetailsParams, ITrendingParams, ITrendingTotal } from '../interfaces';
import { DataTypeEnum, ModelType, RouteItemEnum, ToDate, TrendsType } from '@shared/enums';
import { DataSetType, FrequencyType, GroupType } from '@measurement-studio/enums';
import { getStartAndEndDateFromParams, replaceLegacyCohort, toCapitalize } from '@util/helpers';
import {
  IAppliedGlobalFiltersAsParams,
  IDashboardTile,
  IDateCohort, IExportToken,
  IGetTileData,
  ILabelValue,
  IOrgConfigFeatures,
  ITileData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes,
  TrendingPrevious
} from '@shared/interfaces';
import { getConvertedDates, getFrequencyTypes } from '../utils/trending.utils';
import {
  METRIC_OPTIONS_DOLLAR_INFLUENCED,
  METRIC_OPTIONS_FULL_PLATFORM,
  TileSettingsTrendingFields,
  TRENDING_DOLLARS_METRICS_IDS
} from '../data/trending.data';
import * as DateCohortsStore from '@date-cohorts';
import * as OrgConfigStore from '@org-config';
import { getWfStagesForMetricOptions } from '@shared/data-access/catalogs';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { BenchmarkFields } from '@ui/components/benchmark-control';

@Injectable({providedIn: 'root'})
export class TrendingService implements IGetTileData<ITrendingParams> {
  constructor(private source: TrendingSource,
              private store: Store<unknown>,
              private retry: RetryWithEscalation,
              private translateService: TranslateService,
              envService: EnvService,) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getTotal$(params: ITrendingParams, routeId: RouteItemEnum): Observable<ITrendingTotal> {
    return regenerateOnRetry(() => this.source.getTotal$(params, routeId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getGroupDetails$(params: ITrendingGroupDetailsParams, routeId: RouteItemEnum): Observable<ITrendingGroupDetails> {
    return regenerateOnRetry(() => this.source.getGroupDetails$(params, routeId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getDetails$(params: ITrendingParams, routeId: RouteItemEnum): Observable<ITrendingDetails> {
    return regenerateOnRetry(() => this.source.getDetail$(params, routeId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileData$(params: ITrendingParams, routeId: RouteItemEnum): Observable<ITileData> {
    // if type is Benchmark we have to make 2 requests - for current and for previous periods
    const isBenchmark = params.type === TrendsType.Benchmark;
    const previous: TrendingPrevious = params.previous && ['true', 'yoy'].includes(params.previous)
      ? params.previous
      : 'true';
    const requests = isBenchmark
      ? [
        this.source.getTotal$({...params, previous: null}, routeId),
        this.source.getTotal$({...params, previous}, routeId)
      ]
      : [
        this.source.getTotal$({...params, previous: null}, routeId),
      ];
    return combineLatest(requests).pipe(
      withLatestFrom(this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(params.cohort)))),
      map(([totals, dateCohort]: [ITrendingTotal[], IDateCohort]) => {
        return this.tileAdapter(totals[0], totals[1], params, dateCohort);
      })
    );
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    const numberFormat = TRENDING_DOLLARS_METRICS_IDS.includes(tile.settings.field as string)
      ? '$#a'
      : '#a';
    const numberFormatGlobal = TRENDING_DOLLARS_METRICS_IDS.includes(tile.settings.field as string)
      ? '$#,###.00'
      : '#,###.';
    return combineLatest([
      this.store.pipe(select(getWfStagesForMetricOptions)),
      this.store.pipe(select(OrgConfigStore.getOrgConfigFeatures))
    ]).pipe(
      // Get all metrics
      map(([stages, features]: [
        ILabelValue[],
        IOrgConfigFeatures
      ]) => this.getMetricOptions(stages, features)),
      // get selected metrics
      map((metricOptions: ILabelValue[]) =>
        metricOptions.find(option => option.value === tile.settings.field)),
      // get translation for this metric
      mergeMap((selectedField: ILabelValue) =>
        selectedField?.label ? this.translateService.get(selectedField.label) : of(null)
      ),
      map((yAxisLabel: string) => ({
        metricLabel: yAxisLabel,
        chart: {
          numberFormatter: {
            numberFormat: numberFormatGlobal
          },
          colors: {
            list: ['#4F5A9F', '#C4CBCF']
          },
          yAxes: [{
            type: 'ValueAxis',
            min: 0,
            title: {
              text: toCapitalize(yAxisLabel),
              fill: '#637178'
            },
            numberFormatter: {
              type: 'NumberFormatter',
              forceCreate: true,
              numberFormat
            }
          }],
          xAxes: [{
            type: 'CategoryAxis',
            dataFields: {
              category: TILE_DATA_FIELD_NAME
            },
            renderer: {
              grid: {
                disabled: true
              },
              minGridDistance: 160, // to show first and last label
            }
          }],
        }
      }))
    );
  }

  getMetricOptions(stages: ILabelValue[],
                   features: IOrgConfigFeatures): ILabelValue[] {

    const dollarOptions = features?.campaignTrending?.influenced
      ? METRIC_OPTIONS_DOLLAR_INFLUENCED
      : [];
    return [
      ...METRIC_OPTIONS_FULL_PLATFORM,
      ...dollarOptions,
      ...stages
    ];
  }

  getTrendingFiltersFromParams(params: Params,
                               userModels: ILabelValue[],
                               metricOptions: ILabelValue[]): ITrendingParams {
    // get query params from url or use default params
    const defaultQueryParams: ITrendingParams = {
      dataSet: DataSetType.MembershipActivity,
      model: ModelType.Even,
      field: 'leads',
      grp: GroupType.NoList,
      [BenchmarkFields.Type]: TrendsType.Benchmark,
      freqType: FrequencyType.Auto,
      [BenchmarkFields.Previous]: null,
      [BenchmarkFields.Goal]: null,
      cohort: ToDate.QUARTER_TO_DATE
    };
    const modelType = params.model && userModels.find(model => model.value === params.model)
      ? params.model
      : defaultQueryParams.model;
    const field = params.field && metricOptions.find(model => model.value === params.field)
      ? params.field
      : defaultQueryParams.field;
    const trendType = params.type
    && [TrendsType.Trend, TrendsType.Goal, TrendsType.Benchmark].includes(params.type)
      ? params.type
      : defaultQueryParams.type;
    const groupType = params.grp && [GroupType.NoList, GroupType.Only].includes(params.grp)
      ? params.grp
      : defaultQueryParams.grp;
    const dataSetType = params.dataSet && [
      DataSetType.MembershipActivity,
      DataSetType.OpptyCloseDate,
      DataSetType.OpptyCreatedDate,
      DataSetType.CampaignCreatedDate
    ].includes(params.dataSet)
      ? params.dataSet
      : defaultQueryParams.dataSet;
    // leave previous only if trend type is Benchmark otherwise set null
    const previous = params.previous && ['true', 'yoy'].includes(params.previous) && trendType === TrendsType.Benchmark
      ? params.previous
      : trendType === TrendsType.Benchmark ? 'true' : defaultQueryParams.previous;
    const freqType = params.freqType && getFrequencyTypes(params.cohort).find(type => type.value === params.freqType)
      ? params.freqType
      : defaultQueryParams.freqType;
    const filters = {
      dataSet: dataSetType,
      model: modelType,
      field: field,
      grp: groupType,
      [BenchmarkFields.Type]: trendType,
      freqType: freqType,
      [BenchmarkFields.Previous]: previous,
      [BenchmarkFields.Goal]: params.goal && !isNaN(params.goal)
        ? Number(params.goal)
        // if type is goal and there is no goal amount then set 0 as default amount
        : trendType === TrendsType.Goal ? 0 : defaultQueryParams.goal,
      cohort: replaceLegacyCohort(params.cohort) || defaultQueryParams.cohort,
      endDate: params.endDate,
      startDate: params.startDate,
    };

    return {
      ...filters,
      ...getStartAndEndDateFromParams(filters.cohort, filters.startDate, filters.endDate)
    };
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    // TODO: figure our how to pass user model and metrics
    return {
      ...params, // in case if we have global filters we have to keep them
      ...this.getTrendingFiltersFromParams(params, [], []),
      [TileSettingsTrendingFields.Field]: params[TileSettingsTrendingFields.Field] || 'leads',
      [TileSettingsTrendingFields.Model]: params[TileSettingsTrendingFields.Model] || ModelType.Even,
      [TileSettingsTrendingFields.Goal]: params[TileSettingsTrendingFields.Goal],
    };
  }

  downloadTrendingCsv$(filters: ITrendingGroupDetailsParams, globalFilters: IAppliedGlobalFiltersAsParams,
                       token: IExportToken, isCampaignTrends: boolean): Observable<string> {
    return regenerateOnRetry(() => this.source.downloadTrendingCsv$(filters, globalFilters, token, isCampaignTrends))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileSettingsFilters$(): Observable<ITileSettingControl[]> {
    return combineLatest([
      this.store.pipe(select(getWfStagesForMetricOptions)),
      this.store.pipe(select(OrgConfigStore.getOrgConfigFeatures))
    ]).pipe(
      map(([stages, features]: [ILabelValue[], IOrgConfigFeatures]) =>
        this.getMetricOptions(stages, features)),
      withLatestFrom(
        this.store.pipe(select(OrgConfigStore.getUserModels))
      ),
      map(([metrics, models]: ILabelValue[][]) => [{
        key: TileSettingsTrendingFields.Field,
        label: 'shared.dashboards.settings.metric',
        type: TileSettingsControlTypes.Selector,
        options: metrics,
      }, {
        key: TileSettingsTrendingFields.Interval,
        label: 'feature.sharedTrending.filters.interval',
        type: TileSettingsControlTypes.Selector,
        options: null,
        getCustomOptions: (settings: ITileSettings) => getFrequencyTypes(settings?.cohort as string),
      }, {
        key: TileSettingsTrendingFields.Model,
        label: 'feature.sharedTrending.filters.attributionModel',
        type: TileSettingsControlTypes.Selector,
        options: models
      }, {
        key: TileSettingsTrendingFields.DataSet,
        label: 'feature.sharedTrending.filters.campaignsThat',
        type: TileSettingsControlTypes.Selector,
        options: new DataSetOptions().getDataSetFullOptions()
      }, {
        key: TileSettingsTrendingFields.Type,
        label: '',
        type: TileSettingsControlTypes.Benchmark,
        options: null
      }])
    );
  }

  private tileAdapter(nowData: ITrendingTotal,
                      thenData: ITrendingTotal | null,
                      params: ITrendingParams,
                      dateCohort: IDateCohort): ITileData {
    const labels = {
      now: dateCohort?.name || 'Current period',
      then: params?.type === TrendsType.Goal ? 'Goal' : 'Previous period',
    };
    const items = this.getTileItems(nowData, thenData, params, labels);
    const previousMetric = items.items.length && params?.type === TrendsType.Benchmark
      ? {
        previous: {
          value: items.items[items.items.length - 1][labels.then] as number,
          dataType: TRENDING_DOLLARS_METRICS_IDS.includes(params?.field)
            ? DataTypeEnum.Currency
            : DataTypeEnum.Number
        },
      }
      : {};
    const metric = items.items.length
      ? {
        metric: {
          current: {
            value: items.items[items.items.length - 1][labels.now] as number,
            dataType: TRENDING_DOLLARS_METRICS_IDS.includes(params?.field)
              ? DataTypeEnum.Currency
              : DataTypeEnum.Number
          },
          ...previousMetric
        }
      }
      : {};

    return {
      ...items,
      ...metric
    };
  }

  private getTileItems(nowData: ITrendingTotal,
                       thenData: ITrendingTotal | null,
                       params: ITrendingParams,
                       labels: { now: string, then: string }): ITileData {
    const isGoal = params?.type === TrendsType.Goal;
    if (nowData?.data?.length) {
      // get dates array based on start date, end date and frequency value
      const nowDates = getConvertedDates(nowData).map(date => {
        return nowData.frequency === FrequencyType.Month
        || nowData.frequency === FrequencyType.Year
          ? format(date, 'MMM, yyyy')
          : format(date, 'MMM dd, yyyy');
      });

      // it could be more data for previous period then for current that's why we have to show all of them, not only
      // current period's data. Need to calculate number of periods to show in UI
      const periodNumbers = nowData.data.length && thenData?.data?.length
      && thenData.data.length > nowData.data.length
        ? thenData.data.length
        : nowData.data.length;
      const data = [];
      for (let i = 0; i < periodNumbers; i++) {
        const currentItem = nowData.data[i];
        // get cumulated value for current period
        const now = currentItem ? {
          [labels.now]: i === 0 || data.length === 0
            ? currentItem.totals
            : currentItem.totals + data[i - 1][labels.now],
        } : {
          [labels.now]: null,
        };
        // get cumulated value for previous period if there is data for this period
        const then = params.type === TrendsType.Benchmark && thenData?.data && thenData.data[i]
          ? {
            [labels.then]: i === 0 || data.length === 0
              ? thenData.data[i].totals
              : thenData.data[i].totals + data[i - 1][labels.then],
          }
          : isGoal
            ? {
              [labels.then]: params.goal || 0
            } // set goal amount as previous value
            : null;
        data.push({
          [TILE_DATA_FIELD_NAME]: nowDates[i] || format(new Date(), 'MMM dd, yyyy'),
          ...now,
          ...then,
        });
      }
      // if every item in the data is null or equal 0 then return empty result
      if (data.every(item => (item[labels.then] === null || item[labels.then] === 0)
        && (item[labels.now] === null || item[labels.now] === 0))) {
        return {
          items: [],
        };
      }
      // otherwise return items
      return {
        items: data,
      };
    }
    return {
      items: [],
    };
  }
}
