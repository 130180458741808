<ng-container *ngIf="filters">
  <tsh-date-cohort
    *ngIf="selectedDateCohort"
    (apply)="applyDateCohort($event)"
    (dateRangeFilterChange)="changeDateCohort($event)"
    [selectedDateRange]="selectedDateCohort"
    [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>
  <tsh-report-setting-panel
    (apply)="apply()"
    [reportName]="reportName"
    [isDisabled]="isLoading">
    <tsh-report-filter-selector
      [title]="datasetTitle"
      [options]="datasetTypeOptions"
      [field]="filterForm.get(filterFormField.dataSet)"
      data-cy="analytics-filters-data-set">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      [title]="influenceTitle"
      [options]="influenceOptions"
      [field]="filterForm.get(filterFormField.influenceType)"
      data-cy="analytics-filters-influence-type">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      [title]="modelTitle"
      [options]="modelTypeOptions"
      [field]="filterForm.get(filterFormField.model)"
      data-cy="analytics-filters-model">
    </tsh-report-filter-selector>
  </tsh-report-setting-panel>
</ng-container>
