<ts-expansion-panel class="global-filter-expansion-panel" (expandedChange)="panelToggled()">
  <ts-expansion-panel-trigger *ngIf="folderState" collapsedHeight="48px" expandedHeight="64px">
    {{ titleMap[titleKey] | translate }}

    <ts-chip tsChipBadge *ngIf="!!folderState.selectedItems" class="filter-item-badge" theme="default"
      [isDisabled]="false" [isRemovable]="false" [isSelectable]="false">
      {{ folderState.selectedItems }}
    </ts-chip>

  </ts-expansion-panel-trigger>

  <ul *ngIf="accountListFolder" class="filter-menu-list">
    <li *ngFor="let accountList of accountListFolder" class="no-bullets">
      <div *ngIf="displayFolderItem(folderState, accountList.folderName)" class="filter-menu-item-container">
        <div class="filter-menu-item">
          <ts-checkbox size="medium" [isChecked]="folderState.itemSelect[accountList.folderName].allSelected"
            (inputChange)="selectFilterFolder.emit(targetFolderItem(titleKey, accountList.folderName))">
          </ts-checkbox>

          <ts-tooltip [tooltipValue]="accountList.folderName | translate" tooltipPosition="above"
            [hasUnderline]="false">
            <div class="ellipsized"
              (click)="selectFilterFolder.emit(targetFolderItem(titleKey, accountList.folderName))">
              {{ accountList.folderName | translate }}
            </div>
          </ts-tooltip>

          <ts-chip tsChipBadge *ngIf="!!folderState.itemSelect[accountList.folderName].selectedItems"
            class="filter-item-badge" theme="default" [isDisabled]="false" [isRemovable]="false" [isSelectable]="false">
            {{ folderState.itemSelect[accountList.folderName].selectedItems }}
          </ts-chip>
        </div>

        <ts-icon-button *ngIf="!folderState.itemSelect[accountList.folderName].isOpen" buttonType="button"
          class="hub-icon-sm" [icon]="iconRight"
          (clicked)="toggleFilterFolderMenu.emit(targetFolderItem(titleKey, accountList.folderName))">
        </ts-icon-button>
        <ts-icon-button *ngIf="folderState.itemSelect[accountList.folderName].isOpen" buttonType="button"
          class="hub-icon-sm" [icon]="iconDown"
          (clicked)="toggleFilterFolderMenu.emit(targetFolderItem(titleKey, accountList.folderName))">
        </ts-icon-button>
      </div>
      <ng-container
        *ngIf="displayFolderItem(folderState, accountList.folderName) && folderState.itemSelect[accountList.folderName].isOpen">
        <ul *ngFor="let content of accountList.contents" class="filter-menu-item-container">
          <ng-container
            *ngIf="displayFolderItemOption(folderState.itemSelect[accountList.folderName].itemSelect, content.id)">
            <ts-checkbox size="medium"
              [isChecked]="folderState.itemSelect[accountList.folderName].itemSelect[content.id]"
              (inputChange)="selectFilterFolderItem.emit(targetFolderItemKey(titleKey, accountList.folderName, content.id))"></ts-checkbox>

            <ts-tooltip [tooltipValue]="content.name | translate" tooltipPosition="above" [hasUnderline]="false">
              <div class="ellipsized"
                (click)="selectFilterFolderItem.emit(targetFolderItemKey(titleKey, accountList.folderName, content.id))">
                {{ content.name | translate }}
              </div>
            </ts-tooltip>

          </ng-container>
        </ul>
      </ng-container>
    </li>
  </ul>

  <ul *ngIf="filterOptions" class="filter-menu-list">
    <li *ngFor="let filter of filterOptions" class="no-bullets">
      <div *ngIf="filter.number !== 'segments' && displayFolderItem(folderState, filter.number)" class="filter-menu-item-container">
        <div class="filter-menu-item">
          <ts-checkbox size="medium" [isChecked]="folderState.itemSelect[filter.number].allSelected"
            (inputChange)="selectFilterFolder.emit(targetFolderItem(titleKey, filter.number))"></ts-checkbox>

          <span (click)="selectFilterFolder.emit(targetFolderItem(titleKey, filter.number))">
            {{ filter.name }}
          </span>

          <ts-chip tsChipBadge *ngIf="!!folderState.itemSelect[filter.number].selectedItems" class="filter-item-badge"
            theme="default" [isDisabled]="false" [isRemovable]="false" [isSelectable]="false">
            {{ folderState.itemSelect[filter.number].selectedItems }}
          </ts-chip>
        </div>

        <ts-icon-button *ngIf="!folderState.itemSelect[filter.number].isOpen" buttonType="button" class="hub-icon-sm"
          [icon]="iconRight" (clicked)="toggleFilterFolderMenu.emit(targetFolderItem(titleKey, filter.number))">
        </ts-icon-button>
        <ts-icon-button *ngIf="folderState.itemSelect[filter.number].isOpen" class="hub-icon-sm" buttonType="button"
          [icon]="iconDown" (clicked)="toggleFilterFolderMenu.emit(targetFolderItem(titleKey, filter.number))">
        </ts-icon-button>
      </div>

      <!-- segments skips the nested folder, no need to control what to display in the nested scaffolding -->
      <ul *ngIf="filter.number === 'segments' || displayFolderItem(folderState, filter.number) && folderState.itemSelect[filter.number].isOpen" class="filter-menu-list-item">
        <ng-container *ngFor="let data of filter.data">
          <li class="filter-menu-item-container"
            *ngIf="displayFolderItemOption(folderState.itemSelect[filter.number].itemSelect, data)">
            <div class="filter-menu-item">
              <ts-checkbox size="medium" [isChecked]="folderState.itemSelect[filter.number].itemSelect[data]"
                (inputChange)="selectFilterFolderItem.emit(targetFolderItemKey(titleKey, filter.number, data))"></ts-checkbox>
              <ts-tooltip [tooltipValue]="getDisplayName(titleKey, data)">
                <div class="ellipsized"
                  (click)="selectFilterFolderItem.emit(targetFolderItemKey(titleKey, filter.number, data))">
                  {{ getDisplayName(titleKey, data) }}
                </div>
              </ts-tooltip>
            </div>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</ts-expansion-panel>