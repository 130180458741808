import { ChangeDetectionStrategy, Component } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';
import { Observable } from 'rxjs';

import { TrendingDataPeriod } from '../../enums';
import { ICampaignPageEvent, ICampaignRowEvent, ICampaignSortEvent, IGroupDetail, ITrendingCampaignDetails } from '../../interfaces';
import * as selectors from '../../state/trending.selectors';
import { trendingActions } from '../../state/trending.actions';

@Component({
  selector: 'ms-trending-details',
  templateUrl: './trending-details.component.html',
  styleUrls: ['./trending-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingDetailsComponent {
  public groupDetails$ = this.store.pipe(select(selectors.getTrendingGroupDetailsInfo));
  public groupDetailsColumns$ = this.store.pipe(select(selectors.getGroupDetailsColumns));
  public groupDetailsSortState$ = this.store.pipe(select(selectors.getGroupDetailsColumns));
  public groupDetailsTotals$ = this.store.pipe(select(selectors.getGroupDetailsTotals));
  public groupDetailsData$ = this.store.pipe(select(selectors.getGroupDetailsData));
  public groupDetailsLoading$ = this.store.pipe(select(selectors.getCampaignsLoading));
  public campaigns$: Observable<Record<TrendingDataPeriod, Record<string, ITrendingCampaignDetails[]>>> =
    this.store.pipe(select(selectors.getSortedCampaigns));
  public campaignColumns$ = this.store.pipe(select(selectors.getCampaignDetailsColumns));
  public campaignSortState$ = this.store.pipe(select(selectors.getCampaignDetailsSortState));
  public campaignPagination$ = this.store.pipe(select(selectors.getCampaignDetailsPagination));
  public campaignRowState$ = this.store.pipe(select(selectors.getCampaignDetailsRowState));
  public campaignTotalCount$ = this.store.pipe(select(selectors.getCampaignDetailsTotalCount));
  public isCampaignTrendsReport$ = this.store.pipe(select(selectors.isCampaignTrendsReport));
  public responsesColumns$ = this.store.pipe(select(selectors.getResponsesColumns));
  public filters$ = this.store.pipe(select(selectors.getTrendingFilters));

  constructor(private store: Store<unknown>) {
  }

  changeSort(data: { state: TsSortState, period: TrendingDataPeriod }): void {
    this.store.dispatch(trendingActions.changeGroupDetailsSortState(data));
  }

  changeCampaignSort(data: ICampaignSortEvent): void {
    this.store.dispatch(trendingActions.changeCampaignDetailsSortState({sortEvent: data}));
  }

  changeCampaignPage(data: ICampaignPageEvent): void {
    this.store.dispatch(trendingActions.changeCampaignDetailsPagination({pageEvent: data}));
  }

  expandCampaignRow(data: ICampaignRowEvent): void {
    // NOTE: we have to change row state on click because data for all rows is
    // already in the store
    this.store.dispatch(trendingActions.changeCampaignDetailsRowState({rowEvent: data}));
  }

  expandRow(data: { group: IGroupDetail, period: TrendingDataPeriod }): void {
    this.store.dispatch(trendingActions.toggleExpandGroupDetailsRow(data));
  }

  downloadGroupDetails(period: TrendingDataPeriod): void {
    this.store.dispatch(trendingActions.downloadGroupDetailsCSV({period}));
  }

  downloadCampaign(data: { group: string, period: TrendingDataPeriod }): void {
    this.store.dispatch(trendingActions.downloadCampaignDetailsCSV(data));
  }

  downloadResponses(data: { group: string, period: TrendingDataPeriod, id: string }): void {
    this.store.dispatch(trendingActions.downloadResponsesCSV(data));
  }
}
