/* istanbul ignore file */
import { Injectable } from '@angular/core';

import type { PieChart, PieSeries, PieSeriesDataItem } from '@amcharts/amcharts4/charts';
import type { Color, Slice } from '@amcharts/amcharts4/core';

import { TILE_DATA_FIELD_NAME } from '@shared/constants';
import { IChartConfig, IDashboardTile, ITileDataItem } from '@shared/interfaces';
import { ChartConfigClass } from '../chart-config.class';
import { MAX_LEGEND_LENGTH } from '../../tile.constant';

@Injectable()
export class PieChartConfigService implements ChartConfigClass {

  getConfig(tile: IDashboardTile, data: ITileDataItem[]): IChartConfig {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return {
      type: 'PieChart',
      series: [
        {
          type: 'PieSeries',
          dataFields: {
            value: 'value',
            category: TILE_DATA_FIELD_NAME
          },
          labels: {
            disabled: true
          },
          ticks: {
            disabled: true
          },
          slices: {
            stroke: '#ffffff',
            strokeWidth: 2,
            strokeOpacity: 1
          },
          events: {
            datavalidated(event: { target: PieSeries, type: string }) {
              event.target.slices.each((slice: Slice) => {
                // hide very small slices (if percent less than 5)
                if (slice.dataItem.values.value.percent < 5) {
                  slice.dataItem.hide();
                }
              });
            }
          }
        }
      ],
      events: {
        ready(event: { type: string, target: PieChart & { customLegend: unknown } }) {
          that.createCustomLegend(event.target, tile.id);
          that.createCustomTooltip(event.target);
        }
      },
      data: data || [] // get from BE
    };
  }

  private createCustomTooltip(target: PieChart): void {
    const pieSeries = target.series.getIndex(0); // pie chart has only 1 series
    pieSeries.slices.each((slice: Slice) => {
      const color = (slice.fill as Color).hex;
      slice.tooltipHTML = `<div class="amcharts-tooltip amcharts-tooltip-tile">
                                     <span class="title">{category}</span>
                                     <hr class="report-divider">
                                     <div class="section">
                                        <div class="color" style="background-color: ${color};"></div>
                                        {value}
                                     </div>
                                 </div>`;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createCustomLegend(target: PieChart & { customLegend: any }, id: string): void {
    target.customLegend = document.getElementById(`legend-${id}`);
    const pieSeries = target.series.getIndex(0); // pie chart has only 1 series
    const isLongLegend = pieSeries.dataItems.length > MAX_LEGEND_LENGTH;
    const itemsForLegend = isLongLegend
      ? pieSeries.dataItems.slice(0, MAX_LEGEND_LENGTH)
      : pieSeries.dataItems;
    itemsForLegend.each((row: PieSeriesDataItem) => {
      const color = (row.slice.fill as Color).hex;
      target.customLegend.innerHTML += `
                    <span class="custom-legend-item text-capitalize">
                        <span class="custom-legend-marker" style="background: ${color}"></span>
                        ${row.category || 'n/a'}
                    </span>
                `;
    });

    if (isLongLegend) {
      target.customLegend.innerHTML +=
        `<span class="custom-legend-item">+${pieSeries.dataItems.length - MAX_LEGEND_LENGTH} others</span>`;
    }
  }
}
