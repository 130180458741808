import { createAction, props } from '@ngrx/store';
import { IDateCohortResponse } from '@shared/interfaces';

export const DateCohortsActionTypes = {
  LoadDateCohorts: '[Date Cohorts] Load Date Cohort Data',
  LoadDateCohortsSuccess: '[Date Cohorts] Load Date Cohort Data Success',
  LoadDateCohortsFailure: '[Date Cohorts] Load Date Cohort Data Failure',
};

const loadDateCohorts = createAction(DateCohortsActionTypes.LoadDateCohorts);

const loadDateCohortsSuccess = createAction(
  DateCohortsActionTypes.LoadDateCohortsSuccess,
  props<{ data: IDateCohortResponse }>()
);

const loadDateCohortsFailure = createAction(
  DateCohortsActionTypes.LoadDateCohortsFailure,
  props<{ message: string }>()
);

export const dateCohortsActions = {
  loadDateCohorts,
  loadDateCohortsSuccess,
  loadDateCohortsFailure,
}
