import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActionType, select, Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { IDashboard, userMessageFactory } from '@shared/interfaces';
import { NotificationTypes, RouteItemEnum } from '@shared/enums';
import { NavigateTo } from '@hub-navigation';
import { notificationMessagesActions } from '@notification-messages';
import { navMenusActions } from '@nav-menus';
import { compare } from '@util/helpers';
import { DashboardsService } from '../service/dashboards.service';
import * as selectors from './dashboards.selectors';
import * as reducer from './dashboards.reducer';
import { dashboardsActions, MutationSuccessActions } from './dashboards.actions';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable()
export class DashboardsEffects {

  constructor(public dashboardsService: DashboardsService,
    private titleService: Title,
    private translateService: TranslateService,
    private store: Store<{
      [reducer.dashboardsFeatureKey]: reducer.DashboardsState
    }>,
    private actions$: Actions,
    private router: Router) {
  }

  public loadList$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.loadList),
    switchMap(() => {
      return this.dashboardsService.getDashboards$().pipe(
        map((data: IDashboard[]) => {
          // NOTE: sort dashboards by sortOrder field before saving to store
          return data?.length
            ? data.slice().sort((a: IDashboard, b: IDashboard) =>
              compare(a.sortOrder, b.sortOrder, true))
            : data;
        }),
        map((data: IDashboard[]) => dashboardsActions.loadListSuccess({ data })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.dashboards.errors.loadList';
          return of(dashboardsActions.loadListFailure({ message }));
        })
      );
    })
  ));

  public loadDetails$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.loadDetails),
    concatLatestFrom(() => this.store.pipe(select(selectors.getDetails))),
    mergeMap(([action, details]: [ActionType<typeof dashboardsActions.loadDetails>, IDashboard | null]) => {
      return iif(() => !!details,
        of(dashboardsActions.loadDetailsSuccess({ data: details })), // if details present in list
        this.loadDetails(action.id), // otherwise load from BE
      );
    })
  ));

  public setDetailsRouteLabel$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.loadDetailsSuccess, dashboardsActions.setDetailsRouteLabel),
    concatLatestFrom(() => this.store.pipe(select(selectors.getDetails))),
    filter(([_, details]: [null, IDashboard]) => !!details),
    tap(([_, details]: [null, IDashboard]) => this.titleService.setTitle(details.name)),
    map(([_, details]: [null, IDashboard]) => navMenusActions.SetCustomRouteLabel({
      payload: details.name
    })),
  ));

  public create$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.create),
    switchMap(action => this.dashboardsService.create$(action.request).pipe(
      map((data: IDashboard) => dashboardsActions.createSuccess({ data })),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.create';
        return of(dashboardsActions.createFailure({ message }));
      })
    ))
  ));

  public edit$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.edit),
    switchMap(action => this.dashboardsService.update$(action.item).pipe(
      mergeMap(() => [
        dashboardsActions.editSuccess({ data: action.item }),
        navMenusActions.SetCustomRouteLabel({
          payload: action.item.name
        }),
      ]),
      tap(() => this.titleService.setTitle(action.item.name)),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.edit';
        return of(dashboardsActions.editFailure({ message }));
      })
    ))
  ));

  public delete$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.deleteDashboard),
    switchMap(action => this.dashboardsService.delete$(action.item.id).pipe(
      map(() => dashboardsActions.deleteDashboardSuccess({ data: action.item })),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.delete';
        return of(dashboardsActions.deleteDashboardFailure({ message }));
      })
    ))
  ));

  public deleteDetails$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.deleteDetails),
    switchMap(action => this.dashboardsService.delete$(action.item.id).pipe(
      mergeMap(() => [
        dashboardsActions.deleteDashboardSuccess({ data: action.item }),
        NavigateTo({ routeId: RouteItemEnum.Dashboards }),
      ]),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.delete';
        return of(dashboardsActions.deleteDashboardFailure({ message }));
      })
    ))
  ));

  public duplicate$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.duplicate),
    switchMap(action => this.dashboardsService.duplicate$(action.id).pipe(
      map((data: IDashboard) => dashboardsActions.duplicateSuccess({ data, originalName: action.name })),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.duplicate';
        return of(dashboardsActions.duplicateFailure({ message }));
      })
    ))
  ));

  public duplicateDetails$ = createEffect(() => this.actions$.pipe(
    ofType(dashboardsActions.duplicateDetails),
    switchMap(action => this.dashboardsService.duplicate$(action.id).pipe(
      map((data: IDashboard) => dashboardsActions.duplicateSuccess({ data, originalName: action.name })),
      tap(successAction => {
        this.router.navigate(['/', 'dashboards', successAction.data.id]).then();
      }),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.dashboards.errors.duplicate';
        return of(dashboardsActions.duplicateFailure({ message }));
      })
    ))
  ));

  public showNotification$ = createEffect(() => this.actions$.pipe(
    ofType(
      dashboardsActions.createSuccess,
      dashboardsActions.deleteDashboardSuccess,
      dashboardsActions.duplicateSuccess,
      dashboardsActions.editSuccess,
    ),
    switchMap(this.getNotificationMessage.bind(this)),
    map((message: string) => notificationMessagesActions.addMessage({
      message: {
        message,
        type: NotificationTypes.Success,
      }
    }))
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      dashboardsActions.loadListFailure,
      dashboardsActions.loadDetailsFailure,
      dashboardsActions.createFailure,
      dashboardsActions.editFailure,
      dashboardsActions.deleteDashboardFailure,
      dashboardsActions.duplicateFailure,
    ),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) }))
  ));

  private loadDetails(dashboardId: string): Observable<TypedAction<string>> {
    return this.dashboardsService.getDashboards$({ dashboardId }).pipe(
      map((data: [IDashboard]) => data && data[0]),
      map((dashboard: IDashboard) => dashboardsActions.loadDetailsSuccess({ data: dashboard })),
      catchError((error: Error) => {
        const message = error?.message || 'shared.dashboards.errors.loadDetails';
        return of(dashboardsActions.loadDetailsFailure({ message }));
      })
    );
  }

  private getNotificationMessage(action: MutationSuccessActions): Observable<string> {
    const prefix = this.getNotificationPrefix(action);
    const name = action.type === dashboardsActions.duplicateSuccess.type
      ? (action as ActionType<typeof dashboardsActions.duplicateSuccess>).originalName
      : action.data.name;
    return this.translateService.get(prefix, { name });
  }

  private getNotificationPrefix(action: MutationSuccessActions): string {
    switch (action.type) {
      case dashboardsActions.createSuccess.type:
        return 'shared.dashboards.notifications.created';
      case dashboardsActions.deleteDashboardSuccess.type:
        return 'shared.dashboards.notifications.deleted';
      case dashboardsActions.duplicateSuccess.type:
        return 'shared.dashboards.notifications.duplicated';
      case dashboardsActions.editSuccess.type:
        return 'shared.dashboards.notifications.edited';
    }
  }
}
