<div class="hub-modal-header">
  <h3
    class="hub-modal-title">{{ 'shared.reportDialog.title'| translate: { action: data.report ? 'Edit' : 'Create' } }}</h3>
</div>
<div class="hub-modal-body report-modal-body">
  <ts-input
    label="{{ 'shared.common.name' | translate }}"
    name="reportName"
    [isRequired]="true"
    [isDisabled]="isDisabled"
    [formControl]="form.get('name')">
  </ts-input>
  <ts-input
    label="{{ 'shared.common.url' | translate }}"
    name="reportUrl"
    [isRequired]="true"
    [isDisabled]="true"
    [formControl]="form.get('url')">
  </ts-input>
  <ts-selection-list
    label="{{ 'shared.common.folder' | translate }}"
    name="reportFolder"
    [displayFormatter]="formatDisplay"
    [valueComparator]="folderCompareFunction"
    [isRequired]="true"
    [isDisabled]="isDisabled || data?.report"
    [formControl]="form.get('folderId')">
    <ts-option
      *ngFor="let folder of data.foldersOptions"
      [value]="folder">
      {{ folder.folderName }}
    </ts-option>
  </ts-selection-list>
  <ts-input
    label="{{ 'shared.common.description' | translate }}"
    name="reportDescription"
    [isDisabled]="isDisabled"
    [formControl]="form.get('description')"
    [isTextarea]="true">
  </ts-input>
</div>
<div class="hub-modal-footer "
     [ngClass]="{
     'hub-modal-footer-between': !!data.report,
     'hub-modal-footer-end': !data.report
     }">
  <ts-button
    *ngIf="data.report"
    actionName="Delete"
    buttonType="button"
    theme="warning"
    [isDisabled]="isDisabled"
    class="delete"
    (clicked)="deleteReport()">
    {{ 'shared.reportDialog.deleteThisReport' | translate }}
  </ts-button>
  <div>
    <ts-button
      actionName="Cancel"
      buttonType="button"
      theme="secondary"
      class="cancel-button"
      [isDisabled]="isDisabled"
      (clicked)="cancel()">
      {{ 'shared.common.cancel' | translate }}
    </ts-button>
    <ts-button
      [isDisabled]="form.invalid || isDisabled"
      actionName="Save"
      buttonType="submit"
      theme="default"
      (clicked)="saveReport()">
      {{ 'shared.common.save' | translate }}
    </ts-button>
  </div>
</div>
