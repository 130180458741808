import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { TsWindowService, untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { concatMap, filter, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DateCohortsStore from '@date-cohorts';
import { GoParams, IDateCohort } from '@shared/interfaces';
import * as selectors from '../state/stages-snapshot.selectors';
import { stagesSnapshotActions } from '../state/stages-snapshot.actions';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { PortalTargets, TileTypes, ToDate } from '@shared/enums';
import { TsSortState } from '@terminus-lib/ui-sort';
import { NavigateTo } from '@hub-navigation';
import * as OrgConfigStore from '@org-config';
import { catalogsActions } from '@shared/data-access/catalogs';
import { TileModalService } from '@shared/features/tile-modal';
import { IStagesSnapshotFilters } from '../interfaces';

@WithDestroy
@Component({
  selector: 'ms-stages-shapshot',
  templateUrl: './stages-snapshot.component.html',
  styleUrls: ['./stages-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StagesSnapshotComponent implements OnInit {
  public readonly targets = PortalTargets;
  public dateCohortOptions$ = this.store.pipe(select(DateCohortsStore.getGroupedDateCohortOptions()));
  public selectedDateCohort$ = this.store.pipe(select(selectors.getStagesSnapshotDateCohort));
  public isLoading$ = this.store.pipe(select(selectors.getStagesSnapshotLoading));
  public filters$ = this.store.pipe(select(selectors.getStagesSnapshotFilters));
  public chartData$ = this.store.pipe(select(selectors.getChartData));
  public tabs$ = this.store.pipe(select(selectors.getStagesSnapshotStageProgressionData));
  public activeTab$ = this.store.pipe(select(selectors.getStagesSnapshotActiveStage));
  public table$ = this.store.pipe(
    select(selectors.getStagesSnapshotTableData),
    filter(data => !!data)
  );
  public columns$ = this.store.pipe(select(selectors.getStagesSnapshotColumns));
  public tableOptions$ = this.store.pipe(select(selectors.getStagesSnapshotTableOptions));
  public all$ = this.store.pipe(select(selectors.getSearchedTableData));
  public crmUrl$ = this.store.pipe(select(OrgConfigStore.getCrmUrlForAccount));

  public noResultDescription = 'measurementStudio.features.stagesSnapshot.noResultDescription';
  public noResultTableDescription = 'measurementStudio.features.stagesSnapshot.noResultTableDescription';
  public noResultTitle = 'measurementStudio.features.stagesSnapshot.noResultTitle';
  public noResultTableTitle = 'measurementStudio.features.stagesSnapshot.noResultTableTitle';

  public defaultQueryParams: IStagesSnapshotFilters = {
    cohort: ToDate.QUARTER_TO_DATE,
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
  };

  constructor(private store: Store<unknown>,
              private route: ActivatedRoute,
              private router: Router,
              private windowService: TsWindowService,
              private tileModalService: TileModalService) {
  }

  ngOnInit(): void {
    this.store.dispatch(catalogsActions.loadWfStages());

    this.route.queryParams
      .pipe(
        untilComponentDestroyed(this),
        concatMap(params => of(params).pipe(
          withLatestFrom(
            this.filters$.pipe(take(1)),
            this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1))
          )
        ))
      )
      .subscribe(([params, filters, globalFilters]) => {
        if (!Object.keys(params).length && !filters) {
          // if there is no any query params then use default params
          this.applyFilters({...this.defaultQueryParams, ...globalFilters});
          return;
        }

        if (!Object.keys(params).length && filters) {
          // if there is no any query params but we have already had filters in the store then we have to use them
          this.applyFilters({...filters, ...globalFilters});
          return;
        }

        this.store.dispatch(stagesSnapshotActions.getFilters({params}));
      });
  }

  /**
   * Apply change for date cohort only
   */
  applyFilters(filters?: IStagesSnapshotFilters): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: filters,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  changeDateCohort(cohort: IDateCohort): void {
    this.store.dispatch(stagesSnapshotActions.setSelectedDateCohort({cohort}));
  }

  setActiveStage(stage: string): void {
    this.store.dispatch(stagesSnapshotActions.setActiveStage({stage}));
  }

  applySort(data: TsSortState): void {
    this.store.dispatch(stagesSnapshotActions.setTableSortState({data}));
  }

  applySearch(search: string): void {
    this.store.dispatch(stagesSnapshotActions.setTableSearch({search}));
  }

  applyPage(page: number): void {
    this.store.dispatch(stagesSnapshotActions.setTablePage({page}));
  }

  download(): void {
    this.store.dispatch(stagesSnapshotActions.downloadCSV());
  }

  externalRedirect(url: string): void {
    this.windowService.nativeWindow.open(url, '_blank');
  }

  redirect(params: GoParams): void {
    this.store.dispatch(NavigateTo(params));
  }

  addToDashboardDialog(): void {
    const {queryParams} = this.route.snapshot;

    this.tileModalService.openAddToDashboardModal(TileTypes.StagesSnapshot, queryParams);
  }
}
