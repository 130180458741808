<tsh-table-panel (submitted)="emitSearch($event)"
                 (download)="emitDownload()"
                 [totalTitle]="totalTitle"
                 [noResult]="!report?.length"
                 [searchEnabled]="true"
                 [totalCount]="count"
                 [showColumns]="false"
                 [searchQuery]="reportOptions.searchQuery">
</tsh-table-panel>

<ng-container *ngIf="report?.length">
  <div class="table-container">
    <table ts-table
           tsSort
           #campaignMembersTable="tsTable"
           (tsSortChange)="emitChangeSort($event)"
           [tsSortActive]="reportOptions.sortState.active"
           [tsSortDirection]="reportOptions.sortState.direction"
           [columns]="columns"
           [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns; let colIxd = index;">
        <ng-container [tsColumnDef]="column.name"
                      [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
          <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
            <tsh-header-cell data-cy="stages-snapshot-table-header" [column]="column"></tsh-header-cell>
          </th>
          <td ts-cell *tsCellDef="let row; let rowIdx = index;">
            <ng-container *ngIf="column.name !== 'name'; else linkRow">
              {{ row[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
            </ng-container>
            <ng-template #linkRow>
              <a data-cy="stages-snapshot-table-link"
                 (click)="emitRedirect($event, row)"
                 [title]="row[column.name]">
                {{ row[column.name] }}
              </a>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
      <tr ts-header-row *tsHeaderRowDef="campaignMembersTable.columnNames; sticky: true"></tr>
      <tr ts-row *tsRowDef="let row; columns: campaignMembersTable.columnNames;"></tr>
    </table>
  </div>

  <tsh-pagination [currentPageIndex]="reportOptions.page || 1"
                  [totalRecords]="count || 0"
                  (pageSelect)="emitPageChange($event)"
                  paginatorSummary="measurementStudio.features.stagesSnapshot.people">
  </tsh-pagination>
</ng-container>

<tsh-no-report-result *ngIf="!report?.length"
                      [description]="noResultDescription"
                      [title]="noResultTitle">
</tsh-no-report-result>
