import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { TypedDataModule } from '@ui/pipes/typed-data';
import { ReportMetricComponent } from './report-metric.component';

@NgModule({
  declarations: [ReportMetricComponent],
  exports: [ReportMetricComponent],
  imports: [
      CommonModule,
      TsTooltipModule,
      TypedDataModule,
      TranslateModule,
  ]
})
export class ReportMetricModule {
}
