import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as CampaignsReducer from './campaigns/campaigns.reducer';
import * as CampaignGroupsReducer from './campaign-groups/campaign-groups.reducer';
import * as TotalsReducer from './totals/totals.reducer';
import * as GeneralReducer from './general/general.reducer';
import * as CampaignReducer from './campaign/campaign.reducer';
import * as SourceReducer from './source/source.reducer';
import * as MediumsReducer from './mediums/mediums.reducer';

export const analyticsFeatureKey = 'analytics';

export interface AnalyticsState {
  readonly general: GeneralReducer.GeneralState;
  readonly totals: TotalsReducer.ITotalsState;
  readonly campaigns: CampaignsReducer.ICampaignsState;
  readonly campaignGroups: CampaignGroupsReducer.ICampaignGroupsState;
  readonly campaign: CampaignReducer.ICampaignState;
  readonly source: SourceReducer.ISourceState;
  readonly mediums: MediumsReducer.IMediumsState;
}

export const analyticsStateReducer: ActionReducerMap<AnalyticsState> = {
  general: GeneralReducer.reducer,
  totals: TotalsReducer.reducer,
  campaigns: CampaignsReducer.reducer,
  campaignGroups: CampaignGroupsReducer.reducer,
  campaign: CampaignReducer.reducer,
  source: SourceReducer.reducer,
  mediums: MediumsReducer.reducer,
};

export const initialState: AnalyticsState = {
  general: GeneralReducer.initialState,
  totals: TotalsReducer.initialState,
  campaigns: CampaignsReducer.initialState,
  campaignGroups: CampaignGroupsReducer.initialState,
  campaign: CampaignReducer.initialState,
  source: SourceReducer.initialState,
  mediums: MediumsReducer.initialState,
};

export const selectAnalyticsState = createFeatureSelector<AnalyticsState>(analyticsFeatureKey);


