<ts-accordion *ngIf="savedReports?.length; else noReports" class="saved-reports-list">
  <ts-expansion-panel *ngFor="let folder of savedReports"
                      (expandedChange)="panelStateChanged($event, folder.folderId)">
    <ts-expansion-panel-trigger class="folder-container"
                                collapsedHeight="48px"
                                expandedHeight="48px">
      <ts-icon [icon]="folderIcon" class="folder-icon"></ts-icon>
      <span class="folder-title">{{ folder.folderName }}</span>
      <ts-icon-button
        class="edit-button hub-icon-sm"
        buttonType="button"
        actionName="Edit folder"
        [icon]="penIcon"
        *ngIf="folder.isMine"
        (clicked)="openFolderDialog($event, folder)">
      </ts-icon-button>
    </ts-expansion-panel-trigger>

    <ul class="reports-container" *ngIf="folder.contents?.length; else emptyFolder">
      <li class="report-content" *ngFor="let report of folder.contents">
        <div class="report-info">
          <button type="button"
                  class="report-title"
                  (click)="show(report)">
            <span>{{ report.name }}</span>
          </button>
          <span class="report-description">{{ report.description }}</span>
        </div>
        <ts-icon-button
          class="edit-button"
          buttonType="button"
          actionName="Edit Report"
          [icon]="penIcon"
          (clicked)="openReportDialog(report, folder.folderId)">
        </ts-icon-button>
      </li>
    </ul>
    <ng-template #emptyFolder>
      <div class="saved-reports-empty">
        <p>There are no reports in this folder.</p>
        <p>What are you waiting for?
          <button type="button"
                  (click)="openReportDialog()">
            Create a Report
          </button>
          !
        </p>
      </div>
    </ng-template>
  </ts-expansion-panel>
</ts-accordion>
<ng-template #noReports>
  <div class="saved-reports-empty">
    <p>You do not have any saved folders or reports!</p>
    <p>To create a saved report, you need to first create a folder to store them in.</p>
    <p>
      <button type="button"
              (click)="openFolderDialog()">
        Let's Create a Folder
      </button>
      !
    </p>
  </div>
</ng-template>
