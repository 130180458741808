import { Injectable } from '@angular/core';
import { Observable, combineLatest, from, of } from 'rxjs';
import { ListUserDefinedSegmentsRequest } from './generated/terminus/segment/management/api/v1/messages.pb';
import { SegmentManagementApiService } from './generated/terminus/segment/management/api/v1/service.pb';
import { UserDefinedSegment } from './generated/terminus/user_defined_segment/v1/user_defined_segment.pb';
import { Store, select } from '@ngrx/store';
import { EnvService } from '@shared/data-access/environment';
import { switchMap } from 'rxjs/operators';
import { retryWithBackoff } from 'terminus-lib/libs/fe-utilities/src/lib/general/retry-with-backoff';
import { selectUserState } from '@shared/data-access/user-common';
import { ClaimMap, regenerateOnRetry, tokenFor } from '@terminus-lib/fe-jwt';

type TokenName = 'Terminus Hub';

@Injectable()
export class SegmentsApi {
  protected baseUrl = this.envService.getEnv().SEGMENTS_API_URL;
  protected jwtTokenName: TokenName = 'Terminus Hub';

  constructor(private store: Store, private envService: EnvService) {}

  getSegments$ = (request: ListUserDefinedSegmentsRequest) => {
    return combineLatest([
      this.store.pipe(select(selectUserState)),
      this.store.pipe(select(tokenFor<ClaimMap, TokenName>(this.jwtTokenName))),
    ]).pipe(
      switchMap(([userState, jwt]) => {
        if (request.query && userState.userProfile.orgUuid) {
          request.query.orgUuid = userState.userProfile.orgUuid;
          request.query.pageSize = '50';
        }

        return regenerateOnRetry(() =>
          from(
            SegmentManagementApiService.ListUserDefinedSegments(request, {
              pathPrefix: this.baseUrl,
              headers: { authorization: `Bearer ${jwt}` },
            }),
          ).pipe(retryWithBackoff({})),
        );
      }),
    );
  };

  getAllSegments$ = (
    request: ListUserDefinedSegmentsRequest,
    loaded: UserDefinedSegment[] = [],
  ): Observable<UserDefinedSegment[]> => {
    return this.getSegments$(request).pipe(
      switchMap(res =>
        res.continuationToken
          ? this.getAllSegments$(
              { continuationToken: res.continuationToken },
              loaded.concat(res.userDefinedSegments || []),
            )
          : of(loaded.concat(res.userDefinedSegments || [])),
      ),
    );
  };
}
