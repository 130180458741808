import { TsSortState } from '@terminus-lib/ui-sort';
import { Action, createReducer, on } from '@ngrx/store';

import { dashboardsActions } from './dashboards.actions';
import { IDashboard, IReportPagination } from '@shared/interfaces';
import { PER_PAGE } from '@shared/constants';
import { deleteById, replaceArrayItem } from '@util/helpers';
import { SortDirections } from '@shared/enums';

export const dashboardsFeatureKey = 'dashboards';

export interface DashboardsState {
  list: IDashboard[];
  isListLoading: boolean;
  sortState: TsSortState;
  pagination: IReportPagination;
  searchQuery: string;
  visibility: boolean | null;
  detailsId: string | null;
  isDetailsLoading: boolean;
}

export const initialState: DashboardsState = {
  list: [],
  isListLoading: false,
  sortState: {
    active: '',
    direction: SortDirections.Asc,
  },
  pagination: {
    page: 1,
    perPage: PER_PAGE,
  },
  searchQuery: '',
  visibility: null,
  detailsId: null,
  isDetailsLoading: false,
};

const dashboardsReducer = createReducer(
  initialState,
  on(dashboardsActions.loadList, (state) => ({
    ...state,
    isListLoading: true,
  })),
  on(dashboardsActions.loadListSuccess, (state, action) => ({
    ...state,
    list: action.data,
    isListLoading: false,
  })),
  on(dashboardsActions.loadListFailure, (state) => ({
    ...state,
    isListLoading: false,
  })),
  on(dashboardsActions.loadDetails, (state, action) => ({
    ...state,
    detailsId: action.id,
    isDetailsLoading: true
  })),
  on(dashboardsActions.loadDetailsSuccess, (state, action) => ({
    ...state,
    ...state.list.find(item => item.id === action.data.id)
      ? null
      : {list: [...state.list, action.data]},
    isDetailsLoading: false
  })),
  on(dashboardsActions.loadDetailsFailure, (state) => ({
    ...state,
    isDetailsLoading: false,
  })),
  on(dashboardsActions.createSuccess, (state, action) => ({
    ...state,
    list: [...state.list, action.data],
  })),
  on(dashboardsActions.editSuccess, (state, action) => ({
    ...state,
    list: replaceArrayItem(state.list, action.data),
  })),
  on(dashboardsActions.deleteDashboardSuccess, (state, action) => ({
    ...state,
    list: deleteById(state.list, action.data.id),
  })),
  on(dashboardsActions.duplicateSuccess, (state, action) => ({
    ...state,
    list: [...state.list, action.data],
  })),
  on(dashboardsActions.setSortState, (state, action) => ({
    ...state,
    sortState: action.data,
  })),
  on(dashboardsActions.setPagination, (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.page
    },
  })),
  on(dashboardsActions.setSearchQuery, (state, action) => ({
    ...state,
    searchQuery: action.query,
    pagination: {
      ...state.pagination,
      page: 1,
    },
  })),
  on(dashboardsActions.setVisibility, (state, action) => ({
    ...state,
    visibility: action.visibility,
    pagination: {
      ...state.pagination,
      page: 1,
    },
  })),
);

export function reducer(state: DashboardsState | undefined, action: Action) {
  return dashboardsReducer(state, action);
}
