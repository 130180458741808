import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as InsightsFiltersSelectors from '../insights-filters/insights-filters.selectors';
import * as InfluencedInsightsReducer from './influenced-insights.reducer';
import { ADS_INSIGHTS_REPORT_TYPE_MAPPING, AdsInsightsReportType } from '../../enums/insights-report-types.enum';
import { InsightTypes } from '../../enums/ad-insight-types.enum';
import { IAdsInfluencedRequest, InfluencedInsightsReport } from '../../interfaces';
import { getAnalyticsGlobalFilters } from '@shared/data-access/global-filters';
import { selectRouteId } from '@hub-router';
import { RouteItemEnum } from '@shared/enums';
import { AdInsightsSidenavStates } from '../../enums/insights-sidenav-states.enum';

export const getInfluencedInsightsState =
  createFeatureSelector<InfluencedInsightsReducer.InfluencedInsightsState>(InfluencedInsightsReducer.influencedInsightsFeatureKey);

export const getAllInfluencedInsights = createSelector(
  getInfluencedInsightsState,
  (
    state: InfluencedInsightsReducer.InfluencedInsightsState
  ): Record<AdsInsightsReportType, InfluencedInsightsReport> => {
    return {
      [AdsInsightsReportType.PageView]: state[AdsInsightsReportType.PageView],
      [AdsInsightsReportType.Lead]: state[AdsInsightsReportType.Lead],
      [AdsInsightsReportType.CampaignResponses]: state[AdsInsightsReportType.CampaignResponses],
      [AdsInsightsReportType.NewPipeline]: state[AdsInsightsReportType.NewPipeline],
      [AdsInsightsReportType.Pipeline]: state[AdsInsightsReportType.Pipeline],
      [AdsInsightsReportType.Revenue]: state[AdsInsightsReportType.Revenue]
    };
  }
);

export const getInfluencedInsightsAddingToAccount = createSelector(getInfluencedInsightsState,
  (state: InfluencedInsightsReducer.InfluencedInsightsState) => state.isAddingToAccount);

export const getAddedAccountsQuantity = createSelector(getInfluencedInsightsState,
  (state: InfluencedInsightsReducer.InfluencedInsightsState) => state.addedAccounts);


export const getActiveReport =
  createSelector(getInfluencedInsightsState, (state: InfluencedInsightsReducer.InfluencedInsightsState) => state.activeReport);

export const getActiveDetailsSimple = createSelector(
  getInfluencedInsightsState,
  (state: InfluencedInsightsReducer.InfluencedInsightsState) => state.activeDetails);

export const getActiveDetails = createSelector(
  getActiveDetailsSimple,
  (activeDetails: string) => activeDetails && activeDetails.charAt(0).toLowerCase() + activeDetails.slice(1));

export const getInsightTypeByKey$ = createSelector(
  getActiveDetailsSimple,
  (activeDetails: string) => InsightTypes[activeDetails] ? InsightTypes[activeDetails] : InsightTypes.TotalWithAccounts);

export const getInfluencedInsights$ = createSelector(
  getInfluencedInsightsState,
  (state) => (reportType: AdsInsightsReportType) => state[reportType]
);



export const getSortState$ = createSelector(
  getInfluencedInsights$,
  (reportFunc) => (reportType: AdsInsightsReportType) => reportFunc(reportType)?.sortState
);

export const getPagination$ = createSelector(
  getInfluencedInsights$,
  (reportFunc) => (reportType: AdsInsightsReportType) => reportFunc(reportType)?.pagination
);

export const getSearch$ = createSelector(
  getInfluencedInsights$,
  (reportFunc) => (reportType: AdsInsightsReportType) => reportFunc(reportType)?.search
);

export const getInfluencedInsightsLoading$ = createSelector(getInfluencedInsightsState,
  (
    state: InfluencedInsightsReducer.InfluencedInsightsState
  ) => (reportType: AdsInsightsReportType) => state[reportType]?.isLoading);

export const buildQueryParams$ = createSelector(
  getAnalyticsGlobalFilters,
  InsightsFiltersSelectors.getSelectedDateCohort$,
  InsightsFiltersSelectors.getInsightsReportFilters$,
  InsightsFiltersSelectors.getInsightsReportSettingsForm$,
  getInsightTypeByKey$,
  getSortState$,
  getSearch$,
  getPagination$,
  (
    globalFilters,
    cohort,
    filters,
    reportSettingsForm,
    insightType,
    sortStateFunc,
    searchFunc,
    paginationFunc
  ) => (reportType: AdsInsightsReportType): IAdsInfluencedRequest => {
    const sortState = sortStateFunc(reportType);
    const search = searchFunc(reportType);
    const pagination = paginationFunc(reportType);
    return {
      cohort: cohort,
      influenceWindow: filters.influenceWindow,
      globalFilters,
      reportType,
      ...((sortState) && {sorter: sortState}),
      ...((search) && {searchParam: search}),
      minImpressions: reportSettingsForm.values.adImpressionsMin !== null ? reportSettingsForm.values.adImpressionsMin : undefined,
      maxImpressions: reportSettingsForm.values.adImpressionsMax !== null ? reportSettingsForm.values.adImpressionsMax : undefined,
      insightType,
      pageSize: pagination.perPage,
      pageOffset: pagination.page
    };
  }
);

export const getInfoPanelActiveState = createSelector(getInfluencedInsightsState,
  (state: InfluencedInsightsReducer.InfluencedInsightsState): AdInsightsSidenavStates => state.activeInfoPanelState);

export const getInfoPanelLabel = createSelector(selectRouteId,
  (routeId: RouteItemEnum) => {
    const routeToReportType = {
      [RouteItemEnum.AdvertisingInsights]: RouteItemEnum.AdvertisingInsights,
      [RouteItemEnum.InsightsCampaignResponsesInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.CampaignResponses],
      [RouteItemEnum.InsightsLeadsInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.Lead],
      [RouteItemEnum.InsightsNewPipelineInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.NewPipeline],
      [RouteItemEnum.InsightsPageViewsInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.PageView],
      [RouteItemEnum.InsightsPipelineInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.Pipeline],
      [RouteItemEnum.InsightsRevenueInfluenced]: ADS_INSIGHTS_REPORT_TYPE_MAPPING[AdsInsightsReportType.Revenue]
    };
    return routeToReportType[routeId] || null;
  }
);



