import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IAccountFolder, userMessageFactory } from '@shared/interfaces';

import * as selectors from './account-list.selectors';
import * as actions from './account-list.actions';
import * as reducer from './account-list.reducer';
import { notificationMessagesActions } from '@notification-messages';
import { AccountHubSharedService } from '../services/account-hub.service';

@Injectable()
export class AccountListEffects {
  constructor(private actions$: Actions,
              private store: Store<{
                [reducer.accountHubFeatureKey]: reducer.State
              }>,
              public accountHubService: AccountHubSharedService) {
  }

  public getFolders$ = createEffect(() => this.actions$.pipe(
    ofType(actions.getAccountLists),
    concatLatestFrom(() => this.store.pipe(select(selectors.getAccountHubState))),
    // if loading in process or there is already data in store then don't dispatch action
    filter(([_, state]: [null, reducer.State]) => !state.isLoading && state.folders === null),
    map(() => actions.loadAccountLists())
  ));

  public loadFolders$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadAccountLists),
    mergeMap(() => this.accountHubService.getAccountListsFolders$().pipe(
      map((folders: IAccountFolder[]) => actions.loadAccountListsSuccess({data: folders})),
      catchError((error: HttpErrorResponse) => {
        const message = error?.message || 'Unable to load account lists. Please try again later.';
        return of(actions.loadAccountListsFailure({error: `Error: ${message}`}));
      })
    ))
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadAccountListsFailure),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.error}) }))
  ));
}
