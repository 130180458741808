export enum GaugeChartColors {
    Min = '#FF6368',
    Mid = '#FFA600',
    Max = '#34A26A'
}

export enum GaugeChartFields {
    Minimum = 'gaugeRangeMin',
    Midpoint = 'gaugeRangeRedYellow',
    Goal = 'gaugeRangeYellowGreen',
    Maximum = 'gaugeRangeMax',
}
