import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IReportColumn, IReportLink } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { ITrendingCampaignDetails, ITrendingParams } from '../../interfaces';

@Component({
  selector: 'ms-trending-campaign-table',
  templateUrl: './trending-campaign-table.component.html',
  styleUrls: ['./trending-campaign-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendingCampaignTableComponent implements OnChanges {
  @Input() public data: ITrendingCampaignDetails[];
  @Input() public columns: IReportColumn[] = [];
  @Input() public responsesColumns: IReportColumn[] = [];
  @Input() public rowState: Record<string, boolean>;
  @Input() public page: number;
  @Input() public totalCount = 0;
  @Input() public filters: ITrendingParams;
  @Input() public sortState: TsSortState | null;
  @Input() public isCampaignTrendsReport: boolean;
  @Output() public changeSort = new EventEmitter<TsSortState>();
  @Output() public download = new EventEmitter<void>();
  @Output() public expandRow = new EventEmitter<string>();
  @Output() public changePage = new EventEmitter<number>();
  @Output() public downloadResponses = new EventEmitter<string>();

  public dataSource: TsTableDataSource<ITrendingCampaignDetails> = new TsTableDataSource();
  public dataTypes = DataTypeEnum;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.dataSource.data = this.data;
      this.changeDetector.detectChanges();
    }

    if (changes.data || changes.columns) {
      this.changeDetector.detectChanges();
    }
  }

  emitToggleExpand(event: MouseEvent, id: string): void {
    event.stopPropagation();
    this.expandRow.emit(id);
  }

  emitChangeSort(event: TsSortState): void {
    this.changeSort.emit(event);
  }

  emitDownload(): void {
    this.download.emit();
  }

  emitDownloadResponses(campaignId: string): void {
    this.downloadResponses.emit(campaignId);
  }

  getLinkData(campaign: ITrendingCampaignDetails, linkCb: (item, params) => IReportLink): IReportLink {
    return linkCb(campaign, {
      ...this.filters,
      isCampaignTrendsReport: this.isCampaignTrendsReport
    });
  }

  emitPageChange(event: number): void {
    this.changePage.emit(event);
  }
}
