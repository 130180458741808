import { Action, createReducer, on } from '@ngrx/store';

import { IAccountFolder } from '@shared/interfaces';
import * as actions from './account-list.actions';

export const accountHubFeatureKey = 'accountList';

export interface State {
  isLoading: boolean;
  folders: IAccountFolder[];
}

export const initialState: State = {
  isLoading: false,
  folders: null,
};

const accountListReducer = createReducer(
  initialState,
  on(actions.loadAccountLists, (state) => ({
    ...state,
    isLoading: true
  })),
  on(actions.loadAccountListsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    folders: action.data
  })),
  on(actions.loadAccountListsFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return accountListReducer(state, action);
}
