<ts-tooltip
  [tooltipValue]="(showProgress ? preparingMsg : downloadMsg) | translate"
  tooltipPosition="above"
  [hasUnderline]="false">
  <button
    (click)="emitDownload()"
    [disabled]="isDisabled || showProgress"
    type="button"
    [ngClass]="{
      'c-button--progress': showProgress && !isDisabled
    }">
    <div class="button-content-container">
      <i
        class="fas fa-cloud-download"
        [ngClass]="{
          'c-button-icon--progress': showProgress && !isDisabled
        }">
      </i>
      <span
        class="c-button__spinner"
        *ngIf="showProgress && !isDisabled"
        [ngClass]="{'c-button__spinner--active': showProgress && !isDisabled}">
          <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
      </span>
    </div>
  </button>
</ts-tooltip>
