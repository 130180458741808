export enum RouteItemEnum {
  Dashboards = 'app.dashboard',
  Settings = 'app.settings',
  SegmentBuilder = 'app.segmentBuilder',
  Discover = 'app.discover',
  Login = 'app.login',
  AccountEngagement = 'app.settings.selfConfigure.accountEngagement',
  MarketingImpact = 'app.discover.revenueAndPipeline.marketingImpact',
  AttributionByCampaignType = 'app.discover.revenueAndPipeline.attributionByQuarter',
  AttributionTrends = 'app.discover.revenueAndPipeline.attributionTrends',
  StagesSnapshot = 'app.discover.stageProgression.stagesSnapshot',
  CohortedWaterfall = 'app.discover.stageProgression.attribution',
  CohortedWaterfallDetails = 'app.discover.stageProgression.details',
  ProgressionByQuarter = 'app.discover.stageProgression.trending',
  ProgressionByQuarterDetails = 'app.discover.stageProgression.trendingDetails',
  CampaignAnalytics = 'app.analyze.campaigns.listAnalysis',
  CampaignHistoricalAverages = 'app.analyze.campaigns.historicalAverages',
  CampaignAnalyticsGroups = 'app.analyze.campaigns.listAnalysis.campaignGroups',
  CampaignAnalyticsCampaigns = 'app.analyze.campaigns.listAnalysis.campaigns',
  CampaignTrends = 'app.analyze.campaigns.trendingAnalysis',
  /* Campaign Specific Start */
  CampaignSpecific = 'app.analyze.campaigns.campaignSpecific',
  CampaignSpecificLeads = 'app.analyze.campaigns.campaignSpecific.attribution.leads',
  CampaignSpecificOpportunities = 'app.analyze.campaigns.campaignSpecific.attribution.opportunities',
  CampaignSpecificDeals = 'app.analyze.campaigns.campaignSpecific.attribution.deals',
  CampaignSpecificStages = 'app.analyze.campaigns.campaignSpecific.attribution.stages',
  CampaignSpecificValues = 'app.analyze.campaigns.campaignSpecific.attribution.values', // legacy route
  /* Campaign Specific End */
  WebTracking = 'app.analyze.webTracking',
  WebActivitiesList = 'app.analyze.webTracking.webActivity',
  WebActivitiesChannel = 'app.analyze.webTracking.webActivity.channel',
  WebActivitiesChannelAssets = 'app.analyze.webTracking.webActivity.channelAssets',
  WebActivitiesChannelMedium = 'app.analyze.webTracking.webActivity.channelMedium',
  /* Web Specific Start */
  WebActivitiesSpecific = 'app.analyze.webTracking.channelAssetSpecific',
  WebActivitiesLeads = 'app.analyze.webTracking.channelAssetSpecific.attribution.leads',
  WebActivitiesOpportunities = 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities',
  WebActivitiesDeals = 'app.analyze.webTracking.channelAssetSpecific.attribution.deals',
  WebActivitiesValues = 'app.analyze.webTracking.channelAssetSpecific.attribution.values', // legacy route
  WebActivitiesStages = 'app.analyze.webTracking.channelAssetSpecific.attribution.stages',
  /* Web Specific End */
  WebActivitiesTrending = 'app.analyze.webTracking.webActivityTrending',
  OpportunityInsights = 'app.analyze.opportunities.listAnalysis',
  OpportunitySpecific = 'app.analyze.opportunities.opportunitySpecific',
  OpportunityAllOpptysTouches = 'app.analyze.opportunities.allOpptysTouches',
  OpportunitySpecificTotalTouches = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches',
  OpportunitySpecificPeopleInfluencing = 'app.analyze.opportunities.opportunitySpecific.attribution.peopleInfluencing',
  OpportunitySpecificCampaignInfluencing = 'app.analyze.opportunities.opportunitySpecific.attribution.campaignInfluencing',
  AccountSpecific = 'app.analyze.accountsAccountSpecific',
  AccountSpecificAttribution = 'app.analyze.accountsAccountSpecific.attribution',
  AccountSpecificTrending = 'app.analyze.accountsAccountSpecific.trending',
  AccountSpecificAccountJourney = 'app.analyze.accountsAccountSpecific.accountJourney',
  AccountSpecificPeople = 'app.analyze.accountsAccountSpecific.people',
  AccountSpecificActivities = 'app.analyze.accountsAccountSpecific.activities',
  AccountHub = 'app.analyze.accounts.attribution',
  AccountTrends = 'app.analyze.accounts.trending',
  Scorecard = 'app.analyze.accounts.scorecard',
  ScorecardOverview = 'app.analyze.accounts.scorecard.overview',
  ScorecardTrendingKpi = 'app.analyze.accounts.scorecard.kpi',
  ScorecardTrendingKpiDetails = 'app.analyze.accounts.scorecard.kpi.details',
  ScorecardAccountList = 'app.analyze.accounts.scorecard.accountList',
  ScorecardTrending = 'app.analyze.accounts.scorecard.kpi',
  AccountListDetails = 'app.analyze.accounts.scorecard.details',
  AnalyzeMarketingImpact = 'app.analyze.revenueAndPipeline.marketingImpact',
  AnalyzeAttributionByCampaignType = 'app.analyze.campaigns.attributionByQuarter',
  DemoConfig = 'app.demoConfig',
  CampaignOverview = 'app.terminus.engage.campaignOverview',
  CreateNewCampaign = 'app.terminus.engage.createNewCampaign',
  Permissions = 'app.settings.permissions.teamPermissions',
  Stages = 'app.settings.stages',
  PagesAndFeatures = 'app.settings.pagesAndFeatures',
  DataHygiene = 'app.settings.dataHygiene',
  CampaignScoring = 'app.settings.attributionWeight',
  DigitalAttribution = 'app.settings.webTracking',
  InternalConfiguration = 'app.settings.internalConfigure',
  Configuration = 'app.settings.selfConfigure',
  HubspotConfigure = 'app.settings.selfConfigure.hubspot',
  SalesforceConfigure = 'app.settings.selfConfigure.sfdc',
  Salesforce = 'app.salesforce',
  SalesforceCompare = 'app.analyze.campaigns.campaignSpecific.sfdc',
  AdvertisingInsights = 'app.measurementStudio.advertisingInsights',
  // TODO - What should these be set to?
  InsightsPageViewsInfluenced = 'app.measurementStudio.advertisingInsights.pageViewsInfluenced',
  InsightsLeadsInfluenced = 'app.measurementStudio.advertisingInsights.leadsInfluenced',
  InsightsCampaignResponsesInfluenced = 'app.measurementStudio.advertisingInsights.campaignResponsesInfluenced',
  InsightsNewPipelineInfluenced = 'app.measurementStudio.advertisingInsights.newPipelineInfluenced',
  InsightsPipelineInfluenced = 'app.measurementStudio.advertisingInsights.pipelineInfluenced',
  InsightsRevenueInfluenced = 'app.measurementStudio.advertisingInsights.revenueInfluenced',
  StageInfluence = 'app.analyze.campaigns.stageInfluence',
  StageInfluenceCampaignType = 'app.analyze.campaigns.stageInfluence.campaignType',
  SegmentIndex = 'app.dataStudio.segments.index',
  SegmentDetails = 'app.dataStudio.segments.details',
  Onboarding = 'app.onboarding'
}

export enum RouteTypeEnum {
  InternalLink = 0,
  ExternalLink = 1,
  LegacyLink = 2
}
