import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { EnvService } from '@shared/environment';
import { hubTokenName } from '@shared/constants';
import { CampaignPerformanceSource } from '../sources/campaign-performance.source';
import { ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';

@Injectable()
export class CampaignPerformanceService {

  constructor(private source: CampaignPerformanceSource,
              private retry: RetryWithEscalation,
              private envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignPerformance$(params: ICampaignFilters): Observable<ICampaignPerformance> {
    return regenerateOnRetry(() => this.source.getCampaignPerformance$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignTotalAmount$(params: ICampaignFilters): Observable<ICampaignTotalAmount> {
    return regenerateOnRetry(() => this.source.getCampaignTotalAmount$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
