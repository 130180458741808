import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CsvDownloadButtonComponent } from './csv-download-button.component';

@NgModule({
  imports: [
    CommonModule,
    TsTooltipModule,
    TranslateModule
  ],
  declarations: [CsvDownloadButtonComponent],
  exports: [CsvDownloadButtonComponent]
})
export class CsvDownloadButtonModule {
}
