import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';


import { DataTypeEnum, PortalTargets } from '@shared/enums';
import { GoParams, IMenuItem, IRouteItem, ParentLink, SpecificReportLink } from '@shared/interfaces';
import { IMAGE_STORAGE_ROUTE, REPORT_NAV_LABEL } from '@shared/constants';

@Component({
  selector: 'tsh-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  @Input() public lastUpdate: Date;
  @Input() public applicationTitle: string;
  @Input() public specificLink: SpecificReportLink | null;
  @Input() public isNewLayout = false;
  @Input() public selectedNavItem: IMenuItem;
  @Input() public selectedRoute: IRouteItem;
  @Input() public parent: ParentLink | null;
  @Output() public navigate = new EventEmitter<GoParams>();

  public currentDate = new Date();
  public dayAtTimeType = DataTypeEnum.DayAtTime;
  public reportLabel = REPORT_NAV_LABEL;
  public readonly portalTargets = PortalTargets;
  public readonly imageStorage = IMAGE_STORAGE_ROUTE;

  emitNavigate(route: GoParams): void {
    this.navigate.emit(route);
  }
}
