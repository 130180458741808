import { IAppliedGlobalFiltersAsParams, IFactoryParams } from '@shared/interfaces';
import { InfluenceType, ModelType } from '@shared/enums';
import { Period } from '@measurement-studio/enums';

export enum OpportunityType {
  DEAL = 'deal',
  OPPTY = 'oppty',
}

export interface IMarketingInfluenceRequest {
  influenceType?: InfluenceType;
  modelType?: ModelType;
  period?: Period;
  opptyType?: OpportunityType;
  cohort?: string;
  endDate?: number;
  startDate?: number;
}

export type MarketingInfluenceUsedParams = IMarketingInfluenceRequest & IAppliedGlobalFiltersAsParams;

export interface MarketingInfluenceTileSettings extends IMarketingInfluenceRequest {
  type: 'actual'; // TODO: what is this?
  plotPoints: number;
}

export function marketingInfluenceTileSettingsFactory({
  overrides = {},
}: IFactoryParams<MarketingInfluenceTileSettings> = {}): MarketingInfluenceTileSettings {
  return {
    ...marketingInfluenceRequestFactory(),
    type: 'actual',
    plotPoints: 7,
    ...overrides,
  };
}

export function marketingInfluenceRequestFactory({
  overrides = {},
}: IFactoryParams<IMarketingInfluenceRequest, number, boolean> = {}): IMarketingInfluenceRequest {
  return {
    influenceType: InfluenceType.ANY,
    modelType: ModelType.Sourced,
    period: Period.MONTH,
    opptyType: OpportunityType.DEAL,
    cohort: 'time',
    startDate: 1483221600000, // 01/01/2017
    endDate: new Date().getTime(),
    ...overrides,
  };
}

