<div [tsLoadingOverlay]="isLoading$ | async">
  <div class="header-container">
    <div class="campaign-info">
      <div class="campaign-info-section" *ngFor="let info of campaignInfo$ | async">
        <strong>{{ info.title | translate }}</strong>
        <span>{{ info.value | typedData : info.type | titlecase }}</span>
      </div>
    </div>
  </div>

  <ms-campaign-filters (apply)="applyFilters($event)"
                       (setSelectedDateCohort)="setSelectedDateCohort($event)"
                       [dateCohortOptions]="dateCohortOptions$ | async"
                       [selectedDateCohort]="selectedDateCohort$ | async"
                       [modelTypeOptions]="modelTypeOptions$ | async"
                       [filters]="filters$ | async">
  </ms-campaign-filters>
  <hr class="report-divider">
  <div class="metric-container">
    <tsh-report-metric *ngFor="let metric of metrics$ | async"
                       [data]="metric">
    </tsh-report-metric>
    <tsh-selector-with-trigger (selected)="changeChartType($event)"
                               [options]="chartTypeOptions"
                               triggerTitle="feature.campaignSpecific.chart.triggerTitle"
                               [selectedOption]="selectedChartType$ | async">
    </tsh-selector-with-trigger>
  </div>
  <ng-container *ngIf="(chart$ | async)?.length; else noChart">
    <ms-campaign-chart [data]="chart$ | async"
                       [tooltipLabel]="chartTooltip$ | async"
                       [selectedChartType]="selectedChartType$ | async">
    </ms-campaign-chart>
  </ng-container>
  <ng-template #noChart>
    <tsh-no-report-result *ngIf="(isLoading$ | async) === false"
                          [description]="noResultDescription"
                          [title]="noResultTitle">
    </tsh-no-report-result>
  </ng-template>
  <hr class="report-divider">
  <ng-container *ngIf="tabs$ | async as tabs">
    <tsh-report-tabs [tabs]="tabs"></tsh-report-tabs>
  </ng-container>
  <router-outlet></router-outlet>
</div>

<ng-template [tshAttachTo]="targets.HeaderButtons">
  <ng-container *ngIf="(links$ | async)?.length">
    <ts-menu [menuItemsTemplate]="menuTemplate"
             class="campaign-specific-menu"
             triggerTheme="secondary">
      <i class="fas fa-ellipsis-h"></i>
    </ts-menu>
    <ng-template #menuTemplate>
      <ul class="campaign-links">
        <li *ngFor="let link of links$ | async">
          <a (click)="redirect(link)">
            {{ link.title | translate }} <i class="fas fa-external-link" *ngIf="link.isExternal"></i>
          </a>
        </li>
      </ul>
    </ng-template>
  </ng-container>
  <ts-button actionName="Button"
             buttonType="button"
             (clicked)="addToDashboardDialog()">
    {{ 'appHeader.addToDashboard' | translate }}
  </ts-button>
</ng-template>



