/* istanbul ignore file */
import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

import { IDateCohort, IPager, ISorter } from '@shared/interfaces';
import {
  ILoadCSVPayload,
  ILoadSuccessCSVPayload,
  IStoredColumns,
  ITabTotalsResponse,
  IToggleColumn,
  IWebActivitiesTabTotalsResponse
} from '../../interfaces';
import { AnalyticTypes } from '../../enums';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export enum GeneralActionsTypes {
  SetReportType = '[Campaign Analytics] Set Report Type',
  SetFilters = '[Campaign Analytics] Set Filters',
  GetFilters = '[Campaign Analytics] Get Filters',
  SetSelectedDateCohort = '[Campaign Analytics] Set selected date cohort',
  GetSelectedDateCohort = '[Campaign Analytics] Get selected date cohort',
  ChangeCampaignGroupsSorting = '[Campaign Analytics] Change sorting for campaign groups table',
  ChangeCampaignGroupsPagination = '[Campaign Analytics] Change pagination for campaign groups table',
  ChangeCampaignGroupsSearch = '[Campaign Analytics] Change search for campaign groups table',
  ToggleCampaignGroupsColumnVisibility = '[Campaign Analytics] Toggle campaign groups column visibility',
  ToggleCampaignsColumnVisibility = '[Campaign Analytics] Toggle campaigns column visibility',
  ToggleSourceColumnVisibility = '[Campaign Analytics] Toggle source column visibility',
  ToggleCampaignColumnVisibility = '[Campaign Analytics] Toggle campaign column visibility',
  ToggleMediumsColumnVisibility = '[Campaign Analytics] Toggle medium column visibility',
  DownloadAnalyticsCSV = '[Campaign Analytics] Download Analytics CSV',
  DownloadAnalyticsCSVSuccess = '[Campaign Analytics] Download Analytics CSV success',
  DownloadAnalyticsCSVFailure = '[Campaign Analytics] Download Analytics CSV failure',
  ChangeCampaignsSorting = '[Campaign Analytics] Change sorting for campaigns table',
  ChangeCampaignsPagination = '[Campaign Analytics] Change pagination for campaigns table',
  ChangeCampaignsSearch = '[Campaign Analytics] Change search for campaigns table',
  ChangeCampaignSorting = '[Campaign Analytics] Change sorting for campaign table',
  ChangeCampaignPagination = '[Campaign Analytics] Change pagination for campaign table',
  ChangeCampaignSearch = '[Campaign Analytics] Change search for campaign table',
  ChangeSourceSorting = '[Campaign Analytics] Change sorting for source table',
  ChangeSourcePagination = '[Campaign Analytics] Change pagination for source table',
  ChangeSourceSearch = '[Campaign Analytics] Change search for source table',
  ChangeMediumsSorting = '[Campaign Analytics] Change sorting for medium table',
  ChangeMediumsPagination = '[Campaign Analytics] Change pagination for medium table',
  ChangeMediumsSearch = '[Campaign Analytics] Change search for medium table',
  SetColumns = '[Campaign Analytics] Set columns for tables',
  LoadTabTotals = '[Campaign Analytics] Load Tab Totals',
  LoadTabTotalsSuccess = '[Campaign Analytics] Load Tab Totals Success',
  LoadTabTotalsFailure = '[Campaign Analytics] Load Tab Totals Failure',
  LoadWebActivitiesTabTotals = '[WebActivities] Load Web Activities Tab Totals',
  LoadWebActivitiesTabTotalsSuccess = '[WebActivities] Load Web Activities Tab Totals Success',
  LoadWebActivitiesTabTotalsFailure = '[WebActivities] Load Web Activities Tab Totals Failure',
  ChangeCampaignsSearchByTags = '[Campaign Analytics] Change searchByTags for campaigns table',
  ChangeCampaignSearchByTags = '[Web Activities] Change searchByTags for campaign table',
}


const setReportType = createAction(
  GeneralActionsTypes.SetReportType,
  props<{ reportType: AnalyticTypes }>()
);

const setFilters = createAction(
  GeneralActionsTypes.SetFilters,
  props<{ request: IAnalyticsRequest }>()
);

const getFilters = createAction(
  GeneralActionsTypes.GetFilters,
  props<{ params: Params }>()
);

const setSelectedDateCohort = createAction(
  GeneralActionsTypes.SetSelectedDateCohort,
  props<{ cohort: IDateCohort }>()
);

const getSelectedDateCohort = createAction(
  GeneralActionsTypes.GetSelectedDateCohort,
  props<{ request: IAnalyticsRequest }>()
);

const toggleCampaignGroupsColumnVisibility = createAction(
  GeneralActionsTypes.ToggleCampaignGroupsColumnVisibility,
  props<IToggleColumn>()
);

const toggleCampaignsColumnVisibility = createAction(
  GeneralActionsTypes.ToggleCampaignsColumnVisibility,
  props<IToggleColumn>()
);

const toggleSourceColumnVisibility = createAction(
  GeneralActionsTypes.ToggleSourceColumnVisibility,
  props<IToggleColumn>()
);

const toggleMediumsColumnVisibility = createAction(
  GeneralActionsTypes.ToggleMediumsColumnVisibility,
  props<IToggleColumn>()
);

const toggleCampaignColumnVisibility = createAction(
  GeneralActionsTypes.ToggleCampaignColumnVisibility,
  props<IToggleColumn>()
);

const changeCampaignGroupsSorting = createAction(
  GeneralActionsTypes.ChangeCampaignGroupsSorting,
  props<ISorter>()
);

const changeCampaignGroupsPagination = createAction(
  GeneralActionsTypes.ChangeCampaignGroupsPagination,
  props<IPager>()
);

const changeCampaignGroupsSearch = createAction(
  GeneralActionsTypes.ChangeCampaignGroupsSearch,
  props<{ search: string }>()
);

const downloadAnalyticsCSV = createAction(
  GeneralActionsTypes.DownloadAnalyticsCSV,
  props<{ request: ILoadCSVPayload }>()
);

const downloadAnalyticsCSVSuccess = createAction(
  GeneralActionsTypes.DownloadAnalyticsCSVSuccess,
  props<{ data: ILoadSuccessCSVPayload }>()
);

const downloadAnalyticsCSVFailure = createAction(
  GeneralActionsTypes.DownloadAnalyticsCSVFailure,
  props<{ message: string }>()
);

const changeCampaignsSorting = createAction(
  GeneralActionsTypes.ChangeCampaignsSorting,
  props<ISorter>()
);

const changeCampaignsPagination = createAction(
  GeneralActionsTypes.ChangeCampaignsPagination,
  props<IPager>()
);

const changeCampaignsSearch = createAction(
  GeneralActionsTypes.ChangeCampaignsSearch,
  props<{ search: string }>()
);

const changeCampaignSorting = createAction(
  GeneralActionsTypes.ChangeCampaignSorting,
  props<ISorter>()
);

const changeCampaignPagination = createAction(
  GeneralActionsTypes.ChangeCampaignPagination,
  props<IPager>()
);

const changeCampaignSearch = createAction(
  GeneralActionsTypes.ChangeCampaignSearch,
  props<{ search: string }>()
);

const changeSourceSorting = createAction(
  GeneralActionsTypes.ChangeSourceSorting,
  props<ISorter>()
);

const changeSourcePagination = createAction(
  GeneralActionsTypes.ChangeSourcePagination,
  props<IPager>()
);

const changeSourceSearch = createAction(
  GeneralActionsTypes.ChangeSourceSearch,
  props<{ search: string }>()
);

const changeMediumsSorting = createAction(
  GeneralActionsTypes.ChangeMediumsSorting,
  props<ISorter>()
);

const changeMediumsPagination = createAction(
  GeneralActionsTypes.ChangeMediumsPagination,
  props<IPager>()
);

const changeMediumsSearch = createAction(
  GeneralActionsTypes.ChangeMediumsSearch,
  props<{ search: string }>()
);

const setColumns = createAction(
  GeneralActionsTypes.SetColumns,
  props<{ columns: IStoredColumns }>()
);

const loadTabTotals = createAction(
  GeneralActionsTypes.LoadTabTotals,
  props<{ request: IAnalyticsRequest }>()
);

const loadTabTotalsSuccess = createAction(
  GeneralActionsTypes.LoadTabTotalsSuccess,
  props<{ data: ITabTotalsResponse }>()
);

const loadTabTotalsFailure = createAction(
  GeneralActionsTypes.LoadTabTotalsFailure,
  props<{ message: string }>()
);

const loadWebActivitiesTabTotals = createAction(
  GeneralActionsTypes.LoadWebActivitiesTabTotals,
  props<{ request: IAnalyticsRequest }>()
);

const loadWebActivitiesTabTotalsSuccess = createAction(
  GeneralActionsTypes.LoadWebActivitiesTabTotalsSuccess,
  props<{ data: IWebActivitiesTabTotalsResponse }>()
);

const loadWebActivitiesTabTotalsFailure = createAction(
  GeneralActionsTypes.LoadWebActivitiesTabTotalsFailure,
  props<{ message: string }>()
);

const changeCampaignsSearchByTags = createAction(
  GeneralActionsTypes.ChangeCampaignsSearchByTags,
  props<{ searchByTags: boolean }>()
);
const changeCampaignSearchByTags = createAction(
  GeneralActionsTypes.ChangeCampaignSearchByTags,
  props<{ searchByTags: boolean }>()
);

export const generalActions = {
  setReportType,
  setFilters,
  getFilters,
  setSelectedDateCohort,
  getSelectedDateCohort,
  changeCampaignGroupsSorting,
  changeCampaignGroupsPagination,
  changeCampaignGroupsSearch,
  toggleCampaignGroupsColumnVisibility,
  toggleCampaignsColumnVisibility,
  toggleSourceColumnVisibility,
  toggleCampaignColumnVisibility,
  toggleMediumsColumnVisibility,
  downloadAnalyticsCSV,
  downloadAnalyticsCSVSuccess,
  downloadAnalyticsCSVFailure,
  changeCampaignsSorting,
  changeCampaignsPagination,
  changeCampaignsSearch,
  changeCampaignSorting,
  changeCampaignPagination,
  changeCampaignSearch,
  changeSourceSorting,
  changeSourcePagination,
  changeSourceSearch,
  changeMediumsSorting,
  changeMediumsPagination,
  changeMediumsSearch,
  setColumns,
  loadTabTotals,
  loadTabTotalsSuccess,
  loadTabTotalsFailure,
  loadWebActivitiesTabTotals,
  loadWebActivitiesTabTotalsSuccess,
  loadWebActivitiesTabTotalsFailure,
  changeCampaignsSearchByTags: changeCampaignsSearchByTags,
  changeCampaignSearchByTags,
};
