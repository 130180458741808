<div [tsLoadingOverlay]="isLoading$ | async">
  <tsh-date-cohort *ngIf="selectedDateCohort$ | async as selectedDateCohort"
                   (apply)="applyFilters($event || selectedDateCohort)"
                   (dateRangeFilterChange)="changeDateCohort($event)"
                   [selectedDateRange]="selectedDateCohort"
                   [dateCohortOptions]="dateCohortOptions$ | async"
                   [isDisabled]="isLoading$ | async">
  </tsh-date-cohort>
  <hr class="report-divider">
  <ng-container *ngIf="(chartData$ | async)?.length">
    <ms-stages-snapshot-chart [chartData]="chartData$ | async" data-cy="stages-snapshot-chart">
    </ms-stages-snapshot-chart>
    <hr class="report-divider">
    <ms-stages-snapshot-tabs [tabs]="tabs$ | async"
                             data-cy="stages-snapshot-tabs"
                             [activeTab]="activeTab$ | async"
                             [activeCount]="(all$ | async)?.length"
                             (selectTab)="setActiveStage($event)">
    </ms-stages-snapshot-tabs>
    <ms-stages-snapshot-table [report]="table$ | async"
                              data-cy="stages-snapshot-table"
                              [columns]="columns$ | async"
                              [isLoading]="isLoading$ | async"
                              [reportOptions]="tableOptions$ | async"
                              totalTitle="measurementStudio.features.stagesSnapshot.people"
                              [count]="(all$ | async)?.length"
                              [crmUrl]="crmUrl$ | async"
                              [noResultTitle]="noResultTableTitle"
                              [noResultDescription]="noResultTableDescription"
                              (sort)="applySort($event)"
                              (search)="applySearch($event)"
                              (pageSelect)="applyPage($event)"
                              (download)="download()"
                              (externalRedirect)="externalRedirect($event)"
                              (redirect)="redirect($event)">
    </ms-stages-snapshot-table>
  </ng-container>
  <tsh-no-report-result *ngIf="(isLoading$ | async) === false && !(chartData$ | async).length"
                        data-cy="stages-snapshot-no-report"
                        [description]="noResultDescription"
                        [title]="noResultTitle">
  </tsh-no-report-result>
</div>

<tsh-add-to-dashboard-button (add)="addToDashboardDialog()"
                             [target]="targets.HeaderButtons">
</tsh-add-to-dashboard-button>
