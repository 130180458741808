<ts-expansion-panel (expandedChange)="panelExpandedChange($event)"
                    [transparentMode]="true"
                    class="report-setting-panel"
                    data-cy="report-setting-panel">
  <ts-expansion-panel-trigger collapsedHeight="27px" expandedHeight="27px">
    <div class="report-setting-trigger">
      <span class="report-setting-title">Report Settings</span>
      <i class="fas fa-xs" [ngClass]="{'fa-chevron-down': isExpanded, 'fa-chevron-right': !isExpanded}"></i>
      <span class="report-setting-subtitle">
        <ng-container *ngIf="specificSettingSubtitleHead; else defaultSettingSubtitle">
          {{ specificSettingSubtitleHead | translate: {reportSettingsCount: reportSettingsCount} }}
        </ng-container>
        <ng-template #defaultSettingSubtitle>
          {{ 'shared.reportSettingPanel.reportSettingSubtitle.head' | translate: {
            reportName: (reportName | translate),
            reportSettingsCount: reportSettingsCount
          } }}
        </ng-template>
        <ng-container *ngIf="appliedFiltersNumber$ | async as appliedFiltersNumber">
          {{ 'shared.reportSettingPanel.reportSettingSubtitle.middle' | translate: {appliedFiltersNumber: appliedFiltersNumber} }}
        </ng-container>
        {{ 'shared.reportSettingPanel.reportSettingSubtitle.tail' | translate }}
      </span>
    </div>
  </ts-expansion-panel-trigger>
  <div class="filters-container">
    <ng-content></ng-content>
    <ts-button class="apply-button"
               theme="default"
               [isDisabled]="isDisabled"
               (clicked)="emitApply()"
               [isSmall]="true"
               data-cy="report-setting-panel-apply">
      {{ 'shared.reportSettingPanel.applyReportSettings' | translate }}
    </ts-button>
    <span class="pending-message" *ngIf="isPending">
      {{ 'shared.reportSettingPanel.pendingChanges' | translate }}
    </span>
  </div>
</ts-expansion-panel>
