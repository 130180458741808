import { Injectable } from '@angular/core';
import { XyChartConfigService } from '../xy-chart-config/xy-chart-config.service';
import { PieChartConfigService } from '../pie-chart-config/pie-chart-config.service';
import { GaugeChartConfigService } from '../gauge-chart-config/gauge-chart-config.service';
import { VisualizationTypes } from '@shared/enums';
import { IChartConfig, IDashboardTile, ITileData, ITileGaugeData } from '@shared/interfaces';

@Injectable()
export class ChartConfigService {

  constructor(
    private xyChartService: XyChartConfigService,
    private pieChartService: PieChartConfigService,
    private gaugeChartService: GaugeChartConfigService,
  ) {
  }

  getChartConfig(
    tile: IDashboardTile,
    data: ITileData | ITileGaugeData | null,
  ): IChartConfig {
    if (!data) {
      return null;
    }
    switch (tile.settings.visualization) {
      case VisualizationTypes.Column:
      case VisualizationTypes.StackedColumn:
      case VisualizationTypes.Line:
      case VisualizationTypes.StackedArea:
      case VisualizationTypes.Pareto:
      case VisualizationTypes.ClusteredColumn:
        return this.xyChartService.getConfig(tile, (data as ITileData).items);

      case VisualizationTypes.Donut:
        return this.pieChartService.getConfig(tile, (data as ITileData).items);

      case VisualizationTypes.Gauge: {
        // check if there is data
        if ((data as ITileGaugeData)?.items[0]) {
          return this.gaugeChartService.getConfig(tile, (data as ITileGaugeData).items[0]);
        }
        return;
      }

      default:
        return null;
    }
  }
}
