import { dateCohortFactory, IDateCohort, IFactoryParams } from '@shared/interfaces';
import { IAdsInfluencedBaseRequest } from './ads-influenced-request.interface';
import { AdsInsightsReportType } from '../enums/insights-report-types.enum';

export interface IBaseAdsInfluencedChildTableRequest extends IAdsInfluencedBaseRequest {
  cohort: IDateCohort;
}

export interface IAdsInfluencedChildAccountTableRequest extends IBaseAdsInfluencedChildTableRequest {
  crmAccountId: string;
}

export interface IAdsInfluencedChildTacticTableRequest extends IBaseAdsInfluencedChildTableRequest {
  tacticUuid: string;
}

export interface IAdsInfluencedChildReportTableRequest extends IBaseAdsInfluencedChildTableRequest {
  identifier: string;
}

export function baseAdsInfluencedChildTableRequestFactory({
  overrides = {}
}: IFactoryParams<IBaseAdsInfluencedChildTableRequest, number, boolean> = {}
): IBaseAdsInfluencedChildTableRequest {
  return {
    cohort: dateCohortFactory(),
    reportType: AdsInsightsReportType.PageView,
    ...overrides
  };
}

export function adsInfluencedChildAccountTableRequestFactory({
 overrides = {}
}: IFactoryParams<IAdsInfluencedChildAccountTableRequest, number, boolean> = {}
): IAdsInfluencedChildAccountTableRequest {
  return {
    ...baseAdsInfluencedChildTableRequestFactory(),
    crmAccountId: 'crmAccountId0',
    ...overrides
  };
}

export function adsInfluencedChildTacticTableRequestFactory({
  overrides = {}
}: IFactoryParams<IAdsInfluencedChildTacticTableRequest, number, boolean> = {}
): IAdsInfluencedChildTacticTableRequest {
  return {
    ...baseAdsInfluencedChildTableRequestFactory(),
    tacticUuid: 'tacticUuid0',
    ...overrides
  };
}

export function adsInfluencedChildReportTableRequestFactory({
  overrides = {}
}: IFactoryParams<IAdsInfluencedChildReportTableRequest, number, boolean> = {}
): IAdsInfluencedChildReportTableRequest {
  return {
    ...baseAdsInfluencedChildTableRequestFactory(),
    identifier: 'identifier0',
    ...overrides
  };
}
