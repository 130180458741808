import { createAction, props } from '@ngrx/store';

export const PanicActionTypes = {
  AddPanicMessage: '[Panic] Add message',
}

const addPanicMessage = createAction(
  PanicActionTypes.AddPanicMessage,
  props<{ message: string }>()
);

export const panicActions = {
  addPanicMessage,
}
