import { createSelector } from '@ngrx/store';
import { AnalyticsState, selectAnalyticsState } from '..';
import { gerRecordIds, isStateLoading } from '../../helpers/store.helper';
import { IMediumsTableItem, INestedTableState } from '../../interfaces';
import { IMediumsState, mediumsFeatureKey } from './mediums.reducer';

export const getMediums = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[mediumsFeatureKey]);

export const getReportData = createSelector(getMediums,
  (state: IMediumsState): Partial<IMediumsTableItem>[] => state.data);

export const getMediumsTotalResults = createSelector(getMediums,
  (state: IMediumsState) => state.totalResults);

export const getMediumsRecordIds = createSelector(getMediums,
  (state: IMediumsState) => gerRecordIds(state.data));

export const getMediumsUsedParams = createSelector(getMediums,
  (state: IMediumsState) => state.usedParamsInDriver);

export const getMediumsIsLoading = createSelector(getMediums, isStateLoading);

export const getMediumsIsDriverLoading = createSelector(getMediums,
  (state: IMediumsState) => state.isDriverLoading);

export const getNestedTableState = createSelector(getMediums,
  (campaigns: IMediumsState) => campaigns.nestedTableState);

export const getNestedTableVisibility = createSelector(getNestedTableState,
  (nestedState: INestedTableState): Record<string, boolean> => nestedState.visible);
