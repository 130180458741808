import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DefaultTileInformation, IDashboardTile, ITileData, ITileGaugeData, ITileVisualizationConfig } from '@shared/interfaces';
import { TileTypes, VisualizationTypes } from '@shared/enums';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tsh-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent {
  @Input()
  public set tile(data: IDashboardTile) {
    this.supportedTypeOfTile = data
      && data.type !== TileTypes.UngatedCampaigns
      && data.type !== TileTypes.VelocityCampaign;
    this._tile = data;
    this.extended$.next(data.size?.rows === 2);
  }

  public get tile(): IDashboardTile {
    return this._tile;
  }

  @Input() public defaultInformation: Record<TileTypes, DefaultTileInformation>;
  @Input() public data: ITileData | ITileGaugeData | null;
  @Input() public error: string | null;
  @Input() public visualizationConfig: ITileVisualizationConfig | null;
  @Input() public loading = false;
  @Input() public isLimitReached = false;
  @Input() public currency: string | null;
  // Show menu on dashboard page and hide in tile modal
  @Input() public showMenu = false;
  // Add a special class to make header draggable
  // it's valid only for dashboard page
  @Input() public isDraggable = false;

  @Output() public edit = new EventEmitter<IDashboardTile>();
  @Output() public delete = new EventEmitter<IDashboardTile>();
  @Output() public duplicate = new EventEmitter<IDashboardTile>();

  public readonly visualizationTypes = VisualizationTypes;
  // This flag blocks edit/duplicate functionality for those types of tiles
  // which we don't support anymore (e.g. UngatedCampaigns, VelocityCampaign)
  public supportedTypeOfTile = false;
  public extended$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _tile: IDashboardTile;

  emitEdit(tile: IDashboardTile): void {
    this.edit.emit(tile);
  }

  emitDelete(tile: IDashboardTile): void {
    this.delete.emit(tile);
  }

  emitDuplicate(tile: IDashboardTile): void {
    this.duplicate.emit(tile);
  }

  preventDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }
}
