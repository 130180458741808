import { Inject, Injectable } from '@angular/core';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { RouteItemEnum, TileCategories, TileTypes } from '@shared/enums';
import { MarketingInfluenceService, MarketingInfluenceTileSettings } from '@measurement-studio/features/marketing-influence';
import {
  DefaultTileInformation,
  IAccountListDataParams,
  IDashboardTile,
  IMetricInsightsParams,
  IReportsAccess,
  IRouteItem,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig
} from '@shared/interfaces';
import { IAnalyticsRequest, ICampaignSpecificFilters, IOpportunityRequest } from '@measurement-studio/interfaces';
import { DEFAULT_TILE_INFORMATION } from '@shared/constants';
import * as UserSelectors from '@user/user.selectors';
import { orgConfigActions, getSelectedTerminusData } from '@org-config';
import { aggregateListsActions } from '@shared/data-access/aggregate-lists';
import { campaignsActions } from '@shared/data-access/campaigns';
import { catalogsActions } from '@shared/data-access/catalogs';
import { AccountHubSharedService } from '@shared/data-access/account-hub';
import { KpiService } from '@measurement-studio/features/scorecard';
import { CampaignAnalyticsService, WebActivitiesService } from '@measurement-studio/features/analytics';
import { OpportunitiesService } from '@measurement-studio/features/opportunities';
import { IStagesSnapshotFilters, StagesSnapshotService } from '@measurement-studio/features/stages-snapshot';
import { ITrendingParams, TrendingService } from '@measurement-studio/features/trending';
import { CampaignSpecificService } from '@measurement-studio/features/attribution';
import { APP_ROUTES } from '@app-routes';
import { ITileModalData } from '@shared/data-access/tile-modal';

@Injectable({
  providedIn: 'root'
})
export class TileDataService implements ITileModalData {
  constructor(public opportunitiesService: OpportunitiesService,
    public kpiService: KpiService,
    public trendingService: TrendingService,
    public campaignSpecificService: CampaignSpecificService,
    public stagesSnapshotService: StagesSnapshotService,
    public webActivitiesService: WebActivitiesService,
    public campaignAnalyticsService: CampaignAnalyticsService,
    public marketingInfluenceService: MarketingInfluenceService,
    public sharedAccountHubService: AccountHubSharedService,
    private store: Store<unknown>,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[]) {
  }

  getTileData$(tile: IDashboardTile): Observable<ITileData | ITileGaugeData> {
    const params = tile.settings;
    switch (tile.type) {
      case TileTypes.ScorecardTrending:
        return this.kpiService.getTileData$(params);

      case TileTypes.OpportunityInsights:
        return this.opportunitiesService.getTileData$(params as IOpportunityRequest);

      case TileTypes.MarketingInfluence:
        return this.marketingInfluenceService.getTileData$(params as unknown as MarketingInfluenceTileSettings);

      case TileTypes.WebActivitiesTrending:
      case TileTypes.CampaignTrends:
        return this.trendingService.getTileData$(params as unknown as ITrendingParams, tile.route);

      case TileTypes.TopCampaignTypes:
      case TileTypes.TopCampaigns:
        return this.campaignAnalyticsService.getTileData$(params as unknown as IAnalyticsRequest, tile.route);

      case TileTypes.CampaignSpecific:
      case TileTypes.WebActivitiesSpecific:
        return this.campaignSpecificService.getTileData$(params as unknown as ICampaignSpecificFilters, tile.route);

      case TileTypes.StagesSnapshot:
        return this.stagesSnapshotService.getTileData$(params as IStagesSnapshotFilters);

      case TileTypes.WebActivitiesChannel:
      case TileTypes.WebActivitiesChannelAssets:
        return this.webActivitiesService.getTileData$(params as unknown as IAnalyticsRequest, tile.route);

      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileData$(params as unknown as IMetricInsightsParams | IAccountListDataParams,
          null,
          tile.type);

      case TileTypes.VelocityCampaign:
      case TileTypes.UngatedCampaigns:
        return of({
          items: []
        }); // NOTE: we don't need to support these types but we want to show no data message

      default:
        return throwError(`Unknown report: ${tile.route}`);
    }
  }

  // Settings for chart and table
  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    switch (tile.route) {
      case RouteItemEnum.ScorecardTrendingKpi:
        return this.kpiService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.OpportunityInsights:
        return this.opportunitiesService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.MarketingImpact:
      case RouteItemEnum.AnalyzeMarketingImpact:
        return this.marketingInfluenceService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.CampaignTrends:
      case RouteItemEnum.WebActivitiesTrending:
        return this.trendingService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.CampaignAnalyticsGroups:
      case RouteItemEnum.CampaignAnalyticsCampaigns:
        return this.campaignAnalyticsService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.CampaignSpecific:
      case RouteItemEnum.WebActivitiesSpecific:
      case RouteItemEnum.WebActivitiesLeads: // to support legacy tiles
      case RouteItemEnum.CampaignSpecificValues: // to support legacy tiles
      case RouteItemEnum.WebActivitiesValues: // to support legacy tiles
        return this.campaignSpecificService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.StagesSnapshot:
        return this.stagesSnapshotService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.WebActivitiesChannel:
      case RouteItemEnum.WebActivitiesChannelAssets:
        return this.webActivitiesService.getTileVisualizationConfig$(tile);
      case RouteItemEnum.AccountHub:
        return this.sharedAccountHubService.getTileVisualizationConfig$(tile);

      default:
        return of(null);
    }
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[] | null> {
    switch (type) {
      case TileTypes.ScorecardTrending:
        return this.kpiService.getTileSettingsFilters$();

      case TileTypes.TopCampaignTypes:
      case TileTypes.TopCampaigns:
        return this.campaignAnalyticsService.getTileSettingsFilters$(type);

      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileSettingsFilters$(type);

      case TileTypes.OpportunityInsights:
        return this.opportunitiesService.getTileSettingsFilters$();

      case TileTypes.StagesSnapshot:
        return this.stagesSnapshotService.getTileSettingsFilters$();

      case TileTypes.MarketingInfluence:
        return this.marketingInfluenceService.getTileSettingsFilters$();

      case TileTypes.CampaignSpecific:
      case TileTypes.WebActivitiesSpecific:
        return this.campaignSpecificService.getTileSettingsFilters$(type);

      case TileTypes.WebActivitiesChannel:
      case TileTypes.WebActivitiesChannelAssets:
        return this.webActivitiesService.getTileSettingsFilters$(type);

      case TileTypes.WebActivitiesTrending:
      case TileTypes.CampaignTrends:
        return this.trendingService.getTileSettingsFilters$();

      case TileTypes.VelocityCampaign:
      case TileTypes.UngatedCampaigns:
        return of(null); // NOTE: unknown type

      default:
        console.error(`Couldn't find tile's type ${type}`);
        return of(null);
    }
  }

  // get all available tile after checking permission by category
  // add default settings for each tile
  getAvailableTiles$(): Observable<Record<TileTypes, DefaultTileInformation>> {
    return combineLatest([
      this.store.pipe(select(UserSelectors.selectUserProfileReportsAccess)),
      this.store.pipe(select(getSelectedTerminusData))
    ]).pipe(
      map(([access, isTerminusData]: [IReportsAccess, boolean]) => {
        const types = Object.keys(DEFAULT_TILE_INFORMATION) as TileTypes[];
        return types.reduce((acc, type: TileTypes) => {
          // return tile if it's available or in case of revenue attribution category
          // return disabled or not disabled tile
          const checkedTile = this.checkTileAccess(
            DEFAULT_TILE_INFORMATION[type],
            isTerminusData,
            access
          );
          if (checkedTile) {
            const settings = this.getTileSettingsByType(type);
            acc[type] = {
              ...checkedTile,
              settings: {
                ...checkedTile.settings,
                ...settings
              }
            };
          }

          return acc;
        }, {} as Record<TileTypes, DefaultTileInformation>);
      }),
    );
  }

  // Settings for tile modal form
  getTileSettingsByType(type: TileTypes, params?: ITileSettings): ITileSettings {
    switch (type) {
      case TileTypes.ScorecardTrending:
        return this.kpiService.getTileDefaultSettings(params);

      case TileTypes.TopCampaignTypes:
      case TileTypes.TopCampaigns:
        return this.campaignAnalyticsService.getTileDefaultSettings(params);

      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileDefaultSettings(params, type);

      case TileTypes.OpportunityInsights:
        return this.opportunitiesService.getTileDefaultSettings(params);

      case TileTypes.StagesSnapshot:
        return this.stagesSnapshotService.getTileDefaultSettings(params);

      case TileTypes.MarketingInfluence:
        return this.marketingInfluenceService.getTileDefaultSettings(params);

      case TileTypes.CampaignSpecific:
      case TileTypes.WebActivitiesSpecific:
        return this.campaignSpecificService.getTileDefaultSettings(params);

      case TileTypes.WebActivitiesChannel:
      case TileTypes.WebActivitiesChannelAssets:
        return this.webActivitiesService.getTileDefaultSettings(params);

      case TileTypes.WebActivitiesTrending:
      case TileTypes.CampaignTrends:
        return this.trendingService.getTileDefaultSettings(params);

      case TileTypes.VelocityCampaign:
        return {} as ITileSettings; // NOTE: unknown type

      case TileTypes.UngatedCampaigns:
        return {} as ITileSettings; // NOTE: unknown type

      default:
        console.error(`Couldn't find tile's type ${type}`);
        return {} as ITileSettings;
    }
  }

  loadOptions(): void {
    // spikes models and aggregate lists are needed for some tiles
    this.store.dispatch(aggregateListsActions.loadAggregateLists());
    this.store.dispatch(orgConfigActions.LoadSpikeModels());
    this.store.dispatch(campaignsActions.getCampaignTypes());
    this.store.dispatch(catalogsActions.loadWebActivitiesTypes());
  }

  private checkTileAccess(tile: DefaultTileInformation,
    isTerminusData: boolean,
    reportAccess: IReportsAccess
  ): DefaultTileInformation | null {
    // Check EngagementInsights separately because it depends
    // on terminus data from org config
    if (tile.type === TileTypes.EngagementInsights) {
      return isTerminusData ? tile : null;
    }
    const routeInformation = this.getRouteItem(tile.route);
    // if there is no route which we use in the tile then return null
    // there is some kind of error
    if (!routeInformation) {
      console.error('No route for this tile');
      return null;
    }
    // NOTE: there is no access info for CampaignAnalyticsGroups, CampaignAnalyticsCampaigns and CampaignSpecific
    // that's why we have to check their parent CampaignAnalytics
    if (routeInformation.routeId.includes(RouteItemEnum.CampaignAnalytics)
      || routeInformation.routeId === RouteItemEnum.CampaignSpecific) {
      return {
        ...tile,
        // disable the type if there is no access to CampaignAnalytics
        disabled: !reportAccess[RouteItemEnum.CampaignAnalytics]
      };
    }

    // NOTE: there is no access info for WebActivitiesChannel, WebActivitiesChannelAssets, WebActivitiesSpecific
    // that's why we have to check their parent WebTracking
    if (routeInformation.routeId.includes(RouteItemEnum.WebTracking)) {
      return {
        ...tile,
        // disable the type if there is no access to WebTracking
        disabled: !reportAccess[RouteItemEnum.WebTracking]
      };
    }

    // check other RevenueAttribution and DigitalAttribution tiles by their route's access and if there is no access then
    // add them and marks them as disabled. This is a business requirement
    if (tile.category === TileCategories.RevenueAttribution || tile.category === TileCategories.DigitalAttribution) {
      // set as disabled
      return {
        ...tile,
        disabled: !routeInformation.alwaysAdd && !reportAccess[tile.route]
      };
    }
    // check other tiles and add them to the result if there is access to this route
    // otherwise remove from the result
    if ((routeInformation.alwaysAdd || reportAccess[tile.route])) {
      return tile;
    }

    return null;
  }

  private getRouteItem(routeId: RouteItemEnum): IRouteItem | null {
    return this.applicationRoutes.find(route => route.routeId === routeId);
  }
}
