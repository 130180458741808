export enum TileTypes {
    ScorecardTrending = 'trendingKpis',
    TopCampaignTypes = 'cpList', // CampaignAnalyticsGroups
    TopCampaigns = 'campaignsList', // CampaignAnalyticsCampaigns
    UngatedCampaigns = 'ungatedCampaigns', // No longer available
    AccountsList = 'accountsList', // AccountHub
    EngagementInsights = 'engagementInsights', // AccountHub
    OpportunityInsights = 'opptyAnalysisList',
    StagesSnapshot = 'stagesSnapshot',
    CampaignTrends = 'leadsGenerated',
    MarketingInfluence = 'marketingImpact',
    VelocityCampaign = 'velocityCampaign', // No longer available
    CampaignSpecific = 'campaignSpecificTrending',
    WebActivitiesChannel = 'webActivityList', // WebActivitiesChannel
    WebActivitiesChannelAssets = 'channelAssetsList',
    WebActivitiesSpecific = 'channelAssetSpecificTrendingTile',
    WebActivitiesTrending = 'webActivityTrendingTile',
}
