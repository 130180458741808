<div class="benchmark-container">
  <span class="benchmark-label">{{ 'feature.sharedTrending.filters.benchmark' | translate }}</span>
  <ts-menu [menuItemsTemplate]="benchmarkTemplate" class="ts-menu-as-selector">
        <span class="benchmark-result">
            {{ currentType?.label | translate }}
          <ng-container *ngIf="currentType?.value === newTrendsType.Goal && currentAmount">
                ({{ currentAmount | typedData : dataTypes.Number }})
            </ng-container>
        </span>
  </ts-menu>
</div>

<ng-template #benchmarkTemplate>
  <div class="radio-button-container">
    <ts-radio-group [value]="selectedBenchmark.value" (click)="$event.stopPropagation()">
      <ng-container *ngFor="let item of options">
        <ts-radio-button [value]="item.value"
                         (selectionChange)="selectedBenchmark = item">{{ item.label | translate }}</ts-radio-button>
        <ng-container *ngIf="selectedBenchmark.value === newTrendsType.Goal && item.value === newTrendsType.Goal">
          <ts-input [formControl]="goalField"
                    [isSmall]="true"
                    name="goal"
                    type="number"
                    [label]="'feature.sharedTrending.filters.goalLabel' | translate"
                    (click)="$event.stopPropagation()"
                    [isRequired]="true">
          </ts-input>
        </ng-container>
      </ng-container>
    </ts-radio-group>
  </div>
  <hr class="report-divider">
  <div class="confirm-button-container">
    <ts-button theme="default"
               tsMenuItem="transparent"
               [isSmall]="true"
               [isDisabled]="selectedBenchmark.value === newTrendsType.Goal && !goalField?.value"
               (click)="$event.stopPropagation()"
               (clicked)="confirmBenchmarkChange(selectedBenchmark)">
      Confirm
    </ts-button>
    <ts-button theme="secondary"
               [isSmall]="true"
               tsMenuItem="transparent"
               (clicked)="cancelBenchmarkChange()">
      Cancel
    </ts-button>
  </div>
</ng-template>
