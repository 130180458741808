import { map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SplitIoState } from './split-io.reducer';
import { splitIoFlagRequested, splitIoFlagReceived } from './split-io.actions';
import { SplitIoService } from '../services/split-io.service';

@Injectable()
export class SplitIoEffects {
  public readonly flagRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(splitIoFlagRequested),
      mergeMap(action =>
        this.splitIoService.getTreatment(action.trafficType, action.treatment, action.attrs).pipe(
          map(status => {
            return splitIoFlagReceived({ trafficType: action.trafficType, treatment: action.treatment, status });
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly store: Store<SplitIoState>,
    private readonly splitIoService: SplitIoService
  ) {  }
}
