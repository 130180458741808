import { createAction, props } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';

import { IAuthToken } from '@shared/interfaces';

export enum HubCookieActionTypes {
  UPDATE_JWT = '[Hub Cookie] Update JWT',
  UPDATE_JWT_ON_SWITCH_USER = '[Hub Cookie] Update JWT on switch user',
  DELETE_JWT = '[Hub Cookie] Delete JWT',
}

defineTypeEnum(HubCookieActionTypes);

const updateJwt = createAction(
  HubCookieActionTypes.UPDATE_JWT,
  props<{token: IAuthToken}>()
);

const updateJwtOnSwitchUser = createAction(
  HubCookieActionTypes.UPDATE_JWT_ON_SWITCH_USER,
  props<{token: IAuthToken}>()
);

const deleteJwt = createAction(
  HubCookieActionTypes.DELETE_JWT
);

export const hubCookieActions = {
  updateJwt,
  updateJwtOnSwitchUser,
  deleteJwt
};
