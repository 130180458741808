import { IInsightsDetailsReportTab } from '../interfaces';
import * as Keys from '../enums/insights-report-tab';
import { INSIGHTS_DETAILS_COLUMN_DEFINITIONS } from './details-report-column-configs.consts';

// TODO - These will also include a reference to a table configuration
export const PAGE_VIEWS_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.PageViewsInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.PageViewsInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];

export const LEADS_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.LeadsInfluencedReportTab.Leads,
    label: 'measurementStudio.features.advertisingInsights.leads',
    type: 'report'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.LeadsInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.LeadsInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];

export const CAMPAIGN_RESPONSES_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.CampaignResponsesInfluencedReportTab.CampaignResponses,
    label: 'measurementStudio.features.advertisingInsights.campaignResponses',
    type: 'report'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.CampaignResponsesInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.CampaignResponsesInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];

export const NEW_PIPELINE_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.NewPipelineInfluencedReportTab.Pipeline,
    label: 'measurementStudio.features.advertisingInsights.pipeline',
    type: 'report'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.NewPipelineInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.NewPipelineInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];

export const PIPELINE_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.PipelineInfluencedReportTab.Pipeline,
    label: 'measurementStudio.features.advertisingInsights.pipeline',
    type: 'report'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.PipelineInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.PipelineInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];

export const REVENUE_INFLUENCED_REPORT_TABS: IInsightsDetailsReportTab[] = [
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.RevenueInfluencedReportTab.Revenue,
    label: 'measurementStudio.features.advertisingInsights.revenue',
    type: 'report'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.AccountName.name
    ],
    key: Keys.RevenueInfluencedReportTab.Accounts,
    label: 'measurementStudio.features.advertisingInsights.accounts',
    type: 'account'
  },
  {
    filterProps: [
      INSIGHTS_DETAILS_COLUMN_DEFINITIONS.TacticName.name
    ],
    key: Keys.RevenueInfluencedReportTab.AdTactics,
    label: 'measurementStudio.features.advertisingInsights.adTactics',
    type: 'tactic'
  }
];
