import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsSidenavModule } from '@terminus-lib/ui-sidenav';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsButtonModule } from '@terminus-lib/ui-button';

import { AppMenuComponent } from './app-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TsSidenavModule,
    TsTooltipModule,
    TranslateModule,
    TsInputModule,
    TsButtonModule
  ],
  declarations: [
    AppMenuComponent
  ],
  exports: [
    AppMenuComponent
  ]
})
export class AppMenuModule {
}
