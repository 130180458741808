import { Action, ActionReducer } from '@ngrx/store';
import { demoConfigFeatureKey, DemoConfigState, initialState } from './demo-config.reducer';

export const getSavedDemoConfigState = (localStorageKey: string): DemoConfigState => JSON.parse(localStorage.getItem(localStorageKey));

export function demoConfigMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true; // after load/refresh…
  return function (state: S, action: A): S {
    // get the next state.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedDemoState = getSavedDemoConfigState('demoConfigState');
      return {
        ...nextState,
        [demoConfigFeatureKey]: savedDemoState ? savedDemoState : initialState,
      };
    }

    return nextState;
  };
}
