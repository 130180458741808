import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { TsAmChartsService } from '@terminus-lib/ui-chart';
import type { GaugeChart, PieChart, XYChart } from '@amcharts/amcharts4/charts';
import cloneDeep from 'lodash.clonedeep';

import { VisualizationTypes } from '@shared/enums';
import { IDashboardTile, ITileData, ITileGaugeData, ITileVisualizationConfig } from '@shared/interfaces';
import { ChartConfigService } from '../../services/chart-config/chart-config.service';

type Chart = XYChart | PieChart | GaugeChart;

@Component({
  selector: 'tsh-chart-visualization',
  templateUrl: './chart-visualization.component.html',
  styleUrls: ['./chart-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartVisualizationComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() public tile: IDashboardTile;
  @Input() public customSettings: ITileVisualizationConfig | null;
  @Input() public data: ITileData | ITileGaugeData | null;

  public chart: Chart;

  constructor(
    private amChartsService: TsAmChartsService,
    private chartConfigService: ChartConfigService,
    private zone: NgZone
  ) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // update chart data
    if (changes.data
      && !changes.data.isFirstChange()
      && changes.data.currentValue
      && this.chart
      && this.tile.settings.visualization !== VisualizationTypes.Gauge) {
      this.chart.data = (this.data as ITileData).items;
    }
  }

  ngOnDestroy(): void {
    // run the code outside angular zone
    // source - amcharts official doc
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    });
  }

  ngAfterViewInit(): void {
    // run the code outside angular zone
    // source - amcharts official doc
    this.zone.runOutsideAngular(() => {
      // dispose before creating in case if the chart wasn't disposed on destroy
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      const config = this.chartConfigService.getChartConfig(this.tile, this.data);
      if (config) {
        // need to clone deep because createFromConfig method mutates data inside itself and it leads
        // to memory leaks and broken charts
        const updatedConfig = this.customSettings?.chart
          ? {...config, ...this.customSettings.chart}
          : config;
        this.chart = this.amChartsService.amCharts.core.createFromConfig(
          cloneDeep(updatedConfig),
          `chart-${this.tile.id}`,
        ) as Chart;
      }
    });
  }
}
