import { createAction, props } from '@ngrx/store';
import { defineTypeEnum } from '@terminus-lib/fe-utilities';

import { OutputFormState, OutputMultipleFormState } from './store-form-in-state.interfaces';

export enum FormActions {
  STORE_FORM_STATE_ACTION = '[form storage] StoreFormData',
  STORE_MULTIPLE_FORMS_STATE_ACTION = '[form storage] StoreFormData Multiple',
}

defineTypeEnum(FormActions);

const storeFormStateAction = <T, C>() => createAction(
  FormActions.STORE_FORM_STATE_ACTION,
  props<{
    formName: T,
    formData: OutputFormState<C>,
    version: number
  }>()
);

const storeMultipleFormStateAction = <T, C>() => createAction(
  FormActions.STORE_MULTIPLE_FORMS_STATE_ACTION,
  props<{
    formName: T,
    formData: OutputMultipleFormState<C>,
    version: number
  }>()
);

export const formActions = {
  storeFormStateAction,
  storeMultipleFormStateAction
}
