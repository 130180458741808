import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { getHttpParams } from '@util/http';
import { IFlatStageProgression, IFlatStageProgressionDetail, IStagesSnapshotFilters } from '../interfaces';

export enum StagesSnapshotURLs {
  FlatProgression = '/api/v1/flat_stage_progression',
  FlatProgressionDetail = '/api/v1/flat_stage_progression_detail',
}

@Injectable({providedIn: 'root'})
export class StagesSnapshotSource extends HttpClientBase {

  getFlatStageProgression$(params: IStagesSnapshotFilters): Observable<IFlatStageProgression[]> {
    const endpoint = this.endpoint(StagesSnapshotURLs.FlatProgression);

    return this.httpClient.get<IFlatStageProgression[]>(endpoint, {
      params: getHttpParams({...params})
    });
  }

  getFlatStageProgressionDetail$(params: IStagesSnapshotFilters): Observable<IFlatStageProgressionDetail[]> {
    const endpoint = this.endpoint(StagesSnapshotURLs.FlatProgressionDetail);

    return this.httpClient.get<IFlatStageProgressionDetail[]>(endpoint, {
      params: getHttpParams({...params})
    });
  }
}
