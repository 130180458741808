import { Injectable } from '@angular/core';
import { getHttpParams } from '@util/http';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAppliedGlobalFiltersAsParams, IExportToken } from '@shared/interfaces';
import { IOpportunityRequest } from '@measurement-studio/interfaces';
import { IOpportunityReport, IOpportunityVisual } from '../interfaces';
import { convertOpportunityItemFieldName } from '../utils/opportunities.utils';

export enum OpportunitiesSourceURLs {
  GetOpportunityReport = '/api/v1/oppty_perf',
  GetOpportunityVisual = '/v2/oppty_list_visual',
  DownloadListDetailsData = '/export/opptyPerformance'
}

@Injectable({providedIn: 'root'})
export class OpportunitiesSource extends HttpClientBase {
  getOpportunityReport$(params: IOpportunityRequest): Observable<IOpportunityReport> {
    const endpoint = this.endpoint(OpportunitiesSourceURLs.GetOpportunityReport);

    // NOTE: convert camel case field's name to snake case
    return this.httpClient.get<IOpportunityReport>(endpoint, {
      params: getHttpParams({
        ...params,
        fld: this.convertOpportunityItemFieldName(params.fld)
      }),
    });
  }

  getOpportunityVisual$(params: IOpportunityRequest): Observable<IOpportunityVisual> {
    const endpoint = this.endpoint(OpportunitiesSourceURLs.GetOpportunityVisual);

    // NOTE: convert camel case field's name to snake case
    return this.httpClient.get<IOpportunityVisual>(endpoint, {
      params: getHttpParams({
        ...params,
        fld: this.convertOpportunityItemFieldName(params.fld)
      }),
    });
  }

  downloadCSV$(filters: IOpportunityRequest, globalFilters: IAppliedGlobalFiltersAsParams, token: IExportToken): Observable<string> {
    const endpoint = this.endpoint(OpportunitiesSourceURLs.DownloadListDetailsData);
    // we do not need to send in ps and pg
    return this.httpClient.get(endpoint, {
      params: getHttpParams({
        ...filters,
        ...globalFilters,
        ...token,
        fld: this.convertOpportunityItemFieldName(filters.fld)
      }),
      responseType: 'text'
    });
  }

  private convertOpportunityItemFieldName(field: string): string {
    return field ? convertOpportunityItemFieldName(field) : null;
  }
}
