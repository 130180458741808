import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';

import { catalogsActions } from './catalogs.actions';
import * as selectors from './catalogs.selectors';
import * as reducer from './catalogs.reducer';
import { notificationMessagesActions } from '@notification-messages';
import { userMessageFactory } from '@shared/interfaces';
import { IWebActivitiesTypes, IWfStage } from '../interfaces';
import { CatalogsService } from '../service/catalogs.service';

@Injectable()
export class CatalogsEffects {
  constructor(private catalogsService: CatalogsService,
              private store: Store<reducer.CatalogsState>,
              private actions$: Actions) {
  }

  public loadWebActivitiesTypes$ = createEffect(() => this.actions$.pipe(
    ofType(catalogsActions.loadWebActivitiesTypes),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getWebActivitiesTypes)))
    )),
    filter(([_, types]: [TypedAction<string>, IWebActivitiesTypes]) => !types),
    mergeMap(() => {
      return this.catalogsService.getWebActivitiesTypes$().pipe(
        map((data: IWebActivitiesTypes) => catalogsActions.loadWebActivitiesTypesSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'feature.campaignAnalytics.errors.loadWebActivitiesTypes';
          return of(catalogsActions.loadWebActivitiesTypesFailure({message}));
        }),
      );
    })
  ));

  public loadStages$ = createEffect(() => this.actions$.pipe(
    ofType(catalogsActions.loadWfStages),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getIsWfStagesLoaded)))
    )),
    // load stages only if there is no this data in the store
    filter(([_, stagesLoaded]: [TypedAction<string>, boolean]) => !stagesLoaded),
    mergeMap(() => this.catalogsService.getStages$().pipe(
      map((stages: IWfStage[]) => catalogsActions.loadWfStagesSuccess({data: stages})),
      catchError((error: HttpErrorResponse) => {
        const message = error?.message || 'ngrx.wfStages.loadError';
        return of(catalogsActions.loadWfStagesFailure({message}));
      }))
    )
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      catalogsActions.loadWebActivitiesTypesFailure,
      catalogsActions.loadWfStagesFailure,
    ),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))));
}
