export enum GlobalFiltersKeys {
  AccountList = 'accountList',
  Account = 'account',
  Campaign = 'campaign',
  CampaignMember = 'campaignMember',
  Lead = 'lead',
  Opportunity = 'opportunity',
  TerminusAccount = 'terminusAccount',
  Segments = 'segments'
}

/* Global Filter Headers */
export enum GlobalFiltersHeader {
  AccountList = 'Account List',
  Account = 'Account',
  Campaign = 'Campaign',
  CampaignMember = 'Campaign Member',
  Lead = 'Lead',
  Opportunity = 'Opportunity',
  TerminusAccount = 'Terminus Account',
  People = 'People',
  Segments = 'Segments'
}

/* Global Filters Api Key */
export enum GlobalFiltersApiKeys {
  Account = 'a',
  Campaign = 'c',
  CampaignMember = 'cm',
  Lead = 'l',
  Opportunity = 'o',
  TerminusAccount = 'ta',
  Segments = 'sg'
}

export enum TableIdentifier {
  Account = 'account',
  TerminusAccount = 'terminus_account',
  AccountList = 'account_list',
  Opportunity = 'opportunity',
  Lead = 'lead',
  Campaign = 'campaign',
  CampaignMember = 'campaign_member',
  Segments = 'segment'
}

