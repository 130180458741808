import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { IDateCohort, IDateCohortGroup, ILabelValue } from '@shared/interfaces';
import { StageInfluenceColumnType } from '../../enums';
import { IStageInfluenceFilters } from '../../interfaces';

@Component({
  selector: 'ms-stage-influence-filters',
  templateUrl: './stage-influence-filters.component.html',
  styleUrls: ['./stage-influence-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StageInfluenceFiltersComponent implements OnChanges {
  @Input() public filters: IStageInfluenceFilters;
  @Input() public dateCohortOptions: IDateCohortGroup[];
  @Input() public selectedDateCohort: IDateCohort;
  @Input() public isLoading: boolean;
  @Input() public modelTypeOptions: ILabelValue[];
  @Output() public applyFilters = new EventEmitter<IStageInfluenceFilters>();
  @Output() public changeSelectedDateCohort = new EventEmitter<IDateCohort>();
  @Output() public searchCampaign = new EventEmitter<string>();

  public filterForm = this.formBuilder.group({
    influenceType: [null, Validators.required],
    dataSet: [null, Validators.required],
    model: [null, Validators.required],
    columnType: [null, Validators.required],
  });

  public datasetTypeOptions = new DataSetOptions().getDataSetFullOptions();
  public columnTypeOptions: ILabelValue[] = [
    {label: 'measurementStudio.features.stageInfluence.filters.leadStages', value: StageInfluenceColumnType.leadStages},
    {label: 'measurementStudio.features.stageInfluence.filters.opptyStages', value: StageInfluenceColumnType.opptyStages}
  ];

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters?.currentValue) {
      this.filterForm.patchValue({
        influenceType: changes.filters.currentValue.influenceType,
        dataSet: changes.filters.currentValue.dataSet,
        model: changes.filters.currentValue.model,
        columnType: changes.filters.currentValue.columnType,
      });
    }
  }

  applyDateCohort(dateCohort?: IDateCohort): void {
    this.applyFilters.emit({
      ...this.filters,
      cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
      endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
      startDate: dateCohort?.startDate || this.selectedDateCohort.startDate,
    });
  }

  changeDateCohort(dateCohort: IDateCohort): void {
    this.changeSelectedDateCohort.emit(dateCohort);
  }

  apply(): void {
    this.applyFilters.emit({
      ...this.filters,
      ...this.filterForm.value,
    } as IStageInfluenceFilters);
  }
}
