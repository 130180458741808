import { createAction, props } from '@ngrx/store';
import {
  IAccountFolder,
  IAccountsFolderResponse,
  IUpdateAccountsFolderData,
  IUpdateAccountsFolderParams,
  ICreateAccountsFolderParams,
  IAccountsNotification,
  ICreateAccountsListParams
} from '@shared/interfaces';
import { ReloadDataType } from '@shared/enums';

export enum AccountsActionTypes {
  LoadFolders = '[Account List] Load Folders',
  LoadFoldersSuccess = '[Account List] Load Folders success',
  LoadFoldersFailure = '[Account List] Load Folders Failure',

  AddToList = '[Account List] Add To List',
  AddToListSuccess = '[Account List] Add To List Success',
  AddToListFailure = '[Account List] Add To List Failure',
  AddNotification = '[Account List] Add Notification',

  LoadFolderUsers = '[Account List] Load Folder Users',
  LoadFolderUsersSuccess = '[Account List] Load Folder Users Success',
  LoadFolderUsersFailure = '[Account List] Load Folder Users Failure',

  UpdateFolder = '[Account List] Update Folder',
  UpdateFolderSuccess = '[Account List] Update Folder Success',
  UpdateFolderFailure = '[Account List] Update Folder Failure',

  CreateFolder = '[Account List] Create Folder',
  CreateFolderSuccess = '[Account List] Create Folder Success',
  CreateFolderFailure = '[Account List] Create Folder Failure',

  CreateList = '[Account List] Create List',
  CreateListSuccess = '[Account List] Create List Success',
  CreateListFailure = '[Account List] Create List Failure',
}

const loadFolders = createAction(
  AccountsActionTypes.LoadFolders,
)

const loadFoldersSuccess = createAction(
  AccountsActionTypes.LoadFoldersSuccess,
  props<{ payload: IAccountFolder[] }>()
)

const loadFoldersFailure = createAction(
  AccountsActionTypes.LoadFoldersFailure,
  props<{ payload: string }>()
)

const addToList = createAction(
  AccountsActionTypes.AddToList,
  props<{ payload: Record<string, string | number> }>()
)

const addToListSuccess = createAction(
  AccountsActionTypes.AddToListSuccess,
)

const addToListFailure = createAction(
  AccountsActionTypes.AddToListFailure,
  props<{ payload: string }>()
)

const loadFolderUsers = createAction(
  AccountsActionTypes.LoadFolderUsers,
  props<{ payload: IAccountsFolderResponse }>()
)

const loadFolderUsersSuccess = createAction(
  AccountsActionTypes.LoadFolderUsersSuccess,
  props<{ payload: IUpdateAccountsFolderData }>()
)

const loadFolderUsersFailure = createAction(
  AccountsActionTypes.LoadFolderUsersFailure,
  props<{ payload: string }>()
)

const updateFolder = createAction(
  AccountsActionTypes.UpdateFolder,
  props<{ payload: IUpdateAccountsFolderParams }>()
)

const updateFolderSuccess = createAction(AccountsActionTypes.UpdateFolderSuccess)

const updateFolderFailure = createAction(
  AccountsActionTypes.UpdateFolderFailure,
  props<{ payload: string }>()
)

const createFolder = createAction(
  AccountsActionTypes.CreateFolder,
  props<{ payload: ICreateAccountsFolderParams }>()
)

const createFolderSuccess = createAction(AccountsActionTypes.CreateFolderSuccess)

const createFolderFailure = createAction(
  AccountsActionTypes.CreateFolderFailure,
  props<{ payload: string }>()
)

const createList = createAction(
  AccountsActionTypes.CreateList,
  props<{ payload: ICreateAccountsListParams }>()
)

const createListSuccess = createAction(
  AccountsActionTypes.CreateListSuccess,
  props<{ payload: ReloadDataType }>()
)

const createListFailure = createAction(
  AccountsActionTypes.CreateListFailure,
  props<{ payload: string }>()
)

const addNotification = createAction(
  AccountsActionTypes.AddNotification,
  props<{ payload: IAccountsNotification }>()
)

export const listPopupActions = {
  loadFolders,
  loadFoldersSuccess,
  addToList,
  addToListSuccess,
  addToListFailure,
  loadFoldersFailure,
  loadFolderUsers,
  loadFolderUsersSuccess,
  loadFolderUsersFailure,
  updateFolder,
  updateFolderSuccess,
  updateFolderFailure,
  createFolder,
  createFolderSuccess,
  createFolderFailure,
  createList,
  createListSuccess,
  createListFailure,
  addNotification
}

