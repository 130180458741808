<div class="hub-modal-header">
  <h3 class="hub-modal-title">{{ data.folder ? 'Edit' : 'Create' }} Folder</h3>
</div>
<div class="hub-modal-body">
  <ts-input
    label="Name"
    name="folderName"
    [isRequired]="true"
    [isDisabled]="isDisabled"
    [formControl]="form.get('folderName')">
  </ts-input>
  <span class="folder-label">{{ 'shared.folderDialog.folderAccess' | translate }}</span>
  <ts-radio-group
    [isDisabled]="isDisabled"
    ariaLabel="Choose folder permission"
    id="folderPermission"
    name="folderPermission"
    theme="default"
    [formControl]="form.get('folderPermission')">
    <ng-container *ngFor="let option of permissionOptions">
      <ts-radio-button [value]="option">
        {{ option }}
      </ts-radio-button>
    </ng-container>
  </ts-radio-group>
</div>
<div class="hub-modal-footer "
     [ngClass]="{
     'hub-modal-footer-between': !!data.folder,
     'hub-modal-footer-end': !data.folder
     }">
  <ts-button
    *ngIf="data.folder"
    actionName="Delete"
    buttonType="button"
    theme="warning"
    class="delete"
    [isDisabled]="isDisabled"
    (clicked)="deleteFolder()">
    {{ 'shared.folderDialog.deleteThisFolder' | translate }}
  </ts-button>
  <div>
    <ts-button
      actionName="Cancel"
      buttonType="button"
      theme="secondary"
      class="cancel-button"
      [isDisabled]="isDisabled"
      (clicked)="cancel()">
      {{ 'shared.common.cancel' | translate }}
    </ts-button>
    <ts-button
      [isDisabled]="form.invalid || isDisabled"
      actionName="Save"
      buttonType="submit"
      theme="default"
      (clicked)="saveFolder()">
      {{ 'shared.common.save' | translate }}
    </ts-button>
  </div>
</div>
