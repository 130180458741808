import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import {
  IMarketingInfluenceDeal,
  IMarketingInfluenceOppty,
  IMarketingInfluenceReport,
  IMarketingInfluenceRequest,
  IMarketingInfluenceResponse,
  MarketingInfluenceUsedParams
} from '../interfaces';
import { MarketingInfluenceService } from '../services/marketing-influence.service';
import { modifyMarketingResponse } from '../utils/marketing-influence.utils';
import { marketingInfluenceActions } from './marketing-influence.actions';
import * as selectors from './marketing-influence.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as OrgConfigStore from '@org-config';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { isFiltersChanged } from '@util/helpers';
import { ColumnsSaverService, LocalStorageColumnsKey as Key } from '@shared/data-access/columns-saver';

@Injectable()
export class MarketingInfluenceEffects {
  constructor(private store: Store<unknown>,
              private marketingService: MarketingInfluenceService,
              private columnsSaverService: ColumnsSaverService,
              private actions$: Actions,
              private downloadService: DownloadCsvService,
              private translate: TranslateService) {
  }

  public downloadCsv$ = createEffect(() => this.actions$.pipe(
    ofType(marketingInfluenceActions.downloadCsv),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getMarketingInfluenceReport)),
      this.store.pipe(select(selectors.getMarketingInfluenceReportTotals)),
      this.store.pipe(
        select(selectors.getAllMarketingInfluenceColumns),
        mergeMap(columns => combineLatest(
          columns.map(column => this.translate.get(column.displayName).pipe(
            map(displayName => ({
              ...column,
              displayName,
            }))
          ))
        ))
      ),
      this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting))
    ]),
    map(([_, report, totals, columns, currency]) => {
      const periodName = columns.find(column => column.name === 'month')?.displayName.toLocaleLowerCase() || 'month';
      const fileName = `Marketing Influence-${periodName}-actual`;
      this.downloadService.downloadCsv(fileName, report, columns, currency, totals);

      return marketingInfluenceActions.downloadCsvSuccess();
    })
  ));


  public getMarketingInfluenceReport$ = createEffect(() => this.actions$.pipe(
    ofType(marketingInfluenceActions.getMarketingInfluenceReportData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getMarketingInfluenceFilters)),
      this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
    ]),
    switchMap(([_, filters, globalFiltersParams]) => {
      const params = { ...filters, ...globalFiltersParams }as MarketingInfluenceUsedParams;
      return this.marketingService.getMarketingInfluenceReportData$(params).pipe(
        map((response: IMarketingInfluenceResponse) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore NOTE: Use ts-ignore because of union type error
          return response && response.map((item: IMarketingInfluenceDeal | IMarketingInfluenceOppty) => {
            return modifyMarketingResponse(filters, item);
          });
        }),
        mergeMap((data: IMarketingInfluenceReport[]) => [
          marketingInfluenceActions.getMarketingInfluenceReportDataSuccess({ data }),
          marketingInfluenceActions.setUsedQueryParams({ params })
        ]),
        catchError((error: HttpErrorResponse) => {
          const message$ = error.message
            ? of(error.message)
            : this.translate.get('measurementStudio.features.marketingInfluence.errors.getMarketingInfluenceReport');

          return message$.pipe(
            map(message => marketingInfluenceActions.getMarketingInfluenceReportDataFailure({ message }))
          );
        })
      );
    })
  ));


  public onFilterChange$ = createEffect(() => this.actions$.pipe(
    ofType(marketingInfluenceActions.setMarketingInfluenceFilter),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getMarketingUsedQueryParams)),
      this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
    ]),
    filter(([action, usedQueryParams, globalFilters]) => isFiltersChanged(usedQueryParams, action.filters, globalFilters)),
    map(([action]) => action.filters),
    mergeMap((filters: IMarketingInfluenceRequest) => {
      // get and set columns based on filters
      const filteredColumns = this.marketingService.getReportColumns(filters);
      const columns = this.columnsSaverService.getColumns(Key.MarketingInfluence, filteredColumns);
      return [
        marketingInfluenceActions.setColumns({ columns }),
        marketingInfluenceActions.getMarketingInfluenceReportData()
      ];
    })
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(marketingInfluenceActions.toggleColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleMarketingInfluenceColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(Key.MarketingInfluence, visibleColumns);
    })
  ), {dispatch: false});
}
