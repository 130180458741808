import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IFlatStageProgression } from '../../interfaces';

@Component({
  selector: 'ms-stages-snapshot-tabs',
  templateUrl: './stages-snapshot-tabs.component.html',
  styleUrls: ['./stages-snapshot-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StagesSnapshotTabsComponent {
  @Input() public tabs: IFlatStageProgression[];
  @Input() public activeTab: string;
  @Input() public activeCount: number;
  @Output() public selectTab = new EventEmitter<string>();

  changeActiveTab(tab: IFlatStageProgression): void {
    if (tab.name !== this.activeTab) {
      this.selectTab.emit(tab.name);
    }
  }
}
