import { Component, Inject } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { IGlobalSearchState } from '../state/global-search.reducer';
import { globalSearchActions } from '../state/global-search.actions';
import * as globalSearchSelectors from '../state/global-search.selectors';
import { GlobalSearchTypeEnum } from '../enums';
import { EnvService } from '@shared/environment';
import { APP_ROUTES } from '@app-routes';
import { IRouteItem } from '@shared/interfaces';

@Component({
  selector: 'tsh-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {
  public isLoading$ = this.store.pipe(select(globalSearchSelectors.getIsLoading));
  public data$ = this.store.pipe(select(globalSearchSelectors.getMarkedData));
  public searchQuery$ = this.store.pipe(select(globalSearchSelectors.getSearchQuery));
  public viewData$ = this.store.pipe(select(globalSearchSelectors.getViewData));
  public options$ = this.store.pipe(select(globalSearchSelectors.getOptions));
  public routeData$ = this.store.pipe(select(globalSearchSelectors.getRouteData(this.applicationRoutes, this.envService.getEnv())));

  constructor(
    private store: Store<IGlobalSearchState>,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[],
    private envService: EnvService,
  ) {}

  onSearchSubmit(searchQuery: string): void {
    this.store.dispatch(globalSearchActions.loadSearchData({ searchQuery }))
  }

  onViewChanges(data: GlobalSearchTypeEnum[]): void {
    this.store.dispatch(globalSearchActions.setViewData({ data }));
  }
}
