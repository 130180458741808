<div class="container">
  <span class="label">{{ label | translate }}</span>
  <ts-menu [menuItemsTemplate]="options"
           class="ts-menu-as-selector">
    <span class="result">
        {{((selectedOption$ | async)?.label || 'shared.ui.selector.none') | translate}}
    </span>
  </ts-menu>
</div>

<ng-template #options>
  <div class="search-container">
    <ts-input #searchInput
              type="text"
              name="search"
              [isDisabled]="isLoading"
              (click)="$event.stopPropagation()"
              (keyup.enter)="search(searchInput.value)"
              [placeholder]="'shared.ui.selector.placeholder' | translate"
              [isSmall]="true">
    </ts-input>
    <i class="fas fa-search"
       [ngClass]="{'loading': isLoading}"
       (click)="searchIcon($event, searchInput.value)">
    </i>
    <div *ngIf="isLoading"
         class="loader">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
  <cdk-virtual-scroll-viewport class="options-container"
                               itemSize="31"
                               minBufferPx="200"
                               maxBufferPx="400">
    <div *cdkVirtualFor="let option of options$ | async; let i = index"
         matRipple
         class="option"
         [ngClass]="{'active': option.value === value}"
         (click)="select(option)">
      {{option.label | translate}}
    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>
