import { createAction, props } from '@ngrx/store';

import { IAccountFolder } from '@shared/interfaces';

export const getAccountLists = createAction('[AccountLists] Get Account Lists Folders');
export const loadAccountLists = createAction('[AccountLists] Load Account Lists Folders');

export const loadAccountListsSuccess = createAction(
  '[AccountLists] Load Account Lists Folders Success',
  props<{ data: IAccountFolder[] }>()
);

export const loadAccountListsFailure = createAction(
  '[AccountLists] Load Account Lists Folders Failure',
  props<{ error: string }>()
);
