/* istanbul ignore file */
import { HttpClientModule } from '@angular/common/http';

import { MetaReducer, RuntimeChecks, StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { sharedEffects, sharedReducers } from '@shared-store';
import { environment } from '@measurement-studio/environments/environment';
import * as TagsStore from '@measurement/data-access/tags';
import { demoConfigMetaReducer } from '@shared/data-access/demo-config-state';

export const metaReducers: MetaReducer[] = [
  demoConfigMetaReducer
];

// NOTE: set strictStateImmutability and strictActionImmutability to false because
// Angular 9 and ngrx return error during creating dynamic component
// need to be returned back to !environment.production after resolving the problem
export const runtimeChecks: RuntimeChecks = {
  strictStateImmutability: false,
  strictActionImmutability: false,
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictActionWithinNgZone: false,
};

export const STORE_IMPORTS = [
  HttpClientModule,
  StoreRouterConnectingModule.forRoot(),
  StoreModule.forRoot({
    ...sharedReducers,
    tags: TagsStore.reducer,
  }, {
    runtimeChecks, metaReducers
  }),
  EffectsModule.forRoot([
    ...sharedEffects,
    TagsStore.TagsEffects,
  ]),
  StoreDevtoolsModule.instrument({
    name: 'Measurement Studio',
    maxAge: 50, // Retains last 50 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
];
