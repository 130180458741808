import { Inject, Injectable } from '@angular/core';

import { Environment } from './environment.interface';
import { ENVIRONMENT } from './environment.token';

/* USE THIS ENV SERVICE GOING FORWARD 04/13/22 !!! */
@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private readonly envJsonResponse: Environment;

  constructor(@Inject(ENVIRONMENT) environment: Environment) {
    this.envJsonResponse = environment;
  }

  getEnv(): Environment {
    return {...this.envJsonResponse};
  }
}
