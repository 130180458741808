<ts-checkbox
  class="qa-select-option-checkbox"
  *ngIf="allowMultiple && selectComponent"
  tabIndex="-1"
  [isDisabled]="isDisabled"
  [isChecked]="selected"
>
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
</ts-checkbox>

<ng-container *ngIf="!allowMultiple || !selectComponent">
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
</ng-container>


<div
  class="mat-option-ripple"
  mat-ripple
  [matRippleTrigger]="hostElement"
  [matRippleDisabled]="isDisabled"
></div>


<ng-template #contentTemplate>
  <span class="ts-option-text qa-option-text">
    <ng-content *ngIf="!optionTemplate">
    </ng-content>

    <ng-container
      *ngIf="optionTemplate"
      [ngTemplateOutlet]="optionTemplate"
      [ngTemplateOutletContext]="{$implicit: option}"
    ></ng-container>
  </span>
</ng-template>
