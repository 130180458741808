import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IKpiResponse } from '../../kpi-trending/interfaces/kpi-response.interface';
import { IKpiDetailsResponse } from '../../trending-details/interfaces/kpi-details-response.interface';
import { IKpiSource } from './kpi.source.interface';
import { getHttpParams } from '@util/http';
import { ITrendingDetailsCSVRequestParams, ITrendingDetailsRequestParams } from '../../trending-details/interfaces/trending-details-filters.interface';
import { IKpiTrendingFilters } from '../../kpi-trending/interfaces/kpi-trending-filters.interface';

export enum KpiSourceURLs {
  GetKpiData = '/v1/get_scorecard_kpi_trends',
  GetKpiDetails = '/v1/get_scorecard_kpi_trend_details',
  DownloadKpiDetails = '/export/get_kpi_details',
}

@Injectable({providedIn: 'root'})
export class KpiSource extends HttpClientBase implements IKpiSource {

  getKpiData$(params: IKpiTrendingFilters): Observable<IKpiResponse> {
    const endpoint = this.endpoint(KpiSourceURLs.GetKpiData);

    return this.httpClient.post<IKpiResponse>(endpoint, params);
  }

  getKpiDetails$(params: ITrendingDetailsRequestParams): Observable<IKpiDetailsResponse> {
    const endpoint = this.endpoint(KpiSourceURLs.GetKpiDetails);

    return this.httpClient.post<IKpiDetailsResponse>(endpoint, params);
  }

  downloadKpiDetails$(params: ITrendingDetailsCSVRequestParams): Observable<string> {
    const endpoint = this.endpoint(KpiSourceURLs.DownloadKpiDetails);
    const urlParams = this.getKpiDetailsParams(params);

    return this.httpClient.get(endpoint, {
      params: getHttpParams(urlParams),
      responseType: 'text'
    });
  }

  private getKpiDetailsParams(params: ITrendingDetailsCSVRequestParams) {
    return {
      scorecardAggregateIds: params.scorecardAggregateIds,
      audience: params.audience,
      trendInterval: params.trendInterval.descriptor,
      type: params.type,
      interval: params.interval,
      progression: params.progression,
      trend: params.trend,
      group: params.group,
      rollingTime: params.rollingTime,
      firmographic: params.firmographic,
      segment: params.segment.descriptor,
      cohort: params.cohort.cohort,
      startDate: params.cohort.startDate,
      endDate: params.cohort.endDate,
      context: params.sorter.context,
      ...params.exportToken,
      ...params.globalFilters
    };
  }
}
