<form *ngIf="form" [formGroup]="form" class="tile-settings-form">
  <div class="title">{{'shared.dashboards.dialog.tile.basic' | translate}}</div>

  <div class="label">{{'shared.dashboards.dialog.tile.nameLabel' | translate}}</div>
  <ts-input *ngIf="form.get(fields.Name) as nameControl"
            data-cy="tile-dialog-form-name"
            type="text"
            [formControl]="nameControl"
            [hint]="getInputHint
                | memoize: nameControl.hasError('maxlength')
                | translate: {max: MAX_NAME_LENGTH, current: nameControl.value?.length || 0}">
  </ts-input>

  <div class="label">{{'shared.dashboards.dialog.tile.cohortLabel' | translate}}</div>
  <tsh-date-cohort *ngIf="form.get([fields.Settings, fields.Cohort]) as cohort"
                   data-cy="tile-dialog-form-date-cohort"
                   (apply)="applyDateCohort($event)"
                   (dateRangeFilterChange)="changeDateCohort($event)"
                   [selectedDateRange]="selectedCohort"
                   [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>

  <tsh-tile-settings-form-controls *ngIf="settingsFilters"
                                   [settingsFormGroup]="form.get(fields.Settings)"
                                   [filters]="settingsFilters">
  </tsh-tile-settings-form-controls>

  <hr class="report-divider">

  <tsh-visualization-types [control]="form.get([fields.Settings, fields.Visualization])"
                           data-cy="tile-dialog-form-visualization-types"
                           [tileType]="form.get(fields.Type).value">
  </tsh-visualization-types>

  <tsh-visualization-options [settingsFormGroup]="form.get(fields.Settings)"
                             [isHighlightedErrors]="isHighlightedErrors"
                             [selectedVisualization]="form.get([fields.Settings, fields.Visualization])?.value">
  </tsh-visualization-options>

  <hr class="report-divider">

  <tsh-tile-global-filters *ngIf="showGlobalFiltersSection"
                           data-cy="tile-dialog-form-global-filters"
                           [appliedFiltersNumber]="appliedFiltersNumber"
                           [allSelectedFilters]="allSelectFilters"
                           [savedGlobalFilters]="savedGlobalFilters"
                           [selectedGlobalFilters]="selectedGlobalFilters"
                           [globalFilters]="globalFilters"
                           [globalFiltersSearchState]="globalFiltersSearchState"
                           [accountListsSearchState]="accountListsSearchState"
                           [accountListFolders]="accountListFolders"
                           [globalFiltersState]="globalFiltersState"
                           [accountListFoldersState]="accountListFoldersState"
                           (toggleFolderSelect)="emitToggleFolderSelect($event)"
                           (folderSelectAll)="folderSelectAllItems($event)"
                           (toggleFolderItemSelect)="emitToggleFolderItemSelect($event)"
                           (applySavedGlobalFilter)="emitApplySavedGlobalFilter($event)"
                           (removeSelectedFolder)="emitRemoveSelectedFolder($event)"
                           [field]="form.get([fields.Settings, fields.GlobalFilters])"
                           (search)="emitSearchGlobalFilters($event)">
  </tsh-tile-global-filters>
</form>
