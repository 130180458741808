import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { TableButtonsPanelModule } from '@ui/components/table-buttons-panel';
import { SearchByPropertyModule } from '@ui/components/search-by-property';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { TablePanelComponent } from './table-panel.component';

@NgModule({
  imports: [
    CommonModule,
    TableButtonsPanelModule,
    SearchByPropertyModule,
    TranslateModule,
    TypedDataModule,
  ],
  declarations: [TablePanelComponent],
  exports: [TablePanelComponent]
})
export class TablePanelModule {
}
