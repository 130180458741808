import { DataTypeEnum, SortDirections } from '@shared/enums';
import { campaignsColumns, campaignTypeColumns } from '../data/stage-influence.columns';
import { IDateCohort, ILabelValue, IReportColumn, IReportTab } from '@shared/interfaces';
import { stageInfluenceActions } from './stage-influence.actions';
import { updateColumnsVisibility } from '@util/store';
import { IStageInfluenceFilters, IStageInfluenceResponse, IStageInfluenceTableOptions } from '../interfaces';
import { StageInfluenceTabs } from '../enums';
import { Action, createReducer, on } from '@ngrx/store';
import { unescapeColumnName } from '../helpers/column-name.helper';

export const allCampaignTypeOption: ILabelValue = {
  label: 'shared.ui.reportCheckboxSelector.all',
  value: '',
};

export const STAGE_INFLUENCE_KEY = 'campaignStageInfluence';

export const defaultTableOptions = {
  sorter: {
    sortDirection: SortDirections.Asc,
    sortField: 'name'
  },
  pager: {
    pageNum: 1,
    pageSize: 10,
  },
  searchParam: '',
};

export interface StageInfluenceState {
  isLoading: boolean;
  isLoadingTotals: boolean;
  filters: IStageInfluenceFilters | null;
  campaignTableOptions: IStageInfluenceTableOptions | null;
  campaignTypeTableOptions: IStageInfluenceTableOptions | null;
  selectedDateCohort: IDateCohort | null;
  campaignColumns: IReportColumn[];
  campaignTypeColumns: IReportColumn[];
  campaign: IStageInfluenceResponse | null;
  campaignType: IStageInfluenceResponse | null;
  totalsCampaign: IStageInfluenceResponse | null;
  tabs: IReportTab[];
  selectedCampaignType: ILabelValue;
}

export const initialState: StageInfluenceState = {
  isLoading: false,
  isLoadingTotals: false,
  filters: null,
  campaignTableOptions: defaultTableOptions,
  campaignTypeTableOptions: defaultTableOptions,
  selectedDateCohort: null,
  campaignColumns: campaignsColumns,
  campaignTypeColumns: campaignTypeColumns,
  campaign: null,
  campaignType: null,
  totalsCampaign: null,
  tabs: [
    {
      key: StageInfluenceTabs.CampaignType,
      title: 'measurementStudio.features.stageInfluence.campaignType',
      link: 'campaign-type',
    },
    {
      key: StageInfluenceTabs.Campaign,
      title: 'measurementStudio.features.stageInfluence.campaign',
      link: 'campaign',
    },
  ],
  selectedCampaignType: allCampaignTypeOption,
};

const stageInfluenceReducer = createReducer(
  initialState,
  on(stageInfluenceActions.setFilters, (state, action) => ({
    ...state,
    filters: action.request,
  })),
  on(stageInfluenceActions.setSelectedDateCohort, (state, action) => ({
    ...state,
    selectedDateCohort: {
      cohort: action.cohort,
      name: action.name,
      startDate: action.startDate,
      endDate: action.endDate,
    }
  })),
  on(stageInfluenceActions.loadCampaignStageInfluenceData,
    stageInfluenceActions.loadCampaignTypeStageInfluenceData, (state) => ({
      ...state,
      isLoading: true,
    })),
  on(stageInfluenceActions.loadCampaignStageInfluenceDataSuccess, (state, action) => {
    const fields = Object.keys(action.data[state.filters.columnType][0] || {})
      .filter(name => name !== 'name' && name !== 'groupId');
    return {
      ...state,
      isLoading: false,
      campaign: action.data,
      campaignColumns: campaignsColumns.concat(fields.map(name => ({
        name: unescapeColumnName(name),
        width: 200,
        displayName: unescapeColumnName(name),
        dataType: DataTypeEnum.Number,
        startSorting: 'desc',
        visible: true,
        internalLink: null,
      })))
    };
  }),
  on(stageInfluenceActions.loadCampaignStageInfluenceDataFailure,
    stageInfluenceActions.loadCampaignTypeStageInfluenceDataFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
  on(stageInfluenceActions.loadCampaignTypeStageInfluenceDataSuccess, (state, action) => {
    const fields = Object.keys(action.data[state.filters.columnType][0] || {})
      .filter(name => name !== 'name' && name !== 'groupId');
    return {
      ...state,
      isLoading: false,
      campaignType: action.data,
      campaignTypeColumns: campaignTypeColumns.concat(fields.map(name => ({
        name: unescapeColumnName(name),
        width: 250,
        displayName: unescapeColumnName(name), // names are presented as string with wrapped \' and slice using for removing wrapped \'
        dataType: DataTypeEnum.Number,
        startSorting: 'desc',
        visible: true,
        internalLink: null,
      })))
    };
  }),
  on(stageInfluenceActions.loadCampaignStageInfluenceTotalsData, (state) => ({
    ...state,
    isLoadingTotals: true,
  })),
  on(stageInfluenceActions.loadCampaignStageInfluenceTotalsDataSuccess, (state, action) => ({
    ...state,
    isLoadingTotals: false,
    totalsCampaign: action.data,
  })),
  on(stageInfluenceActions.loadCampaignStageInfluenceTotalsDataFailure, (state) => ({
    ...state,
    isLoadingTotals: false,
  })),
  on(stageInfluenceActions.toggleCampaignColumnVisibility, (state, action) => ({
    ...state,
    campaignColumns: updateColumnsVisibility(state.campaignColumns, {name: action.name, isVisible: action.isVisible})
  })),
  on(stageInfluenceActions.toggleCampaignTypeColumnVisibility, (state, action) => ({
    ...state,
    campaignTypeColumns: updateColumnsVisibility(state.campaignTypeColumns, {name: action.name, isVisible: action.isVisible})
  })),
  on(stageInfluenceActions.setCampaignTableSortState, (state, action) => ({
    ...state,
    campaignTableOptions: {
      ...state.campaignTableOptions,
      sorter: {
        sortDirection: action.sortDirection,
        sortField: action.sortField
      },
    },
  })),
  on(stageInfluenceActions.setCampaignTypeTableSortState, (state, action) => ({
    ...state,
    campaignTypeTableOptions: {
      ...state.campaignTypeTableOptions,
      sorter: {
        sortDirection: action.sortDirection,
        sortField: action.sortField
      },
    },
  })),
  on(stageInfluenceActions.setCampaignTablePage, (state, action) => ({
    ...state,
    campaignTableOptions: {
      ...state.campaignTableOptions,
      pager: {
        pageSize: state.campaignTableOptions.pager.pageSize,
        pageNum: action.page,
      }
    },
  })),
  on(stageInfluenceActions.setCampaignTypeTablePage, (state, action) => ({
    ...state,
    campaignTypeTableOptions: {
      ...state.campaignTypeTableOptions,
      pager: {
        pageSize: state.campaignTypeTableOptions.pager.pageSize,
        pageNum: action.page,
      }
    },
  })),
  on(stageInfluenceActions.setCampaignTableSearch, (state, action) => ({
    ...state,
    campaignTableOptions: {
      ...state.campaignTableOptions,
      searchParam: action.search,
      pager: {
        pageSize: state.campaignTypeTableOptions.pager.pageSize,
        pageNum: 1,
      }
    },
  })),
  on(stageInfluenceActions.setCampaignTypeTableSearch, (state, action) => ({
    ...state,
    campaignTypeTableOptions: {
      ...state.campaignTypeTableOptions,
      searchParam: action.search,
      pager: {
        pageSize: state.campaignTypeTableOptions.pager.pageSize,
        pageNum: 1,
      }
    },
  })),
  on(stageInfluenceActions.setSelectedCampaignType, (state, action) => ({
    ...state,
    selectedCampaignType: {
      label: action.label,
      value: action.value,
    }
  })),
);

export function reducer(state, action: Action): StageInfluenceState {
  return stageInfluenceReducer(state, action);
}
