// will convert all parentheses to html entities
export function listEscapeParentheses(list: string[]): string[] {
  return list.map(item => normalize(item));
}

// will convert all parentheses in keys in html entities
export function objectKeysEscapeParentheses <T>(data: T[]): T[] {
  return data.map(item => {
    return Object.keys(item).reduce((obj, key) => {
      const normalizedKey = normalize(key);
      obj[normalizedKey] = item[key];
      return obj;
    }, {}) as T;
  });
}

// convert parentheses to html entities "test ( 1 )" will be converted to the "test &#40; 1 &#41;"
// chart tooltip it not working with parentheses correctly (https://terminus.atlassian.net/browse/PI-3324)
export function normalize(key: string): string {
  return key.replace(new RegExp(/\(/gm), '&#40;').replace(new RegExp(/\)/gm), '&#41;');
}
