import { Inject, Injectable } from '@angular/core';
import { storageProvider } from './local-storage.token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(storageProvider) private localStorage: Storage) {
  }

  setItem(key: string, value: string): void {
    try {
      this.localStorage.setItem(key, value);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  getItem(key: string): string | undefined {
    try {
      return this.localStorage.getItem(key) || undefined;
    } catch (e) {
      return undefined;
    }
  }

  removeItem(key: string): void {
    try {
      this.localStorage.removeItem(key);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }
}
