import { amChartsColor, palette } from './charts-colors.palette';
import { generateChartsPalette } from './helpers/generate-charts-palette.helpers';

/**
 * https://www.figma.com/file/xCYAu1KMvIh06yCEg1AUzQ/Terminus-Design-System?node-id=27448%3A93176
 */

export const designSystemPalette = {
  governor: palette.governor,
  pink: palette.darkPink,
  orange: palette.orange,
  apple: palette.apple,
  darkCyan: palette.darkCyan,
  lightViolette: palette.lightViolette,
  watermelon: palette.watermelon,
  lemon: palette.lemon,
  green: '#34A26A',
};

export const designSystemChartsPalette: Record<keyof typeof designSystemPalette, amChartsColor> = generateChartsPalette(designSystemPalette);
