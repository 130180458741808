import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { map, take, withLatestFrom } from 'rxjs/operators';

import * as selectors from '../state/stage-influence.selectors';
import {stageInfluenceActions} from '../state/stage-influence.actions';
import * as DateCohortsStore from '@date-cohorts';
import { IAppliedGlobalFiltersAsParams, IDateCohort } from '@shared/interfaces';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { InfluenceType, ModelType, ToDate } from '@shared/enums';
import { DataSetType } from '@measurement-studio/enums';
import * as OrgConfigStore from '@org-config';
import { StageInfluenceColumnType } from '../enums';
import { IStageInfluenceFilters } from '../interfaces';

@WithDestroy
@Component({
  selector: 'ms-stage-influence',
  templateUrl: './stage-influence.component.html',
  styleUrls: ['./stage-influence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StageInfluenceComponent implements OnInit {
  public isLoading$ = this.store.pipe(select(selectors.getStageInfluenceLoading));
  public filters$ = this.store.pipe(select(selectors.getStageInfluenceFilters));
  public dateCohortOptions$ = this.store.pipe(select(DateCohortsStore.getGroupedDateCohortOptions()));
  public selectedDateCohort$ = this.store.pipe(select(selectors.getStageInfluenceDateCohort));
  public modelTypeOptions$ = this.store.pipe(
    select(OrgConfigStore.getUserModels),
    map(filters => filters.filter(item => item.value === ModelType.Sourced || item.value === ModelType.Last))
  );
  public tabs$ = this.store.pipe(select(selectors.getTabs));

  public defaultQueryParams: IStageInfluenceFilters = {
    cohort: ToDate.QUARTER_TO_DATE,
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    columnType: StageInfluenceColumnType.leadStages,
    dataSet: DataSetType.MembershipActivity,
    tagIds: [],
    model: ModelType.Sourced,
    influenceType: InfluenceType.ANY,
  };

  constructor(private store: Store<unknown>,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(
        this.filters$.pipe(take(1)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1)),
      )
    ).subscribe(([params, filters, globalFilters]: [Params, IStageInfluenceFilters, IAppliedGlobalFiltersAsParams]) => {
      if (!Object.keys(params).length && !filters) {
        // if there is no any query params then use default params
        this.applyFilters({...this.defaultQueryParams, ...globalFilters});
        return;
      }

      if (!Object.keys(params).length && filters) {
        // if there is no any query params but filters in the store then use them
        this.applyFilters({...filters, ...globalFilters});
        return;
      }

      this.store.dispatch(stageInfluenceActions.getFilters({params}));
    });
  }

  applyFilters(newFilters: IStageInfluenceFilters): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newFilters,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  changeSelectedDateCohort(dateCohort: IDateCohort): void {
    this.store.dispatch(stageInfluenceActions.setSelectedDateCohort(dateCohort));
  }

}
