import { TsAmChartsToken } from '@terminus-lib/ui-chart';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/animated';

export function amChartsFactory(): TsAmChartsToken {
  return {
    core: am4core,
    charts: am4charts,
    themes: [am4themes_animated, am4themes_Terminus]
  };
}

export function amChartsDashboardFactory(): TsAmChartsToken {
  return {
    core: am4core,
    charts: am4charts,
    themes: [am4themes_animated, am4themes_Dashboard]
  };
}

export function amChartsAdexFactory(): TsAmChartsToken {
  return {
    core: am4core,
    charts: am4charts,
    maps: am4maps,
    themes: [am4themes_animated, am4themes_material],
  };
}

const TERMINUS_COLOR_THEME = [
  am4core.color('#4F5A9F'),
  am4core.color('#EB5488'),
  am4core.color('#FFA600'),
  am4core.color('#63AA4E'),
  am4core.color('#008989'),
  am4core.color('#8A59A7'),
  am4core.color('#FF6368'),
  am4core.color('#C7AE00'),
  am4core.color('#34A26A')
];

export function am4themes_Dashboard(target: unknown): void {
  // set color palette
  if (target instanceof am4core.ColorSet) {
    target.list = TERMINUS_COLOR_THEME;
  }

  if (target instanceof am4charts.PieSeries) {
    if (target.tooltip) {
      getDashboardTileTooltip(target.tooltip);
    }
  }

  if (target instanceof am4charts.ColumnSeries) {
    target.columns.template.column.cornerRadiusTopRight = 4;
    target.columns.template.column.cornerRadiusTopLeft = 4;
    target.columns.template.column.cornerRadiusBottomRight = 4;
    target.columns.template.column.cornerRadiusBottomLeft = 4;
    target.stroke = am4core.color('#ffffff');

    if (target.tooltip) {
      getDashboardTileTooltip(target.tooltip);
    }
  }

  if (target instanceof am4charts.LineSeries) {
    target.strokeWidth = 3;

    if (target.tooltip) {
      getDashboardTileTooltip(target.tooltip);
    }
  }

  if (target instanceof am4charts.Axis) {
    target.fontSize = 12;
    target.cursorTooltipEnabled = false;
  }

  if (target instanceof am4charts.AxisRenderer) {
    target.labels.template.fill = am4core.color('#637178');
    target.labels.template.fontSize = 12;
  }

  if (target instanceof am4charts.AxisRendererX) {
    target.grid.template.disabled = true;
    if (target.baseGrid) {
      target.baseGrid.disabled = true;
    }
    target.minGridDistance = 10;
  }

  if (target instanceof am4charts.AxisRendererY) {
    target.grid.template.strokeOpacity = 1;
    target.grid.template.stroke = am4core.color('#DDE1E3');
    target.grid.template.strokeWidth = 1;
    target.grid.template.strokeDasharray = '2,2';
    target.minGridDistance = 40;

    if (target.baseGrid) {
      target.baseGrid.disabled = true;
    }
  }

  if (target instanceof am4core.Tooltip) {
    target.fontSize = 12;
  }
}

export function am4themes_Terminus(target: unknown): void {
  // set color palette
  if (target instanceof am4core.ColorSet) {
    target.list = TERMINUS_COLOR_THEME;
  }

  if (target instanceof am4charts.Axis) {
    target.cursorTooltipEnabled = false;
  }

  // leave only y grid
  if (target instanceof am4charts.AxisRendererX) {
    target.grid.template.disabled = true;
  }

  // remove padding
  if (target instanceof am4charts.XYChart) {
    target.paddingLeft = 0;
    target.paddingRight = 0;
  }

  if (target instanceof am4charts.AxisRenderer) {
    target.labels.template.fill = am4core.color('#637178');
    target.fontSize = 11;
  }

  if (target instanceof am4charts.Legend) {
    target.paddingTop = 20;
    target.fontSize = 12;

    if (target.labels) {
      target.labels.template.fill = am4core.color('#637178');
      target.labels.template.paddingRight = 14;
      target.labels.template.paddingTop = 3;
    }

    if (target.position) {
      target.position = 'bottom';
    }

    if (target.markers && target.markers.template) {
      target.markers.template.width = 8;
      target.markers.template.height = 8;
    }
  }

  if (target instanceof am4charts.AxisRendererY) {
    target.grid.template.strokeOpacity = 1;
    target.grid.template.stroke = am4core.color('#F1F2F4');
    target.grid.template.strokeWidth = 1;
    // distance between label
    target.minGridDistance = 80;
    target.fontSize = 11;
    if (target.baseGrid) {
      target.baseGrid.disabled = true;
    }
  }

  // leave only x grid
  if (target instanceof am4charts.ColumnSeries) {
    target.columns.template.column.cornerRadiusTopRight = 4;
    target.columns.template.column.cornerRadiusTopLeft = 4;
    target.columns.template.column.cornerRadiusBottomRight = 4;
    target.columns.template.column.cornerRadiusBottomLeft = 4;

    if (target.tooltip) {
      target.tooltip.label.fill = am4core.color('#FFFFFF');
      target.tooltip.background.fill = am4core.color('#22272A');
      target.tooltip.label.padding(15, 15, 15, 15);
      target.tooltip.getFillFromObject = false;
      target.tooltip.getStrokeFromObject = false;
      target.tooltip.background.strokeWidth = 0;
      target.tooltip.pointerOrientation = 'vertical';
    }
  }

  if (target instanceof am4charts.LineSeries) {
    if (target.tooltip) {
      target.tooltip.label.fill = am4core.color('#FFFFFF');
      target.tooltip.background.fill = am4core.color('#22272A');
      target.tooltip.label.padding(15, 15, 15, 15);
      target.tooltip.getFillFromObject = false;
      target.tooltip.getStrokeFromObject = false;
      target.tooltip.background.strokeWidth = 0;
      target.tooltip.background.stroke = am4core.color('#22272A');
    }
  }
}

function getDashboardTileTooltip(tooltip: am4core.Tooltip): void {
  tooltip.label.fill = am4core.color('#FFFFFF');
  tooltip.background.fill = am4core.color('#22272A');
  tooltip.background.fillOpacity = 0.9;
  tooltip.label.padding(10, 10, 10, 10);
  tooltip.getFillFromObject = false;
  tooltip.getStrokeFromObject = false;
  tooltip.background.strokeWidth = 0;
}
