import { IDashboard, IDashboardTile } from '@shared/interfaces';
import { TileTypes, VisualizationTypes } from '@shared/enums';
import { DEFAULT_TILE_INFORMATION } from '@shared/constants';

export function replaceArrayItem<T extends IDashboard | IDashboardTile>(items: T[], newItem: T): T[] {
  return items.map(item => {
    if (item.id === newItem.id) {
      return newItem;
    }

    return item;
  });
}

export function deleteById<T extends IDashboard | IDashboardTile>(items: T[], id: string): T[] {
  return items.filter(item => item.id !== id);
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function getVisualizationType(
  type: TileTypes,
  settings: { [key: string]: any }
): VisualizationTypes | null {
  // get default visualization type if there is no in settings
  const defaultVisualizationTypes = DEFAULT_TILE_INFORMATION[type]?.visualizationTypes || [];

  // it could be that we missed to add this type to configs
  if (!defaultVisualizationTypes?.length) {
    console.error(`Invalid dashboard tile type - ${type}`);
    return null;
  }

  const ifTrendingChartStyle = settings?.trendingChartStyle
    && defaultVisualizationTypes.includes(settings.trendingChartStyle); // scorecard legacy
  const ifSettingsVisualization = settings?.visualization
    && defaultVisualizationTypes.includes(settings.visualization);
  const ifChartType = settings?.chartType
    && defaultVisualizationTypes.includes(settings.chartType); // account hub legacy
  // if have valid settings.visualization then use it
  // otherwise if have valid  then use it trendingChartStyle
  // otherwise there is no visualization type from BE and we have to get it from default config
  const visualType = ifSettingsVisualization // settings.visualization has more priority than settings.trendingChartStyle
    ? settings.visualization
    : ifTrendingChartStyle
      ? settings.trendingChartStyle
      : ifChartType ? settings.chartType : null;

  return visualType
    ? visualType
    : defaultVisualizationTypes[0];
}

