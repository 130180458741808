export enum TrendsType {
    Trend = 'trend',
    Goal = 'goal',
    Benchmark = 'benchmark',
}

/**
 * There is a new version of trend type to adapt them
 * to the new design. Before sending data to the backend we have to
 * replace new trend type with old one
 */
export enum NewTrendsType {
    Trend = 'trend',
    Goal = 'goal',
    BenchmarkYeah = 'benchmarkYeah',
    BenchmarkCohort = 'benchmarkCohort'
}
