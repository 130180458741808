import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderCellComponent } from './header-cell.component';

@NgModule({
  declarations: [HeaderCellComponent],
  exports: [HeaderCellComponent],
  imports: [
    CommonModule,
    TsTooltipModule,
    TranslateModule
  ]
})
export class HeaderCellModule {
}
