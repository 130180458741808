import { getCurrencySymbol, getLocaleCurrencySymbol } from '@angular/common';

import { format, isDate, isValid } from 'date-fns';
import { isArray, isNumber } from '@terminus-lib/fe-utilities';

import { DataTypeEnum } from '@shared/enums';

export const formatDataByDataType = (value: number | Date | string | string[] | boolean,
                                     dataType: DataTypeEnum,
                                     locale: string,
                                     decimals: number | null = null,
                                     shouldAbbreviate: boolean = true,
                                     currencySetting?: string,
                                     dateFormat?: string): string => {
  decimals = decimals ?? (dataType === DataTypeEnum.Currency || dataType === DataTypeEnum.Percent ? 2 : 0);
  let formatted = '--';

  switch (dataType) {
    case DataTypeEnum.DayAtTime:
      if (isDate(value) && isValid(value)) {
        formatted = format(<Date>value, 'iiii @ h:mm a');
      }
      break;

    case DataTypeEnum.Date:
      if (value && typeof value === 'string' || typeof value === 'number') {
        const utcDate = toUtc(new Date(value));
        formatted = format(utcDate, dateFormat || 'MMM d, yyyy');
      }
      break;

    case DataTypeEnum.Text:
      if (isArray(value)) {
        formatted = value.join(', ').toString();
      } else if (value) {
        formatted = value.toString();
      }
      break;

    case DataTypeEnum.Number:
      if (isNumber(value)) {
        const isNegative = value < 0;
        const absoluteValue = Math.abs(value);
        formatted = formatData(shouldAbbreviate, absoluteValue, decimals);
        if (isNegative) {
          formatted = `-${formatted}`;
        }
      }
      break;

    case DataTypeEnum.LowerIntNumber:
      if (isNumber(value)) {
        formatted = `${Math.trunc(value)}`;
      }
      break;

    case DataTypeEnum.Currency:
      if (isNumber(value)) {
        const isNegative = value < 0;
        const absoluteValue = Math.abs(value);
        const currencySymbol = currencySetting ? getCurrencySymbol(currencySetting, 'narrow') : getLocaleCurrencySymbol(locale);
        formatted = formatData(shouldAbbreviate, absoluteValue, decimals);
        formatted = `${currencySymbol}${formatted}`;
        if (isNegative) {
          formatted = `-${formatted}`;
        }
      }
      break;

    case DataTypeEnum.Percent:
      if (isNumber(value)) {
        const isNegative = value < 0;
        const absoluteValue = Math.abs(value);
        const percentSymbol = '%';
        formatted = formatData(shouldAbbreviate, absoluteValue, decimals);
        formatted = `${formatted}${percentSymbol}`;
        if (isNegative) {
          formatted = `-${formatted}`;
        }
      }
      break;

    case DataTypeEnum.Boolean:
      formatted = value ? 'Y' : 'N';
      break;

    default:
      break;
  }

  return formatted;
}

const formatData = (shouldAbbreviate: boolean, absoluteValue: number, decimals: number): string => {
  const toThousandsSeparator = (n: string, decimals: number = 0): string =>
    Number(n).toLocaleString(undefined, {minimumFractionDigits: decimals, maximumFractionDigits: decimals});

  return shouldAbbreviate
    ? abbreviate(absoluteValue, decimals)
    : toThousandsSeparator(ensureTrailingZeros(absoluteValue, decimals), decimals);
};

const abbreviate = (value = 0, decimals: number): string => {
  let abbreviated = '0';

  if (value) {
    const suffixes: string[] = ['', 'K', 'M', 'B', 'T'];
    let exponent: number = Math.floor(Math.log(value) / Math.log(1000));

    if (exponent < 0) {
      exponent = 0;
    }

    abbreviated = ensureTrailingZeros(value / Math.pow(1000, exponent), decimals);
    abbreviated += suffixes[exponent];
  }

  return abbreviated;
};

const ensureTrailingZeros = (value: number, decimals: number): string => value.toFixed(decimals);

const toUtc = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};
