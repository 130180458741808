import { Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, debounceTime, mergeMap, retryWhen, take } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as HubTokenSelectors from '@hub-token';
import { select, Store } from '@ngrx/store';
import { userCommonActions } from '@shared/data-access/user-common';
import { RollbarErrorHandler, RollbarLogLevelEnum } from '@shared/rollbar';

@Injectable()
export class HttpBaseInterceptor implements HttpInterceptor {
  public MAX_RETRY_COUNT = 5;
  public RETRY_WAIT = 100; // milliseconds
  private loginRedirect$ = new Subject<void>();

  constructor(protected rollbarReportingService: RollbarErrorHandler,
              protected store: Store<unknown>) {
    this.loginRedirect$.pipe(debounceTime(1000))
      .subscribe(() => this.store.dispatch(userCommonActions.loginRedirect()));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.pipe(
      select(HubTokenSelectors.selectHubToken),
      take(1),
      mergeMap((jwtToken: string) => {
        // NOTE: there could be a case when we have already had a token in the headers
        // it's necessary for authentication functionality (check user.source)
        // or if we want to use another type of token
        // in this case we have to use it instead of jwt token from the store
        const authorizationHeader = request.headers.get('Authorization');
        request = jwtToken ? request.clone({
          setHeaders: {
            Authorization: authorizationHeader || `Bearer ${jwtToken}`
          },
          // NOTE: sometime we need withCredentials = true
          withCredentials: request.withCredentials ?? false
        }) : request;

        return next.handle(request).pipe(
          retryWhen(httpRetryStrategy({
            maxRetryAttempts: this.MAX_RETRY_COUNT,
            scalingDuration: this.RETRY_WAIT,
            retryStatusCodes: [400, 401]
          })),
          catchError((response: HttpErrorResponse) => {
            if(response.status === 401) {
              this.loginRedirect$.next();
            }
            this.rollbarReportingService.report(RollbarLogLevelEnum.ERROR, JSON.stringify(response.error));
            return throwError(response);
          })
        );

      })
    );
  }
}

/// stolen from: https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const httpRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  retryStatusCodes = []
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  retryStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        !retryStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      // retry after 1x, 2x, etc...
      return timer(retryAttempt * scalingDuration);
    })
  );
};
