import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { orgConfigActions } from './org-config.actions';
import * as OrgConfigSelectors from './org-config.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { OrgConfigState } from './org-config.reducer';
import { OrgConfigService } from '../services/org-config.service';
import { IOrgConfig, IUpdateConfig, userMessageFactory } from '@shared/interfaces';

@Injectable()
export class OrgConfigEffects {

  constructor(
    private actions$: Actions,
    private store: Store<OrgConfigState>,
    private orgConfigService: OrgConfigService
  ) { }

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      orgConfigActions.LoadOrgConfigFailure,
      orgConfigActions.LoadSigstrsProductTypeFailure,
      orgConfigActions.LoadCrmUrlFailure,
      orgConfigActions.LoadOpportunityTypesFailure,
      orgConfigActions.LoadOpportunityProductLinesFailure,
      orgConfigActions.UpdateConfigFailure
    ),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.payload }) }))
  ));

  public updateConfig$ = createEffect(() => this.actions$.pipe(
    ofType(orgConfigActions.UpdateConfig),
    pluck('payload'),
    mergeMap((params: IUpdateConfig) =>
      this.orgConfigService.updateConfig$(params).pipe(
        map((response: IUpdateConfig) => orgConfigActions.UpdateConfigSuccess({ payload: response })),
        catchError((error: Error) => {
          const message = error?.message || 'shared.settings.errors.updateConfig';
          return of(orgConfigActions.UpdateConfigFailure({ payload: message }));
        })
      ))
  ));

  public getProductType$ = createEffect(() => ({
    type$ = this.store.pipe(select(OrgConfigSelectors.getSigstrProductType))
  } = {}) => this.actions$.pipe(
    ofType(orgConfigActions.LoadSigstrsProductType),
    withLatestFrom(type$),
    filter(([, type]: [any, string]) => !type),
    switchMap(() => {
      return this.orgConfigService.getSigstrProductType$().pipe(
        map(result => orgConfigActions.LoadSigstrsProductTypeSuccess({ payload: result })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'ngrx.orgConfig.getProductType';
          return of(orgConfigActions.LoadSigstrsProductTypeFailure({ payload: message }));
        })
      );
    })
  ));

  loadConfig$ = createEffect(() => ({
    config$ = this.store.pipe(select(OrgConfigSelectors.getOrgConfig))
  } = {}) => this.actions$.pipe(
    ofType(orgConfigActions.LoadOrgConfig),
    withLatestFrom(config$),
    filter(([, config]: [any, IOrgConfig]) => !config),
    switchMap(() => {
      return this.orgConfigService.getOrgConfig$().pipe(
        map((config: IOrgConfig) => orgConfigActions.LoadOrgConfigSuccess({ payload: config })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'ngrx.orgConfig.loadConfig';
          return of(orgConfigActions.LoadOrgConfigFailure({ payload: message }));
        })
      );
    })
  ));

  loadCrmUrl$ = createEffect(() => ({
    url$ = this.store.pipe(select(OrgConfigSelectors.getCrmUrl))
  } = {}) => this.actions$.pipe(
    ofType(orgConfigActions.LoadCrmUrl),
    withLatestFrom(url$),
    filter(([, url]: [any, string]) => !url),
    switchMap(() => {
      return this.orgConfigService.getCrmUrl$().pipe(
        map((url: string) => orgConfigActions.LoadCrmUrlSuccess({ url })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'ngrx.orgConfig.loadCrmUrl';
          return of(orgConfigActions.LoadCrmUrlFailure({ payload: message }));
        })
      );
    })
  ));

  public getOpportunityTypes$ = createEffect(() => this.actions$.pipe(
    ofType(orgConfigActions.LoadOpportunityTypes),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(OrgConfigSelectors.getOpportunityTypes)))
    )),
    filter(([, types]) => !types),
    mergeMap(() => {
      return this.orgConfigService.getOpportunityTypes$().pipe(
        map(types => orgConfigActions.LoadOpportunityTypesSuccess({ payload: types })),
        catchError((error: Error) => {
          const message = error?.message || 'ngrx.orgConfig.loadTypes';
          return of(orgConfigActions.LoadOpportunityTypesFailure({ payload: message }));
        })
      );
    })
  ));

  public getOpportunityProductLines$ = createEffect(() => this.actions$.pipe(
    ofType(orgConfigActions.LoadOpportunityProductLines),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(OrgConfigSelectors.getOpportunityProductLines)))
    )),
    filter(([, lines]) => !lines),
    mergeMap(() => {
      return this.orgConfigService.getOpportunityProductLines$().pipe(
        map(lines => orgConfigActions.LoadOpportunityProductLinesSuccess({ payload: lines })),
        catchError((error: Error) => {
          const message = error?.message || 'ngrx.orgConfig.productLines';
          return of(orgConfigActions.LoadOpportunityProductLinesFailure({ payload: message }));
        })
      );
    })
  ));

  public getSpikeModels$ = createEffect(() => this.actions$.pipe(
    ofType(orgConfigActions.LoadSpikeModels),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(OrgConfigSelectors.getSpikeModels))
      ))),
    filter(([, models]) => !models),
    mergeMap(() => {
      return this.orgConfigService.getSpikeModels$().pipe(
        map(lines => orgConfigActions.LoadSpikeModelsSuccess({ payload: lines })),
        catchError((error: Error) => {
          const message = error?.message || 'ngrx.orgConfig.spikeModelsError';
          return of(orgConfigActions.LoadSpikeModelsFailure({ payload: message }));
        })
      );
    })
  ));

  public getIntentTopics$ = createEffect(() => this.actions$.pipe(
    ofType(orgConfigActions.LoadIntentTopics),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(OrgConfigSelectors.getIntentTopics)))
    )),
    filter(([, topics]) => !topics),
    mergeMap(() => {
      return this.orgConfigService.getIntentTopics$().pipe(
        map(lines => orgConfigActions.LoadIntentTopicsSuccess({ payload: lines })),
        catchError((error: Error) => {
          const message = error?.message || 'ngrx.orgConfig.intentTopicsError';
          return of(orgConfigActions.LoadIntentTopicsFailure({ payload: message }));
        })
      );
    })
  ));
}
