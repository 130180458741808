<ng-container *ngIf="filters">
  <tsh-date-cohort
    (apply)="applyDateCohort($event)"
    (dateRangeFilterChange)="changeDateCohort($event)"
    [selectedDateRange]="selectedDateCohort"
    [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>
  <tsh-report-setting-panel
    (apply)="emitApply()"
    reportName="measurementStudio.features.attributionCampaign.reportName">
    <tsh-report-filter-selector
      title="measurementStudio.features.attributionCampaign.filters.metric"
      [options]="revenueOptions"
      [field]="filterForm.get('revenueType')">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      *ngIf="filterForm.get('revenueType').value === types.Revenue"
      title="measurementStudio.features.attributionCampaign.filters.influenceType"
      [options]="influenceOptions"
      [field]="filterForm.get('influenceType')">
    </tsh-report-filter-selector>
  </tsh-report-setting-panel>
</ng-container>
