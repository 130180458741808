import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input,
  Output
} from '@angular/core';

import { faWindowClose } from '@fortawesome/pro-solid-svg-icons/faWindowClose';

export type AlertTypes = 'default' | 'warning' | 'error';

@Component({
  selector: 'tsh-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() public dismissible = false;
  @Input() public type: AlertTypes = 'default';
  @Input() public tooltip = 'shared.alert.close';
  @Output() public closeAlert = new EventEmitter<void>();

  public icon = faWindowClose;

  emitClose(event: MouseEvent): void {
    event.stopPropagation();
    this.closeAlert.emit();
  }
}

