import { Injectable, OnDestroy } from '@angular/core';

import { publicShapeOf } from '@terminus-lib/fe-utilities';
import { EnvService } from './env.service';
import { Environment, environmentFactory } from './environment.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvServiceMock implements publicShapeOf<EnvService>, OnDestroy {

  getEnv(): Environment {
    return environmentFactory();
  }

  // include the following unused member variables here for PublicShapeOf
  ngOnDestroy(): void {
  }
}
