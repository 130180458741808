import { IAnalyticsColumn, ICampaignTableItem } from '../interfaces';
import * as sharedColumns from './shared-columns.data';
import { AnalyticsColumnName as Column } from '../enums';
import { DataTypeEnum, ModelType, RouteItemEnum } from '@shared/enums';
import { GoParams } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export const SOURCE_COLUMNS: IAnalyticsColumn[] = [
  {
    name: Column.SourceName,
    width: 130,
    displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.sourceName',
    dataType: DataTypeEnum.Text,
    visible: true,
    startSorting: 'asc',
    models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
    link(row: ICampaignTableItem, filters: IAnalyticsRequest): GoParams {
      return {
        routeId: RouteItemEnum.WebActivitiesChannelAssets,
        queryParams: {
          gf: row.sourceName,
          model: filters.model,
          dataSet: filters.dataSet,
          cohort: filters.cohort.cohort,
          startDate: filters.cohort.startDate,
          endDate: filters.cohort.endDate,
          influenceType: filters.influenceType,
        }
      };
    },
  },
  sharedColumns.mediumName,
  sharedColumns.responses,
  sharedColumns.people,
  sharedColumns.accounts,
  sharedColumns.opptys,
  sharedColumns.deals,
  sharedColumns.opptyTouches,
  sharedColumns.dealTouches,
  sharedColumns.attributedPipeline,
  sharedColumns.attributedRevenue,
  sharedColumns.influencedPipeline,
  sharedColumns.influencedRevenue,
  sharedColumns.LTO,
  sharedColumns.CPL,
  sharedColumns.CPO,
  sharedColumns.CPD,
  sharedColumns.pipelineRoi,
  sharedColumns.ROI,
  sharedColumns.OTD,
];

