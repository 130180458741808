export enum DataTypeEnum {
    Text = 'text',
    Number = 'number',
    Currency = 'currency',
    Percent = 'percent',
    Date = 'date',
    DayAtTime = 'dayAtTime',
    Boolean = 'boolean',
    String = 'string',
    LowerIntNumber = 'lowerIntNumber',
}
