import { AgoFromToday, AgoRoundDown, All, LastFull, ToDate } from '@shared/enums';

export const ungrouppedMap = {
  label: All.LABEL,
  [All.ALL_TIME]: true,
};

export const toDateGroupMap = {
  label: ToDate.LABEL,
  [ToDate.WEEK_TO_DATE]: true,
  [ToDate.MONTH_TO_DATE]: true,
  [ToDate.QUARTER_TO_DATE]: true,
};

export const lastFullGroupMap = {
  label: LastFull.LABEL,
  [LastFull.LAST_FULL_WEEK]: true,
  [LastFull.LAST_FULL_MONTH]: true,
  [LastFull.LAST_FULL_QUARTER]: true,
};

export const agoFromTodayGroupMap = {
  label: AgoFromToday.LABEL,
  [AgoFromToday.WEEK_AGO_FROM_TODAY]: true,
  [AgoFromToday.ONE_MONTH_AGO_FROM_TODAY]: true,
  [AgoFromToday.NINETY_DAYS_AGO_FROM_TODAY]: true,
  [AgoFromToday.THREE_MONTHS_AGO_FROM_TODAY]: true,
  [AgoFromToday.SIX_MONTHS_AGO_FROM_TODAY]: true,
  [AgoFromToday.YEAR_AGO_FROM_TODAY]: true,
};

export const agoRoundDownGroupMap = {
  label: AgoRoundDown.LABEL,
  [AgoRoundDown.ONE_YEAR_AGO_FROM_TODAY]: true,
  [AgoRoundDown.TWO_YEARS_AGO_FROM_TODAY]: true,
};
