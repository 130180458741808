<div
  cdk-overlay-origin
  class="ts-sidenav-platform-switcher__trigger"
  #origin="cdkOverlayOrigin"
  [attr.aria-owns]="isPanelOpen ? id + '-panel' : null"
>
  <ts-logo type="mark-solid"></ts-logo>
  <ng-container *ngTemplateOutlet="dropdownCaret"></ng-container>
  <h1 class="ts-sidenav-ps__title">{{ currentTitle }}</h1>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="ts-sidenav-panel-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isPanelOpen"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayOffsetX]="offsetX"
  (backdropClick)="close()"
  (detach)="close()"
>
  <div class="ts-sidenav-panel-wrap ts-sidenav-panel-wrap--switcher">
    <nav
      role="listbox"
      tabindex="-1"
      class="ts-sidenav-panel ts-sidenav-panel--switcher ts-sidenav-panel--popout ts-switcher"
      [attr.id]="id + '-panel'"
      [@transformPanel]="'showing'"
      [style.transformOrigin]="transformOrigin"
    >
      <ul class="ts-switcher__list">
        <ng-container *ngFor="let section of switcherContent">
          <li class="ts-switcher__section-title">{{ section.title }}</li>

          <ul class="ts-switcher__list ts-switcher__children" *ngIf="section.children && section.children.length">
            <ng-container *ngFor="let child of section.children">
              <li class="ts-switcher__child">
                <ng-container *ngIf="child.url">
                  <a class="ts-switcher__link" [href]="child.url">
                    <span class="ts-switcher__link-icon" [ngClass]="child.iconClasses"></span>
                    {{ child.title }}
                  </a>
                </ng-container>
                <ng-container *ngIf="child.route">
                  <a class="ts-switcher__link" [routerLink]="child.route">
                    <span class="ts-switcher__link-icon" [ngClass]="child.iconClasses"></span>
                    {{ child.title }}
                  </a>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </ul>
    </nav>
  </div>
</ng-template>

<ng-template #dropdownCaret>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="caret-down"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    class="caret svg-inline--fa fa-caret-down fa-w-10 fa-3x"
  >
    <path
      fill="currentColor"
      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
    ></path>
  </svg>
</ng-template>
