import { Action } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';

export enum NullActionTypes {
  NULL = '[NullAction] Null Action',
  RETRYABLE_NULL = '[NullAction] Retryable Null Action',
}

defineTypeEnum(NullActionTypes);

// NOTE: This is needed to allow testing of Effects that do not need to return an action.
// As of NGRX 4.x testing an effect that doesn't return an action causes a jasmine timeout.
export class NullAction implements Action {
  readonly type: typeof NullActionTypes.NULL = NullActionTypes.NULL;
}

export type Actions = NullAction;
