import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import camelCase from 'camelcase';

import { userMessageFactory } from '@shared/interfaces';
import { ColorService } from '@util/color';
import { campaignsActions } from './campaigns.actions';
import * as selectors from './campaigns.selectors';
import * as reducer from './campaigns.reducer';
import { notificationMessagesActions } from '@notification-messages';
import { CampaignsService } from '../service/campaigns.service';

@Injectable()
export class CampaignsEffects {
  constructor(private actions$: Actions,
              private store: Store<{
                [reducer.campaignsFeatureKey]: reducer.State
              }>,
              public campaignsService: CampaignsService,
              private colorService: ColorService) {
  }

  public getTypes$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.getCampaignTypes),
    concatLatestFrom(() => this.store.pipe(select(selectors.selectCampaignsState))),
    filter(([_action, state]: [undefined, reducer.State]) => !state.isLoading && !state.types?.length),
    switchMap(() => of(campaignsActions.loadCampaignTypes()))
  ));

  public loadTypes$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadCampaignTypes),
    mergeMap(() => this.campaignsService.getCampaignTypes$().pipe(
      map(types => campaignsActions.loadCampaignTypesSuccess({types})),
      catchError(error => {
        const message = error?.message
          ? error.message
          : 'ngrx.revenueGroups.effects.loadGroups.defaultErrorMessage';
        return of(campaignsActions.loadCampaignTypesFailure({error: message}));
      }),
    ))
  ));

  public loadFailure$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadCampaignTypesFailure),
    pluck('error'),
    map((error: string) => notificationMessagesActions.addMessage({ message: userMessageFactory({n: error}) }))
  ));

  public loadSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadCampaignTypesSuccess),
    map(action => action.types || []),
    map((groups: string[]) => {
      const colors = this.colorService.getColors(groups.length);
      const revenueGroupColors = groups.reduce((acc, groupName: string, index: number) => {
        // modify group's name to camel case format to make it matches attribution campaign data
        acc[camelCase(groupName)] = colors[index];
        return acc;
      }, {});

      return campaignsActions.setCampaignTypesColor({colorMap: revenueGroupColors});
    })
  ));
}
