import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IReportTab } from '@shared/interfaces';

@Component({
  selector: 'tsh-report-tabs',
  templateUrl: './report-tabs.component.html',
  styleUrls: ['./report-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTabsComponent {
  @Input() tabs: IReportTab[];
}
