import { createAction, props } from '@ngrx/store';
import { SPLITIO_STATUS } from '../constants';

export enum SplitIoActions {
  SplitIoFlagRequested = '[splitio] Flag requested',
  SplitIoFlagReceived = '[splitio] Flag received',

  SetOverrides = '[splitio] Set overrides',
}

export const setOverrides = createAction(
  SplitIoActions.SetOverrides,
  props<{ overrides: Record<string, SPLITIO_STATUS> | null }>(),
);

export const splitIoFlagRequested = createAction(
  SplitIoActions.SplitIoFlagRequested,
  props<{ trafficType: string; treatment: string; attrs?: any }>(),
);

export const splitIoFlagReceived = createAction(
  SplitIoActions.SplitIoFlagReceived,
  props<{ trafficType: string; treatment: string; status: SPLITIO_STATUS }>(),
);
