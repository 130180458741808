import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserSourceURLs } from '@user/user.source';
import { EXCLUDED_URLS_FOR_INTERCEPTOR } from './excluded-urls.token';
import { convertBodyToCamelcase } from '@util/helpers';

@Injectable()
export class CamelCaseInterceptor implements HttpInterceptor {
  // don't apply the interceptor to these urls
  private excludedURLs: string[] = [];
  private readonly sharedUrls: string[] = [
    UserSourceURLs.GetUser,
  ];

  constructor(
    @Inject(EXCLUDED_URLS_FOR_INTERCEPTOR) private applicationURLs: string[]
  ) {
    // merge the application urls and shared urls (from libs/shared/)
    this.excludedURLs = [
      ...this.sharedUrls,
      ...this.applicationURLs
    ];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            const contentType = event.headers.get('content-type') || '';
            return contentType.includes('application/json')
            && event.body
            && !this.excludedURLs.find(url => request.url.includes(url))
              ? event.clone({body: convertBodyToCamelcase(event.body)})
              : event;
          }

          return event;
        })
      );
  }
}
