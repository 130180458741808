import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { TranslateModule } from '@ngx-translate/core';

import { SelectorWithTriggerComponent } from './selector-with-trigger.component';

@NgModule({
  declarations: [SelectorWithTriggerComponent],
  exports: [SelectorWithTriggerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSelectModule
  ]
})
export class SelectorWithTriggerModule {
}
