import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAuthToken } from '@shared/interfaces';
import {
  IGetUnifiedCustomerIdForCOURequest,
  IGetUnifiedCustomerIdForCOUResponse
} from '../../interfaces/customer-entitlement.interface';

export enum CustomerEntitlementSourceURLs {
  Authorize = '/v1/authorize',
  GetClosestUnifiedCustomerIdForCOU = '/v1/get_closest_unified_customer_id_for_cou',
}

@Injectable({
  providedIn: 'root',
})
export class CustomerEntitlementSource extends HttpClientBase {
  authorize$(): Observable<IAuthToken> {
    const endpoint = `${this.envService.getEnv().CUSTOMER_ENTITLEMENT_URL}${CustomerEntitlementSourceURLs.Authorize}`;

    return this.httpClient.get<IAuthToken>(endpoint);
  }

  getClosestUnifiedCustomerIdForCOU$(params: IGetUnifiedCustomerIdForCOURequest): Observable<IGetUnifiedCustomerIdForCOUResponse> {
    const endpoint =
      `${this.envService.getEnv().CUSTOMER_ENTITLEMENT_URL}${CustomerEntitlementSourceURLs.GetClosestUnifiedCustomerIdForCOU}`;

    return this.httpClient.post<IGetUnifiedCustomerIdForCOUResponse>(endpoint, params);
  }
}
