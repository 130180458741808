import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as interfaces from '../../interfaces';
import { mediumsActions, MediumsLoadActions } from './mediums.actions';
import * as selectors from './mediums.selectors';
import * as generalSelectors from '../general/general.selectors';
import { generalActions } from '../general/general.actions';
import * as webActivitiesSelectors from '../selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import { AnalyticsHelperService } from '../../helpers/analytics-helper.service';
import { IAnalyticsGlobalFilters, IPager, ISorter, userMessageFactory } from '@shared/interfaces';
import { checkDataByColumnName, getDriverRequestParams, getSecondaryRequestParams } from '../../helpers/store.helper';
import { WebActivitiesService } from '../../services/web-activities.service';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  ISorter,
  IPager,
  string[],
  string,
];

type LoadRequestParams<A extends Action> = [
  A,
  IAnalyticsRequest,
  Action[]?,
];

@Injectable()
export class MediumsEffects {
  constructor(
    public webActivitiesService: WebActivitiesService,
    private analyticsHelperService: AnalyticsHelperService,
    private store: Store<unknown>,
    private actions$: Actions
  ) {
  }

  public loadMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadMetaData),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumMetaData$(requestParams).pipe(
        mergeMap((data: interfaces.IMetaData<interfaces.IWebActivitiesMediumResponse>) => [
          mediumsActions.loadMetaDataSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumMetaData';
          return of(mediumsActions.loadMetaDataFailure({message}));
        })
      );
    })
  ));

  public loadResponses$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadResponses),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumResponses$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignResponses) => [
          mediumsActions.loadResponsesSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumResponses';
          return of(mediumsActions.loadResponsesFailure({message}));
        })
      );
    })
  ));

  public loadAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadAttribution),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumAttribution$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignAttribution) => [
          mediumsActions.loadAttributionSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumAttribution';
          return of(mediumsActions.loadAttributionFailure({message}));
        })
      );
    })
  ));

  public loadInfluence$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadInfluence),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumInfluence$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignInfluence) => [
          mediumsActions.loadInfluenceSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumInfluence';
          return of(mediumsActions.loadInfluenceFailure({message}));
        })
      );
    })
  ));

  public loadReturns$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadReturns),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumReturns$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignReturns) => [
          mediumsActions.loadReturnsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumReturns';
          return of(mediumsActions.loadReturnsFailure({message}));
        })
      );
    })
  ));

  public loadCosts$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.loadCosts),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<MediumsLoadActions>) => {
      return this.webActivitiesService.getMediumCosts$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignCosts) => [
          mediumsActions.loadCostsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadMediumCosts';
          return of(mediumsActions.loadCostsFailure({message}));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      mediumsActions.loadMetaDataFailure,
      mediumsActions.loadResponsesFailure,
      mediumsActions.loadAttributionFailure,
      mediumsActions.loadInfluenceFailure,
      mediumsActions.loadReturnsFailure,
      mediumsActions.loadCostsFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public getMediums$ = createEffect(() => this.actions$.pipe(
    ofType(mediumsActions.getMediums),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    concatMap(([_, filters, globalFilters, sorter, pager, recordIds, search]: LatestFilters<Action>) => {
      const requestParams = this.getRequestParams({isDriver: true}, filters, sorter, pager, search, recordIds, globalFilters);
      return of(requestParams).pipe(withLatestFrom(this.store.pipe(select(selectors.getMediumsUsedParams))));
    }),
    filter(([currentFilters, prevFilters]: IAnalyticsRequest[]) => {
      return this.analyticsHelperService.areFiltersChanged(currentFilters, prevFilters);
    }),
    map(([currentFilters]: IAnalyticsRequest[]) =>
      this.analyticsHelperService.getMediumsLoadAction(currentFilters.sorter.sortField, {isDriver: true})
    )
  ));

  public onChangeMediumsConfig$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.changeMediumsPagination,
      generalActions.changeMediumsSorting,
      generalActions.changeMediumsSearch
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getMediumsSorter)),
    ))),
    mergeMap(([_, sorter]) => [
      this.analyticsHelperService.getMediumsLoadAction(sorter.sortField, {isDriver: true}),
      mediumsActions.closeNestedTables()
    ])
  ));

  public onLoadDriverSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      mediumsActions.loadAttributionSuccess,
      mediumsActions.loadCostsSuccess,
      mediumsActions.loadInfluenceSuccess,
      mediumsActions.loadMetaDataSuccess,
      mediumsActions.loadResponsesSuccess,
      mediumsActions.loadReturnsSuccess
    ),
    filter((action) => action.isDriver && !!action.data.totalResults),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getVisibleMediumsColumnNames)),
    ))),
    mergeMap(([action, columnNames]) => {
      const secondaryRequests = this.analyticsHelperService.getMediumsSecondaryLoadActions(action, columnNames);
      // return secondaryRequests if they exists, otherwise make totals requests
      return secondaryRequests.length
        ? secondaryRequests
        : this.analyticsHelperService.getWebActivitiesTotalsLoadActions(columnNames, action.parentId || 'medium');
    }),
  ));

  public onLoadSecondarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      mediumsActions.loadAttributionSuccess,
      mediumsActions.loadCostsSuccess,
      mediumsActions.loadInfluenceSuccess,
      mediumsActions.loadMetaDataSuccess,
      mediumsActions.loadResponsesSuccess,
      mediumsActions.loadReturnsSuccess
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(
        select(webActivitiesSelectors.getUnloadedTotalsColumnNames(generalSelectors.getVisibleMediumsColumns), {key: 'mediums'})),
      this.store.pipe(select(selectors.getMediumsIsLoading)),
    ))),
    filter(([action, _, isLoading]) => !action.isDriver && !isLoading),
    switchMap(([action, columnNames]) =>
      this.analyticsHelperService.getWebActivitiesTotalsLoadActions(columnNames, action.parentId || 'mediums')
    ),
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleMediumsColumnVisibility),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(
        select(selectors.getReportData),
        map((data: interfaces.ICampaignIdentifiable[]) => checkDataByColumnName(data, action.data.name))
      ),
    ))),
    // do not dispatch load action if data is present in state
    filter(([_, data]) => !data.length),
    // dispatch actions to load extra data
    map(([action]) => this.analyticsHelperService.getMediumsLoadAction(action.data.name, {isDriver: false})
    )
  ));

  private withLatestFilters<T extends Action>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(generalSelectors.getMediumsSorter)),
        this.store.pipe(select(generalSelectors.getMediumsPager)),
        this.store.pipe(select(selectors.getMediumsRecordIds)),
        this.store.pipe(select(generalSelectors.getMediumsSearch)),
      ),
    );
  }

  private mapToRequestAndUsedParamsAction<T extends MediumsLoadActions>(
    [action, filters, globalFilters, sorter, pager, recordIds, searchParam]: LatestFilters<T>
  ): LoadRequestParams<T> {
    const requestParams = this.getRequestParams(action, filters, sorter, pager, searchParam, recordIds, globalFilters);
    const usedParamsAction = action.isDriver ? [mediumsActions.setUsedParams({request: requestParams})] : [];
    return [action, requestParams, usedParamsAction];
  }

  private getRequestParams(payload: interfaces.ILoadPayload,
                           filters: IAnalyticsRequest,
                           sorter: ISorter,
                           pager: IPager,
                           searchParam: string,
                           recordIds: string[],
                           globalFilters: IAnalyticsGlobalFilters): IAnalyticsRequest {
    const requestParams = payload.isDriver
      ? getDriverRequestParams(filters, sorter, pager, searchParam)
      : getSecondaryRequestParams(filters, recordIds);
    if (payload.parentId) {
      requestParams.crossTypeFilter = payload.parentId;
    }
    requestParams.globalFilters = globalFilters || [];
    // typeFilter is needed only for source and campaign tabs
    // remove it from the object to avoid redundant requests during changing tabs with gf query param in url
    delete requestParams.typeFilter;
    return requestParams;
  }
}
