import { createReducer, on, Action } from '@ngrx/store'
import { listPopupActions } from './list-popup.actions'
import {
  IAccountFolder,
} from '@shared/interfaces';

export const LIST_POPUP_KEY = 'listPopupKey';

export interface AccountListState {
  isLoading: number;
  folders: IAccountFolder[] | null;
}

export const initialState: AccountListState = {
  isLoading: 0,
  folders: null,
};

const listPopupReducer = createReducer(
  initialState,

  on(
    listPopupActions.loadFolders,
    listPopupActions.addToList,
    listPopupActions.createList,
    listPopupActions.createFolder,
    (state) => ({
      ...state,
      isLoading: state.isLoading + 1
    })
  ),

  on(
    listPopupActions.loadFoldersSuccess,
    (state, action) => ({
      ...state,
      folders: action.payload,
    })
  ),

  on(
    listPopupActions.loadFoldersSuccess,
    listPopupActions.loadFoldersFailure,
    listPopupActions.addToListFailure,
    listPopupActions.addToListSuccess,
    listPopupActions.createListFailure,
    listPopupActions.createFolderFailure,
    (state) => ({
      ...state,
        isLoading: state.isLoading - 1,
    })
  ),
)

export function reducer(state: AccountListState | undefined, action: Action): AccountListState {
  return listPopupReducer(state, action)
}
