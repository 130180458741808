import { Injectable } from '@angular/core';

import { map, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { MarketingInfluenceSource } from '../sources/marketing-influence.source';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { EnvService } from '@shared/environment';
import {
  IMarketingInfluenceDeal,
  IMarketingInfluenceOppty,
  IMarketingInfluenceReport,
  IMarketingInfluenceRequest,
  IMarketingInfluenceResponse,
  MarketingInfluenceTileSettings,
  OpportunityType
} from '../interfaces';
import { hubTokenName, TILE_DATA_FIELD_NAME } from '@shared/constants';
import {
  IDashboardTile,
  IGetTileData,
  ILabelValue,
  IReportColumn,
  IReportLink,
  ITileData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { Period } from '@measurement-studio/enums';
import { AgoRoundDown, DataTypeEnum, InfluenceType, ModelType, RouteItemEnum } from '@shared/enums';
import { getOpportunityParams } from '../utils/marketing-influence.utils';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { capitalizeFirstLetter, getStartAndEndDateFromParams, replaceLegacyCohort, toCapitalize } from '@util/helpers';
import {
  ChartColors,
  INFLUENCE_TYPE_FIELD,
  OPPORT_TYPE_FIELD,
  OPPTY_TYPE_OPTIONS,
  PERIOD_FIELD,
  PLOT_POINTS_FIELD,
  VALID_OPPTY_TYPES
} from '../marketing-influence.constants';
import {
  INFLUENCE_TYPE_OPTIONS,
  PERIOD_TYPE_OPTIONS,
  VALID_INFLUENCE_TYPES,
  VALID_MODEL_TYPES,
  VALID_PERIODS
} from '@measurement-studio/constants';

@Injectable({
  providedIn: 'root'
})
export class MarketingInfluenceService implements IGetTileData<MarketingInfluenceTileSettings> {
  constructor(
    private source: MarketingInfluenceSource,
    private retry: RetryWithEscalation,
    private translateService: TranslateService,
    envService: EnvService,
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getMarketingInfluenceReportData$(params: IMarketingInfluenceRequest): Observable<IMarketingInfluenceResponse> {
    return regenerateOnRetry(() => this.source.getMarketingInfluenceReportData$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileData$(params: MarketingInfluenceTileSettings): Observable<ITileData> {
    return this.source.getMarketingInfluenceReportData$(params).pipe(
      withLatestFrom(this.getPropertiesKeys(params)),
      map(([data, labels]: [IMarketingInfluenceResponse, Record<string, string>]) =>
        this.tileAdapter(data, params, labels))
    );
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    const cohort = {
      cohort: replaceLegacyCohort(params.cohort as string) || AgoRoundDown.TWO_YEARS_AGO_FROM_TODAY,
      ...getStartAndEndDateFromParams(params.cohort as string, params.startDate, params.endDate)
    };
    return {
      ...params, // in case if we have global filters we have to keep them
      ...cohort,
      [INFLUENCE_TYPE_FIELD]: params[INFLUENCE_TYPE_FIELD] && VALID_INFLUENCE_TYPES.includes(<InfluenceType>params[INFLUENCE_TYPE_FIELD])
        ? params.influenceType
        : InfluenceType.ANY,
      modelType: params.modelType && VALID_MODEL_TYPES.includes(<ModelType>params.modelType)
        ? params.modelType
        : ModelType.Even,
      [PERIOD_FIELD]: params[PERIOD_FIELD] && VALID_PERIODS.includes(<Period>params[PERIOD_FIELD])
        ? params[PERIOD_FIELD]
        : Period.QUARTER,
      [OPPORT_TYPE_FIELD]: params[OPPORT_TYPE_FIELD] && VALID_OPPTY_TYPES.includes(<OpportunityType>params[OPPORT_TYPE_FIELD])
        ? params[OPPORT_TYPE_FIELD]
        : OpportunityType.DEAL,
      type: 'actual', // TODO: what is this
      [PLOT_POINTS_FIELD]: params[PLOT_POINTS_FIELD] || 5,
    };
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    const isDeal = tile?.settings?.opptyType === OpportunityType.DEAL;
    const colors = isDeal ? [
      ChartColors.DealValue,
      ChartColors.DealMarketingValue,
    ] : [
      ChartColors.OpptyValue,
      ChartColors.OpptyMarketingValue,
    ];
    const label = isDeal
      ? 'measurementStudio.features.marketingInfluence.chart.revenueInfluenced'
      : 'measurementStudio.features.marketingInfluence.chart.pipelineInfluenced';
    return this.translateService.get(label).pipe(
      map((yAxisLabel: string) => ({
        metricLabel: yAxisLabel,
        chart: {
          numberFormatter: {
            numberFormat: '$#,###.00'
          },
          colors: {
            list: colors
          },
          yAxes: [{
            type: 'ValueAxis',
            min: 0,
            title: {
              text: toCapitalize(yAxisLabel),
              fill: '#637178'
            },
            numberFormatter: {
              type: 'NumberFormatter',
              forceCreate: true,
              numberFormat: '$#a'
            }
          }],
        }
      }))
    );
  }

  getReportColumns(filters: IMarketingInfluenceRequest): IReportColumn[] {
    const isDeal = filters?.opptyType === OpportunityType.DEAL;

    return [
      {
        displayName: filters?.period === Period.MONTH
          ? 'measurementStudio.features.marketingInfluence.columns.month'
          : 'measurementStudio.features.marketingInfluence.columns.quarter',
        name: 'month',
        width: 150,
        startSorting: 'asc',
        dataType: DataTypeEnum.Text,
        visible: true
      },
      {
        displayName: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.totalRevenue'
          : 'measurementStudio.features.marketingInfluence.columns.totalPipelineCreated',
        name: 'value',
        width: 180,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        visible: true
      },
      {
        displayName: isDeal
          ? `measurementStudio.features.marketingInfluence.columns.influencedRevenue`
          : `measurementStudio.features.marketingInfluence.columns.influencedPipeline`,
        name: 'marketingValue',
        width: 300,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        visible: true
      },
      {
        displayName: isDeal
          ? `measurementStudio.features.marketingInfluence.columns.influencedRevenuePercent`
          : `measurementStudio.features.marketingInfluence.columns.influencedPipelinePercent`,
        name: 'calculatedValue',
        width: 300,
        startSorting: 'desc',
        dataType: DataTypeEnum.Percent,
        decimal: 2,
        visible: false
      },
      {
        displayName: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.totalDeals'
          : 'measurementStudio.features.marketingInfluence.columns.totalOpportunities',
        name: 'count',
        width: 250,
        startSorting: 'desc',
        dataType: DataTypeEnum.Number,
        visible: true,
        internalLink(row: IMarketingInfluenceReport, reportFilters: IMarketingInfluenceRequest): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: {
              ...getOpportunityParams(row.month, reportFilters),
              model: InfluenceType.ANY // use only ANY for this link
            }
          };
        },
      },
      {
        displayName: isDeal
          ? `measurementStudio.features.marketingInfluence.columns.influencedDeals`
          : `measurementStudio.features.marketingInfluence.columns.influencedOpportunities`,
        name: 'marketingCount',
        width: 250,
        startSorting: 'desc',
        dataType: DataTypeEnum.Number,
        decimal: 0,
        visible: true,
        internalLink(row: IMarketingInfluenceReport, reportFilters: IMarketingInfluenceRequest): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: getOpportunityParams(row.month, reportFilters),
          };
        },
      },
      {
        displayName: isDeal
          ? `measurementStudio.features.marketingInfluence.columns.influencedDealsPercent`
          : `measurementStudio.features.marketingInfluence.columns.influencedOpportunitiesPercent`,
        name: 'calculatedCount',
        width: 250,
        startSorting: 'desc',
        dataType: DataTypeEnum.Percent,
        decimal: 2,
        visible: false,
        internalLink(row: IMarketingInfluenceReport, reportFilters: IMarketingInfluenceRequest): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: getOpportunityParams(row.month, reportFilters),
          };
        },
      }
    ];
  }

  getTileSettingsFilters$(): Observable<ITileSettingControl[]> {
    return of([{
      key: PERIOD_FIELD,
      label: 'measurementStudio.features.marketingInfluence.filters.interval',
      type: TileSettingsControlTypes.Selector,
      options: PERIOD_TYPE_OPTIONS,
    }, {
      key: OPPORT_TYPE_FIELD,
      label: 'measurementStudio.features.marketingInfluence.filters.metric',
      type: TileSettingsControlTypes.Selector,
      options: OPPTY_TYPE_OPTIONS,
    }, {
      key: PLOT_POINTS_FIELD,
      label: 'measurementStudio.features.marketingInfluence.filters.monthPoints',
      type: TileSettingsControlTypes.Selector,
      options: null,
      getCustomOptions(settings) {
        // 8 or 5 is max number of shown periods
        // functionality is from hub-ajs
        const plotLength = settings[PERIOD_FIELD] === Period.MONTH ? 8 : 5;
        const plotPointsOptions: ILabelValue<number>[] = [];
        for (let i = 2; i <= plotLength; i++) {
          plotPointsOptions.push({
            label: i.toString(),
            value: i
          });
        }
        return plotPointsOptions;
      },
      getCustomLabel(settings) {
        return settings[PERIOD_FIELD] === Period.MONTH
          ? 'measurementStudio.features.marketingInfluence.filters.monthPoints'
          : 'measurementStudio.features.marketingInfluence.filters.quarterPoints';
      }
    }, {
      key: INFLUENCE_TYPE_FIELD,
      label: 'measurementStudio.features.marketingInfluence.filters.influenceType',
      type: TileSettingsControlTypes.Selector,
      options: INFLUENCE_TYPE_OPTIONS,
      show(settings) {
        // NOTE: show this filter only if opportunity type is Deal
        return settings[OPPORT_TYPE_FIELD] === OpportunityType.DEAL;
      }
    }]);
  }

  private getPropertiesKeys(params: MarketingInfluenceTileSettings): Observable<Record<string, string>> {
    const { opptyType } = params;
    const isDeal = opptyType === OpportunityType.DEAL;
    const influencedKey = `marketing${capitalizeFirstLetter(opptyType)}`;

    return combineLatest([
      this.translateService.get(isDeal
        ? 'measurementStudio.features.marketingInfluence.chart.totalRevenue'
        : 'measurementStudio.features.marketingInfluence.chart.totalPipeline'
      ),
      this.translateService.get(isDeal
        ? 'measurementStudio.features.marketingInfluence.chart.revenueInfluenced'
        : 'measurementStudio.features.marketingInfluence.chart.pipelineInfluenced'
      )
    ]).pipe(
      map(([totalLabel, influencedLabel]: [string, string]) => ({
        [opptyType]: totalLabel,
        // marketingOppty or marketingDeal
        [influencedKey]: influencedLabel
      }))
    );
  }

  private tileAdapter(
    data: IMarketingInfluenceResponse,
    params: MarketingInfluenceTileSettings,
    labels: Record<string, string>
  ): ITileData {
    const { plotPoints, opptyType } = params; // number of visible items

    if (!data || !plotPoints) {
      return {
        items: [],
      };
    }
    // calculate how much we need to slice based on data length and plotPoints value
    // if plotPoints more than number of items then use 0
    const numberToSlice = plotPoints > data.length ? 0 : data.length - plotPoints;
    const influencedKey = `marketing${capitalizeFirstLetter(opptyType)}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore union type (NOTE: there is an issue related to union type as argument)
    const metricValue = data.reduce((acc: number, item: IMarketingInfluenceOppty | IMarketingInfluenceDeal) => {
      const itemValue = item[influencedKey]?.value || 0;
      return acc + itemValue;
    }, 0);

    return {
      metric: {
        current: {
          value: metricValue,
          dataType: DataTypeEnum.Currency,
        },
      },
      items: data
      .slice(numberToSlice)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore union type (NOTE: there is an issue related to union type as argument)
      .map((item: IMarketingInfluenceOppty | IMarketingInfluenceDeal) => ({
        [TILE_DATA_FIELD_NAME]: item.month,
        [labels[opptyType]]: item[opptyType]?.value, // there could be no data
        [labels[influencedKey]]: item[influencedKey]?.value // there could be no data
      })),
    };
  }
}
