<ng-container *ngIf="filters">
  <tsh-date-cohort *ngIf="selectedDateCohort"
                   (apply)="applyDateCohort($event)"
                   (dateRangeFilterChange)="changeDateCohort($event)"
                   [selectedDateRange]="selectedDateCohort"
                   [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>
  <tsh-report-setting-panel (apply)="emitApply()"
                            reportName="measurementStudio.features.common.attribution.reportName">
    <tsh-report-filter-selector title="measurementStudio.features.common.filters.dateField"
                                [field]="filterForm.get('dataSet')"
                                [options]="dateTypeOptions">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="measurementStudio.features.common.filters.influenceType"
                                [field]="filterForm.get('influenceType')"
                                [options]="influenceTypeOptions">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="measurementStudio.features.common.filters.attributionModel"
                                [field]="filterForm.get('modelType')"
                                [options]="modelTypeOptions">
    </tsh-report-filter-selector>
  </tsh-report-setting-panel>
</ng-container>
