import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { IAppliedGlobalFiltersAsParams, IDateCohort, IDateCohortGroup, IOrgConfig, userMessageFactory } from '@shared/interfaces';
import {
  DateCohortsGroups,
  ModelType,
  PortalTargets,
  RouteItemEnum,
  TileTypes,
  ToDate,
  TrendsType
} from '@shared/enums';
import { DataSetType, FrequencyType, GroupType } from '@measurement-studio/enums';
import { LABEL_QUARTER, LABEL_YEAR } from '@shared/constants';
import { TileModalService } from '@shared/features/tile-modal';
import * as OrgConfigStore from '@org-config';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as TrendingSelector from '../../state/trending.selectors';
import { notificationMessagesActions } from '@notification-messages';
import * as DateCohortsStore from '@date-cohorts';
import { dateCohortsActions } from '@date-cohorts';
import { catalogsActions, getIsWfStagesLoaded } from '@shared/data-access/catalogs';
import { ITrendingParams, ITrendingReport } from '../../interfaces';
import { TrendingDataPeriod } from '../../enums';
import { trendingActions } from '../../state/trending.actions';

@WithDestroy
@Component({
  selector: 'ms-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.scss']
})
export class TrendingComponent implements OnInit {
  public readonly targets = PortalTargets;
  public frequencyTitle$ = this.store.pipe(select(TrendingSelector.getFrequencyTitle));
  public chartData$ = this.store.pipe(select(TrendingSelector.getTrendingChartData));
  public dataType$ = this.store.pipe(select(TrendingSelector.getDataType));
  public reportData$ = this.store.pipe(select(TrendingSelector.getTrendingReportData));
  public isCampaignTrendsReport$ = this.store.pipe(select(TrendingSelector.isCampaignTrendsReport));
  public isWebActivitiesTrending$ = this.store.pipe(select(TrendingSelector.isWebActivitiesTrending));
  public filters$ = this.store.pipe(select(TrendingSelector.getTrendingFilters));
  public isLoadingAll$ = this.store.pipe(select(TrendingSelector.getTrendingIsLoadingAll));
  public isLoadingTotal$ = this.store.pipe(select(TrendingSelector.getTrendingIsLoadingTotal));
  public modelTypeOptions$ = this.store.pipe(select(OrgConfigStore.getUserModels));
  public metricOptions$ = this.store.pipe(select(TrendingSelector.getMetricOptions));
  public frequencyTypeOptions$ = this.store.pipe(select(TrendingSelector.getFrequencyTypeOptions));
  public dateCohortOptions$ = this.store.pipe(
    select(DateCohortsStore.getGroupedDateCohortOptions([
      DateCohortsGroups.Custom, DateCohortsGroups.LastFull, DateCohortsGroups.ToDate,
      DateCohortsGroups.Ago, DateCohortsGroups.Years, DateCohortsGroups.Quarters,
    ])),
    map(this.modifyDateCohortsGroup));
  public selectedDateCohort$ = this.store.pipe(select(TrendingSelector.getSelectedDateCohort));
  public comparativeColumns$ = this.store.pipe(select(TrendingSelector.getComparativeTableColumns));
  public comparativeTableData$ = this.store.pipe(select(TrendingSelector.getComparativeTableData));
  public comparativeTotalTitle$ = this.store.pipe(select(TrendingSelector.getComparativeTableTotalTitle));
  public comparativeTableCurrentPage$ = this.store.pipe(select(TrendingSelector.getComparativeTablePagination));
  public comparativeReportData$ = this.store.pipe(select(TrendingSelector.getTrendingReportDataWithPagination));
  public metrics$ = this.store.pipe(select(TrendingSelector.getTrendingMetrics));
  public metricFieldLabel$ = this.store.pipe(select(TrendingSelector.getMetricFieldLabel));
  public selectedGroupId$ = this.store.pipe(select(TrendingSelector.getSelectedGroupId));

  public defaultQueryParams: ITrendingParams = {
    dataSet: DataSetType.MembershipActivity,
    model: ModelType.Even,
    field: 'leads',
    grp: GroupType.NoList,
    type: TrendsType.Benchmark,
    freqType: FrequencyType.Auto,
    previous: 'true',
    cohort: ToDate.QUARTER_TO_DATE
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<unknown>,
    private tileModalService: TileModalService,
  ) {
  }

  ngOnInit(): void {
    this.getDateCohorts();
    this.getWfStages();
    this.setSelectedReport();
    this.getMetricsOptions();
    this.setFilters();
  }

  getDateCohorts(): void {
    this.store.dispatch(dateCohortsActions.loadDateCohorts());
  }

  getWfStages(): void {
    this.store.dispatch(catalogsActions.loadWfStages());
  }

  setSelectedReport(): void {
    if (this.route.snapshot.data.routeId) {
      this.store.dispatch(trendingActions.setSelectedReport(
        { routeItem: this.route.snapshot.data.routeId as RouteItemEnum }));
    } else {
      this.store.dispatch(notificationMessagesActions.addMessage({ message: userMessageFactory({
        n: 'There is no routeId in route data. Unable to load data'
      }) }));
    }
  }

  getMetricsOptions(): void {
    combineLatest([
      this.store.pipe(select(getIsWfStagesLoaded)),
      this.store.pipe(select(OrgConfigStore.getOrgConfig))
    ]).pipe(
      untilComponentDestroyed(this),
      filter(([isLoaded, config]: [boolean, IOrgConfig]) => !!(isLoaded && config))
    ).subscribe(() => {
      // dispatch action only if platform, config and stages in the store
      this.store.dispatch(trendingActions.getMetricOptions());
    });
  }

  setFilters(): void {
    this.route.queryParams.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(
        this.filters$.pipe(take(1)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1)),
      )
    ).subscribe(([params, filters, globalFilters]: [Params, ITrendingParams, IAppliedGlobalFiltersAsParams]) => {
      if (!Object.keys(params).length && !filters) {
        // if there is no any query params then use default params
        this.applyFilters({...this.defaultQueryParams, ...globalFilters});
        return;
      }

      if (!Object.keys(params).length && filters) {
        // if there is no any query params but filters in the store then use them
        this.applyFilters({...filters, ...globalFilters});
        return;
      }

      // NOTE: get filter only after setting metrics options
      // because we need them to check field param
      this.metricOptions$.pipe(
        untilComponentDestroyed(this),
        filter(options => !!options?.length)
      ).subscribe(() => {
        this.store.dispatch(trendingActions.getFilters(params))
      });
    });
  }

  applyFilters(newFilters: ITrendingParams): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newFilters,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  changeSelectedDateCohort(dateCohort: IDateCohort): void {
    this.store.dispatch(trendingActions.clearReportData());
    this.store.dispatch(trendingActions.setSelectedDateCohort({ dateCohort: dateCohort }));
  }

  selectGroup(item: ITrendingReport): void {
    this.store.dispatch(trendingActions.getGroupDetails({
      id: item.id,
      period: TrendingDataPeriod.Now
    }));
    combineLatest([
      this.filters$.pipe(take(1)),
      this.store.pipe(select(TrendingSelector.getTrendingIsLoadingGroupNow))
    ]).pipe(
      filter(([filters, isLoading]: [ITrendingParams, boolean]) => {
        // dispatch LoadGroupDetails for previous period only if
        // type === Benchmark and loading is false (to avoid request cancellation)
        return filters.type === TrendsType.Benchmark && !isLoading;
      }),
      take(1),
    ).subscribe(() => {
      this.store.dispatch(trendingActions.getGroupDetails({
        id: item.id,
        period: TrendingDataPeriod.Then
      }));
    });
  }

  private modifyDateCohortsGroup(cohorts: IDateCohortGroup[]): IDateCohortGroup[] {
    // remove last quarter and last year from options to prevent error when we set filter
    // to "One Cohort Ago"
    return cohorts.map(cohortGroup => {
      if (cohortGroup.label === LABEL_QUARTER || cohortGroup.label === LABEL_YEAR) {
        return {
          ...cohortGroup,
          options: cohortGroup.options.slice(0, cohortGroup.options.length - 1)
        };
      }
      return cohortGroup;
    });
  }

  changePage(page: number): void {
    this.store.dispatch(trendingActions.setComparativeTablePage({page}));
  }

  download(): void {
    this.store.dispatch(trendingActions.downloadComparativeCSV());
  }

  addToDashboardDialog(): void {
    const {queryParams, data} = this.route.snapshot;
    const type = data.routeId === RouteItemEnum.WebActivitiesTrending ? TileTypes.WebActivitiesTrending : TileTypes.CampaignTrends;

    this.tileModalService.openAddToDashboardModal(type, queryParams);
  }
}
