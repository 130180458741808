<a
  *ngIf="!isExternal"
  class="c-link c-link--internal"
  [routerLink]="destination || localRoute"
  fragment="{{ fragment }}"
  tabindex="{{ tabIndex }}"
>
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
</a>

<a
  *ngIf="isExternal"
  class="c-link c-link--external"
  [href]="destination"
  [target]="isTerminus ? '_top' : '_blank'"
  rel="noopener"
  tabindex="{{ tabIndex }}"
>
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
  <ng-template *ngTemplateOutlet="externalIcon"></ng-template>
</a>


<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #externalIcon>
  <div *ngIf="!isTerminus" class="c-link__icon">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="external-link"
      class=" svg-inline--fa fa-external-link fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM474.67,0H316a28,28,0,0,0-28,28V46.71A28,28,0,0,0,316.79,73.9L384,72,135.06,319.09l-.06.06a24,24,0,0,0,0,33.94l23.94,23.85.06.06a24,24,0,0,0,33.91-.09L440,128l-1.88,67.22V196a28,28,0,0,0,28,28H484a28,28,0,0,0,28-28V37.33h0A37.33,37.33,0,0,0,474.67,0Z"
      ></path>
    </svg>
  </div>
</ng-template>
