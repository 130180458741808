export default {
  oidc: {
    clientId: ``,
    issuer: ``,
    scopes: ['openid', 'profile', 'email', 'groups'],
    tokenManager: {
      autoRenew: false,
      storage: 'localStorage',
    },
  }
};
