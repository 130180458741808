// Search functionality for array of objects by specific field
export function searchByField<T, F extends keyof T>(data: T[], field: F, query: string): T[] {
  return (data || []).filter((item: T) =>  {
    const fieldValue = item[field].toString().toLowerCase();
    return fieldValue.includes(query.trim().toLowerCase());
  });
}

export function searchByFieldsList<T>(data: T[], fieldsList: string[], query: string): T[] {
  if (!query || query.trim() === '') {
    return data;
  }
  if (!fieldsList) {
    return [];
  }
  return (data || []).filter((item: T) =>  {
    for (const field of fieldsList) {
      if (item[field] !== null && item[field] !== undefined) {
        const fieldValue = item[field].toString().toLowerCase();
        const isMatch = fieldValue.includes(query.trim().toLowerCase());
        if (isMatch) {
          return true;
        }
      }
    }
    return false;
  });
}

export function searchInReportData<T>(data: T[], searchQuery: string | null): T[] {
  return searchQuery ? searchByAnyField(data, searchQuery) : data;
}

// Search functionality for array of objects by all fields
export function searchByAnyField<T>(data: T[], searchQuery: string): T[] {
  if (!data || !Array.isArray(data)) {
    return []; // to avoid js error
  }

  if (!searchQuery
    || typeof searchQuery !== 'string'
    || !searchQuery.trim()
    || !data.length) {
    return data;
  }

  return data.filter(item => searchInObject(item, searchQuery.trim().toLowerCase()));
}

function searchInObject<T>(obj: T, searchQuery: string): boolean {
  return !!Object.values(obj).find(value => {
    if (value === null || value === undefined || typeof value === 'function') {
      return false;
    }

    if (typeof value === 'string') {
      return value.toLowerCase().includes(searchQuery);
    }

    if (typeof value === 'number') {
      return value.toString().includes(searchQuery);
    }

    if (typeof value === 'object') {
      return searchInObject(value, searchQuery);
    }
  });
}
