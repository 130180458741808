<ng-container *ngIf="reportFilters">
    <tsh-date-cohort
        (apply)="applyDateCohort($event)"
        (dateRangeFilterChange)="changeDateCohort($event)"
        [selectedDateRange]="selectedDateCohort"
        [dateCohortOptions]="dateCohortOptions"
        [isDisabled]="isLoading">
    </tsh-date-cohort>
    <tsh-report-setting-panel
        (apply)="apply()"
        reportName="measurementStudio.features.marketingInfluence.common.marketingInfluence"
        [isDisabled]="isLoading">
        <tsh-report-filter-selector
            title="measurementStudio.features.marketingInfluence.filters.interval"
            [options]="periodTypeOption"
            [field]="filterForm.get('period')"
            cy-data="marketing-influence-period">
        </tsh-report-filter-selector>
        <tsh-report-filter-selector
            title="measurementStudio.features.marketingInfluence.filters.metric"
            [options]="opptyTypeOption"
            [field]="filterForm.get('opptyType')"
            cy-data="marketing-influence-oppty-type">
        </tsh-report-filter-selector>
        <tsh-report-filter-selector
            title="measurementStudio.features.marketingInfluence.filters.influenceType"
            *ngIf="filterForm.get('opptyType').value === opptyTypeEnum.DEAL"
            [options]="influenceTypeOption"
            [field]="filterForm.get('influenceType')"
            cy-data="marketing-influence-influence-type">
        </tsh-report-filter-selector>
    </tsh-report-setting-panel>
</ng-container>
