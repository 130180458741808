import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { PortalService } from './portal.service';

@Directive({
    selector: '[tshAttachTo]'
})
export class AttachToDirective implements OnInit, OnDestroy {
    @Input('tshAttachTo') public targetName: string;

    constructor(private portalService: PortalService,
                private templateRef: TemplateRef<unknown>) {
    }

    ngOnInit(): void {
        this.portalService.attach(this.targetName, this.templateRef);
    }

    ngOnDestroy(): void {
        this.portalService.clear(this.targetName);
    }
}
