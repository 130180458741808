<div [tsLoadingOverlay]="isLoading$ | async">
  <ms-opportunities-filters
    (apply)="applyFilters($event)"
    (removeCampaign)="applyFilters($event)"
    (setSelectedDateCohort)="setSelectedDateCohort($event)"
    [dateCohortOptions]="dateCohortOptions$ | async"
    [modelsOptions]="modelsOptions$ | async"
    [campaignSpecificData]="campaignSpecificData$ | async"
    [selectedDateCohort]="selectedDateCohort$ | async"
    [filters]="filters$ | async">
  </ms-opportunities-filters>
  <hr class="report-divider">
  <ng-container *ngIf="(report$ | async)?.length">
    <div class="metrics" *ngIf="metrics$ | async as metrics">
      <tsh-report-metric *ngFor="let metric of metrics"
                         [currency]="currency$ | async"
                         [data]="metric">
      </tsh-report-metric>
    </div>
  </ng-container>
  <ng-container *ngIf="(selectedChart$ | async) && (report$ | async)?.length">
    <ms-opportunities-chart
      (selectChart)="selectChart($event)"
      [tooltipTitle]="'measurementStudio.features.opportunities.chart.tooltipTitle' | translate"
      [isLoading]="isLoadingVisual$ | async"
      [selectedChart]="selectedChart$ | async"
      [options]="chartOptions$ | async"
      [data]="chart$ | async">
    </ms-opportunities-chart>
  </ng-container>
  <ms-opportunities-table
    (download)="download()"
    (changeColumns)="changeColumns($event)"
    (search)="applyFilters($event)"
    (changePage)="applyFilters($event)"
    (changeSort)="applyFilters($event)"
    (redirect)="redirect($event)"
    [currency]="currency$ | async"
    [isLoading]="isLoading$ | async"
    [filters]="filters$ | async"
    [totals]="totals$ | async"
    [searchOptions]="searchOptions$ | async"
    [allColumns]="allColumns$ | async"
    [visibleColumns]="visibleColumns$ | async"
    [report]="report$ | async">
  </ms-opportunities-table>
  <tsh-no-report-result
    *ngIf="(isLoading$ | async) === false && !(report$ | async)?.length"
    [description]="noResultDescription"
    [title]="noResultTitle">
  </tsh-no-report-result>
</div>

<!--<tsh-add-to-dashboard-button (add)="addToDashboardDialog()"-->
<!--                             [target]="targets.HeaderButtons">-->
<!--</tsh-add-to-dashboard-button>-->
<ng-template [tshAttachTo]="targets.HeaderButtons">
  <ts-button actionName="Button"
             buttonType="button"
             (clicked)="addToDashboardDialog()">
    {{ 'appHeader.addToDashboard' | translate }}
  </ts-button>
  <ts-button *ngIf="filters$ | async as filters"
             actionName="Button"
             buttonType="button"
             theme="default"
             class="add-button"
             (clicked)="getToAccountList(filters)"
             data-cy="scorecard-add-list-button">
    {{ 'measurementStudio.features.scorecard.addToListButton' | translate }}
  </ts-button>
</ng-template>
