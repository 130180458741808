import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationData } from './notification.interface';

/* Component Usage:
    constructor(
        private snackBar: MatSnackBar,
    ) {}
    .....
    this.snackBar.openFromComponent(NotificationComponent, {
        data: {
            message: successMsg,
            action: 'Dismiss', // Optional
            type: NotificationTypes.Success,
            icon: NotificationIcons.Check, // Optional
        }
    });
*/
@Component({
  selector: 'tsh-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
    private snackRef: MatSnackBarRef<NotificationComponent>
  ) {
  }

  dismiss(): void {
    this.snackRef.dismiss();
  }
}


