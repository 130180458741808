import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import * as interfaces from '@shared/interfaces';

export enum DashboardsSourceURLs {
  Create = '/v1/dashboard/create',
  Delete = '/v1/dashboard/delete',
  Update = '/v1/dashboard/update',
  Order = '/v1/dashboard/order',
  Duplicate = '/v1/dashboard/duplicate',
  GetDashboards = '/v1/dashboard/get',
  GetDashboardTiles = '/v1/dashboard/tile/get_all',
  CreateTile = '/v1/dashboard/tile/add',
  UpdateTile = '/v1/dashboard/tile/update',
  DeleteTile = '/v1/dashboard/tile/delete',
  OrderTiles = '/v1/dashboard/tile/order_all',
}

@Injectable({
  providedIn: 'root'
})
export class DashboardsSource extends HttpClientBase {
  create$(request: interfaces.ICreateDashboardRequest): Observable<interfaces.IDashboard> {
    const endpoint = this.endpoint(DashboardsSourceURLs.Create);

    return this.httpClient.post<interfaces.IDashboard>(endpoint, request);
  }

  duplicate$(dashboardId: string): Observable<interfaces.IDashboard> {
    const endpoint = this.endpoint(DashboardsSourceURLs.Duplicate);

    return this.httpClient.post<interfaces.IDashboard>(endpoint, {dashboardId});
  }

  delete$(dashboardId: string): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.Delete);

    return this.httpClient.post<string>(endpoint, {dashboardId}, this.getDefaultOptionsAndResponseType());
  }

  update$(request: interfaces.IDashboard): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.Update);

    return this.httpClient.post<string>(endpoint, request, this.getDefaultOptionsAndResponseType());
  }

  order$(dashboardIds: string[]): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.Order);

    return this.httpClient.post<string>(endpoint, {dashboardIds}, this.getDefaultOptionsAndResponseType());
  }

  getDashboards$(param?: interfaces.IDashboardRequest): Observable<interfaces.IDashboard[]> {
    const endpoint = this.endpoint(DashboardsSourceURLs.GetDashboards);

    return this.httpClient.post<interfaces.IDashboard[]>(endpoint, param || {});
  }

  createTile$(request: interfaces.ICreateDashboardTileRequest): Observable<interfaces.IDashboardTile> {
    const endpoint = this.endpoint(DashboardsSourceURLs.CreateTile);

    return this.httpClient.post<interfaces.IDashboardTile>(endpoint, request);
  }

  deleteTile$(tileId: string): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.DeleteTile);

    return this.httpClient.post<string>(endpoint, {tileId}, this.getDefaultOptionsAndResponseType());
  }

  updateTile$(request: interfaces.IDashboardTile): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.UpdateTile);

    return this.httpClient.post<string>(endpoint, request, this.getDefaultOptionsAndResponseType());
  }

  orderTiles$(tileIds: string[]): Observable<string> {
    const endpoint = this.endpoint(DashboardsSourceURLs.OrderTiles);

    return this.httpClient.post<string>(endpoint, {tileIds}, this.getDefaultOptionsAndResponseType());
  }

  getDashboardTiles$(dashboardId: string): Observable<interfaces.IDashboardTile[]> {
    const endpoint = this.endpoint(DashboardsSourceURLs.GetDashboardTiles);

    return this.httpClient.post<interfaces.IDashboardTile[]>(endpoint, {dashboardId});
  }

  private getDefaultOptionsAndResponseType() {
    return {
      responseType: 'text' as never // use this option to prevent parse error
    };
  }
}
