export enum AnalyticsReports {
  Campaigns = 'campaigns',
  CampaignGroups = 'campaignGroups',
  Mediums = 'mediums',
  Sources = 'sources',
  UTMCampaigns = 'utmCampaigns',
}

export enum AnalyticTypes {
  CampaignAnalytics = 'campaignAnalytics',
  WebActivities = 'webActivities',
}
