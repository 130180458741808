<ts-tooltip *ngIf="data?.tooltip; else noTooltip"
            [tooltipValue]="data.tooltip | translate"
            [tooltipPosition]="'above'"
            [hasUnderline]="false">
  <div class="container">
    <span class="title" *ngIf="data.title">
      {{ data.title | translate }}
      <i class="fas fa-xs fa-info-circle"></i>
    </span>
    <div class="section-container">
      <div class="color-section"
           *ngIf="data.current.color"
           [ngStyle]="{'background-color': data.current.color}">
      </div>
      <span class="value-section">
        {{ data.current.value | typedData : data.current.dataType : null: false : currency }}
      </span>
      <span *ngIf="changeValue !== null"
            class="change-section"
            [ngClass]="{negative: changeValue < 0, positive: changeValue > 0}">
        <i class="fas fa-sm fa-arrow-right"></i>
        {{ changeValueToDisplay | typedData : data.current.dataType : null: false : currency }}
        ({{ changePercentToDisplay | typedData : percentType : null: false }})
      </span>
    </div>
    <div *ngIf="data.previous" class="section-container">
      <div class="color-section"
           *ngIf="data.previous.color"
           [ngStyle]="{'background-color': data.previous.color}">
      </div>
      <span class="previous-value-section">
        {{ data.previous.value | typedData : data.previous.dataType : null: false : currency }}
        {{ 'shared.ui.reportMetric.prevPeriod' | translate }}
      </span>
    </div>
    <div class="section-container" *ngIf="data[goalField]">
      <span class="previous-value-section">
        {{ 'shared.ui.reportMetric.goal' | translate }}
        : {{ data[goalField].value | typedData : data[goalField].dataType : null: false : currency}}
      </span>
    </div>
  </div>
</ts-tooltip>
<ng-template #noTooltip>
  <div class="container" *ngIf="data">
    <span class="title" *ngIf="data.title">{{ data.title | translate }}</span>
    <div class="section-container">
      <div class="color-section"
           *ngIf="data.current.color"
           [ngStyle]="{'background-color': data.current.color}">
      </div>
      <span
        class="value-section">{{ data.current.value | typedData : data.current.dataType : null: false : currency }}</span>
      <span *ngIf="changeValue !== null"
            class="change-section"
            [ngClass]="{negative: changeValue < 0, positive: changeValue > 0}">
                <i class="fas fa-sm fa-arrow-right"></i>
        {{ changeValueToDisplay | typedData : data.current.dataType : null: false : currency }}
        ({{ changePercentToDisplay | typedData : percentType : null: false }})
            </span>
    </div>
    <div *ngIf="data.previous" class="section-container">
      <div class="color-section"
           *ngIf="data.previous.color"
           [ngStyle]="{'background-color': data.previous.color}">
      </div>
      <span class="previous-value-section">
        {{ data.previous.value | typedData : data.previous.dataType : null: false : currency }}
        {{ 'shared.ui.reportMetric.prevPeriod' | translate }}
      </span>
    </div>
    <div class="section-container" *ngIf="data[goalField]">
      <span class="previous-value-section">
          {{ 'shared.ui.reportMetric.goal' | translate }}
        : {{ data[goalField].value | typedData : data[goalField].dataType : null: false : currency }}
        ({{ goalPercent | typedData: percentType : null: false }})
      </span>
    </div>
  </div>
</ng-template>
