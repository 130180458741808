import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  AllJwtTokensExpired,
  FailedToActivateRoute,
  JwtTokenManagementActionTypes,
  JwtTokenNearingExpiration,
  StoreToken,
  TokenExtractor
} from '@terminus-lib/fe-jwt';
import { TsCookieService } from '@terminus-lib/fe-utilities';
import { TokenResponse } from '@okta/okta-auth-js';

import { IAuthToken } from '@shared/interfaces';
import { ClaimMap } from '../claim-map';
import { AUTH_CONT, AUTH_CONT_KEY_EMPLOYEE, hubTokenName } from '@shared/constants';
import * as HubTokenSelectors from './hub-token.selectors';
import { hubCookieActions } from '@hub-cookie';
import { NavigateTo } from '@hub-navigation';
import { panicActions } from '@panic';
import { OktaService, UNIFIED_LOGIN_LEARN_MORE } from '@hub-okta';
import { EnvService } from '@shared/environment';
import * as UserCommon from '@shared/data-access/user-common';
import { getIsUnifiedLoginEnabled, userCommonActions } from '@shared/data-access/user-common';
import { APP_IDENTITY } from '@shared/data-access/app-identity';

@Injectable()
export class HubTokenEffects {
  constructor(
    private store: Store<unknown>,
    private actions$: Actions,
    @Inject(UserCommon.userAuthenticateToken) private userService: UserCommon.IUserAuthenticate,
    private cookieService: TsCookieService,
    private tokenExtractor: TokenExtractor,
    private oktaService: OktaService,
    private envService: EnvService,
    @Inject(APP_IDENTITY) private appIdentity: string
  ) {
  }

  navigatedWithoutToken$ = createEffect(() => this.actions$.pipe(
    ofType<FailedToActivateRoute | AllJwtTokensExpired>(
      JwtTokenManagementActionTypes.FailedToActivateRoute,
      JwtTokenManagementActionTypes.AllTokensExpired
    ),
    mergeMap(
      () => [
        userCommonActions.loginRedirect(),
        userCommonActions.clearUserIsAuthenticated()
      ]
    )
  ));

  tokenSet$ = createEffect(() => this.actions$.pipe(
    ofType<StoreToken<ClaimMap>>(JwtTokenManagementActionTypes.StoreToken),
    concatLatestFrom(() => [
      this.store.pipe(select(UserCommon.selectIsUserProfileLoaded)),
      this.store.pipe(select(UserCommon.isUserLoading))
    ]),
    filter(([, isLoaded, isLoading]: [StoreToken<ClaimMap>, boolean, boolean]) =>!isLoaded && !isLoading),
    mergeMap(([tokensData]: [StoreToken<ClaimMap>, boolean, boolean]) => [
      userCommonActions.setUserIsAuthenticated(),
      userCommonActions.getUserProfile(),
      userCommonActions.requestUserSplitIoFlags({jwtToken: tokensData.token}),
    ])
  ));

  tokenNearingExpiration$ = createEffect(() => ({
    tokenSelector$ = this.store.select(HubTokenSelectors.selectHubToken),
    unifiedLoginEnabled$ = this.store.pipe(select(
      getIsUnifiedLoginEnabled
    )),
    isOktaUser$ = of(this.cookieService.get(AUTH_CONT) !== AUTH_CONT_KEY_EMPLOYEE)
  } = {}) => this.actions$.pipe(
    ofType<JwtTokenNearingExpiration<ClaimMap>>(JwtTokenManagementActionTypes.TokenNearingExpiration),
    filter((a: JwtTokenNearingExpiration<ClaimMap>) => a.tokenName === hubTokenName),
    withLatestFrom(
      tokenSelector$,
      unifiedLoginEnabled$,
      isOktaUser$
    ),
    filter(([a, existingToken, ]: [JwtTokenNearingExpiration<ClaimMap>, string, boolean, boolean]) => a.token === existingToken),
    switchMap(([, existingToken, unifiedLoginEnabled, isOktaUser]: [
      JwtTokenNearingExpiration<ClaimMap>, string, boolean, boolean
    ]) => unifiedLoginEnabled && isOktaUser
      ? this.oktaService.getSession$().pipe(
        mergeMap((sessionExist: boolean) => sessionExist
          ? this.oktaService.getWithoutPrompt$().pipe(
            mergeMap((res: TokenResponse) => {
              const oktaJwt = res.tokens.idToken.idToken;
              const oktaUserClaims = res.tokens.idToken.claims;
              const utmCustDomain = oktaUserClaims?.email.substring(oktaUserClaims?.email.indexOf('@') + 1) || '';

              return this.userService.authenticateUnifiedLogin$(oktaJwt).pipe(
                this.tokenExtractor.extractJwtToken({tokenName: hubTokenName, isDefaultToken: true}),
                mergeMap((authToken: IAuthToken) => [
                  hubCookieActions.updateJwt({token: authToken}),
                  userCommonActions.setUserIsAuthenticated()
                ]),
                catchError(() => of(
                  NavigateTo({
                    path: [this.envService.getEnv().UNIFIED_LOGIN_URL, UNIFIED_LOGIN_LEARN_MORE],
                    extras: {
                      queryParams: {
                        p: this.appIdentity,
                        utm_cust_domain: utmCustDomain
                      }
                    },
                    external: true
                  }))
                ));
            }),
            catchError((
              (error: HttpErrorResponse) => [
                panicActions.addPanicMessage({message: error.message}),
                NavigateTo({
                  path: ['/panic']
                })
              ]
            ))
          )
          : [
            userCommonActions.loginRedirect()
          ]
        ),
        catchError((
          (error: HttpErrorResponse) => [
            panicActions.addPanicMessage({message: error.message}),
            NavigateTo({
              path: ['/panic']
            })
          ]
        ))
      )
      : this.userService.authenticate$(existingToken).pipe(
        this.tokenExtractor.extractJwtToken({tokenName: hubTokenName, isDefaultToken: true}),
        mergeMap((authToken: IAuthToken) => [
          hubCookieActions.updateJwt({token: authToken}),
          userCommonActions.setUserIsAuthenticated()
        ]),
        catchError(() => {
            return of(userCommonActions.loginRedirect());
          }
        )
      ))
  ));
}
