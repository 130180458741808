<div class="report-filter-container">
    <span class="report-filter-title" *ngIf="title" [title]="title | translate">
        {{ title | translate }}
    </span>
  <ng-container *ngIf="field; else noSelector">
    <mat-select
      *ngIf="!isMultiSelect"
      [formControl]="field"
      [placeholder]="placeholder | translate"
      [title]="getTitle | memoize: field.value: options | translate"
      (selectionChange)="selectionChange()"
      class="report-filter-selector"
      data-cy="report-filter-selector-menu">
      <mat-option
        *ngFor="let option of options; index as i"
        class="reports-filter-options"
        [value]="option.value"
        [attr.data-cy]="'report-filter-selector-option-' + i"
        (click)="changeOutput();">
        {{ option.label | translate }}
      </mat-option>
    </mat-select>

    <mat-select
      *ngIf="isMultiSelect"
      [formControl]="field"
      [placeholder]="placeholder | translate"
      [title]="getTitle | memoize: field.value: options | translate"
      (selectionChange)="selectionChange()"
      class="report-filter-selector"
      multiple
      data-cy="report-filter-selector-menu">
      <mat-option
        *ngFor="let option of options; index as i"
        class="reports-filter-options"
        [value]="option.value"
        [attr.data-cy]="'report-filter-selector-option-' + i">
        {{ option.label | translate }}
      </mat-option>
    </mat-select>
  </ng-container>
  <ng-template #noSelector>
    <em>{{ 'shared.reportFilterSelector.noData' | translate }}</em>
  </ng-template>
</div>
