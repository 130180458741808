import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faFolder } from '@fortawesome/pro-solid-svg-icons/faFolder';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';

import { ISavedFolder, ISavedReport } from '@shared/interfaces';

@Component({
  selector: 'tsh-saved-reports-list',
  templateUrl: './saved-reports-list.component.html',
  styleUrls: ['./saved-reports-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedReportsListComponent {
  @Input() public savedReports: ISavedFolder[];

  @Output() public reportDialog = new EventEmitter<{ report?: ISavedReport; folderId?: number }>();
  @Output() public folderDialog = new EventEmitter<{ event?: MouseEvent; folder?: ISavedFolder }>();
  @Output() public showReport = new EventEmitter<ISavedReport>();
  @Output() public toggleFolderState = new EventEmitter<{ folderId: number; state: boolean }>();

  public folderIcon = faFolder;
  public penIcon = faPen;

  show(report: ISavedReport): void {
    this.showReport.emit(report);
  }

  openReportDialog(report?: ISavedReport, folderId?: number): void {
    this.reportDialog.emit({
      report,
      folderId
    });
  }

  openFolderDialog(event?: MouseEvent, folder?: ISavedFolder): void {
    this.folderDialog.emit({
      event,
      folder
    });
  }

  panelStateChanged(state: boolean, folderId: number): void {
    this.toggleFolderState.emit({
      folderId,
      state
    });
  }
}
