import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './account-list.reducer';
import { IAccountFolder } from '@shared/interfaces';

export const getAccountHubState = createFeatureSelector<reducer.State>(reducer.accountHubFeatureKey);
export const getFolders = createSelector(getAccountHubState, (state: reducer.State) => state.folders);
export const getAllAccountListsFromFolder = createSelector(
  getFolders,
  (
    folders: IAccountFolder[]
  ) => {
    if (!folders) {
      return [];
    }

    return folders.map(folder => folder.contents).flat();
  });

export const getFoldersLoading = createSelector(getAccountHubState, (state: reducer.State) => state.isLoading);
