import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { ISavedReportsSource } from './saved-reports.source.interface';
import {
  convertCreateFolderData,
  convertCreateReportData,
  convertDeleteFolderData,
  convertDeleteReportData,
  convertUpdateFolderData,
  convertUpdateReportData,
  ICreateSavedFolder,
  ICreateSavedReport,
  IDeleteSavedFolder,
  IDeleteSavedReport,
  ISavedFolder,
  IUpdateSavedFolder,
  IUpdateSavedReport
} from '@shared/interfaces';
import { convertToFormData } from '@util/http';

export enum SavedReportsSourceURLs {
  GetReports = '/folder/getSavableReportsFolders',
  UpdateReport = '/report/update',
  CreateReport = '/report/create',
  DeleteReport = '/report/delete',
  UpdateFolder = '/folder/updateFolder',
  CreateFolder = '/folder/createFolder',
  DeleteFolder = '/folder/deleteFolder',
}

@Injectable({
  providedIn: 'root'
})
export class SavedReportsSource extends HttpClientBase implements ISavedReportsSource {

  getSavableReportsFolders$(): Observable<ISavedFolder[]> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.GetReports);

    return this.httpClient.get<ISavedFolder[]>(endpoint);
  }

  updateSavableReportsFolder$(params: IUpdateSavedFolder): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.UpdateFolder);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertUpdateFolderData(params)),
      {
        responseType: 'text' as never // use this option to prevent parse error
      }
    );
  }

  createSavableReportsFolder$(params: ICreateSavedFolder): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.CreateFolder);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertCreateFolderData(params)),
      {
        responseType: 'text' as never // use this option to prevent parse error
      }
    );
  }

  deleteSavableReportsFolder$(params: IDeleteSavedFolder): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.DeleteFolder);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertDeleteFolderData(params)),
      {
        responseType: 'text' as never // use this option to prevent parse error
      }
    );
  }

  createSavableReport$(params: ICreateSavedReport): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.CreateReport);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertCreateReportData(params)),
    );
  }

  updateSavableReport$(params: IUpdateSavedReport): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.UpdateReport);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertUpdateReportData(params)),
    );
  }

  deleteSavableReport$(params: IDeleteSavedReport): Observable<void> {
    const endpoint = this.endpoint(SavedReportsSourceURLs.DeleteReport);

    return this.httpClient.post<void>(endpoint,
      convertToFormData(convertDeleteReportData(params)),
      {
        responseType: 'text' as never // use this option to prevent parse error
      }
    );
  }
}
