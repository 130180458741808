import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';

import { ReportMetricModule } from '@ui/components/report-metric';
import { TileComponent } from './containers/tile.component';
import { TableVisualizationComponent } from './components/table-visualization/table-visualization.component';
import { ChartVisualizationComponent } from './components/chart-visualization/chart-visualization.component';
import { amChartsDashboardFactory } from '@util/amcharts';
import { ChartConfigService } from './services/chart-config/chart-config.service';
import { XyChartConfigService } from './services/xy-chart-config/xy-chart-config.service';
import { PieChartConfigService } from './services/pie-chart-config/pie-chart-config.service';
import { GaugeChartConfigService } from './services/gauge-chart-config/gauge-chart-config.service';
import { TileSubtitlePipeModule } from './pipes/tile-subtitle.pipe';
import { TypedDataModule } from '@ui/pipes/typed-data';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TsMenuModule,
    TsTooltipModule,
    ReportMetricModule,
    TsChartModule,
    TileSubtitlePipeModule,
    TypedDataModule,
  ],
  declarations: [
    TileComponent,
    TableVisualizationComponent,
    ChartVisualizationComponent,
  ],
  exports: [
    TileComponent
  ],
  providers: [
    ChartConfigService,
    XyChartConfigService,
    PieChartConfigService,
    GaugeChartConfigService,
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsDashboardFactory,
    }
  ]
})
export class TileModule {
}
