import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import camelcaseKeys from 'camelcase-keys';

import { IAdvertisingInsightsSource } from './advertising-insights-source.interface';
import { HttpClientBase } from '@shared/http-client-base';
import { IAdsInfluencedRequest } from '../../interfaces/ads-influenced-request.interface';
import { IAdsInfluencedTrendsResponse } from '../../interfaces/ads-influenced-trends-response.interface';
import { IAdsInfluencedAccountTableResponse } from '../../interfaces/ads-influenced-account-table-response.interface';
import { IAdsInfluencedTotalsResponse } from '../../interfaces/ads-influenced-totals-response.interface';
import { IAdsTacticsResponse } from '../../interfaces/ads-tactics-response.interface';
import { IAdsInfluencedLeadConfigResponse } from '../../interfaces/ads-influenced-lead-config-response.interface';
import { IAdsInfluencedTotalInsightsResponse } from '../../interfaces/ads-influenced-total-insights-response.interface';
import { IAdsInfluencedTacticTableResponse } from '../../interfaces/ads-influenced-tactic-table-response.interface';
import { IAdsInfluencedReportTableResponse } from '../../interfaces/ads-influenced-report-table-response.interface';
import {
  IAdsInfluencedChildAccountTableRequest,
  IAdsInfluencedChildReportTableRequest,
  IAdsInfluencedChildTacticTableRequest
} from '../../interfaces/ads-influenced-child-table-request.interface';
import { IAdsInfluencedChildAccountTableResponse } from '../../interfaces/ads-influenced-child-account-table-response.interface';
import { IAdsInfluencedChildReportTableResponse } from '../../interfaces/ads-influenced-child-report-table-response.interface';
import { IAdsInfluencedChildTacticTableResponse } from '../../interfaces/ads-influenced-child-tactic-table-response.interface';

export enum AdvertisingInsightsSourceURLs {
  ExportAccountTable = '/v1/export_ads_influenced_account_table',
  ExportTacticTable = '/v1/export_ads_influenced_tactic_table',
  ExportReportTable = '/v1/export_ads_influenced_report_table',
  ExportChildAccountTable = '/v1/export_ads_influenced_child_account_table',
  ExportChildTacticTable = '/v1/export_ads_influenced_child_tactic_table',
  ExportChildReportTable = '/v1/export_ads_influenced_child_report_table',
  GetTrends = '/v1/get_ads_influenced_trends',
  GetTotals = '/v1/get_ads_influenced_totals',
  GetTotalInsights = '/v1/get_ads_influenced_total_insights',
  GetAccountTable = '/v1/get_ads_influenced_account_table',
  GetTacticTable = '/v1/get_ads_influenced_tactic_table',
  GetReportTable = '/v1/get_ads_influenced_report_table',
  GetChildAccountTable = '/v1/get_ads_influenced_child_account_table',
  GetChildTacticTable = '/v1/get_ads_influenced_child_tactic_table',
  GetChildReportTable = '/v1/get_ads_influenced_child_report_table',
  GetAdsTactics = '/v1/get_ads_tactics',
  GetAdsInfluencedConfig = '/v1/get_ads_influenced_config',
  SetAdsInfluencedConfig = '/v1/set_ads_influenced_config',
}

@Injectable({
  providedIn: 'root'
})
export class AdvertisingInsightsSource extends HttpClientBase implements IAdvertisingInsightsSource {

  // Setting the type 'blob' as 'json' is due to this issue: https://github.com/angular/angular/issues/18586
  public getServiceOptionsForExport() {
    return {responseType: 'blob' as 'json'};
  }

  // For Graph
  getAdsInfluencedTrends$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedTrendsResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetTrends);

    return this.httpClient.post<IAdsInfluencedTrendsResponse>(endpoint, request);
  }

  getAdsInfluencedTotals$<R extends IAdsInfluencedRequest, T extends IAdsInfluencedTotalsResponse>(request: R): Observable<T> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetTotals);

    return this.httpClient.post<T>(endpoint, request);
  }

  getAdsInfluencedTotalInsights$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedTotalInsightsResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetTotalInsights);

    return this.httpClient.post<IAdsInfluencedTotalInsightsResponse>(endpoint, request);
  }

  getAdsInfluencedAccountTable$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedAccountTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetAccountTable);

    return this.httpClient.post<IAdsInfluencedAccountTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  getAdsInfluencedTacticTable$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedTacticTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetTacticTable);

    return this.httpClient.post<IAdsInfluencedTacticTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  getAdsInfluencedReportTable$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedReportTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetReportTable);

    return this.httpClient.post<IAdsInfluencedReportTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  exportAdsInfluencedAccountTable$(request: IAdsInfluencedRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportAccountTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  exportAdsInfluencedTacticTable$(request: IAdsInfluencedRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportTacticTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  exportAdsInfluencedReportTable$(request: IAdsInfluencedRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportReportTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  getAdsInfluencedAccountChildTable$(request: IAdsInfluencedChildAccountTableRequest): Observable<IAdsInfluencedChildAccountTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetChildAccountTable);

    return this.httpClient.post<IAdsInfluencedChildAccountTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  getAdsInfluencedTacticChildTable$(request: IAdsInfluencedChildTacticTableRequest): Observable<IAdsInfluencedChildTacticTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetChildTacticTable);

    return this.httpClient.post<IAdsInfluencedChildTacticTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  getAdsInfluencedReportChildTable$(request: IAdsInfluencedChildReportTableRequest): Observable<IAdsInfluencedChildReportTableResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetChildReportTable);

    return this.httpClient.post<IAdsInfluencedChildReportTableResponse>(endpoint, request).pipe(
      map((resp) => ({...resp, rows: camelcaseKeys(resp.rows, {deep: true})}))
    );
  }

  exportAdsInfluencedAccountChildTable$(request: IAdsInfluencedChildAccountTableRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportChildAccountTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  exportAdsInfluencedTacticChildTable$(request: IAdsInfluencedChildTacticTableRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportChildTacticTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  exportAdsInfluencedReportChildTable$(request: IAdsInfluencedChildReportTableRequest): Observable<Blob> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.ExportChildReportTable);

    return this.httpClient.post<Blob>(endpoint, request, this.getServiceOptionsForExport());
  }

  getAdsTactics$(): Observable<IAdsTacticsResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetAdsTactics);

    return this.httpClient.post<IAdsTacticsResponse>(endpoint, {}).pipe(
    );
  }

  getAdsInfluencedConfig$(): Observable<IAdsInfluencedLeadConfigResponse> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.GetAdsInfluencedConfig);

    return this.httpClient.post<IAdsInfluencedLeadConfigResponse>(endpoint, {}).pipe(
    );
  }

  setAdsInfluencedConfig$(data: IAdsInfluencedLeadConfigResponse): Observable<string> {
    const endpoint = this.endpoint(AdvertisingInsightsSourceURLs.SetAdsInfluencedConfig);

    return this.httpClient.post(endpoint, data, {
      responseType: 'text'
    });
  }
}
