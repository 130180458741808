import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

import { Subscription } from 'rxjs';

import {
  deleteSavedFolderFactory, deleteSavedReportFactory,
  ICreateSavedFolder, ICreateSavedReport, IDeleteSavedFolder, IDeleteSavedReport,
  IFolderOption, IReportStateInfo, ISavedFolder, ISavedReport, IUpdateSavedFolder,
  IUpdateSavedReport
} from '@shared/interfaces';
import { FolderDialogComponent } from '../../components/folder-dialog/folder-dialog.component';
import { ReportDialogComponent } from '../../components/report-dialog/report-dialog.component';

@Component({
  selector: 'tsh-saved-reports',
  templateUrl: './saved-reports.component.html',
  styleUrls: ['./saved-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedReportsComponent implements OnChanges, OnDestroy {
  @Input() public isLoading: boolean;
  @Input() public savedReports: ISavedFolder[];
  @Input() public folderOptions: IFolderOption[];
  @Input() public stateInformation: IReportStateInfo;

  @Output() public updateFolder = new EventEmitter<IUpdateSavedFolder>();
  @Output() public createFolder = new EventEmitter<ICreateSavedFolder>();
  @Output() public deleteFolder = new EventEmitter<IDeleteSavedFolder>();
  @Output() public deleteReport = new EventEmitter<IDeleteSavedReport>();
  @Output() public updateReport = new EventEmitter<IUpdateSavedReport>();
  @Output() public createReport = new EventEmitter<ICreateSavedReport>();
  @Output() public showReport = new EventEmitter<ISavedReport>();

  public foldersState: { [key: string]: boolean };

  private dialogSub: Subscription;

  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedReports && changes.savedReports.currentValue) {
      this.foldersState = this.savedReports.reduce((acc, folder: ISavedFolder) => {
        acc[folder.folderId] = false;
        return acc;
      }, {});
    }
  }

  openReportDialog(data?: { report?: ISavedReport; folderId?: number }): void {
    const reportWithFolderId = data?.report
      ? {...data.report, folderId: data.folderId}
      : null;
    this.openDialog(reportWithFolderId, false);
  }

  openFolderDialog(data?: { event?: MouseEvent, folder?: ISavedFolder }): boolean {
    if (data?.event) {
      // NOTE: add this code in order to stop opening tab
      data.event.stopPropagation();
      data.event.preventDefault();
    }

    const folder = data?.folder;
    this.openDialog(folder);

    // NOTE: add this code in order to stop opening tab
    return false;
  }

  openDialog(dialogData?: ISavedReport | ISavedFolder, isFolder: boolean = true): void {
    const component: ComponentType<FolderDialogComponent | ReportDialogComponent>
      = isFolder ? FolderDialogComponent : ReportDialogComponent;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const initialData: any = {
      [isFolder ? 'folder' : 'report']: dialogData
    };

    if (!isFolder) {
      initialData.foldersOptions = this.folderOptions;
      initialData.stateInformation = this.stateInformation;
      initialData.currentFolderId = !dialogData ? this.getCurrentFolderId() : null;
    }

    const dialogRef = this.dialog.open(component, {
      data: initialData,
      width: '500px',
      panelClass: 'hub-modal-dialog'
    });

    this.dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (dialogData && typeof result === 'object') {
        return isFolder
          ? this.updateFolder.emit(result)
          : this.updateReport.emit({
            reportId: result.reportId,
            name: result.name,
            description: result.description
          });
      } else if (!dialogData && typeof result === 'object') {
        return isFolder ? this.createFolder.emit(result) : this.createReport.emit(result);
      } else if (dialogData && typeof result === 'number') {
        return isFolder
          ? this.deleteFolder.emit(deleteSavedFolderFactory({n: result}))
          : this.deleteReport.emit(deleteSavedReportFactory({n: result}));
      }
    });
  }

  redirectToReport(report: ISavedReport): void {
    this.showReport.emit(report);
  }

  toggleFolderState({folderId, state}: { folderId: number; state: boolean }): void {
    this.foldersState[folderId] = state;
  }

  ngOnDestroy(): void {
    if (this.dialogSub) {
      this.dialogSub.unsubscribe();
    }
  }

  private getCurrentFolderId(): number {
    const folderId = Object.keys(this.foldersState).find(id => this.foldersState[id]);

    return folderId ? Number(folderId) : null;
  }
}
