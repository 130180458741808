import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { IAuthToken, IExportToken, IUserProfile } from '@shared/interfaces';
import { EnvService } from '@shared/environment';
import { UserSource } from '../../sources/user/user.source';
import { IUserAuthenticate } from '@shared/data-access/user-common';

@Injectable({
  providedIn: 'root'
})
export class UserService implements IUserAuthenticate {

  constructor(
    public source: UserSource,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getUserProfile$(): Observable<IUserProfile> {
    return regenerateOnRetry(() => this.source.getUserProfile$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  authenticate$(tokenNearingExpiration: string): Observable<IAuthToken> {
    return regenerateOnRetry(() => this.source.authenticate$(tokenNearingExpiration)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  authenticateUnifiedLogin$(oktaToken: string): Observable<IAuthToken> {
    return regenerateOnRetry(() => this.source.authenticateUnifiedLogin$(oktaToken)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  authenticateExportToken$(): Observable<IExportToken> {
    return regenerateOnRetry(() => this.source.authenticateExportToken$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  switchUser$(username: string): Observable<IAuthToken> {
    // NOTE: skip using regenerateOnRetry to show 403 error
    return this.source.switchUser$(username);
  }
}
