import { IInsightsDetailsChartConfig } from '../interfaces';
import * as Colors from './report-colors.consts';

export const X_DATA_FIELD = 'weekDate' as const;

export const PAGE_VIEWS_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.PAGE_VIEWS_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'count',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.pageViewsInfluenced',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerPageView',
  isCurrency: false,
  lineIsCurrency: true
};

export const LEADS_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.LEADS_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'count',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.leadsInfluenced',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerLead',
  isCurrency: false,
  lineIsCurrency: true
};

export const CAMPAIGN_RESPONSES_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.CAMPAIGN_RESPONSES_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'count',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.campaignResponsesInfluenced',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerResponse',
  isCurrency: false,
  lineIsCurrency: true
};

export const NEW_PIPELINE_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.NEW_PIPELINE_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'amount',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.newPipelineInfluenced',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerNewOpportunity',
  isCurrency: true
};

export const PIPELINE_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.PIPELINE_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'amount',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.existingPipelineInfluenced',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerExistingOpportunity',
  isCurrency: true
};

export const REVENUE_INFLUENCED_CHART_CONFIG: IInsightsDetailsChartConfig = {
  barColor: Colors.REVENUE_INFLUENCED_COLOR,
  xDataField: X_DATA_FIELD,
  barDataField: 'amount',
  lineDataField: 'cost',
  barName: 'measurementStudio.features.advertisingInsights.metrics.revenue',
  lineName: 'measurementStudio.features.advertisingInsights.metrics.costPerDeal',
  isCurrency: true
};
