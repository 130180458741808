import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { addDays, addMonths, addYears, startOfDay, subDays, subMonths, subYears } from 'date-fns';
import { select, Store } from '@ngrx/store';
import { catchError, delay, mergeMap } from 'rxjs/operators';

import { JsonFile, Payload } from './demoable.interface';
import { convertBodyToCamelcase } from '@util/helpers';
import { DemoConfigState, selectDemoConfigState } from '@shared/data-access/demo-config-state';

@Injectable({
  providedIn: 'root',
})
export class DemoInterceptor implements HttpInterceptor {
  public demoConfig?: DemoConfigState;

  constructor(private store: Store<DemoConfigState>, private http: HttpClient) {
    // Doing this in the interceptor itself causes issues of caching responses, as well as piled up observables
    // that seem to never get freed. So we will do this once on load.
    this.store.pipe(select(selectDemoConfigState)).subscribe((state: DemoConfigState) => {
      this.demoConfig = state;
    });
  }

  /**
   * Check our config and see if we're in demo mode. If not, make requests as usual.
   * If we are, then try to find the json file associated with this api call.
   *
   * @param request
   * @param next
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const regex = new RegExp(/^\/assets/);
    if (!this.demoConfig || !this.demoConfig.enabled || regex.test(request.url)) {
      return next.handle(request);
    }

    try {
      // Don't remove fileType, in case if we add enterprise files we need it
      // this.demoConfig.isEnterprise ? 'ent' : 'mid';
      let requestUrl = request.url;
      const fileType = 'mid';
      const hasHttp = requestUrl.match(/^https?:/) !== null;
      if (!hasHttp) {
        requestUrl = `https:${requestUrl}`;
      }
      const url = new URL(requestUrl);
      if (url.pathname.includes('/assets/')) {
        return next.handle(request);
      }
      const pathname = `/assets/demo-data/json${url.pathname}.${fileType}.json`;
      return this.getPayload(
        url,
        pathname,
        this.demoConfig.slowdownEnabled ? this.demoConfig.slowdown : 0,
        this.demoConfig.debug,
        request,
        next,
      );
    } catch (e) {
      return next.handle(request);
    }
  }

  /*
   * If we can get the json file associated with the url in the request, then try to find a payload
   * in that json file based on the method type, and the arguments (body/params) of the request if
   * they're defined.  If all else fails, just go back to making a standard http call.
   */
  public getPayload(
    url: URL,
    pathname: string,
    slowdown: number,
    debug: boolean,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const slowdownInSeconds = slowdown * 1000;
    return this.http.get<JsonFile>(pathname).pipe(
      delay(slowdownInSeconds),
      mergeMap(json => {
        if (debug) {
          console.groupCollapsed(`DEMO MODE REQUEST: (${request.method}) ${url.pathname}`);
          console.log('  - Body: ', request.body);
        }

        const payload =
          request.method.toLowerCase() === 'get'
            ? this.findPayloadFromParams(json.payloads, request)
            : this.findPayload(json.payloads, request);
        if (!payload) {
          if (debug) {
            console.log('  - Response: ', '-- No payload found. --');
            console.groupEnd();
          }
          return next.handle(request);
        }
        if (payload.error) {
          if (debug) {
            console.log('  - Response: ', '-- Error: ', payload.error);
            console.groupEnd();
          }
          return throwError(new HttpErrorResponse(payload.error));
        }
        let body = this.massageData(payload.value);
        if (payload.isBlob) {
          // for cases when response expected to be attached or blob instead of plain string
          body = new Blob([payload.value as string], { type: 'text/csv;charset=utf-8;' });
        }
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('  - Response: ', body);
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
        return of(
          new HttpResponse({
            status: 200,
            body,
          }),
        );
      }),
      catchError(() => next.handle(request)),
    );
  }

  private findPayload(payloads: Payload[], req: HttpRequest<unknown>, params?: Record<string, string>): Payload {
    let asObject = {};
    if (!params && req && req.body) {
      // eslint-disable-next-line
      const requestBody = req.body as any;
      if (requestBody.encoder && requestBody.map) {
        // handle case with body encoded as HttpParams

        try {
          requestBody.map.forEach((value, key) => {
            asObject[key] = requestBody.encoder.decodeValue(value);
          });
          asObject = convertBodyToCamelcase(asObject);
        } catch (e) {
          console.log('request decoding failed');
        }
      }
    }
    const data = convertBodyToCamelcase(params ?? req.body);
    const requestMethod = req.method.toLowerCase();

    return payloads.find(p => {
      let isMatch = p.method === requestMethod;

      if (isMatch && p.args) {
        isMatch = p.isRequestDecoded ? doesContain(p.args, asObject) : doesContain(p.args, data);
      }

      return isMatch;
    });
  }

  private findPayloadFromParams(payloads: Payload[], req: HttpRequest<unknown>): Payload {
    const params = req.params.keys().reduce(
      (acc: Record<string, string>, key: string) => ({
        ...acc,
        [key]: req.params.get(key),
      }),
      {},
    );

    return this.findPayload(payloads, req, params);
  }

  private massageData(item: unknown): unknown {
    if (item === null || item === undefined) {
      return item;
    }
    if (typeof item === 'string') {
      item = this.parseByType(item);
    } else if (Array.isArray(item)) {
      item = item.map(i => this.parseByType(i));
    } else if (typeof item === 'object') {
      const value = { ...item };
      item = Object.keys(value).reduce((acc, k: string) => {
        acc[k] = this.parseByType(value[k]);
        return acc;
      }, {});
    }
    return item;
  }

  private parseByType(item: unknown): unknown {
    if (typeof item === 'string') {
      return this.parseSpecialDate(item);
    } else if (Array.isArray(item)) {
      return item.map((i: unknown) => this.massageData(i));
    } else if (typeof item === 'object') {
      return this.massageData(item);
    }
    return item;
  }

  private parseSpecialDate(str: string | number): string | number {
    if (typeof str !== 'string') {
      return str;
    }

    // matches: $today, $today + 1, $today - 1
    const match = str.match(/(\$today|\$year|\$month|\$day)(?:(?:\s)?([+-])(?:\s)?(\d+)?)?/);
    if (match) {
      let date = startOfDay(new Date());
      if (match.length === 4) {
        const op = match[2];
        const total = parseInt(match[3], 10);
        if (op === '-') {
          if (match[1] === '$today' || match[1] === '$day') {
            date = subDays(date, total);
          } else if (match[1] === '$year') {
            date = subYears(date, total);
          } else if (match[1] === '$month') {
            date = subMonths(date, total);
          }
        } else if (op === '+') {
          if (match[1] === '$today' || match[1] === '$day') {
            date = addDays(date, total);
          } else if (match[1] === '$year') {
            date = addYears(date, total);
          } else if (match[1] === '$month') {
            date = addMonths(date, total);
          }
        }
      }

      if (match[1] === '$today') {
        return date.toISOString();
      } else if (match[1] === '$month') {
        return date.getMonth() + 1;
      } else if (match[1] === '$year') {
        return date.getFullYear();
      } else if (match[1] === '$day') {
        return date.getDate();
      }
    }

    return str;
  }
}

function doesContain(base: unknown, derived: unknown): boolean {
  if (isArgumentEmpty(base, derived)) {
    return false;
  }
  if (Array.isArray(base)) {
    return base.every((k, idx) => doesContain(k, derived[idx]));
  } else if (typeof base === 'object') {
    return Object.keys(base).every(k => doesContain(base[k], derived[k]));
  }
  // if the base value contains a splat (*), then it should compare to anything
  return base === '*' || base === derived;
}

function isArgumentEmpty(base: unknown, derived: unknown): boolean {
  // prevent casting "" to false and handling bool values
  const castablePrimitiveTypes = ['string', 'boolean'];
  return !castablePrimitiveTypes.includes(typeof base) && !castablePrimitiveTypes.includes(typeof derived) && !derived;
}
