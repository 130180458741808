import { createFeatureSelector, createSelector } from '@ngrx/store';

import { orgConfigFeatureKey, OrgConfigState } from './org-config.reducer';
import { IAttributionModel, ILabelValue, IOrgConfig, IOrgConfigCustomColumn } from '@shared/interfaces';
import { AttributionTypes, ModelType } from '@shared/enums';
import { sfUrlMissing } from '@shared/constants';

export const selectOrgConfigState = createFeatureSelector<OrgConfigState>(orgConfigFeatureKey);
export const getOrgConfig = createSelector(selectOrgConfigState, (state: OrgConfigState) => state.config);
export const getAttributionModels = createSelector(getOrgConfig, (config: IOrgConfig) => config?.attributionModels);
export const getUserModels = createSelector(getAttributionModels,
  (models: Record<ModelType, IAttributionModel>): ILabelValue[] => {
    // get active attribution models
    if (models) {
      return Object.keys(models).reduce((acc, key: ModelType) => {
        if (models[key].active) {
          acc.push({
            label: models[key].customName,
            value: key
          });
        }
        return acc;
      }, []);
    }

    return [];
  });

export const getOrgConfigSettings = createSelector(getOrgConfig,
  (config: IOrgConfig) => {
    return config?.organizationSettings;
  });
export const isDynamics365Enabled = createSelector(getOrgConfigSettings,
  (settings: {dynamics365Enabled: boolean; dynamicsUrl: boolean}): boolean => {
    return !!(settings?.dynamics365Enabled && settings?.dynamicsUrl);
  });
export const getOrgConfigFeatures = createSelector(getOrgConfig,
  (config: IOrgConfig) => {
    return config?.features;
  });
export const getOrgCurrencySetting = createSelector(getOrgConfig, (orgConfig) => orgConfig?.groupings?.currency?.slice(0, 3));
export const getSigstrProductType = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.sigstrProductType
);
export const getCrmUrl = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.crmUrl
);
export const getOpportunityTypes = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.opportunityTypes
);
export const getOpportunityProductLines = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.opportunityProductLines
);
export const getSpikeModels = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.spikeModels
);
export const getIntentTopics = createSelector(selectOrgConfigState,
  (state: OrgConfigState) => state.intentTopics
);
export const getSalesforceUrl = createSelector(getCrmUrl,
  (url: string) => url || 'sf_url_missing'
);
export const isSigstrEnabled = createSelector(getSigstrProductType,
  (type: string) => type ? type === 'relationship' : false
);
export const getChannelFieldName = (field: string) => createSelector(getOrgConfig,
  (config: IOrgConfig) => {
    const defaultNames = {
      channelName: 'feature.common.utmNames.channel',
      channelGroup: 'feature.common.utmNames.channelGroup',
      channelParent: 'feature.common.utmNames.channelParent'
    };
    if (config?.webActivity) {
      // TODO: replace this functionality with one we have in hub-ajs
      // after clarifying requirements
      // campaignPerformance-srvc.js tabMappings
      return defaultNames[field];
    }

    return defaultNames[field];
  });

// use for campaign scoring settings page
export const getSelectedAttributionType = createSelector(getOrgConfig, (config: IOrgConfig): AttributionTypes => {
  return config?.attribution?.custom?.weightType || AttributionTypes.Percent;
});

export const isOCROverrideEnabled = createSelector(getOrgConfig, (config: IOrgConfig): boolean => {
  return config?.attribution?.custom?.ocrOverride === 'true';
});

// use for accounts hub page
export const getSelectedCustomColumnsAccount = createSelector(getOrgConfig, (config: IOrgConfig): IOrgConfigCustomColumn[] => {
  return config?.customColumns?.account || [];
});

export const getSelectedTerminusData = createSelector(getOrgConfig, (config: IOrgConfig): boolean => {
  return config?.terminusData;
});

export const isSalesActivityEnabled = createSelector(getOrgConfig, (config: IOrgConfig): boolean => {
  return config?.salesActivity?.enabled || false;
});

// Account
export const getCrmUrlForAccount = createSelector(
  getCrmUrl,
  (crmUrl) => crmUrl && typeof crmUrl === 'object' ? crmUrl.account : (crmUrl || sfUrlMissing) ,
);

// Activities
export const getCrmUrlForActivity = createSelector(
  getCrmUrl,
  (crmUrl) => crmUrl && typeof crmUrl === 'object' ? crmUrl.opportunity : (crmUrl || sfUrlMissing) ,
);

// People / Leads
export const getCrmUrlForLead = createSelector(
  getCrmUrl,
  (crmUrl) => crmUrl && typeof crmUrl === 'object' ? crmUrl.contact : (crmUrl || sfUrlMissing) ,
);
