import { IFactoryParams } from '@shared/interfaces';

export interface IMarketingInfluenceCount {
  count: number;
  value: number;
}

export function marketingInfluenceCountFactory({
  n = 1,
  overrides = {},
}: IFactoryParams<IMarketingInfluenceCount, number, boolean> = {}): IMarketingInfluenceCount {
  return {
    count: 10 * n,
    value: 10000000 * n,
    ...overrides
  };
}
