import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as am4Charts from '@amcharts/amcharts4/charts';
import { TsAmChartsService } from '@terminus-lib/ui-chart';
import { combineLatest, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import { IMarketingInfluenceReport, IMarketingInfluenceRequest, OpportunityType } from '../../interfaces';
import { ChartColors } from '../../marketing-influence.constants';
import { Period } from '@measurement-studio/enums';

interface ChartSegment {
    value: string;
    color: string;
}

@Component({
    selector: 'ms-marketing-chart',
    templateUrl: './marketing-chart.component.html',
    styleUrls: ['./marketing-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingChartComponent implements OnChanges, OnDestroy {
    @Input() chartData: IMarketingInfluenceReport[];
    @Input() reportFilters: IMarketingInfluenceRequest;

    chart: am4Charts.XYChart;
    isMonth: boolean;
    isDeal: boolean;

    private _subscription = new Subscription();

    constructor(
        private amChartsService: TsAmChartsService,
        private translate: TranslateService,
    ) {
        this.amChartsService.amCharts.core.options.commercialLicense = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isMonth = this.reportFilters?.period === Period.MONTH;
        this.isDeal = this.reportFilters?.opptyType === OpportunityType.DEAL;

        if (changes.chartData
            && !changes.chartData.isFirstChange()
            && JSON.stringify(changes.chartData.currentValue) !== JSON.stringify(changes.chartData.previousValue)) {
            this.initChart();
        }
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.dispose();
        }
        this._subscription.unsubscribe();
    }

    setUpChart(chart: am4Charts.XYChart): void {
        this.chart = chart;
        this.initChart();
    }

    initChart(): void {
        const xAxes = this.createXAxes();
        this.createYAxes();

        // set chart data
        this.chart.data = this.chartData;
        this.createCursor(xAxes);
        this.chart.series.clear();
        const segments = this.getSegments();
        // set chart colors
        this.chart.colors.list = segments.map(({ color }) => this.amChartsService.amCharts.core.color(color));
        // create series
        segments.forEach(segment => {
            this.createSeries(segment.value);
        });
        // add tooltip
        this.addTooltip(segments);
    }

    private createCursor(xAxes: am4Charts.DateAxis | am4Charts.CategoryAxis): void {
        this.chart.cursor = new this.amChartsService.amCharts.charts.XYCursor();
        this.chart.cursor.xAxis = xAxes;
        this.chart.cursor.fullWidthLineX = true;
        this.chart.cursor.lineX.strokeWidth = 0;
        this.chart.cursor.lineX.fill = this.amChartsService.amCharts.core.color('#637178');
        this.chart.cursor.lineX.fillOpacity = 0.15;
        this.chart.cursor.lineY.disabled = true;
    }

    private createXAxes(): am4Charts.DateAxis | am4Charts.CategoryAxis {
        this.chart.xAxes.clear();
        const intervalAxis = this.isMonth
            ? this.chart.xAxes.push(new this.amChartsService.amCharts.charts.DateAxis())
            : this.chart.xAxes.push(new this.amChartsService.amCharts.charts.CategoryAxis());
        if (this.isMonth) {
            (intervalAxis as am4Charts.DateAxis).chart.dateFormatter.inputDateFormat = 'MMM yyyy';
        } else {
            (intervalAxis as am4Charts.CategoryAxis).dataFields.category = 'month';
        }
        intervalAxis.renderer.cellStartLocation = 0.2;
        intervalAxis.renderer.cellEndLocation = 0.8;
        intervalAxis.cursorTooltipEnabled = false;
        return intervalAxis;
    }

    private createYAxes(): void {
        this.chart.yAxes.clear();
        const valueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
        valueAxis.numberFormatter.numberFormat = '$#a';
        valueAxis.tooltip.disabled = true;
    }

    private createSeries(value: string): am4Charts.ColumnSeries {
        const series = this.chart.series.push(new this.amChartsService.amCharts.charts.ColumnSeries());
        series.dataFields.valueY = value;
        if (this.isMonth) {
            series.dataFields.dateX = 'month';
        } else {
            series.dataFields.categoryX = 'month';
        }

        return series;
    }

    private getSegments(): ChartSegment[] {
        return [{
            value: 'value',
            color: this.isDeal ? ChartColors.DealValue : ChartColors.OpptyValue
        }, {
            value: 'marketingValue',
            color: this.isDeal ? ChartColors.DealMarketingValue : ChartColors.OpptyMarketingValue
        }];
    }

    // Add tooltip to first series only to avoid duplication
    private addTooltip(segments: ChartSegment[]): void {
        const seriesTooltip = this.chart.series.getIndex(0);
        const totalTooltip$ = this.translate.get(this.isDeal
            ? 'measurementStudio.features.marketingInfluence.chart.totalRevenue'
            : 'measurementStudio.features.marketingInfluence.chart.totalPipeline'
        );
        const influencedTooltip$ = this.translate.get(this.isDeal
            ? 'measurementStudio.features.marketingInfluence.chart.revenueInfluenced'
            : 'measurementStudio.features.marketingInfluence.chart.pipelineInfluenced'
        );
        const color = segments[0].color;
        const colorInfluenced = segments[1].color;

        if (!seriesTooltip) {
            return;
        }
        this._subscription.add(
            combineLatest([
                totalTooltip$,
                influencedTooltip$
            ]).pipe(
                tap(([totalTooltip, influencedTooltip]) => {
                    seriesTooltip.tooltipHTML = `
                        <div class="amcharts-tooltip">
                            <span class="title">{month}</span>
                            <hr class="report-divider">
                            <span class="subtitle">${ totalTooltip }</span></br>
                            <div class="section">
                                <div class="color" style="background-color: ${ color };"></div>
                                {value.formatNumber("$#,###.00")}
                            </div>
                            <hr class="report-divider">
                            <span class="subtitle">${ influencedTooltip }</span></br>
                            <div class="section">
                                <div class="color" style="background-color: ${ colorInfluenced };"></div>
                                {marketingValue.formatNumber("$#,###.00")}
                            </div>
                        </div>`;
                })
            ).subscribe()
        );
    }
}
