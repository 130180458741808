import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { storageProvider } from './local-storage.token';
import { LocalStorageService } from './local-storage.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    LocalStorageService
  ]
})
export class LocalStorageModule {
  static forRoot(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [{
        provide: storageProvider,
        useValue: window.localStorage
      }]
    };
  }
}
