import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DefaultTileInformation, TileConfigurationSteps } from '@shared/interfaces';

@Component({
  selector: 'tsh-preview-header',
  templateUrl: './preview-header.component.html',
  styleUrls: ['./preview-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewHeaderComponent {
  @Input() public step: TileConfigurationSteps;
  @Input() public tile: DefaultTileInformation;

  public readonly steps = TileConfigurationSteps;
}
