import {
  IMarketingInfluenceDeal,
  IMarketingInfluenceOppty,
  IMarketingInfluenceReport,
  IMarketingInfluenceRequest,
  OpportunityType
} from '../interfaces';
import { ClosedType, DateType, Period, WonType } from '@measurement-studio/enums';
import { DateCohortsGroups, InfluenceType, ModelType } from '@shared/enums';
import { addMonths, subDays } from 'date-fns';
import { IOpportunityRequest } from '@measurement-studio/interfaces';

export function modifyMarketingResponse(filter: IMarketingInfluenceRequest,
                                        item: IMarketingInfluenceOppty | IMarketingInfluenceDeal): IMarketingInfluenceReport {
    const type = filter.opptyType;
    const field = type === OpportunityType.OPPTY ? 'marketingOppty' : 'marketingDeal';
    const itemValue = item[type];
    const itemMarketingValue = item[field];
    // NOTE: sometimes backend returns only month value for item.
    // That's why we have to check if there is value for the item or not
    return {
        month: item.month,
        count: itemValue ? itemValue.count : 0,
        value: itemValue ? itemValue.value : 0,
        marketingCount: itemMarketingValue ? itemMarketingValue.count : 0,
        marketingValue: itemMarketingValue ? itemMarketingValue.value : 0,
        calculatedCount: !itemMarketingValue || !itemValue ? 0 : calculatePercentage(itemMarketingValue.count, itemValue.count),
        calculatedValue: !itemMarketingValue || !itemValue ? 0 : calculatePercentage(itemMarketingValue.value, itemValue.value),
        sortTime: getSortTime(filter.period, item.month)
    };
}

// Get percent and round to at most 2 decimal places (only if necessary)
// 51.1234 => 51.12
// 70 => 70
// 9.1 => 9.1
export function calculatePercentage(valueA: number, valueB: number): number {
    return valueA / valueB * 100;
    // return Math.round(percent * 100) / 100;
}

// Transform string to milliseconds so you can sort chronologically
// Month - 'Jan 2018' => to ms
// Quarter - 'Q1 2018' => to ms
export function getSortTime(period: Period, time: string): number {
    const parts = time.split(' ');

    if (period === Period.MONTH) {
        return new Date(Number(parts[1]), getMonthIndex(parts[0])).getTime();
    }

    return new Date(Number(parts[1]), getQuarterIndex(parts[0])).getTime();
}

export function getMonthIndex(name: string): number {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];
    return months.findIndex(month => {
        return month.startsWith(name);
    });
}

export function getQuarterIndex(name: string): number {
    switch (name) {
        case 'Q1':
            return 0;
        case 'Q2':
            return 3;
        case 'Q3':
            return 6;
        case 'Q4':
            return 9;
        default:
            return 0;
    }
}

/**
 * Get query params for the link to Opportunity Insight report
 * @param month
 * @param filters
 */
export function getOpportunityParams(month: string, filters: IMarketingInfluenceRequest): IOpportunityRequest {
    const params: IOpportunityRequest = {
        dateType: DateType.Closed,
        closedType: ClosedType.CLOSED,
        wonType: WonType.Won,
        model: filters.influenceType === InfluenceType.ANY
            ? ModelType.Even
            : filters.influenceType
    };
    // get particular month in custom range or quarter from the options list
    if (filters.period === Period.MONTH) {
        const startDate = getSortTime(Period.MONTH, month);
        const endDate = subDays(addMonths(startDate, 1), 1).getTime();
        params.cohort = DateCohortsGroups.Custom;
        params.startDate = startDate;
        params.endDate = endDate;
    } else {
        params.cohort = month.replace(' 20', '');
    }

    return params;
}
