import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { AuthContEffects } from '@auth-cont';
import { HubTokenEffects } from '@hub-token';
import { HubNavigationEffects } from '@hub-navigation';
import { UserState } from '@shared/data-access/user-common';
import * as PanicStore from '@panic';
import * as HubCookieStore from '@hub-cookie';
import * as NotificationMessagesStore from '@notification-messages';
import * as AppSettingsStore from '@app-settings/store';
import * as UserStore from '@user/store';
import * as HubRouterStore from '@hub-router';
import * as OrgConfigStore from '@org-config';
import * as NavMenusStore from '@nav-menus';
import * as CatalogsStore from '@shared/data-access/catalogs';
import * as DateCohortsStore from '@date-cohorts';
import * as CampaignsStore from '@shared/data-access/campaigns';
import * as AccountListStore from '@shared/data-access/account-hub';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as DashboardsStore from '@shared/data-access/dashboards';
import * as AggregateListsStore from '@shared/data-access/aggregate-lists';
import * as TileModalStore from '@shared/data-access/tile-modal';
import * as DemoConfigStore from '@shared/data-access/demo-config-state';

export interface SharedState {
  readonly panic: PanicStore.PanicState;
  readonly notificationMessages: NotificationMessagesStore.NotificationMessagesState;
  readonly appSettings: AppSettingsStore.AppSettingsState;
  readonly userState: UserState;
  readonly hubRouterState: RouterReducerState<HubRouterStore.HubRouterState>;
  readonly catalogs: CatalogsStore.CatalogsState;
  readonly organization: OrgConfigStore.OrgConfigState;
  readonly navMenu: NavMenusStore.NavMenusState;
  readonly dateCohorts: DateCohortsStore.DateCohortsState;
  readonly campaigns: CampaignsStore.State;
  readonly accountList: AccountListStore.State;
  readonly globalFiltersState: GlobalFiltersStore.GlobalFiltersState;
  readonly dashboards: DashboardsStore.DashboardsState;
  readonly aggregateLists: AggregateListsStore.AggregateListsState;
  readonly tileModal: TileModalStore.TileModalState;
  readonly demoConfigState: DemoConfigStore.DemoConfigState;
}

export const sharedReducers: ActionReducerMap<SharedState> = {
  panic: PanicStore.reducer,
  notificationMessages: NotificationMessagesStore.reducer,
  appSettings: AppSettingsStore.reducer,
  userState: UserStore.reducer,
  hubRouterState: routerReducer,
  catalogs: CatalogsStore.reducer,
  organization: OrgConfigStore.reducer,
  navMenu: NavMenusStore.reducer,
  dateCohorts: DateCohortsStore.reducer,
  campaigns: CampaignsStore.reducer,
  accountList: AccountListStore.reducer,
  globalFiltersState: GlobalFiltersStore.reducer,
  dashboards: DashboardsStore.reducer,
  aggregateLists: AggregateListsStore.reducer,
  tileModal: TileModalStore.reducer,
  demoConfigState: DemoConfigStore.demoConfigReducer,
};

export const sharedEffects = [
  PanicStore.PanicEffects,
  HubCookieStore.HubCookieEffects,
  AppSettingsStore.AppSettingsEffects,
  UserStore.UserEffects,
  HubRouterStore.HubRouterEffects,
  AuthContEffects,
  HubTokenEffects,
  CatalogsStore.CatalogsEffects,
  HubNavigationEffects,
  OrgConfigStore.OrgConfigEffects,
  NavMenusStore.NavMenusEffects,
  DateCohortsStore.DateCohortsEffects,
  CampaignsStore.CampaignsEffects,
  AccountListStore.AccountListEffects,
  GlobalFiltersStore.GlobalFiltersEffects,
  DashboardsStore.DashboardsEffects,
  AggregateListsStore.AggregateListsEffects,
  TileModalStore.TileModalEffects,
  DemoConfigStore.DemoConfigEffects
];

export const sharedInitialStateMock = {
  panic: PanicStore.initialState,
  notificationMessages: NotificationMessagesStore.initialState,
  appSettings: AppSettingsStore.initialState,
  userState: UserStore.initialState,
  catalogs: CatalogsStore.initialState,
  navMenu: NavMenusStore.initialState,
  organization: OrgConfigStore.initialState,
  campaigns: CampaignsStore.initialState,
  dateCohorts: DateCohortsStore.initialState,
  accountList: AccountListStore.initialState,
  globalFiltersState: GlobalFiltersStore.initialState,
  dashboards: DashboardsStore.initialState,
  tileModal: TileModalStore.initialState,
  demoConfig: DemoConfigStore.initialState
};
