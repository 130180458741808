import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { CreateListDialogComponent } from '../create-list-dialog/create-list-dialog.component';
import { DialogType, FolderPermission, AccountsListActionType } from '@shared/enums';
import { listPopupActions } from '../../state/list-popup.actions';
import { ConfirmationDialogComponent } from '@ui/components/confirmation-dialog';
import { IUserCrm, IAccountsFolderResponse, IAccountsFolderList } from '@shared/interfaces';
import { getAccountsFolders, getIsLoading } from '../../state/list-popup.selectors';

type AccountsFolderResponse = IAccountsFolderResponse & { change$?: BehaviorSubject<boolean | null>; };

@WithDestroy
@Component({
  selector: 'tsh-manage-lists-dialog',
  templateUrl: './manage-lists-dialog.component.html',
  styleUrls: ['./manage-lists-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageListsDialogComponent implements OnInit {
  public selectedList: IAccountsFolderList | null = null;
  public selectedFolder: IAccountsFolderResponse | null = null;
  public DialogType = DialogType;
  public FolderPermission = FolderPermission;
  public folders$ = this.store.pipe(select(getAccountsFolders));
  public isLoading$ = this.store.pipe(select(getIsLoading));

  public readonly AccountsListActionType = AccountsListActionType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogType: DialogType,
      accountIds?: string[],
      filters?: Record<string, string | number>,
      userCrm: IUserCrm
    },
    private dialog: MatDialog,
    private store: Store,
    public dialogRef: MatDialogRef<ManageListsDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(listPopupActions.loadFolders());
  }

  onExpandedChange(opened: boolean, folder: AccountsFolderResponse): void {
    if (opened) {
      this.selectedFolder = folder;
    }
    this.selectedList = null;
  }

  createFolder(): void {
    this.store.dispatch(listPopupActions.loadFolderUsers({ payload: null }));
  }

  createList(folders: AccountsFolderResponse[]): void {
    const dialogConfig: MatDialogConfig = {
      width: '446px',
      data: { foldersOptions: folders, selectedFolder: this.selectedFolder },
    };
    this.dialog.open(CreateListDialogComponent, dialogConfig).afterClosed().pipe(
      untilComponentDestroyed(this),
      filter((data) => data),
    ).subscribe((data: { name: string; folders: IAccountsFolderResponse[]; }) => {
      this.store.dispatch(listPopupActions.createList({
        payload: {
          name: data.name,
          folder_id: data.folders[0].folderId,
          account_ids: this.data.accountIds,
          filters: this.data.filters
        }
      }));
    });
  }

  deleteFolder(): void {
    const dialogConfig: MatDialogConfig = {
      width: '446px',
      data: {
        title: 'feature.accountHub.deleteFolderTitle',
        message: 'feature.accountHub.deleteFolderMessage',
        applyLabel: 'feature.accountHub.deleteFolderTitle',
        applyButtonTheme: 'warning',
        class: 'account-delete-confirmation'
      },
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().pipe(
      untilComponentDestroyed(this),
      tap(),
      filter(data => data),
    ).subscribe(() => {
      // TODO unfreeze this after delete folder feature will be migrated
      // this.store.dispatch(listPopupActions.deleteFolder({
      //   payload: {
      //     folder_id: this.selectedFolder.folderId,
      //     folder_type: 'account_list',
      //     folder_name: this.selectedFolder.folderName,
      //   }
      // }));
      this.selectedFolder = null;
      this.selectedList = null;
    });
  }

  deleteList(): void {
    const dialogConfig: MatDialogConfig = {
      width: '446px',
      data: {
        title: 'feature.accountHub.deleteListTitle',
        message: 'feature.accountHub.deleteListMessage',
        applyLabel: 'feature.accountHub.deleteListTitle',
        applyButtonTheme: 'warning',
        class: 'account-delete-confirmation'
      },
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().pipe(
      untilComponentDestroyed(this),
      filter(data => data),
    ).subscribe(() => {
      this.selectedList = null;
    });
  }

  editFolderProperties(): void {
    //TODO add function
  }

  editListProperties(folders: AccountsFolderResponse[]): void {
    const dialogConfig: MatDialogConfig = {
      width: '446px',
      data: {
        foldersOptions: folders,
        selectedFolder: this.selectedFolder,
        list: this.selectedList
      },
    };
    this.dialog.open(CreateListDialogComponent, dialogConfig).afterClosed().pipe(
      untilComponentDestroyed(this),
      filter(data => {
        return data
      }),
    ).subscribe((data: { name: string; folders: IAccountsFolderResponse[]; id: number }) => {
      //TODO add actions
    });
  }

  useCsvData(folders: AccountsFolderResponse[]): void {
    // TODO unfreeze this after useCSV feature migrated
    // const dialogConfig: MatDialogConfig = {
    //   width: '626px',
    //   data: {
    //     folders,
    //     userCrm: this.data?.userCrm,
    //   }
    // };
  }

  addToList() {
    this.store.dispatch(listPopupActions.addToList({
      payload: {
        name: this.selectedList?.name || '',
        id: this.selectedList?.id || '',
        ...this.data.filters
      }}
    ));
    this.dialogRef.close();
  }

  editList(list: IAccountsFolderList, folder: IAccountsFolderResponse): boolean {
    this.dialogRef.close({
      list: {
        ...list,
        folder_id: folder.folderId,
        folder_name: folder.folderName
      },
      actionType: AccountsListActionType.EditList
    });
    return false;
  }
}
