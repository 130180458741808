import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import { dashboardsFeatureKey, DashboardsState } from './dashboards.reducer';
import { applyPageToReportData, searchByField, sortReportData } from '@util/helpers';
import { IDashboard, IReportPagination } from '@shared/interfaces';

export const selectDashboardsState = createFeatureSelector<DashboardsState>(dashboardsFeatureKey);

export const getList = createSelector(selectDashboardsState, (state: DashboardsState) => state.list);

export const getIsListLoading = createSelector(selectDashboardsState, (state: DashboardsState) => state.isListLoading);

export const getSortState = createSelector(selectDashboardsState, (state: DashboardsState) => state.sortState);

export const getPagination = createSelector(selectDashboardsState, (state: DashboardsState) => state.pagination);

export const getSearchQuery = createSelector(selectDashboardsState, (state: DashboardsState) => state.searchQuery);

export const getVisibility = createSelector(selectDashboardsState, (state: DashboardsState) => state.visibility);

export const getSearchedList = createSelector(getList,
  getSearchQuery,
  (state: IDashboard[], searchQuery: string) => searchQuery ? searchByField(state, 'name', searchQuery) : state
);

export const getTotalPublicCount = createSelector(getSearchedList,
  (state: IDashboard[]) => state.filter(item => item.isPublic).length
);

export const getTotalPrivateCount = createSelector(getSearchedList,
  (state: IDashboard[]) => state.filter(item => !item.isPublic).length
);

export const getDetailsId = createSelector(selectDashboardsState,
  (state: DashboardsState) => state.detailsId
);

export const getDetails = createSelector(getDetailsId,
  getList,
  (id: string, list: IDashboard[]) => list.find(item => item?.id === id) || null
);

export const getIsDetailsLoading = createSelector(selectDashboardsState,
  (state: DashboardsState) => state.isDetailsLoading
);

export const getPreparedList = createSelector(
  getSearchedList,
  getSortState,
  getPagination,
  getVisibility,
  (list: IDashboard[], sortState: TsSortState, pagination: IReportPagination, visibility: boolean | null) => {
    const items = visibility === null ? list : list.filter(item => item.isPublic === visibility);
    const sortedItems = sortReportData<IDashboard>(items, sortState);
    return applyPageToReportData<IDashboard>(sortedItems, pagination.page);
  });

export const getTableTotalCount = createSelector(getSearchedList,
  getVisibility,
  (state: IDashboard[], visibility: boolean | null) =>
    (visibility === null ? state : state.filter(item => item.isPublic === visibility)).length
);

export const getSidebarTotalCount = createSelector(getSearchedList, (state: IDashboard[]) => state.length);
