import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TsAmChartsService } from '@terminus-lib/ui-chart';

import * as am4Charts from '@amcharts/amcharts4/charts';
import { ChartColors } from '../../enums/chart-colors.enum';
import { IFlatStageProgression } from '../../interfaces';

@Component({
  selector: 'ms-stages-snapshot-chart',
  templateUrl: './stages-snapshot-chart.component.html',
  styleUrls: ['./stages-snapshot-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StagesSnapshotChartComponent implements OnChanges {
  @Input() public chartData: IFlatStageProgression[];

  public chart: am4Charts.XYChart;

  constructor(private amChartsService: TsAmChartsService) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData
      && this.chart
      && JSON.stringify(changes.chartData.currentValue) !== JSON.stringify(changes.chartData.previousValue)) {
      this.initChart();
    }
  }


  setUpChart(chart: am4Charts.XYChart): void {
    this.chart = chart;
    this.initChart();
  }

  initChart(): void {
    const xAxes = this.createXAxes();
    this.chart.data = this.chartData;
    this.chart.yAxes.clear();
    this.chart.series.clear();
    this.chart.colors.list = [
      this.amChartsService.amCharts.core.color(ChartColors.Lead),
      this.amChartsService.amCharts.core.color(ChartColors.Oppty),
    ];
    this.createSeries('lead',
      this.createYAxes('Leads', false, '$#a'),
      ChartColors.Lead);
    this.createSeries('oppty',
      this.createYAxes('Opportunities', true, '#a'),
      ChartColors.Oppty);
    this.createCursor(xAxes);
  }

  private createXAxes(): am4Charts.CategoryAxis {
    this.chart.xAxes.clear();
    const xAxis = this.chart.xAxes.push(new this.amChartsService.amCharts.charts.CategoryAxis());
    xAxis.dataFields.category = 'name';
    xAxis.cursorTooltipEnabled = false;
    return xAxis;
  }

  private createYAxes(name: string, opposite: boolean, numberFormat: string): am4Charts.ValueAxis {
    const valueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
    valueAxis.numberFormatter.numberFormat = numberFormat;
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = name;
    valueAxis.title.fill = this.amChartsService.amCharts.core.color('#637178');
    valueAxis.renderer.opposite = opposite;
    return valueAxis;
  }

  private createCursor(xAxes: am4Charts.CategoryAxis): void {
    this.chart.cursor = new this.amChartsService.amCharts.charts.XYCursor();
    this.chart.cursor.xAxis = xAxes;
    this.chart.cursor.fullWidthLineX = true;
    this.chart.cursor.lineX.strokeWidth = 0;
    this.chart.cursor.lineX.fill = this.amChartsService.amCharts.core.color('#ffffff');
    this.chart.cursor.lineX.fillOpacity = 0.12;
    this.chart.cursor.lineY.disabled = true;
  }

  private createSeries(value: string, valueAxis: am4Charts.ValueAxis, color: string): am4Charts.ColumnSeries {
    const series = this.chart.series.push(new this.amChartsService.amCharts.charts.ColumnSeries());
    series.dataFields.valueY = value;
    series.dataFields.categoryX = 'name';
    series.yAxis = valueAxis;
    series.clustered = false;
    series.columns.template.width = this.amChartsService.amCharts.core.percent(50);
    series.tooltipHTML = `
                        <div class="amcharts-tooltip">
                            <span class="title">{name}</span>
                            <hr class="report-divider">
                            <span class="subtitle">${valueAxis.title.text}</span></br>
                            <div class="section">
                                <div class="color" style="background-color: ${color};"></div>
                                {valueY.formatNumber("#,###.")}
                            </div>
                        </div>`;
    return series;
  }
}
