import { IFactoryParams } from './factory-params.interface';

export interface ILabelValue<T = string> {
    label: string;
    value: T;
}

export interface ILabelValueChecked<T = string> extends ILabelValue<T> {
    checked: boolean;
}

export function labelValueFactory({
    n = 0,
    overrides = {}
}: IFactoryParams<ILabelValue, number> = {}): ILabelValue {
    return {
        label: `label${ n }`,
        value: `value${ n }`,
        ...overrides
    };
}

export function labelValueCheckedFactory({
    n = 0,
    overrides = {},
    defaultValue = true
}: IFactoryParams<ILabelValueChecked, number, boolean> = {}): ILabelValueChecked {
    return {
        label: `label${ n }`,
        value: `value${ n }`,
        checked: defaultValue,
        ...overrides
    };
}

export function labelValueNumberFactory({
    n = 0,
    overrides = {}
}: IFactoryParams<ILabelValue<number>, number> = {}): ILabelValue<number> {
    return {
        label: `label${ n }`,
        value: n,
        ...overrides
    };
}
