import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import {
  IAttributionCampaignExpandedRow, IAttributionCampaignRow,
  IAttributionCampaignTotals
} from '../../interfaces/attribution-campaign-report.interface';
import { DataTypeEnum } from '@shared/enums';
import { ICampaignFilters } from '../../interfaces/campaign-filters.interface';
import { GoParams, IReportColumn } from '@shared/interfaces';
import { TsSortState } from '@terminus-lib/ui-sort';
import { IAttributionCampaignPagination } from '../../interfaces/attribution-campaign-pagination.interface';

@Component({
  selector: 'ms-attribution-campaign-table',
  templateUrl: './attribution-campaign-table.component.html',
  styleUrls: ['./attribution-campaign-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributionCampaignTableComponent implements OnChanges {
  @Input() public report: IAttributionCampaignRow[];
  @Input() public sortState: TsSortState;
  @Input() public colors: Record<string, string>;
  @Input() public currency: string | null;
  @Input() public expandedSortState: Record<string, TsSortState>;
  @Input() public expandedReport: Record<string, IAttributionCampaignExpandedRow[]>;
  @Input() public expandedRowState: Record<string, boolean>;
  @Input() public expandedReportTotals: Record<string, number>;
  @Input() public expandedReportPagination: Record<string, IAttributionCampaignPagination>;
  @Input() public filters: ICampaignFilters;
  @Input() public isLoading: boolean;
  @Input() public totals: IAttributionCampaignTotals;
  @Input() public columns: IReportColumn[] = [];
  @Input() public expandedColumns: IReportColumn[] = [];
  @Output() public toggleExpand = new EventEmitter<string>();
  @Output() public navigate = new EventEmitter<GoParams>();
  @Output() public changeSort = new EventEmitter<TsSortState>();
  @Output() public changeExpandedSort = new EventEmitter<{ state: TsSortState, key: string }>();
  @Output() public changeExpandedPage = new EventEmitter<{ page: number, key: string }>();
  @Output() public downloadExpanded = new EventEmitter<{ key: string; label: string }>();
  @Output() public download = new EventEmitter<void>();

  public dataSource = new TsTableDataSource<IAttributionCampaignRow>();
  public dataTypes = DataTypeEnum;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.report
      && changes.report.currentValue
      && changes.report.currentValue !== changes.report.previousValue) {
      this.dataSource.data = this.report;
      this.changeDetector.detectChanges(); // to fix table width issue
    }

    if (changes.totals) {
      this.changeDetector.detectChanges(); // to fix table width issue
    }
  }

  emitToggleExpand(event: MouseEvent, key: string): void {
    event.stopPropagation();
    this.toggleExpand.emit(key);
  }

  emitNavigate(route: GoParams, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.navigate.emit(route);
  }

  emitChangeSort(sortState: TsSortState): void {
    this.changeSort.emit(sortState);
  }

  emitChangeExpandedSortState(state: TsSortState, key: string): void {
    this.changeExpandedSort.emit({
      state,
      key
    });
  }

  emitChangePage(page: number, key: string): void {
    this.changeExpandedPage.emit({
      page,
      key
    });
  }

  emitDownloadExpanded(key: string, label: string): void {
    this.downloadExpanded.emit({
      key,
      label
    });
  }

  emitDownload(): void {
    this.download.emit();
  }
}
