/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';

import { IWebActivitiesTypes, IWfStage } from '../interfaces';
import { defineTypeEnum } from '@terminus-lib/fe-utilities';

export const CatalogsActionsTypes = {
  LoadWebActivitiesTypes: '[Catalogs] Load Web Activities Types',
  LoadWebActivitiesTypesSuccess: '[Catalogs] Load Web Activities Types Success',
  LoadWebActivitiesTypesFailure: '[Catalogs] Load Web Activities Types Failure',
  LoadWfStages: '[Catalogs] Load stages',
  LoadWfStagesSuccess: '[Catalogs] Load stages Success',
  LoadWfStagesFailure: '[Catalogs] Load stages Failure',
};

defineTypeEnum(CatalogsActionsTypes);

const loadWebActivitiesTypes = createAction(
  CatalogsActionsTypes.LoadWebActivitiesTypes,
);

const loadWebActivitiesTypesSuccess = createAction(
  CatalogsActionsTypes.LoadWebActivitiesTypesSuccess,
  props<{ data: IWebActivitiesTypes }>()
);

const loadWebActivitiesTypesFailure = createAction(
  CatalogsActionsTypes.LoadWebActivitiesTypesFailure,
  props<{ message: string }>()
);

const loadWfStages = createAction(
  CatalogsActionsTypes.LoadWfStages,
);

const loadWfStagesSuccess = createAction(
  CatalogsActionsTypes.LoadWfStagesSuccess,
  props<{ data: IWfStage[] }>()
);

const loadWfStagesFailure = createAction(
  CatalogsActionsTypes.LoadWfStagesFailure,
  props<{ message: string }>()
);

export const catalogsActions = {
  loadWebActivitiesTypes,
  loadWebActivitiesTypesSuccess,
  loadWebActivitiesTypesFailure,
  loadWfStages,
  loadWfStagesSuccess,
  loadWfStagesFailure,
}
