import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TsButtonModule } from '@terminus-lib/ui-button';

import { AdImpressionsValueType } from '../../enums';

@Component({
  selector: 'ms-ad-impressions-filter',
  templateUrl: './ad-impressions-filter.component.html',
  styleUrls: ['./ad-impressions-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdImpressionsFilterComponent {
  @Input()
  public set rangeValue({min, max}) {
    const formMin = this.form.get('rangeMin')?.value;
    const formMax = this.form.get('rangeMax')?.value;
    if ((min && !max && 0 < +min && formMin !== min) || (min && max && +min < +max && formMax !== max)) {
      this.form.patchValue({
        type: min ? AdImpressionsValueType.Range : AdImpressionsValueType.Default,
        rangeMin: min,
        rangeMax: max || ''
      });
      this.changed.emit([min, max]);
    }
  }

  @Output() public cancelled = new EventEmitter<void>();

  // Changes are emitted as an array, first element is minimum, second element is maximum
  @Output() public changed = new EventEmitter<[number, number]>();

  public readonly AdImpressionsValueType = AdImpressionsValueType;
  public readonly adImpressionsLabelDefault = 'measurementStudio.features.advertisingInsights.settings.adImpressionsGreaterThanZero';
  public readonly adImpressionsLabelRange = 'measurementStudio.features.advertisingInsights.settings.adImpressionsRange';
  public form = this.formBuilder.group({
    type: [AdImpressionsValueType.Default, Validators.required],
    rangeMin: [''],
    rangeMax: ['']
  }, {
    validators: this.validateRange
  });

  constructor(private formBuilder: FormBuilder) {
  }

  public validateRange(group: FormGroup): { [key: string]: string | boolean } | null {
    const type = group.get('type').value;
    if (type === AdImpressionsValueType.Range) {
      const min = group.get('rangeMin').value;
      const max = group.get('rangeMax').value;

      if (!min && !max) {
        return {required: true};
      }

      // Ensure that a minimum value is entered if a maximum value has been entered
      // and range minimum is greater than zero if a value has been entered
      if (!min || (min !== '' && +min < 1)) {
        return {errorMsg: 'measurementStudio.features.advertisingInsights.settings.adImpressionsRangeMinError'};
      }

      // Ensure that a maximum value is entered f a minimum value has been entered
      if (!max) {
        return {errorMsg: 'measurementStudio.features.advertisingInsights.settings.adImpressionsRangeMaxError'};
      }

      if (+max < +min) {
        return {errorMsg: 'measurementStudio.features.advertisingInsights.settings.adImpressionsRangeMaxError'};
      }
    }

    return null;
  }

  public onConfirm(): void {
    if (this.form.invalid) {
      return;
    }
    const values = {...this.form.value};
    if (values['type'] === AdImpressionsValueType.Default) {
      this.form.patchValue({
        rangeMin: '',
        rangeMax: ''
      });
      return this.changed.emit([0, 0]);
    } else {
      return this.changed.emit([Number(values['rangeMin']), Number(values['rangeMax'])]);
    }
  }
}

@NgModule({
  declarations: [
    AdImpressionsFilterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    TsInputModule,
    TsRadioGroupModule,
    ReactiveFormsModule,
    TsButtonModule
  ],
  exports: [
    AdImpressionsFilterComponent
  ]
})
export class AdImpressionsFilterModule {
}

