export enum OpportunityColumnsFields {
  Name = 'name',
  AccountName = 'accountName',
  Owner = 'owner',
  Revenue = 'revenue',
  ConfiguredStage = 'configuredStage',
  CreatedDate = 'createdDate',
  CloseDate = 'closeDate',
  OtdVelocity = 'otdVelocity',
  SourceCampaignName = 'sourceCampaignName',
  SourceCampaignGroup = 'sourceCampaignGroup',
  LastCampaignName = 'lastCampaignName',
  LastTouchCampaignGroup = 'lastTouchCampaignGroup',
  UniqueCampaigns = 'uniqueCampaigns',
  OpptyContactCount = 'opptyContactCount',
  AccountContactCount = 'accountContactCount',
  ContactRolesEngaged = 'contactRolesEngaged',
  ContactCountEngaged = 'contactCountEngaged',
  LeadSource = 'leadSource',
  PreCreateAttributions = 'preCreateAttributions',
  PostCreateAttributions = 'postCreateAttributions',
  CampaignAttributions = 'campaignAttributions',
  Type = 'type',
  CompanyScore = 'companyScore',
  IntentScore = 'intentScore',
  IntentTopics = 'intentTopics',
  LastIntentDate = 'lastIntentDate',
  FirstEmail = 'firstEmail',
  LastEmail = 'lastEmail',
}
