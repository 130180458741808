import { createAction, props } from '@ngrx/store';
import { AggregateList } from '@shared/interfaces';

export const AggregateListsActionTypes = {
  LoadAggregateLists: '[AggregateLists] Load AggregateLists',
  LoadAggregateListsSuccess: '[AggregateLists] Load AggregateLists Success',
  LoadAggregateListsFailure: '[AggregateLists] Load AggregateLists Failure',
  ResetAggregateLists: '[AggregateLists] Reset AggregateLists Failure',
  LoadAggregateSegments: '[AggregateSegments] Load AggregateSegments',
  LoadAggregateSegmentsSuccess: '[AggregateSegments] Load AggregateSegments Success',
  LoadAggregateSegmentsFailure: '[AggregateSegments] Load AggregateSegments Failure',
}

const loadAggregateLists = createAction(AggregateListsActionTypes.LoadAggregateLists);

const loadAggregateListsSuccess = createAction(
  AggregateListsActionTypes.LoadAggregateListsSuccess,
  props<{ data: AggregateList[] }>()
);

const loadAggregateListsFailure = createAction(
  AggregateListsActionTypes.LoadAggregateListsFailure,
  props<{ message: string }>()
);

const loadAggregateSegments = createAction(AggregateListsActionTypes.LoadAggregateSegments);

const loadAggregateSegmentsSuccess = createAction(
  AggregateListsActionTypes.LoadAggregateSegmentsSuccess,
  props<{ data: AggregateList[] }>()
);

const loadAggregateSegmentsFailure = createAction(
  AggregateListsActionTypes.LoadAggregateListsFailure,
  props<{ message: string }>()
);

const resetAggregateLists = createAction(AggregateListsActionTypes.ResetAggregateLists);

export const aggregateListsActions = {
  loadAggregateLists,
  loadAggregateSegments,
  loadAggregateListsSuccess,
  loadAggregateSegmentsSuccess,
  loadAggregateListsFailure,
  loadAggregateSegmentsFailure,
  resetAggregateLists,
}
