import { Inject, LOCALE_ID, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTypeEnum } from '@shared/enums';
import { formatDataByDataType } from '@shared/util/data-type';

@Pipe({name: 'typedData'})
export class TypedDataPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: number | Date | string | string[] | boolean,
            dataType: DataTypeEnum,
            decimals: number | null = null,
            shouldAbbreviate: boolean = true,
            currencySetting?: string,
            dateFormat?: string): string {
    return formatDataByDataType(value, dataType, this.locale, decimals, shouldAbbreviate, currencySetting, dateFormat);
  }
}

@NgModule({
  declarations: [TypedDataPipe],
  exports: [TypedDataPipe],
  imports: [CommonModule]
})
export class TypedDataModule {
}
