<label class="c-input__label-text" [for]="id">{{ label }}</label>

<div class="ts-input__input-container">
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
  <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
  <!-- Catch all outlet in case a consumer needs to add element(s) inside the container -->
  <ng-content></ng-content>
</div>

<div class="ts-input__messages" *ngIf="!noValidationOrHint">
  <div class="ts-input__error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="ts-input__hint" *ngIf="hint && !errorMessage">{{ hint }}</div>
</div>

<!-- Inner element proper -->
<ng-template #contentTemplate>
  <ng-container *ngIf="!isTextarea && !datepicker">
    <input
      class="c-input__text ts-form-field-autofill-control"
      [class.c-input__text--error]="!!errorMessage"
      [class.c-input__text--small]="isSmall"
      [attr.type]="type"
      [attr.aria-required]="isRequired"
      [attr.aria-describedby]="ariaDescribedby || null"
      [attr.autocapitalize]="autocapitalize ? 'on' : 'off'"
      [attr.autocomplete]="autocomplete"
      [attr.autofocus]="isFocused ? '' : null"
      [disabled]="shouldBeDisabled"
      [attr.name]="name"
      [attr.id]="id"
      [readonly]="readOnly"
      [required]="isRequired ? 'required' : null"
      [attr.spellcheck]="spellcheck"
      [attr.placeholder]="placeholder"
      tabIndex="{{ tabIndex }}"
      [(ngModel)]="value"
      (blur)="focusChanged(false)"
      (focus)="focusChanged(true)"
      (input)="onInput($event)"
      (paste)="inputPaste.emit($event)"
      #inputElement
    >
  </ng-container>

  <ng-container *ngIf="!isTextarea && datepicker">
    <input
      class="c-input__text c-input__text--datepicker ts-form-field-autofill-control"
      [class.c-input__text--error]="!!errorMessage"
      [class.c-input__text--small]="isSmall"
      [attr.type]="type"
      [attr.aria-required]="isRequired"
      [attr.aria-describedby]="ariaDescribedby || null"
      [attr.autocapitalize]="autocapitalize ? 'on' : 'off'"
      [attr.autocomplete]="autocomplete"
      [attr.autofocus]="isFocused ? '' : null"
      [disabled]="shouldBeDisabled"
      [attr.name]="name"
      [attr.id]="id"
      [readonly]="readOnly"
      [required]="isRequired ? 'required' : null"
      [attr.spellcheck]="spellcheck"
      tabIndex="{{ tabIndex }}"
      [(ngModel)]="value"
      (blur)="focusChanged(false)"
      (focus)="focusChanged(true)"
      (input)="onInput($event)"
      [matDatepickerFilter]="dateFilter"
      [matDatepicker]="picker"
      [min]="minDate"
      [max]="maxDate"
      (dateChange)="onDateChanged($event.value)"
      (paste)="inputPaste.emit($event)"
      #inputElement
    >
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      class="c-datepicker__calendar"
      [disabled]="shouldBeDisabled"
      [startView]="startingView"
      [startAt]="openTo"
      [opened]="datepickerDefaultOpenSubscription$ | async"
      (opened)="onDatePickerOpened()"
      (closed)="onDatePickerClosed()"
      #picker
    ></mat-datepicker>
  </ng-container>

  <ng-container *ngIf="isTextarea">
    <textarea
      class="c-input__text c-input__text--textarea ts-form-field-autofill-control"
      [class.c-input__text--error]="!!errorMessage"
      [class.c-input__text--small]="isSmall"
      [attr.type]="type"
      [attr.aria-required]="isRequired"
      [attr.aria-describedby]="ariaDescribedby || null"
      [attr.autocapitalize]="autocapitalize ? 'on' : 'off'"
      [attr.autocomplete]="autocomplete"
      [attr.autofocus]="isFocused ? '' : null"
      [disabled]="shouldBeDisabled"
      [attr.name]="name"
      [attr.id]="id"
      [attr.rows]="textareaRows"
      [readonly]="readOnly"
      [required]="isRequired ? 'required' : null"
      [attr.spellcheck]="spellcheck"
      tabIndex="{{ tabIndex }}"
      [(ngModel)]="value"
      (blur)="focusChanged(false)"
      (focus)="focusChanged(true)"
      (input)="onInput($event)"
      (paste)="inputPaste.emit($event)"
      #inputElement
    ></textarea>
  </ng-container>
</ng-template>

<ng-template #clearIconTemplate>
  <span
    class="c-input__clear"
    [class.c-input__clear--small]="isSmall"
    *ngIf="isClearable && !shouldBeDisabled && !datepicker"
    [class.c-input__clear--visible]="!empty"
    (click)="reset()"
  >
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times-circle"
      class="svg-inline--fa fa-times-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
      ></path>
    </svg>
  </span>
</ng-template>
