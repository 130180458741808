import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import * as DateCohortsStore from '@date-cohorts';
import { dateCohortsActions } from '@date-cohorts';
import * as OrgConfigStore from '@org-config';
import { generalActions } from '../../../state/general/general.actions';
import * as generalSelectors from '../../../state/general/general.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { IAppliedGlobalFiltersAsParams, IDateCohort } from '@shared/interfaces';
import { InfluenceType, ModelType, ToDate } from '@shared/enums';
import { DataSetType } from '@measurement-studio/enums';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { getCampaignAnalyticsIsLoading } from '../../../state/selectors';
import { AnalyticsColumnName as Column, AnalyticTypes } from '../../../enums';
import { IStoredColumns } from '../../../interfaces';
import { CAMPAIGN_COLUMNS, CAMPAIGN_GROUPS_COLUMNS, CAMPAIGNS_COLUMNS, MEDIUMS_COLUMNS, SOURCE_COLUMNS } from '../../../data';
import { ColumnsSaverService, LocalStorageColumnsKey as Key } from '@shared/data-access/columns-saver';
import { hasNestedTable } from '../../../data/shared-columns.data';

@WithDestroy
@Component({
  selector: 'ms-campaign-analytics',
  templateUrl: './campaign-analytics.component.html',
  styleUrls: ['./campaign-analytics.component.scss']
})
export class CampaignAnalyticsComponent implements OnInit {
  public modelTypeOptions$ = this.store.pipe(select(OrgConfigStore.getUserModels));
  public selectedDateCohort$ = this.store.pipe(select(generalSelectors.getSelectedDateCohort));
  public dateCohortOptions$ = this.store.pipe(select(DateCohortsStore.getGroupedDateCohortOptions()));
  public filters$ = this.store.pipe(select(generalSelectors.getFilters));
  public tabs$ = this.store.pipe(select(generalSelectors.getCampaignAnalyticsCountableTabs));
  public isLoading$ = this.store.pipe(select(getCampaignAnalyticsIsLoading));
  public isDownloadInProcess$ = this.store.pipe(select(generalSelectors.getIsDownloadInProcess));

  public defaultQueryParams: IAnalyticsRequest = {
    influenceType: InfluenceType.ANY,
    cohort: {cohort: ToDate.QUARTER_TO_DATE},
    dataSet: DataSetType.MembershipActivity,
    model: ModelType.Even,
    isDriver: false,
    sorter: {
      sortDirection: 'asc',
      sortField: 'responses',
    },
    pager: {
      pageNum: 1,
      pageSize: 10,
    },
    globalFilters: [],
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<unknown>,
              private columnsSaverService: ColumnsSaverService) {
  }

  ngOnInit(): void {
    this.setReportType();
    this.getColumns();
    this.getDateCohorts();
    this.setFilters();
  }

  setReportType(): void {
    this.store.dispatch(generalActions.setReportType({reportType: AnalyticTypes.CampaignAnalytics}));
  }

  getColumns(): void {
    const columns: IStoredColumns = this.getAnalyticsColumns();
    this.store.dispatch(generalActions.setColumns({columns}));
  }

  getDateCohorts(): void {
    this.store.dispatch(dateCohortsActions.loadDateCohorts());
  }

  changeSelectedDateCohort(dateCohort: IDateCohort): void {
    this.store.dispatch(generalActions.setSelectedDateCohort({cohort: dateCohort}));
  }

  setFilters(): void {
    this.route.queryParams.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(
        this.filters$.pipe(take(1)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1)),
      )
    ).subscribe(([params, filters, globalFilters]: [Params, IAnalyticsRequest, IAppliedGlobalFiltersAsParams]) => {
      if (!Object.keys(params).length && !filters) {
        // if there is no any query params then use default params
        this.applyFilters(this.defaultQueryParams, globalFilters);
        return;
      }

      if (!Object.keys(params).length && filters) {
        // if there is no any query params but filters in the store then use them
        this.applyFilters(filters, globalFilters);
        return;
      }

      // NOTE: get filter only after setting model types
      // because we need them to check field param
      this.modelTypeOptions$.pipe(
        untilComponentDestroyed(this),
        filter(options => !!options?.length)
      ).subscribe(() => this.store.dispatch(generalActions.getFilters({params})));
    });
  }

  applyFilters(newFilters: IAnalyticsRequest, globalFilters?: IAppliedGlobalFiltersAsParams): void {
    const queryParams = this.assembleFiltersAsQueryParams(newFilters, globalFilters);
    this.router.navigate([], {
      queryParams,
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  private assembleFiltersAsQueryParams(newFilters: IAnalyticsRequest, globalFiltersParams?: IAppliedGlobalFiltersAsParams): Params {
    return {
      ...globalFiltersParams ? globalFiltersParams : null,
      influenceType: newFilters.influenceType,
      dataSet: newFilters.dataSet,
      model: newFilters.model,
      cohort: newFilters.cohort.cohort,
      startDate: newFilters.cohort.startDate,
      endDate: newFilters.cohort.endDate,
      gf: newFilters.typeFilter,
    };
  }

  private getAnalyticsColumns(): IStoredColumns {
    const campaign = [hasNestedTable].concat(this.columnsSaverService.getColumns(Key.Campaign, CAMPAIGN_COLUMNS, []));
    const campaigns = [hasNestedTable].concat(this.columnsSaverService.getColumns(Key.Campaigns, CAMPAIGNS_COLUMNS, []));
    const campaignGroups = [hasNestedTable].concat(this.columnsSaverService.getColumns(Key.CampaignGroups, CAMPAIGN_GROUPS_COLUMNS, []));
    const source = [hasNestedTable].concat(this.columnsSaverService.getColumns(Key.Source, SOURCE_COLUMNS, []));
    const mediums = this.columnsSaverService.getColumns(Key.Mediums, MEDIUMS_COLUMNS, [Column.HasNestedTable]);
    return {
      campaign,
      campaigns,
      campaignGroups,
      source,
      mediums
    };
  }
}
