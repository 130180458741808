/* istanbul ignore file */
import { Action, createAction, props } from '@ngrx/store';

import * as interfaces from '../../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { CampaignActionsTypes } from '../campaign/campaign.actions';
import { INestedTableEvent } from '@measurement-studio/ui/analytics-tables';
import { IPager, ISorter } from '@shared/interfaces';
import { TypedAction } from '@ngrx/store/src/models';

export enum CampaignGroupsActionsTypes {
  SetUsedParams = '[CA Campaign Groups] Set used params in driver request',
  GetCampaignGroups = '[CA Campaign Groups] Get all data',
  HasWebActivity = '[CA Campaign Groups] Has Web Activity',
  HasWebActivitySuccess = '[CA Campaign Groups] Has Web Activity Success',
  HasWebActivityFailure = '[CA Campaign Groups] Has Web Activity Failure',
  ToggleTableRow = '[CA Campaign Groups] Toggle Table Row',
  CloseNestedTables = '[CA Campaign Groups] Close Nested Tables',
  LoadTypeMetaData = '[CA Campaign Groups] Load Type MetaData',
  LoadTypeMetaDataSuccess = '[CA Campaign Groups] Load Type MetaData Success',
  LoadTypeMetaDataFailure = '[CA Campaign Groups] Load Type MetaData Failure',
  LoadTypeResponses = '[CA Campaign Groups] Load Type Responses',
  LoadTypeResponsesSuccess = '[CA Campaign Groups] Load Type Responses Success',
  LoadTypeResponsesFailure = '[CA Campaign Groups] Load Type Responses Failure',
  LoadTypeAttribution = '[CA Campaign Groups] Load Type Attribution',
  LoadTypeAttributionSuccess = '[CA Campaign Groups] Load Type Attribution Success',
  LoadTypeAttributionFailure = '[CA Campaign Groups] Load Type Attribution Failure',
  LoadTypeInfluence = '[CA Campaign Groups] Load Type Influence',
  LoadTypeInfluenceSuccess = '[CA Campaign Groups] Load Type Influence Success',
  LoadTypeInfluenceFailure = '[CA Campaign Groups] Load Type Influence Failure',
  LoadTypeReturns = '[CA Campaign Groups] Load Type Returns',
  LoadTypeReturnsSuccess = '[CA Campaign Groups] Load Type Returns Success',
  LoadTypeReturnsFailure = '[CA Campaign Groups] Load Type Returns Failure',
  LoadTypeCosts = '[CA Campaign Groups] Load Type Costs',
  LoadTypeCostsSuccess = '[CA Campaign Groups] Load Type Costs Success',
  LoadTypeCostsFailure = '[CA Campaign Groups] Load Type Costs Failure',
}

const setUsedParams = createAction(
  CampaignGroupsActionsTypes.SetUsedParams,
  props<{ request: IAnalyticsRequest }>()
);

const getCampaignGroups = createAction(
  CampaignGroupsActionsTypes.GetCampaignGroups,
);

const hasWebActivity = createAction(
  CampaignGroupsActionsTypes.HasWebActivity,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const hasWebActivitySuccess = createAction(
  CampaignGroupsActionsTypes.HasWebActivitySuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignHasWebActivity>>()
);

const hasWebActivityFailure = createAction(
  CampaignGroupsActionsTypes.HasWebActivityFailure,
  props<{ message: string }>()
);

const toggleTableRow = createAction(
  CampaignGroupsActionsTypes.ToggleTableRow,
  props<{ id: string }>()
);

const closeNestedTables = createAction(
  CampaignGroupsActionsTypes.CloseNestedTables,
);

const loadTypeMetaData = createAction(
  CampaignGroupsActionsTypes.LoadTypeMetaData,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeMetaDataSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeMetaDataSuccess,
  props<interfaces.ISuccessPayload<interfaces.IMetaData<interfaces.ICampaignTypeResponse>>>()
);

const loadTypeMetaDataFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeMetaDataFailure,
  props<{ message: string }>()
);

const loadTypeResponses = createAction(
  CampaignGroupsActionsTypes.LoadTypeResponses,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeResponsesSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeResponsesSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignResponses>>()
);

const loadTypeResponsesFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeResponsesFailure,
  props<{ message: string }>()
);

const loadTypeAttribution = createAction(
  CampaignGroupsActionsTypes.LoadTypeAttribution,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeAttributionSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeAttributionSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignAttribution>>()
);

const loadTypeAttributionFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeAttributionFailure,
  props<{ message: string }>()
);

const loadTypeInfluence = createAction(
  CampaignGroupsActionsTypes.LoadTypeInfluence,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeInfluenceSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeInfluenceSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignInfluence>>()
);

const loadTypeInfluenceFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeInfluenceFailure,
  props<{ message: string }>()
);

const loadTypeReturns = createAction(
  CampaignGroupsActionsTypes.LoadTypeReturns,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeReturnsSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeReturnsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignReturns>>()
);

const loadTypeReturnsFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeReturnsFailure,
  props<{ message: string }>()
);

const loadTypeCosts = createAction(
  CampaignGroupsActionsTypes.LoadTypeCosts,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadTypeCostsSuccess = createAction(
  CampaignGroupsActionsTypes.LoadTypeCostsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignCosts>>()
);

const loadTypeCostsFailure = createAction(
  CampaignGroupsActionsTypes.LoadTypeCostsFailure,
  props<{ message: string }>()
);

export const campaignGroupsActions = {
  setUsedParams,
  getCampaignGroups,
  hasWebActivity,
  hasWebActivitySuccess,
  hasWebActivityFailure,
  toggleTableRow,
  closeNestedTables,
  loadTypeMetaData,
  loadTypeMetaDataSuccess,
  loadTypeMetaDataFailure,
  loadTypeResponses,
  loadTypeResponsesSuccess,
  loadTypeResponsesFailure,
  loadTypeAttribution,
  loadTypeAttributionSuccess,
  loadTypeAttributionFailure,
  loadTypeInfluence,
  loadTypeInfluenceSuccess,
  loadTypeInfluenceFailure,
  loadTypeReturns,
  loadTypeReturnsSuccess,
  loadTypeReturnsFailure,
  loadTypeCosts,
  loadTypeCostsSuccess,
  loadTypeCostsFailure,
};

export type CampaignGroupsLoadActions = interfaces.ILoadPayload & TypedAction<CampaignGroupsActionsTypes>;

export type CampaignGroupsLoadSuccessActions = interfaces.ISuccessPayload<any> & TypedAction<CampaignGroupsActionsTypes>;

// Establishing dependencies between LoadTypeSuccess and LoadType actions (Campaign Groups load actions)
export const campaignGroupsActionsMap: Map<CampaignGroupsActionsTypes, CampaignGroupsLoadActions> = new Map()
  .set(CampaignGroupsActionsTypes.LoadTypeAttributionSuccess, loadTypeAttribution())
  .set(CampaignGroupsActionsTypes.LoadTypeCostsSuccess, loadTypeCosts())
  .set(CampaignGroupsActionsTypes.LoadTypeInfluenceSuccess, loadTypeInfluence())
  .set(CampaignGroupsActionsTypes.LoadTypeMetaDataSuccess, loadTypeMetaData())
  .set(CampaignGroupsActionsTypes.LoadTypeResponsesSuccess, loadTypeResponses())
  .set(CampaignGroupsActionsTypes.LoadTypeReturnsSuccess, loadTypeReturns());
