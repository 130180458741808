<div class="selected-filters-popover sidenav-container" *ngIf="showAppliedFilters">
  <div class="layout column">
    <div class="sidenav-content-header">
      <h3>{{ 'shared.features.sidenavUtilities.appliedFilters' | translate }}</h3>
      <ts-icon-button
        class="icon-button"
        buttonType="button"
        actionName="Close sidenav"
        [icon]="timesIcon"
        (clicked)="togglePopover($event)">
      </ts-icon-button>
    </div>
    <div class="sidenav-content" *ngIf="(selectedDataFilter$ | async) || (selectedAccountLists$ | async)">
      <ng-container *ngIf="(selectedAccountLists$ | async)?.length">
        <div class="selected-filter">
          <ng-container *ngFor="let selectedFolder of selectedAccountLists$ | async">
            <div class="selected-filter-list-head">
              {{ selectedFolder.folderName }}
            </div>
            <ul class="selected-filter-list">
              <li *ngFor="let selectedList of selectedFolder.lists">
                {{ selectedList }}
              </li>
            </ul>
          </ng-container>
          <mat-divider></mat-divider>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedDataFilter$ | async as selectedDataFilter">
        <ng-container *ngFor="let filterGroup of selectedDataFilter.filterNames">
          <div class="selected-filter">
            <div class="selected-filter-head">
              {{ titleMap[filterGroup] }}
            </div>
            <ng-container *ngFor="let filter of getFilterKeys(selectedDataFilter.filters[filterGroup])">
              <ng-container *ngIf="selectedDataFilter.filters[filterGroup][filter].length">
                <div class="selected-filter-list-head" *ngIf="filterGroup !== 'segments'">
                  {{ getFilterGroupName(filter, (globalFilters$ | async)[filterGroup]) }}
                </div>
                <ul class="selected-filter-list">
                  <li *ngFor="let filterName of selectedDataFilter.filters[filterGroup][filter]">
                    {{ getDisplayName(filter, filterName) }}
                  </li>
                </ul>
                <mat-divider></mat-divider>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="sidenav-container">
  <ng-container *ngIf="(isHiddenGlobalFilters$ | async) === false">
    <span
      class="badge-container"
      [ngClass]="{ 'includes-info': (routeWithInfoPanel$ | async) }"
      *ngIf="appliedFiltersNumber$ | async as appliedNumber">
        {{ appliedNumber }}
    </span>
  </ng-container>
  <div class="sidenav-buttons" *ngIf="(isExtended$ | async) === false">
    <ng-container *ngIf="routeWithInfoPanel$ | async">
      <div id="insights-icon"
           class="sidenav-button">
        <ts-icon-button
          buttonType="button"
          [icon]="faInfoSquare"
          [isDisabled]="isReportsLoading$ | async"
          (clicked)="selectTab(sidenavUtilitiesTabs.InfoPanel)">
        </ts-icon-button>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <div class="sidenav-button" *ngIf="(isHiddenGlobalFilters$ | async) === false">
      <ts-icon-button
        buttonType="button"
        [icon]="filterIcon"
        [isDisabled]="isReportsLoading$ | async"
        (clicked)="selectTab(sidenavUtilitiesTabs.GlobalFilters)"
        [attr.data-cy]="'side-nav-filter-button'">
      </ts-icon-button>
    </div>
    <div class="sidenav-button">
      <ts-icon-button
        buttonType="button"
        [icon]="fileIcon"
        [isDisabled]="isReportsLoading$ | async"
        (clicked)="selectTab(sidenavUtilitiesTabs.SavedReports)"
        [attr.data-cy]="'side-nav-reports-button'">
      </ts-icon-button>
    </div>
    <div class="sidenav-button">
      <ts-icon-button
        buttonType="button"
        [icon]="faSearch"
        [isDisabled]="isReportsLoading$ | async"
        (clicked)="selectTab(sidenavUtilitiesTabs.GlobalSearch)"
        [attr.data-cy]="'side-nav-search-button'">
      </ts-icon-button>
    </div>
    <div class="sidenav-button hidden-button" *ngIf="(isHiddenGlobalFilters$ | async) === false else openForm">
      <button (click)="selectTab(sidenavUtilitiesTabs.GlobalFilters)"
              [disabled]="isReportsLoading$ | async"
              type="button"
              aria-label="Open global filters">
      </button>
    </div>
    <ng-template #openForm>
      <div class="sidenav-button hidden-button">
        <button (click)="selectTab(sidenavUtilitiesTabs.SavedReports)"
                [disabled]="isReportsLoading$ | async"
                type="button"
                aria-label="Open global filters">
        </button>
      </div>
    </ng-template>
    <div class="sidenav-button info" *ngIf="(isHiddenGlobalFilters$ | async) === false">
      <i class="fas fa-caret-left"></i>
      <ts-icon-button
        buttonType="button"
        [icon]="faInfoCircle"
        [isDisabled]="isReportsLoading$ | async"
        (clicked)="togglePopover($event)">
      </ts-icon-button>
    </div>
  </div>
  <ts-tab-collection
    *ngIf="isExtended$ | async"
    [selectedIndex]="selectedTabIndex$ | async"
    [class.no-info]="(routeWithInfoPanel$ | async) === false"
    [class.no-filters]="isHiddenGlobalFilters$ | async">
    <ts-tab>
      <ng-template tsTabLabel>
        <ts-icon-button
          class="icon-button"
          [icon]="faInfoSquare"
          (clicked)="selectTab(sidenavUtilitiesTabs.InfoPanel)"
        ></ts-icon-button>
      </ng-template>
      <ng-template tsTabContent>
        <div class="info-panel">
          <ng-container *ngTemplateOutlet="closeButton"></ng-container>
          <ng-container [tshAddTarget]="portalTargets.SidenavUtilitiesForSpecificReport"></ng-container>
        </div>
      </ng-template>
    </ts-tab>

    <ts-tab>
      <ng-template tsTabLabel>
        <ts-icon-button
          class="icon-button"
          [icon]="filterIcon"
          (clicked)="selectTab(sidenavUtilitiesTabs.GlobalFilters)"
        ></ts-icon-button>
      </ng-template>
      <ng-template tsTabContent>
        <tsh-global-filters>
          <div class="sidenav-content-header">
            <h3>{{ 'shared.features.sidenavUtilities.globalFilters' | translate }}</h3>
            <ng-container *ngTemplateOutlet="closeButton"></ng-container>
          </div>
        </tsh-global-filters>
      </ng-template>
    </ts-tab>

    <ts-tab>
      <ng-template tsTabLabel>
        <ts-icon-button
          class="icon-button"
          [icon]="fileIcon"
          (clicked)="selectTab(sidenavUtilitiesTabs.SavedReports)"
        ></ts-icon-button>
      </ng-template>

      <tsh-saved-reports
        (updateFolder)="updateFolder($event)"
        (createFolder)="createFolder($event)"
        (deleteFolder)="deleteFolder($event)"
        (deleteReport)="deleteReport($event)"
        (updateReport)="updateReport($event)"
        (createReport)="createReport($event)"
        (showReport)="showReport($event)"
        [isLoading]="isReportsLoading$ | async"
        [stateInformation]="stateInformation$ | async"
        [folderOptions]="folderOptions$ | async"
        [savedReports]="savedReports$ | async">
        <div class="sidenav-content-header">
          <h3>{{ 'shared.features.sidenavUtilities.savedReports' | translate}}</h3>
          <ng-container *ngTemplateOutlet="closeButton"></ng-container>
        </div>
      </tsh-saved-reports>
    </ts-tab>

    <ts-tab>
      <ng-template tsTabLabel>
        <ts-icon-button
          class="icon-button"
          [icon]="faSearch"
          (clicked)="selectTab(sidenavUtilitiesTabs.GlobalSearch)"
        ></ts-icon-button>
      </ng-template>

      <tsh-global-search
      >
        <div class="sidenav-content-header">
          <h3>{{ 'shared.features.sidenavUtilities.globalSearch' | translate }}</h3>
          <ng-container *ngTemplateOutlet="closeButton"></ng-container>
        </div>
      </tsh-global-search>
    </ts-tab>

    <ts-tab>
      <ng-template tsTabLabel>
        <div class="sidenav-button info">
          <i class="fas fa-caret-left"></i>
          <ts-icon-button
            buttonType="button"
            [icon]="faInfoCircle"
            (clicked)="togglePopover($event)">
          </ts-icon-button>
        </div>
      </ng-template>
    </ts-tab>
  </ts-tab-collection>
</div>

<ng-template #closeButton>
  <ts-icon-button
    class="icon-button"
    buttonType="button"
    actionName="Close sidenav"
    [icon]="exitIcon"
    (clicked)="closeSidenav()">
  </ts-icon-button>
</ng-template>
