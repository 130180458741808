import { AnalyticsColumnName as ColumnName } from '../enums';
import { SortDirections } from '@shared/enums';
import { PER_PAGE } from '@shared/constants';
import { ILabelValue, IPager, ISorter } from '@shared/interfaces';

export const defaultSorter: ISorter = {sortField: ColumnName.Responses, sortDirection: SortDirections.Desc};
export const defaultPager: IPager = {pageSize: PER_PAGE, pageNum: 1};

export const analyticsTileFields: string[] = [
  ColumnName.TotalTouches,
  ColumnName.Cost,
  ColumnName.Responses,
  ColumnName.People,
  ColumnName.Accounts,
  ColumnName.Opptys,
  ColumnName.Deals,
  ColumnName.InfluencedPipeline,
  ColumnName.InfluencedRevenue,
  ColumnName.OpptyTouches,
  ColumnName.DealTouches,
  ColumnName.AttributedPipeline,
  ColumnName.AttributedRevenue,
  ColumnName.CPL,
  ColumnName.CPO,
  ColumnName.CPD,
  ColumnName.PipelineRoi,
  ColumnName.ROI,
  ColumnName.ROAS,
  ColumnName.LTO,
  ColumnName.OTD,
];

export const analyticsTileFieldOptions: ILabelValue[] = [
  {value: ColumnName.TotalTouches, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.totalTouches'},
  {value: ColumnName.Cost, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.cost'},
  {value: ColumnName.Responses, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.responses'},
  {value: ColumnName.People, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.people'},
  {value: ColumnName.Accounts, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.accounts'},
  {value: ColumnName.Opptys, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.opptys'},
  {value: ColumnName.Deals, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.deals'},
  {value: ColumnName.InfluencedPipeline, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.influencedPipeline'},
  {value: ColumnName.InfluencedRevenue, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.influencedRevenue'},
  {value: ColumnName.OpptyTouches, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.opptyTouches'},
  {value: ColumnName.DealTouches, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.dealTouches'},
  {value: ColumnName.AttributedPipeline, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.attributedPipelineLabel'},
  {value: ColumnName.AttributedRevenue, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.attributedRevenueLabel'},
  {value: ColumnName.CPL, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpl'},
  {value: ColumnName.CPO, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpo'},
  {value: ColumnName.CPD, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpd'},
  {value: ColumnName.PipelineRoi, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.pipelineRoi'},
  {value: ColumnName.ROI, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.roi'},
  {value: ColumnName.ROAS, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.roas'},
  {value: ColumnName.LTO, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.lto'},
  {value: ColumnName.OTD, label: 'measurementStudio.features.analytics.campaignAnalytics.columns.otd'},
];

export enum TileSettingsAnalyticsFields {
  DataSet = 'dataSet',
  Field = 'field',
  InfluenceType = 'influenceType',
  Model = 'model',
  Group = 'gf',
}
