import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { TsDateRangeModule } from '@terminus-lib/ui-date-range';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsPipesModule } from '@terminus-lib/ui-pipes';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsSortModule } from '@terminus-lib/ui-sort';

import * as fromMarketingReducer from './state/marketing-influence.reducer';
import { MarketingInfluenceComponent } from './containers/container.component';
import { MarketingInfluenceRoutingModule } from './marketing-influence-routing.module';
import { MarketingInfluenceEffects } from './state/marketing-influence.effects';
import { MarketingTableComponent } from './components/marketing-table/marketing-table.component';
import { MarketingChartComponent } from './components/marketing-chart/marketing-chart.component';
import { MarketingFiltersComponent } from './components/marketing-filters/marketing-filters.component';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { amChartsFactory } from '@util/amcharts';
import { ReportMetricModule } from '@ui/components/report-metric';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { HeaderCellModule } from '@ui/components/header-cell';
import { TranslateModule } from '@ngx-translate/core';
import { TablePanelModule } from '@ui/components/table-panel';
import { AddToDashboardButtonModule } from '@ui/components/add-to-dashboard-button';
import { PaginationModule } from '@ui/components/pagination';
import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { ColumnsSaverService } from '@shared/data-access/columns-saver';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MarketingInfluenceRoutingModule,
    RouterModule,
    TsSortModule,
    TsChartModule,
    TsDateRangeModule,
    TsLoadingOverlayModule,
    TsPipesModule,
    TsTableModule,
    TsIconButtonModule,
    ReportSettingPanelModule,
    TablePanelModule,
    ReportFilterSelectorModule,
    DateCohortsModule,
    TypedDataModule,
    ReportMetricModule,
    NoReportResultModule,
    HeaderCellModule,
    StoreModule.forFeature(fromMarketingReducer.MARKETING_INFLUENCE_KEY, fromMarketingReducer.reducer),
    EffectsModule.forFeature([MarketingInfluenceEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
    TranslateModule,
    AddToDashboardButtonModule,
    PaginationModule,
    SharedUiPipesMemoizeModule,
  ],

  declarations: [
    MarketingInfluenceComponent,
    MarketingTableComponent,
    MarketingChartComponent,
    MarketingFiltersComponent,
  ],

  providers: [
    ColumnsSaverService,
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsFactory,
    }
  ]
})
export class MarketingInfluenceModule {
}
