import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TranslateModule } from '@ngx-translate/core';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsSortModule } from '@terminus-lib/ui-sort';

import { StagesSnapshotComponent } from './containers/stages-snapshot.component';
import { StagesSnapshotRoutingModule } from './stages-snapshot-routing.module';
import * as StagesSnapshotReducer from './state/stages-snapshot.reducer';
import { StagesSnapshotEffects } from './state/stages-snapshot.effects';
import { StagesSnapshotChartComponent } from './components/stages-snapshot-chart/stages-snapshot-chart.component';
import { StagesSnapshotTabsComponent } from './components/stages-snapshot-tabs/stages-snapshot-tabs.component';
import { StagesSnapshotTableComponent } from './components/stages-snapshot-table/stages-snapshot-table.component';
import { amChartsFactory } from '@util/amcharts';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { PaginationModule } from '@ui/components/pagination';
import { HeaderCellModule } from '@ui/components/header-cell';
import { TablePanelModule } from '@ui/components/table-panel';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { AddToDashboardButtonModule } from '@ui/components/add-to-dashboard-button';

@NgModule({
  declarations: [
    StagesSnapshotComponent,
    StagesSnapshotChartComponent,
    StagesSnapshotTabsComponent,
    StagesSnapshotTableComponent,
  ],
  imports: [
    CommonModule,
    StagesSnapshotRoutingModule,
    StoreModule.forFeature(StagesSnapshotReducer.STAGES_SNAPSHOT_KEY, StagesSnapshotReducer.reducer),
    EffectsModule.forFeature([StagesSnapshotEffects]),
    DateCohortsModule,
    TsLoadingOverlayModule,
    TsChartModule,
    TranslateModule.forChild(),
    TypedDataModule,
    TsTableModule,
    TablePanelModule,
    HeaderCellModule,
    TsSortModule,
    PaginationModule,
    NoReportResultModule,
    AddToDashboardButtonModule,
  ],
  providers: [
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsFactory,
    }
  ]
})
export class StagesSnapshotModule {
}
