import { ILabelValue } from '@shared/interfaces';
import {
  KpiFirmographic, KpiGroup, KpiInterval, KpiProgression,
  KpiTrend, KpiTrendLabels, KpiType, SeriesTypes, KpiAudience
} from '../enums/scorecard.enum';
import { DataTypeEnum } from '@shared/enums';

export const TRENDING_KPIS_MISSING_DESCRIPTOR_BACKFILL = 'n/a';

export const kpiTypeAudienceMap: Record<KpiAudience, string> = {
  [KpiAudience.Account]: 'measurementStudio.features.scorecard.audiences.accountLists',
  [KpiAudience.Segment]: 'measurementStudio.features.scorecard.audiences.segments'
};

export const kpiTypeHumanReadableMap: Record<KpiType, string> = {
  [KpiType.Account]: 'measurementStudio.features.scorecard.typeOptions.uniqueAccounts',
  [KpiType.OpptyCreated]: 'measurementStudio.features.scorecard.typeOptions.uniqueOpportunitiesCreated',
  [KpiType.OpptyClosed]: 'measurementStudio.features.scorecard.typeOptions.uniqueOpportunitiesClosed',
  [KpiType.OpptyWon]: 'measurementStudio.features.scorecard.typeOptions.uniqueOpportunitiesWon',
  [KpiType.Pipeline]: 'measurementStudio.features.scorecard.typeOptions.pipeline',
  [KpiType.Revenue]: 'measurementStudio.features.scorecard.typeOptions.revenue',
  [KpiType.WinRate]: 'measurementStudio.features.scorecard.typeOptions.winRate',
  [KpiType.DealVelocity]: 'measurementStudio.features.scorecard.typeOptions.dealVelocity',
  [KpiType.AvgDealSize]: 'measurementStudio.features.scorecard.typeOptions.averageDealSize'
};

export const kpiFirmographicTitleMapping: Record<KpiFirmographic, string> = {
  [KpiFirmographic.Revenue]: 'measurementStudio.features.scorecard.firmographicOptions.revenue',
  [KpiFirmographic.Employee]: 'measurementStudio.features.scorecard.firmographicOptions.employee',
  [KpiFirmographic.Industry]: 'measurementStudio.features.scorecard.firmographicOptions.industry',
  [KpiFirmographic.None]: 'measurementStudio.features.scorecard.firmographicOptions.none'
};

export const kpiTypeValueAxisFormat: Record<KpiType, string> = {
  [KpiType.Account]: '#,###',
  [KpiType.OpptyCreated]: '#,###',
  [KpiType.OpptyClosed]: '#,###',
  [KpiType.OpptyWon]: '#,###',
  [KpiType.Pipeline]: '$#,###.00',
  [KpiType.Revenue]: '$#,###.00',
  [KpiType.WinRate]: '#,###.00%',
  [KpiType.DealVelocity]: '#,###.',
  [KpiType.AvgDealSize]: '$#,###.00'
};

export const kpiTypeValueTileAxisFormat: Record<KpiType, string> = {
  [KpiType.Account]: '#a',
  [KpiType.OpptyCreated]: '#a',
  [KpiType.OpptyClosed]: '#a',
  [KpiType.OpptyWon]: '#a',
  [KpiType.Pipeline]: '$#a',
  [KpiType.Revenue]: '$#a',
  [KpiType.WinRate]: '#,###.00%',
  [KpiType.DealVelocity]: '#a',
  [KpiType.AvgDealSize]: '$#a'
};

export interface ITableValueFormatting {
  dataType: DataTypeEnum;
  decimal: number;
}

export const kpiTypeTableFormatMap: Record<KpiType, ITableValueFormatting> = {
  [KpiType.Account]: {dataType: DataTypeEnum.Number, decimal: 0},
  [KpiType.OpptyCreated]: {dataType: DataTypeEnum.Number, decimal: 0},
  [KpiType.OpptyClosed]: {dataType: DataTypeEnum.Number, decimal: 0},
  [KpiType.OpptyWon]: {dataType: DataTypeEnum.Number, decimal: 0},
  [KpiType.Pipeline]: {dataType: DataTypeEnum.Currency, decimal: 2},
  [KpiType.Revenue]: {dataType: DataTypeEnum.Currency, decimal: 2},
  [KpiType.WinRate]: {dataType: DataTypeEnum.Percent, decimal: 2},
  [KpiType.DealVelocity]: {dataType: DataTypeEnum.Number, decimal: 0},
  [KpiType.AvgDealSize]: {dataType: DataTypeEnum.Currency, decimal: 2}
};

/*--- END KpiType Mappings ---*/

/*--- KpiGroup Mappings ---*/

export const kpiGroupLegendMapping: Record<KpiGroup, string> = {
  [KpiGroup.None]: 'measurementStudio.features.scorecard.groupOptions.metric',
  [KpiGroup.ScorecardAggregate]: 'measurementStudio.features.scorecard.groupOptions.accountList',
  [KpiGroup.ScorecardSegment]: 'measurementStudio.features.scorecard.groupOptions.accountSegment',
  [KpiGroup.AccountEngagement]: 'measurementStudio.features.scorecard.groupOptions.engagement',
  [KpiGroup.Impressions]: 'measurementStudio.features.scorecard.groupOptions.impressions',
  [KpiGroup.Firmographics]: 'measurementStudio.features.scorecard.groupOptions.firmographics'
};

/*--- END KpiGroup Mappings ---*/

export const KPI_PROGRESSION_OPTIONS: ILabelValue<KpiProgression>[] = [
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.targetAccounts',
    value: KpiProgression.List
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.engagedAccounts',
    value: KpiProgression.Engaged
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.opportunities',
    value: KpiProgression.Opportunity
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.wonAccounts',
    value: KpiProgression.Won
  }
];

export const KPI_PROGRESSION_SEGMENT_OPTIONS: ILabelValue<KpiProgression>[] = [
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.segmentAccounts',
    value: KpiProgression.Segment
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.engagedAccounts',
    value: KpiProgression.Engaged
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.opportunities',
    value: KpiProgression.Opportunity
  },
  {
    label: 'measurementStudio.features.scorecard.progressionOptions.wonAccounts',
    value: KpiProgression.Won
  }
];

export const KPI_TYPE_OPTIONS: ILabelValue<KpiType>[] = [
  {
    label: 'measurementStudio.features.scorecard.typeOptions.accounts',
    value: KpiType.Account
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.opportunitiesCreated',
    value: KpiType.OpptyCreated
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.opportunitiesClosed',
    value: KpiType.OpptyClosed
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.opportunitiesWon',
    value: KpiType.OpptyWon
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.pipeline',
    value: KpiType.Pipeline
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.revenue',
    value: KpiType.Revenue
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.winRate',
    value: KpiType.WinRate
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.dealVelocity',
    value: KpiType.DealVelocity
  },
  {
    label: 'measurementStudio.features.scorecard.typeOptions.averageDealSize',
    value: KpiType.AvgDealSize
  }
];

export const KPI_AUDIENCE_OPTIONS: ILabelValue<KpiAudience>[] = [
  {
    label: 'measurementStudio.features.scorecard.audiences.accountLists',
    value: KpiAudience.Account
  },
  {
    label: 'measurementStudio.features.scorecard.audiences.segments',
    value: KpiAudience.Segment
  }
];

export const KPI_GROUP_OPTIONS: ILabelValue<KpiGroup>[] = [
  {
    label: 'measurementStudio.features.scorecard.groupOptions.accountList',
    value: KpiGroup.ScorecardAggregate
  },
  {
    label: 'measurementStudio.features.scorecard.groupOptions.accountEngagement',
    value: KpiGroup.AccountEngagement
  },
  {
    label: 'measurementStudio.features.scorecard.groupOptions.terminusFirmographics',
    value: KpiGroup.Firmographics
  }
];

export const KPI_GROUP_OPTIONS_SEGMENT: ILabelValue<KpiGroup>[] = [
  {
    label: 'measurementStudio.features.scorecard.groupOptions.accountSegment',
    value: KpiGroup.ScorecardSegment
  },
  {
    label: 'measurementStudio.features.scorecard.groupOptions.accountEngagement',
    value: KpiGroup.AccountEngagement
  },
  {
    label: 'measurementStudio.features.scorecard.groupOptions.terminusFirmographics',
    value: KpiGroup.Firmographics
  }
];

export const FIRMOGRAPHIC_OPTIONS: ILabelValue<KpiFirmographic>[] = [
  {
    label: 'measurementStudio.features.scorecard.firmographicOptions.none',
    value: KpiFirmographic.None
  },
  {
    label: 'measurementStudio.features.scorecard.firmographicOptions.revenue',
    value: KpiFirmographic.Revenue
  },
  {
    label: 'measurementStudio.features.scorecard.firmographicOptions.employee',
    value: KpiFirmographic.Employee
  },
  {
    label: 'measurementStudio.features.scorecard.firmographicOptions.industry',
    value: KpiFirmographic.Industry
  }
];

export const KPI_INTERVAL_OPTIONS: ILabelValue<KpiInterval>[] = [
  {
    label: 'measurementStudio.features.scorecard.intervalOptions.week',
    value: KpiInterval.Weeks
  },
  {
    label: 'measurementStudio.features.scorecard.intervalOptions.month',
    value: KpiInterval.Months
  },
  {
    label: 'measurementStudio.features.scorecard.intervalOptions.quarter',
    value: KpiInterval.Quarters
  }
];

export const KPI_TREND_OPTIONS: ILabelValue<KpiTrend>[] = [
  {
    label: KpiTrendLabels.Cumulative,
    value: KpiTrend.Cumulative
  },
  {
    label: KpiTrendLabels.NetChange,
    value: KpiTrend.Net
  },
  {
    label: KpiTrendLabels.Snapshot,
    value: KpiTrend.Snapshot
  },
  {
    label: KpiTrendLabels.NetNew,
    value: KpiTrend.Net
  }
];

export const KPI_GRAPH_TYPE_OPTIONS: ILabelValue<SeriesTypes>[] = [
  {
    label: 'measurementStudio.features.scorecard.graphTypes.line',
    value: SeriesTypes.Line
  },
  {
    label: 'measurementStudio.features.scorecard.graphTypes.stackedArea',
    value: SeriesTypes.StackedArea
  },
  {
    label: 'measurementStudio.features.scorecard.graphTypes.column',
    value: SeriesTypes.Column
  },
  {
    label: 'measurementStudio.features.scorecard.graphTypes.stackedColumn',
    value: SeriesTypes.StackedColumn
  }
];

export enum KpiTrendingTileFields {
  Type = 'type',
  Progression = 'progression',
  ScorecardAggregateIds = 'scorecardAggregateIds',
  scorecardSegmentIds = 'scorecardSegmentIds',
  Group = 'group',
  Firmographic = 'firmographic',
  Interval = 'interval',
  Trend = 'trend',
}
