/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as fm from '../../../../../fetch.pb';
import * as TerminusSegmentManagementApiV1Messages from './messages.pb';
export class SegmentManagementApiService {
  static ListUserDefinedSegments(
    req: TerminusSegmentManagementApiV1Messages.ListUserDefinedSegmentsRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.ListUserDefinedSegmentsResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.ListUserDefinedSegmentsRequest,
      TerminusSegmentManagementApiV1Messages.ListUserDefinedSegmentsResponse
    >(`/v1/user_defined_segment/list_user_defined_segments`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static GetTotalUserDefinedSegments(
    req: TerminusSegmentManagementApiV1Messages.TotalUserDefinedSegmentRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.TotalUserDefinedSegmentResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.TotalUserDefinedSegmentRequest,
      TerminusSegmentManagementApiV1Messages.TotalUserDefinedSegmentResponse
    >(`/v1/user_defined_segment/list_user_defined_segment_total`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static ChangeUserDefinedSegmentFolder(
    req: TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFolderRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFolderResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFolderRequest,
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFolderResponse
    >(`/v1/user_defined_segment/change_user_defined_segment_folder`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static DuplicateUserDefinedSegment(
    req: TerminusSegmentManagementApiV1Messages.DuplicateUserDefinedSegmentRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.DuplicateUserDefinedSegmentResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.DuplicateUserDefinedSegmentRequest,
      TerminusSegmentManagementApiV1Messages.DuplicateUserDefinedSegmentResponse
    >(`/v1/user_defined_segment/duplicate_user_defined_segment`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static DeleteUserDefinedSegment(
    req: TerminusSegmentManagementApiV1Messages.DeleteUserDefinedSegmentRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.DeleteUserDefinedSegmentResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.DeleteUserDefinedSegmentRequest,
      TerminusSegmentManagementApiV1Messages.DeleteUserDefinedSegmentResponse
    >(`/v1/user_defined_segment/delete_user_defined_segment`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static GetUserDefinedSegmentData(
    req: TerminusSegmentManagementApiV1Messages.UserDefinedSegmentDataRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.UserDefinedSegmentDataResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentDataRequest,
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentDataResponse
    >(`/v1/user_defined_segment/get_user_defined_segment_data`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static GetUserDefinedSegmentFields(
    req: TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFieldsRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFieldsResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFieldsRequest,
      TerminusSegmentManagementApiV1Messages.UserDefinedSegmentFieldsResponse
    >(`/v1/user_defined_segment/get_user_defined_segment_fields`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static CreateUserDefinedSegment(
    req: TerminusSegmentManagementApiV1Messages.CreateUserDefinedSegmentRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.CreateUserDefinedSegmentResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.CreateUserDefinedSegmentRequest,
      TerminusSegmentManagementApiV1Messages.CreateUserDefinedSegmentResponse
    >(`/v1/user_defined_segment/upsert_user_defined_segment`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
  static ValidateUserDefinedSegment(
    req: TerminusSegmentManagementApiV1Messages.ValidateQueryExpressionRequest,
    initReq?: fm.InitReq
  ): Promise<TerminusSegmentManagementApiV1Messages.ValidateQueryExpressionResponse> {
    return fm.fetchReq<
      TerminusSegmentManagementApiV1Messages.ValidateQueryExpressionRequest,
      TerminusSegmentManagementApiV1Messages.ValidateQueryExpressionResponse
    >(`/v1/validate_query_expression`, {
      ...initReq,
      method: 'POST',
      body: JSON.stringify(req, fm.replacer),
    });
  }
}
