import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TranslateModule } from '@ngx-translate/core';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';

import { SelectorComponent } from './selector.component';

@NgModule({
  declarations: [SelectorComponent],
  exports: [SelectorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TsMenuModule,
    TsInputModule,
    TsIconButtonModule,
    MatRippleModule,
    ScrollingModule,
    MatProgressSpinnerModule,
  ]
})
export class SelectorModule {
}
