import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TsInputModule } from '@terminus-lib/ui-input';

import { TileFormFields } from '../../../enums/tile-form-fields.enum';
import { GAUGE_FORM_CONFIG } from '../../../tile-modal.constant';
import { GaugeChartColors, VisualizationTypes } from '@shared/enums';
import { GOAL_FIELD, VISUALIZATION_WITH_GOAL_FIELD } from '@shared/constants';

@Component({
    selector: 'tsh-visualization-options',
    templateUrl: './visualization-options.component.html',
    styleUrls: ['./visualization-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizationOptionsComponent {
    @Input() public settingsFormGroup: FormGroup;
    @Input() public selectedVisualization: VisualizationTypes;
    @Input() public isHighlightedErrors: boolean;

    public readonly fields = TileFormFields;
    public readonly goalField = GOAL_FIELD;
    public readonly gaugeConfig = GAUGE_FORM_CONFIG;
    public readonly visualizationWithGoal = VISUALIZATION_WITH_GOAL_FIELD;
    public readonly gaugeChartColors = GaugeChartColors;
    public readonly visualizationTypes = VisualizationTypes;
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TsInputModule,
        ReactiveFormsModule,
    ],
    declarations: [VisualizationOptionsComponent],
    exports: [VisualizationOptionsComponent],
})
export class VisualizationOptionsModule {
}
