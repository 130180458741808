import { Observable, of } from 'rxjs';

import { ID, OptionsProvider } from '@shared/interfaces';
import { ILabelValue } from '@shared/interfaces';

export class MemoryOptionsProvider<T extends ILabelValue<ID>> implements OptionsProvider<T> {
  constructor(private options: T[]) {}

  getById(id: ID): Observable<T> {
    const option = this.options.find((item: T) => item.value === id);
    return of(option);
  }

  getFilteredOptions(search: string): Observable<T[]> {
    const options = this.filterOptions(search);
    return of(options);
  }

  getAllOptions(): T[] {
    return this.options;
  }

  handleError(message: string): void {
    console.error(message);
  }

  private filterOptions(search: string): T[] {
    const reg = new RegExp(search, 'i');
    return this.options.filter(option => {
      return reg.test(option.label);
    });
  }
}
