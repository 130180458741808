import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';

import { IAccountFolder, IGlobalFilter, IGlobalFilterFolderItem, ITargetFolderItem } from '@shared/interfaces';
import { GlobalFiltersHeader, GlobalFiltersKeys } from '@shared/enums';
import { GLOBAL_FILTER_HEADER_KEY_MAP } from '@shared/constants';
import { UserDefinedSegment } from '@shared/data-access/segments-api';

@Component({
  selector: 'tsh-global-filters-expansion-panel',
  templateUrl: './filter-expansion-panel.component.html',
  styleUrls: ['./filter-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFiltersExpansionPanelComponent {
  @Input() public accountListFolder: IAccountFolder[];
  @Input() public filterOptions: IGlobalFilter[];
  @Input() public folderState: IGlobalFilterFolderItem;
  @Input() public titleKey: string;
  @Input() public segments: UserDefinedSegment[];

  @Output() public selectFilterFolder: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();
  @Output() public selectFilterFolderItem: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();
  @Output() public toggleFilterFolderMenu: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();

  public readonly iconRight = faChevronRight;
  public readonly iconDown = faChevronDown;
  public readonly titleMap: Record<GlobalFiltersKeys, GlobalFiltersHeader> = GLOBAL_FILTER_HEADER_KEY_MAP;

  public displayFolderItem = (folder: IGlobalFilterFolderItem, folderName: string) =>
    folderName === 'segments' || (folder && folder.itemSelect && folder.itemSelect[folderName]);
  public displayFolderItemOption = (itemSelect: Record<string, boolean>, itemName: string | number) =>
    typeof itemName === 'number'
      ? Object.keys(itemSelect).indexOf(`${itemName}`) > -1
      : Object.keys(itemSelect).indexOf(itemName) > -1;
  public targetFolderItem = (folderName: GlobalFiltersKeys, folderItem: string) => ({ folderName, folderItem });
  public targetFolderItemKey = (folderName: GlobalFiltersKeys, folderItem: string, folderSelectKey?: string) => ({
    folderName,
    folderItem,
    folderSelectKey,
  });

  panelToggled() {
    if (this.titleKey === GlobalFiltersKeys.Segments)
      this.toggleFilterFolderMenu.emit({ folderName: GlobalFiltersKeys.Segments, folderItem: 'segments' });
  }

  getDisplayName(titleKey: string, data: string) {
    if (titleKey.toLowerCase() === GlobalFiltersKeys.Segments && this.segments) {
      const segment = this.segments.find(segment => segment.lqsSharedQueryId === data);
      if (segment) return segment.displayName;
    }

    return data;
  }
}
