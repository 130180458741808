import { OnInit, OnDestroy, Directive } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { navMenusActions } from '@nav-menus';
import { ParentLink } from '@shared/interfaces';
import { RouteItemEnum } from '@shared/enums';
import { Subscription } from 'rxjs';

@Directive()
export abstract class SetParentLinkDirective implements OnInit, OnDestroy {
  private webSpecificPages = [
    RouteItemEnum.CampaignSpecificLeads,
    RouteItemEnum.CampaignSpecificOpportunities,
    RouteItemEnum.CampaignSpecificDeals
  ];

  private campaignTitles: {[key in RouteItemEnum]?: string} = {
    [RouteItemEnum.WebActivitiesSpecific]: 'feature.webActivitiesSpecific.parentTitle',
    [RouteItemEnum.CampaignSpecific]: 'feature.campaignSpecific.parentTitle'
  };

  private _route: Subscription

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<unknown>,
  ) {}

  ngOnInit(): void {
    this._route = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map(route => {
        while (route.firstChild) {
          // tslint:disable-next-line:no-parameter-reassignment
          route = route.firstChild;
        }
        const routeId = route?.data?.routeId;
        // TODO refactor this temporary solution to something better
        this.store.dispatch(navMenusActions.SetParentLink({ parentLink: this.getParentLink(routeId, route) }));
      })
    ).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnDestroy(): void {
    this._route && this._route.unsubscribe();
  }

  getParentLink(routeId: RouteItemEnum, route: ActivatedRouteSnapshot): ParentLink {
    if (routeId && this.webSpecificPages.includes(routeId) && route && route.parent) {
      while ((route.parent?.data && route.parent.data.routeId !== RouteItemEnum.WebActivitiesSpecific) &&
      (route.parent.data.routeId !== RouteItemEnum.CampaignSpecific)) {
        route = route.parent;
      }
      const routeId = route.parent?.data.routeId as RouteItemEnum;
      return {
        routeId,
        title: routeId ? this.campaignTitles[routeId] : ''
      };
    }
    return route?.data?.parent || null;
  }
}
