import { createReducer, on } from '@ngrx/store';
import * as actions from './demo-config.actions';

export const demoConfigFeatureKey = 'demoConfigState';

export interface DemoConfigState {
  enabled: boolean;
  slowdown: number;
  slowdownEnabled: boolean;
  debug: boolean;
}

export const initialState: DemoConfigState = {
  enabled: false,
  slowdown: 0,
  slowdownEnabled: false,
  debug: false,
};

export const demoConfigReducer = createReducer(
  initialState,
  on(actions.toggleDemoMode, state => {
    // reset all settings is demo mode is disabled
    const newState = !state.enabled;
    const clearStateIfDisabled = newState
      ? {}
      : {
        slowdown: 0,
        slowdownEnabled: false,
        debug: false,
      };

    return {
      ...state,
      enabled: newState,
      ...clearStateIfDisabled
    }
  }),
  on(actions.toggleSlowdown, state => ({
    ...state,
    slowdownEnabled: !state.slowdownEnabled,
  })),
  on(actions.toggleDebug, state => ({
    ...state,
    debug: !state.debug,
  })),
  on(actions.slowdownSecondsChanged, (state, action) => ({
    ...state,
    slowdown: action.time,
  })),
);

