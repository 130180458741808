import { Injectable } from '@angular/core';

import { hubTokenName, MAX_EXTENDED_ROWS_NUMBER } from '@shared/constants';
import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { EnvService } from '@shared/environment';
import { OpportunitiesSource } from '../sources/opportunities.source';
import {
  IAppliedGlobalFiltersAsParams,
  IDashboardTile,
  IExportToken,
  IGetTileData,
  ILabelValue,
  ITileData,
  ITileDataItem,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { OPPORTUNITY_REPORT_COLUMNS, OPPORTUNITY_REPORT_COLUMNS_SIGSTR } from '../data/opportunity-report-columns.data';
import { InfluenceType, ToDate } from '@shared/enums';
import * as OrgConfigStore from '@org-config';
import { ClosedType, DateType, WonType } from '@measurement-studio/enums';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';
import { ClosedTypeOptions, DateTypeOptions, WonTypeOptions } from '@measurement-studio/constants';
import * as OpportunitiesSelector from '../state/opportunities.selectors';
import { IOpportunityRequest, OpportunityDataType, OpportunityDirection } from '@measurement-studio/interfaces';
import { IOpportunityColumn, IOpportunityReport, IOpportunityVisual } from '../interfaces';
import { OpportunityColumnsFields } from '../enums/opportunities.enum';
import { TileSettingsOpportunitiesFields } from '../opportunities.constant';

@Injectable({providedIn: 'root'})
export class OpportunitiesService implements IGetTileData<IOpportunityRequest> {

  constructor(public source: OpportunitiesSource,
              public store: Store<unknown>,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getOpportunityReport$(params: IOpportunityRequest): Observable<IOpportunityReport> {
    return regenerateOnRetry(() => this.source.getOpportunityReport$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getOpportunityVisual$(params: IOpportunityRequest): Observable<IOpportunityVisual> {
    return regenerateOnRetry(() => this.source.getOpportunityVisual$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  downloadCSV$(filters: IOpportunityRequest, globalFilters: IAppliedGlobalFiltersAsParams, token: IExportToken): Observable<string> {
    return regenerateOnRetry(() => this.source.downloadCSV$(filters, globalFilters, token)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getTileData$(params: IOpportunityRequest): Observable<ITileData> {
    return this.source.getOpportunityReport$({
      ...params,
      pg: 1,
      ps: MAX_EXTENDED_ROWS_NUMBER,
      tbl: 'oppty',
      fld: 'revenue',
      dir: OpportunityDirection.Desc,
    }).pipe(
      map((data: IOpportunityReport) => this.tileAdapter(data))
    );
  }

  getTileVisualizationConfig$(_tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    // name of columns which will be used in table visualization in the tile
    const fields: string[] = [OpportunityColumnsFields.Name, OpportunityColumnsFields.Revenue];
    // todo: oleg refactor it
    return combineLatest([
      this.store.pipe(select(OrgConfigStore.isSigstrEnabled)),
    ]).pipe(
      map(([isSigstrEnabled]: [boolean]) => this.getTableColumns(isSigstrEnabled)),
      map((columns: IOpportunityColumn[]) => ({
        table: {
          columns: columns.filter(column => fields.includes(column.name)),
          totalsPluralLabel: 'measurementStudio.features.opportunities.totalTitlePlural',
          totalsLabel: 'measurementStudio.features.opportunities.totalTitle',
        }
      }))
    );
  }

  getTableColumns(isSigstrEnabled: boolean): IOpportunityColumn[] {
    const columns = isSigstrEnabled
      ? [...OPPORTUNITY_REPORT_COLUMNS, ...OPPORTUNITY_REPORT_COLUMNS_SIGSTR]
      : OPPORTUNITY_REPORT_COLUMNS;

    return columns;
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    const cohort = {
      cohort: replaceLegacyCohort(params.cohort as string) || ToDate.QUARTER_TO_DATE,
      ...getStartAndEndDateFromParams(params.cohort as string, params.startDate, params.endDate)
    };
    return {
      ...params, // in case if we have global filters we have to keep them
      ...cohort,
      [TileSettingsOpportunitiesFields.DateType]: params[TileSettingsOpportunitiesFields.DateType] || DateType.Created,
      [TileSettingsOpportunitiesFields.ClosedType]: params[TileSettingsOpportunitiesFields.ClosedType] || ClosedType.ANY,
      [TileSettingsOpportunitiesFields.WonType]: params[TileSettingsOpportunitiesFields.WonType] || WonType.Any,
      [TileSettingsOpportunitiesFields.Model]: params[TileSettingsOpportunitiesFields.Model] || InfluenceType.ANY,
      type: OpportunityDataType.Pipeline,
    };
  }

  getTileSettingsFilters$(): Observable<ITileSettingControl[]> {
    return this.store.pipe(
      select(OpportunitiesSelector.getModelsOptions),
      map((models: ILabelValue[]) => [{
        key: TileSettingsOpportunitiesFields.DateType,
        label: 'measurementStudio.features.opportunities.filters.dateField',
        type: TileSettingsControlTypes.Selector,
        options: DateTypeOptions,
      }, {
        key: TileSettingsOpportunitiesFields.ClosedType,
        label: 'measurementStudio.features.opportunities.filters.status',
        type: TileSettingsControlTypes.Selector,
        options: ClosedTypeOptions,
      }, {
        key: TileSettingsOpportunitiesFields.WonType,
        label: 'measurementStudio.features.opportunities.filters.closed',
        type: TileSettingsControlTypes.Selector,
        options: WonTypeOptions,
        show: settingsValue => settingsValue[TileSettingsOpportunitiesFields.ClosedType] !== ClosedType.OPEN
      }, {
        key: TileSettingsOpportunitiesFields.Model,
        label: 'measurementStudio.features.opportunities.filters.marketingInfluence',
        type: TileSettingsControlTypes.Selector,
        options: models,
      }]),
    );
  }

  private tileAdapter(data: IOpportunityReport): ITileData {
    if (!data?.opptyData) {
      return {
        items: []
      };
    }

    return {
      items: data.opptyData as unknown as ITileDataItem[],
      totalCount: data.opptyTotals.opptyCount
    };
  }
}
