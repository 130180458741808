<div class="ts-radio-button__container">
  <input
    #input
    (change)="onInputChange($event)"
    (click)="onInputClick($event)"
    [attr.aria-describedby]="ariaDescribedby"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-checked]="isChecked"
    [attr.name]="name"
    [attr.value]="value"
    [checked]="isChecked"
    [disabled]="isDisabled"
    [id]="inputId"
    [required]="isRequired"
    [tabIndex]="tabIndex"
    class="ts-radio-button__input cdk-visually-hidden"
    type="radio"
  >

  <label [attr.for]="inputId" class="ts-radio-button__label">
    <span class="ts-radio-button__label-content">
      <ng-content></ng-content>
    </span>
    <span class="ts-radio-button__label-content ts-radio-button__label-content-default">{{ textContent }}</span>

    <span class="ts-radio-button__label-checkmark">
      <svg viewBox="0 0 100 100" focusable="false">
        <path class="path" fill="none" stroke="#000" stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
              d="M12.1 52.1l24.4 24.4 53-53"/>
      </svg>
    </span>
  </label>
</div>
