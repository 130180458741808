import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsh-add-to-dashboard-button',
  templateUrl: './add-to-dashboard-button.component.html',
  styleUrls: ['./add-to-dashboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToDashboardButtonComponent {
  @Input() public target: string;
  @Output() public add = new EventEmitter<void>();

  emitAdd(): void {
    this.add.emit();
  }
}
