export enum AnalyticsColumnName {
  HasNestedTable = 'hasNestedTable',
  CampaignType = 'campaignType',
  CampaignName = 'campaignName',
  CreatedDate = 'createdDate',
  SourceName = 'sourceName',
  MediumName = 'mediumName',
  TotalTouches = 'totalTouches',
  Cost = 'cost',
  Responses = 'responses',
  People = 'people',
  Accounts = 'accounts',
  Opptys = 'opptys',
  Deals = 'deals',
  InfluencedPipeline = 'influencedPipeline',
  InfluencedRevenue = 'influencedRevenue',
  OpptyTouches = 'opptyTouches',
  DealTouches = 'dealTouches',
  AttributedPipeline = 'attributedPipeline',
  AttributedRevenue = 'attributedRevenue',
  CPL = 'cpl',
  CPO = 'cpo',
  CPD = 'cpd',
  PipelineRoi = 'pipelineRoi',
  ROI = 'roi',
  ROAS = 'roas',
  LTO = 'lto',
  OTD = 'otd',
}
