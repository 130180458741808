import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { TranslateService } from '@ngx-translate/core';

import * as HubRouterReducer from './hub-router.reducer';

@Injectable()
export class HubRouterEffects {
  constructor(
    private actions$: Actions,
    private titleService: Title,
    private translate: TranslateService,
  ) {}

  updateTitle$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction<HubRouterReducer.HubRouterState>>(ROUTER_NAVIGATION),
    map((action: RouterNavigationAction<HubRouterReducer.HubRouterState>) => action.payload.routerState.title),
    filter((title: string) => !!title),
    mergeMap(title => this.translate.get(title)),
    tap(title => this.titleService.setTitle(title))
  ), { dispatch: false });
}
