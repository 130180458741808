<section>
  <h3 class="title">{{ 'shared.dashboards.dialog.globalFilters' | translate }}</h3>
  <ts-menu [menuItemsTemplate]="globalFiltersTemplate" class="ts-menu-as-selector"
           data-cy="tile-options-global-filters-menu">
  <span class="filter-label-result">
     {{ 'shared.dashboards.dialog.addGlobalFilters' | translate }}
  </span>
  </ts-menu>
  <ts-menu [menuItemsTemplate]="savedFiltersTemplate"
           class="ts-menu-as-selector">
  <span class="filter-label-result">
     {{ 'shared.dashboards.dialog.savedFilters' | translate }}
  </span>
  </ts-menu>
  <span class="no-added-filters" *ngIf="appliedFiltersNumber === 0">
    {{ 'shared.dashboards.dialog.noAddedFilters' | translate }}
  </span>
  <tsh-global-filters-table
    *ngIf="appliedFiltersNumber > 0"
    (toggleFolderItemSelect)="selectOneFolderItem($event)"
    (toggleFolderSelectAll)="selectAllFolderItems($event)"
    (removeSelectedFolder)="removeFolder($event)"
    [appliedFiltersNumber]="appliedFiltersNumber"
    [allSelectedFilters]="allSelectedFilters">
  </tsh-global-filters-table>
</section>

<ng-template #savedFiltersTemplate>
  <button tsMenuItem *ngFor="let option of savedFiltersOptions"
          class="menu-button"
          (click)="selectSavedFilter(option.value)"
          type="button">
    {{ option.label }}
  </button>
  <span *ngIf="!savedFiltersOptions?.length" class="no-saved-filters">
    {{ 'shared.dashboards.dialog.noSavedFilters' | translate }}
  </span>
</ng-template>

<ng-template #globalFiltersTemplate>
  <div class="add-global-filter-container">
    <ts-input class="search-input"
              type="text"
              (click)="$event.stopPropagation()"
              [isSmall]="true"
              [formControl]="searchControl"
              [placeholder]="'shared.dashboards.dialog.search' | translate">
    </ts-input>
    <div class="filters-list-container" data-cy="tile-global-filters-list-container">
      <section *ngIf="accountListFolders?.length && accountListsSearchState">
        <span class="filter-title">{{ filtersNames[accountListKey] | translate }}</span>
        <ng-container *ngFor="let folder of accountListFolders">
          <ts-checkbox class="filter-checkbox"
                       *ngIf="accountListsSearchState.itemSelect[folder.folderName] as visibleList"
                       [isChecked]="visibleList.selectedItems > 0"
                       [isDisabled]="visibleList.selectedItems > 0"
                       (inputChange)="toggleGlobalFilterSubSection(accountListKey, folder.folderName)"
                       (click)="$event.stopPropagation()">
            {{ folder.folderName }}
          </ts-checkbox>
        </ng-container>
      </section>
      <ng-container *ngIf="globalFilters && globalFiltersSearchState">
        <ng-container *ngFor="let key of filtersKeys">
          <section *ngIf="globalFilters[key] && globalFiltersSearchState[key]">
            <span class="filter-title">{{ filtersNames[key] | translate }}</span>
            <ng-container *ngFor="let filter of globalFilters[key]">
              <ts-checkbox class="filter-checkbox"
                           *ngIf="globalFiltersSearchState[key]?.itemSelect
                           && globalFiltersSearchState[key]?.itemSelect[filter.number] as visibleFilter"
                           (inputChange)="toggleGlobalFilterSubSection(key, filter.number)"
                           [isChecked]="visibleFilter?.selectedItems > 0"
                           [isDisabled]="visibleFilter?.selectedItems > 0"
                           (click)="$event.stopPropagation()">
                {{ filter.name }}
              </ts-checkbox>
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
    </div>
    <hr class="report-divider">
    <div class="global-filters-buttons">
      <ts-button theme="secondary"
                 buttonType="button"
                 (clicked)="cancelChanges()"
                 [isSmall]="true">
        {{ 'shared.common.cancel' | translate }}
      </ts-button>
      <ts-button theme="default"
                 buttonType="button"
                 (clicked)="addGlobalFilters()"
                 [isDisabled]="!newSelectedGlobalFilters.length"
                 [isSmall]="true"
                 data-cy="tile-global-filters-add-button">
        {{ 'shared.dashboards.dialog.addGlobalFilters' | translate }}
      </ts-button>
    </div>
  </div>
</ng-template>
