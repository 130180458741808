import { Action, createReducer, on } from '@ngrx/store'
import { UtilitiesSidenavActions } from './utilities-sidenav.actions';
import { SidenavUtilitiesTabs } from '@shared/enums';

export const utilitiesSidenavFeatureKey = 'utilitiesSidenav';

export interface SidenavUtilitiesState {
  isSidenavExtended: boolean;
  activeTab: SidenavUtilitiesTabs;
}

export const initialState: SidenavUtilitiesState = {
  isSidenavExtended: false,
  activeTab: SidenavUtilitiesTabs.GlobalFilters,
};

const sideNavReducer = createReducer(
  initialState,
  on(UtilitiesSidenavActions.UpdateExtendedState, (state, action) => ({
    ...state,
    isSidenavExtended: action.payload,
  })),
  on(UtilitiesSidenavActions.UpdateActiveTab, (state, action) => ({
    ...state,
    activeTab: action.payload,
  })),
)

export function reducer(state: SidenavUtilitiesState | undefined, action: Action): SidenavUtilitiesState {
  return sideNavReducer(state, action)
}