import { generalActions } from './general.actions';
import { IDateCohort, IPager, IReportTab, ISorter } from '@shared/interfaces';
import { IAnalyticsColumn } from '../../interfaces';
import { AnalyticsReports, AnalyticTypes } from '../../enums';
import { defaultPager, defaultSorter } from '../../data';
import {
  applyCounters,
  updateAnalyticsTabState,
  updateAnalyticsTabStateAndResetPager
} from '../../helpers/store.helper';
import { updateColumnsVisibility } from '@util/store';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const generalFeatureKey = 'general';

export interface AnalyticsTabState {
  columns: IAnalyticsColumn[];
  sorter: ISorter;
  pager: IPager;
  search: string;
  searchByTags: boolean;
}

export interface GeneralState {
  type: AnalyticTypes;
  filters: IAnalyticsRequest | null;
  selectedDateCohort: IDateCohort | null;
  tabs: IReportTab[];
  webActivitiesTabs: IReportTab[];
  campaignGroups: AnalyticsTabState;
  campaigns: AnalyticsTabState;
  source: AnalyticsTabState;
  campaign: AnalyticsTabState;
  mediums: AnalyticsTabState;
  isDownloading: boolean;
}

const initialTabState: AnalyticsTabState = {
  columns: [],
  sorter: defaultSorter,
  pager: defaultPager,
  search: '',
  searchByTags: false,
};

export const initialState: GeneralState = {
  type: AnalyticTypes.CampaignAnalytics,
  filters: null,
  selectedDateCohort: null,
  tabs: [{
    key: AnalyticsReports.CampaignGroups,
    title: 'measurementStudio.features.analytics.campaignAnalytics.campaignGroupsTable.title',
    link: 'campaign-groups',
  }, {
    key: AnalyticsReports.Campaigns,
    title: 'measurementStudio.features.analytics.campaignAnalytics.campaignsTable.title',
    link: 'campaigns',
  }],
  webActivitiesTabs: [{
    key: AnalyticsReports.Mediums,
    title: 'measurementStudio.features.analytics.webActivities.mediumTable.title',
    link: 'channel-medium',
  }, {
    key: AnalyticsReports.Sources,
    title: 'measurementStudio.features.analytics.webActivities.sourceTable.title',
    link: 'channel-group',
  }, {
    key: AnalyticsReports.UTMCampaigns,
    title: 'measurementStudio.features.analytics.webActivities.campaignTable.title',
    link: 'channel-name',
  }],
  campaignGroups: {...initialTabState},
  source: {...initialTabState},
  campaigns: {...initialTabState},
  campaign: {...initialTabState},
  mediums: {...initialTabState},
  isDownloading: false,
};

const generalReducer = createReducer(
  initialState,
  on(generalActions.setReportType, (state, action) => ({
    ...state,
    type: action.reportType,
  })),
  on(generalActions.loadTabTotalsSuccess, (state, action) => {
    const campaignAnalyticsTabTotals: Map<AnalyticsReports, number> = new Map()
      .set(AnalyticsReports.CampaignGroups, action.data.campaignTypeTotal)
      .set(AnalyticsReports.Campaigns, action.data.campaignTotal);
    return {
      ...state,
      tabs: applyCounters(state.tabs, campaignAnalyticsTabTotals),
    };
  }),
  on(generalActions.loadWebActivitiesTabTotalsSuccess, (state, action) => {
    const webActivitiesTabTotals: Map<AnalyticsReports, number> = new Map()
      .set(AnalyticsReports.Mediums, action.data.mediumTotal)
      .set(AnalyticsReports.Sources, action.data.sourceTotal)
      .set(AnalyticsReports.UTMCampaigns, action.data.campaignTotal);
    return {
      ...state,
      webActivitiesTabs: applyCounters(state.webActivitiesTabs, webActivitiesTabTotals),
    };
  }),
  on(generalActions.downloadAnalyticsCSVSuccess,
    generalActions.downloadAnalyticsCSVFailure, (state) => ({
      ...state,
      isDownloading: false
    })),
  on(generalActions.downloadAnalyticsCSV, (state) => ({
    ...state,
    isDownloading: true,
  })),
  on(generalActions.setSelectedDateCohort, (state, action) => ({
    ...state,
    selectedDateCohort: action.cohort,
  })),
  on(generalActions.setFilters, (state, action) => ({
    ...state,
    filters: action.request,
  })),
  on(generalActions.changeCampaignGroupsSorting, (state, action) => {
    const sortData = action.sortDirection ? {sortField: action.sortField, sortDirection: action.sortDirection} : defaultSorter;
    return {
      ...state,
      campaignGroups: updateAnalyticsTabState(state.campaignGroups, 'sorter', sortData),
    };
  }),
  on(generalActions.changeCampaignGroupsPagination, (state, action) => ({
    ...state,
    campaignGroups: updateAnalyticsTabState(state.campaignGroups, 'pager', {pageNum: action.pageNum, pageSize: action.pageSize}),
  })),
  on(generalActions.changeCampaignGroupsSearch, (state, action) => ({
    ...state,
    campaignGroups: updateAnalyticsTabState(state.campaignGroups, 'search', action.search),
  })),
  on(generalActions.toggleCampaignGroupsColumnVisibility, (state, action) => {
    const campaignGroupsColumns = updateColumnsVisibility(state.campaignGroups.columns, action.data);
    return {
      ...state,
      campaignGroups: updateAnalyticsTabState(state.campaignGroups, 'columns', campaignGroupsColumns),
    };
  }),
  on(generalActions.toggleSourceColumnVisibility, (state, action) => {
    const sourceColumns = updateColumnsVisibility(state.source.columns, action.data);
    return {
      ...state,
      source: updateAnalyticsTabState(state.source, 'columns', sourceColumns),
    };
  }),
  on(generalActions.toggleCampaignsColumnVisibility, (state, action) => {
    const campaignsColumns = updateColumnsVisibility(state.campaigns.columns, action.data);
    return {
      ...state,
      campaigns: updateAnalyticsTabState(state.campaigns, 'columns', campaignsColumns),
    };
  }),
  on(generalActions.toggleMediumsColumnVisibility, (state, action) => {
    const mediumColumns = updateColumnsVisibility(state.mediums.columns, action.data);
    return {
      ...state,
      mediums: updateAnalyticsTabState(state.mediums, 'columns', mediumColumns),
    };
  }),
  on(generalActions.toggleCampaignColumnVisibility, (state, action) => {
    const campaignColumns = updateColumnsVisibility(state.campaign.columns, action.data);
    return {
      ...state,
      campaign: updateAnalyticsTabState(state.campaign, 'columns', campaignColumns),
    };
  }),
  on(generalActions.changeCampaignsSorting, (state, action) => ({
    ...state,
    campaigns: updateAnalyticsTabState(state.campaigns, 'sorter',
      action.sortDirection ? {sortField: action.sortField, sortDirection: action.sortDirection} : defaultSorter),
  })),
  on(generalActions.changeCampaignsPagination, (state, action) => ({
    ...state,
    campaigns: updateAnalyticsTabState(state.campaigns, 'pager', {pageNum: action.pageNum, pageSize: action.pageSize}),
  })),
  on(generalActions.changeCampaignsSearch, (state, action) => ({
    ...state,
    campaigns: updateAnalyticsTabState(state.campaigns, 'search', action.search),
  })),
  on(generalActions.changeCampaignSorting, (state, action) => ({
    ...state,
    campaign: updateAnalyticsTabState(state.campaign, 'sorter',
      action.sortDirection ? {sortField: action.sortField, sortDirection: action.sortDirection} : defaultSorter),
  })),
  on(generalActions.changeCampaignPagination, (state, action) => ({
    ...state,
    campaign: updateAnalyticsTabState(state.campaign, 'pager', {pageNum: action.pageNum, pageSize: action.pageSize}),
  })),
  on(generalActions.changeCampaignSearch, (state, action) => ({
    ...state,
    campaign: updateAnalyticsTabState(state.campaign, 'search', action.search),
  })),
  on(generalActions.changeSourceSorting, (state, action) => ({
    ...state,
    source: updateAnalyticsTabState(state.source, 'sorter',
      action.sortDirection ? {sortField: action.sortField, sortDirection: action.sortDirection} : defaultSorter),
  })),
  on(generalActions.changeSourcePagination, (state, action) => ({
    ...state,
    source: updateAnalyticsTabState(state.source, 'pager', {pageNum: action.pageNum, pageSize: action.pageSize}),
  })),
  on(generalActions.changeSourceSearch, (state, action) => ({
    ...state,
    source: updateAnalyticsTabState(state.source, 'search', action.search),
  })),
  on(generalActions.changeMediumsSorting, (state, action) => ({
    ...state,
    mediums: updateAnalyticsTabState(state.mediums, 'sorter',
      action.sortDirection ? {sortField: action.sortField, sortDirection: action.sortDirection} : defaultSorter),
  })),
  on(generalActions.changeMediumsPagination, (state, action) => ({
    ...state,
    mediums: updateAnalyticsTabState(state.mediums, 'pager', {pageNum: action.pageNum, pageSize: action.pageSize}),
  })),
  on(generalActions.changeMediumsSearch, (state, action) => ({
    ...state,
    mediums: updateAnalyticsTabState(state.mediums, 'search', action.search),
  })),
  on(generalActions.setColumns, (state, action) => ({
    ...state,
    campaign: updateAnalyticsTabState(state.campaign, 'columns', action.columns.campaign),
    campaignGroups: updateAnalyticsTabState(state.campaignGroups, 'columns', action.columns.campaignGroups),
    campaigns: updateAnalyticsTabState(state.campaigns, 'columns', action.columns.campaigns),
    source: updateAnalyticsTabState(state.source, 'columns', action.columns.source),
    mediums: updateAnalyticsTabState(state.mediums, 'columns', action.columns.mediums),
  })),
  on(generalActions.changeCampaignsSearchByTags, (state, action) => ({
    ...state,
    campaigns: updateAnalyticsTabState(state.campaigns, 'searchByTags', action.searchByTags),
  })),
  on(generalActions.changeCampaignSearchByTags, (state, action) => {
    return {
      ...state,
      campaign: updateAnalyticsTabStateAndResetPager(state.campaign, 'searchByTags', action.searchByTags)
    }
  }),
);

export function reducer(state, action: Action): GeneralState {
  return generalReducer(state, action);
}
