import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { OpportunitiesComponent } from './containers/opportunities/opportunities.component';
import { ReportAccessGuard } from '@shared/data-access/guards';
import { RouteItemEnum } from '@shared/enums';

const routes: Routes = [
  {
    path: '',
    component: OpportunitiesComponent,
    canActivate: [
      ReportAccessGuard,
    ],
    data: {
      title: 'Opportunity Insights - Measurement Studio',
      routeId: RouteItemEnum.OpportunityInsights,
    }
  },
  {
    path: 'opportunity-specific/attribution',
    loadChildren: () => import('@measurement-studio/features/opportunity-specific').then(m => m.OpportunitySpecificModule),
    canActivate: [
      ReportAccessGuard,
    ],
    data: {
      title: 'Opportunity Journey - Measurement Studio',
      routeId: RouteItemEnum.OpportunitySpecific,
    },
  },
  {
    path: 'all-opptys-touches',
    loadChildren: () => import('@measurement-studio/features/opportunity-touches').then(m => m.OpportunityTouchesModule),
    canActivate: [
      ReportAccessGuard,
    ],
    data: {
      parent: {
        title: 'measurementStudio.features.opportunityTouches.parentTitle',
        routeId: RouteItemEnum.OpportunityInsights
      },
      title: 'Opportunity Campaign Responses - Measurement Studio',
      routeId: RouteItemEnum.OpportunityAllOpptysTouches,
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunitiesRoutingModule {
}
