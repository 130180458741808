<div [ngClass]="{'button-group': showDownload && showColumns}" class="table-buttons-panel">
  <ts-tooltip
    *ngIf="showColumns"
    tooltipValue="Edit Columns"
    tooltipPosition="above"
    [hasUnderline]="false">
    <ts-menu [menuItemsTemplate]="columnsTemplate"
             data-cy="toggle-menu"
             [isDisabled]="isDisabled"
             triggerTheme="secondary"
             data-cy="table-buttons-visibility-button"
             class="ts-menu-for-btn-group">
      <i class="fas fa-columns"></i>
    </ts-menu>
  </ts-tooltip>
  <ng-template #columnsTemplate>
    <div class="edit-columns-container">
      <ts-input *ngIf="isSearchVisible"
                class="search-input"
                type="text"
                (click)="$event.stopPropagation()"
                [formControl]="search"
                [isRequired]="false"
                [placeholder]="searchLabel | translate">
      </ts-input>
      <!-- Use columns property instead of filteredColumns if isSearchVisible is false to improve optimization -->
      <div *ngFor="let column of isSearchVisible ? filteredColumns : columns; let i = index"
           class="check-box-container">
        <!-- The menu normally closes after each interaction, so we need to stop propagation here to
            support multiple selections while open -->
        <ts-toggle
          labelPosition="before"
          theme="blue"
          [attr.data-cy]="'toggle-column-' + i"
          [fullWidth]="true"
          (selectionChange)="toggleVisibility($event.checked, column.name)"
          (click)="$event.stopPropagation()"
          [isChecked]="!!column.visible">
          {{ column.displayName | translate }}
        </ts-toggle>
      </div>
    </div>
  </ng-template>
  <tsh-csv-download-button
    *ngIf="showDownload"
    data-cy="csv-download-button"
    [isDisabled]="isDisabled"
    [showProgress]="showProgress"
    data-cy="csv-download-button"
    (download)="emitDownload()"
  ></tsh-csv-download-button>
</div>
