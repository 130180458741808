import { DataTypeEnum } from '@shared/enums';
import { OverviewViewTypeEnum } from './enums/overview-view-type.enum';
import {
  KpiAudience,
  KpiGroup, KpiInterval, KpiProgression,
  KpiTrend, KpiType, SeriesTypes
} from '../enums/scorecard.enum';
import { IHudData } from './interfaces/hud-data.interface';
import { ILabelValue, IReportColumn } from '@shared/interfaces';
import { IScorecardList } from './interfaces/scorecard-list.interface';
import { IOverviewFilters } from './interfaces/overview-filters.interface';
import { OverviewAudienceEnum } from './enums/overview-audience.enum';

export const VALID_OVERVIEW_VIEW_TYPES = [
  OverviewViewTypeEnum.Account,
  OverviewViewTypeEnum.Opportunity
];

export const VALID_AUDIENCE_TYPES = [
  OverviewAudienceEnum.Segments,
  OverviewAudienceEnum.AccountLists
];

export const ACCOUNT_VIEW_COLUMNS: IReportColumn[] = [
  {
    name: 'aggregateName',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.name',
    dataType: DataTypeEnum.Text,
    visible: true,
    startSorting: 'asc',
    internalLink(row: IScorecardList, filters: IOverviewFilters) {
      return {
        link: `/scorecard/list-details/${row.listId}`,
        queryParams: {
          cohort: filters.cohort,
          startDate: filters.startDate,
          endDate: filters.endDate,
          audience: filters.audience,
          aggregateId: row.aggregateId
        }
      };
    }
  },
  {
    name: 'totalAccounts',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.totalAccounts',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'engagedAccounts',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.engagedAccounts',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'percentEngaged',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.percentEngaged',
    dataType: DataTypeEnum.Percent,
    decimals: 1,
    visible: false,
    startSorting: 'desc'
  },
  {
    name: 'opportunityAccounts',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.opportunityAccounts',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'percentOpportunities',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.percentOpportunities',
    dataType: DataTypeEnum.Percent,
    decimals: 1,
    visible: false,
    startSorting: 'desc'
  },
  {
    name: 'wonAccounts',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.wonAccounts',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'percentWon',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.percentWon',
    dataType: DataTypeEnum.Percent,
    decimals: 1,
    visible: false,
    startSorting: 'desc'
  },
  {
    name: 'pipeline',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.pipeline',
    dataType: DataTypeEnum.Currency,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'revenue',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.revenue',
    dataType: DataTypeEnum.Currency,
    visible: true,
    startSorting: 'desc'
  },

  {
    name: 'revenuePerAccount',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.revenuePerAccount',
    dataType: DataTypeEnum.Currency,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'dealVelocity',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.dealVelocity',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  }
];
export const OPPORTUNITY_VIEW_COLUMNS: IReportColumn[] = [
  {
    name: 'aggregateName',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.name',
    dataType: DataTypeEnum.Text,
    visible: true,
    startSorting: 'asc',
    internalLink(row: IScorecardList, filters: IOverviewFilters) {
      return {
        link: `/scorecard/list-details/${row.listId}`,
        queryParams: {
          cohort: filters.cohort,
          startDate: filters.startDate,
          endDate: filters.endDate,
          aggregateId: row.aggregateId,
          audience: filters.audience,
        }
      };
    }
  },
  {
    name: 'totalAccounts',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.totalAccounts',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'opportunitiesCreated',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.opportunitiesCreated',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'pipeline',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.pipeline',
    dataType: DataTypeEnum.Currency,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'pipelineCoverage',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.pipelineCoverage',
    dataType: DataTypeEnum.Number,
    decimals: 1,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'opportunitiesClosed',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.opportunitiesClosed',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'closedWonDeal',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.closedWonDeal',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'revenue',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.revenue',
    dataType: DataTypeEnum.Currency,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'winRate',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.winRate',
    dataType: DataTypeEnum.Percent,
    decimals: 1,
    visible: true,
    startSorting: 'desc'
  },
  {
    name: 'averageDealSize',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.averageDealSize',
    dataType: DataTypeEnum.Currency,
    visible: true
  },
  {
    name: 'dealVelocity',
    width: 200,
    displayName: 'feature.scorecardOverview.columns.dealVelocity',
    dataType: DataTypeEnum.Number,
    decimals: 0,
    visible: true,
    startSorting: 'desc'
  }
];
export const OVERVIEW_VIEW_TYPE_OPTIONS: ILabelValue[] = [{
  label: 'measurementStudio.features.scorecard.viewType.accounts',
  value: OverviewViewTypeEnum.Account
}, {
  label: 'measurementStudio.features.scorecard.viewType.opportunities',
  value: OverviewViewTypeEnum.Opportunity
}];

export const OVERVIEW_AUDIENCE_OPTIONS: ILabelValue[]= [ {
  label: 'measurementStudio.features.scorecard.audiences.accountLists',
  value: OverviewAudienceEnum.AccountLists
}];

export const OVERVIEW_AUDIENCE_SEGMENT_OPTIONS: ILabelValue[]= [ {
  label: 'measurementStudio.features.scorecard.audiences.accountLists',
  value: OverviewAudienceEnum.AccountLists
},{
  label: 'measurementStudio.features.scorecard.audiences.segments',
  value: OverviewAudienceEnum.Segments
}];

export const OPPORTUNITY_HUB_LIST_TILES: IHudData[] = [
  {
    title: 'measurementStudio.features.scorecard.metrics.targetAccount.title',
    field: 'totalAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.targetAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Account,
      progression: KpiProgression.List,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunitiesCreated.title',
    field: 'opportunitiesCreated',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunitiesCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyCreated,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyCreated,
      progression: KpiProgression.Opportunity,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunitiesClosed.title',
    field: 'opportunitiesClosed',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunitiesClosed.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyClosed,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyClosed,
      progression: KpiProgression.List,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.closedWonDeals.title',
    field: 'closedWonDeals',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    extraField: 'closedWonDealsPercent',
    extraValueDecimals: 1,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.closedWonDeals.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyWon,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyWon,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.pipelineCreated.title',
    field: 'pipelineCreated',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.pipelineCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Pipeline,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Pipeline,
      progression: KpiProgression.Opportunity,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenueWon.title',
    field: 'revenueWon',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenueWon.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Revenue,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Revenue,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.averageDealSize.title',
    field: 'averageDealSize',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.averageDealSize.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.AvgDealSize,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.AvgDealSize,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.velocity.title',
    field: 'dealVelocity',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.velocity.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.DealVelocity,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.DealVelocity,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  }
];
export const ACCOUNT_HUB_LIST_TILES: IHudData[] = [
  {
    title: 'measurementStudio.features.scorecard.metrics.targetAccount.title',
    field: 'totalAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.targetAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.List,
      type: KpiType.Account,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.engagedAccounts.title',
    field: 'engagedAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'engagedAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.engagedAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Engaged,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Engaged,
      type: KpiType.Account,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunityAccounts.title',
    field: 'opportunityAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'opportunityAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunityAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Opportunity,
      type: KpiType.Account,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.wonAccounts.title',
    field: 'wonAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'wonAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.wonAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Account,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.velocity.title',
    field: 'dealVelocity',
    decimals: 0,
    dataType: DataTypeEnum.LowerIntNumber,
    tooltip: 'measurementStudio.features.scorecard.metrics.velocity.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.DealVelocity,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.DealVelocity,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.pipelineCreated.title',
    field: 'pipelineCreated',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.pipelineCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Pipeline,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.List,
      type: KpiType.Pipeline,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenueWon.title',
    field: 'revenueWon',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenueWon.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Revenue,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Won,
      type: KpiType.Revenue,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenuePerAccount.title',
    field: 'revenuePerAccount',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenuePerAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardAggregate,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.AvgDealSize,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.AvgDealSize,
      progression: KpiProgression.Won,
      audience: KpiAudience.Account,
      group: KpiGroup.ScorecardAggregate,
    }
  }
];


export const OPPORTUNITY_HUB_SEGMENT_TILES: IHudData[] = [
  {
    title: 'measurementStudio.features.scorecard.metrics.targetAccount.title',
    field: 'totalAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.targetAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Account,
      progression: KpiProgression.Segment,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunitiesCreated.title',
    field: 'opportunitiesCreated',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunitiesCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyCreated,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyCreated,
      progression: KpiProgression.Opportunity,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunitiesClosed.title',
    field: 'opportunitiesClosed',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunitiesClosed.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyClosed,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyClosed,
      progression: KpiProgression.Segment,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.closedWonDeals.title',
    field: 'closedWonDeals',
    decimals: 1,
    dataType: DataTypeEnum.Number,
    extraField: 'closedWonDealsPercent',
    extraValueDecimals: 1,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.closedWonDeals.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.OpptyWon,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.OpptyWon,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.pipelineCreated.title',
    field: 'pipelineCreated',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.pipelineCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Pipeline,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Pipeline,
      progression: KpiProgression.Opportunity,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenueWon.title',
    field: 'revenueWon',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenueWon.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Revenue,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Revenue,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.averageDealSize.title',
    field: 'averageDealSize',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.averageDealSize.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.AvgDealSize,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.AvgDealSize,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.velocity.title',
    field: 'dealVelocity',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.velocity.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.DealVelocity,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.DealVelocity,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  }
];
export const ACCOUNT_HUB_SEGMENT_TILES: IHudData[] = [
  {
    title: 'measurementStudio.features.scorecard.metrics.targetAccount.title',
    field: 'totalAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.scorecard.metrics.targetAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.List,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Segment,
      type: KpiType.Account,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.engagedAccounts.title',
    field: 'engagedAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'engagedAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.engagedAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Engaged,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Engaged,
      type: KpiType.Account,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.opportunityAccounts.title',
    field: 'opportunityAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'opportunityAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.opportunityAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Opportunity,
      type: KpiType.Account,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.wonAccounts.title',
    field: 'wonAccounts',
    decimals: 0,
    dataType: DataTypeEnum.Number,
    extraField: 'wonAccountsPercent',
    extraValueDecimals: 2,
    extraValueDataType: DataTypeEnum.Percent,
    tooltip: 'measurementStudio.features.scorecard.metrics.wonAccounts.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Account,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.Account,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.velocity.title',
    field: 'dealVelocity',
    decimals: 0,
    dataType: DataTypeEnum.LowerIntNumber,
    tooltip: 'measurementStudio.features.scorecard.metrics.velocity.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.DealVelocity,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.Line,
      type: KpiType.DealVelocity,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.pipelineCreated.title',
    field: 'pipelineCreated',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.pipelineCreated.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Pipeline,
      kpiProgression: KpiProgression.Opportunity,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Segment,
      type: KpiType.Pipeline,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenueWon.title',
    field: 'revenueWon',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenueWon.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.Revenue,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      progression: KpiProgression.Won,
      type: KpiType.Revenue,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  },
  {
    title: 'measurementStudio.features.scorecard.metrics.revenuePerAccount.title',
    field: 'revenuePerAccount',
    decimals: 2,
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.scorecard.metrics.revenuePerAccount.tooltip',
    trendingParams: {
      kpiGroup: KpiGroup.ScorecardSegment,
      kpiInterval: KpiInterval.Months,
      kpiTrend: KpiTrend.Cumulative,
      kpiType: KpiType.AvgDealSize,
      kpiProgression: KpiProgression.Won,
      visualization: SeriesTypes.StackedColumn,
      type: KpiType.AvgDealSize,
      progression: KpiProgression.Won,
      audience: KpiAudience.Segment,
      group: KpiGroup.ScorecardSegment
    }
  }
];
