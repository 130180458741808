export enum AccountListsFields {
    AccountFilter = 'accountFilter',
    Field = 'fld'
}

export enum EngagementInsightsFields {
    TimeUnit = 'timeUnit',
    AccountField = 'accountField',
    MetricField = 'metricField',
    Threshold = 'threshold',
    AccountCorrelation = 'accountCorrelation',
    Models = 'models'
}

export enum TimeUnits {
    Auto = 'auto',
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Quarters = 'fiscal_qtr',
    Years = 'fiscal_year',
}

export enum AccountListFilters {
    All = 'all',
    NeedsMarketing = 'needs_marketing',
    NeedsSales = 'needs_sales',
    NeedsMarketingSales = 'needs_sales_and_marketing',
    NeedsContacts = 'needs_contacts',
    HasOpenOpportunities = 'has_open_opportunities',
    ActiveAccountsWithNoOpenOpportunities = 'active_with_no_opportunities',
    AllAccountsWithNoOpenOpportunities = 'no_open_opportunities',
    WithoutAdImpressions = 'without_ad_impressions',
    WithAdImpressions = 'with_ad_impressions',
    WithoutAdClicks = 'without_ad_clicks',
    WithAdClicks = 'with_ad_clicks',
    WithoutPageViews = 'without_page_views',
    WithPageViews = 'with_page_views',
}

export enum AccountTypes {
    Impressions = 'ad_impressions',
    Clicks = 'ad_clicks',
    PageViews = 'page_views',
    Spikes = 'spikes',
    Opptys = 'opptys',
    Deals = 'deals',
}

export enum AccountMetrics {
    Impressions = 'ad_impressions',
    Clicks = 'ad_clicks',
    PageViews = 'page_views',
    Spikes = 'spikes',
}

export enum FieldType {
    AccountColumns = 'account_columns',
    Firmographics = 'firmographics',
    EngagementSpike = 'engagement_spike',
    AdImpressions = 'ad_impressions',
    AdClicks = 'ad_clicks',
    WebVisits = 'web_visits',
    Intent = 'intent',
    SigstrRelationship = 'sigstr_relationship',
    Contact = 'contact',
    InfluencedContacts = 'influenced_contacts',
    CampaignMember = 'campaign_member',
    ParentAccount = 'parent_account',
    OpptyInfluence = 'oppty_influence',
    SalesActivity = 'sales_activity',
    OpenOpportunities = 'open_opportunities',
    Opportunities = 'opportunities',
    Deals = 'deals',
}

export enum ReloadDataType {
    None,
    Accounts,
    Folders,
}

