import { IFactoryParams } from './factory-params.interface';

export interface IDateCohort {
    cohort: string;
    name: string;
    startDate: number;
    endDate: number;
}

export interface IDateCohortGroup {
  label: string;
  options: IDateCohort[];
}

export function dateCohortFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IDateCohort, number, boolean> = {}): IDateCohort {
    const date = 1614062065112;
    return {
        cohort: `cohort${n}`,
        name: `name${n}`,
        startDate: date,
        endDate: date,
        ...overrides,
    };
}
