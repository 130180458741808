import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AnalyticsTabState, generalFeatureKey, GeneralState } from './general.reducer';
import { AnalyticsState, selectAnalyticsState } from '..';
import { IPager, IReportTab, ISorter } from '@shared/interfaces';
import { getCampaignGroupsTotalResults } from '../campaign-groups/campaign-groups.selectors';
import { getCampaignsTotalResults } from '../campaigns/campaigns.selectors';
import { getMediumsTotalResults } from '../mediums/mediums.selectors';
import { getSourceTotalResults } from '../source/source.selectors';
import { getCampaignTotalResults } from '../campaign/campaign.selectors';
import * as interfaces from '../../interfaces';
import * as UserSelectors from '@user/user.selectors';
import { FeatureFlagsEnum, ModelType } from '@shared/enums';
import { AnalyticsColumnName as Column, AnalyticsReports } from '../../enums';
import { applyCounters, getColumnKeyByModel } from '../../helpers/store.helper';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export const selectGeneralState = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[generalFeatureKey]);

export const getReportType = createSelector(selectGeneralState,
  (state: GeneralState) => state.type);

export const getFilters = createSelector(selectGeneralState,
  (state: GeneralState) => state.filters);

export const getCampaignGroups = createSelector(selectGeneralState,
  (state: GeneralState) => state.campaignGroups);

export const getSource = createSelector(selectGeneralState,
  (state: GeneralState) => state.source);

export const getMediums = createSelector(selectGeneralState,
  (state: GeneralState) => state.mediums);

export const getCampaigns = createSelector(selectGeneralState,
  (state: GeneralState) => state.campaigns);

export const getCampaign = createSelector(selectGeneralState,
  (state: GeneralState) => state.campaign);

export const getIsDownloadInProcess = createSelector(selectGeneralState,
  (state: GeneralState) => state.isDownloading);

export const getSelectedDateCohort = createSelector(selectGeneralState,
  (state: GeneralState) => state.selectedDateCohort);

export const getCampaignAnalyticsTabs = createSelector(selectGeneralState,
  (state: GeneralState) => state.tabs);

export const getWebActivitiesTabs = createSelector(selectGeneralState,
  (state: GeneralState) => state.webActivitiesTabs);

// Searches
const getSearch = (state: AnalyticsTabState): string => state.search;
export const getCampaignsSearch = createSelector(getCampaigns, getSearch);
export const getCampaignGroupsSearch = createSelector(getCampaignGroups, getSearch);
export const getCampaignSearch = createSelector(getCampaign, getSearch);
export const getSourceSearch = createSelector(getSource, getSearch);
export const getMediumsSearch = createSelector(getMediums, getSearch);
export const getCampaignsSearchByTags = createSelector(getCampaigns,
  (state: AnalyticsTabState): boolean => state.searchByTags);

export const getCampaignSearchByTags = createSelector(getCampaign,
  (state: AnalyticsTabState): boolean => {
    return state.searchByTags
  });

// Sorters
const getSorter = (state: AnalyticsTabState): ISorter => state.sorter;
export const getCampaignsSorter = createSelector(getCampaigns, getSorter);
export const getCampaignGroupsSorter = createSelector(getCampaignGroups, getSorter);
export const getCampaignSorter = createSelector(getCampaign, getSorter);
export const getSourceSorter = createSelector(getSource, getSorter);
export const getMediumsSorter = createSelector(getMediums, getSorter);

// Pagers
const getPager = (state: AnalyticsTabState): IPager => state.pager;
export const getCampaignsPager = createSelector(getCampaigns, getPager);
export const getCampaignGroupsPager = createSelector(getCampaignGroups, getPager);
export const getCampaignPager = createSelector(getCampaign, getPager);
export const getSourcePager = createSelector(getSource, getPager);
export const getMediumsPager = createSelector(getMediums, getPager);

export const getCampaignAnalyticsCountableTabs = createSelector(
  getCampaignAnalyticsTabs,
  getCampaignGroupsTotalResults,
  getCampaignsTotalResults,
  (
    tabs: IReportTab[],
    campaignGroupsTotalResults: number | undefined,
    campaignsTotalResults: number | undefined,
  ) => {
    const data: Map<AnalyticsReports, number> = new Map()
      .set(AnalyticsReports.CampaignGroups, campaignGroupsTotalResults)
      .set(AnalyticsReports.Campaigns, campaignsTotalResults);
    return applyCounters(tabs, data);
  });

export const getWebActivitiesCountableTabs = createSelector(
  getWebActivitiesTabs,
  getMediumsTotalResults,
  getSourceTotalResults,
  getCampaignTotalResults,
  (
    tabs: IReportTab[],
    mediumTotalResults: number,
    sourceTotalResults: number,
    campaignTotalResults: number,
  ) => {
    const data: Map<AnalyticsReports, number> = new Map()
      .set(AnalyticsReports.Mediums, mediumTotalResults)
      .set(AnalyticsReports.Sources, sourceTotalResults)
      .set(AnalyticsReports.UTMCampaigns, campaignTotalResults);
    return applyCounters(tabs, data);
  });

const getColumnNamesThatRequiredFeatureFlag = (model: ModelType): Column | string[] => {
  switch (model) {
    case ModelType.Sourced:
      return [Column.LTO, Column.OTD, Column.CPL, Column.CPO, Column.CPD];
    case ModelType.Last:
      return [Column.LTO, Column.OTD];
    default:
      return [];
  }
};

const filterColumns = (
  columns: interfaces.IAnalyticsColumn[],
  model: ModelType,
  isAnalyticsColumnsEnabled: boolean,
): interfaces.IAnalyticsColumn[] => {
  // return empty array if model is undefined
  if (!model) {
    return [];
  }

  const key = getColumnKeyByModel(model);
  const markedColumns = getColumnNamesThatRequiredFeatureFlag(model);
  return columns.filter(column => column.models.includes(model)
    && (isAnalyticsColumnsEnabled ? true : !markedColumns.includes(column.name))
  ).map(column => {
    switch (column.name) {
      case Column.AttributedPipeline:
        return column.displayName.includes(key) ? column : {
          ...column,
          displayName: `measurementStudio.features.analytics.campaignAnalytics.columns.attributedPipeline.${key}`,
        };
      case Column.AttributedRevenue:
        return column.displayName.includes(key) ? column : {
          ...column,
          displayName: `measurementStudio.features.analytics.campaignAnalytics.columns.attributedRevenue.${key}`,
        };
      default:
        return column;
    }
  });
};

const createFilteredColumnsSelector = (columnSelector: MemoizedSelector<unknown, interfaces.IAnalyticsColumn[]>) => createSelector(
  columnSelector,
  getFilters,
  UserSelectors.isFeatureFlagActive(FeatureFlagsEnum.AnalyticsAttributedColumns),
  (
    columns: interfaces.IAnalyticsColumn[],
    filters: IAnalyticsRequest,
    isAnalyticsColumnsEnabled: boolean,
  ) => filterColumns(columns, filters?.model, isAnalyticsColumnsEnabled));

const createVisibleColumnsSelector = (columnsSelector: MemoizedSelector<unknown, interfaces.IAnalyticsColumn[]>) => createSelector(
  columnsSelector, (columns: interfaces.IAnalyticsColumn[]) => columns.filter(column => column.visible));

const createColumnNamesSelector = (columnsSelector: MemoizedSelector<unknown, interfaces.IAnalyticsColumn[]>) => createSelector(
  columnsSelector, (columns: interfaces.IAnalyticsColumn[]) => columns.map(column => column.name));

// Campaign Groups Column Selectors
export const getAllCampaignGroupsColumns = createSelector(getCampaignGroups, (state: AnalyticsTabState) => state.columns);
export const getCampaignGroupsColumns = createFilteredColumnsSelector(getAllCampaignGroupsColumns);
export const getVisibleCampaignGroupsColumns = createVisibleColumnsSelector(getCampaignGroupsColumns);
export const getVisibleCampaignGroupsColumnNames = createColumnNamesSelector(getVisibleCampaignGroupsColumns);

// Campaigns Column Selectors
export const getAllCampaignsColumns = createSelector(getCampaigns, (state: AnalyticsTabState) => state.columns);
export const getCampaignsColumns = createFilteredColumnsSelector(getAllCampaignsColumns);
export const getVisibleCampaignsColumns = createVisibleColumnsSelector(getCampaignsColumns);
export const getVisibleCampaignsColumnNames = createColumnNamesSelector(getVisibleCampaignsColumns);

// Source Column Selectors
export const getAllSourceColumns = createSelector(getSource, (state: AnalyticsTabState) => state.columns);
export const getSourceColumns = createFilteredColumnsSelector(getAllSourceColumns);
export const getVisibleSourceColumns = createVisibleColumnsSelector(getSourceColumns);
export const getVisibleSourceColumnNames = createColumnNamesSelector(getVisibleSourceColumns);

// Campaign Column Selectors
export const getAllCampaignColumns = createSelector(getCampaign, (state: AnalyticsTabState) => state.columns);
export const getCampaignColumns = createFilteredColumnsSelector(getAllCampaignColumns);
export const getVisibleCampaignColumns = createVisibleColumnsSelector(getCampaignColumns);
export const getVisibleCampaignColumnNames = createColumnNamesSelector(getVisibleCampaignColumns);

// Medium Column Selectors
export const getAllMediumsColumns = createSelector(getMediums, (state: AnalyticsTabState) => state.columns);
export const getMediumsColumns = createFilteredColumnsSelector(getAllMediumsColumns);
export const getVisibleMediumsColumns = createVisibleColumnsSelector(getMediumsColumns);
export const getVisibleMediumsColumnNames = createColumnNamesSelector(getVisibleMediumsColumns);

// nested columns
const filterNestedColumns = (visibleColumnNames: string[], columns: interfaces.IAnalyticsColumn[], uniqueColumns: string[]) =>
  // columns.slice(1) => remove toggle column for nested table
  columns.slice(1).filter((column) => {
    return visibleColumnNames.includes(column.name) || uniqueColumns.includes(column.name);
  });

export const getSourceNestedColumns = createSelector(
  getVisibleCampaignGroupsColumnNames,
  getSourceColumns,
  (visibleColumnNames: string[], columns: interfaces.IAnalyticsColumn[]) =>
    filterNestedColumns(visibleColumnNames, columns, [Column.SourceName, Column.MediumName])
);
export const getVisibleSourceNestedColumns = createVisibleColumnsSelector(getSourceNestedColumns);
export const getVisibleSourceNestedColumnNames = createColumnNamesSelector(getVisibleSourceNestedColumns);

export const getCampaignNestedColumns = createSelector(
  getVisibleCampaignsColumnNames,
  getCampaignColumns,
  (visibleColumnNames: string[], columns: interfaces.IAnalyticsColumn[]) =>
    filterNestedColumns(visibleColumnNames, columns, [Column.CampaignName, Column.SourceName, Column.MediumName])
);
export const getVisibleCampaignNestedColumns = createVisibleColumnsSelector(getCampaignNestedColumns);
export const getVisibleCampaignNestedColumnNames = createColumnNamesSelector(getVisibleCampaignNestedColumns);

export const getCampaignCampaignsNestedColumns = createSelector(
  getVisibleCampaignColumnNames,
  getCampaignsColumns,
  (visibleColumnNames: string[], columns: interfaces.IAnalyticsColumn[]) =>
    filterNestedColumns(visibleColumnNames, columns, [Column.CampaignType, Column.CampaignName])
);
export const getCampaignVisibleCampaignsNestedColumns = createVisibleColumnsSelector(getCampaignCampaignsNestedColumns);
export const getCampaignVisibleCampaignsNestedColumnNames = createColumnNamesSelector(getCampaignVisibleCampaignsNestedColumns);

export const getSourceCampaignsNestedColumns = createSelector(
  getVisibleSourceColumnNames,
  getCampaignsColumns,
  (visibleColumnNames: string[], columns: interfaces.IAnalyticsColumn[]) =>
    filterNestedColumns(visibleColumnNames, columns, [Column.CampaignType, Column.CampaignName])
);
export const getSourceVisibleCampaignsNestedColumns = createVisibleColumnsSelector(getSourceCampaignsNestedColumns);
export const getSourceVisibleCampaignsNestedColumnNames = createColumnNamesSelector(getSourceVisibleCampaignsNestedColumns);


