import { ChangeDetectionStrategy, Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { TsTableDataSource, TsTableModule } from '@terminus-lib/ui-table';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TranslateModule } from '@ngx-translate/core';

import { TILE_MODAL_DASHBOARDS_COLUMNS, TILE_MODAL_DASHBOARDS_COLUMNS_MAP, TileModalDashboardsColumnKeys } from './select-dashboard.model';
import { IDashboard, IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { TypedDataModule } from '@ui/pipes/typed-data';

@Component({
  selector: 'tsh-select-dashboard',
  templateUrl: './select-dashboard.component.html',
  styleUrls: ['./select-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDashboardComponent implements OnChanges {
  @Input() public control: FormControl;
  @Input() public items: IDashboard[] = [];

  public readonly columnsMap: Map<TileModalDashboardsColumnKeys, IReportColumn> = TILE_MODAL_DASHBOARDS_COLUMNS_MAP;
  public readonly columns: IReportColumn[] = TILE_MODAL_DASHBOARDS_COLUMNS;
  public readonly columnKeys = TileModalDashboardsColumnKeys;
  public readonly dataTypes = DataTypeEnum;
  public dataSource: TsTableDataSource<IDashboard> = new TsTableDataSource();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.dataSource = new TsTableDataSource();
      this.dataSource.data = this.items;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatDialogModule,
    TsTableModule,
    TypedDataModule,
    TsTooltipModule,
    TsRadioGroupModule,
    ReactiveFormsModule,
  ],
  declarations: [SelectDashboardComponent],
  exports: [SelectDashboardComponent],
})
export class SelectDashboardModule {
}
