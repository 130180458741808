import { createAction, props } from '@ngrx/store';

import { GlobalSearchTypeEnum } from '../enums';
import { IGlobalSearchItem } from '../interfaces';

export enum GlobalSearchActionTypes {
  LoadSearchData = '[Global Search] Load Search Data',
  LoadSearchDataSuccess = '[Global Search] Load Search Data Success',
  LoadSearchDataFailed = '[Global Search] Load Search Data Failed',
  SetViewData = '[Global Search] Set View Data',
  SetLoading = '[Global Search] Set Loading'
}

const loadSearchData = createAction(
  GlobalSearchActionTypes.LoadSearchData,
  props<{ searchQuery: string }>()
);

const loadSearchDataSuccess = createAction(
  GlobalSearchActionTypes.LoadSearchDataSuccess,
  props<{ key: GlobalSearchTypeEnum, data: IGlobalSearchItem[] }>()
);

const loadSearchDataFailed = createAction(
  GlobalSearchActionTypes.LoadSearchDataFailed,
  props<{ message: string }>()
);

const setViewData = createAction(
  GlobalSearchActionTypes.SetViewData,
  props<{ data: GlobalSearchTypeEnum[] }>()
);

const setLoading = createAction(
  GlobalSearchActionTypes.SetLoading,
  props<{ number: number }>()
);


export const globalSearchActions = {
  loadSearchData,
  loadSearchDataSuccess,
  loadSearchDataFailed,
  setViewData,
  setLoading
}
