import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { asyncScheduler, of } from 'rxjs';
import {buffer, catchError, debounceTime, filter, map, mergeMap, pluck, switchMap, tap} from 'rxjs/operators';
import { ActionType, select, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CampaignMutateSuccessActions, ChannelMutateSuccessActions, tagsActions } from './tags.actions';
import * as selectors from './tags.selectors';
import * as reducer from './tags.reducer';
import { notificationMessagesActions } from '@notification-messages';
import { userMessageFactory } from '@shared/interfaces';
import { ITagRequest, ITagResponse, ITagsResponse } from '@measurement-studio/interfaces';
import { NotificationTypes } from '@shared/enums';
import { TagsService } from '../service/tags.service';

@Injectable()
export class TagsEffects {
  constructor(public tagsService: TagsService,
              private store: Store<{
                [reducer.tagsFeatureKey]: reducer.ITagsState,
              }>,
              private actions$: Actions) {
  }

  public loadTags$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.loadTags),
    mergeMap(action => this.tagsService.getTags$(action.request).pipe(
      map((data: { name: string }[]) => tagsActions.loadTagsSuccess({data: data.map(item => item.name)})),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'ngrx.tags.errors.loadTags';
        return of(tagsActions.loadTagsFailure({message}));
      })
    ))
  ));

  public loadTagsByCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.loadTagsByCampaign),
    switchMap(({request, globalFilters}: ActionType<typeof tagsActions.loadTagsByCampaign>) => {
      return this.tagsService.getTagsByCampaign$(request, globalFilters).pipe(
        map((data: ITagsResponse) => tagsActions.loadTagsByCampaignSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.loadTagsByCampaign';
          return of(tagsActions.loadTagsByCampaignFailure({message}));
        }),
      );
    })
  ));

  public postTag$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.addCampaignTag),
    pluck('request'),
    concatLatestFrom(() => this.store.pipe(select(selectors.getTagsByCampaign))),
    map(this.filterRequestDto),
    filter((newTag: ITagRequest) => !!newTag.objIds.length),
    switchMap((newTag: ITagRequest) => {
      return this.tagsService.postTags$(newTag).pipe(
        // reset selected items on success
        mergeMap((data: ITagsResponse) => [
          tagsActions.addCampaignTagSuccess({data}),
          tagsActions.setCampaignSelectedRowIds({data: []}),
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.addTag';
          return of(tagsActions.addCampaignTagFailure({message}));
        }),
      );
    })
  ));

  public deleteTag$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.removeCampaignTag),
    pluck('request'),
    switchMap((request: ITagRequest) => {
      return this.tagsService.deleteTags$(request).pipe(
        map(() => tagsActions.removeCampaignTagSuccess({data: request})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.removeTag';
          return of(tagsActions.removeCampaignTagFailure({message}));
        }),
      );
    })
  ));


  public showCampaignNotification$ = createEffect(() => ({debounce = 500, scheduler = asyncScheduler} = {}) => this.actions$.pipe(
    ofType(tagsActions.addCampaignTagSuccess, tagsActions.removeCampaignTagSuccess),
    buffer(this.actions$.pipe(
      ofType(tagsActions.addCampaignTagSuccess, tagsActions.removeCampaignTagSuccess),
      debounceTime(debounce, scheduler),
    )),
    map(this.getNotificationMessage),
    map((message: string) => notificationMessagesActions.addMessage({ message: {
      message,
      type: NotificationTypes.Success,
    }})),
  ));

  public loadTagsByChannel$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.loadTagsByChannel),
    switchMap(({request, globalFilters}: ActionType<typeof tagsActions.loadTagsByChannel>) => {
      return this.tagsService.getTagsByChannel$(request, globalFilters).pipe(
        map((data: ITagsResponse) => tagsActions.loadTagsByChannelSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.loadTagsByChannel';
          return of(tagsActions.loadTagsByChannelFailure({message}));
        }),
      );
    })
  ));

  public postChannelTags$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.addChannelTag),
    pluck('request'),
    concatLatestFrom(() =>
      this.store.pipe(select(selectors.getTagsByChannel))
    ),
    map(this.filterRequestDto),
    filter((newTag: ITagRequest) => !!newTag.objIds.length),
    switchMap((newTag: ITagRequest) => {
      return this.tagsService.postChannelTags$(newTag).pipe(
        // reset selected items on success
        mergeMap((data: ITagsResponse) => [
          tagsActions.addChannelTagSuccess({data}),
          tagsActions.setChannelSelectedRowIds({data: []}),
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.addChannelTag';
          return of(tagsActions.addChannelTagFailure({message}));
        }),
      );
    })
  ));

  public deleteChannelTag$ = createEffect(() => this.actions$.pipe(
    ofType(tagsActions.removeChannelTag),
    pluck('request'),
    switchMap((request: ITagRequest) => {
      return this.tagsService.deleteChannelTags$(request).pipe(
        map(() => tagsActions.removeChannelTagSuccess({data: request})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'ngrx.tags.errors.removeChannelTag';
          return of(tagsActions.removeChannelTagFailure({message}));
        }),
      );
    })
  ));

  public showChannelNotification$ = createEffect(() => ({debounce = 500, scheduler = asyncScheduler} = {}) => this.actions$.pipe(
    ofType<ChannelMutateSuccessActions>(tagsActions.addChannelTagSuccess, tagsActions.removeChannelTagSuccess),
    buffer(this.actions$.pipe(
      ofType<ChannelMutateSuccessActions>(tagsActions.addChannelTagSuccess, tagsActions.removeChannelTagSuccess),
      debounceTime(debounce, scheduler),
    )),
    map(this.getChannelNotificationMessage),
    map((message: string) => notificationMessagesActions.addMessage({ message: {
      message,
      type: NotificationTypes.Success,
    }})),
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      tagsActions.loadTagsFailure,
      tagsActions.loadTagsByCampaignFailure,
      tagsActions.addCampaignTagFailure,
      tagsActions.removeCampaignTagFailure,
      tagsActions.loadTagsByChannelFailure,
      tagsActions.addChannelTagFailure,
      tagsActions.removeChannelTagFailure,
    ),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  // if campaign has already had this tag then remove campaign's id from the objIds
  private filterRequestDto([newTag, tagsByEntity]: [ITagRequest, ITagsResponse]): ITagRequest {
    return {
      ...newTag,
      objIds: newTag.objIds.filter(id => {
        const currentTagsForThisEntity: ITagResponse[] = tagsByEntity[id];
        if (!currentTagsForThisEntity) {
          return true;
        }
        const currentTagsNames = currentTagsForThisEntity.map(tag => tag.name);
        return !currentTagsNames.includes(newTag.name);
      })
    };
  }

  private getNotificationMessage(bufferedActions: CampaignMutateSuccessActions[]): string {
    if (bufferedActions[0].type === tagsActions.removeCampaignTagSuccess.type) {
      return 'ngrx.tags.notifications.removeTagFromCampaign';
    } else {
      const campaignIds: string[] = Object.keys(bufferedActions[0].data);
      switch (true) {
        // 2 and more tags and 1 campaign
        case bufferedActions.length > 1 && campaignIds.length === 1:
          return 'ngrx.tags.notifications.addedTagsToCampaign';
        // 2 and more tags and 2 and more campaigns
        case bufferedActions.length > 1 && campaignIds.length > 1:
          return 'ngrx.tags.notifications.addedTagsToCampaigns';
        // 1 tag and 1 campaign
        case bufferedActions.length === 1 && campaignIds.length === 1:
          return 'ngrx.tags.notifications.addedTagToCampaign';
        // 1 tag and 2 and more campaigns
        case bufferedActions.length === 1 && campaignIds.length > 1:
          return 'ngrx.tags.notifications.addedTagToCampaigns';
      }
    }
  }

  private getChannelNotificationMessage(bufferedActions: ChannelMutateSuccessActions[]): string {
    if (bufferedActions[0].type === tagsActions.removeChannelTagSuccess.type) {
      return 'ngrx.tags.notifications.removeTagFromChannel';
    } else {
      const channelIds: string[] = Object.keys(bufferedActions[0].data);
      switch (true) {
        // 2 and more tags and 1 channel
        case bufferedActions.length > 1 && channelIds.length === 1:
          return 'ngrx.tags.notifications.addedTagsToChannel';
        // 2 and more tags and 2 and more channels
        case bufferedActions.length > 1 && channelIds.length > 1:
          return 'ngrx.tags.notifications.addedTagsToChannels';
        // 1 tag and 1 channel
        case bufferedActions.length === 1 && channelIds.length === 1:
          return 'ngrx.tags.notifications.addedTagToChannel';
        // 1 tag and 2 and more channels
        case bufferedActions.length === 1 && channelIds.length > 1:
          return 'ngrx.tags.notifications.addedTagToChannels';
      }
    }
  }
}
