export enum PageViewsInfluencedReportTab {
  Accounts,
  AdTactics,
}

export enum LeadsInfluencedReportTab {
  Leads,
  Accounts,
  AdTactics,
}

export enum CampaignResponsesInfluencedReportTab {
  CampaignResponses,
  Accounts,
  AdTactics,
}

export enum NewPipelineInfluencedReportTab {
  Pipeline,
  Accounts,
  AdTactics,
}

export enum PipelineInfluencedReportTab {
  Pipeline,
  Accounts,
  AdTactics,
}

export enum RevenueInfluencedReportTab {
  Revenue,
  Accounts,
  AdTactics,
}
