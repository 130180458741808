import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { iif, Observable, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, ActionType, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as interfaces from '../../interfaces';
import { campaignsActions } from './campaigns.actions';
import * as generalSelectors from '../general/general.selectors';
import { notificationMessagesActions } from '@notification-messages';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { generalActions } from '../general/general.actions';
import { sourceActions } from '../source/source.actions';
import { campaignActions } from '../campaign/campaign.actions';
import * as analyticsSelectors from '../selectors';
import * as selectors from './campaigns.selectors';
import { CampaignAnalyticsService } from '../../services/campaign-analytics.service';
import { IAnalyticsGlobalFilters, IPager, ISorter, userMessageFactory } from '@shared/interfaces';
import { AnalyticsHelperService } from '../../helpers/analytics-helper.service';
import { checkDataByColumnName, getDriverRequestParams, getSecondaryRequestParams } from '../../helpers/store.helper';
import { AnalyticsColumnName } from '../../enums';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { CampaignsLoadActions } from './campaigns.actions';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  ISorter,
  IPager,
  string[],
  string,
  boolean,
];

type LoadRequestParams<A extends Action> = [
  A,
  IAnalyticsRequest,
  Action[]?,
];

@Injectable()
export class CampaignsEffects {
  constructor(
    public campaignAnalyticsService: CampaignAnalyticsService,
    private analyticsHelperService: AnalyticsHelperService,
    private store: Store<unknown>,
    private actions$: Actions
  ) {
  }

  public hasWebActivity$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.hasWebActivity),
    filter(action => !action.parentId),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(selectors.getCampaignsRecordIds)),
      ),
    )), // use concatMap to wait until dispatching this action
    mergeMap(([action, filters, recordIds]) => {
      const requestParams = getSecondaryRequestParams(filters, recordIds);
      return this.campaignAnalyticsService.getCampaignAnalyticsHasWebActivity$(requestParams).pipe(
        map((data: interfaces.ICampaignHasWebActivity) => campaignsActions.hasWebActivitySuccess({...action, data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.hasWebActivity';
          return of(campaignsActions.hasWebActivityFailure({message}));
        })
      );
    })
  ));

  public loadMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadMetaData),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    distinctUntilChanged(null, data => JSON.stringify(data)),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsMetaData$(requestParams).pipe(
        mergeMap((data: interfaces.IMetaData<interfaces.ICampaignsResponse>) => [
          campaignsActions.loadMetaDataSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadMetaData';
          return of(campaignsActions.loadMetaDataFailure({message}));
        })
      );
    })
  ));

  public loadResponses$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadResponses),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsResponses$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignResponses) => [
          campaignsActions.loadResponsesSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadResponses';
          return of(campaignsActions.loadResponsesFailure({message}));
        })
      );
    })
  ));

  public loadAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadAttribution),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsAttribution$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignAttribution) => [
          campaignsActions.loadAttributionSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadAttribution';
          return of(campaignsActions.loadAttributionFailure({message}));
        })
      );
    })
  ));

  public loadInfluence$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadInfluence),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsInfluence$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignInfluence) => [
          campaignsActions.loadInfluenceSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadInfluence';
          return of(campaignsActions.loadInfluenceFailure({message}));
        })
      );
    })
  ));

  public loadReturns$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadReturns),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsReturns$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignReturns) => [
          campaignsActions.loadReturnsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadReturns';
          return of(campaignsActions.loadReturnsFailure({message}));
        })
      );
    })
  ));

  public loadCosts$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.loadCosts),
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsCosts$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignCosts) => [
          campaignsActions.loadCostsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCosts';
          return of(campaignsActions.loadCostsFailure({message}));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignsActions.hasWebActivityFailure,
      campaignsActions.loadMetaDataFailure,
      campaignsActions.loadResponsesFailure,
      campaignsActions.loadAttributionFailure,
      campaignsActions.loadInfluenceFailure,
      campaignsActions.loadReturnsFailure,
      campaignsActions.loadCostsFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public onChangeCampaignsConfig$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.changeCampaignsSearch,
      generalActions.changeCampaignsPagination,
      generalActions.changeCampaignsSorting,
      generalActions.changeCampaignsSearchByTags,
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getCampaignsSorter)),
    ))),
    mergeMap(([_, sorter]) => [
      this.analyticsHelperService.getCampaignsLoadAction(sorter.sortField, {isDriver: true}),
      campaignsActions.closeNestedTables()
    ])
  ));

  public getCampaigns$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.getCampaigns),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    switchMap(([_, filters, globalFilters, sorter, pager, recordIds, search, searchByTags]: LatestFilters<Action>) => {
      const requestParams = this.getRequestParams(
        {isDriver: true}, filters, globalFilters, sorter, pager, search, recordIds, searchByTags
      );
      return of(requestParams).pipe(
        withLatestFrom(
          this.store.pipe(select(selectors.getCampaignsUsedParams))
        )
      );
    }),
    filter(([currentFilters, prevFilters]: IAnalyticsRequest[]) => {
      return this.analyticsHelperService.areFiltersChanged(currentFilters, prevFilters);
    }),
    map(([currentFilters]: IAnalyticsRequest[]) =>
      this.analyticsHelperService.getCampaignsLoadAction(currentFilters.sorter.sortField, {isDriver: true}),
    )
  ));

  public onLoadDriverSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignsActions.loadAttributionSuccess,
      campaignsActions.loadCostsSuccess,
      campaignsActions.loadInfluenceSuccess,
      campaignsActions.loadMetaDataSuccess,
      campaignsActions.loadResponsesSuccess,
      campaignsActions.loadReturnsSuccess
    ),
    filter((action) => action.isDriver && !!action.data.totalResults),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(this.getColumnNamesSelector(action.parentId))),
    ))),
    mergeMap(([action, columnNames]) => {
      const secondaryRequests = this.analyticsHelperService.getCampaignsSecondaryLoadActions(action, columnNames);
      // return secondaryRequests if they exists, otherwise make totals requests
      return secondaryRequests.length
        ? secondaryRequests
        : this.analyticsHelperService.getTotalsLoadActions(columnNames, action.parentId || 'campaigns');
    }),
  ));

  public onLoadSecondarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignsActions.hasWebActivitySuccess,
      campaignsActions.loadAttributionSuccess,
      campaignsActions.loadCostsSuccess,
      campaignsActions.loadInfluenceSuccess,
      campaignsActions.loadMetaDataSuccess,
      campaignsActions.loadResponsesSuccess,
      campaignsActions.loadReturnsSuccess
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(
        analyticsSelectors.getUnloadedTotalsColumnNames(generalSelectors.getVisibleCampaignsColumns), {key: 'campaigns'})),
      this.store.pipe(select(selectors.getCampaignsIsLoading)),
    ))),
    filter(([action, _, isLoading]) => !action.isDriver && !isLoading),
    switchMap(([action, columnNames, __]) =>
      this.analyticsHelperService.getTotalsLoadActions(columnNames, action.parentId || 'campaigns')
    ),
  ));

  public onChangeNestedSortingOrPagination$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.changeNestedPagination, campaignsActions.changeNestedSorting),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(selectors.getNestedSorter, action.data.id)),
    ))),
    map(([action, sorter]) =>
      this.analyticsHelperService.getCampaignsLoadAction(sorter.sortField, {isDriver: true, parentId: action.data.id}))
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignsColumnVisibility),
    concatMap(action => of(action).pipe(withLatestFrom(
      iif(
        () => !!action.parentId,
        this.store.pipe(select(selectors.getNestedDataById, action.parentId)),
        this.store.pipe(select(selectors.getReportData)),
      ).pipe(
        map((data: interfaces.ICampaignIdentifiable[]) => checkDataByColumnName(data, action.data.name)),
      )
    ))),
    // do not dispatch load action if data is present in state
    filter(([_, data]) => !data.length),
    concatMap(([action]) =>
      of(action).pipe(withLatestFrom(
        this.store.pipe(select(analyticsSelectors.getCampaignExpandedRowIds)),
        this.store.pipe(select(analyticsSelectors.getSourceExpandedRowIds)),
      ))),
    map(([action, campaignExpandedRowIds, sourceExpandedRowIds]) => [action, [...campaignExpandedRowIds, ...sourceExpandedRowIds]]),
    // dispatch actions to load extra data
    mergeMap(([action, parentIds]: [ActionType<typeof generalActions.toggleCampaignsColumnVisibility>, string[]]) => {
      const parentId = action.parentId;
      const columnName = action.data.name;
      if (parentIds.length && parentId) {
        // dispatch action for each visible nested table
        return parentIds.map(id => this.analyticsHelperService.getCampaignsLoadAction(columnName, {isDriver: false, parentId: id}));
      }
      return [this.analyticsHelperService.getCampaignsLoadAction(action.data.name, {isDriver: false, parentId})];
    })
  ));

  public onToggleTableRow$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.toggleTableRow, campaignActions.toggleTableRow),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getNestedCampaigns))),
    )),
    filter(([action, state]) => !state.data[action.id]),
    map(([action, state]) => {
      const sortField = state.sorter[action.id]?.sortField || AnalyticsColumnName.Responses;
      return this.analyticsHelperService.getCampaignsLoadAction(sortField, {isDriver: true, parentId: action.id});
    })
  ));

  public onChangeFiltersState$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.setFilters),
    mergeMap(() => [
      campaignsActions.clearNestedState(),
      campaignsActions.closeNestedTables(),
    ]),
  ));

  private withLatestFilters<T extends CampaignsLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(generalSelectors.getCampaignsSorter)),
        this.store.pipe(select(generalSelectors.getCampaignsPager)),
        this.store.pipe(select(selectors.getCampaignsRecordIds)),
        this.store.pipe(select(generalSelectors.getCampaignsSearch)),
        this.store.pipe(select(generalSelectors.getCampaignsSearchByTags)),
      ),
    );
  }

  private withLatestNestedFilters<T extends CampaignsLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(selectors.getNestedSorter, action.parentId)),
        this.store.pipe(select(selectors.getNestedPager, action.parentId)),
        this.store.pipe(select(selectors.getNestedRecordIds, action.parentId)),
        of(''), // mock empty search for nested table
      ),
    );
  }

  private mapToRequestAndUsedParamsAction<T extends CampaignsLoadActions>(
    [action, filters, globalFilters, sorter, pager, recordIds, searchParam, searchByTags]: LatestFilters<T>
  ): LoadRequestParams<T> {
    const requestParams =
      this.getRequestParams(action, filters, globalFilters, sorter, pager, searchParam, recordIds, searchByTags);
    const usedParamsAction = action.isDriver ? [campaignsActions.setUsedParams({request: requestParams})] : [];
    return [action, requestParams, usedParamsAction];
  }

  private getRequestParams(payload: interfaces.ILoadPayload,
                           filters: IAnalyticsRequest,
                           globalFilters: IAnalyticsGlobalFilters,
                           sorter: ISorter,
                           pager: IPager,
                           searchParam: string,
                           recordIds: string[],
                           searchByTags: boolean): IAnalyticsRequest {
    const requestParams = payload.isDriver
      ? getDriverRequestParams(filters, sorter, pager, searchByTags ? '' : searchParam)
      : getSecondaryRequestParams(filters, recordIds);
    requestParams.globalFilters = globalFilters || [];
    if (searchByTags && searchParam && payload.isDriver) {
      requestParams.tagNames = [searchParam];
    }
    if (payload.parentId) {
      requestParams.crossTypeFilter = payload.parentId.toString();
      delete requestParams.typeFilter;
    }
    return requestParams;
  }

  private getColumnNamesSelector(parentId: string | number | null): MemoizedSelector<unknown, string[]> {
    if (!parentId) {
      return generalSelectors.getVisibleCampaignsColumnNames;
    }

    return typeof parentId === 'number' && isFinite(parentId)
      ? generalSelectors.getCampaignVisibleCampaignsNestedColumnNames
      : generalSelectors.getSourceVisibleCampaignsNestedColumnNames;
  }
}
