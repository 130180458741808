import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons/faCloudDownload';
import { faColumns } from '@fortawesome/pro-solid-svg-icons/faColumns';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { TsMenuComponent } from '@terminus-lib/ui-menu';
import { TranslateService } from '@ngx-translate/core';

import { IEditTableColumn, IReportColumn } from '@shared/interfaces';

@WithDestroy
@Component({
  selector: 'tsh-table-buttons-panel',
  templateUrl: './table-buttons-panel.component.html',
  styleUrls: ['./table-buttons-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableButtonsPanelComponent implements AfterViewInit {
  /**
   * Is buttons disabled
   * Enabled by default
   */
  @Input() public isDisabled = false;
  /**
   * Should show progress spinner for download csv
   * Disabled by default
   */
  @Input() public showProgress = false;
  /**
   * Show or hide download button
   * Show by default
   */
  @Input() public showDownload = true;
  /**
   * Show or hide columns button
   * Hide by default
   */
  @Input() public showColumns = false;
  /**
   * Placeholder for search input
   */
  @Input() public searchLabel = 'shared.common.searchLabel';
  /**
   * If search input visible
   * Visible by default
   */
  @Input() public isSearchVisible = true;
  /**
   * Array of columns for the current report table
   */
  @Input() public columns: IReportColumn[] = [];
  /**
   * Emit event on change columns
   */
  @Output() public changeColumns = new EventEmitter<IEditTableColumn>();
  /**
   * Emit download CSV
   */
  @Output() public download = new EventEmitter<void>();

  @ViewChild(TsMenuComponent) public menu!: TsMenuComponent;

  public search = new FormControl('');
  public uploadIcon = faCloudDownload;
  public columnsIcon = faColumns;

  constructor(private translateService: TranslateService) {
  }

  public get filteredColumns(): IReportColumn[] {
    const reg = new RegExp(this.search.value, 'i');
    return this.columns.filter(column => {
      const translatedName  = this.translateService.instant(column.displayName);
      return reg.test(translatedName);
    });
  }

  ngAfterViewInit(): void {
    // NOTE: if there is search input then we have to reset
    // search value after closing the menu
    if (this.isSearchVisible && this.menu) {
      this.menu.trigger.menuClosed
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {
          this.search.setValue('');
        });
    }
  }

  emitDownload(): void {
    this.download.emit();
  }

  toggleVisibility(isVisible: boolean, name: string): void {
    this.changeColumns.emit({
      isVisible,
      name
    });
  }
}
