import { FrequencyType } from '@measurement-studio/enums';
import { add, isBefore } from 'date-fns';

// NOTE: This functionality is from hub-ajs
// utilities-srvc.js getDates
export function getDatesBasedOnFrequency(startDate: Date, endDate: Date, frequency: FrequencyType = FrequencyType.Days): Date[] {
  const dateArray = [];
  let currentDate = new Date(startDate); // copy of start date
  while (isBefore(currentDate, endDate)) {
    dateArray.push(currentDate);
    currentDate = add(currentDate, {
      [frequency]: 1
    });
  }

  dateArray.push(endDate);

  return dateArray;
}
