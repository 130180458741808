import { createReducer, on } from '@ngrx/store';
import { IUserMessage } from '@shared/interfaces';
import { notificationMessagesActions } from './notification-messages.actions';

export const notificationMessagesFeatureKey = 'notificationMessages';

export interface NotificationMessagesState {
  messages: IUserMessage[];
}

export const initialState: NotificationMessagesState = {
  messages: [],
};

const notificationMessagesReducer = createReducer(
  initialState,
  on(
    notificationMessagesActions.addMessage,
    (state, action) => ({
      ...state,
      messages: [ ...state.messages, action.message ],
    })
  ),
  on(
    notificationMessagesActions.clearMessage,
    (state, action) => ({
      ...state,
      messages: state.messages.filter((item: IUserMessage) => item.message !== action.message),
    })
  ),
  on(
    notificationMessagesActions.clearAllMessages,
    (state) => ({
      ...state,
      messages: []
    })
  )
);

export function reducer(state, action): NotificationMessagesState {
  return notificationMessagesReducer(state, action);
}
