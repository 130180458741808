export enum InsightDetailsSortColumn {
  accountName = 'ACCOUNT_NAME',
  accountsReached = 'ACCOUNTS_REACHED',
  activityDate = 'ACTIVITY_DATE',
  adSpend = 'AD_SPEND',
  campaignName = 'CAMPAIGN_NAME',
  costPerCampaign = 'COST_PER_CAMPAIGN',
  costPerOpportunity = 'COST_PER_OPPORTUNITY',
  costPerPageView = 'COST_PAGE_VIEW',
  costPerLead = 'COST_PER_LEAD',
  daysToLeadCreated = 'DAYS_TO_LEAD_CREATED',
  daysToResponse = 'DAYS_TO_RESPONSE',
  firstImpressionDate = 'FIRST_IMPRESSION_DATE',
  firstLeadCreated = 'FIRST_LEAD_CREATED',
  impressionCount = 'IMPRESSIONS',
  leadName = 'LEAD_NAME',
  leadsInfluenced = 'LEADS_INFLUENCED',
  newOpportunities = 'NEW_OPPORTUNITIES',
  opportunities = 'OPPORTUNITIES',
  opportunityAmount = 'OPPORTUNITY_AMOUNT',
  opportunityCloseDate = 'OPPORTUNITY_CLOSE_DATE',
  opportunityCreatedDate = 'OPPORTUNITY_CREATED_DATE',
  opportunityName = 'OPPORTUNITY_NAME',
  opportunityType = 'OPPORTUNITY_TYPE',
  pageViews = 'PAGE_VIEWS',
  pipelineInfluenced = 'PIPELINE_INFLUENCED',
  responsesInfluenced = 'RESPONSES_INFLUENCED',
  spendPerDeal = 'SPEND_PER_DEAL',
  revenueInfluenced = 'REVENUE_INFLUENCED',
  newPipelineInfluenced = 'NEW_PIPELINE_INFLUENCED',
  existingOpportunitiesInfluenced = 'EXISTING_OPPORTUNITIES_INFLUENCED',
  spendPerOpportunity = 'SPEND_PER_OPPORTUNITY',
  tacticName = 'TACTIC_NAME',
  tactics = 'TACTICS',
}
