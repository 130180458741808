<div>
  <div class="label">
    <div>{{ label }}</div>
    <ts-tooltip
      *ngIf="tooltip"
      [tooltipValue]="tooltip"
      class="label__tooltip">
      <i class="fas fa-xs fa-info-circle"></i>
    </ts-tooltip>
  </div>
  <div class="body">
    <div
      *ngIf="legendColor"
      class="color"
      [ngStyle]="{'backgroundColor': legendColor}"
    ></div>
    {{ value }}
  </div>
</div>
