import { Component, Input } from '@angular/core';

import { GlobalSearchTypeEnum } from '../../enums';
import { IGlobalSearchData, ISearchRouteData } from '../../interfaces';
import { IRouteItem } from '@shared/interfaces';
import { SearchTypeLabels } from '../../data/search-type-labels';

@Component({
  selector: 'tsh-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent {
  @Input() data: IGlobalSearchData;
  @Input() isLoading: number;
  @Input() searchQuery: string;
  @Input() viewData: GlobalSearchTypeEnum[];
  @Input() public routeData: ISearchRouteData;

  public campaignRoute: IRouteItem;
  public accountRoute: IRouteItem;
  public channelRoute: IRouteItem;
  public readonly labels = SearchTypeLabels;

}
