import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SavedReportsService } from '../services/saved-reports.service';
import { ISavedFolder, ISavedReport, userMessageFactory } from '@shared/interfaces';
import * as SavedReportsSelectors from './saved-reports.selectors';
import { savedReportsActions } from './saved-reports.actions';
import * as SavedReportsReducer from './saved-reports.reducer';
import { notificationMessagesActions } from '@notification-messages';
import { globalFiltersActions } from '@shared/data-access/global-filters';
import { getAllGlobalFiltersFromParams, getFiltersFromRoute } from '@util/helpers';
import { NavigateTo } from '@hub-navigation';
import { RouteItemEnum } from '@shared/enums';

@Injectable()
export class SavedReportsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SavedReportsReducer.SavedReportState>,
    private reportService: SavedReportsService,
    private translate: TranslateService,
  ) {}

  public getReports$ = createEffect(
    () =>
      ({ state$ = this.store.pipe(select(SavedReportsSelectors.getSavedReportsState)) } = {}) =>
        this.actions$.pipe(
          ofType(savedReportsActions.getSavedReports),
          withLatestFrom(state$),
          // if loading in process or there is already data in store then don't dispatch action
          filter(([_, state]: [null, SavedReportsReducer.SavedReportState]) => !state.isLoading && !state.folders),
          map(() => savedReportsActions.loadSavedReports()),
        ),
  );

  public loadReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.loadSavedReports),
      mergeMap(() => {
        return this.reportService.getSavableReportsFolders$().pipe(
          map((folders: ISavedFolder[]) => savedReportsActions.loadSavedReportsSuccess({ data: folders })),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.loadReport.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.loadSavedReportsFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public createReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.createSavableReport),
      mergeMap(action => {
        return this.reportService.createSavableReport$(action.data).pipe(
          map(() => savedReportsActions.createSavableReportSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.createReport.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.createSavableReportFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public updateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.updateSavableReport),
      mergeMap(action => {
        return this.reportService.updateSavableReport$(action.data).pipe(
          map(() => savedReportsActions.updateSavableReportSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.updateReport.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.updateSavableReportFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public deleteReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.deleteSavableReport),
      mergeMap(action => {
        return this.reportService.deleteSavableReport$(action.data).pipe(
          map(() => savedReportsActions.deleteSavableReportSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.deleteReport.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.deleteSavableReportFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public createFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.createSavableFolder),
      mergeMap(action => {
        return this.reportService.createSavableReportsFolder$(action.data).pipe(
          map(() => savedReportsActions.createSavableFolderSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.createFolder.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.createSavableFolderFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public updateFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.updateSavableFolder),
      mergeMap(action => {
        return this.reportService.updateSavableReportsFolder$(action.data).pipe(
          map(() => savedReportsActions.updateSavableFolderSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.updateFolder.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.updateSavableFolderFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  public deleteFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.deleteSavableFolder),
      mergeMap(action => {
        return this.reportService.deleteSavableReportsFolder$(action.data).pipe(
          map(() => savedReportsActions.deleteSavableFolderSuccess()),
          catchError((error: HttpErrorResponse) => {
            const message$ = error?.message
              ? of(error.message)
              : this.translate.get('features.savedReports.deleteFolder.defaultErrorMessage');
            return message$.pipe(
              mergeMap(message =>
                this.translate
                  .get('ngrx.errorMessage', { message })
                  .pipe(map(result => savedReportsActions.deleteSavableFolderFailure({ message: result }))),
              ),
            );
          }),
        );
      }),
    ),
  );

  // NOTE: reload saved reports data after each successful change

  public onSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        savedReportsActions.createSavableReportSuccess,
        savedReportsActions.updateSavableReportSuccess,
        savedReportsActions.deleteSavableReportSuccess,
        savedReportsActions.createSavableFolderSuccess,
        savedReportsActions.updateSavableFolderSuccess,
        savedReportsActions.deleteSavableFolderSuccess,
      ),
      map(() => savedReportsActions.loadSavedReports()),
    ),
  );

  public onFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        savedReportsActions.loadSavedReportsFailure,
        savedReportsActions.createSavableReportFailure,
        savedReportsActions.updateSavableReportFailure,
        savedReportsActions.deleteSavableReportFailure,
        savedReportsActions.createSavableFolderFailure,
        savedReportsActions.updateSavableFolderFailure,
        savedReportsActions.deleteSavableFolderFailure,
      ),
      map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) })),
    ),
  );

  public showReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savedReportsActions.showSavedReports),
      mergeMap(action => {
        const report: ISavedReport = action.data;
        const queryParams = report.stateParams && report.stateParams.length ? JSON.parse(report.stateParams) : null;

        if (!queryParams) {
          return [
            NavigateTo({
              routeId: report.stateName as RouteItemEnum,
            }),
          ];
        }

        return [
          globalFiltersActions.syncGlobalFilters(getAllGlobalFiltersFromParams(queryParams)),
          NavigateTo({
            routeId: report.stateName as RouteItemEnum,
            queryParams,
          }),
        ];
      }),
    ),
  );
}
