import { createSelector } from '@ngrx/store';

import { ITotalsState, totalsFeatureKey } from './totals.reducer';
import { AnalyticsState, selectAnalyticsState } from '../index';
import { IAnalyticsTableTotals, LoadTotalsPayload } from '../../interfaces';

export const getTotals = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[totalsFeatureKey]);

export const getTotalsData = createSelector(getTotals,
  (state: ITotalsState, key: LoadTotalsPayload) => state[key]);

export const getNestedTotals = createSelector(getTotals,
  (state: ITotalsState): Record<string, Partial<IAnalyticsTableTotals>> => state.nestedTotals);
