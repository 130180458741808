import { influencedInsightsActions } from './influenced-insights.actions';
import { AdsInsightsReportType } from '../../enums/insights-report-types.enum';
import { PER_PAGE } from '@shared/constants';
import { AdInsightsSidenavStates } from '../../enums/insights-sidenav-states.enum';
import { InfluencedInsightsReport } from '../../interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const influencedInsightsFeatureKey = 'influencedInsights';

export interface InfluencedInsightsState {
  isLoading: boolean;
  activeReport: AdsInsightsReportType;
  activeDetails: string;
  activeInfoPanelState: AdInsightsSidenavStates;
  isAddingToAccount: boolean;
  addedAccounts: number;
  [AdsInsightsReportType.PageView]: InfluencedInsightsReport;
  [AdsInsightsReportType.Lead]: InfluencedInsightsReport;
  [AdsInsightsReportType.CampaignResponses]: InfluencedInsightsReport;
  [AdsInsightsReportType.NewPipeline]: InfluencedInsightsReport;
  [AdsInsightsReportType.Pipeline]: InfluencedInsightsReport;
  [AdsInsightsReportType.Revenue]: InfluencedInsightsReport;
}

export const insightsInitialState: InfluencedInsightsState = {
  isLoading: false,
  activeReport: null,
  activeDetails: null,
  activeInfoPanelState: AdInsightsSidenavStates.InfluencedList,
  isAddingToAccount: false,
  addedAccounts: null,
  [AdsInsightsReportType.PageView]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'pageViews'
  },
  [AdsInsightsReportType.Lead]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'leads'
  },
  [AdsInsightsReportType.CampaignResponses]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'campaignResponses'
  },
  [AdsInsightsReportType.NewPipeline]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'newPipeline'
  },
  [AdsInsightsReportType.Pipeline]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'pipeline'
  },
  [AdsInsightsReportType.Revenue]: {
    data: null,
    details: null,
    isLoading: false,
    isDownloadingCsv: false,
    sortState: null,
    search: null,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    translationKey: 'revenue'
  }
};

export const insightsReducer = createReducer(
  insightsInitialState,
  on(
    influencedInsightsActions.loadInfluencedInsights,
    (state, action) => ({
      ...state,
      [action.params.reportType]: {
        ...state[action.params.reportType],
        isLoading: true
      }
    })
  ),
  on(
    influencedInsightsActions.loadInfluencedInsightsSuccess,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        data: action.insights,
        isLoading: false
      }
    })
  ),
  on(
    influencedInsightsActions.loadInfluencedInsightsDetails,
    (state, action) => ({
      ...state,
      [action.reportType]: {
        ...state[action.reportType],
        isLoading: true
      }
    })
  ),
  on(
    influencedInsightsActions.loadInfluencedInsightsDetailsSuccess,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        details: action.details,
        isLoading: false
      }
    })
  ),
  on(
    influencedInsightsActions.loadInfluencedInsightsDetailsFailure,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        isLoading: false
      }
    })
  ),
  on(
    influencedInsightsActions.loadInfluencedInsightsFailure,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        isLoading: false
      }
    })
  ),
  on(
    influencedInsightsActions.setActiveReportAndDetails,
    (state, action) => ({
      ...state,
      activeReport: action.reportType,
      activeDetails: action.detailsKey
    })
  ),
  on(
    influencedInsightsActions.changeSort,
    (state, action) => ({
      ...state,
      [action.reportType]: {
        ...state[action.reportType],
        sortState: action.sortState
      }
    })
  ),
  on(
    influencedInsightsActions.changePage,
    (state, action) => ({
      ...state,
      [action.reportType]: {
        ...state[action.reportType],
        pagination: {
          ...state[action.reportType].pagination,
          page: action.page
        }
      }
    })
  ),
  on(
    influencedInsightsActions.changeSearch,
    (state, action) => ({
      ...state,
      [action.reportType]: {
        ...state[action.reportType],
        pagination: {
          ...state[action.reportType].pagination,
          page: 1
        },
        search: action.search
      }
    })
  ),
  on(
    influencedInsightsActions.downloadCSV,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        isDownloadingCsv: true
      }
    })
  ),
  on(
    influencedInsightsActions.downloadCSVFailure,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        isDownloadingCsv: false
      }
    })
  ),
  on(
    influencedInsightsActions.downloadCSVSuccess,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        isDownloadingCsv: false
      }
    })
  ),
  on(
    influencedInsightsActions.updateInsightsInfoPanelState,
    (state, action) => ({
      ...state,
      activeInfoPanelState: action.state
    })
  ),
  on(
    influencedInsightsActions.addAccountToAccountsList,
    (state) => ({
      ...state,
      isAddingToAccount: true,
      addedAccounts: null
    })
  ),
  on(
    influencedInsightsActions.addAccountToAccountsListSuccess,
    (state, action) => ({
      ...state,
      isAddingToAccount: false,
      addedAccounts: action.count
    })
  ),
  on(
    influencedInsightsActions.addAccountToAccountsListFailure,
    (state) => ({
      ...state,
      isAddingToAccount: false
    })
  )
);

export function reducer(state: InfluencedInsightsState, action: Action): InfluencedInsightsState {
  return insightsReducer(state, action);
}
