import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './alert.component';

@NgModule({
  imports: [
    CommonModule,
    TsIconButtonModule,
    TsTooltipModule,
    TranslateModule
  ],
  declarations: [
    AlertComponent
  ],
  exports: [
    AlertComponent
  ]
})
export class AlertModule {
}
