import { IFactoryParams } from '@shared/interfaces';

export interface IMarketingInfluenceReport {
  month: string;
  count: number;
  value: number;
  marketingCount: number;
  marketingValue: number;
  calculatedCount: number;
  calculatedValue: number;
  sortTime: number;
}

export function marketingInfluenceReportDataFactory({
  n = 1,
  overrides = {},
}: IFactoryParams<IMarketingInfluenceReport, number, boolean> = {}): IMarketingInfluenceReport {
  return {
    month: `Q1 ${2000 + n}`,
    count: n * 10,
    value: n * 1000000,
    marketingCount: n * 10,
    marketingValue: n * 1000000,
    calculatedCount: n * 100,
    calculatedValue: n * 100000,
    sortTime: new Date('01/01/2020').getTime() + n * 10000,
    ...overrides
  };
}

