import { Params } from '@angular/router';

import { AnalyticsColumnName as Column, AnalyticsReports, AnalyticsResponseTypeEnum as ResponseType } from '../enums';
import * as interfaces from '../interfaces';
import { InfluenceType, ModelType, ToDate } from '@shared/enums';
import { ILabelValue, IPager, IReportTab, ISorter } from '@shared/interfaces';
import { AnalyticsTabState } from '../state/general/general.reducer';
import { DataSetType } from '@measurement-studio/enums';
import { VALID_INFLUENCE_TYPES } from '@measurement-studio/constants';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';
import { IAnalyticsNested, IAnalyticsRequest } from '@measurement-studio/interfaces';
import { defaultPager } from '../data';

const mergeStateWithLoadedItems = <T extends interfaces.ICampaignIdentifiable>(items: Partial<T>[], data: T[]): Partial<T>[] => {
  return items.map((item: T) => {
    const itemToMerge: T = data.find(currentItem => currentItem.groupId === item.groupId);
    const itemWithEmptyValues = !itemToMerge && assembleItemWithEmptyValues(data, item);
    return {
      ...itemToMerge ? itemToMerge : itemWithEmptyValues,
      ...item,
    };
  });
};

const assembleItemWithEmptyValues =
  <T extends interfaces.ICampaignIdentifiable>(data: T[], stateItem: T): T | interfaces.ICampaignsTableBase => {
    const copy = {...data?.length && data[0] ? data[0] : interfaces.campaignsTableBaseFactory()};
    // set default values to received item fields
    for (const field of Object.keys(copy)) {
      copy[field] = typeof stateItem[field] === 'string' ? '' : 0;
    }
    return copy;
  };

// Update Nested State (add items, change loading, modify totalResults)
export const updateNestedState = <T extends interfaces.ICampaignIdentifiable, R extends interfaces.ICountable>(
  state: IAnalyticsNested<T>,
  payload: interfaces.ISuccessPayload<R>,
  data: T[],
): IAnalyticsNested<T> => {
  const items = payload.isDriver ? data : state.data[payload.parentId];
  return {
    ...state,
    data: {
      ...state.data,
      [payload.parentId]: mergeStateWithLoadedItems(items, data),
    },
    loading: {
      ...state.loading,
      [payload.parentId]: false
    },
    totalResults: {
      ...state.totalResults,
      ...payload.isDriver ? {[payload.parentId]: payload.data.totalResults} : {},
    },
  };
};

// Update Loading State for tables (table and nested table)
export const updateLoadingState = (payload: interfaces.ILoadPayload, nestedState: IAnalyticsNested<unknown>, value: boolean) => {
  return payload.parentId
    ? {nested: updateNestedTableState(nestedState, 'loading', payload.parentId, value)}
    : {isDriverLoading: payload.isDriver};
};

// assemble data to clear previous state and add loaded value if isDriver = true,
// otherwise get slice of data to update state with loaded value
export const assembleAnalyticsData = <T extends interfaces.ICampaignIdentifiable, R extends interfaces.ICountable>(
  state: Partial<T>[],
  payload: interfaces.ISuccessPayload<R>,
  data: T[]
) => {
  const items = payload.isDriver ? data : state;
  return {
    data: mergeStateWithLoadedItems(items, data),
    ...payload.isDriver ? {totalResults: payload.data.totalResults} : null,
  };
};


// assemble secondary params for request
export const getSecondaryRequestParams = (request: IAnalyticsRequest,
                                          recordIds: string[]): IAnalyticsRequest => {
  return {
    ...request,
    recordIds,
    isDriver: false,
  };
};

// assemble driver params for request
export const getDriverRequestParams = (request: IAnalyticsRequest,
                                       sorter: ISorter,
                                       pager: IPager,
                                       searchParam: string): IAnalyticsRequest => {
  return {
    ...request,
    sorter,
    pager,
    searchParam,
    isDriver: true,
  };
};

// Map column name to correct slice of data
export const getResponseTypeByColumn = (columnName: string | Column): ResponseType => {
  switch (columnName) {
    case Column.OpptyTouches:
    case Column.DealTouches:
    case Column.AttributedPipeline:
    case Column.AttributedRevenue:
      return ResponseType.Attributed;
    case Column.CPL:
    case Column.CPO:
    case Column.CPD:
    case Column.LTO:
    case Column.OTD:
      return ResponseType.Cost;
    case Column.Opptys:
    case Column.Deals:
    case Column.InfluencedPipeline:
    case Column.InfluencedRevenue:
      return ResponseType.Influence;
    case Column.SourceName:
    case Column.MediumName:
    case Column.Cost:
    case Column.TotalTouches:
      return ResponseType.Campaign;
    case Column.CampaignType:
    case Column.CampaignName:
    case Column.CreatedDate:
    case Column.Responses:
    case Column.People:
    case Column.Accounts:
      return ResponseType.Response;
    case Column.PipelineRoi:
    case Column.ROI:
    case Column.ROAS:
      return ResponseType.Returns;
    case Column.HasNestedTable:
      return ResponseType.HasWebActivity;
    default:
      throw new Error('Wrong column name: ' + columnName);
  }
};

// helper to receive correct translation which is based on filters (modelType)
export const getColumnKeyByModel = (filtersModel: ModelType): 'multi' | 'last' | 'first' => {
  switch (filtersModel) {
    case ModelType.Custom:
    case ModelType.Even:
      return 'multi';
    case ModelType.Last:
      return 'last';
    case ModelType.Sourced:
      return 'first';
  }
};

export const checkDataByColumnName = <T extends interfaces.ICampaignIdentifiable>(items: Partial<T>[], key: Column | string): unknown[] =>
  (items || []).map(item => item[key]).filter(Boolean);

export const isStateLoading = <T extends interfaces.IAnalyticsIsLoading>(state: T) => state.hasWebActivityIsLoading
  || state.campaignIsLoading
  || state.responseIsLoading
  || state.attributedIsLoading
  || state.influenceIsLoading
  || state.costIsLoading
  || state.returnsIsLoading;

export const gerRecordIds = <T extends interfaces.ICampaignIdentifiable>(items: Partial<T>[] | null): string[] =>
  (items || []).map(item => item.groupId);

export const applyCounters = (tabs: IReportTab[], map: Map<AnalyticsReports | string, number>): IReportTab[] => tabs.map(tab => ({
  ...tab,
  ...map.get(tab.key) !== undefined ? {count: map.get(tab.key)} : null,
}));

export const updateAnalyticsTabState = <V extends keyof T, T = AnalyticsTabState>(state: T, key: V, value: T[V]): T => {
  return {
    ...state,
    [key]: value,
  };
};

export const updateNestedTableState = <V extends keyof T,
  S extends keyof T[V],
  T = IAnalyticsNested<interfaces.ICampaignIdentifiable>>(state: T, key: V, id: S, value: T[V][S]): T => {
  return {
    ...state,
    [key]: {
      ...state[key],
      [id]: value
    }
  };
};

export const updateNestedTableVisibility = (state: interfaces.INestedTableState, parentId: string): interfaces.INestedTableState => {
  return {
    ...state,
    visible: {
      ...state.visible,
      [parentId]: !state.visible[parentId],
    }
  };
};

export const updateAnalyticsTabStateAndResetPager = <V extends keyof T, T = AnalyticsTabState>(state: T, key: V, value: T[V]): T => {
  return {
    ...state,
    [key]: value,
    pager: defaultPager
  };
};

export const getFiltersFromParams = (params: Params, userModels: ILabelValue[]): IAnalyticsRequest => {
  // get query params from url or use default params
  const defaultQueryParams: IAnalyticsRequest = {
    influenceType: InfluenceType.ANY,
    cohort: {
      cohort: ToDate.QUARTER_TO_DATE,
    },
    dataSet: DataSetType.MembershipActivity,
    model: ModelType.Even,
    isDriver: true,
    globalFilters: [],
  };

  const modelType = params.model && userModels.find(model => model.value === params.model)
    ? params.model
    : defaultQueryParams.model;
  const dataSetType = params.dataSet && [
    DataSetType.MembershipActivity,
    DataSetType.OpptyCloseDate,
    DataSetType.OpptyCreatedDate,
    DataSetType.CampaignCreatedDate
  ].includes(params.dataSet)
    ? params.dataSet
    : defaultQueryParams.dataSet;
  const influenceType = params.influenceType && VALID_INFLUENCE_TYPES.includes(params.influenceType)
    ? params.influenceType
    : defaultQueryParams.influenceType;
  const cohort = replaceLegacyCohort(params.cohort) || defaultQueryParams.cohort.cohort;
  const typeFilter = params.gf ? {typeFilter: params.gf} : {};

  return {
    ...defaultQueryParams,
    dataSet: dataSetType,
    model: modelType,
    influenceType: influenceType,
    cohort: {
      cohort,
      ...getStartAndEndDateFromParams(cohort, params.startDate, params.endDate),
    },
    ...typeFilter
  };
};
