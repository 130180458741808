import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName, MAX_EXTENDED_ROWS_NUMBER } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { CampaignAnalyticsSource } from '../sources/campaign-analytics.source';
import * as interfaces from '../interfaces';
import {
  IDashboardTile,
  IGetTileData,
  ILabelValue,
  IReportColumn,
  ITileData,
  ITileDataItem,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { RouteItemEnum, TileTypes } from '@shared/enums';
import { AnalyticsColumnName as Column, AnalyticsResponseTypeEnum as ResponseType } from '../enums';
import {
  analyticsTileFieldOptions,
  analyticsTileFields,
  CAMPAIGN_GROUPS_COLUMNS,
  CAMPAIGNS_COLUMNS,
  TileSettingsAnalyticsFields
} from '../data';
import { getFiltersFromParams, getResponseTypeByColumn } from '../helpers/store.helper';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import * as OrgConfigStore from '@org-config';
import * as CampaignsStore from '@shared/data-access/campaigns';
import { allCampaignTypeOption } from '../state/campaigns/campaigns.reducer';
import { getAllAnalyticsGlobalFilters, getAllGlobalFiltersFromParams } from '@util/helpers';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

@Injectable({providedIn: 'root'})
export class CampaignAnalyticsService implements IGetTileData<IAnalyticsRequest> {
  constructor(public source: CampaignAnalyticsSource,
              public store: Store<unknown>,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignAnalyticsTabTotals$(body: IAnalyticsRequest): Observable<interfaces.ITabTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTabTotals$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesTabTotals$(body: IAnalyticsRequest): Observable<interfaces.ITabTotals<interfaces.IWebActivitiesTabTotalsResponse>> {
    return regenerateOnRetry(() => this.source.getWebActivitiesTabTotals$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.ICampaignsResponse>> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsMetaData$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsResponses$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName)
      );
  }

  getCampaignAnalyticsAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsAttribution$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsInfluence$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsReturns$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsCosts$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.ICampaignTypeResponse>> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeMetaData$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeResponses$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeAttribution$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeInfluence$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeReturns$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeCosts$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsHasWebActivity$(body: IAnalyticsRequest): Observable<interfaces.ICampaignHasWebActivity> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsHasWebActivity$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsTypeHasWebActivity$(body: IAnalyticsRequest): Observable<interfaces.ICampaignHasWebActivity> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsTypeHasWebActivity$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsCSV$(body: interfaces.IAnalyticsCSVRequest): Observable<string> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsCSV$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getFiltersFromParams(params: Params, userModels: ILabelValue[]): IAnalyticsRequest {
    return getFiltersFromParams(params, userModels);
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    // TODO: pass user models
    const defaultSettings = this.getFiltersFromParams(params, []);
    const field = this.getCorrectColumn(params);
    return {
      ...params, // to keep global filters
      ...defaultSettings,
      ...defaultSettings.cohort, // remove nested cohort
      [TileSettingsAnalyticsFields.Field]: field,
      [TileSettingsAnalyticsFields.Group]: defaultSettings.typeFilter || '',
      [TileSettingsAnalyticsFields.Model]: params[TileSettingsAnalyticsFields.Model] || defaultSettings.model,
    };
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    const isGroups = tile.route === RouteItemEnum.CampaignAnalyticsGroups;
    const field = this.getCorrectColumn(tile.settings);
    const columns: Partial<IReportColumn>[] = this.getTileColumnsByType(tile.route, field);

    return of({
      metricLabel: isGroups
        ? 'measurementStudio.features.analytics.campaignAnalytics.campaignGroupsTable.titlePlural'
        : 'measurementStudio.features.analytics.campaignAnalytics.campaignsTable.titlePlural',
      table: {
        columns,
        totalsPluralLabel: isGroups
          ? 'measurementStudio.features.analytics.campaignAnalytics.campaignGroupsTable.titlePlural'
          : 'measurementStudio.features.analytics.campaignAnalytics.campaignsTable.titlePlural',
        totalsLabel: isGroups
          ? 'measurementStudio.features.analytics.campaignAnalytics.campaignGroupsTable.title'
          : 'measurementStudio.features.analytics.campaignAnalytics.campaignsTable.title',
      }
    });
  }

  getTileData$(params: IAnalyticsRequest, routeId: RouteItemEnum): Observable<ITileData> {
    const field: Column = this.getCorrectColumn(params);
    const requestParams: IAnalyticsRequest = this.getDriverRequest(params, field);
    const responseType: ResponseType = getResponseTypeByColumn(field);
    const isGroups = routeId === RouteItemEnum.CampaignAnalyticsGroups;
    const [getMetaData, getFieldData] = this.getSourceMethods(isGroups, field);
    // driver request
    return this.source[getFieldData](requestParams).pipe(
      // secondary request
      switchMap((data: interfaces.ICountable) => this.source[getMetaData]({
        ...requestParams,
        isDriver: false,
        recordIds: data[responseType].map(item => item.groupId),
      }).pipe(
        pluck(ResponseType.Campaign),
        map((metaData: interfaces.ICampaignIdentifiable[]) => this.tileAdapter(data[responseType], metaData, data.totalResults)),
      )),
    );
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[]> {
    return combineLatest([
      this.store.pipe(select(OrgConfigStore.getUserModels)),
      this.store.pipe(select(CampaignsStore.getCampaignTypesOptions)),
    ]).pipe(
      map(([models, types]: ILabelValue[][]) => [{
        key: TileSettingsAnalyticsFields.DataSet,
        label: 'measurementStudio.features.analytics.filterscampaignsThat',
        type: TileSettingsControlTypes.Selector,
        options: new DataSetOptions().getDataSetFullOptions(),
      }, {
        key: TileSettingsAnalyticsFields.Model,
        label: 'measurementStudio.features.analytics.filtersmodel',
        type: TileSettingsControlTypes.Selector,
        options: models,
      }, {
        key: TileSettingsAnalyticsFields.InfluenceType,
        label: 'measurementStudio.features.analytics.filtersinfluenceType',
        type: TileSettingsControlTypes.Selector,
        options: INFLUENCE_TYPE_OPTIONS,
      }, {
        key: TileSettingsAnalyticsFields.Field,
        label: 'shared.dashboards.settings.metric',
        type: TileSettingsControlTypes.Selector,
        options: analyticsTileFieldOptions,
      },
        ...this.getTypesSettingControl(type, types),
      ])
    );
  }

  private getDriverRequest(params: IAnalyticsRequest, field: Column): IAnalyticsRequest {
    const globalFilters = params?.globalFilters?.length ? null : getAllGlobalFiltersFromParams(params);
    const analyticsGlobalFilters = params?.globalFilters?.length
      ? params.globalFilters
      : globalFilters
        ? getAllAnalyticsGlobalFilters(globalFilters.listIds, globalFilters.filters)
        : [];
    return {
      ...params,
      cohort: {
        cohort: typeof params['cohort'] === 'object' ? params['cohort'].cohort : params['cohort'],
        startDate: typeof params['cohort'] === 'object' ? params['cohort'].startDate : params['startDate'],
        endDate: typeof params['cohort'] === 'object' ? params['cohort'].endDate : params['endDate'],
      },
      globalFilters: analyticsGlobalFilters,
      typeFilter: params['gf'] || '',
      isDriver: true,
      sorter: {sortDirection: 'desc', sortField: field},
      pager: {pageNum: 1, pageSize: MAX_EXTENDED_ROWS_NUMBER}
    } as IAnalyticsRequest;
  }

  private getSourceMethods(isGroups: boolean, field: Column): [string, string] {
    const dataMethod = this.getMethodByField(isGroups, field);
    return [
      isGroups ? 'getCampaignAnalyticsTypeMetaData$' : 'getCampaignAnalyticsMetaData$',
      dataMethod,
    ];
  }

  private getMethodByField(isGroups: boolean, field: Column): string {
    const typeSuffix = isGroups ? 'Type' : '';
    switch (field) {
      case Column.OpptyTouches:
      case Column.DealTouches:
      case Column.AttributedPipeline:
      case Column.AttributedRevenue:
        return `getCampaignAnalytics${typeSuffix}Attribution$`;
      case Column.CPL:
      case Column.CPO:
      case Column.CPD:
      case Column.LTO:
      case Column.OTD:
        return `getCampaignAnalytics${typeSuffix}Costs$`;
      case Column.Opptys:
      case Column.Deals:
      case Column.InfluencedPipeline:
      case Column.InfluencedRevenue:
        return `getCampaignAnalytics${typeSuffix}Influence$`;
      case Column.Cost:
      case Column.TotalTouches:
        return `getCampaignAnalytics${typeSuffix}MetaData$`;
      case Column.Responses:
      case Column.People:
      case Column.Accounts:
        return `getCampaignAnalytics${typeSuffix}Responses$`;
      case Column.PipelineRoi:
      case Column.ROI:
      case Column.ROAS:
        return `getCampaignAnalytics${typeSuffix}Returns$`;
    }
  }

  private tileAdapter<T extends interfaces.ICampaignIdentifiable>(driverItems: T[], metaItems: T[], totalCount: number): ITileData {
    return {
      items: driverItems.map(driverItem => {
        const metaData = metaItems.find(metaItem => metaItem.groupId === driverItem.groupId);
        return {
          ...metaData,
          ...driverItem,
        };
      }) as unknown as ITileDataItem[],
      totalCount,
    };
  }

  private getTileColumnsByType(routeId: RouteItemEnum, field: Column): IReportColumn[] {
    switch (routeId) {
      case RouteItemEnum.CampaignAnalyticsCampaigns:
        return CAMPAIGNS_COLUMNS.filter(item => item.name === field || item.name === Column.CampaignName);
      case RouteItemEnum.CampaignAnalyticsGroups:
        return CAMPAIGN_GROUPS_COLUMNS.filter(item => item.name === field || item.name === Column.CampaignType);
    }
  }

  private getCorrectColumn(params: Params): Column {
    return analyticsTileFields.includes(params['field']) ? params['field'] as Column : Column.Responses;
  }

  private getTypesSettingControl(type: TileTypes, types: ILabelValue[]): ITileSettingControl[] {
    return type === TileTypes.TopCampaigns ? [{
      key: TileSettingsAnalyticsFields.Group,
      label: 'shared.dashboards.settings.filterBy',
      type: TileSettingsControlTypes.Selector,
      options: [allCampaignTypeOption, ...types],
    }] : [];
  }
}
