export interface IInsightsMetricBarItem<V extends string> {
  label: string;
  value: string;
  tooltip?: string;
  legendColor?: string;
  valueProperty?: V;
  valueFormat?: 'CURRENCY' | 'DECIMAL' | 'PERCENT';
  digitsInfo?: string;
  valueAppend?: string;
  displayNegative?: boolean;
}

export const InsightsMetricBarItemFactory = (config: Partial<IInsightsMetricBarItem<string>>): IInsightsMetricBarItem<string> => ({
  label: '',
  value: '0',
  ...config
});
