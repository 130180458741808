import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { IReportColumn, IReportLink } from '@shared/interfaces';
import { ICampaignStageInfluence, IStageInfluenceFilters } from '../interfaces';

export const campaignTypeColumns: IReportColumn[] = [
  {
    name: 'name',
    width: 550,
    displayName: 'measurementStudio.features.stageInfluence.campaignType',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: ICampaignStageInfluence, filters: IStageInfluenceFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.StageInfluence),
        queryParams: {
          columnType: filters.columnType,
          dataSet: filters.dataSet,
          influenceType: filters.influenceType,
          model: filters.model,
          cohort: filters.cohort,
          startDate: filters.cohort,
          endDate: filters.cohort,
          typeFilter: row.name,
        }
      };
    },
    visible: true,
  }
];

export const campaignsColumns: IReportColumn[] = [
  {
    name: 'name',
    width: 550,
    displayName: 'measurementStudio.features.stageInfluence.name',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: ICampaignStageInfluence, filters: IStageInfluenceFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
        queryParams: {
          name: row.campaignName,
          campaignId: row.groupId,
          model: filters.model,
          cohort: filters.cohort,
          startDate: filters.cohort,
          endDate: filters.cohort,
        }
      };
    },
    visible: true,
  }
];

