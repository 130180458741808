import { IFactoryParams } from '@shared/interfaces';

export type IOpportunityVisual = Record<VisualKeys, IOpportunityVisualItem[]>;

export enum VisualKeys {
    SourceCampaignGroup = 'sourceCampaignGroup',
    LastTouchCampaignGroup = 'lastTouchCampaignGroup',
    Stage = 'stage',
    IntentScore = 'intentScore',
    ScoreMapped = 'scoreMapped',
}

export type IOpportunityVisualItem = [string, number];

export type IOpportunityInsightCharts = Record<VisualKeys, IOpportunityInsightChartData[]>;

export interface IOpportunityInsightChartData {
    type: string | null;
    value: number;
}

export function opportunityInsightChartDataFactory({
   overrides = {},
}: IFactoryParams<IOpportunityInsightChartData> = {}): IOpportunityInsightChartData {
    return {
        type: 'test',
        value: 10,
        ...overrides
    };
}

export function opportunityVisualItemFactory({
    n = 0,
}: IFactoryParams<IOpportunityVisualItem, number, boolean> = {}): IOpportunityVisualItem {
    return [`test${n}`, n * 100];
}

export function opportunityVisualFactory({
    overrides = {},
}: IFactoryParams<IOpportunityVisual, number, boolean> = {}): IOpportunityVisual {
    return {
        [VisualKeys.SourceCampaignGroup]: [opportunityVisualItemFactory()],
        [VisualKeys.LastTouchCampaignGroup]: [opportunityVisualItemFactory({n: 1})],
        [VisualKeys.Stage]: [opportunityVisualItemFactory({n: 2})],
        [VisualKeys.IntentScore]: [opportunityVisualItemFactory({n: 3})],
        [VisualKeys.ScoreMapped]: [opportunityVisualItemFactory({n: 4})],
        ...overrides
    };
}
