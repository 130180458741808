import { createFeatureSelector, createSelector } from '@ngrx/store';

import { opportunitiesFeatureKey, OpportunitiesState } from './opportunities.reducer';
import * as OrgConfigStore from '@org-config';
import * as UserSelectors from '@user/user.selectors';
import { DataTypeEnum, FeatureFlagsEnum, InfluenceType } from '@shared/enums';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { ILabelValue, ReportMetric } from '@shared/interfaces';
import {
  ICampaignSpecific,
  IOpportunityColumn,
  IOpportunityInsightChartData,
  IOpportunityInsightCharts,
  IOpportunityTotals,
  IOpportunityVisual,
  IOpportunityVisualItem,
  VisualKeys
} from '../interfaces';
import { toCapitalize } from '@util/helpers';
import { ISearchOption } from '@ui/components/search-by-property';
import { OpportunityInfluences } from '../opportunities.constant';
import { IOpportunityRequest } from '@measurement-studio/interfaces';
import { InfluencesKeys } from '@measurement-studio/enums';
import { getIsSplitIoFlagOn, treatmentTokens } from '@shared/splitio';
import { OpportunityColumnsFields } from '../enums/opportunities.enum';

export const selectOpportunitiesState = createFeatureSelector<OpportunitiesState>(opportunitiesFeatureKey);

export const getOpportunityReport = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.report
);
export const getOpportunityFilters = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.filters
);
export const getOpportunityVisualization = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.visualization
);
export const getOpportunityIsLoading = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.isLoading || state.isLoadingChart
);
export const getOpportunityIsLoadingVisual = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.isLoadingChart
);
export const getOpportunityTotals = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.totals
);
export const getAllOpportunityColumns = createSelector(
  selectOpportunitiesState,
  (state: OpportunitiesState) => state.columns.filter(column => column)
);

export const getVisibleOpportunityColumns = createSelector(getAllOpportunityColumns,
  (columns: IOpportunityColumn[]) => columns.filter(column => column.visible)
);

export const getSelectedDateCohort = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.selectedDateCohort
);
export const getUsedQueryParams = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.usedQueryParams
);
export const getSelectedChartKey = createSelector(selectOpportunitiesState,
  (state: OpportunitiesState) => state.selectedChart
);
export const getSearchOptions = createSelector(getAllOpportunityColumns,
  (columns: IOpportunityColumn[]): ISearchOption[] => {
    return columns.reduce((acc: ISearchOption[], column: IOpportunityColumn) => {
      if (column.searchable) {
        acc.push({
          name: column.displayName,
          value: getSearchName(column.name)
        });
      }

      return acc;
    }, []);
  }
);

export const getModelsOptions = createSelector(
  OrgConfigStore.getUserModels,
  (models: ILabelValue[]): ILabelValue[] => {
    const options = [{
      label: 'measurementStudio.features.opportunities.filters.modelOptions.any',
      value: InfluenceType.ANY,
    }];
    const userModels = models.slice();
    const influence = INFLUENCE_TYPE_OPTIONS.filter(type => type.value !== InfluenceType.ANY);

    return [
      ...options,
      ...userModels,
      ...influence,
      {
        label: 'measurementStudio.features.opportunities.filters.modelOptions.none',
        value: 'none',
      }
    ];
  }
);

export const getCampaignSpecificData = createSelector(getOpportunityFilters,
  (filters: IOpportunityRequest): ICampaignSpecific | null => {
    if (filters) {
      const influenceKey = Object.keys(OpportunityInfluences).find(key => filters[key]) as InfluencesKeys;
      if (influenceKey) {
        return {
          key: influenceKey,
          name: filters[OpportunityInfluences[influenceKey].field],
          description: OpportunityInfluences[influenceKey].description,
        };
      }
    }
    return null;
  }
);

export const getChartOptions = createSelector(
  OrgConfigStore.isSigstrEnabled,
  UserSelectors.isFeatureFlagActive(FeatureFlagsEnum.WaterfallStages),
  (
    isSigstrEnabled: boolean,
    isWaterfallStagesActive: boolean,
  ): ILabelValue[] => {
    const options = [{
      value: VisualKeys.Stage,
      label: 'measurementStudio.features.opportunities.chart.options.stage'
    }, {
      value: VisualKeys.SourceCampaignGroup,
      label: 'measurementStudio.features.opportunities.chart.options.sourceCampaignGroup'
    }, {
      value: VisualKeys.LastTouchCampaignGroup,
      label: 'measurementStudio.features.opportunities.chart.options.lastTouchCampaignGroup'
    }, {
      value: VisualKeys.IntentScore,
      label: 'measurementStudio.features.opportunities.chart.options.intentScore'
    }, {
      value: VisualKeys.ScoreMapped,
      label: 'measurementStudio.features.opportunities.chart.options.scoreMapped'
    }];

    return options.filter(option => {
      // don't show Stage chart if WaterfallStages is active
      if (isWaterfallStagesActive && option.value === VisualKeys.Stage) {
        return false;
      }
      // if sigstr is disabled then don't show IntentScore and ScoreMapped chart
      if (!isSigstrEnabled && [VisualKeys.IntentScore, VisualKeys.ScoreMapped].includes(option.value)) {
        return false;
      }

      return true;
    });
  });

export const getAllCharts = createSelector(
  getOpportunityVisualization,
  getChartOptions,
  (
    visualData: IOpportunityVisual,
    options: ILabelValue[]
  ): IOpportunityInsightCharts => {
    if (!visualData) {
      return {} as IOpportunityInsightCharts;
    }

    return options.reduce((acc: IOpportunityInsightCharts, option: ILabelValue) => {
      acc[option.value] = visualData[option.value].map((visual: IOpportunityVisualItem) => {
        return {
          type: visual[0] ? toCapitalize(visual[0]) : null,
          value: visual[1],
        };
      });
      return acc;
    }, {} as IOpportunityInsightCharts);
  });

export const getChartByKey = createSelector(
  getAllCharts,
  getSelectedChartKey,
  (
    charts: IOpportunityInsightCharts,
    key: VisualKeys
  ): IOpportunityInsightChartData[] => {
    if (key && charts) {
      return charts[key];
    }

    return [];
  });

export const getOpportunitiesMetrics = createSelector(
  getOpportunityTotals, (totals: IOpportunityTotals): ReportMetric[] => {
    if (totals) {
      return [
        {
          title: 'measurementStudio.features.opportunities.metrics.opportunities',
          current: {
            value: totals.opptyCount,
            dataType: DataTypeEnum.Number,
            color: '#4F5A9F'
          }
        }, {
          title: 'measurementStudio.features.opportunities.metrics.amounts',
          current: {
            value: totals.revenue,
            dataType: DataTypeEnum.Currency,
          }
        }];
    }

    return [];
  });

// need to adapt to snake case to send to the backend
function getSearchName(name: string): string {
  switch (name) {
    case 'accountName':
      return 'account_name';
    case 'configuredStage':
      return 'configured_stage';
    case 'sourceCampaignName':
      return 'source_campaign_name';
    case 'sourceCampaignGroup':
      return 'source_campaign_group';
    case 'lastCampaignName':
      return 'last_campaign_name';
    case 'lastTouchCampaignGroup':
      return 'last_touch_campaign_group';
    default:
      return name;
  }
}
