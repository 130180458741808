import { Action, createReducer, on } from '@ngrx/store';

import { campaignsActions } from './campaigns.actions';
import * as interfaces from '../../interfaces';
import {
  assembleAnalyticsData,
  updateLoadingState,
  updateNestedState,
  updateNestedTableState,
  updateNestedTableVisibility
} from '../../helpers/store.helper';
import { ILabelValue } from '@shared/interfaces';
import { defaultSorter } from '../../data';
import { IAnalyticsNested, IAnalyticsRequest } from '@measurement-studio/interfaces';

export const allCampaignTypeOption: ILabelValue = {
  label: 'shared.ui.reportCheckboxSelector.all',
  value: '',
};

export const campaignsFeatureKey = 'campaigns';

export interface ICampaignsState extends interfaces.IAnalyticsIsLoading {
  nested: IAnalyticsNested<Partial<interfaces.ICampaignsTableItem>>;
  totalResults: number;
  selectedCampaignType: ILabelValue;
  selectedTags: string[];
  hasWebActivity: interfaces.ICampaignHasWebActivityResponse[];
  data: Partial<interfaces.ICampaignsTableItem>[];
  nestedTableState: interfaces.INestedTableState;
  usedParamsInDriver: IAnalyticsRequest | null;
}

export const initialState: ICampaignsState = {
  nested: {
    data: {},
    loading: {},
    totalResults: {},
    sorter: {},
    pager: {},
  },
  totalResults: undefined,
  selectedCampaignType: allCampaignTypeOption,
  selectedTags: [],
  isDriverLoading: false,
  hasWebActivity: [],
  hasWebActivityIsLoading: false,
  data: [],
  campaignIsLoading: false,
  responseIsLoading: false,
  attributedIsLoading: false,
  influenceIsLoading: false,
  returnsIsLoading: false,
  costIsLoading: false,
  nestedTableState: {
    visible: {},
  },
  usedParamsInDriver: null,
};

const campaignsReducer = createReducer(
  initialState,
  on(campaignsActions.setUsedParams, (state, action) => ({
    ...state,
    usedParamsInDriver: action.request,
  })),
  on(campaignsActions.hasWebActivitySuccess, (state, action) => ({
    ...state,
    hasWebActivity: action.data.hasWebActivity,
    hasWebActivityIsLoading: false,
  })),
  on(campaignsActions.loadMetaDataSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.campaign)}
      : assembleAnalyticsData(state.data, action, action.data.campaign)),
    campaignIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.loadResponsesSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.response)}
      : assembleAnalyticsData(state.data, action, action.data.response)),
    responseIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.loadAttributionSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.attributed)}
      : assembleAnalyticsData(state.data, action, action.data.attributed)),
    attributedIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.loadInfluenceSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.influenced)}
      : assembleAnalyticsData(state.data, action, action.data.influenced)),
    influenceIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.loadReturnsSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.returns)}
      : assembleAnalyticsData(state.data, action, action.data.returns)),
    returnsIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.loadCostsSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.costs)}
      : assembleAnalyticsData(state.data, action, action.data.costs)),
    costIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignsActions.hasWebActivity, (state) => ({
    ...state,
    hasWebActivityIsLoading: true
  })),
  on(campaignsActions.loadMetaData, (state, action) => ({
    ...state,
    campaignIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.loadResponses, (state, action) => ({
    ...state,
    responseIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.loadAttribution, (state, action) => ({
    ...state,
    attributedIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.loadInfluence, (state, action) => ({
    ...state,
    influenceIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.loadCosts, (state, action) => ({
    ...state,
    costIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.loadReturns, (state, action) => ({
    ...state,
    returnsIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignsActions.hasWebActivityFailure, (state) => ({
    ...state, hasWebActivityIsLoading: false
  })),
  on(campaignsActions.loadMetaDataFailure, (state) => ({
    ...state, campaignIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.loadResponsesFailure, (state) => ({
    ...state, responseIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.loadAttributionFailure, (state) => ({
    ...state, attributedIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.loadInfluenceFailure, (state) => ({
    ...state, influenceIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.loadCostsFailure, (state) => ({
    ...state, costIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.loadReturnsFailure, (state) => ({
    ...state, returnsIsLoading: false, isDriverLoading: false
  })),
  on(campaignsActions.setSelectedCampaignType, (state, action) => ({
    ...state, selectedCampaignType: action.data
  })),
  on(campaignsActions.toggleTableRow, (state, action) => ({
    ...state,
    nestedTableState: updateNestedTableVisibility(state.nestedTableState, action.id),
  })),
  on(campaignsActions.changeNestedSorting, (state, action) => {
    const nestedSorting = action.data.value.sortDirection ? action.data.value : defaultSorter;
    return {
      ...state,
      nested: updateNestedTableState(state.nested, 'sorter', action.data.id, nestedSorting),
    };
  }),
  on(campaignsActions.changeNestedPagination, (state, action) => ({
    ...state,
    nested: updateNestedTableState(state.nested, 'pager', action.data.id, action.data.value),
  })),
  on(campaignsActions.clearNestedState, (state) => ({
    ...state,
    nested: {
      data: {},
      loading: {},
      totalResults: {},
      sorter: {},
      pager: {},
    }
  })),
  on(campaignsActions.closeNestedTables, (state) => ({
    ...state,
    nestedTableState: {visible: {}}
  })),
);

export function reducer(state, action: Action): ICampaignsState {
  return campaignsReducer(state, action);
}
