<div class="container">
  <a *ngFor="let tab of tabs; index as i"
     [routerLink]="tab.link"
     class="report-tab"
     queryParamsHandling="preserve"
     routerLinkActive="report-tab-active"
     [attr.data-cy]="'report-tabs-' + i">
    {{ tab.title | translate }} <span *ngIf="tab.count" class="report-tab-count">{{ tab.count }}</span>
  </a>
</div>
