import { createAction, props } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';

export enum AppSettingsActionTypes {
  LAST_DC_UPDATED_DATE_LOAD = '[App Settings] Last DC Updated Date Load',
  LAST_DC_UPDATED_DATE_LOAD_SUCCESS = '[App Settings] Last DC Updated Date Load Success',
  LAST_DC_UPDATED_DATE_LOAD_FAILURE = '[App Settings] Last DC Updated Date Load Failure',
  LOAD_CUSTOMER_ENTITLEMENT_AUTHORIZE = '[App Setting] Customer Entitlement Authorize',
  GET_CUSTOMER_ENTITLEMENT_CLAIMS_SUCCESS = '[App Setting] Get Token with Customer Entitlement Claims Success',
  SET_UNIFIED_LOGIN_STATUS = '[App Setting] Set Unified Login Status',
  SET_UNIFIED_CUSTOMER_ID = '[App Setting] Set Customer Entitlement Id',
}

defineTypeEnum(AppSettingsActionTypes);

const loadCustomerEntitlementAuthorize = createAction(
  AppSettingsActionTypes.LOAD_CUSTOMER_ENTITLEMENT_AUTHORIZE
);

const loadDcLastUpdatedDate = createAction(
  AppSettingsActionTypes.LAST_DC_UPDATED_DATE_LOAD
);

const loadDcLastUpdatedDateSuccess = createAction(
  AppSettingsActionTypes.LAST_DC_UPDATED_DATE_LOAD_SUCCESS,
  props<{ date: Date }>(),
);

const loadDcLastUpdatedDateFailure = createAction(
  AppSettingsActionTypes.LAST_DC_UPDATED_DATE_LOAD_FAILURE,
  props<{ message: string }>()
);

const setUnifiedLoginStatus = createAction(
  AppSettingsActionTypes.SET_UNIFIED_LOGIN_STATUS,
  props<{ status: boolean }>()
);

const setUnifiedCustomerId = createAction(
  AppSettingsActionTypes.SET_UNIFIED_CUSTOMER_ID,
  props<{ id: string }>()
);

const getCustomerEntitlementClaimsSuccess = createAction(
  AppSettingsActionTypes.GET_CUSTOMER_ENTITLEMENT_CLAIMS_SUCCESS
);

export const appSettingsActions = {
  getCustomerEntitlementClaimsSuccess,
  loadCustomerEntitlementAuthorize,
  loadDcLastUpdatedDate,
  loadDcLastUpdatedDateSuccess,
  loadDcLastUpdatedDateFailure,
  setUnifiedCustomerId,
  setUnifiedLoginStatus
};
