import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';

import { ReportTabsModule } from '@ui/components/report-tabs';
import { CampaignAnalyticsComponent } from './containers/campaign-analytics.component';
import { CampaignAnalyticsRoutingModule } from './campaign-analytics-routing.module';
import { SharedAnalyticsModule } from '../../shared-analytics.module';
import { AnalyticsFiltersModule } from '../../components/filters/analytics-filters.module';

@NgModule({
  declarations: [CampaignAnalyticsComponent],
  imports: [
    CommonModule,
    CampaignAnalyticsRoutingModule,
    SharedAnalyticsModule,
    AnalyticsFiltersModule,
    ReportTabsModule,
    TsLoadingOverlayModule,
  ],
})
export class CampaignAnalyticsModule {
}
