import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TsSelectionListComparator, TsSelectionListFormatter } from '@terminus-lib/ui-selection-list';

import { FolderPermission } from '@shared/enums';
import { IAccountsFolderUser, IUpdateAccountsFolderData } from '@shared/interfaces';

@Component({
  selector: 'tsh-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFolderDialogComponent implements OnInit {
  public form: FormGroup;
  public FolderPermission = FolderPermission;
  public query = '';

  public formatDisplay: TsSelectionListFormatter =
    (v: IAccountsFolderUser) => v.email;
  public userCompareFunction: TsSelectionListComparator =
    (a: IAccountsFolderUser, b: IAccountsFolderUser) => a.userId === b.userId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUpdateAccountsFolderData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>
  ) {
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      folder_name: [this.data.folder?.folderName || '', [Validators.required, Validators.maxLength(64)]],
      folder_permission: this.data.folder?.folderPermission || FolderPermission.Public,
      folder_type: 'account_list',
      foldersNames: [this.data.foldersNames.filter(name => name !== this.data.folder?.folderName)],
      ...(this.data.folder
        ? {
          owners: [this.data.users.filter(user => user.email === this.data.folder.email)],
          folder_id: this.data.folder.folderId
        }
        : {}),
    }, {validators: this.duplicateValidator});
  }

  public close(): void {
    this.dialogRef.close(this.form.value);
  }

  public duplicateValidator(control: FormGroup): ValidationErrors | null {
    const name = control.get('folder_name').value;
    const lists = control.get('foldersNames').value;
    const exists = lists.includes(name);
    return exists ? { duplicate: true } : null;
  }

  public get users(): IAccountsFolderUser[] {
    return this.data.users.filter(user =>
      this.query === this.form.value.owners[0].email ||
        user.email.toLowerCase().startsWith(this.query.toLowerCase()))
  }
}
