<ms-trending-filters (applyFilters)="applyFilters($event)"
                     (changeSelectedDateCohort)="changeSelectedDateCohort($event)"
                     [isCampaignTrendsReport]="isCampaignTrendsReport$ | async"
                     [isWebActivitiesTrending]="isWebActivitiesTrending$ | async"
                     [modelTypeOptions]="modelTypeOptions$| async"
                     [frequencyTypeOptions]="frequencyTypeOptions$| async"
                     [metricOptions]="metricOptions$ | async"
                     [selectedDateCohort]="selectedDateCohort$ | async"
                     [dateCohortOptions]="dateCohortOptions$ | async"
                     [filters]="filters$ | async"
                     [isLoading]="isLoadingAll$ | async">
</ms-trending-filters>
<hr class="report-divider">
<div [tsLoadingOverlay]="isLoadingTotal$ | async">
    <div class="metrics" *ngIf="(reportData$ | async) && (metrics$ | async) as metrics">
      <tsh-report-metric *ngFor="let metric of metrics"
                         [data]="metric">
      </tsh-report-metric>
    </div>
    <ms-trending-chart [tooltipGoal]="'feature.sharedTrending.chart.tooltipGoal' | translate"
                       [tooltipPrevious]="'feature.sharedTrending.chart.previousPeriod' | translate"
                       [tooltipTitle]="(metricFieldLabel$ | async) | translate"
                       [frequency]="frequencyTitle$ | async"
                       [filters]="filters$ | async"
                       [dataType]="dataType$ | async"
                       *ngIf="(reportData$ | async)"
                       data-cy="trending-chart"
                       [data]="chartData$ | async">
    </ms-trending-chart>
    <hr class="report-divider">
    <ms-trending-comparative-table *ngIf="(comparativeReportData$ | async)?.length"
                                   (selected)="selectGroup($event)"
                                   [report]="comparativeReportData$ | async"
                                   [columns]="comparativeColumns$ | async"
                                   [totalTitle]="comparativeTotalTitle$ | async"
                                   [data]="comparativeTableData$ | async"
                                   [countData]="(reportData$ | async)?.length"
                                   [currentPage]="comparativeTableCurrentPage$ | async"
                                   (changePage)="changePage($event)"
                                   (download)="download()"
    ></ms-trending-comparative-table>
    <hr class="report-divider">
</div>
<div [tsLoadingOverlay]="isLoadingAll$ | async">
  <ms-trending-buttons (selected)="selectGroup($event)"
                       [selectedId]="selectedGroupId$ | async"
                       [reportData]="reportData$ | async">
  </ms-trending-buttons>
  <ms-trending-details></ms-trending-details>
</div>

<tsh-add-to-dashboard-button (add)="addToDashboardDialog()"
                             [target]="targets.HeaderButtons">
</tsh-add-to-dashboard-button>
