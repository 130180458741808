import {
  ChangeDetectionStrategy, Component, Input,
  OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';

import * as am4Charts from '@amcharts/amcharts4/charts';
import { TsAmChartsService } from '@terminus-lib/ui-chart';

import {
  AttributionCampaignInitialChartData,
  IAttributionCampaignChartColumn,
  IAttributionCampaignChartGroup
} from '../../interfaces/attribution-campaign-chart.interface';

@Component({
  selector: 'ms-attribution-campaign-chart',
  templateUrl: './attribution-campaign-chart.component.html',
  styleUrls: ['./attribution-campaign-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributionCampaignChartComponent implements OnChanges, OnDestroy {
  @Input() public data: AttributionCampaignInitialChartData | null;
  @Input() public groups: IAttributionCampaignChartGroup[] = [];
  @Input() public columns: IAttributionCampaignChartColumn[] = [];
  @Input() public colors: Record<string, string>;
  @Input() public isLoading = false;

  public chart: am4Charts.XYChart;

  constructor(private amChartsService: TsAmChartsService) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // update chart after finishing loading all data
    if (changes.isLoading
      && !changes.isLoading.isFirstChange()
      && changes.isLoading.currentValue === false) {
      this.initChart();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setUpChart(chart: am4Charts.XYChart): void {
    this.chart = chart;
    this.createLegend();
    this.createXAxes();
    this.createYAxes();
    if (this.data) {
      this.initChart();
    }
  }

  initChart(): void {
    // set chart data
    // combine columns' info with data by revenue group
    this.chart.data = this.columns.map(column => {
      const chartData = this.data && this.data[column.key] || {};
      return {
        ...column,
        ...chartData
      };
    });
    this.chart.series.clear();
    this.groups.forEach(group => {
      this.createSeries(group.key, group.label);
    });
  }

  private createLegend(): void {
    this.chart.legend = new this.amChartsService.amCharts.charts.Legend();
    this.chart.legend.labels.template.text = '[text-transform: capitalize]{name}[/]';
  }

  private createXAxes(): void {
    this.chart.xAxes.clear();
    const categoryAxis = this.chart.xAxes.push(new this.amChartsService.amCharts.charts.CategoryAxis());
    categoryAxis.dataFields.category = 'title';
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 140;
    label.textAlign = 'middle';
    label.tooltipText = '{tooltip}';
    categoryAxis.tooltip.label.maxWidth = 350;
    categoryAxis.tooltip.label.wrap = true;
  }

  private createYAxes(): void {
    this.chart.yAxes.clear();
    const valueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.renderer.labels.template.adapter.add('text', text => {
      /* istanbul ignore next */
      return text + '%';
    });
  }

  private createSeries(key: string, name: string): am4Charts.ColumnSeries {
    const color = this.colors && this.colors[key];
    const series = this.chart.series.push(new this.amChartsService.amCharts.charts.ColumnSeries());
    series.columns.template.width = this.amChartsService.amCharts.core.percent(60);
    series.columns.template.fill = this.amChartsService.amCharts.core.color(color);
    series.columns.template.stroke = this.amChartsService.amCharts.core.color('#ffffff');
    series.columns.template.strokeWidth = 2;
    series.name = name;
    series.dataFields.categoryX = 'title';
    series.dataFields.valueY = key;
    series.dataFields.valueYShow = 'totalPercent';
    series.stacked = true;
    series.columns.template.tooltipHTML = `
            <div class="amcharts-tooltip">
            <span class="title text-capitalize">{name}</span>
            <hr class="report-divider">
            <span class="subtitle">{title}</span></br>
            <div class="section">
                <div class="color" style="background-color: ${color};"></div>
                {valueY.formatNumber("$#,###.00")}
            </div>
            <span class="subtitle">{valueY.totalPercent.formatNumber('#.00')}%</span>
        </div>`;

    return series;
  }
}
