import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as HubRouterStore from '@hub-router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { AUTH_CONT, AUTH_CONT_KEY_OKTA, AUTH_CONT_URL } from '@shared/constants';
import { APP_IDENTITY } from '@shared/data-access/app-identity';
import { TsCookieService, TsWindowService } from '@terminus-lib/fe-utilities';
import { NullAction } from '@util/testing';

@Injectable()
export class AuthContEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private cookieService: TsCookieService,
    public windowService: TsWindowService,
    @Inject(APP_IDENTITY) private appIdentity: string
  ) {
  }

  onNavigationComplete$ = createEffect(() => ({
    events$ = this.router.events,
    expires$ = of(Date.now() + 12 * 60 * 60 * 1000)
  } = {}) => this.actions$.pipe(
    ofType<RouterNavigationAction<HubRouterStore.HubRouterState>>(ROUTER_NAVIGATION),
    (action: Observable<RouterNavigationAction<HubRouterStore.HubRouterState>>) => combineLatest([action, events$, expires$]),
    filter(([, routerEvent]: [RouterNavigationAction<HubRouterStore.HubRouterState>, NavigationEnd, number]) =>
      routerEvent instanceof NavigationEnd && routerEvent.url.includes(AUTH_CONT_URL)),
    map(([action, , expires]: [RouterNavigationAction<HubRouterStore.HubRouterState>, NavigationEnd, number]) => {
      const host = this.windowService.nativeWindow.location.hostname;
      const groups = host.match(/(?:[a-z|-]*\.)?([a-z|\\.]+)/);
      const skey = action.payload.routerState?.queryParams?.skey;
      const state = sessionStorage.getItem(skey) || (
        this.appIdentity === 'measure' ? '/scorecard' : '/list-analysis'
      );

      sessionStorage.removeItem(skey);
      if (groups[1]) {
        this.cookieService.set(AUTH_CONT, AUTH_CONT_KEY_OKTA, expires, '/', `.${groups[1]}`);
      }

      console.log('App Identity: ', this.appIdentity);

      this.windowService.nativeWindow.location.href = state;
      return new NullAction();
    })
  ));

}

export const explodeURL = (url: string): [string, string] => {
  const [path='', queryParamsString=''] = url.split('?')
  return [path, queryParamsString];
}

export const parseQueryParams = (queryString: string): {[key: string]: string} => {
  const queryParams = {};
  const valuePairs = queryString.slice().split('&');
  valuePairs.map((pair) => {
    const [key, value] = pair.split('=');
    if(key) {
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
    }
  });
  return queryParams;
}
