import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as SavedReportsReducer from './saved-reports.reducer';
import { IFolderOption, ISavedFolder, SavedFolderPermissionTypes } from '@shared/interfaces';

export const getSavedReportsState =
  createFeatureSelector<SavedReportsReducer.SavedReportState>(SavedReportsReducer.savedReportsFeatureKey);

export const getAllSavedReports =
  createSelector(getSavedReportsState, (state: SavedReportsReducer.SavedReportState) => state.folders);

// the current user has access only for his/her folders, public and read-only folders
export const getPermittedSavedReports =
  createSelector(getAllSavedReports, folders => {
    return folders?.filter(folder => {
      return folder.isMine
        || folder.folderPermission === SavedFolderPermissionTypes.Public
        || folder.folderPermission === SavedFolderPermissionTypes.Readonly;
    });
  });

export const getSavedReportsIsLoading =
  createSelector(getSavedReportsState, (state: SavedReportsReducer.SavedReportState) => state.isLoading);

// options for selector in report dialog component
export const getFolderOptions =
  createSelector(getAllSavedReports, (reports: ISavedFolder[]): IFolderOption[] => {
    return reports?.map(item => {
      return {
        folderId: item.folderId,
        folderName: item.folderName
      };
    });
  });
