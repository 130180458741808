import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsCheckboxComponent, TsCheckboxModule } from '@terminus-lib/ui-checkbox';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsInputModule } from '@terminus-lib/ui-input';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { delay } from 'rxjs/operators';

import {
  IAccountFolder,
  IDataFilter,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
  ILabelValue,
  ISelectedGlobalFilter,
  ITargetFolderItem
} from '@shared/interfaces';
import { TablePanelModule } from '@ui/components/table-panel';
import { HeaderCellModule } from '@ui/components/header-cell';
import { GLOBAL_FILTER_HEADER_KEY_MAP, GLOBAL_FILTERS_KEYS } from '@shared/constants';
import { GlobalFiltersTableComponent } from './global-filters-table/global-filters-table.component';
import { GlobalFiltersHeader, GlobalFiltersKeys } from '@shared/enums';

@WithDestroy
@Component({
  selector: 'tsh-tile-global-filters',
  templateUrl: './tile-global-filters.component.html',
  styleUrls: ['./tile-global-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileGlobalFiltersComponent implements OnInit {
  @Input() public field: FormControl;
  @Input() public accountListFolders: IAccountFolder[];
  @Input() public globalFilters: IGlobalFilters;
  @Input() public appliedFiltersNumber: number;
  // state of all account lists (selected, not selected)
  @Input() public accountListFoldersState: IGlobalFilterFolderItem;
  // state of all filters (selected, not selected)
  @Input() public globalFiltersState: IGlobalFilterFolder;
  // state of filters after search
  @Input() public globalFiltersSearchState: IGlobalFilterFolder;
  // state of account lists after search
  @Input() public accountListsSearchState: IGlobalFilterFolderItem;
  @Input() public allSelectedFilters: ISelectedGlobalFilter[];
  @Input() public selectedGlobalFilters: IDataFilter;

  @Input()
  public set savedGlobalFilters(filters: IDataFilter[]) {
    this._savedGlobalFilters = filters;
    this.savedFiltersOptions = filters?.map(filter => ({
      label: filter.name,
      value: filter.name
    })) || [];
  }

  public get savedGlobalFilters(): IDataFilter[] {
    return this._savedGlobalFilters;
  }

  @Output() public search = new EventEmitter<string>();
  @Output() public applySavedGlobalFilter = new EventEmitter<IDataFilter>();
  @Output() public toggleFolderSelect = new EventEmitter<ITargetFolderItem[]>();
  @Output() public folderSelectAll = new EventEmitter<ITargetFolderItem[]>();
  @Output() public toggleFolderItemSelect = new EventEmitter<ITargetFolderItem>();
  @Output() public removeSelectedFolder = new EventEmitter<ITargetFolderItem>();

  @ViewChildren(TsCheckboxComponent) public checkboxes!: QueryList<TsCheckboxComponent>;

  public readonly filtersKeys = GLOBAL_FILTERS_KEYS;
  public readonly filtersNames: Record<GlobalFiltersKeys, GlobalFiltersHeader> = GLOBAL_FILTER_HEADER_KEY_MAP;
  public readonly accountListKey = GlobalFiltersKeys.AccountList;
  public savedFiltersOptions: ILabelValue[] = [];
  public searchControl = new FormControl('');
  public newSelectedGlobalFilters: ITargetFolderItem[] = [];

  private _savedGlobalFilters: IDataFilter[];

  ngOnInit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        delay(300),
        untilComponentDestroyed(this)
      )
      .subscribe((searchQuery: string) => {
        this.search.emit(searchQuery.trim());
      });
  }

  selectSavedFilter(name: string): void {
    // update selected saved filter only if we select another filter
    if (!this.selectedGlobalFilters || this.selectedGlobalFilters.name !== name) {
      const selectedSavedFilter = this.savedGlobalFilters.find(filter => filter.name === name);
      this.applySavedGlobalFilter.emit(selectedSavedFilter);
    }
  }

  toggleGlobalFilterSubSection(folderName: GlobalFiltersKeys, folderItem: string): void {
    // check if we've already added this filter
    if (this.newSelectedGlobalFilters.find(item => item.folderName === folderName && item.folderItem === folderItem)) {
      // remove already selected filter
      this.newSelectedGlobalFilters =
        this.newSelectedGlobalFilters.filter(item => !(item.folderName === folderName && item.folderItem === folderItem));
    } else {
      // or add new filter to the array
      this.newSelectedGlobalFilters.push({
        folderName,
        folderItem
      });
    }
  }

  cancelChanges(): void {
    this.newSelectedGlobalFilters = [];
    this.searchControl.setValue(''); // reset search
    // reset all checked but nor applied checkboxes because TsCheckboxComponent doesn't do this on cancel
    this.checkboxes.forEach((checkbox: TsCheckboxComponent) => {
      if (checkbox.isChecked && !checkbox.isDisabled) {
        checkbox.isChecked = false;
      }
    });
  }

  addGlobalFilters(): void {
    if (!this.newSelectedGlobalFilters.length) {
      return;
    }

    this.toggleFolderSelect.emit(this.newSelectedGlobalFilters);
    this.newSelectedGlobalFilters = [];
  }

  removeFolder(folder: ITargetFolderItem): void {
    this.removeSelectedFolder.emit(folder);
  }

  selectAllFolderItems(folder: ITargetFolderItem[]): void {
    this.folderSelectAll.emit(folder);
  }

  selectOneFolderItem(folderItem: ITargetFolderItem): void {
    this.toggleFolderItemSelect.emit(folderItem);
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TsMenuModule,
    TsTableModule,
    TablePanelModule,
    HeaderCellModule,
    TsButtonModule,
    TsInputModule,
    TsCheckboxModule
  ],
  declarations: [
    TileGlobalFiltersComponent,
    GlobalFiltersTableComponent,
  ],
  exports: [TileGlobalFiltersComponent],
})
export class TileGlobalFiltersModule {
}
