import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DefaultTokenRequired, JwtTokenManagementModule } from '@terminus-lib/fe-jwt';
import { RouterStateSerializer } from '@ngrx/router-store';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';

import { ClaimMap } from '@hub-token';
import { HubRouterSerializer } from '@hub-router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TypedDataPipe } from '@ui/pipes/typed-data';
import { AppMenuModule } from '@ui/components/app-menu';
import { RollbarErrorHandler, rollbarFactory, RollbarJS } from '@shared/rollbar';
import {
  CamelCaseInterceptor,
  ContinuationTokenOnlyInterceptor,
  EXCLUDED_URLS_FOR_INTERCEPTOR,
  HttpBaseInterceptor,
} from '@shared/interceptors';
import { NotificationModule } from '@ui/components/notification';
import { APP_ROUTES } from '@app-routes';
import {
  CARD_CONTAINER_BREAKPOINTS,
  CustomBreakPointsProvider,
  MEASUREMENT_STUDIO_NAV,
  MEASUREMENT_STUDIO_ROUTES,
  MEASUREMENT_STUDIO_SETTINGS_ROUTE_ITEMS,
} from '@measurement-studio/constants';
import { TagsURLs } from '@measurement/data-access/tags';
import { CampaignsSourceURLs } from '@shared/data-access/campaigns';
import { NAV_MENU } from '@nav-menus';
import {
  DATA_STUDIO_SHARED_ROUTES,
  hubTokenName,
  LANGUAGES,
  MEASUREMENT_STUDIO_SHARED_ROUTES,
} from '@shared/constants';
import { AppHeaderModule } from '@ui/components/app-header';
import { SETTINGS_ROUTE_ITEMS } from '@shared/data-access/shared-settings';
import { SidenavUtilitiesModule } from '@shared/features/sidenav-utilities';
import { StageInfluenceURLs } from '@measurement-studio/features/stage-influence';
import { CampaignPerformanceSourceURLs } from '@measurement-studio/features/attribution-campaign';
import { InfluencedInsightsSourceURLs } from '@measurement-studio/features/insights-common';
import { STORE_IMPORTS } from './store-imports';
import { CommonModule } from '@angular/common';
import { PortalDirectiveModule } from '@ui/directives/portal';
import { LocalStorageModule } from '@shared/data-access/local-storage';
import { TileModalModule } from '@shared/features/tile-modal';
import { TileDataService } from '@measurement-studio/features/tiles-data';
import { userAuthenticateToken } from '@shared/data-access/user-common';
import { UserService } from '@user/*';
import { DemoInterceptor } from '@shared/data-access/demoable-interceptor';
import { APP_IDENTITY } from '@shared/data-access/app-identity';
import { AppContainerComponent } from './containers/app/app-container.component';
import { SplitioModule, TreatmentTokenModule } from '@shared/splitio';
import { RouteTypeEnum } from '@shared/enums';
import { GreenfieldBridge } from '@util/greenfield-bridge';
import { ENVIRONMENT } from '@shared/data-access/environment';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/shared/i18n/', suffix: '.json?tstamp=' + Date.now() },
    { prefix: './assets/i18n/', suffix: '.json?tstamp=' + Date.now() },
  ]);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@NgModule({
  declarations: [AppContainerComponent, AppComponent],

  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SplitioModule,
    TreatmentTokenModule,
    FlexLayoutModule.withConfig({}, CARD_CONTAINER_BREAKPOINTS),
    // Store
    ...STORE_IMPORTS,
    // Material
    MatSidenavModule,
    MatToolbarModule,
    // app
    AppMenuModule,
    NotificationModule,
    AppRoutingModule,
    AppHeaderModule,
    SidenavUtilitiesModule,
    PortalDirectiveModule.forRoot(),
    JwtTokenManagementModule.forRoot<ClaimMap>({
      initialTokenName: hubTokenName,
    }),
    TranslateModule.forRoot({
      defaultLanguage: LANGUAGES[0],
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LocalStorageModule.forRoot(),
    TileModalModule.forRoot(TileDataService),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],

  providers: [
    Title,
    TypedDataPipe, // add to providers so that we can use it in download service
    {
      provide: DefaultTokenRequired,
      useClass: DefaultTokenRequired,
    },
    {
      provide: RouterStateSerializer,
      useClass: HubRouterSerializer,
    },
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler,
    },
    {
      provide: RollbarJS,
      useFactory: rollbarFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DemoInterceptor,
      multi: true,
    },
    {
      provide: userAuthenticateToken,
      useClass: UserService,
    },
    {
      provide: APP_ROUTES,
      useValue: [
        ...MEASUREMENT_STUDIO_ROUTES,
        ...MEASUREMENT_STUDIO_SHARED_ROUTES,
        ...DATA_STUDIO_SHARED_ROUTES.map(route => ({
          ...route,
          externalUrlKey: 'DATA_STUDIO_URL',
          routeType: RouteTypeEnum.ExternalLink,
        })),
      ],
    },
    {
      provide: NAV_MENU,
      useValue: MEASUREMENT_STUDIO_NAV,
    },
    {
      provide: SETTINGS_ROUTE_ITEMS,
      useValue: MEASUREMENT_STUDIO_SETTINGS_ROUTE_ITEMS,
    },
    {
      provide: APP_IDENTITY,
      useValue: 'measure',
    },
    // for camel case interceptor (only measurement studio urls)
    {
      provide: EXCLUDED_URLS_FOR_INTERCEPTOR,
      useValue: [
        CampaignPerformanceSourceURLs.GetCampaignTotalAmount,
        TagsURLs.TagsByCampaign,
        TagsURLs.PostTags,
        TagsURLs.TagsByChannel,
        TagsURLs.PostChannelTags,
        CampaignsSourceURLs.GetCampaignTypes,
        StageInfluenceURLs.CampaignStageInfluence,
        StageInfluenceURLs.CampaignStageInfluenceTotals,
        StageInfluenceURLs.CampaignTypeStageInfluence,
        InfluencedInsightsSourceURLs.AddToAccountList,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CamelCaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContinuationTokenOnlyInterceptor,
      multi: true,
    },
    {
      provide: GreenfieldBridge,
      useValue: new GreenfieldBridge()
    },
    CustomBreakPointsProvider,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(ENVIRONMENT) readonly env: { MS_GREENFIELD_URL: string, GREENFIELD_REDIRECT: boolean },
    greenfieldBridge: GreenfieldBridge,
    router: Router
  ) {
    greenfieldBridge.init(env.MS_GREENFIELD_URL, env.GREENFIELD_REDIRECT, (url) => router.navigateByUrl(url));
  }
}
