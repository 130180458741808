import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BehaviorSubject } from 'rxjs';

import { AppModule } from './app/app.module';
/* Environment Juggling:
    To support build-once deploy-anywhere, we don't use Angular's 
    fileReplacements tooling to entirely swap environment variables. 
    TODO: ((link to man))

   environment: 
     Using angular's fileReplacement, this provides { production: true | false } 
   environmentJSON: 
     This contains the source-of-truth for environment variables and types the app expects,
     along with values that work for ninja. 
   ENVIRONMENT / ENV_TOKEN: 
     angular injection tokens for dependency injection through the app 
*/
import { environment } from './environments/environment';
import environmentJSON from './environments/environment.json';
import { ENVIRONMENT, ENV_TOKEN, bootstrapEnvironmentVars } from '@shared/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

bootstrapEnvironmentVars(environmentJSON, environment.production, {
  /* environment.json overrides
     This should be exceptionally rare, usually just for local
     testing when testing against prod environment.json
     example:
      ALLOW_EMPLOYEE_LOGIN_PAGE: true
   */
}).then( env => {
  platformBrowserDynamic([
  {
    provide: ENVIRONMENT,
    useValue: env
  },
  // For more legacy code that depends on
  // an observable for env values, use
  // a BehaviorSubject
  {
    provide: ENV_TOKEN,
    useValue: new BehaviorSubject(env),
  }
  ]).bootstrapModule(AppModule)
}).catch( err => console.error(err) )
