import { createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';
import { ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { AttributionUsedQueryParams, ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { IDateCohort } from '@shared/interfaces';
import { IAttributionCampaignExpandedRow } from '../interfaces/attribution-campaign-report.interface';

export enum AttributionCampaignActionTypes {
  LoadCampaignPerformance = '[Attribution By Campaign Type] Load Campaign Performance',
  LoadCampaignPerformanceSuccess = '[Attribution By Campaign Type] Load Campaign Performance Success',
  LoadCampaignPerformanceFailure = '[Attribution By Campaign Type] Load Campaign Performance Failure',
  LoadTotalAmount = '[Attribution By Campaign Type] Load Total Amount',
  LoadTotalAmountSuccess = '[Attribution By Campaign Type] Load Total Amount Success',
  LoadTotalAmountFailure = '[Attribution By Campaign Type] Load Total Amount Failure',
  SetAttributionCampaignFilters = '[Attribution By Campaign Type] Set Filters',
  SetAttributionCampaignDateCohort = '[Attribution By Campaign Type] Set Date Cohort',
  GetAttributionCampaignExtendedRow = '[Attribution By Campaign Type] Get extended row information',
  CollectAttributionCampaignRowData = '[Attribution By Campaign Type] Collect information for extended row',
  SetAttributionCampaignExtendedRow = '[Attribution By Campaign Type] Set extended row information',
  ToggleExpandAttributionCampaignRow = '[Attribution By Campaign Type] Open or close row with group information',
  ToggleExpandedAttributionCampaignState = '[Attribution By Campaign Type] Change row\'s state to open or close',
  SetAttributionReportSortState = '[Attribution By Campaign Type] Set a new sort state for the report',
  SetAttributionUsedQueryParams = '[Attribution By Campaign Type] Set used query params',
  SetAttributionExpandedReportSortState = '[Attribution By Campaign Type] Set a new sort state for the expanded report',
  SetAttributionExpandedReportPage = '[Attribution By Campaign Type] Set a new page for the expanded report',
  DownloadCSV = '[Attribution By Campaign Type] download csv file',
  DownloadCSVSuccess = '[Attribution By Campaign Type] download csv file success',
  DownloadCSVForExpandedReport = '[Attribution By Campaign Type] download csv file for expanded report',
  DownloadCSVForExpandedReportSuccess = '[Attribution By Campaign Type] download csv file for expanded report success',
}

const loadCampaignPerformance = createAction(
  AttributionCampaignActionTypes.LoadCampaignPerformance,
);

const loadCampaignPerformanceSuccess = createAction(
  AttributionCampaignActionTypes.LoadCampaignPerformanceSuccess,
  props<{ data: ICampaignPerformance }>()
);

const loadCampaignPerformanceFailure = createAction(
  AttributionCampaignActionTypes.LoadCampaignPerformanceFailure,
  props<{ error: string }>()
);

const loadTotalAmount = createAction(
  AttributionCampaignActionTypes.LoadTotalAmount
);

const loadTotalAmountSuccess = createAction(
  AttributionCampaignActionTypes.LoadTotalAmountSuccess,
  props<{ data: ICampaignTotalAmount }>()
);

const loadTotalAmountFailure = createAction(
  AttributionCampaignActionTypes.LoadTotalAmountFailure,
  props<{ error: string }>()
);

const setAttributionCampaignFilters = createAction(
  AttributionCampaignActionTypes.SetAttributionCampaignFilters,
  props<{ filters: ICampaignFilters }>()
);

const setAttributionCampaignDateCohort = createAction(
  AttributionCampaignActionTypes.SetAttributionCampaignDateCohort,
  props<{ date: IDateCohort }>()
);

const getAttributionCampaignExtendedRow = createAction(
  AttributionCampaignActionTypes.GetAttributionCampaignExtendedRow,
  props<{ key: string }>()
);

const collectAttributionCampaignRowData = createAction(
  AttributionCampaignActionTypes.CollectAttributionCampaignRowData,
  props<{ key: string }>()
);

const setAttributionCampaignExtendedRow = createAction(
  AttributionCampaignActionTypes.SetAttributionCampaignExtendedRow,
  props<{ data: IAttributionCampaignExpandedRow[]; key: string }>()
);

const toggleExpandAttributionCampaignRow = createAction(
  AttributionCampaignActionTypes.ToggleExpandAttributionCampaignRow,
  props<{ key: string }>()
);

const toggleExpandedAttributionCampaignState = createAction(
  AttributionCampaignActionTypes.ToggleExpandedAttributionCampaignState,
  props<{ key: string; state: boolean }>()
);

const setAttributionReportSortState = createAction(
  AttributionCampaignActionTypes.SetAttributionReportSortState,
  props<{ state: TsSortState }>()
);

const setAttributionUsedQueryParams = createAction(
  AttributionCampaignActionTypes.SetAttributionUsedQueryParams,
  props<{ params: AttributionUsedQueryParams }>()
);

const setAttributionExpandedReportSortState = createAction(
  AttributionCampaignActionTypes.SetAttributionExpandedReportSortState,
  props<{ key: string; state: TsSortState }>()
);

const setAttributionExpandedReportPage = createAction(
  AttributionCampaignActionTypes.SetAttributionExpandedReportPage,
  props<{ key: string; page: number }>()
);

const downloadCSV = createAction(
  AttributionCampaignActionTypes.DownloadCSV
);

const downloadCSVSuccess = createAction(
  AttributionCampaignActionTypes.DownloadCSVSuccess
);

const downloadCSVForExpandedReport = createAction(
  AttributionCampaignActionTypes.DownloadCSVForExpandedReport,
  props<{ key: string; label: string; }>()
);

const downloadCSVForExpandedReportSuccess = createAction(
  AttributionCampaignActionTypes.DownloadCSVForExpandedReportSuccess
);

export const attributionCampaignActions = {
  loadCampaignPerformance,
  loadCampaignPerformanceSuccess,
  loadCampaignPerformanceFailure,
  loadTotalAmount,
  loadTotalAmountSuccess,
  loadTotalAmountFailure,
  setAttributionCampaignFilters,
  setAttributionCampaignDateCohort,
  getAttributionCampaignExtendedRow,
  collectAttributionCampaignRowData,
  setAttributionCampaignExtendedRow,
  toggleExpandAttributionCampaignRow,
  toggleExpandedAttributionCampaignState,
  setAttributionReportSortState,
  setAttributionUsedQueryParams,
  setAttributionExpandedReportSortState,
  setAttributionExpandedReportPage,
  downloadCSV,
  downloadCSVSuccess,
  downloadCSVForExpandedReport,
  downloadCSVForExpandedReportSuccess
}

