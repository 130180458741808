<div class="c-icon" *ngIf="icon">
  <fa-icon
    [icon]="icon"
    [classes]="classes"
    [title]="title"
    [styles]="styles"
    [size]="size"
    [pull]="pull"
    [border]="border"
    [inverse]="inverse"
    [symbol]="symbol"
    [fixedWidth]="fixedWidth"
    [flip]="flip"
    [rotate]="rotate"
    [transform]="transform"
    [pulse]="pulse"
    [spin]="spin"
  ></fa-icon>
</div>
