import { IFactoryParams } from '@shared/interfaces';

export interface IMarketingInfluenceReportTotals {
    count: number;
    value: number;
    marketingCount: number;
    marketingValue: number;
    calculatedCount: number;
    calculatedValue: number;
}

export function marketingInfluenceReportTotalsFactory({
    n = 1,
    overrides = {},
}: IFactoryParams<IMarketingInfluenceReportTotals, number, boolean> = {}): IMarketingInfluenceReportTotals {
    return {
        count: n * 10,
        value: n * 1000000,
        marketingCount: n * 10,
        marketingValue: n * 1000000,
        calculatedCount: n * 100,
        calculatedValue: n * 100000,
        ...overrides
    };
}




