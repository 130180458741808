import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { TsWindowService } from '@terminus-lib/fe-utilities';

import * as HubNavigationActions from './hub-navigation.actions';
import { GoParams, IRouteItem } from '@shared/interfaces';
import { RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { getQueryString } from '@util/http';
import { EnvService } from '@shared/environment';
import { APP_ROUTES } from '@app-routes';
import * as OrgConfigStore from '@org-config';
import { findRouteItem as findRouteHelper } from '@util/helpers';
import { getIsSplitIoFlagOn, treatmentTokens } from '@shared/splitio';

@Injectable()
export class HubNavigationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<unknown>,
    private readonly router: Router,
    private readonly windowService: TsWindowService,
    private readonly envService: EnvService,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[]
  ) {}

  navigateTo$ = createEffect(() => this.actions$.pipe(
    ofType(HubNavigationActions.NavigateTo),
    concatLatestFrom(() => [
      this.store.pipe(select(getIsSplitIoFlagOn(treatmentTokens.hubajs_migration))),
      this.store.pipe(select(OrgConfigStore.getSalesforceUrl))
    ]),
    tap(([goParams, isMigratedSettingsEnabled, salesforceUrl]: [GoParams, boolean, string]) => {
      // NOTE: new implementation
      if (goParams.routeId) {
        const routeItem = this.findRouteItem(goParams.routeId, isMigratedSettingsEnabled);
        if (!routeItem) {
          console.error('Incorrect route is used.');
          return;
        }

        if (routeItem && goParams.routeId === RouteItemEnum.Salesforce) {
          // add id to salesforce url
          const link = goParams?.queryParams?.id
            ? `${salesforceUrl}/${goParams.queryParams.id}` : salesforceUrl;
          routeItem.routeLink = [link];
        }

        if (routeItem.routeType === RouteTypeEnum.InternalLink) {

          this.router.navigate(routeItem.routeLink, { queryParams: goParams.queryParams }).then();
        } else if (routeItem.routeType === RouteTypeEnum.LegacyLink) {
          const queryString = getQueryString(goParams.queryParams);
          this.windowService.nativeWindow.location.href =
            `${this.envService.getEnv().HUB_AJS_URL}/${routeItem.routeLink[0]}${queryString ? '?' + queryString : ''}`;
        } else if (routeItem.routeType === RouteTypeEnum.ExternalLink) {
          const queryString = getQueryString(goParams.queryParams);
          const externalUrl = routeItem.externalUrlKey
            ? `${this.envService.getEnv()[routeItem.externalUrlKey]}${
            routeItem.routeLink[0] === this.envService.getEnv()[routeItem.externalUrlKey] ? routeItem.routeLink[1] : routeItem.routeLink[0]
          }${queryString ? '?' + queryString : ''}`
            : routeItem.routeLink[0];
          this.windowService.nativeWindow.open(externalUrl, '_blank');
        }
      } else {
        // NOTE: old implementation, which has to be  deleted in future
        // TODO: delete this implementation after replacing all cases with new one
        if (goParams.external) {
          const queryString = goParams?.extras?.queryParams ? getQueryString(goParams.extras.queryParams) : null;
          let url = goParams.path.join('/');

          if (queryString) {
            url = `${url}?${queryString}`;
          }

          if (goParams.newTab) {
            this.windowService.nativeWindow.open(url, '_blank');
          } else {
            this.windowService.nativeWindow.location.replace(url);
          }
        } else {
          this.router.navigate(goParams.path, { queryParams: goParams.query, ...goParams.extras });
        }
      }
    }),
  ), { dispatch: false });

  public findRouteItem(routeId: RouteItemEnum, isEnabled: boolean): IRouteItem | null {
    return findRouteHelper(routeId, isEnabled, this.applicationRoutes);
  }
}
