import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { merge, NEVER, of } from 'rxjs';
import { select, Store } from '@ngrx/store';


import { globalSearchActions } from './global-search.actions';
import { CampaignsService } from '@shared/data-access/campaigns';
import { GlobalSearchTypeEnum } from '../enums';
import { GlobalSearchService } from '../services/global-search.service';
import { HttpErrorResponse } from '@angular/common/http';
import { userMessageFactory } from '@shared/interfaces';
import { notificationMessagesActions } from '@notification-messages';
import * as UserSelectors from '@user/selectors';
import { IGlobalSearchState } from './global-search.reducer';
import { RouteItemEnum } from '@shared/enums';

@Injectable()
export class GlobalSearchEffects {

  constructor(
    private actions$: Actions,
    private campaignServise: CampaignsService,
    private globalSearchService: GlobalSearchService,
    private store: Store<IGlobalSearchState>,
  ){}

  public onLoadSearchData$ = createEffect(() => this.actions$.pipe(
    ofType(globalSearchActions.loadSearchData),
    concatLatestFrom(() => this.store.pipe(select(UserSelectors.getHasReportAccessByName(RouteItemEnum.WebTracking)))),
    mergeMap(([action, hasWebActivity]) => merge(
      of(globalSearchActions.setLoading({ number:  hasWebActivity ? 4 : 3})),
      this.campaignServise.getCampaigns$(action.searchQuery).pipe(
        map(data => globalSearchActions.loadSearchDataSuccess({
          key: GlobalSearchTypeEnum.Campaigns,
          data
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.features.globalSearch.errors.loadCampaigns';
          return of(globalSearchActions.loadSearchDataFailed({ message }));
        })
      ),
      this.globalSearchService.getAccounts$(action.searchQuery).pipe(
        map(data => globalSearchActions.loadSearchDataSuccess({
          key: GlobalSearchTypeEnum.Accounts,
          data
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.features.globalSearch.errors.loadAccounts';
          return of(globalSearchActions.loadSearchDataFailed({ message }));
        })
      ),
      this.globalSearchService.getOpportunities$(action.searchQuery).pipe(
        map(data => globalSearchActions.loadSearchDataSuccess({
          key: GlobalSearchTypeEnum.Opportunities,
          data
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.features.globalSearch.errors.loadOpportunities';
          return of(globalSearchActions.loadSearchDataFailed({ message }));
        })
      ),
      hasWebActivity
        ? this.globalSearchService.getChannels$(action.searchQuery).pipe(
            map(data => globalSearchActions.loadSearchDataSuccess({
              key: GlobalSearchTypeEnum.Channels,
              data
            })),
            catchError((error: HttpErrorResponse) => {
              const message = error.message || 'shared.features.globalSearch.errors.loadChannels';
              return of(globalSearchActions.loadSearchDataFailed({ message }));
            })
          )
        : NEVER
    )),
  ));

  public onLoadSearchDataFailed$ = createEffect(() => this.actions$.pipe(
    ofType(globalSearchActions.loadSearchDataFailed),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) }))
  ));
}
