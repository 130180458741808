<div class="title" data-cy="tile-dialog-dashboards-title">{{'shared.dashboards.dialog.tile.dashboard' | translate}}</div>

<ng-container *ngIf="items.length; else noData">
  <div class="count">
    {{items.length}} {{(items.length === 1 ? 'shared.dashboards.totalTitle' : 'shared.dashboards.totalTitlePlural') | translate}}
  </div>
  <div class="table-container">
    <table ts-table
           #table="tsTable"
           [columns]="columns"
           [dataSource]="dataSource">
      <ng-container *ngIf="columnsMap.get(columnKeys.RadioButton) as column"
                    [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell></th>
        <td ts-cell *tsCellDef="let row">
          <ts-radio-group [formControl]="control">
            <ts-radio-button [value]="row['id']" [isDisabled]="row['tileCount'] >= row['tileCountMax']">
              <span>_</span>
            </ts-radio-button>
          </ts-radio-group>
        </td>
      </ng-container>

      <ng-container *ngIf="columnsMap.get(columnKeys.Name) as column"
                    [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell>{{ column.displayName | translate }}</th>
        <td ts-cell *tsCellDef="let row">
          <span>{{ row[column.name] | typedData : column.dataType : column.decimal : false }}</span>
        </td>
      </ng-container>

      <ng-container *ngIf="columnsMap.get(columnKeys.Tiles) as column"
                    [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell>{{ column.displayName | translate }}</th>
        <td ts-cell *tsCellDef="let row" data-cy="dashboard-option-row">
          <ng-container *ngIf="row['tileCount'] >= row['tileCountMax']; else count">
            <ts-tooltip [tooltipValue]="'shared.dashboards.tooltip.limitModalTable' | translate"
                        tooltipPosition="above"
                        [hasUnderline]="false"
                        (click)="$event.stopPropagation()">
              <div class="tooltip-value">
                <ng-container *ngTemplateOutlet="count"></ng-container>
                <i class="fas fa-xs fa-info-circle"></i>
              </div>
            </ts-tooltip>
          </ng-container>

          <ng-template #count>
            <span [title]="row[column.name]" data-cy="dashboard-option-row-count">{{ row['tileCount'] }} / {{ row['tileCountMax'] }}</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container *ngIf="columnsMap.get(columnKeys.IsPublic) as column"
                    [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell>{{ column.displayName | translate }}</th>
        <td ts-cell *tsCellDef="let row">
          <span [title]="row[column.name]">
            {{ (row[column.name] ? 'shared.dashboards.types.public' : 'shared.dashboards.types.private') | translate }}
          </span>
        </td>
      </ng-container>

      <tr ts-header-row *tsHeaderRowDef="table.columnNames; sticky: true"></tr>
      <tr class="pointer"
          [attr.data-cy]="'tile-dialog-dashboard-' + row['id']"
          [ngClass]="{
              'active': control.value === row['id'],
              'disabled': row['tileCount'] >= row['tileCountMax']
            }"
          ts-row
          *tsRowDef="let row; columns: table.columnNames;"
          (click)="control.setValue(row['id'])">
      </tr>
    </table>
  </div>
</ng-container>

<ng-template #noData>
  <div class="no-data-container">
    <span>{{'shared.dashboards.dialog.tile.noDashboards.prefix' | translate}}</span>
    <a mat-dialog-close
       data-cy="tile-dialog-dashboard-no-data-link"
       [routerLink]="['dashboards']">{{'shared.dashboards.dialog.tile.noDashboards.link' | translate}}</a>
    <span>{{'shared.dashboards.dialog.tile.noDashboards.suffix' | translate}}</span>
  </div>
</ng-template>
