/* https://github.com/GetTerminus/jwt/wiki/Claims */

export interface HubClaims {
  'bf.pod': string; // Directs calls to specific pod
  'bf.acthub': boolean; // Bearer may use the account hub
  'bf.oadm': boolean; // User is an Org Admin
  'bf.badm': boolean; // User is a BF Admin
  'bf.gm': boolean; // User has access to god mode
  // If true, bearer may access all view routes, and is able to set
  // and list values as long as his/her sub.org claim matches
  // the org uuid in the request
  'bmb.mgr': boolean;
  'sub.org': string; // Organization UUID
  'ce.read_entitlements'?: boolean;
  sub: string;
  iss: string;
  exp: number;
  nbf: number;
  iat: number;
}

export enum HubClaimsKeys {
  OrgAdmin = 'bf.oadm',
  BFAdmin = 'bf.badm',
  Pod = 'bf.pod',
  AccountHub = 'bf.acthub',
  GodMode = 'bf.gm'
}

export type HubTokenNameType = 'Terminus Hub';
export const hubServiceName: HubTokenNameType = 'Terminus Hub';

export interface ClaimMap {
  'Terminus Hub': HubClaims;
}
