import { tagsActions } from './tags.actions';
import { ITagsResponse } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const tagsFeatureKey = 'tags';

export interface ITagsState {
  tags: string[];
  tagsByCampaign: ITagsResponse;
  selectedCampaignIds: string[];
  tagsByChannel: ITagsResponse;
  selectedChannelIds: string[];
}

export const initialState: ITagsState = {
  tags: [],
  tagsByCampaign: {},
  selectedCampaignIds: [],
  tagsByChannel: {},
  selectedChannelIds: [],
};

const tagsReducer = createReducer(
  initialState,
  on(tagsActions.loadTagsSuccess, (state, action) => ({
    ...state,
    tags: action.data,
  })),
  on(tagsActions.loadTagsByCampaignSuccess, (state, action) => ({
    ...state,
    tagsByCampaign: action.data,
  })),
  on(tagsActions.addCampaignTagSuccess, (state, action) => ({
    ...state,
    tagsByCampaign: {
      ...state.tagsByCampaign,
      ...action.data
    }
  })),
  on(tagsActions.removeCampaignTagSuccess, (state, action) => ({
    ...state,
    tagsByCampaign: {
      ...state.tagsByCampaign,
      ...action.data.objIds.reduce((acc, id) => {
        acc[id] = state.tagsByCampaign[id].filter(tag => tag.name !== action.data.name);
        return acc;
      }, {})
    }
  })),
  on(tagsActions.setCampaignSelectedRowIds, (state, action) => ({
    ...state,
    selectedCampaignIds: action.data
  })),
  on(tagsActions.loadTagsByChannelSuccess, (state, action) => ({
    ...state,
    tagsByChannel: action.data,
  })),
  on(tagsActions.addChannelTagSuccess, (state, action) => ({
    ...state,
    tagsByChannel: {
      ...state.tagsByChannel,
      ...action.data
    }
  })),
  on(tagsActions.removeChannelTagSuccess, (state, action) => ({
    ...state,
    tagsByChannel: {
      ...state.tagsByChannel,
      ...action.data.objIds.reduce((acc, id) => {
        acc[id] = state.tagsByChannel[id].filter(tag => tag.name !== action.data.name);
        return acc;
      }, {})
    }
  })),
  on(tagsActions.setChannelSelectedRowIds, (state, action) => ({
    ...state,
    selectedChannelIds: action.data
  })),
);

export function reducer(state: ITagsState | undefined, action: Action) {
  return tagsReducer(state, action);
}
