import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { TsTabChangeEvent } from '@terminus-lib/ui-tabs';
import { Subscription } from 'rxjs';

import { PortalTargets, RouteItemEnum, TileTypes } from '@shared/enums';
import * as UserSelectors from '@user/user.selectors';
import { scorecardActions } from '../state/scorecard.actions';
import * as ScorecardSelectors from '../state/scorecard.selectors';
import * as AggregateListsSelectors from '@shared/data-access/aggregate-lists';
import { aggregateListsActions } from '@shared/data-access/aggregate-lists';
import { TileModalService } from '@shared/features/tile-modal';
import { SCORECARD_OVERVIEW, SCORECARD_TRENDING_KPI } from '../scorecard.constant';
import { ScorecardState } from '../state/scorecard.reducer';

@Component({
  selector: 'ms-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss']
})
export class ScorecardComponent implements OnInit, OnDestroy {
  public readonly targets = PortalTargets;
  public hasTrendingReportAccess$ = this.store.pipe(select(UserSelectors.getHasReportAccessByName(RouteItemEnum.ScorecardTrendingKpi)));
  public hasAccountList$ = this.store.pipe(
    select(AggregateListsSelectors.getAggregateLists),
    map(list => !!list?.length)
  );
  public canAddList$ = this.store.pipe(select(ScorecardSelectors.getCanAddAccountList));
  public selectedTab = 0;

  private sub$: Subscription;

  constructor(
    private store: Store<ScorecardState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tileModalService: TileModalService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(aggregateListsActions.loadAggregateLists());
    this.store.dispatch(aggregateListsActions.loadAggregateSegments());
    this.store.dispatch(scorecardActions.loadCanAddListFlag());
    this.setSelectedTab();
    this.subscribeToRouteNavigation();
  }

  setSelectedTab(): void {
    if (this.router.url.includes(SCORECARD_TRENDING_KPI)) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 0;
    }
  }

  subscribeToRouteNavigation(): void {
    this.sub$ = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes(SCORECARD_TRENDING_KPI)) {
        this.selectedTab = 1;
      } else {
        this.selectedTab = 0;
      }
    });
  }

  handleSelectedTabChange(event: TsTabChangeEvent): void {
    // 1st tab is overview report, 2sd tab - is trending kpi
    this.selectedTab = event.index;
    const url = event.index === 0 ? SCORECARD_OVERVIEW : SCORECARD_TRENDING_KPI;
    this.router.navigate([url], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    });
  }

  goToScorecardList(): void {
    this.router.navigate(['list'], {relativeTo: this.activatedRoute});
  }

  addToDashboardDialog(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const ids = queryParams?.scorecardAggregateIds?.length
      ? queryParams.scorecardAggregateIds.split(',').map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
      : [];

    this.tileModalService.openAddToDashboardModal(TileTypes.ScorecardTrending, {
      ...queryParams,
      scorecardAggregateIds: ids
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
