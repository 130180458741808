/* eslint-disable max-len */
export const MARK_SOLID_LOGO = `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 1682 1798" style="enable-background:new 0 0 1682 1798;" xml:space="preserve">
    <title>Terminus Logo</title>
    <style type="text/css">
      .lms{fill:#FFFFFF;}
    </style>
    <path id="logo-mark-solid" class="lms" d="M774.3,955.44l303.94-104.58c12.84-4.71,26.45,4.79,26.45,18.47v884.01c0,10.86-8.81,19.67-19.67,19.67H781.08
      c-10.86,0-19.67-8.81-19.67-19.67V973.9C761.41,965.65,766.55,958.28,774.3,955.44z M94.9,1202.27v551.06
      c0,10.86,8.81,19.67,19.67,19.67h301c10.86,0,19.67-8.81,19.67-19.67v-652.81c0-13.33-12.99-22.8-25.68-18.73l-301,101.76
      C100.42,1186.15,94.9,1193.72,94.9,1202.27z M1093.83,127.01l25.66,124.12L107.44,601.25c-7.57,2.94-12.55,10.22-12.55,18.34v263.4
      c0,13.57,13.42,23.07,26.22,18.55L1190.5,532.75l23.24,101.43c3.53,15.39,22.83,20.56,33.58,9l380.34-409.1
      c10.47-11.26,4.55-29.64-10.52-32.68L1116.9,103.4C1102.93,100.59,1090.7,113.1,1093.83,127.01z"/>
  </svg>
`;
