import { createAction, props } from '@ngrx/store';

import { IUserProfile } from '@shared/interfaces';
import { defineTypeEnum } from '@terminus-lib/fe-utilities';

export enum UserCommonActionTypes {
  LOGIN_REDIRECT = '[User] Login Redirect',

  SET_USER_IS_AUTHENTICATED = '[User] Set User isAuthenticated',
  CLEAR_USER_IS_AUTHENTICATED = '[User] Clear User isAuthenticated',

  GET_USER_PROFILE = '[User] Get User Profile',
  GET_USER_PROFILE_SUCCESS = '[User] Get User Profile Success',
  GET_USER_PROFILE_FAILURE = '[User] Get User Profile Failure',

  REQUEST_USER_SPLITIO_FLAGS = '[User] Request User SplitIo flags',
}

defineTypeEnum(UserCommonActionTypes);

const loginRedirect = createAction(
  UserCommonActionTypes.LOGIN_REDIRECT
);

const setUserIsAuthenticated = createAction(
  UserCommonActionTypes.SET_USER_IS_AUTHENTICATED
);

const clearUserIsAuthenticated = createAction(
  UserCommonActionTypes.CLEAR_USER_IS_AUTHENTICATED
)

// GET USER PROFILE
const getUserProfile = createAction(
  UserCommonActionTypes.GET_USER_PROFILE
)

const getUserProfileSuccess = createAction(
  UserCommonActionTypes.GET_USER_PROFILE_SUCCESS,
  props<{ profile: IUserProfile }>()
);

const getUserProfileFailure = createAction(
  UserCommonActionTypes.GET_USER_PROFILE_FAILURE,
  props<{ message: string }>()
);

const requestUserSplitIoFlags = createAction(
  UserCommonActionTypes.REQUEST_USER_SPLITIO_FLAGS,
  props<{ jwtToken: string }>()
);

export const userCommonActions = {
  loginRedirect,
  setUserIsAuthenticated,
  clearUserIsAuthenticated,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  requestUserSplitIoFlags
};
