<div class="search-by-property-container" [ngClass]="{'search-by-property': searchByFieldEnabled}">
  <ts-input type="text"
            name="search"
            data-cy="search-by-property-input"
            (change)="searchQueryChange.emit($event.target.value)"
            (keyup.enter)="startSearch()"
            (cleared)="startSearch()"
            [isClearable]="isClearable"
            [isDisabled]="isDisabledInput"
            [isRequired]="false"
            [formControl]="queryControl"
            [placeholder]="searchLabel | translate"
            [isSmall]="true"
            data-cy="search-by-property-input">
  </ts-input>
  <mat-select *ngIf="searchByFieldEnabled"
              data-cy="search-by-property-select"
              [compareWith]="compareWith"
              [formControl]="searchField"
              [disabled]="isDisabledOptions">
    <mat-select-trigger>
      {{ searchField.value?.name | translate }}
    </mat-select-trigger>
    <mat-option *ngFor="let option of searchOptions" [value]="option">
      {{ option.name | translate }}
    </mat-option>
  </mat-select>
</div>
