<div class="filter-select-container">
    <ts-selection-list
        [isRequired]="false"
        [valueComparator]="compareDataFilterNames"
        [formControl]="selectedDataFilterForm.get('dataFilter')"
        (valueChange)="dataFilterChange.emit($event[0])"
        (queryChange)="onFilterDataFilter($event)"
        [displayFormatter]="formatDisplayFilter">
        <ts-option
            [value]="emptyFilter">
            {{ 'shared.globalFilters.dataFilterSelect.none' | translate }}
        </ts-option>
        <ts-select-optgroup
            [label]="'shared.globalFilters.dataFilterSelect.organization' | translate">
            <ts-option
                *ngFor="let orgFilter of organizationDataFiltersCopy"
                [value]="orgFilter">
                {{ orgFilter.name | translate }}
            </ts-option>
        </ts-select-optgroup>
        <ts-select-optgroup
            [label]="'shared.globalFilters.dataFilterSelect.personal' | translate">
            <ts-option
                *ngFor="let personalFilter of personalDataFiltersCopy"
                [value]="personalFilter">
                {{ personalFilter.name | translate }}
            </ts-option>
        </ts-select-optgroup>
    </ts-selection-list>

    <div
        *ngIf="savedDataFilterSelected"
        class="filter-select-btn-group">
        <ts-button
            [isDisabled]="!savedDataFilterChanged || displayDeleteConfirmation"
            (clicked)="updateDataFilter.emit(selectedDataFilter)"
            [isSmall]="true"
            theme="secondary">
            Save Filter
        </ts-button>
        <!-- Enabled -->
        <ts-button
            *ngIf="!displayDeleteConfirmation"
            (clicked)="handleDisplayConfirmation()"
            [isSmall]="true"
            theme="warning">
            {{ 'shared.globalFilters.dataFilterSelect.deleteFilter' | translate }}
        </ts-button>
    </div>
    <div
        *ngIf="displayDeleteConfirmation"
        class="confirmation-container">
        {{ 'shared.globalFilters.dataFilterSelect.delete' | translate }}

        <ts-button
            (clicked)="handleDeleteConfirmation(selectedDataFilter.name)"
            theme="warning">
            {{ 'shared.globalFilters.dataFilterSelect.yesDelete' | translate }}
        </ts-button>
        <ts-button
            (clicked)="handleDisplayConfirmation()"
            theme="warning">
            {{ 'shared.globalFilters.dataFilterSelect.noCancel' | translate }}
        </ts-button>
    </div>
</div>
