import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClientBase } from '@shared/http-client-base';
import { IHubspotCrmUrl, IOrgConfig, ISigstrResponse, IUpdateConfig } from '@shared/interfaces';
import { IOrgConfigSource } from './org-config.source.interface';

export enum OrgConfigSourceURLs {
  GetOrgConfig = '/api/v1/load_config',
  GetSigstrProductType = '/v1/load_sigstr_product_type',
  GetCrmUrl = '/organizationInfo/get_crm_url',
  GetOpportunityTypes = '/organizationInfo/loadOpptyTypes',
  GetOpportunityProductLines = '/organizationInfo/loadOpptyProducts',
  GetSpikeModels = '/organizationInfo/loadSpikeModels',
  GetIntentTopics = 'organizationInfo/loadIntentTopics',
  UpdateConfig = '/orgAdmin/update_config',
}

@Injectable({
  providedIn: 'root'
})
export class OrgConfigSource extends HttpClientBase implements IOrgConfigSource {
  getOrgConfig$(): Observable<IOrgConfig> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetOrgConfig);

    return this.httpClient.get<IOrgConfig>(endpoint);
  }

  updateConfig$(body: IUpdateConfig): Observable<IUpdateConfig> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.UpdateConfig);

    return this.httpClient.post<IUpdateConfig>(endpoint, body);
  }

  getSigstrProductType$(): Observable<ISigstrResponse> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetSigstrProductType);

    return this.httpClient.post<ISigstrResponse>(endpoint, {});
  }

  getCrmUrl$(): Observable<string | IHubspotCrmUrl> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetCrmUrl);

    return this.httpClient.get<{ crmUrl: string }>(endpoint).pipe(
      map((data: { crmUrl: string | IHubspotCrmUrl }) => data?.crmUrl)
    );
  }

  getOpportunityTypes$(): Observable<string[]> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetOpportunityTypes);
    return this.httpClient.get<string[]>(endpoint);
  }

  getOpportunityProductLines$(): Observable<string[]> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetOpportunityProductLines);
    return this.httpClient.get<string[]>(endpoint);
  }

  getSpikeModels$(): Observable<string[]> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetSpikeModels);
    return this.httpClient.get<string[]>(endpoint);
  }

  getIntentTopics$(): Observable<string[]> {
    const endpoint = this.endpoint(OrgConfigSourceURLs.GetIntentTopics);
    return this.httpClient.get<string[]>(endpoint);
  }
}
