import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { SavedReportsSource } from '../sources/saved-reports.source';
import { EnvService } from '@shared/environment';
import {
  ICreateSavedFolder,
  ICreateSavedReport,
  IDeleteSavedFolder,
  IDeleteSavedReport,
  ISavedFolder,
  IUpdateSavedFolder,
  IUpdateSavedReport
} from '@shared/interfaces';
import { hubTokenName } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class SavedReportsService {
  constructor(private source: SavedReportsSource,
              private retry: RetryWithEscalation,
              private envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getSavableReportsFolders$(): Observable<ISavedFolder[]> {
    return regenerateOnRetry(() => this.source.getSavableReportsFolders$())
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateSavableReportsFolder$(params: IUpdateSavedFolder): Observable<void> {
    return regenerateOnRetry(() => this.source.updateSavableReportsFolder$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  createSavableReportsFolder$(params: ICreateSavedFolder): Observable<void> {
    return regenerateOnRetry(() => this.source.createSavableReportsFolder$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteSavableReportsFolder$(params: IDeleteSavedFolder): Observable<void> {
    return regenerateOnRetry(() => this.source.deleteSavableReportsFolder$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  createSavableReport$(params: ICreateSavedReport): Observable<void> {
    return regenerateOnRetry(() => this.source.createSavableReport$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateSavableReport$(params: IUpdateSavedReport): Observable<void> {
    return regenerateOnRetry(() => this.source.updateSavableReport$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteSavableReport$(params: IDeleteSavedReport): Observable<void> {
    return regenerateOnRetry(() => this.source.deleteSavableReport$(params))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
