<div class="global-search-container">
  <div class="global-search-content">
    <!-- ng content for tab's header -->
    <ng-content></ng-content>

    <ts-input (input)="onSearchSubmit($event.target.value)"
              theme="default"
              [isSmall]="true"
              type="text"
              [placeholder]="'shared.globalFilters.container.searchLabel' | translate">
      <i class="fas fa-spinner"
        *ngIf="(isLoading$ | async) > 0"></i>
      <i class="fas fa-search"></i>
    </ts-input>

    <tsh-view-selector
      [viewData]="viewData$ | async"
      [options]="options$ | async"
      (changed)="onViewChanges($event)"
    ></tsh-view-selector>

    <tsh-search-list
      [data]="data$ | async"
      [isLoading]="isLoading$ | async"
      [searchQuery]="searchQuery$ | async"
      [viewData]="viewData$ | async"
      [routeData]="routeData$ | async"
    ></tsh-search-list>
  </div>
</div>
