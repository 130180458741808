import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IScorecardList } from '../../overview/interfaces/scorecard-list.interface';
import { IScorecardListSummary } from '../../overview/interfaces/scorecard-list-summary.interface';
import { IListDetailsRequestParams } from '../../account-list-details/interfaces/list-details-params.interface';
import { IScorecardSource } from './scorecard.source.interface';
import { IListDetailsResponse } from '../../account-list-details/interfaces/account-list-details.interface';
import { getHttpParams } from '@util/http';
import { IOverviewRequestParams } from '../../overview/interfaces/overview-filters.interface';
import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';

export enum ScorecardSourceURLs {
  GetAggregates = '/v1/get_scorecard_aggregates',
  GetCanCreateAggregate = '/accountScorecard/canCreateAggregate',
  GetListData = '/accountScorecard/loadTableData',
  DownloadListData = '/export/accountScorecard',
  GetListDetailsData = '/accountScorecard/loadListDetail',
  DownloadListDetailsData = '/export/accountListDetail',
  GetListSummaryData = '/accountScorecard/loadSummary',
}

@Injectable({
  providedIn: 'root'
})
export class ScorecardSource extends HttpClientBase implements IScorecardSource {

  getCanCreateAggregate$(): Observable<boolean> {
    const endpoint = this.endpoint(ScorecardSourceURLs.GetCanCreateAggregate);

    return this.httpClient.get<boolean>(endpoint);
  }

  getListData$(params: IOverviewRequestParams): Observable<IScorecardList[]> {
    const endpoint = this.endpoint(ScorecardSourceURLs.GetListData);
    // we do not need to send in view type for list table
    return this.httpClient.get<IScorecardList[]>(endpoint, {
      params: getHttpParams(params)
    });
  }

  getListSummaryData$(params: IOverviewRequestParams): Observable<IScorecardListSummary> {
    const endpoint = this.endpoint(ScorecardSourceURLs.GetListSummaryData);

    return this.httpClient.get<IScorecardListSummary>(endpoint, {
      params: getHttpParams(params)
    });
  }

  downloadListData$(params: IOverviewRequestParams): Observable<string> {
    const endpoint = this.endpoint(ScorecardSourceURLs.DownloadListData);
    // we need to send in view type for download (text/csv)
    return this.httpClient.get(endpoint, {
      params: getHttpParams({...params, ...params.exportParams}),
      responseType: 'text'
    });
  }

  getListDetailsData$(params: IListDetailsRequestParams, globalFilersParams: IAppliedGlobalFiltersAsParams): Observable<IListDetailsResponse> {
    const endpoint = this.endpoint(ScorecardSourceURLs.GetListDetailsData);
    // we need to send in ps and pg
    const urlParams = this.toListDetailsHttpParams({pg: params.pg, ps: params.ps}, params, globalFilersParams);

    return this.httpClient.get<IListDetailsResponse>(endpoint, {...urlParams});
  }

  downloadListDetailsData$(params: IListDetailsRequestParams, globalFilersParams: IAppliedGlobalFiltersAsParams): Observable<string> {
    const endpoint = this.endpoint(ScorecardSourceURLs.DownloadListDetailsData);
    // we do not need to send in ps and pg
    const urlParams = this.toListDetailsHttpParams({}, params, globalFilersParams);

    return this.httpClient.get(endpoint, {
      ...urlParams, responseType: 'text'
    });
  }

  private toListDetailsHttpParams(fromObject: {ps?: number, pg?: number}  = {},
                                  params: IListDetailsRequestParams,
                                  globalFilersParams: IAppliedGlobalFiltersAsParams): {params: HttpParams} {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    let newParams: any = {
      ...fromObject,
      aggregateId: params.aggregateId,
      listId: params.listId,
      opptyCohort: params.cohort,
      audience: params.audience || 'list',
      ...globalFilersParams
    };

    if (params.cohort === 'time' || params.cohort === 'custom') {
      newParams = {...newParams, opptyStartDate: params.startDate, opptyEndDate: params.endDate};
    }

    if (params.dir) {
      newParams = {...newParams, fld: params.fld, dir: params.dir};
    }

    if (params.exportParams) {
      newParams = {...newParams, ...params.exportParams};
    }

    return {params: new HttpParams({fromObject: newParams})};
  }

}
