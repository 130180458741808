import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import * as interfaces from '../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export enum CampaignAnalyticsURLs {
  TabTotals = '/v1/campaign_analytics/tab/totals',
  WebActivitiesTabTotals = '/v1/web_activities/tab/totals',
  MetaData = '/v1/campaign_analytics/campaign/meta_data',
  Responses = '/v1/campaign_analytics/campaign/responses',
  Attribution = '/v1/campaign_analytics/campaign/attribution',
  Influence = '/v1/campaign_analytics/campaign/influence',
  Returns = '/v1/campaign_analytics/campaign/returns',
  Costs = '/v1/campaign_analytics/campaign/costs',
  TypeMetaData = '/v1/campaign_analytics/campaign_type/meta_data',
  TypeResponses = '/v1/campaign_analytics/campaign_type/responses',
  TypeAttribution = '/v1/campaign_analytics/campaign_type/attribution',
  TypeInfluence = '/v1/campaign_analytics/campaign_type/influence',
  TypeReturns = '/v1/campaign_analytics/campaign_type/returns',
  TypeCosts = '/v1/campaign_analytics/campaign_type/costs',
  HasWebActivity = '/v1/campaign_analytics/campaign/has_web_activity',
  TypeHasWebActivity = '/v1/campaign_analytics/campaign_type/has_web_activity',
  Export = '/v1/campaign_analytics/export',
}

@Injectable({providedIn: 'root'})
export class CampaignAnalyticsSource extends HttpClientBase {

  getCampaignAnalyticsTabTotals$(body: IAnalyticsRequest): Observable<interfaces.ITabTotals> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TabTotals);

    return this.httpClient.post<interfaces.ITabTotals>(endpoint, body);
  }


  getWebActivitiesTabTotals$(body: IAnalyticsRequest): Observable<interfaces.ITabTotals<interfaces.IWebActivitiesTabTotalsResponse>> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.WebActivitiesTabTotals);

    return this.httpClient.post<interfaces.ITabTotals<interfaces.IWebActivitiesTabTotalsResponse>>(endpoint, body);
  }

  getCampaignAnalyticsMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.ICampaignsResponse>> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.MetaData);

    return this.httpClient.post<interfaces.IMetaData<interfaces.ICampaignsResponse>>(endpoint, body);
  }

  getCampaignAnalyticsResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Responses);

    return this.httpClient.post<interfaces.ICampaignResponses>(endpoint, body);
  }

  getCampaignAnalyticsAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Attribution);

    return this.httpClient.post<interfaces.ICampaignAttribution>(endpoint, body);
  }

  getCampaignAnalyticsInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Influence);

    return this.httpClient.post<interfaces.ICampaignInfluence>(endpoint, body);
  }

  getCampaignAnalyticsReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Returns);

    return this.httpClient.post<interfaces.ICampaignReturns>(endpoint, body);
  }

  getCampaignAnalyticsCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Costs);

    return this.httpClient.post<interfaces.ICampaignCosts>(endpoint, body);
  }

  getCampaignAnalyticsTypeMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.ICampaignTypeResponse>> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeMetaData);

    return this.httpClient.post<interfaces.IMetaData<interfaces.ICampaignTypeResponse>>(endpoint, body);
  }

  getCampaignAnalyticsTypeResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeResponses);

    return this.httpClient.post<interfaces.ICampaignResponses>(endpoint, body);
  }

  getCampaignAnalyticsTypeAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeAttribution);

    return this.httpClient.post<interfaces.ICampaignAttribution>(endpoint, body);
  }

  getCampaignAnalyticsTypeInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeInfluence);

    return this.httpClient.post<interfaces.ICampaignInfluence>(endpoint, body);
  }

  getCampaignAnalyticsTypeReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeReturns);

    return this.httpClient.post<interfaces.ICampaignReturns>(endpoint, body);
  }

  getCampaignAnalyticsTypeCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeCosts);

    return this.httpClient.post<interfaces.ICampaignCosts>(endpoint, body);
  }

  getCampaignAnalyticsHasWebActivity$(body: IAnalyticsRequest): Observable<interfaces.ICampaignHasWebActivity> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.HasWebActivity);

    return this.httpClient.post<interfaces.ICampaignHasWebActivity>(endpoint, body);
  }

  getCampaignAnalyticsTypeHasWebActivity$(body: IAnalyticsRequest): Observable<interfaces.ICampaignHasWebActivity> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.TypeHasWebActivity);

    return this.httpClient.post<interfaces.ICampaignHasWebActivity>(endpoint, body);
  }

  getCampaignAnalyticsCSV$(body: interfaces.IAnalyticsCSVRequest): Observable<string> {
    const endpoint = this.endpoint(CampaignAnalyticsURLs.Export);

    return this.httpClient.post<string>(endpoint, body, {
      responseType: 'text' as never // use this option to prevent parse error
    });
  }
}
