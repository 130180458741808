import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttachToDirective } from './attach-to.directive';
import { AddTargetDirective } from './add-target.directive';
import { PortalService } from './portal.service';

const directives = [
  AttachToDirective,
  AddTargetDirective,
];

@NgModule({
  declarations: [directives],
  exports: [directives],
  imports: [
    CommonModule,
  ]
})
export class PortalDirectiveModule {
  static forRoot(): ModuleWithProviders<PortalDirectiveModule> {
    return {
      ngModule: PortalDirectiveModule,
      providers: [PortalService]
    };
  }
}
