import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as CampaignsReducer from './campaigns.reducer';
import { toCapitalize } from '@util/helpers';

export const selectCampaignsState = createFeatureSelector<CampaignsReducer.State>(CampaignsReducer.campaignsFeatureKey);

export const getCampaignTypes = createSelector(
  selectCampaignsState,
  (state: CampaignsReducer.State) => state.types || [],
);
export const getCampaignTypesOptions = createSelector(
  getCampaignTypes,
  (groups: string[] | null) => {
    if (!groups) {
      return [];
    }

    return groups.map(group => ({
      label: toCapitalize(group),
      value: group,
    }));
  }
);

export const getCampaignsIsLoading = createSelector(
  selectCampaignsState,
  (state: CampaignsReducer.State) => state.isLoading
);

export const getCampaignsTypesColors = createSelector(
  selectCampaignsState,
  (state: CampaignsReducer.State) => state.colorMap
);
