import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActionType, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  DefaultTileInformation,
  ICreateDashboardTileRequest,
  IDashboardTile,
  IDataFilter,
  IFilter,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  ITargetFolderItem,
  ITileConfig,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  userMessageFactory
} from '@shared/interfaces';
import { GlobalFiltersKeys, NotificationTypes, TileTypes } from '@shared/enums';
import * as selectors from './tile-modal.selectors';
import { tileModalActions } from './tile-modal.actions';
import * as globalFiltersHelpers from '@util/helpers';
import { DashboardsService } from '@shared/data-access/dashboards';
import { notificationMessagesActions } from '@notification-messages';
import { IFolderItemsSelector, ITileModalData } from '../tile-modal.interface';
import { tileModalDataToken } from '../tile-modal.token';

@Injectable()
export class TileModalEffects {

  constructor(private dashboardsService: DashboardsService,
              @Inject(tileModalDataToken) private tileModalDataService: ITileModalData,
              private translateService: TranslateService,
              private actions$: Actions,
              private store: Store<unknown>) {
  }

  public getTilesInfo$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.getTilesInformation),
    concatLatestFrom(() => this.store.pipe(select(selectors.getTilesInfo))),
    filter(([_, tiles]: [null, Record<TileTypes, DefaultTileInformation> | null]) => !tiles),
    // assemble data only if there is no data in the store
    mergeMap(() => this.tileModalDataService.getAvailableTiles$().pipe(
      map((tiles: Record<TileTypes, DefaultTileInformation>) => tileModalActions.setTilesInformation({data: tiles}))
    ))
  ));

  public getTilePreviewData$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.getTilePreviewData),
    pluck('data'),
    concatLatestFrom(() => this.store.pipe(select(selectors.getPreviewData))),
    filter(([_, previewData]: [ITileConfig, ITileData | ITileGaugeData | null]) => !previewData),
    map(([tile]) => tileModalActions.loadTilePreviewData({data: tile}))
  ));

  public loadTilePreviewData$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.loadTilePreviewData),
    pluck('data'),
    concatLatestFrom(() => this.store.pipe(select(selectors.getSelectedGlobalFiltersAsParams))),
    mergeMap(([tile, globalFilters]: [ITileConfig, Record<string, string[] | string>]) => {
      const preparedTile: IDashboardTile = {
        ...tile,
        settings: {
          ...tile.settings,
          ...globalFilters // add global filters or empty object
        },
        id: tile.id || null, // NOTE: there are no id, order and size in tile that's why I added missing properties
        size: null,
        order: null
      };
      return this.tileModalDataService.getTileData$(preparedTile).pipe(
        mergeMap((data: ITileData | ITileGaugeData) => [
          tileModalActions.loadTilePreviewDataSuccess({data}),
          tileModalActions.getVisualizationConfig({data: preparedTile})
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.dashboards.errors.loadTileData';
          return of(tileModalActions.loadTilePreviewDataFailure({message}));
        })
      );
    })
  ));

  public getVisualizationConfig$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.getVisualizationConfig),
    pluck('data'),
    mergeMap((tile: IDashboardTile) =>
      this.tileModalDataService.getTileVisualizationConfig$(tile).pipe(
        map((config: ITileVisualizationConfig) => tileModalActions.setVisualizationConfig({data: config})),
      ))
  ));

  public getSettingsFilters$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.getSettingsControls),
    pluck('data'),
    switchMap((type: TileTypes) => this.tileModalDataService.getTileSettingsFilters$(type).pipe(
      map((filters: ITileSettingControl[]) => tileModalActions.setSettingsControls({data: filters}))
    )),
  ));

  public getGlobalFiltersFromSettings$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.getGlobalFiltersFromSettings),
    pluck('data'),
    map((settings: ITileSettings) => globalFiltersHelpers.getAllGlobalFiltersFromParams(settings)),
    // set global filters only if there were filters in settings
    filter((globalFilters: { filters: IFilter, listIds: string[] }) =>
      (globalFilters.filters && !!Object.keys(globalFilters.filters).length)
      || (globalFilters.listIds && !!globalFilters.listIds.length)),
    map(globalFilters => tileModalActions.setGlobalFiltersFromSettings(globalFilters))
  ));

  public toggleFolderSelect$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.toggleGlobalFilterFolderSelect),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getSelectedGlobalFilters)),
      this.store.pipe(select(selectors.getGlobalFilterMenuBase)),
      this.store.pipe(select(selectors.getAccountListMenuBase))
    ]),
    map(([action, selectedGlobalFilters, filtersState, listsState]: [
      IFolderItemsSelector, IDataFilter, IGlobalFilterFolder, IGlobalFilterFolderItem
    ]) => {
      let updatedFiltersMenu: IGlobalFilterFolder;
      let updatedAccountListMenu: IGlobalFilterFolderItem;
      const data = action.folders.reduce((acc: IDataFilter, folder: ITargetFolderItem) => {
        const {folderName, folderItem} = folder;

        if (folderName !== GlobalFiltersKeys.AccountList) {
          const list = (updatedFiltersMenu && updatedFiltersMenu[folderName]) || filtersState[folderName];
          const folderItems = Object.keys(list.itemSelect[folderItem].itemSelect);
          const firstItem = folderItems && folderItems.length > 0 ? folderItems[0] : null;
          // use updated filters state or state from the store if it's 1 filter
          // in the array or the first filter from the array
          const updatedFolder = firstItem && !action.selectAll ?
            globalFiltersHelpers.selectTargetItemInFolder(list, folderItem, firstItem) :
            globalFiltersHelpers.selectAllItemsInFolder(list, folderItem);
          updatedFiltersMenu = {
            ...(updatedFiltersMenu || filtersState),
            [folderName]: {
              ...updatedFolder
            }
          };
          const updatedDataFilter = globalFiltersHelpers.mapFilterMenuToDataFilter(updatedFiltersMenu);
          return {
            ...acc,
            filters: updatedDataFilter,
          };
        } else {
          const list = updatedAccountListMenu || listsState;
          const folderItems = Object.keys(list.itemSelect[folderItem].itemSelect);
          const firstItem = folderItems && folderItems.length > 0 ? folderItems[0] : null;
          // use updated list ids state or state from the store
          updatedAccountListMenu = firstItem && !action.selectAll ?
            globalFiltersHelpers.selectTargetItemInFolder(list, folderItem, firstItem) :
            globalFiltersHelpers.selectAllItemsInFolder(list, folderItem);
          const updatedListIds = globalFiltersHelpers.mapAccountListMenuToListIds(updatedAccountListMenu);
          return {
            ...acc,
            listIds: updatedListIds,
          };
        }
      }, {
        ...selectedGlobalFilters,
        name: null, // In case if there is information about saved filters then we have to reset it
        isMine: true, // on each changing of global filters
        ownerId: 0
      });

      return tileModalActions.updateSelectedGlobalFilters({data});
    })
  ));

  public toggleFolderItemSelect$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.toggleGlobalFilterFolderItemSelect),
    pluck('data'),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getSelectedGlobalFilters)),
      this.store.pipe(select(selectors.getGlobalFilterMenuBase)),
      this.store.pipe(select(selectors.getAccountListMenuBase)),
    ]),
    map(([selectedFolderItem, selectedGlobalFilters, filtersState, listsState]: [
      ITargetFolderItem, IDataFilter, IGlobalFilterFolder, IGlobalFilterFolderItem
    ]) => {
      const {folderName, folderItem, folderSelectKey} = selectedFolderItem;
      if (folderName !== GlobalFiltersKeys.AccountList) {
        const updatedState = {
          ...filtersState,
          [folderName]: {
            ...globalFiltersHelpers.selectTargetItemInFolder(filtersState[folderName], folderItem, folderSelectKey),
          }
        };
        const updatedDataFilter = globalFiltersHelpers.mapFilterMenuToDataFilter(updatedState);
        return tileModalActions.updateSelectedGlobalFilters({
          data: {
            ...selectedGlobalFilters,
            filters: updatedDataFilter,
            name: null, // In case if there is information about saved filters then we have to reset it
            isMine: true, // on each changing of global filters
            ownerId: 0
          }
        });
      }

      const updatedAccountListMenu = globalFiltersHelpers.selectTargetItemInFolder(listsState, folderItem, folderSelectKey);
      const updatedListIds = globalFiltersHelpers.mapAccountListMenuToListIds(updatedAccountListMenu);
      return tileModalActions.updateSelectedGlobalFilters({
        data: {
          ...selectedGlobalFilters,
          listIds: updatedListIds,
          name: null, // In case if there is information about saved filters then we have to reset it
          isMine: true, // on each changing of global filters
          ownerId: 0
        }
      });
    })
  ));

  public removeFolder$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.removeGlobalFilterFolder),
    pluck('data'),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getSelectedGlobalFilters)),
      this.store.pipe(select(selectors.getAccountListMenuBase))
    ]),
    map(([folder, selectedGlobalFilters, listsState]: [
      ITargetFolderItem, IDataFilter, IGlobalFilterFolderItem
    ]) => {
      const {folderName, folderItem} = folder;
      if (folderName !== GlobalFiltersKeys.AccountList) {
        return tileModalActions.updateSelectedGlobalFilters({
          data: {
            ...selectedGlobalFilters,
            filters: {
              ...selectedGlobalFilters.filters,
              [folderName]: {
                ...selectedGlobalFilters.filters[folderName],
                [folderItem]: [] // reset selected filter
              }
            },
            name: null, // In case if there is information about saved filters then we have to reset it
            isMine: true, // on each changing of global filters
            ownerId: 0
          }
        });
      }

      const idsToRemove = Object.keys(listsState.itemSelect[folderItem].itemSelect);
      return tileModalActions.updateSelectedGlobalFilters({
        data: {
          ...selectedGlobalFilters,
          // remove all ids from selected folder
          listIds: selectedGlobalFilters.listIds.filter(id => !idsToRemove.includes(id)),
          name: null, // In case if there is information about saved filters then we have to reset it
          isMine: true, // on each changing of global filters
          ownerId: 0
        }
      });
    })
  ));

  public saveNewTile$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.saveNewTile),
    pluck('request'),
    switchMap((tile: ICreateDashboardTileRequest) =>
      this.dashboardsService.createTile$(tile).pipe(
        map((data: IDashboardTile) => tileModalActions.saveNewTileSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.dashboards.errors.createTile';
          return of(tileModalActions.saveNewTileFailure({message}));
        })
      )
    ))
  );

  public onSaveFailure$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.saveNewTileFailure),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public onSaveSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(tileModalActions.saveNewTileSuccess),
    switchMap(this.getNotificationMessage.bind(this)),
    map((message: string) => notificationMessagesActions.addMessage({ message: {
      message,
      type: NotificationTypes.Success,
    }}))
  ));

  private getNotificationMessage(action: ActionType<typeof tileModalActions.saveNewTileSuccess>): Observable<string> {
    const name = action.data.name;
    return this.translateService.get('shared.dashboards.notifications.createdTile', {name});
  }
}
