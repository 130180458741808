<div
  class="ts-sort-header-container"
  [class.ts-sort-header-position-before]="arrowPosition == 'before'"
>
  <button
    class="ts-sort-header-button"
    type="button"
    [attr.aria-label]="_intl.sortButtonLabel(id)"
    [attr.disabled]="_isDisabled() || null"
    (click)="_handleClick()"
  >
    <ng-content></ng-content>

    <span
      class="ts-sort-header-container__icon"
      [class.ts-sort-header-container__icon--hidden]="_isSorted()"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="arrows-v"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 512"
      >
        <path
          fill="currentColor"
          d="M160 406.186V105.815l43.728 46.208c9.289 9.815 24.846 10.03 34.402.474l10.84-10.84c9.373-9.373 9.373-24.569 0-33.941L150.627 9.373c-12.497-12.497-32.758-12.497-45.255 0L7.029 107.715c-9.373 9.373-9.373 24.569 0 33.941l10.84 10.84c9.556 9.556 25.113 9.341 34.402-.474L96 105.815v300.371l-43.728-46.208c-9.289-9.815-24.846-10.03-34.402-.474l-10.84 10.84c-9.373 9.373-9.373 24.569 0 33.941l98.343 98.343c12.497 12.497 32.758 12.497 45.255 0l98.343-98.343c9.373-9.373 9.373-24.569 0-33.941l-10.84-10.84c-9.556-9.556-25.113-9.341-34.402.474L160 406.186z"
        ></path>
      </svg>
    </span>

    <span
      class="ts-sort-header-arrow"
      *ngIf="_isSorted()"
      [@indicatorToggle]="_sort.direction"
    >
      <span class="ts-sort-header-stem"></span>

      <span class="ts-sort-header-indicator" [@indicator]="_sort.direction" >
        <span class="ts-sort-header-pointer-left" [@leftPointer]="_sort.direction"></span>
        <span class="ts-sort-header-pointer-right" [@rightPointer]="_sort.direction"></span>
        <span class="ts-sort-header-pointer-middle"></span>
      </span>
    </span>
  </button>
</div>

<span class="cdk-visually-hidden" *ngIf="_isSorted()">
  &nbsp;{{ _intl.sortDescriptionLabel(id, _sort.direction) }}
</span>
