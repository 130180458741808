import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CampaignAnalyticsService } from '../../services/campaign-analytics.service';
import * as interfaces from '../../interfaces';
import { campaignGroupsActions, CampaignGroupsLoadActions, CampaignGroupsLoadSuccessActions } from './campaign-groups.actions';
import * as selectors from './campaign-groups.selectors';
import * as generalSelectors from '../general/general.selectors';
import { generalActions } from '../general/general.actions';
import * as analyticsSelectors from '../selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import { AnalyticsHelperService } from '../../helpers/analytics-helper.service';
import { IAnalyticsGlobalFilters, IPager, ISorter, userMessageFactory } from '@shared/interfaces';
import { checkDataByColumnName, getDriverRequestParams, getSecondaryRequestParams } from '../../helpers/store.helper';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { TypedAction } from '@ngrx/store/src/models';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  ISorter,
  IPager,
  string[],
  string,
];

type LoadRequestParams<A extends Action> = [
  A,
  IAnalyticsRequest,
  (TypedAction<string>)[]?,
];

@Injectable()
export class CampaignGroupsEffects {
  constructor(public campaignAnalyticsService: CampaignAnalyticsService,
              private analyticsHelperService: AnalyticsHelperService,
              private store: Store<unknown>,
              private actions$: Actions) {
  }

  public hasWebActivity$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.hasWebActivity),
    // use concatMap to wait until dispatching this action
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(selectors.getCampaignGroupsRecordIds)),
      ),
    )),
    mergeMap(([action, filters, recordIds]) => {
      const requestParams = getSecondaryRequestParams(filters, recordIds);
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeHasWebActivity$(requestParams).pipe(
        map((data: interfaces.ICampaignHasWebActivity) => campaignGroupsActions.hasWebActivitySuccess({...action, data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.hasWebActivity';
          return of(campaignGroupsActions.hasWebActivityFailure({message}));
        })
      );
    })
  ));

  public loadTypeMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeMetaData),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeMetaData$(requestParams).pipe(
        mergeMap((data: interfaces.IMetaData<interfaces.ICampaignTypeResponse>) => [
          campaignGroupsActions.loadTypeMetaDataSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeMetaData';
          return of(campaignGroupsActions.loadTypeMetaDataFailure({message}));
        })
      );
    })
  ));

  public loadTypeResponses$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeResponses),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeResponses$(requestParams)
        .pipe(
          mergeMap((data: interfaces.ICampaignResponses) => [
            campaignGroupsActions.loadTypeResponsesSuccess({...action, data}),
            ...usedParamsAction
          ]),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeResponses';
            return of(campaignGroupsActions.loadTypeResponsesFailure({message}));
          })
        );
    })
  ));

  public loadTypeAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeAttribution),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeAttribution$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignAttribution) => [
          campaignGroupsActions.loadTypeAttributionSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeAttribution';
          return of(campaignGroupsActions.loadTypeAttributionFailure({message}));
        })
      );
    })
  ));

  public loadTypeInfluence$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeInfluence),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeInfluence$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignInfluence) => [
          campaignGroupsActions.loadTypeInfluenceSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeInfluence';
          return of(campaignGroupsActions.loadTypeInfluenceFailure({message}));
        })
      );
    })
  ));

  public loadTypeReturns$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeReturns),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeReturns$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignReturns) => [
          campaignGroupsActions.loadTypeReturnsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeReturns';
          return of(campaignGroupsActions.loadTypeReturnsFailure({message}));
        })
      );
    })
  ));

  public loadTypeCosts$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.loadTypeCosts),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignGroupsLoadActions>) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTypeCosts$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignCosts) => [
          campaignGroupsActions.loadTypeCostsSuccess({...action, data}),
          ...usedParamsAction
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTypeCosts';
          return of(campaignGroupsActions.loadTypeCostsFailure({message}));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignGroupsActions.hasWebActivityFailure,
      campaignGroupsActions.loadTypeMetaDataFailure,
      campaignGroupsActions.loadTypeResponsesFailure,
      campaignGroupsActions.loadTypeAttributionFailure,
      campaignGroupsActions.loadTypeInfluenceFailure,
      campaignGroupsActions.loadTypeReturnsFailure,
      campaignGroupsActions.loadTypeCostsFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public getCampaignGroups$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.getCampaignGroups),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    concatMap(([_, filters, globalFilters, sorter, pager, recordIds, search]) => {
      const requestParams = this.getRequestParams({isDriver: true}, filters, sorter, pager, search, recordIds, globalFilters);
      return of(requestParams).pipe(withLatestFrom(this.store.pipe(select(selectors.getCampaignGroupsUsedParams))));
    }),
    filter(([currentFilters, prevFilters]: IAnalyticsRequest[]) => {
      return this.analyticsHelperService.areFiltersChanged(currentFilters, prevFilters);
    }),
    map(([currentFilters]: IAnalyticsRequest[]) =>
      this.analyticsHelperService.getCampaignGroupsLoadAction(currentFilters.sorter.sortField, {isDriver: true})
    )
  ));

  public onChangeCampaignGroupsConfig$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.changeCampaignGroupsPagination,
      generalActions.changeCampaignGroupsSorting,
      generalActions.changeCampaignGroupsSearch
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getCampaignGroupsSorter)),
    ))),
    mergeMap(([_, sorter]) => [
      this.analyticsHelperService.getCampaignGroupsLoadAction(sorter.sortField, {isDriver: true}),
      campaignGroupsActions.closeNestedTables()
    ])
  ));

  public onLoadTypeDriverSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignGroupsActions.loadTypeAttributionSuccess,
      campaignGroupsActions.loadTypeCostsSuccess,
      campaignGroupsActions.loadTypeInfluenceSuccess,
      campaignGroupsActions.loadTypeMetaDataSuccess,
      campaignGroupsActions.loadTypeResponsesSuccess,
      campaignGroupsActions.loadTypeReturnsSuccess
    ),
    filter((action) => action.isDriver && !!action.data.totalResults),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getVisibleCampaignGroupsColumnNames)),
    ))),
    mergeMap(([action, columnNames]) => {
      const secondaryRequests = this.analyticsHelperService.getCampaignGroupsSecondaryLoadActions(action, columnNames);
      // return secondaryRequests if they exists, otherwise make totals requests
      return secondaryRequests.length
        ? secondaryRequests
        : this.analyticsHelperService.getTotalsLoadActions(columnNames, action.parentId || 'campaignGroups');
    }),
  ));

  public onLoadTypeSecondarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignGroupsActions.hasWebActivitySuccess,
      campaignGroupsActions.loadTypeAttributionSuccess,
      campaignGroupsActions.loadTypeCostsSuccess,
      campaignGroupsActions.loadTypeInfluenceSuccess,
      campaignGroupsActions.loadTypeMetaDataSuccess,
      campaignGroupsActions.loadTypeResponsesSuccess,
      campaignGroupsActions.loadTypeReturnsSuccess
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(
        analyticsSelectors.getUnloadedTotalsColumnNames(generalSelectors.getVisibleCampaignGroupsColumns), {key: 'campaignGroups'})
      ),
      this.store.pipe(select(selectors.getCampaignGroupsIsLoading)),
    ))),
    filter(([action, _, isLoading]) =>
      !action.isDriver && !isLoading),
    switchMap(([action, columnNames]) =>
      this.analyticsHelperService.getTotalsLoadActions(columnNames, action.parentId || 'campaignGroups')
    ),
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignGroupsColumnVisibility),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(
        select(selectors.getReportData),
        map((data: interfaces.ICampaignIdentifiable[]) => checkDataByColumnName(data, action.data.name))
      ),
    ))),
    // do not dispatch load action if data is present in state
    filter(([_, data]) => !data.length),
    // dispatch actions to load extra data
    map(([action]) =>
      this.analyticsHelperService.getCampaignGroupsLoadAction(action.data.name, {isDriver: false})
    )
  ));

  public onChangeFiltersState$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.setFilters),
    map(() => campaignGroupsActions.closeNestedTables()),
  ));

  private withLatestFilters<T extends Action>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(generalSelectors.getCampaignGroupsSorter)),
        this.store.pipe(select(generalSelectors.getCampaignGroupsPager)),
        this.store.pipe(select(selectors.getCampaignGroupsRecordIds)),
        this.store.pipe(select(generalSelectors.getCampaignGroupsSearch)),
      ),
    );
  }

  private mapToRequestAndUsedParamsAction<T extends CampaignGroupsLoadActions>(
    [action, filters, globalFilters, sorter, pager, recordIds, searchParam]: LatestFilters<T>
  ): LoadRequestParams<T> {
    const requestParams = this.getRequestParams(action, filters, sorter, pager, searchParam, recordIds, globalFilters);
    const usedParamsAction = action.isDriver ? [campaignGroupsActions.setUsedParams({request: requestParams})] : [];
    return [action, requestParams, usedParamsAction];
  }

  private getRequestParams(payload: interfaces.ILoadPayload,
                           filters: IAnalyticsRequest,
                           sorter: ISorter,
                           pager: IPager,
                           searchParam: string,
                           recordIds: string[],
                           globalFilters: IAnalyticsGlobalFilters): IAnalyticsRequest {
    const requestParams = payload.isDriver
      ? getDriverRequestParams(filters, sorter, pager, searchParam)
      : getSecondaryRequestParams(filters, recordIds);
    if (payload.parentId) {
      requestParams.crossTypeFilter = payload.parentId;
    }
    requestParams.globalFilters = globalFilters || [];
    // typeFilter is needed only for campaigns tab
    // remove it from the object to avoid redundant requests during
    // changing tabs with gf query param in url
    delete requestParams.typeFilter;
    return requestParams;
  }
}
