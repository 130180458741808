import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';
import { IAnalyticsRequest, ITagRequest, ITagsRequest, ITagsResponse } from '@measurement-studio/interfaces';
import { TagsSource } from '../source/tags.source';

@Injectable({providedIn: 'root'})
export class TagsService {
  constructor(public source: TagsSource,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getTags$(params: ITagsRequest): Observable<{ name: string }[]> {
    return regenerateOnRetry(() => this.source.getTags$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  postTags$(body: ITagRequest): Observable<ITagsResponse> {
    return regenerateOnRetry(() => this.source.postTags$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteTags$(body: ITagRequest): Observable<string[]> {
    return regenerateOnRetry(() => this.source.deleteTags$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTagsByCampaign$(request: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams): Observable<ITagsResponse> {
    return regenerateOnRetry(() => this.source.getTagsByCampaign$(request, globalFilters))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  postChannelTags$(body: ITagRequest): Observable<ITagsResponse> {
    return regenerateOnRetry(() => this.source.postChannelTags$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteChannelTags$(body: ITagRequest): Observable<string[]> {
    return regenerateOnRetry(() => this.source.deleteChannelTags$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTagsByChannel$(request: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams): Observable<ITagsResponse> {
    return regenerateOnRetry(() => this.source.getTagsByChannel$(request, globalFilters))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
