import { animate, AnimationStyleMetadata, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

const shiftWithFadeOutStates: AnimationStyleMetadata[] = [
    style({opacity: 1, width: '*', offset: 0}),
    style({opacity: 0, transform: 'translate3d(-60%, 0, 0)', offset: .6}),
    style({height: '0', offset: 1}),
];

export const shiftWithFadeInStates: AnimationStyleMetadata[] = [
    style({opacity: 0, width: 0, offset: 0}),
    style({opacity: 0, width: '*', offset: .4}),
    style({opacity: 1, offset: 1}),
];

export function shiftWithFadeOut(timing: number | string = 300): AnimationTriggerMetadata {
    return trigger('shiftWithFadeOut', [
        transition(`:leave`, animate(timing, keyframes(shiftWithFadeOutStates))),
    ]);
}

export function shiftWithFadeIn(timing: number | string = 300): AnimationTriggerMetadata {
    return trigger('shiftWithFadeIn', [
        transition(`:enter`, animate(timing, keyframes(shiftWithFadeInStates))),
    ]);
}
