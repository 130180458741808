import { Action, createReducer, on } from '@ngrx/store';

import { globalFiltersActions } from './global-filters.actions';

import { GlobalFiltersKeys } from '@shared/enums';
import { EMPTY_GLOBAL_FILTERS_STATE } from '@shared/constants';
import { IDataFilter, IGlobalFilters, ITargetFolderItem } from '@shared/interfaces';
import { UserDefinedSegment } from '@shared/data-access/segments-api';

export const globalFiltersFeatureKey = 'globalFiltersState';

export interface GlobalFiltersState {
  appliedDataFilter: IDataFilter;
  globalFilters: IGlobalFilters;
  filterChanged: boolean;
  filterMenuLoading: boolean;
  initialDataLoaded: boolean;
  isLoading: boolean;
  savedDataFilters: IDataFilter[];
  selectedDataFilter: IDataFilter;
  searchQuery: string;
  targetFolder: {
    folderItem: ITargetFolderItem;
    isOpen: boolean;
  };
  isSaving: boolean;
  isDeleting: boolean;
  isApplying: boolean;
  segments: UserDefinedSegment[];
}

export const defaultFolderItem: ITargetFolderItem = {
  folderName: '' as GlobalFiltersKeys,
  folderItem: '',
  folderSelectKey: '',
};

export const initialState: GlobalFiltersState = {
  appliedDataFilter: {
    ...EMPTY_GLOBAL_FILTERS_STATE,
  },
  filterChanged: false,
  filterMenuLoading: false,
  globalFilters: {
    opportunity: [],
    campaignMember: [],
    lead: [],
    account: [],
    terminusAccount: [],
    campaign: [],
    segments: [],
  },
  initialDataLoaded: false,
  isLoading: true,
  savedDataFilters: [],
  selectedDataFilter: {
    ...EMPTY_GLOBAL_FILTERS_STATE,
  },
  searchQuery: '',
  targetFolder: {
    folderItem: defaultFolderItem,
    isOpen: false,
  },
  isSaving: false,
  isDeleting: false,
  isApplying: false,
  segments: [],
};
export const globalFiltersReducer = createReducer(
  initialState,
  on(globalFiltersActions.clearAppliedAndSelectedDataFilter, state => ({
    ...state,
    filterChanged: false,
  })),
  on(globalFiltersActions.filterGlobalFilters, (state, action) => ({
    ...state,
    searchQuery: action.data,
  })),
  on(globalFiltersActions.getDataGlobalFilters, state => ({
    ...state,
    isLoading: true,
  })),
  on(globalFiltersActions.getDataGlobalFiltersFailure, state => ({
    ...state,
    isLoading: false,
  })),
  on(globalFiltersActions.getDataGlobalFiltersSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    globalFilters: { ...action.filters },
    initialDataLoaded: true,
  })),
  on(globalFiltersActions.getSavedFiltersData, state => ({
    ...state,
    isLoading: true,
    filterMenuLoading: true,
  })),
  on(globalFiltersActions.getSavedFiltersDataFailure, state => ({
    ...state,
    isLoading: false,
    filterChanged: false,
    filterMenuLoading: false,
  })),
  on(globalFiltersActions.getSavedFiltersDataSuccess, (state, action) => {
    // if there is a filter from the saved filters list, which is applied then we
    // have to set it as appliedDataFilter as selectedDataFilter
    const selectedFilter: IDataFilter = state.appliedDataFilter.name
      ? action.filters.filter((dataFilter: IDataFilter) => dataFilter.name === state.appliedDataFilter.name)[0]
      : null;
    const appliedDataFilter = selectedFilter || state.appliedDataFilter;
    const selectedDataFilter = selectedFilter || state.selectedDataFilter;

    return {
      ...state,
      appliedDataFilter,
      isLoading: false,
      filterChanged: false,
      filterMenuLoading: false,
      savedDataFilters: [...action.filters],
      selectedDataFilter,
    };
  }),
  on(globalFiltersActions.filterChanged, state => ({
    ...state,
    filterChanged: true,
  })),
  on(globalFiltersActions.toggleFolderSelect, (state, action) => ({
    ...state,
    filterMenuLoading: true,
    targetFolder: {
      ...state.targetFolder,
      folderItem: action.folder,
    },
  })),
  on(globalFiltersActions.toggleFolderItemSelect, (state, action) => ({
    ...state,
    filterMenuLoading: true,
    targetFolder: {
      ...state.targetFolder,
      folderItem: action.folder,
    },
  })),
  on(globalFiltersActions.toggleFolderExpandedMenu, (state, action) => {
    const isDifferentItem =
      action.folder.folderItem !== state.targetFolder.folderItem.folderItem &&
      action.folder.folderName !== state.targetFolder.folderItem.folderName &&
      (!action.folder.folderSelectKey ||
        action.folder.folderSelectKey !== state.targetFolder.folderItem.folderSelectKey);
    return {
      ...state,
      targetFolder: {
        folderItem: action.folder,
        isOpen: isDifferentItem ? true : !state.targetFolder.isOpen,
      },
    };
  }),
  on(globalFiltersActions.submitDeleteSavedFilters, state => ({
    ...state,
    filterMenuLoading: true,
    isDeleting: true,
  })),
  on(
    globalFiltersActions.submitDeleteSavedFiltersFailure,
    globalFiltersActions.submitDeleteSavedFiltersSuccess,
    state => ({
      ...state,
      isDeleting: false,
    }),
  ),
  on(globalFiltersActions.submitSaveFilters, state => ({
    ...state,
    filterMenuLoading: true,
    isSaving: true,
  })),
  on(globalFiltersActions.applySelectedFilters, state => ({
    ...state,
    isApplying: true,
  })),
  on(globalFiltersActions.submitSaveFiltersFailure, globalFiltersActions.submitSaveFiltersSuccess, state => ({
    ...state,
    isSaving: false,
  })),
  on(globalFiltersActions.updateAppliedDataFilterSuccess, state => ({
    ...state,
    isApplying: false,
  })),
  on(globalFiltersActions.updateAppliedDataFilter, (state, action) => ({
    ...state,
    appliedDataFilter: {
      ...action.filter,
    },
    filterMenuLoading: false,
  })),
  on(globalFiltersActions.updateSelectedDataFilter, (state, action) => ({
    ...state,
    selectedDataFilter: {
      ...action.filter,
      listIds: action.filter.listIds.filter(listId => listId),
    },
    filterMenuLoading: false,
  })),
  on(globalFiltersActions.syncGlobalFilters, (state, action) => ({
    ...state,
    selectedDataFilter: {
      ...state.selectedDataFilter,
      filters: action.filters,
      listIds: action.listIds,
    },
    appliedDataFilter: {
      ...state.appliedDataFilter,
      filters: action.filters,
      listIds: action.listIds,
    },
  })),
  on(globalFiltersActions.updateGlobalFiltersWithTilesOne, (state, action) => ({
    ...state,
    selectedDataFilter: action.filter,
    appliedDataFilter: action.filter,
  })),
  on(globalFiltersActions.segmentsLoadedSuccess, (state, action) => ({
    ...state,
    segments: action.queries,
  }))
);

export function reducer(state = initialState, action: Action): GlobalFiltersState {
  return globalFiltersReducer(state, action);
}
