<div class="filter-label-container">
  <ts-tooltip *ngIf="tooltip; else noTooltip"
              [tooltipValue]="tooltip | translate"
              tooltipPosition="above"
              [hasUnderline]="false">
    <span class="filter-label">
      {{ label | translate }}
      <i class="fas fa-xs fa-question-circle"></i>
    </span>
  </ts-tooltip>
  <ng-template #noTooltip>
    <span class="filter-label">
        {{ label | translate }}
    </span>
  </ng-template>
  <ts-menu [menuItemsTemplate]="menuTemplate"
           class="ts-menu-as-selector"
           data-cy="report-checkbox-selector-menu">
    <span class="filter-label-result">
        {{ labelSelected | translate:params }}
    </span>
  </ts-menu>
</div>

<ng-template #menuTemplate>
  <div class="items-container">
    <ts-input [isSmall]="true"
              type="text"
              (click)="$event.stopPropagation()"
              [isRequired]="true"
              [formControl]="queryControl"
              *ngIf="showSearch">
    </ts-input>
    <div class="checkbox-container">
      <ts-checkbox *ngIf="enableSelectAllOption"
                   class="text-capitalize"
                   [isChecked]="allSelected"
                   (inputChange)="selectedAllOption()"
                   (click)="$event.stopPropagation()">{{ 'shared.ui.reportCheckboxSelector.all' | translate }}</ts-checkbox>
      <ng-container *ngFor="let selectedOption of selectedOptions">
        <ts-checkbox class="text-capitalize"
                     [isChecked]="selectedOption.checked"
                     (inputChange)="changeOption(selectedOption)"
                     (click)="$event.stopPropagation()">{{ selectedOption.label | translate }}</ts-checkbox>
      </ng-container>
      <ng-container *ngFor="let item of options; index as i">
        <ts-checkbox class="text-capitalize"
                     [isChecked]="item.checked"
                     (inputChange)="changeOption(item)"
                     (click)="$event.stopPropagation()"
                     [attr.data-cy]="'report-checkbox-selector-checkbox-' + i">
          {{ item.label | translate}}
        </ts-checkbox>
      </ng-container>
    </div>
  </div>
  <hr class="report-divider">
  <div class="confirm-button-container">
    <ts-button theme="default"
               tsMenuItem="transparent"
               [isSmall]="true"
               (click)="$event.stopPropagation()"
               (clicked)="confirmDialog()"
               data-cy="report-checkbox-selector-confirm">
      {{ 'shared.ui.reportCheckboxSelector.confirm' | translate }}
    </ts-button>
    <ts-button theme="secondary"
               [isSmall]="true"
               tsMenuItem="transparent"
               (clicked)="cancelDialog()"
               data-cy="report-checkbox-selector-cancel">
      {{ 'shared.ui.reportCheckboxSelector.cancel' | translate }}
    </ts-button>
  </div>
</ng-template>
