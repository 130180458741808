<div [tsLoadingOverlay]="isLoading$ | async">
  <ms-marketing-filters
    (applyFilters)="applyFilters($event)"
    (changeSelectedDateCohort)="changeSelectedDateCohort($event)"
    [selectedDateCohort]="selectedDateCohort$ | async"
    [dateCohortOptions]="dateCohortOptions$ | async"
    [reportFilters]="reportFilters$ | async"
    [isLoading]="isLoading$ | async">
  </ms-marketing-filters>
  <hr class="report-divider">
  <ng-container *ngIf="((isLoading$ | async) === false) && (reportData$ | async)?.length">
    <div class="metrics" *ngIf="metrics$ | async as metrics">
      <tsh-report-metric *ngFor="let metric of metrics"
                         [currency]="currencySetting$ | async"
                         [data]="metric">
      </tsh-report-metric>
    </div>
    <ms-marketing-chart
      [reportFilters]="reportFilters$ | async"
      [chartData]="chartData$ | async">
    </ms-marketing-chart>
    <hr class="report-divider">
    <ms-report-table
      (download)="downloadReport()"
      (sortChange)="changeSortState($event)"
      (navigate)="navigate($event)"
      (changeColumns)="changeColumns($event)"
      (changePage)="changePage($event)"
      [page]="page$ | async"
      [filters]="reportFilters$ | async"
      [sortState]="sortState$ | async"
      [isLoading]="isLoading$ | async"
      [totals]="reportTotals$ | async"
      [allColumns]="allColumns$ | async"
      [columns]="reportColumns$ | async"
      [report]="reportData$ | async"
      [totalCount]="count$ | async"
      [currency]="currencySetting$ | async">
    </ms-report-table>
  </ng-container>
  <tsh-no-report-result
    *ngIf="((isLoading$ | async) === false) && !(reportData$ | async)?.length"
    [description]="noResultDescription"
    [title]="noResultTitle">
  </tsh-no-report-result>
</div>

<tsh-add-to-dashboard-button (add)="addToDashboardDialog()"
                             [target]="targets.HeaderButtons">
</tsh-add-to-dashboard-button>
