import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import * as am4Charts from '@amcharts/amcharts4/charts';
import { TsAmChartsService } from '@terminus-lib/ui-chart';

import { DataTypeEnum, TrendsType } from '@shared/enums';
import { ITrendingChart, ITrendingParams } from '../../interfaces';
import { TrendingColors, TrendingDataPeriod } from '../../enums';

@Component({
  selector: 'ms-trending-chart',
  templateUrl: './trending-chart.component.html',
  styleUrls: ['./trending-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingChartComponent implements OnChanges, OnDestroy {
  @Input() public data: ITrendingChart[];
  @Input() public dataType: DataTypeEnum;
  @Input() public filters: ITrendingParams;
  @Input() public frequency: string;
  @Input() public tooltipTitle: string;
  @Input() public tooltipPrevious: string;
  @Input() public tooltipGoal: string;

  public chart: am4Charts.XYChart;
  public groups = [TrendingDataPeriod.Now, TrendingDataPeriod.Then];

  constructor(private amChartsService: TsAmChartsService) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // update chart data after changing report data
    if (changes.data && this.chart) {
      this.initChart();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setUpChart(chart: am4Charts.XYChart): void {
    this.chart = chart;
    this.createYAxes();
    const xAxes = this.createXAxes();
    this.createCursor(xAxes);
    if (this.data && this.filters) {
      this.initChart();
    }
  }

  initChart(): void {
    this.createYAxes();
    // set chart data
    this.chart.data = this.data;
    this.createSeries();
    this.createTooltip();
  }

  private createSeries(): void {
    this.chart.series.clear();
    [TrendingDataPeriod.Now, TrendingDataPeriod.Then].forEach(period => {
      const series = this.chart.series.push(new this.amChartsService.amCharts.charts.LineSeries());
      series.dataFields.categoryX = 'id';
      series.name = period;
      series.stroke = period === TrendingDataPeriod.Now ? TrendingColors.Now : TrendingColors.Then;
      series.strokeWidth = 3;
      series.fill = series.stroke;
      series.fillOpacity = 0.2;
      const fillModifier = new this.amChartsService.amCharts.core.LinearGradientModifier();
      fillModifier.opacities = [1, 0];
      fillModifier.offsets = [0, 1];
      fillModifier.gradient.rotation = 90;
      series.segments.template.fillModifier = fillModifier;
      series.dataFields.valueY = period === TrendingDataPeriod.Now ? 'valueNow' : 'valueThen';
    });
  }

  private createTooltip(): void {
    // Add tooltip to those series which has more data item
    const nowDataLength = this.data.filter(item => item.valueNow !== null && item.valueNow !== undefined).length;
    const thenDataLength = this.data.filter(item => item.valueThen !== null && item.valueThen !== undefined).length;
    const series = this.chart.series.getIndex(nowDataLength > thenDataLength ? 0 : 1);

    const dates = this.filters.type === TrendsType.Benchmark
      ? `({dateNow.formatDate('M/d/yyyy')} - {dateThen.formatDate('M/d/yyyy')})`
      : `({dateNow.formatDate('M/d/yyyy')})`;
    const previousSection = this.filters.type === TrendsType.Benchmark
      ? `{valueThen} ${this.tooltipPrevious}`
      : this.filters.type === TrendsType.Goal
        ? `{valueThen} ${this.tooltipGoal}`
        : '';
    const isVisible = this.filters.type === TrendsType.Benchmark || this.filters.type === TrendsType.Goal
      ? 'flex'
      : 'none';
    series.tooltipHTML = `
      <div class="amcharts-tooltip">
          <span class="title text-capitalize">${this.frequency} {id} ${dates}</span>
          <hr class="report-divider">
          <span class="subtitle">${this.tooltipTitle}</span></br>
          <div class="section">
              <div class="color" style="background-color: ${TrendingColors.Now};"></div>
              {valueNow}
              {diffTooltip}
          </div>
          <div class="section previous-section" style="display: ${isVisible};">
              <div class="color" style="background-color: ${TrendingColors.Then};"></div>
              ${previousSection}
          </div>
      </div>`;
  }

  private createXAxes(): am4Charts.CategoryAxis {
    this.chart.xAxes.clear();
    const categoryAxis = this.chart.xAxes.push(new this.amChartsService.amCharts.charts.CategoryAxis());
    categoryAxis.dataFields.category = 'id';
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.minGridDistance = 30;
    return categoryAxis;
  }

  private createYAxes(): void {
    this.chart.yAxes.clear();
    const valueAxis: am4Charts.ValueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
    valueAxis.numberFormatter = new this.amChartsService.amCharts.core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = this.dataType === DataTypeEnum.Currency ? '$#a' : '#';
    valueAxis.tooltip.disabled = true;
    valueAxis.min = 0;
  }

  private createCursor(
    xAxes: am4Charts.CategoryAxis,
  ): void {
    this.chart.cursor = new this.amChartsService.amCharts.charts.XYCursor();
    this.chart.cursor.xAxis = xAxes;
    this.chart.cursor.lineY.disabled = true;
    this.chart.cursor.lineX.stroke = this.amChartsService.amCharts.core.color('#637178');
    this.chart.cursor.lineX.strokeWidth = 30;
    this.chart.cursor.lineX.strokeOpacity = 0.2;
    this.chart.cursor.lineX.strokeDasharray = '';
  }
}
