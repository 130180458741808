import { campaignActions } from './campaign.actions';
import * as interfaces from '../../interfaces';
import {
  assembleAnalyticsData,
  updateLoadingState,
  updateNestedState,
  updateNestedTableState,
  updateNestedTableVisibility
} from '../../helpers/store.helper';
import { defaultSorter } from '../../data';
import { ILabelValue } from '@shared/interfaces';
import { IAnalyticsNested, IAnalyticsRequest } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const allSourceOption: ILabelValue = {
  label: 'measurementStudio.features.analytics.webActivities.campaignTable.selectorAllTypes',
  value: '',
};

export const campaignFeatureKey = 'campaign';

export interface ICampaignState extends interfaces.IAnalyticsIsLoading {
  nested: IAnalyticsNested<interfaces.ICampaignTableItem>;
  nestedTableState: interfaces.INestedTableState;
  totalResults: number;
  data: Partial<interfaces.ICampaignTableItem>[];
  selectedSource: ILabelValue;
  usedParamsInDriver: IAnalyticsRequest | null;
  selectedTags: string[];
}

export const initialState: ICampaignState = {
  nested: {
    data: {},
    loading: {},
    totalResults: {},
    sorter: {},
    pager: {},
  },
  nestedTableState: {
    visible: {}
  },
  totalResults: 0,
  isDriverLoading: false,
  campaignIsLoading: false,
  responseIsLoading: false,
  attributedIsLoading: false,
  influenceIsLoading: false,
  returnsIsLoading: false,
  costIsLoading: false,
  data: [],
  usedParamsInDriver: null,
  selectedSource: allSourceOption,
  selectedTags: []
};

const campaignReducer = createReducer(
  initialState,
  on(campaignActions.loadMetaDataSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.campaign)}
      : assembleAnalyticsData(state.data, action, action.data.campaign)),
    campaignIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadResponsesSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.response)}
      : assembleAnalyticsData(state.data, action, action.data.response)),
    responseIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadAttributionSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.attributed)}
      : assembleAnalyticsData(state.data, action, action.data.attributed)),
    attributedIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadInfluenceSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.influenced)}
      : assembleAnalyticsData(state.data, action, action.data.influenced)),
    influenceIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadReturnsSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.returns)}
      : assembleAnalyticsData(state.data, action, action.data.returns)),
    returnsIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadCostsSuccess, (state, action) => ({
    ...state,
    ...(action.parentId
      ? {nested: updateNestedState(state.nested, action, action.data.costs)}
      : assembleAnalyticsData(state.data, action, action.data.costs)),
    costIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignActions.loadMetaData, (state, action) => ({
    ...state,
    campaignIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadResponses, (state, action) => ({
    ...state,
    responseIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadAttribution, (state, action) => ({
    ...state,
    attributedIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadInfluence, (state, action) => ({
    ...state,
    influenceIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadCosts, (state, action) => ({
    ...state,
    costIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadReturns, (state, action) => ({
    ...state,
    returnsIsLoading: true,
    ...updateLoadingState(action, state.nested, true),
  })),
  on(campaignActions.loadMetaDataFailure, (state) => ({
    ...state, campaignIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.loadResponsesFailure, (state) => ({
    ...state, responseIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.loadAttributionFailure, (state) => ({
    ...state, attributedIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.loadInfluenceFailure, (state) => ({
    ...state, influenceIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.loadCostsFailure, (state) => ({
    ...state, costIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.loadReturnsFailure, (state) => ({
    ...state, returnsIsLoading: false, isDriverLoading: false
  })),
  on(campaignActions.changeNestedSorting, (state, action) => {
    const nestedSorting = action.data.value.sortDirection ? action.data.value : defaultSorter;
    return {
      ...state,
      nested: updateNestedTableState(state.nested, 'sorter', action.data.id, nestedSorting),
    };
  }),
  on(campaignActions.changeNestedPagination, (state, action) => ({
    ...state,
    nested: updateNestedTableState(state.nested, 'pager', action.data.id, action.data.value),
  })),
  on(campaignActions.clearNestedState, (state) => ({
    ...state,
    nested: {
      data: {},
      loading: {},
      totalResults: {},
      sorter: {},
      pager: {},
    }
  })),
  on(campaignActions.closeNestedTables, (state) => ({
    ...state,
    nestedTableState: {visible: {}}
  })),
  on(campaignActions.toggleTableRow, (state, action) => ({
    ...state,
    nestedTableState: updateNestedTableVisibility(state.nestedTableState, action.id),
  })),
  on(campaignActions.setSelectedSource, (state, action) => ({
    ...state,
    selectedSource: action.data,
  })),
);

export function reducer(state, action: Action): ICampaignState {
  return campaignReducer(state, action);
}
