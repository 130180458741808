import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { ReportCheckboxSelectorModule } from '@ui/components/report-checkbox-selector';
import { ICampaign, ICampaignByIdResponse, ILabelValueChecked, userMessageFactory } from '@shared/interfaces';
import { ReportFilterChange } from '@shared/classes';
import { CampaignsService } from '@shared/data-access/campaigns';
import { notificationMessagesActions } from '@notification-messages';

@WithDestroy
@Component({
  selector: 'tsh-filter-by-campaign',
  templateUrl: './filter-by-campaign.component.html',
  styleUrls: ['./filter-by-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterByCampaignComponent extends ReportFilterChange implements OnInit {
  // If you want to change label - Default "Filter By Campaign"
  @Input() public label = 'shared.filterByCampaign.label';
  // If you want to show tooltip for this filter then put value. Optional property
  @Input() public tooltip: string;
  // Selected campaigns - array of ids
  @Input() public field: FormControl;

  public options$: BehaviorSubject<ILabelValueChecked[]> = new BehaviorSubject([]);
  public selectedOptions$: BehaviorSubject<ILabelValueChecked[]> = new BehaviorSubject([]);
  private initialState: ILabelValueChecked[] = [];

  constructor(
    public campaignsService: CampaignsService,
    private store: Store<unknown>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.field?.value?.length) {
      this.getSelectedCampaigns();
    }
  }

  search(searchQuery: string): void {
    if (!searchQuery) {
      this.options$.next([]);
      return;
    }

    this.campaignsService.getCampaigns$(searchQuery).pipe(
      untilComponentDestroyed(this),
      map((campaigns: ICampaign[]) => campaigns.map(campaign => {
        return {
          label: campaign.name,
          value: campaign.id,
          checked: false,
        };
      })),
      catchError((error: Error) => {
        const message = error?.message || 'shared.filterByCampaign.error';
        this.store.dispatch(
          notificationMessagesActions.addMessage({ message: userMessageFactory({
            n: message
          }) })
        );
        return of([]); // in case of error return empty array to avoid crashing app
      })
    ).subscribe((options: ILabelValueChecked[]) => {
      const allUnselectedOptions = options.filter(option => {
        return !this.selectedOptions$.getValue().find(selectedOption => selectedOption.value === option.value);
      });
      this.options$.next(allUnselectedOptions);
    });
  }

  getSelectedCampaigns(): void {
    const ids = this.field.value;
    this.campaignsService.getCampaignsByIds$({campaignIds: ids})
      .pipe(
        untilComponentDestroyed(this),
        map(({campaigns}: ICampaignByIdResponse) => campaigns.map(campaign => {
          return {
            label: campaign.name,
            value: campaign.campaignId,
            checked: true,
          };
        })),
        catchError((error: Error) => {
          const message = error?.message || 'shared.filterByCampaign.error';
          this.store.dispatch(
            notificationMessagesActions.addMessage({ message: userMessageFactory({
              n: message
            }) })
          );
          return of([]); // in case of error return empty array to avoid crashing app
        })
      )
      .subscribe((data: ILabelValueChecked[]) => {
        this.selectedOptions$.next(data);
        this.initialState = data;
      });
  }

  onChangeOptions(changedOption: ILabelValueChecked): void {
    // remove checked option from options and add it to selected options
    // otherwise remove it from selected options and add it to options
    if (changedOption.checked) {
      this.selectedOptions$.next([...this.selectedOptions$.getValue(), changedOption]);
      this.options$.next(
        this.options$.getValue().filter(option => option.value !== changedOption.value)
      );
    } else {
      this.selectedOptions$.next(
        this.selectedOptions$.getValue().filter(option => option.value !== changedOption.value)
      );
      this.options$.next([...this.options$.getValue(), changedOption]);
    }
  }

  onCancel(): void {
    // reset all changes and return selected option to initial state
    // options on cancel is []
    this.selectedOptions$.next(this.initialState.map(option => ({...option, checked: true})));
  }

  onConfirm(): void {
    // we have to mark this update as pending
    this.change$.next(true);
    // update initial state with all selected options
    this.initialState = this.selectedOptions$.getValue();
  }
}

@NgModule({
  declarations: [FilterByCampaignComponent],
  exports: [FilterByCampaignComponent],
  imports: [
    CommonModule,
    ReportCheckboxSelectorModule,
  ]
})
export class FilterByCampaignModule {
}
