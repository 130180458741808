<ts-expansion-panel (expandedChange)="panelExpandedChange($event)"
                    [hideToggle]="true"
                    [transparentMode]="true">
  <ts-expansion-panel-trigger collapsedHeight="27px" expandedHeight="27px" data-cy="tile-dialog-form-setting-expansion-panel">
    <span class="title">{{'shared.dashboards.settings.title' | translate}}</span>
    <i class="fas fa-xs" [ngClass]="{'fa-chevron-down': isExpanded, 'fa-chevron-right': !isExpanded}"></i>
  </ts-expansion-panel-trigger>
  <div class="container">
    <ng-container *ngFor="let filter of filters">
      <ng-container *ngIf="filter.show ? (filter.show | memoize: settingsFormGroup.value) : true">
        <ng-container [ngSwitch]="filter.type">
          <ng-container *ngSwitchCase="controlTypes.Selector">
            <tsh-report-filter-selector @shiftWithFadeOut
                                        @shiftWithFadeIn
                                        [attr.data-cy]="'tile-dialog-form-setting-' + filter.key"
                                        [field]="settingsFormGroup.get(filter.key)"
                                        [options]="filter.getCustomOptions
                                          ? (filter.getCustomOptions | memoize: settingsFormGroup.value: settingsFormGroup)
                                          : filter.options"
                                        [title]="filter.getCustomLabel
                                          ? (filter.getCustomLabel | memoize: settingsFormGroup.value)
                                          : filter.label">
            </tsh-report-filter-selector>
          </ng-container>
          <ng-container *ngSwitchCase="controlTypes.Checkbox">
            <tsh-report-checkbox-selector @shiftWithFadeOut
                                          @shiftWithFadeIn
                                          [attr.data-cy]="'tile-dialog-form-setting-' + filter.key"
                                          [noneLabel]="filter.noneLabel || 'shared.ui.reportCheckboxSelector.none'"
                                          [label]="filter.getCustomLabel
                                            ? (filter.getCustomLabel | memoize: settingsFormGroup.value: settingsFormGroup)
                                            : filter.label"
                                          [options]="filter.getCustomOptions
                                            ? (filter.getCustomOptions | memoize: settingsFormGroup.value: settingsFormGroup)
                                            : filter.options"
                                          [field]="settingsFormGroup.get(filter.key)">
            </tsh-report-checkbox-selector>
          </ng-container>
          <ng-container *ngSwitchCase="controlTypes.Benchmark">
            <tsh-benchmark-control @shiftWithFadeOut
                                   @shiftWithFadeIn
                                   [attr.data-cy]="'tile-dialog-form-setting-' + filter.key"
                                   *ngIf="settingsFormGroup.get(benchmarkFields.Type)
                                      && settingsFormGroup.get(benchmarkFields.Goal)
                                      && settingsFormGroup.get(benchmarkFields.Previous)"
                                   [typeField]="settingsFormGroup.get(benchmarkFields.Type)"
                                   [goalField]="settingsFormGroup.get(benchmarkFields.Goal)"
                                   [previous]="settingsFormGroup.get(benchmarkFields.Previous).value"
                                   (confirm)="confirmBenchmarkChange($event)">
            </tsh-benchmark-control>
          </ng-container>
          <ng-container *ngSwitchCase="controlTypes.SelectorWithOptionsProvider">
            <tsh-selector @shiftWithFadeOut
                          @shiftWithFadeIn
                          [attr.data-cy]="'tile-dialog-form-setting-' + filter.key"
                          [formControl]="settingsFormGroup.get(filter.key)"
                          [label]="filter.label"
                          [options]="filter.optionsProvider">
            </tsh-selector>
          </ng-container>
          <ng-container *ngSwitchCase="controlTypes.InputNumber">
            <tsh-report-filter-input @shiftWithFadeOut
                                     @shiftWithFadeIn
                                     [attr.data-cy]="'tile-dialog-form-setting-' + filter.key"
                                     [isNumeric]="true"
                                     [field]="settingsFormGroup.get(filter.key)"
                                     [label]="filter.getCustomLabel
                                      ? (filter.getCustomLabel | memoize: settingsFormGroup.value)
                                      : filter.label">
            </tsh-report-filter-input>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ts-expansion-panel>
