import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsSelectionListModule } from '@terminus-lib/ui-selection-list';
import { TsSortModule } from '@terminus-lib/ui-sort';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';

import { HeaderCellModule } from '@ui/components/header-cell';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { SearchByPropertyModule } from '@ui/components/search-by-property';
import { PaginationModule } from '@ui/components/pagination';
import { CsvDownloadButtonModule } from '@ui/components/csv-download-button';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { InfoPanelComponent } from './containers/info-panel.component';
import { InfoPanelTableComponent } from './components/info-panel-table.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    TsButtonModule,
    TsIconButtonModule,
    TsOptionModule,
    NoReportResultModule,
    TsTableModule,
    HeaderCellModule,
    TypedDataModule,
    TsSortModule,
    TsSelectionListModule,
    SearchByPropertyModule,
    TsLoadingOverlayModule,
    PaginationModule,
    CsvDownloadButtonModule,
    TsTooltipModule
  ],
  declarations: [
    InfoPanelComponent,
    InfoPanelTableComponent
  ],
  exports: [
    InfoPanelComponent
  ]
})
export class InfoPanelModule {
}
