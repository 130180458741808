import { createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IAdsInfluencedInsights, IAdsInfluencedInsightsDetailsResponse } from '@measurement-studio/interfaces';
import { IAdsInfluencedRequest } from '../../interfaces/ads-influenced-request.interface';
import { IAddToAccountListRequest } from '@shared/interfaces';
import { AdInsightsSidenavStates, AdsInsightsReportType } from '../../enums';

export enum InfluencedInsightsActionTypes {
  GetInfluencedInsights = '[InfluencedInsights] Get Influenced Insights',

  LoadInfluencedInsights = '[InfluencedInsights] Load Influenced Insights',
  LoadInfluencedInsightsSuccess = '[InfluencedInsights] Load Influenced Insights Success',
  LoadInfluencedInsightsFailure = '[InfluencedInsights] Load Influenced Insights Failure',

  LoadInfluencedInsightsDetails = '[InfluencedInsights] Load Influenced Insights Details',
  LoadInfluencedInsightsDetailsSuccess = '[InfluencedInsights] Load Influenced Insights Details Success',
  LoadInfluencedInsightsDetailsFailure = '[InfluencedInsights] Load Influenced Insights Details Failure',

  DownloadCSV = '[InfluencedInsights] Download Influenced Insights Details',
  DownloadCSVSuccess = '[InfluencedInsights] Download Influenced Insights Details Success',
  DownloadCSVFailure = '[InfluencedInsights] Download Influenced Insights Details Failure',
  ChangeSort = '[InfluencedInsights] Change Sort Influenced Insights Details',
  ChangePage = '[InfluencedInsights] Change Page Influenced Insights Details',
  ChangeSearch = '[InfluencedInsights] Change Search Influenced Insights Details',
  SetActiveReportAndDetails = '[InfluencedInsights] Set Active Report and Details',

  UpdateInsightsInfoPanelState = '[InfluencedInsights] Update Insights Info Panel State',

  AddAccountToAccountsList = '[InfluencedInsights] Add Account To Accounts List',
  AddAccountToAccountsListSuccess = '[InfluencedInsights] Add Account To Accounts List Success',
  AddAccountToAccountsListFailure = '[InfluencedInsights] Add Account To Accounts List Failure',
}

const getInfluencedInsights = createAction(
  InfluencedInsightsActionTypes.GetInfluencedInsights
);

const loadInfluencedInsights = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsights,
  props<{ params: IAdsInfluencedRequest }>()
);

const loadInfluencedInsightsSuccess = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsightsSuccess,
  props<{
    key: AdsInsightsReportType,
    insights: IAdsInfluencedInsights
  }>()
);

const loadInfluencedInsightsFailure = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsightsFailure,
  props<{
    key: AdsInsightsReportType,
    message: string
  }>()
);

const changeSearch = createAction(
  InfluencedInsightsActionTypes.ChangeSearch,
  props<{
    reportType: AdsInsightsReportType,
    search: string
  }>()
);

const changeSort = createAction(
  InfluencedInsightsActionTypes.ChangeSort,
  props<{
    reportType: AdsInsightsReportType,
    sortState: TsSortState
  }>()
);

const changePage = createAction(
  InfluencedInsightsActionTypes.ChangePage,
  props<{
    reportType: AdsInsightsReportType,
    page: number,
  }>()
);

const loadInfluencedInsightsDetails = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsightsDetails,
  props<{ reportType: AdsInsightsReportType }>()
);

const loadInfluencedInsightsDetailsSuccess = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsightsDetailsSuccess,
  props<{
    key: AdsInsightsReportType,
    details: IAdsInfluencedInsightsDetailsResponse
  }>()
);

const loadInfluencedInsightsDetailsFailure = createAction(
  InfluencedInsightsActionTypes.LoadInfluencedInsightsDetailsFailure,
  props<{
    key: AdsInsightsReportType,
    message: string
  }>()
);

const downloadCSV = createAction(
  InfluencedInsightsActionTypes.DownloadCSV,
  props<{
    key: AdsInsightsReportType,
    detailsKey: string
  }>()
);

const downloadCSVSuccess = createAction(
  InfluencedInsightsActionTypes.DownloadCSVSuccess,
  props<{
    key: AdsInsightsReportType,
    data: string,
  }>()
);

const downloadCSVFailure = createAction(
  InfluencedInsightsActionTypes.DownloadCSVFailure,
  props<{
    key: AdsInsightsReportType,
    message: string
  }>()
);

const setActiveReportAndDetails = createAction(
  InfluencedInsightsActionTypes.SetActiveReportAndDetails,
  props<{
    reportType: AdsInsightsReportType,
    detailsKey: string
  }>()
);

const updateInsightsInfoPanelState = createAction(
  InfluencedInsightsActionTypes.UpdateInsightsInfoPanelState,
  props<{ state: AdInsightsSidenavStates }>()
);

const addAccountToAccountsList = createAction(
  InfluencedInsightsActionTypes.AddAccountToAccountsList,
  props<{ params: IAddToAccountListRequest }>()
);

const addAccountToAccountsListSuccess = createAction(
  InfluencedInsightsActionTypes.AddAccountToAccountsListSuccess,
  props<{ count: number }>()
);

const addAccountToAccountsListFailure = createAction(
  InfluencedInsightsActionTypes.AddAccountToAccountsListFailure,
  props<{ message: string }>()
);

export const influencedInsightsActions = {
  getInfluencedInsights,
  loadInfluencedInsights,
  loadInfluencedInsightsSuccess,
  loadInfluencedInsightsFailure,
  loadInfluencedInsightsDetails,
  loadInfluencedInsightsDetailsSuccess,
  loadInfluencedInsightsDetailsFailure,
  setActiveReportAndDetails,
  downloadCSV,
  downloadCSVSuccess,
  downloadCSVFailure,
  changeSort,
  changePage,
  changeSearch,
  updateInsightsInfoPanelState,
  addAccountToAccountsList,
  addAccountToAccountsListSuccess,
  addAccountToAccountsListFailure
};
