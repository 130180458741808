import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';
import { MonoTypeOperatorFunction, Observable, range, throwError, timer, zip } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';

import { EnvJsonResponse, ENV_TOKEN, InjectedEnv } from '@shared/data-access/environment';
import { exponentialBackoffDelayCalculator, httpRetryer, RetryWithBackoff } from '@terminus-lib/fe-utilities';
import { isInRefArray } from '@util/helpers';
import { GrpcStatusCodes, RETRIES_COUNT, rollbarConfig, RollbarLogLevelEnum } from './rollbar.constants';
import { RollbarJS } from './rollbar.token';

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  public rollbar: Rollbar;

  constructor(
    private injector: Injector,
    @Inject(ENV_TOKEN) private envService: InjectedEnv
  ) {
    this.rollbar = this.injector.get(RollbarJS);

    this.envService.subscribe((vars: EnvJsonResponse) => {
      rollbarConfig.accessToken = vars.ROLLBAR_JS_ACCESS_TOKEN;
      rollbarConfig.environment = vars.ROLLBAR_ENVIRONMENT;

      if (rollbarConfig.accessToken) {
        rollbarConfig.enabled = true;
        rollbarConfig.verbose = false;
      }

      this.rollbar.configure(rollbarConfig);
    });
  }

  /**
   * Automatically called by Angular to handle any errors that occur.
   *
   * @param err - An intercepted error
   */
  // eslint-disable-next-line
  public handleError(err: any): void {
    this.logErrorToBrowser(err);
    this.rollbar.error(err.originalError || err);
  }

  // eslint-disable-next-line
  public logErrorToBrowser(err: any): void {
    if (window && window.console) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  public reportErrors(message: string, skippedHttpResponseCodes: number[]) {
    return <T>(source: Observable<T>) =>
      source.pipe(
        catchError((e: Error | HttpErrorResponse) => {
          if (e instanceof HttpErrorResponse && isInRefArray(e.status, skippedHttpResponseCodes)) {
            throw e;
          }

          const err = `${message} - ${e.name}\n${JSON.stringify(e, null, 2)}`;
          this.handleError(err);
          throw e;
        }),
      );
  }

  public retryOnError<T>({
    retries = 8,
    skippedGrpcCodes = [],
    delayCalculator = exponentialBackoffDelayCalculator({}),
  }: Partial<RetryWithBackoff & { skippedGrpcCodes: GrpcStatusCodes[] }>): MonoTypeOperatorFunction<T> {
    return retryWhen(errors =>
      zip(errors, range(1, retries)).pipe(
        mergeMap(([err, retry]) => {
          if ((err.error && isInRefArray(err.error.code, skippedGrpcCodes)) || retry >= retries) {
            return throwError(err);
          }

          return timer(delayCalculator(retry));
        }),
      ),
    );
  }

  public httpGrpcRetryerWithReporting(
    message: string,
    skippedGrpcCodes: GrpcStatusCodes[] = [GrpcStatusCodes.UNIMPLEMENTED],
    skippedReportCodes: number[] = [403],
  ) {
    return <T>(source: Observable<T>) =>
      source.pipe(
        this.reportErrors(message, skippedReportCodes),
        this.retryOnError({
          retries: RETRIES_COUNT,
          skippedGrpcCodes,
        }),
      );
  }

  public httpRetryerWithReporting(message: string, skippedHttpResponeCodes: number[] = [403], retries = RETRIES_COUNT) {
    return <T>(source: Observable<T>) =>
      source.pipe(this.reportErrors(message, skippedHttpResponeCodes), httpRetryer({ retries }));
  }

  public report(logLevel: RollbarLogLevelEnum, message: string) {
    if (this.rollbar) {
      if (this.rollbar[logLevel] && typeof this.rollbar[logLevel] === 'function') {
        this.rollbar[logLevel](message);
      }
    }
  }
}
