import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as UtilitiesSidenavReducer from './utilities-sidenav.reducer';
import { RouteItemEnum } from '@shared/enums';
import { selectRouteId } from '@hub-router';
import { treatmentTokens, getIsSplitIoFlagOn } from '@shared/splitio';

export const getUtilitiesSidenavState = createFeatureSelector<UtilitiesSidenavReducer.SidenavUtilitiesState>
(UtilitiesSidenavReducer.utilitiesSidenavFeatureKey);

export const getIsSidenavExtended = createSelector(getUtilitiesSidenavState,
  (state: UtilitiesSidenavReducer.SidenavUtilitiesState) => state.isSidenavExtended);

export const getActiveTabIndex = createSelector(getUtilitiesSidenavState,
  (state: UtilitiesSidenavReducer.SidenavUtilitiesState) => state.activeTab);

export const isRouteWithUtilitiesSidenav = createSelector(
  selectRouteId,
  (routeId: RouteItemEnum) => {
    const advertisingInsightsRoutes = [
      RouteItemEnum.AdvertisingInsights,
      RouteItemEnum.InsightsCampaignResponsesInfluenced,
      RouteItemEnum.InsightsLeadsInfluenced,
      RouteItemEnum.InsightsNewPipelineInfluenced,
      RouteItemEnum.InsightsPageViewsInfluenced,
      RouteItemEnum.InsightsPipelineInfluenced,
      RouteItemEnum.InsightsRevenueInfluenced,
    ];

    const msRoutesWithUtilitiesSidenav = [
      ...advertisingInsightsRoutes,
      RouteItemEnum.MarketingImpact,
      RouteItemEnum.AnalyzeMarketingImpact,
      RouteItemEnum.AttributionByCampaignType,
      RouteItemEnum.AnalyzeAttributionByCampaignType,
      RouteItemEnum.OpportunityInsights,
      RouteItemEnum.OpportunitySpecificTotalTouches,
      RouteItemEnum.OpportunitySpecificPeopleInfluencing,
      RouteItemEnum.OpportunitySpecificCampaignInfluencing,
      RouteItemEnum.OpportunityAllOpptysTouches,
      RouteItemEnum.CampaignTrends,
      RouteItemEnum.StagesSnapshot,
      RouteItemEnum.CampaignSpecificStages,
      RouteItemEnum.CampaignSpecificLeads,
      RouteItemEnum.CampaignSpecificOpportunities,
      RouteItemEnum.CampaignSpecificDeals,
      RouteItemEnum.WebActivitiesTrending,
      RouteItemEnum.WebActivitiesStages,
      RouteItemEnum.WebActivitiesChannel,
      RouteItemEnum.WebActivitiesChannelAssets,
      RouteItemEnum.WebActivitiesChannelMedium,
      RouteItemEnum.CampaignAnalyticsCampaigns,
      RouteItemEnum.CampaignAnalyticsGroups,
      RouteItemEnum.CohortedWaterfall,
      RouteItemEnum.CohortedWaterfallDetails,
      RouteItemEnum.StageInfluence,
      RouteItemEnum.StageInfluenceCampaignType,
      RouteItemEnum.ProgressionByQuarterDetails,
      RouteItemEnum.ProgressionByQuarter,
      RouteItemEnum.ScorecardOverview,
      RouteItemEnum.ScorecardTrendingKpi,
    ];

    const dsRoutesWithUtilitiesSidenav = [
      RouteItemEnum.AccountHub,
      RouteItemEnum.AccountSpecificAttribution,
      RouteItemEnum.AccountSpecificTrending,
      RouteItemEnum.AccountSpecificPeople,
      RouteItemEnum.AccountSpecificActivities,
    ];

    return [
      ...msRoutesWithUtilitiesSidenav,
      ...dsRoutesWithUtilitiesSidenav
    ].includes(routeId);
  }
);

export const routesWithNoAccessToGlobalFilters = createSelector(
  selectRouteId,
  (routeId: RouteItemEnum) => {
    const routes = [];
    return routes.includes(routeId);
  }
);

export const routesWithInfoPanel = createSelector(
  selectRouteId,
  (routeId: RouteItemEnum) => {
    const routes = [
      RouteItemEnum.AdvertisingInsights,
      RouteItemEnum.InsightsCampaignResponsesInfluenced,
      RouteItemEnum.InsightsLeadsInfluenced,
      RouteItemEnum.InsightsNewPipelineInfluenced,
      RouteItemEnum.InsightsPageViewsInfluenced,
      RouteItemEnum.InsightsPipelineInfluenced,
      RouteItemEnum.InsightsRevenueInfluenced,
    ];
    return routes.includes(routeId);
  }
);

