import { createAction, props } from '@ngrx/store';

export enum ScorecardActionTypes {
  LoadCanAddListFlag = '[Scorecard] Load Can Add List flag',
  LoadCanAddListFlagSuccess = '[Scorecard] Load Can Add List flag Success',
  LoadCanAddListFlagFailure = '[Scorecard] Load Can Add List flag Failure',
  ResetData = '[Scorecard] Reset Data after changing in Scorecard',
}

const resetData = createAction(
  ScorecardActionTypes.ResetData
);

const loadCanAddListFlag = createAction(
  ScorecardActionTypes.LoadCanAddListFlag
);

const loadCanAddListFlagSuccess = createAction(
  ScorecardActionTypes.LoadCanAddListFlagSuccess,
  props<{ flag: boolean }>()
)

const loadCanAddListFlagFailure = createAction(
  ScorecardActionTypes.LoadCanAddListFlagFailure,
  props<{ message: string }>()
);

export const scorecardActions = {
  loadCanAddListFlag,
  loadCanAddListFlagSuccess,
  loadCanAddListFlagFailure,
  resetData
};

