import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { TsExpansionPanelModule } from '@terminus-lib/ui-expansion-panel';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsSelectionListModule } from '@terminus-lib/ui-selection-list';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TsCheckboxModule } from '@terminus-lib/ui-checkbox';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TsDrawerModule } from '@terminus-lib/ui-drawer';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsIconModule } from '@terminus-lib/ui-icon';
import { TsCardModule } from '@terminus-lib/ui-card';
import { TsTabsModule } from '@terminus-lib/ui-tabs';
import { TsChipModule } from '@terminus-lib/ui-chip';

import { GlobalFiltersComponent } from './containers/global-filters.component';
import { GlobalFiltersExpansionPanelComponent } from './components/filter-expansion-panel/filter-expansion-panel.component';
import { GlobalFiltersListComponent } from './components/filters-list/filters-list.component';
import { GlobalFiltersDataFilterSelectComponent } from './components/data-filter-select/component';
import { GlobalFiltersFooterComponent } from './components/footer/component';
import { GlobalFiltersSaveFilterDialogComponent } from './components/save-filter-dialog/component';
import { GlobalFiltersResolver, GlobalFiltersService, GlobalFiltersSource } from '@shared/data-access/global-filters';
import { SegmentsApi } from '@shared/data-access/segments-api';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TsButtonModule,
    TsCardModule,
    TsCheckboxModule,
    TsChipModule,
    TsDrawerModule,
    TsExpansionPanelModule,
    TsIconButtonModule,
    TsIconModule,
    TsInputModule,
    TsLoadingOverlayModule,
    TsOptionModule,
    TsRadioGroupModule,
    TsTabsModule,
    TsTooltipModule,
    TsSelectionListModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    GlobalFiltersComponent,
    GlobalFiltersDataFilterSelectComponent,
    GlobalFiltersExpansionPanelComponent,
    GlobalFiltersFooterComponent,
    GlobalFiltersListComponent,
    GlobalFiltersSaveFilterDialogComponent,
  ],
  exports: [GlobalFiltersComponent, GlobalFiltersSaveFilterDialogComponent],
  entryComponents: [GlobalFiltersSaveFilterDialogComponent],
  providers: [GlobalFiltersService, GlobalFiltersResolver, GlobalFiltersSource, SegmentsApi],
})
export class GlobalFiltersModule {}
