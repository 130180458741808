import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TsCookieService, TsWindowService } from '@terminus-lib/fe-utilities';

import { hubCookieActions } from './hub-cookie.actions';
import { IAuthToken } from '@shared/interfaces';
import { AUTH_CONT, AUTH_CONT_KEY_EMPLOYEE } from '@shared/constants';

@Injectable()
export class HubCookieEffects {
  constructor(private actions$: Actions,
              private cookieService: TsCookieService,
              private windowService: TsWindowService) {
  }

  updateJwt$ = createEffect(() => this.actions$.pipe(
    ofType(hubCookieActions.updateJwt),
    tap((action) => {
      const groups = this.getHost();

      if (groups[1]) {
        const domain = this.getDomain(groups[1]);
        this.cookieService.set('jwt_cookie', action.token.token, null, '/', domain);
        // NOTE: set auth_cont cookie for localhost and firebase to avoid login redirects
        if (!domain) {
          this.cookieService.set(AUTH_CONT, AUTH_CONT_KEY_EMPLOYEE, Date.now() + 12 * 60 * 60 * 1000, '/', domain);
        }
      }
    }),
  ), {dispatch: false});

  updateJwtOnSwitchUser$ = createEffect(() => this.actions$.pipe(
    ofType(hubCookieActions.updateJwtOnSwitchUser),
    map(action => action.token),
    tap((token: IAuthToken) => {
      const groups = this.getHost();

      if (groups[1]) {
        const domain = this.getDomain(groups[1]);
        this.cookieService.set('jwt_cookie', token.token, null, '/', domain);
        this.windowService.nativeWindow.location.reload();
      }
    }),
  ), {dispatch: false});

  deleteJwt$ = createEffect(() => this.actions$.pipe(
    ofType(hubCookieActions.deleteJwt),
    tap(() => {
      const groups = this.getHost();

      if (groups[1]) {
        const domain = this.getDomain(groups[1]);
        this.cookieService.delete('jwt_cookie', '/', domain);
      }
    }),
  ), {dispatch: false});

  private getHost(): RegExpMatchArray | null {
    const host = this.windowService.nativeWindow.location.hostname;
    return host.match(/(?:[a-z|-]*\.)?([a-z|\\.]+)/);
  }

  private getDomain(group: string): string | null {
    const localhost = 'localhost';
    const firebasePreviewApp = 'terminus';
    return group !== localhost && group !== firebasePreviewApp ? `.${group}` : null;
  }
}
