import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './demo-config.reducer';

export const selectDemoConfigState
  = createFeatureSelector<reducer.DemoConfigState>(reducer.demoConfigFeatureKey);

export const isDemoModeEnabled = createSelector(selectDemoConfigState,
  (state: reducer.DemoConfigState) => state.enabled
);

export const isSlowdownEnabled = createSelector(selectDemoConfigState,
  (state: reducer.DemoConfigState) => state.slowdownEnabled
);

export const getSlowdownTime = createSelector(selectDemoConfigState,
  (state: reducer.DemoConfigState) => state.slowdown
);

export const isDebugEnabled = createSelector(selectDemoConfigState,
  (state: reducer.DemoConfigState) => state.debug
);
