import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IDataFilter, IFilterDeleteRequest, IFilterSaveRequest, IGlobalFilters } from '@shared/interfaces';
import { convertToFormData } from '@util/http';

export enum GlobalFiltersURLs {
  LoadData = '/api/v1/load_data_filters',
  LoadSaved = '/filters/load_saved_filters',
  UpdateSaved = '/filters/update_saved_filter',
  DeleteSaved = '/filters/delete_saved_filter',
}

@Injectable({providedIn: 'root'})
export class GlobalFiltersSource extends HttpClientBase {

  getGlobalFiltersData$(): Observable<IGlobalFilters> {
    const endpoint = this.endpoint(GlobalFiltersURLs.LoadData);

    return this.httpClient.get<IGlobalFilters>(endpoint);
  }

  getSavedDataFilters$(): Observable<IDataFilter[]> {
    const endpoint = this.endpoint(GlobalFiltersURLs.LoadSaved);

    return this.httpClient.get<IDataFilter[]>(endpoint);
  }

  updateSavedDataFilter$(params: IFilterSaveRequest): Observable<string> {
    const endpoint = this.endpoint(GlobalFiltersURLs.UpdateSaved);

    const urlParams = this.toSaveDataFilterHttpsParams(params);

    return this.httpClient.post<string>(endpoint, urlParams, {
      responseType: 'text' as 'json',
    });
  }

  deleteSavedDataFilter$(params: IFilterDeleteRequest): Observable<string> {
    const endpoint = this.endpoint(GlobalFiltersURLs.DeleteSaved);

    const urlParams = this.toDeleteDataFilterHttpsParams(params);

    return this.httpClient.post<string>(endpoint, urlParams, {
      responseType: 'text' as 'json',
    });
  }

  private toSaveDataFilterHttpsParams(params: IFilterSaveRequest): FormData {
    const newParams: Record<string, string | string[] | boolean | number> = {
      name: params.name,
      filters: JSON.stringify(params.filters),
      private: params.private,
      listIds: params.listIds,
    };

    if (params.ownerId) {
      newParams.owner_id = params.ownerId;
    }

    return convertToFormData(newParams);
  }

  private toDeleteDataFilterHttpsParams(params: IFilterDeleteRequest): FormData {
    return convertToFormData({name: params.name});
  }
}
