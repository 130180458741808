import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * This pipe does nothing more than just invokes the first argument (that is expected to be function), passing other arguments inside.
 * The memoization is already built in the way how Angular handles pure pipes facing them in templates.
 *
 * Usage: {{square | memoize:counter}}
 * where 'square' is method/function
 *       'counter' is argument
 */
@Pipe({name: 'memoize'})
export class MemoizePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(fn: (...args: any[]) => any, ...args: any[]): any {
    return fn(...args);
  }
}

@NgModule({
  declarations: [MemoizePipe],
  exports: [MemoizePipe],
  imports: [CommonModule]
})
export class SharedUiPipesMemoizeModule {
}
