import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { TsCardModule } from '@terminus-lib/ui-card';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationComponent } from './notification.component';

@NgModule({
  declarations: [NotificationComponent],
  entryComponents: [NotificationComponent],
  exports: [NotificationComponent],
  imports: [
    CommonModule,
    TsCardModule,
    MatSnackBarModule,
    TranslateModule,
  ],
  providers: [{
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'tsh-notification-panel'
    }
  }],
})
export class NotificationModule {
}
