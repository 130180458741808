import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsCheckboxModule } from '@terminus-lib/ui-checkbox';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ReportCheckboxSelectorComponent } from './report-checkbox-selector.component';

@NgModule({
  declarations: [ReportCheckboxSelectorComponent],
  exports: [
    ReportCheckboxSelectorComponent
  ],
  imports: [
    CommonModule,
    TsMenuModule,
    TsInputModule,
    TsCheckboxModule,
    TsButtonModule,
    TsTooltipModule,
    TranslateModule,
    ReactiveFormsModule,
  ]
})
export class ReportCheckboxSelectorModule {
}
