import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, filter, map, startWith, take } from 'rxjs/operators';

import { getSplitIoFlagValue } from '../state/split-io.selectors';
import { SplitIoState } from '../state/split-io.reducer';
import { SPLITIO_STATUS } from '../constants';

@Injectable({ providedIn: 'root' })
export class SplitIoGuard implements CanActivate, CanLoad {
  constructor(private readonly store: Store<SplitIoState>, private readonly router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.getGuardResult(route);
  }

  public canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.getGuardResult(route);
  }

  private getGuardResult(route: Route | ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const token = route.data?.treatmentToken as string;
    const redirectUrl = route.data?.treatmentFailureRedirectUrl;

    return combineLatest([
      this.store.select(getSplitIoFlagValue(token)),
      of(true).pipe(delay(3000), startWith(false)),
    ]).pipe(
      filter(([status, timeout]) => (status && status !== SPLITIO_STATUS.control) || timeout),
      map(
        ([status, timeout]) => (!timeout && status === SPLITIO_STATUS.on) || this.router.parseUrl(redirectUrl || '/'),
      ),
      take(1),
    );
  }
}
