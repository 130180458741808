<ng-container [ngSwitch]="step">
  <ng-container *ngSwitchCase="steps.Type">
    <span class="title">{{'shared.dashboards.dialog.tile.stepType' | translate}}</span>
  </ng-container>

  <ng-container *ngSwitchCase="steps.Dashboard">
    <span class="title">{{'shared.dashboards.dialog.tile.stepDashboard' | translate}}</span>
  </ng-container>

  <ng-container *ngSwitchCase="steps.Settings">
    <ts-button theme="secondary"
               data-cy="tile-dialog-steps-back-button"
               (clicked)="back.emit(initialStep)"
               [isSmall]="true">
      <i class="fas fa-arrow-left"></i>
    </ts-button>
    <span class="title">{{'shared.dashboards.dialog.tile.stepConfigure' | translate}}</span>
  </ng-container>
</ng-container>
