import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

export function convertToFormData(data: unknown): FormData {
  const form = new FormData();

  if (data && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      form.append(key, data[key]);
    });

    return form;
  }
}

/* 
 The builtin URIQueryEncoder from Angular un-encodes a handful of 
 characters that are allowed in URLs (like +, =, etc) These characters
 should be encoded though, so we avoid this decoding using this implementation.
 See more here: https://github.com/angular/angular/blob/5.2.9/packages/http/src/url_search_params.ts#L35
*/
export const URIQueryEncoder: HttpParameterCodec = {
  encodeValue: (value: string): string => encodeURIComponent(value),
  encodeKey: (key: string): string => encodeURIComponent(key),
  decodeValue: (value: string): string => decodeURIComponent(value),
  decodeKey: (key: string): string => decodeURIComponent(key),
}

export function getHttpParams(params: unknown): HttpParams {
  if (typeof params === 'object' && params !== null) {
    const fromObject = Object.keys(params).reduce((acc, key: string) => {
      if (params[key]) {
        acc[key] = params[key];
      }

      return acc;
    }, {});

    return new HttpParams({fromObject, encoder: URIQueryEncoder});
  }

  return new HttpParams({fromObject: {}});
}

export function getQueryString(params: Params): string {
  if (!params || typeof params !== 'object') {
    return '';
  }

  return Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => {
      // if param is array we have to separate each item in array and join it with & symbol
      // incoming data: { filters: ['test', 'test2'] }
      // result: filters=test&filters=test2
      if (Array.isArray(params[key])) {
        return params[key].map(item => [key, item].map(encodeURIComponent).join('=')).join('&');
      }
      return [key, params[key]].map(encodeURIComponent).join('=');
    }).join('&');
}

