import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, differenceInMilliseconds, format, isBefore } from 'date-fns';

import { hubTokenName, TILE_DATA_FIELD_NAME } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { CampaignSpecificSource } from '../sources/campaign-specific.source';
import {
  ICampaignChart,
  ICampaignChartData,
  ICampaignChartType,
  ICampaignCohortsForOrgs,
  ICampaignGroupInfo,
  ICampaignIsOrphan,
  ICampaignIsOrphanParams,
  ICampaignTouchesResponse,
  ICohortForOrgsParams
} from '../interfaces';
import { FrequencyType } from '@measurement-studio/enums';
import { DataTypeEnum, DateCohortsGroups, RouteItemEnum, TileTypes, ToDate } from '@shared/enums';
import { getCampaignSpecificFiltersFromParams, getDatesBasedOnFrequency } from '@measurement-studio/util/helpers';
import { replaceLegacyCohort } from '@util/helpers';
import {
  IDashboardTile,
  IDateCohort,
  IGetTileData,
  ILabelValue,
  ITileData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { CampaignChartTypes } from '../enums/campaign-chart.enum';
import * as DateCohortsStore from '@date-cohorts';
import { CAMPAIGN_CHART_OPTIONS, TileSettingsAttributionFields } from '../data/attribution.data';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import * as OrgConfigStore from '@org-config';
import { CampaignsService } from '@shared/data-access/campaigns';
import { CampaignsOptionsProvider, ChannelsOptionsProvider } from '@shared/data-access/options-providers';
import { ICampaignOrChannelAttribution, ICampaignSpecificFilters } from '@measurement-studio/interfaces';

@Injectable({providedIn: 'root'})
export class CampaignSpecificService implements IGetTileData<ICampaignSpecificFilters> {

  constructor(private source: CampaignSpecificSource,
              private store: Store<unknown>,
              private retry: RetryWithEscalation,
              private translateService: TranslateService,
              private campaignsService: CampaignsService,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignOrChannelAttribution$(params: ICampaignSpecificFilters,
                                   selectedReport: RouteItemEnum): Observable<ICampaignOrChannelAttribution> {
    return regenerateOnRetry(() => this.source.getCampaignOrChannelAttribution$(params, selectedReport))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileData$(params: ICampaignSpecificFilters, selectedReport: RouteItemEnum): Observable<ITileData> {
    const dateFieldFromDataset = new DataSetOptions().getDateFieldFromDataset(params.dataSet);
    const cohort = replaceLegacyCohort(params.cohort) || ToDate.QUARTER_TO_DATE; // default value
    const cohortObj = cohort !== DateCohortsGroups.Custom
      ? this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(cohort)))
      : of({
        name: 'Custom Range',
        cohort: DateCohortsGroups.Custom,
        endDate: params.endDate,
        startDate: params.startDate,
      } as IDateCohort);
    return this.source.getCampaignOrChannelAttribution$({...params, cohort}, selectedReport).pipe(
      withLatestFrom(
        cohortObj,
        this.getSelectedChartOptions()
      ),
      map(([data, dateCohort, option]: [ICampaignOrChannelAttribution, IDateCohort, ICampaignChartType]) =>
        this.tileAdapter(data, dateCohort, dateFieldFromDataset, option))
    );
  }

  getTileVisualizationConfig$(_tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    return this.getSelectedChartOptions().pipe(
      map((options: ICampaignChartType) => {
        const numberFormat = options.dataType === DataTypeEnum.Currency
          ? '$#a'
          : '#a';
        const numberFormatGlobal = options.dataType === DataTypeEnum.Currency
          ? '$#,###.00'
          : '#,###.';
        return {
          metricLabel: options.label,
          chart: {
            numberFormatter: {
              numberFormat: numberFormatGlobal
            },
            yAxes: [{
              type: 'ValueAxis',
              min: 0,
              title: {
                text: options.label,
                fill: '#637178'
              },
              numberFormatter: {
                type: 'NumberFormatter',
                forceCreate: true,
                numberFormat
              }
            }],
          }
        };
      })
    );
  }

  getCampaignTouches$(params: ICampaignSpecificFilters, selectedReport: RouteItemEnum): Observable<ICampaignTouchesResponse> {
    return regenerateOnRetry(() => this.source.getCampaignTouches$(params, selectedReport))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignGroupInfo$(body: ICampaignSpecificFilters): Observable<ICampaignGroupInfo> {
    return regenerateOnRetry(() => this.source.getCampaignGroupInfo$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignIsOrphan$(body: ICampaignIsOrphanParams): Observable<ICampaignIsOrphan> {
    return regenerateOnRetry(() => this.source.getCampaignIsOrphan$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignCohortsForOrgs$(body: ICohortForOrgsParams): Observable<ICampaignCohortsForOrgs> {
    return regenerateOnRetry(() => this.source.getCampaignCohortsForOrgs$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  // NOTE: this functionality is from hub-ajs campaign-ctrl.js calc.compare
  getTrendingChatData(
    attributionData: ICampaignOrChannelAttribution,
    dateCohort: IDateCohort,
    dateField: string): ICampaignChart {
    const createdDate = new Date(attributionData.campaign.createdDate).getTime();
    const cohortDistance = differenceInMilliseconds(dateCohort.endDate, dateCohort.startDate);
    const campaignDistance = differenceInMilliseconds(dateCohort.endDate, createdDate);
    let startDate;
    let endDate;
    if (dateCohort.cohort === DateCohortsGroups.Custom) {
      startDate = dateCohort.startDate;
      endDate = dateCohort.endDate;
    } else {
      startDate = cohortDistance >= campaignDistance ? createdDate : dateCohort.startDate;
      endDate = dateCohort.endDate;
    }
    const frequency = this.getFrequencyStepsFromDates(startDate, endDate);
    const dates = getDatesBasedOnFrequency(new Date(startDate), new Date(endDate), frequency);
    return {
      [CampaignChartTypes.CampaignResponses]: attributionData.leads.length
        ? this.getHistoricalData(attributionData.leads, dates, dateField, true) : [],
      [CampaignChartTypes.Opportunities]: attributionData.opptys.length
        ? this.getHistoricalData(attributionData.opptys, dates, dateField, true) : [],
      [CampaignChartTypes.Deals]: attributionData.deals.length
        ? this.getHistoricalData(attributionData.deals, dates, dateField, true) : [],
      [CampaignChartTypes.PipelineAttributed]: attributionData.opptys.length
        ? this.getHistoricalData(attributionData.opptys, dates, dateField, false, 'amountPipeline') : [],
      [CampaignChartTypes.RevenueAttributed]: attributionData.deals.length
        ? this.getHistoricalData(attributionData.deals, dates, dateField, false, 'amount') : []
    };
  }

  // NOTE: this functionality is from hub-ajs campaignPerformanceTrend-srvc.js parseTrends
  /**
   * @param items - attribution data
   * @param dates - array of dates
   * @param dateField - string; activityDate, createdDate, closeDate
   * @param needCount - boolean; whether to show counts (true) or sum (false)
   * @param sumField - string; if showing sum, which field to sum
   */
  getHistoricalData<T>(items: T[],
                       dates: Date[],
                       dateField: string,
                       needCount: boolean,
                       sumField?: string): ICampaignChartData[] {
    return dates.reduce((acc: ICampaignChartData[], interval: Date) => {
      const stepData = items.filter(touch => {
        // if the touch occurred before this moment, return true to count it
        return isBefore(new Date(touch[dateField]), interval);
      });
      if (needCount) {
        acc.push({
          date: interval,
          total: stepData.length
        });
      } else if (sumField) {
        acc.push({
          date: interval,
          total: stepData.reduce((sum: number, item: T) => {
            return sum + item[sumField];
          }, 0)
        });
      }

      return acc;
    }, []);
  }

  // NOTE: this functionality is from hub-ajs utilities-srvc.js
  getFrequencyStepsFromDates(startDate: number, endDate: number): FrequencyType {
    // get bounds of selected cohort -- this is going to be your window
    const distance = differenceInDays(endDate, startDate);
    const monthCutoffInterval = 1460;
    const weekCutoffInterval = 200;
    const dayCutoffInterval = 13;

    if (distance <= dayCutoffInterval) {
      return FrequencyType.Days;
    }
    if (distance <= weekCutoffInterval) {
      return FrequencyType.Week;
    }
    if (distance <= monthCutoffInterval) {
      return FrequencyType.Month;
    }
    return FrequencyType.Year;
  }

  getCampaignSpecificFiltersFromParams(params: Params, models: ILabelValue[]): ICampaignSpecificFilters {
    return getCampaignSpecificFiltersFromParams(params, models);
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    // TODO: replace empty array with real data, figure out how it should work
    const defaultParams = this.getCampaignSpecificFiltersFromParams(params, []);
    return {
      ...params, // in case if we have global filters we have to keep them
      ...defaultParams,
      [TileSettingsAttributionFields.ModelType]: params[TileSettingsAttributionFields.ModelType] || defaultParams.modelType,
    };
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[]> {
    const optionsProvider = type === TileTypes.CampaignSpecific
      ? new CampaignsOptionsProvider(this.campaignsService, this.store)
      : new ChannelsOptionsProvider(this.campaignsService, this.store);
    return this.store.pipe(
      select(OrgConfigStore.getUserModels),
      map((models: ILabelValue[]) => [{
        key: TileSettingsAttributionFields.Campaign,
        label: 'shared.dashboards.settings.campaign',
        type: TileSettingsControlTypes.SelectorWithOptionsProvider,
        optionsProvider,
      }, {
        key: TileSettingsAttributionFields.DataSet,
        label: 'measurementStudio.features.common.filters.dateField',
        type: TileSettingsControlTypes.Selector,
        options: new DataSetOptions().getCampaignDataOptions(),
      }, {
        key: TileSettingsAttributionFields.InfluenceType,
        label: 'measurementStudio.features.common.filters.influenceType',
        type: TileSettingsControlTypes.Selector,
        options: INFLUENCE_TYPE_OPTIONS,
      }, {
        key: TileSettingsAttributionFields.ModelType,
        label: 'measurementStudio.features.common.filters.attributionModel',
        type: TileSettingsControlTypes.Selector,
        options: models,
      }]),
    );
  }

  private getSelectedChartOptions(): Observable<ICampaignChartType> {
    // use campaign responses as default data
    // right now we don't have ability to select what type of data to show in the tile
    const selectedChart = CAMPAIGN_CHART_OPTIONS.find(option =>
      option.value === CampaignChartTypes.CampaignResponses);
    return this.translateService.get(selectedChart.label).pipe(
      map((label: string) => ({
        ...selectedChart,
        label
      }))
    );
  }

  private tileAdapter(data: ICampaignOrChannelAttribution,
                      dateCohort: IDateCohort,
                      dateField: string,
                      option: ICampaignChartType): ITileData {
    const empty = {
      items: []
    };
    if (!data) {
      return empty;
    }
    const allTypesData = this.getTrendingChatData(data, dateCohort, dateField);
    // use campaign responses as default data
    // right now we don't have ability to select what type of data to show in the tile
    const tileData = allTypesData ? allTypesData[CampaignChartTypes.CampaignResponses] : [];

    if (!tileData.length) {
      return empty;
    }

    const items = tileData.map((item: ICampaignChartData) => ({
      [TILE_DATA_FIELD_NAME]: format(item.date, 'MMM, yyyy'),
      [dateCohort.name]: item.total
    }));
    return {
      items,
      metric: {
        current: {
          value: items[items.length - 1][dateCohort.name] as number,
          dataType: option.dataType || DataTypeEnum.Number
        },
      }
    };
  }
}
