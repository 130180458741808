import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import camelcaseKeys from 'camelcase-keys';

import { HttpClientBase } from '@shared/http-client-base';
import { ICampaignPerformanceSource } from './campaign-performance.source.interface';
import { ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';
import { getHttpParams } from '@util/http';

export enum CampaignPerformanceSourceURLs {
  GetCampaignPerformance = '/api/v1/campaign_performance',
  GetCampaignTotalAmount = '/api/v1/campaign_total_amount',
}

@Injectable()
export class CampaignPerformanceSource extends HttpClientBase implements ICampaignPerformanceSource {

  getCampaignPerformance$(params: ICampaignFilters): Observable<ICampaignPerformance> {
    const endpoint = this.endpoint(CampaignPerformanceSourceURLs.GetCampaignPerformance);

    return this.httpClient.get<ICampaignPerformance>(endpoint, {
      params: getHttpParams(params)
    });
  }

  getCampaignTotalAmount$(params: ICampaignFilters): Observable<ICampaignTotalAmount> {
    const endpoint = this.endpoint(CampaignPerformanceSourceURLs.GetCampaignTotalAmount);

    return this.httpClient.get<ICampaignTotalAmount>(endpoint, {
      params: getHttpParams(params)
    }).pipe(
      map(data => {
        if (data) {
          // NOTE: to avoid modifying campaign ids by camelcaseKeys function we have to keep them
          // without changes but we have to modify campaign data.
          // { id: { campaign data }}
          const campaigns = Object.keys(data.campaigns).reduce((acc, campaignId: string) => {
            acc[campaignId] = camelcaseKeys(data.campaigns[campaignId], {deep: true});
            return acc;
          }, {});

          return {
            ...camelcaseKeys(data, {deep: true, stopPaths: ['campaigns']}),
            campaigns
          };
        }
      })
    );
  }
}
