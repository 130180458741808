import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { panicActions } from './panic.actions';
import { FullStoryService } from '@fullstory/fullstory.service';

@Injectable()
export class PanicEffects {

  constructor(
    private actions$: Actions,
    private fullStoryService: FullStoryService,
  ) {
  }

  addMessage$ = createEffect(() => this.actions$.pipe(
    ofType(panicActions.addPanicMessage),
    map(action => action.message),
    tap(error => {
      this.fullStoryService.sendEvent('Measurement-Dot: Panic', {
        error_info: error
      });
    })
  ), { dispatch: false });
}
