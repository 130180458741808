import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { IDateCohort, IDateCohortGroup, ILabelValue } from '@shared/interfaces';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { IBenchmarkConfirmEvent } from '@ui/components/benchmark-control';
import { ITrendingParams } from '../../interfaces';

@Component({
  selector: 'ms-trending-filters',
  templateUrl: './trending-filters.component.html',
  styleUrls: ['./trending-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingFiltersComponent implements OnChanges {
  @Input() public isLoading = false;
  @Input() public isCampaignTrendsReport = false;
  @Input() public isWebActivitiesTrending = false;
  @Input() public metricOptions: ILabelValue[] = [];
  @Input() public frequencyTypeOptions: ILabelValue[] = [];
  @Input() public modelTypeOptions: ILabelValue[] = [];
  @Input() public filters: ITrendingParams;
  @Input() public dateCohortOptions: IDateCohortGroup[] = [];
  @Input() public selectedDateCohort: IDateCohort;
  @Output() public applyFilters = new EventEmitter<ITrendingParams>();
  @Output() public changeSelectedDateCohort = new EventEmitter<IDateCohort>();

  public filterForm = this.formBuilder.group({
    field: [null, Validators.required],
    freqType: [null, Validators.required],
    dataSet: [null, Validators.required],
    model: [null, Validators.required],
    type: [null, Validators.required],
    previous: [null, Validators.required],
    goal: [null, [Validators.required, Validators.min(0)]],
  });
  public datasetTypeOptions = new DataSetOptions().getDataSetFullOptions();

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      // NOTE: use selectedBenchmark in ui to show as selected instead of filterForm.get('type')
      // because benchmark is changed only after confirm action
      this.filterForm.patchValue({
        field: this.filters.field,
        freqType: this.filters.freqType,
        dataSet: this.filters.dataSet,
        model: this.filters.model,
        type: this.filters.type,
        previous: this.filters.previous,
        goal: this.filters.goal,
      } as any);
    }
    if (changes.isWebActivitiesTrending) {
      this.datasetTypeOptions = this.isWebActivitiesTrending ? new DataSetOptions().getCampaignDataOptions() :
        new DataSetOptions().getDataSetFullOptions();
    }
  }

  /**
   * Apply change for report setting only
   */
  apply(): void {
    this.applyFilters.emit({
      ...this.filters,
      ...this.filterForm.value
    } as ITrendingParams);
  }

  /**
   * Apply change for date cohort only
   */
  applyDateCohort(dateCohort?: IDateCohort): void {
    this.applyFilters.emit({
      ...this.filters,
      cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
      endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
      startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
    });
  }

  changeDateCohort(dateCohort: IDateCohort): void {
    this.changeSelectedDateCohort.emit(dateCohort);
  }

  confirmBenchmarkChange(event: IBenchmarkConfirmEvent): void {
    this.filterForm.patchValue(event as any);
  }
}
