<nav class="ts-paginator__nav">
  <div class="ts-paginator__summary" *ngIf="paginatorSummary">
    {{ paginatorSummary }}
  </div>

  <ts-tooltip
    [tooltipValue]="(templateDisablePrevious$ | async) ? '' : previousPageTooltip"
    #previousTooltip="tsTooltipComponent"
  >
    <button
      class="ts-paginator__button ts-paginator__button--previous"
      [disabled]="templateDisablePrevious$ | async"
      (click)="previousPageClicked.emit()"
    ><ng-container *ngTemplateOutlet="caret"></ng-container></button>
  </ts-tooltip>

  <ol class="ts-paginator__pages" *ngIf="!isSimpleMode">
    <ng-container *ngIf="pages$ | async as domPages">
      <ng-container *ngFor="let page of domPages; let i=index;">
        <ng-container *ngIf="activePageIndex$.getValue() | tsPaginatorDom:i:domPages.length:'button'">
          <ng-container *ngIf="activePageIndex$.getValue() | tsPaginatorDom:i:domPages.length:'ellipsis-start'">
            <li class="ts-paginator__page ts-paginator__page--ellipsis">...</li>
          </ng-container>
          <li class="ts-paginator__page ts-paginator__page--button">
            <button
              class="ts-paginator__button"
              [class.ts-paginator__button--active]="(activePageIndex$ | async) === page.pageNumber"
              (click)="pageClicked.emit(page.pageNumber)"
            >{{ page.pageDisplay }}</button>
          </li>
          <ng-container *ngIf="activePageIndex$.getValue() | tsPaginatorDom:i:domPages.length:'ellipsis-end'">
            <li class="ts-paginator__page ts-paginator__page--ellipsis">... </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ol>

  <ts-tooltip
    [tooltipValue]="(templateDisableNext$ | async) ? '' : nextPageTooltip"
    class="tooltip-next"
  >
    <button
      class="ts-paginator__button ts-paginator__button--next"
      [disabled]="templateDisableNext$ | async"
      (click)="nextPageClicked.emit()"
    ><ng-container *ngTemplateOutlet="caret"></ng-container></button>
  </ts-tooltip>
</nav>

<ng-template #caret>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="angle-right"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 512"
  >
    <path
      fill="currentColor"
      d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
    ></path>
  </svg>
</ng-template>
