import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsTabsModule } from '@terminus-lib/ui-tabs';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { ScorecardComponent } from './containers/scorecard.component';
import { ScorecardRoutingModule } from './scorecard-routing.module';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { reducer, scorecardFeatureKey } from './state/scorecard.reducer';
import { ScorecardEffects } from './state/scorecard.effects';
import { AddToDashboardButtonModule } from '@ui/components/add-to-dashboard-button';
import { PortalDirectiveModule } from '@ui/directives/portal';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ScorecardRoutingModule,
    TsButtonModule,
    TsOptionModule,
    TsTabsModule,
    TsTooltipModule,
    NoReportResultModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(scorecardFeatureKey, reducer),
    EffectsModule.forFeature([
      ScorecardEffects
    ]),
    AddToDashboardButtonModule,
    PortalDirectiveModule
  ],
  declarations: [
    ScorecardComponent
  ]
})
export class ScorecardModule {
}
