import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';

import { DefaultTileInformation } from '@shared/interfaces';
import { DEFAULT_TILE_INFORMATION } from '@shared/constants';
import { TileTypes, VisualizationTypes } from '@shared/enums';
import { VISUALIZATION_ICON_PATH_MAPPER } from '../../../tile-modal.constant';

@Component({
  selector: 'tsh-visualization-types',
  templateUrl: './visualization-types.component.html',
  styleUrls: ['./visualization-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualizationTypesComponent {
  @Input() public tileType: TileTypes;
  @Input() public control: FormControl;

  public readonly mapper: Record<TileTypes, DefaultTileInformation> = DEFAULT_TILE_INFORMATION;
  public readonly visualizationMapper: Record<VisualizationTypes, { iconPath: string, label: string }> = VISUALIZATION_ICON_PATH_MAPPER;
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TsRadioGroupModule,
    ReactiveFormsModule,
  ],
  declarations: [VisualizationTypesComponent],
  exports: [VisualizationTypesComponent],
})
export class VisualizationTypesModule {
}
