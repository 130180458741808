/* istanbul ignore file */
import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import {
  ICampaignPageEvent,
  ICampaignRowEvent,
  ICampaignSortEvent,
  IGroupDetail,
  IGroupDetailActionSuccess,
  ITrendingCampaignDetails,
  ITrendingGroupDetailsActionParams,
  ITrendingParams,
  ITrendingTotal
} from '../interfaces';
import { RouteItemEnum } from '@shared/enums';
import { IDateCohort, ILabelValue } from '@shared/interfaces';
import { TrendingDataPeriod } from '../enums';

export enum TrendingActionsTypes {
  GetMetricOptions = '[Trending] Get metric options for filters functionality',
  SetMetricOptions = '[Trending] Set metric options for filters functionality',
  SetSelectedReport = '[Trending] Set selected report id',
  LoadTotal = '[Trending] Load Total',
  LoadTotalSuccess = '[Trending] Load Total Success',
  LoadTotalFailure = '[Trending] Load Total Failure',
  GetGroupDetails = '[Trending] Get Group Details',
  LoadGroupDetails = '[Trending] Load Group Details',
  LoadGroupDetailsSuccess = '[Trending] Load Group Details Success',
  LoadGroupDetailsFailure = '[Trending] Load Group Details Failure',
  LoadDetails = '[Trending] Load Details',
  LoadDetailsSuccess = '[Trending] Load Details Success',
  LoadDetailsFailure = '[Trending] Load Details Failure',
  SetFilters = '[Trending] Set Filters',
  GetFilters = '[Trending] Get Filters',
  SetSelectedDateCohort = '[Trending] Set selected date cohort',
  GetSelectedDateCohort = '[Trending] Get selected date cohort',
  SetComparativeTablePage = '[Trending] Set Comparative Table page',
  DownloadComparativeCSV = '[Trending] Download comparative CSV',
  ChangeGroupDetailsSortState = '[Trending] Change Group Details SortState',
  ToggleExpandGroupDetailsRow = '[Trending] Open or close group details row',
  ChangeGroupDetailsRowState = '[Trending] Change row state for group details table',
  DownloadGroupDetailsCSV = '[Trending] Download group details CSV',
  ChangeCampaignDetailsSortState = '[Trending] Change Campaign Details SortState',
  ChangeCampaignDetailsRowState = '[Trending] Change row state for Campaign details table',
  ChangeCampaignDetailsPagination = '[Trending] Change pagination for Campaign details table',
  DownloadCampaignDetailsCSV = '[Trending] Download campaign details CSV',
  DownloadResponsesCSV = '[Trending] Download responses CSV',
  ClearGroupDetails = '[Trending] Clear Group Details',
  ClearReportData = '[Trending] Clear Report Data',
  DownloadCSVSuccess = '[Trending] Download CSV Success',
  DownloadCSVFailure = '[Trending] Download CSV Failure',
}

const downloadResponsesCSV = createAction(
  TrendingActionsTypes.DownloadResponsesCSV,
  props<{ group: string, period: TrendingDataPeriod, id: string }>()
);


const downloadCampaignDetailsCSV = createAction(
  TrendingActionsTypes.DownloadCampaignDetailsCSV,
  props<{ group: string, period: TrendingDataPeriod}>()
);

const downloadGroupDetailsCSV = createAction(
  TrendingActionsTypes.DownloadGroupDetailsCSV,
  props<{ period: TrendingDataPeriod}>()
);

const changeCampaignDetailsSortState = createAction(
  TrendingActionsTypes.ChangeCampaignDetailsSortState,
  props<{ sortEvent: ICampaignSortEvent}>()
);

const changeCampaignDetailsPagination = createAction(
  TrendingActionsTypes.ChangeCampaignDetailsPagination,
  props<{ pageEvent: ICampaignPageEvent}>()
);

const changeCampaignDetailsRowState = createAction(
  TrendingActionsTypes.ChangeCampaignDetailsRowState,
  props<{ rowEvent: ICampaignRowEvent}>()
);

const changeGroupDetailsRowState = createAction(
  TrendingActionsTypes.ChangeGroupDetailsRowState,
  props<{ group: string, period: TrendingDataPeriod }>()
);

const toggleExpandGroupDetailsRow = createAction(
  TrendingActionsTypes.ToggleExpandGroupDetailsRow,
  props<{ group: IGroupDetail, period: TrendingDataPeriod }>()
);

const changeGroupDetailsSortState = createAction(
  TrendingActionsTypes.ChangeGroupDetailsSortState,
  props<{ state: TsSortState, period: TrendingDataPeriod }>()
);

const getMetricOptions = createAction(
  TrendingActionsTypes.GetMetricOptions
);

const setMetricOptions = createAction(
  TrendingActionsTypes.SetMetricOptions,
  props<{ value: ILabelValue[]}>()
);

const setSelectedReport = createAction(
  TrendingActionsTypes.SetSelectedReport,
  props<{ routeItem: RouteItemEnum }>()
);


const loadTotal = createAction(
  TrendingActionsTypes.LoadTotal,
  props<{ period: TrendingDataPeriod }>()
);

const loadTotalSuccess = createAction(
  TrendingActionsTypes.LoadTotalSuccess,
  props<{ data: ITrendingTotal, period: TrendingDataPeriod }>()
);


const loadTotalFailure = createAction(
  TrendingActionsTypes.LoadTotalFailure,
  props<{ message: string, period: TrendingDataPeriod }>()
);

const getGroupDetails = createAction(
  TrendingActionsTypes.GetGroupDetails,
  props<ITrendingGroupDetailsActionParams>()
);

const loadGroupDetails = createAction(
  TrendingActionsTypes.LoadGroupDetails,
  props<ITrendingGroupDetailsActionParams>()
);

const loadGroupDetailsSuccess = createAction(
  TrendingActionsTypes.LoadGroupDetailsSuccess,
  props<IGroupDetailActionSuccess>()
);

const loadGroupDetailsFailure = createAction(
  TrendingActionsTypes.LoadGroupDetailsFailure,
  props<{ message: string, period: TrendingDataPeriod }>()
);

const loadDetails = createAction(
  TrendingActionsTypes.LoadDetails,
  props<{ period: TrendingDataPeriod, group: string }>()
);

const loadDetailsSuccess = createAction(
  TrendingActionsTypes.LoadDetailsSuccess,
  props<{ campaigns: ITrendingCampaignDetails[], period: TrendingDataPeriod, group: string }>()
);

const loadDetailsFailure = createAction(
  TrendingActionsTypes.LoadDetailsFailure,
  props<{ error: string, period: TrendingDataPeriod }>()
);

const setFilters = createAction(
  TrendingActionsTypes.SetFilters,
  props<{ filters: ITrendingParams }>()
);

//checked
const getFilters = createAction(
  TrendingActionsTypes.GetFilters,
  props<Params>()
);

const setSelectedDateCohort = createAction(
  TrendingActionsTypes.SetSelectedDateCohort,
  props<{ dateCohort: IDateCohort }>()
);

const getSelectedDateCohort = createAction(
  TrendingActionsTypes.GetSelectedDateCohort,
  props<{ filters: ITrendingParams }>()
);

const setComparativeTablePage = createAction(
  TrendingActionsTypes.SetComparativeTablePage,
  props<{ page: number }>()
);

const downloadComparativeCSV = createAction(
  TrendingActionsTypes.DownloadComparativeCSV
);

const clearGroupDetails = createAction(
  TrendingActionsTypes.ClearGroupDetails,
  props<{ period: TrendingDataPeriod }>()
);

const clearReportData = createAction(
  TrendingActionsTypes.ClearReportData
);

const downloadCSVSuccess = createAction(
  TrendingActionsTypes.DownloadCSVSuccess,
  props<{ csvContent: string }>()
);

const downloadCSVFailure = createAction(
  TrendingActionsTypes.DownloadCSVFailure,
  props<{ message: string }>()
);

export const trendingActions = {
  downloadResponsesCSV,
  downloadCampaignDetailsCSV,
  downloadGroupDetailsCSV,
  changeGroupDetailsSortState,
  changeCampaignDetailsSortState,
  changeCampaignDetailsPagination,
  changeCampaignDetailsRowState,
  changeGroupDetailsRowState,
  getMetricOptions,
  setMetricOptions,
  toggleExpandGroupDetailsRow,
  setSelectedReport,
  loadTotal,
  loadTotalSuccess,
  loadTotalFailure,
  getGroupDetails,
  loadGroupDetails,
  loadGroupDetailsSuccess,
  loadGroupDetailsFailure,
  loadDetails,
  loadDetailsSuccess,
  loadDetailsFailure,
  setFilters,
  getFilters,
  setSelectedDateCohort,
  getSelectedDateCohort,
  setComparativeTablePage,
  downloadComparativeCSV,
  clearGroupDetails,
  clearReportData,
  downloadCSVSuccess,
  downloadCSVFailure
};
