import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NoReportResultComponent } from './no-report-result.component';

@NgModule({
  declarations: [NoReportResultComponent],
  exports: [NoReportResultComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ]
})
export class NoReportResultModule {
}

