<ts-tooltip
  *ngIf="column?.tooltip; else noTooltipHeader"
  [tooltipValue]="column?.tooltip | translate"
  [tooltipPosition]="'above'"
  [hasUnderline]="false"
>
  {{ column?.displayName | translate }}
</ts-tooltip>
<ng-template #noTooltipHeader>
  <div [title]="column?.displayName | translate">
    {{ column?.displayName | translate }}{{ index > 0 ? index : "" }}
  </div>
</ng-template>
