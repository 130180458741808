import * as Colors from './report-colors.consts';
import {
  IInsightsMetricBarItem,
  IAdsInfluencedTotalsCampaignResponsesResponse,
  IAdsInfluencedTotalsLeadResponse,
  IAdsInfluencedTotalsNewPipelineResponse,
  IAdsInfluencedTotalsPageViewResponse,
  IAdsInfluencedTotalsPipelineResponse,
  IAdsInfluencedTotalsRevenueResponse
} from '../interfaces';

export const INSIGHTS_PAGE_METRIC_ITEMS: Partial<IInsightsMetricBarItem<string>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.accountsReached',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.accountsReached',
    valueProperty: 'accountsReached',
    valueFormat: 'DECIMAL',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalTactics',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.tactics',
    valueProperty: 'tactics',
    valueFormat: 'DECIMAL',
    displayNegative: true
  }
];

export const PAGE_VIEWS_METRIC_ITEMS: Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsPageViewResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalPageViews',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.pageViews',
    legendColor: Colors.PAGE_VIEWS_INFLUENCED_COLOR,
    valueProperty: 'pageViews',
    valueFormat: 'DECIMAL',
    digitsInfo: '1.0-0',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.costPerPageView',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.costPerPageView',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerPageView',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.pageViewLift',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.pageViewLift',
    valueProperty: 'pageViewLift',
    valueFormat: 'DECIMAL',
    valueAppend: '%',
    digitsInfo: '1.2-2',
    displayNegative: false
  }
];

export const LEADS_INFLUENCED_METRIC_ITEMS: Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsLeadResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalLeads',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.leadsInfluenced',
    legendColor: Colors.LEADS_INFLUENCED_COLOR,
    valueProperty: 'totalLeads',
    valueFormat: 'DECIMAL',
    digitsInfo: '1.0-0',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.costPerLead',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.costPerLead',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerLead',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.averageDaysToCreateNewLead',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.averageDaysToCreateNewLead',
    valueProperty: 'daysToLeadCreated',
    valueFormat: 'DECIMAL',
    digitsInfo: '1.0-0',
    displayNegative: true
  }
];

export const CAMPAIGN_RESPONSES_INFLUENCED_METRIC_ITEMS:
  Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsCampaignResponsesResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalCampaignResponses',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.campaignResponsesInfluenced',
    legendColor: Colors.CAMPAIGN_RESPONSES_INFLUENCED_COLOR,
    valueProperty: 'totalResponses',
    valueFormat: 'DECIMAL',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.costPerResponse',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.costPerResponse',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerResponse',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.campaignResponseLift',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.campaignResponseLift',
    valueProperty: 'campaignResponseLift',
    valueFormat: 'DECIMAL',
    valueAppend: '%',
    displayNegative: false
  }
];

export const NEW_PIPELINE_INFLUENCED_METRIC_ITEMS: Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsNewPipelineResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalNewPipeline',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.totalNewPipeline',
    legendColor: Colors.NEW_PIPELINE_INFLUENCED_COLOR,
    valueProperty: 'totalNewPipeline',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.costPerOpportunity',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.costPerOpportunity',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerOpportunity',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalPipelineInfluencedByAds',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.pipelineInfluenced',
    valueProperty: 'percentInfluenced',
    valueFormat: 'DECIMAL',
    valueAppend: '%',
    digitsInfo: '1.2-2',
    displayNegative: true
  }
];

export const PIPELINE_INFLUENCED_METRIC_ITEMS: Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsPipelineResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.openPipeline',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.existingPipelineInfluenced',
    legendColor: Colors.PIPELINE_INFLUENCED_COLOR,
    valueProperty: 'openPipeline',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.costPerOpportunity',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.costPerOpportunity',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerOpportunity',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalPipelineInfluencedByAds',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.pipelineInfluenced',
    valueProperty: 'percentInfluenced',
    valueFormat: 'DECIMAL',
    valueAppend: '%',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
];

export const PIPELINE_INFLUENCED_METRIC_ITEMS_WITH_SPLIT_IO_FLAGS : Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsPipelineResponse>>[] = [{
  label: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.valueLift.title',
  tooltip: 'measurementStudio.features.advertisingInsights.tooltips.pipelineInfluencedValueLift',
  valueProperty: 'valueLift',
  valueFormat: 'DECIMAL',
  valueAppend: '%',
  digitsInfo: '1.2-2',
  displayNegative: true
}];

export const REVENUE_INFLUENCED_METRIC_ITEMS: Partial<IInsightsMetricBarItem<keyof IAdsInfluencedTotalsRevenueResponse>>[] = [
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalRevenueInfluenced',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.revenueInfluenced',
    legendColor: Colors.REVENUE_INFLUENCED_COLOR,
    valueProperty: 'totalNewRevenue',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.returnOnAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.returnOnAdSpend',
    legendColor: Colors.INSIGHTS_SECONDARY_LINE_SERIES_COLOR,
    valueProperty: 'costPerDeal',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalAdSpend',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.adSpend',
    valueProperty: 'adSpend',
    valueFormat: 'CURRENCY',
    digitsInfo: '1.2-2',
    displayNegative: true
  },
  {
    label: 'measurementStudio.features.advertisingInsights.metrics.totalRevenueInfluencedPercent',
    tooltip: 'measurementStudio.features.advertisingInsights.tooltips.percentInfluenced',
    valueProperty: 'percentInfluenced',
    valueFormat: 'DECIMAL',
    valueAppend: '%',
    digitsInfo: '1.2-2',
    displayNegative: true
  }
];
