import { IFactoryParams } from '@shared/interfaces';
import { ICampaignInfluenceTotalsData } from './campaign-influence-totals.interface';

export enum TotalsSlice {
  Campaigns = 'campaigns',
  CampaignGroups = 'campaignGroups',
  Source = 'source',
  Campaign = 'campaign',
  Mediums = 'mediums',
}

export type LoadTotalsPayload = TotalsSlice | string;

export const totalsTableKeys: LoadTotalsPayload[] =
  [TotalsSlice.Campaigns, TotalsSlice.CampaignGroups, TotalsSlice.Source, TotalsSlice.Campaign, TotalsSlice.Mediums];

export interface ITotalsSuccessPayload<T> {
  data: T[];
  key: LoadTotalsPayload;
}

export function totalsSuccessPayloadFactory({
  overrides = {}
}: IFactoryParams<ITotalsSuccessPayload<ICampaignInfluenceTotalsData>, number> = {}): ITotalsSuccessPayload<ICampaignInfluenceTotalsData> {
  return {
    data: [],
    key: TotalsSlice.Campaigns,
    ...overrides
  };
}
