import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { IAppliedGlobalFiltersAsParams, IReportColumn, userMessageFactory } from '@shared/interfaces';
import { CampaignPerformanceService } from '../services/campaign-performance.service';
import { attributionCampaignActions } from './attribution-campaign.actions';
import * as AttributionCampaignSelectors from './attribution-campaign.selectors';
import { notificationMessagesActions } from '@notification-messages';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { ICampaignListItem, ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';
import { DataTypeEnum, ModelType } from '@shared/enums';
import {
  IAttributionCampaignExpandedRow, IAttributionCampaignRow,
  IAttributionCampaignTotals
} from '../interfaces/attribution-campaign-report.interface';
import * as OrgConfigStore from '@org-config';
import { AttributionUsedQueryParams, ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { isFiltersChanged } from '@util/helpers';
import { AttributionCampaignState } from './attribution-campaign.reducer';

@Injectable()
export class AttributionCampaignEffects {

  constructor(public campaignService: CampaignPerformanceService,
              private actions$: Actions,
              private store: Store<AttributionCampaignState>,
              private downloadService: DownloadCsvService,
              private translate: TranslateService
  ) {
  }

  loadCampaignPerformance$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.loadCampaignPerformance),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
      )
    )),
    mergeMap(([_, filters, globalFilters]) => {
      const params = {...filters, ...globalFilters} as AttributionUsedQueryParams;
      return this.campaignService.getCampaignPerformance$(params).pipe(
        mergeMap(data => [
          attributionCampaignActions.loadCampaignPerformanceSuccess({data}),
          attributionCampaignActions.setAttributionUsedQueryParams({params})
        ]),
        catchError((error: HttpErrorResponse) => {
          const message$ = error?.message
            ? of(error.message)
            : this.translate.get('measurementStudio.features.attributionCampaign.errors.loadCampaignPerformance');

          return message$.pipe(
            map(message => attributionCampaignActions.loadCampaignPerformanceFailure({error: message}))
          );
        })
      );
    })
    )
  );

  loadTotalAmount$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.loadTotalAmount),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
      )
    )),
    mergeMap(([_, filters, globalFilters]) =>
      this.campaignService.getCampaignTotalAmount$({...filters, ...globalFilters}).pipe(
        map(data => attributionCampaignActions.loadTotalAmountSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message$ = error?.message
            ? of(error.message)
            : this.translate.get('measurementStudio.features.attributionCampaign.errors.loadTotalAmount');

          return message$.pipe(
            map(message => attributionCampaignActions.loadTotalAmountFailure({error: message}))
          );
        })
      ))
    )
  );

  onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      attributionCampaignActions.loadTotalAmountFailure,
      attributionCampaignActions.loadCampaignPerformanceFailure
    ),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.error}) }))
  ));

  setFilters$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.setAttributionCampaignFilters),
    concatMap(action => of(action.filters).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(AttributionCampaignSelectors.getUsedQueryParams))
      )
    )),
    filter(([newFilters, globalFilters, usedQueryParams]: [
      ICampaignFilters, IAppliedGlobalFiltersAsParams, AttributionUsedQueryParams
    ]) => isFiltersChanged(usedQueryParams, newFilters, globalFilters)),
    mergeMap(() => [
      attributionCampaignActions.loadCampaignPerformance(),
      attributionCampaignActions.loadTotalAmount()
    ])
  ));

  toggleExtendRow$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.toggleExpandAttributionCampaignRow),
    concatMap(action => of(action.key).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignExpandedRowState))
      )
    )),
    switchMap(([key, expandedRowState]: [string, Record<string, boolean>]) => {
      // check if row is already opened then close it otherwise get info and open it
      if (expandedRowState[key]) {
        return of(attributionCampaignActions.toggleExpandedAttributionCampaignState({
          key,
          state: false
        }));
      }

      return of(attributionCampaignActions.getAttributionCampaignExtendedRow({key}));
    })
  ));

  getExtendedRowInformation$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.getAttributionCampaignExtendedRow),
    concatMap(action => of(action.key).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignExpandedReport))
      )
    )),
    switchMap(([key, expandedReport]) => {
      // check if we've already had information for this group
      // then just open the row
      if (expandedReport[key]) {
        return of(attributionCampaignActions.toggleExpandedAttributionCampaignState({
          key,
          state: true
        }));
      }

      // otherwise collect data for the row and only then open it
      return of(attributionCampaignActions.collectAttributionCampaignRowData({key}));
    })
  ));

  collectExtendedRowData$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.collectAttributionCampaignRowData),
    concatMap(action => of(action.key).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.isAttributionCampaignPipeline)),
        this.store.pipe(select(AttributionCampaignSelectors.getCampaignPerformance)),
        this.store.pipe(select(AttributionCampaignSelectors.getTotalAmount))
      )
    )),
    switchMap(([
                 key, isPipeline, campaignPerformance, totals
               ]: [
      string, boolean, ICampaignPerformance, ICampaignTotalAmount
    ]) => {
      const {list} = campaignPerformance.groups[key];
      const campaigns = list.reduce((arr: IAttributionCampaignExpandedRow[], campaign: ICampaignListItem) => {
        const attributedCount = isPipeline ? campaign[ModelType.Even].opptys : campaign[ModelType.Even].deals;

        if (attributedCount !== 0) {
          const expandedTableData = {
            id: campaign.campaignId,
            name: campaign.campaignName,
            createdDate: campaign.campaignCreatedDate,
            [ModelType.Sourced]: isPipeline ? campaign[ModelType.Sourced].pipeline : campaign[ModelType.Sourced].revenue,
            [ModelType.Last]: isPipeline ? campaign[ModelType.Last].pipeline : campaign[ModelType.Last].revenue,
            [ModelType.Even]: isPipeline ? campaign[ModelType.Even].pipeline : campaign[ModelType.Even].revenue,
            [ModelType.Custom]: isPipeline ? campaign[ModelType.Custom].pipeline : campaign[ModelType.Custom].revenue,
            sourcedCount: isPipeline ? campaign[ModelType.Sourced].opptys : campaign[ModelType.Sourced].deals,
            attributedCount,
            unique: isPipeline ? totals.campaigns[campaign.campaignId]?.opptysUnique : totals.campaigns[campaign.campaignId]?.dealsUnique
          };

          arr.push(expandedTableData);
        }

        return arr;
      }, []);
      return of(attributionCampaignActions.setAttributionCampaignExtendedRow({
        data: campaigns,
        key
      }));
    })
  ));

  downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.downloadCSV),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getSortedReport)),
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignReportTotals)),
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignFilters)),
        this.store.pipe(
          select(AttributionCampaignSelectors.getAttributionCampaignColumns),
          mergeMap(columns => combineLatest(
            columns.map(column => column.displayName
              ? this.translate.get(column.displayName).pipe(
                map(displayName => ({
                  ...column,
                  displayName
                })))
              : of(column)
            )
          ))
        ),
        this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting))
      )
    )),
    switchMap(([
                 _, report, totals, filters, columns, currency
               ]: [
      undefined, IAttributionCampaignRow[], IAttributionCampaignTotals, ICampaignFilters, IReportColumn[], string | null
    ]) => {
      const fileName = `Attribution by Campaign Type-${filters.cohort}`;
      // remove first column because it doesn't contain data (icon)
      this.downloadService.downloadCsv(fileName, report, columns.slice(1), currency, totals);
      return of(attributionCampaignActions.downloadCSVSuccess());
    })
  ));

  downloadCSVForExpandedReport$ = createEffect(() => this.actions$.pipe(
    ofType(attributionCampaignActions.downloadCSVForExpandedReport),
    concatMap(action => of(action.label).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AttributionCampaignSelectors.getExpandedReportByKey, {key: action.key})),
        this.store.pipe(
          select(AttributionCampaignSelectors.getAttributionCampaignExpandedColumns),
          mergeMap(columns => combineLatest(
            columns.map(column => column.displayName
              ? this.translate.get(column.displayName).pipe(
                map(displayName => ({
                  ...column,
                  displayName
                })))
              : of(column)
            )
          ))
        ),
        this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignFilters)),
        this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting))
      )
    )),
    switchMap(([
                 label, report, columns, filters, currency
               ]: [
      string, IAttributionCampaignExpandedRow[], IReportColumn[], ICampaignFilters, string | null
    ]) => {
      const fileName = `Attribution by Campaign Type-${filters.cohort}-${label}-campaigns`;
      const idColumn: IReportColumn = {
        name: 'id',
        width: 100,
        displayName: 'Id',
        dataType: DataTypeEnum.Text
      };
      this.downloadService.downloadCsv(fileName, report, [...columns, idColumn], currency);
      return of(attributionCampaignActions.downloadCSVForExpandedReportSuccess());
    })
  ));
}
