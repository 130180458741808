// Feature Flag State
export const CAN_ACCESS = 'canAccess';
export const LANGUAGES = ['en'];

export const SIGN_OUT_ROUTE = 'sign-out';
export const PROFILE_ROUTE = 'profile';
export const ACADEMY_ROUTE = 'https://customer-academy.terminus.com/app';
export const HELP_CENTER_ROUTE = 'helpcenter';
export const COMMUNITY_ROUTE = 'community';
export const KNOWLEDGE_BASE_ROUTE = 'https://support.terminus.com';

export const LEARN_MORE_DIGITAL_ATTRIBUTION
  = 'https://support.terminus.com/hc/en-us/articles/360053841314-Digital-Attribution-Reporting-in-Measurement-Studio';
export const LEARN_MORE_REVENUE_ATTRIBUTION
  = 'https://support.terminus.com/hc/en-us/articles/360053841254-Revenue-Attribution-Reporting-in-Measurement-Studio';

export const REPORT_NAV_LABEL = 'common.reports';

export const IMAGE_STORAGE_ROUTE = 'https://logo.clearbit.com/';
