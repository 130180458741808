import {
  SORT_COLUMN,
  SORT_DIRECTION,
  CONVERSION_TYPE,
} from '@shared/enums';

export enum PATTERN_ALPHA_SORT {
  asc = 'ALPHA_ASC',
  desc = 'ALPHA_DESC',
}

export enum PATTERN_CHRONO_SORT {
  asc = 'CHRONO_ASC',
  desc = 'CHRONO_DESC',
}


export interface ITacticConversionPatternsRequest {
  marketerUuid?: string;
  sort?: PATTERN_ALPHA_SORT | PATTERN_CHRONO_SORT;
  continuationToken?: string;
}

export interface ITacticConversionPattern {
  pattern: string;
  createdAtMomentUtc?: string | Date;
}

export interface ITacticConversionsValidatePatternsRequest {
  marketerUuid?: string;
  patterns: string[];
}

export interface ITacticConversionsValidatePatternsResponse {
  invalidPatterns?: string[];
}

export interface ITacticConversionPatternsResponse {
  patterns?: ITacticConversionPattern[];
  continuationToken?: string;
}

export interface ITacticConversionRemovePatternRequest {
  marketerUuid?: string;
  pattern: string;
}

export interface ITacticConversionsAddPatternsRequest {
  marketerUuid?: string;
  patterns: string[];
}

export enum ConversionFilterType {
  Any = 0,
  VisitSession = 1,
  TrackedConversions = 2,
}

export interface ITacticConversionReportFilters {
  sortColumn?: SORT_COLUMN;
  sortDirection?: SORT_DIRECTION;
  nameContains?: string;
  conversionType?: ConversionFilterType;
  overallMaximumDaysFromLastTouchToConversion?: number;
  maximumDaysFromLastTouchToConversion?: number;
  minimumConversionMomentUtc?: string | Date;
  maximumConversionMomentUtc?: string | Date;
  minimumAggregationMomentUtc?: string | Date;
  maximumAggregationMomentUtc?: string | Date;
}

export interface IStartReadRequest {
  reportId?: string;
  filters?: ITacticConversionReportFilters;
}

export interface IConversionSegment {
  accountDisplayName: string;
  accountUuid: string;
  conversionMomentUtc: string | Date;
  triggeredPageUrl: string;
  daysFromLastTouchToConversion: number;
  conversionType: CONVERSION_TYPE;
}

export interface IReadReportResponse {
  records: IConversionSegment[];
  continuationToken: string;
}

export enum ReportStatus {
  Unstarted = 'UNSTARTED',
  Importing = 'IMPORTING',
  Ready = 'READY',
  Failed = 'FAILED',
}

export interface IDownloadTTDTacticConversionsReportResponse {
  jobUuid: string;
}

export interface ICountTTDTacticConversionsReportsResponse {
  approximateRecordCount: number;
}

export interface IUtcDate {
  year: number;
  month: number;
  day: number;
}

export interface ICountOfConversionSegments {
  dateUtc: Date | IUtcDate;
  trackedConversionCount: number;
  visitSessionCount: number;
}

export interface IReadDailyCountTTDTacticConversionsReportsResponse {
  records: ICountOfConversionSegments[];
}
