import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

import { IDateCohort, IEditTableColumn, ILabelValue, ISorter } from '@shared/interfaces';
import { IStageInfluenceFilters, IStageInfluenceResponse } from '../interfaces';
import { StageInfluenceGroupingType } from '../enums';

export enum StageInfluenceActionTypes {
  SetSelectedDateCohort = '[Stage Influence] Set selected date cohort for Campaign Stage Influence report',
  SetFilters = '[Stage Influence] Set Filters for Campaign Stage Influence report',
  GetFilters = '[Stage Influence] Get filters for setup query params of Campaign Stage Influence',
  DownloadCSV = '[Stage Influence] Download CSV for Campaign Stage Influence report',
  DownloadCSVSuccess = '[Stage Influence] Download CSV Success for Campaign Stage Influence report',
  DownloadCSVFailure = '[Stage Influence] Download CSV Failure for Campaign Stage Influence report',
  LoadCampaignStageInfluenceData = '[Stage Influence] Load Campaign Stage Influence Data',
  LoadCampaignStageInfluenceDataSuccess = '[Stage Influence] Load Campaign Stage Influence Data Success',
  LoadCampaignStageInfluenceDataFailure = '[Stage Influence] Load Campaign Stage Influence Data Failure',
  LoadCampaignTypeStageInfluenceData = '[Stage Influence] Load Campaign Type Stage Influence Data',
  LoadCampaignTypeStageInfluenceDataSuccess = '[Stage Influence] Load Campaign Type Stage Influence Data Success',
  LoadCampaignTypeStageInfluenceDataFailure = '[Stage Influence] Load Campaign Type Stage Influence Data Failure',
  LoadCampaignStageInfluenceTotalsData = '[Stage Influence] Load Campaign Stage Influence Totals Data',
  LoadCampaignStageInfluenceTotalsDataSuccess = '[Stage Influence] Load Campaign Stage Influence Totals Data Success',
  LoadCampaignStageInfluenceTotalsDataFailure = '[Stage Influence] Load Campaign Stage Influence Totals Data Failure',
  GetCampaignStageInfluenceData = '[Stage Influence] Get Campaign Stage Influence Data',
  GetCampaignTypeStageInfluenceData = '[Stage Influence] Get Campaign Type Stage Influence Data',
  ToggleCampaignColumnVisibility = '[Stage Influence] Toggle Campaign Column Visibility',
  ToggleCampaignTypeColumnVisibility = '[Stage Influence] Toggle Campaign Type Column Visibility',
  SetCampaignTableSortState = '[Stage Influence] Set Campaign table sort state for Campaign Stage Influence report',
  SetCampaignTablePage = '[Stage Influence] Set Campaign table page for Campaign Stage Influence report',
  SetCampaignTableSearch = '[Stage Influence] Set Campaign table search for Campaign Stage Influence report',
  SetCampaignTypeTableSortState = '[Stage Influence] Set Campaign Type table sort state for Campaign Stage Influence report',
  SetCampaignTypeTablePage = '[Stage Influence] Set Campaign Type table page for Campaign Stage Influence report',
  SetCampaignTypeTableSearch = '[Stage Influence] Set Campaign Type table search for Campaign Stage Influence report',
  SetSelectedCampaignType = '[Stage Influence] Set Selected Campaign Type',
}

const setFilters = createAction(
  StageInfluenceActionTypes.SetFilters,
  props<{ request: IStageInfluenceFilters }>()
);

const getFilters = createAction(
  StageInfluenceActionTypes.GetFilters,
  props<{ params: Params }>()
);

const setSelectedDateCohort = createAction(
  StageInfluenceActionTypes.SetSelectedDateCohort,
  props<IDateCohort>()
);

const downloadCSV = createAction(
  StageInfluenceActionTypes.DownloadCSV,
  props<{ groupingType: StageInfluenceGroupingType }>()
);

const downloadCSVSuccess = createAction(
  StageInfluenceActionTypes.DownloadCSVSuccess,
  props<{ data: string; filename: string; }>()
);

const downloadCSVFailure = createAction(
  StageInfluenceActionTypes.DownloadCSVFailure,
  props<{ message: string; }>()
);

const loadCampaignStageInfluenceData = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceData,
);

const loadCampaignStageInfluenceDataSuccess = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceDataSuccess,
  props<{ data: IStageInfluenceResponse; }>()
);

const loadCampaignStageInfluenceDataFailure = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceDataFailure,
  props<{ message: string; }>()
);

const loadCampaignTypeStageInfluenceData = createAction(
  StageInfluenceActionTypes.LoadCampaignTypeStageInfluenceData,
);

const loadCampaignTypeStageInfluenceDataSuccess = createAction(
  StageInfluenceActionTypes.LoadCampaignTypeStageInfluenceDataSuccess,
  props<{ data: IStageInfluenceResponse; }>()
);

const loadCampaignTypeStageInfluenceDataFailure = createAction(
  StageInfluenceActionTypes.LoadCampaignTypeStageInfluenceDataFailure,
  props<{ message: string; }>()
);

const loadCampaignStageInfluenceTotalsData = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceTotalsData,
);

const loadCampaignStageInfluenceTotalsDataSuccess = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceTotalsDataSuccess,
  props<{ data: IStageInfluenceResponse; }>()
);

const loadCampaignStageInfluenceTotalsDataFailure = createAction(
  StageInfluenceActionTypes.LoadCampaignStageInfluenceTotalsDataFailure,
  props<{ message: string; }>()
);

const getCampaignStageInfluenceData = createAction(
  StageInfluenceActionTypes.GetCampaignStageInfluenceData,
);

const getCampaignTypeStageInfluenceData = createAction(
  StageInfluenceActionTypes.GetCampaignTypeStageInfluenceData,
);

const toggleCampaignColumnVisibility = createAction(
  StageInfluenceActionTypes.ToggleCampaignColumnVisibility,
  props<IEditTableColumn>()
);

const toggleCampaignTypeColumnVisibility = createAction(
  StageInfluenceActionTypes.ToggleCampaignTypeColumnVisibility,
  props<IEditTableColumn>()
);

const setCampaignTableSortState = createAction(
  StageInfluenceActionTypes.SetCampaignTableSortState,
  props<ISorter>()
);

const setCampaignTablePage = createAction(
  StageInfluenceActionTypes.SetCampaignTablePage,
  props<{ page: number }>()
);

const setCampaignTableSearch = createAction(
  StageInfluenceActionTypes.SetCampaignTableSearch,
  props<{ search: string }>()
);

const setCampaignTypeTableSortState = createAction(
  StageInfluenceActionTypes.SetCampaignTypeTableSortState,
  props<ISorter>()
);

const setCampaignTypeTablePage = createAction(
  StageInfluenceActionTypes.SetCampaignTypeTablePage,
  props<{ page: number }>()
);

const setCampaignTypeTableSearch = createAction(
  StageInfluenceActionTypes.SetCampaignTypeTableSearch,
  props<{ search: string }>()
);

const setSelectedCampaignType = createAction(
  StageInfluenceActionTypes.SetSelectedCampaignType,
  props<ILabelValue>()
);

export const stageInfluenceActions = {
  setFilters,
  getFilters,
  setSelectedDateCohort,
  setCampaignTableSortState,
  setCampaignTablePage,
  downloadCSV,
  downloadCSVSuccess,
  downloadCSVFailure,
  loadCampaignStageInfluenceData,
  loadCampaignStageInfluenceDataSuccess,
  loadCampaignStageInfluenceDataFailure,
  loadCampaignTypeStageInfluenceData,
  loadCampaignTypeStageInfluenceDataSuccess,
  loadCampaignTypeStageInfluenceDataFailure,
  loadCampaignStageInfluenceTotalsData,
  loadCampaignStageInfluenceTotalsDataSuccess,
  loadCampaignStageInfluenceTotalsDataFailure,
  getCampaignStageInfluenceData,
  getCampaignTypeStageInfluenceData,
  toggleCampaignColumnVisibility,
  toggleCampaignTypeColumnVisibility,
  setCampaignTableSearch,
  setCampaignTypeTableSortState,
  setCampaignTypeTablePage,
  setCampaignTypeTableSearch,
  setSelectedCampaignType,
};
