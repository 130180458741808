import { Action, createReducer, on } from '@ngrx/store';
import { GlobalSearchTypeEnum } from '../enums';
import { IGlobalSearchData, ISearchRouteData } from '../interfaces';
import { globalSearchActions } from './global-search.actions';

export const globalSearchFeatureKey = 'globalSearch';

export interface IGlobalSearchState {
  data: IGlobalSearchData | null,
  isLoading: number;
  searchQuery: string;
  viewData: GlobalSearchTypeEnum[];
  routeData: ISearchRouteData;
}

export const initialState: IGlobalSearchState = {
  data: null,
  isLoading: 0,
  searchQuery: '',
  viewData: [],
  routeData: {
    [GlobalSearchTypeEnum.Campaigns]: {
      link: null,
      field: 'campaignId'
    },
    [GlobalSearchTypeEnum.Opportunities]: {
      link: null,
      field: 'oppty'
    },
    [GlobalSearchTypeEnum.Accounts]: {
      link: null,
      field: 'accountId'
    },
    [GlobalSearchTypeEnum.Channels]: {
      link: null,
      field: 'campaignId'
    }
  },
}

const globalSearchReducer = createReducer(
  initialState,
  on(
    globalSearchActions.loadSearchData,
    (state, action) => ({
      ...state,
      data: null,
      searchQuery: action.searchQuery
    })
  ),
  on(
    globalSearchActions.loadSearchDataSuccess,
    (state, action) => ({
      ...state,
      data: {
        ...state.data,
        [action.key]: action.data
      },
      isLoading: state.isLoading - 1,
    })
  ),
  on(
    globalSearchActions.loadSearchDataFailed,
    (state) => ({
      ...state,
      isLoading: state.isLoading - 1,
    })
  ),
  on(
    globalSearchActions.setViewData,
    (state, action) => ({
      ...state,
      viewData: action.data,
    })
  ),
  on(
    globalSearchActions.setLoading,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading + action.number
    })
  )
)

export function reducer(state: IGlobalSearchState, action: Action): IGlobalSearchState {
  return globalSearchReducer(state, action);
}
