import { TsSortDirection } from '@terminus-lib/ui-sort';
import { OpportunityDirection } from '@measurement-studio/interfaces';
import { opportunityItemFieldAdapter } from '../interfaces';

export function convertOpportunitySortDirection(direction: OpportunityDirection): TsSortDirection {
  switch (direction) {
    case OpportunityDirection.Asc:
      return 'asc';
    case OpportunityDirection.Desc:
      return 'desc';
    default:
      return '';
  }
}

export function convertToOpportunitySortDirection(direction: TsSortDirection): OpportunityDirection {
  switch (direction) {
    case 'asc':
      return OpportunityDirection.Asc;
    case 'desc':
      return OpportunityDirection.Desc;
    default:
      return null;
  }
}

export function convertOpportunityItemFieldName(field: string): string {
  return opportunityItemFieldAdapter[field];
}
