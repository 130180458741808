import { createSelector } from '@ngrx/store';
import { TsSidenavDefaultOptions } from '@terminus-lib/ui-sidenav';

import { IFeatureFlags, IReportsAccess, IUserProfile } from '@shared/interfaces';
import { FeatureFlagsEnum, RouteItemEnum, UserCrmType } from '@shared/enums';
import {
  ACADEMY_ROUTE,
  CAN_ACCESS,
  COMMUNITY_ROUTE,
  HELP_CENTER_ROUTE,
  KNOWLEDGE_BASE_ROUTE,
  PROFILE_ROUTE,
  SIGN_OUT_ROUTE
} from '@shared/constants';
import { selectFeatureFlags, selectUserProfile, selectUserState, UserState } from '@shared/data-access/user-common';

export const selectIsAuthenticated = createSelector(selectUserState, (state: UserState) => state.isAuthenticated);
export const selectUserProfileEmail = createSelector(selectUserProfile, (userProfile: IUserProfile) => userProfile.email);
export const selectUserProfileReportsAccess = createSelector(selectUserProfile, (userProfile: IUserProfile) => userProfile.reportsAccess);
export const selectUserRedirectUrl = createSelector(selectUserState, (state: UserState) => state.redirectUrl);
export const selectOrganizationId = createSelector(selectUserProfile,
  (userProfile: IUserProfile) => userProfile?.orgId ? Number(userProfile.orgId) : null);
export const selectUserCrm = createSelector(selectUserProfile, (userProfile: IUserProfile) => userProfile?.crm);
export const isUseNewInfluenceType = createSelector(selectFeatureFlags,
  (flags: IFeatureFlags) => {
    return flags
      && (flags[FeatureFlagsEnum.NewInfluenceType]?.state === CAN_ACCESS
        || flags[FeatureFlagsEnum.NewInfluenceTypeLimited]?.state === CAN_ACCESS);
  });

export const getHasZendeskSsoAccess = createSelector(
  selectUserState,
  (state: UserState) => state.hasZendeskSsoAccess
);
export const isFeatureFlagActive = (featureFlagName: FeatureFlagsEnum) => createSelector(
  selectFeatureFlags,
  (flags: IFeatureFlags) => {
    return flags && flags[featureFlagName]?.state === CAN_ACCESS;
  });

export const getHasReportAccessByName = (reportName: RouteItemEnum) => createSelector(
  selectUserProfileReportsAccess,
  (reportAccess: IReportsAccess) => reportAccess[reportName]
);
export const getSidenavUserMenu = (platformHomeUrl: string) => createSelector(
  getHasZendeskSsoAccess,
  (
    hasAccess: boolean
  ) => {
    const SIDENAV_OPTIONS: TsSidenavDefaultOptions = {
      profileRoute: PROFILE_ROUTE,
      signOutRoute: SIGN_OUT_ROUTE,
      academyUrl: ACADEMY_ROUTE,
      knowledgeBaseUrl: KNOWLEDGE_BASE_ROUTE
    };

    return {
      ...SIDENAV_OPTIONS,
      ...(hasAccess
        ? {
          communityUrl: `${platformHomeUrl}/${COMMUNITY_ROUTE}`,
          knowledgeBaseUrl: `${platformHomeUrl}/${HELP_CENTER_ROUTE}`,
        }
        : {}
      )
    };
  }
);
export const getIsAutomatedHubspotIngest = createSelector(
  selectUserCrm,
  (userCrm) => userCrm.type === UserCrmType.Hubspot && userCrm.isAutomatedIngest,
);

export const getIsHubspotOrSalesforce = createSelector(
  selectUserCrm,
  (userCrm) => userCrm.type === UserCrmType.Hubspot || userCrm.type === UserCrmType.Salesforce,
);
