import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';

import { ISearchOption, ISearchQuery } from './search-by-property.interface';

@Component({
  selector: 'tsh-search-by-property',
  templateUrl: './search-by-property.component.html',
  styleUrls: ['./search-by-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchByPropertyComponent implements OnChanges {
  @Input() public isDisabledOptions = false;
  @Input() public isDisabledInput = false;
  @Input() public isClearable = true;
  @Input() public searchByFieldEnabled = false;
  @Input() public searchOptions: ISearchOption[] = [];
  @Input() public searchQuery = '';
  @Input() public searchLabel = 'shared.searchByProperty.searchLabel';
  @Input() public optionsLabel = 'shared.searchByProperty.optionsLabel';
  @Output() public submitted = new EventEmitter<ISearchQuery>();
  @Output() public searchQueryChange = new EventEmitter<string>();

  public searchField = new FormControl<ISearchOption>(null, Validators.required);
  public queryControl = new FormControl('');
  public icon = faSearch;

  ngOnChanges(changes: SimpleChanges): void {
    // set first option as selected by default
    if (changes.searchOptions
      && this.searchOptions.length
      && !this.searchField.value) {
      this.searchField.setValue(this.searchOptions[0]);
    }

    if (changes.searchQuery) {
      this.queryControl.setValue(this.searchQuery);
    }
  }

  compareWith(one: ISearchOption, two: ISearchOption): boolean {
    return one && two && one.value === two.value;
  }

  startSearch(): void {
    this.submitted.emit({
      searchField: this.searchField.value && this.searchField.value.value,
      searchQuery: this.queryControl.value
    });
  }
}
