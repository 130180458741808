import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TsSortState } from '@terminus-lib/ui-sort';
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons/faCloudDownload';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { TsTooltipComponent } from '@terminus-lib/ui-tooltip';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { IAccountFolder } from '@shared/interfaces';
import { DataTypeEnum, RouteItemEnum, SidenavUtilitiesTabs } from '@shared/enums';
import { IAdsInfluencedInsightsDetailsResponse } from '@measurement-studio/interfaces';
import { GainsightService } from '@gainsight';
import { ISearchQuery } from '@ui/components/search-by-property';
import * as AccountListsStore from '@shared/data-access/account-hub';
import * as InfluencedInsightsSelectors from '../../../state/influenced-insights/influenced-insights.selectors';
import { influencedInsightsActions } from '../../../state/influenced-insights/influenced-insights.actions';
import * as SidenavUtilitiesStore from '@shared/features/sidenav-utilities';
import { AdInsightsSidenavStates, ADS_INSIGHTS_REPORT_TYPE_MAPPING, AdsInsightsReportType } from '../../../enums';
import { DETAILS_COLUMNS } from '../data/info-panel.columns';
import { InfluencedInsightsReport } from '../../../interfaces';
import {
  GAINSIGHT_EVENT_CSV_DOWNLOAD,
  GAINSIGHT_EVENT_LOCATION_PROPERTY_FIELD_MAP,
  GAINSIGHT_EVENT_TAB_PROPERTY_FIELD_MAP,
  GAINSIGHT_EVENT_TABLE_SEARCH
} from '../../../data/insights-gainsight-events.consts';
import { InfluencedInsightsState } from '../../../state/influenced-insights/influenced-insights.reducer';

@WithDestroy
@Component({
  selector: 'ms-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InfoPanelComponent implements OnInit {
  public accountListFolders$ = this.store.pipe(select(AccountListsStore.getFolders));
  public addingToAccount$ = this.store.pipe(select(InfluencedInsightsSelectors.getInfluencedInsightsAddingToAccount));
  public influencedInsights$ = this.store.pipe(select(InfluencedInsightsSelectors.getAllInfluencedInsights));
  public searchQuery: string;
  public tooltipText: string;
  public chosenFolder: IAccountFolder;
  public form: FormGroup = this.fb.group({
    folderId: null,
    listId: null
  });
  public reportKeys: AdsInsightsReportType[];
  public columns = [...DETAILS_COLUMNS].slice(1);
  public activeReport: AdsInsightsReportType;
  public currentState: AdInsightsSidenavStates;
  public addedAccounts: number;
  public activeDetails: string;
  public addingToAccount: boolean;
  public showReport: string;
  public influencedInsights: {
    [AdsInsightsReportType.PageView]: InfluencedInsightsReport;
    [AdsInsightsReportType.Lead]: InfluencedInsightsReport;
    [AdsInsightsReportType.CampaignResponses]: InfluencedInsightsReport;
    [AdsInsightsReportType.NewPipeline]: InfluencedInsightsReport;
    [AdsInsightsReportType.Pipeline]: InfluencedInsightsReport;
    [AdsInsightsReportType.Revenue]: InfluencedInsightsReport;
  };
  public readonly insightsState = RouteItemEnum.AdvertisingInsights;
  public readonly reportTypes = AdsInsightsReportType;
  public readonly adInsightsStates = AdInsightsSidenavStates;
  public readonly cloudDownload = faCloudDownload;
  public readonly dataTypes = {
    benchmark: DataTypeEnum.Currency,
    valueLift: DataTypeEnum.Percent,
    velocityLift: DataTypeEnum.Percent,
    valueWithAds: DataTypeEnum.Currency,
    valueWithoutAds: DataTypeEnum.Currency
  };

  @ViewChild('tooltip') public tooltip: TsTooltipComponent;

  constructor(
    public gainsightService: GainsightService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    public store: Store<InfluencedInsightsState>
  ) {
  }

  ngOnInit(): void {
    this.reportKeys = Object.keys(ADS_INSIGHTS_REPORT_TYPE_MAPPING) as AdsInsightsReportType[];
    this.initData();
    this.hideTooltip();
  }

  initData(): void {
    this.store.pipe(
      select(InfluencedInsightsSelectors.getActiveReport),
      untilComponentDestroyed(this)
    ).subscribe((report: AdsInsightsReportType) => {
      this.activeReport = report;
    });

    this.store.pipe(
      select(InfluencedInsightsSelectors.getInfoPanelLabel),
      untilComponentDestroyed(this)
    ).subscribe((report: string) => {
      this.showReport = report;
    });

    this.store.pipe(
      select(InfluencedInsightsSelectors.getInfoPanelActiveState),
      untilComponentDestroyed(this)
    ).subscribe((state: AdInsightsSidenavStates) => {
      this.currentState = state;
    });

    this.store.pipe(
      select(InfluencedInsightsSelectors.getActiveDetails),
      untilComponentDestroyed(this)
    ).subscribe((details: string) => {
      this.activeDetails = details;
    });

    this.store.pipe(
      select(InfluencedInsightsSelectors.getAddedAccountsQuantity),
      untilComponentDestroyed(this)
    ).subscribe((addedAccounts: number) => {
      this.addedAccounts = addedAccounts;
    });

    this.store.pipe(
      select(SidenavUtilitiesStore.getActiveTabIndex),
      filter((index: SidenavUtilitiesTabs) => index === SidenavUtilitiesTabs.InfoPanel),
      untilComponentDestroyed(this)
    ).subscribe(() => {
      this.checkLoadInsights();
    });
  }

  hideTooltip(): void {
    // Hide tooltip after adding account to account list successfully
    this.addingToAccount$.subscribe((addingToAccount: boolean) => {
      if (!addingToAccount && this.addedAccounts) {
        this.tooltip.hideTooltip();
        this.tooltipText = this.translate.instant('measurementStudio.features.advertisingInsights.informationPanel.tooltip.addedAccounts');
        this.tooltipText = this.tooltipText.replace('[number]', this.addedAccounts.toString());
        this.tooltip.showTooltip();
        this.form.reset();
        this.changeState(AdInsightsSidenavStates.InfluencedDetails);
      }
    });
  }

  checkLoadInsights() {
    if (this.showReport === this.insightsState) {
      this.reportKeys.forEach((reportType: AdsInsightsReportType) => {
        this.store.dispatch(influencedInsightsActions.loadInfluencedInsights({ params: {
          reportType
        }}));
      });
    } else {
      this.store.dispatch(influencedInsightsActions.loadInfluencedInsights({ params: {
        reportType: AdsInsightsReportType[this.showReport]
      }}));
    }
  }

  onSearchSubmit(value: ISearchQuery): void {
    this.gainsightService.emitGainsightTrackEvent(GAINSIGHT_EVENT_TABLE_SEARCH, {
      location: GAINSIGHT_EVENT_LOCATION_PROPERTY_FIELD_MAP.insights,
      tab: GAINSIGHT_EVENT_TAB_PROPERTY_FIELD_MAP.drawer
    });
    this.store.dispatch(influencedInsightsActions.changeSearch({
      reportType: this.activeReport,
      search: value.searchQuery
    }));
  }

  changeState(newState: AdInsightsSidenavStates, reportType?: string, insightType?: string): void {
    if (newState === AdInsightsSidenavStates.InfluencedDetails
      && this.currentState === AdInsightsSidenavStates.InfluencedList) {
      this.setActiveReportAndDetails(reportType, insightType);
    }
    this.store.dispatch(influencedInsightsActions.updateInsightsInfoPanelState({ state: newState }));
  }

  setActiveReportAndDetails(reportType: string, insightType: string) {
    // TODO: It seems like redundant logic (in fact AdsInsightsReportType[AdsInsightsReportTypeMapping[reportType]] === reportType)
    this.store.dispatch(influencedInsightsActions.setActiveReportAndDetails({
      reportType: AdsInsightsReportType[ADS_INSIGHTS_REPORT_TYPE_MAPPING[reportType]],
      detailsKey: insightType
    }));
  }

  getObjectKeys(item: unknown): string[] {
    return Object.keys(item);
  }

  addToAccountList(details: IAdsInfluencedInsightsDetailsResponse): void {
    this.tooltipText = this.translate.instant('measurementStudio.features.advertisingInsights.informationPanel.tooltip.addingAccount');
    this.changeDetector.detectChanges();
    this.tooltip.showTooltip();
    this.store.dispatch(influencedInsightsActions.addAccountToAccountsList({ params: {
      reportType: this.activeReport,
      accountCount: details.totalRows,
      listId: this.form.get('listId').value.toString()
    }}));
  }

  downloadCSV(): void {
    this.gainsightService.emitGainsightTrackEvent(GAINSIGHT_EVENT_CSV_DOWNLOAD, {
      location: GAINSIGHT_EVENT_LOCATION_PROPERTY_FIELD_MAP.insights,
      tab: GAINSIGHT_EVENT_TAB_PROPERTY_FIELD_MAP.drawer
    });
    this.store.dispatch(influencedInsightsActions.downloadCSV({
      key: this.activeReport,
      detailsKey: this.activeDetails
    }));
  }

  onChangeSort(state: TsSortState) {
    this.store.dispatch(influencedInsightsActions.changeSort({
      sortState: state,
      reportType: this.activeReport
    }));
  }

  onChangePage(page: number): void {
    this.store.dispatch(influencedInsightsActions.changePage({
      page,
      reportType: this.activeReport
    }));
  }
}
