import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';
import { IAnalyticsRequest, ITagRequest, ITagsRequest, ITagsResponse } from '@measurement-studio/interfaces';

export enum TagsURLs {
  Tags = '/api/v1/load_tags',
  PostTags = '/api/v1/add_tag',
  DeleteTags = '/api/v1/remove_tag',
  TagsByCampaign = '/api/v1/load_tags_by_campaign',
  PostChannelTags = '/api/v1/add_tag_channel',
  DeleteChannelTags = '/api/v1/remove_tag_channel',
  TagsByChannel = '/api/v1/load_tags_by_channel',
}

@Injectable({providedIn: 'root'})
export class TagsSource extends HttpClientBase {

  getTags$(body: ITagsRequest): Observable<{ name: string }[]> {
    const endpoint = this.endpoint(TagsURLs.Tags);

    const params = new HttpParams()
      .set('objectType', body.objectType)
      .set('tagType', body.tagType);
    return this.httpClient.get<{ name: string }[]>(endpoint, {params});
  }

  postTags$(body: ITagRequest): Observable<ITagsResponse> {
    const endpoint = this.endpoint(TagsURLs.PostTags);

    return this.httpClient.post<ITagsResponse>(endpoint, body);
  }

  deleteTags$(body: ITagRequest): Observable<string[]> {
    const endpoint = this.endpoint(TagsURLs.DeleteTags);

    return this.httpClient.post<string[]>(endpoint, body);
  }

  getTagsByCampaign$(body: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams): Observable<ITagsResponse> {
    const endpoint = this.endpoint(TagsURLs.TagsByCampaign);

    const params = this.getHttpParams(body, globalFilters);
    return this.httpClient.get<ITagsResponse>(endpoint, {params});
  }

  postChannelTags$(body: ITagRequest): Observable<ITagsResponse> {
    const endpoint = this.endpoint(TagsURLs.PostChannelTags);

    return this.httpClient.post<ITagsResponse>(endpoint, body);
  }

  deleteChannelTags$(body: ITagRequest): Observable<string[]> {
    const endpoint = this.endpoint(TagsURLs.DeleteChannelTags);

    return this.httpClient.post<string[]>(endpoint, body);
  }

  getTagsByChannel$(body: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams): Observable<ITagsResponse> {
    const endpoint = this.endpoint(TagsURLs.TagsByChannel);

    const params = this.getHttpParams(body, globalFilters);
    return this.httpClient.get<ITagsResponse>(endpoint, {params});
  }

  private getHttpParams(body: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams): HttpParams {
    return new HttpParams()
      .set('dataSet', body.dataSet)
      .set('influenceType', body.influenceType)
      .set('model', String(body.model))
      .set('cohort', body.cohort.cohort)
      .set('startDate', String(body.cohort.startDate))
      .set('endDate', String(body.cohort.endDate))
      .appendAll(globalFilters);
  }
}
