import { IFactoryParams } from '@shared/interfaces';
import { IAdsTacticsRow } from './ads-tactics-row.interface';

export interface IAdsTacticsResponse {
  tactics: IAdsTacticsRow[];
}

export function adsTacticsResponseFactory({
  overrides = {}
}: IFactoryParams<IAdsTacticsResponse,
  number,
  boolean> = {}): IAdsTacticsResponse {
  return {
    tactics: [],
    ...overrides
  };
}
