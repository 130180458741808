import { createAction, props } from '@ngrx/store';

export enum UtilitiesSideNavActionTypes {
  UpdateActiveTab = '[UtilitiesSidenav] Update Active Tab Index',
  UpdateExtendedState = '[UtilitiesSidenav] Update Sidenav Extended State',
}

const UpdateActiveTab = createAction(
  UtilitiesSideNavActionTypes.UpdateActiveTab,
  props<{ payload: number }>())

const UpdateExtendedState = createAction(
  UtilitiesSideNavActionTypes.UpdateExtendedState,
  props<{ payload: boolean }>())


export const UtilitiesSidenavActions = {
  UpdateActiveTab,
  UpdateExtendedState,
};