/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import * as interfaces from '../../interfaces';
import { CampaignLoadActions } from '../campaign/campaign.actions';
import { ILabelValue, IPager, ISorter } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { INestedTableEvent } from '@measurement-studio/ui/analytics-tables';

export enum SourceActionsTypes {
  SetUsedParams = '[Source] Set used params in driver request',
  GetSources = '[Source] Get all data',
  ToggleTableRow = '[Source] Toggle Table Row',
  CloseNestedTables = '[Source] Close Nested Tables',
  LoadMetaData = '[Source] Load MetaData',
  LoadMetaDataSuccess = '[Source] Load MetaData Success',
  LoadMetaDataFailure = '[Source] Load MetaData Failure',
  LoadResponses = '[Source] Load Responses',
  LoadResponsesSuccess = '[Source] Load Responses Success',
  LoadResponsesFailure = '[Source] Load Responses Failure',
  LoadAttribution = '[Source] Load Attribution',
  LoadAttributionSuccess = '[Source] Load Attribution Success',
  LoadAttributionFailure = '[Source] Load Attribution Failure',
  LoadInfluence = '[Source] Load Influence',
  LoadInfluenceSuccess = '[Source] Load Influence Success',
  LoadInfluenceFailure = '[Source] Load Influence Failure',
  LoadReturns = '[Source] Load Returns',
  LoadReturnsSuccess = '[Source] Load Returns Success',
  LoadReturnsFailure = '[Source] Load Returns Failure',
  LoadCosts = '[Source] Load Costs',
  LoadCostsSuccess = '[Source] Load Costs Success',
  LoadCostsFailure = '[Source] Load Costs Failure',
  ChangeNestedSorting = '[Source] Change nested sorting for source id',
  ChangeNestedPagination = '[Source] Change nested pagination for source id',
  ClearNestedState = '[Source] Clear nested state',
  SetSelectedMedium = '[Source] Set selected medium',
}

const setUsedParams = createAction(
  SourceActionsTypes.SetUsedParams,
  props<{ request: IAnalyticsRequest }>()
);

const getSources = createAction(
  SourceActionsTypes.GetSources,
);

const toggleTableRow = createAction(
  SourceActionsTypes.ToggleTableRow,
  props<{ id: string }>()
);

const closeNestedTables = createAction(
  SourceActionsTypes.CloseNestedTables,
);

const loadMetaData = createAction(
  SourceActionsTypes.LoadMetaData,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadMetaDataSuccess = createAction(
  SourceActionsTypes.LoadMetaDataSuccess,
  props<interfaces.ISuccessPayload<interfaces.IMetaData<interfaces.IWebActivitiesGroupResponse>>>()
);

const loadMetaDataFailure = createAction(
  SourceActionsTypes.LoadMetaDataFailure,
  props<{ message: string }>()
);

const loadResponses = createAction(
  SourceActionsTypes.LoadResponses,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadResponsesSuccess = createAction(
  SourceActionsTypes.LoadResponsesSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignResponses>>()
);

const loadResponsesFailure = createAction(
  SourceActionsTypes.LoadResponsesFailure,
  props<{ message: string }>()
);

const loadAttribution = createAction(
  SourceActionsTypes.LoadAttribution,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadAttributionSuccess = createAction(
  SourceActionsTypes.LoadAttributionSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignAttribution>>()
);

const loadAttributionFailure = createAction(
  SourceActionsTypes.LoadAttributionFailure,
  props<{ message: string }>()
);

const loadInfluence = createAction(
  SourceActionsTypes.LoadInfluence,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadInfluenceSuccess = createAction(
  SourceActionsTypes.LoadInfluenceSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignInfluence>>()
);

const loadInfluenceFailure = createAction(
  SourceActionsTypes.LoadInfluenceFailure,
  props<{ message: string }>()
);

const loadReturns = createAction(
  SourceActionsTypes.LoadReturns,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadReturnsSuccess = createAction(
  SourceActionsTypes.LoadReturnsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignReturns>>()
);

const loadReturnsFailure = createAction(
  SourceActionsTypes.LoadReturnsFailure,
  props<{ message: string }>()
);

const loadCosts = createAction(
  SourceActionsTypes.LoadCosts,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadCostsSuccess = createAction(
  SourceActionsTypes.LoadCostsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignCosts>>()
);

const loadCostsFailure = createAction(
  SourceActionsTypes.LoadCostsFailure,
  props<{ message: string }>()
);

const changeNestedSorting = createAction(
  SourceActionsTypes.ChangeNestedSorting,
  props<{ data: INestedTableEvent<ISorter> }>()
);

const changeNestedPagination = createAction(
  SourceActionsTypes.ChangeNestedPagination,
  props<{ data: INestedTableEvent<IPager> }>()
);

const clearNestedState = createAction(
  SourceActionsTypes.ClearNestedState,
);

const setSelectedMedium = createAction(
  SourceActionsTypes.SetSelectedMedium,
  props<{ data: ILabelValue }>()
);

export const sourceActions = {
  setUsedParams,
  getSources,
  toggleTableRow,
  closeNestedTables,
  loadMetaData,
  loadMetaDataSuccess,
  loadMetaDataFailure,
  loadResponses,
  loadResponsesSuccess,
  loadResponsesFailure,
  loadAttribution,
  loadAttributionSuccess,
  loadAttributionFailure,
  loadInfluence,
  loadInfluenceSuccess,
  loadInfluenceFailure,
  loadReturns,
  loadReturnsSuccess,
  loadReturnsFailure,
  loadCosts,
  loadCostsSuccess,
  loadCostsFailure,
  changeNestedSorting,
  changeNestedPagination,
  clearNestedState,
  setSelectedMedium,
};

export type SourceLoadActions = interfaces.ILoadPayload & TypedAction<SourceActionsTypes>;

export type SourceLoadSuccessActions = interfaces.ISuccessPayload<any> & TypedAction<SourceActionsTypes>;

export const sourceActionsMap: Map<SourceActionsTypes, CampaignLoadActions> = new Map()
  .set(SourceActionsTypes.LoadAttributionSuccess, loadAttribution())
  .set(SourceActionsTypes.LoadCostsSuccess, loadCosts())
  .set(SourceActionsTypes.LoadInfluenceSuccess, loadInfluence())
  .set(SourceActionsTypes.LoadMetaDataSuccess, loadMetaData())
  .set(SourceActionsTypes.LoadResponsesSuccess, loadResponses())
  .set(SourceActionsTypes.LoadReturnsSuccess, loadReturns());
