import { createSelector, MemoizedSelector } from '@ngrx/store';

import {IAnalyticsColumn, IAnalyticsTableTotals, ICampaignsTableBase, ICampaignsTableItem} from '../interfaces';
import * as TotalsSelectors from './totals/totals.selectors';
import * as CampaignGroupsSelectors from './campaign-groups/campaign-groups.selectors';
import * as CampaignsSelectors from './campaigns/campaigns.selectors';
import * as CampaignSelectors from './campaign/campaign.selectors';
import * as SourceSelectors from './source/source.selectors';
import * as MediumsSelectors from './mediums/mediums.selectors';
import { AnalyticsColumnName as Column, GroupingType } from '../enums';

export const getCampaignAnalyticsIsLoading = createSelector(CampaignGroupsSelectors.getCampaignGroupsIsDriverLoading,
  CampaignsSelectors.getCampaignsIsDriverLoading,
  (isCampaignGroupsDriverLoading: boolean, isCampaignsDriverLoading: boolean) => isCampaignGroupsDriverLoading || isCampaignsDriverLoading);

export const getWebActivitiesIsLoading = createSelector(CampaignSelectors.getCampaignIsDriverLoading,
  SourceSelectors.getSourceIsDriverLoading,
  MediumsSelectors.getMediumsIsDriverLoading,
  (isCampaignDriverLoading: boolean, isSourceDriverLoading: boolean, isMediumsDriverLoading: boolean) =>
    isCampaignDriverLoading || isSourceDriverLoading || isMediumsDriverLoading);

export const getUnloadedTotalsColumnNames = (visibleColumnsSelector: MemoizedSelector<unknown, IAnalyticsColumn[]>) => createSelector(
  visibleColumnsSelector,
  (state, props) => TotalsSelectors.getTotalsData(state, props.key),
  (columns: IAnalyticsColumn[], totals: Partial<IAnalyticsTableTotals>) => columns
    // NOTE: to prevent load already loaded data
    // the CampaignType isn't totaled so check the Cost from the same slice of totals response
    .filter(column => column.name === Column.CampaignType ? !totals[Column.Cost] : !totals[column.name])
    .map(column => column.name));

const getParentReportDataSelector = (type: GroupingType, parentId: string | number) => {
  switch (type) {
    case GroupingType.Campaigns:
      return typeof parentId === 'number'
        ? CampaignSelectors.getReportData
        : SourceSelectors.getReportData;
    case GroupingType.CampaignTypes:
      return CampaignsSelectors.getReportData;
    case GroupingType.WebSources:
      return CampaignGroupsSelectors.getReportData;
    case GroupingType.WebCampaigns:
      return CampaignsSelectors.getReportData;
  }
};

export const getParentTitle = (type: GroupingType, parentId: string | number) => createSelector(
  getParentReportDataSelector(type, parentId),
  ((items: ICampaignsTableItem[]) => {
    const parentRow = items.find(item => item.groupId === parentId);
    return parentRow[Column.CampaignName] || parentRow[Column.SourceName] || parentRow[Column.CampaignType];
  }));

const getExpandedRowIds = (data: Record<string, boolean>): string[] =>
  Array.from(Object.entries(data || {}).filter(([_, value]) => value).map(([key]) => key));
export const getCampaignGroupsExpandedRowIds = createSelector(CampaignGroupsSelectors.getNestedTableVisibility, getExpandedRowIds);
export const getCampaignsExpandedRowIds = createSelector(CampaignsSelectors.getNestedTableVisibility, getExpandedRowIds);
export const getSourceExpandedRowIds = createSelector(SourceSelectors.getNestedTableVisibility, getExpandedRowIds);
export const getCampaignExpandedRowIds = createSelector(CampaignSelectors.getNestedTableVisibility, getExpandedRowIds);
export const getMediumsExpandedRowIds = createSelector(MediumsSelectors.getNestedTableVisibility, getExpandedRowIds);

const calcUntrackedRow = (totals, parent) => {
  return Object.keys(totals).reduce((acc, key) => {
    if (!!Object.getOwnPropertyDescriptor(parent, key) && !isNaN(parseFloat(parent[key]))) {
      const untrackedValue = parent[key] - totals[key];
      acc[key] = untrackedValue < 0 ? 0 : untrackedValue;
    } else if (totals[key]) {
      acc[key] = '';
    }
    return acc;
  }, {groupId: 'untracked', sourceName: 'untracked', mediumName: '', campaignName: ''});
};

export const getUntrackedData = <T extends Partial<ICampaignsTableBase>>(reportDataSelector: MemoizedSelector<unknown, T[]>) =>
  createSelector(
    reportDataSelector,
    TotalsSelectors.getNestedTotals,
    (tableItems, nestedTotals) => {
      return Object.entries<Partial<IAnalyticsTableTotals>>(nestedTotals).reduce((acc, [parentId, nestedTotal]) => {
        const parentRow = tableItems.find(item => item.groupId === parentId);
        if (parentRow) {
          acc[parentId] = calcUntrackedRow(nestedTotal, parentRow);
        }
        return acc;
      }, {});
    });
