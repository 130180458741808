import { Dictionary } from '@shared/interfaces';

enum ChartMetrics {
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  CTR = 'CTR',
  CPM = 'CPM',
  Engagement = 'Engagement',
  Conversions = 'Conversions',
  Spend = 'Spend',
  Campaigns = 'Tactics', // OLD
  Tactics = 'Tactics',
  Creatives = 'Creatives',
  Accounts = 'Accounts',
  Devices = 'Devices',
  CampaignsAsIs = 'Campaigns',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace ChartMetrics {
  export const description: Dictionary<string> = {
    [ChartMetrics.Impressions]: 'Impressions',
    [ChartMetrics.Clicks]: 'Clicks',
    [ChartMetrics.CTR]: 'CTR',
    [ChartMetrics.CPM]: 'CPM',
    [ChartMetrics.Engagement]: 'Total Engagement',
    [ChartMetrics.Conversions]: 'Conversions',
    [ChartMetrics.Spend]: 'Spend',
    [ChartMetrics.Campaigns]: 'Tactics',
    [ChartMetrics.Tactics]: 'Tactics',
    [ChartMetrics.Accounts]: 'Accounts',
    [ChartMetrics.Devices]: 'Devices',
    [ChartMetrics.CampaignsAsIs]: 'Campaigns',
  };

  // eslint-disable-next-line prefer-const
  export let listItems: ChartMetrics[] = [
    ChartMetrics.Impressions,
    ChartMetrics.Clicks,
    ChartMetrics.CTR,
    ChartMetrics.CPM,
    ChartMetrics.Engagement,
    ChartMetrics.Conversions,
    ChartMetrics.Spend,
    ChartMetrics.Tactics,
    ChartMetrics.Creatives,
    ChartMetrics.Accounts,
    ChartMetrics.Devices,
  ];
}

export { ChartMetrics };
