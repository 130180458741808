import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IReportTab } from '@shared/interfaces';
import { STAGE_INFLUENCE_KEY, StageInfluenceState } from './stage-influence.reducer';
import { IStageInfluenceResponse } from '../interfaces';
import { StageInfluenceTabs } from '../enums';

export const selectStageInfluenceState = createFeatureSelector<StageInfluenceState>(STAGE_INFLUENCE_KEY);

export const getStageInfluenceFilters = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.filters
);

export const getCampaignStageInfluenceTableOptions = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaignTableOptions
);

export const getCampaignTypeStageInfluenceTableOptions = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaignTypeTableOptions
);

export const getStageInfluenceLoading = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.isLoading || state.isLoadingTotals
);

export const getStageInfluenceDateCohort = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.selectedDateCohort
);

export const getStageInfluenceCampaigColumns = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaignColumns
);

export const getStageInfluenceCampaignTypeColumns = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaignTypeColumns
);

export const getStageInfluenceCampaign = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaign
);

export const getStageInfluenceCampaignType = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.campaignType
);

export const getStageInfluenceCampaignTotals = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.totalsCampaign
);

export const getStageInfluenceTabs = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.tabs
);

export const getSelectedCampaignType = createSelector(
  selectStageInfluenceState,
  (state: StageInfluenceState) => state.selectedCampaignType);

export const getTabs = createSelector(
  getStageInfluenceTabs,
  getStageInfluenceCampaign,
  getStageInfluenceCampaignType,
  (
    tabs: IReportTab[],
    campaign: IStageInfluenceResponse,
    campaignsType: IStageInfluenceResponse,
  ) => {
    const data = {
      [StageInfluenceTabs.Campaign]: campaign?.totalResults || 0,
      [StageInfluenceTabs.CampaignType]: campaignsType?.totalResults || 0,
    };
    return tabs.map(tab => {
      return {
        ...tab,
        count: data[tab.key]
      };
    });
  });

export const getVisibleCampaignColumns = createSelector(
  getStageInfluenceCampaigColumns,
  (columns) => columns.filter(column => column.visible)
);

export const getVisibleCampaignTypeColumns = createSelector(
  getStageInfluenceCampaignTypeColumns,
  (columns) => columns.filter(column => column.visible)
);

export const getStageInfluenceCampaignData = createSelector(
  getStageInfluenceCampaign,
  getStageInfluenceFilters,
  (campaign, filters) => campaign ? campaign[filters.columnType] : []
);

export const getStageInfluenceCampaignCount = createSelector(
  getStageInfluenceCampaign,
  (campaign) => campaign?.totalResults
);

export const getStageInfluenceCampaignTypeData = createSelector(
  getStageInfluenceCampaignType,
  getStageInfluenceFilters,
  (campaignType, filters) => campaignType ? campaignType[filters.columnType] : []
);

export const getStageInfluenceCampaignTypeCount = createSelector(
  getStageInfluenceCampaignType,
  (campaignType) => campaignType?.totalResults
);

export const getStageInfluenceCampaignTotalsData = createSelector(
  getStageInfluenceCampaignTotals,
  getStageInfluenceFilters,
  (totals, filters) => totals && totals[filters.columnType] ? totals[filters.columnType][0] : []
);

export const getStageInfluenceVisibleColumns = createSelector(
  getStageInfluenceCampaigColumns,
  (columns) => ['id'].concat(
    columns.filter(column => column.visible).map(column => column.name)
  )
);
