import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { catchError, shareReplay, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TsInputComponent } from '@terminus-lib/ui-input';
import { TsMenuComponent } from '@terminus-lib/ui-menu';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { ID, ILabelValue, OptionsProvider } from '@shared/interfaces';
import { MemoryOptionsProvider } from './memory-options.provider';

/**
 * Usage:
 * - arr = array of items
 * - service = service which have access to API to get options (all / by search query / by id)
 * <tsh-selector formControl="key"
 *               label="label"
 *               [options]="array || new OptionsProvider(service)">
 * </tsh-selector>
 */
@WithDestroy
@Component({
  selector: 'tsh-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectorComponent),
    multi: true
  }],
})
export class SelectorComponent<T extends ILabelValue> implements ControlValueAccessor, AfterViewInit {
  @Input() public label: string;
  @Input() public defaultError = 'shared.ui.selector.error';

  /**
   * Input can get 2 types of incoming params
   * @type {OptionsProvider | Array<extend ILabelValue>}
   *  - Array of items will be mapped to MemoryOptionsProvider
   *  - Some custom options provider has to implement OptionsProvider class
   */
  @Input()
  public set options(options: OptionsProvider<T> | T[]) {
    if (Array.isArray(options)) {
      this._optionsProvider = new MemoryOptionsProvider(options);
      this._options$.next(this._optionsProvider.getAllOptions());
    } else {
      this._optionsProvider = options || new MemoryOptionsProvider([]);
    }
  }

  @ViewChild(TsMenuComponent, {static: true}) public menu: TsMenuComponent;
  @ViewChild(TsInputComponent, {static: false}) public searchInput: TsInputComponent;

  private _optionsProvider: OptionsProvider<T>;
  private _options$: BehaviorSubject<T[]> = new BehaviorSubject([]);
  private _selectedOption$: BehaviorSubject<T> = new BehaviorSubject(null);
  private _value: ID;

  get value(): ID {
    return this._value;
  }

  set value(value: ID) {
    if (this._value === value) {
      return;
    }

    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  get selectedOption$(): Observable<T> {
    return this._selectedOption$.asObservable().pipe(shareReplay(1));
  }

  get options$(): Observable<T[]> {
    return this._options$.asObservable();
  }

  public isLoading = false;
  public onChange: any = () => {
    return;
  };
  public onTouched: any = () => {
    return;
  };

  ngAfterViewInit(): void {
    this.menu.trigger.menuOpened
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.searchInput.focus();
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: ID): void {
    if (value) {
      this.value = value;
      this.getSelectedOption(value);
    }
  }

  search(searchQuery: string): void {
    if (!searchQuery) {
      this._options$.next(this._optionsProvider.getAllOptions());
      return;
    }

    this.isLoading = true;
    this._optionsProvider.getFilteredOptions(searchQuery).pipe(
      catchError((error: Error) => {
        this.handleError(error?.message);
        // in case of error return empty array to avoid crashing app
        return of([]);
      }),
      take(1),
    ).subscribe((options: T[]) => {
      this.isLoading = false;
      this._options$.next(options);
    });
  }

  searchIcon(event: MouseEvent, searchQuery: string): void {
    event.stopPropagation();
    this.search(searchQuery);
  }

  select(item: T): void {
    if (item.value !== this.value) {
      this._selectedOption$.next(item);
      this.value = item.value;
    }
  }

  private getSelectedOption(value: ID): void {
    this._optionsProvider.getById(value).pipe(
      catchError((error: Error) => {
        this.handleError(error?.message);
        // in case of error return null
        return of(null);
      }),
      take(1),
    ).subscribe((data: T) => {
      this._selectedOption$.next(data);
    });
  }

  private handleError(message: string | null): void {
    this._optionsProvider.handleError(message || this.defaultError);
  }
}
