import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ISearchOption, ISearchQuery } from '@ui/components/search-by-property';
import { IEditTableColumn, IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';

@Component({
  selector: 'tsh-table-panel',
  templateUrl: './table-panel.component.html',
  styleUrls: ['./table-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablePanelComponent {
  /**
   * Total number of rows in the table
   */
  @Input() public totalCount = 0;
  @Input() public totalTitle: string;
  @Input() public searchOptions: ISearchOption[] = [];
  @Input() public searchQuery: string;
  @Input() public searchEnabled = false;
  @Input() public searchByFieldEnabled = false;
  @Input() public buttonPanelEnabled = true;
  @Input() public showDownload = true;
  @Input() public showProgress = false;
  @Input() public showColumns = false;
  @Input() public showColumnsSearch = true;
  @Input() public columns: IReportColumn[] = [];
  @Input() public noResult = false;
  /**
   * Emit on submit search
   */
  @Output() public submitted = new EventEmitter<ISearchQuery>();
  /**
   * Emit event on change columns
   */
  @Output() public changeColumns = new EventEmitter<IEditTableColumn>();
  /**
   * Emit download CSV
   */
  @Output() public download = new EventEmitter<void>();

  public dataTypes = DataTypeEnum;

  emitSearch(searchData: ISearchQuery): void {
    this.submitted.emit(searchData);
  }

  emitChangeColumns(data: IEditTableColumn): void {
    this.changeColumns.emit(data);
  }

  emitDownload(): void {
    this.download.emit();
  }
}
