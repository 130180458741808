import { AbstractControl, ValidatorFn } from '@angular/forms';

import { TileFormFields } from '../enums/tile-form-fields.enum';
import { GaugeChartFields, VisualizationTypes } from '@shared/enums';

// check if there are all gauge options set up if visualization type - gauge
export function gaugeRangeRequiredValidator(): ValidatorFn {
  return (group: AbstractControl): { [key: string]: string } | null => {
    const selectedVisualization = group.get(TileFormFields.Visualization)?.value;
    if (selectedVisualization === VisualizationTypes.Gauge) {
      const minControl = group.get(GaugeChartFields.Minimum);
      const midControl = group.get(GaugeChartFields.Midpoint);
      const goalControl = group.get(GaugeChartFields.Goal);
      const maxControl = group.get(GaugeChartFields.Maximum);
      return (!minControl.value && minControl.value !== 0)
      || (!midControl.value && midControl.value !== 0)
      || (!goalControl.value && goalControl.value !== 0)
      || (!maxControl.value && maxControl.value !== 0)
        ? {gauge: 'shared.dashboards.dialog.invalidGaugeAll'}
        : null;
    }

    return null;
  };
}

// check if each range of gauge options is valid
export function gaugeRangeValidator(): ValidatorFn {
  return (group: AbstractControl): { [key: string]: string } | null => {
    const selectedVisualization = group.get(TileFormFields.Visualization)?.value;
    if (selectedVisualization === VisualizationTypes.Gauge) {
      const error = 'shared.dashboards.dialog.invalidGaugeOne';
      const minControl = group.get(GaugeChartFields.Minimum);
      const midControl = group.get(GaugeChartFields.Midpoint);
      const goalControl = group.get(GaugeChartFields.Goal);
      const maxControl = group.get(GaugeChartFields.Maximum);
      // get into consideration only controls with values and set error only for them in case of error
      const minValue = !isEmptyValue(minControl.value) && !isNaN(Number(minControl.value)) ? Number(minControl.value) : null;
      const midValue = !isEmptyValue(midControl.value) && !isNaN(Number(midControl.value)) ? Number(midControl.value) : null;
      const goalValue = !isEmptyValue(goalControl.value) && !isNaN(Number(goalControl.value)) ? Number(goalControl.value) : null;
      const maxValue = !isEmptyValue(maxControl.value) && !isNaN(Number(maxControl.value)) ? Number(maxControl.value) : null;
      const errors = {};
      if (minValue !== null && midValue !== null && minValue > midValue) {
        errors[GaugeChartFields.Minimum] = error;
      }

      if (minValue !== null && midValue !== null && goalValue !== null && (midValue > goalValue || midValue < minValue)) {
        errors[GaugeChartFields.Midpoint] = error;
      }

      if (midValue !== null && goalValue !== null && maxValue !== null && (goalValue > maxValue || goalValue < midValue)) {
        errors[GaugeChartFields.Goal] = error;
      }

      if (goalValue !== null && maxValue !== null && goalValue > maxValue) {
        errors[GaugeChartFields.Maximum] = error;
      }

      return Object.keys(errors).length ? errors : null;
    }

    return null;
  };
}

function isEmptyValue(value: string | null): boolean {
  return value === undefined || value === null || value === '';
}
