import { Action, createReducer, on } from '@ngrx/store';

import {
  DefaultTileInformation,
  IDataFilter,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileVisualizationConfig
} from '@shared/interfaces';
import { tileModalActions } from './tile-modal.actions';
import { TileTypes } from '@shared/enums';
import { EMPTY_GLOBAL_FILTERS_STATE } from '@shared/constants';

export const tileModalFeatureKey = 'tileModal';

export interface TileModalState {
  tilesInfo: Record<TileTypes, DefaultTileInformation> | null;
  isLoadingData: boolean;
  error: string | null;
  previewData: ITileData | ITileGaugeData | null;
  previewVisualizationConfig: ITileVisualizationConfig | null;
  settingsFilters: ITileSettingControl[] | null;
  selectedGlobalFilters: IDataFilter;
  filterSearchQuery: string | null;
}

export const initialState: TileModalState = {
  tilesInfo: null,
  isLoadingData: false,
  error: null,
  previewData: null,
  previewVisualizationConfig: null,
  settingsFilters: null,
  selectedGlobalFilters: EMPTY_GLOBAL_FILTERS_STATE,
  filterSearchQuery: null,
};

const tileModalReducer = createReducer(
  initialState,
  on(tileModalActions.setTilesInformation, (state, action) => ({
    ...state,
    tilesInfo: action.data
  })),
  on(tileModalActions.loadTilePreviewData, (state) => ({
    ...state,
    isLoadingData: true
  })),
  on(tileModalActions.loadTilePreviewDataSuccess, (state, action) => ({
    ...state,
    isLoadingData: false,
    previewData: action.data
  })),
  on(tileModalActions.loadTilePreviewDataFailure, (state, action) => ({
    ...state,
    error: action.message,
    isLoadingData: false
  })),
  on(tileModalActions.setVisualizationConfig, (state, action) => ({
    ...state,
    previewVisualizationConfig: action.data,
  })),
  on(tileModalActions.resetPreviewData, (state) => ({
    ...state,
    error: null,
    previewData: null,
    previewVisualizationConfig: null,
    selectedGlobalFilters: EMPTY_GLOBAL_FILTERS_STATE,
    filterSearchQuery: null
  })),
  on(tileModalActions.setSettingsControls, (state, action) => ({
    ...state,
    settingsFilters: action.data,
  })),
  on(tileModalActions.updateSelectedGlobalFilters, (state, action) => ({
    ...state,
    selectedGlobalFilters: action.data,
  })),
  on(tileModalActions.updateGlobalFiltersSearchQuery, (state, action) => ({
    ...state,
    filterSearchQuery: action.query,
  })),
  on(tileModalActions.setGlobalFiltersFromSettings, (state, action) => ({
    ...state,
    selectedGlobalFilters: {
      ...state.selectedGlobalFilters,
      filters: action.filters,
      listIds: action.listIds,
    }
  })),
);


export function reducer(state = initialState, action: Action): TileModalState {
  return tileModalReducer(state, action);
}
