import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { OktaAuth, TokenResponse } from '@okta/okta-auth-js';
import { EnvService } from '@shared/environment';
import { TsWindowService } from '@terminus-lib/fe-utilities';

import config from './okta.config';
import { ID_TOKEN, TOKEN } from './okta.constants';

@Injectable({
  providedIn: 'root',
})
export class OktaService {
  private authClient: OktaAuth;

  constructor(
    private envService: EnvService,
    private windowService: TsWindowService,
  ) {
    const redirectUri = `${this.windowService.nativeWindow.location.protocol}//${this.windowService.nativeWindow.location.host}`;

    this.authClient = new OktaAuth({
      ...config.oidc,
      clientId: this.envService.getEnv()?.OKTA_CLIENT_ID,
      issuer: `https://${this.envService.getEnv()?.OKTA_ISSUER}/oauth2/default`,
      redirectUri,
    });
  }

  public getSession$(): Observable<boolean> {
    return from(this.authClient.session.exists());
  }

  public getWithoutPrompt$(): Observable<TokenResponse> {
    return from(this.authClient.token.getWithoutPrompt({
      responseType: [TOKEN, ID_TOKEN],
    }));
  }
}
