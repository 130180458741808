import { createAction, props } from '@ngrx/store';

import {
  DefaultTileInformation,
  ICreateDashboardTileRequest,
  IDashboardTile,
  IDataFilter,
  IFilter,
  ITargetFolderItem,
  ITileConfig,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig
} from '@shared/interfaces';
import { TileTypes } from '@shared/enums';
import { IFolderItemsSelector } from '../tile-modal.interface';

export enum TileModalActionsTypes {
  GetTilesInformation = '[Tile Modal] Get Default Tiles Information',
  SetTilesInformation = '[Tile Modal] Set Default Tiles Information',
  GetTilePreviewData = '[Tile Modal] Get tile data to show it in preview',
  LoadTilePreviewData = '[Tile Modal API] Get tile data to show it in preview',
  LoadTilePreviewDataSuccess = '[Tile Modal API] Get tile data to show it in preview success',
  LoadTilePreviewDataFailure = '[Tile Modal API] Get tile data to show it in preview failure',
  GetVisualizationConfig = '[Tile Modal] Get Preview Visualization config',
  SetVisualizationConfig = '[Tile Modal] Set Preview Visualization config',
  ResetPreviewData = '[Tile Modal] Reset all data related to preview',
  GetSettingsControls = '[Tile Modal] Get Tile Settings Config',
  SetSettingsControls = '[Tile Modal] Set Tile Settings Config',
  RemoveGlobalFilterFolder = '[Tile Modal] Remove Global Filter Folder',
  ToggleGlobalFilterFolderSelect = '[Tile Modal] Toggle Global Filter Folder Select',
  ToggleGlobalFilterFolderItemSelect = '[Tile Modal] Toggle Global Filter Folder Item Select',
  UpdateSelectedGlobalFilters = '[Tile Modal] Update Selected Global Filters',
  UpdateGlobalFiltersSearchQuery = '[Tile Modal] Update Search Query',
  GetGlobalFiltersFromSettings = '[Tile Modal] Get Global Filters from Settings',
  SetGlobalFiltersFromSettings = '[Tile Modal] Set Global Filters from Settings',
  SaveNewTile = '[Tile Modal] Save new tile',
  SaveNewTileSuccess = '[Tile Modal] Save new tile success',
  SaveNewTileFailure = '[Tile Modal] Save new tile failure',
}

const getTilesInformation = createAction(TileModalActionsTypes.GetTilesInformation);

const setTilesInformation = createAction(
  TileModalActionsTypes.SetTilesInformation,
  props<{ data: Record<TileTypes, DefaultTileInformation> }>()
);

const getTilePreviewData = createAction(
  TileModalActionsTypes.GetTilePreviewData,
  props<{ data: ITileConfig }>()
);

const loadTilePreviewData = createAction(
  TileModalActionsTypes.LoadTilePreviewData,
  props<{ data: ITileConfig }>()
);

const loadTilePreviewDataSuccess = createAction(
  TileModalActionsTypes.LoadTilePreviewDataSuccess,
  props<{ data: ITileData | ITileGaugeData }>()
);

const loadTilePreviewDataFailure = createAction(
  TileModalActionsTypes.LoadTilePreviewDataFailure,
  props<{ message: string }>()
);

const getVisualizationConfig = createAction(
  TileModalActionsTypes.GetVisualizationConfig,
  props<{ data: IDashboardTile }>()
);

const setVisualizationConfig = createAction(
  TileModalActionsTypes.SetVisualizationConfig,
  props<{ data: ITileVisualizationConfig }>()
);

const resetPreviewData = createAction(TileModalActionsTypes.ResetPreviewData);

const getSettingsControls = createAction(
  TileModalActionsTypes.GetSettingsControls,
  props<{ data: TileTypes }>()
);

const setSettingsControls = createAction(
  TileModalActionsTypes.SetSettingsControls,
  props<{ data: ITileSettingControl[] }>()
);

const removeGlobalFilterFolder = createAction(
  TileModalActionsTypes.RemoveGlobalFilterFolder,
  props<{ data: ITargetFolderItem }>()
);

const toggleGlobalFilterFolderSelect = createAction(
  TileModalActionsTypes.ToggleGlobalFilterFolderSelect,
  props<IFolderItemsSelector>()
);

const toggleGlobalFilterFolderItemSelect = createAction(
  TileModalActionsTypes.ToggleGlobalFilterFolderItemSelect,
  props<{ data: ITargetFolderItem }>()
);

const updateSelectedGlobalFilters = createAction(
  TileModalActionsTypes.UpdateSelectedGlobalFilters,
  props<{ data: IDataFilter }>()
);

const updateGlobalFiltersSearchQuery = createAction(
  TileModalActionsTypes.UpdateGlobalFiltersSearchQuery,
  props<{ query: string }>()
);

const getGlobalFiltersFromSettings = createAction(
  TileModalActionsTypes.GetGlobalFiltersFromSettings,
  props<{ data: ITileSettings }>()
);

const setGlobalFiltersFromSettings = createAction(
  TileModalActionsTypes.SetGlobalFiltersFromSettings,
  props<{ filters: IFilter, listIds: string[] }>()
);

const saveNewTile = createAction(
  TileModalActionsTypes.SaveNewTile,
  props<{ request: ICreateDashboardTileRequest }>()
);

const saveNewTileSuccess = createAction(
  TileModalActionsTypes.SaveNewTileSuccess,
  props<{ data: IDashboardTile }>()
);

const saveNewTileFailure = createAction(
  TileModalActionsTypes.SaveNewTileFailure,
  props<{ message: string }>()
);

export const tileModalActions = {
  getTilesInformation,
  setTilesInformation,
  getTilePreviewData,
  loadTilePreviewData,
  loadTilePreviewDataSuccess,
  loadTilePreviewDataFailure,
  getVisualizationConfig,
  setVisualizationConfig,
  resetPreviewData,
  getSettingsControls,
  setSettingsControls,
  removeGlobalFilterFolder,
  toggleGlobalFilterFolderSelect,
  toggleGlobalFilterFolderItemSelect,
  updateSelectedGlobalFilters,
  updateGlobalFiltersSearchQuery,
  getGlobalFiltersFromSettings,
  setGlobalFiltersFromSettings,
  saveNewTile,
  saveNewTileSuccess,
  saveNewTileFailure,
};
