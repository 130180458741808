<div class="footer-container">
  <div *ngIf="unappliedFilters" class="unapplied-container">
    {{ 'shared.globalFilters.footer.unappliedFilters' | translate: {count: unappliedFilters} }}
    <ts-button theme="default"
               (clicked)="applyFilterChanges.emit()">
      {{ 'shared.common.apply' | translate }}
    </ts-button>
  </div>

  <div class="submit-container">
    <ts-button theme="default"
               [isDisabled]="!filterMenuChanged"
               (clicked)="showSaveModal.emit(appliedFilter)">
      {{ 'shared.globalFilters.footer.saveFilterAs' | translate }}
    </ts-button>
  </div>
</div>
