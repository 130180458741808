import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ScorecardService } from '../services/scorecard/scorecard.service';
import { userMessageFactory } from '@shared/interfaces';
import { scorecardActions } from './scorecard.actions';
import * as ScorecardSelectors from './scorecard.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { ScorecardState } from './scorecard.reducer';

@Injectable()
export class ScorecardEffects {

  constructor(
    public store: Store<ScorecardState>,
    public actions$: Actions,
    public scorecardService: ScorecardService
  ) {
  }

  public loadCanAddListFlag$ = createEffect(() => this.actions$.pipe(
    ofType(scorecardActions.loadCanAddListFlag),
    concatMap(() => of(null).pipe(
      withLatestFrom(
        this.store.pipe(select(ScorecardSelectors.getCanAddAccountList))
      )
    )),
    filter(([_, flag]: [null, boolean | null]) => flag === null),
    switchMap(() => {
      return this.scorecardService.getCanCreateAggregate$().pipe(
        map((flag: boolean) => scorecardActions.loadCanAddListFlagSuccess({ flag })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.scorecard.errors.loadCanAddList';

          return of(scorecardActions.loadCanAddListFlagFailure({ message }));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(scorecardActions.loadCanAddListFlagFailure),
    map(action =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));
}
