import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IMarketingInfluenceReport, IMarketingInfluenceReportTotals, IMarketingInfluenceRequest } from '../../interfaces';
import { GoParams, IEditTableColumn, IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { Period } from '@measurement-studio/enums';

@Component({
  selector: 'ms-report-table',
  templateUrl: './marketing-table.component.html',
  styleUrls: ['./marketing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingTableComponent implements OnChanges {
  @Input() public report: IMarketingInfluenceReport[];
  @Input() public totalCount: number;
  @Input() public filters: IMarketingInfluenceRequest;
  @Input() public columns: IReportColumn[] = [];
  @Input() public allColumns: IReportColumn[] = [];
  @Input() public totals: IMarketingInfluenceReportTotals;
  @Input() public isLoading: boolean;
  @Input() public sortState: TsSortState;
  @Input() public page: number;
  @Input() public currency: string | null;

  @Output() public sortChange = new EventEmitter<TsSortState>();
  @Output() public navigate = new EventEmitter<GoParams>();
  @Output() public download = new EventEmitter<void>();
  @Output() public changeColumns = new EventEmitter<IEditTableColumn>();
  @Output() public changePage = new EventEmitter<number>();

  public dataSource: TsTableDataSource<IMarketingInfluenceReport | IMarketingInfluenceReportTotals> = new TsTableDataSource();
  public dataTypes = DataTypeEnum;
  public periodTypes = Period;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.report
      && changes.report.currentValue
      && changes.report.currentValue !== changes.report.previousValue) {
      // NOTE: last row is report totals
      this.dataSource.data = this.report;
      this.changeDetector.detectChanges(); // to fix table width issue
    }

    if (changes.totals) {
      this.changeDetector.detectChanges(); // to fix table width issue
    }
  }

  emitChangeColumns(data: IEditTableColumn): void {
    this.changeColumns.emit(data);
  }

  changeSort(sortState: TsSortState): void {
    this.sortChange.emit(sortState);
  }

  emitNavigate(route: GoParams): void {
    this.navigate.emit(route);
  }

  emitDownload(): void {
    this.download.emit();
  }

  emitPageChange(page: number): void {
    this.changePage.emit(page);
  }

  getTotalTitle(count: number, period: Period): string {
    if (period === Period.QUARTER) {
      return count === 1
        ? 'measurementStudio.features.marketingInfluence.table.totalTitleQuarter'
        : 'measurementStudio.features.marketingInfluence.table.totalTitleQuarterPlural';
    } else {
      return count === 1
        ? 'measurementStudio.features.marketingInfluence.table.totalTitleMonth'
        : 'measurementStudio.features.marketingInfluence.table.totalTitleMonthPlural';
    }
  }
}
