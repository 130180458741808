import { RouteItemEnum } from '@shared/enums';
import { MEASUREMENT_STUDIO_ROUTES } from '@measurement-studio/constants';
import { MEASUREMENT_STUDIO_SHARED_ROUTES } from '@shared/constants';

export function getRouteLink(routeId: RouteItemEnum): string[] | string {
  const route = [
    ...MEASUREMENT_STUDIO_ROUTES,
    ...MEASUREMENT_STUDIO_SHARED_ROUTES
  ].find(_route => _route.routeId === routeId);
  return route?.routeLink || '';
}
