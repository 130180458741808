import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { IFeatureFlags } from '@shared/interfaces';
import { selectFeatureFlags, selectIsUserProfileLoaded } from '@shared/data-access/user-common';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagAccessGuard implements CanActivate {
  userProfileLoadedState$ = this.store.pipe(
    select(selectIsUserProfileLoaded),
    map((loaded: boolean) => loaded)
  );

  featureFlagsState$ = this.store.pipe(
    select(selectFeatureFlags),
    map((featureFlags: IFeatureFlags) => featureFlags)
  );

  constructor(private store: Store<unknown>,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userProfileLoadedState$.pipe(
      filter((loaded: boolean) => loaded),
      withLatestFrom(this.featureFlagsState$),
      map(([_, featureFlags]: [boolean, IFeatureFlags]) => {
        // if there is no access to current feature then navigate to dashboards page. Because
        // its available for everyone. And home page (Scorecard report right now) is not available for everyone
        const isAvailableFeature = featureFlags[route.data.featureFlag]?.state === 'canAccess';

        if (!isAvailableFeature) {
          this.router.navigate(['dashboards']);
        }
        return !!isAvailableFeature;
      })
    );
  }
}
