import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { addMinutes, differenceInCalendarDays, subDays } from 'date-fns';
import { TsSelectionListChange } from '@terminus-lib/ui-selection-list';
import { TsDateRange } from '@terminus-lib/ui-date-range';

import { IDateCohort, IDateCohortGroup } from '@shared/interfaces';
import { toCapitalize } from '@util/helpers';
import { DateCohortsGroups } from '@shared/enums';

@Component({
  selector: 'tsh-date-cohort',
  templateUrl: './date-cohorts.component.html',
  styleUrls: ['./date-cohorts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCohortsComponent implements OnChanges {
  /**
   * Is disable filter
   * Default value - false
   */
  @Input() public isDisabled = false;

  /**
   * Array of date cohorts options by groups
   */
  @Input() public dateCohortOptions: IDateCohortGroup[];

  /**
   * Active date cohort
   */
  @Input()
  public set selectedDateRange(range: IDateCohort) {
    // If cohort is not custom then we have to subtract 1 day from the endDate
    if (range && range.cohort !== DateCohortsGroups.Custom) {
      this._selectedValue = {
        ...range,
        startDate: range.startDate ? +(new Date(range.startDate)) : null,
        endDate: range.endDate ? +(subDays(range.endDate, 1)) : null
      };
    } else {
      this._selectedValue = {...range};
    }
  }

  public get selectedDateRange(): IDateCohort {
    return this._selectedValue;
  }

  /**
   * Cohort change event emitter
   */
  @Output() dateRangeFilterChange = new EventEmitter<IDateCohort>();

  /**
   * Emit to apply date cohort changes. Emit empty on custom date change
   */
  @Output() apply = new EventEmitter<IDateCohort | undefined>();

  public formGroup = this.formBuilder.group({
    dateRange: this.formBuilder.group({
      startDate: null,
      endDate: null,
    }),
    cohort: [],
  });
  public customDateCohort = DateCohortsGroups.Custom;
  public endMinDate = new Date(2014, 0, 1);
  public endMaxDate = new Date();
  public startMinDate = new Date(2014, 0, 1);
  public startMaxDate = new Date();

  private _selectedValue: IDateCohort;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedDateRange?.currentValue) {
      this.formGroup.get('cohort').setValue([this.selectedDateRange]);
      this.formGroup.get('dateRange').setValue({
        startDate: this.convertToGMT(new Date(this.selectedDateRange.startDate)),
        endDate: this.convertToGMT(new Date(this.selectedDateRange.endDate)),
      });
    }
  }

  compareCohorts(a: IDateCohort, b: IDateCohort): boolean {
    return a && b && a.cohort === b.cohort;
  }

  formatter(cohort: IDateCohort): string {
    // don't apply toCapitalize to Year and Quarter cohorts
    if (cohort.name?.includes('FY') || cohort.name?.includes('Q')) {
      return cohort.name;
    }

    return toCapitalize(cohort.name);
  }

  changeDateCohort(event: TsSelectionListChange): void {
    const dateCohort = event.value[0];
    this.dateRangeFilterChange.emit(dateCohort);
    // if we select not custom range then it should be
    // data update without confirmation
    if (dateCohort.cohort !== DateCohortsGroups.Custom) {
      this.apply.emit(event.value[0]);
    }
  }

  changeCustomDateRange(dateRange: TsDateRange): void {
    dateRange.start.setHours(0);
    dateRange.end.setHours(0);
    const isTheSameStartDate = differenceInCalendarDays(dateRange.start, this.selectedDateRange.startDate) === 0;
    const isTheSameEndDate = differenceInCalendarDays(dateRange.end, this.selectedDateRange.endDate) === 0;

    if (!isTheSameStartDate || !isTheSameEndDate) {
      this.dateRangeFilterChange.emit({
        cohort: DateCohortsGroups.Custom,
        name: 'Custom Range',
        startDate: this.applyGMTOffset(dateRange.start),
        endDate: this.applyGMTOffset(dateRange.end)
      });
    }
  }

  // shift the date to some amount of minutes(timezone offset) to make it the same by GMT
  applyGMTOffset(timestamp: Date): number {
    const date = new Date(timestamp);
    const minutesOffset = -(date.getTimezoneOffset());
    return addMinutes(date, minutesOffset).getTime();
  }

  convertToGMT(date: Date): Date {
    // return date without timezone correction in order to show timeframes independently from the timezone
    const gmtDate = new Date(date.toLocaleString('en-US', {timeZone: 'Europe/London'}));
    gmtDate.setHours(0);
    return gmtDate;
  }

  emitApply(): void {
    this.apply.emit();
  }

  trackByFn(index: number): number {
    return index;
  }
}
