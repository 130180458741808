import { QueryList } from '@angular/core';

export class MockQueryList extends QueryList<unknown> {
  private idx = 0;

  constructor(private items: unknown[] = []) {
    super();
  }

  [Symbol.iterator] = (): IterableIterator<unknown> => this;
  public next = (): IteratorResult<unknown> => ({
    done: (this.idx >= this.items.length),
    value: (this.idx >= this.items.length) ? null : this.items[this.idx++],
  });
  public toArray = () => [...this.items];
  public get = (index: number) => this.items[index]
}
