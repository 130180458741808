import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';
import { filter, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { AgoRoundDown, DateCohortsGroups, InfluenceType, ModelType, PortalTargets, TileTypes } from '@shared/enums';
import { IMarketingInfluenceRequest, OpportunityType } from '../interfaces';
import { GoParams, IDateCohort, IEditTableColumn } from '@shared/interfaces';
import { Period } from '@measurement-studio/enums';
import { NavigateTo } from '@hub-navigation';
import { marketingInfluenceActions } from '../state/marketing-influence.actions';
import * as selectors from '../state/marketing-influence.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as DateCohortsStore from '@date-cohorts';
import * as OrgConfigStore from '@org-config';
import { TileModalService } from '@shared/features/tile-modal';
import { PLOT_POINTS_FIELD } from '../marketing-influence.constants';
import { MarketingInfluenceState } from '../state/marketing-influence.reducer';

@WithDestroy
@Component({
  selector: 'ms-marketing-influence',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class MarketingInfluenceComponent implements OnInit {
  public readonly targets = PortalTargets;
  public reportData$ = this.store.pipe(select(selectors.getMarketingInfluenceSortedReportData));
  public count$ = this.store.pipe(select(selectors.getMarketingInfluenceReportCount));
  public reportColumns$ = this.store.pipe(select(selectors.getVisibleMarketingInfluenceColumns));
  public allColumns$ = this.store.pipe(select(selectors.getColumnsForSelector));
  public isLoading$ = this.store.pipe(select(selectors.getMarketingInfluenceLoading));
  public reportFilters$ = this.store.pipe(select(selectors.getMarketingInfluenceFilters));
  public sortState$ = this.store.pipe(select(selectors.getMarketingInfluenceSortState));
  public page$ = this.store.pipe(select(selectors.getMarketingInfluencePage));
  public reportTotals$ = this.store.pipe(select(selectors.getMarketingInfluenceReportTotals));
  public chartData$ = this.store.pipe(select(selectors.getMarketingInfluenceReport));
  public dateCohortOptions$ = this.store.pipe(
    select(DateCohortsStore.getGroupedDateCohortOptions([
      DateCohortsGroups.All, DateCohortsGroups.Years, DateCohortsGroups.AgoRoundDown, DateCohortsGroups.Custom
    ])));
  public selectedDateCohort$ = this.store.pipe(select(selectors.getMarketingSelectedDateCohort));
  public metrics$ = this.store.pipe(select(selectors.getMarketingInfluenceMetrics));
  public currencySetting$ = this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting));

  public defaultQueryParams: IMarketingInfluenceRequest = {
    influenceType: InfluenceType.ANY,
    modelType: ModelType.Even,
    period: Period.QUARTER,
    opptyType: OpportunityType.DEAL,
    cohort: AgoRoundDown.TWO_YEARS_AGO_FROM_TODAY
  };
  public noResultTitle = 'measurementStudio.features.marketingInfluence.noResultTitle';
  public noResultDescription = 'measurementStudio.features.marketingInfluence.noResultDescription';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<MarketingInfluenceState>,
    private tileModalService: TileModalService,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(untilComponentDestroyed(this))
    .subscribe(params => {
      const filters$ = this.reportFilters$.pipe(take(1));
      const globalFilters$ = this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1));
      combineLatest([filters$, globalFilters$])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([filters, globalFilters]) => {
        if (!Object.keys(params).length && !filters) {
          // if there is no any query params then use default params
          this.applyFilters({ ...this.defaultQueryParams, ...globalFilters });
          return;
        }

        if (!Object.keys(params).length && filters) {
          // if there is no any query params but we have already had filters in the store then we have to use them
          this.applyFilters({ ...filters, ...globalFilters });
          return;
        }

        const reportParams = this.getReportFilters(params);
        this.setDateCohort(reportParams);
        // get report data with filters from query params
        this.store.dispatch(marketingInfluenceActions.setMarketingInfluenceFilter({ filters: reportParams }));
      });
    });
  }

  changeSortState(sortState: TsSortState): void {
    this.store.dispatch(marketingInfluenceActions.setMarketingTableSortState({ sortState }));
  }

  changePage(page: number): void {
    this.store.dispatch(marketingInfluenceActions.setMarketingTablePage({ page }));
  }

  applyFilters(newFilters: IMarketingInfluenceRequest): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...newFilters,
        influenceType: newFilters.opptyType === OpportunityType.OPPTY ? InfluenceType.PRE : newFilters.influenceType,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  downloadReport(): void {
    this.store.dispatch(marketingInfluenceActions.downloadCsv());
  }

  changeSelectedDateCohort(cohort: IDateCohort): void {
    this.store.dispatch(marketingInfluenceActions.setSelectedDateCohort({ cohort }));
  }

  navigate(route: GoParams): void {
    this.store.dispatch(NavigateTo(route));
  }

  changeColumns(column: IEditTableColumn): void {
    this.store.dispatch(marketingInfluenceActions.toggleColumnVisibility({ column }));
  }

  addToDashboardDialog(): void {
    const { queryParams } = this.route.snapshot;
    const settings = {
      ...queryParams,
      [PLOT_POINTS_FIELD]: Number(queryParams[PLOT_POINTS_FIELD]) || 5,
    };

    this.tileModalService.openAddToDashboardModal(TileTypes.MarketingInfluence, settings);
  }

  private setDateCohort(reportParams: IMarketingInfluenceRequest): void {
    if (reportParams.cohort === 'custom') {
      // in case of custom date cohort set data from query params
      this.changeSelectedDateCohort({
        name: 'Custom Range',
        cohort: reportParams.cohort,
        endDate: reportParams.endDate,
        startDate: reportParams.startDate,
      });
    } else {
      // in case of non custom date cohort set data from date cohorts source
      this.store.pipe(
        untilComponentDestroyed(this),
        select(DateCohortsStore.getDateCohortByCohort(reportParams.cohort)),
        filter((dateCohort: IDateCohort) => !!dateCohort)
      ).subscribe(data => {
        this.changeSelectedDateCohort(data);
      });
    }
  }

  private getReportFilters(queryParams: Params): IMarketingInfluenceRequest {
    // get query params from url or use default params
    const type = queryParams['opptyType'] || this.defaultQueryParams.opptyType;
    const isOppty = type === OpportunityType.OPPTY;
    const influence = queryParams['influenceType'] || this.defaultQueryParams.influenceType;
    const defaultParams = {
      influenceType: isOppty ? InfluenceType.PRE : influence,
      modelType: queryParams['modelType'] || this.defaultQueryParams.modelType,
      period: queryParams['period'] || this.defaultQueryParams.period,
      opptyType: type,
      cohort: queryParams['cohort'] || this.defaultQueryParams.cohort,
      endDate: queryParams['endDate'],
      startDate: queryParams['startDate'],
    };

    // NOTE: if date cohort is custom but there is no startDate
    // then we have to set startDate the same as endDate or just current date
    const startDate = queryParams.cohort === 'custom'
      ? queryParams.startDate || queryParams.endDate || new Date().getTime()
      : queryParams.startDate;
    // NOTE: if date cohort is custom but there is no endDate
    // then we have to set endDate the same as startDate or just current date
    const endDate = queryParams.cohort === 'custom'
      ? queryParams.endDate || queryParams.startDate || new Date().getTime()
      : queryParams.endDate;
    // need to change startDate and endDate to number because query params are string
    return {
      ...defaultParams,
      startDate: startDate ? Number(startDate) : startDate,
      endDate: endDate ? Number(endDate) : endDate,
    };
  }
}
