<div class="tables-container">
  <ng-container *ngFor="let details of groupDetails$ | async">
    <ms-trending-group-table (expandRow)="expandRow($event)"
                             (downloadResponses)="downloadResponses($event)"
                             (downloadCampaign)="downloadCampaign($event)"
                             (download)="downloadGroupDetails($event)"
                             (changeSort)="changeSort($event)"
                             (changeCampaignSort)="changeCampaignSort($event)"
                             (changeCampaignPage)="changeCampaignPage($event)"
                             (expandCampaignRow)="expandCampaignRow($event)"
                             [isCampaignTrendsReport]="isCampaignTrendsReport$ | async"
                             [isLoading]="(groupDetailsLoading$ | async)[details.period]"
                             [campaignTotalCount]="(campaignTotalCount$ | async)[details.period]"
                             [campaignPagination]="(campaignPagination$ | async)[details.period]"
                             [campaignColumns]="campaignColumns$ | async"
                             [campaignRowState]="(campaignRowState$ | async)[details.period]"
                             [campaignSortState]="(campaignSortState$ | async)[details.period]"
                             [campaigns]="(campaigns$ | async) && (campaigns$ | async)[details.period]"
                             [columns]="groupDetailsColumns$ | async"
                             [responsesColumns]="responsesColumns$ | async"
                             [filters]="filters$ | async"
                             [color]="details.color"
                             [periodTitle]="details.periodTitle"
                             [period]="details.period"
                             [data]="(groupDetailsData$ | async) && (groupDetailsData$ | async)[details.period]"
                             [totalCount]="(groupDetailsTotals$ | async) && (groupDetailsTotals$ | async)[details.period]"
                             [sortState]="details.sortState"
                             [rowState]="details.rowState"
                             [title]="details.title">
    </ms-trending-group-table>
  </ng-container>
</div>
