<label class="c-input__label-text" [for]="id" *ngIf="!showPlaceholder">{{ label }}</label>

<div
  class="ts-selection-list__input-wrap"
  [class.ts-selection-list__input-wrap--minimal]="isMinimal"
  [class.ts-selection-list__input-wrap--error]="!!errorMessage"
  [class.ts-selection-list__input-wrap--disabled]="isDisabled"
  #panelContainer
>
  <ng-container *ngIf="allowMultiple">
    <ts-chip-collection
      (tabUpdateFocus)="focusInput()"
      [isDisabled]="isDisabled"
    >
      <ts-chip
        *ngFor="let chip of ngControl.value; trackBy: trackByFn"
        [value]="chip"
        [isDisabled]="isDisabled"
        (remove)="deselectItem($event)"
      >{{ displayFormatter(chip) }}</ts-chip>

      <input
        class="ts-selection-list__input ts-selection-list__input--multiple"
        [placeholder]="showPlaceholder ? label : ''"
        *ngIf="allowUserInput"
        [tsSelectionListTrigger]="auto"
        [containingElement]="panelContainerElementRef"
        [attr.data-allow-input]="allowUserInput"
        [attr.id]="id"
        [disabled]="isDisabled"
        [allowMultiple]="allowMultiple"
        [reopenAfterSelection]="reopenAfterSelection"
        [(ngModel)]="searchQuery"
        [readonly]="isDisabled || !allowUserInput ? 'true' : null"
        (ngModelChange)="querySubject.next($event)"
        (blur)="handleInputBlur($event)"
        onfocus="this.select()"
        #input
      />
      <ng-template *ngTemplateOutlet="triggerTemplate"></ng-template>
    </ts-chip-collection>

    <ng-template *ngTemplateOutlet="spinnerTemplate"></ng-template>
  </ng-container>

  <ng-container *ngIf="!allowMultiple">
    <input
      class="ts-selection-list__input"
      *ngIf="allowUserInput"
      [placeholder]="showPlaceholder ? label : ''"
      [tsSelectionListTrigger]="auto"
      [containingElement]="panelContainerElementRef"
      [attr.data-allow-input]="allowUserInput"
      [attr.id]="id"
      [disabled]="isDisabled"
      [allowMultiple]="allowMultiple"
      [readonly]="isDisabled || !allowUserInput ? 'true' : null"
      [(ngModel)]="searchQuery"
      [value]="searchQuery"
      (ngModelChange)="querySubject.next($event)"
      (blur)="handleInputBlur($event)"
      onfocus="this.select()"
      #input
    />

    <ng-template *ngTemplateOutlet="triggerTemplate"></ng-template>
    <ng-template *ngTemplateOutlet="spinnerTemplate"></ng-template>
  </ng-container>

  <span *ngIf="showArrow" class="ts-selection-list__caret">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="caret-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
      ></path>
    </svg>
    </span>
</div>

<div class="ts-selection-list__messages" *ngIf="!noValidationOrHint">
  <div class="ts-selection-list__error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="ts-selection-list__hint" *ngIf="hint && !errorMessage">{{ hint }}</div>
</div>

<ng-template #triggerTemplate>
  <input
    type="text"
    *ngIf="!allowUserInput"
    [placeholder]="showPlaceholder ? label : ''"
    [tsSelectionListTrigger]="auto"
    class="ts-selection-list__input ts-selection-list__custom-trigger"
    [attr.data-disabled]="isDisabled"
    readonly
    value="{{ staticTriggerDisplay }}"
    title="{{ staticTriggerDisplay }}"
    #input
  >
</ng-template>

<ts-selection-list-panel
  class="ts-selection-list"
  #auto="tsSelectionListPanel"
  [id]="id + '-panel'"
  [options]="options"
  [optionGroups]="optionGroups"
  [displayWith]="displayFormatter"
  (optionSelected)="selectItem($event)"
>
  <!-- Outlet for options passed in by consumer -->
  <ng-template *ngTemplateOutlet="contentTemplate"></ng-template>
</ts-selection-list-panel>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #spinnerTemplate>
  <span *ngIf="showProgress" class="c-selection-list__spinner">
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </span>
</ng-template>
