/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';

import * as interfaces from '../../interfaces';
import { ILabelValue, IPager, ISorter } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { INestedTableEvent } from '@measurement-studio/ui/analytics-tables';
import { TypedAction } from '@ngrx/store/src/models';

export enum CampaignsActionsTypes {
  SetUsedParams = '[CA Campaigns] Set used params in driver request',
  GetCampaigns = '[CA Campaigns] Get all data',
  HasWebActivity = '[CA Campaigns] Has Web Activity',
  HasWebActivitySuccess = '[CA Campaigns] Has Web Activity Success',
  HasWebActivityFailure = '[CA Campaigns] Has Web Activity Failure',
  ToggleTableRow = '[CA Campaigns] Toggle Table Row',
  CloseNestedTables = '[CA Campaigns] Close Nested Tables',
  LoadMetaData = '[CA Campaigns] Load MetaData',
  LoadMetaDataSuccess = '[CA Campaigns] Load MetaData Success',
  LoadMetaDataFailure = '[CA Campaigns] Load MetaData Failure',
  LoadResponses = '[CA Campaigns] Load Responses',
  LoadResponsesSuccess = '[CA Campaigns] Load Responses Success',
  LoadResponsesFailure = '[CA Campaigns] Load Responses Failure',
  LoadAttribution = '[CA Campaigns] Load Attribution',
  LoadAttributionSuccess = '[CA Campaigns] Load Attribution Success',
  LoadAttributionFailure = '[CA Campaigns] Load Attribution Failure',
  LoadInfluence = '[CA Campaigns] Load Influence',
  LoadInfluenceSuccess = '[CA Campaigns] Load Influence Success',
  LoadInfluenceFailure = '[CA Campaigns] Load Influence Failure',
  LoadReturns = '[CA Campaigns] Load Returns',
  LoadReturnsSuccess = '[CA Campaigns] Load Returns Success',
  LoadReturnsFailure = '[CA Campaigns] Load Returns Failure',
  LoadCosts = '[CA Campaigns] Load Costs',
  LoadCostsSuccess = '[CA Campaigns] Load Costs Success',
  LoadCostsFailure = '[CA Campaigns] Load Costs Failure',
  SetSelectedCampaignType = '[CA Campaigns] Set Selected Campaign Type',
  SetSelectedTags = '[CA Campaigns] Set Selected Tags',
  ChangeNestedSorting = '[CA Campaigns] Change nested sorting for campaign id',
  ChangeNestedPagination = '[CA Campaigns] Change nested pagination for campaign id',
  ClearNestedState = '[Campaign] Clear nested state',
}

const setUsedParams = createAction(
  CampaignsActionsTypes.SetUsedParams,
  props<{ request: IAnalyticsRequest }>()
);

const getCampaigns = createAction(
  CampaignsActionsTypes.GetCampaigns,
);

const hasWebActivity = createAction(
  CampaignsActionsTypes.HasWebActivity,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const hasWebActivitySuccess = createAction(
  CampaignsActionsTypes.HasWebActivitySuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignHasWebActivity>>()
);

const hasWebActivityFailure = createAction(
  CampaignsActionsTypes.HasWebActivityFailure,
  props<{ message: string }>()
);


const toggleTableRow = createAction(
  CampaignsActionsTypes.ToggleTableRow,
  props<{ id: string }>()
);

const closeNestedTables = createAction(
  CampaignsActionsTypes.CloseNestedTables,
);

const loadMetaData = createAction(
  CampaignsActionsTypes.LoadMetaData,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadMetaDataSuccess = createAction(
  CampaignsActionsTypes.LoadMetaDataSuccess,
  props<interfaces.ISuccessPayload<interfaces.IMetaData<interfaces.ICampaignsResponse>>>()
);

const loadMetaDataFailure = createAction(
  CampaignsActionsTypes.LoadMetaDataFailure,
  props<{ message: string }>()
);

const loadResponses = createAction(
  CampaignsActionsTypes.LoadResponses,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadResponsesSuccess = createAction(
  CampaignsActionsTypes.LoadResponsesSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignResponses>>()
);

const loadResponsesFailure = createAction(
  CampaignsActionsTypes.LoadResponsesFailure,
  props<{ message: string }>()
);

const loadAttribution = createAction(
  CampaignsActionsTypes.LoadAttribution,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadAttributionSuccess = createAction(
  CampaignsActionsTypes.LoadAttributionSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignAttribution>>()
);

const loadAttributionFailure = createAction(
  CampaignsActionsTypes.LoadAttributionFailure,
  props<{ message: string }>()
);

const loadInfluence = createAction(
  CampaignsActionsTypes.LoadInfluence,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadInfluenceSuccess = createAction(
  CampaignsActionsTypes.LoadInfluenceSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignInfluence>>()
);

const loadInfluenceFailure = createAction(
  CampaignsActionsTypes.LoadInfluenceFailure,
  props<{ message: string }>()
);

const loadReturns = createAction(
  CampaignsActionsTypes.LoadReturns,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadReturnsSuccess = createAction(
  CampaignsActionsTypes.LoadReturnsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignReturns>>()
);

const loadReturnsFailure = createAction(
  CampaignsActionsTypes.LoadReturnsFailure,
  props<{ message: string }>()
);

const loadCosts = createAction(
  CampaignsActionsTypes.LoadCosts,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadCostsSuccess = createAction(
  CampaignsActionsTypes.LoadCostsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignCosts>>()
);

const loadCostsFailure = createAction(
  CampaignsActionsTypes.LoadCostsFailure,
  props<{ message: string }>()
);

const setSelectedCampaignType = createAction(
  CampaignsActionsTypes.SetSelectedCampaignType,
  props<{ data: ILabelValue }>()
);

const setSelectedTags = createAction(
  CampaignsActionsTypes.SetSelectedTags,
  props<{ data: string[] }>()
);

const changeNestedSorting = createAction(
  CampaignsActionsTypes.ChangeNestedSorting,
  props<{ data: INestedTableEvent<ISorter> }>()
);

const changeNestedPagination = createAction(
  CampaignsActionsTypes.ChangeNestedPagination,
  props<{ data: INestedTableEvent<IPager> }>()
);

const clearNestedState = createAction(
  CampaignsActionsTypes.ClearNestedState,
);

export const campaignsActions = {
  setUsedParams,
  getCampaigns,
  hasWebActivity,
  hasWebActivitySuccess,
  hasWebActivityFailure,
  toggleTableRow,
  closeNestedTables,
  loadMetaData,
  loadMetaDataSuccess,
  loadMetaDataFailure,
  loadResponses,
  loadResponsesSuccess,
  loadResponsesFailure,
  loadAttribution,
  loadAttributionSuccess,
  loadAttributionFailure,
  loadInfluence,
  loadInfluenceSuccess,
  loadInfluenceFailure,
  loadReturns,
  loadReturnsSuccess,
  loadReturnsFailure,
  loadCosts,
  loadCostsSuccess,
  loadCostsFailure,
  setSelectedCampaignType,
  setSelectedTags,
  changeNestedSorting,
  changeNestedPagination,
  clearNestedState,
};

export type CampaignsLoadActions = interfaces.ILoadPayload & TypedAction<CampaignsActionsTypes>;

export type CampaignsLoadSuccessActions = interfaces.ISuccessPayload<any> & TypedAction<CampaignsActionsTypes>;

// Establishing dependencies between LoadSuccess and Load actions (Campaigns load actions)
export const campaignsActionsMap: Map<CampaignsActionsTypes, CampaignsLoadActions> = new Map()
  .set(CampaignsActionsTypes.LoadAttributionSuccess, loadAttribution())
  .set(CampaignsActionsTypes.LoadCostsSuccess, loadCosts())
  .set(CampaignsActionsTypes.LoadInfluenceSuccess, loadInfluence())
  .set(CampaignsActionsTypes.LoadMetaDataSuccess, loadMetaData())
  .set(CampaignsActionsTypes.LoadResponsesSuccess, loadResponses())
  .set(CampaignsActionsTypes.LoadReturnsSuccess, loadReturns());
