import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export class ContinuationTokenOnlyInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req?.body && 'continuationToken' in req.body) {
            const extraKeys = Object.keys(req.body).filter(key => key !== 'continuationToken');
            if(extraKeys.length) {
                console.warn('Removing extra keys from request with continuationToken', {url: req.url, keys: extraKeys});
                for(const key of extraKeys){
                    delete req.body[key];
                }
            }
        }
        return next.handle(req);
    }
}
