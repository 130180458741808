/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import { Action, createReducer, on } from '@ngrx/store';
import { SPLITIO_STATUS } from '../constants';

import * as SplitIOActions from './split-io.actions';

export const SPLIT_IO_TOKEN = 'splitIoState';

export interface SplitIoState {
  grouped: {
    [clientKey: string]: {
      [treatment: string]: SPLITIO_STATUS;
    };
  };
  overrides: Record<string, SPLITIO_STATUS> | null;
}

export const initialState: SplitIoState = {
  grouped: {},
  overrides: null,
};

const reducer = createReducer(
  initialState,

  on(SplitIOActions.setOverrides, (state, action) => {
    return {
      ...state,
      overrides: action.overrides,
    };
  }),

  on(SplitIOActions.splitIoFlagReceived, (state, action) => {
    return {
      ...state,
      grouped: {
        ...state.grouped,
        [action.trafficType]: {
          ...(state.grouped[action.trafficType] || {}),
          [action.treatment]: action.status,
        },
      },
    };
  }),
);

export function splitIoReducer(state: SplitIoState, action: Action) {
  return reducer(state, action);
}
