import { campaignGroupsActions } from './campaign-groups.actions';
import * as interfaces from '../../interfaces';
import { assembleAnalyticsData, updateNestedTableVisibility } from '../../helpers/store.helper';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const campaignGroupsFeatureKey = 'campaignGroups';

export interface ICampaignGroupsState extends interfaces.IAnalyticsIsLoading {
  totalResults: number;
  data: Partial<interfaces.ICampaignGroupsTableItem>[];
  hasWebActivity: interfaces.ICampaignHasWebActivityResponse[];
  nestedTableState: interfaces.INestedTableState;
  usedParamsInDriver: IAnalyticsRequest | null;
}

export const initialState: ICampaignGroupsState = {
  totalResults: undefined,
  data: [],
  hasWebActivity: [],
  hasWebActivityIsLoading: false,
  isDriverLoading: false,
  campaignIsLoading: false,
  responseIsLoading: false,
  attributedIsLoading: false,
  influenceIsLoading: false,
  returnsIsLoading: false,
  costIsLoading: false,
  nestedTableState: {
    visible: {},
  },
  usedParamsInDriver: null,
};

const campaignGroupsReducer = createReducer(
  initialState,
  on(campaignGroupsActions.setUsedParams, (state, action) => ({
    ...state,
    usedParamsInDriver: action.request
  })),
  on(campaignGroupsActions.hasWebActivitySuccess, (state, action) => ({
    ...state,
    hasWebActivity: action.data.hasWebActivity,
    hasWebActivityIsLoading: false,
  })),
  on(campaignGroupsActions.loadTypeMetaDataSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.campaign),
    campaignIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeResponsesSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.response),
    responseIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeAttributionSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.attributed),
    attributedIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeInfluenceSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.influenced),
    influenceIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeReturnsSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.returns),
    returnsIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeCostsSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.costs),
    costIsLoading: false,
    isDriverLoading: false,
  })),
  on(campaignGroupsActions.loadTypeMetaData, (state, action) => ({
    ...state,
    campaignIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.hasWebActivity, (state, action) => ({
    ...state,
    hasWebActivityIsLoading: true,
  })),
  on(campaignGroupsActions.loadTypeResponses, (state, action) => ({
    ...state,
    responseIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.loadTypeAttribution, (state, action) => ({
    ...state,
    attributedIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.loadTypeInfluence, (state, action) => ({
    ...state,
    influenceIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.loadTypeCosts, (state, action) => ({
    ...state,
    costIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.loadTypeReturns, (state, action) => ({
    ...state,
    returnsIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(campaignGroupsActions.loadTypeMetaDataFailure, (state) => ({
    ...state, campaignIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.hasWebActivityFailure, (state) => ({
    ...state, hasWebActivityIsLoading: false
  })),
  on(campaignGroupsActions.loadTypeResponsesFailure, (state) => ({
    ...state, responseIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.loadTypeAttributionFailure, (state) => ({
    ...state, attributedIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.loadTypeInfluenceFailure, (state) => ({
    ...state, influenceIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.loadTypeCostsFailure, (state) => ({
    ...state, costIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.loadTypeReturnsFailure, (state) => ({
    ...state, returnsIsLoading: false, isDriverLoading: false
  })),
  on(campaignGroupsActions.closeNestedTables, (state) => ({
    ...state,
    nestedTableState: {visible: {}}
  })),
  on(campaignGroupsActions.toggleTableRow, (state, action) => ({
    ...state,
    nestedTableState: updateNestedTableVisibility(state.nestedTableState, action.id),
  })),
);

export function reducer(state, action: Action): ICampaignGroupsState {
  return campaignGroupsReducer(state, action);
}
