import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { TsTooltipModule } from '@terminus/ui-tooltip';

import { SearchByPropertyComponent } from './search-by-property.component';

@NgModule({
  declarations: [SearchByPropertyComponent],
  exports: [SearchByPropertyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TsInputModule,
    TranslateModule,
    MatSelectModule,
  ]
})
export class SearchByPropertyModule {
}
