<ng-container *ngIf="filters">
  <tsh-date-cohort *ngIf="selectedDateCohort"
                   (apply)="applyDateCohort($event)"
                   (dateRangeFilterChange)="changeDateCohort($event)"
                   [selectedDateRange]="selectedDateCohort"
                   [dateCohortOptions]="dateCohortOptions"
                   [isDisabled]="isLoading">
  </tsh-date-cohort>
  <tsh-report-setting-panel (apply)="apply()"
                            [reportName]="isCampaignTrendsReport ? 'feature.campaignTrends.filters.reportName' : 'feature.webActivitiesTrending.filters.reportName'"
                            [isDisabled]="isLoading"
                            data-cy="trending-filters-report-settings-panel"
>
    <tsh-report-filter-selector title="feature.sharedTrending.filters.metric"
                                [options]="metricOptions"
                                [field]="filterForm.get('field')">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="feature.sharedTrending.filters.interval"
                                [options]="frequencyTypeOptions"
                                [field]="filterForm.get('freqType')"
                                data-cy="trending-filter-interval-selector">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="feature.sharedTrending.filters.attributionModel"
                                [options]="modelTypeOptions"
                                [field]="filterForm.get('model')">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="feature.sharedTrending.filters.campaignsThat"
                                [options]="datasetTypeOptions"
                                [field]="filterForm.get('dataSet')">
    </tsh-report-filter-selector>
    <tsh-benchmark-control (confirm)="confirmBenchmarkChange($event)"
                           [goalField]="filterForm.get('goal')"
                           [typeField]="filterForm.get('type')"
                           [previous]="filterForm.get('previous').value">
    </tsh-benchmark-control>
  </tsh-report-setting-panel>
</ng-container>
