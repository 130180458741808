import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, map, switchMap} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {  Store } from '@ngrx/store';

import { IDcLastUpdatedDate, userMessageFactory } from '@shared/interfaces';
import { AppSettingsService } from '../services/app-settings/app-settings.service';
import { AppSettingsState } from './app-settings.reducer';
import { appSettingsActions } from './app-settings.actions';
import { notificationMessagesActions } from '@notification-messages';
import { Router } from '@angular/router';
import { EnvService } from '@shared/environment';

@Injectable()
export class AppSettingsEffects {
  constructor(
    private actions$: Actions,
    private appSettingsService: AppSettingsService,
    private store: Store<AppSettingsState>,
    private readonly router: Router,
    private readonly envService: EnvService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appSettingsActions.loadDcLastUpdatedDate),
      switchMap(() =>
        this.appSettingsService.getDcLastUpdatedDate$().pipe(
          map((lastUpdated: IDcLastUpdatedDate) =>
            appSettingsActions.loadDcLastUpdatedDateSuccess({
              date: new Date(lastUpdated.lastUpdate),
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              appSettingsActions.loadDcLastUpdatedDateFailure({
                message: `${error.status}: Unable to retrieve last data cycle date.  Please try again later.`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appSettingsActions.loadDcLastUpdatedDateFailure),
      map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) })),
    ),
  );
}
