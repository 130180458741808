import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IReportColumn } from '@shared/interfaces';

@Component({
  selector: 'tsh-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderCellComponent {
  @Input() column: IReportColumn;
  @Input() index = 0;
}
