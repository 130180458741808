import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { splitIoReducer, SPLIT_IO_TOKEN } from './state/split-io.reducer';
import { SplitIoEffects } from './state/split-io.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SPLIT_IO_TOKEN, splitIoReducer),
    EffectsModule.forFeature([SplitIoEffects]),
  ],
  declarations: [],
  exports: []
})
export class SplitioModule {}
