export enum KpiGroup {
  None = 'NONE',
  ScorecardAggregate = 'SCORECARD_AGGREGATE',
  ScorecardSegment = 'SCORECARD_SEGMENT',
  AccountEngagement = 'ACCOUNT_ENGAGEMENT',
  Impressions = 'IMPRESSIONS',
  Firmographics = 'FIRMOGRAPHICS',
}

export enum KpiAudience {
  Account = 'list',
  Segment = 'segment',
}

export enum KpiInterval {
  Weeks = 'WEEKS',
  Months = 'MONTHS',
  Quarters = 'QUARTERS',
}

export enum KpiProgression {
  List = 'LIST',
  Engaged = 'ENGAGED',
  Segment = 'SEGMENT',
  Opportunity = 'OPPORTUNITY',
  Won = 'WON',
}

export enum KpiTrend {
  Net = 'NET',
  Cumulative = 'CUMULATIVE',
  Snapshot = 'SNAPSHOT',
}

export enum KpiType {
  Account = 'ACCOUNT',
  OpptyCreated = 'OPPTY_CREATED',
  OpptyClosed = 'OPPTY_CLOSED',
  OpptyWon = 'OPPTY_WON',
  Pipeline = 'PIPELINE',
  Revenue = 'REVENUE',
  WinRate = 'WIN_RATE',
  DealVelocity = 'DEAL_VELOCITY',
  AvgDealSize = 'AVG_DEAL_SIZE',
}

export enum KpiFirmographic {
  None = 'NONE',
  Revenue = 'REVENUE',
  Employee = 'EMPLOYEE',
  Industry = 'INDUSTRY',
}

export enum SeriesTypes {
  Line = 'line',
  StackedArea = 'stackedArea',
  Column = 'column',
  StackedColumn = 'stackedColumn',
}

export enum KpiTrendLabels {
  NetNew = 'measurementStudio.features.scorecard.trendOptions.netNew',
  NetChange = 'measurementStudio.features.scorecard.trendOptions.netChange',
  Snapshot = 'measurementStudio.features.scorecard.trendOptions.snapshot',
  Cumulative = 'measurementStudio.features.scorecard.trendOptions.cumulatively',
}
