<div [tsLoadingOverlay]="isLoading$ | async">
  <div class="header">
    <h3 class="header-title"
      *ngIf="data">{{
      (data.dialogType === DialogType.AddAccounts
        ? 'shared.accountHub.addAccountsToList'
        : 'shared.accountHub.manageLists') | translate }}</h3>
    <i class="fas fa-times" data-cy="ds-manage-lists-close"
        (click)="dialogRef.close()"></i>
  </div>
  <hr class="dialog-divider">
  <div class="content"
    *ngIf="data && (folders$ | async) as folders">
    <h3 class="header-sub-title">{{ 'shared.accountHub.folders' | translate }}</h3>
    <div class="block" data-cy="ds-manage-lists-container">
      <ts-accordion>
        <ng-container *ngFor="let folder of folders; let folderIndex = index">
          <ts-expansion-panel *ngIf="folder.isMine || folder.folderPermission !== FolderPermission.Private"
            [transparentMode]="true"
            (expandedChange)="onExpandedChange($event, folder)">
            <ts-expansion-panel-trigger
              collapsedHeight="36px"
              expandedHeight="36px"
              [attr.data-cy]="'tsh-list-folder-' + folderIndex"
              [ngClass]="{ 'folder-active': !selectedList && selectedFolder?.folderId === folder.folderId }">
              <div class="flex start folder">
                <i class="fas fa-folder"></i>
                <div class="flex space-between">
                  <div class="ellipsis"
                    [title]="folder.folderName">
                    {{ folder.folderName }}
                  </div>
                  <div class="type">
                    {{ folder.folderPermission }}
                  </div>
                </div>
              </div>
            </ts-expansion-panel-trigger>

            <ng-template tsExpansionPanelContent>
              <ng-container *ngIf="folder.contents.length">
                <div *ngFor="let list of folder.contents; let folderContentIndex = index"
                  class="list"
                  (click)="selectedList = list">
                  <div class="list-info"
                    [attr.data-cy]="'tsh-list-folder-content-container'"
                    [ngClass]="{ 'list-active': selectedList?.id === list.id }">
                    <div>
                      <span class="ellipsis"
                        *ngIf="data.dialogType === DialogType.AddAccounts ||
                              (!selectedFolder?.isMine &&
                              selectedFolder?.folderPermission !== FolderPermission.Public)">{{ list.name }}
                      </span>
                      <a lass="ellipsis"
                        *ngIf="data.dialogType === DialogType.ManageLists &&
                              (selectedFolder?.isMine || selectedFolder?.folderPermission === FolderPermission.Public)"
                        (click)="editList(list, folder)"
                         [attr.data-cy]="'tsh-list-folder-content-' + folderIndex + '-' +  folderContentIndex"
                        [title]="(!selectedFolder?.isMine && selectedFolder?.folderPermission !== FolderPermission.Public
                                  ? 'shared.accountHub.editListDisabled'
                                  : 'shared.accountHub.editList') | translate">{{ list.name }}
                      </a>
                    </div>
                    <div>{{ list.numMembers }} accounts</div>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="!folder.contents.length"
                class="no-list">
                <p>{{ 'shared.accountHub.noAccountListsInFolder' | translate }}</p>
                <a (click)="createList(folders)">{{ 'shared.accountHub.createAccountList' | translate }}</a>
              </div>
            </ng-template>
          </ts-expansion-panel>
        </ng-container>
        <div *ngIf="!folders.length">
          <p>{{ 'shared.accountHub.noAccountListFolders' | translate }}</p>
          <p>{{ 'shared.accountHub.createAccountListDescription' | translate }}</p>
          <a (click)="createFolder()">{{ 'shared.accountHub.createFolder' | translate }}</a>
        </div>
      </ts-accordion>
    </div>
  </div>
  <hr class="dialog-divider">
  <div class="actions"
    *ngIf="data && (folders$ | async) as folders">
    <div class="start">
        <ts-button *ngIf="data.dialogType === DialogType.ManageLists && (!selectedFolder || !selectedList)"
            cdkFocusInitial
            theme="secondary"
            [isDisabled]="!selectedFolder?.isMine"
            (clicked)="deleteFolder()"
            [title]="(!selectedFolder?.isMine
                ? 'shared.accountHub.deleteDisabled'
                : 'shared.accountHub.deletePopover') | translate">
            <i class="fas fa-trash"></i>
        </ts-button>
        <ts-button *ngIf="data.dialogType === DialogType.ManageLists && selectedList"
            cdkFocusInitial
            theme="secondary"
            [isDisabled]="!selectedFolder?.isMine && selectedFolder?.folderPermission !== FolderPermission.Public"
            (clicked)="deleteList()"
            data-cy="ds-manage-lists-delete-list-button"
            [title]="(!selectedFolder.isMine && selectedFolder?.folderPermission !== FolderPermission.Public
                ? 'shared.accountHub.deleteDisabled'
                : 'shared.accountHub.deletePopover') | translate">
            <i class="fas fa-trash"></i>
        </ts-button>
        <ts-button *ngIf="data.dialogType === DialogType.ManageLists && (!selectedFolder || !selectedList)"
            cdkFocusInitial
            theme="secondary"
            [isDisabled]="!selectedFolder?.isMine"
            (clicked)="editFolderProperties()"
            [title]="(!selectedFolder?.isMine
                ? 'shared.accountHub.editPropertiesDisabled'
                : 'shared.accountHub.editProperties') | translate">
            <i class="fas fa-pencil"></i>
        </ts-button>
        <ts-button *ngIf="data.dialogType === DialogType.ManageLists && selectedList"
            cdkFocusInitial
            data-cy="ds-manage-lists-edit-list-button"
            theme="secondary"
            [isDisabled]="!selectedFolder?.isMine && selectedFolder?.folderPermission !== FolderPermission.Public"
            (clicked)="editListProperties(folders)"
            [title]="(!selectedFolder.isMine && selectedFolder?.folderPermission !== FolderPermission.Public
                ? 'shared.accountHub.editPropertiesDisabled'
                : 'shared.accountHub.editProperties') | translate">
            <i class="fas fa-pencil"></i>
        </ts-button>
    </div>
    <div class="end">
      <ts-button
        cdkFocusInitial
        theme="secondary"
        data-cy="ds-list-create-folder-button"
        (clicked)="createFolder()">
        {{ 'shared.accountHub.createNewFolder' | translate }}
      </ts-button>
      <ts-button
        cdkFocusInitial
        theme="default"
        [isDisabled]="!selectedFolder?.isMine && selectedFolder?.folderPermission !== FolderPermission.Public"
        (clicked)="createList(folders)"
        data-cy="ds-list-create-list-button"
      >
        {{ 'shared.accountHub.createNewList' | translate }}
      </ts-button>
      <ts-button *ngIf="data.dialogType === DialogType.AddAccounts"
        theme="default"
        data-cy="ds-list-add-to-list-button"
        [isDisabled]="!selectedList ||
                    (!selectedFolder?.isMine && selectedFolder.folderPermission !== FolderPermission.Public)"
        (clicked)="addToList()">
        {{ 'shared.accountHub.addToList' | translate }}
      </ts-button>
      <ts-button  *ngIf="data.dialogType === DialogType.ManageLists"
        cdkFocusInitial
        theme="default"
        data-cy="ds-list-use-csv"
        (clicked)="useCsvData(folders)"
        [title]="'shared.accountHub.useCsvToUploadList' | translate">
        <i class="fas fa-cloud-upload"></i>
      </ts-button>
    </div>
  </div>
</div>
