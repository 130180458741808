import { FormGroup } from '@angular/forms';

import { IFactoryParams } from '../factory-params.interface';
import { ILabelValue, ILabelValueChecked } from '../label-value.interface';
import { ITileSettings } from './dashboard-tile.interface';
import { OptionsProvider } from '../options-provider.interface';

export enum TileSettingsControlTypes {
  Selector,
  Checkbox,
  Benchmark,
  SelectorWithOptionsProvider,
  InputNumber
}

export type TileSettingOptions = ILabelValue[]
  | ILabelValue<number>[]
  | ILabelValue<boolean>[]
  | ILabelValueChecked<number>[]
  | ILabelValueChecked[]
  | null;

export interface ITileSettingControl {
  key: string;
  label: string;
  // label for Checkbox selector to define the label if there are no selected items
  noneLabel?: string;
  type: TileSettingsControlTypes;
  options?: TileSettingOptions;
  // Show of hide current filter based on incoming settings
  show?: (settings: ITileSettings) => boolean;
  // Get label for filter based on incoming settings
  // if there is getCustomLabel then we use it instead of label property
  getCustomLabel?: (settings: ITileSettings) => string;
  // Get options for filter based on incoming settings
  // if there is getCustomOptions then we use them instead of options property
  getCustomOptions?: (settings: ITileSettings, settingsForm?: FormGroup) => TileSettingOptions;
  // Get options for selector (API calls)
  optionsProvider?: OptionsProvider<ILabelValue | ILabelValue<number>>;
}

export function tileSettingControlFactory({
   overrides = {},
}: IFactoryParams<ITileSettingControl> = {}): ITileSettingControl {
  return {
    key: 'field',
    label: 'label',
    type: TileSettingsControlTypes.Selector,
    options: [],
    ...overrides,
  };
}
