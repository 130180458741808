import { TsSortState } from '@terminus-lib/ui-sort';

import {
  ITrendingAllTotal,
  ITrendingCampaigns,
  ITrendingGroupDetailsAll,
  ITrendingGroupDetailsParams,
  ITrendingParams
} from '../interfaces';
import { RouteItemEnum } from '@shared/enums';
import { IDateCohort, ILabelValue, IReportColumn } from '@shared/interfaces';
import { TrendingDataPeriod } from '../enums';
import { CAMPAIGN_DETAILS_COLUMNS, GROUP_DETAILS_COLUMNS, TRENDING_RESPONSES_COLUMNS } from '../data/trending.data';
import { Action, createReducer, on } from '@ngrx/store';
import { trendingActions } from './trending.actions';


export const trendingFeatureKey = 'campaignOrWebTrending';

export interface ITrendingDetailsTable {
  columns: IReportColumn[];
  rowState: Record<TrendingDataPeriod, { [key: string]: boolean }>; // if table row open or close for each period
  sortState: Record<TrendingDataPeriod, TsSortState | null>; // sort state for each period
}

export interface ICampaignDetailsTable {
  columns: IReportColumn[];
  rowState: Record<TrendingDataPeriod, { [key: string]: boolean }>; // if table row open or close for each period for each group
  sortState: Record<TrendingDataPeriod, { [key: string]: TsSortState | null }>; // sort state for each period for each group
  pagination: Record<TrendingDataPeriod, { [key: string]: number }>; // page for each period for each group
}

export interface IResponsesTable {
  columns: IReportColumn[];
}

export interface TrendingState {
  selectedReport: RouteItemEnum | null; // it could be either Campaign Trend or Web Activity Trending
  isLoading: Record<TrendingDataPeriod, boolean>;
  isLoadingGroupDetails: Record<TrendingDataPeriod, boolean>;
  isLoadingCampaigns: Record<TrendingDataPeriod, boolean>;
  filters: ITrendingParams | null;
  total: ITrendingAllTotal;
  groupDetails: ITrendingGroupDetailsAll;
  groupDetailsParams: Record<TrendingDataPeriod, ITrendingGroupDetailsParams | null>;
  selectedGroupId: number | null;
  metricOptions: ILabelValue[];
  selectedDateCohort: IDateCohort | null;
  comparativeTablePagination: number;
  groupDetailsTable: ITrendingDetailsTable;
  campaigns: ITrendingCampaigns;
  campaignDetailsTable: ICampaignDetailsTable;
  responsesTable: IResponsesTable;
}

export const initialState: TrendingState = {
  selectedReport: null,
  isLoading: {
    [TrendingDataPeriod.Now]: false,
    [TrendingDataPeriod.Then]: false,
  },
  isLoadingGroupDetails: {
    [TrendingDataPeriod.Now]: false,
    [TrendingDataPeriod.Then]: false,
  },
  isLoadingCampaigns: {
    [TrendingDataPeriod.Now]: false,
    [TrendingDataPeriod.Then]: false,
  },
  filters: null,
  total: {
    [TrendingDataPeriod.Now]: null,
    [TrendingDataPeriod.Then]: null,
  },
  groupDetails: {
    [TrendingDataPeriod.Now]: null,
    [TrendingDataPeriod.Then]: null,
  },
  groupDetailsParams: {
    [TrendingDataPeriod.Now]: null,
    [TrendingDataPeriod.Then]: null,
  },
  campaigns: {
    [TrendingDataPeriod.Now]: {},
    [TrendingDataPeriod.Then]: {},
  },
  selectedGroupId: null,
  selectedDateCohort: null,
  metricOptions: [],
  comparativeTablePagination: 1,
  groupDetailsTable: {
    columns: GROUP_DETAILS_COLUMNS,
    sortState: {
      [TrendingDataPeriod.Now]: null,
      [TrendingDataPeriod.Then]: null,
    },
    rowState: {
      [TrendingDataPeriod.Now]: {},
      [TrendingDataPeriod.Then]: {},
    }
  },
  campaignDetailsTable: {
    columns: CAMPAIGN_DETAILS_COLUMNS,
    sortState: {
      [TrendingDataPeriod.Now]: {},
      [TrendingDataPeriod.Then]: {},
    },
    rowState: {
      [TrendingDataPeriod.Now]: {},
      [TrendingDataPeriod.Then]: {},
    },
    pagination: {
      [TrendingDataPeriod.Now]: {},
      [TrendingDataPeriod.Then]: {},
    }
  },
  responsesTable: {
    columns: TRENDING_RESPONSES_COLUMNS,
  }
};

export const trendingReducer = createReducer(
  initialState,
  on(
    trendingActions.changeCampaignDetailsRowState,
    (state, action) => {
      const newRowState = {
        ...state.campaignDetailsTable.rowState[action.rowEvent.period],
        [action.rowEvent.id]: !state.campaignDetailsTable.rowState[action.rowEvent.period][action.rowEvent.id]
      };
      return {
        ...state,
        campaignDetailsTable: {
          ...state.campaignDetailsTable,
          rowState: {
            ...state.campaignDetailsTable.rowState,
            [action.rowEvent.period]: newRowState
          }
        }
      };
    }
  ),
  on(trendingActions.changeCampaignDetailsPagination,
    (state, action) => {
      const newPagination = {
        ...state.campaignDetailsTable.pagination[action.pageEvent.period],
        [action.pageEvent.group]: action.pageEvent.page
      };
      return {
        ...state,
        campaignDetailsTable: {
          ...state.campaignDetailsTable,
          pagination: {
            ...state.campaignDetailsTable.pagination,
            [action.pageEvent.period]: newPagination
          }
        }
      };
    }
  ),
  on(trendingActions.changeCampaignDetailsSortState,
    (state, action) => {
      const newSortState = {
        ...state.campaignDetailsTable.sortState[action.sortEvent.period],
        [action.sortEvent.group]: action.sortEvent.state
      };
      return {
        ...state,
        campaignDetailsTable: {
          ...state.campaignDetailsTable,
          sortState: {
            ...state.campaignDetailsTable.sortState,
            [action.sortEvent.period]: newSortState
          }
        }
      };
    }
  ),
  on(trendingActions.changeGroupDetailsSortState,
    (state, action) => {
      return {
        ...state,
        groupDetailsTable: {
          ...state.groupDetailsTable,
          sortState: {
            ...state.groupDetailsTable.sortState,
            [action.period]: action.state
          }
        }
      };
    }
  ),
  on(trendingActions.setSelectedDateCohort,
    (state, action) => {
    // CHECK IT!!
      return {
        ...state,
        selectedDateCohort: action.dateCohort
      };
    }
  ),
  on(trendingActions.setSelectedReport,
    (state, action) => {
    //  CHECK IT
      return {
        ...state,
        selectedReport: action.routeItem
      };
    }
  ),
  on(trendingActions.setMetricOptions,
    (state, action) => {
      return {
        ...state,
        metricOptions: action.value
      };
    }
  ),
  on(trendingActions.loadTotal,
    (state, action) => {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.period]: true
        }
      };
    }
  ),
  on(trendingActions.loadGroupDetails,
    (state, action) => {
      return {
        ...state,
        isLoadingGroupDetails: {
          ...state.isLoadingGroupDetails,
          [action.period]: true
        }
      };
    }
  ),
  on(trendingActions.loadDetails,
    (state, action) => {
      return {
        ...state,
        isLoadingCampaigns: {
          ...state.isLoadingCampaigns,
          [action.period]: true // set loading for certain period
        }
      };
    }
  ),
  on(trendingActions.setFilters,
    (state, action) => {
      return {
        ...state,
        filters: action.filters,
        comparativeTablePagination: 1,
      };
    }
  ),
  on(trendingActions.loadTotalSuccess,
    (state, action) => {
      // NOTE: avoid using Object assign or spread operator here in case if the data has different structure
      // otherwise the new data will be inconsistent
      // NOTE: reset data for previous period if we update data for the current period
      const newTotal = action.period === TrendingDataPeriod.Now
        ? {[TrendingDataPeriod.Now]: action.data, [TrendingDataPeriod.Then]: state.total[TrendingDataPeriod.Then]}
        : {[TrendingDataPeriod.Then]: action.data, [TrendingDataPeriod.Now]: state.total[TrendingDataPeriod.Now]};
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.period]: false
        },
        total: newTotal
      };
    }
  ),
  on(trendingActions.loadGroupDetailsSuccess,
    (state, action) => {
      // NOTE: avoid using Object assign or spread operator here in case if the data has different structure
      // otherwise the new data will be inconsistent
      // NOTE: reset data for previous period if we update data for the current period
      const newGroupDetails = action.period === TrendingDataPeriod.Now
        ? {[TrendingDataPeriod.Now]: action.data, [TrendingDataPeriod.Then]: state.groupDetails[TrendingDataPeriod.Then]}
        : {[TrendingDataPeriod.Then]: action.data, [TrendingDataPeriod.Now]: state.groupDetails[TrendingDataPeriod.Now]};
      return {
        ...state,
        isLoadingGroupDetails: {
          ...state.isLoadingGroupDetails,
          [action.period]: false
        },
        selectedGroupId: action.id,
        groupDetails: newGroupDetails,
        campaigns: {
          [TrendingDataPeriod.Now]: {},   // reset campaigns info
          [TrendingDataPeriod.Then]: {},
        },
        groupDetailsTable: {
          ...state.groupDetailsTable,
          rowState: {
            [TrendingDataPeriod.Now]: {},   // reset row state for group details table
            [TrendingDataPeriod.Then]: {},
          }
        },
        campaignDetailsTable: {
          ...state.campaignDetailsTable,
          rowState: {
            [TrendingDataPeriod.Now]: {},   // reset row state for campaign details table
            [TrendingDataPeriod.Then]: {},
          }
        },
        groupDetailsParams: {
          ...state.groupDetailsParams,
          [action.period]: action.params // set params for current group
        }
      };
    }
  ),
  on(trendingActions.loadDetailsSuccess,
    (state, action) => {
      const newCampaigns = {
        ...state.campaigns[action.period],
        [action.group]: action.campaigns
      };
      return {
        ...state,
        isLoadingCampaigns: {
          ...state.isLoadingCampaigns,
          [action.period]: false
        },
        campaigns: {
          ...state.campaigns,
          [action.period]: newCampaigns
        }
      };
    }
  ),
  on(trendingActions.loadTotalFailure,
    (state, action) => {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.period]: false
        },
      };
    }
  ),
  on(trendingActions.loadGroupDetailsFailure,
    (state, action) => {
      return {
        ...state,
        isLoadingGroupDetails: {
          ...state.isLoadingGroupDetails,
          [action.period]: false
        },
      };
    }
  ),
  on(trendingActions.loadDetailsFailure,
    (state, action) => {
      return {
        ...state,
        isLoadingCampaigns: {
          ...state.isLoadingCampaigns,
          [action.period]: false
        },
      };
    }
  ),
  on(trendingActions.changeGroupDetailsRowState,
    (state, action) => {
      const newRowState = {
        ...state.groupDetailsTable.rowState[action.period],
        // change state to opposite (false => true/ true => false)
        [action.group]: !state.groupDetailsTable.rowState[action.period][action.group]
      };
      return {
        ...state,
        groupDetailsTable: {
          ...state.groupDetailsTable,
          rowState: {
            ...state.groupDetailsTable.rowState,
            [action.period]: newRowState
          }
        },
      };
    }
  ),
  on(trendingActions.setComparativeTablePage,
    (state, action) => {
      return {
        ...state,
        comparativeTablePagination: action.page
      };
    }
  ),
  on(trendingActions.clearGroupDetails,
    (state, action) => {
      const groupDetails = action.period === TrendingDataPeriod.Now
        ? {[TrendingDataPeriod.Now]: null, [TrendingDataPeriod.Then]: state.groupDetails[TrendingDataPeriod.Then]}
        : {[TrendingDataPeriod.Then]: null, [TrendingDataPeriod.Now]: state.groupDetails[TrendingDataPeriod.Now]};

      return {
        ...state,
        groupDetails,
      };
    }
  ),
  on(trendingActions.clearReportData,
    (state) => {
      return {
        ...state,
        total: {
          [TrendingDataPeriod.Now]: null,
          [TrendingDataPeriod.Then]: null,
        },
        campaigns: {
          [TrendingDataPeriod.Now]: {},   // reset campaigns info
          [TrendingDataPeriod.Then]: {},
        },
        groupDetailsTable: {
          ...state.groupDetailsTable,
          rowState: {
            [TrendingDataPeriod.Now]: {},   // reset row state for group details table
            [TrendingDataPeriod.Then]: {},
          }
        },
        campaignDetailsTable: {
          ...state.campaignDetailsTable,
          rowState: {
            [TrendingDataPeriod.Now]: {},   // reset row state for campaign details table
            [TrendingDataPeriod.Then]: {},
          }
        },
      };
    }
  )
);
export function reducer(state: TrendingState | undefined, action: Action): TrendingState {
  return trendingReducer(state, action);
}
