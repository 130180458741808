import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { hubTokenName } from '@shared/constants';

import * as interfaces from '../../interfaces';
import { EnvService } from '@shared/environment';
import { AdvertisingInsightsSource } from '../../sources/ad-insights/advertising-insights.source';
import { IAdvertisingInsightsService } from './advertising-insights-service.interface';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingInsightsService implements IAdvertisingInsightsService {
  constructor(
    private source: AdvertisingInsightsSource,
    private retry: RetryWithEscalation,
    private envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getAdsInfluencedTrends$(request: interfaces.IAdsInfluencedRequest): Observable<interfaces.IAdsInfluencedTrendsResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedTrends$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedTotals$<R extends interfaces.IAdsInfluencedRequest, T extends interfaces.IAdsInfluencedTotalsResponse>(
    request: R
  ): Observable<T> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedTotals$<R, T>(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedTotalInsights$(request: interfaces.IAdsInfluencedRequest): Observable<interfaces.IAdsInfluencedTotalInsightsResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedTotalInsights$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedAccountTable$(request: interfaces.IAdsInfluencedRequest): Observable<interfaces.IAdsInfluencedAccountTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedAccountTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedTacticTable$(request: interfaces.IAdsInfluencedRequest): Observable<interfaces.IAdsInfluencedTacticTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedTacticTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedReportTable$(request: interfaces.IAdsInfluencedRequest): Observable<interfaces.IAdsInfluencedReportTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedReportTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedAccountTable$(request: interfaces.IAdsInfluencedRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedAccountTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedTacticTable$(request: interfaces.IAdsInfluencedRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedTacticTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedReportTable$(request: interfaces.IAdsInfluencedRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedReportTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedAccountChildTable$(
    request: interfaces.IAdsInfluencedChildAccountTableRequest
  ): Observable<interfaces.IAdsInfluencedChildAccountTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedAccountChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedTacticChildTable$(
    request: interfaces.IAdsInfluencedChildTacticTableRequest
  ): Observable<interfaces.IAdsInfluencedChildTacticTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedTacticChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedReportChildTable$(
    request: interfaces.IAdsInfluencedChildReportTableRequest
  ): Observable<interfaces.IAdsInfluencedChildReportTableResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedReportChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedAccountChildTable$(request: interfaces.IAdsInfluencedChildAccountTableRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedAccountChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedTacticChildTable$(request: interfaces.IAdsInfluencedChildTacticTableRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedTacticChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  exportAdsInfluencedReportChildTable$(request: interfaces.IAdsInfluencedChildReportTableRequest): Observable<Blob> {
    return regenerateOnRetry(() => this.source.exportAdsInfluencedReportChildTable$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAdsInfluencedConfig$(): Observable<interfaces.IAdsInfluencedLeadConfigResponse> {
    return regenerateOnRetry(() => this.source.getAdsInfluencedConfig$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  setAdsInfluencedConfig$(data: interfaces.IAdsInfluencedLeadConfigResponse): Observable<string> {
    return regenerateOnRetry(() => this.source.setAdsInfluencedConfig$(data)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getAdsTactics$(): Observable<interfaces.IAdsTacticsResponse> {
    return regenerateOnRetry(() => this.source.getAdsTactics$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

}
