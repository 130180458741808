import { ILabelValue } from '@shared/interfaces';
import { OpportunityType } from './interfaces';

export enum ChartColors {
    DealValue = '#C4CBCF',
    DealMarketingValue = '#4F5A9F',
    OpptyValue = '#C4CBCF',
    OpptyMarketingValue = '#EB5488',
}

export const OPPTY_TYPE_OPTIONS: ILabelValue[] = [{
    label: 'feature.common.typeOptions.revenue',
    value: OpportunityType.DEAL,
}, {
    label: 'feature.common.typeOptions.pipeline',
    value: OpportunityType.OPPTY,
}];

export const VALID_OPPTY_TYPES = [
    OpportunityType.DEAL,
    OpportunityType.OPPTY,
];

export const PERIOD_FIELD = 'period';
export const OPPORT_TYPE_FIELD = 'opptyType';
export const PLOT_POINTS_FIELD = 'plotPoints';
export const INFLUENCE_TYPE_FIELD = 'influenceType';
