import { IFactoryParams } from '@shared/interfaces';
import { IMarketingInfluenceCount, marketingInfluenceCountFactory } from './marketing-influence-count.interface';

export interface IMarketingInfluenceOppty {
  month: string;
  oppty: IMarketingInfluenceCount;
  marketingOppty: IMarketingInfluenceCount;
}

export function marketingInfluenceOpptyFactory({
  n = 1,
  overrides = {},
}: IFactoryParams<IMarketingInfluenceOppty, number, boolean> = {}): IMarketingInfluenceOppty {
  return {
    month: `Q1 ${2000 + n}`,
    oppty: marketingInfluenceCountFactory(),
    marketingOppty: marketingInfluenceCountFactory({ n: 0.5 }),
    ...overrides
  };
}
