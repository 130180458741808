/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  of,
} from 'rxjs';
import { catchError, filter, map, mergeMap, pluck, switchMap, tap } from 'rxjs/operators';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import * as DateCohortsStore from '@date-cohorts';
import * as OrgConfigStore from '@org-config';
import * as interfaces from '../../interfaces';
import { generalActions } from './general.actions';
import * as selectors from './general.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import { CampaignAnalyticsService } from '../../services/campaign-analytics.service';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { IAnalyticsGlobalFilters, IDateCohort, ILabelValue, userMessageFactory } from '@shared/interfaces';
import { DateCohortsGroups } from '@shared/enums';
import { AnalyticsColumnName as Column, AnalyticTypes, GroupingType } from '../../enums';
import { getParentTitle } from '../selectors';
import { defaultPager, defaultSorter } from '../../data';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { ColumnsSaverService, LocalStorageColumnsKey } from '@shared/data-access/columns-saver';
import * as generalSelectors from './general.selectors';
import { TotalsLoadActions } from '../totals/totals.actions';

@Injectable()
export class GeneralEffects {
  constructor(
    public campaignAnalyticsService: CampaignAnalyticsService,
    private downloadCsvService: DownloadCsvService,
    private columnsSaverService: ColumnsSaverService,
    private store: Store<unknown>,
    private actions$: Actions
  ) {
  }

  public loadTabTotals$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.loadTabTotals),
    mergeMap((action) => {
      return this.campaignAnalyticsService.getCampaignAnalyticsTabTotals$(action.request).pipe(
        map((data: interfaces.ITabTotals) => generalActions.loadTabTotalsSuccess({data: data.tabTotals[0]})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadTabTotals';
          return of(generalActions.loadTabTotalsFailure({message}));
        }),
      );
    })
  ));

  public loadWebActivitiesTabTotals$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.loadWebActivitiesTabTotals),
    mergeMap((action) => {
      return this.campaignAnalyticsService.getWebActivitiesTabTotals$(action.request).pipe(
        map((data: interfaces.ITabTotals<interfaces.IWebActivitiesTabTotalsResponse>) =>
          generalActions.loadWebActivitiesTabTotalsSuccess({data: data.tabTotals[0]})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadTabTotals';
          return of(generalActions.loadWebActivitiesTabTotalsFailure({message}));
        }),
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.loadWebActivitiesTabTotalsFailure,
      generalActions.downloadAnalyticsCSVFailure,
      generalActions.loadTabTotalsFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({message: userMessageFactory({n: action.message})})
    )
  ));

  public getFilters$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.getFilters),
    pluck('params'),
    concatLatestFrom(() => this.store.pipe(select(OrgConfigStore.getUserModels))),
    mergeMap(([queryParams, userModels]: [Params, ILabelValue[]]) => {
      const request = this.campaignAnalyticsService.getFiltersFromParams(queryParams, userModels);
      return [
        generalActions.getSelectedDateCohort({request}),
        generalActions.setFilters({request})
      ];
    }),
  ));

  public onSetFilters$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.setFilters),
    pluck('request'),
    concatLatestFrom(() => this.store.pipe(select(selectors.getReportType))),
    map(([filters, type]: [IAnalyticsRequest, AnalyticTypes]) => {
      const request: IAnalyticsRequest = {...filters};
      request.pager = defaultPager;
      request.sorter = defaultSorter;
      delete request.typeFilter;
      return type === AnalyticTypes.CampaignAnalytics
        ? generalActions.loadTabTotals({request})
        : generalActions.loadWebActivitiesTabTotals({request});
    }),
  ));

  public getSelectedDateCohort$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.getSelectedDateCohort),
    pluck('request'),
    switchMap((filters: IAnalyticsRequest) => {
      // wait unit all date cohorts will be in the store and set date cohort
      return this.store.pipe(select(DateCohortsStore.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => {
          if (filters.cohort.cohort === DateCohortsGroups.Custom) {
            const customCohort = {
              name: 'Custom Range',
              cohort: filters.cohort.cohort,
              endDate: filters.cohort.endDate,
              startDate: filters.cohort.startDate,
            };
            return of(customCohort);
          }

          return this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(filters.cohort.cohort)));
        })
      );
    }),
    map((dateCohort: IDateCohort) => generalActions.setSelectedDateCohort({cohort: dateCohort}))
  ));

  private getSearchState(action: ReturnType<typeof generalActions.downloadAnalyticsCSV>): Observable<string> {
    switch (action.request.type) {
      case GroupingType.WebSources:
        return this.store.pipe(select(generalSelectors.getSourceSearch));
      case GroupingType.Campaigns:
        return this.store.pipe(select(generalSelectors.getCampaignsSearch));
      case GroupingType.CampaignTypes:
        return this.store.pipe(select(generalSelectors.getCampaignGroupsSearch));
      case GroupingType.WebCampaigns:
        return this.store.pipe(select(generalSelectors.getCampaignSearch));
      case GroupingType.WebMediums:
        return this.store.pipe(select(generalSelectors.getMediumsSearch));
      default:
        return of('');
    }
  }

  public downloadAnalyticsCSV$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.downloadAnalyticsCSV),
    concatLatestFrom((action) => [
      this.store.pipe(select(selectors.getFilters)),
      this.store.pipe(
        // get columns for the current tab
        select(this.getVisilbleColumnsSelectorByType(action.request)),
        // remove HasWebActivity column, which indicates if we have icon or not
        map(columns => columns.find(column => column === Column.HasNestedTable) ? columns.slice(1) : columns)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
      this.getSearchState(action),
      this.store.pipe(select(generalSelectors.getCampaignsSearchByTags)),
    ]),
    mergeMap(([action, filters, visibleColumns, globalFilters, search, searchByTags]) => {
      const csvFilters = this.getCSVFilters(filters, globalFilters, visibleColumns, action.request, search, searchByTags);
      return this.campaignAnalyticsService.getCampaignAnalyticsCSV$(csvFilters)
        .pipe(
          map((data: string) => generalActions.downloadAnalyticsCSVSuccess({data: {...action.request, data}})),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.downloadCsv';
            return of(generalActions.downloadAnalyticsCSVFailure({message}));
          })
        );
    }),
  ));

  public onDownloadAnalyticsCSVSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.downloadAnalyticsCSVSuccess),
    concatLatestFrom((action) => [
      this.store.pipe(select(selectors.getFilters)),
      action.data.parentId ? this.store.pipe(select(getParentTitle(action.data.type, action.data.parentId))) : of(null),
    ]),
    tap(([action, filters, parentName]) => {
      const fileName = this.getCsvFileName(action.data, filters, parentName);
      this.downloadCsvService.createBlobAndDownload(fileName, action.data.data);
    }),
  ), {dispatch: false});

  public onToggleCampaignGroupsColumns$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignGroupsColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleCampaignGroupsColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(LocalStorageColumnsKey.CampaignGroups, visibleColumns, [Column.HasNestedTable]);
    })
  ), {dispatch: false});

  public onToggleCampaignsColumns$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignsColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleCampaignsColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(LocalStorageColumnsKey.Campaigns, visibleColumns, [Column.HasNestedTable]);
    })
  ), {dispatch: false});

  public onToggleCampaignColumns$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleCampaignColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(LocalStorageColumnsKey.Campaign, visibleColumns, [Column.HasNestedTable]);
    })
  ), {dispatch: false});

  public onToggleSourceColumns$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleSourceColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleSourceColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(LocalStorageColumnsKey.Source, visibleColumns, [Column.HasNestedTable]);
    })
  ), {dispatch: false});

  public onToggleMediumsColumns$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleMediumsColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(selectors.getVisibleMediumsColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(LocalStorageColumnsKey.Mediums, visibleColumns, [Column.HasNestedTable]);
    })
  ), {dispatch: false});

  private getCSVFilters(filters: IAnalyticsRequest,
                        globalFilters: IAnalyticsGlobalFilters,
                        visibleColumns: string[],
                        payload: interfaces.ILoadCSVPayload,
                        search,
                        searchByTags,
  ): interfaces.IAnalyticsCSVRequest {
    const {cohort, dataSet, influenceType, model, typeFilter} = filters;
    const searchParam = searchByTags && search ? {
      tagNames: [search],
      searchByTags: true,
    }: {
      searchParam: search
    }
    return {
      cohort,
      dataSet,
      influenceType,
      model,
      globalFilters,
      visibleColumns,
      groupingType: payload.type,
      ...typeFilter ? {typeFilter} : null,
      ...payload.parentId ? {crossTypeFilter: payload.parentId.toString()} : null,
      ...searchParam,
    };
  }

  private getVisilbleColumnsSelectorByType(payload: interfaces.ILoadCSVPayload): MemoizedSelector<unknown, string[]> {
    switch (payload.type) {
      case GroupingType.Campaigns:
        return selectors.getVisibleCampaignsColumnNames;
      case GroupingType.CampaignTypes:
        return selectors.getVisibleCampaignGroupsColumnNames;
      case GroupingType.WebSources:
        return selectors.getVisibleSourceColumnNames;
      case GroupingType.WebCampaigns:
        return selectors.getVisibleCampaignColumnNames;
      case GroupingType.WebMediums:
        return selectors.getVisibleMediumsColumnNames;
    }
  }

  private getCsvFileName(payload: interfaces.ILoadCSVPayload, filters: IAnalyticsRequest, parentName: string | null): string {
    switch (payload.type) {
      case GroupingType.Campaigns:
        return `${parentName || 'campaign-analytics'}-campaigns`;
      case GroupingType.CampaignTypes:
        return `${parentName || 'campaign-analytics'}-campaign-groups`;
      case GroupingType.WebSources:
        return `${parentName || 'UTM Source'}-${filters.cohort.cohort}-${filters.model}`;
      case GroupingType.WebCampaigns:
        return `${parentName || 'UTM Campaign'}-${filters.cohort.cohort}-${filters.model}`;
      case GroupingType.WebMediums:
        return `${parentName || 'UTM Medium'}-${filters.cohort.cohort}-${filters.model}`;
    }
  }
}
