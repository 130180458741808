/* istanbul ignore file */
import { Injectable } from '@angular/core';

import type { ColumnSeries, LineSeries, XYChart, XYSeries } from '@amcharts/amcharts4/charts';
import type { Color } from '@amcharts/amcharts4/core';

import { IChartConfig, IDashboardTile, ITileDataItem } from '@shared/interfaces';
import { TileTypes, VisualizationTypes } from '@shared/enums';
import { TILE_DATA_FIELD_NAME, TILE_DATA_FIELD_PARETO } from '@shared/constants';
import { ChartConfigClass } from '../chart-config.class';
import { MAX_LEGEND_LENGTH, MAX_TOOLTIP_LENGTH } from '../../tile.constant';

@Injectable()
export class XyChartConfigService implements ChartConfigClass {

  getConfig(
    tile: IDashboardTile,
    data: ITileDataItem[],
  ): IChartConfig {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const id = tile.id;
    const visualization = tile.settings.visualization;
    const isLine = visualization === VisualizationTypes.Line || visualization === VisualizationTypes.StackedArea;
    const isStacked = visualization === VisualizationTypes.StackedArea || visualization === VisualizationTypes.StackedColumn;
    const isPareto = visualization === VisualizationTypes.Pareto;
    const series = isLine ? this.getDefaultLineSeries(isStacked) : this.getDefaultColumnSeries(isStacked);
    // get properties' keys to create necessary number of series
    // exclude category and pareto keys
    const keys = data && data[0]
      ? Object.keys(data[0]).filter(key => key !== TILE_DATA_FIELD_NAME && key !== TILE_DATA_FIELD_PARETO)
      : [];
    const allSeries = keys.length ? keys.map((key: string) => {
      return {
        ...series,
        name: key,
        dataFields: {
          ...series.dataFields,
          valueY: key
        }
      };
    }) : [series];
    // add pareto series if chart type is Pareto
    const paretoSeries = isPareto ? [this.getDefaultParetoSeries()] : [];
    const cursor = this.getDefaultCursorSeries();
    // group columns if there is ClusteredColumn
    const cellLocation = visualization === VisualizationTypes.ClusteredColumn ? {
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    } : {};
    // set special colors for Pareto chart
    const colors = isPareto
      ? {
        colors: {
          list: ['#C4CBCF', '#4F5A9F']
        }
      }
      : {};
    return {
      ...cursor,
      ...colors,
      type: 'XYChart',
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: {
          category: TILE_DATA_FIELD_NAME
        },
        renderer: {
          ...cellLocation,
          grid: {
            disabled: true
          },
          minGridDistance: 200, // to show first and last label
        }
      }],
      // TODO: set this block dynamically by passing data
      yAxes: [{
        type: 'ValueAxis',
        min: 0,
        title: {
          text: '',
          fill: '#637178'
        },
        numberFormatter: {
          numberFormat: '#a' // 4M, 1K
        }
      }],
      series: [...allSeries, ...paretoSeries],
      events: {
        ready(event: { type: string, target: XYChart & { customLegend: unknown } }) {
          that.createCustomLegend(event.target, id);
          // don't create custom tooltips for stage snapshot tile
          // because we do this in feature service
          if (tile.type !== TileTypes.StagesSnapshot) {
            that.createCustomTooltip(event.target);
          }
        }
      },
      data: data || [] // get from BE
    };
  }

  private getDefaultCursorSeries(): IChartConfig {
    return {
      cursor: {
        lineY: {
          disabled: true
        },
        lineX: {
          disabled: true
        },
        maxTooltipDistance: -1
      }
    };
  }

  private getDefaultLineSeries(isStacked: boolean): Partial<LineSeries> & { type: string } {
    return {
      type: 'LineSeries',
      dataFields: {
        valueY: 'value', // set dynamically, depend on number of items
        categoryX: TILE_DATA_FIELD_NAME
      },
      name: '',
      fillOpacity: isStacked ? 1 : 0,
      stacked: isStacked
    };
  }

  private getDefaultColumnSeries(isStacked: boolean): Partial<ColumnSeries> & { type: string } {
    return {
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'value', // set dynamically, depend on number of items
        categoryX: TILE_DATA_FIELD_NAME
      },
      name: '', // set dynamically, depend on number of items
      fillOpacity: 1,
      stacked: isStacked
    };
  }

  private getDefaultParetoSeries(): IChartConfig {
    return {
      type: 'LineSeries',
      dataFields: {
        valueY: TILE_DATA_FIELD_PARETO,
        categoryX: TILE_DATA_FIELD_NAME
      },
      strokeWidth: 3,
      name: TILE_DATA_FIELD_PARETO,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createCustomLegend(target: XYChart & { customLegend: any }, tileId: string): void {
    target.customLegend = document.getElementById(`legend-${tileId}`);
    const isLongLegend = target.series.length > MAX_LEGEND_LENGTH;
    const itemsForLegend = isLongLegend
      ? target.series.values.slice(0, MAX_LEGEND_LENGTH)
      : target.series.values;
    itemsForLegend.forEach((row: XYSeries) => {
      const color = (row.fill as Color).hex;
      target.customLegend.innerHTML += `
                    <span class="custom-legend-item text-capitalize" title="${row.name || 'n/a'}">
                        <span class="custom-legend-marker" style="background: ${color}"></span>
                        ${row.name || 'n/a'}
                    </span>
                `;
    });

    if (isLongLegend) {
      target.customLegend.innerHTML +=
        `<span class="custom-legend-item">+${target.series.length - MAX_LEGEND_LENGTH} others</span>`;
    }
  }

  private createCustomTooltip(target: XYChart): void {
    let tooltipHTML = ``;
    const seriesWithTooltip = target.series.getIndex(0); // only first series
    const isLong = target.series.length > MAX_TOOLTIP_LENGTH;
    const longTooltip = isLong
      ? `<span class="subtitle">+ ${target.series.length - MAX_TOOLTIP_LENGTH} others</span>`
      : '';
    const itemsForTooltip = isLong
      ? target.series.values.slice(0, MAX_TOOLTIP_LENGTH)
      : target.series.values;
    itemsForTooltip.forEach((row: XYSeries) => {
      const color = (row.fill as Color).hex;
      tooltipHTML += `<span class="subtitle text-capitalize">${row.name}</span></br>
                            <div class="section">
                                <div class="color" style="background-color: ${color};"></div>
                                {${row.name}}
                            </div>`;
    });
    seriesWithTooltip.tooltipHTML = `<div class="amcharts-tooltip amcharts-tooltip-tile">
                                            <span class="title">{categoryX}</span>
                                            <hr class="report-divider">
                                            ${tooltipHTML}
                                            ${longTooltip}
                                        </div>`;
  }
}
