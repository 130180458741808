import { createFeatureSelector, createSelector } from '@ngrx/store';
import { aggregateListsFeatureKey, AggregateListsState } from './aggregate-lists.reducer';
import { AggregateList } from '@shared/interfaces';
import { ILabelValue } from '@shared/interfaces';

export const selectAggregateListsState = createFeatureSelector<AggregateListsState>(aggregateListsFeatureKey);
export const selectAggregateSegmentsState = createFeatureSelector<AggregateListsState>(aggregateListsFeatureKey);

export const getAggregateLists = createSelector(selectAggregateListsState,
  (state: AggregateListsState) => state.lists
);

export const getAggregateSegments = createSelector(selectAggregateListsState,
  (state: AggregateListsState) =>  state.segments
);

export const getAggregateListsAsOptions = createSelector(getAggregateLists,
  (lists: AggregateList[]): ILabelValue<number>[] => lists?.map(list => {
    return {
      label: list.aggregateName,
      value: list.aggregateId
    }
  }) || []
);

export const getSegmentsAsOptions = createSelector(getAggregateSegments,
  (segments: AggregateList[]): ILabelValue<number>[] => segments?.map(segment => {
    return {
      label: segment.aggregateName,
      value: segment.aggregateId,
    }
  }) || []
);
