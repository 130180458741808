import { Inject, Injectable } from '@angular/core';

import { AdminAccessTypes, RouteItemEnum, RouteTypeEnum, UserCrmType } from '@shared/enums';
import { IReportsAccess, IRouteItem, IUserCrm } from '@shared/interfaces';
import { APP_ROUTES } from '@app-routes';
import { SETTINGS_ROUTE_ITEMS } from './settings-route-items.token';

@Injectable({
  providedIn: 'root'
})
export class SharedSettingsService {
  constructor(
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[],
    @Inject(SETTINGS_ROUTE_ITEMS) private settingsRoutes: RouteItemEnum[],
  ) {
  }

  getSettingsMenu(permissions: AdminAccessTypes[], reportAccess: IReportsAccess,
                  userCrm?: IUserCrm): IRouteItem[] {
    // get setting route items (only internal)
    const routeItems = this.applicationRoutes.filter(route =>
      this.settingsRoutes.includes(route.routeId) &&
      reportAccess[route.routeId] &&
      route.routeType === RouteTypeEnum.InternalLink
    );

    return routeItems.filter(item => {
      // check permission level
      const accessByPermission = item.permissionLevel
        ? permissions.some(type => item.permissionLevel.includes(type))
        : true;

      // add route item only if we have all access
      if (userCrm) {
        const accessByCrm = item.routeId === RouteItemEnum.HubspotConfigure
          ? userCrm.type === UserCrmType.Hubspot && userCrm.isAutomatedIngest
          : item.routeId === RouteItemEnum.SalesforceConfigure && userCrm.type === UserCrmType.Hubspot
            ? false
            : true;

        return accessByPermission && accessByCrm;
      }

      return accessByPermission;
    }).map(item => {
      return {
        ...item,
        // update route label to display in menu because on global level these routes
        // have label = Settings
        routeLabel: this.getSettingsRouteLabel(item.routeId)
      };
    });
  }

  private getSettingsRouteLabel(routeId: RouteItemEnum): string {
    const settingsRouteLabelMap = {
      [RouteItemEnum.AccountEngagement]: 'shared.navMenus.settings.accountEngagement',
      [RouteItemEnum.CampaignScoring]: 'shared.navMenus.settings.campaignScoring',
      [RouteItemEnum.HubspotConfigure]: 'shared.navMenus.settings.hubspot',
      [RouteItemEnum.Permissions]: 'shared.navMenus.settings.permissions',
      [RouteItemEnum.DataHygiene]: 'shared.navMenus.settings.dataHygiene',
      [RouteItemEnum.PagesAndFeatures]: 'shared.navMenus.settings.pagesAndFeatures',
      [RouteItemEnum.Stages]: 'shared.navMenus.settings.stages',
      [RouteItemEnum.DigitalAttribution]: 'shared.navMenus.settings.webTracking',
    }
    return settingsRouteLabelMap[routeId] ? settingsRouteLabelMap[routeId] : 'Unknown link';
  }
}
