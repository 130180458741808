import { isValidTypeValueGenerator } from '@util/helpers';
import {
  VALID_GRAPH_SERIES_TYPES,
  VALID_KPI_FIRMOGRAPHICS,
  VALID_KPI_GROUPS,
  VALID_KPI_INTERVALS,
  VALID_KPI_PROGRESSIONS,
  VALID_KPI_TRENDS,
  VALID_KPI_TYPES
} from '../scorecard.constant';
import { KpiFirmographic, KpiGroup, KpiInterval, KpiProgression, KpiTrend, KpiType, SeriesTypes } from '../enums/scorecard.enum';

export const isValidKpiGroup = isValidTypeValueGenerator<KpiGroup>(VALID_KPI_GROUPS);
export const isValidKpiInterval = isValidTypeValueGenerator<KpiInterval>(VALID_KPI_INTERVALS);
export const isValidKpiProgression = isValidTypeValueGenerator<KpiProgression>(VALID_KPI_PROGRESSIONS);
export const isValidKpiTrend = isValidTypeValueGenerator<KpiTrend>(VALID_KPI_TRENDS);
export const isValidKpiType = isValidTypeValueGenerator<KpiType>(VALID_KPI_TYPES);
export const isValidKpiFirmographic = isValidTypeValueGenerator<KpiFirmographic>(VALID_KPI_FIRMOGRAPHICS);
export const isValidGraphSeriesType = isValidTypeValueGenerator<SeriesTypes>(VALID_GRAPH_SERIES_TYPES);
