/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { ClaimMap as BaseClaimMap, claimsFor } from '@terminus-lib/fe-jwt';

import { ClaimMap, HubTokenNameType } from '../claim-map';

export interface IRequiredClaims<TC extends Record<string, any>> {
  key: Extract<keyof TC, string>;
  validateFn?(claimVal: TC[Extract<keyof TC, string>]): boolean;
}

export interface ITokenAndRequiredClaims<T extends keyof CM, CM = BaseClaimMap> {
  token: T;
  requiredClaims: IRequiredClaims<CM[T]>[];
}

export type TerminusHubTokenAndRequiredClaims = ITokenAndRequiredClaims<HubTokenNameType, ClaimMap>;
export type TokensAndRequiredClaims = TerminusHubTokenAndRequiredClaims;

export interface ITokenClaimGuardRouteData<TRQ> {
  tokenClaimsGuard?: {
    requiredTokenClaims: TRQ[];
  };
}

export const allClaimsAreValid = <
  TC extends ITokenAndRequiredClaims<string, any>,
>(resp: [TC, any][]) => resp.map(
    ([tokenAndRequiredClaims, decodedClaims]: [TC, Record<string, any>]) => {
      if (!decodedClaims) {
        return false;
      }
      return (tokenAndRequiredClaims.requiredClaims as []).every(
        // tslint:disable-next-line no-any
        (requiredClaims: IRequiredClaims<TC>) => {
          if (!requiredClaims.validateFn) {
            return !!decodedClaims[requiredClaims.key];
          }
          return requiredClaims.validateFn(decodedClaims[requiredClaims.key]);
        }
      );
    }
  ).every((allValid: boolean) => allValid);

// tslint:disable-next-line no-any
export const mapTokenClaims = <
  CM extends Record<string, any>,
  TRQ extends ITokenAndRequiredClaims<any, any>
>(store: Store<ClaimMap>) => (
    tokenClaims: TRQ
  ): Observable<[TokensAndRequiredClaims, string | undefined]> => of(tokenClaims).pipe(
    withLatestFrom(
      store.pipe(
        select(claimsFor<CM, Extract<keyof CM, string>>(
          tokenClaims.token as Extract<keyof CM, string>
        ))
      )
    )
  );

export const redirectIfInvalid = <TRQ>(
  routeData: ITokenClaimGuardRouteData<TRQ>,
  routerState: RouterStateSnapshot,
  router: Router
) => (allValid: boolean) => {
    if (!allValid && routeData.tokenClaimsGuard) {
      // navigate home
      router.navigate(['/']);
    }
  };

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line no-unsafe-any
export class TokenClaimsGuard<CM extends Record<string, any>> implements CanActivate {

  constructor(
    public store: Store<ClaimMap>,
    private router: Router
  ) {}

  public canActivate(
    route: { data: ITokenClaimGuardRouteData<ITokenAndRequiredClaims<keyof CM, CM>> },
    routerState: RouterStateSnapshot
  ): Observable<boolean> {
    if (!route.data.tokenClaimsGuard) {
      return of(true);
    }

    const tokensAndRequiredClaims$ = route.data.tokenClaimsGuard.requiredTokenClaims.map(
      mapTokenClaims<CM, ITokenAndRequiredClaims<keyof CM, CM>>(this.store)
    );

    return forkJoin(...tokensAndRequiredClaims$).pipe(
      filter((res: [ITokenAndRequiredClaims<any, any>, any][]) => !!res),
      map(allClaimsAreValid),
      tap(redirectIfInvalid(route.data, routerState, this.router)),
    );
  }
}
