import { Params } from '@angular/router';
import { ILabelValue } from '@shared/interfaces';
import { ICampaignSpecificFilters } from '@measurement-studio/interfaces';
import { DataSetOptions } from '@measurement-studio/classes/*';
import { InfluenceType, ModelType, ToDate } from '@shared/enums';
import { DataSetType } from '@measurement-studio/enums';
import { VALID_INFLUENCE_TYPES } from '@measurement-studio/constants';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';

export const getCampaignSpecificFiltersFromParams = (params: Params, models: ILabelValue[]): ICampaignSpecificFilters => {
  // get query params from url or use default params
  const dataSets = new DataSetOptions().getCampaignDataOptions();
  const defaultQueryParams: ICampaignSpecificFilters = {
    modelType: ModelType.Even,
    dataSet: DataSetType.MembershipActivity,
    campaignId: '',
    startDate: null,
    endDate: null,
    influenceType: InfluenceType.ANY,
    cohort: ToDate.QUARTER_TO_DATE
  };
  const modelType = params.modelType && models.map(item => item.value).includes(params.modelType)
    ? params.modelType
    : defaultQueryParams.modelType;
  const dataSet = params.dataSet && dataSets.map(item => item.value).includes(params.dataSet)
    ? params.dataSet
    : defaultQueryParams.dataSet;
  const campaignId = params.campaignId // for campaign specific
    ? params.campaignId // for web activities specific
    : params.channelId ? params.channelId : defaultQueryParams.campaignId;
  const influenceType = params.influenceType && VALID_INFLUENCE_TYPES.includes(params.influenceType)
    ? params.influenceType
    : defaultQueryParams.influenceType;
  const filters = {
    modelType,
    dataSet,
    campaignId,
    influenceType,
    cohort: replaceLegacyCohort(params.cohort) || defaultQueryParams.cohort,
    endDate: params.endDate,
    startDate: params.startDate,
  };

  return {
    ...filters,
    ...getStartAndEndDateFromParams(filters.cohort, filters.startDate, filters.endDate)
  };
};
