import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { InsightsMetricItemModule } from '../metric-item/insights-metric-item.component';
import { IInsightsMetricBarItem } from '../../interfaces';

@Component({
  selector: 'ms-metric-bar',
  templateUrl: './insights-metric-bar.component.html',
  styleUrls: ['./insights-metric-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsMetricBarComponent {
  // Placeholder data until we're populating with real data
  @Input() public currencySymbol = 'USD';
  @Input() public items: IInsightsMetricBarItem<string>[] = [];
}

@NgModule({
  declarations: [
    InsightsMetricBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InsightsMetricItemModule
  ],
  exports: [
    InsightsMetricBarComponent
  ]
})
export class InsightsMetricBarModule {
}
