<div class="header-container" [ngClass]="{'new-layout': isNewLayout}">
  <div class="header-top">
        <span class="sub-title">
            {{ applicationTitle | translate }} /
            <ng-container *ngIf="selectedNavItem?.label === reportLabel">
                {{ reportLabel | translate }} /
            </ng-container>
            <ng-container *ngIf="parent">
                <!-- solution for internal routing until we stop support hub-ajs-->
               <ng-container *ngIf="parent.link; else navigateViaAction">
                    <a [routerLink]="parent.link"
                       [queryParams]="parent?.queryParams"
                       [queryParamsHandling]="parent?.queryParamsHandling"
                       data-cy="app-header-parent-link">
                        {{ parent.title | translate }}
                    </a>
               </ng-container>
                <ng-template #navigateViaAction>
                    <a data-cy="app-header-parent-link"
                      (click)="emitNavigate({routeId: parent.routeId})">{{ parent.title | translate }}</a>
                </ng-template>
                <span> /</span>
            </ng-container>

            <ng-container *ngIf="specificLink">
                <a (click)="emitNavigate(specificLink.link)"
                  data-cy="app-header-specific-link"> {{ specificLink.title | translate }}</a>
                <span> /</span>
                <span *ngIf="specificLink.additionalText"
                      [innerHTML]="specificLink.additionalText | translate"></span>
            </ng-container>
        </span>
    <ts-tooltip
      *ngIf="lastUpdate"
      [tooltipValue]="lastUpdate | typedData : dayAtTimeType"
      tooltipPosition="above"
      [hasUnderline]="false">
      <span
        class="sub-title">{{ 'appHeader.updated' | translate: { date: (lastUpdate | tsTimeAgo : currentDate) } }}</span>
    </ts-tooltip>
  </div>
  <div class="header-bottom">
    <div class="header-bottom-titles">
      <img alt="" src="{{ imageStorage + selectedRoute.routeLogo }}" class="logo" *ngIf="selectedRoute?.routeLogo">
      <h2 data-cy="header-report-title" class="report-title" *ngIf="selectedRoute">{{ selectedRoute.routeLabel | translate }}</h2>
      <ng-container [tshAddTarget]="portalTargets.HeaderSubTitle"></ng-container>
    </div>
    <div class="header-bottom-actions">
      <ng-container [tshAddTarget]="portalTargets.HeaderButtons"></ng-container>
    </div>
  </div>
</div>
