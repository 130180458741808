import { mediumsActions } from './mediums.actions';
import * as interfaces from '../../interfaces';
import { assembleAnalyticsData, updateNestedTableVisibility } from '../../helpers/store.helper';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const mediumsFeatureKey = 'mediums';

export interface IMediumsState extends interfaces.IAnalyticsIsLoading {
  totalResults: number;
  data: Partial<interfaces.IMediumsTableItem>[];
  nestedTableState: interfaces.INestedTableState;
  usedParamsInDriver: IAnalyticsRequest | null;
}

export const initialState: IMediumsState = {
  totalResults: 0,
  data: [],
  hasWebActivityIsLoading: false,
  isDriverLoading: false,
  campaignIsLoading: false,
  responseIsLoading: false,
  attributedIsLoading: false,
  influenceIsLoading: false,
  returnsIsLoading: false,
  costIsLoading: false,
  nestedTableState: {
    visible: {},
  },
  usedParamsInDriver: null,
};

const mediumsReducer = createReducer(
  initialState,
  on(mediumsActions.setUsedParams, (state, action) => ({
    ...state,
    usedParamsInDriver: action.request
  })),
  on(mediumsActions.loadMetaDataSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.campaign),
    campaignIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadResponsesSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.response),
    responseIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadAttributionSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.attributed),
    attributedIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadInfluenceSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.influenced),
    influenceIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadReturnsSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.returns),
    returnsIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadCostsSuccess, (state, action) => ({
    ...state,
    ...assembleAnalyticsData(state.data, action, action.data.costs),
    costIsLoading: false,
    isDriverLoading: false,
  })),
  on(mediumsActions.loadMetaData, (state, action) => ({
    ...state,
    campaignIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadResponses, (state, action) => ({
    ...state,
    responseIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadAttribution, (state, action) => ({
    ...state,
    attributedIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadInfluence, (state, action) => ({
    ...state,
    influenceIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadCosts, (state, action) => ({
    ...state,
    costIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadReturns, (state, action) => ({
    ...state,
    returnsIsLoading: true,
    isDriverLoading: action.isDriver,
  })),
  on(mediumsActions.loadMetaDataFailure, (state) => ({
    ...state, campaignIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.loadResponsesFailure, (state) => ({
    ...state, responseIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.loadAttributionFailure, (state) => ({
    ...state, attributedIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.loadInfluenceFailure, (state) => ({
    ...state, influenceIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.loadCostsFailure, (state) => ({
    ...state, costIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.loadReturnsFailure, (state) => ({
    ...state, returnsIsLoading: false, isDriverLoading: false
  })),
  on(mediumsActions.toggleTableRow, (state, action) => ({
    ...state,
    nestedTableState: updateNestedTableVisibility(state.nestedTableState, action.id),
  })),
  on(mediumsActions.closeNestedTables, (state) => ({
    ...state,
    nestedTableState: {visible: {}}
  })),
);

export function reducer(state, action: Action): IMediumsState {
  return mediumsReducer(state, action);
}
