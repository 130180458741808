import { FormGroup } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

import { debounceTime, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { FormState, OutputFormState } from '../store-form-in-state.interfaces';

export class FormDataFromComponent<C> {
  constructor(
    private fg: FormGroup,
    private cd: ChangeDetectorRef
  ) {}

  createInputFromStoreHandler(newData: FormState<C>) {
    if (newData) {
      this.fg.patchValue(newData.values);
      this.cd.markForCheck();
    }
  }

  createFormGroupChangeEmitter() {
    return combineLatest([this.fg.valueChanges, this.fg.statusChanges]).pipe(
      debounceTime(10),
      map(([values, statusChange]): OutputFormState<C> => ({
        isValid: typeof statusChange === 'string' && statusChange === 'VALID' || statusChange === 'DISABLED',
        values
      }))
    );
  }
}



