import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ReportMetric } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { GOAL_FIELD } from '@shared/constants';

@Component({
    selector: 'tsh-report-metric',
    templateUrl: './report-metric.component.html',
    styleUrls: ['./report-metric.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportMetricComponent implements OnChanges {
    @Input() public data: ReportMetric;
    @Input() public currency: string | null;

    public changeValue: number | null = null;
    public changePercent: number | null = null;
    public changeValueToDisplay: number;
    public changePercentToDisplay: number;
    public goalPercent: number;
    public readonly percentType = DataTypeEnum.Percent;
    public readonly goalField = GOAL_FIELD;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data?.currentValue) {
            this.calculateChangeValue();
        }
    }

    calculateChangeValue(): void {
        // calculate change value only if there is previous value
        if (this.data?.previous?.value) {
            this.changeValue = this.data.current.value - this.data.previous.value;
            this.changePercent = this.changeValue / this.data.previous.value * 100;
            // show without minus
            this.changeValueToDisplay = Math.abs(this.changeValue);
            // show without minus
            this.changePercentToDisplay = Math.abs(this.changePercent);
        }

        if (this.data?.[GOAL_FIELD]) {
            this.goalPercent = this.data.current.value / this.data[GOAL_FIELD].value * 100;
        }
    }
}

