import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TsWindowService, untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { IDateCohort, userMessageFactory } from '@shared/interfaces';
import { InfluenceType, ModelType, PortalTargets, RouteItemEnum, TileTypes, ToDate } from '@shared/enums';
import { CAMPAIGN_CHART_OPTIONS } from '../data/attribution.data';
import { attributionActions } from '../state/attribution.actions';
import * as selectors from '../state/attribution.selectors';
import * as OrgConfigStore from '@org-config';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as DateCohortsStore from '@date-cohorts';
import { dateCohortsActions } from '@date-cohorts';
import { notificationMessagesActions } from '@notification-messages';
import { NavigateTo } from '@hub-navigation';
import { TileModalService } from '@shared/features/tile-modal';
import { ICampaignSpecificFilters } from '@measurement-studio/interfaces';
import { ICampaignChartType, ICampaignSpecificLink } from '../interfaces';

@WithDestroy
@Component({
  selector: 'ms-attribution',
  templateUrl: './attribution.component.html',
  styleUrls: ['./attribution.component.scss'],
})
export class AttributionComponent implements OnInit {
  public isLoading$ = this.store.pipe(select(selectors.getCampaignSpecificLoading));
  public tabs$ = this.store.pipe(select(selectors.getCampaignSpecificTabs));
  public filters$ = this.store.pipe(select(selectors.getCampaignSpecificParams));
  public selectedDateCohort$ = this.store.pipe(select(selectors.getSelectedCohort));
  public modelTypeOptions$ = this.store.pipe(select(OrgConfigStore.getUserModels));
  public dateCohortOptions$ = this.store.pipe(select(DateCohortsStore.getGroupedDateCohortOptions()));
  public metrics$ = this.store.pipe(select(selectors.getAttributionMetrics));
  public selectedChartType$ = this.store.pipe(select(selectors.getSelectedChartType));
  public chartTooltip$ = this.selectedChartType$.pipe(
    mergeMap(selectedType => this.translateService.get(selectedType.label)));
  public chart$ = this.store.pipe(select(selectors.getSelectedChart));
  public campaignInfo$ = this.store.pipe(select(selectors.getCampaignInfo));
  public links$ = this.store.pipe(select(selectors.getCampaignLinks));

  public readonly noResultDescription = 'feature.campaignSpecific.chart.noResultDescription';
  public readonly noResultTitle = 'feature.campaignSpecific.chart.noResultTitle';
  public readonly targets = PortalTargets;

  public chartTypeOptions = CAMPAIGN_CHART_OPTIONS;
  public defaultQueryParams: ICampaignSpecificFilters = {
    modelType: ModelType.Even,
    dataSet: null,
    campaignId: null,
    startDate: 0,
    endDate: 0,
    influenceType: InfluenceType.ANY,
    cohort: ToDate.QUARTER_TO_DATE
  };

  constructor(private store: Store<unknown>,
              private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private windowService: TsWindowService,
              private tileModalService: TileModalService) {
  }

  ngOnInit(): void {
    this.loadDateCohorts();
    this.setFilters();
  }

  setFilters() {
    this.setSelectedReport();
    this.route.queryParams
      .pipe(
        untilComponentDestroyed(this),
        withLatestFrom(
          this.filters$.pipe(take(1)),
          this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1))
        ))
      .subscribe(([params, filters, globalFilters]) => {
        if (!Object.keys(params).length && !filters) {
          // if there is no any query params then use default params
          this.applyFilters({...this.defaultQueryParams, ...globalFilters});
          return;
        }

        if (!Object.keys(params).length && filters) {
          // if there is no any query params but filters in the store then use them
          this.applyFilters({...filters, ...globalFilters});
          return;
        }

        this.store.dispatch(attributionActions.getCampaignFilters({ params }));
      });
  }

  loadDateCohorts() {
    this.store.dispatch(dateCohortsActions.loadDateCohorts());
  }

  applyFilters(newFilters: ICampaignSpecificFilters): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newFilters,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  setSelectedDateCohort(cohort: IDateCohort): void {
    this.store.dispatch(attributionActions.setDateCohort({ cohort}));
  }

  changeChartType(chartType: ICampaignChartType): void {
    this.store.dispatch(attributionActions.changeSelectedChartType({ chartType }));
  }

  redirect(link: ICampaignSpecificLink): void {
    if (link.isExternal) {
      this.windowService.nativeWindow.open(link.routeId, '_blank');
    } else {
      this.store.dispatch(NavigateTo({
        routeId: link.routeId as RouteItemEnum,
        queryParams: link.queryParams
      }));
    }
  }

  setSelectedReport(): void {
    if (this.route.snapshot.data.routeId) {
      this.store.dispatch(attributionActions.setSelectedReport({ route: this.route.snapshot.data.routeId as RouteItemEnum }));
    } else {
      this.store.dispatch(notificationMessagesActions.addMessage({ message: userMessageFactory({
        n: 'There is no routeId in route data. Unable to load data'
      }) }));
    }
  }

  addToDashboardDialog(): void {
    const {queryParams, data} = this.route.snapshot;
    const type = data.routeId === RouteItemEnum.CampaignSpecific ? TileTypes.CampaignSpecific : TileTypes.WebActivitiesSpecific;

    this.tileModalService.openAddToDashboardModal(type, queryParams);
  }
}
