<div class="c-date-range">
  <ts-input
    class="c-date-range__input c-date-range__input--start"
    [dateLocale]="dateLocale"
    [formControl]="internalStartControl"
    [label]="startLabel"
    [maxDate]="startMaxDate$ | async"
    [minDate]="startMinDate"
    [startingView]="startingView"
    [isDisabled]="isDisabled || startDateControl.disabled"
    mask="date"
    [datepicker]="true"
    [datepickerDefaultOpen]="defaultStartDatepickerOpen"
    (selected)="startDateSelected($event)"
    (inputBlur)="startBlur($event)"
    #start
  ></ts-input>

  <span class="c-date-range__separator">
    {{ separator }}
  </span>

  <ts-input
    class="c-date-range__input c-date-range__input--end"
    [dateLocale]="dateLocale"
    [formControl]="internalEndControl"
    [label]="endLabel"
    [maxDate]="endMaxDate"
    [minDate]="endMinDate$ | async"
    [startingView]="startingView"
    [isDisabled]="isDisabled || endDateControl.disabled"
    mask="date"
    [datepicker]="true"
    [datepickerDefaultOpen]="defaultEndDatepickerOpen"
    (selected)="endDateSelected($event)"
    (inputBlur)="endBlur($event)"
    #end
  ></ts-input>
</div>

<div class="ts-date-range__messages" *ngIf="!noValidationOrHint">
  <div class="ts-date-range__error" *ngIf="errorMessage">{{ errorMessage }}</div>
  <div class="ts-date-range__hint" *ngIf="hint && !errorMessage">{{ hint }}</div>
</div>
