import { createSelector } from '@ngrx/store';

import { AnalyticsResponseTypeEnum as ResponseType } from '../../enums';
import { campaignGroupsFeatureKey, ICampaignGroupsState } from './campaign-groups.reducer';
import { AnalyticsState, selectAnalyticsState } from '..';
import { gerRecordIds, isStateLoading } from '../../helpers/store.helper';
import { ICampaignGroupsTableItem, INestedTableState } from '../../interfaces';

export const getCampaignGroups = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[campaignGroupsFeatureKey]);

export const getReportData = createSelector(getCampaignGroups,
  (campaignGroups: ICampaignGroupsState): Partial<ICampaignGroupsTableItem>[] => campaignGroups.data);

export const getCampaignGroupsTotalResults = createSelector(getCampaignGroups,
  (campaignGroups: ICampaignGroupsState) => campaignGroups.totalResults);

export const getCampaignGroupsRecordIds = createSelector(getCampaignGroups,
  (campaignGroups: ICampaignGroupsState) => gerRecordIds(campaignGroups.data));

export const getCampaignGroupsUsedParams = createSelector(getCampaignGroups,
  (campaignGroups: ICampaignGroupsState) => campaignGroups.usedParamsInDriver);

export const getCampaignGroupsIsLoading = createSelector(getCampaignGroups, isStateLoading);

export const getCampaignGroupsIsDriverLoading = createSelector(getCampaignGroups,
  (campaignGroups: ICampaignGroupsState) => campaignGroups.isDriverLoading);

export const getHasWebActivitiesIds = createSelector(getCampaignGroups,
  (campaigns: ICampaignGroupsState): string[] =>
    campaigns[ResponseType.HasWebActivity].map(item => item.groupId));

export const getNestedTableState = createSelector(getCampaignGroups,
  (campaigns: ICampaignGroupsState) => campaigns.nestedTableState);

export const getNestedTableVisibility = createSelector(getNestedTableState,
  (nestedState: INestedTableState): Record<string, boolean> => nestedState.visible);
