import { IDateCohort, IReportColumn } from '@shared/interfaces';
import { stagesSnapshotActions } from './stages-snapshot.actions';
import { FLAT_STAGE_PROGRESSION_DETAIL_COLUMNS } from '../data/flat-stage-progression-details.colums';
import { SortDirections } from '@shared/enums';
import { IFlatStageProgression, IFlatStageProgressionDetail, IStagesSnapshotFilters, IStagesSnapshotTableOptions } from '../interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const STAGES_SNAPSHOT_KEY = 'stagesSnapshot';

export interface StagesSnapshotState {
  stageProgressionData: IFlatStageProgression[] | null;
  stageProgressionDetailData: { [stage in string]: IFlatStageProgressionDetail[]; } | null;
  isLoading: boolean;
  filters: IStagesSnapshotFilters | null;
  selectedDateCohort: IDateCohort | null;
  activeStage: string | null;
  columns: IReportColumn[];
  tableOptions: IStagesSnapshotTableOptions;
}

export const initialState: StagesSnapshotState = {
  stageProgressionData: null,
  stageProgressionDetailData: null,
  filters: null,
  isLoading: false,
  selectedDateCohort: null,
  activeStage: null,
  columns: FLAT_STAGE_PROGRESSION_DETAIL_COLUMNS,
  tableOptions: {
    sortState: {
      active: '',
      direction: SortDirections.Asc,
    },
    page: 1,
    searchQuery: '',
  },
};

const stagesSnapshotReducer = createReducer(
  initialState,
  on(stagesSnapshotActions.loadStageProgressionData,
    stagesSnapshotActions.loadStageProgressionDetailData, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(stagesSnapshotActions.loadStageProgressionDataFailure,
    stagesSnapshotActions.loadStageProgressionDetailDataFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(stagesSnapshotActions.loadStageProgressionDataSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    stageProgressionData: action.data,
    activeStage: action.data[0]?.name,
    tableOptions: {
      ...state.tableOptions,
      page: 1,
      searchQuery: '',
    }
  })),
  on(stagesSnapshotActions.loadStageProgressionDetailDataSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    stageProgressionDetailData: {
      ...state.stageProgressionDetailData,
      [state.activeStage]: action.data,
    },
    tableOptions: {
      sortState: {
        active: '',
        direction: SortDirections.Asc,
      },
      page: 1,
      searchQuery: '',
    },
  })),
  on(stagesSnapshotActions.setFilters, (state, action) => ({
    ...state,
    filters: action.request,
  })),
  on(stagesSnapshotActions.setSelectedDateCohort, (state, action) => ({
    ...state,
    selectedDateCohort: action.cohort
  })),
  on(stagesSnapshotActions.setActiveStage, (state, action) => ({
    ...state,
    activeStage: action.stage,
    tableOptions: {
      ...state.tableOptions,
      page: 1,
      searchQuery: '',
    }
  })),
  on(stagesSnapshotActions.setTablePage, (state, action) => ({
    ...state,
    tableOptions: {
      ...state.tableOptions,
      page: action.page,
    },
  })),
  on(stagesSnapshotActions.setTableSearch, (state, action) => ({
    ...state,
    tableOptions: {
      ...state.tableOptions,
      searchQuery: action.search,
      page: 1,
    },
  })),
  on(stagesSnapshotActions.setTableSortState, (state, action) => ({
    ...state,
    tableOptions: {
      ...state.tableOptions,
      sortState: action.data,
      page: 1,
    }
  })),
);

export function reducer(state, action: Action): StagesSnapshotState {
  return stagesSnapshotReducer(state, action);
}

