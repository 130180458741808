<div class="alert" [ngClass]="{
    'alert-default': type === 'default',
    'alert-warning': type === 'warning',
    'alert-error': type === 'error',
    'alert-dismissible': dismissible}">
  <div class="alert-content">
    <ng-content></ng-content>
  </div>
  <ts-tooltip *ngIf="dismissible"
              [tooltipValue]="tooltip | translate"
              tooltipPosition="above"
              [hasUnderline]="false">
    <ts-icon-button [icon]="icon"
                    (clicked)="emitClose($event)"
                    buttonType="button"
                    theme="primary">
    </ts-icon-button>
  </ts-tooltip>
</div>
