export enum NotificationTypes {
  Success = 'success',
  Error = 'error',
}

export enum NotificationIcons {
  Check = 'fa-check-square',
  Exclamation = 'fa-exclamation-square',
}

