<div class="header">
  <h3 class="header-title">{{ data.list ?
    ('shared.accountHub.edit' | translate : {name: data.list.name}) :
    ('shared.accountHub.createNewAccountList' | translate )}}</h3>
  <i class="fas fa-times"
    mat-dialog-close></i>
</div>
<hr class="dialog-divider">
<div class="content">
  <form [formGroup]="form">
    <ts-input
      type="text"
      [label]="'shared.accountHub.listName' | translate"
      [placeholder]="'shared.accountHub.listName' | translate"
      formControlName="name"
      [isRequired]="true"
      maxlength="64"
      data-cy="ds-list-create-list-name"
      [errorMessage]="form.get('name').dirty && (
        (form.get('name').errors?.required && ('shared.accountHub.errors.required' | translate)) ||
        (form.errors?.duplicate && ('shared.accountHub.errors.duplicate' | translate)) ||
        (form.get('name').errors?.maxlength && ('shared.accountHub.errors.maxlength' | translate))
      )"
      [hint]="'[' + (form.get('name').value?.length || 0) +  '/' +  '64]'">
    </ts-input>
    <ts-selection-list
      formControlName="folders"
      [displayFormatter]="formatDisplay"
      [valueComparator]="folderCompareFunction"
      [label]="'shared.accountHub.folder' | translate"
      (queryChange)="query = $event"
      data-cy="ds-list-create-folder"
      (selectionChange)="query = $event.value[0].folderName">
      <ts-option *ngFor="let folder of folders"
          [value]="folder"
          [option]="folder">
          {{ folder.folderName }}
        </ts-option>
    </ts-selection-list>
  </form>
</div>
<hr class="dialog-divider">
<div class="actions">
    <ts-button
        theme="default"
        [isDisabled]="form.invalid"
        data-cy="ds-list-create-button"
        (clicked)="close()">
        {{ (data.list ? 'shared.accountHub.save' : 'shared.accountHub.createNewList') | translate }}
    </ts-button>
</div>
