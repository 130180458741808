import * as Keys from '../enums/insights-report-tab';
import {
  INSIGHTS_DETAILS_COLUMN_DEFINITIONS as Columns,
  INSIGHTS_DETAILS_ICON_COLUMN
} from '../data/details-report-column-configs.consts';
import { IInsightsDetailColumn } from '../interfaces';

export interface IInsightsDetailsColumns {
  columns: IInsightsDetailColumn[];
  displayedColumns: string[];
}

export const PAGE_VIEWS_INFLUENCED_REPORT_COLUMNS: Record<string, IInsightsDetailsColumns> = {
  [Keys.PageViewsInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.PageViews,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.CostPerPageView
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.PageViews.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.CostPerPageView.name
    ]
  },
  [Keys.PageViewsInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.PageViews,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.CostPerPageView
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.PageViews.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name,
      Columns.CostPerPageView.name
    ]
  }
};

export const LEADS_INFLUENCED_REPORT_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.LeadsInfluencedReportTab.Leads]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.LeadName,
      Columns.AccountName,
      Columns.LeadCreatedDate,
      Columns.FirstImpressionDate,
      Columns.DaysToLeadCreated
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.LeadName.name,
      Columns.AccountName.name,
      Columns.LeadCreatedDate.name,
      Columns.FirstImpressionDate.name,
      Columns.DaysToLeadCreated.name
    ]
  },
  [Keys.LeadsInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.LeadsInfluenced,
      Columns.DaysToLeadCreated,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.CostPerLead
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.LeadsInfluenced.name,
      Columns.DaysToLeadCreated.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.CostPerLead.name
    ]
  },
  [Keys.LeadsInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.LeadsInfluenced,
      Columns.DaysToLeadCreated,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.CostPerLead
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.LeadsInfluenced.name,
      Columns.DaysToLeadCreated.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name,
      Columns.CostPerLead.name
    ]
  }
};

export const CAMPAIGN_RESPONSES_INFLUENCED_REPORT_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.CampaignName,
      Columns.LeadName,
      Columns.AccountName,
      Columns.ActivityDate,
      Columns.FirstImpressionDate,
      Columns.DaysToResponse
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.CampaignName.name,
      Columns.LeadName.name,
      Columns.AccountName.name,
      Columns.ActivityDate.name,
      Columns.FirstImpressionDate.name,
      Columns.DaysToResponse.name
    ]
  },
  [Keys.CampaignResponsesInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.CampaignResponsesInfluenced,
      Columns.DaysToResponse,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.CostPerCampaignResponse
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.CampaignResponsesInfluenced.name,
      Columns.DaysToResponse.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.CostPerCampaignResponse.name
    ]
  },
  [Keys.CampaignResponsesInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.CampaignResponsesInfluenced,
      Columns.DaysToResponse,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.CostPerCampaignResponse
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.CampaignResponsesInfluenced.name,
      Columns.DaysToResponse.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name,
      Columns.CostPerCampaignResponse.name
    ]
  }
};

export const NEW_PIPELINE_INFLUENCED_REPORT_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.NewPipelineInfluencedReportTab.Pipeline]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.OpportunityName,
      Columns.AccountName,
      Columns.OpportunityType,
      Columns.FirstImpressionDate,
      Columns.OpportunityCreatedDate,
      Columns.OpportunityAmount,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.OpportunityName.name,
      Columns.AccountName.name,
      Columns.OpportunityType.name,
      Columns.FirstImpressionDate.name,
      Columns.OpportunityCreatedDate.name,
      Columns.OpportunityAmount.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.NewPipelineInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.NewOpportunitiesInfluenced,
      Columns.AmountInfluenced,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.CostPerOpportunity
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.NewOpportunitiesInfluenced.name,
      Columns.AmountInfluenced.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.CostPerOpportunity.name
    ]
  },
  [Keys.NewPipelineInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.NewOpportunitiesInfluenced,
      Columns.AmountInfluenced,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.CostPerOpportunity
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.NewOpportunitiesInfluenced.name,
      Columns.AmountInfluenced.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name
    ]
  }
};

export const PIPELINE_INFLUENCED_REPORT_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.PipelineInfluencedReportTab.Pipeline]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.OpportunityName,
      Columns.AccountName,
      Columns.OpportunityType,
      Columns.OpportunityAmount,
      Columns.FirstImpressionDate,
      Columns.OpportunityCloseDate,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.OpportunityName.name,
      Columns.AccountName.name,
      Columns.OpportunityType.name,
      Columns.OpportunityAmount.name,
      Columns.FirstImpressionDate.name,
      Columns.OpportunityCloseDate.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.PipelineInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.PipelineInfluenced,
      Columns.AmountInfluencedExisting,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.CostPerOpportunity
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.PipelineInfluenced.name,
      Columns.AmountInfluencedExisting.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.CostPerOpportunity.name
    ]
  },
  [Keys.PipelineInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.PipelineInfluenced,
      Columns.AmountInfluencedExisting,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.CostPerOpportunity
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.PipelineInfluenced.name,
      Columns.AmountInfluencedExisting.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name
    ]
  }
};

export const REVENUE_INFLUENCED_REPORT_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.RevenueInfluencedReportTab.Revenue]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.OpportunityName,
      Columns.OpportunityCloseDate,
      Columns.OpportunityAmount,
      Columns.FirstImpressionDate,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.ROAS
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.OpportunityName.name,
      Columns.OpportunityCloseDate.name,
      Columns.OpportunityAmount.name,
      Columns.FirstImpressionDate.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.ROAS.name
    ]
  },
  [Keys.RevenueInfluencedReportTab.Accounts]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.AccountName,
      Columns.RevenueInfluenced,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.Tactics,
      Columns.ROAS
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.AccountName.name,
      Columns.RevenueInfluenced.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.Tactics.name,
      Columns.ROAS.name
    ]
  },
  [Keys.RevenueInfluencedReportTab.AdTactics]: {
    columns: [
      INSIGHTS_DETAILS_ICON_COLUMN,
      Columns.TacticName,
      Columns.RevenueInfluenced,
      Columns.AdSpend,
      Columns.Impressions,
      Columns.AccountsReached,
      Columns.ROAS
    ],
    displayedColumns: [
      INSIGHTS_DETAILS_ICON_COLUMN.name,
      Columns.TacticName.name,
      Columns.RevenueInfluenced.name,
      Columns.AdSpend.name,
      Columns.Impressions.name,
      Columns.AccountsReached.name,
      Columns.ROAS.name
    ]
  }
};

export const PAGE_VIEWS_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.PageViewsInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.PageViewsInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.AccountName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.AccountName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  }
};

export const LEADS_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.LeadsInfluencedReportTab.Leads]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.LeadsInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.LeadsInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.LeadName,
      Columns.AccountName
    ],
    displayedColumns: [
      Columns.LeadName.name,
      Columns.AccountName.name
    ]
  }
};

export const CAMPAIGN_RESPONSES_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.CampaignResponsesInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.CampaignResponsesInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.CampaignName,
      Columns.AccountName
    ],
    displayedColumns: [
      Columns.CampaignName.name,
      Columns.AccountName.name
    ]
  }
};

export const NEW_PIPELINE_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.NewPipelineInfluencedReportTab.Pipeline]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.NewPipelineInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.NewPipelineInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.OpportunityName,
      Columns.AccountName
    ],
    displayedColumns: [
      Columns.OpportunityName.name,
      Columns.AccountName.name
    ]
  }
};

export const PIPELINE_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.PipelineInfluencedReportTab.Pipeline]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.PipelineInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.PipelineInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.OpportunityName,
      Columns.AccountName
    ],
    displayedColumns: [
      Columns.OpportunityName.name,
      Columns.AccountName.name
    ]
  }
};

export const REVENUE_INFLUENCED_CHILD_TABLE_COLUMNS: Record<number, IInsightsDetailsColumns> = {
  [Keys.RevenueInfluencedReportTab.Revenue]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.RevenueInfluencedReportTab.Accounts]: {
    columns: [
      Columns.TacticName,
      Columns.AdSpend,
      Columns.Impressions
    ],
    displayedColumns: [
      Columns.TacticName.name,
      Columns.AdSpend.name,
      Columns.Impressions.name
    ]
  },
  [Keys.RevenueInfluencedReportTab.AdTactics]: {
    columns: [
      Columns.OpportunityName,
      Columns.AccountName
    ],
    displayedColumns: [
      Columns.OpportunityName.name,
      Columns.AccountName.name
    ]
  }
};
