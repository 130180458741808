import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { hubTokenName, MAX_EXTENDED_ROWS_NUMBER } from '@shared/constants';

import { EnvService } from '@shared/environment';
import * as interfaces from '../interfaces';
import { WebActivitiesSource } from '../sources/web-activities.source';
import {
  IDashboardTile,
  IGetTileData,
  ILabelValue,
  IReportColumn,
  ITileData,
  ITileDataItem,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { RouteItemEnum, TileTypes, ToDate } from '@shared/enums';
import { AnalyticsColumnName as Column, AnalyticsResponseTypeEnum as ResponseType } from '../enums';
import { analyticsTileFieldOptions, analyticsTileFields, CAMPAIGN_COLUMNS, SOURCE_COLUMNS, TileSettingsAnalyticsFields } from '../data';
import { getFiltersFromParams, getResponseTypeByColumn } from '../helpers/store.helper';
import {
  getAllAnalyticsGlobalFilters,
  getAllGlobalFiltersFromParams,
  getStartAndEndDateFromParams,
  replaceLegacyCohort
} from '@util/helpers';
import * as OrgConfigStore from '@org-config';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { allSourceOption } from '../state/campaign/campaign.reducer';
import * as CatalogsStore from '@shared/data-access/catalogs';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

@Injectable({providedIn: 'root'})
export class WebActivitiesService implements IGetTileData<IAnalyticsRequest> {

  constructor(public source: WebActivitiesSource,
              public store: Store<unknown>,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesCampaignResponse>> {
    return regenerateOnRetry(() => this.source.getCampaignMetaData$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCampaignResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    return regenerateOnRetry(() => this.source.getCampaignResponses$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCampaignAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    return regenerateOnRetry(() => this.source.getCampaignAttribution$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCampaignInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    return regenerateOnRetry(() => this.source.getCampaignInfluence$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCampaignReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    return regenerateOnRetry(() => this.source.getCampaignReturns$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCampaignCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    return regenerateOnRetry(() => this.source.getCampaignCosts$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesGroupResponse>> {
    return regenerateOnRetry(() => this.source.getSourceMetaData$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    return regenerateOnRetry(() => this.source.getSourceResponses$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    return regenerateOnRetry(() => this.source.getSourceAttribution$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    return regenerateOnRetry(() => this.source.getSourceInfluence$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    return regenerateOnRetry(() => this.source.getSourceReturns$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSourceCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    return regenerateOnRetry(() => this.source.getSourceCosts$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesMediumResponse>> {
    return regenerateOnRetry(() => this.source.getMediumMetaData$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    return regenerateOnRetry(() => this.source.getMediumResponses$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    return regenerateOnRetry(() => this.source.getMediumAttribution$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    return regenerateOnRetry(() => this.source.getMediumInfluence$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    return regenerateOnRetry(() => this.source.getMediumReturns$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getMediumCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    return regenerateOnRetry(() => this.source.getMediumCosts$(body)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    const isChannelAsset = tile.route === RouteItemEnum.WebActivitiesChannelAssets;
    const field = this.getCorrectColumn(tile.settings);
    const columns: Partial<IReportColumn>[] = this.getTileColumnsByType(tile.route, field);

    return of({
      metricLabel: isChannelAsset
        ? 'measurementStudio.features.analytics.campaignAnalytics.campaignTable.titlePlural'
        : 'measurementStudio.features.analytics.campaignAnalytics.sourceTable.titlePlural',
      table: {
        columns,
        totalsPluralLabel: isChannelAsset
          ? 'measurementStudio.features.analytics.campaignAnalytics.campaignTable.titlePlural'
          : 'measurementStudio.features.analytics.campaignAnalytics.sourceTable.titlePlural',
        totalsLabel: isChannelAsset
          ? 'measurementStudio.features.analytics.campaignAnalytics.campaignTable.title'
          : 'measurementStudio.features.analytics.campaignAnalytics.sourceTable.title',
      }
    });
  }

  getTileData$(params: IAnalyticsRequest, routeId: RouteItemEnum): Observable<ITileData> {
    const field: Column = this.getCorrectColumn(params);
    const requestParams: IAnalyticsRequest = this.getDriverRequest(params, field);
    const responseType: ResponseType = getResponseTypeByColumn(field);
    const isChannelAsset = routeId === RouteItemEnum.WebActivitiesChannelAssets;
    const [getMetaData, getFieldData] = this.getSourceMethods(isChannelAsset, field);

    return this.source[getFieldData](requestParams).pipe(
      // secondary request
      switchMap((data: interfaces.ICountable) => this.source[getMetaData]({
        ...requestParams,
        isDriver: false,
        recordIds: data[responseType].map(item => item.groupId),
      }).pipe(
        pluck(ResponseType.Campaign),
        map((metaData: interfaces.ICampaignIdentifiable[]) => this.tileAdapter(data[responseType], metaData, data.totalResults)),
      )),
    );
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    const field: Column = this.getCorrectColumn(params);
    const defaultSettings = this.getFiltersFromParams(params, []);
    return {
      ...params, // to keep global filters
      ...defaultSettings,
      ...defaultSettings.cohort, // remove nested cohort object
      [TileSettingsAnalyticsFields.Field]: field,
      [TileSettingsAnalyticsFields.Group]: defaultSettings.typeFilter || '',
      [TileSettingsAnalyticsFields.Model]: params[TileSettingsAnalyticsFields.Model] || defaultSettings.model,
    };
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[]> {
    return combineLatest([
      this.store.pipe(select(OrgConfigStore.getUserModels)),
      this.store.pipe(select(CatalogsStore.getSourceOptions)),
    ]).pipe(
      map(([models, types]: ILabelValue[][]) => [{
        key: TileSettingsAnalyticsFields.DataSet,
        label: 'measurementStudio.features.analytics.filterscampaignsThat',
        type: TileSettingsControlTypes.Selector,
        options: new DataSetOptions().getDataSetFullOptions(),
      }, {
        key: TileSettingsAnalyticsFields.Model,
        label: 'measurementStudio.features.analytics.filtersmodel',
        type: TileSettingsControlTypes.Selector,
        options: models,
      }, {
        key: TileSettingsAnalyticsFields.InfluenceType,
        label: 'measurementStudio.features.analytics.filtersinfluenceType',
        type: TileSettingsControlTypes.Selector,
        options: INFLUENCE_TYPE_OPTIONS,
      }, {
        key: TileSettingsAnalyticsFields.Field,
        label: 'shared.dashboards.settings.metric',
        type: TileSettingsControlTypes.Selector,
        options: analyticsTileFieldOptions,
      },
        ...this.getTypesSettingControl(type, types),
      ])
    );
  }

  private getDriverRequest(params: IAnalyticsRequest, field: Column): IAnalyticsRequest {
    const globalFilters = params?.globalFilters?.length ? null : getAllGlobalFiltersFromParams(params);
    const webGlobalFilters = params?.globalFilters?.length
      ? params.globalFilters
      : globalFilters
        ? getAllAnalyticsGlobalFilters(globalFilters.listIds, globalFilters.filters)
        : [];
    const cohortString = typeof params['cohort'] === 'object'
      ? params['cohort'].cohort
      : params['cohort'];
    const startDate = typeof params['cohort'] === 'object' ? params['cohort'].startDate : params['startDate'];
    const endDate = typeof params['cohort'] === 'object' ? params['cohort'].endDate : params['endDate'];
    const cohort = {
      cohort: replaceLegacyCohort(cohortString) || ToDate.QUARTER_TO_DATE,
      ...getStartAndEndDateFromParams(cohortString, startDate, endDate)
    };
    return {
      ...params,
      cohort,
      globalFilters: webGlobalFilters,
      typeFilter: params['gf'] || '',
      isDriver: true,
      sorter: {sortDirection: 'desc', sortField: field},
      pager: {pageNum: 1, pageSize: MAX_EXTENDED_ROWS_NUMBER}
    } as IAnalyticsRequest;
  }

  private getSourceMethods(isChannelAsset: boolean, field: Column): [string, string] {
    const dataMethod = this.getMethodByField(isChannelAsset, field);
    return [
      isChannelAsset ? 'getCampaignMetaData$' : 'getSourceMetaData$',
      dataMethod,
    ];
  }

  private getMethodByField(isChannelAsset: boolean, field: Column): string {
    const suffix = isChannelAsset ? 'Campaign' : 'Source';
    switch (field) {
      case Column.OpptyTouches:
      case Column.DealTouches:
      case Column.AttributedPipeline:
      case Column.AttributedRevenue:
        return `get${suffix}Attribution$`;
      case Column.CPL:
      case Column.CPO:
      case Column.CPD:
      case Column.LTO:
      case Column.OTD:
        return `get${suffix}Costs$`;
      case Column.Opptys:
      case Column.Deals:
      case Column.InfluencedPipeline:
      case Column.InfluencedRevenue:
        return `get${suffix}Influence$`;
      case Column.Responses:
      case Column.People:
      case Column.Accounts:
        return `get${suffix}Responses$`;
      case Column.PipelineRoi:
      case Column.ROI:
      case Column.ROAS:
        return `get${suffix}Returns$`;
    }
  }

  private tileAdapter<T extends interfaces.ICampaignIdentifiable>(driverItems: T[], metaItems: T[], totalCount: number): ITileData {
    return {
      items: driverItems.map(driverItem => {
        const metaData = metaItems.find(metaItem => metaItem.groupId === driverItem.groupId);
        return {
          ...metaData,
          ...driverItem,
        };
      }) as unknown as ITileDataItem[],
      totalCount,
    };
  }

  private getCorrectColumn(params: Params): Column {
    return analyticsTileFields.includes(params['field']) ? params['field'] as Column : Column.Responses;
  }

  private getTileColumnsByType(routeId: RouteItemEnum, field: Column): Partial<IReportColumn>[] {
    switch (routeId) {
      case RouteItemEnum.WebActivitiesChannel:
        return SOURCE_COLUMNS.filter(item => item.name === field || item.name === Column.SourceName);
      case RouteItemEnum.WebActivitiesChannelAssets:
        return CAMPAIGN_COLUMNS.filter(item => item.name === field || item.name === Column.CampaignName);
    }
  }

  private getTypesSettingControl(type: TileTypes, types: ILabelValue[]): ITileSettingControl[] {
    return type === TileTypes.WebActivitiesChannelAssets ? [{
      key: TileSettingsAnalyticsFields.Group,
      label: 'shared.dashboards.settings.filterBy',
      type: TileSettingsControlTypes.Selector,
      options: [allSourceOption, ...types],
    }] : [];
  }

  private getFiltersFromParams(params: Params, userModels: ILabelValue[]): IAnalyticsRequest {
    return getFiltersFromParams(params, userModels);
  }
}
