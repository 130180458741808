import { Injectable } from '@angular/core';

import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { Observable, of } from 'rxjs';

import { GlobalSearchSource } from '../sources/global-search.source';
import { EnvService } from '@shared/environment';
import { IGlobalSearchItem } from '../interfaces';
import { hubTokenName } from '@shared/constants';

@Injectable()
export class GlobalSearchService {
  constructor(public source: GlobalSearchSource,
              public retry: RetryWithEscalation,
              envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getOpportunities$(searchQuery: string): Observable<IGlobalSearchItem[]> {
    return regenerateOnRetry(() => this.source.getOpportunities$(searchQuery))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccounts$(searchQuery: string): Observable<IGlobalSearchItem[]> {
    return regenerateOnRetry(() => this.source.getAccounts$(searchQuery))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getChannels$(searchQuery: string): Observable<IGlobalSearchItem[]> {
    return regenerateOnRetry(() => this.source.getChannels$(searchQuery))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
