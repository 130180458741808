/* eslint-disable @typescript-eslint/no-explicit-any */
import { publicShapeOf } from '@terminus-lib/fe-utilities';
import { Injectable } from '@angular/core';

import { FullStoryService, WindowWithFS } from './fullstory.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FullStoryServiceMock implements publicShapeOf<FullStoryService> {
  public fullStoryLoadStatus: BehaviorSubject<boolean>;
  public window: WindowWithFS;
  public document: Document;

  get fullStory() {
    return undefined;
  }

  get apiIsUnavailable(): boolean {
    return false;
  }

  isFullStoryInitialized() {
    return;
  }

  init() {
    return;
  }

  identify() {
    return;
  }

  sendEvent() {
    return;
  }

  log() {
    return;
  }

  clearIdentity() {
    return;
  }

  getCurrentSession(): string | undefined {
    return;
  }

  injectSnippet() {
    return;
  }

  setUserVars() {
    return;
  }
}
