import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FullStoryService } from '@fullstory/fullstory.service';
import { TranslateService } from '@ngx-translate/core';
import { TsCookieService, TsWindowService } from '@terminus-lib/fe-utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { InitialTokenExtracted, JwtTokenManagementActionTypes, StoreToken, TokenExtractor, jwtDecode } from '@terminus-lib/fe-jwt';
import { TokenResponse } from '@okta/okta-auth-js';

import { GainsightService } from '@gainsight';
import { hubCookieActions } from '@hub-cookie';
import { notificationMessagesActions } from '@notification-messages';
import { OktaService, UNIFIED_LOGIN_LEARN_MORE, UNIFIED_LOGIN_LOG_IN, UNIFIED_LOGIN_LOG_OUT } from '@hub-okta';
import { panicActions } from '@panic';
import { AUTH_CONT, AUTH_CONT_KEY_EMPLOYEE, hubTokenName, MEASUREMENT_STUDIO_SHARED_ROUTES, TARGET_URI } from '@shared/constants';
import { APP_IDENTITY } from '@shared/data-access/app-identity';
import * as UserCommon from '@shared/data-access/user-common';
import { getIsUnifiedLoginEnabled, userCommonActions } from '@shared/data-access/user-common';
import { NavigateTo } from '@hub-navigation';
import { EnvService } from '@shared/environment';
import { IAuthToken, IUserProfile, userMessageFactory } from '@shared/interfaces';
import {
  CouTypeForRequests,
  IGetUnifiedCustomerIdForCOURequest,
  IGetUnifiedCustomerIdForCOUResponse,
} from '../interfaces/customer-entitlement.interface';
import { CustomerEntitlementService } from '../services/customer-entitlement/customer-entitlement.service';
import { UserService } from '../services/user/user.service';
import { userActions } from './user.actions';
import * as UserSelectors from './user.selectors';
import { RouteItemEnum } from '@shared/enums';
import {
  splitIoFlagRequested,
  SplitIoService,
  TreatmentTypes,
  getTokensForTreatment,
} from '@shared/splitio';

@Injectable()
export class UserEffects {
  private sharedRoutes = MEASUREMENT_STUDIO_SHARED_ROUTES;

  constructor(
    private store: Store<unknown>,
    private actions$: Actions,
    private userService: UserService,
    private windowService: TsWindowService,
    private envService: EnvService,
    private fullstoryService: FullStoryService,
    private router: Router,
    private cookieService: TsCookieService,
    private translate: TranslateService,
    private oktaService: OktaService,
    private tokenExtractor: TokenExtractor,
    private customerEntitlementService: CustomerEntitlementService,
    private gainsightService: GainsightService,
    @Inject(APP_IDENTITY) private appIdentity: string,
    private splitIoService: SplitIoService,
  ) {}

  public loginRedirect$ = createEffect(() => ({
    unifiedLoginEnabled$ = this.store.pipe(select(getIsUnifiedLoginEnabled)),
    isOktaUser$ = of(this.cookieService.get(AUTH_CONT) !== AUTH_CONT_KEY_EMPLOYEE),
  } = {}) => this.actions$.pipe(
    ofType(userCommonActions.loginRedirect),
    concatLatestFrom(() => [
      unifiedLoginEnabled$,
      isOktaUser$
    ]),
    map(([_, unifiedLoginEnabled, isOktaUser]: [null, boolean, boolean]) => {
      if (unifiedLoginEnabled && isOktaUser) {
        const now = new Date();
        const skey = `terminus-${now.getDate()}`;
        const pathToBeStored = getPathAndQuery(this.windowService.nativeWindow.location);

        // don't store if we are attempting to store the AUTH_CONT url, rather use the fallback
        if (pathToBeStored.indexOf(AUTH_CONT) === -1){
          sessionStorage.setItem(skey, pathToBeStored);
        }

        return NavigateTo({
          path: [this.envService.getEnv().UNIFIED_LOGIN_URL, UNIFIED_LOGIN_LOG_IN],
          extras: {
            queryParams: {
              p: this.appIdentity,
              skey
            }
          },
          external: true
        });
      } else {
        return this.redirectToLoginPage(unifiedLoginEnabled);
      }
    })
  ));


  public logout$ = createEffect(() => ({
   unifiedLoginEnabled$ = this.store.pipe(select(
      getIsUnifiedLoginEnabled
   )),
   isOktaUser$ = of(this.cookieService.get(AUTH_CONT) !== AUTH_CONT_KEY_EMPLOYEE)
 } = {}) => this.actions$.pipe(
    ofType(userActions.logOut),
    withLatestFrom(
      unifiedLoginEnabled$,
      isOktaUser$
    ),
    mergeMap(([_, unifiedLoginEnabled, isOktaUser]: [null, boolean, boolean]) => unifiedLoginEnabled && isOktaUser
      ? [NavigateTo({
        path: [this.envService.getEnv().UNIFIED_LOGIN_URL, UNIFIED_LOGIN_LOG_OUT],
        external: true
      })]
      : [
        this.redirectToLoginPage(unifiedLoginEnabled, false),
        userCommonActions.clearUserIsAuthenticated(),
        hubCookieActions.deleteJwt(),
      ]
    )
  ));

  public getUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(userCommonActions.getUserProfile),
    mergeMap(() => {
      return this.userService.getUserProfile$().pipe(
        map((userProfile: IUserProfile) => {
          let routeId: RouteItemEnum;
          if (userProfile.op === 'redirect') {
            this.sharedRoutes.some(item => {
              if (userProfile.url.includes(item.routeLink[0])) {
                routeId = item.routeId;
              }
            });
          }
          return routeId ?
            NavigateTo({routeId}) :
            userCommonActions.getUserProfileSuccess({ profile: userProfile });
        }),
        catchError((error: HttpErrorResponse) => {
          const message = this.translate.get('user.getUserError', {errorStatus: error.status});
          return message.pipe(map(msg => userCommonActions.getUserProfileFailure({ message: msg })));
        })
      );
    })
    )
  );

  public switchUser$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.switchUser),
    map(action => action.user),
    mergeMap((username: string) => this.userService.switchUser$(username).pipe(
      map((response: IAuthToken) => userActions.switchUserSuccess({ token: response })),
      catchError((error: HttpErrorResponse) => {
        const message = error?.status === 403
          ? 'user.noAccess'
          : error?.message || 'user.switchUserError';
        return of(userActions.switchUserFailure({ message }));
      })
    ))
  ));

  splitIoUserFlags$ = createEffect(() => this.actions$.pipe(
    ofType(userCommonActions.requestUserSplitIoFlags),
      mergeMap((action) => {
        let orgUuid = '';
        try {
          const tokenInfo = jwtDecode(action.jwtToken);
          orgUuid = tokenInfo['sub.org'];
        } catch (e) {
          panicActions.addPanicMessage({message: 'Split Io flags requesting flow. JWT parsing failed. Token: ' + action.jwtToken});
        }

        this.splitIoService.initialize({ key: orgUuid, trafficType: TreatmentTypes.studiosOrgUuid });
        return getTokensForTreatment(TreatmentTypes.studiosOrgUuid).map((treatment: string) => {
          return splitIoFlagRequested({
            trafficType: TreatmentTypes.studiosOrgUuid,
            treatment,
          });
        });
      })
  ));

  public onSwitchUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.switchUserSuccess),
    map(action => action.token),
    map((newToken: IAuthToken) => hubCookieActions.updateJwtOnSwitchUser({token: newToken}))
  ));

  public getUserProfileSuccess$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType(userCommonActions.getUserProfileSuccess),
    concatLatestFrom(() => this.store.pipe(select(UserCommon.selectUserState))),
    map(([_, state]) => {
      // full story
      const uid = `hub-${state.userProfile.userId}`;
      const userVars = {
        email: state.userProfile.email,
        orgUuid: state.userProfile.orgUuid
      };
      this.fullstoryService.identify(uid, userVars);

      if (state.redirectUrl) {
        this.store.dispatch(userActions.clearRedirectUrl());
        this.router.navigate([state.redirectUrl.url], {queryParams: {...state.redirectUrl.queryParams}});
      }
    })
  ), {dispatch: false});

  public getUserProfileFailure$ = createEffect(() => this.actions$.pipe(
    ofType(userCommonActions.getUserProfileFailure),
    map(() => userCommonActions.loginRedirect())
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.switchUserFailure),
    map(action =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) }))
  ));

  public handleUserLogin$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.setUnifiedLoginStatus),
    concatLatestFrom(() => [
      this.store.pipe(select(UserSelectors.selectIsAuthenticated))
    ]),
    filter(([_, isAuthenticated]) => !isAuthenticated),
    mergeMap(() => {
      const isOktaUser = this.cookieService.get(AUTH_CONT) !== AUTH_CONT_KEY_EMPLOYEE;
      if (isOktaUser) {
        return this.oktaService.getSession$().pipe(
          mergeMap((sessionExist: boolean) => sessionExist
            ? this.oktaService.getWithoutPrompt$().pipe(
              mergeMap((res: TokenResponse) => {
                const oktaJwt = res.tokens.idToken.idToken;
                const oktaUserClaims = res.tokens.idToken.claims;
                const utmCustDomain = oktaUserClaims?.email.substring(oktaUserClaims?.email.indexOf('@') + 1) || '';

                return this.userService.authenticateUnifiedLogin$(oktaJwt).pipe(
                  this.tokenExtractor.extractJwtToken({tokenName: hubTokenName, isDefaultToken: true}),
                  mergeMap((authToken: IAuthToken) => [
                    hubCookieActions.updateJwt({token: authToken}),
                    new StoreToken({
                      tokenName: hubTokenName,
                      token: authToken.token,
                      isDefaultToken: true
                    })
                  ]),
                  catchError(() => of(
                    NavigateTo({
                      path: [this.envService.getEnv().UNIFIED_LOGIN_URL, UNIFIED_LOGIN_LEARN_MORE],
                      extras: {
                        queryParams: {
                          p: this.appIdentity,
                          utm_cust_domain: utmCustDomain
                        }
                      },
                      external: true
                    }))
                  )
                );
              }),
              catchError((
                (error: HttpErrorResponse) => [
                  panicActions.addPanicMessage({message: error.message}),
                  NavigateTo({
                    path: ['/panic']
                  })
                ]
              ))
            )
            : [
              userCommonActions.loginRedirect()
            ]
          ),
          catchError(((error: HttpErrorResponse) => [
              panicActions.addPanicMessage({message: error.message}),
              NavigateTo({
                path: ['/panic']
              })
            ])
          ));
      }

      return [
        userCommonActions.loginRedirect()
      ];
    })
  ));

  public customerEntitlementAuth$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.loadCustomerEntitlementAuthorize),
    switchMap(() =>
      this.customerEntitlementService.authorize$().pipe(
        this.tokenExtractor.extractJwtToken({tokenName: hubTokenName, isDefaultToken: true}),
        mergeMap((authToken: IAuthToken) => [
          hubCookieActions.updateJwt({token: authToken}),
          userActions.getCustomerEntitlementClaimsSuccess()
        ]),
        catchError(() => {
          return of(userActions.getCustomerEntitlementClaimsError());
        })
      ))
  ));

  public customerEntitlementToken$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.getCustomerEntitlementClaimsSuccess),
    concatLatestFrom(() => this.store.pipe(select(UserCommon.selectUserProfile))),
    filter(([, userProfile]) =>
      !(userProfile?.username === `org${userProfile.orgId}`)),
    switchMap(([, userProfile]) => {
      const request: IGetUnifiedCustomerIdForCOURequest = {
        customerOrganizationalUnit: {
          type: CouTypeForRequests.BF_ORG,
          id: userProfile.orgUuid
        }
      };
      return this.customerEntitlementService.getClosestUnifiedCustomerIdForCOU$(request).pipe(
        tap((unifiedCustomerResponse: IGetUnifiedCustomerIdForCOUResponse) => {
          this.gainsightService.initialize(userProfile, unifiedCustomerResponse.closestUnifiedCustomerId);
        }),
        map((unifiedCustomerResponse: IGetUnifiedCustomerIdForCOUResponse) =>
          userActions.setUnifiedCustomerId({ id: unifiedCustomerResponse.closestUnifiedCustomerId })),
        catchError(() => {
          return of(userActions.getCustomerIdError());
        })
      );
    })
  ));

  public initialTokenExtracted$ = createEffect(() => this.actions$.pipe(
    ofType<InitialTokenExtracted>(JwtTokenManagementActionTypes.InitialTokenExtracted),
    map((action: InitialTokenExtracted) => userActions.initialTokenExtracted({ token: action.token }))
  ));

  public updateJWT$ = createEffect(() => this.actions$.pipe(
    ofType(hubCookieActions.updateJwt),
    map((action) => userActions.updateJWT({ token: action.token.token }))
  ));

  private redirectToLoginPage(hasUnifiedLogin: boolean, withTargetUri = true) {
    const {location} = this.windowService.nativeWindow;
    const isInternalLoginPage = /brightfunnel.com|localhost|web.app/.exec(location.host);

    return isInternalLoginPage && !hasUnifiedLogin
      ? NavigateTo({
        routeId: RouteItemEnum.Login,
        queryParams: withTargetUri ? {[TARGET_URI]: `${location.pathname}${location.search || ''}`} : {},
      })
      : NavigateTo({
        path: [this.getEmployeeEntrance(this.envService.getEnv().HUB_AJS_URL, hasUnifiedLogin), 'login'],
        extras: withTargetUri ? {queryParams: {targetUri: location.href}} : {},
        external: true
      });
  }

  private getEmployeeEntrance(url: string, hasUnifiedLogin: boolean): string {
    const isBrightfunnelDomain = /brightfunnel.com/.exec(this.windowService.nativeWindow.location.host);
    const isNinja = /.ninja|localhost/.exec(this.windowService.nativeWindow.location.host);
    const suffix = isNinja ? '.ninja' : '.com';
    return (isBrightfunnelDomain || isNinja) && !hasUnifiedLogin
      ? url
      : `https://studio.terminusplatform${suffix}`;
  }
}

export const getPathAndQuery = (location: Location): string => {
  const currentUrl = location.href;
  const queryParamIndex = currentUrl.indexOf('?');
  const hasQueryParams = queryParamIndex > -1;
  return `${location.pathname}${hasQueryParams
      ? currentUrl.slice(queryParamIndex)
      : ''}`;
};
