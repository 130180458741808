export enum DateCohortsGroups {
  ToDate = 'toDate',
  AgoRoundDown = 'agoRoundDown',
  Ago = 'ago',
  All = 'all',
  Years = 'years',
  Quarters = 'quarters',
  LastFull = 'lastFull',
  Custom = 'custom',
}

export enum All {
  ALL_TIME = 'all',
  LABEL = '',
}

export enum ToDate {
  WEEK_TO_DATE = 'week2Date',
  MONTH_TO_DATE = 'month2Date',
  QUARTER_TO_DATE = 'quarter2Date',
  LABEL = 'To Date',
}

export enum LastFull {
  LAST_FULL_WEEK = 'lastFullWeek',
  LAST_FULL_MONTH = 'lastFullMonth',
  LAST_FULL_QUARTER = 'lastFullQuarter',
  LABEL = 'Last Full',
}

export enum AgoFromToday {
  WEEK_AGO_FROM_TODAY = 'days7',
  ONE_MONTH_AGO_FROM_TODAY = 'days30',
  NINETY_DAYS_AGO_FROM_TODAY = 'days90',
  THREE_MONTHS_AGO_FROM_TODAY = 'months3',
  SIX_MONTHS_AGO_FROM_TODAY = 'months6',
  YEAR_AGO_FROM_TODAY = 'year',
  LABEL = 'Ago From Today'
}

export enum AgoRoundDown {
  ONE_YEAR_AGO_FROM_TODAY = 'yearRoundDown',
  TWO_YEARS_AGO_FROM_TODAY = 'years2RoundDown',
  LABEL = 'Ago From Today'
}

export enum DateCohortDescription {
  WEEK_TO_DATE = 'Week to Date',
  MONTH_TO_DATE = 'Month to Date',
  QUARTER_TO_DATE = 'Quarter to Date',
  LAST_14_DAYS = 'Last 14 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_90_DAYS = 'Last 90 days',
  LAST_FULL_WEEK = 'Last Full Week',
  LAST_FULL_MONTH = 'Last Full Month',
  LAST_FULL_QUARTER = 'Last Full Quarter',
  CUSTOM_DATES = 'Custom Dates',
  FINAL_90_DAYS = 'Final 90 Days',
}
