<tsh-table-panel (download)="emitDownload()"
                 totalTitle="measurementStudio.features.attributionCampaign.table.totalTitle"
                 [noResult]="!report?.length"
                 [totalCount]="report?.length">
</tsh-table-panel>
<div class="table-container">
  <table
    ts-table
    tsSort
    class="table-hover"
    #attributionTable="tsTable"
    [multiTemplateDataRows]="true"
    [columns]="columns"
    [dataSource]="dataSource"
    (tsSortChange)="emitChangeSort($event)"
    [tsSortActive]="sortState?.active"
    [tsSortDirection]="sortState?.direction">

    <ng-container tsColumnDef="icon" alignment="center">
      <th ts-header-cell *tsHeaderCellDef></th>
      <td ts-cell *tsCellDef="let item">
        <i class="fas fa-angle-right pointer"
           *ngIf="expandedRowState"
           (click)="emitToggleExpand($event, item.key)"
           [ngClass]="{'fa-angle-right': !expandedRowState[item.key], 'fa-angle-down':expandedRowState[item.key]}">
        </i>
      </td>
      <td ts-footer-cell *tsFooterCellDef></td>
    </ng-container>

    <ng-container *ngFor="let column of columns.slice(1); let colIxd = index;">
      <ng-container [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
          <tsh-header-cell [column]="column"></tsh-header-cell>
        </th>
        <td ts-cell *tsCellDef="let item;">
          <ng-container *ngIf="column.internalLink && column.internalLink(item, filters) as linkData">
            <a [queryParamsHandling]="linkData.queryParamsHandling"
               [queryParams]="linkData.queryParams"
               [title]="item[column.name]"
               [routerLink]="linkData.link">
              <ng-container *ngIf="column.name === 'label'; else anotherLink">
                <span class="color-block" [ngStyle]="{'background-color': colors && colors[item.key]}"></span>
                {{ item[column.name] | titlecase }}
              </ng-container>
              <ng-template #anotherLink>
                {{ item[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
              </ng-template>
            </a>
          </ng-container>
          <ng-container *ngIf="!column.link && !column.internalLink">
                        <span class="percent-value">
                            ({{ item[column.name] / totals[column.name] * 100 | typedData: dataTypes.Percent : 2 : false }}
                          )
                        </span>
            {{ item[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
          </ng-container>
        </td>
        <td ts-footer-cell *tsFooterCellDef>
          <ng-container *ngIf="colIxd === 0; else noLabelTotal">
            Totals
          </ng-container>
          <ng-template #noLabelTotal>
            {{ totals[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- Custom column definition for the 'expanded' row -->
    <ng-container tsColumnDef="expanded">
      <td ts-cell
          *tsCellDef="let item"
          [attr.colspan]="columns.length"
          class="expanded-cell"
          [ngClass]="{'open': expandedRowState && expandedRowState[item.key]}">
        <ng-container
          *ngIf="expandedRowState && expandedRowState[item.key] && expandedReport && expandedReport[item.key]">
          <ms-attribution-campaign-expanded-table
            (navigate)="emitNavigate($event)"
            (changeSort)="emitChangeExpandedSortState($event, item.key)"
            (changePage)="emitChangePage($event, item.key)"
            (download)="emitDownloadExpanded(item.key, item.label)"
            [sortState]="expandedSortState[item.key]"
            [currency]="currency"
            [filters]="filters"
            [columns]="expandedColumns"
            [pagination]="expandedReportPagination[item.key]"
            [totals]="expandedReportTotals[item.key]"
            [report]="expandedReport[item.key]">
          </ms-attribution-campaign-expanded-table>
        </ng-container>
      </td>
    </ng-container>

    <tr ts-header-row *tsHeaderRowDef="attributionTable.columnNames"></tr>
    <tr ts-row *tsRowDef="let item; columns: attributionTable.columnNames;">
    </tr>
    <tr ts-row *tsRowDef="let item; columns: ['expanded']" class="expanded-row"></tr>
    <tr ts-footer-row *tsFooterRowDef="attributionTable.columnNames;"></tr>
  </table>
</div>
