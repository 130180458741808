import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import {
  ICampaignPageEvent,
  ICampaignRowEvent,
  ICampaignSortEvent,
  IGroupDetail,
  ITrendingCampaignDetails,
  ITrendingParams
} from '../../interfaces';
import { TrendingColors, TrendingDataPeriod } from '../../enums';

@Component({
  selector: 'ms-trending-group-table',
  templateUrl: './trending-group-table.component.html',
  styleUrls: ['./trending-group-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingGroupTableComponent implements OnChanges {
  @Input() public title: string;
  @Input() public data: IGroupDetail[];
  @Input() public periodTitle: string;
  @Input() public isLoading = false;
  @Input() public isCampaignTrendsReport: boolean;
  @Input() public totalCount = 0;
  @Input() public period: TrendingDataPeriod;
  @Input() public color: TrendingColors;
  @Input() public filters: ITrendingParams;
  @Input() public columns: IReportColumn[] = [];
  @Input() public campaignColumns: IReportColumn[] = [];
  @Input() public responsesColumns: IReportColumn[] = [];
  @Input() public campaignSortState: Record<string, TsSortState | null>;
  @Input() public campaignRowState: Record<string, boolean>;
  @Input() public campaignPagination: Record<string, number>;
  @Input() public campaignTotalCount: Record<string, number>;
  @Input() public campaigns: Record<string, ITrendingCampaignDetails[]>;
  @Input() public sortState: TsSortState | null;
  @Input() public rowState: Record<string, boolean>;
  @Output() public changeSort = new EventEmitter<{ state: TsSortState, period: TrendingDataPeriod }>();
  @Output() public download = new EventEmitter<TrendingDataPeriod>();
  @Output() public expandRow = new EventEmitter<{ group: IGroupDetail, period: TrendingDataPeriod }>();
  @Output() public expandCampaignRow = new EventEmitter<ICampaignRowEvent>();
  @Output() public changeCampaignSort = new EventEmitter<ICampaignSortEvent>();
  @Output() public changeCampaignPage = new EventEmitter<ICampaignPageEvent>();
  @Output() public downloadCampaign = new EventEmitter<{ group: string, period: TrendingDataPeriod }>();
  @Output() public downloadResponses = new EventEmitter<{ group: string, period: TrendingDataPeriod, id: string }>();

  public dataSource: TsTableDataSource<IGroupDetail> = new TsTableDataSource();
  public dataTypes = DataTypeEnum;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.dataSource.data = this.data;
      this.changeDetector.detectChanges();
    }

    if (changes.data || changes.columns) {
      this.changeDetector.detectChanges();
    }
  }

  emitChangeSort(sortState: TsSortState): void {
    this.changeSort.emit({
      state: sortState,
      period: this.period
    });
  }

  changeCampaignSortState(state: TsSortState, group: string): void {
    this.changeCampaignSort.emit({
      state,
      group,
      period: this.period
    });
  }

  changePage(page: number, group: string): void {
    this.changeCampaignPage.emit({
      page,
      group,
      period: this.period
    });
  }

  emitExpandCampaignRow(id: string): void {
    this.expandCampaignRow.emit({
      id,
      period: this.period
    });
  }

  emitDownloadCampaign(group: string): void {
    this.downloadCampaign.emit({
      group,
      period: this.period
    });
  }

  emitDownloadResponses(id: string, group: string): void {
    this.downloadResponses.emit({
      id,
      group,
      period: this.period
    });
  }

  emitDownload(): void {
    this.download.emit(this.period);
  }

  emitToggleExpand(event: MouseEvent, group: IGroupDetail): void {
    event.stopPropagation();
    this.expandRow.emit({
      group,
      period: this.period
    });
  }
}
