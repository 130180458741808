import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { EMPTY_GLOBAL_FILTERS_STATE } from '@shared/constants';
import { IDataFilter } from '@shared/interfaces';

@Component({
  selector: 'tsh-global-filters-data-filter-select',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFiltersDataFilterSelectComponent implements OnChanges {
  @Input() set savedDataFilters(dataFilters: IDataFilter[]) {
    this._organizationDataFilters = dataFilters.filter((dataFilter: IDataFilter) => !dataFilter.private);
    this._personalDataFilters = dataFilters.filter((dataFilter: IDataFilter) => dataFilter.private);
    if (this._organizationDataFilters) {
      this.organizationDataFiltersCopy = this._organizationDataFilters ? [...this._organizationDataFilters] : [];
    }
    if (this._personalDataFilters) {
      this.personalDataFiltersCopy = this._personalDataFilters ? [...this._personalDataFilters] : [];
    }
  }

  @Input() selectedDataFilter: IDataFilter;
  @Input() savedDataFilterSelected: boolean;
  @Input() savedDataFilterChanged: boolean;

  @Output() dataFilterChange: EventEmitter<IDataFilter> = new EventEmitter<IDataFilter>();
  @Output() deleteDataFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateDataFilter: EventEmitter<IDataFilter> = new EventEmitter<IDataFilter>();

  public readonly emptyFilter = EMPTY_GLOBAL_FILTERS_STATE;
  public orgLabel = 'Organization';
  public personalLabel = 'Personal';
  public displayDeleteConfirmation = false;
  public organizationDataFiltersCopy: IDataFilter[];
  public personalDataFiltersCopy: IDataFilter[];
  public _organizationDataFilters: IDataFilter[];
  public _personalDataFilters: IDataFilter[];
  public selectedDataFilterForm = this.formBuilder.group({
    dataFilter: new FormControl<IDataFilter[]>([this.emptyFilter]),
  });

  constructor(private formBuilder: FormBuilder) {
  }

  public compareDataFilterNames = (a: IDataFilter, b: IDataFilter) => a?.name === b?.name;

  public onFilterDataFilter(value: string): void {
    if (this._organizationDataFilters) {
      if (value) {
        this.organizationDataFiltersCopy = this.filterOptions(this._organizationDataFilters, value);
      } else {
        this.organizationDataFiltersCopy = [...this._organizationDataFilters];
      }
    }

    if (this._personalDataFilters) {
      if (value) {
        this.personalDataFiltersCopy = this.filterOptions(this._personalDataFilters, value);
      } else {
        this.personalDataFiltersCopy = [...this._personalDataFilters];
      }
    }
  }

  public handleDisplayConfirmation(): void {
    this.displayDeleteConfirmation = !this.displayDeleteConfirmation;
  }

  public handleDeleteConfirmation(listName: string): void {
    this.displayDeleteConfirmation = false;
    this.deleteDataFilter.emit(listName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDataFilter) {
      this.selectedDataFilterForm.get('dataFilter').setValue([this.selectedDataFilter]);
    }
  }

  formatDisplayFilter(filter: IDataFilter): string {
    return filter.name;
  }

  private filterOptions(options: IDataFilter[], value: string): IDataFilter[] {
    const regex = new RegExp(value, 'i');
    return [...options].filter((dataFilter: IDataFilter) => dataFilter.name.match(regex));
  }
}
