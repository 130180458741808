<section *ngFor="let category of tiles">
  <h3 class="title">{{ categoriesInfo[category.category].name | translate }}</h3>
  <tsh-alert *ngIf="disabledCategories[category.category]">
    <span class="alert-container">
      <i class="fas fa-info-circle"></i>
      <strong>{{ 'shared.dashboards.dialog.upgradeRequired' | translate }}</strong>
      <span>{{ 'shared.dashboards.dialog.requireMessage' | translate: {value: (categoriesInfo[category.category].name | translate)} }}</span>
      <ts-link *ngIf="categoriesInfo[category.category].link"
               [isExternal]="true"
               [destination]="categoriesInfo[category.category].link">
          {{ 'shared.common.learnMore' | translate }}
      </ts-link>
    </span>
  </tsh-alert>
  <ts-radio-group (selectionChange)="change($event)"
                  class="tile-type-group"
                  visualFormat="visual-centered">
    <ts-radio-button [value]="tile.type"
                     [isDisabled]="tile.disabled"
                     [attr.data-cy]="'tile-dialog-type-' + tile.type"
                     [isChecked]="tile.type === selectedType"
                     *ngFor="let tile of category.tiles">
      <span class="tile-title"
        [title]="tile.name | translate">{{ tile.name | translate }}</span>
      <span *ngIf="tile.description"
        class="tile-description"
        [title]="tile.description | translate">
        {{ tile.description | translate }}
      </span>
    </ts-radio-button>
  </ts-radio-group>
</section>
