import { InfluenceType, ModelType } from '@shared/enums';
import { ILabelValue } from '@shared/interfaces';
import { ClosedType, DateType, Period, WonType } from '@measurement-studio/enums';

export const PERIOD_TYPE_OPTIONS: ILabelValue[] = [{
    label: 'common.periodTypeOptions.month',
    value: Period.MONTH
}, {
    label: 'common.periodTypeOptions.quarter',
    value: Period.QUARTER
}];

export const INFLUENCE_TYPE_OPTIONS: ILabelValue[] = [{
    label: 'common.influenceTypeOptions.any',
    value: InfluenceType.ANY
}, {
    label: 'common.influenceTypeOptions.pre',
    value: InfluenceType.PRE
}, {
    label: 'common.influenceTypeOptions.post',
    value: InfluenceType.POST
}];

export const WonTypeOptions: ILabelValue[] = [{
    label: 'common.wonTypeOptions.won',
    value: WonType.Won
}, {
    label: 'common.wonTypeOptions.lost',
    value: WonType.Lost
}, {
    label: 'common.wonTypeOptions.any',
    value: WonType.Any
}];

export const DateTypeOptions: ILabelValue[] = [{
    label: 'common.dateTypeOptions.created',
    value: DateType.Created
}, {
    label: 'common.dateTypeOptions.closed',
    value: DateType.Closed
}];

export const ClosedTypeOptions: ILabelValue[] = [{
    label: 'common.closedTypeOptions.open',
    value: ClosedType.OPEN
}, {
    label: 'common.closedTypeOptions.closed',
    value: ClosedType.CLOSED
}, {
    label: 'common.closedTypeOptions.any',
    value: ClosedType.ANY
}];

export const VALID_INFLUENCE_TYPES = [
    InfluenceType.ANY,
    InfluenceType.POST,
    InfluenceType.PRE,
];

export const VALID_MODEL_TYPES = [
    ModelType.Sourced,
    ModelType.Last,
    ModelType.Even,
    ModelType.Custom,
];

export const VALID_PERIODS = [
    Period.MONTH,
    Period.QUARTER,
];

