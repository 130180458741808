import { GlobalFiltersKeys } from '@shared/enums';
import { IGlobalFilterFolder } from '@shared/interfaces';
import { GLOBAL_FILTER_API_KEY_MAP } from '@shared/constants';

export function mapDataFilterAsParams(filterMenu: IGlobalFilterFolder): Record<string, string[]> {
  return Object.keys(filterMenu).reduce((acc: Record<string, string[]>, globalFilterKey: GlobalFiltersKeys) => {
    const folderItems = filterMenu[globalFilterKey].itemSelect;
    Object.keys(folderItems).forEach((item: string) => {
      const folderItem = folderItems[item];
      const selectedItems = Object.keys(folderItem.itemSelect)
        .filter((filter: string) => folderItem.itemSelect[filter])
        .map((filter: string) => `${filter}:${GLOBAL_FILTER_API_KEY_MAP[globalFilterKey]}`);

      if (selectedItems.length) {
        acc[item] = acc[item] ? acc[item].concat(selectedItems) : [].concat(selectedItems);
      }
    });
    return acc;
  }, {});
}
