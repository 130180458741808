import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

import { TranslateModule } from '@ngx-translate/core';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsTabsModule } from '@terminus-lib/ui-tabs';
import { StoreModule } from '@ngrx/store';

import { SidenavUtilitiesComponent } from './containers/sidenav-utilities.component';
import { PortalDirectiveModule } from '@ui/directives/portal';
import { GlobalFiltersModule } from '@shared/features/global-filters';
import { SavedReportsModule } from '@shared/features/saved-reports';
import { reducer, utilitiesSidenavFeatureKey } from './state/utilities-sidenav.reducer';
import { GlobalSearchModule } from '@shared/features/global-search';

@NgModule({
  declarations: [SidenavUtilitiesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TsIconButtonModule,
    MatDividerModule,
    TsLoadingOverlayModule,
    TsTabsModule,
    PortalDirectiveModule,
    GlobalFiltersModule,
    SavedReportsModule,
    StoreModule.forFeature(utilitiesSidenavFeatureKey, reducer),
    GlobalSearchModule
  ],
  exports: [
    SidenavUtilitiesComponent
  ]
})
export class SidenavUtilitiesModule { }
