import { Action, createReducer, on } from '@ngrx/store';
import { IDateCohortResponse } from '@shared/interfaces';
import { dateCohortsActions } from './date-cohorts.actions';

export const dateCohortsFeatureKey = 'dateCohorts';

export interface DateCohortsState {
  loaded: boolean;
  data: IDateCohortResponse;
}

export const initialState: DateCohortsState = {
  loaded: false,
  data: {
    quarters: [],
    years: [],
    specialCohorts: []
  }
};

const dateCohortsReducer = createReducer(
  initialState,
  on(dateCohortsActions.loadDateCohortsFailure, (state) => ({
    ...state,
    loaded: false
  })),
  on(dateCohortsActions.loadDateCohortsSuccess, (state, action) => ({
    ...state,
    data: {...action.data},
    loaded: true
  })),
);

export function reducer(state = initialState, action: Action): DateCohortsState {
  return dateCohortsReducer(state, action);
}
