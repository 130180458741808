<div [tsLoadingOverlay]="(isLoading$ | async) || (downloadingCSV$ | async)">
  <ms-attribution-campaign-filters
    (apply)="applyFilters($event)"
    (setSelectedDateCohort)="setSelectedDateCohort($event)"
    [selectedDateCohort]="selectedDateCohort$ | async"
    [dateCohortOptions]="dateCohortOptions$ | async"
    [filters]="filters$ | async">
  </ms-attribution-campaign-filters>
  <hr class="report-divider">
  <ng-container *ngIf="(isLoading$ | async) === false && (report$ | async)?.length">
    <div class="metrics" *ngIf="metrics$ | async as metrics">
      <tsh-report-metric *ngFor="let metric of metrics"
                         [currency]="currency$ | async"
                         [data]="metric">
      </tsh-report-metric>
    </div>
    <ms-attribution-campaign-chart
      [colors]="campaignTypesColors$ | async"
      [isLoading]="isLoading$ | async"
      [columns]="chartColumns$ | async"
      [groups]="chartGroups$ | async"
      [data]="chartData$ | async">
    </ms-attribution-campaign-chart>
    <hr class="report-divider">
    <ms-attribution-campaign-table
      (downloadExpanded)="downloadExpandedReport($event)"
      (download)="downloadCSV()"
      (toggleExpand)="toggleExpandRow($event)"
      (navigate)="navigate($event)"
      (changeExpandedPage)="changeExpandedPage($event)"
      (changeExpandedSort)="changeExpandedSort($event)"
      (changeSort)="changeSort($event)"
      [currency]="currency$ | async"
      [colors]="campaignTypesColors$ | async"
      [filters]="filters$ | async"
      [sortState]="sortState$ | async"
      [expandedReportPagination]="expandedReportPagination$ | async"
      [expandedReportTotals]="expandedReportTotals$ | async"
      [expandedSortState]="expandedSortState$ | async"
      [columns]="columns$ | async"
      [isLoading]="isLoading$ | async"
      [expandedColumns]="expandedColumns$ | async"
      [totals]="totals$ | async"
      [expandedReport]="expandedReport$ | async"
      [expandedRowState]="expandedRowState$ | async"
      [report]="report$ | async">
    </ms-attribution-campaign-table>
  </ng-container>
  <tsh-no-report-result
    *ngIf="(isLoading$ | async) === false && (report$ | async) && (report$ | async)?.length === 0"
    [description]="noResultDescription"
    [title]="noResultTitle">
  </tsh-no-report-result>
</div>
