import { endOfDay, subDays } from 'date-fns';
import { createReducer, on } from '@ngrx/store';

import { formActions, FormState, initialFormState, storeFormState } from '@util/store-form-in-state';
import { insightFiltersActions } from './insights-filters.actions';
import { IDateCohort } from '@shared/interfaces';
import { LookbackWindow } from '../../enums/lookback-window';
import { IInsightsReportSettingsFormConfiguration } from '../../interfaces/insights-report-settings-form-configuration.interface';
import { REPORT_FILTER_SETTINGS_FORM_NAME } from '../../insights.constants';

export const InsightsFiltersFeatureKey = 'insightsFilters';

const today = endOfDay(new Date());
const ninetyDaysAgo = subDays(today, 90);
const defaultDateCohort = {
  cohort: 'days90',
  name: '90 Days',
  startDate: ninetyDaysAgo.getTime(),
  endDate: today.getTime()
};

export interface InsightsFiltersState {
  appliedDateCohort: IDateCohort;
  reportSettingsForm: FormState<IInsightsReportSettingsFormConfiguration>;
  selectedDateCohort: IDateCohort;
}

export const insightsFiltersInitialState: InsightsFiltersState = {
  appliedDateCohort: defaultDateCohort,
  reportSettingsForm: initialFormState({
    lookbackWindow: LookbackWindow.Days90,
    tactics: [],
    minimumImpressions: 1,
    adImpressionsMin: null,
    adImpressionsMax: null
  }),
  selectedDateCohort: defaultDateCohort
};

export const insightsFiltersReducer = createReducer(
  insightsFiltersInitialState,
  on(
    insightFiltersActions.changeSelectedDateCohort,
    (state, action) => ({
      ...state,
      selectedDateCohort: action.cohort || state.selectedDateCohort
    })
  ),
  on(
    insightFiltersActions.setAppliedDateCohort,
    (state, action) => ({
      ...state,
      appliedDateCohort: action.cohort || state.appliedDateCohort
    })
  ),
  on(
    insightFiltersActions.setFilters,
    (state, action) => {
      const dateCohort = action.params.cohort;
      let reportSettingsForm = state.reportSettingsForm;

      reportSettingsForm = initialFormState({
        ...reportSettingsForm.values,
        lookbackWindow: action.params.influenceWindow || LookbackWindow.Days90,
        adImpressionsMin: action.params.minImpressions,
        adImpressionsMax: action.params.maxImpressions
      });

      let bookmarkState: InsightsFiltersState = {
        ...state,
        reportSettingsForm
      };
      if (Object.keys(dateCohort).length) {
        bookmarkState = {
          ...bookmarkState,
          selectedDateCohort: dateCohort,
          appliedDateCohort: dateCohort
        };
      }
      return bookmarkState;
    }
  ),
  on(
    formActions.storeFormStateAction<typeof REPORT_FILTER_SETTINGS_FORM_NAME, IInsightsReportSettingsFormConfiguration>(),
    (state, action) => {
      switch (action.formName) {
        case REPORT_FILTER_SETTINGS_FORM_NAME: {
          return {
            ...state,
            reportSettingsForm: storeFormState(state.reportSettingsForm, action)
          };
        }
        default: {
          return state;
        }
      }
    }
  )
)
