import { IFactoryParams } from '../factory-params.interface';

export interface ISavedReport {
    reportId: number;
    name: string;
    description: string;
    url: string;
    stateName: string;
    stateParams: string;
    folderId?: number;
}

export interface ISavedReportAPI {
    report_id: number;
    name: string;
    description: string;
    url: string;
    stateName: string;
    stateParams: string;
}

export interface ICreateSavedReport {
    name: string;
    description: string;
    url: string;
    stateName: string;
    stateParams: string;
    folderId: number;
}

export interface ICreateSavedReportAPI {
    name: string;
    description: string;
    url: string;
    stateName: string;
    stateParams: string;
    folder_id: number;
}

export interface IUpdateSavedReport {
    reportId: number;
    name: string;
    description: string;
}

export interface IUpdateSavedReportAPI {
    report_id: number;
    name: string;
    description: string;
}

export interface IDeleteSavedReport {
    reportId: number;
}

export interface IDeleteSavedReportAPI {
    report_id: number;
}

export interface IReportStateInfo {
    url: string;
    stateName: string;
    stateParams: string;
}

export function savedReportFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<ISavedReport, number, boolean> = {}): ISavedReport {
    return {
        reportId: n,
        name: `Report ${n}`,
        description: `Report ${n} description`,
        url: `http://localhost:${n}`,
        stateName: `State Name ${n}`,
        stateParams: `{}`,
        ...overrides
    };
}

export function createSavedReportFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<ICreateSavedReport, number, boolean> = {}): ICreateSavedReport {
    return {
        name: `Report ${n}`,
        description: `Report ${n} description`,
        url: `http://localhost:${n}`,
        stateName: `State Name ${n}`,
        stateParams: `{}`,
        folderId: n,
        ...overrides
    };
}

export function updateSavedReportFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IUpdateSavedReport, number, boolean> = {}): IUpdateSavedReport {
    return {
        reportId: n,
        name: `Report ${n}`,
        description: `Report ${n} description`,
        ...overrides
    };
}

export function deleteSavedReportFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IDeleteSavedReport, number, boolean> = {}): IDeleteSavedReport {
    return {
        reportId: n,
        ...overrides
    };
}

export function reportStateInfoFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IReportStateInfo, number, boolean> = {}): IReportStateInfo {
    return {
        url: `http://localhost:${n}`,
        stateName: `State Name ${n}`,
        stateParams: `{}`,
        ...overrides
    };
}


export function convertUpdateReportData(data: IUpdateSavedReport): IUpdateSavedReportAPI {
    return {
        report_id: data.reportId,
        name: data.name,
        description: data.description,
    };
}

export function convertCreateReportData(data: ICreateSavedReport): ICreateSavedReportAPI {
    return {
        name: data.name,
        description: data.description,
        url: data.url,
        stateName: data.stateName,
        stateParams: data.stateParams,
        folder_id: data.folderId,
    };
}

export function convertDeleteReportData(data: IDeleteSavedReport): IDeleteSavedReportAPI {
    return {
        report_id: data.reportId,
    };
}
