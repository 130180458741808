import { Validators } from '@angular/forms';

import { RouteItemEnum, TileCategories, TileTypes, VisualizationTypes } from '@shared/enums';
import { DefaultTileInformation } from '@shared/interfaces';

// max number of row that we show in the table visualization
export const MAX_ROWS_NUMBER = 5;
export const MAX_EXTENDED_ROWS_NUMBER = 10;

// max number of tiles that we can create for each dashboard
export const TILES_LIMIT = 12;

// property name which will contain information about instance
// e.g. campaign name, type etc
export const TILE_DATA_FIELD_NAME = 'descriptor';
// property name which will contain information necessary for pareto chart
export const TILE_DATA_FIELD_PARETO = 'cumulative';
export const TILE_DATA_FIELD_PARETO_COLUMN_NAME = 'Net-New';

export const DEFAULT_ACCOUNT_TILE_INFORMATION: Record<TileTypes.AccountsList | TileTypes.EngagementInsights, DefaultTileInformation> = {
  [TileTypes.AccountsList]: {
    type: TileTypes.AccountsList,
    name: 'shared.dashboards.titles.accountsList',
    description: 'shared.dashboards.descriptions.accountsList',
    route: RouteItemEnum.AccountHub,
    category: TileCategories.Standard,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.EngagementInsights]: {
    type: TileTypes.EngagementInsights,
    name: 'shared.dashboards.titles.engagementInsights',
    description: 'shared.dashboards.descriptions.engagementInsights',
    route: RouteItemEnum.AccountHub,
    category: TileCategories.Standard,
    visualizationTypes: [VisualizationTypes.ClusteredColumn, VisualizationTypes.Line],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.ClusteredColumn
    },
    hasGlobalFilters: true,
    settingsValidators: {
      timeUnit: Validators.required
    }
  },
};

// NOTE: You have to get other properties for tile's settings from feature service
export const DEFAULT_TILE_INFORMATION: Record<TileTypes, DefaultTileInformation> = {
  [TileTypes.ScorecardTrending]: {
    type: TileTypes.ScorecardTrending,
    name: 'shared.dashboards.titles.scorecardTrending',
    description: 'shared.dashboards.descriptions.scorecardTrending',
    route: RouteItemEnum.ScorecardTrendingKpi,
    category: TileCategories.Standard,
    visualizationTypes: [
      VisualizationTypes.Line,
      VisualizationTypes.StackedArea,
      VisualizationTypes.Column,
      VisualizationTypes.StackedColumn,
      VisualizationTypes.Donut,
      VisualizationTypes.Pareto,
      VisualizationTypes.Gauge
    ],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Line
    },
    hasGlobalFilters: false,
  },
  [TileTypes.OpportunityInsights]: {
    type: TileTypes.OpportunityInsights,
    name: 'shared.dashboards.titles.opportunityInsights',
    description: 'shared.dashboards.descriptions.opportunityInsights',
    route: RouteItemEnum.OpportunityInsights,
    category: TileCategories.Standard,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.MarketingInfluence]: {
    type: TileTypes.MarketingInfluence,
    name: 'shared.dashboards.titles.marketingInfluence',
    description: 'shared.dashboards.descriptions.marketingInfluence',
    route: RouteItemEnum.MarketingImpact,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.ClusteredColumn],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.ClusteredColumn
    },
    hasGlobalFilters: true,
  },
  [TileTypes.CampaignTrends]: {
    type: TileTypes.CampaignTrends,
    name: 'shared.dashboards.titles.campaignTrends',
    description: 'shared.dashboards.descriptions.campaignTrends',
    route: RouteItemEnum.CampaignTrends,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.Line],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Line
    },
    hasGlobalFilters: true,
  },
  [TileTypes.TopCampaignTypes]: {
    type: TileTypes.TopCampaignTypes,
    name: 'shared.dashboards.titles.topCampaignTypes',
    description: 'shared.dashboards.descriptions.topCampaignTypes',
    route: RouteItemEnum.CampaignAnalyticsGroups,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.TopCampaigns]: {
    type: TileTypes.TopCampaigns,
    name: 'shared.dashboards.titles.topCampaigns',
    description: 'shared.dashboards.descriptions.topCampaigns',
    route: RouteItemEnum.CampaignAnalyticsCampaigns,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.CampaignSpecific]: {
    type: TileTypes.CampaignSpecific,
    name: 'shared.dashboards.titles.campaignSpecific',
    description: null,
    route: RouteItemEnum.CampaignSpecific,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.Line],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Line
    },
    hasGlobalFilters: true,
    settingsValidators: {
      campaignId: Validators.required
    }
  },
  [TileTypes.StagesSnapshot]: {
    type: TileTypes.StagesSnapshot,
    name: 'shared.dashboards.titles.stagesSnapshot',
    description: null,
    route: RouteItemEnum.StagesSnapshot,
    category: TileCategories.RevenueAttribution,
    visualizationTypes: [VisualizationTypes.Column],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Column
    },
    hasGlobalFilters: true,
  },
  [TileTypes.WebActivitiesChannel]: {
    type: TileTypes.WebActivitiesChannel,
    name: 'shared.dashboards.titles.webActivityList',
    description: null,
    route: RouteItemEnum.WebActivitiesChannel,
    category: TileCategories.DigitalAttribution,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.WebActivitiesChannelAssets]: {
    type: TileTypes.WebActivitiesChannelAssets,
    name: 'shared.dashboards.titles.webActivitiesChannelAssets',
    description: null,
    route: RouteItemEnum.WebActivitiesChannelAssets,
    category: TileCategories.DigitalAttribution,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: true,
  },
  [TileTypes.WebActivitiesSpecific]: {
    type: TileTypes.WebActivitiesSpecific,
    name: 'shared.dashboards.titles.webActivitiesSpecific',
    description: null,
    route: RouteItemEnum.WebActivitiesSpecific,
    category: TileCategories.DigitalAttribution,
    visualizationTypes: [VisualizationTypes.Line],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Line
    },
    hasGlobalFilters: true,
    settingsValidators: {
      campaignId: Validators.required
    }
  },
  [TileTypes.WebActivitiesTrending]: {
    type: TileTypes.WebActivitiesTrending,
    name: 'shared.dashboards.titles.webActivitiesTrending',
    description: null,
    route: RouteItemEnum.WebActivitiesTrending,
    category: TileCategories.DigitalAttribution,
    visualizationTypes: [VisualizationTypes.Line],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Line
    },
    hasGlobalFilters: true,
  },
  ...DEFAULT_ACCOUNT_TILE_INFORMATION,
  // unknown tiles types TODO: clarify these types
  [TileTypes.UngatedCampaigns]: {
    type: TileTypes.UngatedCampaigns,
    name: 'shared.dashboards.titles.ungatedCampaigns',
    description: null,
    route: null,
    category: TileCategories.Standard,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: false,
  },
  [TileTypes.VelocityCampaign]: {
    type: TileTypes.VelocityCampaign,
    name: 'shared.dashboards.titles.velocityCampaign',
    description: null,
    route: null,
    category: TileCategories.Standard,
    visualizationTypes: [VisualizationTypes.Table],
    disabled: false,
    settings: {
      visualization: VisualizationTypes.Table
    },
    hasGlobalFilters: false,
  }
};
