import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { selectDemoConfigState } from './state/demo-config.selectors';

export const applyInDemoMode = <T>(store: Store<unknown>, fn: (data: T) => T) => (source: Observable<T>) =>
  store.pipe(
    select(selectDemoConfigState),
    take(1),
    switchMap(config => source.pipe(switchMap(data => (config.enabled ? of(fn(data)) : of(data))))),
  );
