import { createFeatureSelector, createSelector } from '@ngrx/store';
import { notificationMessagesFeatureKey, NotificationMessagesState } from './notification-messages.reducer';
import { IUserMessage } from '@shared/interfaces';

export const selectNotificationMessagesState = createFeatureSelector<NotificationMessagesState>(notificationMessagesFeatureKey);

export const getNotificationMessages = createSelector(selectNotificationMessagesState,
  (state: NotificationMessagesState) => state.messages);

export const getLastMessage = createSelector(getNotificationMessages,
  (messages: IUserMessage[]): IUserMessage | undefined => {
    if(messages.length) {
      return messages[messages.length - 1];
    }
  });
