<div class="hub-modal-header">
    <h3 class="hub-modal-title">{{ 'shared.common.saveFilterAs' | translate }}</h3>
</div>

<div class="hub-modal-body">
    <ts-input
        label="Name"
        name="name"
        [isRequired]="true"
        [formControl]="form.get('name')"
    ></ts-input>

    <div class="content-sub-title">
        {{ 'shared.globalFilters.saveFilterDialog.filterAccess' | translate }}
    </div>

    <ts-radio-group
        class="radio-group"
        [formControl]="form.get('isMine')">
        <ng-container *ngFor="let item of radioItems">
            <ts-radio-button [value]="item.value">{{ item.label }}</ts-radio-button>
        </ng-container>
    </ts-radio-group>
</div>

<div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="hub-modal-footer modal-btn-group"
>
    <ts-button
        class="cancel-btn"
        theme="secondary"
        (clicked)="cancel()"
    >
        {{ 'shared.common.cancel' | translate }}
    </ts-button>
    <ts-button
        theme="default"
        [isDisabled]="form.status === INVALID_TEXT"
        (clicked)="saveFilter()"
    >
        {{ 'shared.common.save' | translate }}
    </ts-button>
</div>
