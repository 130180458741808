<label class="ts-toggle__label" [attr.for]="inputId" [class.full-width]="fullWidth" [attr.data-theme]="theme">
  <input
    class="ts-toggle__input"
    type="checkbox"
    role="switch"
    [attr.aria-checked]="isChecked.toString()"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-label]="ariaLabel"
    [attr.name]="name"
    [checked]="isChecked"
    [disabled]="isDisabled"
    [id]="inputId"
    [required]="isRequired"
    [tabIndex]="tabIndex"
    (change)="onChangeEvent($event)"
    (click)="onInputClick($event)"
    #input
  >
  <span class="ts-toggle__content"><ng-content></ng-content></span>
  <span class="ts-toggle__thumb-container"><span class="ts-toggle__thumb" [class.ts-toggle__thumb__checked]="isChecked"></span></span>
</label>
