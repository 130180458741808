import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight';
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons/faInfoSquare';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { filter, map, tap } from 'rxjs/operators';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import {
  ICreateSavedFolder,
  ICreateSavedReport,
  IDeleteSavedFolder,
  IDeleteSavedReport,
  IFilter,
  IGlobalFilter,
  ISavedReport,
  IUpdateSavedFolder,
  IUpdateSavedReport
} from '@shared/interfaces';
import * as SavedReportsStore from '@shared/features/saved-reports';
import * as HubRouterSelectors from '@hub-router';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as GLOBAL_FILTER_CONSTS from '@shared/constants';
import * as AccountListStore from '@shared/data-access/account-hub';
import { UtilitiesSidenavActions } from '../state/utilities-sidenav.actions';
import * as UtilitiesSidenavSelectors from '../state/utilities-sidenav.selectors';
import { SidenavUtilitiesState } from '../state/utilities-sidenav.reducer';
import { GlobalFiltersHeader, GlobalFiltersKeys, PortalTargets, SidenavUtilitiesTabs } from '@shared/enums';
import { UserDefinedSegment } from '@shared/data-access/segments-api';

@WithDestroy
@Component({
  selector: 'tsh-app-sidenav',
  templateUrl: './sidenav-utilities.component.html',
  styleUrls: ['./sidenav-utilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavUtilitiesComponent implements OnInit {
  public selectedTabIndex$ = this.store.pipe(
    select(UtilitiesSidenavSelectors.getActiveTabIndex),
    tap((index: SidenavUtilitiesTabs) => this.selectedTabIndex = index)
  );
  public isExtended$ = this.store.pipe(
    select(UtilitiesSidenavSelectors.getIsSidenavExtended),
    tap((extended: boolean) => this.isExtended = extended)
  );
  public savedReports$ = this.store.pipe(select(SavedReportsStore.getPermittedSavedReports));
  public isReportsLoading$ = this.store.pipe(select(SavedReportsStore.getSavedReportsIsLoading));
  public folderOptions$ = this.store.pipe(select(SavedReportsStore.getFolderOptions));
  public stateInformation$ = this.store.pipe(select(HubRouterSelectors.getRouterInfoForSavableReport));
  public isHiddenGlobalFilters$ = this.store.pipe(select(UtilitiesSidenavSelectors.routesWithNoAccessToGlobalFilters));
  public appliedFiltersNumber$ = this.store.pipe(select(GlobalFiltersStore.getAppliedFiltersNumber));
  public globalFilters$ = this.store.pipe(select(GlobalFiltersStore.getGlobalFiltersData));
  public selectedAccountLists$ = this.store.pipe(select(GlobalFiltersStore.getAppliedAccountListsForInfoPanel));
  public routeWithInfoPanel$ = this.store.pipe(select(UtilitiesSidenavSelectors.routesWithInfoPanel));
  public selectedDataFilter$ = this.store.pipe(
    select(GlobalFiltersStore.getAppliedFilters),
    map((appliedFilters: IFilter) => {
      const appliedKeys = this.filterEmptyFilterKeys(appliedFilters);
      return {
        filterNames: appliedKeys,
        filters: appliedFilters
      };
    })
  );
  public segments: UserDefinedSegment[] = [];
  public segments$ = this.store.pipe(select(GlobalFiltersStore.getSegments), untilComponentDestroyed(this)).subscribe(segments => this.segments = segments);

  public readonly titleMap: Record<GlobalFiltersKeys, GlobalFiltersHeader> = GLOBAL_FILTER_CONSTS.GLOBAL_FILTER_HEADER_KEY_MAP;
  public readonly portalTargets = PortalTargets;
  public readonly sidenavUtilitiesTabs = SidenavUtilitiesTabs;
  public readonly globalFiltersKeys = GlobalFiltersKeys;
  public readonly exitIcon = faArrowToRight;
  public readonly timesIcon = faTimes;
  public readonly fileIcon = faFile;
  public readonly filterIcon = faFilter;
  public readonly faInfoCircle = faInfoCircle;
  public readonly faInfoSquare = faInfoSquare;
  public readonly faSearch = faSearch;
  public showAppliedFilters = false;
  private selectedTabIndex: SidenavUtilitiesTabs;
  private isExtended: boolean;

  constructor(private store: Store<SidenavUtilitiesState>) { }

  ngOnInit(): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.getSavedReports());
    this.store.dispatch(AccountListStore.loadAccountLists());
    this.routeWithInfoPanel$.pipe(
      filter((isRouteWithInfoPanel: boolean) => !!isRouteWithInfoPanel),
      untilComponentDestroyed(this),
    ).subscribe(() => {
      this.closeSidenav();
    });
    this.isHiddenGlobalFilters$.pipe(
      filter((isScorecard: boolean) => isScorecard),
      untilComponentDestroyed(this),
    ).subscribe(() => {
      this.closeSidenav();
    });
  }

  changeSidenavUtilitiesState(state: boolean): void {
    // false to close or true to open sidenav
    this.store.dispatch(UtilitiesSidenavActions.UpdateExtendedState({ payload: state }));
  }

  selectTab(indexTab: number): void {
    if (this.selectedTabIndex !== indexTab || !this.isExtended) {
      this.changeSidenavUtilitiesState(true);
      this.showAppliedFilters = false;
      this.store.dispatch(UtilitiesSidenavActions.UpdateActiveTab({ payload: indexTab }));
    } else {
      this.changeSidenavUtilitiesState(false);
    }
  }

  closeSidenav(): void {
    this.changeSidenavUtilitiesState(false);
    this.store.dispatch(GlobalFiltersStore.globalFiltersActions.filterGlobalFilters({ data: '' }));
  }

  togglePopover(event: MouseEvent): void {
    event.stopPropagation();
    this.showAppliedFilters = !this.showAppliedFilters;
  }

  filterEmptyFilterKeys(appliedFilters: IFilter): string[] {
    let notEmptyKeys = [];
    for (const key in this.titleMap) {
      // eslint-disable-next-line no-prototype-builtins
      if (appliedFilters.hasOwnProperty(key)) {
        notEmptyKeys = this.checkFilterInAppliedFilters(notEmptyKeys, key, appliedFilters);
      }
    }
    return notEmptyKeys;
  }

  checkFilterInAppliedFilters(notEmptyKeys: string[], key: string, appliedFilters: IFilter): string[] {
    for (const filterKey in appliedFilters[key]) {
      if (!notEmptyKeys.includes(key) &&
        // eslint-disable-next-line no-prototype-builtins
        appliedFilters[key].hasOwnProperty(filterKey) &&
        appliedFilters[key][filterKey].length) {
        notEmptyKeys.push(key);
      }
    }
    return notEmptyKeys;
  }

  getFilterKeys(filters: Record<string, string[]>): string[] {
    return Object.keys(filters);
  }

  getFilterGroupName(filterGroup: string, filters: IGlobalFilter[]): string {
    return filters.filter(filterItem => filterItem.number === filterGroup)[0].name;
  }

  updateFolder(event: IUpdateSavedFolder): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.updateSavableFolder({data: event}));
  }

  createFolder(event: ICreateSavedFolder): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.createSavableFolder({data: event}));
  }

  deleteFolder(event: IDeleteSavedFolder): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.deleteSavableFolder({data: event}));
  }

  deleteReport(event: IDeleteSavedReport): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.deleteSavableReport({data: event}));
  }

  updateReport(event: IUpdateSavedReport): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.updateSavableReport({data: event}));
  }

  createReport(event: ICreateSavedReport): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.createSavableReport({data: event}));
  }

  showReport(report: ISavedReport): void {
    this.store.dispatch(SavedReportsStore.savedReportsActions.showSavedReports({data: report}));
  }

  getDisplayName(titleKey: string, data: string) {
    if (titleKey.toLowerCase() === GlobalFiltersKeys.Segments && this.segments) {
      const segment = this.segments.find(segment => segment.lqsSharedQueryId === data);
      if(segment) return segment.displayName;
    }

    return data;
  }
}


