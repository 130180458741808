import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { getHttpParams } from '@util/http';
import { HttpClientBase } from '@shared/http-client-base';
import { ITrendingDetails, ITrendingGroupDetails, ITrendingGroupDetailsParams, ITrendingParams, ITrendingTotal } from '../interfaces';
import { RouteItemEnum } from '@shared/enums';
import { IAppliedGlobalFiltersAsParams, IExportToken } from '@shared/interfaces';

export enum CampaignTrendsURLs {
  CampaignTrendsTotal = '/api/v1/campaigns_trending_total',
  CampaignTrendsGroupDetails = '/api/v1/campaigns_trending_group_details',
  CampaignTrendsDetails = '/api/v1/campaigns_trending_details'
}

export enum WebActivitiesTrendingURLs {
  WebActivityTotal = '/api/v1/channels_trending_total',
  WebActivityGroupDetails = '/api/v1/channels_trending_group_details',
  WebActivityDetails = '/api/v1/channels_trending_details',
}
export enum ExportsTrendingURLs {
  ExportChannelsUrl = '/export/channelsTrendingDetails',
  ExportCampaignsUrl = '/export/campaignsTrendingDetails',
}

@Injectable({providedIn: 'root'})
export class TrendingSource extends HttpClientBase {
  getTotal$(params: ITrendingParams, routeId: RouteItemEnum): Observable<ITrendingTotal> {
    const endpoint = routeId === RouteItemEnum.CampaignTrends
      ? this.endpoint(CampaignTrendsURLs.CampaignTrendsTotal)
      : this.endpoint(WebActivitiesTrendingURLs.WebActivityTotal);

    return this.httpClient.get<ITrendingTotal>(endpoint, {
      params: getHttpParams(params)
    });
  }

  downloadTrendingCsv$(filters: ITrendingGroupDetailsParams, globalFilters: IAppliedGlobalFiltersAsParams,
                       token: IExportToken, isCampaignTrends: boolean): Observable<string> {
    const endpoint = isCampaignTrends ?
      this.endpoint(ExportsTrendingURLs.ExportCampaignsUrl) :
      this.endpoint(ExportsTrendingURLs.ExportChannelsUrl);

    return this.httpClient.get(endpoint, {
      params: getHttpParams({ ...filters, ...globalFilters, ...token }),
      responseType: 'text'
    });
  }

  getGroupDetails$(params: ITrendingGroupDetailsParams, routeId: RouteItemEnum): Observable<ITrendingGroupDetails> {
    const endpoint = routeId === RouteItemEnum.CampaignTrends
      ? this.endpoint(CampaignTrendsURLs.CampaignTrendsGroupDetails)
      : this.endpoint(WebActivitiesTrendingURLs.WebActivityGroupDetails);

    return this.httpClient.get<ITrendingGroupDetails>(endpoint, {
      params: getHttpParams(params)
    });
  }

  getDetail$(params: ITrendingParams, routeId: RouteItemEnum): Observable<ITrendingDetails> {
    const endpoint = routeId === RouteItemEnum.CampaignTrends
      ? this.endpoint(CampaignTrendsURLs.CampaignTrendsDetails)
      : this.endpoint(WebActivitiesTrendingURLs.WebActivityDetails);

    return this.httpClient.get<ITrendingDetails>(endpoint, {
      params: getHttpParams(params)
    });
  }
}
