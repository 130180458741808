import { RevenueType } from './enums/attribution-campaign.enum';
import { ILabelValue } from '@shared/interfaces';
import { PER_PAGE } from '@shared/constants';

export const revenueTypeOptions: ILabelValue[] = [{
  label: 'feature.common.typeOptions.revenue',
  value: RevenueType.Revenue
}, {
  label: 'feature.common.typeOptions.pipeline',
  value: RevenueType.Pipeline
}];

export const DEFAULT_PER_PAGE = PER_PAGE;
export const DEFAULT_PAGE = 1;
