import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { iif, Observable, of } from 'rxjs';

import { IDashboardTile, IDateCohort } from '@shared/interfaces';
import * as DateCohortsStore from '@date-cohorts';

@Pipe({name: 'tileSubtitle'})
export class TileSubtitlePipe implements PipeTransform {

  constructor(private translateService: TranslateService,
              private store: Store<unknown>) {
  }

  transform(tile: IDashboardTile, metricLabel: string): Observable<string> {
    if (!tile) {
      return of('');
    }

    const cohort$ = this.getCohort$(tile.settings.cohort);

    return this.getSubtitle$(metricLabel, cohort$);
  }

  private getSubtitle$(labelKey: string | null, cohort$: Observable<string | null>): Observable<string> {
    return (labelKey ? this.translateService.get(labelKey) : of(null)).pipe(
      withLatestFrom(cohort$),
      map(([metric, cohortName]: [string | null, string | null]) => this.assembleSubtitle(metric, cohortName)),
    );
  }

  private getCohort$(cohort: string | IDateCohort | null): Observable<string | null> {
    if (!cohort) {
      return of(null);
    }

    return iif(() => typeof cohort === 'string',
      this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(cohort as string))),
      of(cohort as IDateCohort)
    ).pipe(
      map(dateCohort => dateCohort?.name || this.translateService.instant('shared.dateCohorts.customRange'))
    );
  }

  private assembleSubtitle(metric: string | null, cohortName: string | null): string {
    switch (true) {
      case !!(cohortName && metric):
        return `${metric}, ${cohortName}`;
      case !cohortName && !!metric:
        return metric;
      case !!cohortName && !metric:
        return cohortName;
    }
  }
}

@NgModule({
  declarations: [TileSubtitlePipe],
  exports: [TileSubtitlePipe],
  imports: [CommonModule]
})
export class TileSubtitlePipeModule {
}
