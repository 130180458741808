import { InfluencesKeys } from '@measurement-studio/enums';

export const OpportunityInfluences: Record<InfluencesKeys, { field: string; description: string; }> = {
  [InfluencesKeys.SourceCampaignId]: {
    field: 'campaignName',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.sourceCampaignId'
  },
  [InfluencesKeys.SourceCampaignGroup]: {
    field: 'sourceCampaignGroup',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.sourceCampaignGroup'
  },
  [InfluencesKeys.InfluencedCampaignId]: {
    field: 'campaignName',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.influencedCampaignId'
  },
  [InfluencesKeys.InfluencedCampaignGroup]: {
    field: 'influencedCampaignGroup',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.influencedCampaignGroup'
  },
  [InfluencesKeys.LastCampaignId]: {
    field: 'campaignName',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.lastCampaignId'
  },
  [InfluencesKeys.LastCampaignGroup]: {
    field: 'lastCampaignGroup',
    description: 'measurementStudio.features.opportunities.opportunityInfluences.lastCampaignGroup'
  }
};

export enum TileSettingsOpportunitiesFields {
  ClosedType = 'closedType',
  DateType = 'dateType',
  Model = 'model',
  WonType = 'wonType',
}
