import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TsInputModule } from '@terminus-lib/ui-input';
import { TranslateModule } from '@ngx-translate/core';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsMenuModule } from '@terminus-lib/ui-menu';

import { ReportFilterInputComponent } from './report-filter-input.component';

@NgModule({
  declarations: [ReportFilterInputComponent],
  exports: [ReportFilterInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TsInputModule,
    TranslateModule,
    TsMenuModule,
    TsButtonModule
  ]
})
export class ReportFilterInputModule {
}
