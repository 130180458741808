import { Injectable } from '@angular/core';

import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { Observable, of } from 'rxjs';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { IDataFilter, IFilterDeleteRequest, IFilterSaveRequest, IGlobalFilters } from '@shared/interfaces';
import { GlobalFiltersSource } from '../source/global-filters.source';

@Injectable({providedIn: 'root'})
export class GlobalFiltersService {

  constructor(
    public source: GlobalFiltersSource,
    public retry: RetryWithEscalation,
    envService: EnvService,
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getGlobalFiltersData$(): Observable<IGlobalFilters> {
    return regenerateOnRetry(() => this.source.getGlobalFiltersData$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSavedDataFilters$(): Observable<IDataFilter[]> {
    return regenerateOnRetry(() => this.source.getSavedDataFilters$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateSavedDataFilter$(params: IFilterSaveRequest): Observable<string> {
    return regenerateOnRetry(() => this.source.updateSavedDataFilter$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteSavedDataFilter$(params: IFilterDeleteRequest): Observable<string> {
    return regenerateOnRetry(() => this.source.deleteSavedDataFilter$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
