import { createFeatureSelector, createSelector } from '@ngrx/store';

import { catalogsFeatureKey, CatalogsState } from './catalogs.reducer';
import { ILabelValue } from '@shared/interfaces';
import { toCapitalize } from '@util/helpers';
import { IWebActivitiesTypes, IWfStage, WfStageTypes } from '../interfaces';

export const selectCatalogsState = createFeatureSelector<CatalogsState>(catalogsFeatureKey);

export const getWebActivitiesTypes = createSelector(selectCatalogsState, (state: CatalogsState) => state.webActivitiesTypes);

const mapToLabelValues = (types: string[]): ILabelValue[] => (types || []).map(type => ({label: toCapitalize(type), value: type}));

export const getSourceOptions = createSelector(getWebActivitiesTypes, (state: IWebActivitiesTypes) => mapToLabelValues(state?.sources));
export const getMediumsOptions = createSelector(getWebActivitiesTypes, (state: IWebActivitiesTypes) => mapToLabelValues(state?.mediums));


export const getWfStages = createSelector(selectCatalogsState, (state: CatalogsState) => state.stages || []);

export const getIsWfStagesLoaded = createSelector(selectCatalogsState, (state: CatalogsState) => state.stagesLoaded);

export const getWfStagesInCampPerf =
  createSelector(getWfStages, (stages: IWfStage[]) => {
    return stages.filter(stage => stage.inCampPerf);
  });

export const getWfStagesForMetricOptions =
  createSelector(getWfStagesInCampPerf, (stages: IWfStage[]): ILabelValue[] => {
    return stages.map(stage => {
      return {
        value: `${stage.appliesTo}:${stage.stage}`,
        label: stage.stage
      };
    });
  });

export const getWfStagesForParams =
  createSelector(getWfStagesInCampPerf, (stages: IWfStage[]): string[] => {
    return stages.map(stage => (stage.appliesTo === WfStageTypes.Lead ? 'l::' : 'o::') + stage.stage);
  });

export const getProgressionByQuarterStages = createSelector(
  getWfStages,
  (stages: IWfStage[]): IWfStage[] => {
    // logic is taken from hub-ajs
    return stages?.filter(s => {
      return s.type != null && s.type.indexOf('funnel') > -1;
    }) || [];
  }
);
