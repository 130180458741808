import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FormState, MultipleFormState, OutputFormState, OutputMultipleFormState } from './store-form-in-state.interfaces';
import { formActions } from './store-form-in-state.actions';

interface RequiredComponent {
  store: Store<any>;
  ngOnDestroy: () => any;
}

export class FormStateToStoreSync<C, T> {
  emittedFormStateVersion = -1;
  lastReceivedFormStateVersion = -1;

  constructor(private component: RequiredComponent, private formName: T) {
  }

  createObservableOfStore(inp: Observable<MultipleFormState<C> | FormState<C>>) {
    return inp.pipe(
      tap((s) => {
        if (s.version < this.lastReceivedFormStateVersion) {
          this.emittedFormStateVersion = s.version - 1;
        }
        this.lastReceivedFormStateVersion = s.version;
      }),
      filter((s) => s.version > this.emittedFormStateVersion),
      tap((s) => this.emittedFormStateVersion = s.version)
    );
  }

  emitMultipleFormChangesToStore() {
    return (input: OutputMultipleFormState<C>) => {
      this.component.store.dispatch(
        formActions.storeMultipleFormStateAction()({
          formName: this.formName,
          formData: input,
          version: ++this.emittedFormStateVersion
        })
      );
    };
  }

  emitFormChangesToStore() {
    return (input: OutputFormState<C>) => {
      this.component.store.dispatch(
        formActions.storeFormStateAction()({
          formName: this.formName,
          formData: input,
          version: ++this.emittedFormStateVersion
        })
      );
    };
  }
}
