<div>
  <ng-container *ngIf="data?.length">
    <div class="table-container">
      <table ts-table
             tsSort
             #table="tsTable"
             [multiTemplateDataRows]="true"
             [columns]="columns"
             [tsSortActive]="sortState?.active"
             [tsSortDirection]="sortState?.direction"
             (tsSortChange)="onSortChange($event)"
             [dataSource]="dataSource">
        <ng-container *ngFor="let column of columns">
          <ng-container [tsColumnDef]="column.name"
                        [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
            <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
              <tsh-header-cell [column]="column"></tsh-header-cell>
            </th>
            <td ts-cell *tsCellDef="let item">
              {{ item.accountName }}
            </td>
          </ng-container>
        </ng-container>
        <tr ts-header-row *tsHeaderRowDef="table.columnNames; sticky: true"></tr>
        <tr ts-row *tsRowDef="let item; columns: table.columnNames"></tr>
      </table>
    </div>
    <tsh-pagination
      [currentPageIndex]="pagination?.page || 1"
      [perPageOption]="pagination?.perPage || 10"
      [totalRecords]="totalCount"
      (pageSelect)="emitPageChange($event)">
    </tsh-pagination>
  </ng-container>
  <tsh-no-report-result
    title="measurementStudio.features.advertisingInsights.informationPanel.table.noResultTitle"
    description="measurementStudio.features.advertisingInsights.informationPanel.table.noResultDescription"
    *ngIf="!data?.length">
  </tsh-no-report-result>
</div>
