import { createSelector } from '@ngrx/store';

import { campaignsFeatureKey, ICampaignsState } from './campaigns.reducer';
import { AnalyticsState, selectAnalyticsState } from '..';
import { gerRecordIds, isStateLoading } from '../../helpers/store.helper';
import { ICampaignsTableItem, INestedTableState } from '../../interfaces';
import { defaultPager, defaultSorter } from '../../data';
import { IAnalyticsNested } from '@measurement-studio/interfaces';

export const getCampaigns = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[campaignsFeatureKey]);

export const getReportData = createSelector(getCampaigns,
  (state: ICampaignsState): Partial<ICampaignsTableItem>[] => state.data);

export const getSelectedTags = createSelector(getCampaigns,
  (state: ICampaignsState): string[] => state.selectedTags);

export const getCampaignsTotalResults = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => campaigns.totalResults);

export const getCampaignsRecordIds = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => gerRecordIds(campaigns.data));

export const getCampaignsIsLoading = createSelector(getCampaigns, isStateLoading);

export const getCampaignsIsDriverLoading = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => campaigns.isDriverLoading);

export const getSelectedCampaignType = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => campaigns.selectedCampaignType);

export const getHasWebActivitiesIds = createSelector(getCampaigns,
  (campaigns: ICampaignsState): string[] =>
    campaigns.hasWebActivity.map(item => item.groupId));

export const getNestedTableState = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => campaigns.nestedTableState);

export const getCampaignsUsedParams = createSelector(getCampaigns,
  (campaigns: ICampaignsState) => campaigns.usedParamsInDriver);

export const getNestedTableVisibility = createSelector(getNestedTableState,
  (nestedState: INestedTableState): Record<string, boolean> => nestedState.visible);

export const getNestedCampaigns = createSelector(getCampaigns,
  (state: ICampaignsState): IAnalyticsNested<ICampaignsTableItem> => state.nested);

export const getNestedData = createSelector(getNestedCampaigns,
  (state: IAnalyticsNested<ICampaignsTableItem>) => state.data);

export const getNestedDataById = createSelector(getNestedData,
  (state: Record<string, Partial<ICampaignsTableItem>[]>, id: string) => state[id]);

export const getNestedSorter = createSelector(getNestedCampaigns,
  (state: IAnalyticsNested<ICampaignsTableItem>, id: string) => state.sorter[id] || defaultSorter);

export const getNestedPager = createSelector(getNestedCampaigns,
  (state: IAnalyticsNested<ICampaignsTableItem>, id: string) => state.pager[id] || defaultPager);

export const getNestedRecordIds = createSelector(getNestedCampaigns,
  (state: IAnalyticsNested<ICampaignsTableItem>, id: string) => gerRecordIds(state.data[id]));
