import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsInputModule } from '@terminus-lib/ui-input';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TypedDataModule } from '@ui/pipes/typed-data';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { AttributionRoutingModule } from './attribution-routing.module';
import { AttributionComponent } from './containers/attribution.component';
import { CampaignAttributionEffects } from './state/attribution.effects';
import { amChartsFactory } from '@util/amcharts';
import { ReportTabsModule } from '@ui/components/report-tabs';
import { CampaignChartComponent } from './components/campaign-chart/campaign-chart.component';
import * as reducer from './state/attribution.reducer';
import { ReportMetricModule } from '@ui/components/report-metric';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { SelectorWithTriggerModule } from '@ui/components/selector-with-trigger';
import { CampaignAttributionFiltersComponent } from './components/campaign-filters/campaign-filters.component';
import { PortalDirectiveModule } from '@ui/directives/portal';

@NgModule({
  declarations: [
    AttributionComponent,
    CampaignAttributionFiltersComponent,
    CampaignChartComponent
  ],
  imports: [
    CommonModule,
    AttributionRoutingModule,
    RouterModule,
    TsLoadingOverlayModule,
    DateCohortsModule,
    ReportSettingPanelModule,
    ReportFilterSelectorModule,
    TsMenuModule,
    StoreModule.forFeature(reducer.campaignSpecificFeatureKey, reducer.reducer),
    EffectsModule.forFeature([CampaignAttributionEffects]),
    TranslateModule,
    MatRadioModule,
    TsButtonModule,
    ReactiveFormsModule,
    TsInputModule,
    TypedDataModule,
    ReportTabsModule,
    ReportMetricModule,
    SelectorWithTriggerModule,
    TsChartModule,
    NoReportResultModule,
    PortalDirectiveModule
  ],
  providers: [{
    provide: TS_AMCHARTS_TOKEN,
    useFactory: amChartsFactory,
  }]
})
export class AttributionModule {
}
