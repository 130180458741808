<ng-container *ngIf="filters">
  <tsh-date-cohort *ngIf="selectedDateCohort"
                   data-cy="stage-influence-cohort"
                   (apply)="applyDateCohort($event)"
                   (dateRangeFilterChange)="changeDateCohort($event)"
                   [selectedDateRange]="selectedDateCohort"
                   [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>
  <tsh-report-setting-panel reportName="measurementStudio.features.stageInfluence.reportName"
                            data-cy="stage-influence-settings"
                            [isDisabled]="isLoading"
                            (apply)="apply()">
    <tsh-report-filter-selector title="measurementStudio.features.stageInfluence.filters.campaignsThat"
                                data-cy="stage-influence-dataSet"
                                [options]="datasetTypeOptions"
                                [field]="filterForm.get('dataSet')">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="measurementStudio.features.stageInfluence.filters.attributionModel"
                                data-cy="stage-influence-model"
                                [options]="modelTypeOptions"
                                [field]="filterForm.get('model')">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector title="measurementStudio.features.stageInfluence.filters.stages"
                                data-cy="stage-influence-columnType"
                                [options]="columnTypeOptions"
                                [field]="filterForm.get('columnType')">
    </tsh-report-filter-selector>
  </tsh-report-setting-panel>
</ng-container>
