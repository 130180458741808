import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as actions from './demo-config.actions';
import * as selectors from './demo-config.selectors';
import { DemoConfigState, demoConfigFeatureKey } from './demo-config.reducer';
import { LocalStorageService } from '@shared/data-access/local-storage';

@Injectable()
export class DemoConfigEffects {

  constructor(
    private actions$: Actions,
    private store: Store<DemoConfigState>,
    private localStorageService: LocalStorageService,
  ) {}

  public onChangeDemoConfigState$ = createEffect(() => this.actions$.pipe(
    ofType(
      actions.toggleDemoMode,
      actions.toggleSlowdown,
      actions.slowdownSecondsChanged,
      actions.toggleDebug
    ),
    concatLatestFrom(() => this.store.pipe(select(selectors.selectDemoConfigState))),
    tap(([_, state]: [null, DemoConfigState]) => {
      this.localStorageService.setItem(demoConfigFeatureKey, JSON.stringify(state));
    })
  ), { dispatch: false });
}
