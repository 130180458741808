import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { ILabelValue } from '@shared/interfaces';

@Component({
  selector: 'tsh-selector-with-trigger',
  templateUrl: './selector-with-trigger.component.html',
  styleUrls: ['./selector-with-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorWithTriggerComponent<T = string> implements OnChanges {
  @Input() options: ILabelValue<T>[] = [];
  @Input() selectedOption: ILabelValue<T>;
  @Input() triggerTitle = 'shared.ui.selectorWithTrigger.title';
  @Output() selected = new EventEmitter<ILabelValue<T>>();

  public control = new FormControl(null, Validators.required);

  ngOnChanges(changes: SimpleChanges) {
    // update form control after changing selected option
    if (changes.selectedOption || changes.options) {
      const selected = this.options.find(option => option.value === this.selectedOption?.value);
      this.control.setValue(selected);
    }
  }

  emitSelect(event: MatSelectChange): void {
    this.selected.emit(event.value);
  }
}
