import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataFilter } from '@shared/interfaces';

@Component({
  selector: 'tsh-gf-save-filter-dialog',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFiltersSaveFilterDialogComponent implements OnInit {
  public INVALID_TEXT = 'INVALID';
  public form: FormGroup;

  public radioItems = [
    {value: true, label: 'Private'},
    {value: false, label: 'Public'},
  ];

  @Input() displayModal: boolean;
  @Input() dataFilter: IDataFilter;

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitSave: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateAndSaveAppliedDataFilter: EventEmitter<IDataFilter> = new EventEmitter<IDataFilter>();

  public saveFilter(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public modelFormatter = (item: { value: boolean, label: string }) => item.value;
  public uiSubFormatter = (item: { value: boolean, label: string }) => item.label;
  public uiFormatter = (item: { value: boolean, label: string }) => item.label;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [
        '',
        Validators.required,
      ],
      isMine: [
        null,
        Validators.required,
      ],
    });
  }

  constructor(
    public dialogRef: MatDialogRef<GlobalFiltersSaveFilterDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IDataFilter,
  ) {
  }
}
