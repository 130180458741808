import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TsButtonModule } from '@terminus-lib/ui-button';

import { TileConfigurationSteps } from '@shared/interfaces';

@Component({
  selector: 'tsh-steps-navigation',
  templateUrl: './steps-navigation.component.html',
  styleUrls: ['./steps-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsNavigationComponent implements OnInit {
  @Input() public step: TileConfigurationSteps;
  @Output() public back: EventEmitter<TileConfigurationSteps> = new EventEmitter<TileConfigurationSteps>();

  public readonly steps = TileConfigurationSteps;

  private _initialStep: TileConfigurationSteps;

  get initialStep(): TileConfigurationSteps {
    return this._initialStep;
  }

  ngOnInit(): void {
    this._initialStep = this.step;
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TsButtonModule,
  ],
  declarations: [StepsNavigationComponent],
  exports: [StepsNavigationComponent],
})
export class StepsNavigationModule {
}
