<tsh-table-panel (download)="emitDownload()"
                 (changeColumns)="emitChangeColumns($event)"
                 [showColumns]="true"
                 [columns]="allColumns"
                 [totalTitle]="getTotalTitle | memoize: totalCount: filters?.period"
                 [totalCount]="totalCount">
</tsh-table-panel>
<div class="table-container">
  <table ts-table
         tsSort
         #marketingTable="tsTable"
         class="marketing-table"
         [columns]="columns"
         [dataSource]="dataSource"
         (tsSortChange)="changeSort($event)"
         [tsSortActive]="sortState?.active"
         [tsSortDirection]="sortState?.direction">
    <ng-container *ngFor="let column of columns; let colIxd = index;">
      <ng-container [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== 'text' ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
          <tsh-header-cell [column]="column"></tsh-header-cell>
        </th>
        <td ts-cell *tsCellDef="let row; let rowIdx = index;">
          <a *ngIf="column.internalLink && column.internalLink(row, filters) as linkData; else noLink"
             title="Navigate to Opportunity Insights"
             [queryParamsHandling]="linkData.queryParamsHandling"
             [queryParams]="linkData.queryParams"
             [routerLink]="linkData.link">
            {{ row[column.name] | typedData : column.dataType : column.decimal : false : currency }}
          </a>
          <ng-template #noLink>
            {{ row[column.name] | typedData : column.dataType : column.decimal : false : currency }}
          </ng-template>
        </td>
        <td ts-footer-cell *tsFooterCellDef>
          <ng-container *ngIf="colIxd === 0; else totalValue">
            Totals
          </ng-container>
          <ng-template #totalValue>
            {{ totals[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
          </ng-template>
        </td>
      </ng-container>
    </ng-container>
    <tr ts-header-row *tsHeaderRowDef="marketingTable.columnNames; sticky: true;"></tr>
    <tr ts-row *tsRowDef="let row; columns: marketingTable.columnNames; index as i;"
        [attr.data-cy]="'marketing-influence-row-' + i"></tr>
    <tr ts-footer-row *tsFooterRowDef="marketingTable.columnNames;"></tr>
  </table>
</div>

<tsh-pagination [currentPageIndex]="page || 1"
                [totalRecords]="totalCount"
                [paginatorSummary]="getTotalTitle | memoize: totalCount: filters?.period"
                (pageSelect)="emitPageChange($event)">
</tsh-pagination>
