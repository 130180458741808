import { add, isMonday, isWithinInterval, startOfWeek } from 'date-fns';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IDiffLabel, ILabelValue } from '@shared/interfaces';
import { FrequencyType } from '@measurement-studio/enums';
import { compare } from '@util/helpers';
import { DiffLabel, SortDirections } from '@shared/enums';
import { PER_PAGE } from '@shared/constants';
import { ITrendingCampaignDetails, ITrendingTotal } from '../interfaces';
import { getDatesBasedOnFrequency } from '@measurement-studio/util/helpers';

// Get Frequency Types based on cohort type
export function getFrequencyTypes(cohort: string): ILabelValue[] {
  const options: ILabelValue[] = [{
    label: 'feature.sharedTrending.filters.intervalOptions.auto',
    value: FrequencyType.Auto
  }];

  if (!cohort) {
    return options;
  }

  const cohortString = cohort.toLowerCase();
  const days = {label: 'feature.sharedTrending.filters.intervalOptions.day', value: FrequencyType.Days};
  const weeks = {label: 'feature.sharedTrending.filters.intervalOptions.week', value: FrequencyType.Week};
  const months = {label: 'feature.sharedTrending.filters.intervalOptions.month', value: FrequencyType.Month};

  if (cohortString.includes('day')) {
    options.push(days);
  } else if (cohortString.includes('week')) {
    options.push(days);
  } else if (cohortString.includes('month')) {
    options.push(weeks);
    options.push(months);
  } else if (cohortString.includes('quarter') || RegExp(/^q\d+/).test(cohortString)) {
    options.push(weeks);
    options.push(months);
  } else if (cohortString.includes('year') || cohortString.includes('fy') || cohortString == 'custom') {
    options.push(months);
  }

  return options;
}

// NOTE: This functionality is from hub-ajs
// campaignPerformanceTrend-srvc.js convertDate
export function getConvertedDates(total: ITrendingTotal): Date[] {
  // We are now on a Monday-Monday cadence
  // so the first set of dates will be the times.start to the nearest Monday,
  // and then weekly/monthly/daily after that
  if (!total?.times) {
    return [];
  }

  const startDate = new Date(total.times.start);
  const endDate = new Date(total.times.end);
  const today = new Date();
  const firstMonday = isMonday(startDate)
    ? startDate // if start date is monday then use it
    : startOfWeek(add(startDate, {weeks: 1}), {weekStartsOn: 1}); // otherwise get nearest monday
  const isTodayInInterval = isWithinInterval(today, {
    start: startDate,
    end: endDate
  });
  let dates;

  // Check if today is inside the date interval of the cohort selected via start and end times
  if (isTodayInInterval) {
    // If it is, then have the end date be today
    dates = getDatesBasedOnFrequency(firstMonday, today, total.frequency);
    dates.push(today); // make the last frequency date today
  } else {
    // otherwise, have the end date be the end date of the cohort selected
    dates = getDatesBasedOnFrequency(firstMonday, endDate, total.frequency);
  }

  return dates;
}

export function splitCampaignDetailsDataByPages(
  data: Record<string, ITrendingCampaignDetails[]>,
  pagination: Record<string, number>,
  perPage = PER_PAGE
): Record<string, ITrendingCampaignDetails[]> {
  return Object.keys(data).reduce((acc: Record<string, ITrendingCampaignDetails[]>, groupName: string) => {
    const page = pagination[groupName] || 1; // use page from store or 1 as default
    acc[groupName] = data[groupName].slice((page - 1) * perPage, page * perPage); // return data for this page only;
    return acc;
  }, {});
}

export function sortCampaignDetailsData(
  data: Record<string, ITrendingCampaignDetails[]>,
  sortState: Record<string, TsSortState | null>
): Record<string, ITrendingCampaignDetails[]> {
  if (!data || !Object.keys(data).length) {
    return {};
  }

  if (!sortState || !Object.keys(sortState).length) {
    return data;
  }

  return Object.keys(data).reduce((acc: Record<string, ITrendingCampaignDetails[]>, groupName: string) => {
    acc[groupName] = sortTrendingData(data[groupName], sortState[groupName]);
    return acc;
  }, {});
}

export function sortTrendingData<T>(data: T[], sortState: TsSortState | null): T[] {
  if (!data || !data.length) {
    return [];
  }

  if (!sortState || !sortState.direction || !sortState.active) {
    return data;
  }

  return data.slice().sort((a: T, b: T) => {
    const ifAsc = sortState.direction === SortDirections.Asc;
    // NOTE: campaign items is array and we want to sort array length
    const itemA = Array.isArray(a[sortState.active]) ? a[sortState.active].length : a[sortState.active];
    const itemB = Array.isArray(b[sortState.active]) ? b[sortState.active].length : b[sortState.active];
    return compare(itemA, itemB, ifAsc);
  });
}

// Get difference between current and previous value in percent and number type
export function getDifference(a: number, b: number, isGoal = false): IDiffLabel {
  const defaultDiff = {
    diff: null,
    diffPercent: null,
    diffLabel: null,
  };

  if (a === null || a === undefined || typeof a !== 'number') {
    return defaultDiff;
  }

  if (b === null || b === undefined || typeof b !== 'number') {
    return defaultDiff;
  }


  if (b === 0) {
    return {
      diff: a > 0 ? a : 0,
      diffPercent: a > 0 ? 100 : 0,
      diffLabel: a > 0 ? DiffLabel.Positive : DiffLabel.Same
    };
  }

  const diff = a - b;
  const diffPercent = isGoal ? a / b * 100 : a / b * 100 - 100;

  return {
    diff: Math.abs(diff),
    diffPercent: Math.abs(diffPercent),
    diffLabel: diff > 0
      ? DiffLabel.Positive
      : diff < 0 ? DiffLabel.Negative : DiffLabel.Same
  };
}
