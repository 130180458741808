/* istanbul ignore file */
import { Action, createAction, props } from '@ngrx/store';

import * as interfaces from '../../interfaces';
import { ILabelValue, IPager, ISorter } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { INestedTableEvent } from '@measurement-studio/ui/analytics-tables';
import { TypedAction } from '@ngrx/store/src/models';

export enum CampaignActionsTypes {
  SetUsedParams = '[Campaign] Set used params in driver request',
  GetCampaign = '[Campaign] Get all data',
  LoadMetaData = '[Campaign] Load MetaData',
  LoadMetaDataSuccess = '[Campaign] Load MetaData Success',
  LoadMetaDataFailure = '[Campaign] Load MetaData Failure',
  LoadResponses = '[Campaign] Load Responses',
  LoadResponsesSuccess = '[Campaign] Load Responses Success',
  LoadResponsesFailure = '[Campaign] Load Responses Failure',
  LoadAttribution = '[Campaign] Load Attribution',
  LoadAttributionSuccess = '[Campaign] Load Attribution Success',
  LoadAttributionFailure = '[Campaign] Load Attribution Failure',
  LoadInfluence = '[Campaign] Load Influence',
  LoadInfluenceSuccess = '[Campaign] Load Influence Success',
  LoadInfluenceFailure = '[Campaign] Load Influence Failure',
  LoadReturns = '[Campaign] Load Returns',
  LoadReturnsSuccess = '[Campaign] Load Returns Success',
  LoadReturnsFailure = '[Campaign] Load Returns Failure',
  LoadCosts = '[Campaign] Load Costs',
  LoadCostsSuccess = '[Campaign] Load Costs Success',
  LoadCostsFailure = '[Campaign] Load Costs Failure',
  ChangeNestedSorting = '[Campaign] Change nested sorting for campaign id',
  ChangeNestedPagination = '[Campaign] Change nested pagination for campaign id',
  ClearNestedState = '[Campaign] Clear nested state',
  ToggleTableRow = '[Campaign] Toggle table row',
  SetSelectedSource = '[Campaign] Set selected source',
  CloseNestedTables = '[Campaign] Close nested tables',
}

const setUsedParams = createAction(
  CampaignActionsTypes.SetUsedParams,
  props<{ request: IAnalyticsRequest }>()
);

const getCampaign = createAction(
  CampaignActionsTypes.GetCampaign,
);

const loadMetaData = createAction(
  CampaignActionsTypes.LoadMetaData,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadMetaDataSuccess = createAction(
  CampaignActionsTypes.LoadMetaDataSuccess,
  props<interfaces.ISuccessPayload<interfaces.IMetaData<interfaces.IWebActivitiesCampaignResponse>>>()
);

const loadMetaDataFailure = createAction(
  CampaignActionsTypes.LoadMetaDataFailure,
  props<{ message: string }>()
);

const loadResponses = createAction(
  CampaignActionsTypes.LoadResponses,
  (data: interfaces.ILoadPayload = {isDriver: false}) => data
);

const loadResponsesSuccess = createAction(
  CampaignActionsTypes.LoadResponsesSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignResponses>>()
);

const loadResponsesFailure = createAction(
  CampaignActionsTypes.LoadResponsesFailure,
  props<{ message: string }>()
);

const loadAttribution = createAction(
  CampaignActionsTypes.LoadAttribution,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadAttributionSuccess = createAction(
  CampaignActionsTypes.LoadAttributionSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignAttribution>>()
);

const loadAttributionFailure = createAction(
  CampaignActionsTypes.LoadAttributionFailure,
  props<{ message: string }>()
);

const loadInfluence = createAction(
  CampaignActionsTypes.LoadInfluence,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadInfluenceSuccess = createAction(
  CampaignActionsTypes.LoadInfluenceSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignInfluence>>()
);

const loadInfluenceFailure = createAction(
  CampaignActionsTypes.LoadInfluenceFailure,
  props<{ message: string }>()
);

const loadReturns = createAction(
  CampaignActionsTypes.LoadReturns,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadReturnsSuccess = createAction(
  CampaignActionsTypes.LoadReturnsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignReturns>>()
);

const loadReturnsFailure = createAction(
  CampaignActionsTypes.LoadReturnsFailure,
  props<{ message: string }>()
);

const loadCosts = createAction(
  CampaignActionsTypes.LoadCosts,
  ((data: interfaces.ILoadPayload = {isDriver: false}) => data)
);

const loadCostsSuccess = createAction(
  CampaignActionsTypes.LoadCostsSuccess,
  props<interfaces.ISuccessPayload<interfaces.ICampaignCosts>>()
);

const loadCostsFailure = createAction(
  CampaignActionsTypes.LoadCostsFailure,
  props<{ message: string }>()
);

const changeNestedSorting = createAction(
  CampaignActionsTypes.ChangeNestedSorting,
  props<{ data: INestedTableEvent<ISorter> }>()
);

const changeNestedPagination = createAction(
  CampaignActionsTypes.ChangeNestedPagination,
  props<{ data: INestedTableEvent<IPager> }>()
);

const clearNestedState = createAction(
  CampaignActionsTypes.ClearNestedState,
);

const toggleTableRow = createAction(
  CampaignActionsTypes.ToggleTableRow,
  props<{ id: string }>()
);

const setSelectedSource = createAction(
  CampaignActionsTypes.SetSelectedSource,
  props<{ data: ILabelValue }>()
);

const closeNestedTables = createAction(
  CampaignActionsTypes.CloseNestedTables,
);

export const campaignActions = {
  setUsedParams,
  getCampaign,
  loadMetaData,
  loadMetaDataSuccess,
  loadMetaDataFailure,
  loadResponses,
  loadResponsesSuccess,
  loadResponsesFailure,
  loadAttribution,
  loadAttributionSuccess,
  loadAttributionFailure,
  loadInfluence,
  loadInfluenceSuccess,
  loadInfluenceFailure,
  loadReturns,
  loadReturnsSuccess,
  loadReturnsFailure,
  loadCosts,
  loadCostsSuccess,
  loadCostsFailure,
  changeNestedSorting,
  changeNestedPagination,
  clearNestedState,
  toggleTableRow,
  setSelectedSource,
  closeNestedTables,
};

export type CampaignLoadActions = interfaces.ILoadPayload & TypedAction<CampaignActionsTypes>;

export type CampaignLoadSuccessActions = interfaces.ISuccessPayload<any> & TypedAction<CampaignActionsTypes>;

export const campaignActionsMap: Map<CampaignActionsTypes, Action> = new Map()
  .set(CampaignActionsTypes.LoadAttributionSuccess, loadAttribution())
  .set(CampaignActionsTypes.LoadCostsSuccess, loadCosts())
  .set(CampaignActionsTypes.LoadInfluenceSuccess, loadInfluence())
  .set(CampaignActionsTypes.LoadMetaDataSuccess, loadMetaData())
  .set(CampaignActionsTypes.LoadResponsesSuccess, loadResponses())
  .set(CampaignActionsTypes.LoadReturnsSuccess, loadReturns());
