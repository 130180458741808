import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
  selector: 'tsh-no-report-result',
  templateUrl: './no-report-result.component.html',
  styleUrls: ['./no-report-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoReportResultComponent {
  @Input() title = 'No result, yet';
  @Input() description = 'There isn’t any result within the selected filters yet. Please try again later.';
}


