import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as NavMenusReducer from './nav-menus.reducer';
import {
  IMenuCategory,
  IMenuItem,
  IMenuItemWithCategories,
  IMenuSection,
  INavMenu,
  IRouteItem,
} from '@shared/interfaces';
import { REPORT_NAV_LABEL } from '@shared/constants';
import { RouteItemEnum } from '@shared/enums';
import { getStateWithOverrides, SPLITIO_STATUS } from '@shared/splitio';

export const selectNavMenusState = createFeatureSelector<NavMenusReducer.NavMenusState>('navMenu');
export const selectSelectedRouteItem = createSelector(
  selectNavMenusState,
  (state: NavMenusReducer.NavMenusState) => state.selectedRouteItem,
);
export const getNavMenu = createSelector(
  selectNavMenusState, 
  getStateWithOverrides,
  (navState, splitIOFlags): INavMenu =>
    navState.navMenu.map( navSection => ({
        ...navSection,
        items: navSection.items.filter((navItem) => !navItem.splitIOFlag || splitIOFlags[navItem.splitIOFlag] === SPLITIO_STATUS.on)
      }))
);

export const selectSpecificLink = createSelector(
  selectNavMenusState,
  (state: NavMenusReducer.NavMenusState) => state.specificReportLink,
);
export const selectSwitcherContent = createSelector(
  selectNavMenusState,
  (state: NavMenusReducer.NavMenusState) => state.switcherContent,
);
export const getParentLink = createSelector(
  selectNavMenusState,
  (state: NavMenusReducer.NavMenusState) => state.parentLink,
);
// Get selected item in nav menu to highlight selected menu
// For example, if I select Campaign Analytics, I'll have to highlight Report menu
export const getActiveNavItemWithCategories = createSelector(
  getNavMenu,
  selectSelectedRouteItem,
  (menu: INavMenu, selectedRoute: IRouteItem): IMenuItem | null => {
    if (!selectedRoute?.routeId) {
      return null;
    }

    let selectedItem: IMenuItem;
    // NOTE: there are routes for detail pages which we don't have in navMenu data
    // that's why we couldn't figure out if Report section is selected when those pages are active
    // I created a list with routes which have this problem
    // We must to rewrite breadcrumbs functionality at all and remove this "hardcode"
    const reportSpecificRoutes = [
      RouteItemEnum.OpportunityAllOpptysTouches,
      RouteItemEnum.CampaignAnalyticsGroups,
      RouteItemEnum.CampaignAnalyticsCampaigns,
      RouteItemEnum.CampaignSpecificLeads,
      RouteItemEnum.CampaignSpecificOpportunities,
      RouteItemEnum.CampaignSpecificDeals,
      RouteItemEnum.SalesforceCompare,
      RouteItemEnum.ProgressionByQuarterDetails,
      RouteItemEnum.WebActivitiesChannel,
      RouteItemEnum.WebActivitiesChannelMedium,
      RouteItemEnum.WebActivitiesChannelAssets,
      RouteItemEnum.WebActivitiesStages,
    ];

    menu.forEach((section: IMenuSection) => {
      section.items.forEach((item: IMenuItem) => {
        if (item.label === REPORT_NAV_LABEL && reportSpecificRoutes.includes(selectedRoute.routeId)) {
          selectedItem = item;
        } else if ((item as IMenuItemWithCategories).categories) {
          const selectedCategory = (item as IMenuItemWithCategories).categories.find((category: IMenuCategory) =>
            category.routeIds.includes(selectedRoute.routeId),
          );

          if (selectedCategory) {
            selectedItem = item;
          }
        }
      });
    });

    return selectedItem;
  },
);
