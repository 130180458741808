export enum CampaignChartColors {
  CampaignResponses = '#4F5A9F',
  Opportunities = '#EB5488',
  Deals = '#FFA600',
  PipelineAttributed = '#63AA4E',
  RevenueAttributed = '#008989',
}

export enum CampaignChartTypes {
  CampaignResponses = 'leads',
  Opportunities = 'opptys',
  Deals = 'deals',
  PipelineAttributed = 'pipeline',
  RevenueAttributed = 'revenue',
}

export enum CampaignChartKeys {
  CampaignResponses = 'leads',
  Opportunities = 'opptys',
  Deals = 'deals',
  PipelineAttributed = 'pipeline',
  RevenueAttributed = 'revenue',
}
