<div class="selector">
  <mat-select [formControl]="selectedChartControl"
              (selectionChange)="selectionChange()"
              panelClass="opportunity-selector"
              class="report-filter-selector">
    <mat-select-trigger>
      {{ 'measurementStudio.features.opportunities.chart.view' | translate }}: {{ selectedChartControl.value?.label | translate }}
    </mat-select-trigger>
    <mat-option *ngFor="let option of options"
                class="reports-filter-options"
                [value]="option">
      {{ option.label | translate }}
    </mat-option>
  </mat-select>
</div>
<div class="chart-container">
  <ts-chart visualization="xy" (chartInitialized)="setUpChart($event)">
  </ts-chart>
</div>
