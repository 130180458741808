import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';
import { isNumber } from '@terminus-lib/fe-utilities';

import { CampaignAttributionState, campaignSpecificFeatureKey } from './attribution.reducer';
import {
  IAttributionDeal,
  IAttributionLead,
  IAttributionOppty,
  ICampaignOrChannelAttribution,
  ICampaignSpecificFilters,
} from '@measurement-studio/interfaces';
import { CampaignTab } from '../enums/campaign-tab.enum';
import { IReportColumn, IReportPagination, IReportTab, ReportMetric } from '@shared/interfaces';
import { applyPageToReportData, compare, searchByAnyField, sortReportData } from '@util/helpers';
import { DataTypeEnum, RouteItemEnum, SortDirections } from '@shared/enums';
import {
  ICampaignChart,
  ICampaignChartData,
  ICampaignChartType,
  ICampaignSpecificLink,
  ICampaignSpecificNestedReport,
  ICampaignSpecificReport,
  ICampaignTotals,
  ICampaignTouchReturns
} from '../interfaces';
import { PER_PAGE } from '@shared/constants';
import * as OrgConfigStore from '@org-config';
import {
  WEB_ACTIVITY_DEALS_COLUMNS,
  WEB_ACTIVITY_DEALS_NESTED_COLUMNS,
  WEB_ACTIVITY_LEADS_COLUMNS,
  WEB_ACTIVITY_LEADS_NESTED_COLUMNS,
  WEB_ACTIVITY_OPPTYS_COLUMNS
} from '../data/attribution.data';

export function updateColumnsForWebActivities(key: CampaignTab): IReportColumn[] {
  switch (key) {
    case CampaignTab.Leads:
      return WEB_ACTIVITY_LEADS_COLUMNS;

    case CampaignTab.Opportunities:
      return WEB_ACTIVITY_OPPTYS_COLUMNS;

    case CampaignTab.Deals:
      return WEB_ACTIVITY_DEALS_COLUMNS;
    default:
      return [];
  }
}

export const selectCampaignAttributionState = createFeatureSelector<CampaignAttributionState>(campaignSpecificFeatureKey);

export const getCampaignAttribution = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.attribution);

export const getSelectedReport = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.selectedReport);

export const getCampaignSpecificName = createSelector(
  getCampaignAttribution,
  getSelectedReport,
  (attribution: ICampaignOrChannelAttribution, report: RouteItemEnum) => {
    return attribution?.campaign?.name || (report === RouteItemEnum.CampaignSpecific ?
      'feature.campaignSpecific.title' : 'feature.webSpecific.title');
  });

export const getCampaignInfo = createSelector(
  getCampaignAttribution,
  getSelectedReport,
  (attribution: ICampaignOrChannelAttribution, selectedReport: RouteItemEnum) => {
    if (attribution?.campaign) {
      return [{
        title: selectedReport === RouteItemEnum.CampaignSpecific
          ? 'feature.campaignSpecific.info.type'
          : 'feature.campaignSpecific.info.utmSource',
        value: attribution.campaign.group,
        type: DataTypeEnum.Text
      }, {
        title: 'feature.campaignSpecific.info.date',
        value: attribution.campaign.createdDate,
        type: DataTypeEnum.Date
      }];
    }

    return [];
  });

export const getCampaignTouches = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.touches);

export const getCampaignSpecificLoading = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.isLoading);

export const getCampaignSpecificParams = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.filters);

export const getCampaignLinks = createSelector(
  getCampaignAttribution,
  OrgConfigStore.isDynamics365Enabled,
  OrgConfigStore.getSalesforceUrl,
  getSelectedReport,
  getCampaignSpecificParams,
  (
    attribution: ICampaignOrChannelAttribution,
    iDynamics365Enabled: boolean,
    url: string,
    selectedReport: RouteItemEnum,
    filters: ICampaignSpecificFilters,
  ): ICampaignSpecificLink[] => {
    if (attribution?.campaign) {
      // NOTE from hub-ajs: For dynamics 365 enabled orgs we do not currently have
      // a direct link to the campaign page
      const campaignSalesforceUrl = iDynamics365Enabled
        ? []
        : [{
          title: 'feature.campaignSpecific.links.view',
          isExternal: true,
          routeId: `${url}/${attribution.campaign.campaignId}`
        }];
      const stagesUrl = [{
          title: 'feature.campaignSpecific.links.stageProgression',
          isExternal: false,
          routeId: selectedReport === RouteItemEnum.CampaignSpecific
            ? RouteItemEnum.CampaignSpecificStages
            : RouteItemEnum.WebActivitiesStages,
          queryParams: filters
        }];

      return [
        ...campaignSalesforceUrl,
        ...stagesUrl
      ];
    }

    return [];
  });

export const getStateTabs = createSelector(selectCampaignAttributionState, (state: CampaignAttributionState) => state.tabs);

export const getCampaignSpecificTabs = createSelector(
  getStateTabs,
  getSelectedReport,
  (tabs: IReportTab[], report: RouteItemEnum) => {
    if (report === RouteItemEnum.WebActivitiesSpecific) {
      tabs[0] = {
        ...tabs[0],
        title: 'feature.campaignSpecific.tabs.leads.title'
      };
    }
    return tabs;
  });


export const getSelectedCohort = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.selectedDateCohort);

 export const getCampaignAttributionKeyData =
  (key: CampaignTab) => createSelector(
    selectCampaignAttributionState,
    (state: CampaignAttributionState) => state[key]
  );

export const getCampaignDefaultColumnsByKey = (key: CampaignTab) => createSelector(
  getCampaignAttributionKeyData(key),
  (data: ICampaignSpecificReport) => data.columns);

export const getCampaignSpecificReportData = (
  key: CampaignTab
) => createSelector(getCampaignAttribution,
  (attribution: ICampaignOrChannelAttribution) => attribution ? attribution[key] : []);

export const getSpecificReportColumns = (
  key: CampaignTab
) => createSelector(
  getCampaignTouches,
  getCampaignDefaultColumnsByKey(key),
  getSelectedReport,
  (touches: ICampaignTouchReturns[], columns: IReportColumn[], report: RouteItemEnum) => {
    let reportColumns = (report === RouteItemEnum.WebActivitiesSpecific) ? updateColumnsForWebActivities(key) : columns;
    if (touches && touches.length) {
      if (report === RouteItemEnum.WebActivitiesSpecific) {
        reportColumns = reportColumns.map(column => (column.name === 'activityDate') ? {
            name: 'activityDate',
            width: 237,
            startSorting: 'asc',
            displayName: 'feature.webActivitiesSpecific.table.leads.activityDate',
            dataType: DataTypeEnum.Date
          } : column
        );
      }
      return [
        ...reportColumns, {
          displayName: 'feature.campaignSpecific.table.campaignResponses',
          name: 'webTouchesAmount',
          width: 220,
          startSorting: 'desc',
          dataType: DataTypeEnum.Number
        }];
    } else {
      return reportColumns;
    }
  });

export const getSpecificReportPagination = (key: CampaignTab) => createSelector(
  getCampaignAttributionKeyData(key),
  (data: ICampaignSpecificReport) => data?.pagination);

export const getSpecificReportRowState = (key: CampaignTab) => createSelector(
  getCampaignAttributionKeyData(key),
  (data: ICampaignSpecificReport) => data?.rowState);

export const getSpecificReportSortState = (key: CampaignTab) => createSelector(
  getCampaignAttributionKeyData(key),
  (data: ICampaignSpecificReport) => data?.sortState);

export const getSpecificReportSearchQuery = (key: CampaignTab) => createSelector(
  getCampaignAttributionKeyData(key),
  (data: ICampaignSpecificReport) => data?.searchQuery);

export const getSpecificReportNestedTableSortState = (
  key: CampaignTab
) => createSelector(getNestedTable(key),
  (nestedTable: ICampaignSpecificNestedReport) => nestedTable?.sortState);

export const getSpecificReportNestedTablePagination = (
  key: CampaignTab
) => createSelector(getNestedTable(key),
  (nestedTable: ICampaignSpecificNestedReport) => nestedTable?.pagination);

export const getNestedTable = (
  key: CampaignTab
) => createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state[key]?.nestedTable);

export const getSpecificReportNestedTableColumns = (
  key: CampaignTab
) => createSelector(
  getNestedTable(key),
  getSelectedReport,
  (nestedTable: ICampaignSpecificNestedReport, report: RouteItemEnum): IReportColumn[] => {
    if (report === RouteItemEnum.WebActivitiesSpecific) {
      switch (key) {
        case CampaignTab.Leads:
          return WEB_ACTIVITY_LEADS_NESTED_COLUMNS;

        case CampaignTab.Deals:
        case CampaignTab.Opportunities:
          return WEB_ACTIVITY_DEALS_NESTED_COLUMNS;
      }
    }
    return nestedTable.columns;
  });


export const getSpecificReportNestedTableData = (
  key: CampaignTab
) => createSelector(
  getNestedTable(key),
  (nestedTable: ICampaignSpecificNestedReport) => nestedTable?.data);

export function sortNestedTableData<T>(data: T[], sortState: TsSortState | null, nestedKey: string = null): T[] {
  if (!data || !data.length) {
    return [];
  }

  if (!sortState || !sortState.direction || !sortState.active) {
    return data;
  }
  const sortBy = sortState.active;
  // use slice to make a copy of the array to avoid mutation
  return data.slice().sort((a: T, b: T) => {
    return nestedKey ? compare(a[nestedKey][sortBy], b[nestedKey][sortBy], sortState.direction === SortDirections.Asc) :
      compare(a[sortBy], b[sortBy], sortState.direction === SortDirections.Asc);
  });
}

export const getSpecificSortedReport = (key: CampaignTab, paginated = true) => createSelector(
  getSpecificFilteredReport(key),
  getSpecificReportSortState(key),
  getSpecificReportPagination(key),
  (
    report: IAttributionLead[] | IAttributionDeal[] | IAttributionOppty[],
    sortState: TsSortState,
    pagination: IReportPagination,
  ) => {
    const sortedReport = sortReportData<IAttributionLead | IAttributionDeal | IAttributionOppty>(report, sortState);
    return paginated
      ? applyPageToReportData<IAttributionLead | IAttributionDeal | IAttributionOppty>(sortedReport, pagination.page)
      : sortedReport;
  });

export const getSpecificNestedSortedReport = (key: CampaignTab) => createSelector(
  getSpecificReportNestedTableData(key),
  getSpecificReportNestedTableSortState(key),
  getSpecificReportNestedTablePagination(key),
  (
    report: Record<string, ICampaignTouchReturns[]>,
    sortState: Record<string, TsSortState>,
    pagination: Record<string, IReportPagination>,
  ) => {
    return report && Object.keys(report).length
      ? Object.keys(report).reduce((data: Record<string, ICampaignTouchReturns[]>, id: string) => {
        // NOTE: sort table data only if there is a sort state for this report
        const sortedReport = sortNestedTableData<ICampaignTouchReturns>(report[id], sortState[id], 'touch');
        const page = pagination && pagination[id] ? pagination[id].page : 1; // use page from store or default page
        const perPage = pagination && pagination[id] ? pagination[id].perPage : PER_PAGE;
        data[id] = sortedReport ? sortedReport.slice((page - 1) * perPage, page * perPage) : []; // return data for this page only
        return data;
      }, {})
      : report;
  });

export const getSpecificFilteredReport = (key: CampaignTab) => createSelector(
  getCampaignSpecificReportData(key),
  getSpecificReportSearchQuery(key),
  (
    report: [],
    searchQuery: string,
  ) => {
    return searchQuery ? searchByAnyField(report, searchQuery) : report;
  }
);

export const getReportSpecificTotalsLine = (key: CampaignTab) => createSelector(
  getSpecificFilteredReport(key),
  getSelectedReport,
  (
    report: IAttributionDeal[],
    selected: RouteItemEnum
  ) => {
    let result: ICampaignTotals = {
      webTouchesAmount: 0,
      totalAmount: 0,
      amount: 0,
      amountPipeline: 0
    };
    switch (key) {
      case CampaignTab.Leads:
        result = report.reduce(
          (totalTouches: ICampaignTotals, item: IAttributionDeal) => {
            return {
              webTouchesAmount: totalTouches.webTouchesAmount + +item.webTouchesAmount,
              totalAmount: null,
              amount: null,
              amountPipeline: null
            };
          }, result);
        break;

      case CampaignTab.Opportunities:
      case CampaignTab.Deals:
        result = report.reduce(
          (totalTouches: ICampaignTotals, item: IAttributionDeal) => {
            return {
              totalAmount: totalTouches.totalAmount + item.totalAmount,
              ...((isNumber(item.webTouchesAmount)) && {webTouchesAmount: +totalTouches.webTouchesAmount + +item.webTouchesAmount}),
              ...((selected === RouteItemEnum.WebActivitiesSpecific) && {amount: totalTouches.amount + item.amount}),
              ...((selected === RouteItemEnum.WebActivitiesSpecific) && {amountPipeline: totalTouches.amountPipeline + item.amountPipeline})
            };
          }, result);
        break;
    }
    return result;
  }
);

export const getSelectedChartType = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.selectedChartType);

export const getAllCharts = createSelector(selectCampaignAttributionState,
  (state: CampaignAttributionState) => state.chart);

export const getSelectedChart = createSelector(
  getAllCharts,
  getSelectedChartType,
  (
    charts: ICampaignChart | null,
    selectedChartType: ICampaignChartType
  ): ICampaignChartData[] => {
    if (charts && charts[selectedChartType.value]) {
      return charts[selectedChartType.value];
    }

    return [];
  });

export const getAttributionMetrics = createSelector(
  getSelectedChartType,
  getSelectedChart,
  (
    chartType: ICampaignChartType,
    chartData: ICampaignChartData[]
  ): ReportMetric[] => {
    return [{
      title: chartType.label,
      current: {
        // get last total value as result value
        value: chartData.length ? chartData[chartData.length - 1].total : 0,
        dataType: chartType.dataType,
        color: chartType.color
      }
    }];
  });
