<div class="options-container">
  <ng-container *ngIf="selectedVisualization === visualizationTypes.Gauge as isGaugeChart"
                [formGroup]="settingsFormGroup">
    <h3 class="title">{{ 'shared.dashboards.dialog.gaugeOptions' | translate }}</h3>
    <section class="gauge-container">
      <div class="inputs-container">
        <div class="gauge-input" *ngFor="let item of gaugeConfig">
          <!-- NOTE: don't use currency mask because it's emit valueChanges on init and you-->
          <!-- will see refresh button even if there are no changes yet-->
          <ts-input type="number"
                    class="currency-input"
                    [ngClass]="{'input-error': settingsFormGroup.errors && settingsFormGroup.errors[item.field] && isHighlightedErrors}"
                    *ngIf="settingsFormGroup.get(item.field)"
                    [isSmall]="true"
                    [formControlName]="item.field"
                    [isRequired]="isGaugeChart"
                    [label]="item.label | translate"></ts-input>
          <i class="fas fa-long-arrow-right"></i>
        </div>
      </div>
      <div class="colors-container">
        <span class="min" [ngStyle]="{'background-color': gaugeChartColors.Min}"></span>
        <span class="mid" [ngStyle]="{'background-color': gaugeChartColors.Mid}"></span>
        <span class="max" [ngStyle]="{'background-color': gaugeChartColors.Max}"></span>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="visualizationWithGoal.includes(selectedVisualization) && settingsFormGroup.get(goalField)"
                [formGroup]="settingsFormGroup">
    <h3 class="title">{{ 'shared.dashboards.dialog.paretoOptions' | translate }}</h3>
    <!-- NOTE: don't use currency mask because it's emit valueChanges on init and you-->
    <!-- will see refresh button even if there are no changes yet-->
    <ts-input type="number"
              class="currency-input"
              [isSmall]="true"
              [formControlName]="goalField"
              [label]="'shared.dashboards.dialog.goal' | translate">
    </ts-input>
  </ng-container>
</div>
