/* istanbul ignore file */
import { ActionType, createAction, props } from '@ngrx/store';

import { IAnalyticsRequest, ITagRequest, ITagsRequest, ITagsResponse } from '@measurement-studio/interfaces';
import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';

export const TagsActionsTypes = {
  LoadTags: '[Tags] Load Tags',
  LoadTagsSuccess: '[Tags] Load Tags Success',
  LoadTagsFailure: '[Tags] Load Tags Failure',
  LoadTagsByCampaign: '[Tags] Load Tags By Campaign',
  LoadTagsByCampaignSuccess: '[Tags] Load Tags By Campaign Success',
  LoadTagsByCampaignFailure: '[Tags] Load Tags By Campaign Failure',
  AddCampaignTag: '[Tags] Add Tag',
  AddCampaignTagSuccess: '[Tags] Add Tag Success',
  AddCampaignTagFailure: '[Tags] Add Tag Failure',
  RemoveCampaignTag: '[Tags] Remove Tag',
  RemoveCampaignTagSuccess: '[Tags] Remove Tag Success',
  RemoveCampaignTagFailure: '[Tags] Remove Tag Failure',
  SetCampaignSelectedRowIds: '[Tags] Set Selected Row Ids',
  LoadTagsByChannel: '[Tags] Load Tags By Channel',
  LoadTagsByChannelSuccess: '[Tags] Load Tags By Channel Success',
  LoadTagsByChannelFailure: '[Tags] Load Tags By Channel Failure',
  AddChannelTag: '[Tags] Add Channel Tag',
  AddChannelTagSuccess: '[Tags] Add Tag Channel Success',
  AddChannelTagFailure: '[Tags] Add Tag Channel  Failure',
  RemoveChannelTag: '[Tags] Remove Channel Tag',
  RemoveChannelTagSuccess: '[Tags] Remove Channel Tag Success',
  RemoveChannelTagFailure: '[Tags] Remove Channel Tag Failure',
  SetChannelSelectedRowIds: '[Tags] Set Channel Selected Row Ids',
};

const loadTags = createAction(
  TagsActionsTypes.LoadTags,
  props<{ request: ITagsRequest }>()
);

const loadTagsSuccess = createAction(
  TagsActionsTypes.LoadTagsSuccess,
  props<{ data: string[] }>()
);

const loadTagsFailure = createAction(
  TagsActionsTypes.LoadTagsFailure,
  props<{ message: string }>()
);

const loadTagsByCampaign = createAction(
  TagsActionsTypes.LoadTagsByCampaign,
  props<{ request: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams }>()
);

const loadTagsByCampaignSuccess = createAction(
  TagsActionsTypes.LoadTagsByCampaignSuccess,
  props<{ data: ITagsResponse }>()
);

const loadTagsByCampaignFailure = createAction(
  TagsActionsTypes.LoadTagsByCampaignFailure,
  props<{ message: string }>()
);

const addCampaignTag = createAction(
  TagsActionsTypes.AddCampaignTag,
  props<{ request: ITagRequest }>()
);

const addCampaignTagSuccess = createAction(
  TagsActionsTypes.AddCampaignTagSuccess,
  props<{ data: ITagsResponse }>()
);

const addCampaignTagFailure = createAction(
  TagsActionsTypes.AddCampaignTagFailure,
  props<{ message: string }>()
);

const removeCampaignTag = createAction(
  TagsActionsTypes.RemoveCampaignTag,
  props<{ request: ITagRequest }>()
);

const removeCampaignTagSuccess = createAction(
  TagsActionsTypes.RemoveCampaignTagSuccess,
  props<{ data: ITagRequest }>()
);

const removeCampaignTagFailure = createAction(
  TagsActionsTypes.RemoveCampaignTagFailure,
  props<{ message: string }>()
);

const setCampaignSelectedRowIds = createAction(
  TagsActionsTypes.SetCampaignSelectedRowIds,
  props<{ data: string[] }>()
);

const loadTagsByChannel = createAction(
  TagsActionsTypes.LoadTagsByChannel,
  props<{ request: IAnalyticsRequest, globalFilters: IAppliedGlobalFiltersAsParams }>()
);

const loadTagsByChannelSuccess = createAction(
  TagsActionsTypes.LoadTagsByChannelSuccess,
  props<{ data: ITagsResponse }>()
);

const loadTagsByChannelFailure = createAction(
  TagsActionsTypes.LoadTagsByChannelFailure,
  props<{ message: string }>()
);

const addChannelTag = createAction(
  TagsActionsTypes.AddChannelTag,
  props<{ request: ITagRequest }>()
);

const addChannelTagSuccess = createAction(
  TagsActionsTypes.AddChannelTagSuccess,
  props<{ data: ITagsResponse }>()
);

const addChannelTagFailure = createAction(
  TagsActionsTypes.AddChannelTagFailure,
  props<{ message: string }>()
);

const removeChannelTag = createAction(
  TagsActionsTypes.RemoveChannelTag,
  props<{ request: ITagRequest }>()
);

const removeChannelTagSuccess = createAction(
  TagsActionsTypes.RemoveChannelTagSuccess,
  props<{ data: ITagRequest }>()
);

const removeChannelTagFailure = createAction(
  TagsActionsTypes.RemoveChannelTagFailure,
  props<{ message: string }>()
);

const setChannelSelectedRowIds = createAction(
  TagsActionsTypes.SetChannelSelectedRowIds,
  props<{ data: string[] }>()
);

export const tagsActions = {
  loadTags,
  loadTagsSuccess,
  loadTagsFailure,
  loadTagsByCampaign,
  loadTagsByCampaignSuccess,
  loadTagsByCampaignFailure,
  addCampaignTag,
  addCampaignTagSuccess,
  addCampaignTagFailure,
  removeCampaignTag,
  removeCampaignTagSuccess,
  removeCampaignTagFailure,
  setCampaignSelectedRowIds,
  loadTagsByChannel,
  loadTagsByChannelSuccess,
  loadTagsByChannelFailure,
  addChannelTag,
  addChannelTagSuccess,
  addChannelTagFailure,
  removeChannelTag,
  removeChannelTagSuccess,
  removeChannelTagFailure,
  setChannelSelectedRowIds,
}

export type CampaignMutateSuccessActions = ActionType<typeof addCampaignTagSuccess> | ActionType<typeof removeCampaignTagSuccess>;
export type ChannelMutateSuccessActions = ActionType<typeof addChannelTagSuccess> | ActionType<typeof removeChannelTagSuccess>;
