import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';

import { GLOBAL_FILTER_TITLE_MAP, GLOBAL_FILTER_TITLE_PLURAL_MAP, GLOBAL_FILTERS_COLUMNS } from '../../../../tile-modal.constant';
import { ISelectedGlobalFilter, ISelectedGlobalFilterItem, ITargetFolderItem } from '@shared/interfaces';
import { GlobalFiltersKeys } from '@shared/enums';
import { select, Store } from '@ngrx/store';

import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { SharedState } from '@shared-store';
import { filter, take } from 'rxjs/operators';


@Component({
  selector: 'tsh-global-filters-table',
  templateUrl: './global-filters-table.component.html',
  styleUrls: ['./global-filters-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalFiltersTableComponent {
  @Input() public appliedFiltersNumber: number;
  @Input()
  public set allSelectedFilters(data: ISelectedGlobalFilter[]) {
    if (data) {
      this._allSelectedFilters = data;
      this.dataSource.data = data;
      this.updateRowState();
      this.changeDetector.detectChanges();
    }
  }

  public get allSelectedFilters(): ISelectedGlobalFilter[] {
    return this._allSelectedFilters;
  }

  @Output() public toggleFolderSelectAll = new EventEmitter<ITargetFolderItem[]>();
  @Output() public toggleFolderItemSelect = new EventEmitter<ITargetFolderItem>();
  @Output() public removeSelectedFolder = new EventEmitter<ITargetFolderItem>();

  public readonly columns = GLOBAL_FILTERS_COLUMNS;
  public readonly titleMap: Record<GlobalFiltersKeys, string> = GLOBAL_FILTER_TITLE_MAP;
  public readonly pluralTitleMap: Record<GlobalFiltersKeys, string> = GLOBAL_FILTER_TITLE_PLURAL_MAP;
  public rowState: {[key: string]: boolean} = {};
  public dataSource: TsTableDataSource<ISelectedGlobalFilter> = new TsTableDataSource();
  private _allSelectedFilters: ISelectedGlobalFilter[] = [];

  private _segments = [];

  constructor(public changeDetector: ChangeDetectorRef, private store: Store<SharedState>,) {
    this.store.pipe(select(GlobalFiltersStore.getSegments), filter(segments => segments.length > 0), take(1)).subscribe(segments => this._segments = segments);
  }

  selectAllFilterItems(filter: ISelectedGlobalFilter): void {
    // check if there is already selected all items
    // in this case just skip the action
    if (filter.selectedItems === filter.filterItems.length) {
      return;
    }
    this.toggleFolderSelectAll.emit([{
      folderName: filter.sectionKey,
      folderItem: filter.filterNumber,
    }]);
  }

  selectOneFilterItem(filter: ISelectedGlobalFilter, item: ISelectedGlobalFilterItem): void {
    this.toggleFolderItemSelect.emit({
      folderName: filter.sectionKey,
      folderItem: filter.filterNumber,
      folderSelectKey: item.id
    });
  }

  toggleExpand(event: MouseEvent, filter: ISelectedGlobalFilter): void {
    event.stopPropagation();
    this.rowState[filter.name] = !this.rowState[filter.name];
  }

  removeFolder(filter: ISelectedGlobalFilter): void {
    this.removeSelectedFolder.emit({
      folderName: filter.sectionKey,
      folderItem: filter.filterNumber,
    });
  }

  private updateRowState(): void {
    this.allSelectedFilters.forEach(filter => {
      if (this.rowState[filter.name] === undefined) {
        this.rowState[filter.name] = false;
      }
    });
  }

  /**
   * Takes in a comma separated list of segmentIDs, translates to display Name
   * @param data 
   * @returns 
   */
  getSegmentSummaryDisplayName(data: string) {
    if (this._segments) {
      return data.split(',').map(item => {
        const searchItem = item.trim();
        return this._segments.find(segment => segment.lqsSharedQueryId === searchItem)?.displayName || item}).join(', ');
    }

    return data;
  }

  /**
   * Takes in a segmentID, translates to display Name
   * @param data 
   * @returns 
   */
  getSegmentDisplayName(data: string) {
    if (this._segments) {
      const segment = this._segments.find(segment => segment.lqsSharedQueryId === data);
      if (segment) return segment.displayName;
    }

    return data;
  }
}
