import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';

import * as CampaignGroupsActions from '../state/campaign-groups/campaign-groups.actions';
import {
  campaignGroupsActions, campaignGroupsActionsMap,
  CampaignGroupsLoadActions,
  CampaignGroupsLoadSuccessActions
} from '../state/campaign-groups/campaign-groups.actions';
import { campaignsActions, campaignsActionsMap, CampaignsLoadSuccessActions } from '../state/campaigns/campaigns.actions';
import { mediumsActionsMap, mediumsActions, MediumsLoadSuccessActions } from '../state/mediums/mediums.actions';
import { sourceActions, sourceActionsMap, SourceLoadSuccessActions } from '../state/source/source.actions';
import { campaignActions, campaignActionsMap, CampaignLoadSuccessActions } from '../state/campaign/campaign.actions';
import { totalsActions, TotalsLoadActions } from '../state/totals/totals.actions';
import * as interfaces from '../interfaces';
import { AnalyticsColumnName as Column, AnalyticsResponseTypeEnum as ResponseType } from '../enums';
import { getResponseTypeByColumn } from './store.helper';

type ColumnName = Column | string;
type ColumnNames = ColumnName[];
type ColumnToActionMapper<T> = (columnName: string, payload: interfaces.ILoadPayload | interfaces.LoadTotalsPayload) => T;

@Injectable()
export class AnalyticsHelperService {
  // Get Campaign Groups Action to load data by column name with payload (Driver/Secondary)
  getCampaignGroupsLoadAction(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    return this.getCampaignGroupsLoadActionMapper(columnName, payload);
  }

  // Get Campaigns Action to load data by column name with payload (Driver/Secondary)
  getCampaignsLoadAction(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    return this.getCampaignsLoadActionMapper(columnName, payload);
  }

  // Get Campaign Groups Action to load data by column name with payload (Driver/Secondary)
  getMediumsLoadAction(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    return this.getMediumsLoadActionMapper(columnName, payload);
  }

  // Get Source Action to load data by column name with optional (Driver/Secondary)
  getSourceLoadAction(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    return this.getSourceLoadActionMapper(columnName, payload);
  }

  // Get Campaign Action to load data by column name with payload (Driver/Secondary)
  getCampaignLoadAction(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    return this.getCampaignLoadActionMapper(columnName, payload);
  }

  // Get Campaign Groups Actions to load data by column name as Secondary requests except already loaded success action
  getCampaignGroupsSecondaryLoadActions(action: CampaignGroupsLoadSuccessActions, columnNames: ColumnNames): Action[] {
    const payload: interfaces.ILoadPayload = {isDriver: false, parentId: action.parentId};
    const map = this.getUniqueActionsByColumnNames(this.getCampaignGroupsLoadActionMapper, columnNames, payload);
    const loadActionToExclude = campaignGroupsActionsMap.get(action.type);
    map.delete(loadActionToExclude.type);
    return Array.from(map.values());
  }

  // Get Campaign Groups Actions to load data by column name as Secondary requests except already loaded success action
  getCampaignsSecondaryLoadActions(action: CampaignsLoadSuccessActions, columnNames: ColumnNames): Action[] {
    const payload: interfaces.ILoadPayload = {isDriver: false, parentId: action.parentId};
    const map = this.getUniqueActionsByColumnNames(this.getCampaignsLoadActionMapper,
      columnNames, payload);
    const loadActionToExclude = campaignsActionsMap.get(action.type);
    map.delete(loadActionToExclude.type);
    return Array.from(map.values());
  }

  // Get Campaign Groups Actions to load data by column name as Secondary requests except already loaded success action
  getMediumsSecondaryLoadActions(action: MediumsLoadSuccessActions, columnNames: ColumnNames): Action[] {
    const payload: interfaces.ILoadPayload = {isDriver: false, parentId: action.parentId};
    const map = this.getUniqueActionsByColumnNames(this.getMediumsLoadActionMapper,
      columnNames, payload);
    const loadActionToExclude = mediumsActionsMap.get(action.type);
    map.delete(loadActionToExclude.type);
    return Array.from(map.values());
  }

  // Get Campaign Actions to load data by column name as Secondary requests except already loaded success action
  getCampaignSecondaryLoadActions(action: CampaignLoadSuccessActions, columnNames: ColumnNames): Action[] {
    const payload: interfaces.ILoadPayload = {isDriver: false, parentId: action.parentId};
    const map = this.getUniqueActionsByColumnNames(this.getCampaignLoadActionMapper, columnNames, payload);
    const loadActionToExclude = campaignActionsMap.get(action.type);
    map.delete(loadActionToExclude.type);
    return Array.from(map.values());
  }

  // Get Source Actions to load data by column name as Secondary requests except already loaded success action
  getSourceSecondaryLoadActions(action: SourceLoadSuccessActions, columnNames: ColumnNames): Action[] {
    const payload: interfaces.ILoadPayload = {isDriver: false, parentId: action.parentId};
    const map = this.getUniqueActionsByColumnNames(this.getSourceLoadActionMapper, columnNames, payload);
    const loadActionToExclude = sourceActionsMap.get(action.type);
    map.delete(loadActionToExclude.type);
    return Array.from(map.values());
  }

  // Get Totals Actions to load data by visible column names
  getTotalsLoadActions(columnNames: ColumnNames, payload: interfaces.LoadTotalsPayload): TotalsLoadActions[] {
    const map = this.getUniqueActionsByColumnNames(this.getTotalsActionByColumn, columnNames, payload);
    return Array.from(map.values());
  }

  // Get Web Activities Totals Actions to load data by visible column names
  getWebActivitiesTotalsLoadActions(columnNames: ColumnNames, payload: interfaces.LoadTotalsPayload): TotalsLoadActions[] {
    const map = this.getUniqueActionsByColumnNames(this.getWebActivitiesTotalsActionByColumn, columnNames, payload);
    return Array.from(map.values());
  }

  // Get Source Totals Actions to load data by visible column names
  getSourceTotalsLoadActions(columnNames: ColumnNames, payload: interfaces.LoadTotalsPayload): TotalsLoadActions[] {
    const map = this.getUniqueActionsByColumnNames(this.getSourceTotalsActionByColumn, columnNames, payload);
    return Array.from(map.values());
  }

  /**
   * Compare current filters with filters which was used for previous driver request.
   * If filters are different then we have to get new data otherwise we have to skip a request
   * This functionality is necessary because we get data in table's container, not in common container
   * Every time when we click on the tab there is subscription for filters.
   * That's why we have to check if filters are changed to avoid redundant requests
   *
   * @param currentFilters
   * @param prevFilters
   */
  areFiltersChanged<T>(currentFilters: T | null, prevFilters: T | null): boolean {
    if (!prevFilters || !currentFilters) {
      return true;
    }

    // get all keys from  object. Sometimes there are different properties in the object
    const keys = new Set([...Object.keys(currentFilters), ...Object.keys(prevFilters)]);
    // filter only unique keys
    const uniqueKeys = Array.from(keys);

    return uniqueKeys.some((key: string) => {
      return this.compareProperties(currentFilters[key], prevFilters[key]);
    });
  }

  private compareProperties<Q>(currentProperty: Q, previousProperty: Q): boolean {
    switch (typeof currentProperty) {
      case 'boolean':
      case 'number':
      case 'string':
      case 'undefined':
        // just compare values for primitives
        return currentProperty !== previousProperty;

      case 'object': {
        if (currentProperty === null) {
          return currentProperty !== previousProperty;
        }
        // compare values for array via recursion
        if (Array.isArray(currentProperty) && Array.isArray(previousProperty)) {
          if (currentProperty.length !== previousProperty.length) {
            return true;
          }
          return currentProperty
            .some((item, index) => this.compareProperties(item, previousProperty[index]));
        }
        // compare values for object via recursion
        return this.areFiltersChanged(currentProperty, previousProperty);
      }

      default:
        return true;
    }
  }

  private getUniqueActionsByColumnNames<T extends Action>(mapper: ColumnToActionMapper<T>,
                                                          columnNames: ColumnNames,
                                                          payload: interfaces.ILoadPayload | interfaces.LoadTotalsPayload): Map<string, T> {
    const map = new Map<string, T>();
    columnNames.forEach(columnName => {
      const action = mapper(columnName, payload);
      if (action && !map.has(action.type)) {
        map.set(action.type, action);
      }
    });

    return map;
  }

  // Map column name to correct campaign groups action with Driver or Secondary payload
  private getCampaignGroupsLoadActionMapper(columnName: ColumnName,
                                            payload: interfaces.ILoadPayload): Action {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.HasWebActivity:
        return campaignGroupsActions.hasWebActivity();
      case ResponseType.Attributed:
        return campaignGroupsActions.loadTypeAttribution(payload);
      case ResponseType.Cost:
        return campaignGroupsActions.loadTypeCosts(payload);
      case ResponseType.Influence:
        return campaignGroupsActions.loadTypeInfluence(payload);
      case ResponseType.Campaign:
        return campaignGroupsActions.loadTypeMetaData(payload);
      case ResponseType.Response:
        return campaignGroupsActions.loadTypeResponses(payload);
      case ResponseType.Returns:
        return campaignGroupsActions.loadTypeReturns(payload);
    }
  }

  // Map column name to correct campaign groups action with Driver or Secondary payload
  private getMediumsLoadActionMapper(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.Attributed:
        return mediumsActions.loadAttribution(payload);
      case ResponseType.Cost:
        return mediumsActions.loadCosts(payload);
      case ResponseType.Influence:
        return mediumsActions.loadInfluence(payload);
      case ResponseType.Campaign:
        return mediumsActions.loadMetaData(payload);
      case ResponseType.Response:
        return mediumsActions.loadResponses(payload);
      case ResponseType.Returns:
        return mediumsActions.loadReturns(payload);
    }
  }

  // Map column name to correct source action with Driver or Secondary payload
  private getSourceLoadActionMapper(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.Attributed:
        return sourceActions.loadAttribution(payload);
      case ResponseType.Cost:
        return sourceActions.loadCosts(payload);
      case ResponseType.Influence:
        return sourceActions.loadInfluence(payload);
      case ResponseType.Campaign:
        return sourceActions.loadMetaData(payload);
      case ResponseType.Response:
        return sourceActions.loadResponses(payload);
      case ResponseType.Returns:
        return sourceActions.loadReturns(payload);
    }
  }

  // Map column name to correct campaign groups action with Driver or Secondary payload
  private getCampaignsLoadActionMapper(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.HasWebActivity:
        return campaignsActions.hasWebActivity(payload);
      case ResponseType.Attributed:
        return campaignsActions.loadAttribution(payload);
      case ResponseType.Cost:
        return campaignsActions.loadCosts(payload);
      case ResponseType.Influence:
        return campaignsActions.loadInfluence(payload);
      case ResponseType.Campaign:
        return campaignsActions.loadMetaData(payload);
      case ResponseType.Response:
        return campaignsActions.loadResponses(payload);
      case ResponseType.Returns:
        return campaignsActions.loadReturns(payload);
    }
  }

  // Map column name to correct Campaign action with Driver or Secondary payload
  private getCampaignLoadActionMapper(columnName: ColumnName, payload: interfaces.ILoadPayload): Action {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.Attributed:
        return campaignActions.loadAttribution(payload);
      case ResponseType.Cost:
        return campaignActions.loadCosts(payload);
      case ResponseType.Influence:
        return campaignActions.loadInfluence(payload);
      case ResponseType.Campaign:
        return campaignActions.loadMetaData(payload);
      case ResponseType.Response:
        return campaignActions.loadResponses(payload);
      case ResponseType.Returns:
        return campaignActions.loadReturns(payload);
    }
  }

  // Map column name to correct totals action
  private getTotalsActionByColumn(columnName: ColumnName, payload: interfaces.LoadTotalsPayload): TotalsLoadActions | null {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.HasWebActivity:
        return null;
      case ResponseType.Attributed:
        return totalsActions.loadAttributionTotal({payload});
      case ResponseType.Cost:
        return totalsActions.loadCostsTotal({payload});
      case ResponseType.Influence:
        return totalsActions.loadInfluenceTotal({payload});
      case ResponseType.Campaign:
        return totalsActions.loadMetaDataTotal({payload});
      case ResponseType.Response:
        return totalsActions.loadResponsesTotal({payload});
      case ResponseType.Returns:
        return totalsActions.loadReturnsTotal({payload});
    }
  }

  // Map column name to correct web activities totals action
  private getWebActivitiesTotalsActionByColumn(columnName: ColumnName, payload: interfaces.LoadTotalsPayload): TotalsLoadActions | null {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.HasWebActivity:
      case ResponseType.Campaign:
        return null;
      case ResponseType.Attributed:
        return totalsActions.loadWebActivitiesAttributionTotal({payload});
      case ResponseType.Cost:
        return totalsActions.loadWebActivitiesCostsTotal({payload});
      case ResponseType.Influence:
        return totalsActions.loadWebActivitiesInfluenceTotal({payload});
      case ResponseType.Response:
        return totalsActions.loadWebActivitiesResponsesTotal({payload});
      case ResponseType.Returns:
        return totalsActions.loadWebActivitiesReturnsTotal({payload});
    }
  }

  // Map column name to correct web activities totals action
  private getSourceTotalsActionByColumn(columnName: ColumnName, payload: interfaces.LoadTotalsPayload): TotalsLoadActions | null {
    const responseType = getResponseTypeByColumn(columnName);
    switch (responseType) {
      case ResponseType.HasWebActivity:
      case ResponseType.Campaign:
        return null;
      case ResponseType.Attributed:
        return totalsActions.loadSourceAttributionTotal({payload});
      case ResponseType.Cost:
        return totalsActions.loadSourceCostsTotal({payload});
      case ResponseType.Influence:
        return totalsActions.loadSourceInfluenceTotal({payload});
      case ResponseType.Response:
        return totalsActions.loadSourceResponsesTotal({payload});
      case ResponseType.Returns:
        return totalsActions.loadSourceReturnsTotal({payload});
    }
  }
}
