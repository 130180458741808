import * as Keys from '../enums/insights-report-tab';

export const PAGE_VIEWS_INFLUENCED_TOTAL_TITLES = {
  [Keys.PageViewsInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.pageViewsInfluenced.adTactics.totalTitle',
  [Keys.PageViewsInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.pageViewsInfluenced.accounts.totalTitle'
};

export const PAGE_VIEWS_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.PageViewsInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.pageViewsInfluenced.adTactics.childTotalTitle',
  [Keys.PageViewsInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.pageViewsInfluenced.accounts.childTotalTitle'
};

export const LEADS_INFLUENCED_TOTAL_TITLES = {
  [Keys.LeadsInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.accounts.totalTitle',
  [Keys.LeadsInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.adTactics.totalTitle',
  [Keys.LeadsInfluencedReportTab.Leads]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.leads.totalTitle'
};

export const LEADS_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.LeadsInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.accounts.childTotalTitle',
  [Keys.LeadsInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.adTactics.childTotalTitle',
  [Keys.LeadsInfluencedReportTab.Leads]:
 'measurementStudio.features.advertisingInsights.table.leadsInfluenced.leads.childTotalTitle'
};

export const CAMPAIGN_RESPONSES_INFLUENCED_TOTAL_TITLES = {
  [Keys.CampaignResponsesInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.adTactics.totalTitle',
  [Keys.CampaignResponsesInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.accounts.totalTitle',
  [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.campaignResponses.totalTitle'
};

export const CAMPAIGN_RESPONSES_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.CampaignResponsesInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.adTactics.childTotalTitle',
  [Keys.CampaignResponsesInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.accounts.childTotalTitle',
  [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]:
 'measurementStudio.features.advertisingInsights.table.campaignResponsesInfluenced.campaignResponses.childTotalTitle'
};

export const NEW_PIPELINE_INFLUENCED_TOTAL_TITLES = {
  [Keys.NewPipelineInfluencedReportTab.Pipeline]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.pipeline.totalTitle',
  [Keys.NewPipelineInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.accounts.totalTitle',
  [Keys.NewPipelineInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.adTactics.totalTitle'
};

export const NEW_PIPELINE_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.NewPipelineInfluencedReportTab.Pipeline]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.pipeline.childTotalTitle',
  [Keys.NewPipelineInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.accounts.childTotalTitle',
  [Keys.NewPipelineInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.newPipelineInfluenced.adTactics.childTotalTitle'
};

export const PIPELINE_INFLUENCED_TOTAL_TITLES = {
  [Keys.PipelineInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.accounts.totalTitle',
  [Keys.PipelineInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.adTactics.totalTitle',
  [Keys.PipelineInfluencedReportTab.Pipeline]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.pipeline.totalTitle'
};

export const PIPELINE_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.PipelineInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.accounts.childTotalTitle',
  [Keys.PipelineInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.adTactics.childTotalTitle',
  [Keys.PipelineInfluencedReportTab.Pipeline]:
 'measurementStudio.features.advertisingInsights.table.pipelineInfluenced.pipeline.childTotalTitle'
};

export const REVENUE_INFLUENCED_TOTAL_TITLES = {
  [Keys.RevenueInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.accounts.totalTitle',
  [Keys.RevenueInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.adTactics.totalTitle',
  [Keys.RevenueInfluencedReportTab.Revenue]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.revenue.totalTitle'
};

export const REVENUE_INFLUENCED_NESTED_TOTAL_TITLES = {
  [Keys.RevenueInfluencedReportTab.Accounts]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.accounts.childTotalTitle',
  [Keys.RevenueInfluencedReportTab.AdTactics]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.adTactics.childTotalTitle',
  [Keys.RevenueInfluencedReportTab.Revenue]:
 'measurementStudio.features.advertisingInsights.table.revenueInfluenced.revenue.childTotalTitle'
};
