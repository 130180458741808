<div [tsLoadingOverlay]="isLoading$ | async">
  <ms-stage-influence-filters [filters]="filters$ | async"
                              [dateCohortOptions]="dateCohortOptions$ | async"
                              [selectedDateCohort]="selectedDateCohort$ | async"
                              [isLoading]="isLoading$ | async"
                              [modelTypeOptions]="modelTypeOptions$ | async"
                              (applyFilters)="applyFilters($event)"
                              (changeSelectedDateCohort)="changeSelectedDateCohort($event)">
  </ms-stage-influence-filters>
  <hr class="report-divider">
  <tsh-report-tabs [tabs]="tabs$ | async"></tsh-report-tabs>

  <router-outlet></router-outlet>
</div>
