import { AdsInsightsReportType } from './insights-report-types.enum';

export enum InsightsReportKey {
  PageViewsInfluenced = 'measurementStudio.features.advertisingInsights.reports.pageViewsInfluenced',
  LeadsInfluenced = 'measurementStudio.features.advertisingInsights.reports.leadsInfluenced',
  CampaignResponsesInfluenced = 'measurementStudio.features.advertisingInsights.reports.campaignResponsesInfluenced',
  NewPipelineInfluenced = 'measurementStudio.features.advertisingInsights.reports.newPipelineInfluenced',
  PipelineInfluenced = 'measurementStudio.features.advertisingInsights.reports.pipelineInfluenced',
  RevenueInfluenced = 'measurementStudio.features.advertisingInsights.reports.revenueInfluenced',
}

export const ReportKeyReportTypeMap = {
  [InsightsReportKey.PageViewsInfluenced]: AdsInsightsReportType.PageView,
  [InsightsReportKey.LeadsInfluenced]: AdsInsightsReportType.Lead,
  [InsightsReportKey.CampaignResponsesInfluenced]: AdsInsightsReportType.CampaignResponses,
  [InsightsReportKey.NewPipelineInfluenced]: AdsInsightsReportType.NewPipeline,
  [InsightsReportKey.PipelineInfluenced]: AdsInsightsReportType.Pipeline,
  [InsightsReportKey.RevenueInfluenced]: AdsInsightsReportType.Revenue
};
