import { AdminAccessTypes, NavPanelTypes, NavSectionTypes, RouteItemEnum } from '@shared/enums';
import { IMenuSection } from '@shared/interfaces';

// Section with is available only for BF admin
// This section is available for all application
export const INTERNAL_SECTION: IMenuSection = {
  section: NavSectionTypes.Internal,
  permission: [AdminAccessTypes.BFAdmin],
  items: [{
    icon: 'tools',
    type: NavPanelTypes.Basic,
    label: 'shared.navMenus.settings.internalConfiguration',
    routeId: RouteItemEnum.InternalConfiguration,
    routeItem: null
  }, {
    icon: 'people-arrows',
    type: NavPanelTypes.Popout,
    label: 'shared.navMenus.settings.switchUser'
  }, {
    icon: 'presentation',
    type: NavPanelTypes.Basic,
    label: 'shared.navMenus.settings.demo',
    routeId: RouteItemEnum.DemoConfig,
    routeItem: null
  }]
};
// NOTE: if settings feature flag is active
// then we show settings menu as basic panel
export const SETTINGS_MIGRATED: IMenuSection = {
  section: NavSectionTypes.Secondary,
  items: [{
    icon: 'cog',
    type: NavPanelTypes.Basic,
    label: 'shared.navMenus.settings.navLabel',
    routeId: RouteItemEnum.Settings,
    routeItem: null
  }]
};
// otherwise, show old version of settings
export const SETTINGS_LEGACY: IMenuSection = {
  section: NavSectionTypes.Secondary,
  items: [{
    icon: 'cog',
    type: NavPanelTypes.Drawer,
    label: 'shared.navMenus.settings.navLabel',
    categories: [
      {
        label: '',
        routeIds: [
          RouteItemEnum.Permissions,
          RouteItemEnum.Stages,
          RouteItemEnum.PagesAndFeatures,
          RouteItemEnum.DataHygiene,
          RouteItemEnum.CampaignScoring,
          RouteItemEnum.DigitalAttribution,
          RouteItemEnum.Configuration
        ],
        routeItems: []
      }
    ]
  }]
};
