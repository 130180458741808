import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import {
  IAttributionDeal,
  IAttributionLead,
  IAttributionOppty,
  ICampaignOrChannelAttribution,
  ICampaignSpecificFilters
} from '@measurement-studio/interfaces';
import { ICampaignChart, ICampaignChartType, ICampaignTouchReturns } from '../interfaces';
import { IDateCohort, IReportPagination } from '@shared/interfaces';
import { CampaignTab } from '../enums/campaign-tab.enum';
import { RouteItemEnum } from '@shared/enums';

export enum CampaignSpecificActionsTypes {
  SetCampaignAttribution = '[Campaign Specific] Set Campaign Attribution',
  LoadCampaignAttribution = '[Campaign Specific] Load Campaign Attribution',
  LoadCampaignAttributionSuccess = '[Campaign Specific] Load Campaign Attribution Success',
  LoadCampaignAttributionFailure = '[Campaign Specific] Load Campaign Attribution Failure',
  LoadIsOrphan = '[Campaign Is Orphan] Load Campaign Is Orphan',
  LoadIsOrphanSuccess = '[Campaign Is Orphan] Load Campaign Is Orphan Success',
  LoadIsOrphanFailure = '[Campaign Is Orphan] Load Campaign Is Orphan Failure',
  LoadCampaignTouches = '[Campaign Touches] Load Campaign Touches',
  LoadCampaignTouchesSuccess = '[Campaign Touches] Load Campaign Touches Success',
  LoadCampaignTouchesFailure = '[Campaign Touches] Load Campaign Touches Failure',
  LoadCampaignCohorts = '[Campaign Cohorts] Load Campaign Cohorts',
  LoadCampaignCohortsSuccess = '[Campaign Cohorts] Load Campaign Cohorts Success',
  LoadCampaignCohortsFailure = '[Campaign Cohorts] Load Campaign Cohorts Failure',
  LoadCampaignGroups = '[Campaign Groups] Load Campaign Groups',
  LoadCampaignGroupsSuccess = '[Campaign Groups] Load Campaign Groups Success',
  LoadCampaignGroupsFailure = '[Campaign Groups] Load Campaign Groups Failure',
  SetDateCohort = '[Campaign Selected Cohort] Set Campaign Cohort',
  GetCampaignDateCohort = '[Campaign Selected Cohort] Get Campaign Cohort',
  SetCampaignFilters = '[Campaign Specific Filters] Set Campaign Filters',
  GetCampaignFilters = '[Campaign Specific Filters] Get Campaign Filters',
  SetNestedReport = '[Campaign Specific] Set Campaign Specific Nested Table',
  SetInitialNestedTable = '[Campaign Specific] Set Campaign Specific Touches Table',
  GetDataForNestedTable = '[Campaign Specific] Get Data for nested table',
  CollectDataForNestedTable = '[Campaign Specific] Collect Data for Tab nested table',
  ToggleTableRow = '[Campaign Specific Table] Click on the table row to open or close nested table',
  SetReportPage = '[Campaign Specific Table] Change Table page',
  SetReportSortState = '[Campaign Specific Table] Change Table Sort',
  SetReportSearch = '[Campaign Specific Table] Change Table Search',
  ChangeSelectedChartType = '[Campaign Specific Chart] Change Chart Type',
  SetCampaignChartData = '[Campaign Specific Chart] Set Chart Data',
  ChangeNestedTableSortState = '[Campaign Specific Nested Table] Change Nested Table Sort',
  ChangeNestedTablePagination = '[Campaign Specific Nested Table] Change Nested Table Page',
  SetSelectedReport = '[Campaign Specific] Set selected report id',
  DownloadCSV = '[Campaign Specific] Download table CSV',
  DownloadNestedTable = '[Campaign Specific] Download nested table CSV',
}

const loadCampaignAttribution = createAction(
  CampaignSpecificActionsTypes.LoadCampaignAttribution
);

const loadCampaignAttributionSuccess = createAction(
  CampaignSpecificActionsTypes.LoadCampaignAttributionSuccess,
  props<{ attribution: ICampaignOrChannelAttribution }>()
);

const setCampaignAttribution = createAction(
  CampaignSpecificActionsTypes.SetCampaignAttribution,
  props<{ attribution: ICampaignOrChannelAttribution }>()
);

const loadCampaignAttributionFailure = createAction(
  CampaignSpecificActionsTypes.LoadCampaignAttributionFailure,
  props<{ message: string }>()
);

const loadIsOrphan = createAction(
  CampaignSpecificActionsTypes.LoadIsOrphan,
);

const loadIsOrphanSuccess = createAction(
  CampaignSpecificActionsTypes.LoadIsOrphanSuccess,
);

const loadIsOrphanFailure = createAction(
  CampaignSpecificActionsTypes.LoadIsOrphanFailure
);

const loadCampaignTouches = createAction(
  CampaignSpecificActionsTypes.LoadCampaignTouches
);

const loadCampaignTouchesSuccess = createAction(
  CampaignSpecificActionsTypes.LoadCampaignTouchesSuccess,
  props<{ touches: ICampaignTouchReturns[] }>()
);

const loadCampaignTouchesFailure = createAction(
  CampaignSpecificActionsTypes.LoadCampaignTouchesFailure,
  props<{ message: string }>()
);

const loadCampaignCohorts = createAction(
  CampaignSpecificActionsTypes.LoadCampaignCohorts
);

const loadCampaignCohortsSuccess = createAction(
  CampaignSpecificActionsTypes.LoadCampaignCohortsSuccess
);

const loadCampaignCohortsFailure = createAction(
  CampaignSpecificActionsTypes.LoadCampaignCohortsFailure,
);

const loadCampaignGroups = createAction(
  CampaignSpecificActionsTypes.LoadCampaignGroups
);

const loadCampaignGroupsSuccess = createAction(
  CampaignSpecificActionsTypes.LoadCampaignGroupsSuccess
);

const loadCampaignGroupsFailure = createAction(
  CampaignSpecificActionsTypes.LoadCampaignGroupsFailure
);

const setDateCohort = createAction(
  CampaignSpecificActionsTypes.SetDateCohort,
  props<{ cohort: IDateCohort }>()
)

const getCampaignDateCohort = createAction(
  CampaignSpecificActionsTypes.GetCampaignDateCohort,
  props<{ filters: ICampaignSpecificFilters }>()
);

const setCampaignFilters = createAction(
  CampaignSpecificActionsTypes.SetCampaignFilters,
  props<{ filters: ICampaignSpecificFilters }>()
);

const getCampaignFilters = createAction(
  CampaignSpecificActionsTypes.GetCampaignFilters,
  props<{ params: Params }>()
);

const toggleTableRow = createAction(
  CampaignSpecificActionsTypes.ToggleTableRow,
  props<{ key: CampaignTab, id: string }>()
);

const changeSelectedChartType = createAction(
  CampaignSpecificActionsTypes.ChangeSelectedChartType,
  props<{ chartType: ICampaignChartType }>()
);

const setReportPage = createAction(
  CampaignSpecificActionsTypes.SetReportPage,
  props<{ key: CampaignTab, page: number }>()
);

const setReportSortState = createAction(
  CampaignSpecificActionsTypes.SetReportSortState,
  props<{ key: CampaignTab, state: TsSortState }>()
);

const setReportSearch = createAction(
  CampaignSpecificActionsTypes.SetReportSearch,
  props<{ key: CampaignTab, search: string }>()
);

const setCampaignChartData = createAction(
  CampaignSpecificActionsTypes.SetCampaignChartData,
  props<{ chart: ICampaignChart }>()
);

const getDataForNestedTable = createAction(
  CampaignSpecificActionsTypes.GetDataForNestedTable,
  props<{ key: CampaignTab, id: string }>()
);

const collectDataForNestedTable = createAction(
  CampaignSpecificActionsTypes.CollectDataForNestedTable,
  props<{
    key: CampaignTab,
    report: (IAttributionLead | IAttributionDeal | IAttributionOppty)[],
    touches: ICampaignTouchReturns[]
  }>()
)

const setNestedReport = createAction(
  CampaignSpecificActionsTypes.SetNestedReport,
  props<{
    key: string,
    data: Record<string, ICampaignTouchReturns[]>,
    rowState: Record<string, boolean>,
    pagination: Record<string, IReportPagination>
  }>()
);

const setInitialNestedTable = createAction(
  CampaignSpecificActionsTypes.SetInitialNestedTable
)

const changeNestedTableSortState = createAction(
  CampaignSpecificActionsTypes.ChangeNestedTableSortState,
  props<{
    key: CampaignTab,
    id: string;
    state: TsSortState
  }>()
);

const changeNestedTablePagination = createAction(
  CampaignSpecificActionsTypes.ChangeNestedTablePagination,
  props<{
    key: CampaignTab,
    id: string;
    page: number
  }>()
)

const setSelectedReport = createAction(
  CampaignSpecificActionsTypes.SetSelectedReport,
  props<{ route: RouteItemEnum }>()
);

const downloadCSV = createAction(
  CampaignSpecificActionsTypes.DownloadCSV,
  props<{ key: CampaignTab }>()
);

const downloadNestedTable = createAction(
  CampaignSpecificActionsTypes.DownloadNestedTable,
  props<{ key: CampaignTab; id: string; name: string }>()
)

export const attributionActions = {
  loadCampaignAttribution,
  loadCampaignAttributionSuccess,
  setCampaignAttribution,
  loadCampaignAttributionFailure,
  loadIsOrphan,
  loadIsOrphanSuccess,
  loadIsOrphanFailure,
  loadCampaignTouches,
  loadCampaignTouchesSuccess,
  loadCampaignTouchesFailure,
  loadCampaignCohorts,
  loadCampaignCohortsSuccess,
  loadCampaignCohortsFailure,
  loadCampaignGroups,
  loadCampaignGroupsSuccess,
  loadCampaignGroupsFailure,
  setDateCohort,
  getCampaignDateCohort,
  setCampaignFilters,
  getCampaignFilters,
  setNestedReport,
  setInitialNestedTable,
  toggleTableRow,
  getDataForNestedTable,
  collectDataForNestedTable,
  changeSelectedChartType,
  setReportPage,
  setReportSortState,
  setReportSearch,
  setCampaignChartData,
  changeNestedTableSortState,
  changeNestedTablePagination,
  setSelectedReport,
  downloadCSV,
  downloadNestedTable,
};

