import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TranslateModule } from '@ngx-translate/core';

import { TypedDataModule } from '@ui/pipes/typed-data';
import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { BenchmarkControlComponent } from './benchmark-control.component';

@NgModule({
  declarations: [BenchmarkControlComponent],
  exports: [BenchmarkControlComponent],
  imports: [
    CommonModule,
    TsMenuModule,
    TsRadioGroupModule,
    TsInputModule,
    TsButtonModule,
    TranslateModule,
    TypedDataModule,
    ReactiveFormsModule,
    SharedUiPipesMemoizeModule,
  ],
})
export class BenchmarkControlModule {
}
