import { ILabelValue } from '@shared/interfaces';
import { DataSetType } from '@measurement-studio/enums';

export class DataSetOptions {
  private options: { [key: string]: ILabelValue<DataSetType> } = {
    membershipActivity: {
      label: 'feature.sharedTrending.filters.datasetTypeOptions.membershipActivity',
      value: DataSetType.MembershipActivity
    },
    opptyCloseDate: {
      label: 'feature.sharedTrending.filters.datasetTypeOptions.opptyCloseDate',
      value: DataSetType.OpptyCloseDate
    },
    opptyCreatedDate: {
      label: 'feature.sharedTrending.filters.datasetTypeOptions.opptyCreatedDate',
      value: DataSetType.OpptyCreatedDate
    },
    campaignCreatedDate: {
      label: 'feature.sharedTrending.filters.datasetTypeOptions.campaignCreatedDate',
      value: DataSetType.CampaignCreatedDate
    }
  };

  public getDataSetFullOptions(): ILabelValue[] {
    return [
      this.options.membershipActivity,
      this.options.campaignCreatedDate,
      this.options.opptyCloseDate,
      this.options.opptyCreatedDate,
    ];
  }

  public getCampaignDataOptions(): ILabelValue[] {
    return [
      this.options.membershipActivity,
      this.options.opptyCloseDate,
      this.options.opptyCreatedDate,
    ];
  }

  public getDateFieldFromDataset(dataSet: DataSetType): string {
    switch (dataSet) {
      case DataSetType.MembershipActivity:
        return 'activityDate';
      case DataSetType.OpptyCloseDate:
        return 'closeDate';
      case DataSetType.OpptyCreatedDate:
      default:
        return 'createdDate';
    }
  }
}
