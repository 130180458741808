import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './date-cohorts.reducer';
import { agoFromTodayGroupMap, agoRoundDownGroupMap, lastFullGroupMap, toDateGroupMap, ungrouppedMap } from '../date-cohorts.constants';
import { IDateCohort, IDateCohortGroup, IDateCohortResponse, ILabelValue } from '@shared/interfaces';
import { toCapitalize } from '@util/helpers';
import { DateCohortsGroups } from '@shared/enums';
import { LABEL_QUARTER, LABEL_YEAR } from '@shared/constants';

export const getDateCohortsState = createFeatureSelector<reducer.DateCohortsState>(reducer.dateCohortsFeatureKey);
export const getDateCohortsData = createSelector(getDateCohortsState, (state: reducer.DateCohortsState) => state.data);
export const getDateCohortIsLoaded = createSelector(getDateCohortsState, (state: reducer.DateCohortsState) => state.loaded);

// Get Date Cohort object by cohort
export const getDateCohortByCohort = (cohort: string) => createSelector(
  getDateCohortsData, (dateCohorts: IDateCohortResponse): IDateCohort => {
    return Object.values(dateCohorts)
      .flat()
      .find((item: IDateCohort) => item.cohort === cohort);
  });

// Get Date Cohort object for options
export const getDateCohorts = createSelector(
  getDateCohortsData, (dateCohorts: IDateCohortResponse): IDateCohortResponse => {
    return {
      ...dateCohorts,
      specialCohorts: dateCohorts.specialCohorts.map(cohort => {
        return {
          ...cohort,
          name: toCapitalize(cohort.name)
        };
      })
    };
  });

export const getDateQuarterCohortsOptions = createSelector(
  getDateCohortsData, (dateCohorts: IDateCohortResponse): ILabelValue<number>[] => {
    return dateCohorts.quarters.slice(1).map((_, index) => {
      return {
        label: (index + 1).toString(),
        value: index + 1
      };
    });
  });

export const getCurrentQuarter = createSelector(
  getDateCohortsData, (dateCohorts: IDateCohortResponse): IDateCohort => {
    return dateCohorts.quarters[0];
  });

// Get grouped date cohorts options
// If there are custom groups then return only them otherwise return all groups
export const getGroupedDateCohortOptions = (customGroups?: Array<DateCohortsGroups>) => createSelector(
  getDateCohorts, (dateCohorts: IDateCohortResponse): IDateCohortGroup[] => {
    const newDate = new Date();
    let agoFromTodayOptions;
    let allOption;
    let customOption;
    let toDateOptions;
    let lastFullOptions;
    let yearsOptions;
    let quartersOptions;
    let agoRoundDownOptions;

    if (!customGroups || !customGroups.length) {
      agoFromTodayOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => agoFromTodayGroupMap[cohort.cohort]);
      allOption = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => ungrouppedMap[cohort.cohort]);
      toDateOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => toDateGroupMap[cohort.cohort]);
      lastFullOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => lastFullGroupMap[cohort.cohort]);
      yearsOptions = dateCohorts.years;
      quartersOptions = dateCohorts.quarters;
      customOption = [{
        cohort: DateCohortsGroups.Custom,
        name: 'Custom Range',
        startDate: +newDate,
        endDate: +newDate
      }];
    }

    if (customGroups?.includes(DateCohortsGroups.Ago)) {
      agoFromTodayOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => agoFromTodayGroupMap[cohort.cohort]);
    }

    if (customGroups?.includes(DateCohortsGroups.Custom)) {
      customOption = [{
        cohort: DateCohortsGroups.Custom,
        name: 'Custom Range',
        startDate: +newDate,
        endDate: +newDate
      }];
    }

    if (customGroups?.includes(DateCohortsGroups.All)) {
      allOption = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => ungrouppedMap[cohort.cohort]);
    }

    if (customGroups?.includes(DateCohortsGroups.Years)) {
      yearsOptions = dateCohorts.years;
    }

    if (customGroups?.includes(DateCohortsGroups.Quarters)) {
      quartersOptions = dateCohorts.quarters;
    }

    if (customGroups?.includes(DateCohortsGroups.AgoRoundDown)) {
      agoRoundDownOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => agoRoundDownGroupMap[cohort.cohort]);
    }

    if (customGroups?.includes(DateCohortsGroups.LastFull)) {
      lastFullOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => lastFullGroupMap[cohort.cohort]);
    }

    if (customGroups?.includes(DateCohortsGroups.ToDate)) {
      toDateOptions = dateCohorts.specialCohorts.filter((cohort: IDateCohort) => toDateGroupMap[cohort.cohort]);
    }

    return [
      {
        label: ungrouppedMap.label,
        options: customOption
      },
      {
        label: ungrouppedMap.label,
        options: allOption
      },
      {
        label: toDateGroupMap.label,
        options: toDateOptions
      },
      {
        label: lastFullGroupMap.label,
        options: lastFullOptions
      },
      {
        label: agoFromTodayGroupMap.label,
        options: agoFromTodayOptions
      },
      {
        label: agoRoundDownGroupMap.label,
        options: agoRoundDownOptions
      },
      {
        label: LABEL_QUARTER,
        options: quartersOptions
      },
      {
        label: LABEL_YEAR,
        options: yearsOptions
      }
    ].filter(option => !!option.options?.length); // return only groups with options
  });
