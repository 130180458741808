import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { DefaultTileInformation, IDashboardTile, ITileData, ITileVisualizationConfig } from '@shared/interfaces';
import { CommonModule } from '@angular/common';
import { TileTypes, VisualizationTypes } from '@shared/enums';
import { TileModule } from '@shared/features/tile';
import {
  CLUSTERED_TILE_DATA,
  CLUSTERED_VISUALIZATION,
  COLUMN_TILE_DATA,
  COLUMN_VISUALIZATION,
  LINE_TILE_DATA,
  LINE_VISUALIZATION,
  TABLE_TILE_DATA,
  TABLE_VISUALIZATION
} from './fake-tile.data';

@Component({
  selector: 'tsh-fake-tile',
  templateUrl: './fake-tile.component.html',
  styleUrls: ['./fake-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FakeTileComponent implements AfterContentInit {
  @Input()
  public set type(value: TileTypes) {
    this.initData(value);
    this.createView();
  }

  @Input()
  public set defaultInfo(value: DefaultTileInformation) {
    this.tile = {
      id: `id-${value?.type || ''}`,
      name: value?.name || 'Tile Name',
      settings: {
        cohort: 'days90',
        visualization: value?.settings.visualization || VisualizationTypes.Table
      }
    };
  }

  public visualizationConfig: ITileVisualizationConfig;
  public data: ITileData;
  public tile: Partial<IDashboardTile>;

  @ViewChild('container', {read: ViewContainerRef, static: true}) container: ViewContainerRef;
  @ViewChild('fakeTile', {static: true}) fakeTile: TemplateRef<unknown>;

  ngAfterContentInit(): void {
    this.createView();
  }

  private initData(type: TileTypes): void {
    switch (type) {
      case TileTypes.WebActivitiesSpecific:
      case TileTypes.CampaignSpecific:
      case TileTypes.CampaignTrends:
      case TileTypes.WebActivitiesTrending:
      case TileTypes.ScorecardTrending:
        this.data = LINE_TILE_DATA;
        this.visualizationConfig = LINE_VISUALIZATION;
        break;

      case TileTypes.MarketingInfluence:
      case TileTypes.EngagementInsights:
        this.data = CLUSTERED_TILE_DATA;
        this.visualizationConfig = CLUSTERED_VISUALIZATION;
        break;

      case TileTypes.StagesSnapshot:
        this.data = COLUMN_TILE_DATA;
        this.visualizationConfig = COLUMN_VISUALIZATION;
        break;

      case TileTypes.TopCampaigns:
      case TileTypes.TopCampaignTypes:
      case TileTypes.OpportunityInsights:
      case TileTypes.AccountsList:
      case TileTypes.WebActivitiesChannel:
      case TileTypes.WebActivitiesChannelAssets:
      default:
        this.data = TABLE_TILE_DATA;
        this.visualizationConfig = TABLE_VISUALIZATION;
        break;
    }
  }

  private createView(): void {
    if (this.container) {
      this.container.clear();
      this.container.createEmbeddedView(this.fakeTile);
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    TileModule,
  ],
  declarations: [FakeTileComponent],
  exports: [FakeTileComponent],
})
export class FakeTileModule {
}
