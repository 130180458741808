<ts-tab-collection (selectedTabChange)="handleSelectedTabChange($event)"
                   [selectedIndex]="selectedTab">
  <ts-tab [label]="'measurementStudio.features.scorecard.tabs.overview' | translate">
    <ng-template tsTabContent></ng-template>
  </ts-tab>
  <ts-tab *ngIf="hasTrendingReportAccess$ | async"
          [label]="'measurementStudio.features.scorecard.tabs.trending' | translate">
    <ng-template tsTabContent></ng-template>
  </ts-tab>
</ts-tab-collection>
<router-outlet></router-outlet>

<ng-template [tshAttachTo]="targets.HeaderButtons">
  <ts-button *ngIf="selectedTab === 1"
             actionName="Button"
             buttonType="button"
             (clicked)="addToDashboardDialog()">
    {{ 'appHeader.addToDashboard' | translate }}
  </ts-button>
  <ts-button *ngIf="canAddList$ | async"
             actionName="Button"
             buttonType="button"
             theme="default"
             class="add-button"
             (clicked)="goToScorecardList()"
             data-cy="scorecard-add-list-button">
    {{ 'measurementStudio.features.scorecard.addListButton' | translate }}
  </ts-button>
</ng-template>
