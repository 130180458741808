import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { iif, Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';

import { WebActivitiesService } from '../../services/web-activities.service';
import { campaignsActions } from '../campaigns/campaigns.actions';
import * as selectors from './campaign.selectors';
import * as generalSelectors from '../general/general.selectors';
import * as interfaces from '../../interfaces';
import { campaignActions, CampaignLoadActions } from './campaign.actions';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as campaignAnalyticsSelectors from '../selectors';
import { notificationMessagesActions } from '@notification-messages';
import { AnalyticsHelperService } from '../../helpers/analytics-helper.service';
import { IAnalyticsGlobalFilters, IPager, ISorter, userMessageFactory } from '@shared/interfaces';
import { checkDataByColumnName, getDriverRequestParams, getSecondaryRequestParams } from '../../helpers/store.helper';
import { AnalyticsColumnName } from '../../enums';
import { generalActions } from '../general/general.actions';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { getCampaignSearchByTags } from '../general/general.selectors';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  ISorter,
  IPager,
  string[],
  string,
  boolean,
];

type LoadRequestParams<A extends Action> = [
  A,
  IAnalyticsRequest,
  (TypedAction<string>)[]?,
];

@Injectable()
export class CampaignEffects {
  constructor(public webActivitiesService: WebActivitiesService,
              private analyticsHelperService: AnalyticsHelperService,
              private store: Store<unknown>,
              private actions$: Actions) {
  }

  public getCampaign$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.getCampaign),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    switchMap(([_, filters, globalFilters, sorter, pager, recordIds, search, searchByTags]: LatestFilters<typeof campaignActions.getCampaign>) => {
      const requestParams = this.getRequestParams({isDriver: true}, filters, globalFilters, sorter, pager, search, recordIds, searchByTags);
      return of(requestParams).pipe(withLatestFrom(this.store.pipe(select(selectors.getCampaignUsedParams))));
    }),
    filter(([currentFilters, prevFilters]: IAnalyticsRequest[]) => {
      return this.analyticsHelperService.areFiltersChanged(currentFilters, prevFilters);
    }),
    map(([currentFilters]: IAnalyticsRequest[]) =>
      this.analyticsHelperService.getCampaignLoadAction(currentFilters.sorter.sortField, {isDriver: true}),
    )
  ));

  public loadMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadMetaData),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignMetaData$(requestParams).pipe(
        mergeMap((data: interfaces.IMetaData<interfaces.IWebActivitiesCampaignResponse>) => [
          campaignActions.loadMetaDataSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignMetaData';
          return of(campaignActions.loadMetaDataFailure({message}));
        })
      );
    })
  ));

  public loadResponses$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadResponses),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignResponses$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignResponses) => [
          campaignActions.loadResponsesSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignResponses';
          return of(campaignActions.loadResponsesFailure({message}));
        })
      );
    })
  ));

  public loadAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadAttribution),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignAttribution$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignAttribution) => [
          campaignActions.loadAttributionSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignAttribution';
          return of(campaignActions.loadAttributionFailure({message}));
        })
      );
    })
  ));

  public loadInfluence$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadInfluence),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignInfluence$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignInfluence) => [
          campaignActions.loadInfluenceSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignInfluence';
          return of(campaignActions.loadInfluenceFailure({message}));
        })
      );
    })
  ));

  public loadReturns$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadReturns),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignReturns$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignReturns) => [
          campaignActions.loadReturnsSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignReturns';
          return of(campaignActions.loadReturnsFailure({message}));
        })
      );
    })
  ));

  public loadCosts$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.loadCosts),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<CampaignLoadActions>) => {
      return this.webActivitiesService.getCampaignCosts$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignCosts) => [
          campaignActions.loadCostsSuccess({data, isDriver: action.isDriver, parentId: action.parentId}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCampaignCosts';
          return of(campaignActions.loadCostsFailure({message}));
        })
      );
    })
  ));

  public onChangeCampaignConfig$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.changeCampaignSearch,
      generalActions.changeCampaignPagination,
      generalActions.changeCampaignSorting,
      generalActions.changeCampaignSearchByTags,
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getCampaignSorter)),
    ))),
    mergeMap(([_, sorter]) => [
      this.analyticsHelperService.getCampaignLoadAction(sorter.sortField, {isDriver: true}),
      campaignActions.closeNestedTables(),
    ])
  ));

  public onChangeNestedSortingOrPagination$ = createEffect(() => this.actions$.pipe(
    ofType(campaignActions.changeNestedPagination, campaignActions.changeNestedSorting),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(selectors.getNestedSorter, action.data.id)),
    ))),
    map(([action, sorter]) =>
      this.analyticsHelperService.getCampaignLoadAction(sorter.sortField, {isDriver: true, parentId: action.data.id}))
  ));

  public onToggleTableRow$ = createEffect(() => this.actions$.pipe(
    ofType(campaignsActions.toggleTableRow),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getNestedCampaign))),
    )),
    filter(([action, state]) => !state.data[action.id]),
    map(([action, state]) => {
      const sortField = state.sorter[action.id]?.sortField || AnalyticsColumnName.Responses;
      return this.analyticsHelperService.getCampaignLoadAction(sortField, {isDriver: true, parentId: action.id});
    })
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleCampaignColumnVisibility),
    concatMap(action => of(action).pipe(withLatestFrom(
      iif(
        () => !!action.parentId,
        this.store.pipe(select(selectors.getNestedDataById, action.parentId)),
        this.store.pipe(select(selectors.getReportData)),
      ).pipe(
        map((data: interfaces.ICampaignIdentifiable[]) => checkDataByColumnName(data, action.data.name)),
      )
    ))),
    // do not dispatch load action if data is present in state
    filter(([_, data]) => !data.length),
    concatMap(([action]) =>
      of(action).pipe(withLatestFrom(this.store.pipe(select(campaignAnalyticsSelectors.getCampaignsExpandedRowIds))))
    ),
    // dispatch actions to load extra data
    mergeMap(([action, parentIds]) => {
      const parentId = action.parentId;
      const columnName = action.data.name;
      if (parentIds.length && parentId) {
        // dispatch action for each visible nested table
        return parentIds.map(id => this.analyticsHelperService.getCampaignLoadAction(columnName, {isDriver: false, parentId: id}));
      }
      return [this.analyticsHelperService.getCampaignLoadAction(columnName, {isDriver: false, parentId})];
    })
  ));

  public onLoadDriverSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignActions.loadAttributionSuccess,
      campaignActions.loadCostsSuccess,
      campaignActions.loadInfluenceSuccess,
      campaignActions.loadMetaDataSuccess,
      campaignActions.loadResponsesSuccess,
      campaignActions.loadReturnsSuccess,
    ),
    filter((action) => action.isDriver && !!action.data.totalResults),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(action.parentId
        ? generalSelectors.getVisibleCampaignNestedColumnNames
        : generalSelectors.getVisibleCampaignColumnNames)),
    ))),
    mergeMap(([action, columnNames]) => {
      const secondaryRequests = this.analyticsHelperService.getCampaignSecondaryLoadActions(action, columnNames);
      // return secondaryRequests if they exists, otherwise make totals requests
      return secondaryRequests.length
        ? secondaryRequests
        : this.analyticsHelperService.getWebActivitiesTotalsLoadActions(columnNames, action.parentId || 'campaign');
    }),
  ));

  public onLoadSecondarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignActions.loadAttributionSuccess,
      campaignActions.loadCostsSuccess,
      campaignActions.loadInfluenceSuccess,
      campaignActions.loadMetaDataSuccess,
      campaignActions.loadResponsesSuccess,
      campaignActions.loadReturnsSuccess
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(campaignAnalyticsSelectors.getUnloadedTotalsColumnNames(action.parentId
        ? generalSelectors.getVisibleCampaignNestedColumns
        : generalSelectors.getVisibleCampaignColumns), {key: 'campaign'})),
      this.store.pipe(select(selectors.getCampaignIsLoading)),
    ))),
    filter(([action, _, isLoading]) => !action.isDriver && !isLoading),
    switchMap(([action, columnNames]) =>
      this.analyticsHelperService.getWebActivitiesTotalsLoadActions(columnNames, action.parentId || 'campaign')
    ),
  ));

  public onChangeFiltersState$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.setFilters),
    mergeMap(() => [
      campaignActions.clearNestedState(),
      campaignActions.closeNestedTables(),
    ]),
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      campaignActions.loadMetaDataFailure,
      campaignActions.loadResponsesFailure,
      campaignActions.loadAttributionFailure,
      campaignActions.loadInfluenceFailure,
      campaignActions.loadReturnsFailure,
      campaignActions.loadCostsFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  private withLatestFilters<T extends Action>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(generalSelectors.getCampaignSorter)),
        this.store.pipe(select(generalSelectors.getCampaignPager)),
        this.store.pipe(select(selectors.getCampaignRecordIds)),
        this.store.pipe(select(generalSelectors.getCampaignSearch)),
        this.store.pipe(select(generalSelectors.getCampaignSearchByTags)),
      )
    );
  }

  private withLatestNestedFilters<T extends CampaignLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(selectors.getNestedSorter, action.parentId)),
        this.store.pipe(select(selectors.getNestedPager, action.parentId)),
        this.store.pipe(select(selectors.getNestedRecordIds, action.parentId)),
        of(''), // mock empty search for nested table,
      ),
    );
  }

  private mapToRequestAndUsedParamsAction<T extends CampaignLoadActions>(
    [action, filters, globalFilters, sorter, pager, recordIds, searchParam, searchByTags]: LatestFilters<T>
  ): LoadRequestParams<T> {
    const requestParams =
      this.getRequestParams(action, filters, globalFilters, sorter, pager, searchParam, recordIds, searchByTags);
    const usedParamsAction = action.isDriver ? [campaignActions.setUsedParams({request: requestParams})] : [];
    return [action, requestParams, usedParamsAction];
  }

  private getRequestParams(payload: interfaces.ILoadPayload,
                           filters: IAnalyticsRequest,
                           globalFilters: IAnalyticsGlobalFilters,
                           sorter: ISorter,
                           pager: IPager,
                           searchParam: string,
                           recordIds: string[],
                           searchByTags: boolean): IAnalyticsRequest {
    const requestParams = payload.isDriver
      ? getDriverRequestParams(filters, sorter, pager, searchByTags ? '' : searchParam)
      : getSecondaryRequestParams(filters, recordIds);
    if (searchByTags && searchParam && payload.isDriver) {
      requestParams.tagNames = [searchParam];
    }
    if (payload.parentId) {
      requestParams.crossTypeFilter = payload.parentId;
      delete requestParams.typeFilter;
    }
    requestParams.globalFilters = globalFilters || [];
    return requestParams;
  }
}
