import { All, DataTypeEnum, ModelType, RouteItemEnum } from '@shared/enums';
import { AnalyticsColumnName as Column } from '../enums';
import { IAnalyticsColumn, ICampaignsTableItem } from '../interfaces';
import * as sharedColumns from './shared-columns.data';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { IReportLink } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { DataSetType } from '@measurement-studio/enums';

export const CAMPAIGNS_COLUMNS: IAnalyticsColumn[] = [
  {
    name: Column.CampaignName,
    width: 220,
    displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.campaignName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: ICampaignsTableItem, filters: IAnalyticsRequest): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
        queryParams: {
          name: row.campaignName,
          campaignId: row.groupId,
          modelType: filters.model,
          cohort: filters.dataSet === DataSetType.CampaignCreatedDate
            ? All.ALL_TIME
            : filters.cohort.cohort,
          startDate: filters.cohort.startDate,
          endDate: filters.cohort.endDate,
          dataSet: filters.dataSet,
          influenceType: filters.influenceType,
        }
      };
    },
    visible: true,
    models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
  },
  {
    name: Column.CampaignType,
    width: 200,
    displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.campaignType',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: ICampaignsTableItem, filters: IAnalyticsRequest): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignAnalyticsCampaigns),
        queryParams: {
          dataSet: filters.dataSet,
          model: filters.model,
          cohort: filters.cohort.cohort,
          startDate: filters.cohort.startDate,
          endDate: filters.cohort.endDate,
          gf: row.campaignType,
        }
      };
    },
    visible: true,
    models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
  },
  {
    name: Column.CreatedDate,
    width: 150,
    displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.createdDate',
    dataType: DataTypeEnum.Date,
    visible: true,
    startSorting: 'asc',
    models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
  },
  sharedColumns.responses,
  sharedColumns.people,
  sharedColumns.accounts,
  sharedColumns.opptys,
  sharedColumns.deals,
  sharedColumns.opptyTouches,
  sharedColumns.dealTouches,
  sharedColumns.attributedPipeline,
  sharedColumns.attributedRevenue,
  sharedColumns.influencedPipeline,
  sharedColumns.influencedRevenue,
  sharedColumns.LTO,
  sharedColumns.CPL,
  sharedColumns.CPO,
  sharedColumns.CPD,
  sharedColumns.cost,
  sharedColumns.pipelineRoi,
  sharedColumns.ROI,
  sharedColumns.OTD,
  sharedColumns.totalTouches,
];

