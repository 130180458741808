<div class="hub-modal-header">
  <h3 class="hub-modal-title" data-cy="tile-dialog-title">
    {{ (isEditMode ? 'shared.dashboards.dialog.editTile' : 'shared.dashboards.dialog.addTile') | translate }}
  </h3>
  <button type="button"
          data-cy="tile-dialog-close"
          aria-label="Close"
          title="Close"
          class="close-button"
          (click)="closeModal()">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="container" *ngIf="form">
  <div class="background-image"></div>
  <div class="main-content">
    <tsh-steps-navigation *ngIf="!isEditMode"
                          data-cy="tile-dialog-steps"
                          [step]="data.step"
                          (back)="handleBack($event)">
    </tsh-steps-navigation>

    <ng-container [ngSwitch]="data.step">
      <ng-container *ngSwitchCase="steps.Type">
        <tsh-select-tile-type (changeType)="changeType($event)"
                              data-cy="tile-dialog-types"
                              [selectedType]="form.get(fields.Type).value"
                              [tiles]="tilesByCategory$ | async">
        </tsh-select-tile-type>
      </ng-container>

      <ng-container *ngSwitchCase="steps.Dashboard">
        <tsh-select-dashboard [control]="form.get(fields.Dashboard)"
                              [items]="dashboards$ | async">
        </tsh-select-dashboard>
      </ng-container>

      <ng-container *ngSwitchCase="steps.Settings">
        <tsh-tile-settings-form [form]="form"
                                [selectedCohort]="selectedCohort$ | async"
                                [dateCohortOptions]="dateCohortOptions$ | async"
                                [settingsFilters]="settingsFilters$ | async"
                                [allSelectFilters]="allSelectFilters$ | async"
                                [globalFilters]="globalFilters$ | async"
                                [globalFiltersSearchState]="globalFilterSearchState$ | async"
                                [accountListsSearchState]="accountListsSearchState$ | async"
                                [globalFiltersState]="globalFilterMenuState$ | async"
                                [accountListFoldersState]="accountListsMenuState$ | async"
                                [accountListFolders]="accountListFolders$ | async"
                                [savedGlobalFilters]="savedGlobalFilters$ | async"
                                [appliedFiltersNumber]="appliedFiltersNumber$ | async"
                                [showGlobalFiltersSection]="showGlobalFiltersSection"
                                [selectedGlobalFilters]="selectedGlobalFilters$ | async"
                                [isHighlightedErrors]="isHighlightedErrors"
                                (removeSelectedFolder)="removeSelectedFolder($event)"
                                (toggleFolderSelect)="toggleFolderSelect($event)"
                                (toggleFolderItemSelect)="toggleFolderItemSelect($event)"
                                (applySavedGlobalFilter)="applySavedGlobalFilter($event)"
                                (apply)="updateDateCohort($event)"
                                (searchGlobalFilters)="searchGlobalFilters($event)"
                                (dateRangeFilterChange)="updateDateCohort($event)">
        </tsh-tile-settings-form>
      </ng-container>
    </ng-container>
  </div>
  <aside class="sidebar">
    <ng-container *ngIf="allTiles">
      <div class="sidebar-content">
        <tsh-preview-header [step]="data.step"
                            [tile]="allTiles[form.get(fields.Type).value]">
        </tsh-preview-header>
        <div *ngIf="data.step === steps.Type; else tilePreview"
             class="preview-container">
          <tsh-fake-tile data-cy="tile-dialog-fake-tile"
                         [type]="form.get(fields.Type).value"
                         [defaultInfo]="allTiles[form.get(fields.Type).value]">
          </tsh-fake-tile>
        </div>
        <ng-template #tilePreview>
          <div data-cy="tile-dialog-tile-preview"
               class="preview-container"
               [tsLoadingOverlay]="isLoadingPreview$ | async"
               [ngClass]="{'changed': needRefreshPreview}">
            <tsh-tile [tile]="getPreparedTile()"
                      [visualizationConfig]="visualizationConfig$ | async"
                      [error]="errorPreview$ | async"
                      [loading]="isLoadingPreview$ | async"
                      [data]="previewData$ | async">
            </tsh-tile>
          </div>
        </ng-template>
        <tsh-alert *ngIf="needRefreshPreview">
          <span class="alert-refresh">
            <i class="fas fa-info-circle"></i>
            <strong>{{ 'shared.dashboards.dialog.configurationsChanged' | translate }}</strong>
            <button (click)="refreshPreview()"
                    data-cy="tile-dialog-preview-refresh"
                    [disabled]="form.invalid"
                    type="button"
                    [title]="'shared.dashboards.dialog.refreshPreview' | translate">
                {{ 'shared.dashboards.dialog.refreshPreview' | translate }}
            </button>
          </span>
        </tsh-alert>
        <tsh-visualization-preview-types *ngIf="data.step !== steps.Settings"
                                         data-cy="tile-dialog-preview-types"
                                         [tile]="allTiles[form.get(fields.Type).value]">
        </tsh-visualization-preview-types>
      </div>
      <div class="sidebar-footer">
        <ts-button theme="secondary" (clicked)="closeModal()" data-cy="tile-dialog-cancel">
          {{ 'shared.common.cancel' | translate }}
        </ts-button>
        <ts-button (clicked)="handleStepAction()"
                   data-cy="tile-dialog-confirm"
                   [isDisabled]="data.step === steps.Dashboard && form.get(fields.Dashboard).invalid">
          {{(getButtonLabel | memoize: isEditMode: data.step) | translate}}
        </ts-button>
      </div>
    </ng-container>
  </aside>
</div>
