import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';

import { ReportTabsModule } from '@ui/components/report-tabs';
import { AnalyticsFiltersModule } from '../../components/filters/analytics-filters.module';
import { SharedAnalyticsModule } from '../../shared-analytics.module';
import { WebActivitiesComponent } from './containers/web-activities.component';
import { WebActivitiesRoutingModule } from './web-activities-routing.module';

@NgModule({
  declarations: [WebActivitiesComponent],
  imports: [
    CommonModule,
    WebActivitiesRoutingModule,
    SharedAnalyticsModule,
    AnalyticsFiltersModule,
    ReportTabsModule,
    TsLoadingOverlayModule,
  ],
})
export class WebActivitiesModule {
}
