import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IWebActivitiesTypes, IWfStage } from '../interfaces';

export enum CatalogsURLs {
  WebActivityTypes = '/v1/get_web_activity_types',
  WfStages = '/api/v1/load_wf_stages',
}

@Injectable({providedIn: 'root'})
export class CatalogsSource extends HttpClientBase {

  getWebActivitiesTypes$(): Observable<IWebActivitiesTypes> {
    const endpoint = this.endpoint(CatalogsURLs.WebActivityTypes);

    const body = {};
    return this.httpClient.post<IWebActivitiesTypes>(endpoint, body);
  }

  getStages$(): Observable<IWfStage[]> {
    const endpoint = this.endpoint(CatalogsURLs.WfStages);

    return this.httpClient.get<IWfStage[]>(endpoint);
  }
}
