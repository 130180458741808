import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { GLOBAL_FILTERS_KEYS } from '@shared/constants';
import { UserDefinedSegment } from '@shared/data-access/segments-api';
import { IAccountFolder, IGlobalFilterFolder, IGlobalFilterFolderItem, IGlobalFilters, ITargetFolderItem } from '@shared/interfaces';

@Component({
  selector: 'tsh-global-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalFiltersListComponent {
  @Input() public globalFilters: IGlobalFilters;
  @Input() public filterMenuState: IGlobalFilterFolder;
  @Input() public accountListFolder: IAccountFolder[];
  @Input() public accountListFolderItem: IGlobalFilterFolderItem;
  @Input() public segments: UserDefinedSegment[];

  @Output() public selectFilterFolder: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();
  @Output() public selectFilterFolderItem: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();
  @Output() public toggleFilterFolderMenu: EventEmitter<ITargetFolderItem> = new EventEmitter<ITargetFolderItem>();
  @Output() public segmentSelectionChange: EventEmitter<Set<string>> = new EventEmitter<Set<string>>();

  public readonly filterKeys = GLOBAL_FILTERS_KEYS;
}
