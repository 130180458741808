import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TsMenuComponent } from '@terminus-lib/ui-menu';

import { ReportFilterChange } from '@shared/classes';

@Component({
  selector: 'tsh-report-filter-input',
  templateUrl: './report-filter-input.component.html',
  styleUrls: ['./report-filter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFilterInputComponent extends ReportFilterChange {
  /**
   * Filter name
   */
  @Input() public label: string;
  /**
   * Form Control
   */
  @Input()
  public set field(control: FormControl) {
    this._control = control;
    if (control.value) {
      this.inputValue.setValue(control.value);
    }
  }
  public get field(): FormControl {
    return this._control;
  }
  /**
   * Whether we're expecting a numeric value (note, this will mask the input)
   */
  @Input() public isNumeric = false;
  /**
   * Allows decimals in inputted numeric values
   */
  @Input() public allowDecimal = false;

  @ViewChild(TsMenuComponent) public menu!: TsMenuComponent;

  public inputValue = new FormControl(null);
  private _control: FormControl;

  confirmDialog(): void {
    this.change$.next(true);
    // update form control with value only after confirmation
    this.field.setValue(this.inputValue.value)
    this.menu.trigger.closeMenu();
  }

  cancelDialog(): void {
    // reset input value
    this.inputValue.setValue(this.field.value);
    this.menu.trigger.closeMenu();
  }
}

