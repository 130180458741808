import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentTokenDirective } from './treatment-token.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TreatmentTokenDirective],
  exports: [TreatmentTokenDirective],
})
export class TreatmentTokenModule {}
