import { AggregateList } from '@shared/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { aggregateListsActions } from './aggregate-lists.actions';

export const aggregateListsFeatureKey = 'aggregateLists';

export interface AggregateListsState {
  lists: AggregateList[] | null;
  segments: AggregateList[] | null;
}

export const initialState: AggregateListsState = {
  lists: null,
  segments: null,
};

const aggregateListsReducer = createReducer(
  initialState,
  on(aggregateListsActions.loadAggregateListsSuccess, (state, action) => ({
    ...state,
    lists: action.data
  })),
  on(aggregateListsActions.loadAggregateSegmentsSuccess, (state, action) => ({
    ...state,
    segments: action.data
  })),
  on(aggregateListsActions.resetAggregateLists, (state) => ({
    ...state,
    lists: null,
    segments:null
  })),
);

export function reducer(state: AggregateListsState | undefined, action: Action): AggregateListsState {
  return aggregateListsReducer(state, action);
}
