import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { userMessageFactory } from '@shared/interfaces';
import { influencedInsightsActions } from './influenced-insights.actions';
import * as InfluencedInsightsSelectors from './influenced-insights.selectors';
import * as InsightsFiltersSelectors from '../insights-filters/insights-filters.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { IAdsInfluencedInsights, IAdsInfluencedInsightsDetailsResponse } from '@measurement-studio/interfaces';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { IAdsInfluencedAddToListRequest } from '../../interfaces';
import { InfluencedInsightsState } from './influenced-insights.reducer';
import { InfluencedInsightsService } from '../../services/influenced-insights/influenced-insights.service';

@Injectable()
export class InfluencedInsightsEffects {
  constructor(private actions$: Actions,
              private store: Store<InfluencedInsightsState>,
              private influencedInsightsService: InfluencedInsightsService,
              private downloadService: DownloadCsvService
  ) {
  }

  public loadInfluencedInsights$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.loadInfluencedInsights),
    concatMap(action => of(action.params).pipe(
      withLatestFrom(
        this.store.pipe(select(InsightsFiltersSelectors.getSelectedDateCohort$)),
        this.store.pipe(select(InsightsFiltersSelectors.getInsightsReportFilters$))
      )
    )),
    mergeMap(([query, cohort, filters]) => {
      return this.influencedInsightsService.getInfluencedInsights$({
        ...filters,
        cohort,
        ...query
      }).pipe(
        map((insights: IAdsInfluencedInsights) => influencedInsightsActions.loadInfluencedInsightsSuccess({
          key: query.reportType,
          insights
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'Unable to load account lists. Please try again later.';
          return of(influencedInsightsActions.loadInfluencedInsightsFailure({
            key: query.reportType,
            message: `Error: ${message}`
          }));
        })
      );
    })
  ));


  public loadInfluencedInsightsDetails$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.loadInfluencedInsightsDetails),
    concatLatestFrom(() => this.store.pipe(select(InfluencedInsightsSelectors.buildQueryParams$))),
    switchMap(([action, reqFunc]) => {
      return this.influencedInsightsService.getInfluencedInsightsDetails$(reqFunc(action.reportType)).pipe(
        map((details: IAdsInfluencedInsightsDetailsResponse) => influencedInsightsActions.loadInfluencedInsightsDetailsSuccess({
          key: action.reportType,
          details
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'Unable to load account lists. Please try again later.';
          return of(influencedInsightsActions.loadInfluencedInsightsDetailsFailure({
            key: action.reportType,
            message: `Error: ${message}`
          }));
        })
      );
    })
  ));


  public changeSort$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.changeSort),
    map((action) => influencedInsightsActions.loadInfluencedInsightsDetails({ reportType: action.reportType }))
  ));


  public changePage$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.changePage),
    map((action) => influencedInsightsActions.loadInfluencedInsightsDetails({ reportType: action.reportType }))
  ));


  public changeSearch$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.changeSearch),
    map((action) => influencedInsightsActions.loadInfluencedInsightsDetails({ reportType: action.reportType }))
  ));


  public setActiveReportAndDetails$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.setActiveReportAndDetails),
    map((action) => influencedInsightsActions.loadInfluencedInsightsDetails({ reportType: action.reportType }))
  ));


  public downloadAnalyticsCSV$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.downloadCSV),
    concatLatestFrom(() => this.store.pipe(select(InfluencedInsightsSelectors.buildQueryParams$))),
    switchMap(([action, reqFunc]) => {
      return this.influencedInsightsService.getDetailsCSV$(reqFunc(action.key))
        .pipe(
          map((data: string) => influencedInsightsActions.downloadCSVSuccess({ key: action.key, data })),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'measurementStudio.features.advertisingInsights.informationPanel.errors.cantDownloadCSV';
            return of(influencedInsightsActions.downloadCSVFailure({
                key: action.key,
                message
              }
            ));
          })
        );
    })
  ));


  public downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.downloadCSVSuccess),
    map(action => {
      const fileName = 'Account List';
      this.downloadService.createBlobAndDownload(fileName, action.data);
    })
  ), {dispatch: false});

  public addAccountsToAccountList$ = createEffect(() => this.actions$.pipe(
    ofType(influencedInsightsActions.addAccountToAccountsList),
    concatLatestFrom(() => [
      this.store.pipe(select(InfluencedInsightsSelectors.getInsightTypeByKey$)),
      this.store.pipe(select(InsightsFiltersSelectors.getInsightsReportFilters$))
    ]),
    mergeMap(([action, insightType, filters]) => {
      const req = {
        ...filters,
        insightType,
        reportType: action.params.reportType,
        listId: +action.params.listId
      } as IAdsInfluencedAddToListRequest;
      return this.influencedInsightsService.addAccountsToAccountList$(req).pipe(
        map(() => influencedInsightsActions.addAccountToAccountsListSuccess({ count: action.params.accountCount })),
        catchError((error: HttpErrorResponse) => {
          const message = error?.message || 'measurementStudio.features.advertisingInsights.informationPanel.errors.cantAdd';
          return of(influencedInsightsActions.addAccountToAccountsListFailure({
            message: `Error: ${message}`
          }));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      influencedInsightsActions.addAccountToAccountsListFailure,
      influencedInsightsActions.loadInfluencedInsightsFailure,
      influencedInsightsActions.loadInfluencedInsightsDetailsFailure
    ),
    map(action =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));
}
