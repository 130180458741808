<tsh-table-panel (download)="emitDownload()"
                 [totalTitle]="isCampaignTrendsReport
                    ? 'feature.sharedTrending.campaignDetails.totalTitle'
                    : 'feature.sharedTrending.campaignDetails.utmTotalTitle'"
                 [noResult]="!data?.length"
                 [searchEnabled]="false"
                 [totalCount]="totalCount"
                 [showColumns]="false">
</tsh-table-panel>
<div class="table-container">
  <table ts-table
         #trendingTable="tsTable"
         tsSort
         class="table-hover group-details-table"
         [columns]="columns"
         [multiTemplateDataRows]="true"
         (tsSortChange)="emitChangeSort($event)"
         [tsSortActive]="sortState?.active"
         [tsSortDirection]="sortState?.direction"
         [dataSource]="dataSource">
    <ng-container tsColumnDef="icon" alignment="center">
      <th ts-header-cell *tsHeaderCellDef></th>
      <td ts-cell *tsCellDef="let item">
        <i class="fas"
           *ngIf="rowState"
           [ngClass]="{
                       'fa-angle-right': !rowState[item.id],
                       'fa-angle-down': rowState[item.id]
                       }">
        </i>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of columns.slice(1); let colIxd = index;">
      <ng-container [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef  ts-sort-header  ts-header-cell [start]="column.startSorting">
          <tsh-header-cell [column]="column"></tsh-header-cell>
        </th>
        <td ts-cell *tsCellDef="let item">
          <a *ngIf="column.name === 'name' && getLinkData(item, column.internalLink) as linkData"
             [queryParamsHandling]="linkData.queryParamsHandling"
             [queryParams]="linkData.queryParams"
             [title]="item[column.name]"
             [routerLink]="linkData.link"
             (click)="$event.stopPropagation()">
            {{ item[column.name] | typedData : column.dataType }}
          </a>
          <ng-container *ngIf="column.name === 'items'">
            {{ item['items'].length }}
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <!-- Custom column definition for the 'expanded' row -->
    <ng-container tsColumnDef="expanded">
      <td ts-cell
          *tsCellDef="let item"
          [ngClass]="{'open': rowState[item.id]}"
          [attr.colspan]="columns.length"
          class="expanded-cell">
        <ms-trending-responses-table *ngIf="rowState[item.id] && item?.items?.length"
                                     (download)="emitDownloadResponses(item.id)"
                                     [columns]="responsesColumns"
                                     [data]="item?.items">
        </ms-trending-responses-table>
        <tsh-no-report-result *ngIf="rowState[item.id] && !item?.items?.length"
                              description="feature.sharedTrending.noResultDescription"
                              title="feature.sharedTrending.noResultTitle">
        </tsh-no-report-result>
      </td>
    </ng-container>

    <tr ts-header-row *tsHeaderRowDef="trendingTable.columnNames; sticky: true"></tr>
    <tr ts-row
        class="pointer"
        (click)="emitToggleExpand($event, item.id)"
        *tsRowDef="let item; columns: trendingTable.columnNames;"
        data-cy="trending-campaign-table-row">
    </tr>
    <tr ts-row *tsRowDef="let item; columns: ['expanded']" class="expanded-row"></tr>
  </table>
</div>
<tsh-pagination
  [currentPageIndex]="page || 1"
  [totalRecords]="totalCount"
  (pageSelect)="emitPageChange($event)">
</tsh-pagination>
