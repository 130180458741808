import { format, startOfWeek } from 'date-fns';

import { KpiInterval, KpiProgression, KpiType } from '../enums/scorecard.enum';

function formatByWeek(year: number, intervalVal: number) {
  return format(
    startOfWeek(new Date(year, 0, (intervalVal - 1) * 7), {weekStartsOn: 1}),
    'MMM d'
  );
}

function formatByMonth(year: number, intervalVal: number) {
  return format(
    new Date(year, intervalVal - 1, 1),
    'MMM'
  );
}

function formatByQuarter(year: number, intervalVal: number) {
  return `Q${intervalVal} ${year}`;
}

function formatByWeekWithYear(year: number, intervalVal: number) {
  return format(
    startOfWeek(new Date(year, 0, (intervalVal - 1) * 7), {weekStartsOn: 1}),
    'MMM d, yyyy'
  );
}

function formatByMonthWithYear(year: number, intervalVal: number) {
  return format(
    new Date(year, intervalVal - 1, 1),
    'MMM yyyy'
  );
}

export function formatIntervalDate(
  intervalDateStr: string,
  kpiInterval: KpiInterval,
  includeYear: boolean = false
): string {
  const [year, intervalVal] = intervalDateStr.split(' ');

  if (isNaN(+year) || isNaN(+intervalVal)) {
    return `${intervalVal} ${year}`;
  }

  switch (kpiInterval) {
    case KpiInterval.Weeks: {
      return includeYear ? formatByWeekWithYear(+year, +intervalVal) : formatByWeek(+year, +intervalVal);
    }
    case KpiInterval.Months: {
      return includeYear ? formatByMonthWithYear(+year, +intervalVal) : formatByMonth(+year, +intervalVal);
    }
    case KpiInterval.Quarters: {
      return formatByQuarter(+year, +intervalVal);
    }
    default: {
      return `${intervalVal} ${year}`;
    }
  }
}

export function isInvalidProgressionTypeCombination(
  kpiProgression: KpiProgression,
  kpiType: KpiType
): boolean {
  return kpiProgression === KpiProgression.Won &&
    (
      kpiType === KpiType.OpptyCreated ||
      kpiType === KpiType.OpptyClosed ||
      kpiType === KpiType.Pipeline
    );
}
