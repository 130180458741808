import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { CampaignsSource } from '../source/campaigns.source';
import { EnvService } from '@shared/environment';
import { hubTokenName } from '@shared/constants';
import { ICampaign, ICampaignByIdResponse, IChannel, IChannelByIdResponse } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(public source: CampaignsSource,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaigns$(searchQuery: string): Observable<ICampaign[]> {
    return regenerateOnRetry(() => this.source.getCampaigns$(searchQuery))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignsByIds$(data: { campaignIds: string[]; }): Observable<ICampaignByIdResponse> {
    return regenerateOnRetry(() => this.source.getCampaignsByIds$(data))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignTypes$(): Observable<string[]> {
    return regenerateOnRetry(() => this.source.getCampaignTypes$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getChannels$(searchQuery: string): Observable<IChannel[]> {
    return regenerateOnRetry(() => this.source.getChannels$(searchQuery))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getChannelsByIds$(data: { channelIds: string[]; }): Observable<IChannelByIdResponse> {
    return regenerateOnRetry(() => this.source.getChannelsByIds$(data))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
