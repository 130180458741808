import { AdminAccessTypes, RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { IRouteItem } from '@shared/interfaces';

export const MEASUREMENT_STUDIO_SHARED_ROUTES: IRouteItem[] = [
  // Scorecard
  {
    routeId: RouteItemEnum.Scorecard,
    routeLabel: 'data.routeLabel.abmScorecard',
    routeLink: ['/scorecard'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.ScorecardOverview,
    routeLabel: 'data.routeLabel.abmScorecard',
    routeLink: ['/scorecard/overview'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.ScorecardTrendingKpi,
    routeLabel: 'data.routeLabel.abmScorecard',
    routeLink: ['/scorecard/trending-kpi'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.ScorecardTrendingKpiDetails,
    routeLabel: 'data.routeLabel.abmScorecard',
    routeLink: ['/scorecard/trending_details'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.AttributionByCampaignType,
    routeLabel: 'data.routeLabel.attributionCampaignType',
    routeLink: ['/discover/revenue-and-pipeline/attribution-by-quarter'],
    routeType: RouteTypeEnum.InternalLink
  },
  // Discover -> Stage Progression
  {
    routeId: RouteItemEnum.StagesSnapshot,
    routeLabel: 'data.routeLabel.stagesSnapshot',
    routeLink: ['/discover/stage-progression/stages-snapshot'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CohortedWaterfall,
    routeLabel: 'data.routeLabel.cohortedWaterfall',
    routeLink: ['/discover/stage-progression/attribution'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CohortedWaterfallDetails,
    routeLabel: 'data.routeLabel.cohortedWaterfallDetails',
    routeLink: ['/discover/stage-progression/details'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.ProgressionByQuarter,
    routeLabel: 'data.routeLabel.progressionQuarter',
    routeLink: ['/discover/stage-progression/trending'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.ProgressionByQuarterDetails,
    routeLabel: 'data.routeLabel.progressionDetails',
    routeLink: ['/discover/stage-progression/trending-details'],
    routeType: RouteTypeEnum.InternalLink
  },
  // Analyze -> Campaigns
  {
    routeId: RouteItemEnum.CampaignAnalytics,
    routeLabel: 'data.routeLabel.campaignAnalytics',
    routeLink: ['/analyze/campaigns/list-analysis/campaign-groups'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignAnalyticsGroups,
    routeLabel: 'data.routeLabel.campaignAnalytics',
    routeLink: ['/analyze/campaigns/list-analysis/campaign-groups'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignAnalyticsCampaigns,
    routeLabel: 'data.routeLabel.campaignAnalytics',
    routeLink: ['/analyze/campaigns/list-analysis/campaigns'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.StageInfluence,
    routeLabel: 'data.routeLabel.stageInfluence',
    routeLink: ['/analyze/campaigns/stage-influence/campaign'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.StageInfluenceCampaignType,
    routeLabel: 'data.routeLabel.stageInfluence',
    routeLink: ['/analyze/campaigns/stage-influence/campaign-type'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignTrends,
    routeLabel: 'data.routeLabel.campaignTrends',
    routeLink: ['/analyze/campaigns/trending-analysis'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignHistoricalAverages,
    routeLabel: 'data.routeLabel.historicalAverages',
    routeLink: ['#/analyze/campaigns/historical-averages-campaign-group'],
    routeType: RouteTypeEnum.LegacyLink
  },
  {
    routeId: RouteItemEnum.AnalyzeAttributionByCampaignType,
    routeLabel: 'data.routeLabel.attributionCampaignType',
    routeLink: ['/analyze/campaigns/attribution-by-quarter'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignSpecific,
    routeLabel: '', // get from the backend
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/attribution'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignSpecificLeads,
    routeLabel: '', // get from the backend
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/attribution/leads'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignSpecificOpportunities,
    routeLabel: '', // get from the backend
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/attribution/opportunities'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignSpecificDeals,
    routeLabel: '', // get from the backend
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/attribution/deals'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.SalesforceCompare,
    routeLabel: 'data.routeLabel.salesforceCompare',
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/sfdc'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.CampaignSpecificStages,
    routeLabel: 'data.routeLabel.specificStages',
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/stages'],
    routeType: RouteTypeEnum.InternalLink
  },
  // Analyze -> Digital Attribution
  {
    routeId: RouteItemEnum.WebActivitiesList,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-group'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesChannel,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-group'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesChannelMedium,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-medium'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesChannelAssets,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-name'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesTrending,
    routeLabel: 'data.routeLabel.webActivitiesTrending',
    routeLink: ['/analyze/digital-attribution/web-activity-trending'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesLeads,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/leads'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesOpportunities,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/opportunities'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesDeals,
    routeLabel: 'data.routeLabel.webActivitiesList',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/deals'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesStages,
    routeLabel: 'data.routeLabel.specificStages',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/stages'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.WebActivitiesSpecific,
    routeLabel: 'data.routeLabel.specificLeads',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/leads'],
    routeType: RouteTypeEnum.InternalLink
  },
  // Analyze -> Opportunities
  {
    routeId: RouteItemEnum.OpportunityInsights,
    routeLabel: 'data.routeLabel.opportunityInsights',
    routeLink: ['/analyze/opportunities/list-analysis'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.OpportunitySpecificTotalTouches,
    routeLabel: 'data.routeLabel.opportunitySpecific',
    routeLink: ['/analyze/opportunities/list-analysis/opportunity-specific/attribution/total-touches'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.OpportunitySpecificPeopleInfluencing,
    routeLabel: 'data.routeLabel.opportunitySpecific',
    routeLink: ['/analyze/opportunities/list-analysis/opportunity-specific/attribution/people-influencing'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.OpportunitySpecificCampaignInfluencing,
    routeLabel: 'data.routeLabel.opportunitySpecific',
    routeLink: ['/analyze/opportunities/list-analysis/opportunity-specific/attribution/campaignInfluencing'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.OpportunityAllOpptysTouches,
    routeLabel: 'data.routeLabel.opportunitiesTouches',
    routeLink: ['/analyze/opportunities/list-analysis/all-opptys-touches'],
    routeType: RouteTypeEnum.InternalLink
  },
  // Advertising Insights
  {
    routeId: RouteItemEnum.AdvertisingInsights,
    routeLabel: 'data.routeLabel.advertisingInsights',
    // routeLink: [ InsightsReportPath ],
    routeLink: ['/ad-insights'], // TODO: found out a solution how to avoid dependency on insight feature
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsPageViewsInfluenced,
    routeLabel: 'data.routeLabel.insightsPageViewsInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.PageViewsInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/page-views-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsLeadsInfluenced,
    routeLabel: 'data.routeLabel.insightsLeadsInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.LeadsInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/leads-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsCampaignResponsesInfluenced,
    routeLabel: 'data.routeLabel.insightsCampaignResponsesInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.CampaignResponsesInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/campaign-responses-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsNewPipelineInfluenced,
    routeLabel: 'data.routeLabel.insightsNewPipelineInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.NewPipelineInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/new-pipeline-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsPipelineInfluenced,
    routeLabel: 'data.routeLabel.insightsPipelineInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.PipelineInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/pipeline-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.InsightsRevenueInfluenced,
    routeLabel: 'data.routeLabel.insightsRevenueInfluenced',
    // routeLink: getInsightsReportPath(InsightsReportKey.RevenueInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['/ad-insights/revenue-influenced'],
    routeType: RouteTypeEnum.InternalLink
  },
  {
    routeId: RouteItemEnum.Onboarding,
    routeLabel: 'data.routeLabel.onboarding',
    // routeLink: getInsightsReportPath(InsightsReportKey.RevenueInfluenced),
    // TODO: found out a solution how to avoid dependency on insight feature
    routeLink: ['onboarding'],
    routeType: RouteTypeEnum.LegacyLink
  },
  {
    routeId: RouteItemEnum.CampaignScoring,
    routeLabel: 'shared.navMenus.settings.campaignScoring',
    routeLink: ['/settings/campaign-scoring'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
];


export const DATA_STUDIO_SHARED_ROUTES: IRouteItem[] = [
  // Account Hub
  {
    routeId: RouteItemEnum.AccountHub,
    routeLabel: 'data.routeLabel.accountHub',
    routeLink: ['/list-analysis'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.AccountSpecificAttribution,
    routeLabel: 'data.routeLabel.accountHub',
    routeLink: ['/account-specific/attribution'],
    routeType: RouteTypeEnum.InternalLink,
  },
  // Migrated Settings Pages
  {
    routeId: RouteItemEnum.Permissions,
    routeLabel: 'shared.navMenus.settings.permissions',
    routeLink: ['/settings/permissions/team'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.Stages,
    routeLabel: 'shared.navMenus.settings.stages',
    routeLink: ['/settings/stages'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.DigitalAttribution,
    routeLabel: 'shared.navMenus.settings.webTracking',
    routeLink: ['/settings/webTracking'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.PagesAndFeatures,
    routeLabel: 'shared.navMenus.settings.pagesAndFeatures',
    routeLink: ['/settings/pages-and-features'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.DataHygiene,
    routeLabel: 'shared.navMenus.settings.dataHygiene',
    routeLink: ['/settings/data-hygiene/orphan-lead-finder'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
];
