export const rollbarConfig = {
  // Access token for authenticating with Rollbar
  accessToken: '',

  // Enable sending of errors to Rollbar
  enabled: false,

  // Identifier for the current environment
  environment: '',

  // Capture unhandled exceptions
  captureUncaught: true,

  // Capture unhandled promise rejections
  captureUnhandledRejections: true,

  // Log errors to the console in addition to Rollbar
  verbose: true,
};

export enum GrpcStatusCodes {
  OK = 0,
  CANCELLED = 1,
  UNKNOWN = 2,
  INVALID_ARGUMENT = 3,
  DEADLINE_EXCEEDED = 4,
  NOT_FOUND = 5,
  ALREADY_EXISTS = 6,
  PERMISSION_DENIED = 7,
  UNAUTHENTICATED = 16,
  RESOURCE_EXHAUSTED = 8,
  FAILED_PRECONDITION = 9,
  ABORTED = 10,
  OUT_OF_RANGE = 11,
  UNIMPLEMENTED = 12,
  INTERNAL = 13,
  UNAVAILABLE = 14,
  DATA_LOSS = 15,
}

export const RETRIES_COUNT = 8;

export enum RollbarLogLevelEnum {
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug',
  WARN = 'warn',
  ERROR = 'error',
  CRITICAL = 'critical'
}
