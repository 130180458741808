import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TsExpansionPanelModule } from '@terminus-lib/ui-expansion-panel';
import { TranslateModule } from '@ngx-translate/core';

import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { ITileSettingControl, TileSettingsControlTypes } from '@shared/interfaces';
import { shiftWithFadeIn, shiftWithFadeOut } from '@util/animations';
import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { ReportCheckboxSelectorModule } from '@ui/components/report-checkbox-selector';
import { BenchmarkControlModule, BenchmarkFields, IBenchmarkConfirmEvent } from '@ui/components/benchmark-control';
import { SelectorModule } from '@shared/ui/selector';
import { ReportFilterInputModule } from '@ui/components/report-filter-input';

@Component({
  selector: 'tsh-tile-settings-form-controls',
  templateUrl: './tile-settings-form-controls.component.html',
  styleUrls: ['./tile-settings-form-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [shiftWithFadeOut(), shiftWithFadeIn()]
})
export class TileSettingsFormControlsComponent {
  @Input() public settingsFormGroup: FormGroup;
  @Input() public filters: ITileSettingControl[];

  public readonly controlTypes = TileSettingsControlTypes;
  public readonly benchmarkFields = BenchmarkFields;
  public isExpanded = false;

  panelExpandedChange(state: boolean): void {
    this.isExpanded = state;
  }

  confirmBenchmarkChange({type, goal, previous}: IBenchmarkConfirmEvent): void {
    this.settingsFormGroup.patchValue({type, previous, goal});
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReportFilterSelectorModule,
    TsExpansionPanelModule,
    TranslateModule,
    SharedUiPipesMemoizeModule,
    ReportCheckboxSelectorModule,
    BenchmarkControlModule,
    SelectorModule,
    ReactiveFormsModule,
    ReportFilterInputModule
  ],
  declarations: [TileSettingsFormControlsComponent],
  exports: [TileSettingsFormControlsComponent],
})
export class TileSettingsFormControlsModule {
}
