import { IInsightsDetailColumn } from '../interfaces';
import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { InsightsDetailsTableDataType } from '../insights.constants';

export const INSIGHTS_DETAILS_ICON_COLUMN: IInsightsDetailColumn = {
  displayName: '',
  name: 'icon',
  width: 55,
  dataType: DataTypeEnum.Text
};

// TODO - Update all names below to match actual property names
export const INSIGHTS_DETAILS_COLUMN_DEFINITIONS: { [key: string]: IInsightsDetailColumn } = {
  AccountName: {
    name: 'accountName',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.accountName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 160,
    crmIdKeyForCrmLink: 'crmAccountId',
    link(row: InsightsDetailsTableDataType) {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.crmAccountId
        }
      };
    }
  },
  AccountsReached: {
    name: 'accountsReached',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.accountsReached',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  ActivityDate: {
    name: 'activityDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.activityDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 160
  },
  AdSpend: {
    name: 'adSpend',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.adSpend',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    decimal: 2,
    width: 160
  },
  Amount: {
    name: 'amount',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.amount',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  AmountInfluenced: {
    name: 'newPipelineInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.newPipelineInfluenced',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  AmountInfluencedExisting: {
    name: 'newPipelineInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.existingPipelineInfluenced',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  CampaignName: {
    name: 'campaignName',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.campaignName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 160,
    crmIdKeyForCrmLink: 'crmCampaignId',
    link(row: InsightsDetailsTableDataType) {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.crmAccountId
        }
      };
    }
  },
  CampaignResponsesInfluenced: {
    name: 'responsesInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.campaignResponsesInfluenced',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 250
  },
  CloseDate: {
    name: 'closeDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.closeDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 160
  },
  CostPerCampaignResponse: {
    name: 'costPerCampaign',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.costPerCampaignResponse',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    decimal: 2,
    width: 250
  },
  CostPerLead: {
    name: 'costPerLead',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.costPerLead',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  CostPerOpportunity: {
    name: 'costPerOpportunity',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.costPerOpportunity',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    decimal: 2,
    width: 160
  },
  CostPerPageView: {
    name: 'costPerPageView',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.costPerPageView',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    decimal: 2,
    width: 160
  },
  CreatedDate: {
    name: 'createdDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.createdDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 160
  },
  DaysToLeadCreated: {
    name: 'daysToLeadCreated',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.daysToLeadCreated',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  DaysToResponse: {
    name: 'daysToResponse',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.daysToResponse',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  FirstImpressionDate: {
    name: 'firstImpressionDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.firstAccountImpressionDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 300
  },
  Impressions: {
    name: 'impressionCount',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.impressions',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  LeadCreatedDate: {
    name: 'firstLeadCreated',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.leadCreatedDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 160
  },
  LeadName: {
    name: 'leadName',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.leadName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 160,
    crmIdKeyForCrmLink: 'crmLeadId',
    link(row: InsightsDetailsTableDataType) {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.crmAccountId
        }
      };
    }
  },
  LeadsInfluenced: {
    name: 'leadsInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.leadsInfluenced',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  NewOpportunitiesInfluenced: {
    name: 'newOpportunities',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.newOpportunitiesInfluenced',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 250
  },
  OpportunityAmount: {
    name: 'opportunityAmount',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.opportunityAmount',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  OpportunityCloseDate: {
    name: 'opportunityCloseDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.opportunityCloseDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 160
  },
  OpportunityCreatedDate: {
    name: 'opportunityCreatedDate',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.opportunityCreatedDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    width: 250
  },
  OpportunityName: {
    name: 'opportunityName',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.opportunityName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 300,
    crmIdKeyForCrmLink: 'crmOpportunityId',
    link(row: InsightsDetailsTableDataType) {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.crmAccountId
        }
      };
    }
  },
  OpportunityType: {
    name: 'opportunityType',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.opportunityType',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 160
  },
  PageViews: {
    name: 'pageViews',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.pageViews',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  PipelineInfluenced: {
    name: 'existingOpportunitiesInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.pipelineInfluenced',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  },
  RevenueInfluenced: {
    name: 'revenueInfluenced',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.revenueInfluenced',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  ROAS: {
    name: 'spendPerDeal',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.roas',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    width: 160
  },
  SpendPerOpportunity: {
    name: 'spendPerOpportunity',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.spendPerOpportunity',
    dataType: DataTypeEnum.Currency,
    startSorting: 'desc',
    decimal: 2,
    width: 160
  },
  TacticName: {
    name: 'tacticName',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.tacticName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    width: 300,
    externalLinkKey: 'tacticLink'
  },
  Tactics: {
    name: 'tactics',
    displayName: 'measurementStudio.features.advertisingInsights.table.columns.tactics',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    width: 160
  }
};
