<div class="container"
  *ngIf="!isLoading">

  <div *ngIf="data; else noData">
    <p class="title">{{ 'shared.features.globalSearch.searchTitle' | translate:{ searchQuery: searchQuery } }}</p>

    <ng-container *ngFor="let view of viewData">
      <p class="block-title">{{ labels[view] | translate }}</p>
      <div class="block-item"
        *ngFor="let item of data[view]">
        <a [href]="routeData[view].link.routeLink.join('') + '?' + routeData[view].field + '=' + item.id"
          [innerHTML]="item.name"></a>
      </div>
    </ng-container>
  </div>

  <ng-template #noData>
    <p class="title">{{ 'shared.features.globalSearch.noData' | translate }}</p>
  </ng-template>
</div>
