<ts-radio-group [formControl]="form.get('type')" class="ad-impressions-form">
    <ts-radio-button [value]="AdImpressionsValueType.Default">{{ adImpressionsLabelDefault | translate }}</ts-radio-button>
    <ts-radio-button [value]="AdImpressionsValueType.Range">{{ adImpressionsLabelRange | translate }}</ts-radio-button>
</ts-radio-group>
<div class="ad-impressions-range">
    <div class="ad-impressions-range-inputs">
        <ts-input
            [formControl]="form.get('rangeMin')"
            class="ad-impressions-range-input"
            [isSmall]="true"
            mask="number"
            type="text"
            [maskAllowDecimal]="false"
        ></ts-input>
        <div class="ad-impressions-range-dash">-</div>
        <ts-input
            [formControl]="form.get('rangeMax')"
            class="ad-impressions-range-input"
            [isSmall]="true"
            mask="number"
            type="text"
            [maskAllowDecimal]="false"
        ></ts-input>
    </div>

    <div
        *ngIf="form.errors && form.errors.errorMsg"
        class="ad-impressions-range-error">
        {{ form.errors.errorMsg | translate }}
    </div>
</div>
<hr class="ad-impressions-divider" />
<div class="ad-impressions-form-buttons">
    <ts-button
        theme="secondary"
        [isSmall]="true"
        (clicked)="cancelled.emit()"
    >{{ 'shared.common.cancel' | translate }}</ts-button>

    <ts-button
        [isDisabled]="!form.valid"
        [isSmall]="true"
        (clicked)="onConfirm()"
    >{{ 'shared.common.confirm' | translate }}</ts-button>
</div>
