<ts-sidenav
  class="main-menu"
  [user]="userInfo"
  [options]="options"
  [newSidenavDisplay]="zdSsoAccess"
  [switcherCurrentTitle]="currentTitle | translate"
  [switcherContent]="switcherContent"
  (click)="navMenusClick()">

  <ng-container *ngFor="let section of menu; last as isLastSection">
    <ng-container *ngFor="let item of section.items">
      <ng-container [ngSwitch]="item.type">

        <!-- BASIC PANEL START -->
        <ng-container *ngSwitchCase="panelTypes.Basic">
          <ts-sidenav-trigger>
            <ts-tooltip [tooltipValue]="item.label | translate"
                        tooltipPosition="after">
              <ng-container *ngIf="item.routeItem.routeType === routeTypes.InternalLink; else getLinkTemplate">
                <a [routerLink]="item.routeItem.routeLink"
                   routerLinkActive="active-section">
                  <span class="fas fa-{{ item.icon }}"></span>
                  <span class="sr-only">{{ item.label | translate }}</span>
                </a>
              </ng-container>
              <ng-template #getLinkTemplate>
                <a [href]="getLink(item.routeItem)">
                  <span class="fas fa-{{ item.icon }}"></span>
                  <span class="sr-only">{{ item.label | translate }}</span>
                </a>
              </ng-template>
            </ts-tooltip>
          </ts-sidenav-trigger>
        </ng-container>
        <!-- BASIC PANEL END -->

        <!-- DRAWER PANEL START -->
        <ng-container *ngSwitchCase="panelTypes.Drawer">
          <!-- check if there are categories to be 100% sure-->
          <ng-container *ngIf="item.categories">
            <ts-sidenav-trigger
              #sidenavTrigger
              panelType="drawer">
              <ts-tooltip [tooltipValue]="item.label | translate"
                          tooltipPosition="after">
                <button type="button"
                        [ngClass]="{'active-section': selectedNavItem && item.label === selectedNavItem.label}">
                  <span class="fas fa-{{ item.icon }}"></span>
                  <span class="sr-only">{{ item.label | translate }}</span>
                </button>
              </ts-tooltip>

              <div tsTriggerComponentPanelContent>
                <div class="ts-sidenav-panel-title">{{ item.label | translate }}</div>
                <ul>
                  <ng-container *ngFor="let category of item.categories; last as isLast">
                    <li class="ts-sidenav-bold-title" *ngIf="category.label">
                      {{ category.label | translate }}
                    </li>
                    <!-- Show learn more link -->
                    <li *ngIf="!category.routeItems.length && category.learnMoreLink; else itemsList">
                      <a [href]="category.learnMoreLink"
                         target="_blank"
                         class="ts-sidenav-panel-action external-link">
                        {{ 'shared.common.learnMore' | translate }}
                        <i class="fas fa-external-link"></i>
                      </a>
                    </li>
                    <!-- Show items -->
                    <ng-template #itemsList>
                      <li *ngFor="let routeItem of category.routeItems">
                        <!-- Show internal links -->
                        <ng-container *ngIf="routeItem.routeType === routeTypes.InternalLink; else getLinkTemplate2">
                          <a [routerLink]="routeItem.routeLink"
                             routerLinkActive="active"
                             (click)="sidenavTrigger.close()"
                             class="ts-sidenav-panel-action">
                            {{ routeItem.routeLabel | translate }}
                          </a>
                        </ng-container>
                        <!-- Show legacy/external links -->
                        <ng-template #getLinkTemplate2>
                          <a [href]="getLink(routeItem)" class="ts-sidenav-panel-action">
                            {{ routeItem.routeLabel | translate }}
                          </a>
                        </ng-template>
                      </li>
                    </ng-template>
                    <!-- Show divider -->
                    <li *ngIf="!isLast">
                      <hr>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ts-sidenav-trigger>
          </ng-container>
        </ng-container>
        <!-- DRAWER PANEL END -->

        <!-- POPOUT PANEL START -->
        <ng-container *ngSwitchCase="panelTypes.Popout">
          <ts-sidenav-trigger panelType="popout">
            <ts-tooltip [tooltipValue]="item.label | translate"
                        tooltipPosition="after">
              <button type="button">
                <span class="fas fa-{{ item.icon }}"></span>
                <span class="sr-only">{{ item.label | translate }}</span>
              </button>
            </ts-tooltip>
            <!-- Switch User popout -->
            <div tsTriggerComponentPanelContent *ngIf="item.label === 'shared.navMenus.settings.switchUser'">
              <div class="ts-sidenav-panel-section-title">{{ 'shared.navMenus.switchUser.title' | translate }}</div>
              <div class="ts-sidenav-panel-section-subtitle">{{ 'shared.navMenus.switchUser.subtitle' | translate }}</div>
              <hr>
              <form [formGroup]="formGroup" class="switch-user">
                <ts-input type="text"
                          formControlName="user"
                          [errorMessage]="formGroup.invalid && (formGroup.dirty || formGroup.touched) ? 'Enter a valid username' : ''"
                          [isSmall]="true">
                </ts-input>
                <ts-button
                  (clicked)="emitSwitchUser()"
                  [isSmall]="true"
                  [isDisabled]="formGroup.invalid"
                  theme="default">
                  {{ 'shared.navMenus.switchUser.button' | translate }}
                </ts-button>
              </form>
            </div>
          </ts-sidenav-trigger>
        </ng-container>
        <!-- POPOUT PANEL END -->
      </ng-container>
    </ng-container>
    <ts-sidenav-section-break *ngIf="!isLastSection"></ts-sidenav-section-break>
  </ng-container>
</ts-sidenav>
