import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';

import { PortalService } from './portal.service';

@Directive({
    selector: '[tshAddTarget]'
})
export class AddTargetDirective implements OnInit {
    @Input('tshAddTarget') public targetName: string;

    constructor(private portalService: PortalService,
                private viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        this.portalService.addTarget(this.targetName, this.viewContainerRef);
    }
}
