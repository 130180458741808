import { ILabelValue } from '@shared/interfaces';
import { LookbackWindow } from '../enums';

export const LookbackWindowOptions: ILabelValue[] = [{
  label: 'measurementStudio.features.advertisingInsights.settings.lookbackWindowOptions.days30',
  value: LookbackWindow.Days30,
}, {
  label: 'measurementStudio.features.advertisingInsights.settings.lookbackWindowOptions.days60',
  value: LookbackWindow.Days60,
}, {
  label: 'measurementStudio.features.advertisingInsights.settings.lookbackWindowOptions.days90',
  value: LookbackWindow.Days90,
}, {
  label: 'measurementStudio.features.advertisingInsights.settings.lookbackWindowOptions.days180',
  value: LookbackWindow.Days180,
}, {
  label: 'measurementStudio.features.advertisingInsights.settings.lookbackWindowOptions.days365',
  value: LookbackWindow.Days365,
}];
