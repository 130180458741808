import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsSortModule } from '@terminus-lib/ui-sort';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';

import { OpportunitiesComponent } from './containers/opportunities/opportunities.component';
import { OpportunitiesRoutingModule } from './opportunities-routing.module';
import { OpportunitiesTableComponent } from './components/opportunities-table/opportunities-table.component';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { OpportunitiesEffects } from './state/opportunities.effects';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { OpportunitiesFiltersComponent } from './components/opportunities-filters/opportunities-filters.component';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { AlertModule } from '@ui/components/alert';
import { amChartsFactory } from '@util/amcharts';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { HeaderCellModule } from '@ui/components/header-cell';
import { TablePanelModule } from '@ui/components/table-panel';
import * as OpportunitiesReducer from './state/opportunities.reducer';
import { OpportunitiesChartComponent } from './components/opportunities-chart/opportunities-chart.component';
import { ReportMetricModule } from '@ui/components/report-metric';
import { PaginationModule } from '@ui/components/pagination';
import { ColumnsSaverService } from '@shared/data-access/columns-saver';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { PortalDirectiveModule } from '@ui/directives/portal';
import { AccountListPopupModule } from '@shared/features/account-list-popup';


@NgModule({
  declarations: [
    OpportunitiesComponent,
    OpportunitiesTableComponent,
    OpportunitiesFiltersComponent,
    OpportunitiesChartComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OpportunitiesRoutingModule,
    TypedDataModule,
    PaginationModule,
    TsLoadingOverlayModule,
    TsTableModule,
    TsSortModule,
    TsChartModule,
    NoReportResultModule,
    ReportFilterSelectorModule,
    DateCohortsModule,
    ReportSettingPanelModule,
    AlertModule,
    TablePanelModule,
    PortalDirectiveModule,
    AccountListPopupModule,
    HeaderCellModule,
    ReportMetricModule,
    MatSelectModule,
    TranslateModule,
    StoreModule.forFeature(OpportunitiesReducer.opportunitiesFeatureKey, OpportunitiesReducer.reducer),
    EffectsModule.forFeature([OpportunitiesEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
    TsButtonModule,
  ],
  providers: [
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsFactory,
    },
    ColumnsSaverService,
  ]
})
export class OpportunitiesModule {
}
