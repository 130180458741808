import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';

import { IReportColumn, IReportPagination } from '@shared/interfaces';
import { TsTableDataSource } from '@terminus-lib/ui-table';
import { DataTypeEnum } from '@shared/enums';
import { TsSortState } from '@terminus-lib/ui-sort';

@Component({
  selector: 'ms-info-panel-table',
  templateUrl: './info-panel-table.component.html',
  styleUrls: ['./info-panel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPanelTableComponent implements OnChanges {
  @Input() public data: { accountName: string }[];
  @Input() public sortState: TsSortState;
  @Input() public columns: IReportColumn[] = [];
  @Input() public pagination: IReportPagination;
  @Input() public totalCount: number;

  @Output() public changeSort: EventEmitter<TsSortState> = new EventEmitter<TsSortState>();
  @Output() public changePage: EventEmitter<number> = new EventEmitter<number>();

  public dataSource = new TsTableDataSource<{ accountName: string }>();
  public readonly dataTypes = DataTypeEnum;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data
      && changes.data.currentValue
      && changes.data.currentValue !== changes.data.previousValue) {
      this.dataSource.data = this.data;
      this.changeDetector.detectChanges(); // to fix table width issue
    }

    if (changes.columns) {
      this.changeDetector.detectChanges(); // to fix table width issue
    }
  }

  onSortChange(state: TsSortState): void {
    this.changeSort.emit(state);
  }

  emitPageChange(page: number): void {
    this.changePage.emit(page);
  }
}


