import { createFeatureSelector, createSelector } from '@ngrx/store';
import { format } from 'date-fns';
import { TsSortState } from '@terminus-lib/ui-sort';

import {
  ICampaignDetailsTable,
  IResponsesTable,
  ITrendingDetailsTable,
  trendingFeatureKey,
  TrendingState
} from './trending.reducer';
import { DataTypeEnum, RouteItemEnum, TrendsType } from '@shared/enums';
import {
  getConvertedDates,
  getDifference,
  getFrequencyTypes,
  sortCampaignDetailsData,
  sortTrendingData,
  splitCampaignDetailsDataByPages
} from '../utils/trending.utils';
import {
  IComparativeTableData,
  IGroupDetail,
  IGroupDetailInfo,
  ITrendingAllTotal,
  ITrendingCampaigns,
  ITrendingCampaignsCount,
  ITrendingChart,
  ITrendingGroupDetailsAll,
  ITrendingGroupDetailsParams,
  ITrendingParams,
  ITrendingReport,
  ITrendingTotal
} from '../interfaces';
import { TrendingColors, TrendingDataPeriod } from '../enums';
import { ILabelValue, IReportColumn } from '@shared/interfaces';
import { getChannelFieldName } from '@org-config';
import { PER_PAGE } from '@shared/constants';
import { TRENDING_DOLLARS_METRICS_IDS } from '../data/trending.data';

export const selectTrendingState = createFeatureSelector<TrendingState>(trendingFeatureKey);

export const getTrendingFilters = createSelector(selectTrendingState,
  (state: TrendingState) => state.filters);

export const getTrendingTotal = createSelector(selectTrendingState,
  (state: TrendingState) => state.total);

export const getTrendingTotalByPeriod = (period: TrendingDataPeriod) => createSelector(getTrendingTotal,
  (total: ITrendingAllTotal) => total?.[period]);

export const getTrendingGroupDetailsAll = createSelector(selectTrendingState,
  (state: TrendingState) => state.groupDetails);

export const getGroupDetailsByPeriod = (period: TrendingDataPeriod) => createSelector(getTrendingGroupDetailsAll,
  (groupDetails: ITrendingGroupDetailsAll) => groupDetails?.[period]);

export const getGroupDetailsParamsAll = createSelector(selectTrendingState,
  (state: TrendingState) => state.groupDetailsParams);

export const getGroupDetailsParamsByPeriod = (period: TrendingDataPeriod) => createSelector(getGroupDetailsParamsAll,
  (params: Record<TrendingDataPeriod, ITrendingGroupDetailsParams | null>) => params?.[period]);

export const getCampaignDetailsAll = createSelector(selectTrendingState,
  (state: TrendingState) => state.campaigns);

export const getCampaignsLoading = createSelector(selectTrendingState,
  (state: TrendingState) => state.isLoadingCampaigns);

export const getCampaignDetailsByPeriod = (period: TrendingDataPeriod) => createSelector(getCampaignDetailsAll,
  (campaigns: ITrendingCampaigns) => campaigns?.[period]);

export const getTrendingIsLoadingAll = createSelector(selectTrendingState,
  (state: TrendingState) => state.isLoading[TrendingDataPeriod.Now]
    || state.isLoading[TrendingDataPeriod.Then]
    || state.isLoadingGroupDetails[TrendingDataPeriod.Now]
    || state.isLoadingGroupDetails[TrendingDataPeriod.Then]);

export const getTrendingIsLoadingTotal = createSelector(selectTrendingState,
  (state: TrendingState) => state.isLoading[TrendingDataPeriod.Now]
    || state.isLoading[TrendingDataPeriod.Then]);

export const getTrendingIsLoadingGroup = createSelector(selectTrendingState,
  (state: TrendingState) => state.isLoadingGroupDetails[TrendingDataPeriod.Now]
    || state.isLoadingGroupDetails[TrendingDataPeriod.Then]);

export const getTrendingIsLoadingGroupNow = createSelector(selectTrendingState,
  (state: TrendingState) => state.isLoadingGroupDetails[TrendingDataPeriod.Now]);

export const getMetricOptions = createSelector(selectTrendingState,
  (state: TrendingState) => state.metricOptions);

export const getSelectedDateCohort = createSelector(selectTrendingState,
  (state: TrendingState) => state.selectedDateCohort);

export const getSelectedReport = createSelector(selectTrendingState,
  (state: TrendingState) => state.selectedReport);

export const getComparativeTablePagination = createSelector(selectTrendingState,
  (state: TrendingState) => state.comparativeTablePagination);

export const isCampaignTrendsReport = createSelector(getSelectedReport,
  (report: RouteItemEnum) => report === RouteItemEnum.CampaignTrends);

export const isWebActivitiesTrending = createSelector(getSelectedReport,
  (report: RouteItemEnum) => report === RouteItemEnum.WebActivitiesTrending);

export const getFrequencyTypeOptions = createSelector(getTrendingFilters,
  (filters: ITrendingParams) => {
    return getFrequencyTypes(filters?.cohort);
  });

export const getSelectedGroupId = createSelector(selectTrendingState,
  (state: TrendingState) => state.selectedGroupId);

export const getResponsesTable = createSelector(selectTrendingState,
  (state: TrendingState) => state.responsesTable);

export const getResponsesColumns = createSelector(getResponsesTable,
  (table: IResponsesTable) => table.columns);

export const getGroupDetailsTable = createSelector(selectTrendingState,
  (state: TrendingState) => state.groupDetailsTable);

export const getCampaignDetailsTable = createSelector(selectTrendingState,
  (state: TrendingState) => state.campaignDetailsTable);

export const getCampaignDetailsSortState = createSelector(getCampaignDetailsTable,
  (table: ICampaignDetailsTable) => table.sortState);

export const getCampaignDetailsPagination = createSelector(getCampaignDetailsTable,
  (table: ICampaignDetailsTable) => table.pagination);

export const getCampaignDetailsRowState = createSelector(getCampaignDetailsTable,
  (table: ICampaignDetailsTable) => table.rowState);

export const getCampaignDetailsTotalCount = createSelector(getCampaignDetailsAll,
  (allCampaigns: ITrendingCampaigns): ITrendingCampaignsCount => {
    const nowCount = Object.keys(allCampaigns[TrendingDataPeriod.Now]).reduce((acc: Record<string, number>, groupName: string) => {
      acc[groupName] = allCampaigns[TrendingDataPeriod.Now][groupName].length;
      return acc;
    }, {});
    const thenCount = Object.keys(allCampaigns[TrendingDataPeriod.Then]).reduce((acc: Record<string, number>, groupName: string) => {
      acc[groupName] = allCampaigns[TrendingDataPeriod.Then][groupName].length;
      return acc;
    }, {});
    return {
      [TrendingDataPeriod.Now]: nowCount,
      [TrendingDataPeriod.Then]: thenCount,
    };
  });

export const getSortedCampaigns = createSelector(
  getCampaignDetailsAll,
  getCampaignDetailsSortState,
  getCampaignDetailsPagination,
  (
    allCampaigns: ITrendingCampaigns,
    sortState: Record<TrendingDataPeriod, { [key: string]: TsSortState | null }>,
    pagination: Record<TrendingDataPeriod, { [key: string]: number }>,
  ) => {
    // Firstly we have to sort data
    const sortedNow = sortCampaignDetailsData(allCampaigns[TrendingDataPeriod.Now], sortState[TrendingDataPeriod.Now]);
    const sortedThen = sortCampaignDetailsData(allCampaigns[TrendingDataPeriod.Then], sortState[TrendingDataPeriod.Then]);
    // Then split data by pages
    const dataPerPageNow = splitCampaignDetailsDataByPages(sortedNow, pagination[TrendingDataPeriod.Now]);
    const dataPerPageThen = splitCampaignDetailsDataByPages(sortedThen, pagination[TrendingDataPeriod.Then]);
    return {
      [TrendingDataPeriod.Now]: dataPerPageNow,
      [TrendingDataPeriod.Then]: dataPerPageThen,
    };
  });

export const getCampaignDetailsColumns = createSelector(
  getCampaignDetailsTable,
  isCampaignTrendsReport,
  (
    table: ICampaignDetailsTable,
    isCampaignTrends: boolean,
  ) => {
    return table.columns.map(column => {
      if (column.name === 'name') {
        const displayName = isCampaignTrends
          ? 'feature.sharedTrending.groupDetails.columns.name'
          : 'feature.common.utmNames.channel';

        return {
          ...column,
          displayName
        };
      }

      return column;
    });
  });

export const getGroupDetailsColumns = createSelector(
  getGroupDetailsTable,
  isCampaignTrendsReport,
  getChannelFieldName('channelGroup'),
  (
    table: ITrendingDetailsTable,
    isCampaignTrends: boolean,
    utmName: string,
  ) => {
    return table.columns.map(column => {
      if (column.name === 'group') {
        const displayName = isCampaignTrends
          ? 'feature.sharedTrending.groupDetails.columns.type'
          : utmName;

        return {
          ...column,
          displayName
        };
      }

      return column;
    });
  });

export const getGroupDetailsSortState = createSelector(
  getGroupDetailsTable,
  (
    table: ITrendingDetailsTable
  ) => table.sortState);

export const getGroupDetailsRowState = createSelector(
  getGroupDetailsTable,
  (
    table: ITrendingDetailsTable
  ) => table.rowState);

export const getFrequencyTitle = createSelector(getTrendingTotalByPeriod(TrendingDataPeriod.Now),
  (totalNow: ITrendingTotal) => {
    // if last character is S then remove it
    if (totalNow?.frequency && totalNow.frequency[totalNow.frequency.length - 1] === 's') {
      return totalNow.frequency.slice(0, -1);
    }

    return totalNow?.frequency;
  });

export const getTrendingReportData = createSelector(
  getTrendingTotalByPeriod(TrendingDataPeriod.Now),
  getTrendingTotalByPeriod(TrendingDataPeriod.Then),
  getTrendingFilters,
  (
    totalNow: ITrendingTotal,
    totalThen: ITrendingTotal,
    filters: ITrendingParams,
  ): ITrendingReport[] => {
    const isGoal = filters?.type === TrendsType.Goal;
    if (totalNow?.data?.length) {
      // get dates array based on start date, end date and frequency value
      const nowDates = getConvertedDates(totalNow);
      const thenDates = totalThen?.data ? getConvertedDates(totalThen) : [];
      // it could be more data for previous period then for current that's why we have to show all of them, not only
      // current period's data. Need to calculate number of periods to show in UI
      const periodNumbers = totalNow.data.length && totalThen?.data?.length
      && totalThen.data.length > totalNow.data.length
        ? totalThen.data.length
        : totalNow.data.length;
      const report: ITrendingReport[] = [];
      for (let i = 0; i < periodNumbers; i++) {
        const currentItem = totalNow.data[i];
        // get cumulated value for current period
        const now = currentItem ? {
          frequencyNow: currentItem.frequency,
          valueNow: i === 0 || report.length === 0
            ? currentItem.totals
            : currentItem.totals + report[i - 1].valueNow,
          dateNow: nowDates[i]
        } : {
          frequencyNow: null,
          valueNow: null,
          dateNow: null,
        };
        // get cumulated value for previous period if there is data for this period
        const then = filters.type === TrendsType.Benchmark && totalThen?.data && totalThen.data[i]
          ? {
            frequencyThen: totalThen.data[i].frequency,
            valueThen: i === 0 || report.length === 0
              ? totalThen.data[i].totals
              : totalThen.data[i].totals + report[i - 1].valueThen,
            dateThen: thenDates[i]
          }
          : isGoal
            ? {valueThen: filters.goal} // set goal amount as previous value
            : {};
        // get difference between periods
        // if type is goal then we have to use goal amount as based value
        // otherwise use then data for benchmark type
        const diff = totalThen?.data || isGoal ? getDifference(now.valueNow, then.valueThen, isGoal) : {};
        report.push({
          id: i + 1,
          ...now,
          ...then,
          ...diff
        });
      }
      return report;
    }

    return [];
  });

export const getGroupDetailsTotals = createSelector(
  getTrendingGroupDetailsAll,
  (
    groupDetails: ITrendingGroupDetailsAll,
  ): Record<TrendingDataPeriod, number> => {
    const nowTotal = groupDetails?.[TrendingDataPeriod.Now]?.reduce((acc: number, item: IGroupDetail) => {
      // tslint:disable-next-line:no-parameter-reassignment
      return acc += item.count;
    }, 0) || 0;
    const thenTotal = groupDetails?.[TrendingDataPeriod.Then]?.reduce((acc: number, item: IGroupDetail) => {
      // tslint:disable-next-line:no-parameter-reassignment
      return acc += item.count;
    }, 0) || 0;
    return {
      [TrendingDataPeriod.Now]: nowTotal,
      [TrendingDataPeriod.Then]: thenTotal,
    };
  });

export const getGroupDetailsData = createSelector(
  getTrendingGroupDetailsAll,
  getGroupDetailsSortState,
  (
    groupDetails: ITrendingGroupDetailsAll,
    sortState: Record<TrendingDataPeriod, TsSortState | null>,
  ): Record<TrendingDataPeriod, IGroupDetail[]> => {
    return {
      [TrendingDataPeriod.Now]: sortTrendingData(groupDetails?.[TrendingDataPeriod.Now], sortState?.[TrendingDataPeriod.Now]),
      [TrendingDataPeriod.Then]: sortTrendingData(groupDetails?.[TrendingDataPeriod.Then], sortState?.[TrendingDataPeriod.Then]),
    };
  }
);

export const getGroupDetailsDateByPeriod = (period: TrendingDataPeriod) => createSelector(
  getSelectedGroupId,
  getTrendingReportData,
  (
    selectedId: number,
    report: ITrendingReport[],
  ) => {
    const selectedGroup = report ? report.find(group => group.id === selectedId) : null;
    const date = period === TrendingDataPeriod.Now ? selectedGroup?.dateNow : selectedGroup?.dateThen;
    return date ? format(date, 'M/d/yyyy') : '';
  }
);

export const getMetricFieldLabel = createSelector(
  getTrendingFilters,
  getMetricOptions,
  (
    filters: ITrendingParams,
    metricOptions: ILabelValue[],
  ) => {
    const field = metricOptions?.find(option => option.value === filters?.field);
    return field?.label;
  }
);

export const getDataType = createSelector(
  getTrendingFilters,
  (filters: ITrendingParams): DataTypeEnum => TRENDING_DOLLARS_METRICS_IDS.includes(filters?.field)
    ? DataTypeEnum.Currency
    : DataTypeEnum.Number
);

export const getTrendingGroupDetailsInfo = createSelector(
  getTrendingFilters,
  getSelectedGroupId,
  getTrendingReportData,
  getFrequencyTitle,
  getGroupDetailsSortState,
  getGroupDetailsRowState,
  (
    filters: ITrendingParams,
    selectedId: number,
    report: ITrendingReport[],
    frequency: string | null,
    sortState: Record<TrendingDataPeriod, TsSortState | null>,
    rowState: Record<TrendingDataPeriod, { [key: string]: boolean }>,
  ): IGroupDetailInfo[] => {
    const selectedGroup = report ? report.find(group => group.id === selectedId) : null;
    const dateNow = selectedGroup?.dateNow ? ` (${format(selectedGroup.dateNow, 'M/d/yyyy')})` : '';
    const titleNow = `${frequency} ${selectedId}${dateNow}`;
    const now: IGroupDetailInfo = {
      period: TrendingDataPeriod.Now,
      periodTitle: 'feature.sharedTrending.comparativeTable.now',
      color: TrendingColors.Now,
      title: titleNow,
      sortState: sortState[TrendingDataPeriod.Now],
      rowState: rowState[TrendingDataPeriod.Now],
    };

    if (filters?.type === TrendsType.Benchmark) {
      const dateThen = selectedGroup?.dateThen ? ` (${format(selectedGroup.dateThen, 'M/d/yyyy')})` : '';
      const titleThen = `${frequency} ${selectedId}${dateThen}`;
      return [
        now,
        {
          period: TrendingDataPeriod.Then,
          periodTitle: 'feature.sharedTrending.comparativeTable.then',
          color: TrendingColors.Then,
          title: titleThen,
          sortState: sortState[TrendingDataPeriod.Then],
          rowState: rowState[TrendingDataPeriod.Then],
        }
      ];
    }

    return [now];
  });

export const getTrendingReportDataWithPagination = createSelector(
  getTrendingReportData,
  getComparativeTablePagination,
  (
    data: ITrendingReport[],
    page: number
  ): ITrendingReport[] => {
    if (data.length > PER_PAGE) {
      const start = (page - 1) * PER_PAGE;
      const end = page * PER_PAGE;
      return data.slice(start, end);
    }

    return data;
  }
);

const buildColumns = (report: ITrendingReport[], dataType: DataTypeEnum): IReportColumn[] => {
  const numericValues = [DataTypeEnum.Currency, DataTypeEnum.Number, DataTypeEnum.Percent];
  const startSorting: 'asc' | 'desc' = numericValues.includes(dataType) ? 'desc' : 'asc';
  if (!report.length) {
    return [];
  }
  return [{
    name: 'type',
    displayName: '',
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
    width: 155
  }, ...report.map(item => ({
    name: '' + item.id,
    displayName: '' + item.id,
    dataType,
    startSorting,
    width: 105,
  }))];
};

export const getComparativeTableColumns = createSelector(
  getTrendingReportDataWithPagination,
  getDataType,
  buildColumns,
);

export const getComparativeTableDownloadColumns = createSelector(
  getTrendingReportData,
  getDataType,
  buildColumns,
);


export const getComparativeTableData = createSelector(
  getTrendingReportDataWithPagination,
  getTrendingFilters,
  getSelectedGroupId,
  (
    report: ITrendingReport[],
    filters: ITrendingParams,
    selectedId: number,
  ): IComparativeTableData[] => {
    if (filters?.type === TrendsType.Benchmark
      && report.length
      // need to check if Then data is already in item
      // otherwise table won't be updated with this data
      // eslint-disable-next-line no-prototype-builtins
      && report[0].hasOwnProperty('valueThen')) {
      return [{
        title: 'feature.sharedTrending.comparativeTable.now',
        color: TrendingColors.Now,
        selectedId // add selectedId to table source so that change detection could update active column
      }, {
        title: 'feature.sharedTrending.comparativeTable.then',
        color: TrendingColors.Then,
        selectedId // add selectedId to table source so that change detection could update active column
      }];
    }

    return [{
      title: 'feature.sharedTrending.comparativeTable.now',
      color: TrendingColors.Now,
      selectedId // add selectedId to table source so that change detection could update active column
    }];
  }
);

export const getComparativeTableTotalTitle = createSelector(
  getTrendingFilters,
  (
    filters: ITrendingParams
  ): string | null => {
    if (filters?.type === TrendsType.Benchmark) {
      return 'feature.sharedTrending.comparativeTable.difference';
    }

    if (filters?.type === TrendsType.Goal) {
      return 'feature.sharedTrending.comparativeTable.goalDifference';
    }

    return null;
  }
);

export const getTrendingChartData = createSelector(
  getTrendingReportData,
  getTrendingFilters,
  (
    data: ITrendingReport[],
    filters: ITrendingParams,
  ): ITrendingChart[] => {
    return data.map(item => {
      if (filters?.type === TrendsType.Benchmark) {
        return {
          ...item,
          diffTooltip: item.diffLabel ? `
                        <span class="${item.diffLabel} diff-section">
                            <i class="fas fa-sm fa-arrow-right"></i>
                            ${item.diff} (${item.diffPercent.toFixed(1)}%)
                        </span>
                    ` : ''
        };
      }

      return item;
    });
  }
);

const getNonNullableValues = (data: ITrendingReport[]) =>
  data.reduce<[ITrendingReport[], ITrendingReport[]]>((acc, item: ITrendingReport) => {
    if (item.valueNow) {
      acc[0].push(item);
    }

    if (item?.valueThen) {
      acc[1].push(item);
    }
    return acc;
  }, [[], []]);

export const getTrendingMetrics = createSelector(
  getTrendingReportData,
  getTrendingFilters,
  getMetricFieldLabel,
  getDataType,
  (
    data: ITrendingReport[],
    filters: ITrendingParams,
    selectedMetricLabel: string | undefined,
    dataType: string | undefined,
  ) => {
    if (data?.length) {
      const [valueNowData, valueThenData] = getNonNullableValues(data);
      const mainBlock = {
        title: selectedMetricLabel || 'feature.sharedTrending.chart.campaignResponses',
        current: {
          value: data[valueNowData.length - 1]?.valueNow,
          dataType,
          color: TrendingColors.Now,
        },
      };
      const additionalPart = {
        value: data[valueThenData.length - 1]?.valueThen,
        dataType,
        color: TrendingColors.Then,
      };

      switch (filters?.type) {
        case TrendsType.Benchmark:
          return [{
            ...mainBlock,
            previous: additionalPart,
          }];
        case TrendsType.Goal:
          return [mainBlock, {
            title: 'feature.sharedTrending.chart.tooltipGoal',
            current: additionalPart,
          }];
        case TrendsType.Trend:
          return [mainBlock];
      }
    }

    return [];
  }
);
