<tsh-table-panel
  (changeColumns)="emitChangeColumns($event)"
  (submitted)="emitSearch($event)"
  (download)="emitDownload()"
  [totalTitle]="totals?.opptyCount === 1
    ? 'measurementStudio.features.opportunities.totalTitle'
    : 'measurementStudio.features.opportunities.totalTitlePlural'"
  [noResult]="!isLoading && !report?.length"
  [searchEnabled]="true"
  [totalCount]="totals?.opptyCount"
  [showColumns]="true"
  [columns]="allColumns"
  [searchByFieldEnabled]="true"
  [searchQuery]="filters?.searchQuery"
  [searchOptions]="searchOptions">
</tsh-table-panel>
<ng-container *ngIf="report?.length">
  <div class="table-container">
    <table ts-table
           tsSort
           #opportunityTable="tsTable"
           (tsSortChange)="emitChangeSort($event)"
           [tsSortActive]="filters?.fld"
           [tsSortDirection]="sortDirection"
           [columns]="visibleColumns"
           [dataSource]="dataSource">
      <ng-container *ngFor="let column of visibleColumns; let colIxd = index;">
        <ng-container [tsColumnDef]="column.name"
                      [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
          <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
            <tsh-header-cell [column]="column"></tsh-header-cell>
          </th>
          <td ts-cell *tsCellDef="let row; let rowIdx = index;">
            <ng-container *ngIf="!column.link && !column.internalLink">
              {{ row[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
            </ng-container>
            <ng-container *ngIf="column.link">
              <a (click)="emitRedirect(column.link(row, filters))"
                 [title]="row[column.name]">
                {{ row[column.name] }}
              </a>
            </ng-container>
            <ng-container *ngIf="column.internalLink && column.internalLink(row, filters) as linkData">
              <a [queryParamsHandling]="linkData.queryParamsHandling"
                 [queryParams]="linkData.queryParams"
                 [title]="row[column.name]"
                 [routerLink]="linkData.link">
                {{ row[column.name] }}
              </a>
            </ng-container>
          </td>
          <td ts-footer-cell *tsFooterCellDef>
            <ng-container *ngIf="colIxd === 0; else totalsTemplate">
              <strong>Totals</strong>
            </ng-container>
            <ng-template #totalsTemplate>
              <ng-container *ngIf="column.dataType !== dataTypes.Text && column.dataType !== dataTypes.Date">
                <ng-container
                  *ngIf="column.totalLink && column.totalLink(totals[column.name]) as linkData; else totalNoLink">
                  <a [queryParamsHandling]="linkData.queryParamsHandling"
                     [queryParams]="linkData.queryParams"
                     [title]="totals[column.name]"
                     [routerLink]="linkData.link">
                    {{ totals[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
                  </a>
                </ng-container>
                <ng-template #totalNoLink>
                  {{ totals[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
                </ng-template>
              </ng-container>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
      <tr ts-header-row *tsHeaderRowDef="opportunityTable.columnNames; sticky: true"></tr>
      <tr ts-row *tsRowDef="let row; columns: opportunityTable.columnNames;"></tr>
      <tr ts-footer-row *tsFooterRowDef="opportunityTable.columnNames;"></tr>
    </table>
  </div>
  <tsh-pagination
    [currentPageIndex]="filters?.pg || 1"
    [totalRecords]="totals?.opptyCount || 0"
    (pageSelect)="emitPageChange($event)"
    [paginatorSummary]="totals?.opptyCount === 1
            ? 'measurementStudio.features.opportunities.totalTitle'
            : 'measurementStudio.features.opportunities.totalTitlePlural'">
  </tsh-pagination>
</ng-container>
