import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MAX_EXTENDED_ROWS_NUMBER, MAX_ROWS_NUMBER, TILE_DATA_FIELD_NAME } from '@shared/constants';
import { ITileData, ITileVisualizationConfig } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';

@Component({
  selector: 'tsh-table-visualization',
  templateUrl: './table-visualization.component.html',
  styleUrls: ['./table-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableVisualizationComponent {
  @Input() public data: ITileData;
  @Input() public customSettings: ITileVisualizationConfig | null;
  @Input() public currency: string | null;
  @Input()
  public set extended(value: boolean) {
    this.maxRows = value ? MAX_EXTENDED_ROWS_NUMBER : MAX_ROWS_NUMBER;
  }

  public readonly fieldName = TILE_DATA_FIELD_NAME;
  public readonly dataTypes = DataTypeEnum;
  public maxRows = MAX_ROWS_NUMBER;
}
