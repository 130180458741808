import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ILabelValue } from '@shared/interfaces';
import { ReportFilterChange } from '@shared/classes';

@Component({
  selector: 'tsh-report-filter-selector',
  templateUrl: './report-filter-selector.component.html',
  styleUrls: ['./report-filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFilterSelectorComponent extends ReportFilterChange {
  @Input() placeholder = '';
  @Input() isMultiSelect = false;
  /**
   * Filter name
   */
  @Input() title: string;
  /**
   * Form Control
   */
  @Input() field: FormControl;
  /**
   * Options for selector
   * Default - empty array
   */
  @Input() options: ILabelValue[] = [];
  /**
   * Options Clicked
   */
  @Output() changeDropdown = new EventEmitter<void>();

  selectionChange(): void {
    this.change$.next(true);
  }

  changeOutput(): void {
    this.changeDropdown.emit();
  }

  getTitle(value: string | null, options: ILabelValue[]): string {
    const selectedOption = (options || []).find(option => option.value === value);
    return selectedOption?.label || '';
  }
}
