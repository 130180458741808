import { IFactoryParams } from '@shared/interfaces';

export enum WfStageTypes {
  Lead = 'lead',
  Opportunity = 'oppty'
}

export interface IWfStage {
  stage: string;
  appliesTo: WfStageTypes;
  type: string;
  id: number;
  inCampPerf: boolean;
  defaultStage: boolean;
  sequence: number;
}

export function wfStageFactory({
  overrides = {},
  n = 1
}: IFactoryParams<IWfStage, number> = {}): IWfStage {
  return {
    stage: 'stage',
    appliesTo: WfStageTypes.Lead,
    type: 'type',
    id: n,
    inCampPerf: true,
    defaultStage: true,
    sequence: n,
    ...overrides
  };
}
