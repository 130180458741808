import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CampaignAnalyticsComponent } from './containers/campaign-analytics.component';

const routes: Routes = [{
  path: '',
  component: CampaignAnalyticsComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'campaign-groups',
    },
    {
      path: 'campaign-groups',
      loadChildren: () => import ('./campaign-groups/campaign-groups.module').then(m => m.CampaignGroupsModule),
    },
    {
      path: 'campaigns',
      loadChildren: () => import ('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignAnalyticsRoutingModule {
}
