import { TS_SIDENAV_PLATFORM_SWITCHER_CONTENT } from '@terminus-lib/ui-sidenav';

import { INavMenu, IRouteItem, ParentLink, SpecificReportLink } from '@shared/interfaces';
import { RouteTypeEnum } from '@shared/enums';
import { navMenusActions } from './nav-menus.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface NavMenusState {
  navMenu: INavMenu;
  selectedRouteItem: IRouteItem;
  switcherContent: TS_SIDENAV_PLATFORM_SWITCHER_CONTENT;
  // data, which could be used in the breadcrumbs for campaign/web specific report
  // this property is updated on every route navigation
  // and could be set only manually by dispatching action SET_SPECIFIC_REPORT_LINK
  specificReportLink: SpecificReportLink | null;
  parentLink: ParentLink | null;
}

export const initialState: NavMenusState = {
  navMenu: [],
  switcherContent: [],
  selectedRouteItem: {
    routeId: null,
    routeLabel: '',
    routeLink: [],
    routeType: RouteTypeEnum.InternalLink
  },
  specificReportLink: null,
  parentLink: null,
};

const navMenuReducer = createReducer(
  initialState,
  on(
    navMenusActions.Load,
    state => ({ ...state })
  ),
  on(
    navMenusActions.SetSpecificReportLink,
    (state, action) => ({
      ...state,
      specificReportLink: action.payload
    })
  ),

  on(
    navMenusActions.LoadSuccess,
    (state, action) => ({
      ...state,
      navMenu: action.payload
    })
  ),

  on(
    navMenusActions.LoadFailure,
    state => state
  ),

  on(
    navMenusActions.SetSelectedRouteItem,
    (state, action) => ({
      ...state,
      selectedRouteItem: action.payload
    })
  ),

  on(
    navMenusActions.SetSwitcherContent,
    (state, action) => ({
      ...state,
      switcherContent: action.payload
    })
  ),

  on(
    navMenusActions.SetCustomRouteLabel,
    (state, action) => ({
      ...state,
      selectedRouteItem: {
        ...state.selectedRouteItem,
        routeLabel: action.payload
      }
    })
  ),

  on(
    navMenusActions.SetRouteLogo,
    (state, action) => ({
      ...state,
      selectedRouteItem: {
        ...state.selectedRouteItem,
        routeLogo: action.payload
      }
    })
  ),

  on(
    navMenusActions.SetParentLink,
    (state, action) => ({
      ...state,
      parentLink: action.parentLink
    })
  )
)

export function reducer(state: NavMenusState, action: Action): NavMenusState {
  return navMenuReducer(state, action)
}
