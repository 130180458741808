import { IAnalyticsColumn } from '../interfaces';
import * as sharedColumns from './shared-columns.data';

export const MEDIUMS_COLUMNS: IAnalyticsColumn[] = [
  sharedColumns.mediumName,
  sharedColumns.responses,
  sharedColumns.people,
  sharedColumns.accounts,
  sharedColumns.opptys,
  sharedColumns.deals,
  sharedColumns.opptyTouches,
  sharedColumns.dealTouches,
  sharedColumns.attributedPipeline,
  sharedColumns.attributedRevenue,
  sharedColumns.influencedPipeline,
  sharedColumns.influencedRevenue,
  sharedColumns.LTO,
  sharedColumns.CPL,
  sharedColumns.CPO,
  sharedColumns.CPD,
  sharedColumns.pipelineRoi,
  sharedColumns.ROI,
  sharedColumns.OTD,
];

