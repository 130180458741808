import { color } from '@amcharts/amcharts4/core';
import { generateChartsPalette } from './helpers/generate-charts-palette.helpers';

export const palette = {
  white: '#fff',
  whisper: '#eee',
  lightGrey: '#dde1e3',
  darkGrey: '#637178',
  blackPearl: '#22272a',
  orange: '#ffa600',
  lemon: '#C7AE00',
  magenta: '#c21bd9',
  mahogany: '#d23d46',
  watermelon: '#ff6368',
  darkPink: '#eb5488',
  limeGreen: '#3ede45',
  apple: '#63aa4e',
  summerSky: '#3eb9eb',
  pacificBlue: '#0098ce',
  darkCyan: '#008989',
  darkCerulean: '#00528A',
  blue: '#0303ff',
  wildBlue: '#848bc2',
  governor: '#4f5a9f',
  lightViolette: '#8a59a7',
  violette: '#5e3c8f',
};

export type amChartsColor = ReturnType<typeof color>;

export const amChartsPalette: Record<keyof typeof palette, amChartsColor> = generateChartsPalette(palette);
