import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TranslateModule } from '@ngx-translate/core';
import { TsToggleModule } from '@terminus-lib/ui-toggle';
import { CsvDownloadButtonModule } from '@ui/components/csv-download-button';
import { TableButtonsPanelComponent } from './table-buttons-panel.component';

@NgModule({
  imports: [
    CommonModule,
    TsTooltipModule,
    ReactiveFormsModule,
    TsButtonModule,
    TsMenuModule,
    TsInputModule,
    TranslateModule,
    TsToggleModule,
    CsvDownloadButtonModule
  ],
  declarations: [TableButtonsPanelComponent],
  exports: [TableButtonsPanelComponent]
})
export class TableButtonsPanelModule {
}
