import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { hubTokenName, MAX_EXTENDED_ROWS_NUMBER, TILE_DATA_FIELD_NAME } from '@shared/constants';
import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from '@shared/environment';
import {
  IAccountFolder,
  IAccountListDataParams,
  IAccountListDataResponse,
  IAccountsColumn,
  IDashboardTile,
  ILabelValue,
  IMetricInsightsItem,
  IMetricInsightsParams,
  IMetricInsightsResponse,
  IOrgConfig,
  ITileData,
  ITileDataItem,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes,
  ICreateAccountsFolderParams,
  IUpdateAccountsFolderParams,
  IDeleteAccountsFolderParams,
  IAccountsFolderUser,
  ICreateAccountsListParams,
  ICreateAccountsListResponse,
  IDeleteAccountsListParams,
  IUpdateAccountsListParams,
  IAddAccountsListResponse
} from '@shared/interfaces';
import {
  AccountListFilters,
  AccountListsFields,
  AccountMetrics,
  AccountTypes,
  AgoFromToday,
  All,
  DataTypeEnum,
  EngagementInsightsFields,
  FeatureFlagsEnum,
  FieldType,
  TileTypes,
  TimeUnits,
  VisualizationTypes
} from '@shared/enums';
import { AccountHubColumnService } from './account-hub-columns.service';
import * as OrgConfigStore from '@org-config';
import * as UserSelectors from '@user/user.selectors';
import {
  getStartAndEndDateFromParams,
  getVisualizationType,
  replaceLegacyCohort,
  toCapitalize,
  toSnakeCase
} from '@util/helpers';
import { AccountHubSource } from '../sources/account-hub.source';
import { toCamelCase } from '@terminus-lib/fe-utilities';

interface EngagementLabels {
  metricField: string;
  accountField: string;
}

@Injectable({ providedIn: 'root' })
export class AccountHubSharedService {

  constructor(private source: AccountHubSource,
              private store: Store<OrgConfigStore.OrgConfigState>,
              private retry: RetryWithEscalation,
              private translateService: TranslateService,
              private envService: EnvService,
              private accountHubColumnService: AccountHubColumnService) {
    source.podPath = of(this.envService.getEnv().GRAILS_URL);
  }

  getAccountListsFolders$(): Observable<IAccountFolder[]> {
    return regenerateOnRetry(() => this.source.getAccountListsFolders$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  loadListData$(request: IAccountListDataParams): Observable<IAccountListDataResponse> {
    return regenerateOnRetry(() => this.source.loadListData$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  loadMetricInsights$(request: IMetricInsightsParams): Observable<IMetricInsightsResponse> {
    return regenerateOnRetry(() => this.source.loadMetricInsights$(request))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  addToList$(request): Observable<IAddAccountsListResponse> {
    return regenerateOnRetry(() => this.source.addToList$(request))
    .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getFolderUsers$(): Observable<IAccountsFolderUser[]> {
    return regenerateOnRetry(() => this.source.getFolderUsers$())
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteFolder$(params: IDeleteAccountsFolderParams): Observable<string> {
    return regenerateOnRetry(() => this.source.deleteFolder$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  createFolder$(params: ICreateAccountsFolderParams): Observable<string> {
    return regenerateOnRetry(() => this.source.createFolder$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateFolder$(params: IUpdateAccountsFolderParams): Observable<string> {
    return regenerateOnRetry(() => this.source.updateFolder$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  createList$(params: ICreateAccountsListParams): Observable<ICreateAccountsListResponse> {
    return regenerateOnRetry(() => this.source.createList$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteList$(params: Omit<IDeleteAccountsListParams, 'reloadType'>): Observable<void> {
    return regenerateOnRetry(() => this.source.deleteList$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateList$(params: Omit<IUpdateAccountsListParams, 'reloadType' | 'message'>): Observable<string> {
    return regenerateOnRetry(() => this.source.updateList$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountListFilters(): ILabelValue[] {
    return [
      {
        label: 'shared.accountHub.accountFilters.allAccounts',
        value: AccountListFilters.All
      }, {
        label: 'shared.accountHub.accountFilters.needsMarketing',
        value: AccountListFilters.NeedsMarketing
      }, {
        label: 'shared.accountHub.accountFilters.needsSales',
        value: AccountListFilters.NeedsSales
      }, {
        label: 'shared.accountHub.accountFilters.needsMarketingSales',
        value: AccountListFilters.NeedsMarketingSales
      }, {
        label: 'shared.accountHub.accountFilters.needsContacts',
        value: AccountListFilters.NeedsContacts
      }, {
        label: 'shared.accountHub.accountFilters.hasOpenOpportunities',
        value: AccountListFilters.HasOpenOpportunities
      }, {
        label: 'shared.accountHub.accountFilters.activeAccountsWithNoOpenOpportunities',
        value: AccountListFilters.ActiveAccountsWithNoOpenOpportunities
      }, {
        label: 'shared.accountHub.accountFilters.allAccountsWithNoOpenOpportunities',
        value: AccountListFilters.AllAccountsWithNoOpenOpportunities
      }, {
        label: 'shared.accountHub.accountFilters.withoutAdImpressions',
        value: AccountListFilters.WithoutAdImpressions
      }, {
        label: 'shared.accountHub.accountFilters.withAdImpressions',
        value: AccountListFilters.WithAdImpressions
      }, {
        label: 'shared.accountHub.accountFilters.withoutAdClicks',
        value: AccountListFilters.WithoutAdClicks
      }, {
        label: 'shared.accountHub.accountFilters.withAdClicks',
        value: AccountListFilters.WithAdClicks
      }, {
        label: 'shared.accountHub.accountFilters.withoutPageViews',
        value: AccountListFilters.WithoutPageViews
      }, {
        label: 'shared.accountHub.accountFilters.withPageViews',
        value: AccountListFilters.WithPageViews
      }
    ];
  }

  getAccountHubColumns(): Observable<IAccountsColumn[]> {
    return combineLatest([
      this.store.pipe(select(OrgConfigStore.getOrgConfig)),
      this.store.pipe(select(OrgConfigStore.isSigstrEnabled)),
      this.store.pipe(select(UserSelectors.isFeatureFlagActive(FeatureFlagsEnum.NewInfluenceType)))
    ]).pipe(
      filter(([orgConfig]: [IOrgConfig, boolean, boolean]) => {
        return orgConfig !== null;
      }),
      map(([orgConfig, isSigstrEnabled, isNewInfluenceTypeEnabled]: [IOrgConfig, boolean, boolean]) => {
        const terminusData = orgConfig?.terminusData;
        const isSalesActivityEnabled = orgConfig?.salesActivity?.enabled;
        const customColumns = orgConfig?.customColumns?.account || [];

        const customAccountHubColumns = customColumns.map(column => ({
          name: column.column,
          displayName: column.name,
          dataType: column.fieldType === DataTypeEnum.Date
            ? DataTypeEnum.Date
            : column.fieldType === DataTypeEnum.String
              ? DataTypeEnum.Text
              : DataTypeEnum.Number,
          width: 100,
          apiType: FieldType.AccountColumns,
        }));

        const terminusDataColumns = terminusData ? this.accountHubColumnService.getTerminusDataColumns() : [];
        const sigstrColumns = isSigstrEnabled ? this.accountHubColumnService.getSigstrColumns() : [];
        const influenceTypeColumns = isNewInfluenceTypeEnabled ? this.accountHubColumnService.getInfluenceTypeColumns() : [];
        const salesColumns = isSalesActivityEnabled ? this.accountHubColumnService.getSalesActivityColumns() : [];
        return [
          ...this.accountHubColumnService.getAccounsColumnsFirstPart(),
          ...customAccountHubColumns,
          ...terminusDataColumns,
          ...this.accountHubColumnService.getAccounsColumnsSecondPart(),
          ...sigstrColumns,
          ...this.accountHubColumnService.getAccountsColumnsThirdPart(),
          ...influenceTypeColumns,
          ...salesColumns,
          ...this.accountHubColumnService.getAccountsColumnsFourthPart(),
        ];
      })
    );
  }

  getTileData$(params: IMetricInsightsParams | IAccountListDataParams, _, type: TileTypes): Observable<ITileData> {
    if (type === TileTypes.AccountsList) {
      return combineLatest([
        this.getSelectedColumnForTile((params as IAccountListDataParams).fld),
        this.store.pipe(select(OrgConfigStore.isSalesActivityEnabled))
      ]).pipe(
        mergeMap(([selectedColumn, isSalesActivityEnabled]: [IAccountsColumn, boolean]) => {
          const updatedParams = {
            ...params,
            fieldType: selectedColumn.apiType,
            tbl: selectedColumn.apiType,
            fld: toSnakeCase(selectedColumn.name),
            dir: 'd',
            ps: MAX_EXTENDED_ROWS_NUMBER,
            withSalesActivity: isSalesActivityEnabled
          } as IAccountListDataParams;
          return this.source.loadListData$(updatedParams).pipe(
            map((data: IAccountListDataResponse) => this.tileAdapterListData(data))
          );
        })
      );
    }

    if (type === TileTypes.EngagementInsights) {
      return this.source.loadMetricInsights$(params as IMetricInsightsParams).pipe(
        withLatestFrom(this.getEngagementLabel(params as IMetricInsightsParams)),
        map(([data, labels]: [IMetricInsightsResponse, IMetricInsightsParams]) =>
          this.tileAdapterEngagement(data, labels))
      );
    }

    return throwError(`Unknown tile type: ${type}`);
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    if (tile.type === TileTypes.AccountsList) {
      return this.getAccountListCustomSettings(tile);
    }

    if (tile.type === TileTypes.EngagementInsights) {
      return this.getEngagementCustomSettings(tile);
    }
  }

  getTileDefaultSettings(params: ITileSettings = {}, type: TileTypes): ITileSettings {
    switch (type) {
      case TileTypes.AccountsList: {
        const cohort = {
          cohort: replaceLegacyCohort(params.cohort as string) || AgoFromToday.THREE_MONTHS_AGO_FROM_TODAY,
          ...getStartAndEndDateFromParams(params.cohort as string, params.startDate, params.endDate)
        };
        return {
          ...params, // in case if we have global filters we have to keep them
          ...cohort,
          [AccountListsFields.AccountFilter]: params[AccountListsFields.AccountFilter] || 'all',
          dir: params.dir || 'd',
          fieldType: params.fieldType || null,
          [AccountListsFields.Field]: params[AccountListsFields.Field] || 'campaignMembers',
          tbl: params.tbl || null,
          pg: params.pg || 1,
          ps: params.ps || MAX_EXTENDED_ROWS_NUMBER,
          withSalesActivity: params.withSalesActivity || true,
        };
      }

      case TileTypes.EngagementInsights: {
        const cohort = {
          cohort: replaceLegacyCohort(params.cohort as string) || All.ALL_TIME,
          ...getStartAndEndDateFromParams(params.cohort as string, params.startDate, params.endDate)
        };
        return {
          ...params, // in case if we have global filters we have to keep them
          ...cohort,
          [EngagementInsightsFields.Models]: params[EngagementInsightsFields.Models] || [],
          [EngagementInsightsFields.AccountCorrelation]: params[EngagementInsightsFields.AccountCorrelation] || false,
          [EngagementInsightsFields.AccountField]: params[EngagementInsightsFields.AccountField] || AccountTypes.Opptys,
          [EngagementInsightsFields.MetricField]: params[EngagementInsightsFields.MetricField] || AccountMetrics.Impressions,
          [EngagementInsightsFields.Threshold]: params[EngagementInsightsFields.Threshold] || 1,
          [EngagementInsightsFields.TimeUnit]: params[EngagementInsightsFields.TimeUnit] || TimeUnits.Auto,
        };
      }

      default:
        return {};
    }
  }

  getMetricsOptions(): ILabelValue[] {
    return [
      {
        label: 'shared.accountHub.metricOptions.impressions',
        value: AccountMetrics.Impressions,
      },
      {
        label: 'shared.accountHub.metricOptions.clicks',
        value: AccountMetrics.Clicks,
      },
      {
        label: 'shared.accountHub.metricOptions.pageViews',
        value: AccountMetrics.PageViews,
      },
      {
        label: 'shared.accountHub.metricOptions.spikes',
        value: AccountMetrics.Spikes,
      }
    ];
  }

  getAccountTypesOptions(): ILabelValue[] {
    return [
      {
        label: 'shared.accountHub.accountTypes.impressions',
        value: AccountTypes.Impressions,
      },
      {
        label: 'shared.accountHub.accountTypes.clicks',
        value: AccountTypes.Clicks,
      },
      {
        label: 'shared.accountHub.accountTypes.pageViews',
        value: AccountTypes.PageViews,
      },
      {
        label: 'shared.accountHub.accountTypes.spikes',
        value: AccountTypes.Spikes,
      },
      {
        label: 'shared.accountHub.accountTypes.opptys',
        value: AccountTypes.Opptys,
      },
      {
        label: 'shared.accountHub.accountTypes.deals',
        value: AccountTypes.Deals,
      }
    ];
  }

  getTimeUnits(): ILabelValue[] {
    return [
      {
        label: 'shared.accountHub.timeUnits.auto',
        value: TimeUnits.Auto
      }, {
        label: 'shared.accountHub.timeUnits.days',
        value: TimeUnits.Day
      }, {
        label: 'shared.accountHub.timeUnits.weeks',
        value: TimeUnits.Week
      }, {
        label: 'shared.accountHub.timeUnits.months',
        value: TimeUnits.Month
      }, {
        label: 'shared.accountHub.timeUnits.quarters',
        value: TimeUnits.Quarters
      }, {
        label: 'shared.accountHub.timeUnits.years',
        value: TimeUnits.Years
      }
    ];
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[]> {
    if (type === TileTypes.AccountsList) {
      return this.getAccountHubColumns().pipe(
        map((columns: IAccountsColumn[]) => columns.filter(column => {
          return column.dataType === DataTypeEnum.Number || column.dataType === DataTypeEnum.Currency;
        })),
        map(filteredColumns => filteredColumns.map(column => ({
          label: column.displayName,
          value: column.name
        }))),
        map((fieldOptions: ILabelValue[]) => {
          return [
            {
              key: AccountListsFields.AccountFilter,
              label: 'shared.accountHub.filters.actions',
              type: TileSettingsControlTypes.Selector,
              options: this.getAccountListFilters()
            }, {
              key: AccountListsFields.Field,
              label: 'shared.accountHub.filters.field',
              type: TileSettingsControlTypes.Selector,
              options: fieldOptions
            }
          ];
        })
      );
    }

    if (type === TileTypes.EngagementInsights) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const accountTypeOptions = this.getAccountTypesOptions();
      return this.store.pipe(select(OrgConfigStore.getSpikeModels)).pipe(
        map((spikeModels: string[]) => ([
          {
            key: EngagementInsightsFields.TimeUnit,
            label: 'shared.accountHub.filters.time',
            type: TileSettingsControlTypes.Selector,
            options: null,
            getCustomOptions(settings: ITileSettings, settingsForm: FormGroup) {
              const allOptions = that.getTimeUnits();
              if (settings[EngagementInsightsFields.AccountField] === AccountTypes.Impressions
                || settings[EngagementInsightsFields.MetricField] === AccountMetrics.Spikes) {
                if (settings[EngagementInsightsFields.TimeUnit] === TimeUnits.Day) {
                  settingsForm.get(EngagementInsightsFields.TimeUnit).setValue(null);
                }

                return allOptions.filter(option => option.value !== TimeUnits.Day);
              }

              return allOptions;
            }
          },
          {
            key: EngagementInsightsFields.AccountField,
            label: 'shared.accountHub.filters.accounts',
            type: TileSettingsControlTypes.Selector,
            options: accountTypeOptions
          },
          {
            key: EngagementInsightsFields.Threshold,
            label: 'shared.accountHub.filters.threshold',
            type: TileSettingsControlTypes.InputNumber,
            options: null,
            show(settings: ITileSettings) {
              const shouldHide = [
                AccountTypes.Opptys,
                AccountTypes.Deals,
                AccountTypes.Spikes
              ].includes(settings[EngagementInsightsFields.AccountField] as AccountTypes);

              return !shouldHide;
            }
          },
          {
            key: EngagementInsightsFields.MetricField,
            label: 'shared.accountHub.filters.engagements',
            type: TileSettingsControlTypes.Selector,
            options: this.getMetricsOptions()
          },
          {
            key: EngagementInsightsFields.Models,
            label: 'shared.accountHub.filters.models',
            noneLabel: 'shared.ui.reportCheckboxSelector.all',
            type: TileSettingsControlTypes.Checkbox,
            options: null,
            show(settings: ITileSettings) {
              return settings[EngagementInsightsFields.AccountField] === AccountTypes.Spikes ||
                settings[EngagementInsightsFields.MetricField] === AccountMetrics.Spikes;
            },
            getCustomOptions(settings: ITileSettings) {
              return spikeModels?.map((model: string) => ({
                label: model,
                value: model,
                checked: settings[EngagementInsightsFields.Models]
                  ? (settings[EngagementInsightsFields.Models] as EngagementInsightsFields[]).includes(model as EngagementInsightsFields)
                  : false
              })) || [];
            }
          },
          {
            key: EngagementInsightsFields.AccountCorrelation,
            label: 'shared.accountHub.filters.accountCorrelation',
            getCustomLabel(settings: ITileSettings) {
              const selectedAccountType = accountTypeOptions.find(option =>
                option.value === settings[EngagementInsightsFields.AccountField]);
              const accountTypeLabel = selectedAccountType
                ? that.translateService.instant(selectedAccountType.label)
                : '';
              return that.translateService.instant('shared.accountHub.filters.accountCorrelation', {value: accountTypeLabel});
            },
            type: TileSettingsControlTypes.Selector,
            options: [{
              label: 'shared.accountHub.accountCorrelation.yes',
              value: true
            }, {
              label: 'shared.accountHub.accountCorrelation.no',
              value: false
            }]
          },
        ]))
      );
    }
  }

  private tileAdapterListData(data: IAccountListDataResponse): ITileData {
    if (!data?.rows) {
      return {
        items: []
      };
    }

    return {
      items: data.rows as unknown as ITileDataItem[],
      totalCount: data.totalRows
    };
  }

  private getAccountListCustomSettings(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    return combineLatest([
      this.getSelectedColumnForTile('accountName'),
      this.getSelectedColumnForTile((tile.settings as unknown as IAccountListDataParams).fld)
    ]).pipe(
      map((columns: IAccountsColumn[]) => {
        return {
          table: {
            columns,
            totalsPluralLabel: 'shared.accountHub.list.totalsPluralLabel',
            totalsLabel: 'shared.accountHub.list.totalsLabel',
          }
        };
      })
    );
  }

  private getSelectedColumnForTile(field: string): Observable<IAccountsColumn> {
    return this.getAccountHubColumns().pipe(
      map((columns: IAccountsColumn[]) => {
        const selectedColumn = columns.find(column => column.name === toCamelCase(field));
        return selectedColumn || columns[1]; // industry column
      })
    );
  }

  private getEngagementCustomSettings(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    const visualization = getVisualizationType(tile.type, tile.settings);
    const seriesType = visualization === VisualizationTypes.Line
      ? 'LineSeries'
      : 'ColumnSeries';
    return this.getEngagementLabel(tile.settings as unknown as IMetricInsightsParams).pipe(
      map((labels: EngagementLabels) => {
        return {
          chart: {
            numberFormatter: {
              numberFormat: '#,###.',
            },
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: {
                category: TILE_DATA_FIELD_NAME
              },
              renderer: {
                cellStartLocation: 0.2,
                cellEndLocation: 0.8,
                grid: {
                  disabled: true
                },
                minGridDistance: 40,
              }
            }],
            yAxes: [
              {
                id: 'v1',  // reference which is need by series
                type: 'ValueAxis',
                min: 0,
                title: {
                  text: toCapitalize(labels.accountField),
                  fill: '#637178',
                  truncate: true,
                  maxWidth: 160,
                },
                numberFormatter: {
                  type: 'NumberFormatter',
                  forceCreate: true,
                  numberFormat: '#a'
                }
              },
              {
                id: 'v2', // reference which is need by series
                type: 'ValueAxis',
                syncWithAxis: 'v1',
                min: 0,
                title: {
                  text: toCapitalize(labels.metricField),
                  fill: '#637178',
                  truncate: true,
                  maxWidth: 160,
                },
                numberFormatter: {
                  type: 'NumberFormatter',
                  forceCreate: true,
                  numberFormat: '#a'
                },
                renderer: {
                  opposite: true
                }
              }
            ],
            series: [
              {
                yAxis: 'v1',
                type: seriesType,
                dataFields: {
                  valueY: labels.accountField,
                  categoryX: TILE_DATA_FIELD_NAME
                },
                name: labels.accountField,
              },
              {
                yAxis: 'v2',
                type: seriesType,
                dataFields: {
                  valueY: labels.metricField,
                  categoryX: TILE_DATA_FIELD_NAME
                },
                name: labels.metricField,
              }
            ]
          }
        };
      })
    );
  }

  private getEngagementLabel(params: IMetricInsightsParams): Observable<EngagementLabels> {
    const defaultAccountField = 'Accounts';
    const defaultMetricField = 'Total';
    const metricOption = this.getMetricsOptions().find(option => option.value === params.metricField);
    const accountOption = this.getAccountTypesOptions().find(option => option.value === params.accountField);

    if (metricOption?.label && accountOption?.label) {
      return combineLatest([
        this.translateService.get(metricOption.label),
        this.translateService.get(accountOption.label)
      ]).pipe(
        map((labels: string[]) => ({
          metricField: labels[0] || defaultMetricField,
          accountField: labels[1] || defaultAccountField,
        }))
      );
    }

    return of({
      metricField: defaultMetricField,
      accountField: defaultAccountField,
    });
  }

  private tileAdapterEngagement(
    data: IMetricInsightsResponse,
    labels: EngagementLabels
  ): ITileData {
    if (!data?.length) {
      return {
        items: []
      };
    }

    return {
      items: data.map((item: IMetricInsightsItem) => ({
        [labels.accountField]: item.accounts,
        [labels.metricField]: item.metric,
        [TILE_DATA_FIELD_NAME]: item.timeIntervalFormatted
      }))
    };
  }
}
