import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  NgModule,
  Output,
  QueryList
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsExpansionPanelModule } from '@terminus-lib/ui-expansion-panel';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { combineLatest } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';

import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { FilterByCampaignComponent } from '@features/filter-by-campaign';
import { ReportFilterSelectorComponent } from '@ui/components/report-filter-selector';
import { ReportFilterInputComponent } from '@ui/components/report-filter-input';
import { ReportCheckboxSelectorComponent } from '@ui/components/report-checkbox-selector';
import { BenchmarkControlComponent } from '@ui/components/benchmark-control';
import { ReportFilterChange } from '@shared/classes';

@WithDestroy
@Component({
  selector: 'tsh-report-setting-panel',
  templateUrl: './report-setting-panel.component.html',
  styleUrls: ['./report-setting-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportSettingPanelComponent implements AfterContentInit {
  @Input() public reportName: string;
  @Input() public isDisabled = false;
  @Input() public specificSettingSubtitleHead: string;
  @Output() public apply = new EventEmitter<void>();

  @ContentChildren(ReportFilterSelectorComponent) selectorFilters: QueryList<ReportFilterSelectorComponent>;
  @ContentChildren(ReportFilterInputComponent) inputFilters: QueryList<ReportFilterInputComponent>;
  @ContentChildren(BenchmarkControlComponent) benchmarkFilters: QueryList<BenchmarkControlComponent>;
  @ContentChildren(ReportCheckboxSelectorComponent) checkboxFilters: QueryList<ReportCheckboxSelectorComponent>;
  @ContentChildren(FilterByCampaignComponent) campaignFilters: QueryList<FilterByCampaignComponent>;

  public get allFilters(): ReportFilterChange[] {
    return [
      ...this.selectorFilters?.toArray() || [],
      ...this.inputFilters?.toArray() || [],
      ...this.benchmarkFilters?.toArray() || [],
      ...this.checkboxFilters?.toArray() || [],
      ...this.campaignFilters?.toArray() || [],
    ];
  }

  public get filterCount(): number {
    return this.allFilters.length;
  }

  public isExpanded = false;
  public isPending = false;
  public reportSettingsCount = 0;
  // TODO: migrate global-filters store or refactor it to Input()
  public appliedFiltersNumber$ = this.store.pipe(select(GlobalFiltersStore.getAppliedFiltersNumber));

  constructor(private store: Store<unknown>,
              private changeDetector: ChangeDetectorRef) {
  }

  ngAfterContentInit(): void {
    if (this.filterCount) {
      // get number of selectors
      this.reportSettingsCount = this.filterCount;
      this.listenToPendingChange();
    }
  }

  emitApply(): void {
    this.apply.emit();
    // update reportSettingsCount after applying filter
    // in case if we have optional filters, which could be hide
    this.reportSettingsCount = this.filterCount;
    // reset isPending after applying changes
    this.isPending = false;
  }

  panelExpandedChange(state: boolean): void {
    this.isExpanded = state;
  }

  listenToPendingChange(): void {
    // collect observables for all filters
    const observables = this.allFilters.map(filter => filter.change$);
    // subscribe to them
    combineLatest<boolean[]>(observables)
      .pipe(untilComponentDestroyed(this))
      .subscribe((changes: boolean[]) => {
        // if there is change then set isPending to true
        if (changes.some(change => change)) {
          this.isPending = true;
          this.changeDetector.detectChanges();
        }
      });
  }
}

@NgModule({
  declarations: [ReportSettingPanelComponent],
  exports: [ReportSettingPanelComponent],
  imports: [
    CommonModule,
    TsExpansionPanelModule,
    TsButtonModule,
    TranslateModule,
  ]
})
export class ReportSettingPanelModule {
}
