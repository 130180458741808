import { GoParams, IReportColumn, IReportLink } from '@shared/interfaces';
import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { CampaignChartColors, CampaignChartTypes } from '../enums/campaign-chart.enum';
import { ICampaignChartType, ICampaignTouch } from '../interfaces';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { IAttributionDeal, IAttributionLead, IAttributionOppty, ICampaignSpecificFilters } from '@measurement-studio/interfaces';

export const LEADS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'leadName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.person',
    dataType: DataTypeEnum.Text
  }, {
    name: 'title',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.title',
    dataType: DataTypeEnum.Text
  }, {
    name: 'email',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.email',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'accountName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.account',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionLead): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId,
        }
      };
    }
  }, {
    name: 'stage',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.stage',
    dataType: DataTypeEnum.Text
  }, {
    name: 'activityDate',
    width: 207,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.activityDate',
    dataType: DataTypeEnum.Date
  }
];

export const WEB_ACTIVITY_LEADS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'leadName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.lead',
    dataType: DataTypeEnum.Text
  }, {
    name: 'title',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.title',
    dataType: DataTypeEnum.Text
  }, {
    name: 'email',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.email',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'accountName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.account',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionLead): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId,
        }
      };
    }
  }, {
    name: 'stage',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.stage',
    dataType: DataTypeEnum.Text
  }, {
    name: 'activityDate',
    width: 207,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.activityDate',
    dataType: DataTypeEnum.Date
  }
];

export const LEADS_NESTED_COLUMNS: IReportColumn[] = [
  {
    displayName: 'feature.common.utmNames.channel',
    name: 'name',
    width: 250,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
    internalLink(row: ICampaignTouch, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.WebActivitiesLeads),
        queryParams: {
          campaignId: row.campaignId,
          cohort: filters.cohort,
          model: filters.modelType,
          dataSet: filters.dataSet
        }
      };
    }
  }, {
    name: 'group',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.common.utmNames.channelGroup',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'date',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.leads.nestedTable.date',
    dataType: DataTypeEnum.Date
  }
];

export const WEB_ACTIVITY_LEADS_NESTED_COLUMNS: IReportColumn[] = [
  {
    displayName: 'feature.webActivitiesSpecific.table.leads.nestedTable.campaign',
    name: 'name',
    width: 387,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
    internalLink(row: ICampaignTouch, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.WebActivitiesLeads),
        queryParams: {
          campaignId: row.campaignId,
          cohort: filters.cohort,
          model: filters.modelType,
          dataSet: filters.dataSet
        }
      };
    }
  }, {
    name: 'group',
    width: 387,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.leads.nestedTable.campaignType',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'date',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.leads.nestedTable.date',
    dataType: DataTypeEnum.Date
  }
];

export const CAMPAIGN_CHART_OPTIONS: ICampaignChartType[] = [
  {
    label: 'feature.campaignSpecific.chart.campaignResponses',
    value: CampaignChartTypes.CampaignResponses,
    color: CampaignChartColors.CampaignResponses,
    dataType: DataTypeEnum.Number
  }, {
    label: 'feature.campaignSpecific.chart.opportunities',
    value: CampaignChartTypes.Opportunities,
    color: CampaignChartColors.Opportunities,
    dataType: DataTypeEnum.Number
  }, {
    label: 'feature.campaignSpecific.chart.deals',
    value: CampaignChartTypes.Deals,
    color: CampaignChartColors.Deals,
    dataType: DataTypeEnum.Number
  }, {
    label: 'feature.campaignSpecific.chart.pipelineAttributed',
    value: CampaignChartTypes.PipelineAttributed,
    color: CampaignChartColors.PipelineAttributed,
    dataType: DataTypeEnum.Currency
  }, {
    label: 'feature.campaignSpecific.chart.revenueAttributed',
    value: CampaignChartTypes.RevenueAttributed,
    color: CampaignChartColors.RevenueAttributed,
    dataType: DataTypeEnum.Currency
  }
];

export const DEALS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    dataType: DataTypeEnum.Text,
  }, {
    name: 'opptyName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.name',
    dataType: DataTypeEnum.Text,
    internalLink(row: IAttributionDeal, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunitySpecificTotalTouches),
        queryParams: {
          oppty: row.opptyId,
          isId: true,
          model: filters.modelType,
        }
      };
    }
  }, {
    name: 'accountName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.account',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionDeal): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId
        }
      };
    }
  }, {
    name: 'waterfallStage',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.stage',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'type',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.type',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'totalAmount',
    width: 200,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.deals.amount',
    dataType: DataTypeEnum.Currency,
  }, {
    name: 'createdDate',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.createdDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'closeDate',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.closeDate',
    dataType: DataTypeEnum.Date,
  },
];

export const WEB_ACTIVITY_DEALS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    dataType: DataTypeEnum.Text,
  }, {
    name: 'opptyName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.deals.deal',
    dataType: DataTypeEnum.Text,
    internalLink(row: IAttributionDeal, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunitySpecificTotalTouches),
        queryParams: {
          oppty: row.opptyId,
          isId: true,
          model: filters.modelType,
        }
      };
    }
  }, {
    name: 'accountName',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.account',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionDeal): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId
        }
      };
    }
  }, {
    name: 'totalAmount',
    width: 200,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.deals.amount',
    dataType: DataTypeEnum.Currency,
  }, {
    name: 'createdDate',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.createdDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'closeDate',
    width: 200,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.closeDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'amount',
    width: 165,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.opptys.amountPipeline',
    dataType: DataTypeEnum.Currency,
  }
];

export const DEALS_NESTED_COLUMNS: IReportColumn[] = [
  {
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.leadName',
    name: 'leadName',
    width: 220,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
    link(row: ICampaignTouch): GoParams {
      return {
        routeId: RouteItemEnum.Salesforce,
        queryParams: {
          campaignId: row.campaignId,
        }
      };
    }
  }, {
    name: 'leadTitle',
    width: 285,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.leadTitle',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'group',
    width: 138,
    startSorting: 'asc',
    displayName: 'feature.common.utmNames.channelGroup',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'source',
    width: 150,
    startSorting: 'asc',
    displayName: 'feature.common.utmNames.channel',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'date',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.date',
    dataType: DataTypeEnum.Date
  }
];

export const WEB_ACTIVITY_DEALS_NESTED_COLUMNS: IReportColumn[] = [
  {
    displayName: 'feature.webActivitiesSpecific.table.opptys.nestedTable.leadName',
    name: 'leadName',
    width: 220,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'leadTitle',
    width: 285,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.leadTitle',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'group',
    width: 150,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.leads.nestedTable.campaignType',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'name',
    width: 250,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.opptys.nestedTable.campaign',
    dataType: DataTypeEnum.Text,
    internalLink(row: ICampaignTouch): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
        queryParamsHandling: 'merge',
        queryParams: {
          campaignId: row.campaignId,
        }
      };
    }
  }, {
    name: 'date',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.webActivitiesSpecific.table.opptys.nestedTable.date',
    dataType: DataTypeEnum.Date
  }
];

export const OPPTYS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    dataType: DataTypeEnum.Text,
  }, {
    name: 'opptyName',
    width: 220,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.name',
    dataType: DataTypeEnum.Text,
    internalLink(row: IAttributionOppty, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunitySpecificTotalTouches),
        queryParams: {
          oppty: row.opptyId,
          isId: true,
          model: filters.modelType,
        }
      };
    }
  }, {
    name: 'accountName',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.accountName',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionOppty): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId
        }
      };
    }
  }, {
    name: 'waterfallStage',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.stage',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'type',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.type',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'totalAmount',
    width: 165,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.opptys.amount',
    dataType: DataTypeEnum.Currency,
  }, {
    name: 'createdDate',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.createdDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'closeDate',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.closeDate',
    dataType: DataTypeEnum.Date,
  }
];

export const WEB_ACTIVITY_OPPTYS_COLUMNS: IReportColumn[] = [
  {
    displayName: '',
    name: 'icon',
    width: 55,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'opptyName',
    width: 220,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.name',
    dataType: DataTypeEnum.Text,
    internalLink(row: IAttributionOppty, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunitySpecificTotalTouches),
        queryParams: {
          oppty: row.opptyId,
          isId: true,
          model: filters.modelType,
        }
      };
    }
  }, {
    name: 'accountName',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.accountName',
    dataType: DataTypeEnum.Text,
    link(row: IAttributionOppty): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId
        }
      };
    }
  }, {
    name: 'waterfallStage',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.stage',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'totalAmount',
    width: 165,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.opptys.amount',
    dataType: DataTypeEnum.Currency,
  }, {
    name: 'createdDate',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.createdDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'closeDate',
    width: 165,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.opptys.closeDate',
    dataType: DataTypeEnum.Date,
  }, {
    name: 'amountPipeline',
    width: 165,
    startSorting: 'desc',
    displayName: 'feature.campaignSpecific.table.opptys.amountPipeline',
    dataType: DataTypeEnum.Currency,
  }
];

export const OPPTYS_NESTED_COLUMNS: IReportColumn[] = [
  {
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.leadName',
    name: 'leadName',
    width: 220,
    startSorting: 'asc',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'leadTitle',
    width: 285,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.leadTitle',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'group',
    width: 138,
    startSorting: 'asc',
    displayName: 'feature.common.utmNames.channelGroup',
    dataType: DataTypeEnum.Text,
  }, {
    name: 'name',
    width: 150,
    startSorting: 'asc',
    displayName: 'feature.common.utmNames.channel',
    dataType: DataTypeEnum.Text,
    internalLink(row: ICampaignTouch, filters: ICampaignSpecificFilters): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.WebActivitiesLeads),
        queryParams: {
          campaignId: row.campaignId,
          cohort: filters.cohort,
          dataSet: filters.dataSet
        }
      };
    }
  }, {
    name: 'date',
    width: 255,
    startSorting: 'asc',
    displayName: 'feature.campaignSpecific.table.deals.nestedTable.date',
    dataType: DataTypeEnum.Date
  }];

export enum TileSettingsAttributionFields {
  Campaign = 'campaignId',
  DataSet = 'dataSet',
  InfluenceType = 'influenceType',
  ModelType = 'modelType',
}
