import { DataTypeEnum } from '@shared/enums';
import { IReportColumn } from '@shared/interfaces';

export const FLAT_STAGE_PROGRESSION_DETAIL_COLUMNS: IReportColumn[] = [
  {
    name: 'name',
    width: 800,
    startSorting: 'asc',
    displayName: 'measurementStudio.features.stagesSnapshot.columns.name',
    dataType: DataTypeEnum.Text,
  },
  {
    name: 'date',
    width: 150,
    startSorting: 'asc',
    displayName: 'measurementStudio.features.stagesSnapshot.columns.date',
    dataType: DataTypeEnum.Date,
  },
];
