import { NewTrendsType, TrendsType } from '@shared/enums';
import { TrendingPrevious } from '@shared/interfaces';

export function adaptNewTrendsTypeToOldOne(newType: NewTrendsType): { type: TrendsType, previous: TrendingPrevious } {
  switch (newType) {
    case NewTrendsType.BenchmarkCohort:
      return {
        type: TrendsType.Benchmark,
        previous: 'true'
      };
    case NewTrendsType.BenchmarkYeah:
      return {
        type: TrendsType.Benchmark,
        previous: 'yoy'
      };
    case NewTrendsType.Goal:
      return {
        type: TrendsType.Goal,
        previous: null
      };
    case NewTrendsType.Trend:
      return {
        type: TrendsType.Trend,
        previous: null
      };
    default:
      throw new Error('There is no such trend type');
  }
}

export function adaptOldTrendsTypeToNewOne(oldType: TrendsType, previous?: TrendingPrevious): NewTrendsType {
  switch (oldType) {
    case TrendsType.Benchmark:
      return previous === 'true' ? NewTrendsType.BenchmarkCohort : NewTrendsType.BenchmarkYeah;

    case TrendsType.Goal:
      return NewTrendsType.Goal;

    case TrendsType.Trend:
      return NewTrendsType.Trend;

    default:
      throw new Error('There is no such trend type');
  }
}
