import { ISavedReport, savedReportFactory } from './report.interface';
import { IFactoryParams } from '../factory-params.interface';

export interface ISavedFolder {
    folderId: number;
    folderName: string;
    folderPermission: SavedFolderPermissionTypes;
    isMine: boolean;
    email: string;
    contents: ISavedReport[];
}

export interface ICreateSavedFolder {
    folderName: string;
    folderPermission: SavedFolderPermissionTypes;
    folderType: SavedFolderTypes.SavedReport;
}

export interface ICreateSavedFolderAPI {
    folder_name: string;
    folder_permission: SavedFolderPermissionTypes;
    folder_type: SavedFolderTypes.SavedReport;
}

export interface IUpdateSavedFolder extends ICreateSavedFolder {
    folderId: number;
}

export interface IUpdateSavedFolderAPI extends ICreateSavedFolderAPI {
    folder_id: number;
}

export interface IDeleteSavedFolder {
    folderId: number;
    folderType: SavedFolderTypes.SavedReport;
}

export interface IDeleteSavedFolderAPI {
    folder_id: number;
    folder_type: SavedFolderTypes.SavedReport;
}

export enum SavedFolderPermissionTypes {
    Public = 'public',
    Private = 'private',
    Readonly = 'read-only',
}

export const folderPermissionOptions: SavedFolderPermissionTypes[] = [
    SavedFolderPermissionTypes.Public,
    SavedFolderPermissionTypes.Private,
    SavedFolderPermissionTypes.Readonly,
];

export enum SavedFolderTypes {
    AccountList = 'account_list',
    SavedReport = 'saved_report',
}

export interface IFolderOption {
    folderId: number;
    folderName: string;
}

export function savedFolderFactory({
    n = 0,
    defaultValue = true,
    overrides = {},
}: IFactoryParams<ISavedFolder, number, boolean> = {}): ISavedFolder {
    return {
        folderId: n,
        folderName: `Folder ${n}`,
        folderPermission: SavedFolderPermissionTypes.Public,
        isMine: defaultValue,
        email: `test${n}@test.com`,
        contents: [savedReportFactory({ n })],
        ...overrides
    };
}

export function createSavedFolderFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<ICreateSavedFolder, number, boolean> = {}): ICreateSavedFolder {
    return {
        folderName: `Folder ${n}`,
        folderPermission: SavedFolderPermissionTypes.Public,
        folderType: SavedFolderTypes.SavedReport,
        ...overrides
    };
}

export function updateSavedFolderFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IUpdateSavedFolder, number, boolean> = {}): IUpdateSavedFolder {
    return {
        folderId: n,
        folderName: `Folder ${n}`,
        folderPermission: SavedFolderPermissionTypes.Public,
        folderType: SavedFolderTypes.SavedReport,
        ...overrides
    };
}

export function deleteSavedFolderFactory({
    n = 0,
    overrides = {},
}: IFactoryParams<IDeleteSavedFolder, number, boolean> = {}): IDeleteSavedFolder {
    return {
        folderId: n,
        folderType: SavedFolderTypes.SavedReport,
        ...overrides
    };
}

export function folderOptionFactory({
     n = 1,
     overrides = {},
 }: IFactoryParams<IFolderOption, number, boolean> = {}): IFolderOption {
    return {
        folderId: n,
        folderName: `Folder ${n}`,
        ...overrides
    };
}

export function convertUpdateFolderData(data: IUpdateSavedFolder): IUpdateSavedFolderAPI {
    return {
        folder_id: data.folderId,
        folder_name: data.folderName,
        folder_permission: data.folderPermission,
        folder_type: data.folderType,
    };
}

export function convertCreateFolderData(data: ICreateSavedFolder): ICreateSavedFolderAPI {
    return {
        folder_name: data.folderName,
        folder_permission: data.folderPermission,
        folder_type: data.folderType,
    };
}

export function convertDeleteFolderData(data: IDeleteSavedFolder): IDeleteSavedFolderAPI {
    return {
        folder_id: data.folderId,
        folder_type: data.folderType,
    };
}
