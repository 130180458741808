import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { IAuthToken, IExportToken } from '@shared/interfaces';

export const userAuthenticateToken = new InjectionToken<IUserAuthenticate>('UserAuthenticate');

export interface IUserAuthenticate {
  authenticate$(tokenNearingExpiration: string): Observable<IAuthToken>;
  authenticateUnifiedLogin$(oktaToken: string): Observable<IAuthToken>;
  authenticateExportToken$(): Observable<IExportToken>;
}
