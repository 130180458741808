<ng-container *ngIf="appSettings$ | async as appSettings">
  <mat-sidenav-container
    class="tsh-app"
    autosize="false"
    hasBackdrop="false"
    [class.right-sidenav-extended]="(rightSidenavExtended$ | async) && (isUtilitiesSidenavVisible$ | async)">
    <!-- left side nav containing terminus logo and app menu items -->
    <mat-sidenav *ngIf="(canUseAccountHub$ | async) && !greenfieldBridge.isConnected"
                 class="left-sidenav"
                 position="start"
                 opened="true"
                 mode="side"
                 [fixedInViewport]="false">
      <tsh-app-menu (switchUser)=switchUser($event)
                    [baseHubPath]="baseHubPath"
                    [user]="userProfile$ | async"
                    [switcherContent]="switcherContent$ | async"
                    [selectedNavItem]="selectedNavItem$ | async"
                    [menu]="navMenu$ | async"
                    currentTitle="common.measurementStudio"
                    [zdSsoAccess]="zdSsoAccess$ | async"
                    [options]="sidenavUserMenuLinks$ | async">
      </tsh-app-menu>
    </mat-sidenav>

    <!-- content area containing application content, left sidenav toggle button, page title and use info -->
    <mat-sidenav-content
      class="sidenav-content"
      [ngClass]="{'hidden-nav': greenfieldBridge.isConnected}"
      [class.content-with-utilities]="isUtilitiesSidenavVisible$ | async"
      [class.content-without-sidenav]="(canUseAccountHub$ | async) === false">
      <!-- header containing report title and last data cycle updated date -->
      <tsh-app-header [selectedRoute]="selectedRoute$ | async"
                      [selectedNavItem]="selectedNavItem$ | async"
                      [isNewLayout]="isNewLayout$ | async"
                      [lastUpdate]="appSettings.dcLastUpdatedDate"
                      [specificLink]="specificLink$ | async"
                      applicationTitle="hub.appHeader.measurementStudio"
                      [parent]="parent$ | async"
                      (navigate)="navigate($event)">
      </tsh-app-header>

      <div class="main-content">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </mat-sidenav-content>

    <mat-sidenav
      *ngIf="(isUtilitiesSidenavVisible$ | async) && (hubToken$ | async)"
      class="right-sidenav"
      disableClose
      opened="true"
      position="end"
      fixedTopGap="64"
      fixedBottomGap="0"
      mode="over"
      [fixedInViewport]="false">
      <tsh-app-sidenav></tsh-app-sidenav>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
