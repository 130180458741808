import { panicActions } from './panic.actions';
import { Action, createReducer, on } from '@ngrx/store';

export const panicFeatureKey = 'panic';

export interface PanicState {
  message: string | null;
}

export const initialState: PanicState = {
  message: null,
};

const panicReducer = createReducer(
  initialState,
  on(panicActions.addPanicMessage, (state, action) => ({
    ...state,
    message: action.message
  })),
);

export function reducer(state, action: Action): PanicState {
  return panicReducer(state, action);
}
