import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { notificationMessagesActions } from '@notification-messages';
import { ICampaign, ICampaignById, ID, ILabelValue, OptionsProvider, userMessageFactory } from '@shared/interfaces';
import { CampaignsService } from '@shared/data-access/campaigns';

export class CampaignsOptionsProvider implements OptionsProvider<ILabelValue> {
  constructor(private campaignsService: CampaignsService,
              private store: Store<unknown>) {
  }

  getById(id: ID): Observable<ILabelValue> {
    return this.campaignsService.getCampaignsByIds$({campaignIds: [id as string]}).pipe(
      pluck('campaigns'),
      map(([campaign]: ICampaignById[]) => ({
        label: campaign.name,
        value: campaign.campaignId,
      })),
    );
  }

  getFilteredOptions(search: string): Observable<ILabelValue[]> {
    return this.campaignsService.getCampaigns$(search).pipe(
      map((campaigns: ICampaign[]) => campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.id,
      }))),
    );
  }

  getAllOptions(): ILabelValue[] {
    return [];
  }

  handleError(message: string): void {
    this.store.dispatch(notificationMessagesActions.addMessage({ message: userMessageFactory({n: message}) }));
  }
}
