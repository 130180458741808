import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFolderDialogComponent } from './components/create-folder-dialog/create-folder-dialog.component';
import { CreateListDialogComponent } from './components/create-list-dialog/create-list-dialog.component';
import { ManageListsDialogComponent }  from './components/manage-lists-dialog/manage-lists-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
// import { MoveListDialogComponent } from './components/move-list-dialog/move-list-dialog.component';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { StoreModule } from '@ngrx/store';
import { LIST_POPUP_KEY, reducer } from './state/list-popup.reducer';
import { EffectsModule } from '@ngrx/effects';
export * from './state/list-popup.effects';
import { ListPopupEffects } from './state/list-popup.effects';
import { TsExpansionPanelModule } from '@terminus-lib/ui-expansion-panel';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { ReactiveFormsModule } from '@angular/forms';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsSelectionListModule } from '@terminus-lib/ui-selection-list';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { SearchFoldersPipe } from './pipes/search-folders.pipe';

@NgModule({
  declarations: [
    CreateFolderDialogComponent,
    CreateListDialogComponent,
    ManageListsDialogComponent,
    // MoveListDialogComponent,
    SearchFoldersPipe
  ],
    imports: [
      CommonModule,
      TsButtonModule,
      TsExpansionPanelModule,
      TsInputModule,
      TsRadioGroupModule,
      ReactiveFormsModule,
      TsOptionModule,
      StoreModule.forFeature(
          LIST_POPUP_KEY,
          reducer
      ),
      EffectsModule.forFeature([
          ListPopupEffects
      ]),
      TranslateModule.forChild(),
      TsLoadingOverlayModule,
      TsSelectionListModule,
    ],
})
export class AccountListPopupModule {}
