import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';

export function isFiltersChanged<T>(
  usedQueryParams: T & IAppliedGlobalFiltersAsParams | T,
  newFilters: T,
  globalFilters: IAppliedGlobalFiltersAsParams
): boolean {
  const newQueryParams = {
    ...newFilters,
    ...globalFilters
  };

  if (!usedQueryParams) {
    return true;
  }

  const keys = [...Object.keys(newQueryParams), ...Object.keys(usedQueryParams)];
  const uniqueKeys = keys.filter((item: string, index: number) => keys.indexOf(item) === index);
  return uniqueKeys.some(key => newQueryParams[key] !== usedQueryParams[key]);
}
