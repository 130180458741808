import { InjectionToken } from '@angular/core';
import { createFeatureSelector } from '@ngrx/store';

import { DemoConfigState } from '@shared/data-access/demo-config-state';
import { ActionsWithReports, ReportType } from '@shared/interfaces';

export const ActionsWithReportsToken = new InjectionToken<ActionsWithReports>('ACTIONS_WITH_REPORTS');
export const MapOfReportsUrlsToken = new InjectionToken<Record<string, ReportType>>('MAP_OF_REPORTS_URLS');
export const ApplyInDemoModeToken = new InjectionToken<string>('APPLY_DEMO_MODE_TOKEN');
export const getDemoConfigState = createFeatureSelector<DemoConfigState>('DEMO_CONFIG_STATE_TOKEN');

export const ERROR_MESSAGES = {
  errorPollingCsvFailed: 'An error occurred while waiting for csv download',
  errorGenerateCsvFailed: 'An Error Occured while generating csv download',
  errorSlermsDownloadFailure: 'An error occurred while retrieving csv download',
  errorTriggerDownloadFailure: 'An error occurred while downloading csv report',
};

// export const applyInDemoMode = <T>(store: Store, fn: (data: T) => T) => (source: Observable<T>) =>
//   store.pipe(
//     select(getDemoConfigState),
//     take(1),
//     switchMap(config => source.pipe(switchMap(data => (config.enabled ? of(fn(data)) : of(data))))),
//   );
