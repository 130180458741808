import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { toCamelCase, untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import {
  DefaultTileInformation,
  IDashboardTile,
  IDataFilter,
  IDateCohort,
  ITargetFolderItem,
  ITileConfig,
  ITileSettings,
  TileConfigurationSteps,
  TileSettingsValidators,
  userMessageFactory
} from '@shared/interfaces';
import { TileFormFields } from '../enums/tile-form-fields.enum';
import { DateCohortsGroups, TileTypes } from '@shared/enums';
import * as DateCohortsStore from '@date-cohorts';
import { gaugeRangeRequiredValidator, gaugeRangeValidator } from '../helpers/tile-modal.validators';
import * as TileModalStore from '@shared/data-access/tile-modal';
import { IFolderItemsSelector, ITileModalData, tileModalDataToken } from '@shared/data-access/tile-modal';
import * as DashboardsStore from '@shared/data-access/dashboards';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import * as AccountListStore from '@shared/data-access/account-hub';
import { DateCohortsService } from '@date-cohorts';

@WithDestroy
@Component({
  selector: 'tsh-tile-modal',
  templateUrl: './tile-modal.component.html',
  styleUrls: ['./tile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileModalComponent implements OnInit {
  public readonly steps = TileConfigurationSteps;
  public readonly fields = TileFormFields;

  public isEditMode: boolean;
  public isLoadingPreview$ = this.store.pipe(select(TileModalStore.getIsLoadingData));
  public errorPreview$ = this.store.pipe(select(TileModalStore.getPreviewError));
  public previewData$ = this.store.pipe(select(TileModalStore.getPreviewData));
  public visualizationConfig$ = this.store.pipe(select(TileModalStore.getPreviewVisualizationConfig));
  public tilesByCategory$ = this.store.pipe(select(TileModalStore.getTilesByCategory));
  public dashboards$ = this.store.pipe(select(DashboardsStore.getList));
  public dateCohortOptions$ = this.store.pipe(select(DateCohortsStore.getGroupedDateCohortOptions()));
  // global filters data - start
  public globalFilterMenuState$ = this.store.pipe(select(TileModalStore.getGlobalFilterMenuBase));
  public accountListsMenuState$ = this.store.pipe(select(TileModalStore.getAccountListMenuBase));
  public globalFilterSearchState$ = this.store.pipe(select(TileModalStore.getGlobalFilterMenuAfterSearch));
  public accountListsSearchState$ = this.store.pipe(select(TileModalStore.getAccountListMenuAfterSearch));
  public appliedFiltersNumber$ = this.store.pipe(select(TileModalStore.getSelectedFiltersNumber));
  public selectedGlobalFilters$ = this.store.pipe(select(TileModalStore.getSelectedGlobalFilters));
  public allSelectFilters$ = this.store.pipe(select(TileModalStore.getGlobalFiltersTableData));
  public savedGlobalFilters$ = this.store.pipe(select(GlobalFiltersStore.getSavedDataFilters));
  public globalFilters$ = this.store.pipe(select(GlobalFiltersStore.getGlobalFiltersData));
  public accountListFolders$ = this.store.pipe(select(AccountListStore.getFolders));
  // global filters data - end
  public settingsFilters$ = this.store.pipe(select(TileModalStore.getSettingsFilters));
  public form: FormGroup;
  public selectedCohort$: BehaviorSubject<IDateCohort> = new BehaviorSubject<IDateCohort>(null);
  public allTiles: Record<TileTypes, DefaultTileInformation>;
  public needRefreshPreview = false;
  public showGlobalFiltersSection = false;
  public isHighlightedErrors = false;

  constructor(private dialogRef: MatDialogRef<TileModalComponent>,
              private store: Store<unknown>,
              private fb: FormBuilder,
              private translateService: TranslateService,
              private dateCohortService: DateCohortsService,
              @Inject(tileModalDataToken) private tileModalDataService: ITileModalData,
              @Inject(MAT_DIALOG_DATA) public data: Partial<ITileConfig>) {
  }

  ngOnInit(): void {
    this.tileModalDataService.loadOptions();
    this.store.dispatch(AccountListStore.getAccountLists());
    this.store.dispatch(TileModalStore.tileModalActions.getTilesInformation());
    this.store.pipe(select(TileModalStore.getTilesInfo), filter(Boolean), take(1))
      .subscribe((allTiles: Record<TileTypes, DefaultTileInformation>) => {
        this.allTiles = allTiles;
        const defaultTileInfo = this.getDefaultInfo(this.data.type);
        this.showGlobalFiltersSection = defaultTileInfo.hasGlobalFilters;
        this.getSelectedCohort(this.data, defaultTileInfo);
        this.getGlobalFilters();
        this.form = this.createForm(defaultTileInfo);
        // get data immediately if we open modal popup with Add to Dashboard button
        if (this.data.step === TileConfigurationSteps.Dashboard) {
          this.store.dispatch(TileModalStore.tileModalActions.getTilePreviewData({data: this.form.value}));
        }
        this.applySettingsValidators(this.form, defaultTileInfo.settingsValidators);
        this.store.dispatch(TileModalStore.tileModalActions.getSettingsControls({data: this.data.type || defaultTileInfo.type}));
        this.listenToFormChanges();
      });
    this.isEditMode = !!this.data.id;

    if (this.data.step === TileConfigurationSteps.Dashboard) {
      // load available dashboard to show them in table
      this.store.dispatch(DashboardsStore.dashboardsActions.loadList());
    }

    // if tile data and visualization config were passed to modal dialog then we have to
    // set them in the tile modal store. This is only for EDIT mode
    if (this.isEditMode && this.data.tileData && this.data.visualizationConfig) {
      this.store.dispatch(TileModalStore.tileModalActions.loadTilePreviewDataSuccess({data: this.data.tileData}));
      this.store.dispatch(TileModalStore.tileModalActions.setVisualizationConfig({data: this.data.visualizationConfig}));
    }
  }

  closeModal(): void {
    this.store.dispatch(TileModalStore.tileModalActions.resetPreviewData());
    this.dialogRef.close();
  }

  getButtonLabel(isEditMode: boolean, step: TileConfigurationSteps): string {
    switch (true) {
      case isEditMode:
        return 'shared.dashboards.dialog.tile.save';
      case step === TileConfigurationSteps.Settings:
        return 'shared.dashboards.dialog.tile.saveAndAdd';
      default:
        return 'shared.dashboards.dialog.tile.configure';
    }
  }

  handleStepAction(): void {
    switch (true) {
      case this.data.step === TileConfigurationSteps.Settings: {
        if (this.form.valid) {
          this.saveTileData();
        } else {
          // show errors if form is invalid
          const errors = this.form.errors || this.form.get(TileFormFields.Settings).errors;
          const errorMessage = errors ? Object.values(errors)[0] : 'Invalid form';
          this.store.dispatch(notificationMessagesActions.addMessage({ message: userMessageFactory({n: errorMessage}) }));
          this.isHighlightedErrors = true;
        }
        break;
      }

      default: {
        this.data.step = TileConfigurationSteps.Settings;
        // not load but get data - if there is already loaded data then just show them
        // otherwise load them from the backend
        this.store.dispatch(TileModalStore.tileModalActions.getTilePreviewData({data: this.form.value}));
      }
    }
  }

  handleBack(prevStep: TileConfigurationSteps): void {
    this.data.step = prevStep;
  }

  refreshPreview(): void {
    this.needRefreshPreview = false;
    // always load new data on clicking refresh
    this.store.dispatch(TileModalStore.tileModalActions.loadTilePreviewData({data: this.form.value}));
  }

  changeType(type: TileTypes): void {
    const defaultTileInfo = this.getDefaultInfo(type);
    this.needRefreshPreview = false;
    this.isHighlightedErrors = false;
    this.showGlobalFiltersSection = defaultTileInfo.hasGlobalFilters;
    this.store.dispatch(TileModalStore.tileModalActions.getSettingsControls({data: type}));
    this.store.dispatch(TileModalStore.tileModalActions.resetPreviewData());
    this.getSelectedCohort(this.allTiles[type], defaultTileInfo);
    this.patchFormByType(type);
    this.applySettingsValidators(this.form, defaultTileInfo.settingsValidators);
  }

  updateDateCohort(cohort: IDateCohort): void {
    if (this.shouldUpdateDateCohort(cohort)) {
      this.selectedCohort$.next(cohort);
      this.form.patchValue({
        [TileFormFields.Settings]: {
          [TileFormFields.Cohort]: cohort.cohort,
          [TileFormFields.StartDate]: cohort.startDate,
          [TileFormFields.EndDate]: cohort.endDate,
        }
      });
    }
  }

  getPreparedTile(): IDashboardTile | null {
    if (this.form?.value) {
      return {
        ...this.form.value,
        id: this.form.value.id || 'preview', // chart container need id value, so we can pass mock value
      };
    }
    // otherwise return null
    return null;
  }

  searchGlobalFilters(searchQuery: string): void {
    this.store.dispatch(TileModalStore.tileModalActions.updateGlobalFiltersSearchQuery({query: searchQuery}));
  }

  applySavedGlobalFilter(filter: IDataFilter): void {
    this.needRefreshPreview = true;
    this.store.dispatch(TileModalStore.tileModalActions.updateSelectedGlobalFilters({data: filter}));
  }

  toggleFolderSelect(event: IFolderItemsSelector): void {
    this.needRefreshPreview = true;
    this.store.dispatch(TileModalStore.tileModalActions.toggleGlobalFilterFolderSelect(event));
  }

  removeSelectedFolder(folder: ITargetFolderItem): void {
    this.needRefreshPreview = true;
    this.store.dispatch(TileModalStore.tileModalActions.removeGlobalFilterFolder({data: folder}));
  }

  toggleFolderItemSelect(folder: ITargetFolderItem): void {
    this.needRefreshPreview = true;
    this.store.dispatch(TileModalStore.tileModalActions.toggleGlobalFilterFolderItemSelect({data: folder}));
  }

  getGlobalFilters(): void {
    if (!this.showGlobalFiltersSection) {
      return;
    }
    // otherwise get global filters from settings and set them to the tile-modal store
    this.store.dispatch(TileModalStore.tileModalActions.getGlobalFiltersFromSettings({data: this.data.settings}));
  }

  saveTileData(): void {
    // pass data out of modal dialog to send it to the BE
    // if there is global filters then get them from the store and add to settings
    // object
    if (this.showGlobalFiltersSection) {
      this.store.pipe(select(TileModalStore.getSelectedGlobalFiltersAsParams)).pipe(
        take(1)
      ).subscribe((globalFilters: Record<string, string[] | string>) => {
        this.dialogRef.close({
          ...this.form.value,
          settings: {
            ...this.form.value.settings,
            ...globalFilters
          }
        });
        this.store.dispatch(TileModalStore.tileModalActions.resetPreviewData());
      });
    } else {
      // otherwise just close modal without global filters
      this.dialogRef.close(this.form.value);
      this.store.dispatch(TileModalStore.tileModalActions.resetPreviewData());
    }
  }

  private createForm(defaultTileInfo: DefaultTileInformation): FormGroup {
    const defaultName = this.translateService.instant(defaultTileInfo.name);
    const settings = this.getSettingsForForm(defaultTileInfo.settings);

    return this.fb.group({
      // NOTE: dashboard ID is required only for creating
      [TileFormFields.Dashboard]: this.data.id ? null : [this.data.dashboardId, Validators.required],
      [TileFormFields.Type]: [this.data.type || defaultTileInfo.type, Validators.required],
      [TileFormFields.Route]: [this.data.route || defaultTileInfo.route, Validators.required],
      [TileFormFields.Name]: [this.data.name || defaultName, [Validators.required, Validators.maxLength(64)]],
      [TileFormFields.Settings]: this.fb.group(settings, {
        validators: [gaugeRangeRequiredValidator(), gaugeRangeValidator()]
      })
    });
  }

  private getSettingsForForm(defaultSettings: ITileSettings): ITileSettings {
    const settingsFormData = {
      // NOTE: if there is edit mode then we have to override default settings with current settings
      // which is passed to modal popup (in case if there are missed some settings)
      // otherwise we have to use just default settings
      ...defaultSettings,
      ...this.data.settings,
    };

    return Object.keys(settingsFormData).reduce((acc: ITileSettings, key: string) => {
      // NOTE: delete all global filters from the object.
      // We will add them to settings object before saving the tile
      // this is necessary for removing global filters from the tile's settings
      if (key !== 'al' && key.indexOf('filter') !== 0) {
        // cover array value into additional array to avoid form group error
        // e.g. fb.group({items: [[1, 2, 3, 4]]})
        acc[key] = Array.isArray(settingsFormData[key]) ? [settingsFormData[key]] : settingsFormData[key];
      }

      // fld key is stored as snake case in dashboard
      // this conversion is needed for the map the form data and ui dropdown
      if (key === 'fld') {
        acc[key] = toCamelCase(settingsFormData[key] as string);
      }

      return acc;
    }, {});
  }

  private getSelectedCohort({settings}: Partial<ITileConfig>, defaultTileInfo: DefaultTileInformation): void {
    // on adding tile we have to use cohort from default settings,
    // on editing - cohort from data which is passed to modal
    if (settings?.cohort || defaultTileInfo.settings?.cohort) {
      const cohortParams = {...settings?.cohort ? settings : defaultTileInfo.settings};
      this.dateCohortService.getSelectedDateCohortFromParams$(cohortParams)
        .pipe(take(1))
        .subscribe((dateCohort: IDateCohort) => this.selectedCohort$.next(dateCohort));
    } else {
      // reset cohort on change tile type if previous type has cohort and current - not
      this.selectedCohort$.next(null);
    }
  }

  private applySettingsValidators(form: FormGroup, settingsValidators: TileSettingsValidators | null): void {
    if (!settingsValidators) {
      return;
    }

    Object.entries(settingsValidators)
      .forEach(([field, validators]: [string, ValidatorFn | ValidatorFn[]]) => {
        form.get([TileFormFields.Settings, field]).setValidators(validators);
      });
  }

  private patchFormByType(type: TileTypes): void {
    const defaultTileInfo = this.getDefaultInfo(type);
    const defaultName = this.translateService.instant(defaultTileInfo.name);

    this.form.patchValue({
      [TileFormFields.Route]: defaultTileInfo.route,
      [TileFormFields.Type]: type,
      [TileFormFields.Name]: defaultName,
    });
    // replace previous settings by new one. We need to re-create form group because there are different
    // properties in settings for all tiles
    this.form.setControl(TileFormFields.Settings, this.fb.group(defaultTileInfo.settings));
    // subscribe to changes again otherwise it will skip value changes
    this.listenToFormChanges();
  }

  private getDefaultInfo(type: TileTypes): DefaultTileInformation {
    const key = type ? type : Object.keys(this.allTiles)[0];
    return this.allTiles[key];
  }

  private listenToFormChanges(): void {
    this.form.get(TileFormFields.Settings).valueChanges
      .pipe(
        untilComponentDestroyed(this),
        // emit only if there is second step
        filter(() => this.data.step === TileConfigurationSteps.Settings),
      )
      .subscribe(() => {
        // reset errors on changing form
        this.isHighlightedErrors = false;
        this.needRefreshPreview = true;
      });
  }

  private shouldUpdateDateCohort(cohort: IDateCohort | undefined): boolean {
    // to avoid double emit
    return cohort
      ? (cohort.cohort !== this.selectedCohort$.getValue()?.cohort || cohort.cohort === DateCohortsGroups.Custom)
      : false;
  }
}
