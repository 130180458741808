import { IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';

export const DETAILS_COLUMNS: IReportColumn[] = [
  {
    name: 'crmAccountId',
    width: 200,
    displayName: 'measurementStudio.features.advertisingInsights.informationPanel.table.Id',
    startSorting: 'asc',
    dataType: DataTypeEnum.Text
  }, {
    name: 'accountName',
    width: 200,
    displayName: 'measurementStudio.features.advertisingInsights.informationPanel.table.accountName',
    startSorting: 'asc',
    dataType: DataTypeEnum.Text
  }
];
