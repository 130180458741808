<tsh-alert *ngIf="campaignSpecificData"
           (closeAlert)="emitRemoveCampaign()"
           [dismissible]="true">
  {{ campaignSpecificData.description | translate }} <b>{{ campaignSpecificData.name }}</b>
</tsh-alert>
<ng-container *ngIf="filters">
  <tsh-date-cohort
    *ngIf="selectedDateCohort"
    (apply)="applyDateCohort($event)"
    (dateRangeFilterChange)="changeDateCohort($event)"
    [selectedDateRange]="selectedDateCohort"
    [dateCohortOptions]="dateCohortOptions">
  </tsh-date-cohort>
  <tsh-report-setting-panel
    (apply)="emitApply()"
    reportName="measurementStudio.features.opportunities.reportName">
    <tsh-report-filter-selector
      title="measurementStudio.features.opportunities.filters.dateField"
      [field]="filterForm.get('dateType')"
      [options]="dateTypeOptions">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      title="measurementStudio.features.opportunities.filters.status"
      [field]="filterForm.get('closedType')"
      [options]="closedTypeOptions">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      *ngIf="filterForm.get('closedType').value !== closedTypes.OPEN"
      title="measurementStudio.features.opportunities.filters.closed"
      [field]="filterForm.get('wonType')"
      [options]="wonTypeOptions">
    </tsh-report-filter-selector>
    <tsh-report-filter-selector
      *ngIf="!campaignSpecificData || filters[influencesKeys.SourceCampaignGroup] || filters[influencesKeys.InfluencedCampaignGroup]"
      title="measurementStudio.features.opportunities.filters.marketingInfluence"
      [field]="filterForm.get('model')"
      [options]="modelsOptions">
    </tsh-report-filter-selector>
  </tsh-report-setting-panel>
</ng-container>
