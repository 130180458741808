import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TsMenuModule } from '@terminus-lib/ui-menu';
import { TranslateModule } from '@ngx-translate/core';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';
import { TsSortModule } from '@terminus-lib/ui-sort';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';

import { TrendingRoutingModule } from './trending-routing.module';
import { TrendingComponent } from './containers/trending/trending.component';
import { TrendingFiltersComponent } from './components/trending-filters/trending-filters.component';
import { TrendingGroupTableComponent } from './components/trending-group-table/trending-group-table.component';
import { TrendingChartComponent } from './components/trending-chart/trending-chart.component';
import { TrendingComparativeTableComponent } from './components/trending-comparative-table/trending-comparative-table.component';
import { TrendingEffects } from './state/trending.effects';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { amChartsFactory } from '@util/amcharts';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { TrendingDetailsComponent } from './containers/trending-details/trending-details.component';
import { ReportMetricModule } from '@ui/components/report-metric';
import { TablePanelModule } from '@ui/components/table-panel';
import { HeaderCellModule } from '@ui/components/header-cell';
import { TrendingCampaignTableComponent } from './components/trending-campaign-table/trending-campaign-table.component';
import { TrendingResponsesTableComponent } from './components/trending-responses-table/trending-responses-table.component';
import * as TrendingReducer from './state/trending.reducer';
import { TrendingButtonsComponent } from './components/trending-buttons/trending-buttons.component';
import { PaginationModule } from '@ui/components/pagination';
import { AddToDashboardButtonModule } from '@ui/components/add-to-dashboard-button';
import { BenchmarkControlModule } from '@ui/components/benchmark-control';

/**
 * This is a shared module for Campaign Trends and Web Activities Trending Reports
 * We are using 1 module because these reports are identical except api call.
 * That's why we have to pass routeId to utils method to identify which api call to use
 */

@NgModule({
  declarations: [
    TrendingComponent,
    TrendingFiltersComponent,
    TrendingGroupTableComponent,
    TrendingChartComponent,
    TrendingComparativeTableComponent,
    TrendingDetailsComponent,
    TrendingCampaignTableComponent,
    TrendingResponsesTableComponent,
    TrendingButtonsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TrendingRoutingModule,
    TypedDataModule,
    TsLoadingOverlayModule,
    DateCohortsModule,
    ReportSettingPanelModule,
    ReportFilterSelectorModule,
    BenchmarkControlModule,
    TsTableModule,
    TsMenuModule,
    TsButtonModule,
    TsInputModule,
    TsChartModule,
    TsTooltipModule,
    TsSortModule,
    NoReportResultModule,
    ReportMetricModule,
    TablePanelModule,
    HeaderCellModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(TrendingReducer.trendingFeatureKey, TrendingReducer.reducer),
    EffectsModule.forFeature([TrendingEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
    PaginationModule,
    TsRadioGroupModule,
    AddToDashboardButtonModule,
  ],
  providers: [
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsFactory,
    }
  ]
})
export class TrendingModule {
}
