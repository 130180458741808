<ts-accordion class="global-filter-list-container">
  <tsh-global-filters-expansion-panel *ngIf="accountListFolderItem" titleKey="accountList"
    [accountListFolder]="accountListFolder" [folderState]="accountListFolderItem"
    (selectFilterFolder)="selectFilterFolder.emit($event)"
    (selectFilterFolderItem)="selectFilterFolderItem.emit($event)"
    (toggleFilterFolderMenu)="toggleFilterFolderMenu.emit($event)">
  </tsh-global-filters-expansion-panel>

  <ng-container *ngFor="let title of filterKeys">
    <tsh-global-filters-expansion-panel *ngIf="globalFilters && filterMenuState && filterMenuState[title]"
      [titleKey]="title" [filterOptions]="globalFilters[title]" [folderState]="filterMenuState[title]" [segments]="segments"
      (selectFilterFolder)="selectFilterFolder.emit($event)"
      (selectFilterFolderItem)="selectFilterFolderItem.emit($event)"
      (toggleFilterFolderMenu)="toggleFilterFolderMenu.emit($event)">
    </tsh-global-filters-expansion-panel>
  </ng-container>
</ts-accordion>