import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { IMarketingInfluenceRequest, OpportunityType } from '../../interfaces';
import { OPPTY_TYPE_OPTIONS } from '../../marketing-influence.constants';
import { IDateCohort, IDateCohortGroup } from '@shared/interfaces';
import { INFLUENCE_TYPE_OPTIONS, PERIOD_TYPE_OPTIONS } from '@measurement-studio/constants';
import { InfluenceType } from '@shared/enums';
import { Period } from '@measurement-studio/enums';

@Component({
    selector: 'ms-marketing-filters',
    templateUrl: './marketing-filters.component.html',
    styleUrls: ['./marketing-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingFiltersComponent implements OnChanges {
    @Input() isLoading = false;
    @Input() reportFilters: IMarketingInfluenceRequest;
    @Input() dateCohortOptions: IDateCohortGroup[] = [];
    @Input() selectedDateCohort: IDateCohort;
    @Output() applyFilters = new EventEmitter<IMarketingInfluenceRequest>();
    @Output() changeSelectedDateCohort = new EventEmitter<IDateCohort>();

    opptyTypeOption = OPPTY_TYPE_OPTIONS;
    periodTypeOption = PERIOD_TYPE_OPTIONS;
    influenceTypeOption = INFLUENCE_TYPE_OPTIONS;
    opptyTypeEnum = OpportunityType;
    filterForm = this.formBuilder.group({
        opptyType: new FormControl<OpportunityType>(null, [Validators.required]),
        period: new FormControl<Period>(null, [Validators.required]),
        influenceType: new FormControl<InfluenceType>(null, [Validators.required])
    });

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reportFilters && this.reportFilters) {
          this.filterForm.get('opptyType').setValue(this.reportFilters.opptyType);
          this.filterForm.get('period').setValue(this.reportFilters.period);
          this.filterForm.get('influenceType').setValue(this.reportFilters.influenceType);
        }

        if (changes.isLoading) {
            if (changes.isLoading.currentValue === true) {
                this.filterForm.disable();
            } else {
                this.filterForm.enable();
            }
        }
    }

    /**
     * Apply change for report setting only
     */
    apply(): void {
      this.applyFilters.emit({
            ...this.reportFilters,
            ...this.filterForm.value
        });
    }

    /**
     * Apply change for date cohort only
     */
    applyDateCohort(dateCohort?: IDateCohort): void {
        this.applyFilters.emit({
            ...this.reportFilters,
            cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
            endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
            startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
        });
    }

    changeDateCohort(dateCohort: IDateCohort): void {
        this.changeSelectedDateCohort.emit(dateCohort);
    }
}
