import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import * as HubRouterReducer from './hub-router.reducer';

export class HubRouterSerializer implements RouterStateSerializer<HubRouterReducer.HubRouterState> {

  serialize(routerState: RouterStateSnapshot): HubRouterReducer.HubRouterState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams } } = routerState;
    const { params } = route;
    const title = route.data['title'];
    const subTitle = route.data['subTitle']; // for new layout
    const routeId = route.data['routeId'];

    return { title, subTitle, routeId, url, params, queryParams };
  }
}
