import { createAction, props } from '@ngrx/store';
import { IOrgConfig, ISigstrResponse, IUpdateConfig } from '@shared/interfaces';

export enum OrgConfigActionTypes {
  LoadOrgConfig = '[OrgConfig API] Load Org Config',
  LoadOrgConfigSuccess = '[OrgConfig API] Load Org Config Success',
  LoadOrgConfigFailure = '[OrgConfig API] Load Org Config Failure',

  UpdateConfig = '[OrgConfig API] Update Config',
  UpdateConfigSuccess = '[OrgConfig API] Update Config Success',
  UpdateConfigFailure = '[OrgConfig API] Update Config Failure',

  LoadSigstrsProductType = '[OrgConfig API] Load Sigstrs Product Type',
  LoadSigstrsProductTypeSuccess = '[OrgConfig API] Load Sigstrs Product Type Success',
  LoadSigstrsProductTypeFailure = '[OrgConfig API] Load Sigstrs Product Type Failure',

  LoadCrmUrl = '[OrgConfig API] Load CRM url',
  LoadCrmUrlSuccess = '[OrgConfig API] Load CRM url Success',
  LoadCrmUrlFailure = '[OrgConfig API] Load CRM url Failure',

  LoadOpportunityTypes = '[OrgConfig API] Load Opportunity Types',
  LoadOpportunityTypesSuccess = '[OrgConfig API] Load Opportunity Types Success',
  LoadOpportunityTypesFailure = '[OrgConfig API] Load Opportunity Types Failure',

  LoadOpportunityProductLines = '[OrgConfig API] Load Opportunity Product Lines',
  LoadOpportunityProductLinesSuccess = '[OrgConfig API] Load Opportunity Product Lines Success',
  LoadOpportunityProductLinesFailure = '[OrgConfig API] Load Opportunity Product Lines Failure',

  LoadSpikeModels = '[OrgConfig API] Load Spike Models',
  LoadSpikeModelsSuccess = '[OrgConfig API] Load Spike Models Success',
  LoadSpikeModelsFailure = '[OrgConfig API] Load Spike Models Failure',

  LoadIntentTopics = '[OrgConfig API] Load IntentTopics',
  LoadIntentTopicsSuccess = '[OrgConfig API] Load Intent Topics Success',
  LoadIntentTopicsFailure = '[OrgConfig API] Load Intent Topics Failure',
}

const LoadOrgConfig = createAction(
  OrgConfigActionTypes.LoadOrgConfig
)

const LoadOrgConfigSuccess = createAction(
  OrgConfigActionTypes.LoadOrgConfigSuccess,
  props<{ payload: IOrgConfig }>()
)

const LoadOrgConfigFailure = createAction(
  OrgConfigActionTypes.LoadOrgConfigFailure,
  props<{ payload: string }>()
)

const UpdateConfig = createAction(
  OrgConfigActionTypes.UpdateConfig,
  props<{ payload: IUpdateConfig }>()
)

const UpdateConfigSuccess = createAction(
  OrgConfigActionTypes.UpdateConfigSuccess,
  props<{ payload: IUpdateConfig }>()
)

const UpdateConfigFailure = createAction(
  OrgConfigActionTypes.UpdateConfigFailure,
  props<{ payload: string }>()
)

const LoadSigstrsProductType = createAction(
  OrgConfigActionTypes.LoadSigstrsProductType
)

const LoadSigstrsProductTypeSuccess = createAction(
  OrgConfigActionTypes.LoadSigstrsProductTypeSuccess,
  props<{ payload: ISigstrResponse }>()
)

const LoadSigstrsProductTypeFailure = createAction(
  OrgConfigActionTypes.LoadSigstrsProductTypeFailure,
  props<{ payload: string }>()
)

const LoadCrmUrl = createAction(
  OrgConfigActionTypes.LoadCrmUrl
)

const LoadCrmUrlSuccess = createAction(
  OrgConfigActionTypes.LoadCrmUrlSuccess,
  props<{ url: string }>()
)

const LoadCrmUrlFailure = createAction(
  OrgConfigActionTypes.LoadCrmUrlFailure,
  props<{ payload: string }>()
)

const LoadOpportunityTypes = createAction(
  OrgConfigActionTypes.LoadOpportunityTypes
)

const LoadOpportunityTypesSuccess = createAction(
  OrgConfigActionTypes.LoadOpportunityTypesSuccess,
  props<{ payload: string[] }>()
)

const LoadOpportunityTypesFailure = createAction(
  OrgConfigActionTypes.LoadOpportunityTypesFailure,
  props<{ payload: string }>()
)

const LoadOpportunityProductLines = createAction(
  OrgConfigActionTypes.LoadOpportunityProductLines
)

const LoadOpportunityProductLinesSuccess = createAction(
  OrgConfigActionTypes.LoadOpportunityProductLinesSuccess,
  props<{ payload: string[] }>()
)

const LoadOpportunityProductLinesFailure = createAction(
  OrgConfigActionTypes.LoadOpportunityProductLinesFailure,
  props<{ payload: string }>()
)

const LoadSpikeModels = createAction(
  OrgConfigActionTypes.LoadSpikeModels
)

const LoadSpikeModelsSuccess = createAction(
  OrgConfigActionTypes.LoadSpikeModelsSuccess,
  props<{ payload: string[] }>()
)

const LoadSpikeModelsFailure = createAction(
  OrgConfigActionTypes.LoadSpikeModelsFailure,
  props<{ payload: string }>()
)

const LoadIntentTopics = createAction(
  OrgConfigActionTypes.LoadIntentTopics
)

const LoadIntentTopicsSuccess = createAction(
  OrgConfigActionTypes.LoadIntentTopicsSuccess,
  props<{ payload: string[] }>()
)

const LoadIntentTopicsFailure = createAction(
  OrgConfigActionTypes.LoadIntentTopicsFailure,
  props<{ payload: string }>()
)

export const orgConfigActions = {
  LoadOrgConfig,
  LoadOrgConfigSuccess,
  LoadOrgConfigFailure,
  UpdateConfig,
  UpdateConfigSuccess,
  UpdateConfigFailure,
  LoadSigstrsProductType,
  LoadSigstrsProductTypeSuccess,
  LoadSigstrsProductTypeFailure,
  LoadCrmUrl,
  LoadCrmUrlSuccess,
  LoadCrmUrlFailure,
  LoadOpportunityTypes,
  LoadOpportunityTypesSuccess,
  LoadOpportunityTypesFailure,
  LoadOpportunityProductLines,
  LoadOpportunityProductLinesSuccess,
  LoadOpportunityProductLinesFailure,
  LoadSpikeModels,
  LoadSpikeModelsSuccess,
  LoadSpikeModelsFailure,
  LoadIntentTopics,
  LoadIntentTopicsSuccess,
  LoadIntentTopicsFailure
}

