import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { hubTokenName } from '@shared/constants';

import { IAdsInfluencedAddToListRequest, IAdsInfluencedRequest } from '../../interfaces/ads-influenced-request.interface';
import { IAdsInfluencedInsights, IAdsInfluencedInsightsDetailsResponse } from '@measurement-studio/interfaces';
import { EnvService } from '@shared/environment';
import { IAdsInfluencedInsightsService } from './influenced-insights.service.interface';
import { InfluencedInsightsSource } from '../../sources/influenced-insights/influenced-insights.source';

@Injectable({
  providedIn: 'root'
})
export class InfluencedInsightsService implements IAdsInfluencedInsightsService {

  constructor(
    private source: InfluencedInsightsSource,
    private retry: RetryWithEscalation,
    private envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getInfluencedInsights$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedInsights> {
    return regenerateOnRetry(() => this.source.getInfluencedInsights$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getInfluencedInsightsDetails$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedInsightsDetailsResponse> {
    return regenerateOnRetry(() => this.source.getInfluencedInsightsDetails$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getDetailsCSV$(request: IAdsInfluencedRequest): Observable<string> {
    return regenerateOnRetry(() => this.source.getDetailsCSV$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  addAccountsToAccountList$(request: IAdsInfluencedAddToListRequest): Observable<string[]> {
    return regenerateOnRetry(() => this.source.addAccountsToAccountList$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
