import { PER_PAGE } from '@shared/constants';
import { SortDirections } from '@shared/enums';
import { compare, searchByAnyField } from '@util/helpers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { STAGES_SNAPSHOT_KEY, StagesSnapshotState } from './stages-snapshot.reducer';
import { getWfStages, WfStageTypes } from '@shared/data-access/catalogs';

export const selectStagesSnapshotState = createFeatureSelector<StagesSnapshotState>(STAGES_SNAPSHOT_KEY);

export const getStagesSnapshotFilters = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.filters
);

export const getStagesSnapshotActiveStage = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.activeStage
);

export const getStagesSnapshotStageProgressionData = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.stageProgressionData
);

export const getStagesSnapshotStageProgressionDetailData = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.stageProgressionDetailData
);

export const getStagesSnapshotLoading = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.isLoading
);

export const getStagesSnapshotDateCohort = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.selectedDateCohort
);

export const getStagesSnapshotColumns = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.columns
);

export const getStagesSnapshotTableOptions = createSelector(
  selectStagesSnapshotState,
  (state: StagesSnapshotState) => state.tableOptions
);

export const getChartData = createSelector(
  getStagesSnapshotStageProgressionData,
  getWfStages,
  (data, wfStages) => {
    if (!data?.length || !wfStages.length) {
      return [];
    }

    return [
      ...data.filter(item => wfStages.find(wfStage => wfStage.stage === item.name).appliesTo === WfStageTypes.Lead)
        .map(item => ({
          lead: item.count,
          oppty: null,
          name: item.name,
        })),
      ...data.filter(item => wfStages.find(wfStage => wfStage.stage === item.name).appliesTo === WfStageTypes.Opportunity)
        .map(item => ({
          lead: null,
          oppty: item.count,
          name: item.name,
        }))
    ];
  }
);

export const getActiveStageProgressionDetailData = createSelector(
  getStagesSnapshotStageProgressionDetailData,
  getStagesSnapshotActiveStage,
  (data, activePage) => {
    return data && data[activePage];
  }
);

export const getSearchedTableData = createSelector(
  getActiveStageProgressionDetailData,
  getStagesSnapshotTableOptions,
  (data, options) => {
    return options.searchQuery && data ? searchByAnyField(data, options.searchQuery) : data;
  });

export const getStagesSnapshotTableData = createSelector(
  getSearchedTableData,
  getStagesSnapshotTableOptions,
  (report, options) => {
    const start = (options.page - 1) * PER_PAGE;
    const end = options.page * PER_PAGE;
    const sortedData = (
      (!options.sortState || !options.sortState.direction || !options.sortState.active)
        ? report
        : report?.slice()
          .sort((a, b) => compare(
            a[options.sortState.active],
            b[options.sortState.active],
            options.sortState.direction === SortDirections.Asc))
    );
    return sortedData && sortedData.slice(start, end);
  }
);
