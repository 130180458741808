import { totalsActions } from './totals.actions';
import * as interfaces from '../../interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const totalsFeatureKey = 'totals';

type TotalsData = (interfaces.ICampaignCostsTotalsData |
  interfaces.ICampaignMetaDataTotalsData |
  interfaces.ICampaignReturnsTotalsData |
  interfaces.ICampaignResponsesTotalData |
  interfaces.ICampaignAttributionTotalsData |
  interfaces.ICampaignInfluenceTotalsData)[];

export interface ITotalsState {
  nestedTotals: Record<string, Partial<interfaces.IAnalyticsTableTotals>>;
  campaigns: Partial<interfaces.IAnalyticsTableTotals>;
  campaignGroups: Partial<interfaces.IAnalyticsTableTotals>;
  source: Partial<interfaces.IAnalyticsTableTotals>;
  campaign: Partial<interfaces.IAnalyticsTableTotals>;
  mediums: Partial<interfaces.IAnalyticsTableTotals>;
}

export const initialState: ITotalsState = {
  campaigns: {},
  campaignGroups: {},
  source: {},
  campaign: {},
  mediums: {},
  nestedTotals: {},
};

const totalsReducer = createReducer(
  initialState,
  on(
    totalsActions.loadMetaDataTotalSuccess,
    totalsActions.loadResponsesTotalSuccess,
    totalsActions.loadAttributionTotalSuccess,
    totalsActions.loadInfluenceTotalSuccess,
    totalsActions.loadReturnsTotalSuccess,
    totalsActions.loadCostsTotalSuccess,
    totalsActions.loadWebActivitiesResponsesTotalSuccess,
    totalsActions.loadWebActivitiesAttributionTotalSuccess,
    totalsActions.loadWebActivitiesInfluenceTotalSuccess,
    totalsActions.loadWebActivitiesReturnsTotalSuccess,
    totalsActions.loadWebActivitiesCostsTotalSuccess,
    totalsActions.loadSourceResponsesTotalSuccess,
    totalsActions.loadSourceAttributionTotalSuccess,
    totalsActions.loadSourceInfluenceTotalSuccess,
    totalsActions.loadSourceReturnsTotalSuccess,
    totalsActions.loadSourceCostsTotalSuccess, (state, action) => ({
      ...state,
      ...updateTotalsData(state, action.data, action.key),
    })),
  on(totalsActions.resetTotalData, (state) => ({
    ...state,
    campaigns: {},
    campaignGroups: {},
    source: {},
    campaign: {},
    mediums: {},
    nestedTotals: {},
  })),
);

export function reducer(state, action: Action): ITotalsState {
  return totalsReducer(state, action);
}

const updateTotalsData = (state: ITotalsState, [data]: TotalsData, key: interfaces.LoadTotalsPayload) => {
  switch (key) {
    case interfaces.TotalsSlice.Campaign:
    case interfaces.TotalsSlice.CampaignGroups:
    case interfaces.TotalsSlice.Campaigns:
    case interfaces.TotalsSlice.Source:
    case interfaces.TotalsSlice.Mediums:
      return {
        ...state,
        [key]: {
          ...state[key],
          ...data,
        }
      };
    // default case is necessary for nested data (key == parentId)
    default:
      return {
        nestedTotals: {
          ...state.nestedTotals,
          [key]: {
            ...state.nestedTotals[key],
            ...data,
          }
        }
      };
  }
};
