import { Inject, Injectable } from '@angular/core';
import { TsDocumentService, TsWindowService } from '@terminus-lib/fe-utilities';

import { IUserProfile } from '@shared/interfaces';

interface GainsightUserField {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  signUpDate?: number;
  plan?: string;
  price?: number;
  userHash?: string;
}

interface GainsightAccountField {
  id: string;
  name?: string;
  program?: string;
}

interface GainsightEventProperty {
  page?: string;
  location?: string;
  tab?: string;
}

interface WindowWithGainsight extends Window {
  aptrinsic(
    id: string,
    trackField: GainsightUserField | string,
    propertyField: GainsightAccountField | GainsightEventProperty
  ): void;

  '_fs_ready': () => void;
}

interface TSWindowServiceWithGainsight extends TsWindowService {
  nativeWindow: WindowWithGainsight;
}

const IDENTIFY = 'identify';
const TRACK = 'track';

@Injectable({
  providedIn: 'root'
})
export class GainsightService {
  public document: Document;
  public window: WindowWithGainsight;

  get gainsight() {
    return this.window.aptrinsic;
  }

  public get apiIsUnavailable(): boolean {
    return !this.gainsight;
  }

  constructor(
    @Inject(TsWindowService)
    private tsWindowService: TSWindowServiceWithGainsight,
    private tsDocumentService: TsDocumentService,
  ) {
    this.window = this.tsWindowService.nativeWindow;
    this.document = this.tsDocumentService.document;
  }

  public injectSnippet(gainsightKey: string): void {
    const scriptElement = this.document.createElement('script');
    const inlineScript = this.document.createTextNode(`
    (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
        (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
        var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
        var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
    })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${gainsightKey}");`);

    scriptElement.appendChild(inlineScript);
    this.document.body.appendChild(scriptElement);
  }

  public initialize(userProfile: IUserProfile, unifiedCustomerId?: string): void {
    const userField: GainsightUserField = {
      id: userProfile.email,
      email: userProfile.email,
    };
    const accountField: GainsightAccountField = {
      id: unifiedCustomerId,
    };

    if (!this.apiIsUnavailable) {
      this.gainsight(IDENTIFY, userField, accountField);
    }
  }

  public emitGainsightTrackEvent(eventField: string, propertyField: GainsightEventProperty) {
    if (!this.apiIsUnavailable) {
      this.gainsight(TRACK, eventField, propertyField);
    }
  }
}
