<div [tsLoadingOverlay]="(isLoading$ | async) || (isDownloadInProcess$ | async)">
  <ms-analytics-filters reportName="measurementStudio.features.analytics.webActivities.filters.reportName"
                         datasetTitle="measurementStudio.features.analytics.webActivities.filters.campaignsThat"
                         influenceTitle="measurementStudio.features.analytics.webActivities.filters.influenceType"
                         modelTitle="measurementStudio.features.analytics.webActivities.filters.model"
                         (applyFilters)="applyFilters($event)"
                         (changeSelectedDateCohort)="changeSelectedDateCohort($event)"
                         [selectedDateCohort]="selectedDateCohort$ | async"
                         [modelTypeOptions]="modelTypeOptions$ | async"
                         [dateCohortOptions]="dateCohortOptions$ | async"
                         [isWebActivities]="true"
                         [filters]="filters$ | async">
  </ms-analytics-filters>
  <hr class="report-divider">
  <tsh-report-tabs [tabs]="tabs$ | async"></tsh-report-tabs>

  <router-outlet></router-outlet>
</div>
