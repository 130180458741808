import { Action, createReducer, on } from '@ngrx/store';
import { campaignsActions } from './campaigns.actions';

export const campaignsFeatureKey = 'campaigns';

export interface State {
  types: string[] | null;
  isLoading: boolean;
  colorMap: Record<string, string> | null;
}

export const initialState: State = {
  types: null,
  isLoading: false,
  colorMap: null,
};

const campaignsReducer = createReducer(
  initialState,
  on(campaignsActions.loadCampaignTypes, state => ({
    ...state,
    isLoading: true
  })),
  on(campaignsActions.loadCampaignTypesSuccess, (state, action) => ({
    ...state,
    types: action.types,
    isLoading: false
  })),
  on(campaignsActions.loadCampaignTypesFailure, state => ({
    ...state,
    isLoading: false
  })),
  on(campaignsActions.setCampaignTypesColor, (state, action) => ({
    ...state,
    colorMap: action.colorMap
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return campaignsReducer(state, action);
}
