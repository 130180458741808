import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  IAccountFolder,
  IAccountList,
  IAnalyticsGlobalFilters,
  IAppliedGlobalFiltersAsParams,
  IDataFilter,
  IFilter,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
  ITargetFolderItem,
} from '@shared/interfaces';
import { GlobalFiltersKeys } from '@shared/enums';
import * as AccountListStore from '@shared/data-access/account-hub';
import * as helpers from '@util/helpers';
import { mapDataFilterAsParams } from '../utils/utils';
import { globalFiltersFeatureKey, GlobalFiltersState } from './global-filters.reducer';

export const selectGlobalFilterState = createFeatureSelector<GlobalFiltersState>(globalFiltersFeatureKey);
export const getAppliedDataFilter = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.appliedDataFilter,
);
export const getInitialDataLoaded = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.initialDataLoaded,
);
export const getFilterChanged = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.filterChanged,
);
export const getGlobalFiltersData = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.globalFilters,
);
export const getIsGlobalFilterLoading = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.isLoading || state.filterMenuLoading,
);
export const getSavedDataFilters = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.savedDataFilters,
);
export const getSelectedDataFilter = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.selectedDataFilter,
);
export const getSearchQuery = createSelector(selectGlobalFilterState, (state: GlobalFiltersState) => state.searchQuery);
export const getExpandedFolder = createSelector(
  selectGlobalFilterState,
  (state: GlobalFiltersState) => state.targetFolder,
);
export const getIsApplying = createSelector(selectGlobalFilterState, (state: GlobalFiltersState) => state.isApplying);
export const getIsSaving = createSelector(selectGlobalFilterState, (state: GlobalFiltersState) => state.isSaving);
export const getIsDeleting = createSelector(selectGlobalFilterState, (state: GlobalFiltersState) => state.isDeleting);
export const getDataFilterFilters = createSelector(
  getSelectedDataFilter,
  (dataFilter: IDataFilter) => dataFilter.filters,
);

export const getDataFilterListIds = createSelector(
  getSelectedDataFilter,
  (dataFilter: IDataFilter) => dataFilter.listIds,
);

export const getAppliedListIds = createSelector(getAppliedDataFilter, (dataFilter: IDataFilter) => dataFilter?.listIds);

export const getAppliedFilters = createSelector(getAppliedDataFilter, (dataFilter: IDataFilter) => dataFilter?.filters);

export const getSegments = createSelector(selectGlobalFilterState, state => state.segments);

// get query params for the report page according to applied filters and list ids
export const getAppliedGlobalFiltersAsParams = createSelector(
  getAppliedListIds,
  getAppliedFilters,
  (listIds: string[], filters: IFilter): IAppliedGlobalFiltersAsParams => {
    const mappedFilters = filters ? helpers.getMappedAppliedFilters(filters) : {};
    const al = listIds?.length ? listIds.join(',') : null;
    const sg = mappedFilters['segments']
      ? mappedFilters['segments'].map(segmentID => segmentID.replace(':sg', '')).join(',')
      : null;
    delete mappedFilters['segments'];

    const filtersAsParams: IAppliedGlobalFiltersAsParams = {...mappedFilters};
    if (al) filtersAsParams['al'] = al;
    if (sg) filtersAsParams['sg'] = sg;
    return filtersAsParams;
  },
);

// Selector to map current state of global filters to the structure of new api
export const getAnalyticsGlobalFilters = createSelector(
  getAppliedListIds,
  getAppliedFilters,
  (listIds: string[], filters: IFilter): IAnalyticsGlobalFilters => {
    return helpers.getAllAnalyticsGlobalFilters(listIds, filters);
  },
);

export const getAppliedFiltersNumber = createSelector(
  getAppliedFilters,
  getAppliedListIds,
  helpers.calculateSelectedFiltersNumber,
);

export const getAccountListMenuBase = createSelector(
  AccountListStore.getFolders,
  getDataFilterListIds,
  helpers.createAccountListMenuState,
);
// Info for utilities sidebar for info block about applied filters
export const getAppliedAccountListsForInfoPanel = createSelector(
  getAccountListMenuBase,
  AccountListStore.getAllAccountListsFromFolder,
  (data: IGlobalFilterFolderItem, lists: IAccountList[]): { folderName: string; lists: string[] }[] => {
    if (data.selectedItems === 0) {
      return [];
    }
    const keys = Object.keys(data.itemSelect);
    return keys.reduce((acc, folderName: string) => {
      // if there are selected account lists in the folder
      if (data.itemSelect[folderName].selectedItems > 0) {
        // get data about them
        const allListsInFolder = data.itemSelect[folderName].itemSelect;
        const selectedLists = Object.keys(allListsInFolder).reduce((acc: string[], id: string) => {
          // is list selected
          if (allListsInFolder[id]) {
            const listName = lists.find(list => list.id.toString() === id)?.name;
            acc.push(listName);
          }
          return acc;
        }, []);
        acc.push({
          folderName: folderName,
          lists: selectedLists,
        });
      }
      return acc;
    }, []);
  },
);

export const getAccountListMenuState = createSelector(
  getAccountListMenuBase,
  AccountListStore.getFolders,
  getSearchQuery,
  getExpandedFolder,
  (
    accountListMenu: IGlobalFilterFolderItem,
    accountListFolder: IAccountFolder[],
    searchQuery: string,
    targetFolder: {
      folderItem: ITargetFolderItem;
      isOpen: boolean;
    },
  ) => {
    let mappedAccountListMenuState = { ...accountListMenu };

    if (mappedAccountListMenuState && searchQuery) {
      mappedAccountListMenuState = helpers.filterAccountListMenuState(
        mappedAccountListMenuState,
        accountListFolder,
        searchQuery,
      );
    }

    if (
      targetFolder.folderItem.folderName &&
      targetFolder.folderItem.folderName === GlobalFiltersKeys.AccountList &&
      targetFolder.folderItem.folderItem
    ) {
      mappedAccountListMenuState.itemSelect[targetFolder.folderItem.folderItem].isOpen = targetFolder.isOpen;
    }

    return Object.keys(mappedAccountListMenuState).length ? mappedAccountListMenuState : null;
  },
);

export const getGlobalFilterMenuBase = createSelector(
  getGlobalFiltersData,
  getDataFilterFilters,
  helpers.createMappedFilterMenuState,
);

export const getGlobalFiltersMenuState = createSelector(
  getGlobalFilterMenuBase,
  getSearchQuery,
  getExpandedFolder,
  getSegments,
  (
    globalFilterFolder: IGlobalFilterFolder,
    searchQuery: string,
    targetFolder: {
      folderItem: ITargetFolderItem;
      isOpen: boolean;
    },
    segments = [],
  ) => {
    let mappedMenuState = { ...globalFilterFolder };

    if (searchQuery) {
      mappedMenuState = helpers.filterFilterMenuState(mappedMenuState, searchQuery, segments);
    }

    if (
      targetFolder.folderItem.folderName &&
      targetFolder.folderItem.folderName !== GlobalFiltersKeys.AccountList &&
      mappedMenuState[targetFolder.folderItem.folderName] &&
      mappedMenuState[targetFolder.folderItem.folderName].itemSelect &&
      targetFolder.folderItem.folderItem &&
      mappedMenuState[targetFolder.folderItem.folderName].itemSelect[targetFolder.folderItem.folderItem]
    ) {
      mappedMenuState[targetFolder.folderItem.folderName].itemSelect[targetFolder.folderItem.folderItem].isOpen =
        targetFolder.isOpen;
    }

    return mappedMenuState;
  },
);

export const getUnappliedFilterNumber = createSelector(
  getAppliedDataFilter,
  getSelectedDataFilter,
  (appliedDataFilter: IDataFilter, selectedDataFilter: IDataFilter) => {
    const filtersKeys = Object.keys(selectedDataFilter.filters);
    const filtersNumber = filtersKeys.reduce((accu: number, filter: GlobalFiltersKeys) => {
      Object.keys(selectedDataFilter.filters[filter]).forEach(currentMenuItem => {
        const selectedItems = selectedDataFilter.filters[filter][currentMenuItem]; // always array
        const appliedItems = appliedDataFilter.filters[filter] && appliedDataFilter.filters[filter][currentMenuItem]; // could be undefined

        if (!appliedItems) {
          // tslint:disable-next-line:no-parameter-reassignment
          return (accu = accu + selectedItems.length);
        }

        if (!appliedItems.length && !selectedItems.length) {
          return accu;
        }
        // NOTE: get all items and check how much unique items in both array - appliedItems and selectedItems
        // this number will be unapplied filters
        const allItemsLength = selectedItems.length + appliedItems.length;
        const allItems = [...selectedItems, ...appliedItems];
        const allNoUniqueItems = allItems.reduce((acc: string[], item: string) => {
          const howManyItemInAllItems = allItems.filter(itm => itm === item).length;
          if (howManyItemInAllItems > 1) {
            acc.push(item);
          }
          return acc;
        }, []);

        // tslint:disable-next-line:no-parameter-reassignment
        accu = accu + allItemsLength - allNoUniqueItems.length;
      });

      return accu;
    }, 0);
    const accountIdsNumber = Math.abs(appliedDataFilter.listIds.length - selectedDataFilter.listIds.length);
    return filtersNumber + accountIdsNumber;
  },
);

export const getMappedSelectedFiltersMenu = createSelector(
  getGlobalFiltersMenuState,
  helpers.mapFilterMenuToDataFilter,
);

export const getFiltersAsParams = createSelector(getGlobalFiltersMenuState, mapDataFilterAsParams);

export const getAreFiltersApplied = createSelector(
  getAppliedListIds,
  getFiltersAsParams,
  getUnappliedFilterNumber,
  (listIds: string[], filterParams: Record<string, string[]>, unappliedFilters: number) =>
    !!((listIds.length || Object.keys(filterParams).length) && !unappliedFilters),
);

export const getIsSavedDataFilterSelected = createSelector(
  getSavedDataFilters,
  getSelectedDataFilter,
  (savedDataFilters: IDataFilter[], selectedDataFilter: IDataFilter) =>
    savedDataFilters.map((dataFilter: IDataFilter) => dataFilter.name).indexOf(selectedDataFilter.name) > -1,
);

export const getHasSavedDataFilterChanged = createSelector(
  getSavedDataFilters,
  getAppliedDataFilter,
  (savedDataFilters: IDataFilter[], appliedDataFilter: IDataFilter) => {
    const targetFilter = savedDataFilters.find((dataFilter: IDataFilter) => dataFilter.name === appliedDataFilter.name);
    if (!targetFilter) {
      return false;
    }

    return JSON.stringify(targetFilter.filters) !== JSON.stringify(appliedDataFilter.filters);
  },
);

export const getUpdatedFilterMenuState = createSelector(
  getGlobalFiltersData,
  getGlobalFiltersMenuState,
  getDataFilterFilters,
  (globalFilters: IGlobalFilters, globalFiltersMenu: IGlobalFilterFolder, dataFilterFilters: IFilter) => {
    const filterKeys = Object.keys(dataFilterFilters);
    let mappedMenuState = helpers.createFilterMenuState(globalFilters);
    let targetFolder: IGlobalFilterFolderItem;

    filterKeys.forEach((folderName: GlobalFiltersKeys) => {
      Object.keys(dataFilterFilters[folderName]).forEach((folder: string) => {
        const folderOpenState = globalFiltersMenu[folderName].itemSelect[folder].isOpen;
        if (dataFilterFilters[folderName][folder] && dataFilterFilters[folderName][folder].length) {
          dataFilterFilters[folderName][folder].forEach((folderItem: string) => {
            targetFolder = { ...mappedMenuState[folderName] };
            mappedMenuState = {
              ...mappedMenuState,
              [folderName]: helpers.selectTargetItemInFolder(targetFolder, folder, folderItem),
            };
          });
        }
        mappedMenuState[folderName].itemSelect[folder].isOpen = folderOpenState;
      });
    });

    return mappedMenuState;
  },
);
