import { Injectable } from '@angular/core';

import { LocalStorageService } from '@shared/data-access/local-storage';
import { LocalStorageColumnsKey as Key } from './local-storage-columns-key.enum';
import { IReportColumn } from '@shared/interfaces';

@Injectable()
export class ColumnsSaverService {
  private readonly separator = ', ';

  constructor(private localStorageService: LocalStorageService) {
  }

  saveColumns<T extends IReportColumn = IReportColumn>(localStorageKey: Key,
                                                       columns: T[],
                                                       excludeColumns: string[] = []): void {
    const value = this.mapColumnsToKeys(columns, excludeColumns).join(this.separator);
    this.localStorageService.setItem(localStorageKey, value);
  }

  getColumns<T extends IReportColumn = IReportColumn>(localStorageKey: Key,
                                                      defaultColumns: T[],
                                                      alwaysVisible: string[] = [],
                                                      defaultSort = true): T[] {
    const storageValue = this.localStorageService.getItem(localStorageKey);
    return storageValue
      ? this.mapKeysToColumns(defaultColumns, storageValue.split(this.separator), alwaysVisible, defaultSort)
      : defaultColumns;
  }

  private mapKeysToColumns<T extends IReportColumn = IReportColumn>(columns: T[],
                                                                    keys: string[],
                                                                    alwaysVisible: string[] = [],
                                                                    defaultSort = true): T[] {
    keys.push(...alwaysVisible);
    const savedColumnOrder = [...keys].reverse();
    const visibleColumns =  columns.map(column => ({
      ...column,
      visible: keys.includes(column.name),
    }));
    if (defaultSort) {
      return visibleColumns.sort((a, b) => savedColumnOrder.indexOf(b.name) - savedColumnOrder.indexOf(a.name));
    }
    return visibleColumns;
  }

  private mapColumnsToKeys<T extends IReportColumn = IReportColumn>(columns: T[],
                                                                    excludeColumns: string[] = []): string[] {
    return columns.map(column => column.name).filter(name => !excludeColumns.includes(name));
  }
}

