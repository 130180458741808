<div class="report-filter-input-container">
  <span class="filter-label">
      {{ label | translate }}
  </span>
  <ts-menu [menuItemsTemplate]="menuTemplate"
           class="ts-menu-as-selector">
        <span class="filter-label-result">
            {{ field.value }}
        </span>
  </ts-menu>
</div>

<ng-template #menuTemplate>
  <div class="items-container">
    <ts-input [isSmall]="true"
              [type]="isNumeric ? 'number' : 'text'"
              (click)="$event.stopPropagation()"
              [formControl]="inputValue">
    </ts-input>
    <hr class="report-divider">
    <div class="confirm-button-container">
      <ts-button theme="secondary"
                 [isSmall]="true"
                 tsMenuItem="transparent"
                 (clicked)="cancelDialog()">
        {{ 'shared.common.cancel' | translate }}
      </ts-button>
      <ts-button theme="default"
                 tsMenuItem="transparent"
                 [isSmall]="true"
                 (click)="$event.stopPropagation()"
                 (clicked)="confirmDialog()">
        {{ 'shared.common.confirm' | translate }}
      </ts-button>
    </div>
  </div>
</ng-template>
