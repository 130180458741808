import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ITagsState, tagsFeatureKey } from './tags.reducer';
import { ITagsResponse } from '@measurement-studio/interfaces';

export const selectTagsState = createFeatureSelector<ITagsState>(tagsFeatureKey);

export const getTags = createSelector(selectTagsState,
  (state: ITagsState): string[] => state.tags);

export const getTagsByCampaign = createSelector(selectTagsState,
  (state: ITagsState): ITagsResponse => state.tagsByCampaign);

export const getSelectedRowIds = createSelector(selectTagsState,
  (state: ITagsState): string[] => state.selectedCampaignIds);

export const getTagsByChannel = createSelector(selectTagsState,
  (state: ITagsState): ITagsResponse => state.tagsByChannel);

export const getSelectedChannelRowIds = createSelector(selectTagsState,
  (state: ITagsState): string[] => state.selectedChannelIds);
