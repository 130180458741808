<div [tsLoadingOverlay]="isLoading">
  <h3>
    <span class="color-block" [ngStyle]="{'background-color': color}"></span>
    {{ periodTitle | translate }},&nbsp;
    <span class="text-capitalize" data-cy="trending-group-table-title">{{ title }}</span>
  </h3>
  <tsh-table-panel
    (download)="emitDownload()"
    [totalTitle]="isCampaignTrendsReport
      ? 'feature.sharedTrending.groupDetails.totalTitle'
      : 'feature.sharedTrending.groupDetails.utmTotalTitle'"
    [noResult]="!data?.length"
    [searchEnabled]="false"
    [totalCount]="data?.length"
    [showColumns]="false">
  </tsh-table-panel>
  <div class="table-container" *ngIf="data?.length">
    <table ts-table
           #trendingTable="tsTable"
           tsSort
           class="table-hover group-details-table"
           [columns]="columns"
           [multiTemplateDataRows]="true"
           (tsSortChange)="emitChangeSort($event)"
           [tsSortActive]="sortState?.active"
           [tsSortDirection]="sortState?.direction"
           [dataSource]="dataSource">
      <ng-container tsColumnDef="icon" alignment="center">
        <th ts-header-cell *tsHeaderCellDef></th>
        <td ts-cell *tsCellDef="let item">
          <i class="fas"
             *ngIf="rowState"
             [ngClass]="{
                       'fa-angle-right': !rowState[item['group']],
                       'fa-angle-down': rowState[item['group']]
                       }">
          </i>
        </td>
        <td ts-footer-cell *tsFooterCellDef></td>
      </ng-container>

      <ng-container *ngFor="let column of columns.slice(1); let colIxd = index;">
        <ng-container [tsColumnDef]="column.name"
                      [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
          <th *tsHeaderCellDef ts-sort-header ts-header-cell [start]="column.startSorting">
            <tsh-header-cell [column]="column"></tsh-header-cell>
          </th>
          <td ts-cell *tsCellDef="let item">
                    <span class="text-capitalize">
                        {{ item[column.name] | typedData : column.dataType }}
                    </span>
          </td>
          <td ts-footer-cell *tsFooterCellDef>
            <ng-container *ngIf="colIxd === 0; else totalsTemplate">
              {{ 'common.tableTotals' |  translate }}
            </ng-container>
            <ng-template #totalsTemplate>
              {{ totalCount }}
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
      <!-- Custom column definition for the 'expanded' row -->
      <ng-container tsColumnDef="expanded">
        <td ts-cell
            *tsCellDef="let item"
            [ngClass]="{'open': rowState[item.group]}"
            [attr.colspan]="columns.length"
            class="expanded-cell">
          <ms-trending-campaign-table *ngIf="rowState[item.group] && campaigns[item.group]"
                                      (changeSort)="changeCampaignSortState($event, item.group)"
                                      (changePage)="changePage($event, item.group)"
                                      (expandRow)="emitExpandCampaignRow($event)"
                                      (download)="emitDownloadCampaign(item.group)"
                                      (downloadResponses)="emitDownloadResponses($event, item.group)"
                                      [sortState]="campaignSortState[item.group]"
                                      [rowState]="campaignRowState"
                                      [filters]="filters"
                                      [totalCount]="campaignTotalCount[item.group]"
                                      [page]="campaignPagination[item.group]"
                                      [isCampaignTrendsReport]="isCampaignTrendsReport"
                                      [data]="campaigns[item.group]"
                                      [responsesColumns]="responsesColumns"
                                      [columns]="campaignColumns">
          </ms-trending-campaign-table>
          <tsh-no-report-result
            *ngIf="rowState[item.group] && !campaigns[item.group]"
            description="feature.sharedTrending.noResultDescription"
            title="feature.sharedTrending.noResultTitle">
          </tsh-no-report-result>
        </td>
      </ng-container>

      <tr ts-header-row *tsHeaderRowDef="trendingTable.columnNames; sticky: true"></tr>
      <tr ts-row
          class="pointer"
          data-cy="trending-group-table-row"
          (click)="emitToggleExpand($event, item)"
          *tsRowDef="let item; columns: trendingTable.columnNames;">
      </tr>
      <tr ts-row *tsRowDef="let item; columns: ['expanded']" class="expanded-row"></tr>
      <tr ts-footer-row *tsFooterRowDef="trendingTable.columnNames;"></tr>
    </table>
  </div>
</div>
<tsh-no-report-result *ngIf="!data?.length && !isLoading"
                      description="feature.sharedTrending.noResultDescription"
                      title="feature.sharedTrending.noResultTitle">
</tsh-no-report-result>
