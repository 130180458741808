import { Inject, Injectable } from '@angular/core';
import { TsDocumentService, TsWindowService } from '@terminus-lib/fe-utilities';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

export type FullStoryLogLevelTypes = 'log' | 'info' | 'warn' | 'debug';

export interface FullStoryApi {
  event(eventName: string, eventProperties: Record<string, unknown>): void;

  identify(uid: string | boolean, userVars?: Record<string, unknown> | undefined): void;

  setUserVars(user: Record<string, unknown>): void;

  getCurrentSession(): string;

  getCurrentSessionURL(): string;

  log(logLevel: string, message: string): void;
}

export interface WindowWithFS extends Window {
  FS?: FullStoryApi;
  '_fs_ready': () => void;
}

interface TSWindowServiceWithFS extends TsWindowService {
  nativeWindow: WindowWithFS;
}

@Injectable({
  providedIn: 'root'
})
export class FullStoryService {
  public fullStoryLoadStatus = new BehaviorSubject(false);

  private _logBuffer = new Subject<{ level: FullStoryLogLevelTypes; message: string }>();
  private readonly _sub$: Subscription;

  /**
   * Store a reference to the global window
   */
  public window: WindowWithFS;
  /**
   * Store a reference to the global document
   */
  public document: Document;

  /**
   * Store a reference to the API
   */
  get fullStory(): FullStoryApi | undefined {
    return this.window.FS;
  }

  /**
   * Getter to determine if the FullStory API object is available and we have stored a reference.
   *
   * If we have not stored a reference to it, we check the window object for its existence and store
   * a reference if it is available.
   *
   * @return Boolean determining if the API is unavailable
   */
  public get apiIsUnavailable(): boolean {
    return !this.fullStory;
  }

  constructor(
    @Inject(TsWindowService)
    private tsWindowService: TSWindowServiceWithFS,
    private tsDocumentService: TsDocumentService,
  ) {
    this.window = this.tsWindowService.nativeWindow;
    this.document = this.tsDocumentService.document;
    this.window['_fs_ready'] = () => this.fullStoryLoadStatus.next(true);

    // Note: It is strange to unsubscribe in constructor
    /* istanbul ignore next */
    if (this._sub$) {
      this._sub$.unsubscribe();
    }

    this._sub$ = this._logBuffer.subscribe(({ level, message }) => {
      if (this.fullStory) {
        this.fullStory.log(level, message);
      }
    });
  }

  /**
   * Injects the FullStory snippet into the page
   */
  public injectSnippet(): void {
    const scriptElement = this.document.createElement('script');
    const inlineScript = this.document.createTextNode(`
      window['_fs_run_in_iframe'] = true;
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'NBGR';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log)
           { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};
          g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n, p){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
      })(window,document,window['_fs_namespace'],'script','user');
    `);

    scriptElement.appendChild(inlineScript);
    this.document.body.appendChild(scriptElement);
  }

  /**
   * Identify the active user for the current FullStory session
   *
   * @param uid - A unique user ID
   * @param userVars - Additional user information as key/value pairs
   */
  public identify(uid: string, userVars?: Record<string, unknown>): void {
    if (this.apiIsUnavailable || !this.fullStory) {
      return;
    }
    this.fullStory.identify(uid, userVars);
  }

  /**
   * Clears the identity of the active user for the current FullStory session
   */
  public clearIdentity(): void {
    if (this.apiIsUnavailable || !this.fullStory) {
      return;
    }

    this.fullStory.identify(false);
  }


  /**
   * Records custom user data for the current FullStory session
   *
   * @param userVars - Object with key/value pairs of data to be stored
   */
  public setUserVars(userVars: Record<string, unknown>): void {
    if (this.apiIsUnavailable || !this.fullStory) {
      return;
    }

    this.fullStory.setUserVars(userVars);
  }


  /**
   * Returns the ID for the current FullStory session, or null if one is
   * not available.
   *
   * @return ID of current FullStory session
   */
  public getCurrentSession(): string | undefined {
    if (this.apiIsUnavailable || !this.fullStory) {
      return;
    }

    return this.fullStory.getCurrentSession();
  }


  /**
   * Silenty sends messages to the FullStory console
   *
   * @param logLevel - Level of the message to log; see `FullStoryLogLevelTypes`
   * @param message - Message to log
   */
  public log(logLevel: FullStoryLogLevelTypes, message: string): void {
    this._logBuffer.next({ level: logLevel, message });
  }

  /**
   * Domain-specific events recorded by FullStory add additional intelligence
   * when you're searching across sessions and creating new user segments.
   *
   * @param eventName
   * @param eventProperties
   */
  sendEvent(eventName: string, eventProperties: Record<string, unknown> = {}): void {
    if (this.apiIsUnavailable || !this.fullStory) {
      return;
    }

    return this.fullStory.event(eventName, eventProperties);
  }
}

