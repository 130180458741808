import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { format } from 'date-fns';

import { DataTypeEnum, DiffLabel } from '@shared/enums';
import { IReportColumn } from '@shared/interfaces';
import { IComparativeTableData, ITrendingReport } from '../../interfaces';
import { PER_PAGE } from '@shared/constants';

@Component({
    selector: 'ms-trending-comparative-table',
    templateUrl: './trending-comparative-table.component.html',
    styleUrls: ['./trending-comparative-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendingComparativeTableComponent implements OnChanges {
    @Input() public report: ITrendingReport[];
    @Input() public data: IComparativeTableData[];
    @Input() public columns: IReportColumn[] = [];
    @Input() public totalTitle: string | null;
    @Input() public countData: number;
    @Input() public currentPage: number;
    @Output() public selected = new EventEmitter<ITrendingReport>();
    @Output() public changePage = new EventEmitter<number>();
    @Output() public download =  new EventEmitter<void>();

    public dataSource: TsTableDataSource<IComparativeTableData> = new TsTableDataSource();
    public dataTypes = DataTypeEnum;
    public diffLabel = DiffLabel;
    public perPage = PER_PAGE;

    constructor(
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue) {
            this.dataSource.data = this.data;
            this.changeDetector.detectChanges();
        }

        if (changes.totalTitle?.currentValue || changes.columns?.currentValue) {
            this.changeDetector.detectChanges();
        }
    }

    emitSelected(item: ITrendingReport, selectedId: number): void {
        if (item.id !== selectedId) {
            this.selected.emit(item);
        }
    }

    getTooltip(index: number): string {
        if (this.report?.length) {
            const dateNow = this.report[index].dateNow ? format(this.report[index].dateNow, 'M/d/yyyy') : '';
            const dateThen = this.report[index].dateThen ? format(this.report[index].dateThen, 'M/d/yyyy') : '';
            return `${dateNow}${dateThen && dateNow ? ' vs ' + dateThen : dateThen}`;
        }
        return '';
    }

    emitPageChange(event: number): void {
        this.changePage.emit(event);
    }

    emitDownload(): void {
        this.download.emit();
    }
}
