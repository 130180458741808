import { ITileData, ITileVisualizationConfig } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { TILE_DATA_FIELD_NAME } from '@shared/constants';

export const TABLE_TILE_DATA: ITileData = {
  items: [{
    name: 'EmailLabs',
    amount: 4028
  }, {
    name: 'Med Device Email',
    amount: 2001
  }, {
    name: 'Email Data Source',
    amount: 673
  }, {
    name: 'Email Services',
    amount: 439
  }, {
    name: 'Austria Email',
    amount: 230
  }],
  totalCount: 1020
};
export const TABLE_VISUALIZATION: ITileVisualizationConfig = {
  table: {
    columns: [{
      name: 'name',
      displayName: 'Name',
      dataType: DataTypeEnum.Text,
      width: 250,
      apiType: 'account_columns',
      visible: true
    }, {
      name: 'amount',
      displayName: 'Amount',
      dataType: DataTypeEnum.Number,
      width: 100,
      apiType: 'campaign_member',
      visible: true
    }],
    totalsLabel: 'Item',
    totalsPluralLabel: 'Items',
  },
  metricLabel: 'Amount',
};

export const LINE_TILE_DATA: ITileData = {
  items: [{
    descriptor: 'Jan', Amount: 40,
  }, {
    descriptor: 'Feb', Amount: 525,
  }, {
    descriptor: 'Mar', Amount: 635,
  }, {
    descriptor: 'Apr', Amount: 1084,
  }, {
    descriptor: 'May', Amount: 1569,
  }, {
    descriptor: 'Jun', Amount: 3180,
  }, {
    descriptor: 'Jul', Amount: 3450,
  }, {
    descriptor: 'Aug', Amount: 3618,
  }, {
    descriptor: 'Sep', Amount: 3905,
  }, {
    descriptor: 'Oct', Amount: 4188,
  }, {
    descriptor: 'Nov', Amount: 4779,
  }, {
    descriptor: 'Dec', Amount: 4802,
  }],
  metric: {
    current: {
      value: 4802,
      dataType: DataTypeEnum.Number
    },
  }
};
export const LINE_VISUALIZATION: ITileVisualizationConfig = {
  metricLabel: 'Amount',
  chart: {
    numberFormatter: {
      numberFormat: '#,###.'
    },
    yAxes: [{
      type: 'ValueAxis',
      min: 0,
      title: {
        text: 'Amount',
        fill: '#4F5A9F'
      },
      numberFormatter: {
        type: 'NumberFormatter',
        forceCreate: true,
        numberFormat: '#a'
      }
    }],
  }
};

export const COLUMN_TILE_DATA: ITileData = {
  items: [{
    Leads: 19970,
    Opportunities: null,
    descriptor: 'Inquiry'
  }, {
    Leads: 7763,
    Opportunities: null,
    descriptor: 'MQL'
  }, {
    Leads: 6798,
    Opportunities: null,
    descriptor: 'SAL'
  }, {
    Leads: null,
    Opportunities: 45,
    descriptor: 'Opportunity Stage 1'
  }, {
    Leads: null,
    Opportunities: 383,
    descriptor: 'Opportunity Stage 2'
  }, {
    Leads: null,
    Opportunities: 180,
    descriptor: 'Closed Won'
  }]
};
export const COLUMN_VISUALIZATION: ITileVisualizationConfig = {
  metricLabel: 'measurementStudio.features.stagesSnapshot.tileLabel',
  chart: {
    colors: {list: ['#4F5A9F', '#008989']},
    xAxes: [{
      type: 'CategoryAxis',
      dataFields: {category: TILE_DATA_FIELD_NAME},
      renderer: {
        grid: {
          disabled: true
        },
        minGridDistance: 10,
        labels: {
          rotation: 300,
          truncate: true,
          maxWidth: 70,
          verticalCenter: 'top',
          horizontalCenter: 'right'
        }
      }
    }],
    yAxes: [{
      id: 'v1',  // reference which is need by series
      type: 'ValueAxis',
      title: {
        text: 'Leads',
        fill: '#637178'
      },
      numberFormatter: {
        type: 'NumberFormatter',
        numberFormat: '#a',
        forceCreate: true,
      }
    }, {
      id: 'v2', // reference which is need by series
      type: 'ValueAxis',
      syncWithAxis: 'v1',
      title: {
        text: 'Opportunities',
        fill: '#637178'
      },
      numberFormatter: {
        type: 'NumberFormatter',
        numberFormat: '#a',
        forceCreate: true,
      },
      renderer: {
        opposite: true
      }
    }],
    series: [{
      yAxis: 'v1',
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'Leads',
        categoryX: TILE_DATA_FIELD_NAME
      },
      name: 'Leads',
      fillOpacity: 1,
    }, {
      yAxis: 'v2',
      type: 'ColumnSeries',
      dataFields: {
        valueY: 'Opportunities',
        categoryX: TILE_DATA_FIELD_NAME
      },
      name: 'Opportunities',
      fillOpacity: 1,
    }],
  }
};

export const CLUSTERED_TILE_DATA: ITileData = {
  metric: {
    current: {
      value: 2824596.15,
      dataType: DataTypeEnum.Currency,
    }
  },
  items: [{
    descriptor: 'Quarter 1',
    'Total Revenue': 1103876.15,
    'Revenue Influenced': 1103876.15
  }, {
    descriptor: 'Quarter 2',
    'Total Revenue': 1000000,
    'Revenue Influenced': 1000000
  }, {
    descriptor: 'Quarter 3',
    'Total Revenue': 720720,
    'Revenue Influenced': 720720
  }]
};
export const CLUSTERED_VISUALIZATION: ITileVisualizationConfig = {
  metricLabel: 'Total Revenue',
  chart: {
    numberFormatter: {
      numberFormat: '$#,###.00'
    },
    colors: {
      list: ['#C4CBCF', '#4F5A9F']
    },
    yAxes: [{
      type: 'ValueAxis',
      min: 0,
      title: {
        text: 'Total Revenue',
        fill: '#637178'
      },
      numberFormatter: {
        type: 'NumberFormatter',
        forceCreate: true,
        numberFormat: '$#a'
      }
    }]
  }
};
