import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { hubTokenName } from '@shared/constants';
import { DateCohortsSource } from '../sources/date-cohorts.source';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { EnvService } from '@shared/environment';
import { IDateCohort, IDateCohortResponse } from '@shared/interfaces';
import { DateCohortsGroups } from '@shared/enums';
import * as DateCohortsStore from '../state/date-cohorts.selectors';
import { DateCohortsState } from '../state/date-cohorts.reducer';
import { replaceLegacyCohort } from '@util/helpers';

@Injectable({
  providedIn: 'root'
})
export class DateCohortsService {
  constructor(
    public source: DateCohortsSource,
    public store: Store<DateCohortsState>,
    public retry: RetryWithEscalation,
    envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getDateCohorts$(): Observable<IDateCohortResponse> {
    return regenerateOnRetry(() => this.source.getDateCohorts$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  getSelectedDateCohortFromParams$(params: { [key: string]: any }): Observable<IDateCohort> {
    const cohortValue = params.cohort && typeof params.cohort === 'string'
      ? params.cohort
      : params.cohort && typeof params.cohort === 'object'
        ? params.cohort.cohort
        : DateCohortsGroups.Custom;
    const endDateValue = params.endDate
      ? params.endDate
      : params.cohort && typeof params.cohort === 'object'
        ? params.cohort.endDate
        : new Date().getTime();
    const startDateValue = params.startDate
      ? params.startDate
      : params.cohort && typeof params.cohort === 'object'
        ? params.cohort.startDate
        : new Date().getTime();
    const checkedCohort = replaceLegacyCohort(cohortValue);
    const customRange = {
      name: 'Custom Range',
      cohort: DateCohortsGroups.Custom,
      endDate: endDateValue,
      startDate: startDateValue
    };

    if (checkedCohort === DateCohortsGroups.Custom) {
      return of(customRange);
    }

    return this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(checkedCohort))).pipe(
      // if there is no selected date cohort from the store then return custom date cohort
      map((dateCohort: IDateCohort) => dateCohort || customRange)
    );
  }
}
