<div
  class="c-card {{ borderClass }}"
  [ngClass]="{
    'c-card--interaction': supportsInteraction,
    'c-card--centered': centeredContent,
    'c-card--aspect': aspectRatioPadding,
    'c-card--disabled': isDisabled,
    'c-card--right-spacing': utilityMenuTemplate || isDisabled,
    'c-card--flat': flat,
    'c-card--elevation2': elevation2
  }"
  [style.paddingTop]="aspectRatioPadding"
  [attr.id]="id"
>
  <div
    class="c-card__inner"
    mat-ripple
    [matRippleDisabled]="!supportsInteraction || isDisabled"
  >
    <ng-content></ng-content>
  </div>

  <ng-container
    *ngIf="utilityMenuTemplate"
    [ngTemplateOutlet]="utilityMenuTemplate"
  ></ng-container>

  <span class="c-card__lock" *ngIf="isDisabled && !utilityMenuTemplate">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="lock-alt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM264 392c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48zm32-168H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
      ></path>
    </svg>
  </span>
</div>
