/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CampaignSpecificService } from '../services/campaign-specific.service';
import {
  IAttributionDeal,
  IAttributionLead,
  IAttributionOppty,
  ICampaignOrChannelAttribution,
  ICampaignSpecificFilters,
} from '@measurement-studio/interfaces';
import { DataTypeEnum, DateCohortsGroups, RouteItemEnum } from '@shared/enums';
import { IDateCohort, IReportColumn, userMessageFactory } from '@shared/interfaces';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { CampaignTab } from '../enums/campaign-tab.enum';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { uniqBy } from '@util/helpers';
import { PER_PAGE } from '@shared/constants';
import { attributionActions } from './attribution.actions';
import * as selectors from './attribution.selectors';
import * as OrgConfigStore from '@org-config';
import * as UserSelectors from '@user/user.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import * as DateCohortsStore from '@date-cohorts';
import { DateCohortsService } from '@date-cohorts';
import { navMenusActions } from '@nav-menus';
import { ICampaignTouchReturns } from '../interfaces';



type Attributions = IAttributionLead | IAttributionDeal | IAttributionOppty;

@Injectable()
export class CampaignAttributionEffects {
  constructor(private campaignSpecificService: CampaignSpecificService,
    private store: Store<unknown>,
    private actions$: Actions,
    private translateService: TranslateService,
    private downloadService: DownloadCsvService,
    public dateCohortService: DateCohortsService) {
  }


  public loadCampaignAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.loadCampaignAttribution),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(selectors.getCampaignSpecificParams)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(selectors.getSelectedReport)),
      ),
    )),
    switchMap(([_, params, globalFilters, selectedReport]) => {
      return this.campaignSpecificService.getCampaignOrChannelAttribution$({ ...params, ...globalFilters }, selectedReport)
        .pipe(
          map((attribution: ICampaignOrChannelAttribution) => attributionActions.loadCampaignAttributionSuccess({ attribution })),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'feature.campaignSpecific.errors.loadCampaignAttribution';
            return of(attributionActions.loadCampaignAttributionFailure({ message }));
          })
        );
    })
  ));


  public getCampaignTouches$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.loadCampaignTouches),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(selectors.getCampaignSpecificParams)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(selectors.getSelectedReport)),
      )
    )),
    switchMap(([_, params, globalFilters, selectedReport]) => {
      return this.campaignSpecificService.getCampaignTouches$({ ...params, ...globalFilters }, selectedReport).pipe(
        map(({ touches }) => attributionActions.loadCampaignTouchesSuccess({ touches })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'feature.campaignSpecific.errors.loadCampaignTouches';
          return of(attributionActions.loadCampaignTouchesFailure({ message }));
        }));
    }
    )
  ));


  public onSetInitialLeadsNestedTable$ = createEffect(() => this.actions$.pipe(
    ofType(
      attributionActions.setInitialNestedTable,
      attributionActions.loadCampaignTouchesSuccess
    ),
    concatMap((action) => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(selectors.getCampaignAttribution)),
        this.store.pipe(select(selectors.getCampaignTouches))
      )
    )),
    concatMap(([_, attribution, touches]) => {
      const obsers = [];
      if (touches && touches.length && attribution) {
        const report = {
          leads: {
            data: {},
            rowState: {},
            pagination: {}
          },
          deals: {
            data: {},
            rowState: {},
            pagination: {}
          },
          opptys: {
            data: {},
            rowState: {},
            pagination: {}
          }

        };
        attribution.leads = attribution.leads.map(lead => {
          const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.leadId === lead.id), 'touch.id');
          if (nested.length > 0) {
            report.leads.data[lead.id] = nested;
            report.leads.rowState[lead.id] = true;
            report.leads.pagination[lead.id] = {
              page: 1,
              perPage: PER_PAGE
            };
          }
          lead.webTouchesAmount = nested.length;
          return lead;
        });
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Leads,
          ...report.leads
        }));
        attribution.deals = attribution.deals.map(deal => {
          const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.opptyId === deal.opptyId), 'touch.id');
          nested.forEach(item => {
            const relatedLead = attribution.leads.find(lead => lead.id === item.leadId);
            item.leadExtLink = relatedLead ? attribution.leads.find(lead => lead.id === item.leadId).leadExtLink : null;
          });
          if (nested.length > 0) {
            report.deals.data[deal.opptyId] = nested;
            report.deals.rowState[deal.opptyId] = true;
            report.deals.pagination[deal.opptyId] = {
              page: 1,
              perPage: PER_PAGE
            };
          }
          deal.webTouchesAmount = nested.length;
          return deal;
        });
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Deals,
          ...report.deals
        }));
        attribution.opptys = attribution.opptys.map(oppty => {
          const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.opptyId === oppty.opptyId), 'touch.id');
          nested.forEach(item => {
            const relatedLead = attribution.leads.find(lead => lead.id === item.leadId);
            item.leadExtLink = relatedLead ? attribution.leads.find(lead => lead.id === item.leadId).leadExtLink : null;
          });
          if (nested.length > 0) {
            report.opptys.data[oppty.opptyId] = nested;
            report.opptys.rowState[oppty.opptyId] = true;
            report.opptys.pagination[oppty.opptyId] = {
              page: 1,
              perPage: PER_PAGE
            };
          }
          oppty.webTouchesAmount = nested.length;
          return oppty;
        });
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Opportunities,
          ...report.opptys
        }));
      } else {
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Leads,
          data: null,
          rowState: null,
          pagination: null
        }));
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Deals,
          data: null,
          rowState: null,
          pagination: null
        }));
        obsers.push(attributionActions.setNestedReport({
          key: CampaignTab.Opportunities,
          data: null,
          rowState: null,
          pagination: null
        }));
      }
      return [...obsers, attributionActions.setCampaignAttribution({ attribution })];
    })
  ));


  public onLoadCampaignAttributionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.loadCampaignAttributionSuccess),
    concatMap(action => of(action.attribution).pipe(
      withLatestFrom(
        this.store.pipe(select(selectors.getCampaignSpecificParams)),
        this.store.pipe(select(OrgConfigStore.getCrmUrlForLead)),
        this.store.pipe(select(OrgConfigStore.getOrgConfigSettings)),
        this.store.pipe(select(selectors.getSelectedReport)),
      )
    )),
    concatMap(([data, filters, url, settings, report]:
      [ICampaignOrChannelAttribution, ICampaignSpecificFilters, string, any, RouteItemEnum]) => {
      data.leads = data.leads.map(lead => {
        lead.leadExtLink = (settings?.dynamics365Enabled && settings?.dynamicsUrl)
          ? `${settings.dynamicsUrl}/main.aspx?pagetype=entityrecord&etc=4&id=%7b${lead.id}%7d`
          : `${url}/${lead.id}`;
        return lead;
      });
      // get dateCohort from the store of if cohort is Custom then leave data from the filter
      const dateCohort = filters.cohort !== DateCohortsGroups.Custom
        ? this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(filters.cohort)))
        : of({
          name: 'Custom Range',
          cohort: filters.cohort,
          endDate: filters.endDate,
          startDate: filters.startDate,
        });
      const dateFieldFromDataset = new DataSetOptions().getDateFieldFromDataset(filters.dataSet);

      return of(data).pipe(withLatestFrom(
        dateCohort,
        of(dateFieldFromDataset),
        of(report)
      ));
    }),
    mergeMap(([
      data, dateCohort, dateField, report
    ]: [
        ICampaignOrChannelAttribution, IDateCohort, string, RouteItemEnum
      ]) => {
      const campaignName = data?.campaign?.name || (report === RouteItemEnum.CampaignSpecific ?
        'feature.campaignSpecific.title' : 'feature.webSpecific.title');
      const chart = this.campaignSpecificService.getTrendingChatData(data, dateCohort, dateField);
      return [
        attributionActions.setCampaignChartData({ chart }),
        // change report name on load success because updating filters reset route label
        navMenusActions.SetCustomRouteLabel({
          payload: campaignName
        }),
        attributionActions.setInitialNestedTable()
      ];
    })
  ));


  public onPageLoad$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.setCampaignFilters),
    concatMap(() => of(null).pipe(
      withLatestFrom(
        this.store.pipe(select(UserSelectors.getHasReportAccessByName(RouteItemEnum.WebTracking)))
      )
    )),
    switchMap(([_, isWebTrackingActive]: [null, boolean]) => {
      if (isWebTrackingActive) {
        return [
          attributionActions.loadCampaignAttribution(),
          attributionActions.loadCampaignTouches(),
        ];
      }
      return [
        attributionActions.loadCampaignAttribution()
      ];
    }),
  ));


  public getFilters$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.getCampaignFilters),
    map(action => action.params),
    concatMap(queryParams => of(queryParams).pipe(
      withLatestFrom(
        this.store.pipe(select(OrgConfigStore.getUserModels)),
      )
    )),
    mergeMap(([queryParams, models]) => {
      const filters = this.campaignSpecificService.getCampaignSpecificFiltersFromParams(queryParams, models);
      return [
        attributionActions.getCampaignDateCohort({ filters }),
        attributionActions.setCampaignFilters({ filters }),
      ];
    }),
  ));


  public getSelectedDateCohort$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.getCampaignDateCohort),
    map(action => action.filters),
    switchMap((filters: ICampaignSpecificFilters) => {
      // wait unit all date cohorts will be in the store and set date cohort
      return this.store.pipe(select(DateCohortsStore.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => this.dateCohortService.getSelectedDateCohortFromParams$(filters))
      );
    }),
    map((cohort: IDateCohort) => attributionActions.setDateCohort({ cohort }))
  ));


  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      attributionActions.loadCampaignAttributionFailure,
      attributionActions.loadCampaignTouchesFailure
    ),
    map(action =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) }))
  ));


  public toggleTableRow$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.toggleTableRow),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(selectors.getSpecificReportRowState(action.key)))
      ),
    )),
    map(([{ key, id }]: [
      { key: CampaignTab, id: string }, Record<string, boolean>
    ]) => {
      // get data for the row and open it then
      return attributionActions.getDataForNestedTable({
        key,
        id
      });
    })
  ));


  public getNestedTableData$ = createEffect(() => this.actions$.pipe(
    ofType(
      attributionActions.getDataForNestedTable,
      attributionActions.setReportPage,
    ),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(selectors.getSpecificSortedReport(action.key))),
        this.store.pipe(select(selectors.getCampaignTouches))
      ),
    )),
    map(([{ key }, report, touches]) => {
      return attributionActions.collectDataForNestedTable({
        key,
        report,
        touches
      });
    })
  ));


  public downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.downloadCSV),
    map(action => {
      let data: Observable<unknown>[];
      const reportKey = action.key;
      switch (reportKey) {
        case CampaignTab.Leads:
          data = [
            this.store.pipe(select(selectors.getSpecificSortedReport(CampaignTab.Leads, false))),
            of(null),
            this.store.pipe(
              select(selectors.getSpecificReportColumns(reportKey)),
              map(responsesColumns => {
                return [
                  ...responsesColumns.slice(1),
                  {
                    name: 'id',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.leads.id',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this))
            ),
            this.store.pipe(select(selectors.getCampaignSpecificName)),
            this.store.pipe(select(selectors.getSelectedCohort)),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
            of(reportKey)
          ];
          break;

        case CampaignTab.Deals:
          data = [
            this.store.pipe(select(selectors.getSpecificSortedReport(CampaignTab.Deals, false))),
            this.store.pipe(select(selectors.getReportSpecificTotalsLine(reportKey))),
            this.store.pipe(
              select(selectors.getSpecificReportColumns(reportKey)),
              map(responsesColumns => {
                return [
                  ...responsesColumns.slice(1),
                  {
                    name: 'opptyId',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.deals.nestedTable.opptyId',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this))
            ),
            this.store.pipe(select(selectors.getCampaignSpecificName)),
            this.store.pipe(select(selectors.getSelectedCohort)),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
            of(reportKey)
          ];
          break;

        case CampaignTab.Opportunities:
          data = [
            this.store.pipe(select(selectors.getSpecificSortedReport(CampaignTab.Opportunities, false))),
            this.store.pipe(select(selectors.getReportSpecificTotalsLine(reportKey))),
            this.store.pipe(
              select(selectors.getSpecificReportColumns(reportKey)),
              map(responsesColumns => {
                return [
                  ...responsesColumns.slice(1),
                  {
                    name: 'opptyId',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.opptys.nestedTable.opptyId',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this))
            ),
            this.store.pipe(select(selectors.getCampaignSpecificName)),
            this.store.pipe(select(selectors.getSelectedCohort)),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
            of(reportKey)
          ];
          break;
        default:
          data = [];
          break;
      }

      return data;
    }),
    concatMap(data => of(null).pipe(withLatestFrom(...data))),
    map(([_, report, totals, columns, name, cohort, currency, key]: [null,
      Attributions[], IAttributionDeal | IAttributionOppty, IReportColumn[], string, IDateCohort, string | null, string]
    ) => {
      const fileName = `${name}-${cohort.name.replace(' 20', '')}-${key}`;
      this.downloadService.downloadCsv<Attributions, IAttributionDeal | IAttributionOppty>(fileName, report, columns, currency, totals);
    }
    )
  ), { dispatch: false });


  public downloadNestedCSV$ = createEffect(() => this.actions$.pipe(
    ofType(attributionActions.downloadNestedTable),
    map(action => {
      let data: Observable<unknown>[] = [];
      switch (action.key) {
        case CampaignTab.Leads:
          data = [
            of(action.id),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableData(action.key)),
            ),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableColumns(action.key)),
              map(responsesColumns => {
                return [
                  ...responsesColumns,
                  {
                    name: 'id',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.leads.id',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this)),
            ),
            this.store.pipe(
              select(selectors.getSelectedCohort),
              map(cohort => `Campaigns-Influenced-By-${action.name}-${cohort.cohort}`),
            ),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
          ];
          break;

        case CampaignTab.Deals:
          data = [
            of(action.id),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableData(action.key)),
            ),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableColumns(action.key)),
              map(responsesColumns => {
                return [
                  ...responsesColumns,
                  {
                    name: 'id',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.leads.id',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this)),
            ),
            this.store.pipe(
              select(selectors.getSelectedCohort),
              map(cohort => `${action.name}-${cohort.cohort}-Web-Activity`),
            ),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
          ];
          break;

        case CampaignTab.Opportunities:
          data = [
            of(action.id),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableData(action.key)),
            ),
            this.store.pipe(
              select(selectors.getSpecificReportNestedTableColumns(action.key)),
              map(responsesColumns => {
                return [
                  ...responsesColumns,
                  {
                    name: 'id',
                    width: 100,
                    displayName: 'feature.campaignSpecific.table.leads.id',
                    dataType: DataTypeEnum.Text,
                  }
                ];
              }),
              mergeMap(this.getColumnsWithTranslationHeaders.bind(this)),
            ),
            this.store.pipe(
              select(selectors.getSelectedCohort),
              map(cohort => `Campaign-Group-Influenced-By-${action.name}-${cohort.cohort}`),
            ),
            this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
          ];
          break;
        default:
          data = [];
          break;
      }
      return data;
    }),
    concatMap(data => of(null).pipe(withLatestFrom(...data))),
    map(([_, id, report, columns, fileName, currency]: [null,
      string, Record<string, ICampaignTouchReturns[]>, IReportColumn[], string, string | null]
    ) => {
      const parsedReport = report[id].map(item => {
        return item.touch;
      });
      this.downloadService.downloadCsv(fileName, parsedReport, columns, currency);
    })
  ), { dispatch: false });

  private getColumnsWithTranslationHeaders(columns: IReportColumn[]): Observable<IReportColumn[]> {
    return combineLatest(
      columns.map(column => this.translateService.get(column.displayName).pipe(
        map(displayName => ({
          ...column,
          displayName,
        }))
      ))
    );
  }
}
