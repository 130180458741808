<ng-container *ngIf="tile">
  <div class="header"
       [ngClass]="{'tsh-drag-tile': isDraggable}"
       (click)="preventDefault($event)">
    <div>
      <h4 data-cy="tile-title"
          class="title">{{ (tile.name || defaultInformation[tile.type]?.name || 'n/a')  | translate }}</h4>
      <span class="subtitle text-capitalize">{{(tile | tileSubtitle: visualizationConfig?.metricLabel) | async}}</span>
    </div>
    <div class="actions" *ngIf="showMenu">
      <ts-menu [menuItemsTemplate]="menuTemplate"
               data-cy="tile-menu"
               class="configuration-menu"
               (click)="preventDefault($event)"
               triggerTheme="secondary">
        <i class="fas fa-sm fa-ellipsis-h"></i>
      </ts-menu>
      <ng-template #menuTemplate>
        <div class="menu-item" data-cy="tile-edit" tsMenuItem (click)="emitEdit(tile)" *ngIf="supportedTypeOfTile">
          {{ 'shared.dashboards.actions.editTile' | translate }}
        </div>
        <ng-container *ngIf="supportedTypeOfTile">
          <ng-container *ngIf="!isLimitReached; else editIsDisabled">
            <div class="menu-item" tsMenuItem data-cy="tile-duplicate" (click)="emitDuplicate(tile)">
              {{ 'shared.dashboards.actions.duplicateTile' | translate }}
            </div>
          </ng-container>
          <hr class="report-divider">
        </ng-container>
        <div class="menu-item justify" data-cy="tile-delete" tsMenuItem (click)="emitDelete(tile)">
          <span>{{ 'shared.dashboards.actions.deleteTile' | translate }}</span>
          <i class="fas fa-trash"></i>
        </div>
        <ng-template #editIsDisabled>
          <ts-tooltip [tooltipValue]="'shared.dashboards.tooltip.limit' | translate"
                      tooltipPosition="above"
                      [hasUnderline]="false">
            <div class="menu-item disabled" tsMenuItem (click)="$event.stopPropagation()">
              {{ 'shared.dashboards.actions.duplicateTile' | translate }}
            </div>
          </ts-tooltip>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="data?.items.length && !loading && !error">
    <ng-container *ngIf="tile.settings.visualization === visualizationTypes.Table; else chartVisualization">
      <tsh-table-visualization [data]="data"
                               data-cy="tile-table"
                               [extended]="extended$ | async"
                               [currency]="currency"
                               [customSettings]="visualizationConfig">
      </tsh-table-visualization>
    </ng-container>

    <ng-template #chartVisualization>
      <!-- NOTE: show this container even if there is no metric -->
      <!-- to align charts as we have them on the design -->
      <div class="metric-container">
        <tsh-report-metric *ngIf="data.metric"
                           [currency]="currency"
                           [data]="data.metric">
        </tsh-report-metric>
      </div>
      <tsh-chart-visualization [tile]="tile"
                               data-cy="tile-chart"
                               [customSettings]="visualizationConfig"
                               [data]="data">
      </tsh-chart-visualization>
    </ng-template>
  </ng-container>
  <div *ngIf="error && !loading" class="no-data-container" data-cy="tile-error">
    {{ 'shared.dashboards.errorTileData' | translate }}
  </div>
  <div *ngIf="!data?.items.length && !loading && !error" class="no-data-container" data-cy="tile-no-data">
    {{ 'shared.dashboards.noTileData' | translate }}
  </div>
</ng-container>
