import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TsButtonModule } from '@terminus-lib/ui-button';

import { PortalDirectiveModule } from '@ui/directives/portal';
import { AddToDashboardButtonComponent } from './add-to-dashboard-button.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TsButtonModule,
    PortalDirectiveModule,
  ],
  declarations: [AddToDashboardButtonComponent],
  exports: [AddToDashboardButtonComponent]
})
export class AddToDashboardButtonModule {
}
