import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import * as am4Charts from '@amcharts/amcharts4/charts';
import { TsAmChartsService } from '@terminus-lib/ui-chart';

import { ILabelValue } from '@shared/interfaces';
import { IOpportunityInsightChartData, VisualKeys } from '../../interfaces';

@Component({
  selector: 'ms-opportunities-chart',
  templateUrl: './opportunities-chart.component.html',
  styleUrls: ['./opportunities-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public data: IOpportunityInsightChartData[];
  @Input() public options: ILabelValue[] = [];
  @Input() public selectedChart: VisualKeys;
  @Input() public isLoading = false;
  @Input() public tooltipTitle: string;
  @Output() public selectChart = new EventEmitter<VisualKeys>();

  public chart: am4Charts.XYChart;
  public selectedChartControl = new FormControl(null, Validators.required);

  constructor(private amChartsService: TsAmChartsService) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnInit(): void {
    this.selectedChartControl.setValue(this.options.find(option => option.value === this.selectedChart));
  }

  ngOnChanges(changes: SimpleChanges) {
    // update chart after finishing loading all data
    if (changes.isLoading
      && !changes.isLoading.isFirstChange()
      && changes.isLoading.currentValue === false) {
      this.initChart();
    }
    // update chart after changing selected chart key
    if (changes.selectedChart
      && !changes.selectedChart.isFirstChange()
      && changes.selectedChart.currentValue !== changes.selectedChart.previousValue) {
      this.initChart();
    }
  }

  ngOnDestroy(): void {
    if (this.chart && this.chart.dispose) {
      this.chart.dispose();
    }
  }

  setUpChart(chart: am4Charts.XYChart): void {
    this.chart = chart;
    if (this.data) {
      this.initChart();
    }
  }

  initChart(): void {
    const xAxes = this.createXAxes();
    this.createYAxes();

    // set chart data
    this.chart.data = this.data;
    this.createCursor(xAxes);
    this.chart.series.clear();
    // create series
    this.createSeries();
  }

  selectionChange(): void {
    this.selectChart.emit(this.selectedChartControl.value?.value);
  }

  private createXAxes(): am4Charts.CategoryAxis {
    this.chart.xAxes.clear();
    const categoryAxis = this.chart.xAxes.push(new this.amChartsService.amCharts.charts.CategoryAxis());
    categoryAxis.dataFields.category = 'type';
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 140;
    label.textAlign = 'middle';

    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    return categoryAxis;
  }

  private createYAxes(): void {
    this.chart.yAxes.clear();
    const valueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
  }

  private createSeries(): void {
    const series = this.chart.series.push(new this.amChartsService.amCharts.charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'type';
    series.columns.template.strokeWidth = 0;
    series.columns.template.fill = this.amChartsService.amCharts.core.color('#4F5A9F');
    series.columns.template.tooltipHTML = `
            <div class="amcharts-tooltip">
            <span class="title text-capitalize">{categoryX}</span>
            <hr class="report-divider">
            <span class="subtitle">${this.tooltipTitle}</span></br>
            <div class="section">
                <div class="color" style="background-color: #4F5A9F;"></div>
                {valueY}
            </div>
        </div>`;
  }

  private createCursor(xAxes: am4Charts.CategoryAxis): void {
    this.chart.cursor = new this.amChartsService.amCharts.charts.XYCursor();
    this.chart.cursor.xAxis = xAxes;
    this.chart.cursor.fullWidthLineX = true;
    this.chart.cursor.lineX.strokeWidth = 0;
    this.chart.cursor.lineX.fill = this.amChartsService.amCharts.core.color('#4F5A9F');
    this.chart.cursor.lineX.fillOpacity = 0.12;
    this.chart.cursor.lineY.disabled = true;
  }
}
