import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IReportColumn } from '@shared/interfaces';
import { DataTypeEnum, SortDirections } from '@shared/enums';
import { compare } from '@util/helpers';
import { PER_PAGE } from '@shared/constants';
import { ITrendingDetailsItem } from '../../interfaces';

@Component({
  selector: 'ms-trending-responses-table',
  templateUrl: './trending-responses-table.component.html',
  styleUrls: ['./trending-responses-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingResponsesTableComponent implements OnChanges {
  @Input() public data: ITrendingDetailsItem[] = [];
  @Input() public columns: IReportColumn[] = [];
  @Output() public download = new EventEmitter<void>();

  public dataSource: TsTableDataSource<ITrendingDetailsItem> = new TsTableDataSource();
  public dataTypes = DataTypeEnum;
  public sortState: TsSortState | null;
  public page = 1;
  public perPage = PER_PAGE;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.updateData();
      this.cd.detectChanges();
    }

    if (changes.data || changes.columns) {
      this.cd.detectChanges();
    }
  }

  changeSort(sortState: TsSortState): void {
    this.sortState = sortState;
    this.updateData();
  }

  pageChange(event: number): void {
    this.page = event;
    this.updateData();
  }

  onNextPageSelect(): void {
    this.page = this.page + 1;
    this.updateData();
  }

  onPreviousPageSelect(): void {
    this.page = this.page - 1;
    this.updateData();
  }

  emitDownload(): void {
    this.download.emit();
  }

  // NOTE: add pagination and sort functionality here because all campaigns have already had
  // data for nested table in their object. So it will be cumbersome structure to
  // extract and store data for responses in the store
  private updateData(): void {
    const sortedData = !this.sortState || !this.sortState.direction || !this.sortState.active
      ? this.data
      : this.data.slice().sort((
        a: ITrendingDetailsItem,
        b: ITrendingDetailsItem
      ) => {
        const ifAsc = this.sortState.direction === SortDirections.Asc;
        return compare(a[this.sortState.active], b[this.sortState.active], ifAsc);
      });
    this.dataSource.data = sortedData.slice((this.page - 1) * this.perPage, this.page * this.perPage);
  }
}
