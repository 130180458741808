import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsPipesModule } from '@terminus-lib/ui-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';

import { PortalDirectiveModule } from '@ui/directives/portal';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { AppHeaderComponent } from './app-header.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TsTooltipModule,
    PortalDirectiveModule,
    TypedDataModule,
    TsPipesModule
  ],
  declarations: [
    AppHeaderComponent
  ],
  exports: [
    AppHeaderComponent
  ]
})
export class AppHeaderModule {
}

