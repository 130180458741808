import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IChannel, IChannelById, ID, ILabelValue, OptionsProvider, userMessageFactory } from '@shared/interfaces';
import { CampaignsService } from '@shared/data-access/campaigns';
import { notificationMessagesActions } from '@notification-messages';

export class ChannelsOptionsProvider implements OptionsProvider<ILabelValue> {
  constructor(private campaignsService: CampaignsService,
              private store: Store<unknown>) {
  }

  getById(id: ID): Observable<ILabelValue> {
    return this.campaignsService.getChannelsByIds$({channelIds: [id as string]}).pipe(
      pluck('channels'),
      map(([campaign]: IChannelById[]) => ({
        label: campaign.name,
        value: campaign.channelId,
      })),
    );
  }

  getFilteredOptions(search: string): Observable<ILabelValue[]> {
    return this.campaignsService.getChannels$(search).pipe(
      map((campaigns: IChannel[]) => campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.id,
      }))),
    );
  }

  getAllOptions(): ILabelValue[] {
    return [];
  }

  handleError(message: string): void {
    this.store.dispatch(notificationMessagesActions.addMessage({ message: userMessageFactory({n: message}) }));
  }
}
