import { ILabelValue, IReportColumn, IReportLink } from '@shared/interfaces';
import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { ITrendingCampaignDetails, ITrendingParams } from '../interfaces';

export const METRIC_OPTIONS_FULL_PLATFORM: ILabelValue[] = [
  {
    value: 'leads',
    label: 'feature.sharedTrending.filters.metricOptions.responses',
  }, {
    value: 'opptys',
    label: 'feature.sharedTrending.filters.metricOptions.opptys',
  }, {
    value: 'opptys_unique',
    label: 'feature.sharedTrending.filters.metricOptions.opptysUnique',
  }, {
    value: 'deals',
    label: 'feature.sharedTrending.filters.metricOptions.deals',
  }, {
    value: 'deals_unique',
    label: 'feature.sharedTrending.filters.metricOptions.dealsUnique',
  }, {
    value: 'pipeline',
    label: 'feature.sharedTrending.filters.metricOptions.pipeline',
  }, {
    value: 'revenue',
    label: 'feature.sharedTrending.filters.metricOptions.revenue',
  }
];

export const METRIC_OPTIONS_DOLLAR_INFLUENCED: ILabelValue[] = [
  {
    label: 'Influenced Pipeline',
    value: 'total_pipeline_influenced',
  }, {
    label: 'Influenced Revenue',
    value: 'total_revenue_influenced',
  }
];

export const GROUP_DETAILS_COLUMNS: IReportColumn[] = [{
  displayName: '',
  name: 'icon',
  width: 55,
  startSorting: 'asc',
  dataType: DataTypeEnum.Text,
}, {
  displayName: '',
  name: 'group',
  width: 250,
  startSorting: 'asc',
  dataType: DataTypeEnum.Text,
}, {
  displayName: 'feature.sharedTrending.groupDetails.columns.responses',
  name: 'count',
  width: 200,
  startSorting: 'desc',
  dataType: DataTypeEnum.Number,
}];

export const CAMPAIGN_DETAILS_COLUMNS: IReportColumn[] = [{
  displayName: '',
  name: 'icon',
  width: 55,
  startSorting: 'asc',
  dataType: DataTypeEnum.Text,
}, {
  displayName: '',
  name: 'name',
  width: 250,
  startSorting: 'asc',
  dataType: DataTypeEnum.Text,
  internalLink(row: ITrendingCampaignDetails, params: ITrendingParams & { isCampaignTrendsReport: boolean }): IReportLink {
    return {
      link: getRouteLink(params.isCampaignTrendsReport
        ? RouteItemEnum.CampaignSpecificLeads
        : RouteItemEnum.WebActivitiesLeads),
      queryParams: {
        name: row.name,
        campaignId: row.id,
        cohort: params.cohort,
        dataSet: params.dataSet,
        modelType: params.model
      }
    };
  }
}, {
  displayName: 'feature.sharedTrending.groupDetails.columns.responses',
  name: 'items',
  width: 200,
  startSorting: 'desc',
  dataType: DataTypeEnum.Number,
}];

export const TRENDING_RESPONSES_COLUMNS: IReportColumn[] = [{
  displayName: 'feature.sharedTrending.responsesTable.person',
  name: 'name',
  width: 150,
  startSorting: 'asc',
  dataType: DataTypeEnum.Text,
}, {
  displayName: 'feature.sharedTrending.responsesTable.amount',
  name: 'amount',
  width: 100,
  startSorting: 'desc',
  dataType: DataTypeEnum.Currency,
}, {
  displayName: 'feature.sharedTrending.responsesTable.activityDate',
  name: 'activityDate',
  width: 150,
  startSorting: 'asc',
  dataType: DataTypeEnum.Date,
}, {
  displayName: 'feature.sharedTrending.responsesTable.createdDate',
  name: 'createdDate',
  width: 120,
  startSorting: 'asc',
  dataType: DataTypeEnum.Date,
}];

export const TRENDING_DOLLARS_METRICS_IDS: string[] = [
  'total_pipeline_influenced',
  'total_revenue_influenced',
  'pipeline',
  'revenue',
];

export enum TileSettingsTrendingFields {
  Field = 'field',
  DataSet = 'dataSet',
  Interval = 'freqType',
  Model = 'model',
  Goal = 'goal',
  Type = 'type',
}
