import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import * as am4Charts from '@amcharts/amcharts4/charts';
import { TsAmChartsService } from '@terminus-lib/ui-chart';

import { DataTypeEnum } from '@shared/enums';
import { ICampaignChartData, ICampaignChartType } from '../../interfaces';

@Component({
  selector: 'ms-campaign-chart',
  templateUrl: './campaign-chart.component.html',
  styleUrls: ['./campaign-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignChartComponent implements OnChanges, OnDestroy {
  @Input() public data: ICampaignChartData[];
  @Input() public selectedChartType: ICampaignChartType;
  @Input() public tooltipLabel: string;

  public chart: am4Charts.XYChart;

  constructor(private amChartsService: TsAmChartsService) {
    this.amChartsService.amCharts.core.options.commercialLicense = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // update chart data after changing report data
    if (changes.data && this.chart) {
      this.initChart();
    }

    if (changes.selectedChartType && !changes.selectedChartType.isFirstChange()) {
      // update cursor color
      this.chart.cursor.lineX.stroke = this.amChartsService.amCharts.core.color(this.selectedChartType.color);
      // update value axes data format
      this.chart.numberFormatter.numberFormat = this.selectedChartType.dataType === DataTypeEnum.Currency ? '$#a' : '#';
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setUpChart(chart: am4Charts.XYChart): void {
    this.chart = chart;
    this.createYAxes();
    const xAxes = this.createXAxes();
    this.createCursor(xAxes);
    if (this.data) {
      this.initChart();
    }
  }

  initChart(): void {
    // set chart data
    this.chart.data = this.data;
    this.createSeries();
  }

  private createSeries(): void {
    this.chart.series.clear();
    const series = this.chart.series.push(new this.amChartsService.amCharts.charts.LineSeries());
    series.dataFields.valueY = 'total';
    series.dataFields.dateX = 'date';
    series.stroke = this.selectedChartType.color;
    series.strokeWidth = 3;
    series.tooltipHTML = `
            <div class="amcharts-tooltip">
                <span class="title text-capitalize">{dateX.formatDate('MMM d, yyyy')}</span>
                <hr class="report-divider">
                <span class="subtitle">${this.tooltipLabel}</span></br>
                <div class="section">
                    <div class="color" style="background-color: ${this.selectedChartType.color};"></div>
                    ${this.selectedChartType.dataType === DataTypeEnum.Currency ? '{valueY.formatNumber("$#,###.00")}' : '{valueY}'}
                </div>
            </div>`;
  }

  private createXAxes(): am4Charts.CategoryAxis {
    this.chart.xAxes.clear();
    const dateAxis = this.chart.xAxes.push(new this.amChartsService.amCharts.charts.DateAxis());
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.startLocation = -0.3;
    dateAxis.endLocation = 1.3;
    return dateAxis;
  }

  private createYAxes(): void {
    this.chart.yAxes.clear();
    const valueAxis = this.chart.yAxes.push(new this.amChartsService.amCharts.charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.min = 0;
    if (this.selectedChartType.dataType === DataTypeEnum.Currency) {
      valueAxis.numberFormatter.numberFormat = '$#a';
    }
  }

  private createCursor(
    xAxes: am4Charts.CategoryAxis,
  ): void {
    this.chart.cursor = new this.amChartsService.amCharts.charts.XYCursor();
    this.chart.cursor.xAxis = xAxes;
    this.chart.cursor.lineY.disabled = true;
    this.chart.cursor.lineX.stroke = this.amChartsService.amCharts.core.color(this.selectedChartType.color);
    this.chart.cursor.lineX.strokeWidth = 30;
    this.chart.cursor.lineX.strokeOpacity = 0.16;
    this.chart.cursor.lineX.strokeDasharray = '';
  }
}
