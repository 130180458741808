<div class="container" *ngIf="customSettings">
  <table class="table">
    <thead>
    <tr>
      <th *ngFor="let column of customSettings.table.columns">
        {{ column.displayName | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of data?.items.slice(0, maxRows)">
      <td *ngFor="let column of customSettings.table.columns"
          [title]="column.dataType === dataTypes.Text ? row[column.name] : ''"
          [ngClass]="{'currency': column.dataType === dataTypes.Currency}">
        {{ row[column.name] | typedData: column.dataType :column.decimal : column.shouldAbbreviate || false : currency }}
      </td>
    </tr>
    </tbody>
  </table>
  <span class="totals">
        1-{{ maxRows > data.totalCount ? data.totalCount : maxRows }} {{ 'shared.dashboards.tableVisualization.of' | translate }}
    {{ data.totalCount }} {{ (data.totalCount > 1 ? customSettings.table.totalsPluralLabel : customSettings.table.totalsLabel) | translate }}
    </span>
</div>
