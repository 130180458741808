import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TsSelectionListComparator, TsSelectionListFormatter } from '@terminus-lib/ui-selection-list';

import { IFolderOption, IReportStateInfo, ISavedReport } from '@shared/interfaces';

interface IngoingData {
  report: ISavedReport;
  foldersOptions: IFolderOption[];
  stateInformation: IReportStateInfo;
  currentFolderId: number;
}

@Component({
  selector: 'tsh-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDialogComponent implements OnInit {
  public form: FormGroup;
  public isDisabled = false;
  public formatDisplay: TsSelectionListFormatter = (v: IFolderOption) => v.folderName;
  public folderCompareFunction: TsSelectionListComparator =
    (a: IFolderOption, b: IFolderOption) => a.folderId === b.folderId;

  constructor(public dialogRef: MatDialogRef<ReportDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) public data: IngoingData) {
  }

  ngOnInit(): void {
    const folderId = this.data?.report?.folderId || this.data.currentFolderId;
    const folder = this.data.foldersOptions.find(folderOption => folderOption.folderId === folderId) || this.data.foldersOptions[0];
    this.form = this.formBuilder.group({
      name: [this.data?.report?.name || null, Validators.required],
      description: this.data?.report?.description || null,
      url: [this.data?.report?.url || this.document.location.href, Validators.required],
      stateName: this.data?.report?.stateName || this.data.stateInformation.stateName,
      stateParams: this.data?.report?.stateParams || this.data.stateInformation.stateParams,
      folderId: [[folder], Validators.required]
    });

    if (this.data.report) {
      // Add report id if we have to update the folder
      this.form.addControl('reportId', new FormControl(this.data.report.reportId));
    }
  }

  saveReport(): void {
    if (this.form.valid) {
      this.isDisabled = true; // to prevent double click
      this.dialogRef.close({
        ...this.form.value,
        folderId: this.form.get('folderId').value[0].folderId
      });
    }
  }


  cancel(): void {
    this.dialogRef.close();
  }

  deleteReport(): void {
    this.isDisabled = true; // to prevent double click
    this.dialogRef.close(this.data.report.reportId);
  }
}
