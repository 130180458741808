import { createAction, props } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IMarketingInfluenceReport, IMarketingInfluenceRequest, MarketingInfluenceUsedParams } from '../interfaces';
import { IDateCohort, IEditTableColumn, IReportColumn } from '@shared/interfaces';

export enum MarketingInfluenceActionTypes {
  DOWNLOAD_CSV = '[Marketing Influence] Download Details CSV',
  DOWNLOAD_CSV_SUCCESS = '[Marketing Influence] Download Details CSV Success',
  DOWNLOAD_CSV_FAILURE = '[Marketing Influence] Download Details CSV Failure',
  GET_MARKETING_INFLUENCE_REPORT_DATA = '[Marketing Influence] Get Marketing Influence Report Data',
  GET_MARKETING_INFLUENCE_REPORT_DATA_SUCCESS = '[Marketing Influence] Get Marketing Influence Report Data Success',
  GET_MARKETING_INFLUENCE_REPORT_DATA_FAILURE = '[Marketing Influence] Get Marketing Influence Report Data Failure',
  SET_MARKETING_TABLE_SORT_STATE = '[Marketing Influence] Set Table Sort State',
  SET_MARKETING_TABLE_PAGE = '[Marketing Influence] Set Table Page',
  SET_MARKETING_INFLUENCE_FILTERS = '[Marketing Influence] Set Marketing Influence Filters',
  SET_SELECTED_DATE_COHORT = '[Marketing Influence] Set selected date cohort for Marketing Influence report',
  MARKETING_INFLUENCE_REDIRECT = '[Marketing Influence] Redirect from Marketing Influence page to Opportunity Insight',
  SET_USED_QUERY_PARAMS = '[Marketing Influence] Set used for api call query params',
  TOGGLE_COLUMN_VISIBILITY = '[Marketing Influence] Show or hide column in the table',
  SET_COLUMNS = '[Marketing Influence] Set columns for the table'
}

defineTypeEnum(MarketingInfluenceActionTypes);

const downloadCsv = createAction(
  MarketingInfluenceActionTypes.DOWNLOAD_CSV
);

const downloadCsvFailure = createAction(
  MarketingInfluenceActionTypes.DOWNLOAD_CSV_FAILURE,
  props<{ message: string }>()
);

const downloadCsvSuccess = createAction(
  MarketingInfluenceActionTypes.DOWNLOAD_CSV_SUCCESS
);

const getMarketingInfluenceReportData = createAction(
  MarketingInfluenceActionTypes.GET_MARKETING_INFLUENCE_REPORT_DATA
);

const getMarketingInfluenceReportDataFailure = createAction(
  MarketingInfluenceActionTypes.GET_MARKETING_INFLUENCE_REPORT_DATA_FAILURE,
  props<{ message: string }>()
);

const getMarketingInfluenceReportDataSuccess = createAction(
  MarketingInfluenceActionTypes.GET_MARKETING_INFLUENCE_REPORT_DATA_SUCCESS,
  props<{ data: IMarketingInfluenceReport[] }>()
);

const setMarketingTableSortState = createAction(
  MarketingInfluenceActionTypes.SET_MARKETING_TABLE_SORT_STATE,
  props<{ sortState: TsSortState }>()
);

const setMarketingTablePage = createAction(
  MarketingInfluenceActionTypes.SET_MARKETING_TABLE_PAGE,
  props<{ page: number }>()
);

const setMarketingInfluenceFilter  = createAction(
  MarketingInfluenceActionTypes.SET_MARKETING_INFLUENCE_FILTERS,
  props<{ filters: IMarketingInfluenceRequest }>()
);

const setUsedQueryParams  = createAction(
  MarketingInfluenceActionTypes.SET_USED_QUERY_PARAMS,
  props<{ params: MarketingInfluenceUsedParams }>()
);


const setSelectedDateCohort  = createAction(
  MarketingInfluenceActionTypes.SET_SELECTED_DATE_COHORT,
  props<{ cohort: IDateCohort }>()
);

const marketingInfluenceRedirect  = createAction(
  MarketingInfluenceActionTypes.MARKETING_INFLUENCE_REDIRECT,
  props<{ payload: string }>()
);

const toggleColumnVisibility  = createAction(
  MarketingInfluenceActionTypes.TOGGLE_COLUMN_VISIBILITY,
  props<{ column: IEditTableColumn }>()
);

const setColumns  = createAction(
  MarketingInfluenceActionTypes.SET_COLUMNS,
  props<{ columns: IReportColumn[] }>()
);

export const marketingInfluenceActions = {
  downloadCsv,
  downloadCsvSuccess,
  downloadCsvFailure,
  getMarketingInfluenceReportData,
  getMarketingInfluenceReportDataFailure,
  getMarketingInfluenceReportDataSuccess,
  setMarketingTableSortState,
  setMarketingTablePage,
  setMarketingInfluenceFilter,
  setSelectedDateCohort,
  marketingInfluenceRedirect,
  setUsedQueryParams,
  toggleColumnVisibility,
  setColumns
};
