import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { toCamelCase, untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ClosedType, DateType, InfluencesKeys, WonType } from '@measurement-studio/enums';
import {
  DateCohortsGroups,
  DialogType,
  InfluenceType,
  PortalTargets,
  TileTypes,
  ToDate
} from '@shared/enums';
import { NavigateTo } from '@hub-navigation';
import { GoParams, IDateCohort, IEditTableColumn, ILabelValue } from '@shared/interfaces';
import { PER_PAGE } from '@shared/constants';
import * as OpportunitiesSelector from '../../state/opportunities.selectors';
import { opportunitiesActions } from '../../state/opportunities.actions';

import * as DateCohortsStore from '@date-cohorts';
import { dateCohortsActions } from '@date-cohorts';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { TileModalService } from '@shared/features/tile-modal';
import { IOpportunityInsightChartData, VisualKeys } from '../../interfaces';
import { IOpportunityRequest, OpportunityDataType } from '@measurement-studio/interfaces';
import { orgConfigActions, getOrgCurrencySetting, getSigstrProductType } from '@org-config';
import { MatDialog } from '@angular/material/dialog';
import { ManageListsDialogComponent } from '@shared/features/account-list-popup';

@WithDestroy
@Component({
  selector: 'ms-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {
  public readonly targets = PortalTargets;
  public report$ = this.store.pipe(select(OpportunitiesSelector.getOpportunityReport));
  public chartOptions$ = this.store.pipe(select(OpportunitiesSelector.getChartOptions));
  public selectedChart$ = this.store.pipe(select(OpportunitiesSelector.getSelectedChartKey));
  public filters$ = this.store.pipe(select(OpportunitiesSelector.getOpportunityFilters));
  public isLoading$ = this.store.pipe(select(OpportunitiesSelector.getOpportunityIsLoading));
  public isLoadingVisual$ = this.store.pipe(select(OpportunitiesSelector.getOpportunityIsLoadingVisual));
  public totals$ = this.store.pipe(select(OpportunitiesSelector.getOpportunityTotals));
  public visibleColumns$ = this.store.pipe(select(OpportunitiesSelector.getVisibleOpportunityColumns));
  public allColumns$ = this.store.pipe(select(OpportunitiesSelector.getAllOpportunityColumns));
  public searchOptions$ = this.store.pipe(select(OpportunitiesSelector.getSearchOptions));
  public currency$ = this.store.pipe(select(getOrgCurrencySetting));
  public modelsOptions$ = this.store.pipe(select(OpportunitiesSelector.getModelsOptions));
  public campaignSpecificData$ = this.store.pipe(select(OpportunitiesSelector.getCampaignSpecificData));
  public selectedDateCohort$ = this.store.pipe(select(OpportunitiesSelector.getSelectedDateCohort));
  public dateCohortOptions$ = this.store.pipe(
    select(DateCohortsStore.getGroupedDateCohortOptions([
      DateCohortsGroups.All, DateCohortsGroups.Years, DateCohortsGroups.Ago,
      DateCohortsGroups.LastFull, DateCohortsGroups.Quarters, DateCohortsGroups.ToDate,
      DateCohortsGroups.Custom
    ])));
  public metrics$ = this.store.pipe(select(OpportunitiesSelector.getOpportunitiesMetrics));
  public chart$ = this.store.pipe(select(OpportunitiesSelector.getChartByKey)).pipe(
    withLatestFrom(this.translateService.get('measurementStudio.features.opportunities.chart.noCampaign')),
    filter(([data]) => !!data),
    map(([data, noCampaignTranslation]: [IOpportunityInsightChartData[], string]) => {
      return data.map(item => {
        return {
          ...item,
          type: item.type || noCampaignTranslation
        };
      });
    })
  );

  public defaultQueryParams: IOpportunityRequest = {
    pg: 1,
    ps: PER_PAGE,
    model: InfluenceType.ANY,
    closedType: ClosedType.ANY,
    dateType: DateType.Created,
    type: OpportunityDataType.Pipeline,
    wonType: WonType.Any,
    cohort: ToDate.QUARTER_TO_DATE
  };
  public noResultTitle = 'measurementStudio.features.opportunities.noResultTitle';
  public noResultDescription = 'measurementStudio.features.opportunities.noResultDescription';

  private sortingColumn: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<unknown>,
              private translateService: TranslateService,
              private tileModalService: TileModalService,
              private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    // load date cohort options for filter component
    this.store.dispatch(dateCohortsActions.loadDateCohorts());
    this.store.dispatch(orgConfigActions.LoadSigstrsProductType());
    this.chartOptions$.pipe(
      untilComponentDestroyed(this),
      filter((options: ILabelValue[]) => !!options.length)
    ).subscribe(options => {
      // Set 1st option as a selected chart by default
      this.store.dispatch(opportunitiesActions.setSelectedChart({ chart: options[0].value as VisualKeys }));
    });
    this.store.pipe(select(getSigstrProductType)).pipe(
      untilComponentDestroyed(this),
      filter((data: string) => data !== null)
    ).subscribe(() => {
      // Get table's columns only after receiving sigstr product type
      this.store.dispatch(opportunitiesActions.getReportColumns());
    });

    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe(params => {
        this.sortingColumn = params.fld;
        const filters$ = this.filters$.pipe(take(1));
        const globalFilters$ = this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1));

        combineLatest([filters$, globalFilters$])
          .pipe(untilComponentDestroyed(this))
          .subscribe(([filters, globalFilters]) => {
            if (!Object.keys(params).length && !filters) {
              // if there is no any query params then use default params
              this.applyFilters({ ...this.defaultQueryParams, ...globalFilters });
              return;
            }

            if (!Object.keys(params).length && filters) {
              // if there is no any query params but filters in the store then use them
              this.applyFilters({ ...filters, ...globalFilters });
              return;
            }

            const queryParams = this.getQueryParams(params);
            this.setDateCohort(queryParams);
            this.store.dispatch(opportunitiesActions.setOpportunityFilters({ filters: queryParams }));
          });
      });

    this.allColumns$.pipe(
      untilComponentDestroyed(this),
      filter(columns => columns && columns.length > 0),
      take(1)
    ).subscribe(columns => {
      if (!this.sortingColumn) {
        return;
      }
      const validColumnName = toCamelCase(this.sortingColumn);
      // show the sorting column if it is passed as parameter and available
      const sortColumnsIndex = columns.findIndex(column => toCamelCase(column.name) === validColumnName);
      if (sortColumnsIndex >= 0 && !columns[sortColumnsIndex].visible) {
        this.store.dispatch(opportunitiesActions.toggleColumnVisibility({
          column: {isVisible: true, name: validColumnName}
        }));
      }
    });
  }

  applyFilters(newFilters: IOpportunityRequest): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.updateTypeParam(newFilters),
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  redirect(params: GoParams): void {
    this.store.dispatch(NavigateTo(params));
  }

  changeColumns(column: IEditTableColumn): void {
    this.store.dispatch(opportunitiesActions.toggleColumnVisibility({ column }));
  }

  setSelectedDateCohort(cohort: IDateCohort): void {
    this.store.dispatch(opportunitiesActions.setDateCohort({ cohort }));
  }

  download(): void {
    this.store.dispatch(opportunitiesActions.downloadCSV());
  }

  selectChart(chart: VisualKeys): void {
    this.store.dispatch(opportunitiesActions.setSelectedChart({ chart }));
  }

  addToDashboardDialog(): void {
    this.tileModalService.openAddToDashboardModal(TileTypes.OpportunityInsights, this.route.snapshot.queryParams);
  }

  getToAccountList(filters: IOpportunityRequest): void {
    const dialogRef = this.dialog.open(ManageListsDialogComponent, {
      data: {
        dialogType: DialogType.AddAccounts,
        filters: {
          model: filters.model,
          closedType: filters.closedType,
          dateType: filters.dateType,
          type: filters.type,
          wonType: filters.wonType,
          cohort: filters.cohort,
          startDate: filters.startDate,
          endDate: filters.endDate
        }
      },
      width: '620px',
      panelClass: '',
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      console.log(result);
    });
  }

  private setDateCohort(filters: IOpportunityRequest): void {
    if (filters.cohort === DateCohortsGroups.Custom) {
      // in case of custom date cohort set data from query params
      this.setSelectedDateCohort({
        name: 'Custom Range',
        cohort: filters.cohort,
        endDate: filters.endDate,
        startDate: filters.startDate,
      });
    } else {
      // in case of non custom date cohort set data from date cohorts source
      this.store.pipe(
        untilComponentDestroyed(this),
        select(DateCohortsStore.getDateCohortByCohort(filters.cohort)),
        filter((dateCohort: IDateCohort) => !!dateCohort)
      ).subscribe(data => {
        this.setSelectedDateCohort(data);
      });
    }
  }

  private getQueryParams(params: Params): IOpportunityRequest {
    // get query params from url or use default params
    const pageSize = params['ps'] && !isNaN(Number(params['ps']))
      ? Number(params['ps'])
      : this.defaultQueryParams.ps;
    const pageNumber = params['pg'] && !isNaN(Number(params['pg'])) && Number(params['pg']) > 0
      ? Number(params['pg'])
      : this.defaultQueryParams.pg;
    const defaultParams = {
      pg: pageNumber,
      ps: pageSize,
      searchField: params['searchField'],
      searchQuery: params['searchQuery'],
      fld: params['fld'],
      dir: params['dir'],
      tbl: params['tbl'],
      [InfluencesKeys.InfluencedCampaignGroup]: params[InfluencesKeys.InfluencedCampaignGroup],
      [InfluencesKeys.SourceCampaignGroup]: params[InfluencesKeys.SourceCampaignGroup],
      model: params['model'] || this.defaultQueryParams.model,
      closedType: params['closedType'] || this.defaultQueryParams.closedType,
      dateType: params['dateType'] || this.defaultQueryParams.dateType,
      type: params['type'] || this.defaultQueryParams.type,
      wonType: params['wonType'] || this.defaultQueryParams.wonType,
      cohort: params['cohort'] || this.defaultQueryParams.cohort,
      endDate: params['endDate'],
      startDate: params['startDate'],
    };

    // NOTE: if date cohort is custom but there is no startDate
    // then we have to set startDate the same as endDate or just current date
    const startDate = params.cohort === 'custom'
      ? params.startDate || params.endDate || new Date().getTime()
      : params.startDate;
    // NOTE: if date cohort is custom but there is no endDate
    // then we have to set endDate the same as startDate or just current date
    const endDate = params.cohort === 'custom'
      ? params.endDate || params.startDate || new Date().getTime()
      : params.endDate;
    // need to change startDate and endDate to number because query params are string
    return {
      ...this.updateTypeParam(defaultParams),
      startDate: startDate ? Number(startDate) : startDate,
      endDate: endDate ? Number(endDate) : endDate,
    };
  }

  private updateTypeParam(filters: IOpportunityRequest): IOpportunityRequest {
    let type = filters.type;
    const isDateClosed = filters.dateType === DateType.Closed;

    if (isDateClosed && filters.closedType === ClosedType.WON) {
      type = OpportunityDataType.Revenue;
    } else if (isDateClosed && filters.closedType === ClosedType.ANY) {
      type = OpportunityDataType.Closed;
    } else if (isDateClosed && filters.closedType === ClosedType.OPEN) {
      type = OpportunityDataType.Forecast;
    }

    return {
      ...filters,
      type
    };
  }
}
