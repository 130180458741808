import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsTooltipModule } from '@terminus-lib/ui-tooltip';

@Component({
  selector: 'ms-insights-metric-item',
  templateUrl: './insights-metric-item.component.html',
  styleUrls: ['./insights-metric-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsMetricItemComponent {
  @Input() public label = '';
  @Input() public value = '';
  @Input() public tooltip = '';
  @Input() public legendColor = '';
}

@NgModule({
  declarations: [
    InsightsMetricItemComponent
  ],
  imports: [
    CommonModule,
    TsTooltipModule
  ],
  exports: [
    InsightsMetricItemComponent
  ]
})
export class InsightsMetricItemModule {
}
