import { createSelector, MemoizedSelector } from '@ngrx/store';

import { FormState } from './store-form-in-state.interfaces';

export function formIsValid(inp: MemoizedSelector<any, FormState<any>>) {
  return createSelector(
    inp,
    (state) => state.isValid
  );
}

export function getErrorsOfFormField<G>(inp: MemoizedSelector<any, FormState<G>>, fieldName: keyof G) {
  return createSelector(
    currentFormValues<G>(inp),
    (values) => values[fieldName].errors
  );
}

export function getValueOfFormField<G>(inp: MemoizedSelector<any, FormState<G>>, fieldName: keyof G) {
  return createSelector(
    currentFormValues<G>(inp),
    (values) => values[fieldName]
  );
}

export function currentFormValues<G>(inp: MemoizedSelector<any, FormState<G>>) {
  return createSelector(
    inp,
    (state) => state.values
  );
}
