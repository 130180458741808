import { Injectable } from '@angular/core';

import { hubTokenName, TILE_DATA_FIELD_NAME } from '@shared/constants';
import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { TranslateService } from '@ngx-translate/core';
import * as am4core from '@amcharts/amcharts4/core';

import { EnvService } from '@shared/environment';
import { StagesSnapshotSource } from '../sources/stages-snapshot.source';
import { IDashboardTile, IGetTileData, ITileData, ITileDataItem, ITileSettings, ITileVisualizationConfig } from '@shared/interfaces';
import { getWfStages, IWfStage, WfStageTypes } from '@shared/data-access/catalogs';
import { ChartColors } from '../enums/chart-colors.enum';
import { getStartAndEndDateFromParams, replaceLegacyCohort, toCapitalize } from '@util/helpers';
import { ToDate } from '@shared/enums';
import { IFlatStageProgression, IFlatStageProgressionDetail, IStagesSnapshotFilters } from '../interfaces';

@Injectable({providedIn: 'root'})
export class StagesSnapshotService implements IGetTileData<IStagesSnapshotFilters> {
  constructor(private source: StagesSnapshotSource,
              private store: Store<unknown>,
              private retry: RetryWithEscalation,
              private translateService: TranslateService,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getFlatStageProgression$(params: IStagesSnapshotFilters): Observable<IFlatStageProgression[]> {
    return regenerateOnRetry(() => this.source.getFlatStageProgression$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileData$(params: IStagesSnapshotFilters): Observable<ITileData> {
    return combineLatest([
      this.source.getFlatStageProgression$(params),
      this.store.pipe(select(getWfStages)),
      this.getPropertiesKeys(),
    ]).pipe(
      map(([data, stages, labels]: [
        IFlatStageProgression[], IWfStage[], Record<WfStageTypes, string>
      ]) => this.tileAdapter(data, stages, labels))
    );
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    const cohort = {
      cohort: replaceLegacyCohort(params.cohort as string) || ToDate.QUARTER_TO_DATE,
      ...getStartAndEndDateFromParams(params.cohort as string, params.startDate, params.endDate)
    };

    return {
      ...params, // in case if we have global filters we have to keep them
      ...cohort,
    };
  }

  getTileVisualizationConfig$(_tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    return this.getPropertiesKeys().pipe(
      map((labels: Record<WfStageTypes, string>) => {
        return {
          metricLabel: 'measurementStudio.features.stagesSnapshot.tileLabel',
          chart: {
            colors: {
              list: [ChartColors.Lead, ChartColors.Oppty]
            },
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: {
                  category: TILE_DATA_FIELD_NAME
                },
                renderer: {
                  grid: {
                    disabled: true
                  },
                  minGridDistance: 10,
                  labels: {
                    rotation: 300,
                    truncate: true,
                    maxWidth: 70,
                    verticalCenter: 'top',
                    horizontalCenter: 'right'
                  }
                }
              }
            ],
            yAxes: [
              {
                id: 'v1',  // reference which is need by series
                type: 'ValueAxis',
                title: {
                  text: toCapitalize(labels[WfStageTypes.Lead]),
                  fill: '#637178'
                },
                numberFormatter: {
                  type: 'NumberFormatter',
                  numberFormat: '#a',
                  forceCreate: true,
                }
              },
              {
                id: 'v2', // reference which is need by series
                type: 'ValueAxis',
                syncWithAxis: 'v1',
                title: {
                  text: toCapitalize(labels[WfStageTypes.Opportunity]),
                  fill: '#637178'
                },
                numberFormatter: {
                  type: 'NumberFormatter',
                  numberFormat: '#a',
                  forceCreate: true,
                },
                renderer: {
                  opposite: true
                }
              }
            ],
            series: [
              {
                yAxis: 'v1',
                type: 'ColumnSeries',
                dataFields: {
                  valueY: labels[WfStageTypes.Lead],
                  categoryX: TILE_DATA_FIELD_NAME
                },
                columns: {
                  tooltipX: am4core.percent(100),
                  tooltipY: am4core.percent(0),
                  tooltipHTML: `<div class="amcharts-tooltip">
                    <span class="title">{categoryX}</span>
                    <hr class="report-divider">
                    <span class="subtitle text-capitalize">
                       ${labels[WfStageTypes.Lead]}
                    </span></br>
                    <div class="section">
                       <div class="color" style="background-color: ${ChartColors.Lead};"></div>
                       {valueY.formatNumber("#,###.")}
                    </div>
                  </div>`,
                },
                name: labels[WfStageTypes.Lead],
                fillOpacity: 1,
              },
              {
                yAxis: 'v2',
                type: 'ColumnSeries',
                dataFields: {
                  valueY: labels[WfStageTypes.Opportunity],
                  categoryX: TILE_DATA_FIELD_NAME
                },
                columns: {
                  tooltipX: am4core.percent(0),
                  tooltipY: am4core.percent(0),
                  tooltipHTML: `<div class="amcharts-tooltip">
                    <span class="title">{categoryX}</span>
                    <hr class="report-divider">
                    <span class="subtitle text-capitalize">
                       ${labels[WfStageTypes.Opportunity]}
                    </span></br>
                    <div class="section">
                       <div class="color" style="background-color: ${ChartColors.Oppty};"></div>
                       {valueY.formatNumber("#,###.")}
                    </div>
                  </div>`,
                },
                name: labels[WfStageTypes.Opportunity],
                fillOpacity: 1,
              }
            ],
          }
        };
      })
    );
  }

  getFlatStageProgressionDetail$(params: IStagesSnapshotFilters): Observable<IFlatStageProgressionDetail[]> {
    return regenerateOnRetry(() => this.source.getFlatStageProgressionDetail$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileSettingsFilters$(): Observable<null> {
    // StagesSnapshot doesn't have setting filters
    return of(null);
  }

  private getPropertiesKeys(): Observable<Record<WfStageTypes, string>> {
    return combineLatest([
      this.translateService.get('measurementStudio.features.stagesSnapshot.chart.leads'),
      this.translateService.get('measurementStudio.features.stagesSnapshot.chart.opportunities')
    ]).pipe(
      map(([lead, oppty]: [string, string]) => ({
        [WfStageTypes.Lead]: lead,
        [WfStageTypes.Opportunity]: oppty
      }))
    );
  }

  private tileAdapter(
    data: IFlatStageProgression[],
    stages: IWfStage[],
    labels: Record<WfStageTypes, string>
  ): ITileData {
    if (!data || !stages.length) {
      return {
        items: [],
      };
    }

    return {
      items: data.reduce((acc: ITileDataItem[], item: IFlatStageProgression) => {
        if (stages.find(stage => stage.stage === item.name)?.appliesTo === WfStageTypes.Lead) {
          acc.push({
            [labels[WfStageTypes.Lead]]: item.count,
            [labels[WfStageTypes.Opportunity]]: null,
            [TILE_DATA_FIELD_NAME]: item.name,
          });
        }

        if (stages.find(stage => stage.stage === item.name)?.appliesTo === WfStageTypes.Opportunity) {
          acc.push({
            [labels[WfStageTypes.Lead]]: null,
            [labels[WfStageTypes.Opportunity]]: item.count,
            [TILE_DATA_FIELD_NAME]: item.name,
          });
        }

        return acc;
      }, [])
    };
  }
}
