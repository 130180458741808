import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TsCookieService, TsWindowService } from '@terminus-lib/fe-utilities';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { FullStoryService } from '@fullstory/fullstory.service';
import { EnvService } from '@shared/environment';
import { NavigateTo } from '@hub-navigation';
import { NotificationIcons, NotificationTypes } from '@shared/enums';
import { LANGUAGES } from '@shared/constants';
import { GoParams, IFeatureFlags, IOrgConfig, IRouteItem, IUserMessage } from '@shared/interfaces';
import { GainsightService } from '@gainsight';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '@ui/components/notification';
import * as UserSelectors from '@user/user.selectors';
import { userActions } from '@user/user.actions';
import { appSettingsActions } from '@app-settings/app-settings.actions';
import * as AppSettingsSelectors from '@app-settings/app-settings.selectors';
import * as HubTokenSelectors from '@hub-token';
import * as HubRouterSelectors from '@hub-router';
import * as SidenavUtilitiesStore from '@shared/features/sidenav-utilities';
import * as NotificationMessagesStore from '@notification-messages';
import { getOrgConfig, orgConfigActions } from '@org-config';
import { dateCohortsActions } from '@date-cohorts';
import * as NavMenusStore from '@nav-menus';
import { selectIsUserProfileLoaded, selectUserProfile } from '@shared/data-access/user-common';
import { APP_ROUTES } from '@app-routes';
import { findRouteItem } from '@util/helpers';
import { getIsSplitIoFlagOn, treatmentTokens } from '@shared/splitio';
import { GreenfieldBridge } from '@util/greenfield-bridge';

@Component({
  selector: 'tsh-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppContainerComponent implements OnInit {
  public appSettings$ = this.store.pipe(select(AppSettingsSelectors.selectAppSettingsState));
  public canUseAccountHub$ = this.store.pipe(select(HubTokenSelectors.selectCanUseAccountHub));
  public isUtilitiesSidenavVisible$ = this.store.pipe(select(SidenavUtilitiesStore.isRouteWithUtilitiesSidenav));
  public isNewLayout$ = this.store.pipe(select(HubRouterSelectors.isNewLayout));
  public navMenu$ = this.store.pipe(select(NavMenusStore.getNavMenu));
  public switcherContent$ = this.store.pipe(select(NavMenusStore.selectSwitcherContent));
  public userProfile$ = this.store.pipe(select(selectUserProfile));
  public selectedNavItem$ = this.store.pipe(select(NavMenusStore.getActiveNavItemWithCategories));
  public selectedRoute$ = this.store.pipe(select(NavMenusStore.selectSelectedRouteItem));
  public zdSsoAccess$: Observable<boolean> = this.store.pipe(select(
    getIsSplitIoFlagOn(treatmentTokens.zd_sso)
  ));
  public sidenavUserMenuLinks$ = this.store.pipe(select(UserSelectors.getSidenavUserMenu(this.envService.getEnv().UNIFIED_LOGIN_URL)));
  public specificLink$ = this.store.pipe(select(NavMenusStore.selectSpecificLink));
  public rightSidenavExtended$ = this.store.pipe(select(SidenavUtilitiesStore.getIsSidenavExtended));
  public baseHubPath = this.envService.getEnv().HUB_AJS_URL;
  public splitIOSdkClientSub: Subscription;
  public hubToken$ = this.store.pipe(select(HubTokenSelectors.selectHubToken))
  public parent$ = this.store.pipe(select(NavMenusStore.getParentLink));
  public hubAjsMigrationEnabled$: Observable<boolean> = this.store.pipe(select(getIsSplitIoFlagOn(treatmentTokens.hubajs_migration)));

  private featureFlags: IFeatureFlags;
  private migratedSettingsEnabled: boolean;

  constructor(
    private store: Store<unknown>,
    public fullStoryService: FullStoryService,
    public gainsightService: GainsightService,
    public envService: EnvService,
    public cookieService: TsCookieService,
    public router: Router,
    public windowService: TsWindowService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[],
    public readonly greenfieldBridge: GreenfieldBridge
  ) {
    /* istanbul ignore next */
    if (this.envService.getEnv().FULL_STORY_ENABLED) {
      this.fullStoryService.injectSnippet();
    }
    /* istanbul ignore next */
    if (this.envService.getEnv().GAINSIGHT_KEY) {
      this.gainsightService.injectSnippet(envService.getEnv().GAINSIGHT_KEY);
    }

    this.translateService.addLangs(LANGUAGES);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    this.getInitialData();
    this.showNotifications();
    this.hubAjsMigrationEnabled$.pipe(
        distinctUntilChanged()
      )
      .subscribe(enabled => {
        this.migratedSettingsEnabled = enabled;
    });
  }

  getInitialData(): void {
    this.store.pipe(
      select(selectIsUserProfileLoaded),
      filter((isLoaded: boolean) => isLoaded),
      take(1)
    ).subscribe(() => {
      this.store.dispatch(userActions.loadCustomerEntitlementAuthorize());
      this.store.dispatch(appSettingsActions.loadDcLastUpdatedDate());
      this.store.dispatch(orgConfigActions.LoadOrgConfig());
      this.store.dispatch(dateCohortsActions.loadDateCohorts());
      this.store.dispatch(orgConfigActions.LoadCrmUrl());
    });

    combineLatest([
      this.store.pipe(select(getOrgConfig)),
      this.store.pipe(select(selectIsUserProfileLoaded)),
    ]).pipe(
      filter(([config, isLoaded]: [IOrgConfig, boolean]) => !!config && isLoaded),
      take(1),
    ).subscribe(() => {
      this.store.dispatch(NavMenusStore.navMenusActions.Load());
    });
  }

  showNotifications(): void {
    this.store.pipe(select(NotificationMessagesStore.getLastMessage))
      .pipe(
        filter((message: IUserMessage) => !!message),
      )
      .subscribe((message: IUserMessage) => {
        this.snackBar.openFromComponent(NotificationComponent, {
          data: {
            message: message.message,
            type: message.type,
            icon: this.getNotificationIcon(message.type),
          }
        });
      });
  }

  onActivate() {
    this.windowService.nativeWindow.document.body.scrollTop = 0;
  }


  switchUser(username: string): void {
    this.store.dispatch(userActions.switchUser({ user: username }));
  }

  navigate(route: GoParams): void {
    const routeItem = findRouteItem(route.routeId, this.migratedSettingsEnabled, this.applicationRoutes);
    if (routeItem) {
      // set the correct name instead of a custom one
      this.store.dispatch(NavMenusStore.navMenusActions.SetSelectedRouteItem({payload: routeItem}));
    }

    this.store.dispatch(NavigateTo(route));
  }

  getUrl(url: string, hasUnifiedLogin: boolean): string {
    if (!hasUnifiedLogin) {
      return url;
    }

    if (/brightfunnel.com/.exec(url)) {
      const isNinja = /.ninja|localhost/.exec(window.location.host);
      const suffix = isNinja ? '.ninja' : '.com';
      const indexOfPath = url.indexOf('/', 9);
      const search = indexOfPath > 0 ? url.substring(indexOfPath) : '/';
      return `https://studio.terminusplatform${suffix}${search}`;
    } else {
      return url;
    }
  }

  private getNotificationIcon(messageType: NotificationTypes): NotificationIcons {
    switch (messageType) {
      case NotificationTypes.Error:
        return NotificationIcons.Exclamation;

      case NotificationTypes.Success:
        return NotificationIcons.Check;

      default:
        return;
    }
  }
}
