import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WebActivitiesComponent } from './containers/web-activities.component';

const routes: Routes = [{
  path: '',
  component: WebActivitiesComponent,
  children: [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'channel-group',
  }, {
    path: 'channel-medium',
    loadChildren: () => import ('./channel-medium/channel-medium.module').then(m => m.ChannelMediumModule),
  }, {
    path: 'channel-group',
    loadChildren: () => import ('./channel-group/channel-group.module').then(m => m.ChannelGroupModule),
  }, {
    path: 'channel-name',
    loadChildren: () => import ('./channel-name/channel-name.module').then(m => m.ChannelNameModule),
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebActivitiesRoutingModule {
}
