import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TsPaginatorModule } from '@terminus-lib/ui-paginator';
import { TranslateModule } from '@ngx-translate/core';

import { PaginationComponent } from './pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [
    CommonModule,
    TsPaginatorModule,
    TranslateModule,
  ],
})
export class PaginationModule {
}
