import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TsRadioChange } from '@terminus-lib/ui-radio-group';

import { ITilesByCategory } from '@shared/interfaces';
import { TileCategories, TileTypes } from '@shared/enums';
import { LEARN_MORE_DIGITAL_ATTRIBUTION, LEARN_MORE_REVENUE_ATTRIBUTION } from '@shared/constants';

@Component({
  selector: 'tsh-select-tile-type',
  templateUrl: './select-tile-type.component.html',
  styleUrls: ['./select-tile-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTileTypeComponent {
  @Input() public selectedType: TileTypes;

  @Input()
  public set tiles(data: ITilesByCategory[]) {
    this._tiles = data || [];
    // get disabled categories to show info message
    if (data) {
      this.disabledCategories = data.reduce((acc: { [key in TileCategories]?: boolean }, item: ITilesByCategory) => {
        acc[item.category] = item.tiles.every(tile => tile.disabled);
        return acc;
      }, {});
    }
  }

  public get tiles(): ITilesByCategory[] {
    return this._tiles;
  }

  @Output() public changeType = new EventEmitter<TileTypes>();

  public readonly categoriesInfo: Record<TileCategories, { name: string, link?: string }> = {
    [TileCategories.Standard]: {
      name: 'shared.dashboards.dialog.categories.standard',
    },
    [TileCategories.RevenueAttribution]: {
      name: 'shared.dashboards.dialog.categories.revenueAttribution',
      link: LEARN_MORE_REVENUE_ATTRIBUTION
    },
    [TileCategories.DigitalAttribution]: {
      name: 'shared.dashboards.dialog.categories.digitalAttribution',
      link: LEARN_MORE_DIGITAL_ATTRIBUTION
    },
  };
  public disabledCategories: { [key in TileCategories]?: boolean } = {};
  private _tiles: ITilesByCategory[];

  change(event: TsRadioChange): void {
    this.changeType.emit(event.value);
  }
}
