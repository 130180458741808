import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsInputModule } from '@terminus-lib/ui-input';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { GlobalSearchComponent } from './containers/global-search.component';
import { SearchListComponent } from './components/search-list/search-list.component';
import { GlobalSearchEffects } from './state/global-search.effects';
import * as globalSearchReducer from './state/global-search.reducer';
import { GlobalSearchSource } from './sources/global-search.source';
import { GlobalSearchService } from './services/global-search.service';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { ViewSelectorComponent } from './components/view-selector/view-selector.component';

@NgModule({
  declarations: [
    GlobalSearchComponent,
    SearchListComponent,
    ViewSelectorComponent,
  ],
  exports: [
    GlobalSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TsInputModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(globalSearchReducer.globalSearchFeatureKey, globalSearchReducer.reducer),
    EffectsModule.forFeature([GlobalSearchEffects]),
    ReportFilterSelectorModule,
  ],
  providers: [
    GlobalSearchSource,
    GlobalSearchService
  ]
})
export class GlobalSearchModule {}
