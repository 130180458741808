import { ParametersType } from "@ngrx/store/src/models";
import { BehaviorSubject, combineLatest, isObservable, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Observable with a projector function attached for unit tests
 */
export interface ProjectedObservable<Props extends Array<unknown>, Out> extends Observable<Out> {
  projector: (...args: Props)=> Out
}

/* Types used to unravel Observables and Subjects to their underlying types */
type Unwrap<T> = T extends Observable<infer N> ? N : T;
type UnwrapTuple<T> = { [K in keyof T]: Unwrap<T[K]> };

/**
 * Symbol used to get prop name from propsNameExtractor proxy
 */
const propNameSym = Symbol();

/**
 * Proxy used to inject in place of the Component
 * context and extract the property names we
 * are observing.
 */
const propsNameExtractor = (target) => 
  new Proxy(target, {
    get: (t, propName) => {
      const value = 'object' === typeof t[propName] ? t[propName] : {};
      Object.defineProperty(value, propNameSym, {value: propName});
      return value;
    },
  })


/**
 * Decorator to enhance a component's @Input() property with Observability.
 * To be used in cooperation with observeProps()
 * 
 * Note: This decorator is only needed for props with get/set accessors. 
 * otherwise, observeProps() decorates the props as needed.
 */
export function ObservableProp(defaultValue = undefined) {
  return function(target: unknown, propertyKey: string, descriptor?: PropertyDescriptor): void { 
    // backing subject to save the prop value
    const valueSubject = new BehaviorSubject(defaultValue);

    // define a specially-named prop to provide
    // the backing subject's value as an observable.
    Object.defineProperty(target, `Ö${propertyKey}$`, {
      get: function() { return valueSubject.asObservable() },
    }); 

    if(descriptor?.set) {
      const customSetter = descriptor.set;
      const customGetter = descriptor.get;
      descriptor.set = function (newVal: unknown){
        customSetter.call(this, newVal);
        valueSubject.next(customGetter ? customGetter.call(this) : newVal);
      }
    } else {
      // define setter/getter methods to replace decorated prop
      Object.defineProperty(target, propertyKey, {
        get: function() { return valueSubject.getValue() },
        set: function(newVal: unknown) { valueSubject.next(newVal); }
      }); 
    }
  }
}

/**
 * Observe prop changes, wrapping non-observables with observables, 
 * and map computed properties
 * 
 * @param target 
 * @param gatherProps 
 * @param mapFunction 
 */
export function observeProps<Ctx, Out, Props extends Array<unknown>>(
  target: Ctx, 
  gatherProps: (c: Ctx) => [...Props],
  mapFunction: (...args: UnwrapTuple<ReturnType<typeof gatherProps>>) => Out
): ProjectedObservable<ParametersType<typeof mapFunction>, Out>;
// TODO: The below doesn't work ... but somehow allowing gatherProps: (c) => c.prop
// in the type overrides would be great. It's supported in the implmentation.
// type NotArray<T> = T extends Array<unknown> ? never : T;
// export function observeProps2<Ctx, Out, Prop extends NotArray<unknown>> (
//   target: Ctx, 
//   gatherProps: (c: Ctx) => Prop,
//   mapFunction: (p: Unwrap<Prop>) => Out
// ): ProjectedObservable<Out>;
export function observeProps( target, gatherProps, mapFunction) {
  const _gatherProps = gatherProps(propsNameExtractor(target));
  const observeProps = (Array.isArray(_gatherProps) ? _gatherProps : [_gatherProps]);
  // TODO: blow up if observeProps.length !== number of times propsNameExtractor set() was called. That means
  // someone is trying to map in something that isn't pulled from the component context - and it won't work

  if('object' !== typeof target) throw Error('First parameter of observeProps should be `this`')
  if('function' !== typeof mapFunction) throw Error('Must provide a mapFunction as the last argument to ObserveInputs')
  if(observeProps.length === 0) throw Error('Must provide at least one input property to observe')
  if(mapFunction.length > observeProps.length) throw Error(`ObserveProps mapFunction params(${mapFunction.length}) and observed props list(${observeProps.length}) lengths must match`)

  // project list of observables.
  const observables = observeProps.map( propVal => {

    const propName = propVal[propNameSym]
    if( propName ) {
      // is it an observable?
      const prop = target[propName];
      if(isObservable(prop)) return prop;

      // does it already have a backing subject?
      const backingObservableName = `Ö${propName}$`;
      if(target[backingObservableName]) return target[backingObservableName];

      // create and wire-up a backing subject
      try {
        const descriptor = Object.getOwnPropertyDescriptor(target, propName);
        if(!descriptor) throw new Error(`Unable to auto-decorate prop [${propName}]. If it has set/get accessors, decorate it with @ObservableProp()`);
        ObservableProp(prop)(target, propName, descriptor)
      } catch( err ) {
        throw Error(`Failed to create an observable for property [${propName}]`);
      }
      if(target[backingObservableName]) return target[backingObservableName];

      throw Error(`Failed to find an observable for property [${propName}]`);
    }
    // otherwise, we drilled through to an external object
    // let's look for an observable or subject there.

    // is it an external direct reference to an observable?
    if(isObservable(propVal)) return propVal;

    throw Error(`Failed to find an observable for selection`);

  })

  const observable = combineLatest(observables).pipe(map( (vals) => mapFunction(...vals) ));
  (observable as ProjectedObservable<unknown[], unknown>).projector = mapFunction;
  return observable;
}
