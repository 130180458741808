import { Action, createReducer, on } from '@ngrx/store';

import { IWebActivitiesTypes, IWfStage } from '../interfaces';
import { catalogsActions } from './catalogs.actions';

export const catalogsFeatureKey = 'catalogs';

export interface CatalogsState {
  webActivitiesTypes: IWebActivitiesTypes;
  stages: IWfStage[] | null;
  stagesLoaded: boolean;
}

export const initialState: CatalogsState = {
  webActivitiesTypes: null,
  stages: null,
  stagesLoaded: false,
};

const catalogsReducer = createReducer(
  initialState,
  on(catalogsActions.loadWebActivitiesTypesSuccess, (state, action) => ({
    ...state,
    webActivitiesTypes: action.data,
  })),
  on(catalogsActions.loadWfStagesSuccess, (state, action) => ({
    ...state,
    stages: action.data,
    stagesLoaded: true
  })),
  on(catalogsActions.loadWfStagesFailure, (state) => ({
    ...state,
    stagesLoaded: false
  })),
);

export function reducer(state: CatalogsState | undefined, action: Action) {
  return catalogsReducer(state, action);
}
