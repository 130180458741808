import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TsExpansionPanelModule } from '@terminus-lib/ui-expansion-panel';
import { TsIconModule } from '@terminus-lib/ui-icon';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TsInputModule } from '@terminus-lib/ui-input';
import { TsIconButtonModule } from '@terminus-lib/ui-icon-button';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { TsSelectionListModule } from '@terminus-lib/ui-selection-list';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SavedReportsComponent } from './containers/saved-reports/saved-reports.component';
import { ReportDialogComponent } from './components/report-dialog/report-dialog.component';
import { FolderDialogComponent } from './components/folder-dialog/folder-dialog.component';
import { SavedReportsListComponent } from './components/saved-reports-list/saved-reports-list.component';
import { SavedReportsEffects } from './state/saved-reports.effects';
import * as SavedReportReducer from './state/saved-reports.reducer';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TsExpansionPanelModule,
    TsIconModule,
    TsIconButtonModule,
    TsButtonModule,
    MatDialogModule,
    TsRadioGroupModule,
    TsInputModule,
    TsOptionModule,
    TsSelectionListModule,
    RouterModule,
    TranslateModule,
    StoreModule.forFeature(SavedReportReducer.savedReportsFeatureKey, SavedReportReducer.reducer),
    EffectsModule.forFeature([SavedReportsEffects]),
  ],
  declarations: [
    SavedReportsComponent,
    ReportDialogComponent,
    FolderDialogComponent,
    SavedReportsListComponent
  ],
  exports: [
    SavedReportsComponent,
    ReportDialogComponent,
    FolderDialogComponent,
    SavedReportsListComponent
  ],
  entryComponents: [
    ReportDialogComponent,
    FolderDialogComponent
  ]
})
export class SavedReportsModule {
}
