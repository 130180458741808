<div
  class="c-chip"
  [ngClass]="{
    'c-chip--disabled': isDisabled,
    'c-chip--removable': isRemovable && chipCollectionRemovable
  }"
  [attr.id]="id"
  (click)="click($event)"
>
  <span #content>
    <ng-content></ng-content>
  </span>

  <ng-container *ngTemplateOutlet="removeIcon"></ng-container>
</div>

<ng-template #removeIcon>
  <span class="c-chip__remove" (click)="removeChip($event)">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
      ></path>
    </svg>
  </span>
</ng-template>
