export enum LocalStorageColumnsKey {
  Campaign = 'campaign_columns',
  Campaigns = 'campaigns_columns',
  CampaignGroups = 'campaign_groups_columns',
  Source = 'source_columns',
  Mediums = 'medium_columns',
  AccountHub = 'account_hub_columns',
  Opportunities = 'opportunities_columns',
  MarketingInfluence = 'marketing_influence_columns',
}
