import { TsSortState } from '@terminus-lib/ui-sort';
import { IMarketingInfluenceReport, IMarketingInfluenceRequest, MarketingInfluenceUsedParams } from '../interfaces';
import { marketingInfluenceActions } from './marketing-influence.actions';
import { IDateCohort, IReportColumn } from '@shared/interfaces';
import { SortDirections } from '@shared/enums';
import { updateColumnsVisibility } from '@util/store';
import { Action, createReducer, on } from '@ngrx/store';

export const MARKETING_INFLUENCE_KEY = 'marketingInfluence';

// Get default date cohort
const date = new Date();
const twoYearAgo = date.setFullYear(date.getFullYear() - 2);
const defaultDateCohort = {
  cohort: 'years2RoundDown',
  name: 'two years ago',
  startDate: twoYearAgo,
  endDate: new Date().getTime(),
};

export interface MarketingInfluenceState {
  reportData: IMarketingInfluenceReport[];
  isLoading: boolean;
  filters: IMarketingInfluenceRequest;
  isDownloadingCsv: boolean;
  tableSortState: TsSortState;
  page: number;
  selectedDateCohort: IDateCohort;
  usedQueryParams: MarketingInfluenceUsedParams;
  columns: IReportColumn[];
}

export const initialState: MarketingInfluenceState = {
  reportData: null,
  isLoading: false,
  filters: null,
  isDownloadingCsv: false,
  tableSortState: {
    active: '',
    direction: SortDirections.Asc,
  },
  page: 1,
  selectedDateCohort: defaultDateCohort,
  usedQueryParams: null,
  columns: [],
};

const marketingInfluenceReducer = createReducer(
  initialState,
  on(
    marketingInfluenceActions.toggleColumnVisibility,
    (state, action) => ({
      ...state,
      columns: updateColumnsVisibility(state.columns, action.column)
    })
  ),
  on(
    marketingInfluenceActions.setColumns,
    (state, action) => ({
      ...state,
      columns: action.columns,
    })
  ),
  on(
    marketingInfluenceActions.getMarketingInfluenceReportData,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    marketingInfluenceActions.getMarketingInfluenceReportDataSuccess,
    (state, action) => ({
      ...state,
      reportData: action.data,
      isLoading: false,
    })
  ),
  on(
    marketingInfluenceActions.setUsedQueryParams,
    (state, action) => ({
      ...state,
      usedQueryParams: action.params,
    })
  ),
  on(
    marketingInfluenceActions.getMarketingInfluenceReportDataFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    marketingInfluenceActions.downloadCsv,
    (state) => ({
      ...state,
      isDownloadingCsv: true,
    })
  ),
  on(
    marketingInfluenceActions.downloadCsvFailure,
    marketingInfluenceActions.downloadCsvSuccess,
    (state) => ({
      ...state,
      isDownloadingCsv: false,
    })
  ),
  on(
    marketingInfluenceActions.setMarketingTableSortState,
    (state, action) => ({
      ...state,
      tableSortState: action.sortState
    })
  ),
  on(
    marketingInfluenceActions.setMarketingTablePage,
    (state, action) => ({
      ...state,
      page: action.page
    })
  ),
  on(
    marketingInfluenceActions.setMarketingInfluenceFilter,
    (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.filters }
    })
  ),
  on(
    marketingInfluenceActions.setSelectedDateCohort,
    (state, action) => ({
      ...state,
      selectedDateCohort: action.cohort
    })
  )
);

export function reducer(state: MarketingInfluenceState, action: Action): MarketingInfluenceState {
  return marketingInfluenceReducer(state, action);
}
