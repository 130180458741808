import { IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';

export enum TileModalDashboardsColumnKeys {
  RadioButton = 'radioButton',
  Name = 'name',
  Tiles = 'tiles',
  IsPublic = 'isPublic',
}

export const TILE_MODAL_DASHBOARDS_COLUMNS_MAP: Map<TileModalDashboardsColumnKeys, IReportColumn> = new Map()
  .set(TileModalDashboardsColumnKeys.RadioButton, {
    name: TileModalDashboardsColumnKeys.RadioButton as string,
    width: 36,
    displayName: '',
    dataType: DataTypeEnum.Text,
  })
  .set(TileModalDashboardsColumnKeys.Name, {
    name: TileModalDashboardsColumnKeys.Name as string,
    width: 200,
    displayName: 'shared.dashboards.columns.name',
    dataType: DataTypeEnum.Text,
  })
  .set(TileModalDashboardsColumnKeys.Tiles, {
    name: TileModalDashboardsColumnKeys.Tiles as string,
    width: 200,
    displayName: 'shared.dashboards.columns.tiles',
    dataType: DataTypeEnum.Text,
  })
  .set(TileModalDashboardsColumnKeys.IsPublic, {
    name: TileModalDashboardsColumnKeys.IsPublic as string,
    width: 130,
    displayName: 'shared.dashboards.columns.isPublic',
    dataType: DataTypeEnum.Text,
  });

export const TILE_MODAL_DASHBOARDS_COLUMNS: IReportColumn[] = Array.from(TILE_MODAL_DASHBOARDS_COLUMNS_MAP.values());
