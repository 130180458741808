import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { iif, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebActivitiesService } from '../../services/web-activities.service';
import { campaignGroupsActions } from '../campaign-groups/campaign-groups.actions';
import * as interfaces from '../../interfaces';
import * as selectors from './source.selectors';
import * as generalSelectors from '../general/general.selectors';
import { sourceActions, SourceLoadActions } from './source.actions';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { AnalyticsHelperService } from '../../helpers/analytics-helper.service';
import { checkDataByColumnName, getDriverRequestParams, getSecondaryRequestParams } from '../../helpers/store.helper';
import { IAnalyticsGlobalFilters, IPager, ISorter, userMessageFactory } from '@shared/interfaces';
import { AnalyticsColumnName } from '../../enums/analytics-column-name.enum';
import { notificationMessagesActions } from '@notification-messages';
import * as analyticsSelectors from '../selectors';
import { generalActions } from '../general/general.actions';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { TypedAction } from '@ngrx/store/src/models';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  ISorter,
  IPager,
  string[],
  string,
];

type LoadRequestParams<A extends Action> = [
  A,
  IAnalyticsRequest,
  (TypedAction<string>)[]?,
];


@Injectable()
export class SourceEffects {
  constructor(
    public webActivitiesService: WebActivitiesService,
    private analyticsHelperService: AnalyticsHelperService,
    private store: Store<unknown>,
    private actions$: Actions
  ) {
  }

  public loadMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadMetaData),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceMetaData$(requestParams).pipe(
        mergeMap((data: interfaces.IMetaData<interfaces.IWebActivitiesGroupResponse>) => [
          sourceActions.loadMetaDataSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceMetaData';
          return of(sourceActions.loadMetaDataFailure({message}));
        })
      );
    })
  ));

  public loadResponses$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadResponses),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceResponses$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignResponses) => [
          sourceActions.loadResponsesSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceResponses';
          return of(sourceActions.loadResponsesFailure({message}));
        })
      );
    })
  ));

  public loadAttribution$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadAttribution),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceAttribution$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignAttribution) => [
          sourceActions.loadAttributionSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceAttribution';
          return of(sourceActions.loadAttributionFailure({message}));
        })
      );
    })
  ));

  public loadInfluence$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadInfluence),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceInfluence$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignInfluence) => [
          sourceActions.loadInfluenceSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceInfluence';
          return of(sourceActions.loadInfluenceFailure({message}));
        })
      );
    })
  ));

  public loadReturns$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadReturns),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceReturns$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignReturns) => [
          sourceActions.loadReturnsSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceReturns';
          return of(sourceActions.loadReturnsFailure({message}));
        })
      );
    })
  ));

  public loadCosts$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.loadCosts),
    // use concatMap to wait until dispatching this action
    concatMap((action) => iif(() => !!action.parentId, this.withLatestNestedFilters(action), this.withLatestFilters(action))),
    map(this.mapToRequestAndUsedParamsAction.bind(this)),
    mergeMap(([action, requestParams, usedParamsAction]: LoadRequestParams<SourceLoadActions>) => {
      return this.webActivitiesService.getSourceCosts$(requestParams).pipe(
        mergeMap((data: interfaces.ICampaignCosts) => [
          sourceActions.loadCostsSuccess({...action, data}),
          ...usedParamsAction,
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadSourceCosts';
          return of(sourceActions.loadCostsFailure({message}));
        })
      );
    })
  ));

  public getSources$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.getSources),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    concatMap(([_, filters, globalFilters, sorter, pager, recordIds, search]: LatestFilters<Action>) => {
      const requestParams = this.getRequestParams({isDriver: true}, filters, globalFilters, sorter, pager, search, recordIds);
      return of(requestParams).pipe(
        withLatestFrom(
          this.store.pipe(select(selectors.getSourceUsedParams))
        )
      );
    }),
    filter(([currentFilters, prevFilters]: IAnalyticsRequest[]) => {
      return this.analyticsHelperService.areFiltersChanged(currentFilters, prevFilters);
    }),
    map(([currentFilters]: IAnalyticsRequest[]) =>
      this.analyticsHelperService.getSourceLoadAction(currentFilters.sorter.sortField, {isDriver: true})
    )
  ));

  public onChangeSourceConfig$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.changeSourceSearch,
      generalActions.changeSourcePagination,
      generalActions.changeSourceSorting,
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(generalSelectors.getSourceSorter)),
    ))),
    mergeMap(([_, sorter]) => [
      this.analyticsHelperService.getSourceLoadAction(sorter.sortField, {isDriver: true}),
      sourceActions.closeNestedTables()
    ])
  ));

  public onChangeNestedSortingOrPagination$ = createEffect(() => this.actions$.pipe(
    ofType(sourceActions.changeNestedPagination, sourceActions.changeNestedSorting),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(selectors.getNestedSorter, action.data.id)),
    ))),
    map(([action, sorter]) =>
      this.analyticsHelperService.getSourceLoadAction(sorter.sortField, {isDriver: true, parentId: action.data.id}))
  ));

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.toggleSourceColumnVisibility),
    concatMap(action => of(action).pipe(withLatestFrom(
      iif(
        () => !!action.parentId,
        this.store.pipe(select(selectors.getNestedDataById, action.parentId)),
        this.store.pipe(select(selectors.getReportData)),
      ).pipe(
        map((data: interfaces.ICampaignIdentifiable[]) => checkDataByColumnName(data, action.data.name)),
      )
    ))),
    // do not dispatch load action if data is present in state
    filter(([_, data]) => !data.length),
    concatMap(([action]) => of(action).pipe(withLatestFrom(
      this.store.pipe(select(analyticsSelectors.getCampaignGroupsExpandedRowIds))
    ))),
    // dispatch actions to load extra data
    mergeMap(([action, parentIds]) => {
      const parentId = action.parentId;
      const columnName = action.data.name;
      if (parentIds.length && parentId) {
        // dispatch action for each visible nested table
        return parentIds.map(id => this.analyticsHelperService.getSourceLoadAction(columnName, {isDriver: false, parentId: id}));
      }
      return [this.analyticsHelperService.getSourceLoadAction(columnName, {isDriver: false, parentId})];
    })
  ));

  public onLoadDriverSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      sourceActions.loadAttributionSuccess,
      sourceActions.loadCostsSuccess,
      sourceActions.loadInfluenceSuccess,
      sourceActions.loadMetaDataSuccess,
      sourceActions.loadResponsesSuccess,
      sourceActions.loadReturnsSuccess,
    ),
    filter((action) => action.isDriver && !!action.data.totalResults),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(action.parentId
        ? generalSelectors.getVisibleSourceNestedColumnNames
        : generalSelectors.getVisibleSourceColumnNames
      ))
    ))),
    mergeMap(([action, columnNames]) => {
      const secondaryRequests = this.analyticsHelperService.getSourceSecondaryLoadActions(action, columnNames);
      // return secondaryRequests if they exists, otherwise make totals requests
      return secondaryRequests.length
        ? secondaryRequests
        : this.analyticsHelperService.getSourceTotalsLoadActions(columnNames, action.parentId || 'source');
    }),
  ));

  public onLoadSecondarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      sourceActions.loadAttributionSuccess,
      sourceActions.loadCostsSuccess,
      sourceActions.loadInfluenceSuccess,
      sourceActions.loadMetaDataSuccess,
      sourceActions.loadResponsesSuccess,
      sourceActions.loadReturnsSuccess
    ),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(analyticsSelectors.getUnloadedTotalsColumnNames(action.parentId
        ? generalSelectors.getVisibleSourceNestedColumns
        : generalSelectors.getVisibleSourceColumns), {key: 'source'})),
      this.store.pipe(select(selectors.getSourceIsLoading)),
    ))),
    filter(([action, _, isLoading]) => !action.isDriver && !isLoading),
    switchMap(([action, columnNames]) =>
      this.analyticsHelperService.getSourceTotalsLoadActions(columnNames, action.parentId || 'source')
    ),
  ));

  public onToggleTableRow$ = createEffect(() => this.actions$.pipe(
    ofType(campaignGroupsActions.toggleTableRow),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getNestedSource))),
    )),
    filter(([action, state]) => !state.data[action.id]),
    map(([action, state]) => {
      const sortField = state.sorter[action.id]?.sortField || AnalyticsColumnName.Responses;
      return this.analyticsHelperService.getSourceLoadAction(sortField, {isDriver: true, parentId: action.id});
    })
  ));

  public onChangeFiltersState$ = createEffect(() => this.actions$.pipe(
    ofType(generalActions.setFilters),
    mergeMap(() => [
      sourceActions.clearNestedState(),
      sourceActions.closeNestedTables(),
    ]),
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      sourceActions.loadMetaDataFailure,
      sourceActions.loadResponsesFailure,
      sourceActions.loadAttributionFailure,
      sourceActions.loadInfluenceFailure,
      sourceActions.loadReturnsFailure,
      sourceActions.loadCostsFailure,
    ),
    map((action) => notificationMessagesActions.addMessage({message: userMessageFactory({n: action.message})}))
  ));

  private withLatestFilters<T extends SourceLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(generalSelectors.getSourceSorter)),
        this.store.pipe(select(generalSelectors.getSourcePager)),
        this.store.pipe(select(selectors.getSourceRecordIds)),
        this.store.pipe(select(generalSelectors.getSourceSearch)),
      ),
    );
  }

  private withLatestNestedFilters<T extends SourceLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.store.pipe(select(selectors.getNestedSorter, action.parentId)),
        this.store.pipe(select(selectors.getNestedPager, action.parentId)),
        this.store.pipe(select(selectors.getNestedRecordIds, action.parentId)),
        of(''), // mock empty search for nested table
      ),
    );
  }

  private mapToRequestAndUsedParamsAction<T extends SourceLoadActions>(
    [action, filters, globalFilters, sorter, pager, recordIds, searchParam]: LatestFilters<T>
  ): LoadRequestParams<T> {
    const requestParams = this.getRequestParams(action, filters, globalFilters, sorter, pager, searchParam, recordIds);
    const usedParamsAction = action.isDriver ? [sourceActions.setUsedParams({request: requestParams})] : [];
    return [action, requestParams, usedParamsAction];
  }

  private getRequestParams(payload: interfaces.ILoadPayload,
                           filters: IAnalyticsRequest,
                           globalFilters: IAnalyticsGlobalFilters,
                           sorter: ISorter,
                           pager: IPager,
                           searchParam: string,
                           recordIds: string[]): IAnalyticsRequest {
    const requestParams = payload.isDriver
      ? getDriverRequestParams(filters, sorter, pager, searchParam)
      : getSecondaryRequestParams(filters, recordIds);
    requestParams.globalFilters = globalFilters || [];
    if (payload.parentId) {
      requestParams.crossTypeFilter = payload.parentId;
      delete requestParams.typeFilter;
    }
    return requestParams;
  }
}
