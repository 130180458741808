import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';

import {
  ICampaignCohortsForOrgs,
  ICampaignGroupInfo,
  ICampaignIsOrphan,
  ICampaignIsOrphanParams,
  ICampaignTouchesResponse,
  ICohortForOrgsParams
} from '../interfaces';
import { getHttpParams } from '@util/http';
import { RouteItemEnum } from '@shared/enums';
import { ICampaignOrChannelAttribution, ICampaignSpecificFilters } from '@measurement-studio/interfaces';

export enum CampaignSpecificURLs {
  CampaignAttribution = '/api/v1/campaign_attribution',
  CampaignTouches = '/api/v1/channel_touches',
  CampaignIsOrphanLead = '/api/v1/is_orphan_lead',
  CampaignGroupInfo = '/api/v1/campaign_group_info',
  CampaignCohortsForOrg = '/api/v1/cohorts_for_org',
}

export enum WebActivitySpecificURLs {
  CampaignAttribution = '/api/v1/channel_attribution',
  CampaignTouches = '/api/v1/campaign_touches',
}

@Injectable({providedIn: 'root'})
export class CampaignSpecificSource extends HttpClientBase {

  getCampaignOrChannelAttribution$(params: ICampaignSpecificFilters,
                                   selectedReport: RouteItemEnum): Observable<ICampaignOrChannelAttribution> {
    // this api call is used by Campaign Specific and Stage Progression reports
    const endpoint = selectedReport === RouteItemEnum.CampaignSpecificStages
    || selectedReport === RouteItemEnum.CampaignSpecific
    || selectedReport === RouteItemEnum.CampaignSpecificValues // to support legacy
      ? this.endpoint(CampaignSpecificURLs.CampaignAttribution)
      : this.endpoint(WebActivitySpecificURLs.CampaignAttribution);

    return this.httpClient.get<ICampaignOrChannelAttribution>(endpoint, {
      params: getHttpParams({...params})
    });
  }

  getCampaignTouches$(params: ICampaignSpecificFilters, selectedReport: RouteItemEnum): Observable<ICampaignTouchesResponse> {
    const endpoint = selectedReport === RouteItemEnum.CampaignSpecificStages
    || selectedReport === RouteItemEnum.CampaignSpecific
      ? this.endpoint(CampaignSpecificURLs.CampaignTouches)
      : this.endpoint(WebActivitySpecificURLs.CampaignTouches);

    return this.httpClient.get<ICampaignTouchesResponse>(endpoint, {
      params: getHttpParams({...params})
    });
  }

  getCampaignIsOrphan$(params: ICampaignIsOrphanParams): Observable<ICampaignIsOrphan> {
    const endpoint = this.endpoint(CampaignSpecificURLs.CampaignIsOrphanLead);

    return this.httpClient.get<ICampaignIsOrphan>(endpoint, {
      params: getHttpParams({...params})
    });
  }

  getCampaignGroupInfo$(params: ICampaignSpecificFilters): Observable<ICampaignGroupInfo> {
    const endpoint = this.endpoint(CampaignSpecificURLs.CampaignGroupInfo);

    return this.httpClient.get<ICampaignGroupInfo>(endpoint, {
      params: getHttpParams({...params})
    });
  }

  getCampaignCohortsForOrgs$(params: ICohortForOrgsParams): Observable<ICampaignCohortsForOrgs> {
    const endpoint = this.endpoint(CampaignSpecificURLs.CampaignCohortsForOrg);

    return this.httpClient.get<ICampaignCohortsForOrgs>(endpoint, {
      params: getHttpParams({...params})
    });
  }
}
