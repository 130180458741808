import { Inject, Injectable } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SPLITIO_STATUS } from '../constants';
import { ENVIRONMENT } from '@shared/environment';

export interface SplitIoInitialize {
  trafficType: string;
  key: string;
}

@Injectable({ providedIn: 'root' })
export class SplitIoService {
  public readonly clients = new Map<string, any>();

  public readonly clientsReady$ = new BehaviorSubject<{ [key: string]: boolean }>({});

  constructor(@Inject(ENVIRONMENT) private env: { SPLITIO_API_KEY: string }) {
    if (!this.env.SPLITIO_API_KEY) {
      throw new Error(`Env SPLITIO_API_KEY is not set.`);
    }
  }

  public initialize(args: SplitIoInitialize, shouldOverride: boolean = false): void {
    if (shouldOverride || (args.trafficType && !this.clients.has(args.trafficType))) {
      const client = SplitFactory({
        core: {
          authorizationKey: this.env.SPLITIO_API_KEY || '',
          key: args.key,
          trafficType: args.trafficType,
        },
      }).client();

      this.clients.set(args.trafficType, client);

      client.on(client.Event.SDK_READY, () => {
        this.clientsReady$.next({
          ...this.clientsReady$.value,
          [args.trafficType]: true,
        });
      });
    }
  }

  public getTreatment(
    trafficType: string,
    treatment: string,
    attributes?: Record<string, any>,
  ): Observable<SPLITIO_STATUS> {
    return this.clientsReady$.pipe(
      map(clientsReady => clientsReady[trafficType]),
      filter(ready => ready),
      map(() => this.clients.get(trafficType).getTreatment(treatment, attributes)),
    );
  }
}
