export const INSIGHTS_SECONDARY_LINE_SERIES_COLOR = '#C4CBCF';

export const PAGE_VIEWS_INFLUENCED_COLOR = '#4F5A9F';

export const LEADS_INFLUENCED_COLOR = '#EB5488';

export const CAMPAIGN_RESPONSES_INFLUENCED_COLOR = '#FFA600';

export const NEW_PIPELINE_INFLUENCED_COLOR = '#63AA4E';

export const PIPELINE_INFLUENCED_COLOR = '#008989';

export const REVENUE_INFLUENCED_COLOR = '#8A59A7';
