import { createAction, props } from '@ngrx/store';
import { UserDefinedSegment } from '@shared/data-access/segments-api';

import { IDataFilter, IFilter, IGlobalFilterFolder, IGlobalFilters, ITargetFolderItem } from '@shared/interfaces';
import { defineTypeEnum } from '@terminus-lib/fe-utilities';

export enum GlobalFiltersActionTypes {
  APPLY_SELECTED_FILTERS = '[Global Filters] Apply Untracked Filters',
  CLEAR_APPLIED_AND_SELECTED_DATA_FILTER = '[Global Filters] Clear Selected Data Filter',
  FILTER_CHANGED = '[Global Filters] Filter Changed',
  FILTER_GLOBAL_FILTERS = '[Global Filters] Filter Global Filter Menu',
  GET_GLOBAL_FILTERS_DATA = '[Global Filters] Get Global Filters Data',
  GET_GLOBAL_FILTERS_DATA_FAILURE = '[Global Filters] Get Global Filters Data Failure',
  GET_GLOBAL_FILTERS_DATA_SUCCESS = '[Global Filters] Get Global Filters Data Success',
  GET_SAVED_FILTERS_DATA = '[Global Filters] Get Saved Filters Data',
  GET_SAVED_FILTERS_DATA_FAILURE = '[Global Filters] Get Saved Filters Data Failure',
  GET_SAVED_FILTERS_DATA_SUCCESS = '[Global Filters] Get Saved Filters Data Success',
  SET_MENU_EXPANDED_STATE = '[Global Filters] Set Menu Expanded State',
  SUBMIT_SAVE_FILTERS = '[Global Filters] Submit Save Filters',
  SUBMIT_SAVE_FILTERS_FAILURE = '[Global Filters] Submit Save Filters Failure',
  SUBMIT_SAVE_FILTERS_SUCCESS = '[Global Filters] Submit Save Filters Success',
  SUBMIT_DELETE_SAVED_FILTERS = '[Global Filters] Delete Saved Filters',
  SUBMIT_DELETE_SAVED_FILTERS_SUCCESS = '[Global Filters] Delete Saved Filters Success',
  SUBMIT_DELETE_SAVED_FILTERS_FAILURE = '[Global Filters] Delete Saved Filters Failure',
  TOGGLE_FOLDER_SELECT = '[Global Filters] Toggle Folder Select',
  TOGGLE_FOLDER_ITEM_SELECT = '[Global Filters] Toggle Folder Item Select',
  TOGGLE_FOLDER_EXPANDED_MENU = '[Global Filters] Toggle Folder Expanded Menu',
  UPDATE_GLOBAL_FILTER_MENU_STATE = '[Global filters] Update Global Filter Ui State',
  UPDATE_APPLIED_DATA_FILTER = '[Global Filters] Update Applied Data Filter',
  UPDATE_APPLIED_DATA_FILTER_SUCCESS = '[Global Filters] Updated Data Filter',
  UPDATE_SELECTED_DATA_FILTER = '[Global Filters] Update Selected Data Filter',
  UPDATE_EXPANDED_FOLDER = '[Global Filters] Update Expanded Folder',
  SYNC_GLOBAL_FILTERS = '[Global Filters] Sync global filters on application initialization',
  UPDATE_GLOBAL_FILTERS_WITH_TILES_ONE = '[Global Filters] Reset current global filters and set the from tile settings',
  SEGMENTS_LOADED_SUCCESS = '[Global Filters] Segments loaded',
  SEGMENTS_SELECTION_CHANGE = '[Global Filters] Segment Selection',
}

defineTypeEnum(GlobalFiltersActionTypes);

const applySelectedFilters = createAction(GlobalFiltersActionTypes.APPLY_SELECTED_FILTERS);

const clearAppliedAndSelectedDataFilter = createAction(GlobalFiltersActionTypes.CLEAR_APPLIED_AND_SELECTED_DATA_FILTER);

const filterChanged = createAction(GlobalFiltersActionTypes.FILTER_CHANGED);

const filterGlobalFilters = createAction(GlobalFiltersActionTypes.FILTER_GLOBAL_FILTERS, props<{ data: string }>());

const getDataGlobalFilters = createAction(GlobalFiltersActionTypes.GET_GLOBAL_FILTERS_DATA);

const getDataGlobalFiltersFailure = createAction(
  GlobalFiltersActionTypes.GET_GLOBAL_FILTERS_DATA_FAILURE,
  props<{ message: string }>(),
);

const getDataGlobalFiltersSuccess = createAction(
  GlobalFiltersActionTypes.GET_GLOBAL_FILTERS_DATA_SUCCESS,
  props<{ filters: IGlobalFilters }>(),
);

const getSavedFiltersData = createAction(GlobalFiltersActionTypes.GET_SAVED_FILTERS_DATA);

const getSavedFiltersDataFailure = createAction(
  GlobalFiltersActionTypes.GET_SAVED_FILTERS_DATA_FAILURE,
  props<{ message: string }>(),
);

const getSavedFiltersDataSuccess = createAction(
  GlobalFiltersActionTypes.GET_SAVED_FILTERS_DATA_SUCCESS,
  props<{ filters: IDataFilter[] }>(),
);

const submitSaveFilters = createAction(GlobalFiltersActionTypes.SUBMIT_SAVE_FILTERS);

const submitSaveFiltersFailure = createAction(
  GlobalFiltersActionTypes.SUBMIT_SAVE_FILTERS_FAILURE,
  props<{ message: string }>(),
);

const submitSaveFiltersSuccess = createAction(GlobalFiltersActionTypes.SUBMIT_SAVE_FILTERS_SUCCESS);

const submitDeleteSavedFilters = createAction(GlobalFiltersActionTypes.SUBMIT_DELETE_SAVED_FILTERS);

const submitDeleteSavedFiltersFailure = createAction(
  GlobalFiltersActionTypes.SUBMIT_DELETE_SAVED_FILTERS_FAILURE,
  props<{ message: string }>(),
);

const submitDeleteSavedFiltersSuccess = createAction(GlobalFiltersActionTypes.SUBMIT_DELETE_SAVED_FILTERS_SUCCESS);

const toggleFolderSelect = createAction(
  GlobalFiltersActionTypes.TOGGLE_FOLDER_SELECT,
  props<{ folder: ITargetFolderItem }>(),
);

const toggleFolderItemSelect = createAction(
  GlobalFiltersActionTypes.TOGGLE_FOLDER_ITEM_SELECT,
  props<{ folder: ITargetFolderItem }>(),
);

const toggleFolderExpandedMenu = createAction(
  GlobalFiltersActionTypes.TOGGLE_FOLDER_EXPANDED_MENU,
  props<{ folder: ITargetFolderItem }>(),
);

const updateGlobalFilterMenuState = createAction(
  GlobalFiltersActionTypes.UPDATE_GLOBAL_FILTER_MENU_STATE,
  props<{ folder: IGlobalFilterFolder }>(),
);

const updateAppliedDataFilter = createAction(
  GlobalFiltersActionTypes.UPDATE_APPLIED_DATA_FILTER,
  props<{ filter: IDataFilter }>(),
);

const updateAppliedDataFilterSuccess = createAction(GlobalFiltersActionTypes.UPDATE_APPLIED_DATA_FILTER_SUCCESS);

const updateSelectedDataFilter = createAction(
  GlobalFiltersActionTypes.UPDATE_SELECTED_DATA_FILTER,
  props<{ filter: IDataFilter }>(),
);

const syncGlobalFilters = createAction(
  GlobalFiltersActionTypes.SYNC_GLOBAL_FILTERS,
  props<{ filters: IFilter; listIds: string[] }>(),
);

const updateGlobalFiltersWithTilesOne = createAction(
  GlobalFiltersActionTypes.UPDATE_GLOBAL_FILTERS_WITH_TILES_ONE,
  props<{ filter: IDataFilter }>(),
);

const segmentsLoadedSuccess = createAction(
  GlobalFiltersActionTypes.SEGMENTS_LOADED_SUCCESS,
  props<{ queries: UserDefinedSegment[] }>(),
);

export const globalFiltersActions = {
  applySelectedFilters,
  clearAppliedAndSelectedDataFilter,
  filterChanged,
  filterGlobalFilters,
  getDataGlobalFilters,
  getDataGlobalFiltersFailure,
  getDataGlobalFiltersSuccess,
  getSavedFiltersData,
  getSavedFiltersDataFailure,
  getSavedFiltersDataSuccess,
  submitSaveFilters,
  submitSaveFiltersFailure,
  submitSaveFiltersSuccess,
  submitDeleteSavedFilters,
  submitDeleteSavedFiltersFailure,
  submitDeleteSavedFiltersSuccess,
  toggleFolderSelect,
  toggleFolderItemSelect,
  toggleFolderExpandedMenu,
  updateGlobalFilterMenuState,
  updateAppliedDataFilter,
  updateAppliedDataFilterSuccess,
  updateSelectedDataFilter,
  syncGlobalFilters,
  updateGlobalFiltersWithTilesOne,
  segmentsLoadedSuccess,
};
