import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDataFilter } from '@shared/interfaces';

@Component({
  selector: 'tsh-global-filters-footer',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class GlobalFiltersFooterComponent {
  @Input() appliedFilter: IDataFilter;
  @Input() filterMenuChanged: boolean;
  @Input() unappliedFilters: number;

  @Output() applyFilterChanges: EventEmitter<void> = new EventEmitter<void>();
  @Output() showSaveModal: EventEmitter<IDataFilter> = new EventEmitter<IDataFilter>();
}
