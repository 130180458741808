import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { IDateCohort, IDateCohortGroup, ILabelValue } from '@shared/interfaces';
import { ClosedTypeOptions, DateTypeOptions, WonTypeOptions } from '@measurement-studio/constants';
import { ClosedType, InfluencesKeys } from '@measurement-studio/enums';
import { IOpportunityRequest } from '@measurement-studio/interfaces';
import { ICampaignSpecific } from '../../interfaces';

@Component({
  selector: 'ms-opportunities-filters',
  templateUrl: './opportunities-filters.component.html',
  styleUrls: ['./opportunities-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesFiltersComponent implements OnChanges {
  @Input() public filters: IOpportunityRequest;
  @Input() public dateCohortOptions: IDateCohortGroup[] = [];
  @Input() public modelsOptions: ILabelValue[] = [];
  @Input() public campaignSpecificData: ICampaignSpecific | null;
  @Input() public selectedDateCohort: IDateCohort;
  @Output() public setSelectedDateCohort = new EventEmitter<IDateCohort>();
  @Output() public apply = new EventEmitter<IOpportunityRequest>();
  @Output() public addToDashboard = new EventEmitter<void>();
  @Output() public removeCampaign = new EventEmitter<IOpportunityRequest>();

  public wonTypeOptions = WonTypeOptions;
  public dateTypeOptions = DateTypeOptions;
  public closedTypeOptions = ClosedTypeOptions;
  public closedTypes = ClosedType;
  public filterForm: FormGroup;
  public influencesKeys = InfluencesKeys;

  constructor(private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({
      wonType: [this.filters?.wonType, Validators.required],
      closedType: [this.filters?.closedType, Validators.required],
      dateType: [this.filters?.dateType, Validators.required],
      model: [this.filters?.model, Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && this.filters) {
      this.filterForm.get('wonType').setValue(this.filters.wonType);
      this.filterForm.get('closedType').setValue(this.filters.closedType);
      this.filterForm.get('dateType').setValue(this.filters.dateType);
      this.filterForm.get('model').setValue(this.filters.model);
    }
  }

  emitApply(): void {
    this.apply.emit({
      ...this.filters,
      ...this.filterForm.value,
    });
  }

  /**
   * Apply change for date cohort only
   */
  applyDateCohort(dateCohort?: IDateCohort): void {
    this.apply.emit({
      ...this.filters,
      cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
      endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
      startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
    });
  }

  emitRemoveCampaign(): void {
    this.removeCampaign.emit({
      ...this.filters,
      [this.campaignSpecificData.key]: null,
      pg: 1, // reset page
    });
  }

  changeDateCohort(date: IDateCohort): void {
    this.setSelectedDateCohort.emit(date);
  }

  emitAddToDashboard(): void {
    this.addToDashboard.emit();
  }
}
