import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { TsSortState } from '@terminus-lib/ui-sort';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { TranslateService } from '@ngx-translate/core';

import { DateCohortsGroups, InfluenceType } from '@shared/enums';
import { DataSetType, GroupType } from '@measurement-studio/enums';
import { ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { RevenueType } from '../enums/attribution-campaign.enum';
import { GoParams, IDateCohort } from '@shared/interfaces';
import { NavigateTo } from '@hub-navigation';
import { AttributionCampaignState } from '../state/attribution-campaign.reducer';
import * as AttributionCampaignSelectors from '../state/attribution-campaign.selectors';
import * as DateCohortsStore from '@date-cohorts';
import { dateCohortsActions } from '@date-cohorts';
import { attributionCampaignActions } from '../state/attribution-campaign.actions';
import * as CampaignsStore from '@shared/data-access/campaigns';
import * as OrgConfigStore from '@org-config';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';

@WithDestroy
@Component({
  selector: 'ms-attribution-campaign',
  templateUrl: './attribution-campaign.component.html',
  styleUrls: ['./attribution-campaign.component.scss']
})
export class AttributionCampaignComponent implements OnInit {
  public downloadingCSV$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignIsDownloadCSV));
  public isLoading$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignIsLoading));
  public filters$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignFilters));
  public totals$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignReportTotals));
  public report$ = this.store.pipe(select(AttributionCampaignSelectors.getSortedReport));
  public chartData$ = this.store.pipe(select(AttributionCampaignSelectors.getChartData));
  public chartGroups$ = this.store.pipe(select(AttributionCampaignSelectors.getChartGroups));
  public chartColumns$ = this.store.pipe(
    select(AttributionCampaignSelectors.getChartColumns),
    mergeMap(columns => columns.length
      // NOTE: check if columns have length because mergeMap converts [] to null
      ? combineLatest(
        // NOTE: update data with translation
        columns.map(column => this.translate.get(column.tooltip).pipe(
          withLatestFrom(this.translate.get(column.title)),
          map(([tooltip, title]) => ({...column, tooltip, title}))
        ))
      )
      : of([])
    )
  );
  public sortState$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignSortState));
  public expandedSortState$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignExpandedSortState));
  public expandedReport$ = this.store.pipe(select(AttributionCampaignSelectors.getSortedExpandedReport));
  public expandedReportTotals$ = this.store.pipe(select(AttributionCampaignSelectors.getExpandedReportTotalCount));
  public expandedReportPagination$ = this.store.pipe(select(AttributionCampaignSelectors.getExpandedReportPagination));
  public expandedRowState$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignExpandedRowState));
  public columns$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignColumns));
  public expandedColumns$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignExpandedColumns));
  public selectedDateCohort$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignSelectedDateCohort));
  public campaignTypesColors$ = this.store.pipe(select(CampaignsStore.getCampaignsTypesColors));
  public currency$ = this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting));
  public dateCohortOptions$ = this.store.pipe(
    select(DateCohortsStore.getGroupedDateCohortOptions([
      DateCohortsGroups.All, DateCohortsGroups.Years, DateCohortsGroups.Ago,
      DateCohortsGroups.LastFull, DateCohortsGroups.Quarters, DateCohortsGroups.ToDate,
      DateCohortsGroups.Custom
    ])));
  public metrics$ = this.store.pipe(select(AttributionCampaignSelectors.getAttributionCampaignMetrics));
  public defaultQueryParams: ICampaignFilters = {
    dataSet: DataSetType.OpptyCloseDate,
    grp: GroupType.Only,
    influenceType: InfluenceType.ANY,
    revenueType: RevenueType.Revenue,
    cohort: 'quarter2Date'
  };
  public noResultTitle = 'measurementStudio.features.attributionCampaign.noResultTitle';
  public noResultDescription = 'measurementStudio.features.attributionCampaign.noResultDescription';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AttributionCampaignState>,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    // load date cohort options for filter component
    this.store.dispatch(dateCohortsActions.loadDateCohorts());
    // load revenue groups to set color map
    this.store.dispatch(CampaignsStore.campaignsActions.getCampaignTypes());

    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe(params => {
        const filters$ = this.filters$.pipe(take(1));
        const globalFilters$ = this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)).pipe(take(1));
        combineLatest([filters$, globalFilters$])
          .pipe(untilComponentDestroyed(this))
          .subscribe(([filters, globalFilters]) => {
            if (!Object.keys(params).length && !filters) {
              // if there is no any query params then use default params
              this.applyFilters({...this.defaultQueryParams, ...globalFilters});
              return;
            }

            if (!Object.keys(params).length && filters) {
              // if there is no any query params but filters in the store then use them
              this.applyFilters({...filters, ...globalFilters});
              return;
            }

            const newFilters = this.getParams(params);
            this.setDateCohort(newFilters);
            this.store.dispatch(attributionCampaignActions.setAttributionCampaignFilters({filters: newFilters}));
          });
      });
  }

  applyFilters(newFilters: ICampaignFilters): void {
    const isPipeline = newFilters.revenueType === RevenueType.Pipeline;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...newFilters,
        dataSet: isPipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
        influenceType: isPipeline ? InfluenceType.PRE : newFilters.influenceType
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  downloadCSV(): void {
    this.store.dispatch(attributionCampaignActions.downloadCSV());
  }

  navigate(route: GoParams): void {
    this.store.dispatch(NavigateTo(route));
  }

  toggleExpandRow(key: string): void {
    this.store.dispatch(attributionCampaignActions.toggleExpandAttributionCampaignRow({key}));
  }

  setSelectedDateCohort(date: IDateCohort): void {
    this.store.dispatch(attributionCampaignActions.setAttributionCampaignDateCohort({date}));
  }

  changeSort(state: TsSortState): void {
    this.store.dispatch(attributionCampaignActions.setAttributionReportSortState({state}));
  }

  changeExpandedSort(data: { state: TsSortState; key: string }): void {
    this.store.dispatch(attributionCampaignActions.setAttributionExpandedReportSortState(data));
  }

  changeExpandedPage(data: { key: string; page: number }): void {
    this.store.dispatch(attributionCampaignActions.setAttributionExpandedReportPage(data));
  }

  downloadExpandedReport(data: { key: string; label: string }): void {
    this.store.dispatch(attributionCampaignActions.downloadCSVForExpandedReport(data));
  }

 private setDateCohort(filters: ICampaignFilters): void {
    if (filters.cohort === 'custom') {
      // in case of custom date cohort set data from query params
      this.setSelectedDateCohort({
        name: 'Custom Range',
        cohort: filters.cohort,
        endDate: filters.endDate,
        startDate: filters.startDate
      });
    } else {
      // in case of non custom date cohort set data from date cohorts source
      this.store.pipe(
        untilComponentDestroyed(this),
        select(DateCohortsStore.getDateCohortByCohort(filters.cohort)),
        filter((dateCohort: IDateCohort) => !!dateCohort)
      ).subscribe(data => {
        this.setSelectedDateCohort(data);
      });
    }
  }

  private getParams(queryParams: Params): ICampaignFilters {
    // get query params from url or use default params
    const revenueType = queryParams['revenueType'] || this.defaultQueryParams.revenueType;
    const isPipeline = revenueType === RevenueType.Pipeline;
    const influence = queryParams['influenceType'] || this.defaultQueryParams.influenceType;
    const cohort = !queryParams['cohort'] || queryParams['cohort'] === '*NONE*'
      ? this.defaultQueryParams.cohort
      : queryParams['cohort'];
    const defaultParams = {
      dataSet: isPipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
      grp: queryParams['grp'] || this.defaultQueryParams.grp,
      influenceType: isPipeline ? InfluenceType.PRE : influence,
      revenueType,
      cohort,
      endDate: queryParams['endDate'],
      startDate: queryParams['startDate']
    };

    const date = new Date().getTime();

    // NOTE: if date cohort is custom but there is no startDate
    // then we have to set startDate the same as endDate or just current date
    const startDate = queryParams.cohort === 'custom'
      ? queryParams.startDate || queryParams.endDate || date
      : queryParams.startDate;
    // NOTE: if date cohort is custom but there is no endDate
    // then we have to set endDate the same as startDate or just current date
    const endDate = queryParams.cohort === 'custom'
      ? queryParams.endDate || queryParams.startDate || date
      : queryParams.endDate;
    // need to change startDate and endDate to number because query params are string
    return {
      ...defaultParams,
      startDate: startDate ? Number(startDate) : date,
      endDate: endDate ? Number(endDate) : date
    };
  }
}
