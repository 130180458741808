import { jwtDecode } from '@terminus-lib/fe-jwt';

import { RouteItemEnum } from '@shared/enums';
// import { HubCookieActionTypes, UpdateJwt } from '@hub-cookie';
import { userActions } from './user.actions';
import { userCommonActions, UserState } from '@shared/data-access/user-common';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: UserState = {
  isUserProfileLoaded: false,
  isLoading: false,
  isAuthenticated: false,
  redirectUrl: null,
  userProfile: {
    profileId: '',
    email: '',
    userId: '',
    username: '',
    orgId: '',
    orgName: '',
    orgUuid: '',
    isInternal: false,
    reportsAccess: {},
    featureFlags: {},
    crm: {
      type:'',
      isAutomatedIngest: false,
      orgId: '',
    },
  },
  hasZendeskSsoAccess: false,
  unifiedCustomerId: '',
  isUnifiedLoginEnabled: false,
  decode: jwtDecode,
};

export const updatedDemoConfigAccess = (state: UserState, token) => ({
  ...state,
  userProfile: {
    ...state.userProfile,
    reportsAccess: {
      ...state.userProfile.reportsAccess,
      [RouteItemEnum.DemoConfig]: (state.decode<Partial<{ ['bf.badm']: boolean }>>(token) || {'bf.badm': false})['bf.badm']
    }
  }
});

export const userReducer = createReducer(
  initialState,
  on(
    userCommonActions.setUserIsAuthenticated,
    (state) => ({
      ...state,
      isAuthenticated: true
    })
  ),
  on(
    userCommonActions.clearUserIsAuthenticated,
    (state) => ({
      ...state,
      isAuthenticated: false
    })
  ),
  on(
    userCommonActions.getUserProfile,
    (state) => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    userActions.initialTokenExtracted,
    (state, action) => ({
      ...state,
      ...(action.token ? updatedDemoConfigAccess(state, action.token) : {})
    })
  ),
  on(
    userActions.updateJWT,
    (state, action) => ({
      ...state,
      ...(action.token ? updatedDemoConfigAccess(state, action.token) : {})
    })
  ),
  on(
    userCommonActions.getUserProfileSuccess,
    (state, action) => ({
      ...state,
      userProfile: {
        ...action.profile,
        reportsAccess: {
          ...action.profile.reportsAccess,
          [RouteItemEnum.DemoConfig]: state.userProfile.reportsAccess[RouteItemEnum.DemoConfig]
        }
      },
      isUserProfileLoaded: true,
      isLoading: false
    })
  ),
  on(
    userCommonActions.getUserProfileFailure,
    (state) => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    userActions.saveRedirectUrl,
    (state, action) => ({
      ...state,
      redirectUrl: {...action.url}
    })
  ),
  on(
    userActions.clearRedirectUrl,
    (state) => ({
      ...state,
      redirectUrl: null
    })
  ),
  on(
    userActions.setUserZendeskSsoAccess,
    (state, action) => ({
      ...state,
      hasZendeskSsoAccess: action.access
    })
  ),
  on(
    userActions.setUnifiedCustomerId,
    (state, action) => ({
      ...state,
      unifiedCustomerId: action.id
    })
  ),
  on(
    userActions.setUnifiedLoginStatus,
    (state, action) => ({
      ...state,
      isUnifiedLoginEnabled: action.status
    })
  )
);

export function reducer(state: UserState, action: Action): UserState {
  return userReducer(state, action);
}
