export enum TileFormFields {
  Dashboard = 'dashboardId',
  Name = 'name',
  Cohort = 'cohort',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Type = 'type',
  Route = 'route',
  Visualization = 'visualization',
  Settings = 'settings',
  GlobalFilters = 'globalFilters',
}
