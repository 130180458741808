import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AggregateList } from '@shared/interfaces';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { AggregateListsSource } from '../sources/aggregate-lists.source';

@Injectable({
  providedIn: 'root'
})
export class AggregateListsService {

  constructor(
    private source: AggregateListsSource,
    private retry: RetryWithEscalation,
    private envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getAggregates$(params?: any): Observable<AggregateList[]> {
    return regenerateOnRetry(() => this.source.getAggregates$(params)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }
}
