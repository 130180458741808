/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';
import { IDateCohort, IEditTableColumn } from '@shared/interfaces';
import { IOpportunityColumn, IOpportunityReport, IOpportunityVisual, VisualKeys } from '../interfaces';
import { IOpportunityRequest, IOpportunityUsedQueryParams } from '@measurement-studio/interfaces';

export enum OpportunitiesActionTypes {
  GetReportColumns = '[Opportunities] Get Report Columns',
  SetReportColumns = '[Opportunities] Set Report Columns',
  ToggleColumnVisibility = '[Opportunities] Toggle Column Visibility',
  LoadReport = '[Opportunities] Load Report data',
  LoadReportSuccess = '[Opportunities] Load Report data Success',
  LoadReportFailure = '[Opportunities] Load Report data Failure',
  LoadVisualization = '[Opportunities] Load visualization data',
  LoadVisualizationSuccess = '[Opportunities] Load visualization data Success',
  LoadVisualizationFailure = '[Opportunities] Load visualization data Failure',
  SetOpportunityFilters = '[Opportunities] Set Filters',
  SetDateCohort = '[Opportunities] Set Selected Date Cohort',
  SetUsedQueryParams = '[Opportunities] Set Used Query Params',
  DownloadCSV = '[Opportunities] Download CSV',
  DownloadCSVSuccess = '[Opportunities] Download CSV for Opportunities Success',
  DownloadCSVFailure = '[Opportunities] Download CSV for Opportunities Failure',
  SetSelectedChart = '[Opportunities] Set Selected Chart Key',
}

const getReportColumns = createAction(
  OpportunitiesActionTypes.GetReportColumns
);

const setReportColumns = createAction(
  OpportunitiesActionTypes.SetReportColumns,
  props<{ columns: IOpportunityColumn[] }>()
);

const setUsedQueryParams = createAction(
  OpportunitiesActionTypes.SetUsedQueryParams,
  props<{ params: IOpportunityUsedQueryParams }>()
);

const toggleColumnVisibility = createAction(
  OpportunitiesActionTypes.ToggleColumnVisibility,
  props<{ column: IEditTableColumn }>()
);

const loadReport = createAction(
  OpportunitiesActionTypes.LoadReport
);

const loadReportSuccess = createAction(
  OpportunitiesActionTypes.LoadReportSuccess,
  props<{ data: IOpportunityReport }>()
);

const loadReportFailure = createAction(
  OpportunitiesActionTypes.LoadReportFailure,
  props<{ message: string }>()
);

const loadVisualization = createAction(
  OpportunitiesActionTypes.LoadVisualization
);

const loadVisualizationSuccess = createAction(
  OpportunitiesActionTypes.LoadVisualizationSuccess,
  props<{ data: IOpportunityVisual }>()
);

const loadVisualizationFailure = createAction(
  OpportunitiesActionTypes.LoadVisualizationFailure,
  props<{ message: string }>()
);

const setOpportunityFilters = createAction(
  OpportunitiesActionTypes.SetOpportunityFilters,
  props<{ filters: IOpportunityRequest }>()
);

const setDateCohort = createAction(
  OpportunitiesActionTypes.SetDateCohort,
  props<{ cohort: IDateCohort }>()
);

const downloadCSV = createAction(
  OpportunitiesActionTypes.DownloadCSV
);

const downloadCSVSuccess = createAction(
  OpportunitiesActionTypes.DownloadCSVSuccess,
  props<{ data: string }>()
);

const downloadCSVFailure = createAction(
  OpportunitiesActionTypes.DownloadCSVFailure,
  props<{ message: string }>()
);

const setSelectedChart = createAction(
  OpportunitiesActionTypes.SetSelectedChart,
  props<{ chart: VisualKeys }>()
);

export const opportunitiesActions = {
  getReportColumns,
  setReportColumns,
  loadReport,
  loadReportSuccess,
  loadReportFailure,
  loadVisualization,
  loadVisualizationSuccess,
  loadVisualizationFailure,
  setOpportunityFilters,
  toggleColumnVisibility,
  setDateCohort,
  setUsedQueryParams,
  downloadCSV,
  downloadCSVSuccess,
  downloadCSVFailure,
  setSelectedChart
};

