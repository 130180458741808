import { createAction, props } from '@ngrx/store';
import {
  ICreateSavedFolder,
  ICreateSavedReport,
  IDeleteSavedFolder,
  IDeleteSavedReport,
  ISavedFolder,
  ISavedReport,
  IUpdateSavedFolder,
  IUpdateSavedReport
} from '@shared/interfaces';

export const SavedReportsActionTypes = {
  GetSavedReports: '[SavedReports] Get Saved Reports',
  LoadSavedReports: '[SavedReports] Load Saved Reports',
  LoadSavedReportsSuccess: '[SavedReports] Load Saved Reports Success',
  LoadSavedReportsFailure: '[SavedReports] Load Saved Reports Failure',
  CreateSavableReport: '[SavedReports] Create Savable Report',
  CreateSavableReportSuccess: '[SavedReports] Create Savable Report Success',
  CreateSavableReportFailure: '[SavedReports] Create Savable Report Failure',
  UpdateSavableReport: '[SavedReports] Update Savable Report',
  UpdateSavableReportSuccess: '[SavedReports] Update Savable Report Success',
  UpdateSavableReportFailure: '[SavedReports] Update Savable Report Failure',
  DeleteSavableReport: '[SavedReports] Delete Savable Report',
  DeleteSavableReportSuccess: '[SavedReports] Delete Savable Report Success',
  DeleteSavableReportFailure: '[SavedReports] Delete Savable Report Failure',
  CreateSavableFolder: '[SavedReports] Create Savable Folder',
  CreateSavableFolderSuccess: '[SavedReports] Create Savable Folder Success',
  CreateSavableFolderFailure: '[SavedReports] Create Savable Folder Failure',
  UpdateSavableFolder: '[SavedReports] Update Savable Folder',
  UpdateSavableFolderSuccess: '[SavedReports] Update Savable Folder Success',
  UpdateSavableFolderFailure: '[SavedReports] Update Savable Folder Failure',
  DeleteSavableFolder: '[SavedReports] Delete Savable Folder',
  DeleteSavableFolderSuccess: '[SavedReports] Delete Savable Folder Success',
  DeleteSavableFolderFailure: '[SavedReports] Delete Savable Folder Failure',
  ShowSavedReports: '[SavedReports] Redirect to saved reports',
}

const getSavedReports = createAction(SavedReportsActionTypes.GetSavedReports);

const loadSavedReports = createAction(SavedReportsActionTypes.LoadSavedReports);

const loadSavedReportsSuccess = createAction(
  SavedReportsActionTypes.LoadSavedReportsSuccess,
  props<{ data: ISavedFolder[] }>()
);

const loadSavedReportsFailure = createAction(
  SavedReportsActionTypes.LoadSavedReportsFailure,
  props<{ message: string }>()
);

const createSavableReport = createAction(
  SavedReportsActionTypes.CreateSavableReport,
  props<{ data: ICreateSavedReport }>()
);

const createSavableReportSuccess = createAction(SavedReportsActionTypes.CreateSavableReportSuccess);

const createSavableReportFailure = createAction(
  SavedReportsActionTypes.CreateSavableReportFailure,
  props<{ message: string }>()
);

const updateSavableReport = createAction(
  SavedReportsActionTypes.UpdateSavableReport,
  props<{ data: IUpdateSavedReport }>()
);

const updateSavableReportSuccess = createAction(SavedReportsActionTypes.UpdateSavableReportSuccess);

const updateSavableReportFailure = createAction(
  SavedReportsActionTypes.UpdateSavableReportFailure,
  props<{ message: string }>()
);

const deleteSavableReport = createAction(
  SavedReportsActionTypes.DeleteSavableReport,
  props<{ data: IDeleteSavedReport }>()
);

const deleteSavableReportSuccess = createAction(SavedReportsActionTypes.DeleteSavableReportSuccess);

const deleteSavableReportFailure = createAction(
  SavedReportsActionTypes.DeleteSavableReportFailure,
  props<{ message: string }>()
);

const createSavableFolder = createAction(
  SavedReportsActionTypes.CreateSavableFolder,
  props<{ data: ICreateSavedFolder }>()
);

const createSavableFolderSuccess = createAction(SavedReportsActionTypes.CreateSavableFolderSuccess);

const createSavableFolderFailure = createAction(
  SavedReportsActionTypes.CreateSavableFolderFailure,
  props<{ message: string }>()
);

const updateSavableFolder = createAction(
  SavedReportsActionTypes.UpdateSavableFolder,
  props<{ data: IUpdateSavedFolder }>()
);

const updateSavableFolderSuccess = createAction(SavedReportsActionTypes.UpdateSavableFolderSuccess);

const updateSavableFolderFailure = createAction(
  SavedReportsActionTypes.UpdateSavableReportFailure,
  props<{ message: string }>()
);

const deleteSavableFolder = createAction(
  SavedReportsActionTypes.DeleteSavableFolder,
  props<{ data: IDeleteSavedFolder }>()
);

const deleteSavableFolderSuccess = createAction(SavedReportsActionTypes.DeleteSavableFolderSuccess);

const deleteSavableFolderFailure = createAction(
  SavedReportsActionTypes.DeleteSavableFolderFailure,
  props<{ message: string }>()
);

const showSavedReports = createAction(
  SavedReportsActionTypes.ShowSavedReports,
  props<{ data: ISavedReport }>()
);

export const savedReportsActions = {
  getSavedReports,
  loadSavedReports,
  loadSavedReportsSuccess,
  loadSavedReportsFailure,
  createSavableReport,
  createSavableReportSuccess,
  createSavableReportFailure,
  updateSavableReport,
  updateSavableReportSuccess,
  updateSavableReportFailure,
  deleteSavableReport,
  deleteSavableReportSuccess,
  deleteSavableReportFailure,
  createSavableFolder,
  createSavableFolderSuccess,
  createSavableFolderFailure,
  updateSavableFolder,
  updateSavableFolderSuccess,
  updateSavableFolderFailure,
  deleteSavableFolder,
  deleteSavableFolderSuccess,
  deleteSavableFolderFailure,
  showSavedReports,
}
