<div class="items">
  <div *ngFor="let item of items" class="item">
    <ms-insights-metric-item
      *ngIf="!item.valueFormat"
      [label]="item.label | translate"
      [value]="item.value + (item.valueAppend ? item.valueAppend : '')"
      [tooltip]="item.tooltip | translate"
      [legendColor]="item.legendColor"
    ></ms-insights-metric-item>
    <ms-insights-metric-item
      *ngIf="!!item.valueFormat && item.valueFormat === 'DECIMAL'"
      [label]="item.label | translate"
      [value]="(item.value > 0 || item.displayNegative)
                ? ((item.value | number: item.digitsInfo || '1.0-0') + (item.valueAppend ? item.valueAppend : ''))
                :  '--'
            "
      [tooltip]="item.tooltip | translate"
      [legendColor]="item.legendColor"
    ></ms-insights-metric-item>
    <ms-insights-metric-item
      *ngIf="!!item.valueFormat && item.valueFormat === 'CURRENCY'"
      [label]="item.label | translate"
      [value]="(item.value | currency: currencySymbol: 'symbol': item.digitsInfo || '1.0-0') + (item.valueAppend ? item.valueAppend : '')"
      [tooltip]="item.tooltip | translate"
      [legendColor]="item.legendColor"
    ></ms-insights-metric-item>
    <ms-insights-metric-item
      *ngIf="!!item.valueFormat && item.valueFormat === 'PERCENT'"
      [label]="item.label | translate"
      [value]="(item.value | percent: item.digitsInfo || '1.0-0') + (item.valueAppend ? item.valueAppend : '')"
      [tooltip]="item.tooltip | translate"
      [legendColor]="item.legendColor"
    ></ms-insights-metric-item>
  </div>
</div>
