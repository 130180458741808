import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { DashboardsSource } from '../source/dashboards.source';
import * as interfaces from '@shared/interfaces';

@Injectable({providedIn: 'root'})
export class DashboardsService {

  constructor(public source: DashboardsSource,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  create$(request: interfaces.ICreateDashboardRequest): Observable<interfaces.IDashboard> {
    return regenerateOnRetry(() => this.source.create$(request))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  duplicate$(dashboardId: string): Observable<interfaces.IDashboard> {
    return regenerateOnRetry(() => this.source.duplicate$(dashboardId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  delete$(dashboardId: string): Observable<string> {
    return regenerateOnRetry(() => this.source.delete$(dashboardId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  update$(body: interfaces.IDashboard): Observable<string> {
    return regenerateOnRetry(() => this.source.update$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  order$(dashboardIds: string[]): Observable<string> {
    return regenerateOnRetry(() => this.source.order$(dashboardIds))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getDashboards$(param?: interfaces.IDashboardRequest): Observable<interfaces.IDashboard[]> {
    return regenerateOnRetry(() => this.source.getDashboards$(param))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  createTile$(body: interfaces.ICreateDashboardTileRequest): Observable<interfaces.IDashboardTile> {
    return regenerateOnRetry(() => this.source.createTile$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  deleteTile$(tileId: string): Observable<string> {
    return regenerateOnRetry(() => this.source.deleteTile$(tileId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  updateTile$(body: interfaces.IDashboardTile): Observable<string> {
    return regenerateOnRetry(() => this.source.updateTile$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  orderTiles$(tileIds: string[]): Observable<string> {
    return regenerateOnRetry(() => this.source.orderTiles$(tileIds))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getDashboardTiles$(dashboardId: string): Observable<interfaces.IDashboardTile[]> {
    return regenerateOnRetry(() => this.source.getDashboardTiles$(dashboardId))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

}
