import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AggregateList, userMessageFactory } from '@shared/interfaces';
import { notificationMessagesActions } from '@notification-messages';
import { AggregateListsState } from './aggregate-lists.reducer';
import { aggregateListsActions } from './aggregate-lists.actions';
import * as selectors from './aggregate-lists.selectors';
import { AggregateListsService } from '../services/aggregate-lists.service';

@Injectable()
export class AggregateListsEffects {

  constructor(private actions$: Actions,
              private store: Store<AggregateListsState>,
              private aggregateListsService: AggregateListsService) {
  }

  public loadAggregates$ = createEffect(() => this.actions$.pipe(
    ofType(aggregateListsActions.loadAggregateLists),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getAggregateLists)))
    )),
    filter(([_, list]: [null, AggregateList[] | null]) => list === null),
    switchMap(() => {
      return this.aggregateListsService.getAggregates$({audience:'list'}).pipe(
        map((data: AggregateList[]) => aggregateListsActions.loadAggregateListsSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.scorecard.errors.loadAggregates';

          return of(aggregateListsActions.loadAggregateListsFailure({message}));
        })
      );
    })
  ));

  public loadSegmentAggregates$ = createEffect(() => this.actions$.pipe(
    ofType(aggregateListsActions.loadAggregateSegments),
    concatMap(() => of(null).pipe(
      withLatestFrom(this.store.pipe(select(selectors.getAggregateLists)))
    )),
    filter(([_, list]: [null, AggregateList[] | null]) => list === null),
    switchMap(() => {
      return this.aggregateListsService.getAggregates$({audience:'segment'}).pipe(
        map((data: AggregateList[]) => aggregateListsActions.loadAggregateSegmentsSuccess({data})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.scorecard.errors.loadAggregates';

          return of(aggregateListsActions.loadAggregateListsFailure({message}));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(aggregateListsActions.loadAggregateListsFailure),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));
}
