import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SPLITIO_STATUS } from '../constants';
import { SplitIoState, SPLIT_IO_TOKEN } from './split-io.reducer';

export const getState = createFeatureSelector<SplitIoState>(SPLIT_IO_TOKEN);

export const getStateWithOverrides = createSelector(getState, state => {
  let obj: Record<string, SPLITIO_STATUS> = {};

  if (state) {
    Object.keys(state.grouped).forEach(key => {
      obj = { ...obj, ...state.grouped[key] };
    });
  }

  return { ...obj, ...state.overrides };
});

export const getSplitIoFlagValue = (treatment: string) =>
  createSelector(getStateWithOverrides, state => state[treatment]);

export const getIsSplitIoFlagOn = (treatment: string, defaultState = false) =>
  createSelector(getSplitIoFlagValue(treatment), flag => (flag ? flag === SPLITIO_STATUS.on : defaultState));
