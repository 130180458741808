import { createReducer, on, Action } from '@ngrx/store'
import { orgConfigActions } from './org-config.actions';
import { IHubspotCrmUrl, IOrgConfig } from '@shared/interfaces';

export const orgConfigFeatureKey = 'organization';

export interface OrgConfigState {
  sigstrProductType: string;
  crmUrl: string | IHubspotCrmUrl;
  config: IOrgConfig;
  opportunityTypes: string[] | null;
  opportunityProductLines: string[] | null;
  spikeModels: string[] | null;
  intentTopics: string[] | null;
}

export const initialState: OrgConfigState = {
  sigstrProductType: null,
  crmUrl: null,
  config: null,
  opportunityTypes: null,
  opportunityProductLines: null,
  spikeModels: null,
  intentTopics: null
};

const orgConfigReducer = createReducer(
  initialState,
  on(
    orgConfigActions.LoadSigstrsProductTypeSuccess,
    (state, action) => ({
      ...state,
      sigstrProductType: action.payload.sigstrProductType
    })
  ),
  on(
    orgConfigActions.LoadOrgConfigSuccess,
    (state, action) => ({
      ...state,
      config: action.payload
    })
  ),
  on(
    // NOTE: this code is match the on(
    // when we update attribution settings
    // action payload in this on(
    //looks like(example)
    // attribution: {
    //     custom: {
    //         weightType: 'value';
    //     }
    // };
    // so we have to update weightType property in org-config state to avoid
    // updating this data by api call
    // in on(
    // if this api call, which we use to update config, returns another structure
    // we have to update this code and find out other solution
    orgConfigActions.UpdateConfigSuccess,
    (state, action) => {
      const updatedConfigKey = Object.keys(action.payload)[0];
      const updatedSubConfigKey = Object.keys(action.payload[updatedConfigKey])[0];
      const updatedPropertyKey = Object.keys(action.payload[updatedConfigKey][updatedSubConfigKey])[0];

      return {
        ...state,
        config: {
          ...state.config,
          [updatedConfigKey]: {
            ...state.config[updatedConfigKey],
            [updatedSubConfigKey]: {
              ...state.config[updatedConfigKey][updatedSubConfigKey],
              [updatedPropertyKey]: action.payload[updatedConfigKey][updatedSubConfigKey][updatedPropertyKey]
            }
          }
        }
      };
    }
  ),
  on(
    orgConfigActions.LoadCrmUrlSuccess,
    (state, action) => ({
      ...state,
      crmUrl: action.url
    })
  ),
  on(
    orgConfigActions.LoadOpportunityTypesSuccess,
    (state, action) => ({
      ...state,
      opportunityTypes: action.payload
    })
  ),
  on(
    orgConfigActions.LoadOpportunityProductLinesSuccess,
    (state, action) => ({
      ...state,
      opportunityProductLines: action.payload
    })
  ),
  on(
    orgConfigActions.LoadSpikeModelsSuccess,
    (state, action) => ({
      ...state,
      spikeModels: action.payload
    })
  ),
  on(
    orgConfigActions.LoadIntentTopicsSuccess,
    (state, action) => ({
      ...state,
      intentTopics: action.payload
    })
  )
)

export function reducer(state: OrgConfigState, action: Action): OrgConfigState {
  return orgConfigReducer(state, action)
}
