import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ScorecardComponent } from './containers/scorecard.component';
import { ReportAccessGuard } from '@shared/data-access/guards';
import { RouteItemEnum } from '@shared/enums';
import { SCORECARD_OVERVIEW, SCORECARD_TRENDING_KPI } from './scorecard.constant';

const routes: Routes = [
  {
    path: '',
    component: ScorecardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SCORECARD_OVERVIEW
      },
      {
        path: SCORECARD_OVERVIEW,
        loadChildren: () => import ('./overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: SCORECARD_TRENDING_KPI,
        loadChildren: () => import ('./kpi-trending/kpi-trending.module').then(m => m.KpiTrendingModule),
        canActivate: [
          ReportAccessGuard
        ],
        data: {
          routeId: RouteItemEnum.ScorecardTrendingKpi
        }
      }
    ]
  },
  {
    path: 'list',
    loadChildren: () => import('./add-account-list/add-account-list.module').then(m => m.ScorecardListFormModule)
  },
  {
    path: 'list-details/:listId',
    loadChildren: () => import('./account-list-details/account-list-details.module').then(m => m.AccountListDetailsModule)
  },
  {
    path: 'trending_details',
    loadChildren: () => import('./trending-details/trending-details.module').then(m => m.TrendingDetailsModule),
    canActivate: [
      ReportAccessGuard
    ],
    data: {
      title: 'Trending Details - Measurement Studio',
      routeId: RouteItemEnum.ScorecardTrendingKpi // use parent route ID to check if we have access to this route
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],

  exports: [
    RouterModule
  ]
})
export class ScorecardRoutingModule {
}
