import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAuthToken, IExportToken, IUserProfile } from '@shared/interfaces';

export enum UserSourceURLs {
  GetUser = '/UserData/get',
  Authenticate = '/authenticate',
  AuthenticateExportToken = '/authenticate/createExportToken',
  AuthenticateUnifiedLogin = '/login/handle_login_ul',
  SwitchUser = '/loginAs/changeToUser'
}

@Injectable({
  providedIn: 'root'
})
export class UserSource extends HttpClientBase {

  getUserProfile$(): Observable<IUserProfile> {
    const endpoint = this.endpoint(UserSourceURLs.GetUser);

    return this.httpClient.get<IUserProfile>(endpoint);
  }

  authenticate$(tokenNearingExpiration: string): Observable<IAuthToken> {
    const endpoint = this.endpoint(UserSourceURLs.Authenticate);
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${tokenNearingExpiration}`);

    return this.httpClient.get<IAuthToken>(endpoint, {
      headers
    });
  }

  authenticateExportToken$(): Observable<IExportToken> {
    const endpoint = this.endpoint(UserSourceURLs.AuthenticateExportToken);

    return this.httpClient.get<IExportToken>(endpoint);
  }

  authenticateUnifiedLogin$(oktaToken: string): Observable<IAuthToken> {
    const endpoint = this.endpoint(UserSourceURLs.AuthenticateUnifiedLogin);

    return this.httpClient.post<IAuthToken>(endpoint, { token: oktaToken });
  }

  switchUser$(username: string): Observable<IAuthToken> {
    const endpoint = this.endpoint(UserSourceURLs.SwitchUser);
    return this.httpClient.post<IAuthToken>(endpoint, { username });
  }
}
