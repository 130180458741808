import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IGlobalSearchItem } from '../interfaces';
import { getHttpParams } from '@util/http';

export enum GlobalSearchURLs {
  LoadOpportunities = '/api/v1/oppty_names_like',
  LoadAccounts = '/api/v1/account_names_like',
  LoadChannels = '/api/v1/channel_assets_like',
}

@Injectable({providedIn: 'root'})
export class GlobalSearchSource extends HttpClientBase {
  getOpportunities$(startName: string): Observable<IGlobalSearchItem[]> {
    const endpoint = this.endpoint(GlobalSearchURLs.LoadOpportunities);

    return this.httpClient.get<IGlobalSearchItem[]>(endpoint, {params: getHttpParams({ startName })});
  }

  getAccounts$(likeName: string): Observable<IGlobalSearchItem[]> {
    const endpoint = this.endpoint(GlobalSearchURLs.LoadAccounts);

    return this.httpClient.get<IGlobalSearchItem[]>(endpoint, {params: getHttpParams({ likeName })});
  }

  getChannels$(likeName: string): Observable<IGlobalSearchItem[]> {
    const endpoint = this.endpoint(GlobalSearchURLs.LoadChannels);

    return this.httpClient.get<IGlobalSearchItem[]>(endpoint, {params: getHttpParams({ likeName })});
  }
}
