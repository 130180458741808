import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IUserProfile } from '@shared/interfaces';
import { UserState } from './interfaces/user-state.interface';

export const selectUserState = createFeatureSelector<UserState>('userState');

export const selectIsUserProfileLoaded = createSelector(selectUserState, (state: UserState) => state.isUserProfileLoaded);
export const isUserLoading = createSelector(selectUserState, (state: UserState) => state.isLoading);
export const getIsUnifiedLoginEnabled = createSelector(selectUserState, state => state.isUnifiedLoginEnabled);
export const selectUserProfile = createSelector(selectUserState, (state: UserState) => state.userProfile);

export const selectFeatureFlags = createSelector(selectUserProfile, (userProfile: IUserProfile) => userProfile?.featureFlags);
