<div class="global-filter-tab-content" [tsLoadingOverlay]="(isLoading$ | async)">
  <ng-content></ng-content>
  <ts-drawer-header>
    <tsh-global-filters-data-filter-select [savedDataFilters]="(savedDataFilters$ | async)"
                                           [selectedDataFilter]="(selectedDataFilter$ | async)"
                                           [savedDataFilterSelected]="(savedDataFilterSelected$ | async)"
                                           [savedDataFilterChanged]="(savedDataFilterChanged$ | async)"
                                           (dataFilterChange)="onDataFilterSelect($event)"
                                           (deleteDataFilter)="onDeleteSavedDataFilter()"
                                           (updateDataFilter)="onUpdateSavedDataFilter()">
    </tsh-global-filters-data-filter-select>
    <div class="global-filter-search-container">
      <ts-input (input)="onSearchSubmit($event.target.value)"
                theme="default"
                [isSmall]="true"
                type="text"
                [placeholder]="searchLabel | translate">
        <i class="fas fa-search"></i>
      </ts-input>
      <ts-button [isDisabled]="(areFiltersApplied$ | async) === false"
                 (clicked)="onClearDataFilterForm()"
                 class="clear-filter-btn"
                 buttonType="button"
                 actionName="Clear Filter"
                 [isSmall]="true"
                 theme="secondary">
        {{ 'shared.globalFilters.container.clearFilter' | translate }}
      </ts-button>
    </div>
  </ts-drawer-header>
  <tsh-global-filters-list class="filter-list-menu"
                           [accountListFolder]="(accountListFolder$ | async)"
                           [accountListFolderItem]="(accountListFolderMenuState$ | async)"
                           [filterMenuState]="(globalFilterMenuState$ | async)"
                           [globalFilters]="(globalFilterData$ | async)"
                           [segments]="segments$ | async"
                           (selectFilterFolder)="onFolderCheckboxSelect($event)"
                           (selectFilterFolderItem)="onFolderItemCheckboxSelect($event)"
                           (toggleFilterFolderMenu)="onToggleFolderMenu($event)">
  </tsh-global-filters-list>
  <ts-drawer-footer>
    <ts-tooltip #tooltip
                tooltipPosition="above"
                [tooltipValue]="tooltipMessage">
    </ts-tooltip>
    <tsh-global-filters-footer [filterMenuChanged]="(filterChanged$ | async)"
                               [unappliedFilters]="(unappliedFilters$ | async)"
                               [appliedFilter]="(appliedDataFilter$ | async)"
                               (applyFilterChanges)="onApplyFilterChanges()"
                               (showSaveModal)="openDialog($event)">
    </tsh-global-filters-footer>
  </ts-drawer-footer>
</div>
