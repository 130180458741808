import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { Environment } from '@shared/environment';
import { IRouteItem } from '@shared/interfaces';
import { SearchTypeLabels } from '../data/search-type-labels';
import { GlobalSearchTypeEnum } from '../enums';
import { IGlobalSearchData, IGlobalSearchItem } from '../interfaces';
import { IGlobalSearchState, globalSearchFeatureKey } from './global-search.reducer';
import * as UserSelectors from '@user/selectors';

export const getGlobalSearchState = createFeatureSelector<IGlobalSearchState>(globalSearchFeatureKey);

export const getData = createSelector(
  getGlobalSearchState,
  (state: IGlobalSearchState) => state.data);

export const getIsLoading = createSelector(
  getGlobalSearchState,
  (state: IGlobalSearchState) => state.isLoading);

export const getSearchQuery = createSelector(
  getGlobalSearchState,
  (state: IGlobalSearchState) => state.searchQuery);

export const getViewData = createSelector(
  getGlobalSearchState,
  (state: IGlobalSearchState) => state.viewData);

export const getStateRouteData = createSelector(
  getGlobalSearchState,
  (state: IGlobalSearchState) => state.routeData);

export const getMarkedData = createSelector(
  getData,
  getSearchQuery,
  (data: IGlobalSearchData, searchQuery: string) => {
    const markItem = (item: IGlobalSearchItem, mark: string) => ({
      id: item.id,
      name: item.name.replace(new RegExp(mark, 'gi'), '<span class="highlight">$&</span>')
    });

    return data && {
      [GlobalSearchTypeEnum.Opportunities]: (data[GlobalSearchTypeEnum.Opportunities] || []).map(
        item => markItem(item, searchQuery)
      ),
      [GlobalSearchTypeEnum.Accounts]: (data[GlobalSearchTypeEnum.Accounts] || []).map(
        item => markItem(item, searchQuery)
      ),
      [GlobalSearchTypeEnum.Campaigns]: (data[GlobalSearchTypeEnum.Campaigns] || []).map(
        item => markItem(item, searchQuery)
      ),
      [GlobalSearchTypeEnum.Channels]: (data[GlobalSearchTypeEnum.Channels] || []).map(
        item => markItem(item, searchQuery)
      ),
    };
  }
);

export const getRouteData = (appRotes: IRouteItem[], env: Environment) => createSelector(
  getStateRouteData,
  (routeData) => {
    const processingExternalLink = (route: IRouteItem):IRouteItem => {
      if (route?.routeType === RouteTypeEnum.ExternalLink) {
        route.routeLink.unshift(env[route.externalUrlKey]);
      }
      return route;
    }

    return {
      [GlobalSearchTypeEnum.Campaigns]: {
        link: processingExternalLink(
          appRotes.find(route =>
            route.routeId === RouteItemEnum.CampaignSpecificLeads &&
            route.routeType !== RouteTypeEnum.LegacyLink)),
        field: routeData[GlobalSearchTypeEnum.Campaigns].field
      },
      [GlobalSearchTypeEnum.Opportunities]: {
        link: processingExternalLink(
          appRotes.find(route =>
            route.routeId === RouteItemEnum.OpportunitySpecificTotalTouches &&
            route.routeType !== RouteTypeEnum.LegacyLink)),
        field: routeData[GlobalSearchTypeEnum.Opportunities].field
      },
      [GlobalSearchTypeEnum.Accounts]: {
        link: processingExternalLink(appRotes.find(route =>
            route.routeId === RouteItemEnum.AccountSpecificAttribution &&
            route.routeType !== RouteTypeEnum.LegacyLink)),
        field: routeData[GlobalSearchTypeEnum.Accounts].field
      },
      [GlobalSearchTypeEnum.Channels]: {
        link: processingExternalLink(
          appRotes.find(route =>
            route.routeId === RouteItemEnum.WebActivitiesList &&
            route.routeType !== RouteTypeEnum.LegacyLink)),
        field: routeData[GlobalSearchTypeEnum.Channels].field
      }
    }
  }
);

export const getOptions = createSelector(
  UserSelectors.getHasReportAccessByName(RouteItemEnum.WebTracking),
  (hasWebActivity) => hasWebActivity !== null
    ? [{
          value: [
            GlobalSearchTypeEnum.Opportunities,
            GlobalSearchTypeEnum.Campaigns,
            GlobalSearchTypeEnum.Accounts,
            ...(hasWebActivity
              ? [GlobalSearchTypeEnum.Channels]
              : [])
          ],
          label: 'shared.ui.reportCheckboxSelector.all'
        }, {
          value: [GlobalSearchTypeEnum.Opportunities],
          label: SearchTypeLabels[GlobalSearchTypeEnum.Opportunities],
        }, {
          value: [GlobalSearchTypeEnum.Campaigns],
          label: SearchTypeLabels[GlobalSearchTypeEnum.Campaigns],
        }, {
          value: [GlobalSearchTypeEnum.Accounts],
          label: SearchTypeLabels[GlobalSearchTypeEnum.Accounts],
        }].concat(hasWebActivity ? [{
          value: [GlobalSearchTypeEnum.Channels],
          label: SearchTypeLabels[GlobalSearchTypeEnum.Channels],
        }] : [])
    : []
);
