/* istanbul ignore file */
import { Action, createAction, props } from '@ngrx/store';

import * as interfaces from '../../interfaces';
import { SourceActionsTypes } from '../source/source.actions';

export enum TotalsActionsTypes {
  LoadMetaDataTotal = '[Totals] Load MetaData Total',
  LoadMetaDataTotalSuccess = '[Totals] Load MetaData Total Success',
  LoadMetaDataTotalFailure = '[Totals] Load MetaData Total Failure',
  LoadResponsesTotal = '[Totals] Load Responses Total',
  LoadResponsesTotalSuccess = '[Totals] Load Responses Total Success',
  LoadResponsesTotalFailure = '[Totals] Load Responses Total Failure',
  LoadAttributionTotal = '[Totals] Load Attribution Total',
  LoadAttributionTotalSuccess = '[Totals] Load Attribution Total Success',
  LoadAttributionTotalFailure = '[Totals] Load Attribution Total Failure',
  LoadInfluenceTotal = '[Totals] Load Influence Total',
  LoadInfluenceTotalSuccess = '[Totals] Load Influence Total Success',
  LoadInfluenceTotalFailure = '[Totals] Load Influence Total Failure',
  LoadReturnsTotal = '[Totals] Load Returns Total',
  LoadReturnsTotalSuccess = '[Totals] Load Returns Total Success',
  LoadReturnsTotalFailure = '[Totals] Load Returns Total Failure',
  LoadCostsTotal = '[Totals] Load Costs Total',
  LoadCostsTotalSuccess = '[Totals] Load Costs Total Success',
  LoadCostsTotalFailure = '[Totals] Load Costs Total Failure',
  LoadWebActivitiesResponsesTotal = '[Totals] Load WebActivities Responses Total',
  LoadWebActivitiesResponsesTotalSuccess = '[Totals] Load WebActivities Responses Total Success',
  LoadWebActivitiesResponsesTotalFailure = '[Totals] Load WebActivities Responses Total Failure',
  LoadWebActivitiesAttributionTotal = '[Totals] Load WebActivities Attribution Total',
  LoadWebActivitiesAttributionTotalSuccess = '[Totals] Load WebActivities Attribution Total Success',
  LoadWebActivitiesAttributionTotalFailure = '[Totals] Load WebActivities Attribution Total Failure',
  LoadWebActivitiesInfluenceTotal = '[Totals] Load WebActivities Influence Total',
  LoadWebActivitiesInfluenceTotalSuccess = '[Totals] Load WebActivities Influence Total Success',
  LoadWebActivitiesInfluenceTotalFailure = '[Totals] Load WebActivities Influence Total Failure',
  LoadWebActivitiesReturnsTotal = '[Totals] Load WebActivities Returns Total',
  LoadWebActivitiesReturnsTotalSuccess = '[Totals] Load WebActivities Returns Total Success',
  LoadWebActivitiesReturnsTotalFailure = '[Totals] Load WebActivities Returns Total Failure',
  LoadWebActivitiesCostsTotal = '[Totals] Load WebActivities Costs Total',
  LoadWebActivitiesCostsTotalSuccess = '[Totals] Load WebActivities Costs Total Success',
  LoadWebActivitiesCostsTotalFailure = '[Totals] Load WebActivities Costs Total Failure',
  LoadSourceResponsesTotal = '[Totals] Load Source Responses Total',
  LoadSourceResponsesTotalSuccess = '[Totals] Load Source Responses Total Success',
  LoadSourceResponsesTotalFailure = '[Totals] Load Source Responses Total Failure',
  LoadSourceAttributionTotal = '[Totals] Load Source Attribution Total',
  LoadSourceAttributionTotalSuccess = '[Totals] Load Source Attribution Total Success',
  LoadSourceAttributionTotalFailure = '[Totals] Load Source Attribution Total Failure',
  LoadSourceInfluenceTotal = '[Totals] Load Source Influence Total',
  LoadSourceInfluenceTotalSuccess = '[Totals] Load Source Influence Total Success',
  LoadSourceInfluenceTotalFailure = '[Totals] Load Source Influence Total Failure',
  LoadSourceReturnsTotal = '[Totals] Load Source Returns Total',
  LoadSourceReturnsTotalSuccess = '[Totals] Load Source Returns Total Success',
  LoadSourceReturnsTotalFailure = '[Totals] Load Source Returns Total Failure',
  LoadSourceCostsTotal = '[Totals] Load Source Costs Total',
  LoadSourceCostsTotalSuccess = '[Totals] Load Source Costs Total Success',
  LoadSourceCostsTotalFailure = '[Totals] Load Source Costs Total Failure',
  ResetTotalData = '[Totals] Reset Total Data',
}

const loadMetaDataTotal = createAction(
  TotalsActionsTypes.LoadMetaDataTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadMetaDataTotalSuccess = createAction(
  TotalsActionsTypes.LoadMetaDataTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignMetaDataTotalsData>>()
);

const loadMetaDataTotalFailure = createAction(
  TotalsActionsTypes.LoadMetaDataTotalFailure,
  props<{ message: string }>()
);

const loadResponsesTotal = createAction(
  TotalsActionsTypes.LoadResponsesTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadResponsesTotalSuccess = createAction(
  TotalsActionsTypes.LoadResponsesTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignResponsesTotalData>>()
);

const loadResponsesTotalFailure = createAction(
  TotalsActionsTypes.LoadResponsesTotalFailure,
  props<{ message: string }>()
);

const loadAttributionTotal = createAction(
  TotalsActionsTypes.LoadAttributionTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadAttributionTotalSuccess = createAction(
  TotalsActionsTypes.LoadAttributionTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignAttributionTotalsData>>()
);

const loadAttributionTotalFailure = createAction(
  TotalsActionsTypes.LoadAttributionTotalFailure,
  props<{ message: string }>()
);

const loadInfluenceTotal = createAction(
  TotalsActionsTypes.LoadInfluenceTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadInfluenceTotalSuccess = createAction(
  TotalsActionsTypes.LoadInfluenceTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignInfluenceTotalsData>>()
);

const loadInfluenceTotalFailure = createAction(
  TotalsActionsTypes.LoadInfluenceTotalFailure,
  props<{ message: string }>()
);

const loadReturnsTotal = createAction(
  TotalsActionsTypes.LoadReturnsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadReturnsTotalSuccess = createAction(
  TotalsActionsTypes.LoadReturnsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignReturnsTotalsData>>()
);

const loadReturnsTotalFailure = createAction(
  TotalsActionsTypes.LoadReturnsTotalFailure,
  props<{ message: string }>()
);

const loadCostsTotal = createAction(
  TotalsActionsTypes.LoadCostsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadCostsTotalSuccess = createAction(
  TotalsActionsTypes.LoadCostsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignCostsTotalsData>>()
);

const loadCostsTotalFailure = createAction(
  TotalsActionsTypes.LoadCostsTotalFailure,
  props<{ message: string }>()
);

const loadWebActivitiesResponsesTotal = createAction(
  TotalsActionsTypes.LoadWebActivitiesResponsesTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadWebActivitiesResponsesTotalSuccess = createAction(
  TotalsActionsTypes.LoadWebActivitiesResponsesTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignResponsesTotalData>>()
);

const loadWebActivitiesResponsesTotalFailure = createAction(
  TotalsActionsTypes.LoadWebActivitiesResponsesTotalFailure,
  props<{ message: string }>()
);

const loadWebActivitiesAttributionTotal = createAction(
  TotalsActionsTypes.LoadWebActivitiesAttributionTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadWebActivitiesAttributionTotalSuccess = createAction(
  TotalsActionsTypes.LoadWebActivitiesAttributionTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignAttributionTotalsData>>()
);

const loadWebActivitiesAttributionTotalFailure = createAction(
  TotalsActionsTypes.LoadWebActivitiesAttributionTotalFailure,
  props<{ message: string }>()
);

const loadWebActivitiesInfluenceTotal = createAction(
  TotalsActionsTypes.LoadWebActivitiesInfluenceTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadWebActivitiesInfluenceTotalSuccess = createAction(
  TotalsActionsTypes.LoadWebActivitiesInfluenceTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignInfluenceTotalsData>>()
);

const loadWebActivitiesInfluenceTotalFailure = createAction(
  TotalsActionsTypes.LoadWebActivitiesInfluenceTotalFailure,
  props<{ message: string }>()
);

const loadWebActivitiesReturnsTotal = createAction(
  TotalsActionsTypes.LoadWebActivitiesReturnsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadWebActivitiesReturnsTotalSuccess = createAction(
  TotalsActionsTypes.LoadWebActivitiesReturnsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignReturnsTotalsData>>()
);

const loadWebActivitiesReturnsTotalFailure = createAction(
  TotalsActionsTypes.LoadWebActivitiesReturnsTotalFailure,
  props<{ message: string }>()
);

const loadWebActivitiesCostsTotal = createAction(
  TotalsActionsTypes.LoadWebActivitiesCostsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadWebActivitiesCostsTotalSuccess = createAction(
  TotalsActionsTypes.LoadWebActivitiesCostsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignCostsTotalsData>>()
);

const loadWebActivitiesCostsTotalFailure = createAction(
  TotalsActionsTypes.LoadWebActivitiesCostsTotalFailure,
  props<{ message: string }>()
);

const loadSourceResponsesTotal = createAction(
  TotalsActionsTypes.LoadSourceResponsesTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadSourceResponsesTotalSuccess = createAction(
  TotalsActionsTypes.LoadSourceResponsesTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignResponsesTotalData>>()
);

const loadSourceResponsesTotalFailure = createAction(
  TotalsActionsTypes.LoadSourceResponsesTotalFailure,
  props<{ message: string }>()
);

const loadSourceAttributionTotal = createAction(
  TotalsActionsTypes.LoadSourceAttributionTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadSourceAttributionTotalSuccess = createAction(
  TotalsActionsTypes.LoadSourceAttributionTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignAttributionTotalsData>>()
);

const loadSourceAttributionTotalFailure = createAction(
  TotalsActionsTypes.LoadSourceAttributionTotalFailure,
  props<{ message: string }>()
);

const loadSourceInfluenceTotal = createAction(
  TotalsActionsTypes.LoadSourceInfluenceTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadSourceInfluenceTotalSuccess = createAction(
  TotalsActionsTypes.LoadSourceInfluenceTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignInfluenceTotalsData>>()
);

const loadSourceInfluenceTotalFailure = createAction(
  TotalsActionsTypes.LoadSourceInfluenceTotalFailure,
  props<{ message: string }>()
);

const loadSourceReturnsTotal = createAction(
  TotalsActionsTypes.LoadSourceReturnsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadSourceReturnsTotalSuccess = createAction(
  TotalsActionsTypes.LoadSourceReturnsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignReturnsTotalsData>>()
);

const loadSourceReturnsTotalFailure = createAction(
  TotalsActionsTypes.LoadSourceReturnsTotalFailure,
  props<{ message: string }>()
);

const loadSourceCostsTotal = createAction(
  TotalsActionsTypes.LoadSourceCostsTotal,
  props<{payload: interfaces.LoadTotalsPayload}>()
);

const loadSourceCostsTotalSuccess = createAction(
  TotalsActionsTypes.LoadSourceCostsTotalSuccess,
  props<interfaces.ITotalsSuccessPayload<interfaces.ICampaignCostsTotalsData>>()
);

const loadSourceCostsTotalFailure = createAction(
  TotalsActionsTypes.LoadSourceCostsTotalFailure,
  props<{ message: string }>()
);

const resetTotalData = createAction(
  TotalsActionsTypes.ResetTotalData,
);

export const totalsActions = {
  loadMetaDataTotal,
  loadMetaDataTotalSuccess,
  loadMetaDataTotalFailure,
  loadResponsesTotal,
  loadResponsesTotalSuccess,
  loadResponsesTotalFailure,
  loadAttributionTotal,
  loadAttributionTotalSuccess,
  loadAttributionTotalFailure,
  loadInfluenceTotal,
  loadInfluenceTotalSuccess,
  loadInfluenceTotalFailure,
  loadReturnsTotal,
  loadReturnsTotalSuccess,
  loadReturnsTotalFailure,
  loadCostsTotal,
  loadCostsTotalSuccess,
  loadCostsTotalFailure,
  loadWebActivitiesResponsesTotal,
  loadWebActivitiesResponsesTotalSuccess,
  loadWebActivitiesResponsesTotalFailure,
  loadWebActivitiesAttributionTotal,
  loadWebActivitiesAttributionTotalSuccess,
  loadWebActivitiesAttributionTotalFailure,
  loadWebActivitiesInfluenceTotal,
  loadWebActivitiesInfluenceTotalSuccess,
  loadWebActivitiesInfluenceTotalFailure,
  loadWebActivitiesReturnsTotal,
  loadWebActivitiesReturnsTotalSuccess,
  loadWebActivitiesReturnsTotalFailure,
  loadWebActivitiesCostsTotal,
  loadWebActivitiesCostsTotalSuccess,
  loadWebActivitiesCostsTotalFailure,
  loadSourceResponsesTotal,
  loadSourceResponsesTotalSuccess,
  loadSourceResponsesTotalFailure,
  loadSourceAttributionTotal,
  loadSourceAttributionTotalSuccess,
  loadSourceAttributionTotalFailure,
  loadSourceInfluenceTotal,
  loadSourceInfluenceTotalSuccess,
  loadSourceInfluenceTotalFailure,
  loadSourceReturnsTotal,
  loadSourceReturnsTotalSuccess,
  loadSourceReturnsTotalFailure,
  loadSourceCostsTotal,
  loadSourceCostsTotalSuccess,
  loadSourceCostsTotalFailure,
  resetTotalData,
};

export type TotalsLoadActions = {payload: interfaces.LoadTotalsPayload} & Action;
