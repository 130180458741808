import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TsDateRangeModule } from '@terminus-lib/ui-date-range';
import { TsOptionModule } from '@terminus-lib/ui-option';
import { TsSelectionListModule } from '@terminus-lib/ui-selection-list';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TranslateModule } from '@ngx-translate/core';

import { DateCohortsComponent } from './date-cohorts.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TsDateRangeModule,
    TsSelectionListModule,
    TsOptionModule,
    TsButtonModule,
    TranslateModule,
  ],
  declarations: [DateCohortsComponent],
  exports: [DateCohortsComponent],
})
export class DateCohortsModule {
}
