import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { globalFiltersActions } from '../state/global-filters.actions';
import { GlobalFiltersState } from '../state/global-filters.reducer';
import * as selectors from '../state/global-filters.selectors';

@Injectable()
export class GlobalFiltersResolver implements Resolve<boolean> {
  constructor(private store: Store<GlobalFiltersState>) {
    this.store.pipe(
      select(selectors.getInitialDataLoaded),
      filter((loaded: boolean) => !loaded),
      take(1),
    ).subscribe(() => {
      this.store.dispatch(globalFiltersActions.getDataGlobalFilters());
      this.store.dispatch(globalFiltersActions.getSavedFiltersData());
    });
  }

  resolve(): Observable<boolean> {
    return this.store.pipe(
      select(selectors.getInitialDataLoaded),
      filter((loaded: boolean) => loaded),
      take(1),
    );
  }
}
