<tsh-table-panel (download)="emitDownload()"
                 totalTitle="feature.sharedTrending.comparativeTable.cohorts"
                 [noResult]="!report?.length"
                 [searchEnabled]="false"
                 [totalCount]="data?.length"
                 [showColumns]="false">
</tsh-table-panel>
<div class="table-container">
  <table *ngIf="columns?.length"
         ts-table
         #campaignTable="tsTable"
         class="campaign-table"
         [columns]="columns"
         [dataSource]="dataSource">
    <ng-container tsColumnDef="type" alignment="left">
      <th ts-header-cell *tsHeaderCellDef></th>
      <td ts-cell *tsCellDef="let item">
        <div class="color-block">
          <span [style.background-color]="item.color"></span>
          {{ item.title | translate }}
        </div>
      </td>
      <td ts-footer-cell *tsFooterCellDef>
        {{ totalTitle | translate  }}
      </td>
    </ng-container>
    <ng-container *ngFor="let column of columns.slice(1); let colIxd = index;">
      <ng-container [tsColumnDef]="column.name" alignment="left">
        <th *tsHeaderCellDef ts-header-cell>
          <ts-tooltip
            [tooltipValue]="getTooltip(colIxd)"
            [tooltipPosition]="'above'"
            [hasUnderline]="false">
            {{ column.displayName }}
          </ts-tooltip>
        </th>
        <td ts-cell *tsCellDef="let item; let rowIdx = index; let even = even"
            class="selectable"
            [ngClass]="{active: report && report[colIxd]?.id === item['selectedId']}"
            (click)="emitSelected(report[colIxd], item['selectedId'])">
          {{ (even ? report[colIxd]?.valueNow : report[colIxd]?.valueThen) | typedData : column.dataType }}
        </td>
        <td ts-footer-cell *tsFooterCellDef>
                    <span [ngClass]="report[colIxd]?.diffLabel">
                            <i class="fas fa-sm fa-arrow-right"
                               *ngIf="report[colIxd]?.diffLabel">
                            </i>
                      {{ report[colIxd]?.diffPercent | typedData: dataTypes.Percent : 0 : false }}
                    </span>
        </td>
      </ng-container>
    </ng-container>
    <tr ts-header-row *tsHeaderRowDef="campaignTable.columnNames;"></tr>
    <tr ts-row *tsRowDef="let row; columns: campaignTable.columnNames;"></tr>
    <!-- hide footer if type is trend-->
    <tr ts-footer-row *tsFooterRowDef="campaignTable.columnNames;"
        [ngClass]="{'hidden': !totalTitle}"></tr>
  </table>
</div>
<tsh-pagination *ngIf="countData > perPage"
                [currentPageIndex]="currentPage"
                [totalRecords]="countData"
                (pageSelect)="emitPageChange($event)">
</tsh-pagination>
