export const unescapeColumnName = (str: string): string => {
  if (str[0] === "'" && str[str.length - 1] === "'") {
    return str.slice(1, -1);
  }
  return str;
}

export function dequotizeObjectKeys<T>(obj: T): T {
  return Object.entries(obj).reduce((result, entry): T => {
    const newKey = unescapeColumnName(entry[0]);
    result[newKey] = entry[1];
    return result;
  }, {} as T);
}
