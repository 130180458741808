import { Action, createReducer, on } from '@ngrx/store';
import { scorecardActions } from './scorecard.actions';

export const scorecardFeatureKey = 'scorecard';

export interface ScorecardState {
  canAddList: boolean | null;
}

export const initialState: ScorecardState = {
  canAddList: null
};

const scorecardReducer = createReducer(
  initialState,
  on(
    scorecardActions.loadCanAddListFlagSuccess,
    (state, action) => ({
      ...state,
      canAddList: action.flag
    })
  ),
  on(
    scorecardActions.resetData,
      // reset data to get it then again
      // this is necessary after adding/updating/removing scorecard list
    (state) => ({
      ...state,
      canAddList: null
    })
  )
);

export function reducer(state = initialState, action: Action): ScorecardState {
  return scorecardReducer(state, action);
}

