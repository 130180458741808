<ts-card>
  <div class="container">
    <div *ngIf="data.icon && data.type" class="container-icon">
      <i class="fad fa-3x"
         [ngClass]="[data.icon, data.type]">
      </i>
    </div>
    <span class="container-message"
          [ngClass]="[data.type]"
          data-cy="notification-message">
      {{data.message | translate}}
    </span>
    <button *ngIf="data?.action"
            data-cy="notification-action"
            type="button"
            class="container-action"
            (click)="dismiss()">
      {{data?.action}}
    </button>
  </div>
</ts-card>
