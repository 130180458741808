import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { IDateCohort, IDateCohortGroup, ILabelValue } from '@shared/interfaces';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { ICampaignSpecificFilters } from '@measurement-studio/interfaces';

@Component({
    selector: 'ms-campaign-filters',
    templateUrl: './campaign-filters.component.html',
    styleUrls: ['./campaign-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignAttributionFiltersComponent implements OnChanges {
    @Input() public filters: ICampaignSpecificFilters;
    @Input() public dateCohortOptions: IDateCohortGroup[] = [];
    @Input() public selectedDateCohort: IDateCohort;
    @Input() public modelTypeOptions: ILabelValue[];
    @Output() public setSelectedDateCohort = new EventEmitter<IDateCohort>();
    @Output() public apply = new EventEmitter<ICampaignSpecificFilters>();

    public dateTypeOptions = new DataSetOptions().getCampaignDataOptions();
    public influenceTypeOptions = INFLUENCE_TYPE_OPTIONS;

    public filterForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
    ) {
        this.filterForm = this.formBuilder.group({
            dataSet: [this.filters?.dataSet, Validators.required],
            influenceType: [this.filters?.influenceType, Validators.required],
            modelType: [this.filters?.modelType, Validators.required],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filters && this.filters) {
            this.filterForm.patchValue({
                dataSet: this.filters.dataSet,
                influenceType: this.filters.influenceType,
                modelType: this.filters.modelType
            });
        }
    }
    /**
     * Apply change for date cohort only
     */
    applyDateCohort(dateCohort?: IDateCohort): void {
        this.apply.emit({
            ...this.filters,
            cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
            endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
            startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
        });
    }

    changeDateCohort(date: IDateCohort): void {
        this.setSelectedDateCohort.emit(date);
    }

    emitApply(): void {
        this.apply.emit({
            ...this.filters,
            ...this.filterForm.value,
        });
    }
}
