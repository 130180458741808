import { Injectable } from '@angular/core';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { Observable } from 'rxjs';

import { IGetUnifiedCustomerIdForCOURequest, IGetUnifiedCustomerIdForCOUResponse } from '../../interfaces/customer-entitlement.interface';
import { IAuthToken } from '@shared/interfaces';
import { CustomerEntitlementSource } from '../../sources/customer-entitlement/customer-entitlement.source';

@Injectable({
  providedIn: 'root',
})
export class CustomerEntitlementService {
  constructor(
    public retry: RetryWithEscalation,
    public source: CustomerEntitlementSource,
  ) {}

  authorize$(): Observable<IAuthToken> {
    return regenerateOnRetry(() => this.source.authorize$());
  }

  getClosestUnifiedCustomerIdForCOU$(params: IGetUnifiedCustomerIdForCOURequest): Observable<IGetUnifiedCustomerIdForCOUResponse> {
    return regenerateOnRetry(() => this.source.getClosestUnifiedCustomerIdForCOU$(params));
  }
}
