import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { TsMenuComponent } from '@terminus-lib/ui-menu';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

import { ILabelValueChecked } from '@shared/interfaces';
import { ReportFilterChange } from '@shared/classes';
import { debounceTime } from 'rxjs/operators';

@WithDestroy
@Component({
  selector: 'tsh-report-checkbox-selector',
  templateUrl: './report-checkbox-selector.component.html',
  styleUrls: ['./report-checkbox-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCheckboxSelectorComponent<T = string> extends ReportFilterChange implements OnInit, OnChanges {
  @Input() public field: FormControl;
  @Input() public options: ILabelValueChecked<T>[];
  // show selected options for dynamic data
  @Input() public selectedOptions: ILabelValueChecked<T>[] = [];
  @Input() public label = 'Default Label';
  @Input() public tooltip?: string;
  @Input() public showSearch = false;
  @Input() public allLabel = 'shared.ui.reportCheckboxSelector.all';
  @Input() public noneLabel = 'shared.ui.reportCheckboxSelector.none';
  @Input() public selectedLabel = 'shared.ui.reportCheckboxSelector.numberSelected';
  @Input() public enableSelectAllOption = false;
  @Output() public search = new EventEmitter<string>();
  @Output() public confirm = new EventEmitter<void>();
  @Output() public changed = new EventEmitter<ILabelValueChecked<T>>();
  @Output() public cancel = new EventEmitter<void>();

  @ViewChild(TsMenuComponent) public menu!: TsMenuComponent;

  public allSelected = false;
  public labelSelected = 'shared.ui.reportCheckboxSelector.none';
  public params: { [key: string]: number } = {};
  public queryControl = new FormControl('');

  ngOnInit() {
    this.queryControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(400) // set debounce
    ).subscribe(() => {
      this.search.emit(this.queryControl.value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options?.currentValue) {
      if (!this.showSearch) {
        // need this functionality only for non dynamic filter
        this.setOptionsChecked();
      }
      this.setSelectedLabel();
    }

    if (changes.selectedOptions?.currentValue) {
      this.setSelectedLabel();
    }
  }

  changeOption(item: ILabelValueChecked<T>): void {
    item.checked = !item.checked;
    // to support non dynamic filter
    if (!this.showSearch) {
      this.setSelectedLabel();
    }
    this.changed.emit(item);
  }

  confirmDialog(): void {
    // get checked items from selectedOptions and options arrays
    const onlySelectedOptions = [
      ...this.selectedOptions, // will be only for dynamic filter
      // in case of dynamic data there won't be checked options
      // but we have to keep them for non dynamic data
      ...this.options.filter(item => item.checked)
    ];
    this.field.setValue(onlySelectedOptions.map(item => item.value));
    this.resetSearchQuery();
    // mark that the data in the filter was changed to show pending status
    this.change$.next(true);
    this.menu.trigger.closeMenu();
    this.confirm.emit();
  }

  cancelDialog(): void {
    if (this.showSearch) {
      this.selectedOptions = this.selectedOptions.map(item => ({
        ...item,
        checked: true
      }));
      this.options = this.options.map(item => ({
        ...item,
        checked: false
      }));
      this.resetSearchQuery();
    } else {
      this.selectedOptions = [];
      this.setOptionsChecked();
    }
    this.setSelectedLabel();
    this.cancel.emit();
    this.menu.trigger.closeMenu();
  }

  selectedAllOption(): void {
    this.options = this.options.map(item => ({
      ...item,
      checked: !this.allSelected,
    }));
    this.setSelectedLabel();
  }

  setSelectedLabel(): void {
    const selected = [
      ...this.selectedOptions, // will be only for dynamic filter
      ...this.options.filter(item => item.checked)
    ];
    const allLength = this.selectedOptions.length + this.options.length;
    if (selected.length === 1) {
      this.labelSelected = selected[0].label;
    } else if (selected.length > 1 && selected.length < allLength) {
      this.labelSelected = this.selectedLabel;
      this.params = {number: selected.length};
    } else if (selected.length
      && selected.length === allLength
      && this.selectedOptions.length
      && !this.options.length) {
      // if there are only selected options and no all options then show
      // how much options are selected instead of All
      this.labelSelected = this.selectedLabel;
      this.params = {number: selected.length};
    } else if (selected.length && selected.length === allLength && this.options.length) {
      this.labelSelected = this.allLabel;
    } else {
      this.labelSelected = this.noneLabel;
    }
    this.allSelected = selected.length === allLength;
  }

  setOptionsChecked(): void {
    this.options = this.options.map(item => ({
      ...item,
      checked: this.field.value?.indexOf(item.value) >= 0
    }));
  }

  resetSearchQuery(): void {
    this.queryControl.setValue('');
  }
}
