/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';

export const CampaignsActionTypes = {
  GetCampaignTypes: '[Campaigns] Get Campaign types',
  LoadCampaignTypes: '[Campaigns API] Load Campaign types',
  LoadCampaignTypesSuccess: '[Campaigns API] Load Campaign types Success',
  LoadCampaignTypesFailure: '[Campaigns API] Load Campaign types Failure',
  SetCampaignTypesColor: '[Campaigns] Set Campaign types color map',
};

const getCampaignTypes = createAction(CampaignsActionTypes.GetCampaignTypes);

const loadCampaignTypes = createAction(
  CampaignsActionTypes.LoadCampaignTypes
);

const loadCampaignTypesSuccess = createAction(
  CampaignsActionTypes.LoadCampaignTypesSuccess,
  props<{ types: string[] }>()
);

const loadCampaignTypesFailure = createAction(
  CampaignsActionTypes.LoadCampaignTypesFailure,
  props<{ error: string }>()
);

const setCampaignTypesColor = createAction(
  CampaignsActionTypes.SetCampaignTypesColor,
  props<{ colorMap: Record<string, string> }>()
);

export const campaignsActions = {
  getCampaignTypes,
  loadCampaignTypes,
  loadCampaignTypesSuccess,
  loadCampaignTypesFailure,
  setCampaignTypesColor,
}
