import { Action, createReducer, on } from '@ngrx/store';

import { TsSortState } from '@terminus-lib/ui-sort';

import { ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';
import { AttributionUsedQueryParams, ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { IDateCohort } from '@shared/interfaces';
import { IAttributionCampaignExpandedRow } from '../interfaces/attribution-campaign-report.interface';
import { IAttributionCampaignPagination } from '../interfaces/attribution-campaign-pagination.interface';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../attribution-campaign.constant';
import { attributionCampaignActions } from './attribution-campaign.actions';
import { SortDirections } from '@shared/enums';

export const attributionCampaignFeatureKey = 'attributionByCampaignType';

// Get default date cohort
const date = new Date();
const defaultDateCohort = {
  cohort: 'quarter2Date',
  name: 'quarter to date',
  startDate: date.getTime(),
  endDate: date.getTime()
};

export interface AttributionCampaignState {
  campaignPerformance: ICampaignPerformance;
  totalAmount: ICampaignTotalAmount;
  filters: ICampaignFilters;
  usedQueryParams: AttributionUsedQueryParams;
  isLoading: boolean;
  isLoadingTotal: boolean;
  isDownloadingCsv: boolean;
  selectedDateCohort: IDateCohort;
  expandedReport: Record<string, IAttributionCampaignExpandedRow[]>;
  expandedRowState: Record<string, boolean>;
  expandedRowLoading: Record<string, boolean>;
  reportSortState: TsSortState;
  expandedReportSortState: Record<string, TsSortState>;
  expandedReportPagination: Record<string, IAttributionCampaignPagination>; // key and pagination data
}

export const initialState: AttributionCampaignState = {
  campaignPerformance: null,
  totalAmount: null,
  filters: null,
  usedQueryParams: null,
  isLoading: false,
  isLoadingTotal: false,
  isDownloadingCsv: false,
  selectedDateCohort: defaultDateCohort,
  expandedReport: {},
  expandedRowState: {},
  expandedRowLoading: {},
  reportSortState: {
    active: '',
    direction: SortDirections.Asc
  },
  expandedReportSortState: {},
  expandedReportPagination: {}
};

const attributionCampaignReducer = createReducer(
  initialState,
  on(attributionCampaignActions.loadCampaignPerformanceSuccess, (state, action) => ({
    ...state,
    campaignPerformance: action.data,
    expandedReport: {}, // reset expanded report
    expandedRowState: {}, // reset expanded report
    expandedRowLoading: {}, // reset expanded report,
    expandedReportSortState: {},
    expandedReportPagination: {},
    isLoading: false
  })),
  on(attributionCampaignActions.loadTotalAmountSuccess, (state, action) => ({
    ...state,
    totalAmount: action.data,
    isLoadingTotal: false
  })),
  on(attributionCampaignActions.setAttributionUsedQueryParams, (state, action) => ({
    ...state,
    usedQueryParams: action.params
  })),
  on(attributionCampaignActions.setAttributionCampaignFilters, (state, action) => ({
    ...state,
    filters: action.filters
  })),
  on(attributionCampaignActions.loadCampaignPerformanceFailure, state => ({
    ...state,
    isLoading: false
  })),
  on(attributionCampaignActions.loadTotalAmountFailure, state => ({
    ...state,
    isLoadingTotal: false
  })),
  on(attributionCampaignActions.loadCampaignPerformance, state => ({
    ...state,
    isLoading: true
  })),
  on(attributionCampaignActions.loadTotalAmount, state => ({
    ...state,
    isLoadingTotal: true
  })),
  on(attributionCampaignActions.setAttributionCampaignDateCohort, (state, action) => ({
    ...state,
    selectedDateCohort: action.date
  })),
  on(attributionCampaignActions.toggleExpandedAttributionCampaignState, (state, action) => ({
    ...state,
    expandedRowState: {
      ...state.expandedRowState,
      [action.key]: action.state
    }
  })),
  on(attributionCampaignActions.collectAttributionCampaignRowData, (state, action) => ({
    ...state,
    expandedRowLoading: {
      ...state.expandedRowLoading,
      [action.key]: true
    }
  })),
  on(attributionCampaignActions.setAttributionCampaignExtendedRow, (state, action) => ({
    ...state,
    expandedRowState: {
      ...state.expandedRowState,
      [action.key]: true // open row
    },
    expandedRowLoading: {
      ...state.expandedRowLoading,
      [action.key]: false // stop loading process for this row
    },
    expandedReport: {
      ...state.expandedReport,
      [action.key]: action.data // row data
    },
    expandedReportPagination: {
      ...state.expandedReportPagination,
      [action.key]: {
        page: DEFAULT_PAGE,
        perPage: DEFAULT_PER_PAGE
      }
    }
  })),
  on(attributionCampaignActions.setAttributionReportSortState, (state, action) => ({
    ...state,
    reportSortState: action.state
  })),
  on(attributionCampaignActions.setAttributionExpandedReportSortState, (state, action) => ({
    ...state,
    expandedReportSortState: {
      ...state.expandedReportSortState,
      [action.key]: action.state
    }
  })),
  on(attributionCampaignActions.setAttributionExpandedReportPage, (state, action) => ({
    ...state,
    expandedReportPagination: {
      ...state.expandedReportPagination,
      [action.key]: {
        page: action.page,
        perPage: DEFAULT_PER_PAGE
      }
    }
  })),
  on(attributionCampaignActions.downloadCSV, attributionCampaignActions.downloadCSVForExpandedReport, state => ({
    ...state,
    isDownloadingCsv: true
  })),
  on(attributionCampaignActions.downloadCSVSuccess, attributionCampaignActions.downloadCSVForExpandedReportSuccess, state => ({
    ...state,
    isDownloadingCsv: false
  }))
);

export function reducer(state: AttributionCampaignState | undefined, action: Action): AttributionCampaignState {
  return attributionCampaignReducer(state, action);
}
