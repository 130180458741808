import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ICampaignFilters } from '../../interfaces/campaign-filters.interface';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { revenueTypeOptions } from '../../attribution-campaign.constant';
import { RevenueType } from '../../enums/attribution-campaign.enum';
import { IDateCohort, IDateCohortGroup } from '@shared/interfaces';

@Component({
  selector: 'ms-attribution-campaign-filters',
  templateUrl: './attribution-campaign-filters.component.html',
  styleUrls: ['./attribution-campaign-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributionCampaignFiltersComponent implements OnChanges {
  @Input() public filters: ICampaignFilters = {};
  @Input() public dateCohortOptions: IDateCohortGroup[] = [];
  @Input() selectedDateCohort: IDateCohort;

  @Output() public apply = new EventEmitter<ICampaignFilters>();
  @Output() public setSelectedDateCohort = new EventEmitter<IDateCohort>();

  public influenceOptions = INFLUENCE_TYPE_OPTIONS;
  public revenueOptions = revenueTypeOptions;
  public filterForm = this.formBuilder.group({
    revenueType: [this.filters.revenueType, Validators.required],
    influenceType: [this.filters.influenceType, Validators.required]
  });
  public types = RevenueType;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && this.filters) {
      this.filterForm.get('revenueType').setValue(this.filters.revenueType);
      this.filterForm.get('influenceType').setValue(this.filters.influenceType);
    }
  }

  emitApply(): void {
    this.apply.emit({
      ...this.filters,
      ...this.filterForm.value
    });
  }

  /**
   * Apply change for date cohort only
   */
  applyDateCohort(dateCohort?: IDateCohort): void {
    this.apply.emit({
      ...this.filters,
      cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
      endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
      startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
    });
  }

  changeDateCohort(date: IDateCohort): void {
    this.setSelectedDateCohort.emit(date);
  }
}
