import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { AggregateList } from '@shared/interfaces';
import { IAggregateListsSource } from './aggregate-lists.source.interface';

export enum AggregateListsURLs {
  GetAggregates = '/v1/get_scorecard_aggregates',
}

@Injectable({
  providedIn: 'root'
})
export class AggregateListsSource extends HttpClientBase implements IAggregateListsSource {

  getAggregates$(params?: any): Observable<AggregateList[]> {
    const endpoint = this.endpoint(AggregateListsURLs.GetAggregates);
    return this.httpClient.post<AggregateList[]>(endpoint, params); // Now passing params in the body of the POST request
  }
}
