<button
  class="c-button c-button--{{ theme }}"
  [ngClass]="{
    'c-button--progress': showProgress && !isDisabled,
    'c-button--small': isSmall
  }"
  [attr.aria-label]="actionName"
  [attr.id]="id"
  [attr.type]="buttonType"
  [disabled]="shouldBeDisabled"
  tabindex="{{ tabIndex }}"
  (click)="clickedButton($event)"
  #button
>
  <span class="c-button__content">
    <span class="c-button__content-ng-content"><ng-content></ng-content></span>
    <span class="c-button__content-input">{{ textContent }}</span>
  </span>

  <span
    class="c-button__spinner"
    *ngIf="showProgress && !isDisabled"
    [ngClass]="{'c-button__spinner--active': showProgress && !isDisabled}"
  >
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </span>
</button>
