import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, distinctUntilChanged, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';

import { StagesSnapshotService } from '../services/stages-snapshot.service';
import { stagesSnapshotActions } from './stages-snapshot.actions';
import * as selectors from './stages-snapshot.selectors';
import * as DateCohortsStore from '@date-cohorts';
import { DataTypeEnum, DateCohortsGroups } from '@shared/enums';
import { getStartAndEndDateFromParams } from '@util/helpers';
import * as OrgConfigStore from '@org-config';
import { IReportColumn } from '@shared/interfaces';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { IFlatStageProgressionDetail } from '../interfaces';

@Injectable()
export class StagesSnapshotEffects {
  constructor(private store: Store<unknown>,
              private stagesSnapshotService: StagesSnapshotService,
              private actions$: Actions,
              private translate: TranslateService,
              private downloadService: DownloadCsvService) {
  }

  public onFilterChange$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.setFilters),
    map(action => action.request),
    mergeMap(filters => {
      return this.store.pipe(select(DateCohortsStore.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => {
          if (filters.cohort === DateCohortsGroups.Custom) {
            const customCohort = {
              name: 'Custom Range',
              cohort: filters.cohort,
              endDate: filters.endDate,
              startDate: filters.startDate,
            };
            return of(customCohort);
          }

          return this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(filters.cohort)));
        })
      );
    }),
    mergeMap(dateCohort => ([
      stagesSnapshotActions.setSelectedDateCohort({cohort: dateCohort}),
      stagesSnapshotActions.loadStageProgressionData(),
    ]))
  ));


  public onGetFilters$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.getFilters),
    pluck('params'),
    concatLatestFrom(() => this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))),
    distinctUntilChanged(null, ([filters, globalFilters]) => JSON.stringify({...filters, ...globalFilters})),
    map(([params]) => {
      const {cohort, startDate, endDate} = params;
      const dataParams = {
        cohort,
        ...getStartAndEndDateFromParams(cohort, startDate, endDate)
      };
      return stagesSnapshotActions.setFilters({request: dataParams});
    })
  ));


  public onActiveStageChange$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.setActiveStage),
    distinctUntilChanged(null, action => action.stage),
    concatLatestFrom(() => this.store.pipe(select(selectors.getStagesSnapshotStageProgressionDetailData))),
    filter(([action, stageProgressionDetailData]) => action.stage && !stageProgressionDetailData[action.stage]),
    map(([action]) => stagesSnapshotActions.loadStageProgressionDetailData({data: action.stage})),
  ));


  public onLoadStageProgressionData$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.loadStageProgressionData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStagesSnapshotFilters)),
      this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
    ]),
    switchMap(([_, filters, globalFilters]) => this.stagesSnapshotService.getFlatStageProgression$({
      ...filters,
      ...globalFilters,
    }).pipe(
      mergeMap(data => {
        if (data[0]?.name) {
          return [
            stagesSnapshotActions.loadStageProgressionDataSuccess({data}),
            stagesSnapshotActions.loadStageProgressionDetailData({data: data[0].name})
          ];
        }
        return [
          stagesSnapshotActions.loadStageProgressionDataSuccess({data})
        ];
      }),
      catchError((error: HttpErrorResponse) => {
        const message$ = error.message
          ? of(error.message)
          : this.translate.get('measurementStudio.features.stagesSnapshot.errors.getStageProgression');

        return message$.pipe(
          map(message => stagesSnapshotActions.loadStageProgressionDataFailure({message}))
        );
      })
    )),
  ));


  public onLoadStageProgressiontDetailData$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.loadStageProgressionDetailData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStagesSnapshotFilters)),
      this.store.pipe(select(selectors.getStagesSnapshotActiveStage)),
      this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
    ]),
    switchMap(([_, filters, stage, globalFilters]) => this.stagesSnapshotService.getFlatStageProgressionDetail$({
      ...filters,
      stage,
      ...globalFilters,
    }).pipe(
      map(data => stagesSnapshotActions.loadStageProgressionDetailDataSuccess({data})),
      catchError((error: HttpErrorResponse) => {
        const message$ = error.message
          ? of(error.message)
          : this.translate.get('measurementStudio.features.stagesSnapshot.errors.getStageProgressionDetail');

        return message$.pipe(
          map(message => stagesSnapshotActions.loadStageProgressionDetailDataFailure({message}))
        );
      })
    )),
  ));


  public downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(stagesSnapshotActions.downloadCSV),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getActiveStageProgressionDetailData)),
      this.store.pipe(select(selectors.getStagesSnapshotColumns)),
      this.store.pipe(select(selectors.getStagesSnapshotActiveStage)),
      this.store.pipe(select(selectors.getStagesSnapshotFilters)),
      this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
    ]),
    mergeMap(([_, report, columns, activeStage, filters, currency]) => {
      const fileName = `Stages Snapshot-${filters.cohort}-${activeStage}`;
      const translatedColumns = columns.concat(
        [{
          name: 'id',
          width: 100,
          displayName: 'measurementStudio.features.stagesSnapshot.columns.id',
          dataType: DataTypeEnum.Text,
        }]
      )
        .map(column => this.translate.get(column.displayName).pipe(
          map(displayName => ({
            ...column,
            displayName,
          }))
        ));

      return combineLatest([
        of(report),
        combineLatest(translatedColumns),
        of(fileName),
        of(currency),
      ]);
    }),
    map(([report, columns, fileName, currency]: [
      IFlatStageProgressionDetail[], IReportColumn[], string, string | null
    ]) => {
      this.downloadService.downloadCsv(fileName, report, columns, currency);
    }),
  ), {dispatch: false});
}
