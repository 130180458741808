import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { TrendingService } from '../services/trending.service';
import {
  IAppliedGlobalFiltersAsParams,
  IDateCohort,
  IExportToken,
  IReportColumn,
  userMessageFactory
} from '@shared/interfaces';
import { DataTypeEnum, DateCohortsGroups, ModelType, RouteItemEnum, TrendsType } from '@shared/enums';
import {
  IGroupDetail,
  ITrendingCampaignDetails,
  ITrendingDetails,
  ITrendingGroupDetails,
  ITrendingGroupDetailsActionParams,
  ITrendingGroupDetailsParams,
  ITrendingParams,
  ITrendingTotal
} from '../interfaces';
import { UserService } from '@user/user.service';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import * as OrgConfigStore from '@org-config';
import * as TrendingSelectors from './trending.selectors';
import * as TrendingSelector from './trending.selectors';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as DateCohortsStore from '@date-cohorts';
import { getWfStagesForMetricOptions } from '@shared/data-access/catalogs';
import { TrendingDataPeriod } from '../enums';
import { notificationMessagesActions } from '@notification-messages';
import { trendingActions } from './trending.actions';

@Injectable()
export class TrendingEffects {
  constructor(private store: Store<unknown>,
              private trendingService: TrendingService,
              private actions$: Actions,
              private downloadService: DownloadCsvService,
              private translateService: TranslateService,
              private userService: UserService) {
  }


  public loadTrendingTotal$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadTotal),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(TrendingSelectors.getTrendingFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(TrendingSelectors.getSelectedReport)),
      ),
    )),
    mergeMap(([action, filters, globalFilters, selectedReport]) => {
      const period = action.period;
      const isBenchmarkThen = period === TrendingDataPeriod.Then && filters.type === TrendsType.Benchmark;
      // keep previous param only for Benchmark type and Then period
      const updatedFilters = !isBenchmarkThen ? {...filters, previous: null} : filters;
      return this.trendingService.getTotal$({...updatedFilters, ...globalFilters}, selectedReport)
        .pipe(
          map((data: ITrendingTotal) => trendingActions.loadTotalSuccess({data, period})),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'feature.sharedTrending.loadErrorTotal';

            return of(trendingActions.loadTotalFailure({ message, period }));
          })
        );
    })
  ));


  public onLoadTotalSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadTotalSuccess),
    map(action => action),
    filter((payload: { data: ITrendingTotal, period: TrendingDataPeriod }) => !!payload.data?.data?.length),
    // load group details for the first month/week/day in the list after loading total information
    map(payload => trendingActions.loadGroupDetails({
      period: payload.period,
      id: 1
    }))
  ));


  public getTrendingGroupDetails$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.getGroupDetails),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(TrendingSelectors.getTrendingTotalByPeriod(action.period))),
      ),
    )),
    // load group details only if there is frequency
    // index starts from 0, id starts from 1
    // clear detail in state if frequency is empty
    map(([payload, total]) => total.data?.[payload.id - 1]?.frequency
      ? trendingActions.loadGroupDetails(payload)
      : trendingActions.clearGroupDetails({period: payload.period})
    )
  ));


  public loadTrendingGroupDetails$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadGroupDetails),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(TrendingSelectors.getTrendingTotalByPeriod(action.period))),
        this.store.pipe(select(TrendingSelectors.getTrendingFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(TrendingSelectors.getSelectedReport)),
      ),
    )),
    mergeMap(([payload, total, filters, globalFilters, selectedReport ]: [
      ITrendingGroupDetailsActionParams, ITrendingTotal, ITrendingParams, IAppliedGlobalFiltersAsParams, RouteItemEnum
     ]) => {
      // NOTE: index starts from 0, id starts from 1
      const newFilters: ITrendingGroupDetailsParams = {
        ...filters,
        startDate: total.times.start,
        endDate: total.times.end,
        freqType: total.frequency,
        frequency: total.data[payload.id - 1].frequency, // get details for certain week/month/day
      };
      return this.trendingService.getGroupDetails$({...newFilters, ...globalFilters}, selectedReport)
        .pipe(
          map((data: ITrendingGroupDetails) => data.list || []),
          map((data: IGroupDetail[]) =>
            trendingActions.loadGroupDetailsSuccess({
              data,
              period: payload.period,
              id: payload.id,
              params: newFilters
            })
          ),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'feature.sharedTrending.loadErrorGroupDetails';

            return of(trendingActions.loadGroupDetailsFailure({ message, period: payload.period }));
          })
        );
    })
  ));


  public getFilters$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.getFilters),
    map((action) => action),
    concatMap(queryParams => of(queryParams).pipe(
      withLatestFrom(
        this.store.pipe(select(OrgConfigStore.getUserModels)),
        this.store.pipe(select(TrendingSelector.getMetricOptions)),
      )
    )),
    mergeMap(([queryParams, userModels, metricOptions]) => {
      const filters = this.trendingService.getTrendingFiltersFromParams(queryParams, userModels, metricOptions);
      return [
        trendingActions.getSelectedDateCohort({filters}),
        trendingActions.setFilters({filters})
      ];
    }),
  ));


  public getSelectedDateCohort$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.getSelectedDateCohort),
    map((action) => action.filters),
    switchMap((filters: ITrendingParams) => {
      // wait unit all date cohorts will be in the store and set date cohort
      return this.store.pipe(select(DateCohortsStore.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => {
          if (filters.cohort === DateCohortsGroups.Custom) {
            const customCohort = {
              name: 'Custom Range',
              cohort: filters.cohort,
              endDate: filters.endDate,
              startDate: filters.startDate,
            };
            return of(customCohort);
          }

          return this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(filters.cohort)));
        })
      );
    }),
    map((dateCohort: IDateCohort) => trendingActions.setSelectedDateCohort({dateCohort: dateCohort}))
  ));


  public onChangeFilters$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.setFilters),
    mergeMap((action) => [
      trendingActions.clearReportData(),
      trendingActions.loadTotal({ period: TrendingDataPeriod.Now }),
      ...(action.filters.type === TrendsType.Benchmark ?
        [trendingActions.loadTotal({ period: TrendingDataPeriod.Then} )] :
        []),
    ]),
  ));


  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      trendingActions.loadTotalFailure,
      trendingActions.loadGroupDetailsFailure,
      trendingActions.downloadCSVFailure,
    ),
    map((action) =>  notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message})}))
  ));


  public onLoadDetailsFailure$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadDetailsFailure),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.error}) }))
  ));


  public getMetricOptions$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.getMetricOptions),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(getWfStagesForMetricOptions)),
        this.store.pipe(select(OrgConfigStore.getOrgConfigFeatures))
      )
    )),
    map(([_, stages, features]) => {
      const value = this.trendingService.getMetricOptions(stages, features);
      return trendingActions.setMetricOptions({value});
    })
  ));


  public downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.downloadComparativeCSV),
    withLatestFrom(
      this.store.pipe(select(TrendingSelector.getComparativeTableDownloadColumns)),
      this.store.pipe(
        select(TrendingSelector.getComparativeTableData),
        mergeMap(items => combineLatest(
          items.map(item => this.translateService.get(item.title))
        ))
      ),
      this.store.pipe(select(TrendingSelector.getTrendingReportData)),
      this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
    ),
    tap(([_, columns, titles, data, currency]) => {
      columns[0].name = '0';
      const fileName = 'campaign-performance-trending';
      // titles = ["Now", "Then"] || ["Now"]. - Set data based on period
      const fileData = titles.map((title, index) => (
        [title, ...data.map(item => index ? item.valueThen : item.valueNow)]
      ));
      this.downloadService.downloadCsv(fileName, fileData, columns, currency);
    })
  ), {dispatch: false});


  public toggleExpandGroupDetailsRow$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.toggleExpandGroupDetailsRow),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(TrendingSelector.getGroupDetailsRowState)),
        this.store.pipe(select(TrendingSelector.getCampaignDetailsByPeriod(action.period))),
      )
    )),
    map(([payload, rowState, campaigns]: [
      { group: IGroupDetail, period: TrendingDataPeriod },
      Record<TrendingDataPeriod, { [key: string]: boolean }>,
      Record<string, ITrendingCampaignDetails[]>
    ]) => {
      // NOTE: if the row is already open then we just have to close it
      // if the row is closed but there are campaigns for this group
      // then we just have to open this row
      if (rowState[payload.period][payload.group.group]
        || campaigns[payload.group.group]) {
        return trendingActions.changeGroupDetailsRowState({
          group: payload.group.group,
          period: payload.period,
        });
      }

      // otherwise get campaigns from the backend and then open the row
      return trendingActions.loadDetails({
        period: payload.period,
        group: payload.group.group,
      });
    })
  ));


  public loadCampaignsDetails$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadDetails),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(TrendingSelectors.getSelectedReport)),
        this.store.pipe(select(TrendingSelectors.getGroupDetailsParamsByPeriod(action.period))),
      ),
    )),
    switchMap(([payload, globalFilters, selectedReport, groupDetailsParams]: [
      { period: TrendingDataPeriod, group: string },
      IAppliedGlobalFiltersAsParams,
      RouteItemEnum,
      ITrendingGroupDetailsParams
    ]) => {
      // use params not from store but from payload because they contain info about frequency and dates
      // add group name to specify which campaigns we want to get
      const filters = {
        ...groupDetailsParams,
        group: payload.group
      };
      return this.trendingService.getDetails$({...filters, ...globalFilters}, selectedReport)
        .pipe(
          map((data: ITrendingDetails) => data.data ? Object.values(data.data) : []),
          map((campaigns: ITrendingCampaignDetails[]) => trendingActions.loadDetailsSuccess({
            campaigns,
            period: payload.period,
            group: payload.group
          })),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'feature.sharedTrending.loadErrorDetails';

            return of(trendingActions.loadDetailsFailure({
              error: message,
              period: payload.period,
            }));
          })
        );
    })
  ));


  public onLoadCampaignsDetailsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.loadDetailsSuccess),
    map(action => action),
    map(payload => {
      // change row state for this group
      return trendingActions.changeGroupDetailsRowState({
        group: payload.group,
        period: payload.period,
      });
    })
  ));


  public downloadGroupDetailsCSV$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.downloadGroupDetailsCSV),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(TrendingSelectors.getGroupDetailsParamsByPeriod(action.period))),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(TrendingSelectors.isCampaignTrendsReport)),
      )
    )),
    mergeMap((data) => {
      return this.userService.authenticateExportToken$().pipe(
        map((token: IExportToken) => ([
          ...data.slice(1), // remove period from data because we don't need it anymore
          token
        ])),
        catchError(() => of([
          ...data.slice(1), // remove period from data because we don't need it anymore
          {} // in case of error
        ]))
      );
    }),
    mergeMap(([filters, globalFilters, isCampaignTrendsReport, token]:
                [ITrendingGroupDetailsParams, IAppliedGlobalFiltersAsParams, boolean, IExportToken]) => {

      return this.trendingService.downloadTrendingCsv$(filters, globalFilters, token, isCampaignTrendsReport).pipe(
        map((csvContent: string) => trendingActions.downloadCSVSuccess({csvContent})),
        catchError((error: Error) => {
          const msg = error ? error : {message: 'feature.trendingDetails.errors.loadErrorCSV'} ;
          return of(trendingActions.downloadCSVFailure(msg));
        })
      );
    })
  ));

  public onDownloadCSVSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.downloadCSVSuccess),
    map(action => action.csvContent),
    tap((csv: string) => {
      this.downloadService.createBlobAndDownload('Trending Details', csv);
    })
  ), {dispatch: false});

  public downloadCampaignDetailsCSV$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.downloadCampaignDetailsCSV),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(
          select(TrendingSelectors.getCampaignDetailsByPeriod(action.period)),
          map((campaigns: Record<string, ITrendingCampaignDetails[]>) => campaigns[action.group]),
          map((groupCampaigns: ITrendingCampaignDetails[]) => groupCampaigns.map(campaign => {
            return {
              ...campaign,
              items: campaign.items.length // return responses length instead of Array
            };
          }))
        ),
        this.store.pipe(
          select(TrendingSelector.getCampaignDetailsColumns),
          map(columns => {
            return [
              ...columns.slice(1), // remove icon column and add id column
              {
                name: 'id',
                width: 100,
                displayName: 'feature.sharedTrending.groupDetails.columns.id',
                dataType: DataTypeEnum.Text,
              }
            ];
          }),
          mergeMap(this.getColumnsWithTranslationHeaders.bind(this)), // translate columns' headers
        ),
        this.translateService.get(action.period === TrendingDataPeriod.Now
          ? 'feature.sharedTrending.comparativeTable.now'
          : 'feature.sharedTrending.comparativeTable.then'),
        this.store.pipe(select(TrendingSelector.getFrequencyTitle)),
        this.store.pipe(select(TrendingSelector.getSelectedGroupId)),
        this.store.pipe(
          select(TrendingSelector.getMetricFieldLabel),
          mergeMap(label => this.translateService.get(label))
        ),
        this.store.pipe(select(TrendingSelector.getGroupDetailsDateByPeriod(action.period))),
        this.store.pipe(
          select(TrendingSelector.getTrendingFilters),
          map(filters => filters.model === ModelType.Sourced ? ' Sourced' : '')
        )
      )
    )),
    tap(([_, data, columns, periodTitle, frequency, selectedId, field, date, model]: [
      { group: string, period: TrendingDataPeriod }, ITrendingCampaignDetails[], IReportColumn[],
      string, string, number, string, string, string
    ]) => {
      const fileName = `${periodTitle} (${frequency} ${selectedId}) - ${field}${model} in ${date}`;
      this.downloadService.downloadCsv(fileName, data, columns, null);
    })
  ), {dispatch: false});


  public downloadResponsesCSV$ = createEffect(() => this.actions$.pipe(
    ofType(trendingActions.downloadResponsesCSV),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(
          select(TrendingSelectors.getCampaignDetailsByPeriod(action.period)),
          map((campaigns: Record<string, ITrendingCampaignDetails[]>) => campaigns[action.group]),
          map((groupCampaigns: ITrendingCampaignDetails[]) =>
            groupCampaigns.find(campaign => campaign.id === action.id)) // responses
        ),
        this.store.pipe(
          select(TrendingSelector.getResponsesColumns),
          map(columns => {
            return [
              ...columns,
              {
                name: 'id',
                width: 100,
                displayName: 'feature.sharedTrending.groupDetails.columns.id',
                dataType: DataTypeEnum.Text,
              }
            ];
          }),
          mergeMap(this.getColumnsWithTranslationHeaders.bind(this)), // translate columns' headers
        ),
        this.translateService.get(action.period === TrendingDataPeriod.Now
          ? 'feature.sharedTrending.comparativeTable.now'
          : 'feature.sharedTrending.comparativeTable.then'),
        this.store.pipe(select(TrendingSelector.getFrequencyTitle)),
        this.store.pipe(select(TrendingSelector.getSelectedGroupId)),
        this.store.pipe(
          select(TrendingSelector.getMetricFieldLabel),
          mergeMap(label => this.translateService.get(label))
        ),
        this.store.pipe(select(TrendingSelector.getGroupDetailsDateByPeriod(action.period))),
        this.store.pipe(
          select(TrendingSelector.getTrendingFilters),
          map(filters => filters.model === ModelType.Sourced ? ' Sourced' : '')
        ),
        this.store.pipe(select(OrgConfigStore.getOrgCurrencySetting)),
      )
    )),
    tap(([_, group, columns, periodTitle, frequency, selectedId, field, date, model, currency]: [
      { group: string, period: TrendingDataPeriod, id: string }, ITrendingCampaignDetails, IReportColumn[],
      string, string, number, string, string, string, string | null
    ]) => {
      const fileName = `${periodTitle} (${frequency} ${selectedId}) - ${field}${model} in ${date}-${group.name}`;
      this.downloadService.downloadCsv(fileName, group.items, columns, currency);
    })
  ), {dispatch: false});

  private getColumnsWithTranslationHeaders(columns: IReportColumn[]): Observable<IReportColumn[]> {
    return combineLatest(
      columns.map(column => this.translateService.get(column.displayName).pipe(
        map(displayName => ({
          ...column,
          displayName,
        }))
      ))
    );
  }
}
