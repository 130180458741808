import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateCohortsModule } from '@ui/components/date-cohorts';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { AnalyticsFiltersComponent } from './analytics-filters.component';

@NgModule({
  declarations: [AnalyticsFiltersComponent],
  exports: [AnalyticsFiltersComponent],
  imports: [
    CommonModule,
    DateCohortsModule,
    ReportSettingPanelModule,
    ReportFilterSelectorModule,
  ]
})
export class AnalyticsFiltersModule {
}
