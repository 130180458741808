import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { ICreateDashboardTileRequest, ITileConfig, TileConfigurationSteps } from '@shared/interfaces';
import { TileModalComponent } from '../containers/tile-modal.component';
import { TileTypes } from '@shared/enums';
import * as TileModalStore from '@shared/data-access/tile-modal';

@Injectable()
export class TileModalService {
  constructor(private dialog: MatDialog,
              private store: Store<unknown>) {
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  openTileModal(data: Partial<ITileConfig>): Observable<undefined | any> {
    const dialogRef = this.dialog.open(TileModalComponent, {
      data,
      width: '87%',
      disableClose: true, // close only by buttons
      panelClass: 'hub-modal-dialog'
    });

    return dialogRef.afterClosed();
  }

  openAddToDashboardModal(type: TileTypes, settings: Params): void {
    const data: Partial<ITileConfig> = {
      step: TileConfigurationSteps.Dashboard,
      type,
      settings: {
        ...settings,
        // parse cohort startDate and endDate to numbers
        ...settings['startDate'] ? {startDate: Number(settings['startDate'])} : null,
        ...settings['endDate'] ? {endDate: Number(settings['endDate'])} : null,
      }
    };

    this.openTileModal(data).pipe(
      take(1),
      filter(Boolean),
    ).subscribe((request: ICreateDashboardTileRequest) => {
      this.store.dispatch(TileModalStore.tileModalActions.saveNewTile({request}));
    });
  }
}
