import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { folderPermissionOptions, ISavedFolder, SavedFolderPermissionTypes, SavedFolderTypes } from '@shared/interfaces';

@Component({
  selector: 'tsh-folder-dialog',
  templateUrl: './folder-dialog.component.html',
  styleUrls: ['./folder-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderDialogComponent implements OnInit {
  form: FormGroup;
  permissionOptions = folderPermissionOptions;
  isDisabled = false;

  constructor(public dialogRef: MatDialogRef<FolderDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: { folder: ISavedFolder }) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      folderName: [this.data.folder ? this.data.folder.folderName : null, Validators.required],
      folderPermission: this.data.folder ? this.data.folder.folderPermission : SavedFolderPermissionTypes.Public,
      folderType: SavedFolderTypes.SavedReport,
    });

    if (this.data.folder) {
      // Add folder id if we have to update the folder
      this.form.addControl('folderId', new FormControl(this.data.folder.folderId));
    }
  }

  saveFolder(): void {
    if (this.form.valid) {
      this.isDisabled = true; // to prevent double click
      this.dialogRef.close(this.form.value);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  deleteFolder(): void {
    this.isDisabled = true; // to prevent double click
    this.dialogRef.close(this.data.folder.folderId);
  }
}
