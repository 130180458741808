import { IFactoryParams } from '@shared/interfaces';

export interface IStagesSnapshotFilters {
  startDate: number;
  endDate: number;
  cohort: string;
  stage?: string;
}

export function stagesSnapshotFiltersFactory({
  overrides = {},
  n = 1
}: IFactoryParams<IStagesSnapshotFilters, number> = {}): IStagesSnapshotFilters {
  const date = new Date().valueOf();

  return {
    startDate: date,
    endDate: date,
    cohort: `cohort${n}`,
    ...overrides
  };
}
