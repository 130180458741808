import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TsSelectionListComparator, TsSelectionListFormatter } from '@terminus-lib/ui-selection-list';

import { IAccountsFolderList, IAccountsFolderResponse } from '@shared/interfaces';

@Component({
  selector: 'tsh-create-list-dialog',
  templateUrl: './create-list-dialog.component.html',
  styleUrls: ['./create-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateListDialogComponent implements OnInit {
  public form: FormGroup;
  public query = '';

  public formatDisplay: TsSelectionListFormatter = (v: IAccountsFolderResponse) => v.folderName;
  public folderCompareFunction: TsSelectionListComparator =
    (a: IAccountsFolderResponse, b: IAccountsFolderResponse) => a.folderId === b.folderId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      foldersOptions: IAccountsFolderResponse[];
      selectedFolder: IAccountsFolderResponse;
      list: IAccountsFolderList;
    },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateListDialogComponent>,
  ) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data.list?.name || '', [Validators.required, Validators.maxLength(64)]],
      folders: [[this.data.selectedFolder]],
      ...(this.data.list ? { id: this.data.list.id } : {}),
    }, { validators: this.duplicateValidator.bind(this) });
  }

  public close(): void {
    this.dialogRef.close(this.form.value);
  }

  public duplicateValidator(control: FormGroup): ValidationErrors | null {
      const name = control.get('name').value;
      const lists = control.get('folders').value[0].contents.filter(list => list.name !== this.data.list?.name);
      const exists = lists.find(list => list.name === name);
      return exists ? { duplicate: true } : null;
  }

  get folders(): IAccountsFolderResponse[] {
    return this.query ? (this.data.foldersOptions || []).filter(folder =>
      this.query === this.form.value.folders[0].folderName ||
        folder.folderName.toLowerCase().startsWith(this.query.toLowerCase())) : this.data.foldersOptions;
  }
}
