export enum Grow {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum Color {
  GREEN = 'GREEN',
  RED = 'RED,',
}

export interface DeltaDescriptor {
  value: string | number;
  grow: Grow;
  color: Color;
}

export interface DeltaRecord {
  value: string | number;
  delta?: DeltaDescriptor | null;
}
