import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'tsh-csv-download-button',
  templateUrl: './csv-download-button.component.html',
  styleUrls: ['./csv-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsvDownloadButtonComponent {
  @Input() public isDisabled: boolean;
  @Input() public showProgress: boolean;
  @Output() public download = new EventEmitter<void>();

  public downloadMsg = 'shared.ui.csvDownloadButton.downloadCsv';
  public preparingMsg = 'shared.ui.csvDownloadButton.preparingMsg';

  emitDownload(): void {
    this.download.emit();
  }
}
