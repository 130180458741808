import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TsSortState } from '@terminus-lib/ui-sort';

import { MARKETING_INFLUENCE_KEY, MarketingInfluenceState } from './marketing-influence.reducer';
import {
  IMarketingInfluenceReport,
  IMarketingInfluenceReportTotals,
  IMarketingInfluenceRequest,
  marketingInfluenceReportTotalsFactory,
  OpportunityType,
} from '../interfaces';
import { calculatePercentage } from '../utils/marketing-influence.utils';
import { DataTypeEnum } from '@shared/enums';
import { ChartColors } from '../marketing-influence.constants';
import { IReportColumn, ReportMetric } from '@shared/interfaces';
import { applyPageToReportData, compare } from '@util/helpers';

export const selectMarketingInfluenceState = createFeatureSelector<MarketingInfluenceState>(MARKETING_INFLUENCE_KEY);

export const getMarketingInfluenceReport = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.reportData
);

export const getMarketingInfluenceReportCount = createSelector(
  getMarketingInfluenceReport,
  (reportData: IMarketingInfluenceReport[]) => reportData?.length || 0,
);

export const getMarketingInfluenceReportTotals = createSelector(
  getMarketingInfluenceReport,
  (reportData: IMarketingInfluenceReport[]): IMarketingInfluenceReportTotals => {
    if (!reportData || !reportData.length) {
      // NOTE: return totals with 0 value for all properties
      return marketingInfluenceReportTotalsFactory({ n: 0 });
    }

    const calculatedTotals = reportData.reduce((acc, item) => {
      return {
        count: acc.count + item.count,
        value: acc.value + item.value,
        marketingCount: acc.marketingCount + item.marketingCount,
        marketingValue: acc.marketingValue + item.marketingValue
      };
    }, {
      count: 0,
      value: 0,
      marketingCount: 0,
      marketingValue: 0,
    });

    return {
      ...calculatedTotals,
      calculatedCount: calculatePercentage(calculatedTotals.marketingCount, calculatedTotals.count),
      calculatedValue: calculatePercentage(calculatedTotals.marketingValue, calculatedTotals.value),
    };
  }
);

export const getMarketingInfluenceFilters = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.filters
);

export const getMarketingUsedQueryParams = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.usedQueryParams
);

export const getMarketingInfluenceLoading = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.isLoading
);

export const getMarketingInfluenceSortState = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.tableSortState
);

export const getMarketingInfluencePage = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.page
);

export const getMarketingSelectedDateCohort = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.selectedDateCohort
);

export const getAllMarketingInfluenceColumns = createSelector(
  selectMarketingInfluenceState,
  (state: MarketingInfluenceState) => state.columns
);

export const getMarketingInfluenceSortedReportData = createSelector(
  getMarketingInfluenceReport,
  getMarketingInfluenceSortState,
  getMarketingInfluencePage,
  (reportData: IMarketingInfluenceReport[], sortState: TsSortState, page: number): IMarketingInfluenceReport[] => {
    if (!reportData || !sortState.active || !sortState.direction) {
      return applyPageToReportData(reportData, page);
    }

    const sortBy = sortState.active === 'month' ? 'sortTime' : sortState.active;
    const sortedItems = reportData.slice().sort((
      a: IMarketingInfluenceReport,
      b: IMarketingInfluenceReport
    ) => compare(a[sortBy], b[sortBy], sortState.direction === 'asc'));

    return applyPageToReportData(sortedItems, page);
  }
);

export const getMarketingInfluenceMetrics = createSelector(
  getMarketingInfluenceReportTotals,
  getMarketingInfluenceFilters,
  (totals: IMarketingInfluenceReportTotals,
   filters: IMarketingInfluenceRequest): ReportMetric[] => {
    const isDeal = filters?.opptyType === OpportunityType.DEAL;
    const valueTooltip = isDeal
      ? 'measurementStudio.features.marketingInfluence.columns.valueTooltip.isDeal'
      : 'measurementStudio.features.marketingInfluence.columns.valueTooltip.notDeal';
    const percentTooltip = isDeal
      ? 'measurementStudio.features.marketingInfluence.columns.percentTooltip.isDeal'
      : 'measurementStudio.features.marketingInfluence.columns.percentTooltip.notDeal';

    return [
      {
        title: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.totalRevenue'
          : 'measurementStudio.features.marketingInfluence.columns.totalPipelineCreated',
        current: {
          value: totals.value,
          dataType: DataTypeEnum.Currency,
          color: isDeal ? ChartColors.DealValue : ChartColors.OpptyValue
        },
      },
      {
        title: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.influencedRevenue'
          : 'measurementStudio.features.marketingInfluence.columns.influencedPipeline',
        current: {
          value: totals.marketingValue,
          dataType: DataTypeEnum.Currency,
          color: isDeal ? ChartColors.DealMarketingValue : ChartColors.OpptyMarketingValue
        },
        tooltip: valueTooltip,
      },
      {
        title: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.totalDeals'
          : 'measurementStudio.features.marketingInfluence.columns.totalOpportunities',
        current: {
          value: totals.count,
          dataType: DataTypeEnum.Number
        }
      },
      {
        title: isDeal
          ? 'measurementStudio.features.marketingInfluence.columns.influencedDeals'
          : 'measurementStudio.features.marketingInfluence.columns.influencedOpportunities',
        current: {
          value: totals.calculatedCount,
          dataType: DataTypeEnum.Percent
        },
        tooltip: percentTooltip,
      }
    ];
  });

export const getVisibleMarketingInfluenceColumns = createSelector(
  getAllMarketingInfluenceColumns,
  (columns: IReportColumn[]): IReportColumn[] => {
    return columns.filter(column => column.visible);
  });

/* In the selector we could change visibility for all columns except Month column */
export const getColumnsForSelector = createSelector(
  getAllMarketingInfluenceColumns,
  (columns: IReportColumn[]): IReportColumn[] => {
    // 1 column is Month column
    return columns.slice(1);
  });
