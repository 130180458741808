import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { TsTooltipComponent } from '@terminus-lib/ui-tooltip';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';
import { take, tap } from 'rxjs/operators';

import { GlobalFiltersSaveFilterDialogComponent } from '../components/save-filter-dialog/component';
import { IDataFilter, ITargetFolderItem } from '@shared/interfaces';
import * as AccountListStore from '@shared/data-access/account-hub';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { globalFiltersActions } from '@shared/data-access/global-filters';
import { SharedState } from '@shared-store';

@WithDestroy
@Component({
  selector: 'tsh-global-filters',
  templateUrl: './global-filters.component.html',
  styleUrls: ['./global-filters.component.scss'],
})
export class GlobalFiltersComponent implements OnInit {
  public accountListFolder$ = this.store.pipe(select(AccountListStore.getFolders));
  public accountListFolderMenuState$ = this.store.pipe(select(GlobalFiltersStore.getAccountListMenuState));
  public appliedDataFilter$ = this.store.pipe(select(GlobalFiltersStore.getAppliedDataFilter));
  public filterChanged$ = this.store.pipe(select(GlobalFiltersStore.getFilterChanged));
  public globalFilterData$ = this.store.pipe(select(GlobalFiltersStore.getGlobalFiltersData));
  public globalFilterMenuState$ = this.store.pipe(select(GlobalFiltersStore.getGlobalFiltersMenuState));
  public isLoading$ = this.store.pipe(select(GlobalFiltersStore.getIsGlobalFilterLoading));
  public areFiltersApplied$ = this.store.pipe(select(GlobalFiltersStore.getAreFiltersApplied));
  public savedDataFilters$ = this.store.pipe(select(GlobalFiltersStore.getSavedDataFilters));
  public savedDataFilterSelected$ = this.store.pipe(select(GlobalFiltersStore.getIsSavedDataFilterSelected));
  public savedDataFilterChanged$ = this.store.pipe(select(GlobalFiltersStore.getHasSavedDataFilterChanged));
  public selectedDataFilter$ = this.store.pipe(select(GlobalFiltersStore.getSelectedDataFilter));
  public unappliedFilters$ = this.store.pipe(select(GlobalFiltersStore.getUnappliedFilterNumber));
  public segments$ = this.store.pipe(select(GlobalFiltersStore.getSegments));

  public searchIcon = faSearch;
  public searchLabel = 'shared.globalFilters.container.searchLabel';

  public applying$ = this.store.pipe(select(GlobalFiltersStore.getIsApplying));
  public saving$ = this.store.pipe(select(GlobalFiltersStore.getIsSaving));
  public deleting$ = this.store.pipe(select(GlobalFiltersStore.getIsDeleting));
  public tooltipMessage = 'Applying';

  @ViewChild('tooltip') public tooltip: TsTooltipComponent;

  constructor(private store: Store<SharedState>,
              public dialog: MatDialog,
              private translate: TranslateService) {
  }

  public ngOnInit(): void {
    this.store.dispatch(AccountListStore.getAccountLists());
    this.trackApplyingStatus();
    this.trackSavingStatus();
    this.trackDeletingStatus();
  }

  showTooltipByKey(key: string): void {
    this.tooltipMessage = this.translate.instant('shared.globalFilters.statusMessage.' + key);
    this.tooltip.showTooltip();
  }

  trackApplyingStatus(): void {
    this.applying$.pipe(untilComponentDestroyed(this)).subscribe(status => {
      if (status) {
        this.showTooltipByKey('applying');
      } else if (this.tooltip) {
        this.showTooltipByKey('applied');
      }
    });
  }

  trackSavingStatus(): void {
    this.saving$.pipe(untilComponentDestroyed(this)).subscribe(status => {
      if (status) {
        this.showTooltipByKey('saving');
      } else if (this.tooltip) {
        this.showTooltipByKey('saved');
      }
    });
  }

  trackDeletingStatus(): void {
    this.deleting$.pipe(untilComponentDestroyed(this)).subscribe(status => {
      if (status) {
        this.showTooltipByKey('deleting');
      } else if (this.tooltip) {
        this.showTooltipByKey('deleted');
      }
    });
  }

  public onClearDataFilterForm(): void {
    this.store.dispatch(globalFiltersActions.clearAppliedAndSelectedDataFilter());
  }

  public onDataFilterSelect(filter: IDataFilter): void {
    this.store.dispatch(globalFiltersActions.updateSelectedDataFilter({ filter }));
  }

  public onUpdateAndSaveAppliedDataFilter(filter: IDataFilter): void {
    this.store.dispatch(globalFiltersActions.updateAppliedDataFilter({ filter }));
    this.store.dispatch(globalFiltersActions.submitSaveFilters());
  }

  public onFolderCheckboxSelect(folder: ITargetFolderItem): void {
    this.store.dispatch(globalFiltersActions.toggleFolderSelect({ folder }));
  }

  public onFolderItemCheckboxSelect(folder: ITargetFolderItem): void {
    this.store.dispatch(globalFiltersActions.toggleFolderItemSelect({ folder }));
  }

  public onToggleFolderMenu(folder: ITargetFolderItem): void {
    this.store.dispatch(globalFiltersActions.toggleFolderExpandedMenu({ folder }));
  }

  public onApplyFilterChanges(): void {
    this.store.dispatch(globalFiltersActions.applySelectedFilters());
    this.store.dispatch(globalFiltersActions.filterChanged());
  }

  public onDeleteSavedDataFilter(): void {
    this.store.dispatch(globalFiltersActions.submitDeleteSavedFilters());
  }

  public onUpdateSavedDataFilter(): void {
    this.store.dispatch(globalFiltersActions.submitSaveFilters());
  }

  public onSearchSubmit(value: string): void {
    this.store.dispatch(globalFiltersActions.filterGlobalFilters({ data: value.trim() }));
  }

  public openDialog(dataFilter: IDataFilter): void {
    const component: ComponentType<GlobalFiltersSaveFilterDialogComponent> = GlobalFiltersSaveFilterDialogComponent;

    const dialogRef = this.dialog.open(component, {
      data: dataFilter,
      width: '500px',
      panelClass: 'hub-modal-dialog',
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (dataFilter && typeof result === 'object') {
        const updatedFilter = {
          ...dataFilter,
          ...result,
          private: result.isMine
        };
        this.onUpdateAndSaveAppliedDataFilter(updatedFilter);
      }
    });
  }
}
