import { IFactoryParams, TrendingPrevious } from '@shared/interfaces';
import { ModelType, TrendsType } from '@shared/enums';
import { DataSetType, FrequencyType, GroupType } from '@measurement-studio/enums';
import { TrendingDataPeriod } from '../enums';

export interface ITrendingParams {
  dataSet: DataSetType;
  model: ModelType;
  field: string;
  grp: GroupType;
  type: TrendsType;
  freqType: FrequencyType;
  previous?: TrendingPrevious;
  goal?: number;
  cohort: string;
  startDate?: number;
  endDate?: number;
}

export interface ITrendingGroupDetailsParams extends ITrendingParams {
  frequency: string;
}

export interface ITrendingGroupDetailsActionParams {
  period: TrendingDataPeriod;
  id: number;
}

export function trendingParamsFactory({
  overrides = {}
}: IFactoryParams<ITrendingParams> = {}): ITrendingParams {
  return {
    cohort: 'cohort',
    dataSet: DataSetType.MembershipActivity,
    startDate: 1605881200901,
    endDate: 1605882200901,
    model: ModelType.Even,
    field: 'opptys',
    grp: GroupType.Only,
    type: TrendsType.Trend,
    freqType: FrequencyType.Auto,
    ...overrides
  };
}

export function trendingGroupParamsFactory({
    overrides = {}
}: IFactoryParams<ITrendingGroupDetailsParams> = {}): ITrendingGroupDetailsParams {
  return {
    ...trendingParamsFactory(),
    frequency: '2019 19',
    ...overrides
  };
}
