import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import {
  IMarketingInfluenceRequest,
  IMarketingInfluenceResponse,
  MarketingInfluenceTileSettings
} from '../interfaces';
import { IMarketingInfluenceSource } from './marketing-influence.source.interface';

export enum MarketingInfluenceSourceURLs {
    GetMarketingInfluenceData = '/api/v1/marketing_impact',
}

@Injectable({
    providedIn: 'root'
})
export class MarketingInfluenceSource extends HttpClientBase implements IMarketingInfluenceSource {

  getMarketingInfluenceReportData$(params: IMarketingInfluenceRequest | MarketingInfluenceTileSettings):
    Observable<IMarketingInfluenceResponse> {
      const endpoint = this.endpoint(MarketingInfluenceSourceURLs.GetMarketingInfluenceData);

      return this.httpClient.get<IMarketingInfluenceResponse>(endpoint, {
        params: this.getMarketingInfluenceHttpParams(params),
      });
  }

  private getMarketingInfluenceHttpParams(params: IMarketingInfluenceRequest): HttpParams {
    const fromObject = Object.keys(params).reduce((acc, key: string) => {
      if (params[key]) {
        acc[key] = params[key];
      }

      return acc;
    }, {});

    return new HttpParams({ fromObject });
  }
}
