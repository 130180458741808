<div
  class="c-menu"
  [ngClass]="{'c-menu--utility': isUtilityMenu}"
>
  <mat-menu
    #menu="matMenu"
    [yPosition]="menuPositionY"
    [xPosition]="menuPositionX"
    [overlapTrigger]="false"
    backdropClass="ts-menu__backdrop"
    class="ts-menu__panel"
  >
    <ng-container [ngTemplateOutlet]="menuItemsTemplate"></ng-container>
  </mat-menu>

  <ts-button
    *ngIf="!isUtilityMenu"
    class="c-menu__trigger"
    [theme]="triggerTheme"
    [matMenuTriggerFor]="menu"
    [isDisabled]="isDisabled"
    [attr.data-disabled]="isDisabled"
  >
    <ng-content></ng-content>

    <span class="c-menu__trigger-icon c-menu__caret">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-down"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
        ></path>
      </svg>
    </span>
  </ts-button>

  <button
    *ngIf="isUtilityMenu"
    class="c-menu__trigger c-menu__trigger--utility"
    [matMenuTriggerFor]="menu"
    [disabled]="isDisabled"
  >
    <span class="c-menu__trigger-icon">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="ellipsis-h"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
        ></path>
      </svg>
    </span>
  </button>
</div>
