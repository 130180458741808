import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { IReportLink } from '@shared/interfaces';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { IOpportunityColumn, IOpportunityItem } from '../interfaces';
import { OpportunityColumnsFields } from '../enums/opportunities.enum';

export const OPPORTUNITY_REPORT_COLUMNS: IOpportunityColumn[] = [
  {
    name: OpportunityColumnsFields.Name,
    width: 250,
    displayName: 'measurementStudio.features.opportunities.table.columns.name',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: IOpportunityItem): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunitySpecificTotalTouches),
        queryParams: {
          isId: true,
          oppty: row.id,
        }
      };
    },
    searchable: true,
    visible: true,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.AccountName,
    width: 250,
    displayName: 'measurementStudio.features.opportunities.table.columns.accountName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    link(row: IOpportunityItem) {
      return {
        routeId: RouteItemEnum.AccountSpecificAttribution,
        queryParams: {
          accountId: row.accountId
        }
      };
    },
    searchable: true,
    visible: true,
    apiType: 'account',
  },
  {
    name: OpportunityColumnsFields.Owner,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.owner',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    link(row: IOpportunityItem) {
      return {
        routeId: RouteItemEnum.Salesforce,
        queryParams: {
          id: row.ownerId
        }
      };
    },
    searchable: true,
    visible: false,
    apiType: 'owner',
  },
  {
    name: OpportunityColumnsFields.Revenue,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.revenue',
    dataType: DataTypeEnum.Currency,
    tooltip: 'measurementStudio.features.opportunities.table.columns.revenueTooltip',
    startSorting: 'desc',
    visible: true,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.ConfiguredStage,
    width: 170,
    displayName: 'measurementStudio.features.opportunities.table.columns.configuredStage',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    searchable: true,
    visible: true,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.CreatedDate,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.createdDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    visible: true,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.CloseDate,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.closeDate',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    visible: true,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.OtdVelocity,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.otdVelocity',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.SourceCampaignName,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.sourceCampaignName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    internalLink(row: IOpportunityItem, _filters = {}): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
        queryParams: {
          name: row.sourceCampaignName,
          campaignId: row.sourceCampaignId
        }
      };
    },
    searchable: true,
    visible: false,
    apiType: 'campaign',
  },
  {
    name: OpportunityColumnsFields.SourceCampaignGroup,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.sourceCampaignGroup',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    searchable: true,
    visible: false,
    apiType: 'campaign'
  },
  {
    name: OpportunityColumnsFields.LastCampaignName,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.lastCampaignName',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    searchable: true,
    tooltip: 'measurementStudio.features.opportunities.table.columns.lastCampaignNameTooltip',
    visible: false,
    apiType: 'campaign',
    internalLink(row: IOpportunityItem, _filters = {}): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
        queryParams: {
          name: row.lastCampaignName,
          campaignId: row.lastCampaignId
        }
      };
    },
  },
  {
    name: OpportunityColumnsFields.LastTouchCampaignGroup,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.lastTouchCampaignGroup',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    searchable: true,
    visible: false,
    apiType: 'campaign'
  },
  {
    name: OpportunityColumnsFields.UniqueCampaigns,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.uniqueCampaigns',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.uniqueCampaignsTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.OpptyContactCount,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.opptyContactCount',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.opptyContactCountTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.AccountContactCount,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.accountContactCount',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.accountContactCountTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'account'
  },
  {
    name: OpportunityColumnsFields.ContactRolesEngaged,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.contactRolesEngaged',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.contactRolesEngagedTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.ContactCountEngaged,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.contactCountEngaged',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.contactCountEngagedTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'account'
  },
  {
    name: OpportunityColumnsFields.LeadSource,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.leadSource',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.PreCreateAttributions,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.preCreateAttributions',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.preCreateAttributionsTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.PostCreateAttributions,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.postCreateAttributions',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.postCreateAttributionsTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.CampaignAttributions,
    width: 140,
    displayName: 'measurementStudio.features.opportunities.table.columns.campaignAttributions',
    dataType: DataTypeEnum.Number,
    tooltip: 'measurementStudio.features.opportunities.table.columns.campaignAttributionsTooltip',
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty',
    totalLink(total: number): IReportLink {
      return {
        link: getRouteLink(RouteItemEnum.OpportunityAllOpptysTouches),
        queryParamsHandling: 'merge',
        queryParams: {
          total,
          pg: 1,
          ps: 10
        }
      };
    },
  },
  {
    name: OpportunityColumnsFields.Type,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.type',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    visible: false,
    apiType: 'oppty'
  },
  {
    name: OpportunityColumnsFields.LastIntentDate,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.lastIntentDate',
    dataType: DataTypeEnum.Date,
    visible: true,
    apiType: 'oppty',
  },
  {
    name: OpportunityColumnsFields.IntentTopics,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.intentTopics',
    dataType: DataTypeEnum.Text,
    visible: true,
    apiType: 'oppty',
  }
];

export const OPPORTUNITY_REPORT_COLUMNS_SIGSTR: IOpportunityColumn[] = [
  {
    name: OpportunityColumnsFields.CompanyScore,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.companyScore',
    dataType: DataTypeEnum.Text,
    startSorting: 'asc',
    visible: false,
    apiType: 'oppty',
  },
  {
    name: OpportunityColumnsFields.IntentScore,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.intentScore',
    dataType: DataTypeEnum.Number,
    startSorting: 'desc',
    visible: false,
    apiType: 'oppty',
  },
  {
    name: OpportunityColumnsFields.FirstEmail,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.firstEmail',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    visible: false,
    apiType: 'oppty',
  },
  {
    name: OpportunityColumnsFields.LastEmail,
    width: 200,
    displayName: 'measurementStudio.features.opportunities.table.columns.lastEmail',
    dataType: DataTypeEnum.Date,
    startSorting: 'asc',
    visible: false,
    apiType: 'oppty',
  },
];
