import { FormState, FormTypes, MultipleFormState, SubFormContent } from './store-form-in-state.interfaces';

export function initialFormState<C extends Record<string, FormTypes>>(values?: Partial<C>): FormState<C> {
  return {
    version: 0,
    isValid: false,
    values: values || {}
  };
}

export function initialFormStateMultiple<C extends Record<string, FormTypes>>(values?: Record<string, Partial<C>>): MultipleFormState<C> {
  const initialForms: SubFormContent<C> = {};

  if (values) {
    for (const idx in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(idx)) {
        initialForms[idx] = {
          isValid: false,
          values: values[idx]
        };
      }
    }
  }

  return {
    version: 0,
    allValid: false,
    lastFormId: 0,
    forms: initialForms
  };
}

export function setForm<FormCfg>(
  state: FormState<FormCfg> | undefined,
  newValues: Partial<FormCfg>
) {
  const defaultState = state || {isValid: true, version: 0, values: {}};
  return {
    isValid: defaultState.isValid,
    version: defaultState.version + 1,
    values: newValues
  };
}



export function storeFormState<FormCfg>(
  state: FormState<FormCfg>,
  action: any
): FormState<FormCfg> {
  return {
    version: action.version,
    values: {...(action.formData.values as any)},
    isValid: action.formData.isValid
  };
}

export function newFormForMultipleFormState<FormCfg>(
  state: MultipleFormState<FormCfg>,
  opts?: { name?: string; initialValues?: Partial<FormCfg> }
) {
  const formName = opts && opts.name ? opts.name : state.lastFormId + 1;
  const formContent = opts && opts.initialValues ? opts.initialValues : {};

  return {
    version: state.version + 1,
    lastFormId: state.lastFormId + 1,
    allValid: false,
    forms: {
      ...state.forms,
      [formName]: {
        values: formContent,
        isValid: false
      }
    }
  };
}

export function removeFormFromMultipleFormState<FormCfg>(
  state: MultipleFormState<FormCfg>,
  formName: string
) {
  const newForms: SubFormContent<FormCfg> = {...state.forms};
  delete newForms[formName];

  return {
    version: state.version + 1,
    lastFormId: state.lastFormId,
    allValid: Object.keys(newForms).every((i) => newForms[i].isValid),
    forms: newForms
  };
}

export function storeMultipleFormState<FormCfg>(
  state: MultipleFormState<FormCfg>,
  action: any
): MultipleFormState<FormCfg> {
  let allValid = true;
  let lastFormId = state.lastFormId;
  for (const formName in action.formData) {
    // eslint-disable-next-line no-prototype-builtins
    if (action.formData.hasOwnProperty(formName)) {
      if (!action.formData[formName].isValid) {
        allValid = false;
      }
      if (+formName > lastFormId) {
        lastFormId = +formName;
      }
    }
  }

  return {
    version: action.version,
    allValid,
    lastFormId: lastFormId,
    forms: action.formData
  };
}
