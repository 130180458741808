import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import { ICampaignPerformance } from '../interfaces/campaign-performance.interface';
import { ICampaignFilters } from '../interfaces/campaign-filters.interface';
import { RevenueType } from '../enums/attribution-campaign.enum';
import { ICampaignTotalAmount } from '../interfaces/campaign-total-amount.interface';
import {
  IAttributionCampaignExpandedRow,
  IAttributionCampaignRow,
  IAttributionCampaignTotals
} from '../interfaces/attribution-campaign-report.interface';
import { DataTypeEnum, ModelType, RouteItemEnum } from '@shared/enums';
import { IAttributionModel, IReportColumn, IReportLink, ReportMetric } from '@shared/interfaces';
import { ClosedType, DataSetType, DateType, InfluencesKeys, WonType } from '@measurement-studio/enums';
import { IAttributionCampaignPagination } from '../interfaces/attribution-campaign-pagination.interface';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../attribution-campaign.constant';
import * as AttributionCampaignReducer from './attribution-campaign.reducer';
import * as OrgConfigStore from '@org-config';
import * as UserSelectors from '@user/user.selectors';
import {
  AttributionCampaignInitialChartData, IAttributionCampaignChartColumn,
  IAttributionCampaignChartGroup
} from '../interfaces/attribution-campaign-chart.interface';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { compare } from '@util/helpers';

export const selectAttributionCampaignState
  = createFeatureSelector<AttributionCampaignReducer.AttributionCampaignState>(AttributionCampaignReducer.attributionCampaignFeatureKey);

export const getCampaignPerformance = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.campaignPerformance
);

export const getTotalAmount = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.totalAmount
);

export const getAttributionCampaignFilters = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.filters
);

export const isAttributionCampaignPipeline = createSelector(
  getAttributionCampaignFilters,
  (filters: ICampaignFilters) => filters?.revenueType === RevenueType.Pipeline
);

export const getAttributionCampaignIsLoading = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.isLoading || state.isLoadingTotal
);

export const getAttributionCampaignSelectedDateCohort = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.selectedDateCohort
);

export const getAttributionCampaignExpandedReport = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.expandedReport
);

export const getAttributionCampaignExpandedRowState = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.expandedRowState
);

export const getExpandedReportPagination = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.expandedReportPagination
);

export const getAttributionCampaignSortState = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.reportSortState
);

export const getAttributionCampaignExpandedSortState = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.expandedReportSortState
);

export const getAttributionCampaignIsDownloadCSV = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.isDownloadingCsv
);

export const getUsedQueryParams = createSelector(
  selectAttributionCampaignState,
  (state: AttributionCampaignReducer.AttributionCampaignState) => state.usedQueryParams
);

export const getAttributionCampaignReport = createSelector(
  getCampaignPerformance,
  getTotalAmount,
  isAttributionCampaignPipeline,
  (
    campaignPerformance: ICampaignPerformance,
    totals: ICampaignTotalAmount,
    isPipeline: boolean
  ): IAttributionCampaignRow[] => {
    if (campaignPerformance?.groups
      && Object.keys(campaignPerformance.groups).length
      && totals?.groups
      && Object.keys(totals.groups).length) {
      return Object.keys(campaignPerformance.groups).reduce((acc: IAttributionCampaignRow[], key: string) => {
        const {summary} = campaignPerformance.groups[key];

        const item = {
          key,
          label: summary.revenueGroup,
          sourcedRevenue: isPipeline ? summary[ModelType.Sourced].pipeline : summary[ModelType.Sourced].revenue,
          sourcedOppty: isPipeline ? summary[ModelType.Sourced].opptys : summary[ModelType.Sourced].deals,
          evenRevenue: isPipeline ? summary[ModelType.Even].pipeline : summary[ModelType.Even].revenue,
          evenOppty: isPipeline ? summary[ModelType.Even].opptys : summary[ModelType.Even].deals,
          customRevenue: isPipeline ? summary[ModelType.Custom].pipeline : summary[ModelType.Custom].revenue,
          customOppty: isPipeline ? summary[ModelType.Custom].opptys : summary[ModelType.Custom].deals,
          lastRevenue: isPipeline ? summary[ModelType.Last].pipeline : summary[ModelType.Last].revenue,
          lastOppty: isPipeline ? summary[ModelType.Last].opptys : summary[ModelType.Last].deals,
          unique: isPipeline && totals.groups[key]
            ? totals.groups[key].opptysUnique
            : (totals.groups[key] && totals.groups[key].dealsUnique)
        };
        // add the item to the report only in case if one of the properties is more them 0
        if (item.sourcedRevenue > 0 || item.sourcedOppty > 0 || item.evenRevenue > 0 || item.evenOppty > 0
          || item.customRevenue > 0 || item.customOppty > 0 || item.lastRevenue > 0 || item.lastOppty > 0) {
          acc.push(item);
        }

        return acc;
      }, []);
    }

    return [];
  }
);

export const getSortedReport = createSelector(
  getAttributionCampaignReport,
  getAttributionCampaignSortState, (
    report: IAttributionCampaignRow[],
    sortState: TsSortState
  ): IAttributionCampaignRow[] => {
    if (!report || !sortState.active || !sortState.direction) {
      return report;
    }

    return report.slice().sort((
      a: IAttributionCampaignRow,
      b: IAttributionCampaignRow
    ) => compare(a[sortState.active], b[sortState.active], sortState.direction === 'asc'));
  });

// Get expanded report according to sort state and pagination page
export const getSortedExpandedReport = createSelector(
  getAttributionCampaignExpandedReport,
  getExpandedReportPagination,
  getAttributionCampaignExpandedSortState, (
    report: Record<string, IAttributionCampaignExpandedRow[]>,
    pagination: Record<string, IAttributionCampaignPagination>,
    sortState: Record<string, TsSortState>
  ): Record<string, IAttributionCampaignExpandedRow[]> => {
    if (!Object.keys(report).length) {
      return report;
    }

    return Object.keys(report).reduce((acc: Record<string, IAttributionCampaignExpandedRow[]>, id: string) => {
      // NOTE: sort expanded report only if there is a sort state for this report
      let sortedReport;
      if (sortState[id] && sortState[id].active && sortState[id].direction) {
        sortedReport = report[id].slice().sort((
          a: IAttributionCampaignExpandedRow,
          b: IAttributionCampaignExpandedRow
        ) => compare(a[sortState[id].active], b[sortState[id].active], sortState[id].direction === 'asc'));
      } else {
        sortedReport = report[id];
      }

      const page = pagination && pagination[id] ? pagination[id].page : DEFAULT_PAGE; // use page from store or default page
      const perPage = pagination && pagination[id] ? pagination[id].perPage : DEFAULT_PER_PAGE;

      acc[id] = sortedReport.slice((page - 1) * perPage, page * perPage); // return data for this page only

      return acc;
    }, {});
  });

export const getExpandedReportByKey = createSelector(
  getAttributionCampaignExpandedReport,
  (reports: Record<string, IAttributionCampaignExpandedRow[]>, props): IAttributionCampaignExpandedRow[] => {
    return reports && reports[props.key];
  }
);

export const getExpandedReportTotalCount = createSelector(
  getAttributionCampaignExpandedReport,
  (report: Record<string, IAttributionCampaignExpandedRow[]>): Record<string, number> => {
    return Object.keys(report).reduce((acc, id: string) => {
      acc[id] = report[id].length;
      return acc;
    }, {});
  });

export const getAttributionCampaignReportTotals = createSelector(
  getAttributionCampaignReport,
  (report: IAttributionCampaignRow[]): IAttributionCampaignTotals => {
    return report.reduce((acc, row) => {
      acc.sourcedRevenue += row.sourcedRevenue || 0;
      acc.sourcedOppty += row.sourcedOppty || 0;
      acc.evenRevenue += row.evenRevenue || 0;
      acc.evenOppty += row.evenOppty || 0;
      acc.customRevenue += row.customRevenue || 0;
      acc.customOppty += row.customOppty || 0;
      acc.lastRevenue += row.lastRevenue || 0;
      acc.lastOppty += row.lastOppty || 0;
      acc.unique += row.unique || 0;
      return acc;
    }, {
      sourcedRevenue: 0,
      sourcedOppty: 0,
      evenRevenue: 0,
      evenOppty: 0,
      customRevenue: 0,
      customOppty: 0,
      lastRevenue: 0,
      lastOppty: 0,
      unique: 0
    });
  });

/* SELECTORS FOR CHART - start */

export const getChartColumns = createSelector(
  OrgConfigStore.getAttributionModels,
  UserSelectors.selectOrganizationId,
  isAttributionCampaignPipeline,
  (
    models: Record<ModelType, IAttributionModel>,
    organizationId: number,
    isPipeline: boolean
  ): IAttributionCampaignChartColumn[] => {
    if (!models) {
      return [];
    }

    const data = [];

    if (models[ModelType.Sourced]?.active) {
      const model = {
        key: ModelType.Sourced,
        title: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineFirstTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueFirstTouch',
        tooltip: 'measurementStudio.features.attributionCampaign.chart.sourcedTooltip'
      };
      data.push(model);
    }

    if (models[ModelType.Last]?.active) {
      const model = {
        key: ModelType.Last,
        title: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineLastTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueLastTouch',
        tooltip: 'measurementStudio.features.attributionCampaign.chart.lastTooltip'
      };
      data.push(model);
    }

    if (models[ModelType.Even]?.active) {
      const model = {
        key: ModelType.Even,
        title: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouch',
        tooltip: 'measurementStudio.features.attributionCampaign.chart.evenTooltip'
      };
      data.push(model);
    }

    if (models[ModelType.Custom]?.active) {
      const model = {
        key: ModelType.Custom,
        title: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouchCustom'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouchCustom',
        tooltip: organizationId === 160 || organizationId === 84 || organizationId === 129
          ? 'measurementStudio.features.attributionCampaign.chart.customTooltip.customFitModel'
          : 'measurementStudio.features.attributionCampaign.chart.customTooltip.eachMarketingActivity'
      };
      data.push(model);
    }

    return data;
  });

// split data by model groups. Amcharts doesn't support nested object that's why we need to keep 1 nesting level
export const getChartData = createSelector(
  getAttributionCampaignReport,
  (
    report: IAttributionCampaignRow[]
  ): AttributionCampaignInitialChartData | null => {
    if (!report || !report.length) {
      return null;
    }

    const defaultValue: AttributionCampaignInitialChartData = {
      [ModelType.Sourced]: {},
      [ModelType.Even]: {},
      [ModelType.Custom]: {},
      [ModelType.Last]: {}
    };

    return report.reduce((acc: AttributionCampaignInitialChartData, item: IAttributionCampaignRow) => {
      acc[ModelType.Sourced][item.key] = item.sourcedRevenue;
      acc[ModelType.Even][item.key] = item.evenRevenue;
      acc[ModelType.Custom][item.key] = item.customRevenue;
      acc[ModelType.Last][item.key] = item.lastRevenue;

      return acc;
    }, defaultValue);
  });

export const getChartGroups = createSelector(
  getAttributionCampaignReport,
  (
    report: IAttributionCampaignRow[]
  ): IAttributionCampaignChartGroup[] => {
    return report.map(row => {
      return {
        key: row.key,
        label: row.label
      };
    });
  }
);

/* SELECTORS FOR CHART - end */

export const getAttributionCampaignColumns = createSelector(
  OrgConfigStore.getAttributionModels,
  isAttributionCampaignPipeline,
  UserSelectors.isUseNewInfluenceType, (
    models: Record<ModelType, IAttributionModel>,
    isPipeline: boolean,
    isUseNewInfluenceType: boolean
  ): IReportColumn[] => {
    if (!models) {
      return [];
    }

    const headers: IReportColumn[] = [
      {
        displayName: '',
        name: 'icon',
        width: 55,
        dataType: DataTypeEnum.Text
      },
      {
        displayName: 'measurementStudio.features.attributionCampaign.columns.campaignType',
        name: 'label',
        width: 200,
        dataType: DataTypeEnum.Text,
        startSorting: 'asc',
        internalLink(row: IAttributionCampaignRow, params: ICampaignFilters): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.CampaignAnalyticsCampaigns),
            queryParams: {
              dataSet: params.revenueType === RevenueType.Pipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
              cohort: params.cohort,
              gf: row.label,
              startDate: params.startDate,
              endDate: params.endDate,
              influenceType: params.influenceType
            }
          };
        }
      }
    ];

    if (models[ModelType.Sourced]?.active) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineFirstTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueFirstTouch',
        name: 'sourcedRevenue',
        width: 244,
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Last]?.active) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineLastTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueLastTouch',
        name: 'lastRevenue',
        width: 243,
        dataType: DataTypeEnum.Currency,
        startSorting: 'desc',
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Even]?.active) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouch',
        name: 'evenRevenue',
        width: 248,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Custom]?.active) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouchCustom'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouchCustom',
        name: 'customRevenue',
        width: 296,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    // repeat the condition because we need a certain order of objects in the array
    if (models[ModelType.Sourced]?.active) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.opportunitiesGenerated'
          : 'measurementStudio.features.attributionCampaign.columns.dealsGenerated',
        name: 'sourcedOppty',
        width: 180,
        dataType: DataTypeEnum.Number,
        startSorting: 'desc',
        internalLink(row: IAttributionCampaignRow, params: ICampaignFilters): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: {
              dateType: params.revenueType === RevenueType.Pipeline ? DateType.Created : DateType.Closed,
              type: params.revenueType,
              wonOnly: params.revenueType !== RevenueType.Pipeline,
              wonType: params.revenueType === RevenueType.Pipeline ? WonType.Any : WonType.Won,
              cohort: params.cohort,
              closedType: ClosedType.CLOSED,
              [InfluencesKeys.SourceCampaignGroup]: row.label,
              model: ModelType.Sourced,
              startDate: params.startDate,
              endDate: params.endDate
            }
          };
        }
      });
    }

    if (!isUseNewInfluenceType && (models[ModelType.Even]?.active || models[ModelType.Custom]?.active)) {
      headers.push({
        displayName: 'measurementStudio.features.attributionCampaign.columns.campaignResponses',
        name: 'customOppty',
        width: 180,
        dataType: DataTypeEnum.Number,
        startSorting: 'desc',
        internalLink(row: IAttributionCampaignRow, params: ICampaignFilters): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: {
              dateType: params.revenueType === RevenueType.Pipeline ? DateType.Created : DateType.Closed,
              type: params.revenueType,
              wonOnly: params.revenueType !== RevenueType.Pipeline,
              wonType: params.revenueType === RevenueType.Pipeline ? WonType.Any : WonType.Won,
              cohort: params.cohort,
              closedType: ClosedType.CLOSED,
              [InfluencesKeys.InfluencedCampaignGroup]: row.label,
              model: ModelType.Even,
              startDate: params.startDate,
              endDate: params.endDate
            }
          };
        }
      });
    }

    if (isUseNewInfluenceType) {
      headers.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.opportunityInfluenced'
          : 'measurementStudio.features.attributionCampaign.columns.dealInfluenced',
        name: 'unique',
        width: 180,
        dataType: DataTypeEnum.Number,
        startSorting: 'desc',
        internalLink(row: IAttributionCampaignRow, params: ICampaignFilters): IReportLink {
          return {
            link: getRouteLink(RouteItemEnum.OpportunityInsights),
            queryParams: {
              dateType: params.revenueType === RevenueType.Pipeline ? DateType.Created : DateType.Closed,
              type: params.revenueType,
              wonOnly: params.revenueType !== RevenueType.Pipeline,
              wonType: params.revenueType === RevenueType.Pipeline ? WonType.Any : WonType.Won,
              cohort: params.cohort,
              closedType: ClosedType.CLOSED,
              [InfluencesKeys.InfluencedCampaignGroup]: row.label,
              model: params.influenceType,
              startDate: params.startDate,
              endDate: params.endDate
            }
          };
        }
      });
    }

    return headers;
  });

export const getAttributionCampaignExpandedColumns = createSelector(
  OrgConfigStore.getAttributionModels,
  isAttributionCampaignPipeline,
  UserSelectors.isUseNewInfluenceType, (
    models: Record<ModelType, IAttributionModel>,
    isPipeline: boolean,
    isUseNewInfluenceType: boolean
  ): IReportColumn[] => {
    if (!models) {
      return [];
    }

    const expandedColumns: IReportColumn[] = [{
      displayName: 'measurementStudio.features.attributionCampaign.columns.campaign',
      name: 'name',
      width: 230,
      dataType: DataTypeEnum.Text,
      startSorting: 'asc',
      internalLink(row: IAttributionCampaignExpandedRow, params: ICampaignFilters): IReportLink {
        return {
          link: getRouteLink(RouteItemEnum.CampaignSpecificLeads),
          queryParams: {
            name: row.name,
            campaignId: row.id,
            cohort: params.cohort,
            dataSet: params.revenueType === RevenueType.Pipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
            tab: params.revenueType === RevenueType.Pipeline ? 'opptys' : 'deals',
            startDate: params.startDate,
            endDate: params.endDate,
            influenceType: params.influenceType
          }
        };
      }
    }];

    if (models[ModelType.Sourced]?.active) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineFirstTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueFirstTouch',
        name: ModelType.Sourced,
        width: 200,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Last]?.active) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineLastTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueLastTouch',
        name: ModelType.Last,
        width: 200,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Even]?.active) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouch'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouch',
        name: ModelType.Even,
        width: 200,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    if (models[ModelType.Custom]?.active) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineMultiTouchCustom'
          : 'measurementStudio.features.attributionCampaign.columns.revenueMultiTouchCustom',
        name: ModelType.Custom,
        width: 200,
        startSorting: 'desc',
        dataType: DataTypeEnum.Currency,
        shouldAbbreviate: false
      });
    }

    // repeat the condition because we need a certain order of objects in the array
    if (models[ModelType.Sourced]?.active) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.opportunitiesGenerated'
          : 'measurementStudio.features.attributionCampaign.columns.dealsGenerated',
        name: 'sourcedCount',
        width: 140,
        startSorting: 'desc',
        dataType: DataTypeEnum.Number,
        link(row: IAttributionCampaignExpandedRow, params: ICampaignFilters) {
          return {
            routeId: params.revenueType === RevenueType.Pipeline
              ? RouteItemEnum.CampaignSpecificOpportunities
              : RouteItemEnum.CampaignSpecificDeals,
            queryParams: {
              name: row.name,
              campaignId: row.id,
              cohort: params.cohort,
              dataSet: params.revenueType === RevenueType.Pipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
              modelType: ModelType.Sourced,
              tab: params.revenueType === RevenueType.Pipeline ? 'opptys' : 'deals',
              startDate: params.startDate,
              endDate: params.endDate,
              influenceType: params.influenceType
            }
          };
        }
      });
    }

    if (!isUseNewInfluenceType && (models[ModelType.Even]?.active || models[ModelType.Custom]?.active)) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.opportunityTouches'
          : 'measurementStudio.features.attributionCampaign.columns.dealTouches',
        name: 'attributedCount',
        width: 110,
        startSorting: 'desc',
        dataType: DataTypeEnum.Number,
        link(row: IAttributionCampaignExpandedRow, params: ICampaignFilters) {
          return {
            routeId: params.revenueType === RevenueType.Pipeline
              ? RouteItemEnum.CampaignSpecificOpportunities
              : RouteItemEnum.CampaignSpecificDeals,
            queryParams: {
              name: row.name,
              campaignId: row.id,
              cohort: params.cohort,
              dataSet: params.revenueType === RevenueType.Pipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
              modelType: ModelType.Even,
              tab: params.revenueType === RevenueType.Pipeline ? 'opptys' : 'deals',
              startDate: params.startDate,
              endDate: params.endDate
            }
          };
        }
      });
    }

    if (isUseNewInfluenceType) {
      expandedColumns.push({
        displayName: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.opportunityInfluenced'
          : 'measurementStudio.features.attributionCampaign.columns.dealInfluenced',
        name: 'unique',
        width: 130,
        startSorting: 'desc',
        dataType: DataTypeEnum.Number,
        link(row: IAttributionCampaignExpandedRow, params: ICampaignFilters) {
          return {
            routeId: params.revenueType === RevenueType.Pipeline
              ? RouteItemEnum.CampaignSpecificOpportunities
              : RouteItemEnum.CampaignSpecificDeals,
            queryParams: {
              name: row.name,
              campaignId: row.id,
              cohort: params.cohort,
              dataSet: params.revenueType === RevenueType.Pipeline ? DataSetType.OpptyCreatedDate : DataSetType.OpptyCloseDate,
              modelType: ModelType.Even,
              tab: params.revenueType === RevenueType.Pipeline ? 'opptys' : 'deals',
              startDate: params.startDate,
              endDate: params.endDate,
              influenceType: params.influenceType,
              wonType: params.revenueType === RevenueType.Pipeline ? WonType.Any : WonType.Won,
              type: params.revenueType
            }
          };
        }
      });
    }

    return expandedColumns;
  });

export const getAttributionCampaignMetrics = createSelector(
  getAttributionCampaignColumns,
  getAttributionCampaignReportTotals,
  isAttributionCampaignPipeline,
  (
    columns: IReportColumn[],
    totals: IAttributionCampaignTotals,
    isPipeline: boolean
  ): ReportMetric[] => {
    // NOTE (Michael Auer): “Revenue Influenced” heads up metric can use any of the numbers from the totals
    // row of the 4 revenue influenced columns because they all add up to the total Revenue attributed.
    const metrics = [];
    const revenueMetricColumn =
      columns.find(column => ['sourcedRevenue', 'lastRevenue', 'evenRevenue', 'customRevenue'].includes(column.name as ModelType));

    if (revenueMetricColumn) {
      metrics.push({
        title: isPipeline
          ? 'measurementStudio.features.attributionCampaign.columns.pipelineAttributed'
          : 'measurementStudio.features.attributionCampaign.columns.revenueAttributed',
        current: {
          value: totals[revenueMetricColumn.name],
          dataType: DataTypeEnum.Currency
        },
        tooltip: 'measurementStudio.features.attributionCampaign.columns.revenueMetricColumnTooltip'
      });
    }

    columns.forEach(column => {
      if (column.name === 'sourcedOppty') {
        metrics.push({
          title: column.displayName,
          current: {
            value: totals[column.name],
            dataType: DataTypeEnum.Number
          }
        });
      }

      if (column.name === 'unique') {
        metrics.push({
          title: column.displayName,
          current: {
            value: totals[column.name],
            dataType: DataTypeEnum.Number
          },
          tooltip: 'measurementStudio.features.attributionCampaign.columns.uniqueTooltip'
        });
      }
    });

    return metrics;
  });
