import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAccountHubSource } from './account-hub.source.interface';
import {
  IAccountFolder,
  IAccountListDataParams,
  IAccountListDataRequestParams,
  IAccountListDataResponse,
  IMetricInsightsParams,
  IMetricInsightsRequestParams,
  IAccountsFolderUser,
  ICreateAccountsFolderParams,
  IUpdateAccountsFolderParams,
  IDeleteAccountsFolderParams,
  ICreateAccountsListParams,
  ICreateAccountsListResponse,
  IDeleteAccountsListParams,
  IUpdateAccountsListParams,
  IMetricInsightsResponse, IAddAccountsListResponse
} from '@shared/interfaces';
import { getHttpParams } from '@util/http';

export enum AccountHubSourceURLs {
  LoadMetricInsights = '/account/load_metric_insights',
  GetAccountListsFolders = '/folder/getAccountsListsFolders',
  LoadListData = '/account/load_list_data',
  AddToList = '/api/v1/add_accounts_from_oppty_insights',
  FolderUsers = '/folder/getUsersForOrg',
  CreateFolder = '/folder/createFolder',
  DeleteFolder = '/folder/deleteFolder',
  UpdateFolder = '/folder/updateFolder',
  CreateList = '/accountsLists/createList',
  DeleteList = '/accountsLists/deleteList',
  UpdateList = '/accountsLists/updateList',
}

@Injectable({
  providedIn: 'root'
})
export class AccountHubSource extends HttpClientBase implements IAccountHubSource {

  getAccountListsFolders$(): Observable<IAccountFolder[]> {
    const endpoint = this.endpoint(AccountHubSourceURLs.GetAccountListsFolders);

    return this.httpClient.get<IAccountFolder[]>(endpoint);
  }

  loadListData$(params: IAccountListDataParams): Observable<IAccountListDataResponse> {
    const endpoint = this.endpoint(AccountHubSourceURLs.LoadListData);

    return this.httpClient.get<IAccountListDataResponse>(endpoint, {
      params: getHttpParams(this.getLoadListDataParams(params))
    });
  }

  addToList$(params: IAccountListDataParams): Observable<IAddAccountsListResponse> {
    const endpoint = this.endpoint(AccountHubSourceURLs.AddToList);
    return this.httpClient.get<IAddAccountsListResponse>(endpoint, {
      params: getHttpParams(this.getLoadListDataParams(params))
    });
  }

  getFolderUsers$(): Observable<IAccountsFolderUser[]> {
    const endpoint = this.endpoint(AccountHubSourceURLs.FolderUsers);

    return this.httpClient.get<IAccountsFolderUser[]>(endpoint);
  }

  createFolder$(body: ICreateAccountsFolderParams): Observable<string> {
    const endpoint = this.endpoint(AccountHubSourceURLs.CreateFolder);

    return this.httpClient.post(endpoint, getHttpParams({ ...body }), {
      responseType: 'text'
    });
  }

  deleteFolder$(body: IDeleteAccountsFolderParams): Observable<void> {
    const endpoint = this.endpoint(AccountHubSourceURLs.DeleteFolder);

    return this.httpClient.post<void>(endpoint, getHttpParams({ ...body }));
  }

  updateFolder$(body: IUpdateAccountsFolderParams): Observable<string> {
    const endpoint = this.endpoint(AccountHubSourceURLs.UpdateFolder);

    return this.httpClient.post(endpoint, getHttpParams({ ...body }), {
      responseType: 'text'
    });
  }

  createList$(body: ICreateAccountsListParams): Observable<ICreateAccountsListResponse> {
    const endpoint = this.endpoint(AccountHubSourceURLs.CreateList);

    return this.httpClient.post<ICreateAccountsListResponse>(endpoint, getHttpParams({ ...body }));
  }

  deleteList$(body: Omit<IDeleteAccountsListParams, 'reloadType'>): Observable<void> {
    const endpoint = this.endpoint(AccountHubSourceURLs.DeleteList);

    return this.httpClient.post<void>(endpoint, getHttpParams({ ...body }));
  }

  updateList$(body: Omit<IUpdateAccountsListParams, 'reloadType' | 'message'>): Observable<string> {
    const endpoint = this.endpoint(AccountHubSourceURLs.UpdateList);

    return this.httpClient.post(endpoint, getHttpParams({ ...body }), {
      responseType: 'text',
    });
  }

  loadMetricInsights$(params: IMetricInsightsParams): Observable<IMetricInsightsResponse> {
    const endpoint = this.endpoint(AccountHubSourceURLs.LoadMetricInsights);
    const requestParams = this.getMetricInsightsParams(params);

    return this.httpClient.get<IMetricInsightsResponse>(endpoint, {
      params: getHttpParams(requestParams)
    });
  }

  private getMetricInsightsParams(params: IMetricInsightsParams): IMetricInsightsRequestParams {
    return {
      ...params, // to keep global filters in the object
      account_correlation: params.accountCorrelation,
      account_field: params.accountField,
      cohort: params.cohort,
      metric_field: params.metricField,
      threshold: params.threshold,
      time_unit: params.timeUnit,
      models: params.models,
    };
  }

  private getLoadListDataParams(params: IAccountListDataParams): IAccountListDataRequestParams {
    return {
      ...params, // to keep global filters in the object
      account_filter: params.accountFilter,
      cohort: params.cohort,
      dir: params.dir,
      fieldType: params.fieldType,
      fld: params.fld,
      pg: params.pg,
      ps: params.ps,
      tbl: params.tbl,
      withSalesActivity: params.withSalesActivity,
    };
  }
}
