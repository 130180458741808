import { DataTypeEnum, GaugeChartFields, GlobalFiltersKeys, VisualizationTypes } from '@shared/enums';
import { IReportColumn } from '@shared/interfaces';

const DASHBOARDS_ASSETS = 'assets/shared/images/dashboards/visualizations/';
export const VISUALIZATION_ICON_PATH_MAPPER: Record<VisualizationTypes, { iconPath: string, label: string }> = {
  [VisualizationTypes.StackedArea]: {
    iconPath: `${DASHBOARDS_ASSETS}stacked-area.svg`,
    label: 'shared.dashboards.visualizationTypes.stackedArea'
  },
  [VisualizationTypes.Pareto]: {
    iconPath: `${DASHBOARDS_ASSETS}pareto.svg`,
    label: 'shared.dashboards.visualizationTypes.pareto'

  },
  [VisualizationTypes.Donut]: {
    iconPath: `${DASHBOARDS_ASSETS}pie.svg`,
    label: 'shared.dashboards.visualizationTypes.donut'
  },
  [VisualizationTypes.Gauge]: {
    iconPath: `${DASHBOARDS_ASSETS}gauge.svg`,
    label: 'shared.dashboards.visualizationTypes.gauge'
  },
  [VisualizationTypes.Line]: {
    iconPath: `${DASHBOARDS_ASSETS}line.svg`,
    label: 'shared.dashboards.visualizationTypes.line'
  },
  [VisualizationTypes.StackedColumn]: {
    iconPath: `${DASHBOARDS_ASSETS}stacked-column.svg`,
    label: 'shared.dashboards.visualizationTypes.stackedColumn'
  },
  [VisualizationTypes.Column]: {
    iconPath: `${DASHBOARDS_ASSETS}column.svg`,
    label: 'shared.dashboards.visualizationTypes.column'
  },
  [VisualizationTypes.Table]: {
    iconPath: `${DASHBOARDS_ASSETS}table.svg`,
    label: 'shared.dashboards.visualizationTypes.table'
  },
  [VisualizationTypes.ClusteredColumn]: {
    iconPath: `${DASHBOARDS_ASSETS}clustered-column.svg`,
    label: 'shared.dashboards.visualizationTypes.clusteredColumn'
  },
};
export const GAUGE_FORM_CONFIG = [
  {
    label: 'shared.dashboards.gaugeConfig.minimum',
    field: GaugeChartFields.Minimum
  }, {
    label: 'shared.dashboards.gaugeConfig.midpoint',
    field: GaugeChartFields.Midpoint
  }, {
    label: 'shared.dashboards.gaugeConfig.goal',
    field: GaugeChartFields.Goal
  }, {
    label: 'shared.dashboards.gaugeConfig.maximum',
    field: GaugeChartFields.Maximum
  }
];

export const GLOBAL_FILTERS_COLUMNS: IReportColumn[] = [{
  displayName: '',
  name: 'icon',
  width: 55,
  dataType: DataTypeEnum.Text,
}, {
  name: 'name',
  width: 200,
  displayName: 'shared.dashboards.dialog.totalTitleFilter',
  dataType: DataTypeEnum.Text
}, {
  name: 'value',
  width: 200,
  displayName: 'Value',
  dataType: DataTypeEnum.Text
}, {
  displayName: '',
  name: 'remove',
  width: 55,
  dataType: DataTypeEnum.Text,
}];

export const GLOBAL_FILTER_TITLE_MAP = {
  [GlobalFiltersKeys.Account]: 'shared.globalFilters.sectionTitle.account',
  [GlobalFiltersKeys.AccountList]: 'shared.globalFilters.sectionTitle.accountList',
  [GlobalFiltersKeys.Campaign]: 'shared.globalFilters.sectionTitle.campaign',
  [GlobalFiltersKeys.CampaignMember]: 'shared.globalFilters.sectionTitle.campaignMember',
  [GlobalFiltersKeys.Lead]: 'shared.globalFilters.sectionTitle.lead',
  [GlobalFiltersKeys.Opportunity]: 'shared.globalFilters.sectionTitle.opportunity',
  [GlobalFiltersKeys.TerminusAccount]: 'shared.globalFilters.sectionTitle.terminusAccount',
  [GlobalFiltersKeys.Segments]: 'shared.globalFilters.sectionTitle.segments'
};

export const GLOBAL_FILTER_TITLE_PLURAL_MAP = {
  [GlobalFiltersKeys.Account]: 'shared.globalFilters.pluralSectionTitle.account',
  [GlobalFiltersKeys.AccountList]: 'shared.globalFilters.pluralSectionTitle.accountList',
  [GlobalFiltersKeys.Campaign]: 'shared.globalFilters.pluralSectionTitle.campaign',
  [GlobalFiltersKeys.CampaignMember]: 'shared.globalFilters.pluralSectionTitle.campaignMember',
  [GlobalFiltersKeys.Lead]: 'shared.globalFilters.pluralSectionTitle.lead',
  [GlobalFiltersKeys.Opportunity]: 'shared.globalFilters.pluralSectionTitle.opportunity',
  [GlobalFiltersKeys.TerminusAccount]: 'shared.globalFilters.pluralSectionTitle.terminusAccount',
  [GlobalFiltersKeys.Segments]: 'shared.globalFilters.pluralSectionTitle.segments'
};
