import { Params } from '@angular/router';

import {
  IAccountFolder,
  IAccountList,
  IAnalyticsGlobalFilters,
  IFilter,
  IFolderItem,
  IGlobalFilter,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
} from '@shared/interfaces';
import {
  GLOBAL_FILTER_API_KEY_MAP,
  GLOBAL_FILTER_API_KEY_MAP_REVERSE,
  ANALYTICS_GLOBAL_FILTER_API_KEY_MAP,
} from '@shared/constants';
import { GlobalFiltersKeys, TableIdentifier } from '@shared/enums';
import { UserDefinedSegment } from '@shared/data-access/segments-api';

export function selectAllItemsInFolder(
  accountListFolderItem: IGlobalFilterFolderItem,
  targetItem: string,
): IGlobalFilterFolderItem {
  const targetFolderState = !accountListFolderItem.itemSelect[targetItem].allSelected;
  const targetFolderItemSelect = Object.keys(accountListFolderItem.itemSelect[targetItem].itemSelect).reduce(
    (acc, itemName: string) => {
      acc[itemName] = targetFolderState;
      return acc;
    },
    {},
  );
  const targetFolderItemSelectTotal = Object.keys(targetFolderItemSelect).length;

  return {
    ...accountListFolderItem,
    itemSelect: {
      ...accountListFolderItem.itemSelect,
      [targetItem]: {
        ...accountListFolderItem.itemSelect[targetItem],
        allSelected: targetFolderState,
        itemSelect: { ...targetFolderItemSelect },
        selectedItems: targetFolderItemSelect ? targetFolderItemSelectTotal : 0,
      },
    },
    selectedItems: targetFolderState
      ? accountListFolderItem.selectedItems + targetFolderItemSelectTotal
      : accountListFolderItem.selectedItems - targetFolderItemSelectTotal,
  };
}

export function selectTargetItemInFolder(
  accountListFolderItem: IGlobalFilterFolderItem,
  targetItem: string,
  targetSelectKey: string,
): IGlobalFilterFolderItem {
  if (
    !accountListFolderItem.itemSelect[targetItem] ||
    !accountListFolderItem.itemSelect[targetItem].itemSelect ||
    accountListFolderItem.itemSelect[targetItem].itemSelect[targetSelectKey] === undefined
  ) {
    return { ...accountListFolderItem };
  }
  const targetFolderItemKeyState = !accountListFolderItem.itemSelect[targetItem].itemSelect[targetSelectKey];
  const folderSelectedItem = targetFolderItemKeyState
    ? accountListFolderItem.selectedItems + 1
    : accountListFolderItem.selectedItems - 1;
  const folderItemSelectedItems = targetFolderItemKeyState
    ? accountListFolderItem.itemSelect[targetItem].selectedItems + 1
    : accountListFolderItem.itemSelect[targetItem].selectedItems - 1;

  return {
    ...accountListFolderItem,
    itemSelect: {
      ...accountListFolderItem.itemSelect,
      [targetItem]: {
        ...accountListFolderItem.itemSelect[targetItem],
        allSelected: accountListFolderItem.itemSelect[targetItem].total === folderItemSelectedItems,
        itemSelect: {
          ...accountListFolderItem.itemSelect[targetItem].itemSelect,
          [targetSelectKey]: targetFolderItemKeyState,
        },
        selectedItems: folderItemSelectedItems,
      },
    },
    selectedItems: folderSelectedItem,
  };
}

export function createMappedFilterMenuState(globalFilters: IGlobalFilters, dataFilterFilters: IFilter) {
  const filterKeys = Object.keys(dataFilterFilters);
  let mappedMenuState = createFilterMenuState(globalFilters);
  let folderInMenu: IGlobalFilterFolderItem;
  filterKeys.forEach((folderName: GlobalFiltersKeys) => {
    Object.keys(dataFilterFilters[folderName]).forEach((folder: string) => {
      if (dataFilterFilters[folderName][folder] && dataFilterFilters[folderName][folder].length) {
        dataFilterFilters[folderName][folder].forEach((folderItem: string) => {
          folderInMenu = { ...mappedMenuState[folderName] };
          mappedMenuState = {
            ...mappedMenuState,
            [folderName]: selectTargetItemInFolder(folderInMenu, folder, folderItem),
          };
        });
      }
    });
  });

  return mappedMenuState;
}

export function createFilterMenuState(globalFilters: IGlobalFilters): IGlobalFilterFolder {
  const globalFilterMenu = {} as IGlobalFilterFolder;

  return Object.keys(globalFilters).reduce((acc: IGlobalFilterFolder, key: GlobalFiltersKeys) => {
    let folderTotal = 0;
    const itemSelect: Record<string, IFolderItem> = globalFilters[key].reduce(
      (accu: Record<string, IFolderItem>, item: IGlobalFilter) => {
        const folderItemSelect: Record<string, boolean> = item.data.reduce(
          (accum: Record<string, boolean>, itemName: string) => {
            accum[itemName] = false;
            return accum;
          },
          {},
        );

        accu[item.number] = {
          allSelected: false,
          itemSelect: folderItemSelect,
          name: item.name,
          isOpen: false,
          total: item.data.length,
          selectedItems: 0,
        };
        folderTotal += accu[item.number].total;

        return accu;
      },
      {},
    );

    acc[key] = {
      itemSelect,
      total: folderTotal,
      selectedItems: 0,
    };

    return acc;
  }, globalFilterMenu);
}

export function createAccountListMenuState(
  accountListFolder: IAccountFolder[],
  listIds: string[],
): IGlobalFilterFolderItem {
  let accountFolderTotal = 0;
  let selectedAccountFolderTotal = 0;
  const accountFolderItemSelect =
    accountListFolder &&
    accountListFolder.reduce((acc: Record<string, IFolderItem>, accountFolder: IAccountFolder) => {
      // NOTE: add folders to the filters only if they have lists
      if (!accountFolder.contents.length) {
        return acc;
      }
      let selectedItemTotal = 0;
      const itemSelect: Record<string, boolean> = accountFolder.contents.reduce(
        (accu: Record<string, boolean>, accountList: IAccountList) => {
          if (listIds.indexOf(`${accountList.id}`) > -1) {
            selectedItemTotal += 1;
          }
          accu[accountList.id] = listIds.indexOf(`${accountList.id}`) > -1;
          return accu;
        },
        {},
      );
      acc[accountFolder.folderName] = {
        allSelected: accountFolder.contents.length === selectedItemTotal,
        itemSelect,
        name: accountFolder.folderName,
        isOpen: false,
        total: accountFolder.contents.length,
        selectedItems: selectedItemTotal,
      };
      accountFolderTotal += acc[accountFolder.folderName].total;
      selectedAccountFolderTotal += selectedItemTotal;
      return acc;
    }, {});

  return {
    itemSelect: accountFolderItemSelect,
    total: accountFolderTotal,
    selectedItems: selectedAccountFolderTotal,
  };
}

export function filterAccountListMenuState(
  accountListFolderItem: IGlobalFilterFolderItem,
  accountListFolder: IAccountFolder[] = [],
  searchQuery: string,
): IGlobalFilterFolderItem {
  let folderIncludesSearchQuery = false;
  const regex = new RegExp(searchQuery, 'i');

  const itemSelect = Object.keys(accountListFolderItem.itemSelect).reduce(
    (acc: Record<string, IFolderItem>, folderItemName: string) => {
      let folderItemIncludesSeachQuery = false;
      const targetFolderAccount =
        accountListFolder &&
        accountListFolder.filter((accountFolder: IAccountFolder) => accountFolder.folderName === folderItemName)[0];
      const folderItem = accountListFolderItem.itemSelect[folderItemName];
      const folderItemSelect = Object.keys(folderItem.itemSelect).reduce(
        (accu: Record<string, boolean>, folderItemSelectItem: string) => {
          const targetFolderAccountList = targetFolderAccount?.contents?.filter(
            (accountList: IAccountList) => `${accountList.id}` === folderItemSelectItem,
          )[0];
          if (targetFolderAccountList && targetFolderAccountList.name.match(regex)) {
            folderItemIncludesSeachQuery = true;
            accu[folderItemSelectItem] = folderItem.itemSelect[folderItemSelectItem];
          }
          return accu;
        },
        {},
      );

      if (folderItemIncludesSeachQuery || folderItemName.match(regex)) {
        folderIncludesSearchQuery = true;
        acc[folderItemName] = {
          ...accountListFolderItem.itemSelect[folderItemName],
          itemSelect: folderItemSelect,
        };
      }

      return acc;
    },
    {},
  );

  return folderIncludesSearchQuery ? { ...accountListFolderItem, itemSelect } : ({} as IGlobalFilterFolderItem);
}

export function filterFilterMenuState(
  globalFilterMenu: IGlobalFilterFolder,
  searchQuery: string,
  segments: UserDefinedSegment[],
): IGlobalFilterFolder {
  const filterMenuKeys = Object.keys(globalFilterMenu);
  const regex = new RegExp(searchQuery, 'i');

  return filterMenuKeys.reduce((acc: IGlobalFilterFolder, key: GlobalFiltersKeys) => {
    let folderIncludesSearchQuery = false;
    let folder: IGlobalFilterFolderItem = {
      ...globalFilterMenu[key],
    };
    const itemSelect = Object.keys(globalFilterMenu[key].itemSelect).reduce(
      (accu: Record<string, IFolderItem>, folderItemName: string) => {
        let folderItemIncludesSearchQuery = false;
        const folderItem = globalFilterMenu[key].itemSelect[folderItemName];
        const folderItemSelect =
          key !== GlobalFiltersKeys.Segments
            ? // Normal path for searching
              Object.keys(folderItem.itemSelect).reduce(
                (accum: Record<string, boolean>, folderItemSelectItem: string) => {
                  if (folderItemSelectItem.match(regex)) {
                    // TODO: fix coverage
                    /* istanbul ignore next */
                    folderItemIncludesSearchQuery = true;
                    /* istanbul ignore next */
                    accum[folderItemSelectItem] = folderItem.itemSelect[folderItemSelectItem];
                  }

                  return accum;
                },
                {},
              )
            : // Segment specific search
              Object.keys(folderItem.itemSelect).reduce(
                (accum: Record<string, boolean>, folderItemSelectItem: string) => {
                  // find segment value
                  const segment = segments.find(segment => segment.lqsSharedQueryId === folderItemSelectItem);
                  if (segment.displayName.match(regex)) {
                    // TODO: fix coverage
                    /* istanbul ignore next */
                    folderItemIncludesSearchQuery = true;
                    /* istanbul ignore next */
                    accum[folderItemSelectItem] = folderItem.itemSelect[folderItemSelectItem];
                  }

                  return accum;
                },
                {},
              );

        if (folderItemIncludesSearchQuery || folderItem.name.match(regex)) {
          folderIncludesSearchQuery = true;
          accu[folderItemName] = {
            ...folderItem,
            itemSelect: folderItemSelect,
          };
        }

        return accu;
      },
      {},
    );

    if (folderIncludesSearchQuery) {
      folder = {
        ...folder,
        itemSelect,
      };
      acc[key] = folder;
    }

    return acc;
  }, {} as IGlobalFilterFolder);
}

export function calculateSelectedFiltersNumber(filters: IFilter, listIds: string[]): number {
  const existedListIds = listIds?.filter(Boolean);
  if (!filters && !existedListIds) {
    return 0;
  }

  const filtersNumber =
    (filters &&
      Object.keys(filters).reduce((acc, key) => {
        return acc + Object.values(filters[key]).flat().length;
      }, 0)) ||
    0;

  const listsNumber = existedListIds?.length || 0;

  return filtersNumber + listsNumber;
}

export function mapAccountListMenuToListIds(accountListFolder: IGlobalFilterFolderItem): string[] {
  return Object.keys(accountListFolder.itemSelect).reduce((acc: string[], folderItem: string) => {
    const folderItemArray = Object.keys(accountListFolder.itemSelect[folderItem].itemSelect).filter(
      (itemName: string) => accountListFolder.itemSelect[folderItem].itemSelect[itemName],
    );
    return [...acc, ...folderItemArray];
  }, []);
}

export function mapFilterMenuToDataFilter(filterMenu: IGlobalFilterFolder): IFilter {
  return Object.keys(filterMenu).reduce((acc: IFilter, globalFilterKey: GlobalFiltersKeys) => {
    const folderItems = filterMenu[globalFilterKey].itemSelect;
    acc[globalFilterKey] = Object.keys(folderItems).reduce((accu: Record<string, string[]>, item: string) => {
      const folderItem = folderItems[item];

      accu[item] = Object.keys(folderItem.itemSelect).filter((filter: string) => folderItem.itemSelect[filter]);

      return accu;
    }, {});

    return acc;
  }, {});
}

// get selected filters from url on init
export function getFiltersFromRoute(params: Params): IFilter {
  return Object.keys(params).reduce((acc, key: string) => {
    if (key.indexOf('filter') === 0 && params[key]?.length) {
      if (Array.isArray(params[key])) {
        params[key].forEach(filter => {
          const pair = filter.split(':'); // filter value and filter api key
          const folder = acc[GLOBAL_FILTER_API_KEY_MAP_REVERSE[pair[1]]];
          const folderFilters = (folder && folder[key]) || [];

          if (!folder) {
            acc[GLOBAL_FILTER_API_KEY_MAP_REVERSE[pair[1]]] = {
              [key]: [pair[0]],
            };
          } else {
            folder[key] = [...folderFilters, pair[0]];
          }
        });
      } else {
        const pair = params[key].split(':'); // filter value and filter api key
        const folder = acc[GLOBAL_FILTER_API_KEY_MAP_REVERSE[pair[1]]];
        const folderFilters = (folder && folder[key]) || [];

        if (!folder) {
          acc[GLOBAL_FILTER_API_KEY_MAP_REVERSE[pair[1]]] = {
            [key]: [pair[0]],
          };
        } else {
          folder[key] = [...folderFilters, pair[0]];
        }
      }
    }

    return acc;
  }, {});
}

export function getAllGlobalFiltersFromParams(params: Params): {
  filters: IFilter;
  listIds: string[];
} {
  const accountList = params['al'];
  const listIdsFromRoute = accountList ? accountList.split(',') : [];
  const segments = params['sg'] ? params['sg'].split(',') : [];
  const filters = getFiltersFromRoute(params);
  if (segments.length)
    filters['segments'] = {'segments': segments};

  return {
    filters,
    listIds: listIdsFromRoute,
  };
}

// NOTE: we have a new api for campaign/web analytics reports
// that use another structure of global filter in query params
// that's why we need to transform regular structure to new one
export function getAllAnalyticsGlobalFilters(listIds: string[], filters: IFilter): IAnalyticsGlobalFilters {
  const mappedFilters = filters ? mapFiltersToAnalyticsFilters(filters) : [];
  if (listIds?.length && listIds[0]) {
    mappedFilters.push({
      tableIdentifier: TableIdentifier.AccountList,
      dimensionIdentifier: 'list',
      dimensionValues: listIds,
    });
  }
  return mappedFilters;
}

// map applied filters in format suitable for the api
const mapFiltersToAnalyticsFilters = (filters: IFilter): IAnalyticsGlobalFilters => {
  return Object.keys(filters).reduce<IAnalyticsGlobalFilters>((acc, key) => {
    const folder = filters[key];
    const tableIdentifier = ANALYTICS_GLOBAL_FILTER_API_KEY_MAP.get(key);
    Object.keys(folder).forEach(dimensionIdentifier => {
      if (folder[dimensionIdentifier].length) {
        acc.push({
          tableIdentifier,
          dimensionIdentifier,
          dimensionValues: folder[dimensionIdentifier],
        });
      }
    });

    return acc;
  }, []);
};

// get applied filters to add them to query params
export function getMappedAppliedFilters(filters: IFilter): Record<string, string[]> {
  return Object.keys(filters).reduce((acc, key) => {
    const folder = filters[key];
    Object.keys(folder).forEach(filterN => {
      if (folder[filterN].length) {
        const mappedFilters = folder[filterN].map(filter => `${filter}:${GLOBAL_FILTER_API_KEY_MAP[key]}`);
        acc[filterN] = acc[filterN] ? [...acc[filterN], ...mappedFilters] : mappedFilters;
      }
    });

    return acc;
  }, {});
}
