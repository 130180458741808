import { createAction, props } from '@ngrx/store';

export const toggleDemoMode = createAction(
  '[DemoConfig] Toggle Demo Mode'
);

export const toggleSlowdown = createAction(
  '[DemoConfig] Slowdown Toggled'
);

export const slowdownSecondsChanged = createAction(
  '[DemoConfig] Slowdown Seconds Changed',
  props<{ time: number }>()
);

export const toggleDebug = createAction(
  '[DemoConfig] Debug Toggled'
);
