import { InjectionToken } from '@angular/core';

import { Environment, InjectedEnv } from './environment.interface';

/**
 * @const ENVIRONMENT
 * Injection token for the environment interface to be provided by the applications.
 */
export const ENVIRONMENT: InjectionToken<Environment> = new InjectionToken('ENVIRONMENT');

export const ENV_TOKEN = new InjectionToken<InjectedEnv>('ENV');
