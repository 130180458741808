<tsh-report-checkbox-selector [label]="label"
                              [tooltip]="tooltip"
                              (search)="search($event)"
                              (cancel)="onCancel()"
                              (changed)="onChangeOptions($event)"
                              (confirm)="onConfirm()"
                              [showSearch]="true"
                              [selectedOptions]="selectedOptions$ | async"
                              [options]="options$ | async"
                              [field]="field">
</tsh-report-checkbox-selector>
