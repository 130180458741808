import { createFeatureSelector, createSelector } from '@ngrx/store';

import { tileModalFeatureKey, TileModalState } from './tile-modal.reducer';
import { GlobalFiltersKeys, TileCategories, TileTypes } from '@shared/enums';
import {
  DefaultTileInformation,
  IAccountFolder,
  IAccountList,
  IDataFilter,
  IGlobalFilter,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
  ISelectedGlobalFilter
} from '@shared/interfaces';
import {
  calculateSelectedFiltersNumber,
  createAccountListMenuState,
  createMappedFilterMenuState,
  filterAccountListMenuState,
  filterFilterMenuState,
  getMappedAppliedFilters
} from '@util/helpers';
import { GLOBAL_FILTER_HEADER_KEY_MAP } from '@shared/constants';
import * as AccountListStore from '@shared/data-access/account-hub';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';

export const selectTileModalState = createFeatureSelector<TileModalState>(tileModalFeatureKey);

export const getIsLoadingData = createSelector(selectTileModalState,
  (state: TileModalState) => state.isLoadingData);

export const getPreviewError = createSelector(selectTileModalState,
  (state: TileModalState) => state.error);

export const getPreviewData = createSelector(selectTileModalState,
  (state: TileModalState) => state.previewData);

export const getPreviewVisualizationConfig = createSelector(selectTileModalState,
  (state: TileModalState) => state.previewVisualizationConfig);

export const getSettingsFilters = createSelector(selectTileModalState,
  (state: TileModalState) => state.settingsFilters);

// get all available tiles for the current user
export const getTilesInfo = createSelector(selectTileModalState,
  (state: TileModalState) => state.tilesInfo);

// split tiles by category to show them in step 1 - Select type
export const getTilesByCategory = createSelector(getTilesInfo,
  (tilesInfo: Record<TileTypes, DefaultTileInformation>) => {
    if (!tilesInfo) {
      return [];
    }
    const tiles = Object.values(tilesInfo);
    const tilesByCategory = tiles.reduce((acc: Record<TileCategories, DefaultTileInformation[]>, tile: DefaultTileInformation) => {
      acc[tile.category] = acc[tile.category]
        ? [...acc[tile.category], tile]
        : [tile];
      return acc;
    }, {} as Record<TileCategories, DefaultTileInformation[]>);
    const availableCategories = Object.keys(tilesByCategory) as TileCategories[];
    return availableCategories.map(category => ({
      category,
      tiles: tilesByCategory[category]
    }));
  });

export const getFilterSearchQuery = createSelector(selectTileModalState,
  (state: TileModalState) => state.filterSearchQuery);

export const getSelectedGlobalFilters = createSelector(selectTileModalState,
  (state: TileModalState) => state.selectedGlobalFilters);

export const getSelectedGlobalFiltersAsParams = createSelector(getSelectedGlobalFilters,
  (dataFilter: IDataFilter): Record<string, string[] | string> => {
    const mappedFilters = dataFilter.filters ? getMappedAppliedFilters(dataFilter.filters) : {};
    const al = dataFilter.listIds?.length ? dataFilter.listIds.join(',') : null;
    return al ? {...mappedFilters, al} : mappedFilters;
  });

export const getSelectedDataFilters = createSelector(getSelectedGlobalFilters,
  (dataFilter: IDataFilter) => dataFilter.filters);

export const getDataFilterListIds = createSelector(getSelectedGlobalFilters,
  (dataFilter: IDataFilter) => dataFilter.listIds);

export const getGlobalFilterMenuBase = createSelector(
  GlobalFiltersStore.getGlobalFiltersData,
  getSelectedDataFilters,
  createMappedFilterMenuState
);

export const getAccountListMenuBase = createSelector(
  AccountListStore.getFolders,
  getDataFilterListIds,
  createAccountListMenuState
);

export const getGlobalFilterMenuAfterSearch = createSelector(
  getGlobalFilterMenuBase,
  getFilterSearchQuery,
  (
    state: IGlobalFilterFolder,
    searchQuery: string | null,
  ) => searchQuery ? filterFilterMenuState(state, searchQuery, []) : state
);

export const getAccountListMenuAfterSearch = createSelector(
  getAccountListMenuBase,
  AccountListStore.getFolders,
  getFilterSearchQuery,
  (
    state: IGlobalFilterFolderItem,
    accountListFolder: IAccountFolder[],
    searchQuery: string | null,
  ) => {
    if (searchQuery && state) {
      const stateAfterSearch = filterAccountListMenuState(state, accountListFolder, searchQuery);
      return Object.keys(stateAfterSearch).length ? stateAfterSearch : null;
    }
    return Object.keys(state).length ? state : null;
  }
);

export const getSelectedFiltersNumber = createSelector(
  getSelectedGlobalFilters,
  (selectedGlobalFilters: IDataFilter) => {
    const selectedFilters = selectedGlobalFilters.filters;
    const selectedListIds = selectedGlobalFilters.listIds;
    return calculateSelectedFiltersNumber(selectedFilters, selectedListIds);
  }
);

export const getGlobalFiltersTableData = createSelector(
  GlobalFiltersStore.getGlobalFiltersData,
  getGlobalFilterMenuBase,
  AccountListStore.getFolders,
  getAccountListMenuBase,
  (
    globalFilters: IGlobalFilters,
    globalFiltersState: IGlobalFilterFolder,
    accountLists: IAccountFolder[],
    accountListsState: IGlobalFilterFolderItem
  ): ISelectedGlobalFilter[] => {
    const keys = Object.keys(globalFiltersState) as GlobalFiltersKeys[];
    const selectedFilters = keys.reduce((acc: ISelectedGlobalFilter[], key: GlobalFiltersKeys) => {
      // we need only selected filters
      if (globalFiltersState[key].selectedItems > 0) {
        globalFilters[key].forEach((filter: IGlobalFilter) => {
          // add each selected filter to the array with all its items (checked or not)
          // to show them in the table
          if (globalFiltersState[key].itemSelect[filter.number].selectedItems > 0) {
            const items = filter.data.map((item: string) => ({
              id: item,
              name: item,
              isChecked: globalFiltersState[key].itemSelect[filter.number].itemSelect[item]
            }));
            acc.push({
              sectionKey: key,
              sectionName: GLOBAL_FILTER_HEADER_KEY_MAP[key],
              filterName: filter.name,
              filterNumber: filter.number,
              filterItems: items,
              selectedItems: globalFiltersState[key].itemSelect[filter.number].selectedItems,
              // name to display in the table
              name: `${GLOBAL_FILTER_HEADER_KEY_MAP[key]} / ${filter.name}`,
              // string of names of all checked items
              value: items.filter(item => item.isChecked).map(item => item.name).join(', ')
            });
          }
        });
      }
      return acc;
    }, []);

    const selectedAccountLists = accountListsState && accountListsState.selectedItems === 0
      ? []
      : accountLists.reduce((acc: ISelectedGlobalFilter[], folder: IAccountFolder) => {
        if (accountListsState.itemSelect[folder.folderName]?.selectedItems > 0) {
          const items = folder.contents.map((list: IAccountList) => ({
            id: list.id.toString(), // is necessary to toggle folder item
            name: list.name,
            isChecked: accountListsState.itemSelect[folder.folderName].itemSelect[list.id]
          }));
          acc.push({
            sectionKey: GlobalFiltersKeys.AccountList,
            sectionName: GLOBAL_FILTER_HEADER_KEY_MAP[GlobalFiltersKeys.AccountList],
            filterName: folder.folderName,
            filterNumber: folder.folderName,
            filterItems: items,
            selectedItems: accountListsState.itemSelect[folder.folderName].selectedItems,
            // name to display in the table
            name: `${GLOBAL_FILTER_HEADER_KEY_MAP[GlobalFiltersKeys.AccountList]} / ${folder.folderName}`,
            // string of names of all checked items
            value: items.filter(item => item.isChecked).map(item => item.name).join(', ')
          });
        }
        return acc;
      }, []);

    return [
      ...selectedAccountLists,
      ...selectedFilters
    ];
  }
);
