export enum AdsInsightsReportType {
  PageView = 'PAGE_VIEW',
  Lead = 'LEAD',
  CampaignResponses = 'CAMPAIGN_RESPONSES',
  NewPipeline = 'NEW_PIPELINE',
  Pipeline = 'PIPELINE',
  Revenue = 'REVENUE',
}

export const ADS_INSIGHTS_REPORT_TYPE_MAPPING: Record<AdsInsightsReportType, string> = {
  [AdsInsightsReportType.PageView]: 'PageView',
  [AdsInsightsReportType.Lead]: 'Lead',
  [AdsInsightsReportType.CampaignResponses]: 'CampaignResponses',
  [AdsInsightsReportType.NewPipeline]: 'NewPipeline',
  [AdsInsightsReportType.Pipeline]: 'Pipeline',
  [AdsInsightsReportType.Revenue]: 'Revenue'
};
