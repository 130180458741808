import { TsSortState } from '@terminus-lib/ui-sort';

import { All, DateCohortsGroups, SortDirections } from '@shared/enums';
import { compare } from './general.helper';
import { PER_PAGE } from '@shared/constants';

export function replaceLegacyCohort(cohort: string): string {
  return cohort === '*NONE*' || cohort === 'time'
    ? All.ALL_TIME
    : cohort;
}

export function getStartAndEndDateFromParams(
  cohort: string,
  startDate?: string | number,
  endDate?: string | number): { endDate: number; startDate: number } {
  // NOTE: if date cohort is custom but there is no startDate
  // then we have to set startDate the same as endDate or just current date
  const newStartDate = cohort === DateCohortsGroups.Custom
    ? startDate || endDate || new Date().getTime()
    : startDate || new Date().getTime();
  // NOTE: if date cohort is custom but there is no endDate
  // then we have to set endDate the same as startDate or just current date
  const newEndDate = cohort === DateCohortsGroups.Custom
    ? endDate || startDate || new Date().getTime()
    : endDate || new Date().getTime();
  // need to change startDate and endDate to number because query params are string
  return {
    startDate: Number(newStartDate),
    endDate: Number(newEndDate),
  };
}

export function sortReportData<T>(data: T[], sortState: TsSortState | null): T[] {
  if (!data || !data.length) {
    return [];
  }

  if (!sortState || !sortState.direction || !sortState.active) {
    return data;
  }
  const sortBy = sortState.active;
  // use slice to make a copy of the array to avoid mutation
  return data.slice().sort((a: T, b: T) => {
    return compare(a[sortBy], b[sortBy], sortState.direction === SortDirections.Asc);
  });
}

export function applyPageToReportData<T>(data: T[], page: number, perPage = PER_PAGE): T[] {
  if (!data || !data.length) {
    return [];
  }

  const pageAfterCheck = !page || isNaN(page) || page <= 0 ? 1 : page;
  const start = (pageAfterCheck - 1) * perPage; // minus 1 because array starts with 0
  const end = pageAfterCheck * perPage;
  return data.slice(start, end);
}
