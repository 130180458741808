import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { AppSettingsSource } from '../../sources/app-settings/app-settings.source';
import { hubTokenName } from '@shared/constants';
import { IDcLastUpdatedDate } from '@shared/interfaces';
import { EnvService } from '@shared/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(
    public source: AppSettingsSource,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getDcLastUpdatedDate$(): Observable<IDcLastUpdatedDate> {
    return regenerateOnRetry(() => this.source.getDcLastUpdatedDate$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }
}
