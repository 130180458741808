// NOTE: Store account hub data here because we don't have
// such feature in Measurement Studio
import { DataTypeEnum, FieldType, RouteItemEnum } from '@shared/enums';
import { IAccountItem, IAccountsColumn, IReportLink, IRouteItem } from '@shared/interfaces';
import { Inject, Injectable } from '@angular/core';
import { APP_ROUTES } from '@app-routes';

@Injectable({ providedIn: 'root' })
export class AccountHubColumnService {

  constructor(@Inject(APP_ROUTES) private applicationRoutes: IRouteItem[]) {
  }

  getAccounsColumnsFirstPart(): IAccountsColumn[] {
    const link = this.getRouteLink(RouteItemEnum.AccountSpecific);
    return [{
      name: 'accountName',
      displayName: 'Account Name',
      dataType: DataTypeEnum.Text,
      width: 250,
      apiType: FieldType.AccountColumns,
      visible: true,
      /* istanbul ignore next */
      internalLink(row: IAccountItem): IReportLink {
        return {
          link,
          queryParamsHandling: 'merge',
          queryParams: {
            accountId: row.accountId,
          }
        };
      },
    }, {
      name: 'industry',
      displayName: 'Industry',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.Firmographics,
      visible: false,
    }, {
      name: 'source',
      displayName: 'Source',
      dataType: DataTypeEnum.Text,
      width: 150,
      apiType: FieldType.AccountColumns,
      visible: true,
    }, {
      name: 'website',
      displayName: 'Website',
      dataType: DataTypeEnum.Text,
      width: 150,
      apiType: FieldType.AccountColumns,
      visible: true,
    }, {
      name: 'employeeRange',
      displayName: 'Employee Range',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.Firmographics,
      visible: false,
    }, {
      name: 'revenueRange',
      displayName: 'Revenue Range',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.Firmographics,
      visible: false,
    }];
  }

  getTerminusDataColumns(): IAccountsColumn[] {
    return [{
      name: 'spikeDate',
      displayName: 'Engagement Spike',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.EngagementSpike,
      visible: true,
    }, {
      name: 'spikeModels',
      displayName: 'Spike Models',
      dataType: DataTypeEnum.Text,
      width: 150,
      apiType: FieldType.EngagementSpike,
      visible: true,
    }, {
      name: 'adImpressions',
      displayName: 'Ad Impressions',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.AdImpressions,
      visible: true,
    }, {
      name: 'adSpend',
      displayName: 'Ad Spend',
      dataType: DataTypeEnum.Currency,
      decimal: 2,
      width: 150,
      apiType: FieldType.AdImpressions,
      visible: false,
    }, {
      name: 'adClicks',
      displayName: 'Ad Clicks',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.AdClicks,
      visible: true,
    }, {
      name: 'pageViews',
      displayName: 'Page Views',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.WebVisits,
      visible: true,
    }];
  }

  getAccounsColumnsSecondPart(): IAccountsColumn[] {
    return [{
      name: 'intentDate',
      displayName: 'Last Intent Date',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.Intent,
      visible: false,
    }, {
      name: 'topic',
      displayName: 'Intent topic',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.Intent,
      visible: true,
    }];
  }

  getSigstrColumns(): IAccountsColumn[] {
    return [{
      name: 'intentScore',
      displayName: 'Propensity to Purchase',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.SigstrRelationship,
      visible: true,
    }, {
      name: 'companyScore',
      displayName: 'Relationship Score',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.SigstrRelationship,
      visible: true,
    }, {
      name: 'firstInteraction',
      displayName: 'Sigstr First Email',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.SigstrRelationship,
      visible: false,
    }, {
      name: 'lastInteraction',
      displayName: 'Sigstr Last Email',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.SigstrRelationship,
      visible: false,
    }];
  }

  getAccountsColumnsThirdPart(): IAccountsColumn[] {
    return [{
      name: 'owner',
      displayName: 'Owner',
      dataType: DataTypeEnum.Text,
      width: 250,
      apiType: FieldType.AccountColumns,
      visible: false,
    }, {
      name: 'contacts',
      displayName: 'Known People',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.Contact,
      visible: true,
    }, {
      name: 'influencedContacts',
      displayName: 'Unique Campaign Respondents',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.InfluencedContacts,
      visible: true,
    }, {
      name: 'campaignMembers',
      displayName: 'Campaign Responses',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.CampaignMember,
      visible: true,
    }, {
      name: 'parentAccount',
      displayName: 'Parent Account',
      dataType: DataTypeEnum.Text,
      width: 200,
      apiType: FieldType.ParentAccount,
      visible: false,
    }];
  }

  getInfluenceTypeColumns(): IAccountsColumn[] {
    return [{
      name: 'pre',
      displayName: 'Pre-Opp Creation Responses',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.OpptyInfluence,
      visible: false,
    }, {
      name: 'post',
      displayName: 'Post-Opp Creation Responses',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.OpptyInfluence,
      visible: false,
    }];
  }

  getSalesActivityColumns(): IAccountsColumn[] {
    return [{
      name: 'salesActivity',
      displayName: 'Sales Activity',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.SalesActivity,
      visible: true,
    }];
  }

  getAccountsColumnsFourthPart(): IAccountsColumn[] {
    return [{
      name: 'lastResponse',
      displayName: 'Last Campaign Response Date',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.AccountColumns,
      visible: true,
    }, {
      name: 'lastActivity',
      displayName: 'Last Activity',
      dataType: DataTypeEnum.Date,
      width: 150,
      apiType: FieldType.AccountColumns,
      visible: true,
    }, {
      name: 'openPipeline',
      displayName: 'Open Pipeline',
      dataType: DataTypeEnum.Currency,
      width: 150,
      apiType: FieldType.OpenOpportunities,
      visible: true,
    }, {
      name: 'openOpptys',
      displayName: 'Open Opportunities',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.OpenOpportunities,
      visible: true,
    }, {
      name: 'opptys',
      displayName: 'Total Opportunities',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.Opportunities,
      visible: false,
    }, {
      name: 'deals',
      displayName: 'Total Deals',
      dataType: DataTypeEnum.Number,
      width: 150,
      apiType: FieldType.Deals,
      visible: false,
    }, {
      name: 'pipeline',
      displayName: 'Total Pipeline',
      dataType: DataTypeEnum.Currency,
      width: 150,
      apiType: FieldType.Opportunities,
      visible: false,
    }, {
      name: 'revenue',
      displayName: 'Total revenue',
      dataType: DataTypeEnum.Currency,
      width: 150,
      apiType: FieldType.Deals,
      visible: false,
    }];
  }

  private getRouteLink(routeId: RouteItemEnum): string[] | string {
    const route = this.applicationRoutes.find(_route => _route.routeId === routeId);
    return route?.routeLink || '';
  }
}
