import { AnalyticsColumnName as Column } from '../enums';
import { DataTypeEnum, ModelType, RouteItemEnum } from '@shared/enums';
import { IAnalyticsColumn, IMediumsTableItem } from '../interfaces';
import { IReportLink } from '@shared/interfaces';
import { getRouteLink } from '@measurement-studio/util/helpers';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export const hasNestedTable: IAnalyticsColumn = {
  name: Column.HasNestedTable,
  width: 60,
  displayName: '',
  dataType: DataTypeEnum.Text,
  visible: true,
  startSorting: 'asc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom]
};

export const responses: IAnalyticsColumn = {
  name: Column.Responses,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.responses',
  dataType: DataTypeEnum.Number,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const people: IAnalyticsColumn = {
  name: Column.People,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.people',
  dataType: DataTypeEnum.Number,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Custom],
};

export const accounts: IAnalyticsColumn = {
  name: Column.Accounts,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.accounts',
  dataType: DataTypeEnum.Number,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const opptys: IAnalyticsColumn = {
  name: Column.Opptys,
  width: 136,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.opptys',
  dataType: DataTypeEnum.Number,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const deals: IAnalyticsColumn = {
  name: Column.Deals,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.deals',
  dataType: DataTypeEnum.Number,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const opptyTouches: IAnalyticsColumn = {
  name: Column.OpptyTouches,
  width: 110,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.opptyTouches',
  dataType: DataTypeEnum.Number,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Custom],
};

export const dealTouches: IAnalyticsColumn = {
  name: Column.DealTouches,
  width: 110,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.dealTouches',
  dataType: DataTypeEnum.Number,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Custom],
};

export const attributedPipeline: IAnalyticsColumn = {
  name: Column.AttributedPipeline,
  width: 170,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.attributedPipeline.multi',
  dataType: DataTypeEnum.Currency,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Custom, ModelType.Even],
};

export const attributedRevenue: IAnalyticsColumn = {
  name: Column.AttributedRevenue,
  width: 170,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.attributedRevenue.multi',
  dataType: DataTypeEnum.Currency,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Custom, ModelType.Even],
};

export const influencedPipeline: IAnalyticsColumn = {
  name: Column.InfluencedPipeline,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.influencedPipeline',
  dataType: DataTypeEnum.Currency,
  visible: true,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Custom],
};

export const influencedRevenue: IAnalyticsColumn = {
  name: Column.InfluencedRevenue,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.influencedRevenue',
  dataType: DataTypeEnum.Currency,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Custom],
};

export const LTO: IAnalyticsColumn = {
  name: Column.LTO,
  width: 80,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.lto',
  dataType: DataTypeEnum.Percent,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last],
};

export const CPL: IAnalyticsColumn = {
  name: Column.CPL,
  width: 80,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpl',
  dataType: DataTypeEnum.Currency,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced]
};

export const CPO: IAnalyticsColumn = {
  name: Column.CPO,
  width: 80,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpo',
  dataType: DataTypeEnum.Currency,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced]
};

export const CPD: IAnalyticsColumn = {
  name: Column.CPD,
  width: 80,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.cpd',
  dataType: DataTypeEnum.Currency,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced],
};

export const cost: IAnalyticsColumn = {
  name: Column.Cost,
  width: 120,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.cost',
  dataType: DataTypeEnum.Currency,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const pipelineRoi: IAnalyticsColumn = {
  name: Column.PipelineRoi,
  width: 120,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.pipelineRoi',
  dataType: DataTypeEnum.Percent,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const ROI: IAnalyticsColumn = {
  name: Column.ROI,
  width: 100,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.roi',
  dataType: DataTypeEnum.Percent,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Even, ModelType.Sourced, ModelType.Even, ModelType.Custom]
};

export const OTD: IAnalyticsColumn = {
  name: Column.OTD,
  width: 80,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.otd',
  dataType: DataTypeEnum.Percent,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last],
};

export const totalTouches: IAnalyticsColumn = {
  name: Column.TotalTouches,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.totalTouches',
  dataType: DataTypeEnum.Number,
  visible: false,
  startSorting: 'desc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
};

export const mediumName: IAnalyticsColumn = {
  name: Column.MediumName,
  width: 130,
  displayName: 'measurementStudio.features.analytics.campaignAnalytics.columns.mediumName',
  dataType: DataTypeEnum.Text,
  visible: true,
  startSorting: 'asc',
  models: [ModelType.Sourced, ModelType.Last, ModelType.Even, ModelType.Custom],
  internalLink(row: IMediumsTableItem, filters: IAnalyticsRequest): IReportLink {
    return {
      link: getRouteLink(RouteItemEnum.WebActivitiesChannel),
      queryParams: {
        gf: row.mediumName,
        model: filters.model,
        dataSet: filters.dataSet,
        cohort: filters.cohort.cohort,
        startDate: filters.cohort.startDate,
        endDate: filters.cohort.endDate,
        influenceType: filters.influenceType,
      }
    };
  }
};
