import camelcaseKeys from 'camelcase-keys';

export function convertBodyToCamelcase(body: unknown): unknown {
  if (Array.isArray(body)) {
    return body.length ? body.map(convertToCamelcase) : [];
  }

  return body ? convertToCamelcase(body) : {};
}

function convertToCamelcase<T>(item: T): T {
  if (typeof item !== 'object') {
    return item;
  }
  return {
    ...camelcaseKeys(item, {deep: true, exclude: ['_permissions']})
  };
}
