<div class="container">
  <button *ngFor="let item of reportData"
          [ngClass]="{ active: selectedId === item.id }"
          (click)="emitSelect(item)"
          title="Show data for this period"
          type="button"
          class="report-tab-button">
    {{ item.id }}
  </button>
</div>
