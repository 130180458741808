import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';

import * as HubRouterReducer from './hub-router.reducer';
import { RouteItemEnum } from '@shared/enums';
import { IReportStateInfo } from '@shared/interfaces';

export const selectHubRouterState = createFeatureSelector<RouterReducerState<HubRouterReducer.HubRouterState>>('hubRouterState');
export const selectRouteParams = createSelector(
  selectHubRouterState,
  (routerState) => routerState && routerState.state ? routerState.state.params : {},
);
export const getRouterInfoForSavableReport = createSelector(
  selectHubRouterState,
  (routerState): IReportStateInfo => {
    return {
      url: routerState?.state?.url || '',
      stateName: routerState?.state?.routeId || '',
      stateParams: routerState?.state?.queryParams ? JSON.stringify(routerState.state.queryParams) : ''
    };
  },
);
// necessary for the new layout functionality
export const selectRouteSubtitle = createSelector(
  selectHubRouterState,
  (routerState) => routerState?.state?.subTitle,
);

export const selectRouteId = createSelector(
  selectHubRouterState,
  (routerState) => routerState?.state?.routeId,
);

// page with layout similar to Dashboards page
export const isNewLayout = createSelector(
  selectRouteId, (routeId: RouteItemEnum) => {
    const routesWithNewLayout = [
      RouteItemEnum.CampaignScoring,
      RouteItemEnum.AccountEngagement,
      RouteItemEnum.Dashboards,
      RouteItemEnum.HubspotConfigure,
      RouteItemEnum.Configuration,
      RouteItemEnum.Permissions,
      RouteItemEnum.DataHygiene,
      RouteItemEnum.PagesAndFeatures,
      RouteItemEnum.Stages,
    ];

    return routesWithNewLayout.includes(routeId);
  }
);

export const getRouteCurrentReport = createSelector(
  selectRouteId,
  (routeId: RouteItemEnum) => {
    const advertisingInsightsRoutes = [
      RouteItemEnum.AdvertisingInsights,
      RouteItemEnum.InsightsCampaignResponsesInfluenced,
      RouteItemEnum.InsightsLeadsInfluenced,
      RouteItemEnum.InsightsNewPipelineInfluenced,
      RouteItemEnum.InsightsPageViewsInfluenced,
      RouteItemEnum.InsightsPipelineInfluenced,
      RouteItemEnum.InsightsRevenueInfluenced,
    ];

    const routeToReportType = {
      [RouteItemEnum.AdvertisingInsights]: RouteItemEnum.AdvertisingInsights,
      [RouteItemEnum.InsightsCampaignResponsesInfluenced]: 'CampaignResponses',
      [RouteItemEnum.InsightsLeadsInfluenced]: 'Lead',
      [RouteItemEnum.InsightsNewPipelineInfluenced]: 'NewPipeline',
      [RouteItemEnum.InsightsPageViewsInfluenced]: 'PageView',
      [RouteItemEnum.InsightsPipelineInfluenced]: 'Pipeline',
      [RouteItemEnum.InsightsRevenueInfluenced]: 'Revenue',
    };
    return advertisingInsightsRoutes.includes(routeId) ? routeToReportType[routeId] : null;
  }
);
