import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { listPopupActions } from './list-popup.actions';
import * as accountHubSelectors from './list-popup.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { NotificationTypes, ReloadDataType } from '@shared/enums';
import { UserService } from '@user/*';
import {
  IAccountFolder,
  IAccountsFolderUser,
  userMessageFactory,
  IAddAccountsListResponse
} from '@shared/interfaces';
import { CreateFolderDialogComponent } from '../components/create-folder-dialog/create-folder-dialog.component';
import { AccountHubSharedService as SharedAccounHubService, loadAccountLists } from '@shared/data-access/account-hub';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { AccountListState } from './list-popup.reducer';
import {getAppliedFilters, getAppliedGlobalFiltersAsParams} from '@shared/data-access/global-filters';

/* istanbul ignore file */

@Injectable()
export class ListPopupEffects {

  constructor(
    private sharedAccountHubService: SharedAccounHubService,
    private actions$: Actions,
    private store: Store<AccountListState>,
    private translate: TranslateService,
    private userService: UserService,
    private downloadCsvService: DownloadCsvService,
    private dialog: MatDialog,
  ) {
  }

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      listPopupActions.loadFoldersFailure,
      listPopupActions.createListFailure,
    ),
    map(action =>
      notificationMessagesActions.addMessage({message: userMessageFactory({n: action.payload})}))
  ));

  public onLoadAccountsListsFolders$ = createEffect(() => this.actions$.pipe(
    ofType(
      listPopupActions.loadFolders,
      listPopupActions.createFolderSuccess,
      listPopupActions.createListSuccess
    ),
    switchMap(() => this.sharedAccountHubService.getAccountListsFolders$().pipe(
      map((responses: IAccountFolder[]) => listPopupActions.loadFoldersSuccess({payload: responses})),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'features.accountHub.errors.getAccountsFolders';
        return of(listPopupActions.loadFoldersFailure({payload: message}));
      })
    )),
  ));

  public onAddToList$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.addToList),
    withLatestFrom(
      this.store.pipe(select(getAppliedGlobalFiltersAsParams))
    ),
    switchMap(([action, globalFilters]) => {
      return this.sharedAccountHubService.addToList$({
        searchField: action.payload['searchField'],
        model: action.payload['model'],
        closedType: action.payload['closedType'],
        dateType: action.payload['dateType'],
        type: action.payload['type'],
        wonType: action.payload['wonType'],
        cohort: action.payload['cohort'],
        listId: action.payload['id'],
        endDate: action.payload['endDate'],
        startDate: action.payload['startDate'],
        ...globalFilters
      }).pipe(
        mergeMap((response: IAddAccountsListResponse) => {
          return [
            listPopupActions.addNotification({
              payload: {
                message: 'shared.accountHub.notification.addAccountsToListSuccess',
                params: {
                  count: response.newMembers,
                  name: '' + action.payload['name'],
                }
              }
            }), listPopupActions.addToListSuccess()]
        }),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'shared.accountHub.errors.addAccountsToList';
          return of(listPopupActions.addToListFailure({payload: message}));
        })
      )
    }),
  ));


  public onLoadFolderUsers$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.loadFolderUsers),
    concatLatestFrom(() => this.store.pipe(select(accountHubSelectors.getAccountsFolders))),
    switchMap(([action, folders]) => action.payload
      ? this.sharedAccountHubService.getFolderUsers$().pipe(
        map((responses: IAccountsFolderUser[]) => listPopupActions.loadFolderUsersSuccess({
          payload: {
            users: responses,
            folder: action.payload,
            foldersNames: folders?.map(folder => folder.folderName) || []
          }
        })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'features.accountHub.errors.getFolderUsers';
          return of(listPopupActions.loadFolderUsersFailure({
            payload: message
          }));
        })
      )
      : of(listPopupActions.loadFolderUsersSuccess({
        payload: {
          users: [],
          folder: action.payload,
          foldersNames: folders?.map(folder => folder.folderName) || []
        }
      }))
    )
  ));

  public onLoadFolderUsersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.loadFolderUsersSuccess),
    mergeMap(action => {
      const dialogConfig: MatDialogConfig = {
        width: '432px',
        data: action.payload,
      };
      return this.dialog.open(CreateFolderDialogComponent, dialogConfig)
        .afterClosed().pipe(
          filter(folder => !!folder),
          map((folder) => folder.folder_id
            ? listPopupActions.updateFolder({
              payload: {
                ...folder,
                owner_id: folder.owners[0].userId,
              }
            })
            : listPopupActions.createFolder({payload: folder})
          )
        );
    })
  ));

  public onCreateFolder$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.createFolder),
    switchMap(action => this.sharedAccountHubService.createFolder$(action.payload).pipe(
      mergeMap(() => ([
        listPopupActions.createFolderSuccess(),
        listPopupActions.addNotification({
          payload: {
            message: 'shared.accountHub.notification.createFolderSuccess',
            params: {
              name: action.payload.folder_name
            }
          }
        }),
      ])),
      catchError(() => {
        const message = 'features.accountHub.errors.createFolder';
        return of(listPopupActions.createFolderFailure({payload: message}));
      })
    )),
  ));

  public onUpdateFolder$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.updateFolder),
    switchMap(action => this.sharedAccountHubService.updateFolder$(action.payload).pipe(
      mergeMap(() => ([
        listPopupActions.updateFolderSuccess(),
        listPopupActions.addNotification({
          payload: {
            message: 'shared.accountHub.notification.updateFolderSuccess',
            params: {
              name: action.payload.folder_name
            }
          }
        }),
      ])),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.accountHub.errors.updateFolder';
        return of(listPopupActions.updateFolderFailure({payload: message}));
      })
    )),
  ));


  public onCreateList$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.createList),
    switchMap(action => this.sharedAccountHubService.createList$(action.payload).pipe(
      mergeMap(list => {
        const resultActions = [];
        if (action.payload.account_ids?.length) {
          resultActions.push(listPopupActions.addToList({
            payload: {
              id: list.id,
            }
          }))
        }
        if (action.payload.filters) {
          resultActions.push(
            listPopupActions.addToList({
              payload: {
                ...action.payload.filters,
                name: list.name,
                id: list.id,
              }
            })
          )
        }
        return [
          ...resultActions,
          listPopupActions.createListSuccess({payload: action.payload.account_ids?.length ?
              ReloadDataType.None : ReloadDataType.Folders
          }),
          listPopupActions.addNotification({
            payload: {
              message: 'shared.accountHub.notification.createListSuccess',
              params: {
                name: list.name,
              }
            }
          }),
          loadAccountLists()
        ]
      }),
      catchError((error: HttpErrorResponse) => {
        const message = error.message || 'shared.accountHub.errors.createList';
        return of(listPopupActions.createListFailure({payload: message}));
      }),
    )),
  ));

  public onAddNotification$ = createEffect(() => this.actions$.pipe(
    ofType(listPopupActions.addNotification),
    mergeMap(action => this.translate.get(action.payload.message, action.payload.params)),
    map(message => notificationMessagesActions.addMessage({
      message: {
        message,
        type: NotificationTypes.Success,
      }
    }))
  ));
}
