import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';

import { StageInfluenceComponent } from './containers/stage-influence.component';
import { StageInfluenceRoutingModule } from './stage-influence-routing.module';
import { StageInfluenceFiltersComponent } from './components/stage-influence-filters/stage-influence-filters.component';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { StageInfluenceEffects } from './state/stage-influence.effects';
import * as reducer from './state/stage-influence.reducer';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { ReportTabsModule } from '@ui/components/report-tabs';

@NgModule({
  declarations: [
    StageInfluenceComponent,
    StageInfluenceFiltersComponent,
  ],
  imports: [
    CommonModule,
    StageInfluenceRoutingModule,
    StoreModule.forFeature(reducer.STAGE_INFLUENCE_KEY, reducer.reducer),
    EffectsModule.forFeature([StageInfluenceEffects]),
    DateCohortsModule,
    ReportSettingPanelModule,
    TsLoadingOverlayModule,
    ReportFilterSelectorModule,
    ReportTabsModule,
  ]
})
export class StageInfluenceModule {
}
