import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { TranslateService } from '@ngx-translate/core';

import { KpiSource } from '../../sources/trending-kpi/kpi.source';
import { IKpiResponse } from '../../kpi-trending/interfaces/kpi-response.interface';
import {
  GOAL_FIELD,
  hubTokenName,
  TILE_DATA_FIELD_NAME,
  TILE_DATA_FIELD_PARETO,
  TILE_DATA_FIELD_PARETO_COLUMN_NAME,
  VISUALIZATION_WITH_GOAL_FIELD
} from '@shared/constants';
import { IKpiDetailsResponse } from '../../trending-details/interfaces/kpi-details-response.interface';
import { EnvService } from '@shared/environment';
import { compare, getStartAndEndDateFromParams, getVisualizationType, replaceLegacyCohort, toCapitalize } from '@util/helpers';
import {
  isValidGraphSeriesType,
  isValidKpiFirmographic,
  isValidKpiGroup,
  isValidKpiInterval,
  isValidKpiProgression,
  isValidKpiTrend,
  isValidKpiType
} from '../../helpers/scorecard.helper';
import {
  KpiAudience,
  KpiFirmographic,
  KpiGroup,
  KpiInterval,
  KpiProgression,
  KpiTrend,
  KpiTrendLabels,
  KpiType,
  SeriesTypes
} from '../../enums/scorecard.enum';
import {
  ITrendingDetailsCSVRequestParams,
  ITrendingDetailsFilters,
  ITrendingDetailsRequestParams
} from '../../trending-details/interfaces/trending-details-filters.interface';
import { IKpiTrendingFilters, ScorecardTrendingTileSettings } from '../../kpi-trending/interfaces/kpi-trending-filters.interface';
import { DataTypeEnum, GaugeChartFields, LastFull, TileTypes, VisualizationTypes } from '@shared/enums';
import {
  AggregateList,
  IDashboardTile,
  IGetTileData,
  ILabelValue,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig,
  TileSettingsControlTypes
} from '@shared/interfaces';
import { IKpiTrendInterval } from '../../kpi-trending/interfaces/kpi-trend-interval.interface';
import { formatIntervalDate, isInvalidProgressionTypeCombination } from '../../kpi-trending/kpi-trending.utils';
import { IKpiSegment } from '../../kpi-trending/interfaces/kpi-segment.interface';
import * as kpiConstants from '../../kpi-trending/kpi-trending.constants';
import * as selectors from '@shared/data-access/aggregate-lists';
import { AggregateListsState } from '@shared/data-access/aggregate-lists';

@Injectable({providedIn: 'root'})
export class KpiService implements IGetTileData<ITileSettings> {

  constructor(
    public source: KpiSource,
    public store: Store<AggregateListsState>,
    public retry: RetryWithEscalation,
    private translateService: TranslateService,
    envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getKpiData$(params: IKpiTrendingFilters): Observable<IKpiResponse> {
    return regenerateOnRetry(() => this.source.getKpiData$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTrendingDetails$(params: ITrendingDetailsRequestParams): Observable<IKpiDetailsResponse> {
    return regenerateOnRetry(() => this.source.getKpiDetails$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  downloadTrendingDetails$(params: ITrendingDetailsCSVRequestParams): Observable<string> {
    return regenerateOnRetry(() => this.source.downloadKpiDetails$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getTileData$(params: ITileSettings): Observable<ITileData | ITileGaugeData> {
    return this.store.pipe(
      select(selectors.getAggregateLists),
      take(1),
      map((aggregateData: AggregateList[]) =>
        aggregateData?.map((aggregate: AggregateList) => aggregate.aggregateId) || []),
      // get adapted request params
      map((ids: number[]) => this.getRequestParamsFromSetting(params as unknown as ScorecardTrendingTileSettings, ids)),
      mergeMap((requestParams) => this.source.getKpiData$(requestParams).pipe(
        withLatestFrom(this.getAggregateLegendText(requestParams)),
        map(([data, text]: [IKpiResponse, string]) => this.tileAdapter(
          data,
          {...params, ...requestParams} as unknown as ScorecardTrendingTileSettings,
          text)
        )
      ))
    );
  }

  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    // get visualization type based on settings or get default type
    const visualization = getVisualizationType(TileTypes.ScorecardTrending, tile.settings);
    const kpiType = tile.settings.kpi || tile.settings.type; // support legacy
    return this.translateService.get(kpiConstants.kpiTypeHumanReadableMap[kpiType as KpiType]).pipe(
      map((yAxisLabel: string) => this.assembleCustomSettings(visualization, yAxisLabel, kpiType as KpiType))
    );
  }

  getKpiTrendingFilters(params: Params): IKpiTrendingFilters {
    const cohort = {
      cohort: replaceLegacyCohort(params.cohort) || LastFull.LAST_FULL_QUARTER,
      name: params.name,
      ...getStartAndEndDateFromParams(params.cohort, params.startDate, params.endDate)
    };
    const scorecardAggregateIds = params?.scorecardAggregateIds
    && typeof params.scorecardAggregateIds === 'string'
    && params.scorecardAggregateIds?.length
      ? params.scorecardAggregateIds.split(',').map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
      : params?.scorecardAggregateIds && Array.isArray(params.scorecardAggregateIds)
        ? params.scorecardAggregateIds.map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
        : [];
    const scorecardSegmentIds = params?.scorecardSegmentIds
    && typeof params.scorecardSegmentIds === 'string'
    && params.scorecardSegmentIds?.length
      ? params.scorecardSegmentIds.split(',').map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
      : params?.scorecardSegmentIds && Array.isArray(params.scorecardSegmentIds)
        ? params.scorecardSegmentIds.map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
        : [];
    const group = params.group && isValidKpiGroup(params.group)
      ? params.group : KpiGroup.ScorecardAggregate;
    return {
      cohort,
      scorecardAggregateIds,
      scorecardSegmentIds,
      progression: params.progression && isValidKpiProgression(params.progression)
        ? params.progression : KpiProgression.List,
      type: params.type && isValidKpiType(params.type)
        ? params.type : KpiType.Pipeline,
      audience: params.audience ? params.audience : KpiAudience.Account,
      trend: params.trend && isValidKpiTrend(params.trend)
        ? params.trend : KpiTrend.Cumulative,
      group: group,
      rollingTime: params.rollingTime && !Number.isNaN(params.rollingTime)
        ? parseInt(params.rollingTime, 10) : 90,
      interval: params.interval && isValidKpiInterval(params.interval)
        ? params.interval : KpiInterval.Months,
      firmographic: params.firmographic
      && isValidKpiFirmographic(params.firmographic)
      && group === KpiGroup.Firmographics
        ? params.firmographic : KpiFirmographic.None,
      visualization: params.visualization && isValidGraphSeriesType(params.visualization)
        ? params.visualization
        : SeriesTypes.Line
    };
  }

  getTrendingDetailsFilters(params: Params): ITrendingDetailsFilters {
    return {
      ...this.getKpiTrendingFilters(params),
      segment: {
        descriptor: params.descriptor,
        amount: 0,
        sortKey: 0
      },
      trendInterval: {
        descriptor: params.trendInterval,
        segments: [],
        total: 0
      }
    };
  }

  getTileDefaultSettings(params: ITileSettings = {}): ITileSettings {
    const cohortObj = params.cohort && typeof params.cohort === 'object' ? {...params.cohort} : {};
    const defaultParams = this.getKpiTrendingFilters({
      ...params,
      ...cohortObj
    });

    const ids = params[kpiConstants.KpiTrendingTileFields.ScorecardAggregateIds] || 
    params[kpiConstants.KpiTrendingTileFields.scorecardSegmentIds]
      || params.accountLists || params.accountListFilter || [];

    return {
      ...params,
      [GaugeChartFields.Minimum]: params[GaugeChartFields.Minimum] || null,
      [GaugeChartFields.Midpoint]: params[GaugeChartFields.Midpoint] || null,
      [GaugeChartFields.Goal]: params[GaugeChartFields.Goal] || null,
      [GaugeChartFields.Maximum]: params[GaugeChartFields.Maximum] || null,
      [GOAL_FIELD]: params[GOAL_FIELD] || null, // use for some visualization like pareto chart
      [kpiConstants.KpiTrendingTileFields.ScorecardAggregateIds]: Array.isArray(ids) ? ids : [],
      [kpiConstants.KpiTrendingTileFields.scorecardSegmentIds]: Array.isArray(ids) ? ids : [],
      [kpiConstants.KpiTrendingTileFields.Type]: params[kpiConstants.KpiTrendingTileFields.Type] || defaultParams.type,
      [kpiConstants.KpiTrendingTileFields.Progression]: params[kpiConstants.KpiTrendingTileFields.Progression] || defaultParams.progression,
      [kpiConstants.KpiTrendingTileFields.Interval]: params[kpiConstants.KpiTrendingTileFields.Interval] || KpiInterval.Months,
      [kpiConstants.KpiTrendingTileFields.Trend]: params[kpiConstants.KpiTrendingTileFields.Trend] || defaultParams.trend,
      [kpiConstants.KpiTrendingTileFields.Group]: params[kpiConstants.KpiTrendingTileFields.Group] || defaultParams.group,
      rollingTime: defaultParams.group === KpiGroup.Impressions ? defaultParams.rollingTime : 90,
      [kpiConstants.KpiTrendingTileFields.Firmographic]:
      params[kpiConstants.KpiTrendingTileFields.Firmographic] || defaultParams.firmographic,
      ...defaultParams.cohort // remove nested cohort
    };
  }

  getTileSettingsFilters$(): Observable<ITileSettingControl[]> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return this.store.pipe(
      select(selectors.getAggregateLists),
      take(1),
      map((aggregateLists: AggregateList[]) => [
        {
          key: kpiConstants.KpiTrendingTileFields.Type,
          label: 'measurementStudio.features.scorecard.filters.kpiTypes',
          type: TileSettingsControlTypes.Selector,
          options: kpiConstants.KPI_TYPE_OPTIONS
        },
        {
          key: kpiConstants.KpiTrendingTileFields.Progression,
          label: 'measurementStudio.features.scorecard.filters.kpiProgression',
          type: TileSettingsControlTypes.Selector,
          options: null,
          getCustomOptions(settings: ITileSettings) {
            return kpiConstants.KPI_PROGRESSION_OPTIONS.filter((progression: ILabelValue<KpiProgression>) =>
              !isInvalidProgressionTypeCombination(
                progression.value,
                <KpiType>settings[kpiConstants.KpiTrendingTileFields.Type]
              )
            );
          }
        },
        {
          key: kpiConstants.KpiTrendingTileFields.ScorecardAggregateIds,
          label: 'measurementStudio.features.scorecard.filters.accountList',
          noneLabel: 'shared.ui.reportCheckboxSelector.all',
          type: TileSettingsControlTypes.Checkbox,
          options: null,
          getCustomOptions(settings: ITileSettings) {
            return aggregateLists?.map((list: AggregateList) => ({
              label: list.aggregateName,
              value: list.aggregateId,
              checked: (settings[kpiConstants.KpiTrendingTileFields.ScorecardAggregateIds] as string).includes(String(list.aggregateId)) ?? false
            })) || [];
          }
        },
        {
          key: kpiConstants.KpiTrendingTileFields.scorecardSegmentIds,
          label: 'measurementStudio.features.scorecard.filters.segments',
          noneLabel: 'shared.ui.reportCheckboxSelector.all',
          type: TileSettingsControlTypes.Checkbox,
          options: null,
          getCustomOptions(settings: ITileSettings) {
            return aggregateLists?.map((list: AggregateList) => ({
              label: list.aggregateName,
              value: list.aggregateId,
              checked: (settings[kpiConstants.KpiTrendingTileFields.scorecardSegmentIds] as string)?.includes(String(list.aggregateId)) ?? false
            })) || [];
          }
        },
        {
          key: kpiConstants.KpiTrendingTileFields.Group,
          label: 'measurementStudio.features.scorecard.filters.groupedBy',
          type: TileSettingsControlTypes.Selector,
          options: kpiConstants.KPI_GROUP_OPTIONS
        },
        {
          key: kpiConstants.KpiTrendingTileFields.Firmographic,
          label: 'measurementStudio.features.scorecard.filters.firmographic',
          type: TileSettingsControlTypes.Selector,
          options: kpiConstants.FIRMOGRAPHIC_OPTIONS,
          show(settings: ITileSettings) {
            return settings[kpiConstants.KpiTrendingTileFields.Group] === KpiGroup.Firmographics;
          }
        },
        {
          key: kpiConstants.KpiTrendingTileFields.Interval,
          label: 'measurementStudio.features.scorecard.filters.interval',
          type: TileSettingsControlTypes.Selector,
          options: kpiConstants.KPI_INTERVAL_OPTIONS
        },
        {
          key: kpiConstants.KpiTrendingTileFields.Trend,
          label: 'measurementStudio.features.scorecard.filters.trend',
          type: TileSettingsControlTypes.Selector,
          options: null,
          getCustomOptions(settings: ITileSettings) {
            // get options which are available for current type and progression only
            const validOptions = that.getValidKpiTrendOptions(settings);
            // update option list
            return kpiConstants.KPI_TREND_OPTIONS.filter(option => validOptions.includes(option.label as KpiTrendLabels));
          }
        }
      ])
    );
  }

  private getValidKpiTrendOptions(settings: ITileSettings): KpiTrendLabels[] {
    const progression = settings[kpiConstants.KpiTrendingTileFields.Progression];
    const type = settings[kpiConstants.KpiTrendingTileFields.Type];

    let options: KpiTrendLabels[] = [];
    if (type === KpiType.Account) {
      if ([KpiProgression.List, KpiProgression.Engaged].includes(progression as KpiProgression)) {
        options = [KpiTrendLabels.NetChange, KpiTrendLabels.Snapshot];
      }
      if ([KpiProgression.Opportunity, KpiProgression.Won].includes(progression as KpiProgression)) {
        options = [KpiTrendLabels.NetNew];
      }
    } else {
      if ([KpiProgression.List, KpiProgression.Engaged].includes(progression as KpiProgression)) {
        options = [KpiTrendLabels.NetChange];
      }
      if ([KpiProgression.Opportunity, KpiProgression.Won].includes(progression as KpiProgression)) {
        options = [KpiTrendLabels.NetNew];
      }
    }
    return [
      ...options,
      KpiTrendLabels.Cumulative
    ];
  }

  private getRequestParamsFromSetting(settings: ScorecardTrendingTileSettings, aggregateIds: number[]): IKpiTrendingFilters {
    const ids = settings.scorecardAggregateIds || settings.scorecardSegmentIds || settings.accountLists || settings.accountListFilter;
    const checkedId = ids && Array.isArray(ids) && ids.length
      ? ids.map(id => Number(id))
      : aggregateIds;
    // NOTE: we have to send cohort as object to the BE, need to check if cohort is string then make it as object
    const cohortObj = typeof settings.cohort === 'string'
      ? {
        cohort: settings.cohort,
        startDate: settings.startDate,
        endDate: settings.endDate,
        name: null
      }
      : settings.cohort;
    const group = settings.group || settings.groupBy;

    return {
      cohort: cohortObj,
      scorecardAggregateIds: checkedId,
      scorecardSegmentIds: checkedId,
      progression: settings.progression,
      type: settings.type || settings.kpi,
      trend: settings.trend || settings.trendingType,
      group,
      interval: settings.interval,
      rollingTime: settings.rollingTime ? Number(settings.rollingTime) : 90,
      firmographic: settings.firmographic && group === KpiGroup.Firmographics
        ? settings.firmographic
        : KpiFirmographic.None
    };
  }

  private getAggregateLegendText(params: IKpiTrendingFilters): Observable<string> {
    const text = kpiConstants.kpiTypeHumanReadableMap[params.type];
    return text ? this.translateService.get(text) : of('n/a');
  }

  private tileAdapter(data: IKpiResponse,
                      params: ScorecardTrendingTileSettings,
                      text: string
  ): ITileData | ITileGaugeData {
    if (!data) {
      return {
        items: []
      };
    }
    // get visualization type based on settings or get default type
    const visualization = getVisualizationType(TileTypes.ScorecardTrending, params);
    const type = params.type || params.kpi;
    const kpiFormat = kpiConstants.kpiTypeTableFormatMap[type];
    const goalValue = params[GOAL_FIELD] && !isNaN(Number(params[GOAL_FIELD]))
      ? Number(params[GOAL_FIELD])
      : params.trendingGoal && !isNaN(Number(params.trendingGoal))
        ? Number(params.trendingGoal) : null; // legacy code
    // add goal section only for those charts which are in the list above
    const metricGoal = goalValue && VISUALIZATION_WITH_GOAL_FIELD.includes(visualization)
      ? {
        [GOAL_FIELD]: {
          value: Number(goalValue),
          dataType: kpiFormat?.dataType || DataTypeEnum.Number
        }
      }
      : {};
    const metric = {
      metric: {
        current: {
          value: data.totalsInterval.total,
          dataType: kpiFormat?.dataType || DataTypeEnum.Number
        },
        ...metricGoal
      }
    };
    const items = this.getTileDataItems(data, params, text, visualization);

    return {
      ...items,
      ...metric
    };
  }

  private getTileDataItems(
    data: IKpiResponse,
    params: ScorecardTrendingTileSettings,
    text: string,
    visualization: VisualizationTypes
  ): ITileData | ITileGaugeData {
    switch (visualization) {
      case VisualizationTypes.Pareto:
        return this.getParetoData(data, params);

      case VisualizationTypes.StackedArea:
      case VisualizationTypes.StackedColumn:
      case VisualizationTypes.Column:
      case VisualizationTypes.Line:
        return this.getXYData(data, params, text);

      case VisualizationTypes.Donut:
        return this.getPieData(data);

      case VisualizationTypes.Gauge:
        return this.getGaugeData(data, params);

      default:
        return {items: []};

    }
  }

  private getParetoData(data: IKpiResponse, params: ScorecardTrendingTileSettings): ITileData {
    let accumulator = 0;
    const {dateIntervals} = data;
    return {
      items: dateIntervals.slice()
        .sort((a: IKpiTrendInterval, b: IKpiTrendInterval) =>
          (a.descriptor > b.descriptor) ? 1 : ((b.descriptor > a.descriptor) ? -1 : 0))
        .map((interval: IKpiTrendInterval) => {
          const category = formatIntervalDate(interval.descriptor, params.interval);
          accumulator += interval.total;
          return {
            [TILE_DATA_FIELD_NAME]: category,
            [TILE_DATA_FIELD_PARETO_COLUMN_NAME]: interval.total,
            [TILE_DATA_FIELD_PARETO]: accumulator
          };
        })
    };
  }

  private getGaugeData(data: IKpiResponse, params: ScorecardTrendingTileSettings): ITileGaugeData {
    const gaugeSettings = {
      [GaugeChartFields.Minimum]: !isNaN(+params[GaugeChartFields.Minimum])
        ? +(params[GaugeChartFields.Minimum]) : null,
      [GaugeChartFields.Midpoint]: !isNaN(+params[GaugeChartFields.Midpoint])
        ? +(params[GaugeChartFields.Midpoint]) : null,
      [GaugeChartFields.Goal]: !isNaN(+params[GaugeChartFields.Goal])
        ? +(params[GaugeChartFields.Goal]) : null,
      [GaugeChartFields.Maximum]: !isNaN(+params[GaugeChartFields.Maximum])
        ? +(params[GaugeChartFields.Maximum]) : null
    };

    if (gaugeSettings[GaugeChartFields.Minimum] === null
      || gaugeSettings[GaugeChartFields.Midpoint] === null
      || gaugeSettings[GaugeChartFields.Goal] === null
      || gaugeSettings[GaugeChartFields.Maximum] === null) {
      return {items: []};
    }

    return {
      items: [{
        ...gaugeSettings,
        value: data.totalsInterval.total
      }]
    };
  }

  private getPieData(data: IKpiResponse): ITileData {
    return {
      items: data.totalsInterval.segments
        .map((segment: IKpiSegment) => ({
          [TILE_DATA_FIELD_NAME]: segment.descriptor,
          value: segment.amount
        }))
        // sort by desc to push the smallest values to the end
        // in this way they won't be shown in the legend and they also will be hidden if
        // they are lees than 5%
        .sort((a, b) => compare(a.value, b.value, false))
    };
  }

  private getXYData(data: IKpiResponse,
                    params: ScorecardTrendingTileSettings,
                    text: string
  ): ITileData {
    return {
      items: data.dateIntervals
        .slice()
        .sort((a: IKpiTrendInterval, b: IKpiTrendInterval) => compare(a.descriptor, b.descriptor, true))
        .map((interval: IKpiTrendInterval) => {
          const category = formatIntervalDate(interval.descriptor, params.interval, true);
          if (params.group === KpiGroup.None) {
            return {
              [TILE_DATA_FIELD_NAME]: category,
              [text]: interval.total
            };
          }

          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const segments = interval.segments.reduce((acc: any, segment: IKpiSegment) => {
            acc[segment.descriptor || 'n/a'] = segment.amount;
            return acc;
          }, {});

          return {
            ...segments,
            [TILE_DATA_FIELD_NAME]: category
          };
        })
    };
  }

  private assembleCustomSettings(visualization: VisualizationTypes,
                                 label: string,
                                 kpiType: KpiType): ITileVisualizationConfig {
    if (visualization === VisualizationTypes.Donut) {
      return {
        metricLabel: label,
        chart: {
          numberFormatter: {
            numberFormat: kpiConstants.kpiTypeValueAxisFormat[kpiType]
          }
        }
      };
    }
    if (visualization === VisualizationTypes.Gauge) {
      return {
        metricLabel: label
      };
    }
    // set cellLocation as we have for ClusteredColumn only for Scorecard Column chart
    const cellLocation = visualization === VisualizationTypes.Column ? {
      cellStartLocation: 0.2,
      cellEndLocation: 0.8
    } : {};

    return {
      metricLabel: label,
      chart: {
        numberFormatter: {
          numberFormat: kpiConstants.kpiTypeValueAxisFormat[kpiType]
        },
        xAxes: [{
          type: 'CategoryAxis',
          dataFields: {
            category: TILE_DATA_FIELD_NAME
          },
          renderer: {
            ...cellLocation,
            grid: {
              disabled: true
            },
            minGridDistance: 200 // to show first and last label
          }
        }],
        yAxes: [{
          type: 'ValueAxis',
          min: 0,
          title: {
            text: toCapitalize(label),
            fill: '#637178'
          },
          numberFormatter: {
            type: 'NumberFormatter',
            forceCreate: true,
            numberFormat: kpiConstants.kpiTypeValueTileAxisFormat[kpiType]
          }
        }]
      }
    };
  }
}
