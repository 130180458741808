export const GAINSIGHT_EVENT_APPLY_BUTTON = 'ad insights apply button';
export const GAINSIGHT_EVENT_CSV_DOWNLOAD = 'ad insights csv download';
export const GAINSIGHT_EVENT_TABLE_SEARCH = 'ad insights table search';

export const GAINSIGHT_EVENT_LOCATION_PROPERTY_FIELD_MAP = {
  child: 'child',
  insights: 'insights',
  parent: 'parent'
};

export const GAINSIGHT_EVENT_PAGE_PROPERTY_FIELD_MAP = {
  details: 'details',
  index: 'index'
};

export const GAINSIGHT_EVENT_TAB_PROPERTY_FIELD_MAP = {
  account: 'account',
  drawer: 'drawer',
  report: 'report',
  tactic: 'tactic'
};

export type GainsightAdsInsightsTrackField =
  typeof GAINSIGHT_EVENT_APPLY_BUTTON |
  typeof GAINSIGHT_EVENT_CSV_DOWNLOAD |
  typeof GAINSIGHT_EVENT_TABLE_SEARCH
  ;
