import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ILabelValue } from '@shared/interfaces';
import { GlobalSearchTypeEnum } from '../../enums';
import { untilComponentDestroyed, WithDestroy } from '@terminus-lib/fe-utilities';

@WithDestroy
@Component({
  selector: 'tsh-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit, OnChanges {
  @Input() viewData: GlobalSearchTypeEnum[];
  @Input() options = [];
  @Output() public changed = new EventEmitter<ILabelValue<GlobalSearchTypeEnum>[]>();

  public field = new FormControl();
  public

  ngOnInit(): void {
    this.field.valueChanges.pipe(
      untilComponentDestroyed(this),
    ).subscribe(value => this.changed.emit(value))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue) {
      this.field.setValue(changes.options.currentValue[0].value);
      this.changed.emit(changes.options.currentValue[0].value);
    }
  }
}
