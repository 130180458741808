import { IFactoryParams } from '../factory-params.interface';
import { RouteItemEnum, TileTypes } from '@shared/enums';
import { IDashboardTile } from './dashboard-tile.interface';
import { ITileData, ITileGaugeData, ITileVisualizationConfig } from './tile-data.interface';

// Use this interface for
export type IDefaultTileConfiguration = Pick<IDashboardTile, 'type' | 'route' | 'name' | 'settings'>;

export interface ITileConfig extends IDefaultTileConfiguration {
  dashboardId?: string;
  id?: string;
  step: TileConfigurationSteps;
  // this property valid only for editing
  tileData?: ITileData | ITileGaugeData | null;
  // this property valid only for editing
  visualizationConfig?: ITileVisualizationConfig | null;
}

export enum TileConfigurationSteps {
  Type,
  Dashboard,
  Settings
}

export function tileConfigFactory({
   overrides = {}
}: IFactoryParams<ITileConfig> = {}): ITileConfig {
  return {
    step: TileConfigurationSteps.Type,
    dashboardId: 'test',
    id: '0038000001a0GwXAAU',
    name: 'Campaign',
    route: RouteItemEnum.OpportunityInsights,
    type: TileTypes.CampaignTrends,
    settings: {},
    ...overrides
  };
}
