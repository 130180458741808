import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { getHttpParams } from '@util/http';
import { ICampaign, ICampaignByIdResponse, IChannel, IChannelByIdResponse } from '@shared/interfaces';

export enum CampaignsSourceURLs {
  GetCampaignsByName = '/api/v1/campaign_names_like',
  GetCampaignsByIds = '/v1/campaigns_by_ids',
  GetCampaignTypes = '/v1/get_campaign_types',
  GetChannelsByName = '/api/v1/channel_assets_like',
  GetChannelsByIds = '/v1/channels_by_ids',
}

@Injectable({providedIn: 'root'})
export class CampaignsSource extends HttpClientBase {
  getCampaigns$(likeName: string): Observable<ICampaign[]> {
    const endpoint = this.endpoint(CampaignsSourceURLs.GetCampaignsByName);

    return this.httpClient.get<ICampaign[]>(endpoint, {params: getHttpParams({likeName})});
  }

  getCampaignsByIds$(body: { campaignIds: string[]; }): Observable<ICampaignByIdResponse> {
    const endpoint = this.endpoint(CampaignsSourceURLs.GetCampaignsByIds);

    return this.httpClient.post<ICampaignByIdResponse>(endpoint, body);
  }

  getCampaignTypes$(): Observable<string[]> {
    const endpoint = this.endpoint(CampaignsSourceURLs.GetCampaignTypes);

    return this.httpClient.get<string[]>(endpoint);
  }

  getChannels$(likeName: string): Observable<IChannel[]> {
    const endpoint = this.endpoint(CampaignsSourceURLs.GetChannelsByName);

    return this.httpClient.get<IChannel[]>(endpoint, {params: getHttpParams({likeName})});
  }

  getChannelsByIds$(body: { channelIds: string[]; }): Observable<IChannelByIdResponse> {
    const endpoint = this.endpoint(CampaignsSourceURLs.GetChannelsByIds);

    return this.httpClient.post<IChannelByIdResponse>(endpoint, body);
  }
}
