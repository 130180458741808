<tsh-table-panel [showDownload]="false" [totalTitle]="'shared.dashboards.dialog.totalTitleFilterPlural'"
  [totalCount]="appliedFiltersNumber" [showColumns]="false">
</tsh-table-panel>
<div class="table-container">
  <table ts-table #table="tsTable" [columns]="columns" [multiTemplateDataRows]="true" [dataSource]="dataSource">
    <ng-container tsColumnDef="icon" alignment="center">
      <th ts-header-cell *tsHeaderCellDef></th>
      <td ts-cell *tsCellDef="let item">
        <i class="fas pointer" [ngClass]="{
               'fa-angle-right': !rowState[item.name],
               'fa-angle-down': rowState[item.name]
           }" (click)="toggleExpand($event, item)">
        </i>
      </td>
    </ng-container>
    <ng-container tsColumnDef="name" alignment="left">
      <th ts-header-cell *tsHeaderCellDef>
        <tsh-header-cell [column]="columns[1]"></tsh-header-cell>
      </th>
      <td ts-cell *tsCellDef="let item" [title]="item.filterName">
        {{ item.filterName }}
      </td>
    </ng-container>
    <ng-container tsColumnDef="value" alignment="left">
      <th ts-header-cell *tsHeaderCellDef>
        <tsh-header-cell [column]="columns[2]"></tsh-header-cell>
      </th>
      <td ts-cell *tsCellDef="let item" [title]="item.sectionKey !== 'segments' ? item.value : getSegmentSummaryDisplayName(item.value)">
        <ng-container *ngIf="item.sectionKey !== 'segments'; else segmentValue">
          {{ item.value }} 
        </ng-container>
        <ng-template #segmentValue>
          {{getSegmentSummaryDisplayName(item.value)}}
        </ng-template>

      </td>
    </ng-container>
    <ng-container tsColumnDef="remove" alignment="right">
      <th ts-header-cell *tsHeaderCellDef></th>
      <td ts-cell *tsCellDef="let item">
        <ts-button buttonType="button" actionName="Button" theme="secondary" (clicked)="removeFolder(item)"
          [isSmall]="true">
          <i class="fas fa-trash"></i>
        </ts-button>
      </td>
    </ng-container>
    <ng-container tsColumnDef="expanded">
      <td ts-cell *tsCellDef="let item" [attr.colspan]="columns.length" class="expanded-cell"
        [ngClass]="{'open': rowState[item.name]}">
        <ng-container *ngIf="rowState[item.name]">
          <ng-container *ngIf="item.sectionKey !== 'segments'; else expandedSegments">
            <ts-button [isSmall]="true" [isDisabled]="item.selectedItems === item.filterItems.length"
              (click)="selectAllFilterItems(item)" class="select-all-button" theme="secondary" buttonType="button">
              {{ 'shared.dashboards.dialog.selectAll' | translate }}
            </ts-button>
            <span class="selected-label">
              {{ item.selectedItems }}
              {{ (item.selectedItems === 1 ? titleMap[item.sectionKey] : pluralTitleMap[item.sectionKey]) | translate }}
              {{ 'shared.dashboards.dialog.selected' | translate }}
            </span>
            <div>
              <ts-checkbox *ngFor="let filterItem of item.filterItems" [isChecked]="filterItem.isChecked"
                (inputChange)="selectOneFilterItem(item, filterItem)" (click)="$event.stopPropagation()">
                {{ filterItem.name }}
              </ts-checkbox>
            </div>
          </ng-container>

          <ng-template #expandedSegments>
            <ts-button [isSmall]="true" [isDisabled]="item.selectedItems === item.filterItems.length"
              (click)="selectAllFilterItems(item)" class="select-all-button" theme="secondary" buttonType="button">
              {{ 'shared.dashboards.dialog.selectAll' | translate }}
            </ts-button>
            <span class="selected-label">
              {{ item.selectedItems }}
              {{ (item.selectedItems === 1 ? titleMap[item.sectionKey] : pluralTitleMap[item.sectionKey]) | translate }}
              {{ 'shared.dashboards.dialog.selected' | translate }}
            </span>
            <div>
              <ts-checkbox *ngFor="let filterItem of item.filterItems" [isChecked]="filterItem.isChecked"
                (inputChange)="selectOneFilterItem(item, filterItem)" (click)="$event.stopPropagation()" [title]="getSegmentDisplayName(filterItem.name)">
                {{ getSegmentDisplayName(filterItem.name) }} 
              </ts-checkbox>
            </div>
          </ng-template>

        </ng-container>
      </td>
    </ng-container>

    <tr ts-header-row *tsHeaderRowDef="table.columnNames; sticky: true"></tr>
    <tr ts-row *tsRowDef="let row; columns: table.columnNames;"></tr>
    <tr ts-row *tsRowDef="let row; columns: ['expanded']" class="expanded-row"></tr>
  </table>
</div>