import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TsTableModule } from '@terminus-lib/ui-table';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TsSortModule } from '@terminus-lib/ui-sort';
import { TS_AMCHARTS_TOKEN, TsChartModule } from '@terminus-lib/ui-chart';
import { TranslateModule } from '@ngx-translate/core';

import { AttributionCampaignComponent } from './containers/attribution-campaign.component';
import { AttributionCampaignRoutingModule } from './attribution-campaign-routing.module';
import { AttributionCampaignEffects } from './state/attribution-campaign.effects';
import { AttributionCampaignFiltersComponent } from './components/attribution-campaign-filters/attribution-campaign-filters.component';
import { ReportFilterSelectorModule } from '@ui/components/report-filter-selector';
import { DateCohortsModule } from '@ui/components/date-cohorts';
import { AttributionCampaignTableComponent } from './components/attribution-campaign-table/attribution-campaign-table.component';
import {
  AttributionCampaignExpandedTableComponent
} from './components/attribution-campaign-expanded-table/attribution-campaign-expanded-table.component';
import { NoReportResultModule } from '@ui/components/no-report-result';
import { AttributionCampaignChartComponent } from './components/attribution-campaign-chart/attribution-campaign-chart.component';
import * as AttributionCampaignReducer from './state/attribution-campaign.reducer';
import { amChartsFactory } from '@util/amcharts';
import { ReportSettingPanelModule } from '@features/report-setting-panel';
import { HeaderCellModule } from '@ui/components/header-cell';
import { TablePanelModule } from '@ui/components/table-panel';
import { ReportMetricModule } from '@ui/components/report-metric';
import { PaginationModule } from '@ui/components/pagination';
import { TypedDataModule } from '@ui/pipes/typed-data';
import { CampaignPerformanceService } from './services/campaign-performance.service';
import { CampaignPerformanceSource } from './sources/campaign-performance.source';

@NgModule({
  declarations: [
    AttributionCampaignComponent,
    AttributionCampaignFiltersComponent,
    AttributionCampaignTableComponent,
    AttributionCampaignExpandedTableComponent,
    AttributionCampaignChartComponent
  ],
  imports: [
    CommonModule,
    AttributionCampaignRoutingModule,
    TsLoadingOverlayModule,
    TsTableModule,
    TsSortModule,
    TsChartModule,
    TablePanelModule,
    ReportFilterSelectorModule,
    DateCohortsModule,
    TypedDataModule,
    NoReportResultModule,
    ReportSettingPanelModule,
    ReportMetricModule,
    HeaderCellModule,
    StoreModule.forFeature(AttributionCampaignReducer.attributionCampaignFeatureKey, AttributionCampaignReducer.reducer),
    EffectsModule.forFeature([AttributionCampaignEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
    TranslateModule,
    PaginationModule
  ],
  providers: [
    {
      provide: TS_AMCHARTS_TOKEN,
      useFactory: amChartsFactory
    },
    CampaignPerformanceSource,
    CampaignPerformanceService
  ]
})

export class AttributionCampaignModule {
}
