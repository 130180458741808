import { color } from '@amcharts/amcharts4/core';
import { amChartsColor } from '../charts-colors.palette';

export function generateChartsPalette<T>(palette: T): Record<string, amChartsColor> {
  return Object.entries(palette).reduce<Record<string, amChartsColor>>(
    (acc, [key, val]) => ({
      ...acc,
      [key]: color(val),
    }),
    {},
  );
}
