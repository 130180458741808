import { IAppliedGlobalFiltersAsParams, IFactoryParams } from '@shared/interfaces';
import { InfluenceType, ModelType } from '@shared/enums';
import { ClosedType, DateType, InfluencesKeys, WonType } from '@measurement-studio/enums';
import { ISearchQuery } from '@ui/components/search-by-property';

export enum OpportunityDataType {
  Closed = 'closed',
  Pipeline = 'pipeline',
  Forecast = 'forecast',
  Revenue = 'revenue',
}

export enum OpportunityDirection {
  Asc = 'a',
  Desc = 'd',
}

export interface IOpportunityPagination {
  pg?: number;
  ps?: number;
}

export interface IOpportunitySort {
  fld?: string;
  tbl?: string; // api type. this property is necessary to sort data
  dir?: OpportunityDirection;
}

export interface IOpportunityRequest extends Partial<ISearchQuery>, IOpportunityPagination, IOpportunitySort {
  dateType?: DateType;
  closedType?: ClosedType;
  model?: ModelType | InfluenceType;
  type?: OpportunityDataType;
  wonType?: WonType;
  cohort?: string;
  endDate?: number;
  startDate?: number;
  [InfluencesKeys.SourceCampaignGroup]?: string;
  [InfluencesKeys.InfluencedCampaignGroup]?: string;
}

export type IOpportunityUsedQueryParams = IOpportunityRequest & IAppliedGlobalFiltersAsParams;

export function opportunityRequestFactory({
                                            overrides = {},
                                          }: IFactoryParams<IOpportunityRequest, number, boolean> = {}): IOpportunityRequest {
  return {
    pg: 1,
    ps: 20,
    dateType: DateType.Closed,
    closedType: ClosedType.ANY,
    model: ModelType.Even,
    type: OpportunityDataType.Closed,
    wonType: WonType.Won,
    cohort: 'time',
    startDate: 1483221600000, // 01/01/2017
    endDate: new Date().getTime(),
    ...overrides,
  };
}
