import { AdminAccessTypes, RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { IRouteItem } from '@shared/interfaces';
import * as constants from '@shared/constants';

export const MEASUREMENT_STUDIO_ROUTES: IRouteItem[] = [
  {
    routeId: RouteItemEnum.Dashboards,
    routeLabel: 'shared.dashboards.reportTitle',
    routeLink: ['/dashboards'],
    routeType: RouteTypeEnum.InternalLink,
    alwaysAdd: true,
  },
  // Discover -> Revenue & Pipeline
  {
    routeId: RouteItemEnum.MarketingImpact,
    routeLabel: 'data.routeLabel.marketingInfluence',
    routeLink: ['/discover/revenue-and-pipeline/marketing-impact'],
    routeType: RouteTypeEnum.InternalLink,
  },
  // Analyze -> Revenue & Pipeline
  {
    routeId: RouteItemEnum.AnalyzeMarketingImpact,
    routeLabel: 'data.routeLabel.marketingInfluence',
    routeLink: ['/analyze/revenue-and-pipeline/marketing-impact'],
    routeType: RouteTypeEnum.InternalLink,
  },
  // Terminus Engage
  {
    routeId: RouteItemEnum.CampaignOverview,
    routeLabel: 'data.routeLabel.campaignOverview',
    routeLink: ['https://my.terminus.com/campaign-overview'],
    routeType: RouteTypeEnum.ExternalLink,
  },
  {
    routeId: RouteItemEnum.CreateNewCampaign,
    routeLabel: 'data.routeLabel.createNewCampaign',
    routeLink: ['https://my.terminus.com/tactic-wizard'],
    routeType: RouteTypeEnum.ExternalLink,
  },
  // Salesforce
  {
    routeId: RouteItemEnum.Salesforce,
    routeLabel: 'data.routeLabel.salesforce',
    routeLink: [''], // NOTE: get from the backend
    routeType: RouteTypeEnum.ExternalLink,
  },
  {
    routeId: RouteItemEnum.Login,
    routeLabel: '',
    routeLink: [`/${constants.LOGIN_ROUTE_PATH}`],
    routeType: RouteTypeEnum.InternalLink,
  },
  // Settings
  {
    routeId: RouteItemEnum.Settings,
    routeLabel: 'data.routeLabel.settings',
    routeLink: ['/settings'],
    routeType: RouteTypeEnum.InternalLink,
    alwaysAdd: true,
  },
  {
    routeId: RouteItemEnum.AccountEngagement,
    routeLabel: 'data.routeLabel.settings',
    routeLink: ['/settings/account-engagement'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin, AdminAccessTypes.BFAdmin],
  },
  {
    routeId: RouteItemEnum.DemoConfig,
    routeLabel: 'data.routeLabel.demoConfig',
    routeLink: ['demo-config'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.BFAdmin],
  },
  {
    routeId: RouteItemEnum.Permissions,
    routeLabel: 'shared.navMenus.settings.permissions',
    routeLink: ['#/settings/permissions/team'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.Stages,
    routeLabel: 'shared.navMenus.settings.stages',
    routeLink: ['#/settings/stages'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.PagesAndFeatures,
    routeLabel: 'shared.navMenus.settings.pagesAndFeatures',
    routeLink: ['#/settings/pages-and-features/user-interface'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.DataHygiene,
    routeLabel: 'shared.navMenus.settings.dataHygiene',
    routeLink: ['#/settings/data-hygiene/orphan-lead-finder'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.CampaignScoring,
    routeLabel: 'shared.navMenus.settings.campaignScoring',
    routeLink: ['#/settings/attribution-weight?editable'],
    routeType: RouteTypeEnum.LegacyLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.DigitalAttribution,
    routeLabel: 'shared.navMenus.settings.digitalAttribution',
    routeLink: ['#/settings/digital-attribution'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.InternalConfiguration,
    routeLabel: 'shared.navMenus.settings.internalConfiguration',
    routeLink: ['#/settings/internal-configure/permissions'],
    routeType: RouteTypeEnum.LegacyLink,
    alwaysAdd: true,
  },
  {
    routeId: RouteItemEnum.Configuration,
    routeLabel: 'shared.navMenus.settings.configuration',
    routeLink: ['#/settings/self-configure/field-mapping'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  // Dashboard Legacy Routes
  {
    routeId: RouteItemEnum.WebActivitiesValues,
    routeLabel: '',
    routeLink: ['/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/leads'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.CampaignSpecificValues,
    routeLabel: '',
    routeLink: ['/analyze/campaigns/list-analysis/campaign-specific/attribution/leads'],
    routeType: RouteTypeEnum.InternalLink,
  },
];
