import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import {
  TS_SIDENAV_PLATFORM_SWITCHER_CONTENT,
  TS_SIDENAV_USER, TsSidenavDefaultOptions,
} from '@terminus-lib/ui-sidenav';

import { IRouteItem, IUserProfile, IMenuItem, INavMenu } from '@shared/interfaces';
import { NavPanelTypes, RouteTypeEnum } from '@shared/enums';
import { TsWindowService } from '@terminus-lib/fe-utilities';
import { EnvService } from '@shared/environment';

@Component({
  selector: 'tsh-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMenuComponent  {
  @Input() public baseHubPath: string; // HUB AJS url
  @Input() public currentTitle: string;
  @Input() public menu: INavMenu;
  @Input()
  public set user(profile: IUserProfile) {
    if (profile) {
      this.userInfo = {
        name: profile.orgName,
        email: profile.email
      };
    }
  }
  @Input() public options: TsSidenavDefaultOptions;
  @Input() public selectedNavItem: IMenuItem | null; // to show active nav item
  @Input() public switcherContent: TS_SIDENAV_PLATFORM_SWITCHER_CONTENT;
  @Input() public zdSsoAccess = false;
  @Output() public switchUser = new EventEmitter<string>();

  public readonly panelTypes = NavPanelTypes;
  public readonly routeTypes = RouteTypeEnum;
  public formGroup = this.formBuilder.group({
    user: [null, [Validators.required]]
  });
  public userInfo: TS_SIDENAV_USER | null;

  constructor(
    private formBuilder: FormBuilder,
    private windowService: TsWindowService,
    private envService: EnvService,
  ) {
  }

  getLink(routeItem: IRouteItem): string {
    if (routeItem.routeType === RouteTypeEnum.LegacyLink) {
      return `${this.baseHubPath}/${routeItem.routeLink[0]}`;
    }

    if (routeItem.routeType === RouteTypeEnum.ExternalLink) {
      return this.assembleExternalLink(routeItem);
    }

    return '';
  }

  emitSwitchUser(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.switchUser.emit((this.formGroup.get('user')?.value || "").toString());
  }

  navMenusClick(): void {
    this.windowService.nativeWindow.document
      .querySelector('.ts-sidenav-user__email')?.setAttribute('title', this.userInfo?.email || '');
  }

  /**
   * Check IRouteItem for an externalUrlKey
   * If an externalUrlKey is present, retrieve the external url from environment json.
   * Prepend the retrieved url and apped the expected routeLink.
   * Otherwise, return the target path.
   * @param routeItem - IRouteItem
   * @rturns url string
  */
  private assembleExternalLink(routeItem: IRouteItem): string {
    const basePath = this.envService.getEnv()[routeItem.externalUrlKey];
    return routeItem.externalUrlKey
      ? `${basePath}${routeItem.routeLink[0]}`
      : routeItem.routeLink[0];
  }
}
