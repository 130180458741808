import {
  KpiFirmographic, KpiGroup, KpiInterval,
  KpiProgression, KpiTrend, KpiType, SeriesTypes
} from './enums/scorecard.enum';

export const VALID_KPI_GROUPS = [
  KpiGroup.None,
  KpiGroup.ScorecardAggregate,
  KpiGroup.ScorecardSegment,
  KpiGroup.AccountEngagement,
  KpiGroup.Impressions,
  KpiGroup.Firmographics
];
export const VALID_KPI_INTERVALS = [
  KpiInterval.Weeks,
  KpiInterval.Months,
  KpiInterval.Quarters
];
export const VALID_KPI_PROGRESSIONS = [
  KpiProgression.List,
  KpiProgression.Engaged,
  KpiProgression.Segment,
  KpiProgression.Opportunity,
  KpiProgression.Won
];
export const VALID_KPI_TRENDS = [
  KpiTrend.Net,
  KpiTrend.Cumulative,
  KpiTrend.Snapshot
];
export const VALID_KPI_TYPES = [
  KpiType.Account,
  KpiType.OpptyCreated,
  KpiType.OpptyClosed,
  KpiType.OpptyWon,
  KpiType.Pipeline,
  KpiType.Revenue,
  KpiType.WinRate,
  KpiType.DealVelocity,
  KpiType.AvgDealSize
];
export const VALID_KPI_FIRMOGRAPHICS = [
  KpiFirmographic.None,
  KpiFirmographic.Revenue,
  KpiFirmographic.Employee,
  KpiFirmographic.Industry
];
export const VALID_GRAPH_SERIES_TYPES = [
  SeriesTypes.Line,
  SeriesTypes.StackedArea,
  SeriesTypes.Column,
  SeriesTypes.StackedColumn
];

export const SCORECARD_OVERVIEW = 'overview';
export const SCORECARD_TRENDING_KPI = 'trending-kpi';
