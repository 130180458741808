import {
  GlobalFiltersApiKeys, GlobalFiltersHeader,
  GlobalFiltersKeys, TableIdentifier
} from '@shared/enums';
import { IDataFilter } from '@shared/interfaces';

export const GLOBAL_FILTERS_KEYS: GlobalFiltersKeys[] = [
  GlobalFiltersKeys.Account,
  GlobalFiltersKeys.Campaign,
  GlobalFiltersKeys.CampaignMember,
  GlobalFiltersKeys.Lead,
  GlobalFiltersKeys.Opportunity,
  GlobalFiltersKeys.TerminusAccount,
  GlobalFiltersKeys.Segments
];

export const GLOBAL_FILTER_HEADER_KEY_MAP = {
  [GlobalFiltersKeys.Account]: GlobalFiltersHeader.Account,
  [GlobalFiltersKeys.AccountList]: GlobalFiltersHeader.AccountList,
  [GlobalFiltersKeys.Campaign]: GlobalFiltersHeader.Campaign,
  [GlobalFiltersKeys.CampaignMember]: GlobalFiltersHeader.CampaignMember,
  [GlobalFiltersKeys.Lead]: GlobalFiltersHeader.People,
  [GlobalFiltersKeys.Opportunity]: GlobalFiltersHeader.Opportunity,
  [GlobalFiltersKeys.TerminusAccount]: GlobalFiltersHeader.TerminusAccount,
  [GlobalFiltersKeys.Segments]: GlobalFiltersHeader.Segments
};

export const GLOBAL_FILTER_API_KEY_MAP = {
  [GlobalFiltersKeys.Account]: GlobalFiltersApiKeys.Account,
  [GlobalFiltersKeys.Campaign]: GlobalFiltersApiKeys.Campaign,
  [GlobalFiltersKeys.CampaignMember]: GlobalFiltersApiKeys.CampaignMember,
  [GlobalFiltersKeys.Lead]: GlobalFiltersApiKeys.Lead,
  [GlobalFiltersKeys.Opportunity]: GlobalFiltersApiKeys.Opportunity,
  [GlobalFiltersKeys.TerminusAccount]: GlobalFiltersApiKeys.TerminusAccount,
  [GlobalFiltersKeys.Segments]: GlobalFiltersApiKeys.Segments
};

export const GLOBAL_FILTER_API_KEY_MAP_REVERSE = {
  [GlobalFiltersApiKeys.Account]: GlobalFiltersKeys.Account,
  [GlobalFiltersApiKeys.Campaign]: GlobalFiltersKeys.Campaign,
  [GlobalFiltersApiKeys.CampaignMember]: GlobalFiltersKeys.CampaignMember,
  [GlobalFiltersApiKeys.Lead]: GlobalFiltersKeys.Lead,
  [GlobalFiltersApiKeys.Opportunity]: GlobalFiltersKeys.Opportunity,
  [GlobalFiltersApiKeys.TerminusAccount]: GlobalFiltersKeys.TerminusAccount,
  [GlobalFiltersKeys.Segments]: GlobalFiltersKeys.Segments
};

export const ANALYTICS_GLOBAL_FILTER_API_KEY_MAP: Map<GlobalFiltersKeys | string, TableIdentifier> =
  new Map<GlobalFiltersKeys | string, TableIdentifier>()
  .set(GlobalFiltersKeys.Account, TableIdentifier.Account)
  .set(GlobalFiltersKeys.Campaign, TableIdentifier.Campaign)
  .set(GlobalFiltersKeys.CampaignMember, TableIdentifier.CampaignMember)
  .set(GlobalFiltersKeys.Lead, TableIdentifier.Lead)
  .set(GlobalFiltersKeys.Opportunity, TableIdentifier.Opportunity)
  .set(GlobalFiltersKeys.TerminusAccount, TableIdentifier.TerminusAccount)
  .set(GlobalFiltersKeys.Segments, TableIdentifier.Segments);

export const EMPTY_GLOBAL_FILTERS_STATE: IDataFilter = {
  name: null,
  modifiedDate: new Date().toString(),
  private: true,
  filters: {},
  applied: false,
  isMine: true,
  ownerId: 0,
  listIds: [],
};
