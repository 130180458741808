import { createAction, props } from '@ngrx/store';
import { IUserMessage } from '@shared/interfaces';

export enum NotificationMessagesActionTypes {
  AddMessage = '[NotificationMessages] Add Message',
  ClearMessage = '[NotificationMessages] Clear Message',
  ClearAllMessages = '[NotificationMessages] Clear All Messages',
}
const addMessage = createAction(
  NotificationMessagesActionTypes.AddMessage,
  props<{ message: IUserMessage }>()
);

const clearMessage = createAction(
  NotificationMessagesActionTypes.ClearMessage,
  props<{ message: string }>()
);

const clearAllMessages = createAction(
  NotificationMessagesActionTypes.ClearAllMessages
);

export const notificationMessagesActions = {
  addMessage,
  clearMessage,
  clearAllMessages
};
