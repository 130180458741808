import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { IDateCohort, IDateCohortGroup, ILabelValue } from '@shared/interfaces';
import { INFLUENCE_TYPE_OPTIONS } from '@measurement-studio/constants';
import { DataSetOptions } from '@measurement-studio/classes/data-set-options';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';
import { FilterFormField } from '../../enums';
import { DataSetType } from '@measurement-studio/enums';
import { InfluenceType, ModelType } from '@shared/enums';

@Component({
  selector: 'ms-analytics-filters',
  templateUrl: './analytics-filters.component.html',
  styleUrls: ['./analytics-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsFiltersComponent implements OnChanges {
  @Input() public reportName = 'measurementStudio.features.analytics.filters.reportName';
  @Input() public datasetTitle = 'measurementStudio.features.analytics.filters.campaignsThat';
  @Input() public influenceTitle = 'measurementStudio.features.analytics.filters.influenceType';
  @Input() public modelTitle = 'measurementStudio.features.analytics.filters.model';
  @Input() public filters: IAnalyticsRequest;
  @Input() public modelTypeOptions: ILabelValue[] = [];
  @Input() public dateCohortOptions: IDateCohortGroup[] = [];
  @Input() public selectedDateCohort: IDateCohort;
  @Input() public isLoading = false;
  @Input() public isWebActivities = false;
  @Output() public applyFilters = new EventEmitter<IAnalyticsRequest>();
  @Output() public changeSelectedDateCohort = new EventEmitter<IDateCohort>();

  public filterForm = this.formBuilder.group({
    [FilterFormField.dataSet]: new FormControl<DataSetType>(null, [Validators.required]),
    [FilterFormField.influenceType]: new FormControl<InfluenceType>(null, [Validators.required]),
    [FilterFormField.model]: new FormControl<ModelType>(null, [Validators.required]),
  });
  public filterFormField = FilterFormField;
  public datasetTypeOptions = new DataSetOptions().getDataSetFullOptions();
  public influenceOptions = INFLUENCE_TYPE_OPTIONS;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters) {
      this.filterForm.patchValue({
        [FilterFormField.dataSet]: this.filters.dataSet,
        [FilterFormField.influenceType]: this.filters.influenceType,
        [FilterFormField.model]: this.filters.model,
      });
    }
    if (changes.isWebActivities) {
      this.datasetTypeOptions = this.isWebActivities ?
        new DataSetOptions().getCampaignDataOptions() : new DataSetOptions().getDataSetFullOptions();
    }
  }

  /**
   * Apply change for report setting only
   */
  apply(): void {
    const newFilters: Partial<IAnalyticsRequest> = {
      dataSet: this.filterForm.get(FilterFormField.dataSet).value,
      influenceType: this.filterForm.get(FilterFormField.influenceType).value,
      model: this.filterForm.get(FilterFormField.model).value
    };
    this.applyFilters.emit({
      ...this.filters,
      ...newFilters
    });
  }

  /**
   * Apply change for date cohort only
   */
  applyDateCohort(dateCohort?: IDateCohort): void {
    this.applyFilters.emit({
      ...this.filters,
      cohort: {
        cohort: dateCohort?.cohort || this.selectedDateCohort.cohort,
        endDate: dateCohort?.endDate || this.selectedDateCohort.endDate,
        startDate: dateCohort?.startDate || this.selectedDateCohort.startDate
      }
    });
  }

  changeDateCohort(dateCohort: IDateCohort): void {
    this.changeSelectedDateCohort.emit(dateCohort);
  }
}
