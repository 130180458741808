import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { getIsSplitIoFlagOn } from '../state/split-io.selectors';

export function splitIoGuard<T>(store: Store, treatmentToken: string, isFlagEnabled = false): OperatorFunction<T, T> {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      concatLatestFrom(() => store.select(getIsSplitIoFlagOn(treatmentToken))),
      filter(([, isOn]) => isFlagEnabled === isOn),
      map(([data]) => data),
    );
  };
}
