<div class="title">{{'shared.dashboards.dialog.tile.visualizationsOptions' | translate}}</div>
<div class="container" *ngIf="tile">
  <div *ngFor="let visualization of tile.visualizationTypes"
       class="visualization">
    <img [src]="visualizationMapper[visualization].iconPath"
         [alt]="visualizationMapper[visualization].label | translate">
    <div class="visualization-label">
      {{visualizationMapper[visualization].label | translate}}
    </div>
  </div>
</div>
