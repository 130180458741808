import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateCohortsActions } from './date-cohorts.actions';
import * as selectors from './date-cohorts.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { IDateCohortResponse, userMessageFactory } from '@shared/interfaces';
import { DateCohortsService } from '../services/date-cohorts.service';
import { DateCohortsState } from './date-cohorts.reducer';

@Injectable()
export class DateCohortsEffects {

  constructor(private actions$: Actions,
              private store: Store<DateCohortsState>,
              public dateCohortsService: DateCohortsService,
              private translate: TranslateService
  ) {
  }

  public loadDateCohorts$ = createEffect(() => ({
    isLoaded$ = this.store.pipe(select(selectors.getDateCohortIsLoaded))
  } = {}) => this.actions$.pipe(
    ofType(dateCohortsActions.loadDateCohorts),
    withLatestFrom(isLoaded$),
    // if already loaded then don't dispatch any action
    filter(([_, isLoaded]: [null, boolean]) => !isLoaded),
    mergeMap(() => {
      return this.dateCohortsService.getDateCohorts$().pipe(
        map((dateCohorts: IDateCohortResponse) => dateCohortsActions.loadDateCohortsSuccess({data: dateCohorts})),
        catchError((error: HttpErrorResponse) => {
          const message$ = error?.message
            ? of(error.message)
            : this.translate.get('shared.dateCohorts.defaultErrorMessage');
          return message$.pipe(
            mergeMap(message => this.translate.get('ngrx.errorMessage', {message}).pipe(
              map(result => dateCohortsActions.loadDateCohortsFailure({message: result}))
            ))
          );
        })
      );
    })
  ));


  public loadDateCohortsFailure$ = createEffect(() => this.actions$.pipe(
    ofType(dateCohortsActions.loadDateCohortsFailure),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));
}
