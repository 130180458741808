import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

import { IFlatStageProgression, IFlatStageProgressionDetail, IStagesSnapshotFilters } from '../interfaces';
import { IDateCohort } from '@shared/interfaces';
import { TsSortState } from '@terminus-lib/ui-sort';


export enum StagesSnapshotActionTypes {
  SetSelectedDateCohort = '[Stages Snapshot] Set selected date cohort for Stages Snapshot report',
  SetFilters = '[Stages Snapshot] Set filters for api call params',
  GetFilters = '[Stages Snapshot] Get filters for setup query params',
  LoadStageProgressionData = '[Stages Snapshot] Get Stage Progression Data',
  LoadStageProgressionDataSuccess = '[Stages Snapshot] Get Stage Progression Data Success',
  LoadStageProgressionDataFailure = '[Stages Snapshot] Get Stage Progression Data Failure',
  LoadStageProgressionDetailData = '[Stages Snapshot] Get Stage Progression Detail Data',
  LoadStageProgressionDetailDataSuccess = '[Stages Snapshot] Get Stage Progression Detail Data Success',
  LoadStageProgressionDetailDataFailure = '[Stages Snapshot] Get Stage Progression Detail Data Failure',
  DownloadCSV = '[Stages Snapshot] Download Details CSV',
  SetActiveStage = '[Stages Snapshot] Set active stage for Stages Snapshot report',
  SetTablePage = '[Stages Snapshot] Set table page for Stages Snapshot report',
  SetTableSearch = '[Stages Snapshot] Set table search for Stages Snapshot report',
  SetTableSortState = '[Stages Snapshot] Set table sort state for Stages Snapshot report',
}

const setFilters = createAction(
  StagesSnapshotActionTypes.SetFilters,
  props<{ request: IStagesSnapshotFilters; }>()
);

const getFilters = createAction(
  StagesSnapshotActionTypes.GetFilters,
  props<{ params: Params; }>()
);

const setSelectedDateCohort = createAction(
  StagesSnapshotActionTypes.SetSelectedDateCohort,
  props<{ cohort: IDateCohort; }>()
);

const loadStageProgressionData = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionData,
);

const loadStageProgressionDataSuccess = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionDataSuccess,
  props<{ data: IFlatStageProgression[]; }>()
);

const loadStageProgressionDataFailure = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionDataFailure,
  props<{ message: string; }>()
);

const loadStageProgressionDetailData = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionDetailData,
  props<{ data: string; }>()
);

const loadStageProgressionDetailDataSuccess = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionDetailDataSuccess,
  props<{ data: IFlatStageProgressionDetail[]; }>()
);

const loadStageProgressionDetailDataFailure = createAction(
  StagesSnapshotActionTypes.LoadStageProgressionDetailDataFailure,
  props<{ message: string; }>()
);

const downloadCSV = createAction(
  StagesSnapshotActionTypes.DownloadCSV,
);

const setActiveStage = createAction(
  StagesSnapshotActionTypes.SetActiveStage,
  props<{ stage: string; }>()
);

const setTablePage = createAction(
  StagesSnapshotActionTypes.SetTablePage,
  props<{ page: number; }>()
);

const setTableSearch = createAction(
  StagesSnapshotActionTypes.SetTableSearch,
  props<{ search: string; }>()
);

const setTableSortState = createAction(
  StagesSnapshotActionTypes.SetTableSortState,
  props<{ data: TsSortState; }>()
);

export const stagesSnapshotActions = {
  setFilters,
  getFilters,
  setSelectedDateCohort,
  loadStageProgressionData,
  loadStageProgressionDataSuccess,
  loadStageProgressionDataFailure,
  loadStageProgressionDetailData,
  loadStageProgressionDetailDataSuccess,
  loadStageProgressionDetailDataFailure,
  downloadCSV,
  setActiveStage,
  setTablePage,
  setTableSearch,
  setTableSortState,
};
