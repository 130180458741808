<div>
  <tsh-table-panel (download)="emitDownload()"
                   totalTitle="measurementStudio.features.attributionCampaign.expandedTable.totalTitle"
                   [totalCount]="totals">
  </tsh-table-panel>
  <table
    *ngIf="report?.length"
    ts-table
    #table="tsTable"
    tsSort
    (tsSortChange)="emitChangeSort($event)"
    [tsSortActive]="sortState?.active"
    [tsSortDirection]="sortState?.direction"
    [columns]="columns"
    [dataSource]="dataSource">
    <ng-container *ngFor="let column of columns">
      <ng-container [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-header-cell ts-sort-header [start]="column.startSorting">
          <tsh-header-cell [column]="column"></tsh-header-cell>
        </th>
        <td ts-cell *tsCellDef="let item;">
          <ng-container *ngIf="!column.link && !column.internalLink">
            {{ item[column.name] | typedData : column.dataType : column.decimal : column.shouldAbbreviate || false : currency }}
          </ng-container>
          <ng-container *ngIf="column.link">
            <a (click)="emitNavigate(column.link(item, filters))"
               [title]="item[column.name]">
              {{ item[column.name] }}
            </a>
          </ng-container>
          <ng-container *ngIf="column.internalLink && column.internalLink(item, filters) as linkData">
            <a [queryParamsHandling]="linkData.queryParamsHandling"
               [queryParams]="linkData.queryParams"
               [title]="item[column.name]"
               [routerLink]="linkData.link">
              {{ item[column.name] }}
            </a>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr ts-header-row *tsHeaderRowDef="table.columnNames"></tr>
    <tr ts-row *tsRowDef="let row; columns: table.columnNames;"></tr>
  </table>
  <tsh-pagination
    [currentPageIndex]="pagination?.page"
    [totalRecords]="totals"
    (pageSelect)="emitPageChange($event)">
  </tsh-pagination>
  <tsh-no-report-result *ngIf="!report?.length">
  </tsh-no-report-result>
</div>
