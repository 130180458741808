<h3 class="title">{{'shared.dashboards.dialog.tile.visualization' | translate}}</h3>
<ng-container *ngIf="mapper[tileType] as tile">
  <ts-radio-group visualFormat="visual-centered" [formControl]="control">
    <ts-radio-button *ngFor="let visualization of mapper[tileType].visualizationTypes"
                     [attr.data-cy]="'tile-dialog-form-visualization-type-' + visualization"
                     [value]="visualization">
      <img [src]="visualizationMapper[visualization].iconPath"
           [alt]="visualizationMapper[visualization].label | translate">
      <div class="visualization-label">
        {{visualizationMapper[visualization].label | translate}}
      </div>
    </ts-radio-button>
  </ts-radio-group>
</ng-container>
