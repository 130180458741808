import { claimValue, tokenFor } from '@terminus-lib/fe-jwt';

import { createSelector } from '@ngrx/store';

import { ClaimMap, hubServiceName, HubTokenNameType } from '../claim-map';
import { AdminAccessTypes } from '@shared/enums';

export const selectHubToken = tokenFor<ClaimMap, HubTokenNameType>(hubServiceName);

export const selectPodPath = claimValue<ClaimMap, HubTokenNameType, 'bf.pod'>(hubServiceName, 'bf.pod');
export const selectCanUseAccountHub = claimValue<ClaimMap, HubTokenNameType, 'bf.acthub'>(hubServiceName, 'bf.acthub');
// User is an Org Admin
export const selectIsOrgAdmin = claimValue<ClaimMap, HubTokenNameType, 'bf.oadm'>(hubServiceName, 'bf.oadm');
// User is an BF Admin
export const selectIsBFAdmin = claimValue<ClaimMap, HubTokenNameType, 'bf.badm'>(hubServiceName, 'bf.badm');

export const getAdminTypesForCurrentUser = createSelector(
  selectIsOrgAdmin,
  selectIsBFAdmin,
  (
    isOrgAdmin: boolean,
    isBFAdmin: boolean
  ): AdminAccessTypes[] => {
    const userAccessTypes = [];

    if (isOrgAdmin) {
      userAccessTypes.push(AdminAccessTypes.OrgAdmin);
    }

    if (isBFAdmin) {
      userAccessTypes.push(AdminAccessTypes.BFAdmin);
    }

    return userAccessTypes;
  }
);
