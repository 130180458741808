import { defineTypeEnum } from '@terminus-lib/fe-utilities';
import { createAction, props } from '@ngrx/store';

import { IDateCohort } from '@shared/interfaces';
import { IFormattedInsightBookmarkParams } from '../../interfaces/insight-bookmark-params.interface';

export enum InsightFiltersActionTypes {
  CHANGE_SELECTED_DATE_COHORT = '[Insights Filters Report] Change Selected Date Cohort',
  SET_APPLIED_DATE_COHORT = '[Insights Filters] Set Applied Date Cohort',
  SET_FILTERS = '[Insights Filters Report] Set Report Filters',
}

defineTypeEnum(InsightFiltersActionTypes);

const changeSelectedDateCohort = createAction(
  InsightFiltersActionTypes.CHANGE_SELECTED_DATE_COHORT,
  props<{ cohort: IDateCohort }>()
);

const setAppliedDateCohort = createAction(
  InsightFiltersActionTypes.SET_APPLIED_DATE_COHORT,
  props<{ cohort: IDateCohort }>()
);

const setFilters = createAction(
  InsightFiltersActionTypes.SET_FILTERS,
  props<{ params: IFormattedInsightBookmarkParams }>()
);

export const insightFiltersActions = {
  changeSelectedDateCohort,
  setAppliedDateCohort,
  setFilters
};
