import { IFactoryParams } from '@shared/interfaces';
import { IMarketingInfluenceCount, marketingInfluenceCountFactory } from './marketing-influence-count.interface';

export interface IMarketingInfluenceDeal {
  month: string;
  deal: IMarketingInfluenceCount;
  marketingDeal: IMarketingInfluenceCount;
}

export function marketingInfluenceDealFactory({
  n = 1,
  overrides = {},
}: IFactoryParams<IMarketingInfluenceDeal, number, boolean> = {}): IMarketingInfluenceDeal {
  return {
    month: `Q1 ${2000 + n}`,
    deal: marketingInfluenceCountFactory(),
    marketingDeal: marketingInfluenceCountFactory({ n: 0.5 }),
    ...overrides
  };
}
