import { RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { IRouteItem } from '@shared/interfaces';

export function findRouteItem(routeId: RouteItemEnum, isEnabled: boolean, applicationRoutes): IRouteItem | null {
  const routes = applicationRoutes.filter(route => route.routeId === routeId);

  if (routes.length === 1) {
    return routes[0]; // the only route
  }

  if (routes.length > 1) { // could be both internal and external routes because of migration
    return isEnabled
      ? routes.find(route => route.routeType === RouteTypeEnum.InternalLink) // then activate internal route
      : routes.find(route => route.routeType === RouteTypeEnum.LegacyLink); // otherwise go to the legacy app
  }
  return null; // no such route
}
