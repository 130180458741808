import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IAdsInfluencedInsights, IAdsInfluencedInsightsDetailsResponse } from '@measurement-studio/interfaces';
import { IAdsInfluencedInsightsSource } from './influenced-insights.source.interface';
import { IAdsInfluencedAddToListRequest, IAdsInfluencedRequest } from '../../interfaces/ads-influenced-request.interface';

export enum InfluencedInsightsSourceURLs {
  GetInfluencedInsights = '/v1/get_ads_influenced_insight',
  GetInfluencedInsightsDetails = '/v1/get_ads_influenced_insight_details',
  Export = '/v1/export_ads_influenced_insight_details',
  AddToAccountList = '/v1/add_to_list_insight_details',
}

@Injectable({
  providedIn: 'root'
})
export class InfluencedInsightsSource extends HttpClientBase implements IAdsInfluencedInsightsSource {

  getInfluencedInsights$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedInsights> {
    const endpoint = this.endpoint(InfluencedInsightsSourceURLs.GetInfluencedInsights);

    return this.httpClient.post<IAdsInfluencedInsights>(endpoint, request);
  }

  getInfluencedInsightsDetails$(request: IAdsInfluencedRequest): Observable<IAdsInfluencedInsightsDetailsResponse> {
    const endpoint = this.endpoint(InfluencedInsightsSourceURLs.GetInfluencedInsightsDetails);

    return this.httpClient.post<IAdsInfluencedInsightsDetailsResponse>(endpoint, request);
  }

  getDetailsCSV$(body: IAdsInfluencedRequest): Observable<string> {
    const endpoint = this.endpoint(InfluencedInsightsSourceURLs.Export);

    return this.httpClient.post<string>(endpoint, body, {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      responseType: 'text' as any // use this option to prevent parse error
    });
  }

  addAccountsToAccountList$(request: IAdsInfluencedAddToListRequest): Observable<string[]> {
    const endpoint = this.endpoint(InfluencedInsightsSourceURLs.AddToAccountList);

    return this.httpClient.post<string[]>(
      endpoint,
      request,
      {responseType: 'text' as 'json'}
    );
  }
}
