import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StageInfluenceComponent } from './containers/stage-influence.component';

const routes: Routes = [{
  path: '',
  component: StageInfluenceComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'campaign',
    },
    {
      path: 'campaign',
      loadChildren: () => import ('./containers/campaign/campaign.module').then(m => m.CampaignModule),
    },
    {
      path: 'campaign-type',
      loadChildren: () => import ('./containers/campaign-type/campaign-type.module').then(m => m.CampaignTypeModule),
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StageInfluenceRoutingModule {
}
