import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AnalyticsHelperService } from './helpers/analytics-helper.service';
import { analyticsFeatureKey, analyticsStateReducer } from './state';
import { GeneralEffects } from './state/general/general.effects';
import { CampaignGroupsEffects } from './state/campaign-groups/campaign-groups.effects';
import { CampaignsEffects } from './state/campaigns/campaigns.effects';
import { TotalsEffects } from './state/totals/totals.effects';
import { CampaignEffects } from './state/campaign/campaign.effects';
import { SourceEffects } from './state/source/source.effects';
import { MediumsEffects } from './state/mediums/mediums.effects';
import { ColumnsSaverService } from '@shared/data-access/columns-saver';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(analyticsFeatureKey, analyticsStateReducer),
    EffectsModule.forFeature([
      GeneralEffects,
      CampaignGroupsEffects,
      CampaignsEffects,
      TotalsEffects,
      SourceEffects,
      CampaignEffects,
      MediumsEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),
  ],
  providers: [AnalyticsHelperService, ColumnsSaverService]
})
export class SharedAnalyticsModule {
}
