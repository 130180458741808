import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { DefaultTileInformation } from '@shared/interfaces';
import { VisualizationTypes } from '@shared/enums';
import { VISUALIZATION_ICON_PATH_MAPPER } from '../../tile-modal.constant';

@Component({
  selector: 'tsh-visualization-preview-types',
  templateUrl: './visualization-types-preview.component.html',
  styleUrls: ['./visualization-types-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizationTypesPreviewComponent {
  @Input() public tile: DefaultTileInformation;

  public readonly visualizationMapper: Record<VisualizationTypes, { iconPath: string, label: string }> = VISUALIZATION_ICON_PATH_MAPPER;
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [VisualizationTypesPreviewComponent],
  exports: [VisualizationTypesPreviewComponent],
})
export class VisualizationTypesPreviewModule {
}
