<tsh-table-panel
  (download)="emitDownload()"
  totalTitle="feature.sharedTrending.responsesTable.totalTitle"
  [noResult]="!data?.length"
  [searchEnabled]="false"
  [totalCount]="data?.length"
  [showColumns]="false">
</tsh-table-panel>
<div class="table-container">
  <table ts-table
         tsSort
         #table="tsTable"
         (tsSortChange)="changeSort($event)"
         [tsSortActive]="sortState?.active"
         [tsSortDirection]="sortState?.direction"
         [columns]="columns"
         [dataSource]="dataSource">

    <ng-container *ngFor="let column of columns; let colIxd = index;">
      <ng-container [tsColumnDef]="column.name"
                    [alignment]="column.dataType !== dataTypes.Text ? 'right' : 'left'">
        <th *tsHeaderCellDef ts-sort-header ts-header-cell start="desc">
          <tsh-header-cell [column]="column"></tsh-header-cell>
        </th>
        <td ts-cell *tsCellDef="let item">
          <ng-container *ngIf="column.dataType === dataTypes.Date; else noDate">
            {{ item[column.name] | date }}
          </ng-container>
          <ng-template #noDate>
            {{ item[column.name] | typedData : column.dataType }}
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <tr ts-header-row *tsHeaderRowDef="table.columnNames; sticky: true"></tr>
    <tr ts-row *tsRowDef="let item; columns: table.columnNames;"></tr>
  </table>
</div>
<tsh-pagination
  [currentPageIndex]="page"
  [totalRecords]="data?.length"
  (pageSelect)="pageChange($event)">
</tsh-pagination>
