import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IAttributionCampaignExpandedRow } from '../../interfaces/attribution-campaign-report.interface';
import { DataTypeEnum } from '@shared/enums';
import { ICampaignFilters } from '../../interfaces/campaign-filters.interface';
import { GoParams, IReportColumn } from '@shared/interfaces';
import { IAttributionCampaignPagination } from '../../interfaces/attribution-campaign-pagination.interface';

@Component({
  selector: 'ms-attribution-campaign-expanded-table',
  templateUrl: './attribution-campaign-expanded-table.component.html',
  styleUrls: ['./attribution-campaign-expanded-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributionCampaignExpandedTableComponent implements OnChanges {
  @Input() public report: IAttributionCampaignExpandedRow[];
  @Input() public totals = 0;
  @Input() public currency: string | null;
  @Input() public sortState: TsSortState | null;
  @Input() public columns: IReportColumn[] = [];
  @Input() public filters: ICampaignFilters;
  @Input() public pagination: IAttributionCampaignPagination;
  @Output() public navigate = new EventEmitter<GoParams>();
  @Output() public changeSort = new EventEmitter<TsSortState>();
  @Output() public changePage = new EventEmitter<number>();
  @Output() public download = new EventEmitter<void>();

  public dataSource = new TsTableDataSource<IAttributionCampaignExpandedRow>();
  public dataTypes = DataTypeEnum;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.report
      && changes.report.currentValue
      && changes.report.currentValue !== changes.report.previousValue) {
      this.dataSource.data = this.report;
      this.changeDetector.detectChanges(); // to fix table width issue
    }

    if (changes.columns) {
      this.changeDetector.detectChanges(); // to fix table width issue
    }
  }

  emitNavigate(route: GoParams): void {
    this.navigate.emit(route);
  }

  emitChangeSort(sortState: TsSortState): void {
    this.changeSort.emit(sortState);
  }

  emitPageChange(event: number): void {
    this.changePage.emit(event);
  }

  emitDownload(): void {
    this.download.emit();
  }
}
