export enum FeatureFlagsEnum {
    AnalyticsAttributedColumns = 'analyticsAttributedColumns',
    WaterfallStages = 'waterfallStages',
    NewInfluenceType = 'feature-model-influence_type',
    NewInfluenceTypeLimited = 'feature-model-influence_type_limited',
    FeatureScorecardTrendingKpiTileUi = 'feature-scorecard_trending-kpi_tile_ui',
    ScorecardOverviewNewUX = 'feature-scorecard_overview-new_ux',
    FeatureCampaignTrendingInfluenced = 'feature-campaign_trending-influenced',
    OlfSfdcPush = 'olf-sfdc-push',
    SettingsMigrated = 'feature-settings-migrated',
}
