/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { EnvService } from '@shared/environment';

@Injectable()
export class HttpClientBase {

  constructor(
    protected httpClient: HttpClient,
    protected envService: EnvService
  ) {
  }

  protected customJWT: string;
  private serverPodPath = 'SERVER_POD_PATH_NOT_INITIALIZED';
  private customJWTSubscription: Subscription;

  set podPath(obs: Observable<string>) {
    obs.pipe(take(1)).subscribe(
      (bfPod: string) => this.serverPodPath = bfPod
    );
  }
  // NOTE: there are cases when we want to use another jwt token in the header. For these cases
  // we can set jwt and update HttpHeaders in source file
  set jwt(obs: Observable<string>) {
    // NOTE: don't use take(1) here ot it will logout you just after login
    if (this.customJWTSubscription) {
      this.customJWTSubscription.unsubscribe();
    }

    this.customJWTSubscription = obs.subscribe(
      (token: string) => this.customJWT = token
    );
  }

  protected endpoint(endpoint: string): string {
    // protect against duplicate '/' in path after combining
    if(endpoint.startsWith('/') && this.serverPodPath.endsWith('/')){
      endpoint = endpoint.slice(1);
    }
    return `${this.serverPodPath}${endpoint}`;
  }
}
