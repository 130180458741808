import { createSelector } from '@ngrx/store';
import { AnalyticsState, selectAnalyticsState } from '..';
import { ISourceState, sourceFeatureKey } from './source.reducer';
import { gerRecordIds, isStateLoading } from '../../helpers/store.helper';
import { INestedTableState, ISourceTableItem } from '../../interfaces';
import { defaultPager, defaultSorter } from '../../data';
import { IAnalyticsNested } from '@measurement-studio/interfaces';

export const getSource = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[sourceFeatureKey]);

export const getReportData = createSelector(getSource,
  (state: ISourceState): Partial<ISourceTableItem>[] => state.data);

export const getNestedSource = createSelector(getSource,
  (state: ISourceState) => state.nested);

export const getNestedData = createSelector(getNestedSource,
  (state: IAnalyticsNested<ISourceTableItem>) => state.data);

export const getNestedDataById = createSelector(getNestedData,
  (state: Record<string, Partial<ISourceTableItem>[]>, id: string) => state[id]);

export const getNestedSorter = createSelector(getNestedSource,
  (state: IAnalyticsNested<ISourceTableItem>, id: string) => state.sorter[id] || defaultSorter);

export const getNestedPager = createSelector(getNestedSource,
  (state: IAnalyticsNested<ISourceTableItem>, id: string) => state.pager[id] || defaultPager);

export const getNestedRecordIds = createSelector(getNestedSource,
  (state: IAnalyticsNested<ISourceTableItem>, id: string) => gerRecordIds(state.data[id]));

export const getSourceRecordIds = createSelector(getSource,
  (state: ISourceState) => gerRecordIds(state.data));

export const getSourceIsLoading = createSelector(getSource, isStateLoading);

export const getSourceIsDriverLoading = createSelector(getSource,
  (state: ISourceState) => state.isDriverLoading);

export const getSourceTotalResults = createSelector(getSource,
  (state: ISourceState) => state.totalResults);

export const getNestedTableState = createSelector(getSource,
  (state: ISourceState) => state.nestedTableState);

export const getNestedTableVisibility = createSelector(getNestedTableState,
  (nestedState: INestedTableState): Record<string, boolean> => nestedState.visible);

export const getSourceUsedParams = createSelector(getSource,
  (state: ISourceState) => state.usedParamsInDriver);

export const getSelectedMedium = createSelector(getSource,
  (state: ISourceState) => state.selectedMedium);
