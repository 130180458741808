import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortState } from '@terminus-lib/ui-sort';

import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { GoParams, IReportColumn } from '@shared/interfaces';
import { ISearchQuery } from '@ui/components/search-by-property';
import { IFlatStageProgressionDetail, IStagesSnapshotTableOptions } from '../../interfaces';

@Component({
  selector: 'ms-stages-snapshot-table',
  templateUrl: './stages-snapshot-table.component.html',
  styleUrls: ['./stages-snapshot-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StagesSnapshotTableComponent implements OnChanges {
  @Input() public columns: IReportColumn[];
  @Input() public currency: string | null;
  @Input() public reportOptions: IStagesSnapshotTableOptions;
  @Input() public isLoading: boolean;
  @Input() public report: IFlatStageProgressionDetail[];
  @Input() public count: number;
  @Input() public totalTitle: string;
  @Input() public crmUrl: string;
  @Input() public noResultTitle: string;
  @Input() public noResultDescription: string;
  @Output() public download = new EventEmitter<void>();
  @Output() public search = new EventEmitter<string>();
  @Output() public sort = new EventEmitter<TsSortState>();
  @Output() public pageSelect = new EventEmitter<number>();
  @Output() public externalRedirect = new EventEmitter<string>();
  @Output() public redirect = new EventEmitter<GoParams>();

  public dataTypes = DataTypeEnum;
  public dataSource: TsTableDataSource<IFlatStageProgressionDetail> = new TsTableDataSource();

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.report?.currentValue) {
      this.dataSource.data = changes.report.currentValue;
      this.changeDetector.detectChanges();
      if (changes.report.isFirstChange) {
        // hack for set table width on full page
        this.changeDetector.detectChanges();
      }
    }
  }

  emitChangeSort(data: TsSortState): void {
    this.sort.emit(data);
  }

  emitSearch({searchQuery}: ISearchQuery): void {
    this.search.emit(searchQuery);
  }

  emitDownload(): void {
    this.download.emit();
  }

  emitPageChange(value: number): void {
    this.pageSelect.emit(value);
  }

  emitRedirect(event: MouseEvent, row: IFlatStageProgressionDetail): void {
    event.stopPropagation();
    if (row.isLead) {
      const url = `${this.crmUrl}/${row.id}`;
      this.externalRedirect.emit(url);
    } else {
      this.redirect.emit({
        routeId: RouteItemEnum.OpportunitySpecificTotalTouches,
        queryParams: {
          oppty: row.id,
          isId: true
        }
      });
    }
  }
}
