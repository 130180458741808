import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InsightsFiltersFeatureKey, InsightsFiltersState as InsightsFiltersState } from './insights-filters.reducer';
import { getAnalyticsGlobalFilters } from '@shared/data-access/global-filters';
import { adsInfluencedRequestFactory, IAdsInfluencedRequest } from '../../interfaces/ads-influenced-request.interface';
import { IInsightsReportSettingsFormConfiguration } from '../../interfaces/insights-report-settings-form-configuration.interface';
import { FormState } from '@util/store-form-in-state';
import { IAnalyticsGlobalFilter, IDateCohort } from '@shared/interfaces';

export const selectInsightsFiltersState = createFeatureSelector<InsightsFiltersState>(InsightsFiltersFeatureKey);

export const getAppliedDateCohort$ = createSelector(
  selectInsightsFiltersState,
  (state: InsightsFiltersState) => state.appliedDateCohort
);

export const getSelectedDateCohort$ = createSelector(
  selectInsightsFiltersState,
  (state: InsightsFiltersState) => state.selectedDateCohort
);

export const getInsightsReportSettingsForm$ = createSelector(
  selectInsightsFiltersState,
  (state) => state.reportSettingsForm
);

export const getInsightsReportFilters$ = createSelector(
  getAnalyticsGlobalFilters,
  getInsightsReportSettingsForm$,
  getSelectedDateCohort$,
  (
    globalFilters: IAnalyticsGlobalFilter[],
   reportSettingsForm: FormState<IInsightsReportSettingsFormConfiguration>,
   cohort: IDateCohort
  ): IAdsInfluencedRequest => {
    const {
      lookbackWindow,
      tactics,
      adImpressionsMin,
      adImpressionsMax,
    } = reportSettingsForm.values;
    const overrides: Partial<IAdsInfluencedRequest> = {
      influenceWindow: +lookbackWindow,
      tacticIds: tactics,
      globalFilters,
      cohort,
    };
    if (adImpressionsMin !== null) {
      overrides.minImpressions = +adImpressionsMin;
    }
    if (adImpressionsMax !== null) {
      overrides.maxImpressions = +adImpressionsMax;
    }
    return adsInfluencedRequestFactory({ overrides });
  }
);
