import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { concatMap, distinctUntilChanged, filter, map, pluck, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { TS_SIDENAV_PLATFORM_SWITCHER_CONTENT } from '@terminus-lib/ui-sidenav';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';

import {
  IMenuCategory,
  IMenuItem,
  IMenuItemWithCategories,
  IMenuItemWithoutCategories,
  IMenuSection,
  INavMenu,
  IReportsAccess,
  IRouteItem,
  IUserMessage,
  IUserProfile,
} from '@shared/interfaces';
import { AdminAccessTypes, RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { INTERNAL_SECTION, SETTINGS_LEGACY } from './nav-menus.data';
import { EnvService } from '@shared/environment';
import * as UserSelectors from '@user/user.selectors';
import { navMenusActions } from './nav-menus.actions';
import * as HubTokenSelectors from '@hub-token';
import * as NotificationMessagesStore from '@notification-messages';
import { notificationMessagesActions } from '@notification-messages';
import * as HubRouterReducer from '@hub-router';
import { CAN_ACCESS } from '@shared/constants';
import { APP_ROUTES } from '@app-routes';
import { NavMenusState } from './nav-menus.reducer';
import { NAV_MENU } from './nav-menus.token';
import { getIsUnifiedLoginEnabled, selectUserProfile } from '@shared/data-access/user-common';
import { findRouteItem } from '@util/helpers';
import { getIsSplitIoFlagOn, treatmentTokens } from '@shared/splitio';

@Injectable()
export class NavMenusEffects {
  constructor(
    private actions$: Actions,
    private store: Store<NavMenusState>,
    private envService: EnvService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[],
    @Inject(NAV_MENU) private navMenuData: INavMenu,
  ) {}

  public getSwitcherContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navMenusActions.GetSwitcherContent),
      concatMap(() => of(null).pipe(withLatestFrom(this.store.pipe(select(getIsUnifiedLoginEnabled))))),
      map(([_, isUnifiedLoginEnabled]: [null, boolean]) => {
        const dataStudioUrl = this.envService.getEnv().DATA_STUDIO_URL;
        const appSwitcherConfig = {
          hub: dataStudioUrl,
          scorecard: this.envService.getEnv().APP_SWITCHER_SCORECARD_URL,
          digitalAds: this.envService.getEnv().APP_SWITCHER_DIGITAL_ADS_URL,
          emailSignatures: this.envService.getEnv().APP_SWITCHER_EMAIL_SIGNATURES_URL,
          chat: this.envService.getEnv().APP_SWITCHER_CHAT_URL,
          web: this.envService.getEnv().APP_SWITCHER_WEB_URL,
          platform: this.envService.getEnv().UNIFIED_LOGIN_URL,
        };

        const mainSwitcherContent: TS_SIDENAV_PLATFORM_SWITCHER_CONTENT = [
          {
            title: this.translateService.instant('shared.appSwitcher.header.accountEngagementHub'),
            children: [
              {
                title: this.translateService.instant('shared.appSwitcher.item.dataStudio'),
                url: appSwitcherConfig.hub,
                iconClasses: 'fas fa-shapes',
              },
              {
                title: this.translateService.instant('shared.appSwitcher.header.measurementStudio'),
                url: appSwitcherConfig.scorecard,
                iconClasses: 'fas fa-chart-pie',
              },
            ],
          },
          {
            title: this.translateService.instant('shared.appSwitcher.header.channels'),
            children: [
              {
                title: this.translateService.instant('shared.appSwitcher.item.adExperiences'),
                url: appSwitcherConfig.digitalAds,
                iconClasses: 'fas fa-clone',
              },
              {
                title: this.translateService.instant('shared.appSwitcher.item.emailExperiences'),
                url: appSwitcherConfig.emailSignatures,
                iconClasses: 'fas fa-paper-plane',
              },
              {
                title: this.translateService.instant('shared.appSwitcher.item.chatExperiences'),
                url: appSwitcherConfig.chat,
                iconClasses: 'fas fa-comment-alt',
              },
              {
                title: this.translateService.instant('shared.appSwitcher.item.webExperiences'),
                url: appSwitcherConfig.web,
                iconClasses: 'fas fa-window-maximize',
              },
            ],
          },
        ];

        const platformSwitcherContent: TS_SIDENAV_PLATFORM_SWITCHER_CONTENT = [
          {
            title: this.translateService.instant('shared.appSwitcher.header.platform'),
            children: [
              {
                title: this.translateService.instant('shared.appSwitcher.header.platform'),
                url: appSwitcherConfig.platform,
                iconClasses: 'fas fa-compress',
              },
            ],
          },
        ];

        if (isUnifiedLoginEnabled) {
          return [...mainSwitcherContent, ...platformSwitcherContent];
        }

        return mainSwitcherContent;
      }),
      map(data => {
        return navMenusActions.SetSwitcherContent({ payload: data });
      }),
    ),
  );

  public updateSelectedRouteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction<HubRouterReducer.HubRouterState>>(ROUTER_NAVIGATION),
      pluck('payload', 'routerState', 'routeId'),
      filter(Boolean),
      distinctUntilChanged(),
      concatLatestFrom(() => this.store.pipe(select(getIsSplitIoFlagOn(treatmentTokens.hubajs_migration)))),
      map(([routeId, isMigratedSettingsEnabled]: [RouteItemEnum, boolean]) => {
        let routeItem: IRouteItem;

        if (routeId) {
          routeItem = this.findRouteItem(routeId, isMigratedSettingsEnabled);
        }

        return navMenusActions.SetSelectedRouteItem({ payload: routeItem });
      }),
    ),
  );

  // TEMPORARY LOGIC TO BE REMOVED ONCE ALL SETTINGS PAGES HAVE BEEN MIGRATED
  public onSplitStatusChange$ = createEffect(
    () =>
      this.store.select(getIsSplitIoFlagOn(treatmentTokens.hubajs_migration)).pipe(
        map(() => navMenusActions.Load()),
      )
  );

  public load$ = createEffect(
    () =>
      ({
        isAuthenticated$ = this.store.pipe(select(UserSelectors.selectIsAuthenticated)),
        reportsAccess$ = this.store.pipe(select(UserSelectors.selectUserProfileReportsAccess)),
        userProfile$ = this.store.pipe(select(selectUserProfile)),
        adminTypes$ = this.store.pipe(select(HubTokenSelectors.getAdminTypesForCurrentUser)),
        // TEMPORARY LOGIC TO BE REMOVED ONCE ALL SETTINGS PAGES HAVE BEEN MIGRATED
        isMigratedSettingsEnabled$ = this.store.pipe(select(getIsSplitIoFlagOn(treatmentTokens.hubajs_migration))),
      } = {}) =>
        this.actions$.pipe(
          ofType(navMenusActions.Load),
          withLatestFrom(isAuthenticated$, reportsAccess$, userProfile$, adminTypes$, isMigratedSettingsEnabled$),
          map(
            ([, isAuthenticated, reportsAccess, userProfile, adminTypes, isMigratedSettingsEnabled]: [
              any,
              boolean,
              IReportsAccess,
              IUserProfile,
              AdminAccessTypes[],
              boolean,
            ]) => {
              if (!isAuthenticated) {
                return navMenusActions.LoadSuccess({ payload: [] });
              }
              const userMenus = this.navMenuData.reduce((acc: INavMenu, section: IMenuSection) => {
                const items = this.getItems(section.items, reportsAccess, userProfile, isMigratedSettingsEnabled);
                if (items.length) {
                  acc.push({
                    ...section,
                    items,
                  });
                }
                return acc;
              }, []);
              // check if the user has permission for this section
              // if there is no permission then just return empty array
              const internalSection = this.getInternalSection(reportsAccess, userProfile, adminTypes, isMigratedSettingsEnabled);
              // add settings menu separately because it could be different depend on feature flag
              const settingMenu = this.getSettingMenu(reportsAccess, userProfile, isMigratedSettingsEnabled);
              // return menu with settings and internalSection
              return navMenusActions.LoadSuccess({
                payload: [...userMenus, ...settingMenu, ...internalSection],
              });
            },
          ),
        ),
  );

  public onLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navMenusActions.LoadSuccess),
      map(() => navMenusActions.GetSwitcherContent()),
    ),
  );

  public routeSet$ = createEffect(
    () =>
      ({ userMessages$ = this.store.pipe(select(NotificationMessagesStore.getNotificationMessages)) } = {}) =>
        this.actions$.pipe(
          ofType(navMenusActions.SetSpecificReportLink),
          withLatestFrom(userMessages$),
          filter(([, userMessages]: [any, IUserMessage[]]) => !!userMessages.length),
          map(() => notificationMessagesActions.clearAllMessages()),
        ),
  );

  private getItems(
    items: IMenuItem[],
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    isMigratedSettingsEnabled: boolean,
  ): IMenuItem[] {
    return items.reduce((acc: IMenuItem[], item: IMenuItem) => {
      if ((item as IMenuItemWithCategories).categories) {
        // get route items for each category
        const categories = this.getCategories((item as IMenuItemWithCategories).categories, reportsAccess, userProfile, isMigratedSettingsEnabled);
        // if category has at least 1 route item
        if (categories.length) {
          acc.push({
            ...item,
            categories,
          });
        }
      } else if ((item as IMenuItemWithoutCategories).routeId) {
        // otherwise just get route item for current item using routeID
        const routeItem = this.getRoute(
          (item as IMenuItemWithoutCategories).routeId,
          reportsAccess,
          userProfile,
          isMigratedSettingsEnabled,
        );
        // there could be a case when the user doesn't have permission for this route
        if (routeItem) {
          acc.push({
            ...item,
            routeItem,
          });
        }
      } else {
        // Popout item (without categories and routeID)
        acc.push(item);
      }

      return acc;
    }, []);
  }

  // Get categories with route items
  // each route item should be filtered
  private getCategories(
    categories: IMenuCategory[],
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    isMigratedSettingsEnabled: boolean,
  ): IMenuCategory[] {
    return categories.reduce((acc: IMenuCategory[], category: IMenuCategory) => {
      if (category.routeIds?.length) {
        const routeItems = this.getCategoryRouteItems(category.routeIds, reportsAccess, userProfile, isMigratedSettingsEnabled);
        // push category to the menu even if there is no route items there
        // in this case we have to show lean more link
        acc.push({
          ...category,
          routeItems,
        });
      }

      return acc;
    }, []);
  }

  private getInternalSection(
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    adminTypes: AdminAccessTypes[],
    isMigratedSettingsEnabled: boolean,
  ): IMenuSection[] {
    const haveAccess = adminTypes.some(type => INTERNAL_SECTION.permission.includes(type));

    if (haveAccess) {
      const items = this.getItems(INTERNAL_SECTION.items, reportsAccess, userProfile, isMigratedSettingsEnabled);

      return [
        {
          ...INTERNAL_SECTION,
          items,
        },
      ];
    }

    return [];
  }

  // Get route items which are available for current user
  private getCategoryRouteItems(
    routeIds: RouteItemEnum[],
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    isMigratedSettingsEnabled: boolean,
  ): IRouteItem[] {
    return routeIds.reduce((acc: IRouteItem[], routeId: RouteItemEnum) => {
      const route = this.getRoute(routeId, reportsAccess, userProfile, isMigratedSettingsEnabled);
      if (route) {
        acc.push(route);
      }
      return acc;
    }, []);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  // todo: consider to remove unused param
  private getRoute(
    routeId: RouteItemEnum,
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    isMigratedSettingsEnabled: boolean,
  ): IRouteItem {
    const flags = userProfile.featureFlags;
    // find all routes with this routeId. It could be several routes because of migrated feature
    const routes = this.applicationRoutes.filter(item => item.routeId === routeId);
    if (routes.length === 0) {
      return null;
    }

    // legacy or internal routes
    if (routes.length === 1) {
      if (routes[0].alwaysAdd) {
        return routes[0]; // always add this route to nav menu
      }
      // NOTE: there were 2 route ids for Attribution by Campaign type. AnalyzeAttributionByCampaignType became deprecated
      // but we still have orgs with this route id. That's why we need to check if AttributionByCampaignType
      // or AnalyzeAttributionByCampaignType available in order to show this report
      if (routeId === RouteItemEnum.AttributionByCampaignType) {
        return reportsAccess[RouteItemEnum.AttributionByCampaignType] ||
          reportsAccess[RouteItemEnum.AnalyzeAttributionByCampaignType]
          ? routes[0]
          : null;
      }

      if (reportsAccess[routeId]) {
        return routes[0]; // add if there is access to this report
      }
    }
    // legacy and internal routes
    if (routes.length > 1) {
      let selectedRoute: IRouteItem;

      // if feature flag is enabled then we can use terminus hub route otherwise
      // we have to use legacy route
      if (
        (flags[routes[0].featureFlag] && flags[routes[0].featureFlag].state === CAN_ACCESS) ||
        isMigratedSettingsEnabled // TEMPORARY LOGIC TO BE REMOVED ONCE ALL SETTINGS PAGES HAVE BEEN MIGRATED
      ) {
        selectedRoute = routes.find(
          route => route.routeType === RouteTypeEnum.InternalLink || route.routeType === RouteTypeEnum.ExternalLink,
        );
      } else {
        selectedRoute = routes.find(route => route.routeType === RouteTypeEnum.LegacyLink);
      }

      if (selectedRoute.alwaysAdd) {
        return selectedRoute; // always add this route to nav menu
      }

      /**
       * NOTE FROM hub-ajs: Adjust Filters for users that have report access that need to appear in different sections of url and ui
       * i.e. 'discover/revenue-and-pipeline/marketing-impact' -> 'analyze/revenue-and-pipeline/marketing-impact'
       */
      const selectedRouteId =
        routeId === RouteItemEnum.AnalyzeMarketingImpact ? RouteItemEnum.MarketingImpact : routeId;

      return reportsAccess[selectedRouteId] ? selectedRoute : null;
    }

    return null;
  }

  private getSettingMenu(
    reportsAccess: IReportsAccess,
    userProfile: IUserProfile,
    isMigratedSettingsEnabled: boolean,
  ): IMenuSection[] {
    const items = this.getItems(SETTINGS_LEGACY.items, reportsAccess, userProfile, isMigratedSettingsEnabled);
    // if items is available then return nav menu with route info
    if (items.length) {
      return [
        {
          ...SETTINGS_LEGACY,
          items,
        },
      ];
    }

    return [];
  }

  // to keep it testable
  public findRouteItem(routeId: RouteItemEnum, isEnabled: boolean): IRouteItem | null {
    return findRouteItem(routeId, isEnabled, this.applicationRoutes);
  }
}
