import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable()
export class PortalService {
  private readonly targets: Map<string, ViewContainerRef>;
  private readonly contents: Map<string, TemplateRef<unknown>>;

  constructor() {
    this.targets = new Map<string, ViewContainerRef>();
    this.contents = new Map<string, TemplateRef<unknown>>();
  }

  addTarget(targetName: string, container: ViewContainerRef): void {
    this.targets.set(targetName, container);
    if (this.contents.has(targetName)) {
      this.attach(targetName, this.contents.get(targetName) as TemplateRef<unknown>);
    }
  }

  clear(targetName: string): void {
    this.getTarget(targetName)?.clear();
    this.contents.delete(targetName);
  }

  attach(targetName: string, template: TemplateRef<unknown>): void {
    this.contents.set(targetName, template);
    this.getTarget(targetName)?.createEmbeddedView(template);
  }

  private getTarget(targetName: string): ViewContainerRef | null | undefined {
    return this.targets.has(targetName) ? this.targets.get(targetName) : null;
  }
}
