/**
 * Bootstraps environment vars from built-in object and a
 * dynamic fetch of /environment.json for production builds.
 *
 * Overrides can be passed in for local testing when loading
 * a production /environment.json
 *
 * Note: This code warns of any discrepancies between the two.
 * Use ensureEnvironmentValidity
 *
 * @param builtinNinjaEnvJson object that defines the expected shape of environment.json.
 *      This should come from a direct import of environment/environment.json in the consuming app
 *
 * @param isProductionConfiguration boolean for whether this is a production configuration build.
 *      If true, a call for /environment.json happens and is used
 *      If false, no call is made, and builtin argument is used
 *
 * @param overrides local override of env values for testing.
 *      Note: DO NOT commit code that uses this override. It's a blunt tool and circumvents our
 *      environment settings tracking done through our *-infra repos!
 *
 * @returns Resolved with the finalized environment object, or rejected with errors.
 */
export function bootstrapEnvironmentVars<T>(
  builtinNinjaEnvJson: T,
  isProductionConfiguration: boolean,
  overrides: Partial<T>={}
){
  // Note: We are returning a promise here instead
  // of using async/await here so that we can choose
  // to neither resolve or reject the promise
  // in the case of a /health-check request.
  return new Promise( (resolve, reject) => {

    overrides = {
      production: isProductionConfiguration,
      ...overrides,
    }

    // If we aren't a production build,
    // just use the compiled-in (ninja) env vars with optional overrides
    if (!isProductionConfiguration) {
      console.warn("?health-check endpoint does not work in development builds, as there is no runtime environment.json to compare to.")
      resolve({...builtinNinjaEnvJson, ...overrides});
      return
    }

    // We're a production build, so grab /environment.json
    // as json, bubbling up any errors
    // Note: appending ?date=`Date.now()` here to cache-bust older nginx-based
    // deployments. Once all apps are deployed on S3, this will no longer be
    // needed
    fetch(`/environment.json?date=${Date.now()}`)
      .then( res => res.ok ? res.json() : reject(res.statusText) )
      .then( runTimeEnvJson => {
        if(!runTimeEnvJson) return; // if the promise was rejected above, this will be undefined

        // combine the fetched env vars with optional overrides
        const finalEnv = {...runTimeEnvJson, ...overrides};

        // validate the final environment shape
        const envErrors = ensureEnvShape(finalEnv, builtinNinjaEnvJson);
        if (document.location.search === '?health-check') {
          // if doing a /health-check, don't resolve the bootstrapEnvironmentVars Promise,
          // instead, just write the health status to the page
          writeHealthToPage(envErrors)
        } else {
          // Not doing a health check, resolve the bootstrapEnvironmentVars Promise
          // so the app can bootstrap. Any Health check issues will have been
          // logged to the console as errors
          resolve(finalEnv);
        }
      });
  })

}

/**
 * Object that wraps up the data needed to
 * compare and report ENV health check errors
 */
interface HealthCheckError {
  key: string
  expectedType: string
  foundType: string
}

/**
 * Writes health-check status to the document.
 * This should only be called if /health-check is triggered.
 * Otherwise, we allow the app to bootstrap and just write
 * any errors to the JS console.
 */
export function writeHealthToPage(errors: false | HealthCheckError[]) {
  const e = document.createElement('div');
  if (errors) {
    e.innerHTML = 'Health: <span id="status">ERROR</span><br /><div id="errors">' 
      + errors.map( ({key, expectedType, foundType}) => 
        `<span>Expected to find environment key [${key}] of type [${expectedType}] but found type [${foundType}] instead.</span>`
      ).join('<br />')
      + "</div>";
  } else {
    e.innerHTML = 'Health: <span id="status">OK</span>';
  }
  document.body.append(e);
}

/**
 * Compare an environment object with one that represents
 * the expected shape (keys and value types).
 * Warns to the console of differences and returns a list
 * of them to the caller.
 *
 * @param env Environment object to validate
 * @param expectedShape Environment object use compare to
 * @returns false if no errors found, otherwise list of issues found
 */
export function ensureEnvShape(env, expectedShape): false | HealthCheckError[] {
  const issues = Object.entries(expectedShape)
          .map(([key, val]) => ({
            key,
            expectedType: typeof val,
            foundType: typeof env[key]
          } as HealthCheckError))
          .filter(({expectedType, foundType})=> expectedType !== foundType)
  // log issues to console
  issues.forEach(({key, expectedType, foundType}) =>
          console.error(`Expected to find environment key [${key}] of type [${expectedType}] but found type [${foundType}] instead.`)
        )
  return issues.length > 0 && issues;
}
