import { IFactoryParams } from '@shared/interfaces';

export interface IOpportunityItem {
    name: string;
    accountName: string;
    owner: string;
    revenue: number;
    revenue2: number;
    configuredStage: string;
    createdDate: string;
    closeDate: string;
    otdVelocity: string;
    sourceCampaignName: string;
    sourceCampaignGroup: string;
    lastCampaignName: string;
    lastTouchCampaignGroup: string;
    uniqueCampaigns: number;
    opptyContactCount: number;
    accountContactCount: number;
    contactRolesEngaged: number;
    contactCountEngaged: number;
    leadSource: string;
    preCreateAttributions: number;
    postCreateAttributions: number;
    campaignAttributions: number;
    type: string;
    id: string;
    accountId: string;
    sourceCampaignId: string;
    lastCampaignId: string;
    ownerId: string;
    companyScore: string;
    intentScore: string;
    firstEmail: string;
    lastEmail: string;
}

export function opportunityItemFactory({
     n = 0,
     overrides = {},
}: IFactoryParams<IOpportunityItem, number, boolean> = {}): IOpportunityItem {
    return {
        name: 'Opportunity - ${n}',
        accountName: `Account ${n}`,
        owner: 'Jim Wharton',
        revenue: n * 2000,
        revenue2: n * 150,
        configuredStage: 'Closed Won',
        createdDate: '2017-02-21T22:32:34Z',
        closeDate: '2016-08-24T22:59:59Z',
        otdVelocity: '-181',
        sourceCampaignName: 'Happy Hour 5 Finance',
        sourceCampaignGroup: 'hosted event',
        lastCampaignName: 'Tradeshow 4 SMX East',
        lastTouchCampaignGroup: 'tradeshow',
        uniqueCampaigns: 7,
        opptyContactCount: 0,
        accountContactCount: n + 10,
        contactRolesEngaged: 0,
        contactCountEngaged: n + 5,
        leadSource: 'Sales Generated',
        preCreateAttributions: 11,
        postCreateAttributions: 0,
        campaignAttributions: 11,
        type: 'Existing Customer - Subscription Add on',
        id: `${n}`,
        accountId: `${n * 10}5iJAAS`,
        sourceCampaignId: `${n * 11}5iJAAS`,
        lastCampaignId: `${n * 12}5iJAAS`,
        ownerId: `${n * 13}5iJAAS`,
        companyScore: null,
        intentScore: null,
        firstEmail: null,
        lastEmail: null,
        ...overrides
    };
}
// object with camel-case and snake-case field names
export const opportunityItemFieldAdapter: Record<string, string> = {
    name: 'name',
    accountName: 'account_name',
    owner: 'owner',
    revenue: 'revenue',
    revenue2: 'revenue2',
    configuredStage: 'configured_stage',
    createdDate: 'created_date',
    closeDate: 'close_date',
    otdVelocity: 'otd_velocity',
    sourceCampaignName: 'source_campaign_name',
    sourceCampaignGroup: 'source_campaign_group',
    lastCampaignName: 'last_campaign_name',
    lastTouchCampaignGroup: 'last_touch_campaign_group',
    lastIntentDate: 'last_intent_date',
    intentTopics: 'intent_topics',
    uniqueCampaigns: 'unique_campaigns',
    opptyContactCount: 'oppty_contact_count',
    accountContactCount: 'account_contact_count',
    contactRolesEngaged: 'contact_roles_engaged',
    contactCountEngaged: 'contact_count_engaged',
    leadSource: 'lead_source',
    preCreateAttributions: 'pre_create_attributions',
    postCreateAttributions: 'post_create_attributions',
    campaignAttributions: 'campaign_attributions',
    type: 'type',
    id: 'id',
    accountId: 'account_id',
    sourceCampaignId: 'source_campaign_id',
    lastCampaignId: 'last_campaign_id',
    ownerId: 'owner_id',
    companyScore: 'company_score',
    intentScore: 'intent_score',
    firstEmail: 'first_email',
    lastEmail: 'last_email',
};
