<button
  class="c-icon-button"
  [attr.aria-label]="actionName"
  [attr.type]="buttonType"
  [disabled]="isDisabled"
  tabindex="{{ tabIndex }}"
  (click)="clicked.emit($event)"
>
  <ts-icon [icon]="icon"></ts-icon>
</button>
