import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TsButtonModule } from '@terminus-lib/ui-button';
import { TranslateModule } from '@ngx-translate/core';
import { TsRadioGroupModule } from '@terminus-lib/ui-radio-group';
import { TsLinkModule } from '@terminus-lib/ui-link';
import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';

import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { TileModalComponent } from './containers/tile-modal.component';
import { SelectTileTypeComponent } from './components/select-tile-type/select-tile-type.component';
import { VisualizationTypesPreviewModule } from './components/visualization-types-preview/visualization-types-preview.component';
import { TileSettingsFormModule } from './components/tile-settings-form/tile-settings-form.component';
import { StepsNavigationModule } from './components/steps-navigation/steps-navigation.component';
import { AlertModule } from '@ui/components/alert';
import { SelectDashboardModule } from './components/select-dashboard/select-dashboard.component';
import { PreviewHeaderComponent } from './components/preview-header/preview-header.component';
import { TileModule } from '@shared/features/tile';
import { TileModalService } from './services/tile-modal.service';
import { ITileModalData, tileModalDataToken } from '@shared/data-access/tile-modal';
import { FakeTileModule } from './components/fake-tile/fake-tile.component';

@NgModule({
  declarations: [
    TileModalComponent,
    SelectTileTypeComponent,
    PreviewHeaderComponent,
  ],
  exports: [TileModalComponent],
  entryComponents: [TileModalComponent],
  imports: [
    CommonModule,
    TsButtonModule,
    TranslateModule,
    TsRadioGroupModule,
    FormsModule,
    VisualizationTypesPreviewModule,
    TileSettingsFormModule,
    ReactiveFormsModule,
    SharedUiPipesMemoizeModule,
    StepsNavigationModule,
    AlertModule,
    TsLinkModule,
    SelectDashboardModule,
    TileModule,
    TsLoadingOverlayModule,
    FakeTileModule
  ],
  providers: [
    TileModalService
  ]
})
export class TileModalModule {
  static forRoot(tileModalDataService: Type<ITileModalData>): ModuleWithProviders<TileModalModule> {
    return {
      ngModule: TileModalModule,
      providers: [{
        provide: tileModalDataToken,
        useClass: tileModalDataService,
      }]
    };
  }
}
