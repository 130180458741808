import { IEditTableColumn, IReportColumn } from '@shared/interfaces';

export const updateColumnsVisibility = <T extends IReportColumn>(columns: T[], payload: IEditTableColumn): T[] => columns.map(column => {
    if (payload.name === column.name) {
        return {
            ...column,
            visible: payload.isVisible
        };
    }
    return column;
});
