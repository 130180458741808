/* istanbul ignore file */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { IChartConfig, IDashboardTile, ITileGaugeDataItem } from '@shared/interfaces';
import { DataTypeEnum, GaugeChartColors, GaugeChartFields } from '@shared/enums';
import { ChartConfigClass } from '../chart-config.class';
import { formatDataByDataType } from '@shared/util/data-type';

@Injectable()
export class GaugeChartConfigService implements ChartConfigClass {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  getConfig(_tile: IDashboardTile, data: ITileGaugeDataItem): IChartConfig {
    const locale = this.locale;
    // validate the value
    const validValue = data.value > data[GaugeChartFields.Maximum] // if more than max
      ? data[GaugeChartFields.Maximum] // then use max as value
      : data.value < data[GaugeChartFields.Minimum] // if less than min
        ? data[GaugeChartFields.Minimum] // then use min as value
        : data.value; // otherwise use value from the BE
    // create ranges with color
    const gaugeRanges = [
      {
        color: GaugeChartColors.Min,
        min: data[GaugeChartFields.Minimum],
        max: data[GaugeChartFields.Midpoint],
      },
      {
        color: GaugeChartColors.Mid,
        min: data[GaugeChartFields.Midpoint],
        max: data[GaugeChartFields.Goal],
      },
      {
        color: GaugeChartColors.Max,
        min: data[GaugeChartFields.Goal],
        max: data[GaugeChartFields.Maximum],
      }
    ];
    const jsonRanges = gaugeRanges.map(range => ({
      axisFill: {
        fill: range.color,
        fillOpacity: 1,
        zIndex: -1
      },
      label: {
        radius: 5,
        text: range.min.toString(),
        adapter: {
          text(text: string): string {
            return formatDataByDataType(text, DataTypeEnum.Currency, locale, 1);
          }
        }
      },
      value: range.min,
      endValue: range.max,
    }));
    return {
      type: 'GaugeChart',
      innerRadius: -30,
      hands: [{
        value: validValue,
        startWidth: 10,
        endWidth: 1,
        radius: 80,
        fill: '#3D4D54',
        strokeWidth: 0,
        pin: {
          radius: 5
        }
      }],
      xAxes: [{
        type: 'ValueAxis',
        min: data[GaugeChartFields.Minimum],
        max: data[GaugeChartFields.Maximum],
        strictMinMax: true,
        renderer: {
          grid: {
            disabled: true
          },
          labels: {
            disabled: true
          }
        },
        axisRanges: [
          ...jsonRanges,
          {
            label: {
              radius: 5,
              text: data[GaugeChartFields.Maximum].toString(),
              adapter: {
                text(text: string): string {
                  return formatDataByDataType(text, DataTypeEnum.Currency, locale, 1);
                }
              }
            },
            value: data[GaugeChartFields.Maximum],
          }
        ]
      }],
    };
  }
}
