<div class="saved-reports-container">
  <div class="saved-reports-content">
    <!-- ng content for tab's header -->
    <ng-content></ng-content>

    <ts-button
      actionName="Create Folder"
      theme="secondary"
      buttonType="button"
      class="create-button"
      [isDisabled]="isLoading"
      (clicked)="openFolderDialog()">
      {{ 'features.savedReports.createFolder.title' | translate }}
    </ts-button>

    <tsh-saved-reports-list
      (folderDialog)="openFolderDialog($event)"
      (reportDialog)="openReportDialog($event)"
      (toggleFolderState)="toggleFolderState($event)"
      (showReport)="redirectToReport($event)"
      [savedReports]="savedReports">
    </tsh-saved-reports-list>
  </div>

  <div class="saved-reports-footer">
    <ts-button
      actionName="Save Report"
      theme="default"
      buttonType="button"
      [isDisabled]="isLoading"
      (clicked)="openReportDialog()">
      {{ (savedReports?.length ? 'features.savedReports.reportCreate' : 'features.savedReports.saveReport') | translate }}
    </ts-button>
  </div>
</div>
