import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IDateCohortResponse } from '@shared/interfaces';
import { IDateCohortsSource } from './date-cohorts-source.interface';

export enum DateCohortsSourceURLs {
  GetDateCohorts = '/v1/get_date_cohorts',
}

@Injectable({
  providedIn: 'root'
})
export class DateCohortsSource extends HttpClientBase implements IDateCohortsSource {

  getDateCohorts$(): Observable<IDateCohortResponse> {
    const endpoint = this.endpoint(DateCohortsSourceURLs.GetDateCohorts);

    return this.httpClient.post<IDateCohortResponse>(endpoint, {});
  }
}
