export enum NavSectionTypes {
    Primary = 0,
    Secondary = 1,
    Internal = 2
}

export enum NavPanelTypes {
    Drawer = 1,
    Popout = 2,
    Basic = 3,
}
