import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';
import { ScorecardSource } from '../../sources/scorecard/scorecard.source';
import { IScorecardList } from '../../overview/interfaces/scorecard-list.interface';
import { IScorecardListSummary } from '../../overview/interfaces/scorecard-list-summary.interface';
import { IListDetailsResponse } from '../../account-list-details/interfaces/account-list-details.interface';
import { IListDetailsFilters, IListDetailsRequestParams } from '../../account-list-details/interfaces/list-details-params.interface';
import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { LastFull, ToDate } from '@shared/enums';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';
import { IOverviewFilters, IOverviewRequestParams } from '../../overview/interfaces/overview-filters.interface';
import { VALID_AUDIENCE_TYPES, VALID_OVERVIEW_VIEW_TYPES } from '../../overview/overview.constants';
import { OverviewViewTypeEnum } from '../../overview/enums/overview-view-type.enum';
import { IAppliedGlobalFiltersAsParams } from '@shared/interfaces';
import { OverviewAudienceEnum } from '../../overview/enums/overview-audience.enum';

@Injectable({providedIn: 'root'})
export class ScorecardService {

  constructor(
    private source: ScorecardSource,
    private retry: RetryWithEscalation,
    private envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCanCreateAggregate$(): Observable<boolean> {
    return regenerateOnRetry(() => this.source.getCanCreateAggregate$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getListData$(params: IOverviewRequestParams): Observable<IScorecardList[]> {
    return regenerateOnRetry(() => this.source.getListData$(params)
      .pipe(this.retry.retryWithEscalation(hubTokenName))
    );
  }

  downloadListData$(params: IOverviewRequestParams): Observable<string> {
    return regenerateOnRetry(() => this.source.downloadListData$(params)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getListDetailsData$(params: IListDetailsRequestParams, globalFiltersParams: IAppliedGlobalFiltersAsParams): Observable<IListDetailsResponse> {
    return regenerateOnRetry(() => this.source.getListDetailsData$(params, globalFiltersParams)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  downloadListDetailsData$(params: IListDetailsRequestParams, globalFiltersParams: IAppliedGlobalFiltersAsParams): Observable<string> {
    return regenerateOnRetry(() => this.source.downloadListDetailsData$(params, globalFiltersParams)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getListSummaryData$(params: IOverviewRequestParams): Observable<IScorecardListSummary> {
    return regenerateOnRetry(() => this.source.getListSummaryData$(params)
      .pipe(this.retry.retryWithEscalation(hubTokenName))
    );
  }

  getAccountListDetailsFilters(params: Params): IListDetailsFilters {
    const cohort = replaceLegacyCohort(params.cohort) || ToDate.QUARTER_TO_DATE;

    return {
      aggregateId: params.aggregateId,
      listId: params.listId,
      audience: params.audience,
      cohort,
      ...getStartAndEndDateFromParams(cohort, params.startDate, params.endDate)
    };
  }

  getOverviewFilters(params: Params): IOverviewFilters {
    const cohort = replaceLegacyCohort(params.cohort) || LastFull.LAST_FULL_QUARTER;
    const scorecardAggregateIds = params?.scorecardAggregateIds?.length
      ? (Array.isArray(params.scorecardAggregateIds)
        ? params.scorecardAggregateIds
        : params.scorecardAggregateIds.split(',')).map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
      : [];
      const scorecardSegmentIds = params?.scorecardSegmentIds?.length
      ? (Array.isArray(params.scorecardSegmentIds)
        ? params.scorecardSegmentIds
        : params.scorecardSegmentIds.split(',')).map((id: string) => +id).filter((id: number) => !Number.isNaN(id))
      : [];

    return {
      viewType: params.viewType && VALID_OVERVIEW_VIEW_TYPES.includes(params.viewType)
        ? params.viewType : OverviewViewTypeEnum.Account,
      audience: params.audience && VALID_AUDIENCE_TYPES.includes(params.audience)
      ? params.audience : OverviewAudienceEnum.AccountLists,
      scorecardAggregateIds,
      scorecardSegmentIds,
      cohort,
      ...getStartAndEndDateFromParams(cohort, params.startDate, params.endDate)
    };
  }
}
