import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { GlobalFiltersService } from '../service/global-filters.service';
import {
  IAppliedGlobalFiltersAsParams,
  IDataFilter,
  IFilter,
  IFilterDeleteRequest,
  IFilterSaveRequest,
  IGlobalFilterFolder,
  IGlobalFilterFolderItem,
  IGlobalFilters,
  ITargetFolderItem,
  userMessageFactory,
} from '@shared/interfaces';
import { notificationMessagesActions } from '@notification-messages';
import { globalFiltersActions } from './global-filters.actions';
import * as selectors from './global-filters.selectors';
import { GlobalFiltersKeys } from '@shared/enums';
import {
  getAllGlobalFiltersFromParams,
  mapAccountListMenuToListIds,
  mapFilterMenuToDataFilter,
  selectAllItemsInFolder,
  selectTargetItemInFolder,
} from '@util/helpers';
import { EMPTY_GLOBAL_FILTERS_STATE } from '@shared/constants';
import { ListUserDefinedSegmentsRequest, SegmentsApi } from '@shared/data-access/segments-api';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Injectable()
export class GlobalFiltersEffects {
  constructor(
    public store: Store,
    public actions$: Actions,
    public globalFiltersService: GlobalFiltersService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private SegmentsApi: SegmentsApi,
  ) {}

  public onApplyDataFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.applySelectedFilters),
      concatLatestFrom(() => [
        this.store.pipe(select(selectors.getSelectedDataFilter)),
        this.store.pipe(select(selectors.getMappedSelectedFiltersMenu)),
        this.store.pipe(select(selectors.getDataFilterListIds)),
      ]),
      map(([_, dataFilter, selectedFilters, listIds]: [null, IDataFilter, IFilter, string[]]) =>
        globalFiltersActions.updateAppliedDataFilter({ filter: { ...dataFilter, filters: selectedFilters, listIds } }),
      ),
    ),
  );

  // After updating global filter we have to update query params for the current route
  // The feature report updates its data using route's subscription
  public onUpdateAppliedFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.updateAppliedDataFilter),
      concatLatestFrom(() => this.store.pipe(select(selectors.getAppliedGlobalFiltersAsParams))),
      switchMap(([_, globalFilters]: [null, IAppliedGlobalFiltersAsParams]) => {
        // NOTE: delete all global filters query params from the route to update route with latest params
        const queryParams = Object.keys(this.route.snapshot.queryParams).reduce((acc, key) => {
          if (key !== 'al' && key.indexOf('filter') !== 0 && key !== 'sg') {
            acc[key] = this.route.snapshot.queryParams[key];
          } else {
            acc[key] = null; // NOTE: it's important to reset global filters to rewrite query params
          }

          return acc;
        }, {});

        return this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { ...queryParams, ...globalFilters },
            replaceUrl: true,
            queryParamsHandling: 'merge',
            skipLocationChange: false,
          })
          .then(() => globalFiltersActions.updateAppliedDataFilterSuccess());
      }),
    ),
  );

  public onClearDataFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.clearAppliedAndSelectedDataFilter),
      mergeMap(() => [
        globalFiltersActions.updateSelectedDataFilter({ filter: EMPTY_GLOBAL_FILTERS_STATE }),
        globalFiltersActions.applySelectedFilters(),
      ]),
    ),
  );

  public onGetGlobalFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.getDataGlobalFilters),
      switchMap(() => {
        return combineLatest([
          this.globalFiltersService.getGlobalFiltersData$(),
          this.SegmentsApi.getAllSegments$({ query: {} }).pipe(map(segments => (segments.length ? segments : []))),
        ]).pipe(
          mergeMap(([filters, segments]) => {
            if (segments.length) {
              return [
                globalFiltersActions.segmentsLoadedSuccess({ queries: segments }),
                globalFiltersActions.getDataGlobalFiltersSuccess({
                  filters: {
                    ...filters,
                    segments: [
                      { name: 'segments', number: 'segments', data: segments.map(segment => segment.lqsSharedQueryId) },
                    ],
                  },
                }),
              ];
            } else {
              return [
                globalFiltersActions.getDataGlobalFiltersSuccess({
                  filters: { ...filters },
                }),
              ];
            }
          }),
          catchError((error: HttpErrorResponse) =>
            this.translate
              .get('shared.globalFilters.ngrx.effects.onGetGlobalFilters.errorMessage', { status: error.status })
              .pipe(map(message => globalFiltersActions.getDataGlobalFiltersFailure({ message }))),
          ),
        );
      }),
    ),
  );

  public onGetGlobalFiltersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.getDataGlobalFiltersSuccess),
      map(() => globalFiltersActions.syncGlobalFilters(getAllGlobalFiltersFromParams(this.route.snapshot.queryParams))),
    ),
  );

  public onGetSavedDataFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.getSavedFiltersData),
      switchMap(() => {
        return this.globalFiltersService.getSavedDataFilters$().pipe(
          map((filters: IDataFilter[]) => globalFiltersActions.getSavedFiltersDataSuccess({ filters })),
          catchError((error: HttpErrorResponse) =>
            this.translate
              .get('shared.globalFilters.ngrx.effects.onGetSavedDataFilters.errorMessage', { status: error.status })
              .pipe(map(message => globalFiltersActions.getSavedFiltersDataFailure({ message }))),
          ),
        );
      }),
    ),
  );

  public onSaveDataFilterSubmit$ = createEffect(
    () =>
      ({ getAppliedDataFilter$ = this.store.pipe(select(selectors.getAppliedDataFilter)) } = {}) =>
        this.actions$.pipe(
          ofType(globalFiltersActions.submitSaveFilters),
          withLatestFrom(getAppliedDataFilter$),
          switchMap(([_, filter]) => {
            return this.globalFiltersService
              .updateSavedDataFilter$(this.toFilterSaveRequest(filter as IDataFilter))
              .pipe(
                mergeMap(() => [
                  globalFiltersActions.getSavedFiltersData(),
                  globalFiltersActions.submitSaveFiltersSuccess(),
                ]),
                catchError((error: HttpErrorResponse) =>
                  this.translate
                    .get('shared.globalFilters.ngrx.effects.onSaveDataFilterSubmit.errorMessage', {
                      status: error.status,
                    })
                    .pipe(map(message => globalFiltersActions.submitSaveFiltersFailure({ message }))),
                ),
              );
          }),
        ),
  );

  public onDeleteSavedFilters$ = createEffect(
    () =>
      ({ getSelectedDataFilter$ = this.store.pipe(select(selectors.getSelectedDataFilter)) } = {}) =>
        this.actions$.pipe(
          ofType(globalFiltersActions.submitDeleteSavedFilters),
          withLatestFrom(getSelectedDataFilter$),
          switchMap(([_, filter]) => {
            return this.globalFiltersService
              .deleteSavedDataFilter$(this.toFilterDeleteRequest(filter as IDataFilter))
              .pipe(
                mergeMap(() => [
                  globalFiltersActions.submitDeleteSavedFiltersSuccess(),
                  globalFiltersActions.getSavedFiltersData(),
                  globalFiltersActions.updateSelectedDataFilter({ filter: EMPTY_GLOBAL_FILTERS_STATE }),
                ]),
                catchError((error: HttpErrorResponse) =>
                  this.translate
                    .get('shared.globalFilters.ngrx.effects.onDeleteSavedFilters.errorMessage', {
                      status: error.status,
                    })
                    .pipe(map(message => globalFiltersActions.submitDeleteSavedFiltersFailure({ message }))),
                ),
              );
          }),
        ),
  );

  public onToggleFolderSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(globalFiltersActions.toggleFolderSelect),
      pluck('folder'),
      concatLatestFrom(() => [
        this.store.pipe(select(selectors.getGlobalFilterMenuBase)),
        this.store.pipe(select(selectors.getSelectedDataFilter)),
        this.store.pipe(select(selectors.getAccountListMenuBase)),
      ]),
      map(
        ([selectedFolder, uiState, selectedDataFilter, accountListMenu]: [
          ITargetFolderItem,
          IGlobalFilterFolder,
          IDataFilter,
          IGlobalFilterFolderItem,
        ]) => {
          const { folderName, folderItem } = selectedFolder;
          if (folderName !== GlobalFiltersKeys.AccountList) {
            const updatedState = {
              ...uiState,
              [folderName]: {
                ...selectAllItemsInFolder(uiState[folderName], folderItem),
              },
            };
            const updatedDataFilter = mapFilterMenuToDataFilter(updatedState);
            return globalFiltersActions.updateSelectedDataFilter({
              filter: { ...selectedDataFilter, filters: updatedDataFilter },
            });
          }

          const updatedAccountListMenu = selectAllItemsInFolder(accountListMenu, folderItem);
          const updatedListIds = mapAccountListMenuToListIds(updatedAccountListMenu);
          return globalFiltersActions.updateSelectedDataFilter({
            filter: { ...selectedDataFilter, listIds: updatedListIds },
          });
        },
      ),
    ),
  );

  public onToggleFolderItemSelect$ = createEffect(
    () =>
      ({
        getFilterMenuBase$ = this.store.pipe(select(selectors.getGlobalFilterMenuBase)),
        getSelectedDataFilter$ = this.store.pipe(select(selectors.getSelectedDataFilter)),
        getAccountListMenuBase$ = this.store.pipe(select(selectors.getAccountListMenuBase)),
      } = {}) =>
        this.actions$.pipe(
          ofType(globalFiltersActions.toggleFolderItemSelect),
          map(selectedFolderItem => selectedFolderItem.folder),
          withLatestFrom(getFilterMenuBase$, getSelectedDataFilter$, getAccountListMenuBase$),
          map(
            ([folder, uiState, selectedDataFilter, accountListMenu]: [
              ITargetFolderItem,
              IGlobalFilterFolder,
              IDataFilter,
              IGlobalFilterFolderItem,
            ]) => {
              const { folderName, folderItem, folderSelectKey } = folder;

              switch (folderName) {
                case GlobalFiltersKeys.AccountList: {
                  const updatedAccountListMenu = selectTargetItemInFolder(accountListMenu, folderItem, folderSelectKey);
                  const updatedListIds = mapAccountListMenuToListIds(updatedAccountListMenu);
                  return globalFiltersActions.updateSelectedDataFilter({
                    filter: { ...selectedDataFilter, listIds: updatedListIds },
                  });
                }
                default: {
                  const updatedState = {
                    ...uiState,
                    [folderName]: {
                      ...selectTargetItemInFolder(uiState[folderName], folderItem, folderSelectKey),
                    },
                  };
                  const updatedDataFilter = mapFilterMenuToDataFilter(updatedState);
                  return globalFiltersActions.updateSelectedDataFilter({
                    filter: { ...selectedDataFilter, filters: updatedDataFilter },
                  });
                }
              }
            },
          ),
        ),
  );

  public failure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        globalFiltersActions.getDataGlobalFiltersFailure,
        globalFiltersActions.getSavedFiltersDataFailure,
        globalFiltersActions.submitSaveFiltersFailure,
        globalFiltersActions.submitDeleteSavedFiltersFailure,
      ),
      map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) })),
    ),
  );

  private toFilterSaveRequest(params: IDataFilter): IFilterSaveRequest {
    return {
      name: params.name,
      private: params.private,
      filters: params.filters,
      ownerId: params.ownerId,
      listIds: params.listIds,
    };
  }

  private toFilterDeleteRequest = ({ name }: IDataFilter): IFilterDeleteRequest => ({ name });
}
