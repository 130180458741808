import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { OrgConfigSource } from '../sources/org-config.source';
import { EnvService } from '@shared/environment';
import { IHubspotCrmUrl, IOrgConfig, ISigstrResponse, IUpdateConfig } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrgConfigService {
  constructor(
    public source: OrgConfigSource,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getOrgConfig$(): Observable<IOrgConfig> {
    return regenerateOnRetry(() => this.source.getOrgConfig$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  updateConfig$(params: IUpdateConfig): Observable<IUpdateConfig> {
    return regenerateOnRetry(() => this.source.updateConfig$(params)).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSigstrProductType$(): Observable<ISigstrResponse> {
    return regenerateOnRetry(() => this.source.getSigstrProductType$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getCrmUrl$(): Observable<string | IHubspotCrmUrl> {
    return regenerateOnRetry(() => this.source.getCrmUrl$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getOpportunityTypes$(): Observable<string[]> {
    return regenerateOnRetry(() => this.source.getOpportunityTypes$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getOpportunityProductLines$(): Observable<string[]> {
    return regenerateOnRetry(() => this.source.getOpportunityProductLines$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getSpikeModels$(): Observable<string[]> {
    return regenerateOnRetry(() => this.source.getSpikeModels$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getIntentTopics$(): Observable<string[]> {
    return regenerateOnRetry(() => this.source.getIntentTopics$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }
}
