import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { StageInfluenceService } from '../services/stage-influence.service';
import { DateCohortsGroups } from '@shared/enums';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as selectors from './stage-influence.selectors';
import { stageInfluenceActions } from './stage-influence.actions';
import * as DateCohortsStore from '@date-cohorts';
import * as TagsStore from '@measurement/data-access/tags';
import { notificationMessagesActions } from '@notification-messages';
import { userMessageFactory } from '@shared/interfaces';
import { allCampaignTypeOption } from './stage-influence.reducer';
import { StageInfluenceGroupingType } from '../enums';

@Injectable()
export class StageInfluenceEffects {
  constructor(private store: Store<unknown>,
              private stageInfluenceService: StageInfluenceService,
              private actions$: Actions,
              private translate: TranslateService,
              private downloadCsvService: DownloadCsvService) {
  }


  public onFilterChange$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.setFilters),
    map(action => action.request),
    mergeMap(filters => {
      return this.store.pipe(select(DateCohortsStore.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => {
          if (filters.cohort === DateCohortsGroups.Custom) {
            const customCohort = {
              name: 'Custom Range',
              cohort: filters.cohort,
              endDate: filters.endDate,
              startDate: filters.startDate,
            };
            return of(customCohort);
          }

          return this.store.pipe(select(DateCohortsStore.getDateCohortByCohort(filters.cohort)));
        })
      );
    }),
    map(dateCohort => stageInfluenceActions.setSelectedDateCohort(dateCohort))
  ));


  public onGetFilters$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.getFilters),
    map(action => action.params),
    distinctUntilChanged(null, (params) => JSON.stringify(params)),
    map(params => {
      const filters = this.stageInfluenceService.getCohortedFiltersFromParams(params);
      return stageInfluenceActions.setFilters({request: filters});
    })
  ));


  public onLoadCampaignStageInfluenceData$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.loadCampaignStageInfluenceData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(selectors.getCampaignStageInfluenceTableOptions)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
    ]),
    switchMap(([_, filters, tableOptions, globalFilters]) => this.stageInfluenceService.getCampaignStageInfluence$({
      ...filters,
      cohort: filters && {
        cohort: filters.cohort,
        startDate: filters.startDate,
        endDate: filters.endDate,
        name: filters.cohort,
      },
      ...tableOptions,
      globalFilters,
      isDriver: true,
    }).pipe(
      map(data => stageInfluenceActions.loadCampaignStageInfluenceDataSuccess({data})),
      catchError((error: HttpErrorResponse) => {
        const message$ = error.message
          ? of(error.message)
          : this.translate.get('measurementStudio.features.stageInfluence.errors.getCampaignStageInfluence');

        return message$.pipe(
          map(message => stageInfluenceActions.loadCampaignStageInfluenceDataFailure({message}))
        );
      })
    )),
  ));


  public onLoadCampaignTypeStageInfluenceData$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.loadCampaignTypeStageInfluenceData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(selectors.getCampaignTypeStageInfluenceTableOptions)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
    ]),
    switchMap(([_, filters, tableOptions, globalFilters]) => this.stageInfluenceService.getCampaignTypeStageInfluence$({
      ...filters,
      cohort: filters && {
        cohort: filters.cohort,
        startDate: filters.startDate,
        endDate: filters.endDate,
        name: filters.cohort,
      },
      ...tableOptions,
      globalFilters,
      isDriver: true,
    }).pipe(
      map(data => stageInfluenceActions.loadCampaignTypeStageInfluenceDataSuccess({data})),
      catchError((error: HttpErrorResponse) => {
        const message$ = error.message
          ? of(error.message)
          : this.translate.get('measurementStudio.features.stageInfluence.errors.getCampaignTypeStageInfluence');

        return message$.pipe(
          map(message => stageInfluenceActions.loadCampaignTypeStageInfluenceDataFailure({message}))
        );
      })
    )),
  ));


  public onLoadCampaignStageInfluenceTotalsData$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.loadCampaignStageInfluenceTotalsData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(selectors.getCampaignStageInfluenceTableOptions)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
    ]),
    switchMap(([_, filters, tableOptions, globalFilters]) => this.stageInfluenceService.getCampaignStageInfluenceTotals$({
      ...filters,
      cohort: {
        cohort: filters.cohort,
        startDate: filters.startDate,
        endDate: filters.endDate,
        name: filters.cohort,
      },
      ...tableOptions,
      globalFilters,
      isDriver: true,
      searchParam: '',
    }).pipe(
      map(data => stageInfluenceActions.loadCampaignStageInfluenceTotalsDataSuccess({data})),
      catchError((error: HttpErrorResponse) => {
        const message$ = error.message
          ? of(error.message)
          : this.translate.get('measurementStudio.features.stageInfluence.errors.getCampaignStageInfluenceTotals');

        return message$.pipe(
          map(message => stageInfluenceActions.loadCampaignStageInfluenceTotalsDataFailure({message}))
        );
      })
    )),
  ));


  public onGetCampaignStageInfluenceData$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.getCampaignStageInfluenceData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
      this.store.pipe(select(selectors.getCampaignStageInfluenceTableOptions)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
    ]),
    distinctUntilChanged(null, ([_, filters, __, globalFilters, tableOptions]) =>
      JSON.stringify(filters) + JSON.stringify(tableOptions) + JSON.stringify(globalFilters)),
    mergeMap(([_, filters, globalFiltersAsParams]) => {
      const payload = filters?.typeFilter
        ? {label: filters.typeFilter, value: filters.typeFilter}
        : allCampaignTypeOption;
      return [
        stageInfluenceActions.loadCampaignStageInfluenceData(),
        stageInfluenceActions.loadCampaignStageInfluenceTotalsData(),
        stageInfluenceActions.setSelectedCampaignType(payload),
        TagsStore.tagsActions.loadTagsByCampaign({
          request: {
            ...filters,
            isDriver: true,
            cohort: filters && {
              cohort: filters.cohort,
              startDate: filters.startDate,
              endDate: filters.endDate,
              name: filters.cohort,
            },
          },
          globalFilters: globalFiltersAsParams
        }),
      ];
    })
  ));


  public onGetCampaignTypeStageInfluenceData$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.getCampaignTypeStageInfluenceData),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(selectors.getCampaignTypeStageInfluenceTableOptions)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
    ]),
    distinctUntilChanged(null, ([_, filters, tableOptions, globalFilters]) =>
      JSON.stringify(filters) + JSON.stringify(tableOptions) + JSON.stringify(globalFilters)),
    mergeMap(() => ([
      stageInfluenceActions.loadCampaignTypeStageInfluenceData(),
      stageInfluenceActions.loadCampaignStageInfluenceTotalsData(),
    ]))
  ));


  public onChangeCampaignTableOptions$ = createEffect(() => this.actions$.pipe(
    ofType(
      stageInfluenceActions.setCampaignTablePage,
      stageInfluenceActions.setCampaignTableSearch,
      stageInfluenceActions.setCampaignTableSortState,
    ),
    map(() => stageInfluenceActions.getCampaignStageInfluenceData())
  ));


  public onChangeCampaignTypeTableOptions$ = createEffect(() => this.actions$.pipe(
    ofType(
      stageInfluenceActions.setCampaignTypeTablePage,
      stageInfluenceActions.setCampaignTypeTableSearch,
      stageInfluenceActions.setCampaignTypeTableSortState,
    ),
    map(() => stageInfluenceActions.getCampaignTypeStageInfluenceData())
  ));


  public onDownloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.downloadCSV),
    concatLatestFrom(() => [
      this.store.pipe(select(selectors.getStageInfluenceFilters)),
      this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
      this.store.pipe(select(selectors.getStageInfluenceVisibleColumns)),
    ]),
    mergeMap(([action, filters, globalFilters, visibleColumns]) => {
      return this.stageInfluenceService.getCampaignStageInfluenceCSV$({
        ...filters,
        cohort: filters && {
          cohort: filters.cohort,
          startDate: filters.startDate,
          endDate: filters.endDate,
          name: filters.cohort,
        },
        globalFilters,
        isDriver: true,
        visibleColumns,
        groupingType: action.groupingType,
      }).pipe(
        map(data => {
          const filename = action.groupingType === StageInfluenceGroupingType.Campaigns
            ? 'Campaign-Stage-Influence'
            : 'Campaign-Type-Stage-Influence';
          return stageInfluenceActions.downloadCSVSuccess({
            data,
            filename,
          });
        }),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.stageInfluence.errors.downloadCsv';
          return of(stageInfluenceActions.downloadCSVFailure({message}));
        })
      );
    }),
  ));


  public onDownloadCSVSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.downloadCSVSuccess),
    tap(action => this.downloadCsvService.createBlobAndDownload(action.filename, action.data)),
  ), {dispatch: false});


  public onDownloadCSVFailure$ = createEffect(() => this.actions$.pipe(
    ofType(stageInfluenceActions.downloadCSVFailure),
    map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));
}
