import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { IWebActivitiesTypes, IWfStage } from '../interfaces';
import { CatalogsSource } from '../source/catalogs.source';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {
  constructor(
    public source: CatalogsSource,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getWebActivitiesTypes$(): Observable<IWebActivitiesTypes> {
    return regenerateOnRetry(() => this.source.getWebActivitiesTypes$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }

  getStages$(): Observable<IWfStage[]> {
    return regenerateOnRetry(() => this.source.getStages$()).pipe(
      this.retry.retryWithEscalation(hubTokenName)
    );
  }
}
