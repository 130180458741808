import { IFactoryParams } from '@shared/interfaces';

export enum CouTypeForRequests {
  UNSPECIFIED = 'UNSPECIFIED',
  ADS_MARKETER = 'ADS_MARKETER',
  CHAT_TEAM = 'CHAT_TEAM',
  EMAIL_COMPANY = 'EMAIL_COMPANY',
  BF_ORG = 'BF_ORG',
}

export interface ICustomerOrganizationalUnit {
  type: CouTypeForRequests;
  id: string;
}

export interface IGetUnifiedCustomerIdForCOURequest {
  customerOrganizationalUnit: ICustomerOrganizationalUnit;
}

export interface IGetUnifiedCustomerIdForCOUResponse {
  closestUnifiedCustomerId: string;
}

export function customerOrganizationalUnitFactory({
  n = 0,
  overrides = {},
}: IFactoryParams<ICustomerOrganizationalUnit, number, string> = {}): ICustomerOrganizationalUnit {
  return {
    id: `${n}`,
    type: CouTypeForRequests.BF_ORG,
    ...overrides,
  };
}

export function getUnifiedCustomerIdForCOURequestFactory({
  n = 0,
  overrides = {},
}: IFactoryParams<IGetUnifiedCustomerIdForCOURequest, number, string> = {}): IGetUnifiedCustomerIdForCOURequest {
  return {
    customerOrganizationalUnit: customerOrganizationalUnitFactory({ n }),
    ...overrides,
  };
}

export function getUnifiedCustomerIdForCOUResponseFactory({
  n = 0,
  overrides = {},
}: IFactoryParams<IGetUnifiedCustomerIdForCOUResponse, number, string> = {}): IGetUnifiedCustomerIdForCOUResponse {
  return {
    closestUnifiedCustomerId: `${n}`,
    ...overrides,
  };
}
