<div class="container">
  <div class="main">
    <ng-container *ngIf="influencedInsights$ | async as influencedInsights">
      <div class="container-header">
        <div class="content">
          {{ 'measurementStudio.features.advertisingInsights.informationPanel.title'  | translate}}
        </div>
        <ng-container *ngIf="currentState === adInsightsStates.InfluencedDetails">
          <div class="back" (click)="changeState(adInsightsStates.InfluencedList)">
            <i class="fas fa-angle-left"></i>
            {{ 'shared.common.back' | translate }}
          </div>
          <div class="block" *ngIf="activeReport">
            <div class="block-header">
              {{ 'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.title' | translate }}
            </div>
            <ng-container [ngSwitch]="activeDetails">
              <div *ngSwitchCase="'underServed'" class="block-text"
                   [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.body' | translate
                                   : {
                                   benchmark: (influencedInsights[activeReport].data.benchmark | typedData : dataTypes.benchmark),
                                   underServed: influencedInsights[activeReport].data.underServed
                                   }">
              </div>
              <div *ngSwitchCase="'underPerforming'" class="block-text"
                   [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.body' | translate
                                   : {
                                   benchmark: (influencedInsights[activeReport].data.benchmark | typedData : dataTypes.benchmark),
                                   underPerforming: influencedInsights[activeReport].data.underPerforming
                                   }">
              </div>
              <div *ngSwitchCase="'overSpent'" class="block-text"
                   [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.body' | translate
                                   : {
                                   benchmark: (influencedInsights[activeReport].data.benchmark | typedData : dataTypes.benchmark),
                                   overSpent: influencedInsights[activeReport].data.overSpent
                                   }">
              </div>
              <div *ngSwitchCase="'valueLift'" class="block-text"
                   [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.body' | translate
                                   : {
                                   valueLift: influencedInsights[activeReport].data.valueLift | typedData : dataTypes.valueLift,
                                   valueWithAds: influencedInsights[activeReport].data.valueWithAds | typedData : dataTypes.valueWithAds,
                                   valueWithoutAds: influencedInsights[activeReport].data.valueWithoutAds | typedData : dataTypes.valueWithoutAds
                                   }">
              </div>
              <div *ngSwitchCase="'velocityLift'" class="block-text"
                   [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[activeReport].translationKey + 'Influenced.' + activeDetails + '.body' | translate
                                   : {
                                   velocityLift: influencedInsights[activeReport].data.velocityLift | typedData : dataTypes.velocityLift,
                                   velocityWithAds: influencedInsights[activeReport].data.velocityWithAds,
                                   velocityWithoutAds: influencedInsights[activeReport].data.velocityWithoutAds
                                   }">
              </div>
            </ng-container>

            <div class="buttons">
              <ts-button
                (clicked)="changeState(adInsightsStates.AddToAccountList)"
                class="add-accounts-btn"
                buttonType="button"
                actionName="Add to Account List"
                [isDisabled]="!influencedInsights[activeReport].details || influencedInsights[activeReport].details.rows.length === 0"
                [isSmall]="true"
                theme="secondary">
                {{ 'shared.infoPanel.addToAccountList' | translate }}
              </ts-button>
              <tsh-csv-download-button
                [isDisabled]="!influencedInsights[activeReport].details || !influencedInsights[activeReport].details.rows?.length"
                [showProgress]="influencedInsights[activeReport].isDownloadingCsv"
                (download)="downloadCSV()"
              ></tsh-csv-download-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentState === adInsightsStates.AddToAccountList">
          <div class="back" (click)="changeState(adInsightsStates.InfluencedDetails)">
            <i class="fas fa-angle-left"></i>
            {{ 'shared.common.back' | translate }}
          </div>
          <div class="block">
            <div class="block-header">
              {{ 'shared.infoPanel.addToAccountList' | translate }}
            </div>
            <div class="block-text">
              {{ influencedInsights[activeReport]?.details?.totalRows }} {{ 'measurementStudio.features.advertisingInsights.informationPanel.accounts' | translate }}
            </div>
            <div class="buttons">
            </div>
          </div>
        </ng-container>
      </div>
      <hr class="report-divider">
      <div class="inner" *ngIf="currentState === adInsightsStates.InfluencedList">
        <ng-container *ngFor="let report of reportKeys">
          <div class="block" [tsLoadingOverlay]="influencedInsights[report]?.isLoading"
               *ngIf="influencedInsights[report]?.isLoading">
            <div class="block-header">
              {{ 'measurementStudio.features.advertisingInsights.reports.' + influencedInsights[report].translationKey + 'Influenced' | translate }}
            </div>
          </div>
          <div class="block" *ngIf="!influencedInsights[report]?.isLoading
            && influencedInsights[report]?.data
            && ((report === reportTypes[showReport]) || (showReport === insightsState))">
            <ng-container
              *ngIf="(influencedInsights[report].data.underServed > 0
               || influencedInsights[report].data.underPerforming > 0
               || influencedInsights[report].data.overSpent > 0
               || influencedInsights[report].data.valueLift > 0
               || influencedInsights[report].data.velocityLift > 0); else emptyDrawerState">
              <div class="block-header">
                {{ 'measurementStudio.features.advertisingInsights.reports.' + influencedInsights[report].translationKey + 'Influenced' | translate }}
              </div>
              <div class="tabs">
                <div class="tab"
                     (click)="changeState(adInsightsStates.InfluencedDetails, report, 'UnderServed');"
                     *ngIf="influencedInsights[report].data.underServed > 0">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[report].translationKey + 'Influenced.underServed.body' | translate
                                      : {
                                      benchmark: (influencedInsights[report].data.benchmark | typedData : dataTypes.benchmark),
                                      underServed: influencedInsights[report].data.underServed
                                      }">
                  </div>
                  <div class="tab-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
                <div class="tab"
                     (click)="changeState(adInsightsStates.InfluencedDetails, report, 'UnderPerforming')"
                     *ngIf="influencedInsights[report].data.underPerforming > 0">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[report].translationKey + 'Influenced.underPerforming.body' | translate
                                      : {
                                      benchmark: (influencedInsights[report].data.benchmark | typedData : dataTypes.benchmark),
                                      underPerforming: influencedInsights[report].data.underPerforming
                                      }">
                  </div>
                  <div class="tab-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
                <div class="tab"
                     (click)="changeState(adInsightsStates.InfluencedDetails, report, 'OverSpent')"
                     *ngIf="influencedInsights[report].data.overSpent > 0">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[report].translationKey + 'Influenced.overSpent.body' | translate
                                      : {
                                      benchmark: (influencedInsights[report].data.benchmark | typedData : dataTypes.benchmark),
                                      overSpent: influencedInsights[report].data.overSpent
                                      }">
                  </div>
                  <div class="tab-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
                <div class="tab"
                     (click)="changeState(adInsightsStates.InfluencedDetails, report, 'ValueLift')"
                     *ngIf="influencedInsights[report].data.valueLift > 0">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[report].translationKey + 'Influenced.valueLift.body' | translate
                                      : {
                                      valueLift: (influencedInsights[report].data.valueLift | typedData : dataTypes.valueLift),
                                      valueWithAds: (influencedInsights[report].data.valueWithAds | typedData : dataTypes.valueWithAds),
                                      valueWithoutAds: (influencedInsights[report].data.valueWithoutAds | typedData : dataTypes.valueWithoutAds)
                                      }">
                  </div>
                  <div class="tab-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
                <div class="tab"
                     (click)="changeState(adInsightsStates.InfluencedDetails, report, 'VelocityLift')"
                     *ngIf="influencedInsights[report].data.velocityLift > 0">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.insightsLists.' + influencedInsights[report].translationKey + 'Influenced.velocityLift.body' | translate
                                      : {
                                      velocityLift: (influencedInsights[report].data.valueLift | typedData : dataTypes.velocityLift),
                                      velocityWithAds: influencedInsights[report].data.velocityWithAds,
                                      velocityWithoutAds: influencedInsights[report].data.velocityWithoutAds
                                      }">
                  </div>
                  <div class="tab-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #emptyDrawerState>
              <div class="block-header">
                {{ 'measurementStudio.features.advertisingInsights.noResultsContent.default.title' | translate }}
              </div>
              <div class="tabs">
                <div class="tab">
                  <div class="tab-text"
                       [innerHTML]="'measurementStudio.features.advertisingInsights.noResultsContent.default.description' | translate"
                  ></div>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <div class="block" *ngIf="currentState === adInsightsStates.InfluencedDetails">
        <tsh-search-by-property
          (submitted)="onSearchSubmit($event)"
          [searchQuery]="searchQuery">
        </tsh-search-by-property>
        <div
          class="block"
          *ngIf="influencedInsights[activeReport]?.isLoading"
          [tsLoadingOverlay]="influencedInsights[activeReport]?.isLoading">
        </div>
        <ms-info-panel-table
          *ngIf="!influencedInsights[activeReport]?.isLoading"
          (changeSort)="onChangeSort($event)"
          (changePage)="onChangePage($event)"
          [columns]="columns"
          [totalCount]="influencedInsights[activeReport]?.details?.totalRows"
          [pagination]="influencedInsights[activeReport]?.pagination"
          [sortState]="influencedInsights[activeReport]?.sortState"
          [data]="influencedInsights[activeReport]?.details?.rows">
        </ms-info-panel-table>
      </div>
      <div class="block" *ngIf="currentState === adInsightsStates.AddToAccountList">
        <div class="add-to-account">
          <label for="accountFolder">
            {{ 'measurementStudio.features.advertisingInsights.informationPanel.folder' | translate }}
          </label>
          <ts-selection-list
            id="accountFolder"
            [isDisabled]="addingToAccount$ | async"
            [formControl]="form.get('folderId')">
            <ts-option
              (click)="chosenFolder = folder"
              *ngFor="let folder of accountListFolders$ | async"
              [value]="folder.folderId">
              {{ folder.folderName }}
            </ts-option>
          </ts-selection-list>
          <label for="accountList">
            {{ 'measurementStudio.features.advertisingInsights.informationPanel.accountList' | translate }}
          </label>
          <ts-selection-list
            id="accountList"
            [disabled]="!chosenFolder || (addingToAccount$ | async)"
            [formControl]="form.get('listId')">
            <ts-option
              *ngFor="let accountList of chosenFolder?.contents"
              [value]="accountList.id">
              {{ accountList.name }}
            </ts-option>
          </ts-selection-list>
          <ts-button
            (clicked)="addToAccountList(influencedInsights[activeReport].details)"
            [isSmall]="true"
            [isDisabled]="!form.get('listId').value"
            [showProgress]="addingToAccount$ | async">
            {{ 'shared.infoPanel.addToAccountList' | translate }}
          </ts-button>
          <span class="text" *ngIf="!form.get('listId').value">
          {{ 'measurementStudio.features.advertisingInsights.informationPanel.formInvalid' | translate }}
        </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="tooltip">
    <ts-tooltip
      #tooltip
      [tooltipValue]="tooltipText"
      tooltipPosition="above"
      [hasUnderline]="false">
    </ts-tooltip>
  </div>
</div>
