import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import * as interfaces from '../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export enum WebActivitiesURLs {
  MetaData = '/v1/web_activities/campaign/meta_data',
  Responses = '/v1/web_activities/campaign/responses',
  Attribution = '/v1/web_activities/campaign/attribution',
  Influence = '/v1/web_activities/campaign/influence',
  Returns = '/v1/web_activities/campaign/returns',
  Costs = '/v1/web_activities/campaign/costs',
  SourceMetaData = '/v1/web_activities/source/meta_data',
  SourceResponses = '/v1/web_activities/source/responses',
  SourceAttribution = '/v1/web_activities/source/attribution',
  SourceInfluence = '/v1/web_activities/source/influence',
  SourceReturns = '/v1/web_activities/source/returns',
  SourceCosts = '/v1/web_activities/source/costs',
  MediumMetaData = '/v1/web_activities/medium/meta_data',
  MediumResponses = '/v1/web_activities/medium/responses',
  MediumAttribution = '/v1/web_activities/medium/attribution',
  MediumInfluence = '/v1/web_activities/medium/influence',
  MediumReturns = '/v1/web_activities/medium/returns',
  MediumCosts = '/v1/web_activities/medium/costs',
}

@Injectable({providedIn: 'root'})
export class WebActivitiesSource extends HttpClientBase {

  getCampaignMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesCampaignResponse>> {
    const endpoint = this.endpoint(WebActivitiesURLs.MetaData);

    return this.httpClient.post<interfaces.IMetaData<interfaces.IWebActivitiesCampaignResponse>>(endpoint, body);
  }

  getCampaignResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    const endpoint = this.endpoint(WebActivitiesURLs.Responses);

    return this.httpClient.post<interfaces.ICampaignResponses>(endpoint, body);
  }

  getCampaignAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    const endpoint = this.endpoint(WebActivitiesURLs.Attribution);

    return this.httpClient.post<interfaces.ICampaignAttribution>(endpoint, body);
  }

  getCampaignInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    const endpoint = this.endpoint(WebActivitiesURLs.Influence);

    return this.httpClient.post<interfaces.ICampaignInfluence>(endpoint, body);
  }

  getCampaignReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    const endpoint = this.endpoint(WebActivitiesURLs.Returns);

    return this.httpClient.post<interfaces.ICampaignReturns>(endpoint, body);
  }

  getCampaignCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    const endpoint = this.endpoint(WebActivitiesURLs.Costs);

    return this.httpClient.post<interfaces.ICampaignCosts>(endpoint, body);
  }

  getSourceMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesGroupResponse>> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceMetaData);

    return this.httpClient.post<interfaces.IMetaData<interfaces.IWebActivitiesGroupResponse>>(endpoint, body);
  }

  getSourceResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceResponses);

    return this.httpClient.post<interfaces.ICampaignResponses>(endpoint, body);
  }

  getSourceAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceAttribution);

    return this.httpClient.post<interfaces.ICampaignAttribution>(endpoint, body);
  }

  getSourceInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceInfluence);

    return this.httpClient.post<interfaces.ICampaignInfluence>(endpoint, body);
  }

  getSourceReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceReturns);

    return this.httpClient.post<interfaces.ICampaignReturns>(endpoint, body);
  }

  getSourceCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    const endpoint = this.endpoint(WebActivitiesURLs.SourceCosts);

    return this.httpClient.post<interfaces.ICampaignCosts>(endpoint, body);
  }

  getMediumMetaData$(body: IAnalyticsRequest): Observable<interfaces.IMetaData<interfaces.IWebActivitiesMediumResponse>> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumMetaData);

    return this.httpClient.post<interfaces.IMetaData<interfaces.IWebActivitiesMediumResponse>>(endpoint, body);
  }

  getMediumResponses$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponses> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumResponses);

    return this.httpClient.post<interfaces.ICampaignResponses>(endpoint, body);
  }

  getMediumAttribution$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttribution> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumAttribution);

    return this.httpClient.post<interfaces.ICampaignAttribution>(endpoint, body);
  }

  getMediumInfluence$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluence> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumInfluence);

    return this.httpClient.post<interfaces.ICampaignInfluence>(endpoint, body);
  }

  getMediumReturns$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturns> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumReturns);

    return this.httpClient.post<interfaces.ICampaignReturns>(endpoint, body);
  }

  getMediumCosts$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCosts> {
    const endpoint = this.endpoint(WebActivitiesURLs.MediumCosts);

    return this.httpClient.post<interfaces.ICampaignCosts>(endpoint, body);
  }
}
