/* istanbul ignore file */
import { createAction, props } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';
import { IAuthToken, IRouterUrl } from '@shared/interfaces';

export enum UserActionTypes {
  LOGOUT = '[User] Log Out',

  SAVE_REDIRECT_URL = '[User] Save Redirect Url',
  CLEAR_REDIRECT_URL = '[User] Clear Redirect Url',

  SWITCH_USER = '[User] Switch User',
  SWITCH_USER_SUCCESS = '[User] Switch User Success',
  SWITCH_USER_FAILURE = '[User] Switch User Failure',

  SET_USER_ZD_SSO_ACCESS = '[User] Set User Zendesk Sso Access',

  SET_UNIFIED_LOGIN_STATUS = '[User] Set Unified Login Status',
  SET_UNIFIED_CUSTOMER_ID = '[User] Set Customer Entitlement Id',
  LOAD_CUSTOMER_ENTITLEMENT_AUTHORIZE = '[User] Customer Entitlement Authorize',
  GET_CUSTOMER_ENTITLEMENT_CLAIMS_SUCCESS = '[User] Get Token with Customer Entitlement Claims Success',
  GET_CUSTOMER_ENTITLEMENT_CLAIMS_ERROR = '[User] Get Token with Customer Entitlement Claims Error',
  GET_CUSTOMER_ID_ERROR = '[User] Get Customer Id Error',

  InitialTokenExtracted = '[User] Initial Token Extracted',
  UPDATE_JWT = '[User] UPDATE JWT',
}

defineTypeEnum(UserActionTypes);

const logOut = createAction(
  UserActionTypes.LOGOUT
);

// REDIRECT URL
const saveRedirectUrl = createAction(
  UserActionTypes.SAVE_REDIRECT_URL,
  props<{ url: IRouterUrl }>()
);

const clearRedirectUrl = createAction(
  UserActionTypes.CLEAR_REDIRECT_URL
);

const switchUser = createAction(
  UserActionTypes.SWITCH_USER,
  props<{ user: string }>()
);

const switchUserSuccess = createAction(
  UserActionTypes.SWITCH_USER_SUCCESS,
  props<{ token: IAuthToken }>()
);

const switchUserFailure = createAction(
  UserActionTypes.SWITCH_USER_FAILURE,
  props<{ message: string }>()
);

const setUserZendeskSsoAccess = createAction(
  UserActionTypes.SET_USER_ZD_SSO_ACCESS,
  props<{ access: boolean }>()
);

const setUnifiedLoginStatus = createAction(
  UserActionTypes.SET_UNIFIED_LOGIN_STATUS,
  props<{ status: boolean }>()
);

const setUnifiedCustomerId = createAction(
  UserActionTypes.SET_UNIFIED_CUSTOMER_ID,
  props<{ id: string }>()
);

const loadCustomerEntitlementAuthorize = createAction(
  UserActionTypes.LOAD_CUSTOMER_ENTITLEMENT_AUTHORIZE
);

const getCustomerEntitlementClaimsSuccess = createAction(
  UserActionTypes.GET_CUSTOMER_ENTITLEMENT_CLAIMS_SUCCESS
);

const getCustomerEntitlementClaimsError = createAction(
  UserActionTypes.GET_CUSTOMER_ENTITLEMENT_CLAIMS_ERROR
);

const getCustomerIdError = createAction(
  UserActionTypes.GET_CUSTOMER_ID_ERROR
);

const initialTokenExtracted = createAction(
  UserActionTypes.InitialTokenExtracted,
  props<{ token: string }>()
);

const updateJWT = createAction(
  UserActionTypes.UPDATE_JWT,
  props<{ token: string }>()
);

export const userActions = {
  logOut,
  saveRedirectUrl,
  clearRedirectUrl,
  switchUser,
  switchUserSuccess,
  switchUserFailure,
  setUserZendeskSsoAccess,
  setUnifiedLoginStatus,
  setUnifiedCustomerId,
  getCustomerEntitlementClaimsSuccess,
  loadCustomerEntitlementAuthorize,
  getCustomerEntitlementClaimsError,
  getCustomerIdError,
  initialTokenExtracted,
  updateJWT
};
