import { opportunitiesActions } from './opportunities.actions';
import { IDateCohort } from '@shared/interfaces';
import { updateColumnsVisibility } from '@util/store';
import { IOpportunityColumn, IOpportunityItem, IOpportunityTotals, IOpportunityVisual, VisualKeys } from '../interfaces';
import { IOpportunityRequest, IOpportunityUsedQueryParams } from '@measurement-studio/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const opportunitiesFeatureKey = 'opportunities';

export interface OpportunitiesState {
  report: IOpportunityItem[];
  totals: IOpportunityTotals;
  isLoading: boolean;
  isLoadingChart: boolean;
  filters: IOpportunityRequest;
  visualization: IOpportunityVisual;
  columns: IOpportunityColumn[];
  selectedDateCohort: IDateCohort;
  usedQueryParams: IOpportunityUsedQueryParams | null;
  selectedChart: VisualKeys | null;
}

export const initialState: OpportunitiesState = {
  report: null,
  totals: null,
  isLoading: false,
  isLoadingChart: false,
  filters: null,
  visualization: null,
  columns: [],
  selectedDateCohort: null,
  usedQueryParams: null,
  selectedChart: null,
};

const opportunitiesReducer = createReducer(
  initialState,
  on(
    opportunitiesActions.loadReport,
    (state) => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    opportunitiesActions.loadVisualization,
    (state) => ({
      ...state,
      isLoadingChart: true
    })
  ),
  on(
    opportunitiesActions.setUsedQueryParams,
    (state, action) => ({
      ...state,
      usedQueryParams: action.params
    })
  ),
  on(
    opportunitiesActions.loadReportFailure,
    (state) => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    opportunitiesActions.loadVisualizationFailure,
    (state) => ({
      ...state,
      isLoadingChart: false
    })
  ),
  on(
    opportunitiesActions.loadReportSuccess,
    (state, action) => ({
      ...state,
      isLoading: false,
      report: action.data.opptyData,
      totals: action.data.opptyTotals
    })
  ),
  on(
    opportunitiesActions.loadVisualizationSuccess,
    (state, action) => ({
      ...state,
      isLoadingChart: false,
      visualization: action.data
    })
  ),
  on(
    opportunitiesActions.setOpportunityFilters,
    (state, action) => ({
      ...state,
      filters: {...state.filters, ...action.filters}
    })
  ),
  on(
    opportunitiesActions.setReportColumns,
    (state, action) => ({
      ...state,
      columns: action.columns
    })
  ),
  on(
    opportunitiesActions.toggleColumnVisibility,
    (state, action) => ({
      ...state,
      columns: updateColumnsVisibility(state.columns, action.column)
    })
  ),
  on(
    opportunitiesActions.setDateCohort,
    (state, action) => ({
      ...state,
      selectedDateCohort: action.cohort
    })
  ),
  on(
    opportunitiesActions.setSelectedChart,
    (state, action) => ({
      ...state,
      selectedChart: action.chart
    })
  )
);

export function reducer(state: OpportunitiesState, action: Action): OpportunitiesState {
  return opportunitiesReducer(state, action);
}
