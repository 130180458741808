import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccountListState, LIST_POPUP_KEY } from './list-popup.reducer';

export const selectAccountListState = createFeatureSelector<AccountListState>(LIST_POPUP_KEY);

export const getAccountsFolders = createSelector(
  selectAccountListState,
  (state: AccountListState) => state.folders
);

export const getLoadingNumber = createSelector(
  selectAccountListState,
  (state: AccountListState) => {
    return state.isLoading
  }
);

export const getIsLoading = createSelector(
  getLoadingNumber,
  (loadNumber: number) => {
    return loadNumber > 0
  }
);
