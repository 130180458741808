<div class="date-cohort-container">
  <ts-selection-list label="Cohort"
                     [isDisabled]="isDisabled"
                     [allowUserInput]="false"
                     [formControl]="formGroup.get('cohort')"
                     [valueComparator]="compareCohorts"
                     [displayFormatter]="formatter"
                     data-cy="date-cohort-selection-list"
                     (selectionChange)="changeDateCohort($event)">
    <ng-container *ngFor="let cohortGroup of dateCohortOptions">
      <strong *ngIf="cohortGroup.label"
              class="option-separator">
        {{ cohortGroup.label | translate }}
      </strong>
      <ts-option [ngClass]="{'option-in-group': !!cohortGroup.label}"
                 [value]="option"
                 [option]="option"
                 *ngFor="let option of cohortGroup.options; trackBy: trackByFn"
                 [attr.data-cy]="'date-cohort-option-' + option.cohort">
        {{ option.name | translate }}
      </ts-option>
    </ng-container>
  </ts-selection-list>
  <ts-date-range
                 data-cy="date-cohorts-date-range"
                 theme="default"
                 startLabel="'shared.dateCohorts.from' | translate"
                 endLabel="'shared.dateCohorts.to' | translate"
                 [endMaxDate]="endMaxDate"
                 [endMinDate]="endMinDate"
                 [startMaxDate]="startMaxDate"
                 [startMinDate]="startMinDate"
                 [isDisabled]="selectedDateRange?.cohort !== customDateCohort"
                 [dateFormGroup]="formGroup.get('dateRange')"
                 (dateRangeChange)="changeCustomDateRange($event)">
  </ts-date-range>
  <ts-button *ngIf="selectedDateRange?.cohort === customDateCohort"
             data-cy="date-cohorts-apply"
             theme="default"
             (clicked)="emitApply()">
    {{ 'shared.common.apply' | translate }}
  </ts-button>
</div>
