import { IInsightsReportConfig } from './interfaces/insights-report.config';
import { InsightsReportKey } from './enums/insights-report-key';
import * as Lists from './data/insights-lists.consts';
import * as MetricItems from './data/metric-items.consts';
import * as ChartConfigs from './data/details-chart-configs.consts';
import * as TableConfigs from './data/details-table-configs.consts';

export const InsightsReportPath = 'ad-insights';

export const REPORT_FILTER_SETTINGS_FORM_NAME = 'shared-insights-report-filter-settings-form';
export const INSIGHTS_REPORT_SETTINGS_FORM_NAME = 'insights-details-tab-filters-form';
export const INSIGHTS_LOADING_TOAST_NOTIFICATION_DELAY = 1000 * 10;
/**
 * Just to make it easier to refactor the table data type
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type InsightsDetailsTableDataType = any;

export const InsightsReportConfigs: Record<InsightsReportKey, IInsightsReportConfig> = {
  [InsightsReportKey.PageViewsInfluenced]: {
    key: InsightsReportKey.PageViewsInfluenced,
    path: 'page-views-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.pageViewsInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.pageViewsInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.pageViewsInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.pageViewsInfluenced.description',
    chartConfig: ChartConfigs.PAGE_VIEWS_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.PAGE_VIEWS_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.PAGE_VIEWS_METRIC_ITEMS,
    tableConfig: TableConfigs.PAGE_VIEWS_INFLUENCED_TABLE_CONFIG
  },
  [InsightsReportKey.LeadsInfluenced]: {
    key: InsightsReportKey.LeadsInfluenced,
    path: 'leads-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.leadsInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.leadsInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.leadsInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.leadsInfluenced.description',
    chartConfig: ChartConfigs.LEADS_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.LEADS_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.LEADS_INFLUENCED_METRIC_ITEMS,
    tableConfig: TableConfigs.LEADS_INFLUENCED_TABLE_CONFIG
  },
  [InsightsReportKey.CampaignResponsesInfluenced]: {
    key: InsightsReportKey.CampaignResponsesInfluenced,
    path: 'campaign-responses-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.campaignResponsesInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.campaignResponsesInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.campaignResponsesInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.campaignResponsesInfluenced.description',
    chartConfig: ChartConfigs.CAMPAIGN_RESPONSES_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.CAMPAIGN_RESPONSES_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.CAMPAIGN_RESPONSES_INFLUENCED_METRIC_ITEMS,
    tableConfig: TableConfigs.CAMPAIGN_RESPONSES_INFLUENCED_TABLE_CONFIG
  },
  [InsightsReportKey.NewPipelineInfluenced]: {
    key: InsightsReportKey.NewPipelineInfluenced,
    path: 'new-pipeline-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.newPipelineInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.newPipelineInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.newPipelineInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.newPipelineInfluenced.description',
    chartConfig: ChartConfigs.NEW_PIPELINE_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.NEW_PIPELINE_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.NEW_PIPELINE_INFLUENCED_METRIC_ITEMS,
    tableConfig: TableConfigs.NEW_PIPELINE_INFLUENCED_TABLE_CONFIG
  },
  [InsightsReportKey.PipelineInfluenced]: {
    key: InsightsReportKey.PipelineInfluenced,
    path: 'pipeline-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.pipelineInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.pipelineInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.pipelineInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.pipelineInfluenced.description',
    chartConfig: ChartConfigs.PIPELINE_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.PIPELINE_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.PIPELINE_INFLUENCED_METRIC_ITEMS,
    tableConfig: TableConfigs.PIPELINE_INFLUENCED_TABLE_CONFIG
  },
  [InsightsReportKey.RevenueInfluenced]: {
    key: InsightsReportKey.RevenueInfluenced,
    path: 'revenue-influenced',
    title: 'measurementStudio.features.advertisingInsights.reports.revenueInfluenced',
    tooltip: `measurementStudio.features.advertisingInsights.tooltips.revenueInfluenced`,
    noDataTitle: 'measurementStudio.features.advertisingInsights.noResultsContent.revenueInfluenced.title',
    noDataDescription: 'measurementStudio.features.advertisingInsights.noResultsContent.revenueInfluenced.description',
    chartConfig: ChartConfigs.REVENUE_INFLUENCED_CHART_CONFIG,
    insightsLists: Lists.REVENUE_INFLUENCED_INSIGHTS_LISTS,
    metricBarItems: MetricItems.REVENUE_INFLUENCED_METRIC_ITEMS,
    tableConfig: TableConfigs.REVENUE_INFLUENCED_TABLE_CONFIG
  }
};
