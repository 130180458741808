import { INavMenu } from '@shared/interfaces';
import { NavPanelTypes, NavSectionTypes, RouteItemEnum } from '@shared/enums';
import { LEARN_MORE_DIGITAL_ATTRIBUTION, LEARN_MORE_REVENUE_ATTRIBUTION, REPORT_NAV_LABEL } from '@shared/constants';

export const MEASUREMENT_STUDIO_NAV: INavMenu = [
  {
    section: NavSectionTypes.Primary,
    items: [{
      icon: 'analytics',
      type: NavPanelTypes.Basic,
      label: 'data.routeLabel.abmScorecard',
      routeId: RouteItemEnum.Scorecard,
      routeItem: null
    }, {
      icon: 'tachometer',
      type: NavPanelTypes.Basic,
      label: 'hub.appMenu.header.dashboard',
      routeId: RouteItemEnum.Dashboards, // Dashboard
      routeItem: null
    },  {
      icon: 'table',
      type: NavPanelTypes.Drawer,
      label: REPORT_NAV_LABEL,
      categories: [
        {
          label: 'shared.navMenus.reports.categoriesLabels.standard',
          routeIds: [
            RouteItemEnum.AdvertisingInsights,
            RouteItemEnum.OpportunityInsights,
          ],
          routeItems: []
        },
        {
          label: 'shared.navMenus.reports.categoriesLabels.revenueAttribution',
          routeIds: [
            RouteItemEnum.MarketingImpact,
            // TODO: deprecated route. Clarify if we need it at all
            // RouteItemEnum.AnalyzeMarketingImpact,
            RouteItemEnum.CampaignAnalytics,
            RouteItemEnum.CampaignTrends,
            RouteItemEnum.AttributionByCampaignType,
            RouteItemEnum.StageInfluence,
            // TODO: deprecated route. Clarify if we need it at all
            // RouteItemEnum.AnalyzeAttributionByCampaignType,
            RouteItemEnum.StagesSnapshot,
            RouteItemEnum.CohortedWaterfall,
            RouteItemEnum.ProgressionByQuarter,
          ],
          routeItems: [],
          learnMoreLink: LEARN_MORE_REVENUE_ATTRIBUTION
        },
        {
          label: 'shared.navMenus.reports.categoriesLabels.digitalAttribution',
          routeIds: [
            RouteItemEnum.WebActivitiesList,
            RouteItemEnum.WebActivitiesTrending,
          ],
          routeItems: [],
          learnMoreLink: LEARN_MORE_DIGITAL_ATTRIBUTION
        },
      ]
    }]
  },
];
