import { Action, createReducer, on } from '@ngrx/store';

import { savedReportsActions } from './saved-reports.actions';
import { ISavedFolder } from '@shared/interfaces';

export const savedReportsFeatureKey = 'savedReports';

export interface SavedReportState {
  isLoading: boolean;
  folders: ISavedFolder[];
}

export const initialState: SavedReportState = {
  isLoading: false,
  folders: null,
};

const savedReportsReducer = createReducer(
  initialState,
  on(
    savedReportsActions.loadSavedReports,
    savedReportsActions.createSavableReport,
    savedReportsActions.updateSavableReport,
    savedReportsActions.deleteSavableReport,
    savedReportsActions.createSavableFolder,
    savedReportsActions.updateSavableFolder,
    savedReportsActions.deleteSavableFolder,
    (state) => ({
      ...state,
      isLoading: true,
    })),
  on(savedReportsActions.loadSavedReportsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    folders: action.data
  })),
  on(
    savedReportsActions.loadSavedReportsFailure,
    savedReportsActions.createSavableReportFailure,
    savedReportsActions.updateSavableReportFailure,
    savedReportsActions.deleteSavableReportFailure,
    savedReportsActions.createSavableFolderFailure,
    savedReportsActions.updateSavableFolderFailure,
    savedReportsActions.deleteSavableFolderFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })),
);

export function reducer(state = initialState, action: Action): SavedReportState {
  return savedReportsReducer(state, action);
}
