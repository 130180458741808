import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { SharedUiPipesMemoizeModule } from '@ui/pipes/memoize';
import { ReportFilterSelectorComponent } from './report-filter-selector.component';

@NgModule({
  declarations: [ReportFilterSelectorComponent],
  exports: [ReportFilterSelectorComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedUiPipesMemoizeModule,
  ]
})
export class ReportFilterSelectorModule {
}
