import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { IAppliedGlobalFiltersAsParams, IExportToken, userMessageFactory } from '@shared/interfaces';
import { OpportunitiesService } from '../services/opportunities.service';
import { isFiltersChanged } from '@util/helpers';
import * as OpportunitiesSelectors from './opportunities.selectors';
import { opportunitiesActions } from './opportunities.actions';
import { notificationMessagesActions } from '@notification-messages';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import * as OrgConfigStore from '@org-config';
import { UserService } from '@user/user.service';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { IOpportunityRequest, IOpportunityUsedQueryParams } from '@measurement-studio/interfaces';
import { IOpportunityColumn, IOpportunityReport, IOpportunityVisual } from '../interfaces';
import { ColumnsSaverService, LocalStorageColumnsKey as Key } from '@shared/data-access/columns-saver';

@Injectable()
export class OpportunitiesEffects {
  constructor(private store: Store<unknown>,
              public opportunityService: OpportunitiesService,
              private actions$: Actions,
              private columnsSaverService: ColumnsSaverService,
              private userService: UserService,
              private downloadCsvService: DownloadCsvService) {
  }

  public loadReport$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.loadReport),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(OpportunitiesSelectors.getOpportunityFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
      ),
    )),
    switchMap(([_, filters, globalFiltersParams]) => {
      const params = {...filters, ...globalFiltersParams} as IOpportunityUsedQueryParams;

      return this.opportunityService.getOpportunityReport$(params).pipe(
        mergeMap((data: IOpportunityReport) => [
          opportunitiesActions.loadReportSuccess({ data }),
          opportunitiesActions.setUsedQueryParams({ params })
        ]),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'features.opportunities.errors.cantLoadData';

          return of(opportunitiesActions.loadReportFailure({ message }));
        })
      );
    })
  ));

  public loadVisualization$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.loadVisualization),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(OpportunitiesSelectors.getOpportunityFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
      )
    )),
    switchMap(([_, filters, globalFiltersParams]) => {
      const params = {
        ...filters,
        ...globalFiltersParams,
        searchField: undefined, // remove search query params from request
        searchQuery: undefined, // remove search query params from request
      };
      return this.opportunityService.getOpportunityVisual$(params).pipe(
        map((data: IOpportunityVisual) => opportunitiesActions.loadVisualizationSuccess({ data })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'features.opportunities.errors.cantLoadVisual';

          return of(opportunitiesActions.loadVisualizationFailure({ message }));
        })
      );
    })
  ));

  public onFilterChange$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.setOpportunityFilters),
    concatMap(action => of(action.filters).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams)),
        this.store.pipe(select(OpportunitiesSelectors.getUsedQueryParams)),
      )
    )),
    filter(([newFilters, globalFilters, usedQueryParams]: [
      IOpportunityRequest, IAppliedGlobalFiltersAsParams, IOpportunityUsedQueryParams
    ]) => isFiltersChanged(usedQueryParams, newFilters, globalFilters)),
    mergeMap(([newFilters, _, usedQueryParams]) => {
      // update visual data only on initialization and when report settings are change
      // if change page or search don't get new visual
      if (usedQueryParams && (newFilters.searchQuery !== usedQueryParams.searchQuery
        || newFilters.searchField !== usedQueryParams.searchField
        || newFilters.ps !== usedQueryParams.ps
        || newFilters.pg !== usedQueryParams.pg)) {
        return [
          opportunitiesActions.loadReport(),
        ];
      } else {
        return [
          opportunitiesActions.loadReport(),
          opportunitiesActions.loadVisualization(),
        ];
      }
    }),
  ));

  public getReportColumns$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.getReportColumns),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(OrgConfigStore.isSigstrEnabled))
      ),
    )),
    map(([_, isSigstrEnabled]) => this.opportunityService.getTableColumns(isSigstrEnabled)),
    map(filteredColumns => {
      const columns: IOpportunityColumn[] = this.columnsSaverService.getColumns(Key.Opportunities, filteredColumns);
      return opportunitiesActions.setReportColumns({ columns })
    }),
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      opportunitiesActions.loadReportFailure,
      opportunitiesActions.loadVisualizationFailure,
      opportunitiesActions.downloadCSVFailure
    ),
    map(action =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public downloadCSV$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.downloadCSV),
    mergeMap(() => {
      return this.userService.authenticateExportToken$().pipe(
        catchError(() => of({}))
      );
    }),
    concatMap((token: IExportToken) => of(token).pipe(
      withLatestFrom(
        this.store.pipe(select(OpportunitiesSelectors.getOpportunityFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAppliedGlobalFiltersAsParams))
      )
    )),
    mergeMap(([token, filters, globalFilters]: [
      IExportToken, IOpportunityRequest, IAppliedGlobalFiltersAsParams
    ]) => {
      return this.opportunityService.downloadCSV$(filters, globalFilters, token).pipe(
        map((data: string) => opportunitiesActions.downloadCSVSuccess({ data })),
        catchError((error: Error) => {
          const message = error?.message || 'features.opportunities.errors.cantDownloadCsv';
          return of(opportunitiesActions.downloadCSVFailure({ message }));
        })
      );
    })
  ));

  public onDownloadCSVSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.downloadCSVSuccess),
    tap(action => {
      this.downloadCsvService.createBlobAndDownload('Opportunities', action.data);
    }),
  ), {dispatch: false});

  public onToggleColumnVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(opportunitiesActions.toggleColumnVisibility),
    concatLatestFrom(() => this.store.pipe(select(OpportunitiesSelectors.getVisibleOpportunityColumns))),
    tap(([_, visibleColumns]) => {
      this.columnsSaverService.saveColumns(Key.Opportunities, visibleColumns);
    })
  ), {dispatch: false});
}
