import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { ReportAccessGuard } from '@shared/data-access/guards';
import { RouteItemEnum } from '@shared/enums';
import { GlobalFiltersResolver } from '@shared/data-access/global-filters';
import { hubTokenName, LOGIN_ROUTE_PATH, PROFILE_ROUTE, SIGN_OUT_ROUTE } from '@shared/constants';
import { HubClaimsKeys, ITokenClaimGuardRouteData, TokenClaimsGuard, TokensAndRequiredClaims } from '@hub-token';
import { AppContainerComponent } from './containers/app/app-container.component';

export interface IRouteData extends ITokenClaimGuardRouteData<TokensAndRequiredClaims> {
  title?: string;
  routeId?: RouteItemEnum;
}

export interface IHubRoutes extends Route {
  data?: IRouteData;
}

const routes: IHubRoutes[] = [
  {
    path: '',
    component: AppContainerComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'scorecard',
          },
          {
            path: 'dashboards',
            loadChildren: () => import ('@measurement-studio/features/dashboards').then(m => m.DashboardsModule),
          },
          {
            path: 'settings',
            loadChildren: () => import ('@measurement-studio/features/settings').then(m => m.SettingsModule),
            canActivate: [
              ReportAccessGuard,
            ],
            data: {
              routeId: RouteItemEnum.Settings,
            }
          },
          {
            path: 'discover',
            loadChildren: () => import ('@measurement-studio/features/discover-routing').then(m => m.DiscoverModule),
          },
          {
            path: 'analyze',
            loadChildren: () => import ('@measurement-studio/features/analyze-routing').then(m => m.AnalyzeModule),
          },
          {
            path: 'ad-insights',
            loadChildren: () => import ('@measurement-studio/features/insights').then(m => m.InsightsReportModule),
            canActivate: [
              ReportAccessGuard,
            ],
            data: {
              title: 'measurementStudio.features.advertisingInsights.title.reportName',
              routeId: RouteItemEnum.AdvertisingInsights,
            }
          },
          {
            path: 'ad-insights',
            loadChildren: () => import ('@measurement-studio/features/insights-details').then(m => m.InsightsDetailsModule),
            data: {
              title: 'measurementStudio.features.advertisingInsights.title.reportName',
              routeId: RouteItemEnum.AdvertisingInsights,
            },
          },
          {
            path: 'scorecard',
            loadChildren: () => import('@measurement-studio/features/scorecard').then(m => m.ScorecardModule),
            canActivate: [
              ReportAccessGuard,
            ],
            data: {
              title: 'feature.scorecard.tabTitle',
              routeId: RouteItemEnum.Scorecard,
            },
          },
          {
            path: SIGN_OUT_ROUTE,
            loadChildren: () => import ('@shared/features/sign-out').then(m => m.SignOutModule),
          },
          {
            path: PROFILE_ROUTE,
            loadChildren: () => import ('@shared/features/profile').then(m => m.ProfileModule),
          },
          {
            path: 'demo-config',
            canActivate: [
              TokenClaimsGuard,
            ],
            data: {
              routeId: RouteItemEnum.DemoConfig,
              title: 'Demo Mode - Measurement Studio',
              tokenClaimsGuard: {
                requiredTokenClaims: [
                  {
                    token: hubTokenName,
                    requiredClaims: [{key: HubClaimsKeys.BFAdmin}],
                  },
                ],
              },
            },
            loadChildren: () => import('@shared/features/demo-config').then(m => m.DemoConfigModule),
          },
        ],
        resolve: {
          globalFilters: GlobalFiltersResolver,
        },
      },
      {
        path: 'page-not-found',
        loadChildren: () => import ('@shared/features/page-not-found').then(m => m.PageNotFoundModule),
        data: {
          title: 'Page Not Found - Measurement Studio',
        }
      },

      {
        path: 'panic',
        loadChildren: () => import ('@shared/features/panic').then(m => m.PanicModule),
        data: {
          title: 'Session Not Found - Measurement Studio'
        }
      },
      {
        path: 'auth_cont',
        loadChildren: () => import ('@shared/features/auth-cont').then(m => m.AuthContModule),
      },
    ]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import ('@shared/features/login-page').then(m => m.LoginPageModule),
    data: {
      title: 'Login Page - Measurement Studio',
    }
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
