import { IFactoryParams } from './factory-params.interface';
import { NotificationTypes } from '@shared/enums';

export interface IUserMessage {
    message: string;
    type: NotificationTypes;
}

export function userMessageFactory({
    n = 'test',
    overrides = {}
}: IFactoryParams<IUserMessage, string> = {}): IUserMessage {
    return {
        message: n,
        type: NotificationTypes.Error,
        ...overrides
    };
}
