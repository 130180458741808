<div class="hub-modal-header">
  <h3 class="hub-modal-title">
    {{ data.title | translate }}
  </h3>
  <button aria-label="Close"
          data-cy="confirmation-dialog-closes"
          class="close-button"
          mat-dialog-close
          title="Close"
          type="button">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="hub-modal-body">
  <p>
    {{ data.message | translate }}
  </p>
  <ng-container *ngTemplateOutlet="data.customTemplate"></ng-container>
</div>
<div class="hub-modal-footer hub-modal-footer-end">
  <ts-button [mat-dialog-close]="false"
             data-cy="confirmation-dialog-cancel"
             [theme]="data.cancelButtonTheme || 'secondary'"
             actionName="Cancel"
             buttonType="button"
             data-cy="confirmation-dialog-cancel">
    {{ (data.cancelLabel || 'shared.common.cancel') | translate }}
  </ts-button>
  <ts-button [mat-dialog-close]="true"
             data-cy="confirmation-dialog-apply"
             [theme]="data.applyButtonTheme || 'default'"
             actionName="Save"
             buttonType="button"
             data-cy="confirmation-dialog-apply">
    {{ (data.applyLabel || 'shared.common.apply') | translate }}
  </ts-button>
</div>
