import { CampaignTab } from '../enums/campaign-tab.enum';
import {
  IAttributionDeal,
  IAttributionLead,
  IAttributionOppty,
  ICampaignOrChannelAttribution,
  ICampaignSpecificFilters,
} from '@measurement-studio/interfaces';
import { attributionActions } from './attribution.actions';
import { PER_PAGE } from '@shared/constants';
import { RouteItemEnum, SortDirections } from '@shared/enums';
import {
  CAMPAIGN_CHART_OPTIONS,
  DEALS_COLUMNS,
  DEALS_NESTED_COLUMNS,
  LEADS_COLUMNS,
  LEADS_NESTED_COLUMNS,
  OPPTYS_COLUMNS,
  OPPTYS_NESTED_COLUMNS
} from '../data/attribution.data';
import { IDateCohort, IReportTab } from '@shared/interfaces';
import { ICampaignChart, ICampaignChartType, ICampaignSpecificReport, ICampaignTouchReturns } from '../interfaces';
import { uniqBy } from '@util/helpers';
import { Action, createReducer, on } from '@ngrx/store';

export const campaignSpecificFeatureKey = 'campaignSpecificAttribution';

export interface CampaignAttributionState {
  selectedReport: RouteItemEnum | null; // it could be either CampaignSpecific or WebActivitiesSpecific
  isLoading: boolean;
  tabs: IReportTab[];
  filters: ICampaignSpecificFilters;
  attribution: ICampaignOrChannelAttribution;
  selectedDateCohort: IDateCohort;
  selectedChartType: ICampaignChartType;
  chart: ICampaignChart | null;
  touches: ICampaignTouchReturns[];
  [CampaignTab.Leads]: ICampaignSpecificReport;
  [CampaignTab.Deals]: ICampaignSpecificReport;
  [CampaignTab.Opportunities]: ICampaignSpecificReport;
}

export const initialState: CampaignAttributionState = {
  selectedReport: null,
  isLoading: false,
  filters: null,
  attribution: null,
  selectedDateCohort: null,
  selectedChartType: CAMPAIGN_CHART_OPTIONS[0],
  chart: null,
  touches: null,
  tabs: [{
    key: CampaignTab.Leads,
    title: 'feature.campaignSpecific.tabs.leads.peopleTitle',
    link: 'leads',
    count: 0
  }, {
    key: CampaignTab.Opportunities,
    title: 'feature.campaignSpecific.tabs.opportunities.title',
    link: 'opportunities',
    count: 0
  }, {
    key: CampaignTab.Deals,
    title: 'feature.campaignSpecific.tabs.deals.title',
    link: 'deals',
    count: 0
  }],
  [CampaignTab.Leads]: {
    columns: LEADS_COLUMNS,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    sortState: {
      active: 'activityDate',
      direction: SortDirections.Desc,
    },
    searchQuery: null,
    rowState: {},
    nestedTable: {
      columns: LEADS_NESTED_COLUMNS,
      data: null,
      pagination: {},
      sortState: {},
    }
  },
  [CampaignTab.Deals]: {
    columns: DEALS_COLUMNS,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    sortState: {
      active: '',
      direction: SortDirections.Asc,
    },
    searchQuery: null,
    rowState: {},
    nestedTable: {
      columns: DEALS_NESTED_COLUMNS,
      data: null,
      pagination: {},
      sortState: {},
    }
  },
  [CampaignTab.Opportunities]: {
    columns: OPPTYS_COLUMNS,
    pagination: {
      page: 1,
      perPage: PER_PAGE
    },
    sortState: {
      active: '',
      direction: SortDirections.Asc,
    },
    searchQuery: null,
    rowState: {},
    nestedTable: {
      columns: OPPTYS_NESTED_COLUMNS,
      data: null,
      pagination: {},
      sortState: {},
    }
  }
};

export const attributionReducer = createReducer(
  initialState,
  on(
    attributionActions.setSelectedReport,
    (state, action)  => ({
        ...state,
        selectedReport: action.route
      })
  ),
  on(
    attributionActions.loadCampaignAttribution,
    (state) => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    attributionActions.loadCampaignAttributionSuccess,

    (state, action) => {
      const tabs = state.tabs.map(tab => {
        tab.count = action.attribution[tab.key].length;
        return tab;
      });
      return {
        ...state,
        attribution: action.attribution,
        tabs,
        isLoading: false
      };
    }
  ),
  on(
    attributionActions.setCampaignAttribution,
    (state, action) => ({
      ...state,
      attribution: action.attribution
    })
  ),
  on(
    attributionActions.setCampaignChartData,
    (state, action) => ({
      ...state,
      chart: action.chart
    })
  ),
  on(
    attributionActions.loadCampaignAttributionFailure,
    (state) => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    attributionActions.loadCampaignTouchesSuccess,
    (state, action) => ({
      ...state,
      touches: action.touches,
    })
  ),
  on(
    attributionActions.setDateCohort,
    (state, action) => ({
      ...state,
      selectedDateCohort: action.cohort
    })
  ),
  on(
    attributionActions.setCampaignFilters,
    (state, action) => ({
      ...state,
      filters: action.filters
    })
  ),
  on(
    attributionActions.setReportPage,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        pagination: {
          page: action.page,
          perPage: PER_PAGE
        }
      }
    })
  ),
  on(
    attributionActions.setReportSortState,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        sortState: action.state,
        rowState: {}
      }
    })
  ),
  on(
    attributionActions.setReportSearch,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        searchQuery: action.search,
        pagination: {
          ...state[action.key].pagination,
          page: 1
        },
      }
    })
  ),
  on(
    attributionActions.toggleTableRow,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        rowState: {
          ...state[action.key].rowState,
          [action.id]: !state[action.key].rowState[action.id]
        }
      }
    })
  ),
  on(
    attributionActions.setNestedReport,
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        nestedTable: {
          ...state[action.key].nestedTable,
          data: action.data,
          rowState: action.rowState,
          pagination: action.pagination
        }
      }
    })
  ),
  on(
    attributionActions.changeNestedTableSortState,
      // update sort state for nested table in specific report by report key and row id
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        nestedTable: {
          ...state[action.key].nestedTable,
          sortState: {
            ...state[action.key].nestedTable.sortState,
            [action.id]: action.state
          }
        }
      }
    })
  ),
  on(
    attributionActions.changeNestedTablePagination,
      // update sort state for nested table in specific report by report key and row id
    (state, action) => ({
      ...state,
      [action.key]: {
        ...state[action.key],
        nestedTable: {
          ...state[action.key].nestedTable,
          pagination: {
            [action.id]: {
              page: action.page,
              perPage: PER_PAGE
            }
          }
        }
      }
    })
  ),
  on(
    attributionActions.changeSelectedChartType,
    (state, action) => ({
      ...state,
      selectedChartType: action.chartType
    })
  ),
  on(
    attributionActions.collectDataForNestedTable,
      // note: collect nested table data only if we have touches
    (state, action) => {
      const nestedTableData = action.touches && action.touches.length ? getNestedTableData(action) : null;
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          nestedTable: {
            ...state[action.key].nestedTable,
            data: nestedTableData
          }
        }
      };
    }
  )
)

export function reducer(state = initialState, action: Action): CampaignAttributionState {
  return attributionReducer(state, action);
}

function getNestedTableData({key, report, touches}: {
  key: CampaignTab,
  report: (IAttributionLead | IAttributionDeal | IAttributionOppty)[],
  touches: ICampaignTouchReturns[]
}): Record<string, ICampaignTouchReturns[]> {
  const newData: Record<string, ICampaignTouchReturns[]> = {};
  switch (key) {
    case CampaignTab.Leads: {
      (report as IAttributionLead[]).forEach((lead: IAttributionLead) => {
        const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.leadId === lead.id), 'touch.id');
        if (nested.length > 0) {
          newData[lead.id] = nested;
        }
      });
      break;
    }

    case CampaignTab.Deals: {
      (report as IAttributionDeal[]).forEach((deal: IAttributionDeal) => {
        const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.opptyId === deal.opptyId), 'touch.id');
        if (nested.length > 0) {
          newData[deal.opptyId] = nested;
        }
      });
      break;
    }

    case CampaignTab.Opportunities: {
      (report as IAttributionOppty[]).forEach((oppty: IAttributionOppty) => {
        const nested = uniqBy<ICampaignTouchReturns>(touches.filter(touch => touch.opptyId === oppty.opptyId), 'touch.id');
        if (nested.length > 0) {
          newData[oppty.opptyId] = nested;
        }
      });
      break;
    }
  }

  return newData;
}
