import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { hubTokenName } from '@shared/constants';
import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { EnvService } from '@shared/environment';
import { StageInfluenceSource } from '../sources/stage-influence.source';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';
import { InfluenceType, ModelType, ToDate } from '@shared/enums';
import { DataSetType } from '@measurement-studio/enums';
import { IStageInfluenceCSVRequest, IStageInfluenceFilters, IStageInfluenceRequest, IStageInfluenceResponse } from '../interfaces';
import { StageInfluenceColumnType } from '../enums';

@Injectable({providedIn: 'root'})
export class StageInfluenceService {

  constructor(public source: StageInfluenceSource,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignStageInfluence$(params: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    return regenerateOnRetry(() => this.source.getCampaignStageInfluence$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignTypeStageInfluence$(params: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    return regenerateOnRetry(() => this.source.getCampaignTypeStageInfluence$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignStageInfluenceTotals$(params: IStageInfluenceRequest): Observable<IStageInfluenceResponse> {
    return regenerateOnRetry(() => this.source.getCampaignStageInfluenceTotals$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignStageInfluenceCSV$(params: IStageInfluenceCSVRequest): Observable<string> {
    return regenerateOnRetry(() => this.source.getCampaignStageInfluenceCSV$(params))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCohortedFiltersFromParams(params: Params): IStageInfluenceFilters {
    // get query params from url or use default params
    const defaultQueryParams: IStageInfluenceFilters = {
      influenceType: InfluenceType.ANY,
      cohort: ToDate.QUARTER_TO_DATE,
      dataSet: DataSetType.MembershipActivity,
      model: ModelType.Sourced,
      tagIds: [],
      columnType: StageInfluenceColumnType.leadStages,
    };

    const tagIds = params.tagIds
      ? (Array.isArray(params.tagIds) ? params.tagIds : [params.tagIds])
      : defaultQueryParams.tagIds;
    const model = [ModelType.Sourced, ModelType.Last].includes(params.model)
      ? params.model
      : defaultQueryParams.model;
    const typeFilterSpread = params.typeFilter ? {typeFilter: params.typeFilter} : {};

    const filters = {
      influenceType: params.influenceType || defaultQueryParams.influenceType,
      cohort: replaceLegacyCohort(params.cohort) || defaultQueryParams.cohort,
      endDate: params.endDate,
      startDate: params.startDate,
      dataSet: params.dataSet || defaultQueryParams.dataSet,
      model,
      tagIds,
      columnType: params.columnType || defaultQueryParams.columnType,
      ...typeFilterSpread,
    };

    return {
      ...filters,
      ...getStartAndEndDateFromParams(filters.cohort, filters.startDate, filters.endDate),
    };
  }
}
