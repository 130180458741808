import { Injectable } from '@angular/core';
import tinyColor from 'tinycolor2';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private colorRanges = [
    ['#4F5A9F', '#403db1', '#6563b2', '#b3b2d9'],
    ['#EB5488', '#e83d78', '#f5acc5', '#f398b8'],
    ['#FFA600', '#cc8500', '#b17300', '#ffe4b1'],
    ['#63AA4E', '#4f873e', '#9dcb8f', '#d7ead2'],
    ['#008989', '#005656', '#00c4c4', '#c4ffff'],
    ['#8A59A7', '#6f4786', '#b799c9', '#e4d9eb'],
    ['#FF6368', '#ff4a4f', '#ff9ea1', '#ffc5c7'],
    ['#C7AE00', '#948100', '#ffee79', '#dbbf00'],
    ['#34A26A', '#287b51', '#87d8af', '#d1f0e0'],
    ['#C4CBCF', '#66747d', '#9aa6ad', '#424c52']
  ];
  private rangeNumber = 4;

  getColors(groupsLength: number): string[] {
    if (groupsLength === 0) {
      return [];
    }
    // find out the number of colors in the range
    // if <= 4 then we can use the default ranges, otherwise we have to generate missing colors
    const rangeLength = Math.ceil(groupsLength / this.colorRanges.length);
    if (rangeLength <= this.rangeNumber) {
      // leave necessary number of colors in the range
      return this.colorRanges
        .map(range => rangeLength === 4
          ? range
          : rangeLength === 1
            ? range.slice(0, 1) // take colors from amcharts theme
            : range.slice(0, rangeLength)
        ).flat();
    }

    const missingNumberOfColorsInRange = rangeLength - this.rangeNumber;

    if (missingNumberOfColorsInRange < 3) {
      return this.colorRanges.map(range => {
        const newColors = missingNumberOfColorsInRange === 1
          ? [tinyColor(range[0]).darken(20).toString()]
          : [tinyColor(range[0]).darken(20).toString(), tinyColor(range[0]).darken(40).toString()];

        return [...range, ...newColors];
      }).flat();
    }

    return this.colorRanges.map(range => {
      // add missing number of color to the range
      const newColors =
        tinyColor(range[0]).analogous(missingNumberOfColorsInRange + 1)  // add 1 because we remove 1 color from the array (see below)
          .map(c => c.toHexString())
          .slice(1); // remove 1st color because it duplicates the default one
      return [...range, ...newColors];
    }).flat();
  }
}
