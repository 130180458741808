import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { TS_PAGINATOR_PAGE } from '@terminus-lib/ui-paginator';

import { PER_PAGE } from '@shared/constants';

@Component({
  selector: 'tsh-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnChanges {
  @Input() public isZeroBased = false;
  @Input() public isSimpleMode = false;

  @Input()
  public set currentPageIndex(idx: number) {
    this.activePage = this.createPage(idx);
    this.setSummary();
  }

  @Input()
  public set totalRecords(total: number) {
    this.pages = this.createPages(total, this.perPageOption);
    this.setSummary();
  }

  @Input() public showRecordsPerPageSelector = false;
  @Input() public paginatorSummary: string;
  @Input() public perPageOption = PER_PAGE;
  @Output() public pageSelect = new EventEmitter<number>();

  public activePage: TS_PAGINATOR_PAGE;
  public pages: (TS_PAGINATOR_PAGE & { size: number })[];
  public _summary: string;

  constructor(public changeDetector: ChangeDetectorRef) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalRecords && changes.totalRecords.currentValue) {
      // TODO: it seems like duplicated logic almost the same we do in setter
      this.pages = this.createPages(changes.totalRecords.currentValue, this.perPageOption);
      this.setSummary();
      this.changeDetector.detectChanges();
    }
    /* istanbul ignore next */
    if (changes.perPageOption && changes.perPageOption.currentValue != null && this.totalRecords) {
      this.pages = this.createPages(this.totalRecords, changes.perPageOption.currentValue);
      this.setSummary();
      this.changeDetector.detectChanges();
    }
  }

  emitPageChange(page: number): void {
    if (this.activePage && page !== this.activePage.pageNumber) {
      const pageNum = this.isZeroBased ? page : (page + 1);
      this.currentPageIndex = pageNum;
      this.changeDetector.detectChanges();
      this.pageSelect.emit(pageNum);
    }
  }

  onNextPageSelect(): void {
    const nextPage = <number>this.activePage.pageNumber + (this.isZeroBased ? 1 : 2);
    this.currentPageIndex = nextPage;
    this.pageSelect.emit(nextPage);
  }

  onPreviousPageSelect(): void {
    const prevPage = <number>this.activePage.pageNumber - (this.isZeroBased ? 1 : 0);
    this.currentPageIndex = prevPage;
    this.pageSelect.emit(prevPage);
  }

  createPage(page: number): TS_PAGINATOR_PAGE {
    const pageNumber = this.isZeroBased ? page : (page - 1);
    const pageDisplay = pageNumber + 1;
    return {
      pageNumber,
      pageDisplay,
    };
  }

  createPages(total: number, pageSize: number): (TS_PAGINATOR_PAGE & { size: number })[] {
    const totalPages = Math.ceil(total / pageSize);
    let _total = total;
    return [...new Array(totalPages)].map((_, idx) => {
      const size = _total > this.perPageOption ? this.perPageOption : _total;
      _total -= this.perPageOption;
      return {
        ...this.createPage(this.isZeroBased ? idx : (idx + 1)),
        size
      };
    });
  }

  setSummary(): void {
    const idx = <number>this.activePage.pageNumber;
    const start = idx * this.perPageOption + 1;
    const end = idx * this.perPageOption + (this.pages?.length ? this.pages[this.activePage.pageNumber]?.size : this.perPageOption);
    this._summary = `${start}-${end} of ${
      this.pages?.length
        ? (this.perPageOption * (this.pages.length - 1) + this.pages[this.pages.length - 1].size)
        : this.perPageOption
    } `;
  }

}
