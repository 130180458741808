import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { IReportsAccess } from '@shared/interfaces';
import { RouteItemEnum } from '@shared/enums';
import * as UserStore from '@user/user.store';
import { selectIsUserProfileLoaded } from '@shared/data-access/user-common';

@Injectable({
  providedIn: 'root'
})
export class ReportAccessGuard implements CanActivate {
  userProfileLoadedState$ = this.store.pipe(
    select(selectIsUserProfileLoaded),
    map((loaded: boolean) => loaded)
  );

  reportsAccessState$ = this.store.pipe(
    select(UserStore.selectUserProfileReportsAccess),
    map((reportsAccess: IReportsAccess) => reportsAccess)
  );

  constructor(private store: Store<unknown>,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isAnalyzeMarketingImpact = route.data['routeId'] === RouteItemEnum.AnalyzeMarketingImpact;
    const routeId = isAnalyzeMarketingImpact ? RouteItemEnum.MarketingImpact : route.data['routeId'];

    return this.userProfileLoadedState$.pipe(
      filter((loaded: boolean) => loaded),
      withLatestFrom(this.reportsAccessState$),
      map(([_, reportsAccess]: [boolean, IReportsAccess]) => {
        // if there is no access to current report then navigate to dashboards page. Because
        // its available for everyone. And home page (Scorecard report right now) is not available for everyone
        const isAvailableReport = routeId === RouteItemEnum.AttributionByCampaignType
          ? reportsAccess[RouteItemEnum.AttributionByCampaignType] || reportsAccess[RouteItemEnum.AnalyzeAttributionByCampaignType]
          : reportsAccess[routeId];

        if (!isAvailableReport) {
          this.router.navigate(['dashboards']);
        }
        return !!isAvailableReport;
      })
    );
  }
}
