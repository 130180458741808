import { ActionType, createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import * as interfaces from '@shared/interfaces';

export const DashboardsActionsTypes = {
  LoadList: '[Dashboards] Load list',
  LoadListSuccess: '[Dashboards] Load list Success',
  LoadListFailure: '[Dashboards] Load List Failure',
  LoadDetails: '[Dashboards] Load Details',
  LoadDetailsSuccess: '[Dashboards] Load Details Success',
  LoadDetailsFailure: '[Dashboards] Load Details Failure',
  Create: '[Dashboards] Create dashboard',
  CreateSuccess: '[Dashboards] Create dashboard Success',
  CreateFailure: '[Dashboards] Create dashboard Failure',
  Edit: '[Dashboards] Edit dashboard',
  EditSuccess: '[Dashboards] Edit dashboard Success',
  EditFailure: '[Dashboards] Edit dashboard Failure',
  DeleteDashboard: '[Dashboards] Delete dashboard',
  DeleteDetails: '[Dashboards] Delete details',
  DeleteDashboardSuccess: '[Dashboards] Delete dashboard Success',
  DeleteDashboardFailure: '[Dashboards] Delete dashboard Failure',
  Duplicate: '[Dashboards] Duplicate dashboard',
  DuplicateDetails: '[Dashboards] Duplicate details',
  DuplicateSuccess: '[Dashboards] Duplicate dashboard Success',
  DuplicateFailure: '[Dashboards] Duplicate dashboard Failure',
  SetSortState: '[Dashboards] Set Sort State',
  SetPagination: '[Dashboards] Set Pagination',
  SetSearchQuery: '[Dashboards] Set Search Query',
  SetVisibility: '[Dashboards] Set Visibility',
  SetDetailsRouteLabel: '[Dashboards] Set Details Route Label',
};

const loadList = createAction(DashboardsActionsTypes.LoadList);

const loadListSuccess = createAction(
  DashboardsActionsTypes.LoadListSuccess,
  props<{ data: interfaces.IDashboard[] }>()
);

const loadListFailure = createAction(
  DashboardsActionsTypes.LoadListFailure,
  props<{ message: string }>()
);

const loadDetails = createAction(
  DashboardsActionsTypes.LoadDetails,
  props<{ id: string }>());

const loadDetailsSuccess = createAction(
  DashboardsActionsTypes.LoadDetailsSuccess,
  props<{ data: interfaces.IDashboard }>()
);

const loadDetailsFailure = createAction(
  DashboardsActionsTypes.LoadDetailsFailure,
  props<{ message: string }>()
);

const create = createAction(
  DashboardsActionsTypes.Create,
  props<{ request: interfaces.ICreateDashboardRequest }>()
);

const createSuccess = createAction(
  DashboardsActionsTypes.CreateSuccess,
  props<{ data: interfaces.IDashboard }>()
);

const createFailure = createAction(
  DashboardsActionsTypes.CreateFailure,
  props<{ message: string }>()
);

const edit = createAction(
  DashboardsActionsTypes.Edit,
  props<{ item: interfaces.IDashboard }>()
);

const editSuccess = createAction(
  DashboardsActionsTypes.EditSuccess,
  props<{ data: interfaces.IDashboard }>()
);

const editFailure = createAction(
  DashboardsActionsTypes.EditFailure,
  props<{ message: string }>()
);

const deleteDashboard = createAction(
  DashboardsActionsTypes.DeleteDashboard,
  props<{ item: interfaces.IDashboard }>());

const deleteDetails = createAction(
  DashboardsActionsTypes.DeleteDetails,
  props<{ item: interfaces.IDashboard }>());

const deleteDashboardSuccess = createAction(
  DashboardsActionsTypes.DeleteDashboardSuccess,
  props<{ data: interfaces.IDashboard }>()
);

const deleteDashboardFailure = createAction(
  DashboardsActionsTypes.DeleteDashboardFailure,
  props<{ message: string }>()
);

const duplicate = createAction(
  DashboardsActionsTypes.Duplicate,
  props<{ id: string, name: string }>());

const duplicateDetails = createAction(
  DashboardsActionsTypes.DuplicateDetails,
  props<{ id: string, name: string }>());

const duplicateSuccess = createAction(
  DashboardsActionsTypes.DuplicateSuccess,
  props<{ data: interfaces.IDashboard, originalName: string }>()
);

const duplicateFailure = createAction(
  DashboardsActionsTypes.DuplicateFailure,
  props<{ message: string }>()
);

const setSortState = createAction(
  DashboardsActionsTypes.SetSortState,
  props<{ data: TsSortState }>()
);

const setPagination = createAction(
  DashboardsActionsTypes.SetPagination,
  props<{ page: number }>()
);

const setSearchQuery = createAction(
  DashboardsActionsTypes.SetSearchQuery,
  props<{ query: string }>()
);

const setVisibility = createAction(
  DashboardsActionsTypes.SetVisibility,
  props<{ visibility: boolean | null }>()
);

const setDetailsRouteLabel = createAction(
  DashboardsActionsTypes.SetDetailsRouteLabel,
);

export const dashboardsActions = {
  loadList,
  loadListSuccess,
  loadListFailure,
  loadDetails,
  loadDetailsSuccess,
  loadDetailsFailure,
  create,
  createSuccess,
  createFailure,
  edit,
  editSuccess,
  editFailure,
  deleteDashboard,
  deleteDetails,
  deleteDashboardSuccess,
  deleteDashboardFailure,
  duplicate,
  duplicateDetails,
  duplicateSuccess,
  duplicateFailure,
  setSortState,
  setPagination,
  setSearchQuery,
  setVisibility,
  setDetailsRouteLabel,
}

export type MutationSuccessActions = ActionType<typeof createSuccess>
  | ActionType<typeof editSuccess>
  | ActionType<typeof deleteDashboardSuccess>
  | ActionType<typeof duplicateSuccess>
  ;
