import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { hubTokenName } from '@shared/constants';
import { EnvService } from '@shared/environment';
import { AnalyticsTotalsSource } from '../sources/analytics-totals.source';
import * as interfaces from '../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

@Injectable({providedIn: 'root'})
export class AnalyticsTotalsService {
  constructor(public source: AnalyticsTotalsSource,
              public retry: RetryWithEscalation,
              envService: EnvService) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getCampaignAnalyticsMetaDataTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignMetaDataTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsMetaDataTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsResponsesTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsAttributionTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsInfluenceTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsReturnsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getCampaignAnalyticsCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    return regenerateOnRetry(() => this.source.getCampaignAnalyticsCostsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    return regenerateOnRetry(() => this.source.getWebActivitiesResponsesTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    return regenerateOnRetry(() => this.source.getWebActivitiesAttributionTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    return regenerateOnRetry(() => this.source.getWebActivitiesInfluenceTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    return regenerateOnRetry(() => this.source.getWebActivitiesReturnsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getWebActivitiesCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    return regenerateOnRetry(() => this.source.getWebActivitiesCostsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSourceResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    return regenerateOnRetry(() => this.source.getSourceResponsesTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSourceAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    return regenerateOnRetry(() => this.source.getSourceAttributionTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSourceInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    return regenerateOnRetry(() => this.source.getSourceInfluenceTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSourceReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    return regenerateOnRetry(() => this.source.getSourceReturnsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getSourceCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    return regenerateOnRetry(() => this.source.getSourceCostsTotal$(body))
      .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}
