import { IInsightsList } from '../interfaces';

export const PAGE_VIEWS_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.underServed.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.underServed.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.underPerforming.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.underPerforming.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.overSpent.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pageViewsInfluenced.overSpent.body'
  }
];

export const LEADS_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.underServed.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.underServed.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.underPerforming.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.underPerforming.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.overSpent.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.leadsInfluenced.overSpent.body'
  }
];

export const CAMPAIGN_RESPONSES_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.underServed.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.underServed.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.underPerforming.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.underPerforming.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.overSpent.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.campaignResponsesInfluenced.overSpent.body'
  }
];

export const NEW_PIPELINE_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.valueLift.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.valueLift.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.underServed.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.underServed.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.underPerforming.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.underPerforming.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.overSpent.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.newPipelineInfluenced.overSpent.body'
  }
];

export const PIPELINE_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.valueLift.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.valueLift.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.velocityLift.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.velocityLift.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.overSpent.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.pipelineInfluenced.overSpent.body'
  }
];

export const REVENUE_INFLUENCED_INSIGHTS_LISTS: IInsightsList[] = [
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.revenueInfluenced.valueLift.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.revenueInfluenced.valueLift.body'
  },
  {
    title: 'measurementStudio.features.advertisingInsights.insightsLists.revenueInfluenced.velocityLift.title',
    body: 'measurementStudio.features.advertisingInsights.insightsLists.revenueInfluenced.velocityLift.body'
  }
];
