export function capitalizeFirstLetter(word: string): string {
  if (word && typeof word === 'string') {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return '';
}

export function toCapitalize(title: string): string {
  if (title && typeof title === 'string') {
    return title.split(' ').map(capitalizeFirstLetter).join(' ');
  }

  return '';
}

export function compare<T>(a: T, b: T, ascending: boolean): number {
  return ((a ?? '') < (b ?? '') ? -1 : 1) * (ascending ? 1 : -1);
}

// uniqBy function allows you to filter your array and keep only one of the same predicate value
// uniqBy([{id: 1}, {id: 1}, {id: 1}], 'id') => [{id: 1}]
export function uniqBy<ArrayType>(arr: ArrayType[], predicate: string): ArrayType[] {
  if (!Array.isArray(arr)) {
    return [];
  }
  const predicates = predicate.split('.');
  const cb = typeof predicate === 'function' ? predicate : (o) => {
    let i = 0;
    let result = o;
    while (predicates[i]) {
      result = result[predicates[i]];
      i++;
    }
    return result;
  };
  const pickedObjects = arr
    .reduce((newMap, item) => {
      const key = cb(item);
      if (!key) {
        return newMap;
      }

      return newMap.has(key) ? newMap : newMap.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
}

export const toSnakeCase = (str: string): string =>
  (str || '').replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
