import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TsTableDataSource } from '@terminus-lib/ui-table';
import { TsSortDirection, TsSortState } from '@terminus-lib/ui-sort';

import { DataTypeEnum } from '@shared/enums';
import { GoParams, IEditTableColumn } from '@shared/interfaces';
import { ISearchOption, ISearchQuery } from '@ui/components/search-by-property';
import { IOpportunityColumn, IOpportunityItem, IOpportunityTotals } from '../../interfaces';
import { IOpportunityRequest } from '@measurement-studio/interfaces';
import { convertOpportunitySortDirection, convertToOpportunitySortDirection } from '../../utils/opportunities.utils';

@Component({
  selector: 'ms-opportunities-table',
  templateUrl: './opportunities-table.component.html',
  styleUrls: ['./opportunities-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesTableComponent implements OnChanges {
  @Input() public totals: IOpportunityTotals;
  @Input() public report: IOpportunityItem[];
  @Input() public filters: IOpportunityRequest;
  @Input() public isLoading: boolean;
  @Input() public visibleColumns: IOpportunityColumn[];
  @Input() public allColumns: IOpportunityColumn[];
  @Input() public searchOptions: ISearchOption[];
  @Input() public currency: string | null;
  @Output() public redirect = new EventEmitter<GoParams>();
  @Output() public changeColumns = new EventEmitter<IEditTableColumn>();
  @Output() public search = new EventEmitter<IOpportunityRequest>();
  @Output() public changePage = new EventEmitter<IOpportunityRequest>();
  @Output() public changeSort = new EventEmitter<IOpportunityRequest>();
  @Output() public download = new EventEmitter<void>();

  public dataSource: TsTableDataSource<IOpportunityItem> = new TsTableDataSource();
  public dataTypes = DataTypeEnum;
  public sortDirection: TsSortDirection = '';

  constructor(public changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.report
      && changes.report.currentValue
      && changes.report.currentValue !== changes.report.previousValue) {
      this.dataSource.data = this.report;
      this.changeDetector.detectChanges();
    }

    if (changes.report || changes.allColumns || changes.visibleColumns) {
      this.changeDetector.detectChanges();
    }

    if (changes.filters && this.filters) {
      this.sortDirection = convertOpportunitySortDirection(this.filters.dir);
    }
  }

  emitRedirect(params: GoParams): void {
    if (params) {
      this.redirect.emit(params);
    }
  }

  emitChangeSort(data: TsSortState): void {
    const dir = convertToOpportunitySortDirection(data.direction);
    const fld = dir ? data.active : null;
    const tbl = dir ? this.visibleColumns.find(column => column.name === data.active).apiType : null;
    this.changeSort.emit({
      ...this.filters,
      fld,
      tbl,
      dir,
    });
  }

  emitChangeColumns(data: IEditTableColumn): void {
    this.changeColumns.emit(data);
  }

  emitSearch(searchData: ISearchQuery): void {
    this.search.emit({
      ...this.filters,
      pg: 1,
      ...searchData
    });
  }

  emitDownload(): void {
    this.download.emit();
  }

  emitPageChange(page: number): void {
    this.changePage.emit({
      ...this.filters,
      pg: page
    });
  }
}
