import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IDcLastUpdatedDate } from '@shared/interfaces';

export enum AppSettingsSourceURLs {
  GetLastUpdate = '/api/v1/last_update',
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsSource extends HttpClientBase {

  getDcLastUpdatedDate$(): Observable<IDcLastUpdatedDate> {
    const endpoint = this.endpoint(AppSettingsSourceURLs.GetLastUpdate);

    return this.httpClient.get<IDcLastUpdatedDate>(endpoint);
  }
}
