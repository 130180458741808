// Should we use number or string as value ??
export enum VisualizationTypes {
    StackedArea = 'stackedArea',
    Pareto = 'pareto',
    Donut = 'donut',
    Gauge = 'gauge',
    Line = 'line',
    StackedColumn = 'stackedColumn',
    Column = 'column',
    Table = 'table',
    ClusteredColumn = 'clusteredColumn',
}
