import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { getCurrencySymbol, getLocaleCurrencySymbol } from '@angular/common';
import { Router } from '@angular/router';

import { TsWindowService } from '@terminus-lib/fe-utilities';

import { IReportColumn } from '@shared/interfaces';
import { DataTypeEnum } from '@shared/enums';
import { formatDataByDataType } from '@shared/util/data-type';

@Injectable({providedIn: 'root'})
export class DownloadCsvService {

  constructor(@Inject(LOCALE_ID) private locale: string,
              private router: Router,
              private windowService: TsWindowService) {
  }

  getCsvData<T, U>(data: T[], columns: IReportColumn[], currency: string | null, totals?: U): string {
    const separator = ',';
    const currencySymbol = currency
      ? getCurrencySymbol(currency, 'narrow')
      : getLocaleCurrencySymbol(this.locale);

    const replacer = (item: T | U) => columns.map(column => {
      if (column.dataType !== DataTypeEnum.Text) {
        return `"${formatDataByDataType(
          item[column.name],
          column.dataType,
          this.locale,
          column.decimal,
          column.shouldAbbreviate || false,
          currencySymbol
        )}"`; // use "" to avoid crashing data in csv program
      }

      return item[column.name] ? `"${item[column.name]}"` : ''; // use "" to avoid crashing data in csv program
    }).join(separator);
    const rows = data.map(replacer);
    const totalRow = totals ? 'Totals' + replacer(totals) : '';
    const headerRow = columns.map(column => column.displayName).join(separator);
    // use "" to avoid crashing data in csv program
    return [headerRow, ...rows, totalRow, '', `"${this.router.url}"`].join('\r\n');
  }

  downloadCsv<T, U>(fileName: string, data: T[], columns: IReportColumn[], currency: string | null, totals?: U): void {
    const csv = this.getCsvData(data, columns, currency, totals);
    this.createBlobAndDownload(fileName, csv);
  }

  createBlobAndDownload(fileName: string, csv: string): void {
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    this.downloadBlob(fileName, blob);
  }

  downloadBlob(fileName: string, blob: Blob): void {
    if (this.windowService.nativeWindow.navigator['msSaveBlob']) { // IE 10+
      this.windowService.nativeWindow.navigator['msSaveBlob'](blob, fileName);
    } else {
      const a = this.windowService.nativeWindow.document.createElement('a');
      if (a.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        a.href = this.windowService.nativeWindow['URL'].createObjectURL(blob);
        a.download = `${fileName}.csv`;
        a.dispatchEvent(new MouseEvent('click'));
        // delete the internal blob reference, to let the browser clear memory from it
        this.windowService.nativeWindow['URL'].revokeObjectURL(a.href);
      }
    }
  }
}
