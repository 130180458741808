<ts-paginator *ngIf="activePage && pages?.length"
              data-cy="pagination"
              [activePage]="activePage"
              [isSimpleMode]="isSimpleMode"
              [pages]="pages"
              (previousPageClicked)="onPreviousPageSelect()"
              (pageClicked)="emitPageChange($event)"
              (nextPageClicked)="onNextPageSelect()"
              [paginatorSummary]="paginatorSummary && (_summary + (paginatorSummary | translate))">
</ts-paginator>
