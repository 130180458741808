import { IInsightsDetailsTableConfiguration } from '../interfaces';
import * as Columns from './details-report-columns.consts';
import * as Tabs from './details-report-tabs.consts';
import * as TotalTitles from './details-report-total-titles.consts';
import * as Keys from '../enums/insights-report-tab';
import { SortDirections } from '@shared/enums';
import { INSIGHTS_DETAILS_COLUMN_DEFINITIONS } from './details-report-column-configs.consts';

export const PAGE_VIEWS_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.PAGE_VIEWS_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.PAGE_VIEWS_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.PAGE_VIEWS_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.PageViewsInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.PageViewsInfluencedReportTab.AdTactics]: 'tacticUuid'
  },
  queryIdProperty: {
    [Keys.PageViewsInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.PageViewsInfluencedReportTab.AdTactics]: 'tacticUuid'
  },
  nameProperty: {
    [Keys.PageViewsInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.PAGE_VIEWS_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.PAGE_VIEWS_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.PAGE_VIEWS_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: '',
      direction: SortDirections.Asc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: '',
      direction: SortDirections.Asc
    }
  }
};

export const LEADS_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.LEADS_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.LEADS_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.LEADS_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.LeadsInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.LeadsInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.LeadsInfluencedReportTab.Leads]: 'crmLeadId'
  },
  queryIdProperty: {
    [Keys.LeadsInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.LeadsInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.LeadsInfluencedReportTab.Leads]: 'crmLeadId'
  },
  nameProperty: {
    [Keys.LeadsInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.LEADS_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.LEADS_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.LEADS_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.LeadName.name,
      direction: SortDirections.Asc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.LeadName.name,
      direction: SortDirections.Asc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  }
};

export const CAMPAIGN_RESPONSES_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.CAMPAIGN_RESPONSES_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.CAMPAIGN_RESPONSES_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.CAMPAIGN_RESPONSES_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.CampaignResponsesInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.CampaignResponsesInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]: 'id'
  },
  queryIdProperty: {
    [Keys.CampaignResponsesInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.CampaignResponsesInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.CampaignResponsesInfluencedReportTab.CampaignResponses]: 'crmCampaignId'
  },
  nameProperty: {
    [Keys.CampaignResponsesInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.CAMPAIGN_RESPONSES_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.CAMPAIGN_RESPONSES_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.CAMPAIGN_RESPONSES_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.CampaignName.name,
      direction: SortDirections.Asc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.CampaignName.name,
      direction: SortDirections.Asc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  }
};

export const NEW_PIPELINE_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.NEW_PIPELINE_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.NEW_PIPELINE_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.NEW_PIPELINE_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.NewPipelineInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.NewPipelineInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.NewPipelineInfluencedReportTab.Pipeline]: 'crmOpportunityId'
  },
  queryIdProperty: {
    [Keys.NewPipelineInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.NewPipelineInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.NewPipelineInfluencedReportTab.Pipeline]: 'crmOpportunityId'
  },
  nameProperty: {
    [Keys.NewPipelineInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.NEW_PIPELINE_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.NEW_PIPELINE_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.NEW_PIPELINE_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.OpportunityName.name,
      direction: SortDirections.Asc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  }
};

export const PIPELINE_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.PIPELINE_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.PIPELINE_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.PIPELINE_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.PipelineInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.PipelineInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.PipelineInfluencedReportTab.Pipeline]: 'crmOpportunityId'
  },
  queryIdProperty: {
    [Keys.PipelineInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.PipelineInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.PipelineInfluencedReportTab.Pipeline]: 'crmOpportunityId'
  },
  nameProperty: {
    [Keys.PipelineInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.PIPELINE_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.PIPELINE_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.PIPELINE_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.OpportunityName.name,
      direction: SortDirections.Asc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  }
};

export const REVENUE_INFLUENCED_TABLE_CONFIG: IInsightsDetailsTableConfiguration = {
  reportTabs: Tabs.REVENUE_INFLUENCED_REPORT_TABS,
  tableColumns: Columns.REVENUE_INFLUENCED_REPORT_COLUMNS,
  nestedTableColumns: Columns.REVENUE_INFLUENCED_CHILD_TABLE_COLUMNS,
  parentTableIdProperty: {
    [Keys.RevenueInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.RevenueInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.RevenueInfluencedReportTab.Revenue]: 'crmOpportunityId'
  },
  queryIdProperty: {
    [Keys.RevenueInfluencedReportTab.Accounts]: 'crmAccountId',
    [Keys.RevenueInfluencedReportTab.AdTactics]: 'tacticUuid',
    [Keys.RevenueInfluencedReportTab.Revenue]: 'crmOpportunityId'
  },
  nameProperty: {
    [Keys.RevenueInfluencedReportTab.AdTactics]: 'tacticName'
  },
  totalTitle: TotalTitles.REVENUE_INFLUENCED_TOTAL_TITLES,
  nestedTotalTitle: TotalTitles.REVENUE_INFLUENCED_NESTED_TOTAL_TITLES,
  paginatorSummary: TotalTitles.REVENUE_INFLUENCED_TOTAL_TITLES,
  defaultParentTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  },
  defaultChildTableSortStates: {
    account: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    },
    tactic: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.OpportunityName.name,
      direction: SortDirections.Asc
    },
    report: {
      active: INSIGHTS_DETAILS_COLUMN_DEFINITIONS.Impressions.name,
      direction: SortDirections.Desc
    }
  }
};

