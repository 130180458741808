import { IFactoryParams } from '@shared/interfaces';
import { ICampaignIdentifiable } from '../campaign-identifiable.interface';

export interface ICampaignsTableBase extends ICampaignIdentifiable {
  cost: number;
  totalTouches: number;
  responses: number;
  people: number;
  accounts: number;
  opptyTouches: number;
  attributedPipeline: number;
  dealTouches: number;
  attributedRevenue: number;
  opptys: number;
  influencedPipeline: number;
  deals: number;
  influencedRevenue: number;
  pipelineRoi: number;
  roi: number;
  roas: number;
  cpl: number;
  cpo: number;
  cpd: number;
  lto: number;
  otd: number;
}

export function campaignsTableBaseFactory({
  n = 1,
  overrides = {},
}: IFactoryParams<ICampaignsTableBase, number, boolean> = {}): ICampaignsTableBase {
  return {
    groupId: `group_id_${n}`,
    cost: n * 1000,
    totalTouches: n * 2000,
    responses: n * 1500,
    people: n * 1200,
    accounts: n * 20,
    opptyTouches: n * 10,
    attributedPipeline: n * 30,
    dealTouches: n * 500,
    attributedRevenue: n * 1200,
    opptys: n * 200,
    influencedPipeline: n * 200,
    deals: n * 200,
    influencedRevenue: n * 1200,
    pipelineRoi: n * 200,
    roi: n * 700,
    roas: n * 700,
    cpl: n * 10,
    cpo: n * 20,
    cpd: n * 30,
    lto: n * 40,
    otd: n * 50,
    ...overrides
  };
}
