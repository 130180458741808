<div class="table-panel-container">
  <div class="container">
    <tsh-search-by-property *ngIf="searchEnabled"
                            (submitted)="emitSearch($event)"
                            (searchQueryChange)="tempSearchQuery = $event"
                            [searchByFieldEnabled]="searchByFieldEnabled"
                            [searchQuery]="searchQuery"
                            [searchOptions]="searchOptions">
    </tsh-search-by-property>
    <span *ngIf="!noResult" class="totals">
      {{ totalCount | typedData: dataTypes.Number : 0 : false }} {{ totalTitle | translate }}
    </span>
    <ng-content select="[leftPart]"></ng-content>
  </div>
  <div class="container">
    <ng-content select="[rightPart]"></ng-content>
    <tsh-table-buttons-panel
        *ngIf="buttonPanelEnabled && !noResult"
        [showColumns]="showColumns"
        [showDownload]="showDownload"
        [showProgress]="showProgress"
        [columns]="columns"
        [isSearchVisible]="showColumnsSearch"
        (download)="emitDownload()"
        (changeColumns)="emitChangeColumns($event)">
    </tsh-table-buttons-panel>
  </div>
</div>
