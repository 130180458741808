import { createSelector } from '@ngrx/store';

import { AnalyticsState, selectAnalyticsState } from '..';
import { campaignFeatureKey, ICampaignState } from './campaign.reducer';
import { gerRecordIds, isStateLoading } from '../../helpers/store.helper';
import { ICampaignTableItem, INestedTableState } from '../../interfaces';
import { defaultPager, defaultSorter } from '../../data';
import { IAnalyticsNested } from '@measurement-studio/interfaces';

export const getCampaign = createSelector(selectAnalyticsState,
  (state: AnalyticsState) => state[campaignFeatureKey]);

export const getReportData = createSelector(getCampaign,
  (state: ICampaignState): Partial<ICampaignTableItem>[] => state.data);

export const getNestedCampaign = createSelector(getCampaign,
  (state: ICampaignState): IAnalyticsNested<ICampaignTableItem> => state.nested);

export const getNestedDataById = createSelector(getNestedCampaign,
  (state: IAnalyticsNested<ICampaignTableItem>, id: string): Partial<ICampaignTableItem>[] => state.data[id]);

export const getNestedSorter = createSelector(getNestedCampaign,
  (state: IAnalyticsNested<ICampaignTableItem>, id: string) => state.sorter[id] || defaultSorter);

export const getNestedPager = createSelector(getNestedCampaign,
  (state: IAnalyticsNested<ICampaignTableItem>, id: string) => state.pager[id] || defaultPager);

export const getNestedRecordIds = createSelector(getNestedCampaign,
  (state: IAnalyticsNested<ICampaignTableItem>, id: string) => gerRecordIds(state.data[id]));

export const getCampaignRecordIds = createSelector(getCampaign,
  (state: ICampaignState) => gerRecordIds(state.data));

export const getCampaignIsLoading = createSelector(getCampaign, isStateLoading);

export const getCampaignIsDriverLoading = createSelector(getCampaign,
  (state: ICampaignState) => state.isDriverLoading);

export const getCampaignTotalResults = createSelector(getCampaign,
  (state: ICampaignState) => state.totalResults);

export const getSelectedSource = createSelector(getCampaign,
  (campaigns: ICampaignState) => campaigns.selectedSource);

export const getNestedTableState = createSelector(getCampaign,
  (state: ICampaignState) => state.nestedTableState);

export const getNestedTableVisibility = createSelector(getNestedTableState,
  (nestedState: INestedTableState): Record<string, boolean> => nestedState.visible);

export const getCampaignUsedParams = createSelector(getCampaign,
  (campaigns: ICampaignState) => campaigns.usedParamsInDriver);
