import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ITrendingReport } from '../../interfaces';

@Component({
  selector: 'ms-trending-buttons',
  templateUrl: './trending-buttons.component.html',
  styleUrls: ['./trending-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendingButtonsComponent {
  @Input() reportData: ITrendingReport[] = [];
  @Input() selectedId: number;
  @Output() selected = new EventEmitter<ITrendingReport>();

  emitSelect(group: ITrendingReport): void {
    if (group.id !== this.selectedId) {
      this.selected.emit(group);
    }
  }
}
