import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import * as interfaces from '../interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

export enum AnalyticsTotalsURLs {
  MetaDataTotals = '/v1/campaign_analytics/campaign/meta_data/totals',
  ResponsesTotals = '/v1/campaign_analytics/campaign/responses/totals',
  AttributionTotals = '/v1/campaign_analytics/campaign/attribution/totals',
  InfluenceTotals = '/v1/campaign_analytics/campaign/influence/totals',
  ReturnsTotals = '/v1/campaign_analytics/campaign/returns/totals',
  CostsTotals = '/v1/campaign_analytics/campaign/costs/totals',
  WebActivitiesResponsesTotals = '/v1/web_activities/campaign/responses/totals',
  WebActivitiesAttributionTotals = '/v1/web_activities/campaign/attribution/totals',
  WebActivitiesInfluenceTotals = '/v1/web_activities/campaign/influence/totals',
  WebActivitiesReturnsTotals = '/v1/web_activities/campaign/returns/totals',
  WebActivitiesCostsTotals = '/v1/web_activities/campaign/costs/totals',
  SourceResponsesTotals = '/v1/web_activities/source/responses/totals',
  SourceAttributionTotals = '/v1/web_activities/source/attribution/totals',
  SourceInfluenceTotals = '/v1/web_activities/source/influence/totals',
  SourceReturnsTotals = '/v1/web_activities/source/returns/totals',
  SourceCostsTotals = '/v1/web_activities/source/costs/totals',
}

@Injectable({providedIn: 'root'})
export class AnalyticsTotalsSource extends HttpClientBase {
  getCampaignAnalyticsMetaDataTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignMetaDataTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.MetaDataTotals);

    return this.httpClient.post<interfaces.ICampaignMetaDataTotals>(endpoint, body);
  }

  getCampaignAnalyticsResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.ResponsesTotals);

    return this.httpClient.post<interfaces.ICampaignResponsesTotals>(endpoint, body);
  }

  getCampaignAnalyticsAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.AttributionTotals);

    return this.httpClient.post<interfaces.ICampaignAttributionTotals>(endpoint, body);
  }

  getCampaignAnalyticsInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.InfluenceTotals);

    return this.httpClient.post<interfaces.ICampaignInfluenceTotals>(endpoint, body);
  }

  getCampaignAnalyticsReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.ReturnsTotals);

    return this.httpClient.post<interfaces.ICampaignReturnsTotals>(endpoint, body);
  }

  getCampaignAnalyticsCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.CostsTotals);

    return this.httpClient.post<interfaces.ICampaignCostsTotals>(endpoint, body);
  }

  getWebActivitiesResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.WebActivitiesResponsesTotals);

    return this.httpClient.post<interfaces.ICampaignResponsesTotals>(endpoint, body);
  }

  getWebActivitiesAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.WebActivitiesAttributionTotals);

    return this.httpClient.post<interfaces.ICampaignAttributionTotals>(endpoint, body);
  }

  getWebActivitiesInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.WebActivitiesInfluenceTotals);

    return this.httpClient.post<interfaces.ICampaignInfluenceTotals>(endpoint, body);
  }

  getWebActivitiesReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.WebActivitiesReturnsTotals);

    return this.httpClient.post<interfaces.ICampaignReturnsTotals>(endpoint, body);
  }

  getWebActivitiesCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.WebActivitiesCostsTotals);

    return this.httpClient.post<interfaces.ICampaignCostsTotals>(endpoint, body);
  }

  getSourceResponsesTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignResponsesTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.SourceResponsesTotals);

    return this.httpClient.post<interfaces.ICampaignResponsesTotals>(endpoint, body);
  }

  getSourceAttributionTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignAttributionTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.SourceAttributionTotals);

    return this.httpClient.post<interfaces.ICampaignAttributionTotals>(endpoint, body);
  }

  getSourceInfluenceTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignInfluenceTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.SourceInfluenceTotals);

    return this.httpClient.post<interfaces.ICampaignInfluenceTotals>(endpoint, body);
  }

  getSourceReturnsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignReturnsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.SourceReturnsTotals);

    return this.httpClient.post<interfaces.ICampaignReturnsTotals>(endpoint, body);
  }

  getSourceCostsTotal$(body: IAnalyticsRequest): Observable<interfaces.ICampaignCostsTotals> {
    const endpoint = this.endpoint(AnalyticsTotalsURLs.SourceCostsTotals);

    return this.httpClient.post<interfaces.ICampaignCostsTotals>(endpoint, body);
  }
}
