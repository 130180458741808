import { createAction, props } from '@ngrx/store';

import { defineTypeEnum } from '@terminus-lib/fe-utilities';
import { TS_SIDENAV_PLATFORM_SWITCHER_CONTENT } from '@terminus-lib/ui-sidenav';

import { INavMenu, IRouteItem, ParentLink, SpecificReportLink } from '@shared/interfaces';

export enum NavMenusActionTypes {
  LOAD = '[NavMenus] Load',
  LOAD_SUCCESS = '[NavMenus] Load Success',
  LOAD_FAILURE = '[NavMenus] Load Failure',

  GET_SWITCHER_CONTENT = '[NavMenus] Get Switcher Content',
  SET_SWITCHER_CONTENT = '[NavMenus] Set Switcher Content',

  SET_SELECTED_ROUTE_ITEM = '[NavMenus] Set Selected Route Item',

  SET_CUSTOM_ROUTE_LABEL = '[NavMenus] Set Custom Route Label for Selected Route Item',
  SET_SPECIFIC_REPORT_LINK = '[NavMenus] Update specific report link route',

  SET_ROUTE_LOGO = '[NavMenus] Set Custom Route Logo link',

  SetParentLink = '[NavMenus] Set Parent link',
}

defineTypeEnum(NavMenusActionTypes);

const Load = createAction(NavMenusActionTypes.LOAD)

const LoadSuccess = createAction(
  NavMenusActionTypes.LOAD_SUCCESS,
  props<{ payload: INavMenu }>()
)

const LoadFailure = createAction(NavMenusActionTypes.LOAD_FAILURE)
const GetSwitcherContent = createAction(NavMenusActionTypes.GET_SWITCHER_CONTENT)
const SetSwitcherContent = createAction(
  NavMenusActionTypes.SET_SWITCHER_CONTENT,
  props<{ payload: TS_SIDENAV_PLATFORM_SWITCHER_CONTENT }>()
)
const SetSelectedRouteItem = createAction(
  NavMenusActionTypes.SET_SELECTED_ROUTE_ITEM,
  props<{ payload: IRouteItem }>()
)
const SetCustomRouteLabel = createAction(
  NavMenusActionTypes.SET_CUSTOM_ROUTE_LABEL,
  props<{ payload: string }>()
)
const SetSpecificReportLink = createAction(
  NavMenusActionTypes.SET_SPECIFIC_REPORT_LINK,
  props<{ payload: SpecificReportLink | null }>()
)
const SetRouteLogo = createAction(
  NavMenusActionTypes.SET_ROUTE_LOGO,
  props<{ payload: string }>()
)

const SetParentLink = createAction(
  NavMenusActionTypes.SetParentLink,
  props<{ parentLink: ParentLink }>()
);

export const navMenusActions = {
  Load,
  LoadSuccess,
  LoadFailure,
  SetSelectedRouteItem,
  SetCustomRouteLabel,
  SetSpecificReportLink,
  GetSwitcherContent,
  SetSwitcherContent,
  SetRouteLogo,
  SetParentLink,
};

